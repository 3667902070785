import {createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import axios from "axios";
import {getAccessToken} from "../auth/auth";

export const exportNotes = async (
  payload: { propertyId: Guid, exportType?: string }
) => {
  const { propertyId, exportType } = payload;

  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/notes/export?format=${exportType ?? 'word'}`
  );

  const uninterceptedAxios = axios.create()

  const response: any = await uninterceptedAxios.post(url, {},
    {
      headers: {
        "Authorization": `Bearer:${getAccessToken()}`,
      },
      responseType: 'blob'
    })

  return response
};
