import React, {useEffect, useState} from "react";
import { ReactComponent as ExpensesIcon } from "../../../images/ExpensesNew.svg";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import {ButtonsWrapper, CloseButton, WorksIconWrapper} from "../works/WorkDrawer";
import {Button, Checkbox, Drawer, Form, Input, notification} from "antd";
import {Label} from "../../screens/RegistrationPage";
import EmailCreatableSelector from "../EmailCreatableSelector";
import {EmailModal, PreviewBox} from "../SendViaEmail";
import ReactHtmlParser from "react-html-parser";
import {Guid} from "guid-typescript";
import {useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../selectors";
import {
  getRecipients,
  processForwardingError,
  safetyRegex,
  transformRecipients
} from "../../screens/helpers";
import {
  ButtonOutlined,
  ButtonSolid,
  ButtonWrapper, ControlButton, Controls,
  ControlsWrapper,
  InputWrapper,
  Title
} from "../works/ForwardWorkDrawer";
import {forwardExpensePreview} from "../../../api-wrapper/email/forwardExpensePreview";
import {forwardExpense} from "../../../api-wrapper/email/forwardExpense";
import {CheckboxStyled, CheckboxWrapper} from "../contacts/ForwardContactDrawer";
import AttachFile from "../attachments/AttachFile";
import {getExpenseById} from "../../../api-wrapper/expenses/getExpenseById";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  expenseId: Guid;
}

const ForwardExpenseDrawer = (props: Props) => {
  const propertyId = useSelector(getPropertyIdSelector).value;
  const {isOpen, toggleDrawerOpen, expenseId} = props;

  const [form] = Form.useForm();

  const [recipients, setRecipients] = useState<any>([])
  const [customMessage, setCustomMessage] = useState("")
  const [ccSender, setCcSender] = useState(false)
  const [isPreviewModalOpen, togglePreviewModal] = useState(false)
  const [preview, setPreview] = useState("")

  const [includePropertyAddress, setIncludePropertyAddress] = useState(false)
  const [includeName, setIncludeName] = useState(false)
  const [includeType, setIncludeType] = useState(false)
  const [includePayee, setIncludePayee] = useState(false)
  const [includeTotalCost, setIncludeTotalCost] = useState(false)
  const [includePaymentDate, setIncludePaymentDate] = useState(false)
  const [includeNotes, setIncludeNotes] = useState(false)
  const [includeFiles, setIncludeFiles] = useState(false)
  const [includeTaxDeductible, setIncludeTaxDeductible] = useState(false)

  const [filesToForward, setFilesToForward] = useState<any>([])
  const [isForwardingAllowed, setIsForwardingAllowed] = useState(true)

  useEffect(() => {
    isOpen && getExpenseById(propertyId, expenseId).then((res) =>
      res && setFilesToForward(res.attachments)
    );
  }, [expenseId, isOpen])

  function restoreInitialState() {
    setRecipients([])
    setCustomMessage("")
    setCcSender(false)
    setPreview("")
    toggleDrawerOpen(false)
    setIncludePropertyAddress(false)
    setIncludeName(false)
    setIncludeType(false)
    setIncludePayee(false)
    setIncludeTotalCost(false)
    setIncludePaymentDate(false)
    setIncludeNotes(false)
    setIncludeFiles(false)
    setIncludeTaxDeductible(false)
    setFilesToForward([])
  }
  
  function selectAll() {
    setIncludePropertyAddress(true)
    setIncludeName(true)
    setIncludeType(true)
    setIncludePayee(true)
    setIncludeTotalCost(true)
    setIncludePaymentDate(true)
    setIncludeNotes(true)
    setIncludeFiles(true)
    setIncludeTaxDeductible(true)
  }

  function deselectAll() {
    setIncludePropertyAddress(false)
    setIncludeName(false)
    setIncludeType(false)
    setIncludePayee(false)
    setIncludeTotalCost(false)
    setIncludePaymentDate(false)
    setIncludeNotes(false)
    setIncludeFiles(false)
    setIncludeTaxDeductible(false)
  }

  function handleForward(mode: string) {
    let sanitizedMessage = customMessage.replace(safetyRegex, '')
    let attachments: any = []
    filesToForward.map((file: any) => attachments.push(file.attachmentId))

    let dataToForward = {
      propertyId,
      expenseId,
      recipients: getRecipients(transformRecipients(recipients)),
      ccSender,
      customMessage: sanitizedMessage,

      isPropertyAddressEnabled: includePropertyAddress,
      isExpenseNameEnabled: includeName,
      isExpenseTypeEnabled: includeType,
      isPayeeNameEnabled: includePayee,
      isTotalCostEnabled: includeTotalCost,
      isPaymentDateEnabled: includePaymentDate,
      isTaxDeductibleEnabled: includeTaxDeductible,
      isExpenseNotesEnabled: includeNotes,
      attachments: includeFiles ? attachments : []
    }

    if (mode === "preview") {
      forwardExpensePreview(dataToForward).then((res: any) => {
        setPreview(res.data.body)
        togglePreviewModal(true)
      })
    }
    if (mode === "send") {
      forwardExpense(dataToForward).then(() => {
        notification.info({
          message: 'Expense has been forwarded successfully',
          placement: "topRight",
        })
        restoreInitialState()
      }).catch((errorResponse: any) => processForwardingError(errorResponse))
    }
  }

  return (<>
      <EmailModal
        width={800}
        style={{top: 10}}
        title={"Send message"}
        visible={isPreviewModalOpen}
        onCancel={() => togglePreviewModal(false)}
        closeIcon={<Button style={{marginLeft: '-3.8rem'}} type={'ghost'}>Cancel</Button>}
        footer={null}>
        <PreviewBox>{ReactHtmlParser(preview)}</PreviewBox>
      </EmailModal>

      <Drawer
        push={{ distance: "32px" }}
        closeIcon={false}
        width={window.innerWidth > 1024 ? "55%" : "100%"}
        visible={isOpen}
        placement="right"
        onClose={restoreInitialState}
        headerStyle={{
          backgroundColor: "#ebf9ff",
          margin: 0,
          padding: "2rem 2rem",
        }}
        title={
          <>
            <section style={{ display: "flex", justifyContent: "space-between" }}>
              <WorksIconWrapper>
                <ExpensesIcon className={"works-theme-icon"} />{" "}
                <span style={{ paddingRight: "0.25rem", color: "#7479E9", fontSize: "0.875", textTransform: "uppercase" }}>
                  <b>Expenses</b>
                </span>
              </WorksIconWrapper>
              <ButtonsWrapper>
                <CloseButton size={"large"}
                             onClick={restoreInitialState}
                >
                  {window.innerWidth > 1024 ?
                    <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
                </CloseButton>
              </ButtonsWrapper>
            </section>
            <section
              style={{
                marginTop: "1.875rem",
                marginBottom: "0.75rem"
              }}
            >
              <Title>Send message</Title>
            </section>
          </>
        }
      >
        <Form
          form={form}
          name="sendEmail"
          layout="vertical"
          size={"large"}
        >
          <InputWrapper>
            <Label style={{marginBottom: "0.75rem"}}>To:</Label>
            <EmailCreatableSelector emailList={recipients} setEmailList={setRecipients} />
            <Checkbox
              style={{marginBottom: '1rem'}}
              checked={ccSender}
              onClick={() => setCcSender(!ccSender)}>
              CC me on this email
            </Checkbox>
            {recipients.length > 1 && ccSender && <p style={{fontWeight: 700, color: "#2a80e1"}}>
                Please note that you will receive {recipients.length} copies of this email.
            </p>}
          </InputWrapper>
          <InputWrapper style={{display: "flex", flexDirection: "column"}}>
            <ControlsWrapper>
              <Label style={{margin: 0}}>Include expense data:</Label>
              <Controls>
                <ControlButton className={"select"} onClick={() => selectAll()}>Select all</ControlButton>
                <ControlButton className={"deselect"} onClick={() => deselectAll()}>Deselect all</ControlButton>
              </Controls>
            </ControlsWrapper>
            <CheckboxWrapper>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeName}
                onClick={() => setIncludeName(!includeName)}>
                Name
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeType}
                onClick={() => setIncludeType(!includeType)}>
                Type
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includePayee}
                onClick={() => setIncludePayee(!includePayee)}>
                Payee name
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeTotalCost}
                onClick={() => setIncludeTotalCost(!includeTotalCost)}>
                Total cost
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includePaymentDate}
                onClick={() => setIncludePaymentDate(!includePaymentDate)}>
                Payment date
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeNotes}
                onClick={() => setIncludeNotes(!includeNotes)}>
                Notes
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeTaxDeductible}
                onClick={() => setIncludeTaxDeductible(!includeTaxDeductible)}>
                Tax deductible status
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includePropertyAddress}
                onClick={() => setIncludePropertyAddress(!includePropertyAddress)}>
                Property address
              </CheckboxStyled>
              <Checkbox
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeFiles}
                onClick={() => setIncludeFiles(!includeFiles)}>
                Documents
              </Checkbox>
            </CheckboxWrapper>
          </InputWrapper>
          <InputWrapper style={{marginBottom: "5.5rem"}}>
            <Label style={{marginBottom: "0.75rem"}}>Your comments:</Label>
            <Form.Item>
              <Input
                style={{borderRadius: "5px"}}
                placeholder={"Add comments here..."}
                value={customMessage}
                onChange={(e) => setCustomMessage(e.target.value)}
              />
            </Form.Item>
            {includeFiles && <AttachFile
                filesToForward={filesToForward}
                setFilesToForward={setFilesToForward}
                isForwardingAllowed={isForwardingAllowed}
                setIsForwardingAllowed={setIsForwardingAllowed}
            />}
          </InputWrapper>
          <ButtonWrapper>
            <ButtonOutlined
              style={{borderRadius: '6px'}}
              type={"primary"}
              onClick={() => handleForward("preview")}
            >Preview</ButtonOutlined>
            <ButtonSolid
              style={{borderRadius: '6px'}}
              type={"primary"}
              onClick={() => handleForward("send")}
              disabled={recipients.length === 0 || !isForwardingAllowed}
            >Send</ButtonSolid>
          </ButtonWrapper>
        </Form>
      </Drawer>
    </>
  )
}

export default ForwardExpenseDrawer;