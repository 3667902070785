import React, {useState} from "react";
import {notification, Switch} from "antd";
import {ControlBox, getStartDate} from "../MaintenanceListItemNew";
import {subscribeToMaintenance} from "../../../../api-wrapper/maintenance/subscribeToMaintenance";
import {getAllMaintenances} from "../../../actions/maintenances";
import {unsubscribeFromMaintenance} from "../../../../api-wrapper/maintenance/unsubscribeFromMaintenance";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../../selectors";
import {getAllOnboardingMaintenances} from "../../../actions/onboardingMaintenances";
import moment from "moment";
import {Guid} from "guid-typescript";

type Props = {
  item: any;
  isOnboarding: boolean;
  refreshParent?: any;
  toggleSubscribe?: (id: Guid, isSubscribed: boolean) => void;
}

const MaintenanceSwitch = (props: Props) => {
  const {item, isOnboarding, refreshParent, toggleSubscribe} = props;
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const [subscribed, toggleSubscribed] = useState(item.subscribed)

  function subscribedNotification(item: any) {
    notification.success({
      message: "Successfully subscribed!",
      description: <p>You have subscribed to receive <span style={{color: '#2A80E1'}}>{item.title}</span> reminders
        starting from {getStartDate(item)}. You can now see the reminders in the To Do list.</p>,
      duration: 5
    });
  }

  function unsubscribedNotification(item: any) {
    notification.success({
      message: "Successfully unsubscribed.",
      description: <p>You have unsubscribed from <span style={{color: '#2A80E1'}}>{item.title}</span></p>,
      duration: 5
    });
  }

  const handleSubscribe = async (item: any) => {
    toggleSubscribe && toggleSubscribed(true)
    const startDate = moment(getStartDate(item)).isSameOrAfter(moment().startOf("day")) ?
      getStartDate(item) : moment().format('YYYY-MM-DD')
    await subscribeToMaintenance({
      propertyId,
      maintenanceId: item.id,
      startDate: startDate
    })
    toggleSubscribe && toggleSubscribe(item.id, true)
    !toggleSubscribe && dispatch(getAllMaintenances(propertyId))
    isOnboarding && dispatch(getAllOnboardingMaintenances(propertyId))
    refreshParent && refreshParent()
    subscribedNotification(item)
  }

  const handleUnsubscribe = async (item: any) => {
    toggleSubscribe && toggleSubscribed(false)
    await unsubscribeFromMaintenance({
      propertyId,
      maintenanceId: item.id
    })
    toggleSubscribe && toggleSubscribe(item.id, false)
    !toggleSubscribe && dispatch(getAllMaintenances(propertyId))
    isOnboarding && dispatch(getAllOnboardingMaintenances(propertyId))
    refreshParent && refreshParent()
    unsubscribedNotification(item)
  }

  return <ControlBox>
    <Switch
      checked={toggleSubscribe ? subscribed : item.subscribed}
      disabled={item.notApplicable}
      onChange={() => item.subscribed ? handleUnsubscribe(item) : handleSubscribe(item)}
    />
  </ControlBox>
}

export default MaintenanceSwitch