import { apiDelete, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export const deleteCustomExpenseType = (propertyId: Guid, customTypeId: Guid, parentTypeId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/expenseType/${customTypeId}/parentType/${parentTypeId}`
  );

  return apiDelete(url);
};
