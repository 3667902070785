import {Drawer, Form, Input, Modal, notification, Popover} from "antd";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {ReactComponent as MyPropertyIcon} from "../../../images/MyProperty.svg";
import messages from "../../screens/messages";
import {
  InputPasswordStyled,
  InputsWrapper,
  InputWrapper,
  Label,
  LabelWrapper,
} from "../../screens/RegistrationPage";
import {useDispatch, useSelector} from "react-redux";
import {authSelector, getPropertyIdSelector, getUserProfileSelector} from "../../selectors";
import {ButtonsWrapper, CloseButton, SaveButton, SaveButtonWrapper} from "../works/WorkDrawer";
import {emailRegex, passwordTest} from "../../screens/helpers";
import {isEmpty} from "lodash";
import { ReactComponent as CrossIcon } from "../../../images/Cross.svg";
import {NOTIFICATION_TOP_RIGHT_PLACEMENT} from "../../helpers/constants";
import {editUser} from "../../../api-wrapper/auth/editUser";
import {getCurrentUserProfile} from "../../actions/getUserProfile";
import {changePassword} from "../../../api-wrapper/auth/changePassword";
import {passwordResult, resultIcon} from "../helper";
import {getAllSubscriptionUsers} from "../../actions/subscriptionUsers";
import {resetSubscriptionLevelAction} from "../../actions/logIn";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
};

const AccountSettingsDrawer = (props: Props) => {
  const {isOpen, toggleDrawerOpen} = props;
  const [formAccount] = Form.useForm();
  const [formPassword] = Form.useForm();
  const [formCookies] = Form.useForm();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const {formatMessage: f} = useIntl();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const userProfile = useSelector(getUserProfileSelector);
  const auth = useSelector(authSelector);

  const [saveDisabled, setSaveDisabled] = useState(false);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);

  const [oldPasswordMismatch, setOldPasswordMismatch] = useState(false);
  const [sameAsOldPassword, setSameAsOldPassword] = useState(false);
  const [passwordStrengthResult, setPasswordStrengthResult] = useState<any>({})

  useEffect(() => {
    formPassword.validateFields(["oldPassword"]);
  },[oldPasswordMismatch]);

  useEffect(() => {
    formPassword.validateFields(["newPassword"]);
  },[sameAsOldPassword]);

  const handleAccountDetailsChange = (e: {
    name: string;
    email: string;
  }) => {
    setSaveDisabled(true);
    const {name, email} = e;

    const executeEditUser = (resetSubscriptionType: boolean) =>
      editUser({
        name,
        email,
        emailCommunication: userProfile.content.emailCommunication,
        marketingCookies: userProfile.content.marketingCookies,
        marketingEmails: userProfile.content.marketingEmails,
        showPets: userProfile.content.showPets
      }).then(() => {
        if (resetSubscriptionType)
          dispatch(resetSubscriptionLevelAction());
        toggleDrawerOpen(false);
        setSaveDisabled(false);
        formAccount.resetFields();
        formCookies.resetFields();
        formPassword.resetFields();
        dispatch(getCurrentUserProfile());
        dispatch(getAllSubscriptionUsers(auth.tenantId));
      }).catch((e) => {
        e.status === 400 && Modal.error({
          title: "Error",
          content: "This email address cannot be used.",
          className: "form-confirm-close-modal"
        });
        setSaveDisabled(false);
      });

    if (auth.subscriptionLevel !== null && auth.subscriptionLevel.includes("mcl")
      && !(email.includes("@mclaren") || email.includes("arby12@outlook") || email.includes("@email"))) {

      Modal.confirm({
        title: "Changing subscription type",
        content:
          "Livlet is free for life for McLaren employees - By changing your email address to a non-McLaren email address you will no longer have access to this offer and may have to pay for the product. Are you sure you wish to continue?",
        okText: "Yes",
        cancelText: "No",
        className: "form-confirm-close-modal",
        onOk() {
          executeEditUser(true);
        },
        onCancel() {
          setSaveDisabled(false);
        }
      });
    } else {
      executeEditUser(false);
    }
  };

  const handleCookiePreferencesChange = (e: {
    marketingCookies: boolean;
  }) => {
    setSaveDisabled(true);
    const {marketingCookies} = e;
    editUser({
      name: userProfile.content.name,
      email: userProfile.content.email,
      emailCommunication: userProfile.content.emailCommunication,
      marketingCookies,
      marketingEmails: userProfile.content.marketingEmails,
      showPets: userProfile.content.showPets
    }).then(() => {
      toggleDrawerOpen(false);
      setSaveDisabled(false);
      formAccount.resetFields();
      formCookies.resetFields();
      formPassword.resetFields();
      dispatch(getCurrentUserProfile());
    });
  };

  const handlePasswordChange = (data: {
    oldPassword: string;
    newPassword: string;
    confirmationPassword: string;
  }) => {
    const {oldPassword, newPassword, confirmationPassword} = data;

    if (isEmpty(newPassword) && isEmpty(confirmationPassword)) {
      api.error({
        message: f(messages.emptyFieldTitle),
        description: f(messages.emptyFieldDescription),
        placement: NOTIFICATION_TOP_RIGHT_PLACEMENT,
      });
    } else {
      if (passwordStrengthResult.final) {
        changePassword({
          oldPassword,
          newPassword,
        }).then(() => {
          toggleDrawerOpen(false);
          setSaveDisabled(false);
          formAccount.resetFields();
          formPassword.resetFields();
        }).catch((e) => {
          let error = e.data.message
          error.includes("same as old one") && setSameAsOldPassword(true)
          error.includes("does not match") && setOldPasswordMismatch(true)
          setSaveDisabled(false);
        });
      }
    }
  };

  const accountInitialValues = () => {
    const {name, email} = userProfile.content;
    return {
      name,
      email
    };
  };

  const cookieInitialValues = () => {
    const {marketingCookies} = userProfile.content;
    return {
      marketingCookies
    };
  };

  useEffect(() => {
    setSaveDisabled(false);
    setFormFieldsChanged(false);
    setSameAsOldPassword(false);
    setOldPasswordMismatch(false);
    formAccount.resetFields();
    formPassword.resetFields();
  }, [formAccount, formPassword, userProfile, propertyId, isOpen]);

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        toggleDrawerOpen(false);
        setSaveDisabled(false);
        formAccount.resetFields();
        formPassword.resetFields();
        dispatch(getCurrentUserProfile());
      },
    });
  }

  return (
    <Drawer
      closeIcon={false}
      width={window.innerWidth > 1024 ? "55%" : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => {
        if (formFieldsChanged) {
          confirmCloseUnsaved();
          return;
        }
        toggleDrawerOpen(false);
        formAccount.resetFields();
      }}
      headerStyle={{
        backgroundColor: "#ebf9ff",
        margin: 0,
        padding: "2rem 2rem",
      }}
      title={
        <>
          <section
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.5rem",
            }}
          >
            <InventoriesIconWrapper>
              <MyPropertyIcon className={"my-property-theme-icon"}/>{" "}
              <span style={{paddingRight: "0.25rem", fontSize: "1rem"}}>Account details</span>
            </InventoriesIconWrapper>
            <ButtonsWrapper>
              <CloseButton
                size={"large"}
                onClick={() => {
                  toggleDrawerOpen(false);
                  formAccount.resetFields();
                }}
              >
                {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
              </CloseButton>
            </ButtonsWrapper>
          </section>
          <Title>Your account</Title>
        </>
      }
    >
      <Form
        form={formAccount}
        name="manageProperty"
        layout="vertical"
        size={"large"}
        onFinish={handleAccountDetailsChange}
        initialValues={accountInitialValues()}
        onFieldsChange={() => setFormFieldsChanged(true)}
      >
        <>
          <span
            style={{fontSize: "1.125rem", fontWeight: 600, color: "#21272B"}}
          >
            Account
          </span>
          <section
            style={{
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "10px",
              padding: "1rem",
              marginTop: "1em",
            }}
          >
            <div style={{paddingBottom: "0.5rem"}}>
              <LabelWrapper>
                <Label>{f(messages.name)}</Label>{" "}
              </LabelWrapper>
              <Form.Item
                key={"name"}
                name={"name"}
                // rules={[
                //   {
                //     required: true,
                //     message: f(messages.validationName),
                //   },
                // ]}
              >
                <InputStyled
                  maxLength={100}
                  placeholder={f(messages.enterFullName)}
                />
              </Form.Item>
              <LabelWrapper>
                <Label>{f(messages.emailAddress)}</Label>{" "}
              </LabelWrapper>
              <Form.Item
                key={"email"}
                name={"email"}
                rules={[
                  {
                    required: true,
                    pattern: emailRegex,
                    message: f(messages.validationEmail),
                  },
                ]}
              >
                <InputStyled
                  maxLength={100}
                  placeholder={f(messages.enterEmailAddress)}
                />
              </Form.Item>
              <SaveButtonWrapper>
                <SaveButton
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  type={"primary"}
                  htmlType={"submit"}
                  //disabled={!formFieldsChanged}
                  loading={saveDisabled}
                >
                  Save details
                </SaveButton>
              </SaveButtonWrapper>
            </div>
          </section>
        </>
      </Form>
      {/*<Form*/}
      {/*  form={formCookies}*/}
      {/*  name="manageCookies"*/}
      {/*  layout="vertical"*/}
      {/*  size={"large"}*/}
      {/*  onFinish={handleCookiePreferencesChange}*/}
      {/*  initialValues={cookieInitialValues()}*/}
      {/*  onFieldsChange={() => setFormFieldsChanged(true)}*/}
      {/*>*/}
      {/*  <div style={{marginTop: "2rem"}}>*/}
      {/*    <span*/}
      {/*      style={{fontSize: "1.125rem", fontWeight: 600, color: "#21272B"}}*/}
      {/*    >*/}
      {/*      Cookies*/}
      {/*    </span>*/}
      {/*    <section*/}
      {/*      style={{*/}
      {/*        border: "1px solid #DAE0E6",*/}
      {/*        boxSizing: "border-box",*/}
      {/*        borderRadius: "10px",*/}
      {/*        padding: "1rem",*/}
      {/*        marginTop: "1em",*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <div>*/}
      {/*        <InputsWrapper>*/}
      {/*          <InputWrapper>*/}
      {/*            <LabelWrapper>*/}
      {/*              <Label>Accept essential cookies?*</Label>{" "}*/}
      {/*            </LabelWrapper>*/}
      {/*            <Form.Item*/}
      {/*              key={"essentialCookies"}*/}
      {/*              name={"essentialCookies"}*/}
      {/*            >*/}
      {/*              <Checkbox checked={true} disabled={true}>*/}
      {/*                <Tooltip*/}
      {/*                  title={"Essential cookies enable core functionality such as Google Captcha and cannot be disabled."}>*/}
      {/*                  I accept essential cookies</Tooltip>*/}
      {/*              </Checkbox>*/}
      {/*            </Form.Item>*/}
      {/*          </InputWrapper>*/}
      {/*          <InputWrapper>*/}
      {/*            <LabelWrapper>*/}
      {/*              <Label>Accept marketing cookies?</Label>{" "}*/}
      {/*            </LabelWrapper>*/}
      {/*            <Form.Item*/}
      {/*              key={"marketingCookies"}*/}
      {/*              name={"marketingCookies"}*/}
      {/*              valuePropName={"checked"}*/}
      {/*            >*/}
      {/*              <Checkbox>*/}
      {/*                <Tooltip*/}
      {/*                  title={"Marketing cookies allow Google Analytics to keep track of your activity on Livlet to improve your experience."}>*/}
      {/*                  I accept marketing cookies</Tooltip>*/}
      {/*              </Checkbox>*/}
      {/*            </Form.Item>*/}
      {/*          </InputWrapper>*/}
      {/*        </InputsWrapper>*/}
      {/*        <p>Please refresh the page manually after making changes to your Cookie settings.</p>*/}
      {/*        <SaveButtonWrapper>*/}
      {/*          <SaveButton*/}
      {/*            style={{*/}
      {/*              display: "flex",*/}
      {/*              alignItems: "center",*/}
      {/*            }}*/}
      {/*            type={"primary"}*/}
      {/*            htmlType={"submit"}*/}
      {/*            loading={saveDisabled}*/}
      {/*          >*/}
      {/*            Save preferences*/}
      {/*          </SaveButton>*/}
      {/*        </SaveButtonWrapper>*/}
      {/*      </div>*/}
      {/*    </section>*/}
      {/*  </div>*/}
      {/*</Form>*/}
      <Form
        form={formPassword}
        name="login"
        size={"large"}
        initialValues={{ remember: true }}
        onFinish={handlePasswordChange}
      >
        <div style={{marginTop: "2rem"}}>
          <span
            style={{fontSize: "1.125rem", fontWeight: 600, color: "#21272B"}}
          >
            Change password
          </span>
          <section
            style={{
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "10px",
              padding: "1rem",
              marginTop: "1em",
            }}
          >
            <div style={{paddingBottom: "0.5rem"}}>
              <InputsWrapper style={{width:"97%"}}>
                <InputWrapper>
                  <LabelWrapper>
                    <Label>Enter existing password *</Label>{" "}
                  </LabelWrapper>
                  <Form.Item
                    key={"oldPassword"}
                    name={"oldPassword"}
                    rules={[
                      {
                        required: true,
                        message: f(messages.validationPassword),
                      },
                      {
                        validator: (rule, value, callback) => {
                          oldPasswordMismatch
                            ? callback("Password is incorrect")
                            : callback();
                        }
                      },
                    ]}
                  >
                    <InputPasswordStyled
                      maxLength={100}
                      placeholder={f(messages.enterPassword)}
                      onChange={() => setOldPasswordMismatch(false)}
                    />
                  </Form.Item>
                </InputWrapper>
              </InputsWrapper>
              <InputsWrapper>
                <InputWrapper>
                  <LabelWrapper>
                    <Label>Enter new password *</Label>{" "}
                  </LabelWrapper>
                  <Popover
                    content={<div style={{padding: '0.7rem'}}>
                      <b>Password must include:</b>
                      <br/>{resultIcon(passwordStrengthResult.appropriateLength)} 8-20 Characters
                      <br/>{resultIcon(passwordStrengthResult.capitalLetters)} At least one capital letter
                      <br/>{resultIcon(passwordStrengthResult.numbers)} At least one number
                      <br/>{resultIcon(passwordStrengthResult.noSpaces)} No spaces
                    </div>}
                    placement="top"
                    trigger={["hover", "focus"]}
                  >
                  <Form.Item
                    key={"newPassword"}
                    name={"newPassword"}
                    rules={[
                      {
                        required: true,
                        message: f(messages.validationPassword),
                      },
                      {
                        validator: (rule, value, callback) => {
                          sameAsOldPassword
                            ? callback("New password is the same as the old password")
                            : callback();
                        }
                      }
                    ]}
                  >
                    <InputPasswordStyled
                      maxLength={100}
                      placeholder={f(messages.enterPassword)}
                      onChange={(e) => {
                        setSameAsOldPassword(false)
                        setPasswordStrengthResult(passwordTest(e.target.value))
                      }}
                    />
                  </Form.Item>
                  </Popover>
                  {formPassword.getFieldValue("newPassword") &&
                    <div style={{display: "flex", marginTop: "-0.3rem", marginBottom: "0.3rem"}}>
                    {passwordResult(passwordStrengthResult.totalScore)}
                      <Popover
                          content={<div style={{padding: '0.7rem'}}>
                            <b>Password must include:</b>
                            <br/>{resultIcon(passwordStrengthResult.appropriateLength)} 8-20 Characters
                            <br/>{resultIcon(passwordStrengthResult.capitalLetters)} At least one capital letter
                            <br/>{resultIcon(passwordStrengthResult.numbers)} At least one number
                            <br/>{resultIcon(passwordStrengthResult.noSpaces)} No spaces
                          </div>}
                          placement="top"
                          trigger={["hover", "focus"]}
                      ><span style={{marginLeft: "0.3rem", marginBottom: "-0.3rem"}}>{resultIcon(passwordStrengthResult.final)}</span>
                      </Popover>
                  </div>}
                </InputWrapper>
                <InputWrapper>
                  <LabelWrapper>
                    <Label>Re-enter new password *</Label>{" "}
                  </LabelWrapper>
                  <Form.Item
                    key={"confirmationPassword"}
                    name={"confirmationPassword"}
                    rules={[
                      {
                        validator: (rule, value, callback) => {
                          if (
                            !formPassword.getFieldValue("newPassword") &&
                            !formPassword.getFieldValue("confirmationPassword")
                          ) {
                            callback(f(messages.validationPassword));
                          }
                          formPassword.getFieldValue("newPassword") !==
                          formPassword.getFieldValue("confirmationPassword")
                            ? callback(f(messages.confirmationPasswordError))
                            : callback();
                        },
                      },
                    ]}
                  >
                    <InputPasswordStyled
                      maxLength={100}
                      placeholder={f(messages.enterPassword)}
                    />
                  </Form.Item>
                </InputWrapper>
              </InputsWrapper>
              <SaveButtonWrapper>
                <SaveButton
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  type={"primary"}
                  htmlType={"submit"}
                  loading={saveDisabled}
                >
                  Change password
                </SaveButton>
              </SaveButtonWrapper>

            </div>
          </section>
        </div>
      </Form>
    </Drawer>
  );
};

const Title = styled.span`
  border: none;
  outline: none;
  background-color: #ebf9ff;
  font-size: 2.5rem;
  @media (max-width: 1024px) {
    font-size: 2rem;
  }  
`;

const InventoriesIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

const InputStyled = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
  color: rgb(107, 113, 133);
`;

export default AccountSettingsDrawer;
