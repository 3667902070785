import {apiPost, apiPut, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TSendViaEmailRequest, TSendViaEmailResponse} from "../email/sendViaEmail";

export type TEditMaintenanceResponse = {
  id: Guid;
  title?: string;
  message?: string;
  actualEstimatedCost?: number;
  actualPeriodicityUnit?: string;
  actualPeriodicity?: number;
  startDate?: string;
  subscribed?: boolean;
};

export type TEditMaintenanceRequest = {
  propertyId: Guid;
  title?: string;
  message?: string;
  actualEstimatedCost?: number;
  actualPeriodicityUnit?: string;
  actualPeriodicity?: number;
  startDate?: string;
  subscribed?: boolean;
};

export const editMaintenanceApi = (payload: TEditMaintenanceRequest, maintenanceId: any) => {
  const { propertyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/alerts/trigger/${maintenanceId}`
  );

  return apiPut<TEditMaintenanceRequest, TEditMaintenanceRequest>(
    url, payload
  );
};
