import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TAddNoteResponse = {
  recordId: Guid;
  title: string;
  message: string;
  createdDate: string;
  emergency: boolean;
  attachment: {
    attachmentId: Guid;
    propertyId: Guid;
    parentId: Guid;
    parentType: string;
    name: string;
    size: number;
  };
};

export type TAddNoteRequest = {
  propertyId: Guid;
  title?: string;
  message?: string;
  attachment?: string;
  emergency?: boolean | false;
};

export const addNote = (payload: TAddNoteRequest) => {
  const { propertyId } = payload;
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/note`);

  let bodyFormData = new FormData();

  payload.title && bodyFormData.append("title", payload.title);
  payload.message && bodyFormData.append("message", payload.message);
  payload.attachment && bodyFormData.append("attachment", payload.attachment);

  return apiPost<TAddNoteResponse, any>(url, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
