import React, {useState} from "react";
import {MainListColumn, MainListItemStyled} from "../../screens/styled";
import {Dropdown, Input,  Menu, Modal} from "antd";
import {ReactComponent as PaperclipSmall} from "../../../images/attachments/PaperclipSmall.svg";
import {ReactComponent as HouseSmall} from "../../../images/attachments/HouseSmall.svg";
import {ReactComponent as ArrowDownGray} from "../../../images/ArrowDownGray.svg";
import _ from "lodash";
import {niceBytes} from "../helper";
import TagContainer from "../tags/TagContainer";
import {getAllAttachments} from "../../actions/attachments";
import {fileTypeIcon} from "../../screens/Files";
import {useDispatch, useSelector} from "react-redux";
import {
  getPropertyIdSelector, getPropertyPreferencesSelector,
  getUserProfileSelector,
} from "../../selectors";
import {MenuStyled} from "../works/WorksListComp";
import {removeAttachment} from "../../../api-wrapper/attachments/removeAttachment";
import {getAllTagsWithUsageCountByType} from "../../actions/tagsWithUsageCountByType";
import {addExpense} from "../../../api-wrapper/expenses/addExpense";
import {addRelation} from "../../../api-wrapper/relation/addRelation";
import {parseInvoiceFromId} from "../../../api-wrapper/expenses/parseInvoiceFromId";
import {InvoiceOverlay} from "./UploadInvoice";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {ReactComponent as PenIcon} from "../../../images/Pen.svg";
import {ReactComponent as MoreMenu} from "../../../images/More.svg";
import {ReactComponent as DeleteIcon} from "../../../images/Delete.svg";
import {ReactComponent as LinkFileIcon} from "../../../images/attachments/LinkFile.svg";
import {ReactComponent as EditFileOnDrawerIcon} from "../../../images/attachments/EditFileOnDrawer.svg";
import {ReactComponent as MobileTagIcon} from "../../../images/attachments/MobileTag.svg";
import styled from "styled-components";
import {Guid} from "guid-typescript";
import moment from "moment";
import {LinkedItemTag, LinkedItemTags} from "../spaces/LinkedItem";
import ScrollContainer from "react-indiana-drag-scroll";
import {RadioStyled} from "../navigation/Header";
import {updateFileName} from "../../../api-wrapper/attachments/updateFileName";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import {getEmailedFilesState, setEmailedFilesState} from "../../../localStorage";

type Props = {
  item: any;
  // highlightAttachmentId: Guid | null;
  isOnboarding?: boolean;

  nameMap?: any;
  setNameMap?: (arg: any) => void;
  // descriptionMap: any;
  // setDescriptionMap: (arg: any) => void;
  //
  nameInEditId?: string;
  setNameInEditId?: (arg: string) => void;
  // descriptionInEditId: string;
  // setDescriptionInEditId: (arg: string) => void;

  selectedFiles?: Set<any> | null;
  setSelectedFiles?: (arg: Set<any>) => void | null;

  selectedTags: Array<any>;
  editTagsMode: boolean;
  toggleEditTagsMode: (arg: boolean) => void;
  toggleForwardDrawer: (arg: boolean) => void;
  setAttachmentToForward: (arg: Guid) => void;

  setInvoiceData: (arg: any) => void;
  toggleExpenseDrawer: (arg: boolean) => void;
  setExpenseData: (arg: any) => void;

  toggleVieFileDrawer?: (arg: boolean) => void | null;
  setAttachmentToViewId?: (arg: any) => void | null;
  setViewFileDrawerDefaultTab?: (arg: any) => void | null;

  onDrawer: boolean;
  toggleEditDrawerOpen?: (arg: boolean) => void | null;
  setAttachmentToEditId?: (arg: any) => void | null;
  toggleAssignPropertySmallDrawerOpen?: (arg: boolean) => void | null;

  duplicateFiles?: Array<any>;
  refreshParent?: () => void | null;
}

const FileItem = (props: Props) => {
  const {item, isOnboarding, onDrawer, selectedFiles, setSelectedFiles, toggleEditDrawerOpen, setAttachmentToEditId, nameMap, setNameMap, /*descriptionMap, setDescriptionMap,*/ nameInEditId, setNameInEditId,
    /*descriptionInEditId, setDescriptionInEditId,*/ setAttachmentToForward, toggleForwardDrawer, toggleVieFileDrawer, setAttachmentToViewId, setViewFileDrawerDefaultTab, duplicateFiles,
    selectedTags, toggleEditTagsMode, setInvoiceData, /*highlightAttachmentId,*/ toggleExpenseDrawer, setExpenseData, toggleAssignPropertySmallDrawerOpen, refreshParent, editTagsMode} = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const userProfile = useSelector(getUserProfileSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const getFileUploadFromEmail = (attachment: any) => {
    if (attachment.source === "email" && userProfile.content.previousLogin < item.uploadedDate) {
      const emailedFilesChecked = getEmailedFilesState();

      if (!emailedFilesChecked || !Array.isArray(JSON.parse(emailedFilesChecked))) {
        return true;
      }
      return !JSON.parse(emailedFilesChecked).includes(attachment.attachmentId);

    } else {
      return false;
    }
  }

  const setFileUploadFromEmail = (attachmentId: any) => {
    const emailedFilesChecked = getEmailedFilesState();

    toggleFileUploadedFromEmail(false);
    if (!emailedFilesChecked || !Array.isArray(JSON.parse(emailedFilesChecked))) {
      setEmailedFilesState([attachmentId]);
      return;
    }
    if (JSON.parse(emailedFilesChecked).includes(attachmentId))
      return;
    setEmailedFilesState([...JSON.parse(emailedFilesChecked), attachmentId]);
  }

  const [showFileUploadedFromEmail, toggleFileUploadedFromEmail] = useState(getFileUploadFromEmail(item));

  const [nameInEdit, setNameInEdit] = useState("");
  const [isDuplicateListVisible, toggleDuplicateListVisibility] = useState(false);
  // const [descriptionInEdit, setDescriptionInEdit] = useState("");

  const linkCount = (item: any) =>
    item["attachmentWorksCount"]+item["attachmentExpensesCount"]+item["attachmentContentsCount"]+item["attachmentContactsCount"]+item["attachmentNotesCount"]+item["attachmentSpacesCount"];


  const getFileName = (fileName: string) => {
    return fileName.replace(fileName.substr(fileName.lastIndexOf('.')), "")
  }

  const getFileExtension = (fileName: string) => {
    return fileName.substr(fileName.lastIndexOf('.'))
  }
  /*
    const setDescriptionForAttachment = (attachmentId: Guid, description: string) => {
      description = description.slice(0, 249);
      descriptionMap.set(attachmentId, description);
      setDescriptionMap(descriptionMap);
      updateDescription(propertyId, attachmentId, description).then();
    };
  */
  const setNameForAttachment = (attachmentId: Guid, name: string, extension: string) => {
    name = name.slice(0, 63);
    nameMap.set(attachmentId, name + extension);
    setNameMap && setNameMap(nameMap);
    updateFileName(propertyId, attachmentId, name).then(() => dispatch(getAllAttachments(propertyId)));
  };

  function confirmDelete(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this document? It will remove it from Livlet.",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        setSelectedFiles && setSelectedFiles(new Set());
        removeAttachment({
          propertyId,
          id: item.attachmentId,
        }).then(() => {
          refreshParent && refreshParent();
          dispatch(getAllTagsWithUsageCountByType(propertyId, "attachment"))
        });
      }
    });
  }

  const canBeParsedSAsInvoice = (fileName: string) => {
    const acceptableTypes = ["png", "jpeg", "jpg", "pdf"]
    let parsedFileName = fileName.split(".")
    let fileType = (parsedFileName[parsedFileName.length - 1])
    return acceptableTypes.includes(fileType)
  }

  const handleParseInvoice = async (file: any) => {
    let newExpense = await addExpense({propertyId, isConfirmed: true})
    await addRelation({
      propertyId,
      //@ts-ignore
      parentId: newExpense.data.expenseId,
      childId: file.attachmentId,
      parentType: "expense",
      childType: "attachment",
    });
    setInvoiceData({
      invoice: null,
      fileName: file.name,
      isProcessing: true,
      isComplete: false,
      error: null
    })
    toggleExpenseDrawer(true)
    setExpenseData(newExpense)

    try {
      let invoiceResponse = await parseInvoiceFromId({propertyId, fileId: file.attachmentId})
      invoiceResponse.data && setInvoiceData && setInvoiceData({
        invoice: invoiceResponse.data[0],
        fileName: file.name,
        isProcessing: false,
        isComplete: true,
        error: null
      })
    } catch (error) {
      setInvoiceData && error && setInvoiceData({
        invoice: null,
        fileName: null,
        isProcessing: false,
        isComplete: true,
        error: error
      })
    }
  }

  const overlay = () => {
    return <InvoiceOverlay>
      <div style={{color: "#2a80e1", fontWeight: 600, marginBottom: "0.5rem"}}>
        Hey, we're still working on this new feature!
      </div>
      Our automated invoice reading feature is brand new, and is continuously being improved by our developer team with
      machine learning algorithms - so please reconfirm the outputs in the Add Expense fields.
    </InvoiceOverlay>
  }

  const moreMenuDropdown = (item: any) => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;

      if (key === "editName") {
        // setNameInEditId(""+item.attachmentId);
        // setNameInEdit(nameMap.get(item.attachmentId));
      } if (key === "editDescription") {
        // setDescriptionInEditId(""+item.attachmentId);
        // setDescriptionInEdit(descriptionMap.get(item.attachmentId));
      } else if (key === "createCost") {
        handleParseInvoice(item).then()
      } else if (key === "delete") {
        confirmDelete(item)
      } else if (key === "forward") {
        toggleForwardDrawer(true)
        setAttachmentToForward(item.attachmentId)
      } else if (key === "edit") {
        setAttachmentToEditId && setAttachmentToEditId(item.attachmentId);
        toggleEditDrawerOpen && toggleEditDrawerOpen(true);
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        {item.propertyId && <Menu.Item key="edit">Edit details</Menu.Item>}
        {canBeParsedSAsInvoice(item.name) && !isOnboarding && item.propertyId && <Menu.Item key="createCost">
            <Dropdown overlay={overlay} placement="topCenter"><div>Create expense</div></Dropdown>
        </Menu.Item>}
        {!isOnboarding && item.propertyId && <Menu.Item key="forward">Forward</Menu.Item>}
        {/*<Menu.Item key="editName">Edit name</Menu.Item>*/}
        {/*<Menu.Item key="editDescription">Edit description</Menu.Item>*/}
        <Menu.Item key="delete">Delete document</Menu.Item>
      </MenuStyled>
    );
  };

  const editableTitle = (item: any) => (
    <h4 className="ant-list-item-meta-title">
      <CenteredWrapper>
        {nameInEditId === ""+item.attachmentId && (
          <CenteredWrapper onClick={(e) => e.stopPropagation()}>
            <InputStyled
              autoFocus={true}
              defaultValue={getFileName(nameMap.get(item.attachmentId))}
              onChange={(e) => setNameInEdit(e.target.value)}
              suffix={getFileExtension(nameMap.get(item.attachmentId))}
            />
            <CheckOutlined style={{marginLeft:"1rem"}}
                           onClick={() => {
                             if (nameInEdit.length === 0) {
                               Modal.error({
                                 title: "Error!",
                                 content: "File name cannot be empty.",
                                 className: "form-confirm-close-modal",
                               });
                             } else if (nameMap.get(item.attachmentId) === nameInEdit) {
                               setNameInEditId && setNameInEditId("");
                             } else {
                               setNameInEditId && setNameInEditId("");
                               setNameForAttachment(item.attachmentId, nameInEdit, getFileExtension(nameMap.get(item.attachmentId)));
                             }
                           }}
            />
            <CloseOutlined style={{marginLeft:"1rem"}} onClick={() => setNameInEditId && setNameInEditId("")}/>
          </CenteredWrapper>
        )}
        {(nameInEditId !== ""+item.attachmentId && !_.isEmpty(nameMap.get(item.attachmentId))) && (
          <span style={{wordBreak: "break-all", display: "flex", alignItems: "center"}}>
            <Ellipsis lines={1}>{nameMap.get(item.attachmentId)}</Ellipsis>
            <PenIcon
              style={{marginLeft:"0.25rem"}}
              onClick={(e) => {
                e.stopPropagation();
                setNameInEditId && setNameInEditId(""+item.attachmentId);
                setNameInEdit("");
              }}
            />
          </span>
        )}
      </CenteredWrapper>
    </h4>
  )

  const selectFile = (e: any, item: any) => {
    e.stopPropagation();
    if (!selectedFiles || !setSelectedFiles)
      return;

    if (selectedFiles.has(item.attachmentId)) {
      selectedFiles.delete(item.attachmentId);
    } else {
      selectedFiles.add(item.attachmentId);
    }

    setSelectedFiles(new Set(selectedFiles));
  }

  const isFileSelected = (item: any) => {
    if (!selectedFiles || !setSelectedFiles)
      return false;
    return selectedFiles.has(item.attachmentId);
  }

  const openViewFileDrawer = (e: any, defaulTab: string | null, itemToOpen?: any) => {
    e.stopPropagation();
    if (editTagsMode)
      return;
    if (!setAttachmentToViewId || !toggleVieFileDrawer)
      return;
    setFileUploadFromEmail(item.attachmentId);
    setAttachmentToViewId(item.attachmentId);
    setAttachmentToViewId(itemToOpen ? itemToOpen.attachmentId : item.attachmentId);
    setViewFileDrawerDefaultTab && setViewFileDrawerDefaultTab(defaulTab);
    toggleVieFileDrawer(true);
  }

  const duplicatePopup = (original: any) => {
    const duplicateCount = duplicateFiles ? duplicateFiles.filter((item: any) => item.duplicateFiles.includes(original.attachmentId)).length : 0;

    return <Dropdown overlay={<DuplicateList>
      {duplicateFiles && duplicateFiles.map((item: any) => {
        if (item.duplicateFiles.includes(original.attachmentId)) return <DuplicateListItem
          onClick={(e) => {
            toggleDuplicateListVisibility(false)
            openViewFileDrawer(e, null, item)
          }}>
          <div className={"item-contents"}>
            <div>
              {(_.isNil(item.thumbnailPath) || _.isEmpty(item.thumbnailPath)) ? <div>{fileTypeIcon(item)}</div>
                : <div style={{width:"3rem"}}>
                  <img className="circle-image-cropped" alt="" src={item.thumbnailPath}
                     onError={(e) => {
                       // @ts-ignore
                       e.target.onError = null;
                       // @ts-ignore
                       e.target.src = "/images/file.png"
                     }}/></div>}
            </div>
            <div className={"item-details"}>
              <FileItemTitle><Ellipsis lines={1}>{item.name}</Ellipsis></FileItemTitle>
              <FileItemSize>{niceBytes("" + item.size)}</FileItemSize>
            </div>
          </div>
          <div className={"item-actions"}>
            <AssignToPropertyButton
              onClick={(e) => {
                e.stopPropagation();
                setAttachmentToEditId && setAttachmentToEditId(item.attachmentId);
                toggleAssignPropertySmallDrawerOpen && toggleAssignPropertySmallDrawerOpen(true);
              }}>
              <HouseSmall style={{marginRight:"0.5rem"}} />
              <span>Assign to a property</span>
            </AssignToPropertyButton>
            <DeleteIcon onClick={(e) => {
              e.stopPropagation();
              confirmDelete(item);
            }} />
          </div>
        </DuplicateListItem>
      })}
    </DuplicateList>} placement={"bottomCenter"} visible={isDuplicateListVisible} onVisibleChange={() => toggleDuplicateListVisibility(false)}>
      <DuplicateIndicator onClick={(e) => {
        e.stopPropagation();
        toggleDuplicateListVisibility(true);
      }}>Show {duplicateCount} duplicate(s) <ArrowDownGray /></DuplicateIndicator>
    </Dropdown>
  }

  const fileItemLinks = () => (
    <FileItemLinks>
      {item["attachmentWorksCount"] > 0 && <LinkedItemTag
          className={"work"} onClick={(e) => !editTagsMode &&openViewFileDrawer(e,"links")}>
        {item["attachmentWorksCount"]} Project{item["attachmentWorksCount"] > 1 && "s"}
      </LinkedItemTag>}
      {item["attachmentExpensesCount"] > 0 && <LinkedItemTag
          className={"cost"} onClick={(e) => openViewFileDrawer(e,"links")}>
        {item["attachmentExpensesCount"]} Expense{item["attachmentExpensesCount"] > 1 && "s"}
      </LinkedItemTag>}
      {item["attachmentContentsCount"] > 0 && <LinkedItemTag
          className={"content"} onClick={(e) => openViewFileDrawer(e,"links")}>
        {item["attachmentContentsCount"]} Content{item["attachmentContentsCount"] > 1 && "s"}
      </LinkedItemTag>}
      {item["attachmentContactsCount"] > 0 && <LinkedItemTag
          className={"contact"} onClick={(e) => openViewFileDrawer(e,"links")}>
        {item["attachmentContactsCount"]} Contact{item["attachmentContactsCount"] > 1 && "s"}
      </LinkedItemTag>}
      {item["attachmentNotesCount"] > 0 && <LinkedItemTag
          className={"note"} onClick={(e) => openViewFileDrawer(e,"links")}>
        {item["attachmentNotesCount"]} Note{item["attachmentNotesCount"] > 1 && "s"}
      </LinkedItemTag>}
      {item["attachmentSpacesCount"] > 0 && <LinkedItemTag
          className={"space"} onClick={(e) => openViewFileDrawer(e,"links")}>
        {item["attachmentSpacesCount"]} Space{item["attachmentSpacesCount"] > 1 && "s"}
      </LinkedItemTag>}
      {item["tasksCount"] > 0 && <LinkedItemTag className={"task"}>
        {item["tasksCount"]} Task{item["tasksCount"] > 1 && "s"}
      </LinkedItemTag>}
      {item["recurringExpensesCount"] > 0 && <LinkedItemTag className={"recurringExpense"}>
        {item["recurringExpensesCount"]} Recurring expense{item["recurringExpense"] > 1 && "s"}
      </LinkedItemTag>}
      {item["remindersCount"] > 0 && <LinkedItemTag className={"reminder"}>
        {item["remindersCount"]} Reminder{item["remindersCount"] > 1 && "s"}
      </LinkedItemTag>}
    </FileItemLinks>
  )

  const FileItemDesktopNew = (item: any) => {
    return (
      <MainListItemStyled
        key={item.id}
        onClick={(e) => openViewFileDrawer(e, null)}
        style={item.propertyId === null ? {background: "#EAF3FC", borderColor: "#2A80E1", borderBottom:"1px solid #2A80E1"} : {}}
      >
        <MainListColumn style={{justifyContent: "flex-start", flexDirection: "row", alignItems: "center", columnGap: "1rem"}} spanWidth={ "40%"}>
          <div
            style={{padding:"0 0 0 1rem", height: "100%"}}
            onClick={(e) => selectFile(e, item)}
          >
            <RadioStyled checked={isFileSelected(item)} />
            {/*disabled={item.propertyId === null}*/}
          </div>
          {(_.isNil(item.thumbnailPath) || _.isEmpty(item.thumbnailPath)) ? <div>{fileTypeIcon(item)}</div>
            : <div style={{width:"3rem"}}><img className="circle-image-cropped" alt="" src={item.thumbnailPath}
               onError={(e) => {
                 // @ts-ignore
                 e.target.onError = null;
                 // @ts-ignore
                 e.target.src = "/images/file.png"
               }}/></div>}
          <FileItemTitleWrapper>
            <FileItemTitle>
              <Ellipsis lines={1}>{item.name}</Ellipsis>
              <FileItemSize>{niceBytes("" + item.size)}</FileItemSize>
            </FileItemTitle>
            {(item.propertyId && !showFileUploadedFromEmail)
              ? fileItemLinks()
              : <ReceivedByEmail>
                <PaperclipSmall style={{marginRight:"0.5rem"}} />
                <span>Received by email {moment(item.uploadedDate).format(propertyPreferences.preferredDateFormat)}</span>
              </ReceivedByEmail>}
          </FileItemTitleWrapper>
        </MainListColumn>

        <MainListColumn className="small-tags" style={{justifyContent: "flex-start", flexDirection: "column"}} spanWidth={"35%"}>
          {item.propertyId
            ? <TagContainer
              parentTags={item.tags}
              parentId={item.attachmentId}
              parentType={"attachment"}
              selectedTags={selectedTags}
              toggleGlobalEditMode={toggleEditTagsMode}
              refreshParent={() => dispatch(getAllAttachments(propertyId))}
            />
            : <AssignToPropertyButton
              onClick={(e) => {e.stopPropagation(); setAttachmentToEditId && setAttachmentToEditId(item.attachmentId); toggleAssignPropertySmallDrawerOpen && toggleAssignPropertySmallDrawerOpen(true);}}
            >
              <HouseSmall style={{marginRight:"0.5rem"}} />
              <span>Assign to a property</span>
            </AssignToPropertyButton>}
          {item.duplicateFiles && duplicatePopup(item)}
        </MainListColumn>

        <MainListColumn style={{justifyContent: "space-between", alignItems: "center", flexDirection: "row", paddingRight: "1rem", fontWeight: 600, color: "#8E8E89"}} spanWidth={"25%"}>
          {moment(item.uploadedDate).format(propertyPreferences.preferredDateFormat)}
          <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row"}}>
            {item.propertyId && <LinkFileIcon
                onClick={(e) => openViewFileDrawer(e, "links")}
                style={{marginRight:"1rem", cursor: "pointer", height: "2.5rem"}}
            />}
            <Dropdown
              overlay={moreMenuDropdown(item)}
              placement="bottomRight"
              trigger={["click"]}
            >
              <MoreMenu style={{cursor: "pointer"}} onClick={(e) => e.stopPropagation()}/>
            </Dropdown>
            {/*{item.source === "email" && userProfile.content.previousLogin < item.uploadedDate &&*/}
            {/*<Paperclip style={{ marginLeft: "2.35rem", marginTop: "0.6rem" }} />}*/}
          </div>
        </MainListColumn>
      </MainListItemStyled>
    )
  }

  const FileItemMobileNew = (item: any) => {
    return (
      <FileItemMobile
        key={item.id}
        className={"file-item-mobile"}
        onClick={(e) => {
          if (!setAttachmentToViewId || !toggleVieFileDrawer)
            return;
          setAttachmentToViewId(item.attachmentId);
          openViewFileDrawer(e,null); }}
      >
        {(_.isNil(item.thumbnailPath) || _.isEmpty(item.thumbnailPath)) ? <div>{fileTypeIcon(item)}</div>
          : <div style={{width:"3rem"}}><img className="circle-image-cropped" alt="" src={item.thumbnailPath}
             onError={(e) => {
               // @ts-ignore
               e.target.onError = null;
               // @ts-ignore
               e.target.src = "/images/file.png"
             }}/></div>}

        <div className={"file-item-content"}>
          <FileItemTitle>
            <Ellipsis lines={1}>{item.name}</Ellipsis>
          </FileItemTitle>
          {linkCount(item) > 0 && <div style={{width: "100%", overflowX: "hidden"}}>
            {fileItemLinks()}
          </div>}

          <div className={"file-item-content-line"}>
            <span style={{fontSize:"0.75rem"}}>
              {!item.propertyId ? "Received by email ": ""}
              {moment(item.uploadedDate).format(propertyPreferences.preferredDateFormat)}
            </span>

            {item.propertyId && <FileItemTag onClick={(e) =>
              openViewFileDrawer(e,"details")}>
              <MobileTagIcon/>
              {item.tags.length > 0 ? item.tags.length : "Add "} tag{item.tags.length !== 1 && "s"}
            </FileItemTag>}
          </div>

          {!item.propertyId && <div className={"file-item-content-line"}>
            <AssignToPropertyButton onClick={(e) => {
              e.stopPropagation();
              setAttachmentToEditId && setAttachmentToEditId(item.attachmentId);
              toggleAssignPropertySmallDrawerOpen && toggleAssignPropertySmallDrawerOpen(true);
            }}>
              <HouseSmall style={{marginRight:"0.5rem"}} />
              <span>Assign to a property</span>
            </AssignToPropertyButton>
          </div>}

          {item.duplicateFiles && <div className={"file-item-content-line"}>
            {duplicatePopup(item)}
          </div>}
        </div>

        <Dropdown
          overlay={moreMenuDropdown(item)}
          placement="bottomRight"
          trigger={["click"]}
        >
          <MoreMenu style={{cursor: "pointer"}} onClick={(e) => e.stopPropagation()}/>
        </Dropdown>
      </FileItemMobile>
    )
  }

  const FileItemOnDrawer = (item: any) => {
    return (
      <div
        key={item.id}
        style={{display:"flex", justifyContent: "flex-start", flexDirection: "row", alignItems: "center", width: "100%", margin:"0.25rem 0"}}
      >
        <div
          onClick={(e) => selectFile(e, item)}
        >
          <RadioStyled checked={isFileSelected(item)}/>
        </div>
        <div style={{display:"flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", background:"#F5F6FA", borderRadius:"8px", width: "100%", padding: "1.5rem 1rem"}}>
          <div style={{display:"flex", justifyContent: "flex-start", flexDirection: "row", columnGap: "1rem", alignItems: "center", width: "100%"}}>
            {(_.isNil(item.thumbnailPath) || _.isEmpty(item.thumbnailPath)) ? <div>{fileTypeIcon(item)}</div>
              : <div style={{width:"3rem"}}><img className="circle-image-cropped" alt="" src={item.thumbnailPath}
                                                 onError={(e) => {
                                                   // @ts-ignore
                                                   e.target.onError = null;
                                                   // @ts-ignore
                                                   e.target.src = "/images/file.png"
                                                 }}/></div>}

            <FileItemTitleWrapper style={{width: "100%"}}>
              <FileItemTitle>
                {editableTitle(item)}
                <FileItemSize>{niceBytes("" + item.size)}</FileItemSize>
              </FileItemTitle>
              {fileItemLinks()}
              <div style={{width:"100%", paddingRight: "1rem"}}>
                <TagContainer
                  parentTags={item.tags}
                  parentId={item.attachmentId}
                  parentType={"attachment"}
                  selectedTags={selectedTags}
                  toggleGlobalEditMode={toggleEditTagsMode}
                  refreshParent={() => dispatch(getAllAttachments(propertyId))}
                />
              </div>
            </FileItemTitleWrapper>
          </div>

          <EditFileOnDrawerIcon style={{cursor: "pointer"}} onClick={(e) => {setAttachmentToEditId && setAttachmentToEditId(item.attachmentId); toggleEditDrawerOpen && toggleEditDrawerOpen(true)}}/>
        </div>
      </div>
    )
  }

  return onDrawer ? FileItemOnDrawer(item) : window.innerWidth > 1024 ? FileItemDesktopNew(item) : FileItemMobileNew(item)
}

const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
`;

const InputStyled = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  height: 2rem;
  border-radius: 4px;
  width:60%;

  :focus {
    border: 1px solid #dae0e6 !important;
    box-shadow: none !important;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.75rem;
  column-gap: 1rem;
`

const EmptyDescriptionWrapper = styled.div`
  opacity: 0.65;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  margin-bottom: 0.75rem;
`

export const FileItemTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.5rem;
  justify-content: space-between;
  width: auto;
`;

export const FileItemTitle = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: flex;
  align-items: center;
  color: #002A43;
  column-gap: 0.375rem;
`;

const FileItemLinks = styled(ScrollContainer)`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  width: 100%;
  
 @media (min-width: 1024px) {
    flex-wrap: wrap;
 }
`;

export const FileItemSize = styled.span`
  color: #8E8E89;
  font-weight: 400;
  font-size: 0.75rem;
  white-space: nowrap;
`;

const ReceivedByEmail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.1rem 0.5rem;
  
  background: #FFFFFF;

  border: 1px solid #00B74F;
  border-radius: 19px;
  
  span {
    margin-top: 0.05rem;
    font-size: 0.75rem;
  }
`;

export const AssignToPropertyButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;

  background: #2A80E1;
  border-radius: 4px;
  width: 11.5rem;
  
  cursor: pointer;
  
  span {
    font-weight: 600;
    color: #FFF;
  }
`;

const DuplicateIndicator = styled.div`
  cursor: pointer;
  font-weight: 600;
  color: rgb(239, 98, 78);
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  
  svg path { stroke: rgb(239, 98, 78); }
  
  @media (max-width: 1024px) { margin-top: 0; }
`

const DuplicateList = styled.div`
  max-width: 32rem;
  border: 1px solid #DAE0E6;
  border-radius: 6px;
  background: white;
  display: flex;
  flex-direction: column;
`

const DuplicateListItem = styled.div`
  width: 100%;
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DAE0E6;
  gap: 1rem;
 
  .item-contents {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }
  
  .item-details {
    display: flex;
    flex-direction: column!important;
    align-items: flex-start;
  }
  
  .item-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    gap: 0.5rem;
  }
  
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
    .item-contents { width: 100%; }
  }
`
const FileItemMobile = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 1rem;
  transition: all .5s ease!important;
  column-gap: 1rem;
  border-top: 1px solid #DAE0E6;
  width: 100%;
  cursor: pointer;
  
  @media (max-width: 1024px) {
    align-items: flex-start;
    background: white;
    border: none;
    border-radius: 12px;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0.25rem;
    overflow: hidden;
    column-gap: 0.5rem;
    
    .file-item-content {
      display: flex;
      flex-direction: column;
      width: calc(100% - 5.25rem);
      align-items: flex-start;
      row-gap: 0.5rem;
    }
    
    .file-item-content-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 0.3rem;
      color: #666F7A;
      font-size: 0.75rem;
    }
    
  }
`

const FileItemTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #EFF1F5;
  border-radius: 4px;
  padding: 0.15rem 0.3rem; 
  column-gap: 0.25rem; 
  width: 5.5rem;       
`

export default FileItem