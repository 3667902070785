import React, {useState} from "react";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {Modal, Radio, Space} from "antd";
import {deleteUpcomingSeria, deleteUpcomingTask} from "../../../api-wrapper/upcomingTasks/dismissUpcomingTask";

type Props = {
  taskToDismiss: any;
  setTaskToDismiss?: (arg: any) => void;
  isDismissModalOpen: boolean;
  toggleDismissModal: (arg: boolean) => void;
  handleRefresh: () => void;
}

const DismissModal = (props: Props) => {
  const {taskToDismiss, setTaskToDismiss, isDismissModalOpen, toggleDismissModal,  handleRefresh} = props;
  const [selectedDismissOption, setSelectedDismissOption] = useState("single");

  const dismissSingle = (reminder: any) => {
    deleteUpcomingTask(reminder).then(() => handleRefresh())
  }

  const dismissSeria = (reminder: any) => {
    deleteUpcomingSeria({
      propertyId: reminder.propertyId,
      triggerId: reminder.templateId }).then(() => {
        handleRefresh()
        setTaskToDismiss && setTaskToDismiss(null)
    })
  }

  return <Modal
    className={"form-confirm-close-modal dismiss-modal"}
    visible={isDismissModalOpen}
    closable={true}
    closeIcon={<></>}
    width={416}
    onOk={() => {
      if (selectedDismissOption === "single") {
        toggleDismissModal(false)
        dismissSingle(taskToDismiss)
        setSelectedDismissOption("single")
      }
      if (selectedDismissOption === "seria") {
        toggleDismissModal(false)
        dismissSeria(taskToDismiss)
        setSelectedDismissOption("single")
      }
    }}
    onCancel={() => {
      toggleDismissModal(false)
      setTaskToDismiss && setTaskToDismiss(null);
    }}
  >
    <div>
      <QuestionCircleOutlined className={"question-icon"} />
    </div>
    <div style={{marginLeft: "1rem"}}>
      <p>Choose a recurring task to delete:</p>
      <Radio.Group
        value={selectedDismissOption}
        onChange={(e) => setSelectedDismissOption(e.target.value)}>
        <Space direction="vertical">
          <Radio value={"single"}>This task only</Radio>
          <p className={"description"}>This will only remove the current reminder, and will not affect other similar reminders.</p>
          <Radio value={"seria"}>All similar tasks</Radio>
          <p className={"description"}>This will remove all the instances of this recurring reminder.</p>
        </Space>
      </Radio.Group>
    </div>
  </Modal>
}

export default DismissModal