import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TForwardNoteRequest} from "./forwardNote";

export const forwardNotePreview = (payload: TForwardNoteRequest) => {
  const {noteId} = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/forwardNote/${noteId}/preview`
  );

  return apiPost<any, TForwardNoteRequest>(
    url, payload
  );
};
