import React from "react";
import {SearchSortBlock, SearchSortDescription, SearchSortTitle} from "./GuidanceSearchComp";
import {ReactComponent as OnboardingProgressStart} from "../../../images/guidance/OnboardingProgressStart.svg";
import {ReactComponent as OnboardingProgress0} from "../../../images/guidance/OnboardingProgress0.svg";
import {ReactComponent as OnboardingProgress1} from "../../../images/guidance/OnboardingProgress1.svg";
import {ReactComponent as OnboardingProgress2} from "../../../images/guidance/OnboardingProgress2.svg";
import {ReactComponent as OnboardingProgress3} from "../../../images/guidance/OnboardingProgress3.svg";
import {ReactComponent as OnboardingProgress4} from "../../../images/guidance/OnboardingProgress4.svg";
import {ReactComponent as OnboardingProgress5} from "../../../images/guidance/OnboardingProgress5.svg";
import {ReactComponent as OnboardingProgress6} from "../../../images/guidance/OnboardingProgress6.svg";
import {ReactComponent as OnboardingProgress7} from "../../../images/guidance/OnboardingProgress7.svg";
import styled from "styled-components";
import {useHistory} from "react-router-dom";

type Props = {
  origin: string;
  onboardingStatus: number;
  marginTop: any;
  marginBottom?: any;
}

const OnboardingComp = (props: Props) => {
  const {origin, onboardingStatus, marginTop, marginBottom} = props;
  const history = useHistory();

  const getOnboardingStatus = () => {
    if (onboardingStatus > 7) return 7
    else return onboardingStatus
  }

  const getProgressIcon = (progress: number) => {
    if (progress === null || progress == undefined) {
      return <OnboardingProgressStart />
    }
    switch (progress) {
      case 0: return <OnboardingProgress0 />;
      case 1: return <OnboardingProgress1 />;
      case 2: return <OnboardingProgress2 />
      case 3: return <OnboardingProgress3 />
      case 4: return <OnboardingProgress4 />
      case 5: return <OnboardingProgress5 />
      case 6: return <OnboardingProgress6 />
      default: return <OnboardingProgress7 />
    }
  }

  return <SearchSortBlock style={{
    flexDirection: "row",
    columnGap: "1.5rem",
    marginTop: marginTop,
    marginBottom: marginBottom ?? "auto"
  }} onClick={() => history.push(`/questions?origin=${origin}`)}>
    {origin === "guidance" && <div>
      <SearchSortTitle>Onboarding</SearchSortTitle>
      <SearchSortDescription style={{marginTop: "0.5rem"}}>
        Revisit your answers and we will tailor the improvements to suit your need
      </SearchSortDescription>
    </div>}
    <div style={{cursor: "pointer"}}>
      <OnboardingProgress>{getOnboardingStatus() > 0 ? getOnboardingStatus()+"/7" : "Start"}</OnboardingProgress>
      {getProgressIcon(getOnboardingStatus())}
    </div>
  </SearchSortBlock>
}

const OnboardingProgress = styled.div`
  width: 4.25rem;
  height: 4.25rem;
  z-index: 10;
  position: absolute;
  color: #002A43; 
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
`

export default OnboardingComp