import React, {useEffect, useState} from "react";

import {ReactComponent as NotesRecord} from "../../../images/records/NotesRecord.svg";
import {ReactComponent as NotesIcon} from "../../../images/records/NotesIcon.svg";
import {useDispatch, useSelector} from "react-redux";
import {getNotesByPropertyIdSelector, getPropertyIdSelector} from "../../selectors";
import {CardContentsItem, CardContentsWrapper, CardTitle, PlusIconWrapper, RecordsCard} from "./SpaceRecordsCard";
import {useHistory} from "react-router-dom";
import {ReactComponent as PlusIcon} from "../../../images/records/PlusIcon.svg";
import {addNote} from "../../../api-wrapper/notes/addNote";
import {getAllNotesByPropertyId} from "../../actions/notesByPropertyId";

type Props = {
  toggleDrawerOpen: (arg: boolean) => void;
  setEditingData: (arg: any) => void;
}

const NoteRecordsCard = (props: Props) => {
  const {toggleDrawerOpen, setEditingData} = props;

  const notes = useSelector(getNotesByPropertyIdSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const history = useHistory();
  const dispatch = useDispatch();

  const [notesCount, setNotesCount] = useState(0);

  useEffect(() => {
    setNotesCount(notes.filter((note) => note.title).length)
  }, [propertyId, notes])

  const addNewNote = () => {
    addNote({
      propertyId,
    }).then((res) => {
      setEditingData({workNotes: res.data})
      dispatch(getAllNotesByPropertyId(propertyId));
      toggleDrawerOpen(true);
    });
  }

  return <RecordsCard
    className={"note-records"}
    onClick={() => history.push("/notes")}
  >
    <CardTitle>
      <NotesRecord /> Notes
      <PlusIconWrapper onClick={(e) => {
        e.stopPropagation()
        addNewNote()
      }}><PlusIcon /></PlusIconWrapper>
    </CardTitle>
    <CardContentsWrapper>
      <CardContentsItem className={"fill"}>
        <span className={"item-count"}><NotesIcon /> {notesCount}</span> Notes
      </CardContentsItem>
    </CardContentsWrapper>
  </RecordsCard>
}

export default NoteRecordsCard