import {apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {PropertyHighlightsData} from "./getImprovements";

type TSimulateValueRequest = {
  propertyId: Guid;
  postCode: string;
  propertyType: string;
  constructionDate: string;
  internalArea: string;
  bedrooms: string;
  bathrooms: string;
  finishQuality: string;
  offStreetParking: string;
  outdoorSpace: string;
};

export const simulateValue = (payload: TSimulateValueRequest) => {
  const { propertyId } = payload;

  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/simulateValue`
  );

  return apiPost<
    PropertyHighlightsData,
    {}
  >(url, payload);
};
