import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {DatePicker, Form, Select} from "antd";
import {emailRegex} from "../../screens/helpers";
import messages from "../../screens/messages";
import SearchLocationInput from "../SearchLocationInput";
import {ReactComponent as ArrowDownSelect} from "../../../images/ArrowDownSelect.svg";
import {
  AddressSearch,
  ButtonBlue,
  ButtonGreen,
  ButtonWrapper,
  FieldWrapper,
  FormLabel,
  FormSectionTitle,
  FormWrapper,
  InputStyled,
  PageTitle,
  PageWrapper,
  SelectStyled
} from "../publicOnboarding/SiteVisitStep";
import styled from "styled-components";
import {InputNumberStyled} from "../expenses/ExpenseDrawer";
import {DraggerStyled, DraggerText, UploadedFile} from "../spaces/UploadImage";
import {ReactComponent as UploadIcon} from "../../../images/spaces/UploadIcon.svg";
import {ReactComponent as ImageUpload} from "../../../images/spaces/ImageUpload.svg";
import {ReactComponent as ImageRemove} from "../../../images/spaces/ImageRemove.svg";
import { processFile } from "../publicOnboarding/DocumentUploadStep";
import { checkFile } from "../publicOnboarding/DocumentUploadStep";
const { Option } = Select;

type Props = {
  step: number;
  setStep: (arg: number) => void;
  setUserData: (arg: any) => void;
  setWorkData: (arg: any) => void;
  form: any;
  location: any;
  isMobile: boolean;
  setLocation: (arg: any) => void;
  uploadedFiles: Array<any>;
  setUploadedFiles: (arg: any) => void;
}

const workTypes = ["Maintenance & Repair", "Improvement & Renovation"]

const KeytekSiteVisitStep = (props: Props) => {
  const {step, setStep, setUserData, setWorkData, form, location, isMobile, setLocation, uploadedFiles, setUploadedFiles} = props;
  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (location) {
      form.setFieldsValue({
        address: location.formattedAddress,
        city: location.data.city,
        country: location.data.country,
        zip: location.data.zipcode,
      });
    }
  }, [location, form]);

  const handleReset = () => {
    window.scrollTo(0, 0);
    form.resetFields();
    setUploadedFiles([]);
  }

  const handleUserData = (formData: any) => {
    setUserData({
      propertyUserInfo: {
        keytekCode: formData.keytekCode ?? null,
        email: formData.email ?? null,
        username: formData.username ?? null,
        address: formData.address ?? null,
        city: formData.city ?? null,
        state: formData.state ?? null,
        country: formData.country ?? null,
        zip: formData.zip ?? null,
      }
    })
  }

  const handleWorkData = (formData: any) => {
    setWorkData({
      works: [{
        name: formData.name ?? null,
        date: formData.date ? formData.date.format("YYYY-MM-DD") : null,
        type: formData.type ?? null,
        notes: formData.notes ?? null,
        cost: formData.cost ?? null,
      }]
    })
  }

  const handleNextStep = (formData: any) => {
    if (!isMobile) {
      handleUserData(formData);
      handleWorkData(formData);
      window.scrollTo(0, 0);
      setStep(3);
    }
    if (isMobile && step === 1) {
      handleUserData(formData);
      window.scrollTo(0, 0);
      setStep(2);
    }
    if (isMobile && step === 2) {
      handleWorkData(formData);
      window.scrollTo(0, 0);
      setStep(3);
    }
  }

  return <PageWrapper>
    <PageTitle>Livlet site visit (Page {isMobile ? `${step} of 3` : "1 of 2"})</PageTitle>
    <FormWrapper>
      <Form
        form={form}
        name="manageProperty"
        layout="vertical"
        size={"large"}
        onFinish={handleNextStep}
      >
        {(!isMobile || isMobile && step === 1) && <>
          <FormSectionTitle>Property & user info</FormSectionTitle>
          <FormLabel>Keytek livlet code <span className={"accent"}>(your 4 digit livlet code)</span></FormLabel>
          <Form.Item key={"keytekCode"} name={"keytekCode"}>
            <InputStyled placeholder={"Enter your Keytek Livlet code"} />
          </Form.Item>
          <FormLabel>Email address <span className={"accent"}>(Livlet account email address)</span></FormLabel>
          <Form.Item
            key={"email"}
            name={"email"}
            rules={[{
              pattern: emailRegex,
              message: f(messages.validationEmail),
            }
            ]}>
            <InputStyled placeholder={"Input your email"} />
          </Form.Item>
          <FormLabel>User preferred name <span className={"accent"}>(Personalises account)</span></FormLabel>
          <Form.Item key={"username"} name={"username"}>
            <InputStyled placeholder={"Input your username"} />
          </Form.Item>

          <FormLabel>Property address<br/><span className={"accent"}>(This will help us to source area specific alerts and search for your energy performance certificate (EPC).)</span></FormLabel>
            <AddressSearch>
              <FormLabel>Find address</FormLabel>
              <SearchLocationInput setValue={setLocation} origin={"onboarding"} />
              <FormLabel>Enter street address <span className={"accent"}>*</span></FormLabel>
              <Form.Item key={"address"} name={"address"}>
                  <InputStyled placeholder={"Input your address"} />
              </Form.Item>
              <FormLabel>Enter city <span className={"accent"}>*</span></FormLabel>
              <Form.Item key={"city"} name={"city"}>
                  <InputStyled placeholder={"Input your city"} />
              </Form.Item>
              <FormLabel>Enter country <span className={"accent"}>*</span></FormLabel>
              <Form.Item key={"country"} name={"country"}>
                  <InputStyled placeholder={"Input your country"} />
              </Form.Item>
              <FormLabel>Enter postcode <span className={"accent"}>*</span></FormLabel>
              <Form.Item key={"zip"} name={"zip"} style={{marginBottom: 0}}>
                  <InputStyled placeholder={"Input your postcode"} />
              </Form.Item>
            </AddressSearch>
        </>}

        {(!isMobile || isMobile && step === 2) && <>
          <FormSectionTitle>Work record</FormSectionTitle>
          <FieldWrapper className={"col-4"}>
            <div>
              <FormLabel>Work title</FormLabel>
              <Form.Item key={"name"} name={"name"}>
                <InputStyled placeholder={"Enter title"} />
              </Form.Item>
            </div>
            <div>
              <FormLabel>Date</FormLabel>
              <Form.Item key={"date"} name={"date"}>
                <DatePickerStyled
                  name={"date"}
                  format={"DD/MM/YYYY"}
                  allowClear={true}
                  inputReadOnly={true}
                />
              </Form.Item>
            </div>
            <div>
              <FormLabel>Type of work</FormLabel>
              <Form.Item key={"type"} name={"type"}>
                <SelectStyledYellow
                  placeholder={"Select type"}
                  suffixIcon={<ArrowDownSelect />}
                  dropdownClassName={"upkeep-tag-select"}
                  dropdownStyle={{width: "100%", borderRadius: "12px", padding: "0.5rem"}}
                >
                  {workTypes.map(option => <Option key={option} value={option}>{option}</Option>)}
                </SelectStyledYellow>
              </Form.Item>
            </div>
            <div>
              <FormLabel>Notes (optional)</FormLabel>
              <Form.Item key={"notes"} name={"notes"}>
                <InputStyled placeholder={"Enter"} />
              </Form.Item>
            </div>
          </FieldWrapper>
          <FieldWrapper className={"col-4"}>
            <div>
              <FormLabel>Cost</FormLabel>
              <Form.Item name={"cost"} key={"cost"} rules={[{pattern: /£\s?|(,*)/, message: "Please enter a valid numeric value"}]}>
                <InputNumberYellow
                  maxLength={10}
                  formatter={(value) => `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  //@ts-ignore
                  parser={(value) => value.replace(/£\s?|(,*)/g, "")}
                />
              </Form.Item>
            </div>
          </FieldWrapper>
          <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
            <DraggerStyled
              customRequest={() => {}}
              multiple={true}
              showUploadList={false}
              style={{background: "white", border: "none"}}
              beforeUpload={(file) => checkFile(file)}
              onChange={(files) => processFile(files.fileList, setUploadedFiles)}
            >
              <div className={"drag-inner"}>
                <UploadIcon />
                <DraggerText>
                  Drag and drop your files here or choose
                  <span className={"link"}>documents from your computer</span>
                </DraggerText>
              </div>
            </DraggerStyled>
            {uploadedFiles.length > 0 && uploadedFiles.map((file: any) => <UploadedFile key={file.id}>
                    <span className={"file-title"}>
                      <ImageUpload />{file.name}
                    </span>
              <ImageRemove style={{cursor: "pointer"}} onClick={() => {
                setUploadedFiles(uploadedFiles.filter((f: any) => f.id !== file.id))
              }} />
            </UploadedFile>)}
          </div>
        </>}

        <ButtonWrapper>
          {(!isMobile || isMobile && step === 1) && <ButtonGreen onClick={() => handleReset()}>Restart</ButtonGreen>}
          {(isMobile && step === 2) && <ButtonBlue onClick={() => setStep(1)}>Back</ButtonBlue>}
          <ButtonBlue htmlType={"submit"}>Next</ButtonBlue>
        </ButtonWrapper>
      </Form>
    </FormWrapper>
  </PageWrapper>
}

export const SelectStyledYellow = styled(SelectStyled)`
  .ant-select-selector { background: #FEF9EA!important; }
  
  .ant-select-selection-item { 
    color: #666F7A!important; 
    font-size: 1rem!important;
  }
  
  .ant-select-selection-placeholder { 
    color: #BFBFC3!important; 
    font-size: 1rem!important;
  }
  
  svg { margin-right: 0.5rem; }
  svg path { stroke: #BFBFC3; }
`

const DatePickerStyled = styled(DatePicker)`
  display: flex;
  align-items: center;
  
  padding: 0.75rem 1rem;
  background: #FEF9EA!important;
  border-radius: 6px;
  border: none;

  .ant-picker-input input { font-weight: 600!important; }
  
  font-size: 1rem;
  line-height: 1.5rem;
  color: #666F7A;
`

const InputNumberYellow = styled(InputNumberStyled)`
  display: flex;
  align-items: center;
  height: 3.125rem!important;
  width: 100%;
  
  .ant-input-number-input { 
    padding: 0!important;
    font-weight: 600!important;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #666F7A;
  }
  
  padding: 0.75rem 1rem!important;
  background: #FEF9EA!important;
  border-radius: 6px;
  border: none;
`

export default KeytekSiteVisitStep