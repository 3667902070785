import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";

export type TReportProblemRequest = {
  name: string,
  email: string,
  subject: string,
  body: string,
  pageURL: string
};

export const reportProblem = (payload: TReportProblemRequest) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/support`
  );

  return apiPost<any, TReportProblemRequest>(
    url, payload
  );
};
