import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import _ from "lodash";

import {Avatar, Button, Collapse, List, Menu, Popover, Progress, Skeleton} from "antd";
import {
  getImprovementsSelector,
  getPropertyIdSelector, getPropertyPreferencesSelector, getRecommendedImprovementsSelector, getWorksSelector,
} from "../../selectors";
// import {ReactComponent as RatingGreatIcon} from "../../images/RatingGreat.svg";
// import {ReactComponent as RatingGoodIcon} from "../../images/RatingGood.svg";
// import {ReactComponent as RatingAverageIcon} from "../../images/RatingAverage.svg";
// import {ReactComponent as RatingPoorIcon} from "../../images/RatingPoor.svg";
import {ReactComponent as StarIcon} from "../../../images/Star.svg";
import {ReactComponent as StarGreenEmptyIcon} from "../../../images/StarGreenEmpty.svg";
import {ReactComponent as StarGreenFullIcon} from "../../../images/StarGreenFull.svg";
import {ReactComponent as StarPurpleIcon} from "../../../images/StarPurple.svg";
import {ReactComponent as LeafIcon} from "../../../images/Leaf.svg";
import {ReactComponent as MoreMenu} from "../../../images/More.svg";
import {ReactComponent as RecommendedImprovementIcon} from "../../../images/RecommendedImprovement.svg";

// import {ReactComponent as LeafGreenIcon} from "../../images/LeafGreen.svg";
// import {ReactComponent as LeafGrayIcon} from "../../images/LeafGray.svg";
// import {ReactComponent as LeafHalfIcon} from "../../images/LeafHalf.svg";
//
// import {ReactComponent as PoundYellowIcon} from "../../images/PoundYellow.svg";
// import {ReactComponent as PoundGrayIcon} from "../../images/PoundGray.svg";
// import {ReactComponent as PoundHalfIcon} from "../../images/PoundHalf.svg";

import {ReactComponent as EPCIcon} from "../../../images/EPC.svg";
import {ReactComponent as ClockIcon} from "../../../images/ClockImprovements.svg";
import { ReactComponent as ECOIcon } from "../../../images/ImprovementsNew.svg";

import {ReactComponent as DownArrow} from "../../../images/DownArrow.svg";
import {SecondaryButton} from "../styled";
import {ReactComponent as CheckIcon} from "../../../images/Check.svg";
import {ReactComponent as WorkIcon} from "../../../images/MaintenceWorksPage.svg";
import {MenuStyled} from "../../components/works/WorksListComp";
import ImprovePropertyDrawer from "../../components/properties/ImprovePropertyDrawer";
import {getAllImprovements, getAllRecommendedImprovements} from "../../actions/improvements";
import {
  addToWishlist, linkImprovementWithWork, markImprovementAsDone,
  markImprovementAsUndone,
  removeFromWishlist, skipRecommendedImprovementForever, skipRecommendedImprovementUntilDate
} from "../../../api-wrapper/properties/improvementsOptions";
import {postAnswer} from "../../../api-wrapper/properties/postAnswer";
import {TFetchingStatus} from "../../../helpers";
import {addWork} from "../../../api-wrapper/works/addWork";
import {getAllWorks} from "../../actions/works";
import WorkDrawer from "../../components/works/WorkDrawer";
import {RouteProps, useHistory, useLocation} from "react-router-dom";
import {ReactComponent as SortUpIcon} from "../../../images/SortUp.svg";
import {ReactComponent as SortDownIcon} from "../../../images/SortDown.svg";
import {ImprovementItem, PropertyQuestionAnswer} from "../../../api-wrapper/properties/getImprovements";
import useDynamicRefs from 'use-dynamic-refs';
import UnansweredQuestionsComp from "../../components/properties/UnansweredQuestionsComp";
import {getEntityTypes} from "../../../api-wrapper/relation/getEntityTypes";
import {getCurrencySign} from "../helpers";

const {Panel} = Collapse;

const ImproveMyProperty = (props: RouteProps) => {
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value
  const improvementsConfiguration = useSelector(getImprovementsSelector)
  const recommendedImprovements = useSelector(getRecommendedImprovementsSelector)
  const propertyPreferences = useSelector(getPropertyPreferencesSelector)
  const works = useSelector(getWorksSelector);

  let history = useHistory();
  const { location } = props;

  const [showingWishList, setShowingWishList] = useState(false);

  const [categories, setCategories] = useState<Array<any>>([]);
  const [improvements, setImprovements] = useState<Array<any>>([]);
  const [questions, setQuestions] = useState<Array<any>>([]);

  const [improvementDrawerOpen, toggleImprovementDrawer] = useState(false);
  const [improvementIdDetails, setImprovementIdDetails] = useState("");

  const [tryDone, setTryDone] = useState<Set<string>>(new Set());

  const [sortByForCategory, setSortByForCategory] = useState<Map<number, string>>(new Map());
  const [sortTypeForCategory, setSortTypeForCategory] = useState<Map<number, boolean>>(new Map());

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  window.onresize = () => setWindowWidth(window.innerWidth)
  const [getRef, setRef] =  useDynamicRefs();

  const [improvementItemForWork, setImprovementItemForWork] = useState<any>(null);
  const [isNewWork, setIsNewWork] = useState(false);
  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editingWorkData, setEditingWorkData] = useState<any>({
    data: {
      name: "",
      type: "",
      completedDate: "",
      dueDate: "",
      recurring: "",
      workId: 0,
      propertyId: 0,
      isComplete: false,
    },
  });

  const improvementsFromCategory =
    (categoryId: number) => improvements.filter(improvement => improvement.category === categoryId);

  const improvementsFromCategoryWithoutParent =
    (categoryId: number) => improvements.filter(improvement => improvement.category === categoryId && _.isNil(improvement.parentId));

  const ecoRatingCalculation =
    improvements.filter(improvement => _.isNil(improvement.parentId) && (!_.isNil(improvement.doneOn) || !_.isNil(improvement.doneByAnswerOn)))
      .reduce(
        (sumOfSavingRatings, {ecoImpactRating}) => sumOfSavingRatings + ecoImpactRating,
        0
      )
    /
    improvements.filter(improvement => _.isNil(improvement.parentId))
      .reduce(
        (sumOfSavingRatings, {ecoImpactRating}) => sumOfSavingRatings + ecoImpactRating,
        0
      )
  ;

  const ecoRatingGreen =
    Math.floor(((ecoRatingCalculation * 100) + 10) / 20)
  ;

  const ecoRatingHalf =
    (Math.floor(ecoRatingCalculation * 100) % 20 === 0 || Math.floor(ecoRatingCalculation * 100) === 70)
      ? (Math.floor(ecoRatingCalculation * 100) === 80 || Math.floor(ecoRatingCalculation * 100) === 20)
      ? 1
      : 0
      : Math.floor(((ecoRatingCalculation + 0.10) * 10 % 2))
  ;

  const ecoRatingEmpty =
    Math.floor((((1.00 - ecoRatingCalculation) * 100)) / 20)
  ;

  const savingRatingCalculation =
    improvements.filter(improvement => _.isNil(improvement.parentId) && (!_.isNil(improvement.doneOn) || !_.isNil(improvement.doneByAnswerOn)))
      .reduce(
        (sumOfSavingRatings, {savingsRating}) => sumOfSavingRatings + savingsRating,
        0
      )
    /
    improvements.filter(improvement => _.isNil(improvement.parentId))
      .reduce(
        (sumOfSavingRatings, {savingsRating}) => sumOfSavingRatings + savingsRating,
        0
      )
  ;

  const savingRatingGreen =
    Math.floor(((savingRatingCalculation * 100) + 10) / 20)
  ;

  const savingRatingHalf =
    (Math.floor(savingRatingCalculation * 100) % 20 === 0 || Math.floor(savingRatingCalculation * 100) === 70)
      ? (Math.floor(savingRatingCalculation * 100) === 80)
      ? 1
      : 0
      : Math.floor(((savingRatingCalculation + 0.10) * 10 % 2))
  ;

  const savingRatingEmpty =
    Math.floor((((1.00 - savingRatingCalculation) * 100)) / 20)
  ;

  // const livletRating =
  //   improvements.filter(improvement => _.isNil(improvement.parentId) && (!_.isNil(improvement.doneOn) || !_.isNil(improvement.doneByAnswerOn)))
  //     .length
  //   /
  //   improvements.filter(improvement => _.isNil(improvement.parentId))
  //     .length
  //   * 100
  // ;

  // const wishListRating =
  //   (
  //     improvements.filter(improvement => _.isNil(improvement.parentId) && (!_.isNil(improvement.doneOn) || !_.isNil(improvement.doneByAnswerOn)))
  //       .length
  //     +
  //     improvements.filter(improvement => _.isNil(improvement.parentId) && _.isNil(improvement.doneOn) && _.isNil(improvement.doneByAnswerOn) && tryDone.has(improvement.id))
  //       .length
  //   )
  //   /
  //   improvements.filter(improvement => _.isNil(improvement.parentId))
  //     .length
  //   * 100
  // ;

  const wishListSize =
    () => improvements.filter(improvement => !_.isNil(improvement.wishListedOn)
      && _.isNil(improvement.doneOn) && _.isNil(improvement.doneByAnswerOn)).length;

  useEffect(() => {
    dispatch(getAllWorks(propertyId));
    dispatch(getAllImprovements(propertyId));
    dispatch(getAllRecommendedImprovements(propertyId));
  }, [dispatch, propertyId]);

  useEffect(() => {
    if (improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success)
      return;
    setCategories(improvementsConfiguration.content.categories.filter(category => category.name));
    setImprovements([...improvementsConfiguration.content.improvements].sort((impr1, impr2) => impr1.displayOrder - impr2.displayOrder));
    setQuestions([...improvementsConfiguration.content.questions].filter(question => question.improvementsPriorities.indexOf(1) !== -1));
  }, [improvementsConfiguration]);

  const useQuery = () => {
    let query = new URLSearchParams(useLocation().search);
    return query.get("categoryId");
  };
  const categoryToOpen = useQuery()

  const [openedCategory, setOpenedCategory] = useState<any>(null)
  const [openedTabs, setOpenedTabs] = useState<any>(null)
  const [workType, setWorkType] = useState<any>("")

  useEffect(() => {
    categoryToOpen && setOpenedCategory(categoryToOpen)
    categoryToOpen && setOpenedTabs(["improvementsPanel" + categoryToOpen])
  }, [categoryToOpen])

  useEffect(() => {
    getEntityTypes(propertyId, "work").then((res: any) => {
      res && setWorkType(res.filter((type: any) => type.name.toLowerCase().includes("improvement"))[0].name)
    })
  }, [propertyId])

  useEffect(() => {
    // @ts-ignore
    if (openedCategory && getRef("category" + openedCategory) && getRef("category" + openedCategory).current) {
      // @ts-ignore
      getRef("category" + openedCategory).current.scrollIntoView()
    }
  }, [openedCategory])

  const answerSelected = (answerId: number) => {
    postAnswer(propertyId, answerId)
      .then(() => {
        dispatch(getAllImprovements(propertyId));
      })
  }

  const setImprovementDone = (improvementId: string) => {
    setImprovements(improvements.map(improvement => {
        if (improvement.id === improvementId) {
          if (improvement.doneOn) {
            markImprovementAsUndone(propertyId, improvementId).then(() => dispatch(getAllImprovements(propertyId)));
            return {...improvement, doneOn: null};
          } else {
            tryDone.delete(improvementId);
            removeFromWishlist(propertyId, improvementId);
            markImprovementAsDone(propertyId, improvementId).then(() => dispatch(getAllImprovements(propertyId)));
            return {...improvement, wishListedOn: null, doneOn: new Date()};
          }
        } else
          return improvement;
      }
    ));
  }

  const setImprovementWishlist = (improvementId: string) => {
    setImprovements(improvements.map(improvement => {
        if (improvement.id === improvementId) {
          if (improvement.wishListedOn) {
            tryDone.delete(improvementId);
            removeFromWishlist(propertyId, improvementId);
            return {...improvement, wishListedOn: null};
          } else {
            tryDone.add(improvementId);
            addToWishlist(propertyId, improvementId);
            return {...improvement, wishListedOn: new Date()};
          }
        } else
          return improvement;
      }
    ));
  }

  const markWishListItemAsTry = (improvementId: string) => {
    if (tryDone.has(improvementId)) {
      tryDone.delete(improvementId);
    } else {
      tryDone.add(improvementId);
    }
    setTryDone(new Set(tryDone));
  }

  const showWishList = () => {
    improvements.filter(improvement => !_.isNil(improvement.wishListedOn) && _.isNil(improvement.doneOn) && _.isNil(improvement.doneByAnswerOn)).map(
      improvement => tryDone.add(improvement.id)
    );
    setShowingWishList(true);
    history.push("/improvements/wishlist")
  }

  const selectSort = (categoryId: number, type: string) => {
    sortByForCategory.set(categoryId, type);
    setSortByForCategory(new Map(sortByForCategory));
    if (!sortTypeForCategory.has(categoryId)) {
      sortTypeForCategory.set(categoryId, true);
      setSortTypeForCategory(new Map(sortTypeForCategory));
    }
  }

  const sortImprovements = (items: Array<ImprovementItem>, type: string | undefined, lowToHigh: boolean | undefined) => {
    if (!lowToHigh) {
      if (type === "ECO")
        return items.sort((impr1, impr2) => impr1.ecoImpactRating - impr2.ecoImpactRating);
      if (type === "SAVINGS")
        return items.sort((impr1, impr2) => impr1.savingsRating - impr2.savingsRating);
      if (type === "COST")
        return items.sort((impr1, impr2) => impr1.costRating - impr2.costRating);
    }
    if (type === "ECO")
      return items.sort((impr1, impr2) => impr2.ecoImpactRating - impr1.ecoImpactRating);
    if (type === "SAVINGS")
      return items.sort((impr1, impr2) => impr2.savingsRating - impr1.savingsRating);
    if (type === "COST")
      return items.sort((impr1, impr2) => impr2.costRating - impr1.costRating);
    return items;
  };

  const moreMenuDropdown = (item: any, isWishlist: boolean) => {
    const createWork = () => {
      addWork({
        propertyId,
        name: item.name,
        type: workType,
        completedDate: null,
        dueDate: null,
        recurring: null,
        isComplete: false,
      }).then((res) => {
        setEditingWorkData(res);
        setIsNewWork(true);
        setImprovementItemForWork(item);
        res.data && linkImprovementWithWork(propertyId, item.id, res.data.workId).then(() => {
          dispatch(getAllWorks(propertyId));
          dispatch(getAllImprovements(propertyId));
          toggleWorkDrawer(true);
        });
      });
    }

    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const {key} = event;

      if (key === "markAsDone") {
        setImprovementDone(item.id);
      } else if (key === "addToWishlist") {
        setImprovementWishlist(item.id);
      } else if (key === "removeToWishlist") {
        setImprovementWishlist(item.id);
      } else if (key === "createWork") {
        createWork();
      } else if (key === "viewWork") {
        setEditingWorkData({
          data: works.content.filter((work) => work.workId === item.relatedWorkId)[0],
        });
        setIsNewWork(false);
        setImprovementItemForWork(item);
        toggleWorkDrawer(true);
      } else if (key === "readMore") {
        setImprovementIdDetails(item.id);
        toggleImprovementDrawer(true);
      }
    };

    const showWishlistOption = (item: any) => {
      if (item.doneByAnswerOn || item.doneOn)
        return false
      else return true
    }

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        {isWishlist && <Menu.Item key="readMore">Read more</Menu.Item>}
        {item.relatedWorkId && <Menu.Item key="viewWork">View project</Menu.Item>}
        {_.isNil(item.relatedWorkId) && <Menu.Item key="createWork">Create project</Menu.Item>}
        <Menu.Item key="markAsDone">{item.doneOn ? "Unmark as done" : "Mark as done"}</Menu.Item>
        {!item.wishListedOn && showWishlistOption(item) &&
          <Menu.Item key="addToWishlist">Add to wish list</Menu.Item>}
        {item.wishListedOn && <Menu.Item key="removeToWishlist">Remove from wish list</Menu.Item>}
      </MenuStyled>
    );
  };

  const displayRecommendedImprovementsDesktop = () => {
    return (
      recommendedImprovements.content.slice(0, 2).map((improvement: any) =>
        <RecommendedImprovement>
          <div>
            <RecommendedImprovementIcon />
          </div>
          <div style={{marginLeft: "2.375rem"}}>
            {improvement.summary}
            <div style={{display: "flex", justifyContent: "space-between", marginTop: "1.875rem"}}>
              <div style={{display: "flex"}}>
                <ReadMoreButton onClick={() => {
                  setImprovementIdDetails(improvement.id);
                  toggleImprovementDrawer(true);
                }}>Read more</ReadMoreButton>
                <RemindLaterButton onClick={() => {
                  skipRecommendedImprovementUntilDate(propertyId, improvement.id).then(() => {
                    dispatch(getAllRecommendedImprovements(propertyId))
                  })
                }}>Remind later</RemindLaterButton>
              </div>
              <RemindLaterButton onClick={() => {
                skipRecommendedImprovementForever(propertyId, improvement.id).then(() => {
                  dispatch(getAllRecommendedImprovements(propertyId))
                })
              }}>Do not remind</RemindLaterButton>
            </div>
          </div>
        </RecommendedImprovement>
      )
    )
  }

  const displayRecommendedImprovementsMobile = () => {
    return (
      recommendedImprovements.content.slice(0, 2).map((improvement: any) =>
        <RecommendedImprovement>
          {improvement.summary}
          <ReadMoreButton onClick={() => {
            setImprovementIdDetails(improvement.id);
            toggleImprovementDrawer(true);
          }}>Read more</ReadMoreButton>
          <RemindLaterButton onClick={() => {
            skipRecommendedImprovementUntilDate(propertyId, improvement.id).then(() => {
              dispatch(getAllRecommendedImprovements(propertyId))
            })
          }}>Remind later</RemindLaterButton>
        </RecommendedImprovement>
      )
    )
  }

  // const displayRating = (rating: number) => {
  //   let color = "#EBF9FF";
  //   let text = "Great";
  //   let icon = <RatingGreatIcon/>;
  //   if (rating <= 25) {
  //     color = "#FCF0F0";
  //     text = "Poor";
  //     icon = <RatingPoorIcon/>;
  //   } else if (rating <= 50) {
  //     color = "#FEF9EA";
  //     text = "Average";
  //     icon = <RatingAverageIcon/>;
  //   } else if (rating <= 75) {
  //     color = "#EFFDF7";
  //     text = "Good";
  //     icon = <RatingGoodIcon/>;
  //   }
  //   return <RatingButton style={{background: color}}>
  //     {text} {icon}
  //   </RatingButton>
  // }

  const showQuestionsHouse = () =>
    <CollapseStyledQuestions
      ghost
      expandIconPosition={"right"}
    >
      <Panel
        header={
          <div style={{
            display: "flex",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "2em",
            flexDirection: windowWidth > 1024 ? "row" : "column",
          }}
          >
            <div style={{flexDirection: "column", paddingLeft: "1rem"}}>
              <h4
                className={
                  "ant-list-item-meta-title capitalize-first-letter"
                }
                style={{
                  fontSize: "1.13rem",
                  fontWeight: 600,
                  color: "#21272B",
                }}
              >
                Tell us more about your house
              </h4>
              <div
                className={"ant-list-item-meta-description"}
                style={{
                  color: "#6B7185",
                  fontWeight: 400,
                  fontSize: "1rem",
                  marginTop: "0.6rem",
                }}
              >
                Answer questions about your property and we will tailor the improvements to suit your needs.
              </div>
            </div>
            <span style={{
              marginTop: "-0.7rem",
              color: "#6B7185",
              fontWeight: 500,
              fontSize: "0.75rem",
              textAlign: "right",
              whiteSpace: "nowrap"
            }}>
                {questions.length} QUESTIONS
              </span>
          </div>
        }
        key={"improvementsQuestionList"}
      >
        <div>
          <div
            className={"ant-list-item-meta-description"}
            style={{
              color: "#6B7185",
              fontWeight: 400,
              fontSize: "1rem",
              marginTop: "0.6rem",
              padding: "0.5rem 2rem"
            }}
          >
            Where answers are marked with <EPCIcon /> this indicates that this data comes
            from the Energy performance certificates data base that Contains public sector
            information licensed under the Open Government Licence v3.0.
          </div>
          {questions.map((question, index) =>
            <div style={{display: "flex", flexDirection: "row", padding: "1rem 2rem"}}>
              <div style={{marginRight: "1.5rem"}}>
                <Avatar
                  size={40}
                  style={{
                    backgroundColor: "#EFFDF7",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#67C18B",
                    fontWeight: 700,
                    fontSize: "1rem",
                  }}
                >
                  {index + 1}
                </Avatar>
              </div>
              <div style={{fontSize: "1rem", color: "#21272B", width: "100%"}}>
                <div style={{fontWeight: 600, maxWidth:"1000px", marginTop:"0.4rem"}}>{question.question}</div>
                <ul style={{
                  listStyleType: "none",
                  paddingLeft: "0.3rem",
                  paddingTop: "1rem",
                  fontSize: "1rem",
                  fontWeight: 300,
                  lineHeight: "1.5rem",
                }}>
                  {question.answers.filter((answer: PropertyQuestionAnswer) => answer.answer !== null)
                    .sort((a1: PropertyQuestionAnswer, a2: PropertyQuestionAnswer) => a1.answerOrder - a2.answerOrder)
                    .map((answer: PropertyQuestionAnswer) =>
                    <li style={{width: "33%", float: "left", display: "flex", alignItems: "flex-start", marginBottom:"1.5rem"}}
                        key={"houseQ" + question.id + "A" + answer.id}>
                      <input type={"radio"} name={"houseQuestion" + question.id}
                             id={"houseQ" + question.id + "A" + answer.id} style={{cursor: "pointer", transform: "scale(1.5)", marginTop:"0.35rem"}}
                             onClick={() => answerSelected(answer.id)}
                             checked={answer.id === question.givenAnswerId}/>
                      <label htmlFor={"houseQ" + question.id + "A" + answer.id}
                             style={{marginLeft: "1rem", cursor: "pointer",
                               maxWidth: "300px",
                             }}>
                        {answer.answer}
                        {answer.id === question.epcAnswerId && <EPCIcon style={{width: "1rem", marginLeft: "0.5rem"}}/>}
                      </label>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
      </Panel>
    </CollapseStyledQuestions>

  const itemDescription = (item: any) => {
    return <>
      {windowWidth > 1024 && <h4 className={"ant-list-item-meta-title capitalize-first-letter"}
           style={{fontSize: "1rem"}}>
        {item.name}{" "}
        {!_.isNil(item.relatedWorkId) &&
        <Popover
            content={<span style={{fontSize: "0.75rem", fontWeight: 500, padding: "0.5rem"}}>Project in progress</span>}
            overlayClassName={"smallTooltipPopover"}
            placement="top"
        >
            <WorkIcon style={{marginBottom: "-0.3rem"}}/>
        </Popover>
        }
      </h4>}
      <div className={"ant-list-item-meta-description"}
           style={{fontSize: "1rem", color: "#6B7185"}}>
        {item.summary}
      </div>
      <RatingElements>
        <li>
          <span>ECO Impact:</span>
          {item.ecoImpactRating > 0
            ? [...Array(item.ecoImpactRating)].map(() =>
              <LeafIcon style={{width: "1rem"}}/>
            )
            : <span style={{color: "#67C18B"}}>-</span>
          }
        </li>
        <li style={{width: "7rem"}}>
          <span>Savings:</span>
          <span style={{color: "#EFC45D"}}>
            {item.savingsRating > 0
              ? [...Array(item.savingsRating)].map(() => {
                return getCurrencySign(propertyPreferences.preferredCurrency)
              })
              : "-"
            }
          </span>
        </li>
        <li style={{width: "7rem"}}>
          <span>Cost:</span>
          <span style={{color: "#EF624E"}}>
            {item.costRating > 0
              ? [...Array(item.costRating)].map(() => {
                return getCurrencySign(propertyPreferences.preferredCurrency)
              })
              : "-"
            }
          </span>
        </li>
        <li>
          <span>Effort:</span>
          <span style={{color: "#6B7185"}}>{item.relativeEffortOfWorkRequired}</span>
        </li>
        <li>
          <span>Required:</span>
          <span style={{color: "#6B7185"}}>{item.specialtyOfWorkRequired}</span>
        </li>
        {item.incentives.length > 0 &&
        <li style={{marginLeft: windowWidth > 1024 ? "2rem" : "0"}}>
            <span>Incentives:</span>
            <span style={{color: "#6B7185"}}>{item.incentives.length}</span>
        </li>
        }
      </RatingElements>
    </>
  }

  const starIcon = (item: any) => {
    return <Popover
      content={<span style={{
        fontSize: "0.75rem",
        fontWeight: 500,
        padding: "0.5rem",
        flexShrink: 0
      }}>
        {(!_.isNil(item.doneOn) || !_.isNil(item.doneByAnswerOn))
          ? "Improvement has already been implemented  on this property."
          : _.isNil(item.wishListedOn)
            ? "Add to wishlist"
            : "Remove from wishlist"
        }
      </span>}
      overlayClassName={"smallTooltipPopover"}
      placement="top"
    >
      <Avatar
        style={{
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        icon={
          (!_.isNil(item.doneOn) || !_.isNil(item.doneByAnswerOn))
            ? <StarPurpleIcon/>
            : !_.isNil(item.wishListedOn)
            ? <StarGreenFullIcon onClick={() => {
              setImprovementWishlist(item.id)
            }}/>
            : <StarGreenEmptyIcon onClick={() => {
              setImprovementWishlist(item.id)
            }}/>
        }
      />
    </Popover>
  }

  const showItemDesktop = (item: any, isWishlist: boolean) => {
    return <ListItemStyled
      actions={[
        !isWishlist &&
        <Popover
            content={<div style={{
              fontSize: "0.75rem",
              fontWeight: 500,
              padding: "0.5rem",
              maxWidth: "17rem",
              textAlign: "center"
            }}>
              {!_.isNil(item.doneByAnswerOn) && "Done based on provided property details related to this improvement."}
              {!_.isNil(item.doneOn) && _.isNil(item.doneByAnswerOn) && "Unmark as done"}
              {_.isNil(item.doneOn) && _.isNil(item.doneByAnswerOn) && "Mark as done"}
            </div>}
            overlayClassName={"smallTooltipPopover"}
            placement="top"
            trigger={["hover"]}
        >
            <ButtonChecked
                icon={
                  (!_.isNil(item.doneOn) || !_.isNil(item.doneByAnswerOn)) && <CheckIcon/>
                }
                onClick={() => {
                  setImprovementDone(item.id)
                }}
                disabled={!_.isNil(item.doneByAnswerOn)}
            >
              {_.isNil(item.doneOn) && " "}
            </ButtonChecked>
        </Popover>
        ,
        !isWishlist && <ButtonDetails
            onClick={() => {
              setImprovementIdDetails(item.id);
              toggleImprovementDrawer(true);
            }}
        >
            Read more
        </ButtonDetails>,
        // isWishlist && <Switch checked={tryDone.has(item.id)} onChange={() => markWishListItemAsTry(item.id)}/>,
        <Popover
          content={moreMenuDropdown(item, isWishlist)}
          placement="bottomRight"
          trigger={["click"]}
          //@ts-ignore
          zIndex={2}
        >
          <MoreMenu
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Popover>
      ]}
    >
      <Skeleton loading={false} title={false} active>
        <List.Item.Meta
          style={{opacity: !isWishlist || tryDone.has(item.id) ? "100%" : "30%"}}
          description={
            <div
              style={{
                display: "flex",
                paddingTop: "0.5rem",
                justifyContent: "space-between",
                width: "100%;"
              }}
            >
              <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: "flex-start",
                  }}
                >
                  {" "}
                  {starIcon(item)}
                  <div
                    style={{flexDirection: "column", paddingLeft: "1rem", width: "100%"}}
                  >
                    {itemDescription(item)}
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Skeleton>
    </ListItemStyled>
  }

  const showItemMobile = (item: any, isWishlist: boolean) => {
    return <ListItemStyled>
      <Skeleton loading={false} title={false} active>
        <List.Item.Meta
          style={{opacity: !isWishlist || tryDone.has(item.id) ? "100%" : "30%"}}
          description={
            <div
              style={{
                display: "flex",
                paddingTop: "0.5rem",
                flexDirection: "column",
                width: "100%;"
              }}
            >
              <div style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: '1rem'
              }}>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  marginRight: '1rem',
                  overflow: "hidden"
                }}>
                  {starIcon(item)}
                  {!_.isNil(item.relatedWorkId) &&
                  <Popover
                      content={<span style={{fontSize: "0.75rem", fontWeight: 500, padding: "0.5rem"}}>Project in progress</span>}
                      overlayClassName={"smallTooltipPopover"}
                      placement="top"
                  >
                      <WorkIcon style={{flexShrink: 0}} />
                  </Popover>
                  }
                  <div style={{
                    fontSize: "1rem",
                    fontWeight: 600,
                    color: "#21272b",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}>{item.name}</div>
                </div>
                <Popover
                  content={moreMenuDropdown(item, isWishlist)}
                  placement="bottomRight"
                  trigger={["click"]}
                  //@ts-ignore
                  zIndex={2}
                >
                  <MoreMenu
                    style={{flexShrink: 0}}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </Popover>
              </div>
              <div style={{flexDirection: "column", width: "100%"}}>
                {itemDescription(item)}
              </div>
            </div>
          }
        />
      </Skeleton>
      <div style={{display: "flex", width: "100%", marginTop: '0.5rem'}}>
        {!isWishlist && <ButtonDetails
            style={{width: "100%", marginRight: '1rem'}}
            onClick={() => {
              setImprovementIdDetails(item.id);
              toggleImprovementDrawer(true);
            }}
        >Read more</ButtonDetails>}
        {!isWishlist &&
        <Popover
            content={<div style={{
              fontSize: "0.75rem",
              fontWeight: 500,
              padding: "0.5rem",
              maxWidth: "17rem",
              textAlign: "center"
            }}>
              {!_.isNil(item.doneByAnswerOn) && "Done based on provided property details related to this improvement."}
              {!_.isNil(item.doneOn) && _.isNil(item.doneByAnswerOn) && "Unmark as done"}
              {_.isNil(item.doneOn) && _.isNil(item.doneByAnswerOn) && "Mark as done"}
            </div>}
            overlayClassName={"smallTooltipPopover"}
            placement="top"
            trigger={["hover"]}
        >
        <ButtonChecked
          icon={(!_.isNil(item.doneOn) || !_.isNil(item.doneByAnswerOn)) && <CheckIcon/>}
          onClick={() => {setImprovementDone(item.id)}}
          disabled={!_.isNil(item.doneByAnswerOn)}
        >{_.isNil(item.doneOn) && " "}</ButtonChecked>
      </Popover>}
        {/*{isWishlist && <Switch checked={tryDone.has(item.id)} onChange={() => markWishListItemAsTry(item.id)}/>}*/}
      </div>
    </ListItemStyled>
  }

  const showListOfImprovements = (items: Array<ImprovementItem>, isWishlist: boolean) => (
    <List
      style={{
        width: "100%",
        alignItems: "center",
        backgroundColor: "#fff",
        borderBottom: "1px",
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px",
        borderTop: "1px solid #DAE0E6",
      }}
      itemLayout="horizontal"
      dataSource={items}
      renderItem={(item) =>
        (windowWidth > 1024 ? showItemDesktop(item, isWishlist) : showItemMobile(item, isWishlist))
      }
    />
  )

  const SortImprovementsDesktop = (category: any) => {
    return <SortImprovements>
      <div style={{display: "flex", alignItems: "center"}}>
        <span style={{marginRight: "2rem"}}>
          Sort by:
        </span>
        <ImprovementSortSelector
          onClick={() => selectSort(category.id,"ECO")}
          className={sortByForCategory.get(category.id) === "ECO" ? "improvements-sort-selected" : ""}
        >
          Eco Impact
        </ImprovementSortSelector>
        <ImprovementSortSelector
          onClick={() => selectSort(category.id,"SAVINGS")}
          className={sortByForCategory.get(category.id) === "SAVINGS" ? "improvements-sort-selected" : ""}
        >
          Savings
        </ImprovementSortSelector>
        <ImprovementSortSelector
          onClick={() => selectSort(category.id,"COST")}
          className={sortByForCategory.get(category.id) === "COST" ? "improvements-sort-selected" : ""}
        >
          Cost
        </ImprovementSortSelector>
        <SortUpIcon
          onClick={() => {sortTypeForCategory.set(category.id, true); setSortTypeForCategory(new Map(sortTypeForCategory));}}
          className={!_.isNil(sortTypeForCategory.get(category.id)) && sortTypeForCategory.get(category.id) ? "improvements-sort-icon-selected" : "improvements-sort-icon-notselected"}
          style={{marginRight: "1rem", cursor:"pointer"}}
        />
        <SortDownIcon
          onClick={() => {sortTypeForCategory.set(category.id, false); setSortTypeForCategory(new Map(sortTypeForCategory));}}
          className={!_.isNil(sortTypeForCategory.get(category.id)) && !sortTypeForCategory.get(category.id) ? "improvements-sort-icon-selected" : "improvements-sort-icon-notselected"}
          style={{marginTop: "0.2rem", cursor:"pointer"}}
        />
      </div>
    </SortImprovements>
  }

  const SortImprovementsMobile = (category: any) => {
    return <SortImprovements>
      <div style={{display: "flex", flexDirection: "column", width: '100%'}}>
        <div style={{display: "flex", justifyContent: "space-around"}}>
          <span style={{marginRight: "2rem"}}>
            Sort by:
          </span>
          <span>
            <SortUpIcon
              onClick={() => {sortTypeForCategory.set(category.id, true); setSortTypeForCategory(new Map(sortTypeForCategory));}}
              className={!_.isNil(sortTypeForCategory.get(category.id)) && sortTypeForCategory.get(category.id) ? "improvements-sort-icon-selected" : "improvements-sort-icon-notselected"}
              style={{marginRight: "1rem", cursor:"pointer"}}
            />
            <SortDownIcon
              onClick={() => {sortTypeForCategory.set(category.id, false); setSortTypeForCategory(new Map(sortTypeForCategory));}}
              className={!_.isNil(sortTypeForCategory.get(category.id)) && !sortTypeForCategory.get(category.id) ? "improvements-sort-icon-selected" : "improvements-sort-icon-notselected"}
              style={{marginTop: "0.2rem", cursor:"pointer"}}
            />
          </span>
        </div>
        <div style={{display: "flex", justifyContent: "space-around"}}>
          <ImprovementSortSelector
            onClick={() => selectSort(category.id,"ECO")}
            className={sortByForCategory.get(category.id) === "ECO" ? "improvements-sort-selected" : ""}
          >
            Eco Impact
          </ImprovementSortSelector>
          <ImprovementSortSelector
            onClick={() => selectSort(category.id,"SAVINGS")}
            className={sortByForCategory.get(category.id) === "SAVINGS" ? "improvements-sort-selected" : ""}
          >
            Savings
          </ImprovementSortSelector>
          <ImprovementSortSelector
            onClick={() => selectSort(category.id,"COST")}
            className={sortByForCategory.get(category.id) === "COST" ? "improvements-sort-selected" : ""}
          >
            Cost
          </ImprovementSortSelector>
        </div>
      </div>
    </SortImprovements>
  }

  const categoryCollapseDesktop = (category: any) => {
    return <CollapseStyled
      ghost
      expandIconPosition={"right"}
      expandIcon={improvementsFromCategory(category.id).length === 0 ? (arg01: any) => {
        return <></>
      } : undefined}
      collapsible={improvementsFromCategory(category.id).length === 0 ? "disabled" : undefined}
      activeKey={openedTabs}
      onChange={(tabs) => {setOpenedTabs(tabs)}}
      key={"improvementCategory" + category.id}
    >
      <Panel
        key={"improvementsPanel" + category.id}
        header={
          <div
            //@ts-ignore
            ref={setRef("category" + category.id)}
            style={{
              display: "flex",
              paddingTop: "0.5rem",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "2em",
              flexWrap: windowWidth > 500 ? "nowrap" : "wrap"
            }}
          >
            <div style={{display: "flex", flexDirection: "column"}}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexShrink: 2
                }}
              >
                {" "}
                <Avatar
                  style={{
                    backgroundColor: "#EFFDF7",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
                <div
                  style={{flexDirection: "column", paddingLeft: "1rem"}}
                >
                  <h4
                    className={
                      "ant-list-item-meta-title category-title capitalize-first-letter"
                    }
                    style={{
                      fontSize: "1.13rem",
                      fontWeight: 600,
                      color: "#21272B",
                    }}
                  >
                    {category.name}
                  </h4>
                  <div
                    className={"ant-list-item-meta-description"}
                    style={{
                      color: "#6B7185",
                      fontWeight: 400,
                      fontSize: "1rem",
                      marginTop: "0.6rem",
                      marginBottom: "0.6rem",
                    }}
                  >
                    {category.name.includes("energy") && <>
                            <span style={{
                              fontSize: "0.875rem",
                              fontWeight: "bold",
                              color: "#21272b",
                              marginRight: "0.3rem"
                            }}>Savings:</span>
                        <span style={{color: "#EFC45D"}}>
                              {savingRatingGreen > 0 ? [...Array(savingRatingGreen)].map(() => {return getCurrencySign(propertyPreferences.preferredCurrency)}) : "-"}
                            </span>
                    </>}
                    {category.name.includes("environment") && <>
                            <span style={{
                              fontSize: "0.875rem",
                              fontWeight: "bold",
                              color: "#21272b",
                              marginRight: "0.3rem"
                            }}>ECO Impact:</span>
                      {ecoRatingGreen > 0 ? [...Array(ecoRatingGreen)].map(() =>
                        <LeafIcon style={{width: "1rem", height: "1rem"}}/>) : "-"}
                    </>}
                  </div>
                </div>
              </div>
            </div>
            {improvementsFromCategory(category.id).length > 0 ?
              <div style={{
                display: "flex",
                flexDirection: "column",
                flexShrink: 0,
                alignItems: "flex-end",
                width: windowWidth > 500 ? "auto" : "100%",
                marginLeft: "2rem"}}>
                    <span style={{color: "#21272B", fontWeight: 600, fontSize: "1.1rem"}}>
                      {Math.floor(improvementsFromCategoryWithoutParent(category.id).filter(improvement => (!_.isNil(improvement.doneOn) || !_.isNil(improvement.doneByAnswerOn))).length / improvementsFromCategoryWithoutParent(category.id).length * 100)}%
                    </span>
                <div style={{width: "8rem"}}>
                  <Progress
                    percent={Math.floor(improvementsFromCategoryWithoutParent(category.id).filter(improvement => (!_.isNil(improvement.doneOn) || !_.isNil(improvement.doneByAnswerOn))).length / improvementsFromCategoryWithoutParent(category.id).length * 100)}
                    size="small" showInfo={false} strokeColor={"#2A80E1"}/>
                </div>
                <span style={{
                  color: "#6B7185",
                  fontWeight: 500,
                  fontSize: "0.75rem",
                  textAlign: "right",
                  whiteSpace: "nowrap"
                }}>
                      {improvementsFromCategoryWithoutParent(category.id).filter(improvement => (!_.isNil(improvement.doneOn) || !_.isNil(improvement.doneByAnswerOn))).length}/{improvementsFromCategoryWithoutParent(category.id).length} IMPROVEMENTS COMPLETED
                    </span>
              </div>
              : <div style={{marginLeft:"3rem"}}>
                <div style={{background:"#EBF9FF", borderRadius:"4px", color:"#2A80E1", fontWeight:500, whiteSpace:"nowrap",
                  display:"flex", alignItems:"center", padding:"0.25rem"}}>
                  <ClockIcon style={{marginRight:"0.5rem"}}/>
                  Coming soon
                </div>
              </div>}
          </div>
        }
      >
        {windowWidth > 500 ? SortImprovementsDesktop(category) : SortImprovementsMobile(category)}
        {showListOfImprovements(sortImprovements(
          improvementsFromCategory(category.id), sortByForCategory.get(category.id), sortTypeForCategory.get(category.id)),
          false)
        }
      </Panel>
    </CollapseStyled>
  }

  if (showingWishList && location && location.pathname === "/improvements/wishlist")
    return <ImproveMyPropertyWrapper>
      <WorkDrawer
        width={null}
        isOpen={isWorkDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        // @ts-ignore
        setEditingWorkData={setEditingWorkData}
        work={editingWorkData}
        parentDrawer={"improvement"}
        isNewWork={isNewWork}
        parentImprovement={improvementItemForWork}
        refreshParent={() => {
          dispatch(getAllImprovements(propertyId));
        }}
      />
      <ImprovePropertyDrawer
        isOpen={improvementDrawerOpen}
        toggleDrawerOpen={toggleImprovementDrawer}
        improvementId={improvementIdDetails}
        setImprovementId={setImprovementIdDetails}
        setImprovementDone={setImprovementDone}
      />
      <BackToImprovementsButton
        type="text"
        icon={<DownArrow/>}
        onClick={() => {
          setShowingWishList(false)
          history.push("/improvements")
        }}
      >
        <span style={{fontSize: "0.75rem", color: "#21272B", fontWeight: 500}}>
          BACK TO GUIDANCE
        </span>
      </BackToImprovementsButton>
      <ImproveMyPropertyHeader>
        <div>My wishlist</div>
      </ImproveMyPropertyHeader>
      <ImproveMyPropertySubHeader>
        <div style={{display: "flex", flexDirection: "row"}}>
          <div style={{display: "flex", flexDirection: "column", marginRight: "5rem"}}>
            <RatingTitle>WISHLIST IMPROVEMENTS</RatingTitle>
            <span style={{color: "#21272B", fontSize: "2rem", fontWeight: 700, marginTop: "-0.5rem"}}>
              {wishListSize()}
            </span>
          </div>
          <div style={{display: "flex", flexDirection: "column"}}>
            <RatingTitle>ACTIVE IMPROVEMENTS</RatingTitle>
            <span style={{color: "#21272B", fontSize: "2rem", fontWeight: 700, marginTop: "-0.5rem"}}>
              {tryDone.size}
            </span>
          </div>
        </div>
        {/*<div style={{display: "flex", flexDirection: "row"}}>*/}
        {/*  <RatingHeader style={{marginRight: "4rem"}}>*/}
        {/*    <RatingTitle>*/}
        {/*      <span>WISHLIST RATING</span>*/}
        {/*      <HelpIcon/>*/}
        {/*    </RatingTitle>*/}
        {/*    {displayRating(wishListRating)}*/}
        {/*  </RatingHeader>*/}
        {/*  <RatingHeader>*/}
        {/*    <RatingTitle>*/}
        {/*      <span>CURRENT RATING</span>*/}
        {/*      <HelpIcon/>*/}
        {/*    </RatingTitle>*/}
        {/*    {displayRating(livletRating)}*/}
        {/*  </RatingHeader>*/}
        {/*</div>*/}
      </ImproveMyPropertySubHeader>
      <ImproveMyPropertyCategories>
        <CollapseStyled
          ghost
          expandIcon={(arg01: any) => {
            return <></>
          }}
          expandIconPosition={"right"}
          collapsible={"disabled"}
          defaultActiveKey={"wishListedPanel"}
        >
          <Panel
            header={
              <div
                style={{
                  display: "flex",
                  paddingTop: "0.5rem",
                  justifyContent: "space-between",
                  paddingRight: "2em",
                }}
              >
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <Avatar
                      style={{
                        backgroundColor: "#EFFDF7",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      icon={<StarGreenFullIcon/>}
                    />
                    <div
                      style={{flexDirection: "column", paddingLeft: "1rem"}}
                    >
                      <h4
                        className={
                          "ant-list-item-meta-title capitalize-first-letter"
                        }
                        style={{
                          fontSize: "1.13rem",
                          fontWeight: 600,
                          color: "#21272B",
                        }}
                      >
                        Manage your wish list
                      </h4>
                      <div
                        className={"ant-list-item-meta-description"}
                        style={{
                          color: "#6B7185",
                          fontWeight: 400,
                          fontSize: "1rem",
                          marginTop: "0.6rem",
                        }}
                      >
                        {/*Toggle your wishlist improvements to see how each effects your rating.*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            key={"wishListedPanel"}>
            {showListOfImprovements(improvements.filter(improvement => !_.isNil(improvement.wishListedOn) && _.isNil(improvement.doneOn) && _.isNil(improvement.doneByAnswerOn)), true)}
          </Panel>
        </CollapseStyled>
      </ImproveMyPropertyCategories>
    </ImproveMyPropertyWrapper>
  else
    return <ImproveMyPropertyWrapper>
      <WorkDrawer
        width={null}
        isOpen={isWorkDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        // @ts-ignore
        setEditingWorkData={setEditingWorkData}
        work={editingWorkData}
        parentDrawer={"improvement"}
        parentImprovement={improvementItemForWork}
        isNewWork={isNewWork}
        refreshParent={() => {
          dispatch(getAllImprovements(propertyId));
        }}
      />
      <ImprovePropertyDrawer
        isOpen={improvementDrawerOpen}
        toggleDrawerOpen={toggleImprovementDrawer}
        improvementId={improvementIdDetails}
        setImprovementId={setImprovementIdDetails}
        setImprovementDone={setImprovementDone}
      />
      <ImproveMyPropertyHeader>
        <div>
          Guidance
          <div
            style={{
              color: '#21272B',
              marginTop: '0.6rem',
              fontSize: '14px',
              fontWeight: 400}}>
            Compare costs and benefits of various improvements and learn what is involved in making the changes.</div>
        </div>
        {/*<RatingHeader>*/}
        {/*  <RatingTitle>*/}
        {/*    <span>LIVLET RATING</span>*/}
        {/*    <HelpIcon/>*/}
        {/*  </RatingTitle>*/}
        {/*  {displayRating(livletRating)}*/}
        {/*</RatingHeader>*/}
        <RatingHeader style={{display: "flex", marginTop: windowWidth > 1024 ? "0" : "2.5rem"}}>
          <RatingTitle>
            <span>{wishListSize() > 0 ? wishListSize() : "NO "} ITEMS WISH LISTED</span>
            {/*<HelpIcon/>*/}
          </RatingTitle>
          <WishListButton onClick={() => showWishList()}>
            <StarIcon />
            My Wish List
          </WishListButton>
        </RatingHeader>
      </ImproveMyPropertyHeader>
      <ImproveMyPropertyHeader>
        {window.innerWidth > 1024 && <UnansweredQuestionsComp/>}
      </ImproveMyPropertyHeader>
      {/*<ImproveMyPropertySubHeader>*/}
      {/*  <div style={{display: "flex", flexDirection: "row"}}>*/}
      {/*    <div style={{display: "flex", flexDirection: "column", marginRight: "5rem"}}>*/}
      {/*      <RatingTitle>ECO IMPROVEMENTS</RatingTitle>*/}
      {/*      <div>*/}
      {/*        {ecoRatingGreen > 0 && [...Array(ecoRatingGreen)].map(() => <LeafGreenIcon/>)}*/}
      {/*        {ecoRatingHalf > 0 && [...Array(ecoRatingHalf)].map(() => <LeafHalfIcon/>)}*/}
      {/*        {(ecoRatingEmpty > 0 && ecoRatingEmpty < 5) && [...Array(ecoRatingEmpty)].map(() => <LeafGrayIcon/>)}*/}
      {/*        {ecoRatingEmpty === 5 &&*/}
      {/*        <span style={{color: "#67C18B", fontSize: "2rem", fontWeight: 700, marginTop: "-0.5rem"}}>-</span>}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div style={{display: "flex", flexDirection: "column"}}>*/}
      {/*      <RatingTitle>SAVINGS FROM IMPROVEMENTS</RatingTitle>*/}
      {/*      <div>*/}
      {/*        {savingRatingGreen > 0 && [...Array(savingRatingGreen)].map(() => <PoundYellowIcon*/}
      {/*          style={{marginRight: "0.5rem"}}/>)}*/}
      {/*        {savingRatingHalf > 0 && [...Array(savingRatingHalf)].map(() => <PoundHalfIcon*/}
      {/*          style={{marginRight: "0.5rem"}}/>)}*/}
      {/*        {(savingRatingEmpty > 0 && savingRatingEmpty < 5) && [...Array(savingRatingEmpty)].map(() =>*/}
      {/*          <PoundGrayIcon style={{marginRight: "0.5rem"}}/>)}*/}
      {/*        {savingRatingEmpty === 5 &&*/}
      {/*        <span style={{color: "#F2C94C", fontSize: "2rem", fontWeight: 700, marginTop: "-0.5rem"}}>-</span>}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</ImproveMyPropertySubHeader>*/}

      {/*<ImproveMyPropertyCategories style={{marginTop: "2rem"}}>*/}
      {/*  <div style={{*/}
      {/*    display: "flex",*/}
      {/*    width: "100%",*/}
      {/*    textAlign: "left",*/}
      {/*    alignItems: "center",*/}
      {/*    fontWeight: 600,*/}
      {/*    fontSize: "1.125rem",*/}
      {/*    lineHeight: "1.5rem",*/}
      {/*    color: "#21272B"*/}
      {/*  }}>*/}
      {/*    <ECOIcon style={{marginRight: "1rem"}} /> Recommended improvements*/}
      {/*  </div>*/}
      {/*  {windowWidth > 1024 ? displayRecommendedImprovementsDesktop() : displayRecommendedImprovementsMobile()}*/}
      {/*</ImproveMyPropertyCategories>*/}

      <ImproveMyPropertyCategories>
        {/*{showQuestionsHouse()}*/}
        {categories.map(category => improvementsFromCategory(category.id).length > 0 && categoryCollapseDesktop(category))}
        {categories.map(category => improvementsFromCategory(category.id).length === 0 && categoryCollapseDesktop(category))}
      </ImproveMyPropertyCategories>
    </ImproveMyPropertyWrapper>;
};

const SortImprovements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  
  padding: 0.5rem 4rem;
  border-top: 1px solid #DAE0E6;

  font-size: 1rem;
  color: #6B7185;
  
  @media (max-width: 500px) {
    padding: 1rem 0.5rem;
    flex-direction: column;
  }
`;

const ImprovementSortSelector = styled.div`
  display: flex;
  background: #DAE0E6;
  color: #6B7185;
  border-radius: 24px;
  padding: 0.5rem 1.25rem;
  margin-right: 2rem;
  
  margin: 0 4.2rem;
  max-width: 1100px;
  
  :hover {
    cursor: pointer;
  }
  
  @media (max-width: 1024px) {
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }
`;


const ImproveMyPropertyWrapper = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0;
  margin: 0 4.2rem;
  max-width: 1100px;

  @media (max-width: 1024px) {
    margin: 1rem;
  }
`;

const ImproveMyPropertyHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 0.5rem;
  
  @media (max-width: 1024px) {
    margin-top: 0;
    margin-bottom: 0;
    flex-direction: column;
  }
`;

const RatingHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const RatingTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  
  font-weight: 500;
  color: #21272B;
  font-size: 0.75rem;

  svg path {
    stroke: #21272B;
  }
`;

export const RatingButton = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.6rem 1rem;
  border-radius: 10px;
  color: black;
  font-size: 1.7rem;
  font-weight: 700;
`;

export const WishListButton = styled(Button)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  
  display: -webkit-flex;
  -webkit-justify-content: space-around;
  -webkit-align-items: center;
  
  -ms-justify-content: space-around;
  -ms-align-items: center;
  
  width: fit-content;
  padding: 1.5rem 0.5rem;
  background: #F0F2F5;
  border: 1px solid #EFC45D;
  box-sizing: border-box;
  border-radius: 6px;
  color: #21272B;
  font-size: 1rem;
  font-weight: 600;
  
  span {
    margin-left: 0.3rem;
  }
  
  svg path {
    stroke: #EFC45D;
  }
  
  :hover {
    color: #21272B;
    border-color: #2A80E1;
    background: #F0F2F5;
  }
  :focus {
    color: #21272B;
    border-color: #2A80E1;
    background: #F0F2F5;
  }
`;


const ImproveMyPropertySubHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
`;

const ImproveMyPropertyCategories = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  
  align-content: center;
  align-items: center;
  margin: 3rem 4.2rem;
  margin-bottom: 0;
  
  @media (max-width: 1024px) {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  } 
`;

const CollapseStyled = styled(Collapse)`
  width: 100%;
  background-color: #fff;
  border-radius: 0.625rem;
  border-color: #dae0e6;
  margin-bottom: 2rem;
  
  .anticon {
    margin-top: 1.8rem;
    margin-right: 1.3rem;
  }
  
  .ant-collapse-item-active .ant-collapse-header {
    opacity: 70%;
  }
  
  .ant-collapse-item-active .category-title {
    color: #2A80E1!important;
  }
  
  .ant-collapse-item-disabled .ant-collapse-header {
    cursor: initial;
  }
`;

const CollapseStyledQuestions = styled(CollapseStyled)`
  .ant-collapse-item-active .ant-collapse-header {
    opacity: 80% !important;
  }

  .ant-collapse-header {
    border-radius: 0.625rem !important;
    background-color: #F1FFFB;
  }
  .ant-collapse-header[aria-expanded="true"] {
    border-top-right-radius: 0.625rem !important;
    border-top-left-radius: 0.625rem !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`;

const ListItemStyled = styled(List.Item)`
  padding: 1rem;

  @media (max-width: 1024px) {
    width: 100%;

    .ant-list-item-action > li {
      width: 100%;
    }
    
    flex-direction: column;
    .ant-list-item-meta {
      width: 100%;
    }
  }

  .ant-list-item-meta-title {
    font-size: 1rem;
    font-weight: 600;
    color: #21272b;
  }
  .ant-list-item-meta-description {
    color: #6b7185;
    font-weight: 400;
    margin-top: 0.4rem;
  }
  .ant-list-item-action {
    display: flex;
    align-items: center;
  }
  
  em {
    display: none;
  }
`;

const RatingElements = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding-left: 0;
  list-style-type: none;
  line-height: 2.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #21272B;

  li {
    display: flex;
    flex-direction: row; 
    align-items: center;
    min-width: 10rem;
    span {
      margin-right: 0.2rem;
    }
   }
   
  @media (max-width: 1024px) {
    li {
      min-width: calc(100% / 3);
    }
  } 
  
  @media (max-width: 500px) {
    li {
      min-width: calc(100% / 2);
    }
  } 
`;

const BackToImprovementsButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -1.5rem;
  padding-left: 0;
  width:100%;
  background: #F0F2F5;
  :hover {
    background: #F0F2F5;
  }
  
  @media (max-width: 1024px) {
    margin-top: 0;
  } 
`;

const ButtonDetails = styled(SecondaryButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-weight: 600;
  font-size: 1rem;
  width: 10rem;
  height: 2.5rem;
  :hover {
    background: white;
    color: black;
   }
`;

export const ButtonChecked = styled(SecondaryButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-weight: 600;
  font-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  padding-left: 0;
  padding-right: 0;
  :hover {
    background: white;
    color: black;
  }
  :focus {
    border: 1px solid #dae0e6;
  }
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

const RecommendedImprovement = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  background: white;
  border-radius: 10px;
  padding: 2.75rem 10rem 2.125rem 3.375rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #2A80E1;
  display: flex;
  
  @media (max-width: 1024px) {
    padding: 1.875rem 1.25rem;
    flex-direction: column;
    text-align: center;
  }
`

const ReadMoreButton = styled(Button)`
  background: #2A80E1;
  width: 11.25rem;
  height: 3.125rem;
  padding: 0.625rem 1.75rem;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  border: 0;
  border-radius: 6px;
  
  @media (max-width: 1130px) {
    width: auto;
    padding: 0.625rem 1rem;
  }
  
  @media (max-width: 1024px) {
    width: 11.25rem;
    margin: 1rem auto;
  }
`

const RemindLaterButton = styled(Button)`
  color: #DAE0E6;
  width: 11.25rem;
  height: 3.125rem;
  padding: 0.625rem 1.75rem;
  background: white;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  border: 0;
  box-shadow: none;
  
  @media (max-width: 1130px) {
    width: auto;
    padding: 0.625rem 1rem;
  }
  
   @media (max-width: 1024px) {
    width: 11.25rem;
    margin: auto;
  }
`

export default ImproveMyProperty;
