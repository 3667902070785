import { Button, Dropdown, Form, Input, Modal, Select } from "antd";
import { useIntl } from "react-intl";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import _ from "lodash";
import styled from "styled-components";

import {ReactComponent as WorkIcon} from "../../../images/planner/WorkIcon.svg";
import { ReactComponent as HelpIcon } from "../../../images/Help.svg";
import messages from "../../screens/messages";
import { removeWork } from "../../../api-wrapper/works/removeWork";
import { getAllWorks } from "../../actions/works";
import { useDispatch, useSelector } from "react-redux";
import { addWork, TAddWorkResponse } from "../../../api-wrapper/works/addWork";
import { editWork } from "../../../api-wrapper/works/editWork";
import {
  getImprovementsSelector,
  getPropertyIdSelector
} from "../../selectors";
import { getWorkById } from "../../../api-wrapper/works/getWorkById";
import UploadFile from "../attachments/UploadFile";
import {
  attachmentsInitial,
  expensesInitial, initialContactData,
  inventoriesInitial,
  notesInitial,
} from "../helper";
import { addRelation } from "../../../api-wrapper/relation/addRelation";
import {Guid} from "guid-typescript";
import {emptyGuid} from "../../screens/helpers";
import {ReactComponent as ECOIcon} from "../../../images/ECO.svg";
import {TUpcomingTask} from "../../../api-wrapper/upcomingTasks/getUpcomingTasks";
import AttachmentsOnDrawer from "../attachments/AttachmentsOnDrawer";
import {getAllImprovements} from "../../actions/improvements";
import {useHistory} from "react-router-dom";
import {getEntityTypes} from "../../../api-wrapper/relation/getEntityTypes";
import moment from "moment";
import {linkToRecord} from "../spaces/AddNewRecordDrawer";
import {
  selectStyle
} from "../spaces/EditSpaceDrawer";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";
import {
  Accent,
  DrawerTitle,
  FormWrapper,
  FrequencyWrapper,
  Label,
  SpaceBetweenWrapper,
  TextButtonWrapper
} from "../planner/UpdateTaskDrawer";
import {ReactComponent as CalendarIcon} from "../../../images/planner/CalendarIcon.svg";
import {ReactComponent as RecurrenceIcon} from "../../../images/planner/RecurrenceIcon.svg";
import TaskCalendar from "../planner/TaskCalendar";
import {Overlay} from "../../screens/Home";
import {
  ActionButtonGreen,
  ActionButtonWhite, CheckboxStyled, CircleIcon,
  DrawerFooter, DrawerHeader,
  EditDrawerStyled,
  FormStyled
} from "../../screens/components";
import {ReactComponent as Checkmark} from "../../../images/planner/Checkmark.svg";
import {ActionButton} from "../planner/ViewTaskDrawer";
import {ReactComponent as CheckmarkIcon} from "../../../images/planner/CheckmarkIcon.svg";

const { TextArea } = Input;

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  work: { data: TAddWorkResponse } | null;
  setEditingWorkData: (
    arg01: TAddWorkResponse | { accessToken: any } | null
  ) => void;
  width?: number | null;
  parentContactId?: Guid | null;
  parentRecordId?: Guid | null;
  parentRecordType?: string;
  refreshInventoryParent?: (arg1: Guid, arg2?: string) => void;
  highlightNote?: Guid | null;
  highlightAttachment?: Guid | null;
  icon?: ReactNode | null;
  drawerTitle?: string | null;
  recordToRemove?: Guid | null;
  refreshParent?: () => void | null;
  reminderToRecord?: TUpcomingTask | null;
  parentImprovement?: any;
  parentDrawer?: any;
  toggleParentDrawer?: (arg01: boolean) => void;
  isNewWork?: boolean | null;
  allowSave?: boolean | null;
};

const WorkDrawer = (props: Props) => {
  const {
    isOpen,
    parentRecordId,
    parentRecordType,
    parentContactId,
    refreshInventoryParent,
    toggleDrawerOpen,
    work,
    setEditingWorkData,
    highlightAttachment,
    refreshParent,
    parentDrawer,
    isNewWork,
    allowSave,
  } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  const selectFilesRef = useRef(null);
  const fileListRef = useRef(null);

  const propertyId = useSelector(getPropertyIdSelector).value;
  const improvementsConfiguration = useSelector(getImprovementsSelector);

  const [recurring, setRecurring] = useState("None");
  const [complete, setComplete] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [workName, setWorkName] = useState("");
  const [selectedDueDate, setSelectedDueDate] = useState(moment());
  const [selectedCompletedDate, setSelectedCompletedDate] = useState(moment());
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [showFileUploadList, setShowFileUploadList] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Set<Guid>>(new Set());
  const [justSaved, setJustSaved] = useState(false);
  const [workTypes, setWorkTypes] = useState<any>([]);
  const [showRecurrenceFields, toggleRecurrenceFields] = useState(false);
  const [hasDateBeenPicked, setPickedDate] = useState(false);
  const [workDetails, setWorkDetails] = useState<any>({
    workId: emptyGuid,
    workNotes: notesInitial,
    contacts: initialContactData,
    attachments: attachmentsInitial,
    expenses: expensesInitial,
    inventories: inventoriesInitial,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  })

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    if (!isOpen)
      return;

    getEntityTypes(propertyId, "work").then((res: any) =>
      res && setWorkTypes(res.filter((type: any) => type.name !== "Expenses"))
    )
  }, [propertyId, isOpen])

  useEffect(() => {
    if (allowSave)
      setFormFieldsChanged(true);
  }, [allowSave]);

  const handleManageWork = (e: {
    name: string;
    recurring: string;
    typeOfWork: string;
    notes: string;
  }) => {
    const { name, typeOfWork, recurring, notes } = e;
    if (work && work.data.workId && !Object.is(work.data.workId, emptyGuid)) {
      editWork({
        propertyId,
        workId: work.data.workId,
        name,
        dueDate: selectedDueDate.format("YYYY-MM-DD"),
        completedDate: complete ? selectedCompletedDate.format("YYYY-MM-DD") : null,
        recurring,
        notes,
        type: typeOfWork,
        isComplete: complete,
        tags: []
      }).then((res: any) => {
        toggleDrawerOpen(false);
        setFormFieldsChanged(false);
        setJustSaved(true);
        if (parentRecordId && parentRecordType) linkToRecord(propertyId, parentRecordId, parentRecordType, res.workId, "work", dispatch)
        if (refreshInventoryParent) refreshInventoryParent(work.data.workId);
        if (refreshParent) refreshParent();
        form.resetFields();
        dispatch(getAllWorks(work.data.propertyId));
      });
    }
    else {
      setSaveDisabled(true);
      addWork({
        propertyId,
        name,
        type: typeOfWork,
        dueDate: selectedDueDate.format("YYYY-MM-DD"),
        completedDate: complete ? selectedCompletedDate.format("YYYY-MM-DD") : null,
        recurring: recurring,
        isComplete: complete,
      }).then((res: any) => {
        refreshInventoryParent &&
          res &&
          res.data &&
          refreshInventoryParent(res.data.workId);
        setSaveDisabled(false);
        setFormFieldsChanged(false);
        form.resetFields();
        setEditingWorkData(res);
        dispatch(getAllWorks(propertyId));
        if (refreshInventoryParent) {
          toggleDrawerOpen(false);
          setEditingWorkData(null);
        }
        if (parentContactId) {
          addRelation({
            propertyId,
            parentId: res.data.workId,
            parentType: "work",
            childId: parentContactId,
            childType: "contact"}).then(() => refreshParent && refreshParent())
        } else {
          if (refreshParent) refreshParent();
        }
        toggleDrawerOpen(false);
      });
    }
  };
  const initialValues = () => {
    if (work) {
      const { name, type, notes } = work.data;
      return {
        name,
        typeOfWork: type,
        notes,
      };
    } else { return {} }
  };

  useEffect(() => {
    if (showFileUploadList && selectFilesRef && selectFilesRef.current) {
      // @ts-ignore
      selectFilesRef.current.scrollIntoView();
    } else if (!showFileUploadList && fileListRef && fileListRef.current) {
      // @ts-ignore
      fileListRef.current.scrollIntoView();
    }
  }, [showFileUploadList])

  useEffect(() => {
    if (work) {
      form.resetFields();
      setWorkName(work.data.name);
      setComplete(work.data.isComplete);
      setRecurring(work.data.recurring ?? "None");
      work.data.name ? setPickedDate(true) : setPickedDate(false);
      work.data.completedDate ? setSelectedCompletedDate(moment(work.data.completedDate)) : setSelectedCompletedDate(moment());
      work.data.dueDate ? setSelectedDueDate(moment(work.data.dueDate)) : setSelectedDueDate(moment());
      (work.data.recurring === "None" || !work.data.recurring) ? toggleRecurrenceFields(false) : toggleRecurrenceFields(true);
    } else {
      form.resetFields();
      setPickedDate(false);
      setWorkName("");
      setSelectedDueDate(moment());
      setSelectedCompletedDate(moment());
      setComplete(false);
      toggleRecurrenceFields(false);
    }
    allowSave ? setFormFieldsChanged(true) : setFormFieldsChanged(false);
    setJustSaved(false);
  }, [form, work, isOpen]);

  useEffect(() => {
    if (work && work.data.workId && !Object.is(work.data.workId, emptyGuid)) {
      getWorkById(propertyId, work.data.workId).then((res) => {
        setWorkDetails(res);
      });
    }
  }, [work]);

  const refreshWork = () => {
    if (refreshParent) refreshParent();
    work &&
      !Object.is(work.data.workId, emptyGuid) &&
      getWorkById(propertyId, work.data.workId).then((res) => {
        setWorkDetails(res);
      });
  };

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        toggleDrawerOpen(false);
        if (parentDrawer === "improvement-create") {
          work && removeWork(work.data.propertyId, work.data.workId).then(() => {
            work && dispatch(getAllWorks(work.data.propertyId))})
        }
        setEditingWorkData(null);
        form.resetFields();
        if (work && _.isEmpty(work.data.name)) {
          work && removeWork(work.data.propertyId, work.data.workId).then(
            () => {
              work && dispatch(getAllWorks(work.data.propertyId));
            }
          )
        } else {
          work && dispatch(getAllWorks(work.data.propertyId));
        }
      },
    });
  }

  const relatedImprovement = improvementsConfiguration.content.improvements
    .filter(improvement => improvement.id === work?.data.relatedImprovementId)[0]

  const handleCancel = () => {
    if (formFieldsChanged) {
      confirmCloseUnsaved();
      return;
    }
    if ((work && _.isEmpty(work.data.name)) || (!justSaved && parentDrawer === "improvement-create" && isNewWork)) {
      work && removeWork(work.data.propertyId, work.data.workId).then(() => {
        work && dispatch(getAllWorks(work.data.propertyId));
        if (parentDrawer === "improvement") dispatch(getAllImprovements(propertyId))})
    } else {
      work && dispatch(getAllWorks(work.data.propertyId));
    }
    toggleDrawerOpen(false);
    setEditingWorkData(null);
    form.resetFields();
  }

  const completedSwitch = () => (
    <div style={{margin: windowWidth > 768 ? "1.5rem 0" : "1rem 0", display: "flex"}}>
      <ActionButton className={"gray"} onClick={() => setComplete(!complete)}>
        <CheckboxStyled className={complete ? "checked small" : "small"}><Checkmark/></CheckboxStyled>
        {windowWidth > 768 ? "Is work complete?" : "Complete?"}
        {/*{complete && <Dropdown placement="bottomCenter" overlay={<Overlay>*/}
        {/*  If you mark a completed work as incomplete, the due date will be automatically set as today's date.*/}
        {/*</Overlay>}><HelpIcon/></Dropdown>}*/}
      </ActionButton>
    </div>
  )

  return (<>
    <AttachmentsOnDrawer
      selectedFiles={selectedFiles}
      setSelectedFiles={setSelectedFiles}
      alreadyAttachedFiles={workDetails.attachments}
      isOpen={showFileUploadList}
      toggleDrawerOpen={setShowFileUploadList}
      icon={<WorkIcon className={"works-theme-icon"}/>}
      drawerTitle={workName ? "Works > Add Attachment" : `${workName} > Add Attachment`}
    >
      <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
        <AttachButtonStyled onClick={() => {
          selectedFiles.forEach(selectedFile => {
            setShowFileUploadList(false)
            work && addRelation({propertyId, parentId: work.data.workId,
              childId: selectedFile, parentType: "work", childType: "attachment"}).then(() => refreshWork())})
          setSelectedFiles(new Set());
        }}>Attach</AttachButtonStyled>
      </div>
    </AttachmentsOnDrawer>

    <EditDrawerStyled
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "100%" : "85%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      visible={isOpen}
      onClose={() => handleCancel()}
      title={<DrawerHeader className={"task-header"} style={{borderBottom: windowWidth > 768 ? "none" : "1px solid #E6E6E6"}}>
        <DrawerTitle>
          <WorkIcon /> Work project
        </DrawerTitle>
        {windowWidth > 768 && <CircleIcon onClick={() => handleCancel()}><CloseIcon/></CircleIcon>}
        {windowWidth <= 768 && completedSwitch()}
      </DrawerHeader>}
    >
      {(!hasDateBeenPicked && work) ? <TaskCalendar
        selectedDate={complete ? selectedCompletedDate : selectedDueDate}
        setSelectedDate={complete ? setSelectedCompletedDate : setSelectedDueDate}
        setPickedDate={setPickedDate}
        handleCancel={handleCancel}
        arePassedDatesAllowed={true}
      /> : <FormStyled
        form={form}
        name="manageWork"
        layout="vertical"
        size={"large"}
        // @ts-ignore
        onFinish={handleManageWork}
        initialValues={initialValues()}
        onFieldsChange={() => setFormFieldsChanged(true)}
        style={{width: "100%"}}
      >
        <FormWrapper>
          <Form.Item key={"name"} name={"name"} rules={[{required: true, message: "Project title is required"}]}>
            <Input
              autoComplete={"off"}
              className={"title-input"}
              placeholder={`Enter work title*`}
              onChange={(e) => setWorkName(e.target.value)}
            />
          </Form.Item>
          {windowWidth > 768 && completedSwitch()}
          <SpaceBetweenWrapper className={showRecurrenceFields ? "" : "underlined"}>
            <TextButtonWrapper onClick={() => setPickedDate(false)}>
              <CalendarIcon />
              {complete ? selectedCompletedDate.format("DD MMM YYYY") : selectedDueDate.format("DD MMM YYYY")}
              <Dropdown placement="bottomCenter" overlay={<Overlay>
                  <div>Due date</div>
                  <div>By adding a due date Livlet will automatically add the project to a reminder,
                    allowing you to more easily keep track of your upcoming tasks.</div>
                </Overlay>}>
                <HelpIcon />
              </Dropdown>
            </TextButtonWrapper>
            {!showRecurrenceFields && <TextButtonWrapper className={"green"} onClick={() => toggleRecurrenceFields(true)}>
                <RecurrenceIcon/>Add recurrence?
            </TextButtonWrapper>}
          </SpaceBetweenWrapper>

          {showRecurrenceFields && <div style={{
            display: "flex", alignItems: "center", gap: "0.75rem",
            borderBottom: windowWidth > 768 ? "none" : "1px solid #E6E6E6", marginBottom: windowWidth > 768 ? 0 : "1rem"
          }}>
            <Label style={{marginBottom: "1.5rem"}}><Accent>{f(messages.recurring)}</Accent></Label>
            <Dropdown placement="bottomRight" overlay={<Overlay>
              <div>Recurring tasks</div>
              <div>When you complete a recurring project a new project will automatically be created for you due based on the
                recurring period and the date you completed the project.</div></Overlay>}>
                <HelpIcon style={{marginBottom: "1.5rem"}} />
            </Dropdown>
            <FrequencyWrapper>
              <Form.Item
                key={"recurring"}
                name={"recurring"}
                className={"no-underline"}
              ><Select
                defaultValue={recurring}
                getPopupContainer={trigger => trigger.parentElement}
                placeholder={"Select period"}
                bordered={false}
                style={{...selectStyle, border: "1px solid #00B74F", marginBottom: 0, minWidth: "12rem"}}
                onChange={(val) => setRecurring(val)}
              >
                <Select.Option key={"None"} value={"None"}>None</Select.Option>
                <Select.Option key={"Weekly"} value={"Weekly"}>Weekly</Select.Option>
                <Select.Option key={"Monthly"} value={"Monthly"}>Monthly</Select.Option>
                <Select.Option key={"Quarterly"} value={"Quarterly"}>Quarterly</Select.Option>
                <Select.Option key={"OnceIn4Months"} value={"OnceIn4Months"}>Once in 4 months</Select.Option>
                <Select.Option key={"TwiceAYear"} value={"TwiceAYear"}>Twice a year</Select.Option>
                <Select.Option key={"Yearly"} value={"Yearly"}>Yearly</Select.Option>
                <Select.Option key={"OnceIn3Years"} value={"OnceIn3Years"}>Once in 3 years</Select.Option>
              </Select>
              </Form.Item>
            </FrequencyWrapper>
          </div>}

          <Label>Type of work <Accent>*</Accent></Label>
          <Form.Item key={"typeOfWork"} name={"typeOfWork"} rules={[{required: true, message: f(messages.validationTypeOfWork)}]}>
            <Select
                getPopupContainer={trigger => trigger.parentElement}
                placeholder={"Select category"}
                bordered={false}
                style={{...selectStyle}}
            >
              {workTypes && workTypes.map((type: any) => <Select.Option key={type.name} value={type.name}>{type.name}</Select.Option>)}
            </Select>
          </Form.Item>

          <Label>Notes <Accent>(optional)</Accent></Label>
          <Form.Item key={"notes"} name={"notes"} className={"no-underline textarea"}>
            <TextArea
              style={{...selectStyle, maxHeight:"auto", resize:"vertical"}}
              maxLength={2500}
              rows={3}
              placeholder={"Enter text"}
            />
          </Form.Item>

          {(work && !_.isNil(work.data.relatedImprovementId) && relatedImprovement) && (
            <div style={{backgroundColor: "#EFFDF7", borderRadius: "16px", padding: "1rem", display: "flex"}}>
              <ECOIcon className={"improve-property-theme-icon"} style={{flexShrink: 0}} />
              <span style={{ paddingLeft: "0.5rem" }}>
              The project is based on the Livlet improvement{" "}
                <span style={{color:"black", textDecoration:"underline"}} onClick={() => {
                  toggleDrawerOpen(false);
                  history.push(`/guidance/improvement/${relatedImprovement.category}/${relatedImprovement.id}`)
                }}>
                {relatedImprovement.name}</span>.
              Complete it to improve your Livlet rating.
            </span>
            </div>
          )}

          {work && workDetails.attachments !== attachmentsInitial && (
            <div ref={fileListRef}>
              <UploadFile
                parentType={"work"}
                parentId={work.data.workId}
                propertyId={propertyId}
                uploadImmediately={true}
                singleAttachment={false}
                fileList={workDetails.attachments}
                refreshParent={refreshWork}
                highlightAttachment={highlightAttachment}
                setShowFileUploadList={setShowFileUploadList}
              />
            </div>
          )}
        </FormWrapper>

        <DrawerFooter className={"fake-footer desktop-footer"}>
          <ActionButtonWhite onClick={() => handleCancel()}>Cancel</ActionButtonWhite>
          <ActionButtonGreen htmlType={"submit"}>Save</ActionButtonGreen>
        </DrawerFooter>
        <DrawerFooter className={"fake-footer task-footer mobile-footer"}>
          <CircleIcon onClick={() => handleCancel()}><CloseIcon/></CircleIcon>
          <CircleIcon className={"green-fill"} htmlType={"submit"}><CheckmarkIcon /></CircleIcon>
        </DrawerFooter>
      </FormStyled>}
    </EditDrawerStyled>
    </>
  );
};

export const SaveButton = styled(Button)`
  display: flex;
  align-items: center;
  
  display: -webkit-flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  
  border-radius: 6px;
  padding: 0.875rem 2.75rem;
`;

export const TotalCostBox = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  color: #002a43;
  margin-top: 0.5rem;
  text-transform: uppercase;
  font-size: 0.8rem;
`;

export const TotalCostTag = styled.div`
  color: #002a43;
  align-items: flex-start;
  padding: 0.4rem;
  padding-top: 0.3rem;
  margin: 0.7rem;
  border-radius: 0.25rem;
  border: 0;
  font-weight: 700;
  font-size: 1.9rem;
  line-height: 20px;
  white-space: nowrap;
`;

export const WorksIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;

  & > *:last-child {
    margin-left: 1.5rem;
  }
`;

export const ButtonStyled = styled(Button)`
  border: 1px solid rgb(33, 39, 43, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #ebf9ff;
  color: #21272b;
  font-weight: 600;
`;

export const SaveButtonWrapper = styled.div`
  display: flex;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  z-index: 1;
  bottom: 0;
  right: 0;
  margin-top: 5rem;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #fff;
`;

export const ButtonStyledLists = styled(ButtonStyled)`
  padding: 1.5rem 2rem;
  background-color: #fff;
`;

export const AttachButtonStyled = styled(ButtonStyled)`
  padding: 1.5rem 2rem;
  background-color: #2a80e1;
  border-color: #2a80e1;
  color: #fff;
`;

export const NoItemsText = styled.span`
  padding: 2em;
  font-family: Inter;
  font-size: 1rem;
  font-weight: normal;
  color: #6b7185;
`;

export const CloseButton = styled(ButtonStyled)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  
  display: -webkit-flex;
  -webkit-justify-content: space-around;
  -webkit-align-items: center;
  
  -ms-justify-content: space-around;
  -ms-align-items: center;

  border-color: #dae0e6 !important;
  padding: 1rem;
  
  :hover {
    background-color: #ebf9ff !important;
    border: 1px solid #2a80e1 !important;
  }
  
  @media (max-width: 1024px) {
    border-radius: 100%;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    
    svg path {
      width: 0.625rem;
      height: 0.625rem;
    }
  }
`;

export const PlusButtonStyledLists = styled.span`
  :hover {
    svg path {
      stroke: #2a80e1;
    }
  }
`;

// export const ToolkitButton = styled(Button)`
//   display: inline;
//   padding: 0 !important;
//   width: auto !important;
//   height: auto !important;
//   line-height: initial !important;
//   background: #2a80e1;
//   border: 0;
//   :hover {
//     background: #2a80e1;
//   }
//   :focus {
//     background: #2a80e1;
//   }
// `;

export const FormToolkitWrapper = styled.div`
  display: flex;
  position: sticky;
  z-index: 1;
  position: -webkit-sticky; /* Safari */
  bottom: 0;
  padding-top: 1rem;
  align-items: center;
  justify-content: center;
`;

// const FormToolkitListButtons = styled.span`
//   display: flex;
//   justify-content: space-evenly;
//   width: 19rem;
//   height: 2.6rem;
//
//   background: #2a80e1;
//   border: 1px solid rgb(33, 39, 43, 0.25);
//   border-radius: 6px;
//
//   svg path {
//     stroke: white;
//   }
// `;

// export const FormToolkitListButtonsDesktop = styled(FormToolkitListButtons)`
//   @media (max-width: 1024px) {
//     display: none;
//   }
// `;
//
// export const FormToolkitListButtonsMobile = styled(FormToolkitListButtons)`
//   @media (min-width: 1024px) {
//     display: none;
//   }
// `;

export const FormToolkitDivider = styled.span`
  height: 1.8rem;
  margin-top: 0.3rem;
  border-left: 1px solid #dae0e6;
`;

export const Separator = styled.hr`
  margin-top: 1.5rem;
  border: 1px solid #dae0e6;
`;

export const TextAreaStyled = styled(TextArea)`
  width: 100%;
  border: 1px solid #DAE0E6;
  box-sizing: border-box;
  border-radius: 6px;
  color: rgb(107,113,133);
  textarea {
    border: none !important;
    box-shadow: none !important;
  }
  textarea:focus {
    border: none!important;
  }
`;

export default WorkDrawer;
