import React from "react";
import { Redirect, Route } from "react-router-dom";

import { getAccessToken } from "../../api-wrapper/auth/auth";

// @ts-ignore
export const PublicRoute = ({component: Component, render: Render, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !getAccessToken() ?(
        Render ? (
          Render(props)
        ) : Component ? (
          <Component {...props} />
        ) : null
      ) : (
        <Redirect
          to={{ pathname: "/", state: { from: props.location } }}
        />
      )
    }
  />
);
