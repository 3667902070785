import React, {useEffect, useState} from "react";

import {ReactComponent as ContactRecord} from "../../../images/records/ContactRecord.svg";
import {ReactComponent as LinkedIcon} from "../../../images/records/LinkedIcon.svg";
import {ReactComponent as NotesIcon} from "../../../images/records/NotesIcon.svg";
import {useDispatch, useSelector} from "react-redux";
import {getContactsSelector, getPropertyIdSelector} from "../../selectors";
import {CardContentsItem, CardContentsWrapper, CardTitle, PlusIconWrapper, RecordsCard} from "./SpaceRecordsCard";
import {useHistory} from "react-router-dom";
import {ReactComponent as PlusIcon} from "../../../images/records/PlusIcon.svg";
import {addContact} from "../../../api-wrapper/contacts/addContact";
import {getAllContacts} from "../../actions/contacts";

type Props = {
  toggleDrawerOpen: (arg: boolean) => void;
  setEditingData: (arg: any) => void;
}

const ContactRecordsCard = (props: Props) => {
  const {toggleDrawerOpen, setEditingData} = props;

  const contacts = useSelector(getContactsSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const history = useHistory();
  const dispatch = useDispatch();

  const [contactCount, setContactCount] = useState(0);
  const [linkCount, setLinkCount] = useState(0);

  useEffect(() => {
    setContactCount(contacts.content.filter(contact => contact.name && contact.contactType !== "Payee").length)
    setLinkCount(contacts.content.filter((contact) =>
      contact.name && contact.contactType !== "Payee" && (contact.expensesCount || contact.worksCount)).length)
  }, [propertyId, contacts])

  const addNewContact = () => {
    addContact({
      propertyId,
      request: {},
      contactImage: null
    }).then((res) => {
      setEditingData(res);
      dispatch(getAllContacts(propertyId));
      toggleDrawerOpen(true);
    });
  }

  return <RecordsCard
    className={"contact-records"}
    onClick={() => history.push("/contacts")}
  >
    <CardTitle>
      <ContactRecord /> Contacts
      <PlusIconWrapper onClick={(e) => {
        e.stopPropagation()
        addNewContact()
      }}><PlusIcon /></PlusIconWrapper>
    </CardTitle>
    <CardContentsWrapper>
      <CardContentsItem className={"fill"}>
        <span className={"item-count"}><NotesIcon /> {contactCount}</span> Contacts
      </CardContentsItem>
      {/*<CardContentsItem className={"fill"}>*/}
      {/*  <span className={"item-count"}><LinkedIcon /> {linkCount}</span> Linked*/}
      {/*</CardContentsItem>*/}
    </CardContentsWrapper>
  </RecordsCard>
}

export default ContactRecordsCard