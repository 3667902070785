import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {Guid} from "guid-typescript";
import {getPropertiesInitAction} from "./properties";

export const getPropertyIdInitAction = createAction(
  "properties/GET_PROPERTYID_INIT"
)();
export const setPropertyIdAction = createAction(
  "propertyId/SET_PROPERTY_ID"
)<Guid>();

export type TGetPropertyIdInitAction = ActionType<
  typeof getPropertyIdInitAction
  >;
export type TSetPropertyIdAction = ActionType<
  typeof setPropertyIdAction
>;

export type TGetPropertyIdActions =
  | TSetPropertyIdAction
  | TGetPropertyIdInitAction;

export type TGetPropertyIdThunkActionType = (propertyId: Guid) => ThunkAction<
  void,
  TRootState,
  null,
  TGetPropertyIdActions
>;

export const setPropertyId: TGetPropertyIdThunkActionType = (propertyId: Guid) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  return dispatch(setPropertyIdAction(propertyId));
};
