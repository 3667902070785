import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import _ from "lodash";
import {Guid} from "guid-typescript";
import {TGetMaintenancesResponse} from "../../api-wrapper/maintenance/getMaintenances";
import {getOnboardingMaintenances} from "../../api-wrapper/maintenance/getOnboardingMaintenances";

export const getOnboardingMaintenancesInitAction = createAction("maintenances/GET_ONBOARDING_MAINTENANCES_INIT")();
export const getOnboardingMaintenancesSuccessAction = createAction(
  "maintenances/GET_ONBOARDING_MAINTENANCES_SUCCESS"
)<TGetMaintenancesResponse>();
export const getOnboardingMaintenancesFailureAction = createAction("maintenances/GET_ONBOARDING_MAINTENANCES_FAILURE")();

export type TGetOnboardingMaintenancesInitAction = ActionType<typeof getOnboardingMaintenancesInitAction>;
export type TGetOnboardingMaintenancesSuccessAction = ActionType<typeof getOnboardingMaintenancesSuccessAction>;
export type TGetOnboardingMaintenancesFailureAction = ActionType<typeof getOnboardingMaintenancesFailureAction>;

export type TGetOnboardingMaintenancesActions =
  | TGetOnboardingMaintenancesInitAction
  | TGetOnboardingMaintenancesSuccessAction
  | TGetOnboardingMaintenancesFailureAction;

export type TGetOnboardingMaintenancesThunkActionType = (propertyId: Guid) => ThunkAction<void, TRootState, null, TGetOnboardingMaintenancesActions>;

export const getAllOnboardingMaintenances: TGetOnboardingMaintenancesThunkActionType = (propertyId: Guid) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getOnboardingMaintenancesInitAction());
  return getOnboardingMaintenances(propertyId)
    .then((payload) => {
      return dispatch(getOnboardingMaintenancesSuccessAction(_.orderBy(payload, ['title'], ['asc'])));
    })
    .catch(() => dispatch(getOnboardingMaintenancesFailureAction()));
};
