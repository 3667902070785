import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TReferFriendsRequest} from "./referFriends";

export const referFriendsPreview = (payload: TReferFriendsRequest) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/referFriendsPreview`
  );

  return apiPost<any, TReferFriendsRequest>(
    url, payload
  );
};
