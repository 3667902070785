import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TGetMaintenancesResponse} from "./getMaintenances";

export const getOnboardingMaintenances = (propertyId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/alerts/recommendedAndStandard`);

  return apiGet<TGetMaintenancesResponse | []>(url, {}, propertyId);
};
