import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { useIntl } from "react-intl";
import messages from "./messages";
import {
  FormWrapper,
  ImageWrapper,
} from "./styled";
import {useHistory, useLocation} from "react-router-dom";
import moment from "moment";
import decodeJwt from "jwt-decode";
import {reset} from "../../api-wrapper/auth/reset";
import {activate} from "../../api-wrapper/auth/activate";

const ActivationPage = () => {
  let history = useHistory();
  const { formatMessage: f } = useIntl();
  const [expired, setExpired] = useState(false)
  const [email, setEmail] = useState("")
  const [resentEmailMessage, setResentEmailMessage] = useState(false)

  const useQuery = () => {
    let query = new URLSearchParams(useLocation().search);
    return query.get("auth_token")
  }

  const authToken = useQuery()

  useEffect(() => {
    if (authToken) {
      const { exp, email } = decodeJwt<{
        exp: number;
        email: string;
      }>(authToken);

      let expirationDate = new Date(exp*1000)
      let currentDate = moment()
      setExpired(currentDate.isAfter(moment(expirationDate)))
      setEmail(email)

      !expired && activate(authToken).then()
    }
  }, [authToken])


  return (
    <Main>
      <FormWrapper>
        <FormHeader>
          <Logo />
        </FormHeader>
        <TitlesWrapper>
          <Title>{expired ? f(messages.activationFailureTitle) : f(messages.activationSuccessTitle)}</Title>
          <Subtitle>
            {expired ? f(messages.activationFailureSubtitle, {
              activationLink: (<span style={{color: '#1890ff'}} onClick={() => {
                reset(email).then(() => setResentEmailMessage(true))
              }}>here</span>)
              }) : <>
                <p>{f(messages.activationSuccessSubtitle)}</p>
                <p>{f(messages.activationLoginSubtitle, {
                loginLink: (<a onClick={() => history.push(`/login?email=${email}`)}>here</a>)
                })}</p>
              </>
            }
          </Subtitle>
          {resentEmailMessage && <Subtitle>
            {f(messages.resentEmailMessage)}
          </Subtitle>}
        </TitlesWrapper>
      </FormWrapper>
      <ImageWrapper>
        <LoginPageImageStyled />
        <TextOnImage>{f(messages.imageText)}</TextOnImage>
      </ImageWrapper>
    </Main>
  );
};

const FormHeader = styled.div`
  @media (max-width: 1024px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 1rem;
  }

  @media (min-height: 720px) {
    height: 15vh;
  }

  padding: 2rem 0 2rem;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
`;

const LoginPageImageStyledInner = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1024px) {
    width: 100%;
    height: 100vh;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(/images/registrationImage.jpg);
    margin: 0 auto;
    position: relative;
  }
`;

const LoginPageImageStyled = styled(LoginPageImageStyledInner)`
  @media (min-width: 1024px) {
    min-height: 720px;
  }
`;

const Main = styled.main`
  display: flex;
  overflow-y: visible;
  height: 100vh;
  max-height: 100vh;
  justify-content: space-between;
  background-color: white;
`;

const Logo = styled.div`
  background-repeat: no-repeat;

  @media (max-width: 1024px) {
    width: 65%;
    height: 2rem;
    background-size: 115px 31px;
  }
  @media (min-width: 1024px) {
    width: 50%;
    height: 2.5rem;
    background-size: 134.5px 36.5px;
  }

  @media (min-width: 1024px) {
    width: 50%;
    height: 3rem;
    background-size: 154px 42px;
  }
  background-image: url(/images/LivletLogoNew.png);
`;

const TitlesWrapper = styled.div`
  @media (min-width: 1024px) and (min-height: 720px) {
    height: 15vh;
  }
`;

const Title = styled.h4`
  font-weight: 700;
  font-size: 1.625rem;
  color: #002a43;
`;

const Subtitle = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: #6b7185;
`;

const TextOnImage = styled.h3`
  font-weight: 700;
  color: #ffffff;
  position: absolute;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1024px) {
    font-size: 2.1rem;
    width: 350px;
    top: 0.5rem;
    right: 0.5rem;
  }
`;

export default ActivationPage;
