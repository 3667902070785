import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type CrimeEntry = {
  crimeType: string;
  crimeDate: string;
  lastMonthCrimesCount: number;
  previousMonthCrimesCount: number;
  distanceMeters: number;
};

export type TGetCrimeDataResponse = Array<CrimeEntry>;

export const getCrimeData = (propertyId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/crimes`);

  return apiGet<TGetCrimeDataResponse>(url, {}, propertyId);
};
