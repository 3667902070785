import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import {Button, Checkbox, Form, Input, InputNumber, Popover, Spin, Tooltip} from "antd";
import EnvConfig from "../../config";

import messages from "./messages";
import {emailRegex, passwordTest, pushStepToGoogle,} from "./helpers";
import "../../index.css";
import {signUp, signUpAcceptInvite, signUpAttempt, signUpCodeConfirmation} from "../actions/signUp";
import { useDispatch, useSelector } from "react-redux";
import { TFetchingStatus } from "../../helpers";
import { authSelector } from "../selectors";
import {loginClean, signInSocial} from "../actions/logIn";
import {
  FormWrapper,
  ImageWrapper,
} from "./styled";
import {useHistory, useLocation} from "react-router-dom";
import {passwordResult, resultIcon} from "../components/helper";
import {ReactComponent as AlertIcon} from "../../images/AlertRed.svg";
import {Guid} from "guid-typescript";
import decodeJwt from "jwt-decode";
import moment from "moment";
import {useCookies} from "react-cookie";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import {ReactComponent as LoginFacebook} from "../../images/LoginFacebook.svg";
import {ReactComponent as RegistrationCodeExample} from "../../images/RegistrationCodeExample.svg";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {clearCurrentPropertyId} from "../../localStorage";
import _ from "lodash";
import {LoadingOutlined} from "@ant-design/icons";
import { ErrorWrapper } from "./LoginPage";

const SimpleRegistration = () => {
  const [form] = Form.useForm();
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  let history = useHistory();
  const auth = useSelector(authSelector);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [codeSignRefs, setCodeSignRefs] = useState<any>([useRef(null),useRef(null),useRef(null),useRef(null),useRef(null),useRef(null)]);
  const [codeSignValues, setCodeSignValues] = useState<any>([]);
  const [codeIncorrect, setCodeIncorrect] = useState(false);
  const [codeSubmitRef, setCodeSubmitRef] = useState<any>(useRef(null));
  const [isRegistering, toggleRegistering] = useState(false);
  const [isSubmitting, toggleSubmitting] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(['livlet-referral']);
  const [referral, setReferral] = useState<string|null>(null);

  const [stepNumber, setStepNumber] = useState(1);

  const [passwordStrengthResult, setPasswordStrengthResult] = useState<any>({})
  const [emailError, setEmailError] = useState(false);
  const [unknownError, setUnknownError] = useState(false);
  const [acceptingInvite, setAcceptingInvite] = useState(false);
  const [inviteToken, setInviteToken] = useState<string|null>();

  const [isPartnerWebsite, togglePartnerWebsite] = useState(false);
  const [isMcLarenWebsite, toggleMcLarenWebsite] = useState(false);

  const [firstStepRegularData, setFirstStepRegularData] = useState({
    email: "",
    password: "",
    confirmationPassword: "",
    termsAndConditions: true,
    marketingEmails: false
  });

  const responseFacebook = (response: any) => {
    pushStepToGoogle('register-facebook-start');

    dispatch(signInSocial({
      externalSourceName: "facebook",
      externalId: response.id,
      externalSourceToken: response.accessToken,
      name: response.name,
      email: response.email,
    }))
  }

  const responseGoogle = (response: any) => {
    pushStepToGoogle('register-google-start');

    dispatch(signInSocial({
      externalSourceName: "google",
      externalId: response.googleId,
      externalSourceToken: response.accessToken,
      name: response.profileObj.name,
      email: response.profileObj.email,
    }))
  }

  const handleFirstStepRegularOnFinish = async (data: {
    email: string;
    password: string;
    confirmationPassword: string;
    termsAndConditions: boolean;
    marketingEmails: boolean;
    partnerCode: string;
  }) => {
    if (passwordStrengthResult.final) {
      toggleRegistering(true);
      setFirstStepRegularData({
        ...data,
        marketingEmails: data.marketingEmails ?? false
      });

      let token;
      if (executeRecaptcha) {
        token = await executeRecaptcha('yourAction');
      }
      // const token = 'ALBeT2yLFzj4OpIckac'

      clearCurrentPropertyId();
      setCodeIncorrect(false);
      if (acceptingInvite) {
        dispatch(
          signUpAcceptInvite({
            email: data.email,
            password: data.password,
            marketingEmails: data.marketingEmails,
            captchaToken: token,
            token: inviteToken ? inviteToken : "",
          })
        )
      } else {
        dispatch(
          signUpAttempt({
            email: data.email,
            password: data.password,
            marketingEmails: data.marketingEmails,
            token,
            partnerCode: data.partnerCode,
            website: window.location.hostname.includes("mcl") ? window.location.hostname : null,
          })
        )
      }
    }
  }

  const handleActivateAccount = async () => {
    for (let i = 0; i < 6; i++) {
      if (_.isUndefined(codeSignValues[i]) || _.isNil(codeSignValues[i])) {
        setCodeIncorrect(true);
        return;
      }
    }
    setCodeIncorrect(false);

    const token = codeSignValues.join("");

    dispatch(
      signUpCodeConfirmation({
        token,
        referral
      })
    );
  }

  // step 1: social media flow
  const useQuery = (queryType: string) => {
    let query = new URLSearchParams(useLocation().search);
    switch (queryType) {
      case 'externalSourceName':
        return query.get("externalSourceName");
      case 'externalId':
        return query.get("externalId");
      case 'externalSourceToken':
        return query.get("externalSourceToken");
      case 'name':
        return query.get("name");
      case 'email':
        return query.get("email");
      case 'invite_token':
        return query.get("invite_token");
      default:
        return ""
    }
  };
  const externalSourceName = useQuery('externalSourceName')
  const externalId = useQuery('externalId')
  const externalSourceToken = useQuery('externalSourceToken')
  const name = useQuery("name")
  const email = useQuery("email")
  const invite_token = useQuery("invite_token");

  const [firstStepSocialData, setFirstStepSocialData] = useState({
    name: "",
    email: "",
    termsAndConditions: true,
    marketingCookies: false,
    marketingEmails: false,
  });

  // empty string -> regular registration flow
  const [registerWithSocial, setRegisterWithSocial] = useState<string|null>("");
  // true is "here's your email and username all set up", false is "here have the fields to edit it manually"
  const [isSocialAccountDetermined, toggleSocialAccount] = useState(true);

  useEffect(() => {
    if (cookies && cookies['livlet-referral']) {
      setReferral(cookies['livlet-referral']);
    }
    setAcceptingInvite(false);
  }, []);

  useEffect(() => {
    console.log("window.location.hostname=" + window.location.hostname)
    if (window.location.hostname.includes("partner")) {
      togglePartnerWebsite(true);
    }
    if (window.location.hostname.includes("mcl") || window.location.hostname.includes("localhost")) {
      toggleMcLarenWebsite(true);
    }
  }, [])

  useEffect(() => {
    const socialData = {
      socialName: name,
      socialEmail: email
    };
    setFirstStepSocialData({
      // @ts-ignore
      name: socialData.socialName,
      // @ts-ignore
      email: socialData.socialEmail,
      termsAndConditions: true,
      marketingCookies: false,
      marketingEmails: false,
    });

    setRegisterWithSocial(externalSourceName);
    form.setFieldsValue({
      socialName: socialData.socialName,
      socialEmail: socialData.socialEmail});
  }, [externalSourceName, name, email])

  const handleFirstStepSocialOnFinish = (data: {
    socialName: string;
    socialEmail: string;
    termsAndConditions: boolean;
    marketingCookies: boolean;
    marketingEmails: boolean;
  }) => {
    toggleRegistering(true);
    dispatch(
      signUp({
        externalSourceName, externalId, externalSourceToken,
        name: firstStepSocialData.name,
        email: firstStepSocialData.email,
        password: Guid.create().toString(),
        marketingEmails: data.marketingEmails ?? false,
        referral,
        website: window.location.hostname.includes("mcl") ? window.location.hostname : null,
      })
    );
  };

  // post-registration processing
  useEffect(() => {
    setUnknownError(false);
    if (auth.fetchingStatus === TFetchingStatus.Failure) {
      if (auth.emailError) {
        setEmailError(true);
        setStepNumber(1);
        form.setFieldsValue({
          password: "",
          confirmationPassword: "",
        });
        dispatch(loginClean());
        toggleRegistering(false);
      }
      if (auth.unknownError) {
        setUnknownError(true);
        setCodeIncorrect(true);
        toggleRegistering(false);
      }
    }
    else if (auth.fetchingStatus === TFetchingStatus.Success) {
      if (auth.firstStepSuccess && !auth.registrationSuccess && !auth.redirectToPayment) {
        setStepNumber(2);
      } else if (auth.registrationSuccess) {
        setStepNumber(5);
      } else {
        setEmailError(false);
        setUnknownError(false);
        setCodeIncorrect(false);
      }
      dispatch(loginClean());
    }
  }, [auth.fetchingStatus, auth.emailError, auth.unknownError, auth.captchaError, f, form, dispatch]);

  useEffect(() => {
    if (invite_token) {
      const { exp, email, userName, secret } = decodeJwt<{
        exp: number;
        email: string;
        userName: string;
        secret: string;
      }>(invite_token);

      if (moment().isAfter(moment(exp*1000))) {
        setStepNumber(101);
        return;
      }

      form.setFieldsValue({
        name: userName,
        email: email,
      });

      setAcceptingInvite(true);
      setInviteToken(secret);
    }
  }, [invite_token]);

  const registrationOnKeyDown = (fieldNumber: number, e: any) => {
    if (e.key === "Backspace" && fieldNumber !== 0) {
      let values = [...codeSignValues];
      if (fieldNumber === 5) {
        values[5] = ""
        e.preventDefault();
        codeSignRefs[4].current && codeSignRefs[4].current.select();
      } else {
        values[fieldNumber] = null;
        e.preventDefault();
        codeSignRefs[fieldNumber-1].current && codeSignRefs[fieldNumber-1].current.select();
      }
      setCodeSignValues(values);
    }
  }

  const registrationCodeDigitChange = useCallback((fieldNumber: number, val: any) => {
    const focusAndSelectField = (field: any) => {
      if (!field)
        return;
      field.select();
    }

    if (val === null || val === undefined) {
      let values = [...codeSignValues];
      values[fieldNumber] = null;
      setCodeSignValues(values);
      return;
    }
    val = String(val).replace(/\D/g,'');

    let values = [...codeSignValues];
    const valArray = String(val).split('');
    valArray.forEach((oneDigit, i) => {
      if (fieldNumber+i < 6) values[fieldNumber+i] = oneDigit;
    });
    const fieldToFocus = fieldNumber + valArray.length;
    if (fieldToFocus < 6) {
      codeSignRefs[fieldToFocus] && focusAndSelectField(codeSignRefs[fieldToFocus].current);
    } else {
      codeSubmitRef.current && codeSubmitRef.current.focus();
    }
    setCodeSignValues(values);
  }, [codeSignRefs, codeSignValues, codeSubmitRef]);

  const pasteHandler = useCallback((fieldNumber: number): React.ClipboardEventHandler<HTMLInputElement> => 
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.nativeEvent.clipboardData?.items[0].getAsString((pasted) => {
        registrationCodeDigitChange(fieldNumber, pasted);
      })
    }, [registrationCodeDigitChange]);

  return (
    <Main>
      <FormWrapper>
        <FormHeader>
          <div style={{width: "175px"}}>
            <img src={"/images/LivletLogoNew.png"} style={{maxWidth: "100%"}} alt={""} />
          </div>
        </FormHeader>
        {stepNumber === 1 && (
          <>
            <>
              <Title>Let's create your Livlet account</Title>
              {(!registerWithSocial && !isPartnerWebsite && !isMcLarenWebsite) && <>
              {window.innerWidth < 1024 && <Title style={{margin: "1rem 0"}}>Sign in with: </Title>}
              <SocialButtonsWrapper>
                <GoogleLogin
                  clientId="915262851651-pk10djnhnvhplsmqaqkn6giefvcceka8.apps.googleusercontent.com"
                  buttonText={"Sign-in with Google"}
                  onSuccess={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                  className={"social-login-button"}
                  style={{marginRight: "1.25rem!important"}}
                />
                <FacebookLogin
                  appId="442122783795366"
                  autoLoad={false}
                  fields="id,email,name"
                  callback={responseFacebook}
                  version={"12.0"}
                  disableMobileRedirect
                  isMobile={false}
                  cssClass={"social-login-button"}
                  textButton={"Sign-in with Facebook"}
                  icon={<LoginFacebook style={{marginRight: "1rem", marginTop: "-0.1rem"}} />}
                />
              </SocialButtonsWrapper>
              <div style={{display: "flex", justifyContent: "center"}}>
                <Subtitle className={"bold"} style={{fontSize: "1rem"}}>or</Subtitle>
              </div>
              </>}
            </>
            <Form
              layout="vertical"
              form={form}
              size={"large"}
              // @ts-ignore
              onFinish={registerWithSocial ? handleFirstStepSocialOnFinish : handleFirstStepRegularOnFinish}
            >
              {!registerWithSocial && <div>
                  <LabelWrapper>
                      <Label>{f(messages.emailAddress)} <Accent>*</Accent></Label>{" "}
                  </LabelWrapper>
                  <Form.Item
                      key={"email"}
                      name={"email"}
                      rules={[
                        {
                          required: true,
                          pattern: emailRegex,
                          message: f(messages.validationEmail),
                        },
                        {
                          validator: (rule, value, callback) => {
                            if (
                              (window.location.hostname.includes("mcl") || window.location.hostname.includes("localhost"))
                              && (value && value.includes("@") &&
                                (!value.includes("@mclaren")
                                  && !value.includes("arby12@outlook")
                                  && !value.includes("@email"))
                              )
                            ) {
                              callback("Please use a valid McLaren email address to proceed.");
                            }

                            if (emailError) {
                              callback("");
                            } else {
                              callback();
                            }
                          }
                        },
                      ]}
                  >
                      <InputStyled
                          maxLength={100}
                          placeholder={f(messages.enterEmailAddress)}
                          onChange={() => {setEmailError(false)}}
                          disabled={acceptingInvite}
                      />
                  </Form.Item>
                {emailError &&
                <div
                    style={{
                      backgroundColor: "#FCF0F0",
                      padding: "1rem",
                      marginTop: "1em",
                      marginBottom: "1em",
                      display: "flex",
                      borderRadius: 6,
                      fontWeight: 400,
                    }}
                >
                    <AlertIcon />
                    <span style={{ paddingLeft: "0.5rem" }}>
                Cannot register the user. If you registered before and can't login please <a onClick={() => history.push("/reset/password")}>reset</a> your password.
                </span>
                </div>}
                  <InputsWrapper>
                      <InputWrapper>
                          <LabelWrapper>
                              <Label>Password <Accent>*</Accent></Label>{" "}
                          </LabelWrapper>
                          <Popover
                              content={<div style={{padding: '0.7rem'}}>
                                <b>Password must include:</b>
                                <br/>{resultIcon(passwordStrengthResult.appropriateLength)} 8-20 Characters
                                <br/>{resultIcon(passwordStrengthResult.capitalLetters)} At least one capital letter
                                <br/>{resultIcon(passwordStrengthResult.numbers)} At least one number
                                <br/>{resultIcon(passwordStrengthResult.noSpaces)} No spaces
                              </div>}
                              placement="top"
                              trigger={["hover", "focus"]}
                          >
                              <Form.Item
                                  key={"password"}
                                  name={"password"}
                                  rules={[
                                    {
                                      required: true,
                                      message: f(messages.validationPassword),
                                    },
                                    {
                                      validator: (rule, value, callback) => {
                                        if (!passwordStrengthResult.final) {
                                          callback("");
                                        } else {
                                          callback();
                                        }
                                      }
                                    }
                                  ]}
                              >
                                  <InputPasswordStyled
                                      maxLength={100}
                                      placeholder={f(messages.enterPassword)}
                                      onChange={(e) => {setPasswordStrengthResult(passwordTest(e.target.value))}}
                                  />
                              </Form.Item>
                          </Popover>
                        {form.getFieldValue("password") &&
                        <div style={{display: "flex", marginTop: "-0.5rem", marginBottom: "0.75rem"}}>
                          {passwordResult(passwordStrengthResult.totalScore)}
                            <Popover
                                content={<div style={{padding: '0.7rem'}}>
                                  <b>Password must include:</b>
                                  <br/>{resultIcon(passwordStrengthResult.appropriateLength)} 8-20 Characters
                                  <br/>{resultIcon(passwordStrengthResult.capitalLetters)} At least one capital letter
                                  <br/>{resultIcon(passwordStrengthResult.numbers)} At least one number
                                  <br/>{resultIcon(passwordStrengthResult.noSpaces)} No spaces
                                </div>}
                                placement="top"
                                trigger={["hover", "focus"]}
                            ><span style={{marginLeft: "0.3rem", marginBottom: "-0.3rem"}}>{resultIcon(passwordStrengthResult.final)}</span>
                            </Popover>
                        </div>}
                      </InputWrapper>
                  </InputsWrapper>
                {isPartnerWebsite && <InputsWrapper>
                    <InputWrapper>
                        <LabelWrapper>
                            <Label>Partner code {window.innerWidth > 1024 ? <span style={{color:"#2A80E1", fontWeight:400}}>* (Typically a 4-5 letter or number code)</span> : "*"}</Label>{" "}
                        </LabelWrapper>
                        <Popover
                            content={<div style={{maxWidth:"19rem", padding: '0.7rem', display:"flex", flexDirection:"column", alignItems:"center"}}>
                              <b>You will typically find this on the flyer that was provided to you by our Partner service providers.</b>
                              <div style={{display:"flex", flexDirection:"row", justifyContent:"center", justifyItems:"center"}}><b style={{marginTop:"0.3rem"}}>For example:</b> <RegistrationCodeExample/></div>
                            </div>}
                            placement="top"
                            trigger={["hover", "focus"]}
                        >
                            <Form.Item
                                key={"partnerCode"}
                                name={"partnerCode"}
                                rules={[
                                    {
                                      required: true,
                                      message: "Please provide a code.",
                                    },
                                ]}
                            >
                                <InputStyled
                                    maxLength={100}
                                    placeholder={window.innerWidth > 1024 ? "Enter your provider’s Partner code" : "Enter code"}
                                />
                            </Form.Item>
                        </Popover>
                    </InputWrapper>
                </InputsWrapper>}
              </div>}
              {unknownError && (
                <div
                  style={{
                    backgroundColor: "#FCF0F0",
                    padding: "1rem",
                    marginBottom: "1em",
                    display: "flex",
                    borderRadius: 6,
                    fontWeight: 400,
                  }}
                >
                  <AlertIcon />
                  <span style={{ paddingLeft: "0.5rem" }}>
                We were unable to complete your registration. Please
                contact <a href={"mailto:support.uk@livlet.com"}>support.uk@livlet.com</a> for help.
              </span>
                </div>
              )}
              {registerWithSocial && isSocialAccountDetermined &&
              <SocialWrapper>
                  <SocialUsername>{firstStepSocialData.name}</SocialUsername>
                  <SocialEmail>{firstStepSocialData.email}</SocialEmail>
                  {/*<PseudoLink onClick={() => toggleSocialAccount(false)}>Change</PseudoLink>*/}
              </SocialWrapper>}
              {registerWithSocial && !isSocialAccountDetermined &&
              <>
                <LabelWrapper>
                    <Label>{f(messages.name)}</Label>{" "}
                </LabelWrapper>
                <Form.Item
                    key={"socialName"}
                    name={"socialName"}
                    rules={[
                      {
                        required: true,
                        message: f(messages.validationName),
                      },
                    ]}
                >
                    <InputStyled
                        maxLength={100}
                        placeholder={f(messages.enterFullName)}
                    />
                </Form.Item>
                  <LabelWrapper>
                      <Label>{f(messages.emailAddress)}</Label>{" "}
                  </LabelWrapper>
                  <Form.Item
                    key={"socialEmail"}
                    name={"socialEmail"}
                    rules={[
                      {
                        required: true,
                        pattern: emailRegex,
                        message: f(messages.validationEmail),
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (emailError) {
                            callback("");
                          } else {
                            callback();
                          }
                        }
                      },
                    ]}
                  >
                    <InputStyled
                      maxLength={100}
                      placeholder={f(messages.enterEmailAddress)}
                      onChange={() => setEmailError(false)}
                    />
                  </Form.Item>
                {emailError &&
                <div
                    style={{
                      backgroundColor: "#FCF0F0",
                      padding: "1rem",
                      marginTop: "1em",
                      marginBottom: "1em",
                      display: "flex",
                      borderRadius: 6,
                      fontWeight: 400,
                    }}
                >
                    <AlertIcon />
                    <span style={{ paddingLeft: "0.5rem" }}>
                Cannot register the user. If you registered before and can't login please <a onClick={() => history.push("/reset/password")}>reset</a> your password.
                </span>
                </div>}
              </>}
              <TermsAndConditions>
                {/*<Form.Item*/}
                {/*  style={{marginBottom: '0'}}*/}
                {/*  key={"termsAndConditions"}*/}
                {/*  name={"termsAndConditions"}*/}
                {/*  valuePropName={"checked"}*/}
                {/*  rules={[*/}
                {/*    {*/}
                {/*      required: true,*/}
                {/*      transform: (value: any) => value || undefined,*/}
                {/*      type: "boolean",*/}
                {/*      message: f(messages.validationTermsAndConditions),*/}
                {/*    },*/}
                {/*  ]}*/}
                {/*>*/}
                {/*  <Checkbox>*/}
                {/*    <TermAndConditionsDescription>*/}
                {/*      {f(messages.termAndConditionsText, {*/}
                {/*        link: (*/}
                {/*          <a href={`/terms`} target="_blank">*/}
                {/*            {f(messages.termAndConditionsLink)}*/}
                {/*          </a>*/}
                {/*        ),*/}
                {/*      })}*/}
                {/*    </TermAndConditionsDescription>*/}
                {/*  </Checkbox>*/}
                {/*</Form.Item>*/}
                <Form.Item
                  key={"marketingEmails"}
                  name={"marketingEmails"}
                  valuePropName={"checked"}
                >
                  <MarketingCheckboxWrapper>
                    <Checkbox />
                    <Tooltip title={"We send bi-weekly newsletter"}>Send me occasional emails about new product features and service</Tooltip>
                  </MarketingCheckboxWrapper>
                </Form.Item>
              </TermsAndConditions>
              {registerWithSocial && firstStepSocialData.email === "undefined" && externalSourceName !== "facebook" &&
              <Subtitle style={{maxWidth: "34.625rem", marginTop: "1rem", color: "#c21111"}}>
                  We were unable to gain access to your email and therefore cannot proceed with the registration.
                  Please make sure your email has been verified, and that you've granted Livlet access to it.
              </Subtitle>}
              {registerWithSocial && firstStepSocialData.email === "undefined" && externalSourceName === "facebook" && <ErrorWrapper>
                <AlertIcon />
                <span style={{ paddingLeft: "0.5rem" }}>
                  We were unable to gain access to your email details via your Facebook settings. Please try an alternative sign-in method.
                </span>
              </ErrorWrapper>}
              <Subtitle className={"bold"}>
                {window.innerWidth > 1024 ? "Click" : "Tap"} "Agree & Create Account", to accept our <a className={"footer-terms"} onClick={() => history.push("/terms")}>Terms and Conditions</a> and
                start using Livlet. Please read our <a className={"footer-privacypolicy"} onClick={() => history.push("/privacypolicy")}>Privacy Policy</a>.
              </Subtitle>
              <ButtonsWrapper style={{display:"flex", justifyContent:"center"}}>
                <RegisterButton htmlType="submit" type="primary">
                  Agree & Create Account
                  {(isRegistering && !emailError && !unknownError || isRegistering && registerWithSocial && firstStepSocialData.email !== "undefined") &&
                    <Spin indicator={<LoadingOutlined style={{color: "white", fontSize: 16}} spin/>}/>}
                </RegisterButton>
              </ButtonsWrapper>
            </Form>
            <Subtitle className={"bold"}>
              Already have an account? <a style={{fontWeight: 600}} onClick={() => window.location.replace(`${EnvConfig.APP_URL}/login`)}>Click here</a> to login.
            </Subtitle>
           <Subtitle style={{marginTop: window.innerWidth > 1024 ? "1rem" : "0.75rem"}}>
              This site is protected by reCAPTCHA and the Google <a className={"footer-privacypolicy"} href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a className={"footer-terms"} href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
            </Subtitle>
          </>
        )}

      {(stepNumber === 2) && <>
         <div>
           <Title>Thank you!</Title>
           <Subtitle className={"activation-subtitle"}>
             To activate your account, please provide us the 6 digit code we have emailed you:
           </Subtitle>
           <CodeInputWrapper>
            {[0, 1, 2, 3, 4, 5].map((index) => (<InputNumberStyled
              key={index}
              maxLength={6 - index} min={0} max={9}
              style={{width:"2.5rem", ...(index !== 0 && {marginLeft:"1rem"})}}
              autoFocus={index === 0}
              type={"number"}
              value={codeSignValues[index]}
              ref={codeSignRefs[index]}
              onKeyDown={(e) => registrationOnKeyDown(index, e)}
              onPaste={pasteHandler(index)}
              onChange={(val) => registrationCodeDigitChange(index, val)}>
            </InputNumberStyled>))}
           </CodeInputWrapper>
           {codeIncorrect && <ActivationCodeError>
             Activation code is incorrect.
           </ActivationCodeError>}
           <RegisterButtonWrapper>
             <RegisterButton ref={codeSubmitRef} type="primary" onClick={() => {
               toggleSubmitting(true);
               handleActivateAccount().then()
             }}>
               {isSubmitting && !codeIncorrect ? "Creating" : "Create Account"}
               {isSubmitting && !codeIncorrect && <Spin indicator={<LoadingOutlined style={{color: "white", fontSize: 16}} spin/>}/>}
             </RegisterButton>
           </RegisterButtonWrapper>
           <Afterword>If you can't find the email in your Inbox, please check your Spam folder.</Afterword>
         </div>
        </>}

      {(stepNumber === 5) && <>
          <div>
              <Title>Thank you!</Title>
              <Subtitle>
                  To activate your account, click the verification link in the email we have just sent to {firstStepRegularData.email}.
              </Subtitle>
              <Subtitle>
                  If you can't find the email in your Inbox, please check your Spam folder.
              </Subtitle>
          </div>
      </>}
      {(stepNumber === 101) && <>
          <div>
              <Title>Invite expired!</Title>
              <Subtitle>
                  Your invite has expired.
              </Subtitle>
              <Subtitle>
                  You can click <a onClick={() => history.push("/")}>here</a> to return to the login page.
              </Subtitle>
          </div>
      </>}
      </FormWrapper>
      <ImageWrapper>
        <RegistrationPageImageStyled  imgSrc={isMcLarenWebsite ? "/images/registrationImageBeach.jpg" : undefined}  />
        <TextOnImage>{f(messages.imageText)}</TextOnImage>
      </ImageWrapper>
    </Main>
  );
};

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormHeader = styled.div`
  padding: 2rem 0;

  @media (min-width: 1024px) {
    height: 15vh;
  }
`;

const TextOnImage = styled.h3`
  font-weight: 700;
  color: #ffffff;
  position: absolute;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1024px) {
    font-size: 2.1rem;
    width: 350px;
    top: 0.5rem;
    right: 0.5rem;
  }
`;

const RegistrationPageImageStyled = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1024px) {
    min-height: 800px;
    width: 100%;
    height: 100vh;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: ${(props: { imgSrc?: string }) => props.imgSrc ? "url("+props.imgSrc+")" : "url(/images/registrationImage.jpg)"};
    margin: 0 auto;
    position: relative;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  padding-top: 0.5rem;
  
  @media (min-width: 1024px) {
    padidng-top: 1.5rem;
  }
`;

const SocialButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media (min-width: 1024px) {
    flex-direction: row;
    margin: 1.75rem auto;
  }

  @media (max-width: 1024px) {
    column-gap: 1.5rem;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  
  @media (max-width: 600px) {
    row-gap: 0.5rem;
    flex-direction: column;
  }
`;

const Main = styled.main`
  display: flex;
  overflow-y: visible;
  height: 100vh;
  max-height: 100vh;
  justify-content: space-between;
  background-color: white;
`;

export const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  
  &.halved { width: 50%; }

  @media (max-width: 1024px) {
    flex-direction: column;
    &.halved { width: 100%; }
  }
`;

export const InputPasswordStyled = styled(Input.Password)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  height: 3.25rem;
  border-radius: 6px;
  width: 100%;

  @media (max-width: 1024px) {
    height: 2.5rem;
  }
`;

export const InputStyled = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  height: 3.25rem;
  border-radius: 6px;

  @media (max-width: 1024px) {
    height: 2.5rem;
  }
  
  &.field-error { border-color: #ff4d4f; }
  &.field-error:focus {
    -webkit-box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
    box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
  }
`;

export const InputWrapper = styled.div`
  width: 100%;

  @media (max-width: 1024px) {
    padding-top: 0.25rem;
  }
`;

const TermsAndConditions = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const Title = styled.h4`
  color: #0C2A41;
  
  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 1.625rem;
  }

  @media (max-width: 1024px) {
    font-weight: 600!important;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
`;

const Subtitle = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: #0C2A41;
  line-height: 1.5rem;
  
  &.bold { font-weight: 600; }
  a { color: #2A80E1; }
  
  @media (max-width: 1024px) {
    font-size: 0.875rem;
    line-height: 1.125rem;
    
    &.activation-subtitle { margin-top: 1rem; }
  }
`;

export const Label = styled.p`
  font-weight: 600;
  font-size: 1rem;
  color: #0C2A41;
`;

const SocialWrapper = styled.div`
  display: flex; 
  flex-direction: column;
  margin: 5rem auto;
  width: 100%;
`

const SocialUsername = styled.span`
  color: #21272B;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1.75rem;
`

const SocialEmail = styled.span`
  color: #6B7185;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
`

const RegisterButton = styled(Button)`
  border-radius: 6px;
  background-color: #009966;
  border: 1px solid #009966;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  transition: all .5s ease;
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 1rem;
  height: 2.625rem;
   
  &:hover, &:active {
    background-color: white;
    color: #009966;
    border: 1px solid #009966;
    transition: all .5s ease;
  }
  
  &:focus {
    background-color: #009966;
    color: white;
    border: 1px solid #009966;
    transition: all .5s ease;
  }
`

const Accent = styled.span`
  color: #2A80E1;
`

const MarketingCheckboxWrapper = styled.div`
  margin-bottom: 0;
  display: flex;
  gap: 0.75rem;
  font-size: 1rem;
  
  @media (max-width: 1024px) {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }  
`

const CodeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  @media (max-width: 1024px) {
    margin-top: 2.5rem;
  }  
`

const ActivationCodeError = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 2rem;
  color: #ef624e;
  font-weight: 700;
  font-size: 1.25rem;
`

const RegisterButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2.5rem;
`

const Afterword = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #45655E;
  margin-top: 2.5rem;
  
  @media (max-width: 1024px) {
    margin-top: 1rem;
  } 
`

const InputNumberStyled = styled(InputNumber)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  
  border: 1px solid #009966;
  box-sizing: border-box;
  border-radius: 6px;

  .ant-input-number-input {
    font-weight: 700!important;
    font-size: 1.625rem;
    line-height: 1.75rem;
    color: #002A43;
  }
`;

export default SimpleRegistration;
