import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TAddContactResponse = {
  name: string;
  surname: string,
  contactId: Guid;
  propertyId: Guid;
  contactType: string;
  company: string;
  email: string;
  phone: string;
  website: string;
  notes: string;
  tags: [];
  emergency: boolean;
  imageThumbnail: string;
};

export type TAddContactRequest = {
  name?: string | null;
  surname?: string | null;
  contactType?: string | null;
  company?: string | null;
  email?: string | null;
  phone?: string | null;
  website?: string | null;
  emergency?: boolean | null;
};

export const addContact = (payload: { request: TAddContactRequest, propertyId: Guid, contactImage: any; }) => {
  const { propertyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/contacts`
  );

  let bodyFormData = new FormData();
  bodyFormData.append('contactCreateDto',
    new Blob([JSON.stringify(payload.request)], {type: 'application/json; charset=UTF-8'}));
  bodyFormData.append("contactImage", payload.contactImage);

  return apiPost<any, any>(url, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
