import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import useDrag from "./useDrag";
import {useHistory, useLocation} from "react-router-dom";
import {LeftArrow, RightArrow} from "./arrows";
import {Card} from "./card"

type Props = {
  selectedType: string;
  setSelectedType: (arg: string) => void;
  maintenanceTypesMap: any;
}

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const MaintenanceTabs = (props: Props) => {
  const {selectedType, setSelectedType, maintenanceTypesMap} = props;
  const { dragStart, dragStop, dragMove } = useDrag();

  let history = useHistory();
  const maintenanceTypes = Array.from(maintenanceTypesMap)

  const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (
    ev: React.MouseEvent
  ) =>
    dragMove(ev, (posDiff) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });

  const useQuery = (query: string) => {
    let queryValue = new URLSearchParams(useLocation().search);
    return queryValue.get(query);
  };
  let contentId = useQuery("contentId")

  const handleSelectTab = (tabName: string) => {
    setSelectedType(tabName)
    if (contentId) {
      history.push(`/upkeep?tab=${tabName}&contentId=${contentId}`)
    } else {
      history.push(`/upkeep?tab=${tabName}`)
    }
  }

  const allowedTypes = ["All", "My maintenances", "Recommended", "Not applicable"]

  return <div onMouseLeave={dragStop} style={{paddingLeft: "0.625rem"}}>
    <ScrollMenu
      LeftArrow={LeftArrow}
      RightArrow={RightArrow}
      onMouseDown={() => dragStart}
      onMouseUp={() => dragStop}
      onMouseMove={handleDrag}>
        {maintenanceTypes.filter((type: any) => allowedTypes.includes(type[0]))
          .map((type: any, id: number) => <Card
          title={type[0]}
          itemId={id.toString()} // itemId is required for tracking items
          key={id}
          onClick={() => {handleSelectTab(type[0])}}
          selected={selectedType === type[0] || (type[0] === "All" && selectedType === "Budgeting")}
        />)}
    </ScrollMenu>
  </div>
}

export default MaintenanceTabs