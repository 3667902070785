import React, {useState} from "react";
import {FilterGroupHeader, FilterGroupList, FilterGroupRow, FilterGroupWrapper} from "../../screens/styled";
import {isEndDateDisabled, isStartDateDisabled} from "../helper";
import {ReactComponent as CalendarArrow} from "../../../images/CalendarArrow.svg";
import moment from "moment";
import styled from "styled-components";
import {Collapse, DatePicker} from "antd";
import {useSelector} from "react-redux";
import {getPropertyPreferencesSelector} from "../../selectors";
import {SubcategoryHeader} from "../tags/FilterTagsNew";
import {ReactComponent as CollapseIconMinus} from "../../../images/CollapseIconMinus.svg";
import {ReactComponent as CollapseIconPlus} from "../../../images/CollapseIconPlus.svg";
const { Panel } = Collapse;

type Props = {
  startDate: any;
  setStartDate: (arg: any) => void;
  endDate: any;
  setEndDate: (arg: any) => void;
  selectedPredefinedDate: any;
  setSelectedPredefinedDate: (arg: any) => void;
}

const PeriodFilter = (props: Props) => {
  const {startDate, setStartDate, endDate, setEndDate, selectedPredefinedDate, setSelectedPredefinedDate} = props;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [activeKeys, setActiveKeys] = useState<any>(["period"]);

  return <PeriodFilterWrapper>
    <Collapse bordered={false} ghost onChange={(key) => setActiveKeys(key)} defaultActiveKey={activeKeys}>
      <Panel showArrow={false} header={<SubcategoryHeader>
        Date and period
        <div className={"button-wrapper"}>
          {activeKeys.includes("period") ? <CollapseIconMinus /> : <CollapseIconPlus />}
        </div>
      </SubcategoryHeader>} key="period">
        <FilterGroupRow className={"cost-date-row"} style={{border: 0, marginBottom: 0, paddingTop: "1rem"}}>
          <FilterGroupWrapper className={"cost-filter cost-date-filter"}>
            <FilterGroupHeader>Date</FilterGroupHeader>
            <FilterGroupList style={{alignItems: "center", columnGap: "0.5rem", minHeight: "2.5rem"}}>
              {selectedPredefinedDate === "week"
                ? <DatePill>This week</DatePill>
                : <DateRange onClick={() => {
                  setSelectedPredefinedDate("week");
                  setStartDate(moment().startOf( 'week'));
                  setEndDate(moment().endOf('week'));}}>This week</DateRange>
              }
              {selectedPredefinedDate === "month"
                ? <DatePill>This month</DatePill>
                : <DateRange onClick={() => {
                  setSelectedPredefinedDate("month");
                  setStartDate(moment().startOf('month'));
                  setEndDate(moment().endOf('month'));}}>This month</DateRange>
              }
              {selectedPredefinedDate === "year"
                ? <DatePill>This year</DatePill>
                : <DateRange onClick={() => {
                  setSelectedPredefinedDate("year");
                  setStartDate(moment().startOf( 'year'));
                  setEndDate(moment().endOf('year'));}}>This year</DateRange>
              }
            </FilterGroupList>
          </FilterGroupWrapper>
          <FilterGroupWrapper className={"cost-filter"}>
            <FilterGroupHeader>Period</FilterGroupHeader>
            <FilterGroupList style={{columnGap: "0.5rem", alignItems: "center"}}>
              {selectedPredefinedDate === "custom"
                ? <DatePill>Custom</DatePill>
                : <DateRange onClick={() => setSelectedPredefinedDate("custom")}>Custom</DateRange>
              }
              <DatePickerStyled
                format={propertyPreferences.preferredDateFormat}
                disabled={selectedPredefinedDate !== "custom"}
                disabledDate={(currentDate) => endDate && isStartDateDisabled(currentDate, endDate)}
                value={startDate}
                onChange={(e: any) => setStartDate(e)} />
              <CalendarArrow style={{flexShrink: 0}} />
              <DatePickerStyled
                format={propertyPreferences.preferredDateFormat}
                disabled={selectedPredefinedDate !== "custom"}
                disabledDate={(currentDate) => startDate && isEndDateDisabled(currentDate, startDate)}
                value={endDate}
                onChange={(e: any) => setEndDate(e)} />
            </FilterGroupList>
          </FilterGroupWrapper>
        </FilterGroupRow>
      </Panel>
    </Collapse>
  </PeriodFilterWrapper>
}

const PeriodFilterWrapper = styled.div`
  .ant-collapse-header { padding: 0!important; }
  padding-bottom: 1rem;
`

export const DatePill = styled.span`
  display: flex;
  align-items: center;

  background: #2F80ED;
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 24px;
  
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding: 0.375rem 0.875rem;
`;

const DateRange = styled.span`
  color: #45655E;
  background: white;
  font-weight: 500;
  cursor: pointer;
  border-radius: 24px;
  
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding: 0.375rem 0.875rem;
`;

const DatePickerStyled = styled(DatePicker)`
  background: white!important;
  width: 100%;
  border-radius: 6px;
  height: 2.5rem;
  
  input {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #45655E;
  }
  
  @media (max-width: 1024px) {
    height: 2.125rem;
    input {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }
`

export default PeriodFilter