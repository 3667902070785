import React, {useEffect, useState} from "react";
import {Collapse, Input, List, Menu, Popover, Select} from "antd";
import styled from "styled-components";
import {TGetMaintenancesResponse} from "../../../api-wrapper/maintenance/getMaintenances";
import MaintenanceTabs from "./tabs/MaintenanceTabs";
import MaintenanceListItemNew, {getDate} from "./MaintenanceListItemNew";
import {ReactComponent as FilterIcon} from "../../../images/Filters.svg";
import {ReactComponent as SortIcon} from "../../../images/Sort.svg";
import {ReactComponent as CustomMaintenancesIcon} from "../../../images/upkeep-categories/CustomMaintenances.svg";
import {ReactComponent as BudgetingIcon} from "../../../images/upkeep-categories/Budgeting.svg";
import {ReactComponent as KitchenLaundryIcon} from "../../../images/upkeep-categories/KitchenLaundry.svg";
import {ReactComponent as ElectricityLightningIcon} from "../../../images/upkeep-categories/ElectricityLightning.svg";
import {ReactComponent as BathroomsIcon} from "../../../images/upkeep-categories/Bathrooms.svg";
import {ReactComponent as ExteriorStructuralIcon} from "../../../images/upkeep-categories/ExteriorStructural.svg";
import {ReactComponent as HeatingCoolingGasIcon} from "../../../images/upkeep-categories/HeatingCoolingGas.svg";
import {ReactComponent as SafetySecurityIcon} from "../../../images/upkeep-categories/SafetySecurity.svg";
import {ReactComponent as InteriorFurnishingIcon} from "../../../images/upkeep-categories/InteriorFurnishing.svg";
import {ReactComponent as GardenIcon} from "../../../images/upkeep-categories/Garden.svg";
import {ReactComponent as WaterSewageIcon} from "../../../images/upkeep-categories/WaterSewage.svg";
import {ReactComponent as HomeCyberSecurityIcon} from "../../../images/upkeep-categories/HomeCyberSecurity.svg";
import {ReactComponent as SwimmingPoolIcon} from "../../../images/upkeep-categories/SwimmingPool.svg";
import {ReactComponent as RentalRelatedIcon} from "../../../images/upkeep-categories/RentalRelated.svg";
import {ReactComponent as ReplacementIcon} from "../../../images/upkeep-categories/Replacement.svg";
import {ReactComponent as ReminderIcon} from "../../../images/upkeep-categories/Reminder.svg";
import {SortMenuStyled} from "../works/WorksListComp";
import moment from "moment";
import {getCost, getFrequency} from "../helper";
import {FilterGroupRow, Filters} from "../../screens/styled";
import {
  CancelButton,
  CategoryHeader,
  EditCategoryBody,
  EditCategoryContainer,
  EditCategoryHeader,
  EditTagsBody,
  SubcategoryHeader,
  TagStyled
} from "../tags/FilterTagsNew";
import _ from "lodash";
import {SelectStyled} from "../tags/TagContainer";
import {ReactComponent as SearchIcon} from "../../../images/SearchGray.svg";
import {useHistory, useLocation} from "react-router-dom";
import {ReactComponent as DotIcon} from "../../../images/DotForFilters.svg";
import {useSelector} from "react-redux";
import {getInventoriesSelector, getMaintenancesSelector, getPropertyIdSelector} from "../../selectors";
import {TFetchingStatus} from "../../../helpers";
import {Guid} from "guid-typescript";
import {getInventoryById} from "../../../api-wrapper/inventories/getInventoryById";
import {IntroductionButton} from "../../screens/components";
import {ReactComponent as CollapseIconMinus} from "../../../images/CollapseIconMinus.svg";
import {ReactComponent as CollapseIconPlus} from "../../../images/CollapseIconPlus.svg";

const { Option } = Select;
const { Panel } = Collapse;

type Props = {
  maintenances: TGetMaintenancesResponse;
  maintenanceTypes: Array<string>;
  maintenanceTypesMap: Map<string, number>;
  contentTypes: Array<any>;
  // drawers
  toggleViewWorkDrawer: (arg: boolean) => void;
  setViewWorkId: (arg: any) => void;
  toggleExpenseDrawer: (arg01: boolean) => void;
  setEditingExpenseData: (arg01: any) => void;
  toggleUpkeepDrawer: (arg01: boolean) => void;
  setEditingUpkeepData: (arg01: any) => void;
  // local state update
  toggleSubscribe?: (id: Guid, isSubscribed: boolean) => void;
  updateMaintenance?: (id: Guid, newValues: any) => void;
};

type PropsSublist = {
  type: string;
  background?: string;
  maintenances: TGetMaintenancesResponse;
};

type PropsList = {
  maintenances: TGetMaintenancesResponse;
  style?: any;
};

export const maintenanceIconDispenser = (type: string) => {
  switch (type) {
    case "User-created":
      return <CustomMaintenancesIcon style={{flexShrink: 0}} />
    case "Budgeting":
      return <BudgetingIcon style={{flexShrink: 0}} />
    case "Recurring expense - User-created":
      return <BudgetingIcon style={{flexShrink: 0}} />
    case "Kitchen & Laundry":
      return <KitchenLaundryIcon style={{flexShrink: 0}} />
    case "Electricity & Lighting":
      return <ElectricityLightningIcon style={{flexShrink: 0}} />
    case "Bathrooms":
      return <BathroomsIcon style={{flexShrink: 0}} />
    case "Exterior & Structural":
      return <ExteriorStructuralIcon style={{flexShrink: 0}} />
    case "Heating, Cooling & Gas":
      return <HeatingCoolingGasIcon style={{flexShrink: 0}} />
    case "Safety & Security":
      return <SafetySecurityIcon />
    case "Interior & Furnishings":
      return <InteriorFurnishingIcon style={{flexShrink: 0}} />
    case "Garden":
      return <GardenIcon style={{flexShrink: 0}} />
    case "Water & Sewage":
      return <WaterSewageIcon style={{flexShrink: 0}} />
    case "Home Cyber security":
      return <HomeCyberSecurityIcon style={{flexShrink: 0}} />
    case "Swimming Pool":
      return <SwimmingPoolIcon style={{flexShrink: 0}} />
    case "Rental Related":
      return <RentalRelatedIcon style={{flexShrink: 0}} />
    case "Replacement":
      return <ReplacementIcon style={{flexShrink: 0}} />
    case "Reminder":
      return <ReminderIcon style={{flexShrink: 0}} />
    default:
      return null;
  }
}

const MaintenanceListComp = (props: Props) => {
  const { maintenances, maintenanceTypes, maintenanceTypesMap, contentTypes, toggleUpkeepDrawer, setEditingUpkeepData,
    toggleExpenseDrawer, setEditingExpenseData, toggleViewWorkDrawer, setViewWorkId, toggleSubscribe, updateMaintenance } = props;

  const history = useHistory();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const dbMaintenances = useSelector(getMaintenancesSelector);
  const inventories = useSelector(getInventoriesSelector);

  const [editing, setEditing] = useState("");
  const [openMaintenanceTabs, setOpenMaintenanceTabs] = useState<any>(null)
  const [sortingType, setSortingType] = useState("");
  const [displayFilters, toggleDisplayFilters] = useState(false);
  const [maintenancesSearch, setMaintenancesSearch] = useState("");
  const [selectedMaintenanceType, setSelectedMaintenanceType] = useState("My maintenances");

  const [maintenanceToFilter, setMaintenanceToFilter] = useState<any>();
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [selectedContents, setSelectedContents] = useState<any>([]);
  const [linkedMaintenanceIds, setLinkedMaintenanceIds] = useState<any>([]);
  const [selectedUpkeepCategoryTypes, setSelectedUpkeepCategoryTypes] = useState<Set<string>>(new Set());
  const [selectedUpkeepStatusTypes, setSelectedUpkeepStatusTypes] = useState<Set<string>>(new Set());
  const [upkeepCategoryTypesWithAmounts, setUpkeepCategoryTypesWithAmounts] = useState<Array<{
    name: string, amount: number}>>([]);
  const [upkeepStatusTypesWithAmounts, setUpkeepStatusTypesWithAmounts] = useState<Array<{
    name: string, amount: number}>>([]);
  const [sortMenuPopoverVisible, setSortMenuPopoverVisible] = useState(false);
  const [activeKeys, setActiveKeys] = useState<any>(["categories", "status"]);

  const toggleTab = (tab: string) => {
    if (openMaintenanceTabs === null) {
      setOpenMaintenanceTabs([...maintenanceTypes.filter((t: string) => t !== tab)])
    } else {
      if (openMaintenanceTabs.includes(tab)) {
        setOpenMaintenanceTabs([...openMaintenanceTabs.filter((t: string) => t !== tab)])
      } else {
        setOpenMaintenanceTabs([...openMaintenanceTabs, tab])
      }
    }
  }

  const haveFiltersBeenUsed = () => selectedItems.length > 0 || selectedUpkeepCategoryTypes.size > 0 || selectedUpkeepStatusTypes.size > 0

  const useQuery = (type: string) => {
    let query = new URLSearchParams(useLocation().search);
    return query.get(type);
  };

  const typeToOpen = useQuery("contentType")
  const contentToOpen = useQuery("contentId")
  const categoryToOpen = useQuery("category")
  const tabToOpen = useQuery("tab")
  const maintenanceToOpen = useQuery("maintenanceId")

  const toggleSelectedContent = (contentId: any) => {
    if (selectedContents.includes(contentId)) {
      setSelectedContents(selectedContents.filter((item: any) => item !== contentId))
      setLinkedMaintenanceIds(linkedMaintenanceIds.filter((item: any) => item.parent !== contentId))
    } else {
      setSelectedContents([...selectedContents, contentId])
      getInventoryById(propertyId, contentId).then((res: any) => {
        let linkedItems: any = []
        res.upkeeps.map((item: any) => linkedItems.push(item.id))
        setLinkedMaintenanceIds([...linkedMaintenanceIds, {parent: contentId, linkedItems}])
      })
    }
  }

  useEffect(() => {
    if (typeToOpen) {
      setSelectedTags([typeToOpen])
      setSelectedItems([...selectedItems, typeToOpen])
    } else {
      setSelectedTags([])
    }
    if (contentToOpen) {
      setSelectedContents([typeToOpen])
      toggleSelectedContent(contentToOpen)
      setSelectedItems([...selectedItems, contentToOpen])
    } else {
      setSelectedContents([])
    }
    if (maintenanceToOpen) {
      setMaintenanceToFilter(maintenanceToOpen);
    } else {
      setMaintenanceToFilter(null);
    }
  }, [typeToOpen, contentToOpen, maintenanceToOpen])

  const getClassesForUpkeepTypeTag = (upkeepType: any) => {
    if (selectedUpkeepCategoryTypes.has(upkeepType.name)) return "tag-blue"
    if (selectedUpkeepStatusTypes.has(upkeepType.name)) return "tag-blue"
    if (upkeepType.amount === 0) return "tag-gray"
  }

  const selectedTagsCheck = (maintenance: any) => {
    if (maintenance.maintenanceKeywords && contentTypes.length) {
      let keywords: any = []
      let maintenanceKeywords: any = []
      contentTypes.filter((type: any) => selectedTags.includes(type.name))
        .map((type: any) => type.keywords && keywords.push(...type.keywords))
      keywords = _.uniq(keywords)
      maintenance.maintenanceKeywords.split(",")
        .map((keyword: any) => maintenanceKeywords.push(keyword))
      return maintenanceKeywords.some((r: string) => keywords.includes(r))
    } else return false
  }

  const selectedContentCheck = (maintenance: any) => {
    const filteredIds: any = []
    linkedMaintenanceIds.map((item: any) => filteredIds.push(...item.linkedItems))
    const uniqueFilteredIds = _.uniq(filteredIds)
    return uniqueFilteredIds.includes(maintenance.id)
  }

  const filteredMaintenances = (category?: string) =>
    maintenances
      .filter((maintenance: any) => _.isNull(maintenanceToFilter) || _.isUndefined(maintenanceToFilter) ? true : maintenanceToFilter === maintenance.id)
      .filter((maintenance: any) => category ? maintenance.category === category : true)
      .filter((maintenance: any) => maintenance.category !== "Reminder")
      .filter((maintenance: any) => selectedMaintenanceType === "My maintenances" ? maintenance.subscribed : true)
      .filter((maintenance: any) => selectedMaintenanceType === "Recommended" ? maintenance.recommended && !maintenance.subscribed : true)
      .filter((maintenance: any) => selectedMaintenanceType === "Not applicable" ? maintenance.notApplicable : !maintenance.notApplicable)
      .filter((maintenance: any) => {
        return selectedUpkeepCategoryTypes.size > 0
          ? selectedUpkeepCategoryTypes.has(""+maintenance.category)
          : true;
      })
      .filter((maintenance: any) => {
        if (selectedUpkeepStatusTypes.size > 0) {
          if (selectedUpkeepStatusTypes.has("Signed-up")) {
            return maintenance.subscribed
          }
          if (selectedUpkeepStatusTypes.has("Not signed-up")) {
            return !maintenance.subscribed
          }
          if (selectedUpkeepStatusTypes.has("Recommended")) {
            return maintenance.recommended
          }
        } else return true
      })
      .filter((maintenance: any) => {
        if (selectedItems.length > 0) {
          if (selectedTags.length > 0 && selectedContents.length === 0) return selectedTagsCheck(maintenance)
          if (selectedTags.length === 0 && selectedContents.length > 0) return selectedContentCheck(maintenance)
          if (selectedTags.length > 0 && selectedContents.length > 0) return (selectedTagsCheck(maintenance) || selectedContentCheck(maintenance))
        } else return true
      })
      .filter((maintenance: any) => {
        return maintenancesSearch.length ? maintenance.title.toLowerCase().includes(maintenancesSearch.toLowerCase()) : true
      })
      .sort((u1: any, u2: any) => {
        if (sortingType === "startDate") {
          // @ts-ignore
          return moment(getDate(u1)).format('YYYYMMDD') - moment(getDate(u2)).format('YYYYMMDD')
        }
        else if (sortingType === "frequencyHighToLow") {
          return getFrequency(u1) - getFrequency(u2)
        }
        else if (sortingType === "frequencyLowToHigh") {
          return getFrequency(u2) - getFrequency(u1)
        }
        else if (sortingType === "costHighToLow"){
          return getCost(u2) - getCost(u1)
        }
        else if (sortingType === "costLowToHigh"){
          return getCost(u1) - getCost(u2)
        }
        else {
          return u1.title.localeCompare(u2.title)
        }
      })

  useEffect(() => {
    let amountsMap = new Map();

    filteredMaintenances().forEach(((upkeep: any) => {
      if (amountsMap.has(upkeep.category)) {
        amountsMap.set(upkeep.category, amountsMap.get(upkeep.category)+1);
      } else {
        amountsMap.set(upkeep.category, 1);
      }
    }));
    let amounts = new Array();
    maintenanceTypes.forEach((type: any) => {
      let amount = 0;
      if (amountsMap.has(type)) {
        amount = amountsMap.get(type);
      }
      amounts.push({name: type, amount: amount});
    });
    amounts = amounts.sort((e1, e2) => e2.amount - e1.amount);

    setUpkeepCategoryTypesWithAmounts(amounts)
  }, [maintenances, maintenanceTypes, selectedItems, selectedMaintenanceType]);

  useEffect(() => {
    let amountsMap = new Map();
    amountsMap.set("Signed-up", 0);
    amountsMap.set("Not signed-up", 0);
    amountsMap.set("Recommended", 0);

    filteredMaintenances().forEach(((upkeep: any) => {
      if (upkeep.subscribed) {
        amountsMap.set("Signed-up", amountsMap.get("Signed-up")+1);
      } else {
        amountsMap.set("Not signed-up", amountsMap.get("Not signed-up")+1);
      }
      if (upkeep.recommended) {
        amountsMap.set("Recommended", amountsMap.get("Recommended")+1);
      }
    }));

    let amounts = new Array();
    amounts.push({name: "Signed-up", amount: amountsMap.get("Signed-up")});
    amounts.push({name: "Not signed-up", amount: amountsMap.get("Not signed-up")});
    amounts.push({name: "Recommended", amount: amountsMap.get("Recommended")});
    amounts = amounts.sort((e1, e2) => e2.amount - e1.amount);
    setUpkeepStatusTypesWithAmounts(amounts)
  }, [maintenances, selectedItems, selectedMaintenanceType]);

  const selectUpkeepCategoryType = (type: string) => {
    const newSet = new Set(selectedUpkeepCategoryTypes);
    if (selectedUpkeepCategoryTypes.has(type)) {
      newSet.delete(type);
    } else {
      newSet.add(type);
    }
    setSelectedUpkeepCategoryTypes(newSet);
  }

  useEffect(() => {
    categoryToOpen && selectUpkeepCategoryType(categoryToOpen.replace("AND", "&"))
  }, [categoryToOpen])

  useEffect(() => {
    tabToOpen && setSelectedMaintenanceType(tabToOpen)
  }, [tabToOpen])

  const selectUpkeepStatusType = (type: string) => {
    const newSet = new Set(selectedUpkeepStatusTypes);
    if (selectedUpkeepStatusTypes.has(type)) {
      newSet.delete(type);
    } else {
      newSet.add(type);
    }
    setSelectedUpkeepStatusTypes(newSet);
  }

  const isEmpty = () => {
    if (filteredMaintenances().length === 0) return true
    else if (selectedMaintenanceType === "My maintenances" && maintenances.filter((m: any) => m.subscribed).length === 0) return true
    else if (selectedMaintenanceType === "Recommended" && maintenances.filter((m: any) => m.recommended).length === 0) return true
    else if (selectedMaintenanceType === "Not Applicable" && maintenances.filter((m: any) => m.notApplicable).length === 0) return true
    else return false
  }

  const sortMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      setSortingType("" + key);
      setSortMenuPopoverVisible(false);
    };

    return (
      <SortMenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="title">Title</Menu.Item>
        <Menu.Item key="startDate">Next date</Menu.Item>
        <Menu.Item key="frequencyHighToLow">Frequency (High to low)</Menu.Item>
        <Menu.Item key="frequencyLowToHigh">Frequency (Low to high)</Menu.Item>
        <Menu.Item key="costHighToLow">Cost (High to low)</Menu.Item>
        <Menu.Item key="costLowToHigh">Cost (Low to high)</Menu.Item>
      </SortMenuStyled>
    );
  };

  const MaintenancesSublist = (props: PropsSublist) => {
    const { type, maintenances } = props;
    if (maintenances.length === 0) return <></>;

    return (
      <CollapseStyled ghost expandIconPosition={"right"} defaultActiveKey={openMaintenanceTabs ?? maintenanceTypes}>
        <Panel key={type} header={<PanelHeaderWrapper onClick={() => toggleTab(type)}>
          <PanelHeaderColumn style={{alignItems: "center"}} spanWidth={"4%"}>
            {maintenanceIconDispenser(type)}
          </PanelHeaderColumn>
          <PanelHeaderColumn style={{justifyContent: "flex-start", columnGap: "1rem"}} spanWidth={"96%"}>
             {type}<ItemCount>{maintenances.length}</ItemCount>
          </PanelHeaderColumn>
        </PanelHeaderWrapper>}>
          <MaintenanceList maintenances={maintenances}/>
        </Panel>
      </CollapseStyled>
    )
  };

  const MaintenanceList = (props: PropsList) => {
    const { maintenances } = props;
    if (maintenances.length === 0) return <></>;

    return <ListStyled
      style={{width: '100%'}}
      itemLayout="horizontal"
      dataSource={maintenances}
      renderItem={(item: any) => <MaintenanceListItemNew
        item={item}
        key={"item"+item.id}
        setViewWorkId={setViewWorkId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setEditingExpenseData={setEditingExpenseData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditingUpkeepData={setEditingUpkeepData}
        toggleUpkeepDrawer={toggleUpkeepDrawer}
        editing={editing}
        setEditing={setEditing}
        isOnboarding={false}
        toggleSubscribe={toggleSubscribe}
        updateMaintenance={updateMaintenance}
      />}
    />
  }

  const emptySection = () => {
    if (selectedMaintenanceType === "Recommended" && !maintenancesSearch) {
      return <EmptyList><span>
        There are no recommended maintenances for you yet!<br/>
        For our recommendations engine, <PseudoLink onClick={() =>
          history.push("/questions?origin=upkeep")}>Build your home</PseudoLink> or <PseudoLink onClick={() =>
          history.push("/questions?openPage=contents&?origin=upkeep")}>Add contents to your property</PseudoLink>.
      </span></EmptyList>
    }
    else if (selectedMaintenanceType === "Not Applicable" && !maintenancesSearch) {
      return <EmptyList>There are no non-applicable maintenances currently identified.</EmptyList>
    }
    else if (selectedMaintenanceType === "My maintenances" && !maintenancesSearch) {
      return <EmptyList>
        You have not subscribed to upkeep tasks yet!<br/>
        Use the wizard to select tasks and populate your upkeep plan.<br/><br/>
        <IntroductionButton
          onClick={() => history.push("/questions?openPage=upkeep&origin=upkeep")}
          size={"large"}>Select upkeep tasks</IntroductionButton>
      </EmptyList>
    }
    else {
      return <EmptyList>No items to display.</EmptyList>
    }
  }

  return (
    <div>
      <MaintenancesTabsSection>
        <MaintenanceTabs
          selectedType={selectedMaintenanceType}
          setSelectedType={setSelectedMaintenanceType}
          maintenanceTypesMap={maintenanceTypesMap} />
      </MaintenancesTabsSection>
      <MaintenancesListSection>
        <MaintenancePageHeader>
          <SearchStyled
            size={"large"}
            onChange={(val) => setMaintenancesSearch(val.target.value)}
            placeholder={"Search upkeep..."}
            suffix={<SearchIcon/>}
          />
          <div style={{display: "flex", columnGap: "0.5rem"}}>
            <FilterButton onClick={() => toggleDisplayFilters(!displayFilters)}>
              <div style={{position: "relative"}}>
                {haveFiltersBeenUsed() && <DotIcon
                    style={{position: "absolute", top: "-0.25rem", left: "-0.3rem"}}/>}
                <FilterIcon style={{marginRight: "0.5rem"}} />
              </div>
              {displayFilters ? "Hide" : "Show"} filters
            </FilterButton>
            <Popover
              content={sortMenuDropdown()}
              className={"sortMenuWorks"}
              placement="bottom"
              trigger={["click"]}
              visible={sortMenuPopoverVisible}
              onVisibleChange={setSortMenuPopoverVisible}
            >
            <SortButton onClick={() => setSortMenuPopoverVisible(!sortMenuPopoverVisible)}>
              <SortIcon />
            </SortButton>
            </Popover>
          </div>
        </MaintenancePageHeader>

        {displayFilters && <Filters>
          <FilterGroupRow>
            <CategoryHeader>
              Filters <CancelButton onClick={() => {
              setSelectedItems([])
              setSelectedContents([])
              setSelectedTags([])
              setSelectedUpkeepCategoryTypes(new Set())
              setSelectedUpkeepStatusTypes(new Set())
            }}>Clear filters</CancelButton>
            </CategoryHeader>
          </FilterGroupRow>
          <FilterGroupRow style={{flexDirection: "column", rowGap: "1rem", border: 0, paddingBottom: 0}}>
            <Collapse bordered={false} ghost onChange={(key) => setActiveKeys(key)} defaultActiveKey={activeKeys}>
              <Panel showArrow={false} header={<SubcategoryHeader>
                Categories
                <div className={"button-wrapper"}>
                  {activeKeys.includes("categories") ? <CollapseIconMinus /> : <CollapseIconPlus />}
                </div>
              </SubcategoryHeader>} key="categories">
                <EditTagsBody style={{marginTop: 0}}>
                  <EditCategoryContainer>
                    <EditCategoryHeader>Upkeep category</EditCategoryHeader>
                    <EditCategoryBody>
                      {upkeepCategoryTypesWithAmounts.map(type => <TagStyled
                        key={"filterType" + type.name}
                        className={getClassesForUpkeepTypeTag(type)}
                        onClick={() => selectUpkeepCategoryType(type.name)}
                      >{type.name} ({type.amount})</TagStyled>)}
                    </EditCategoryBody>
                  </EditCategoryContainer>
                </EditTagsBody>
              </Panel>
              <Panel showArrow={false} header={<SubcategoryHeader style={{marginTop: "1rem"}}>
                Status and type
                <div className={"button-wrapper"}>
                  {activeKeys.includes("status") ? <CollapseIconMinus /> : <CollapseIconPlus />}
                </div>
              </SubcategoryHeader>} key="status">
                <EditTagsBody style={{marginTop: 0}}>
                  <EditCategoryContainer>
                    <EditCategoryHeader>Status</EditCategoryHeader>
                    <EditCategoryBody>
                      {upkeepStatusTypesWithAmounts.map(type => <TagStyled
                        key={"filterType" + type.name}
                        className={getClassesForUpkeepTypeTag(type)}
                        onClick={() => selectUpkeepStatusType(type.name)}
                      >{type.name} ({type.amount})</TagStyled>)}
                    </EditCategoryBody>
                  </EditCategoryContainer>
                  <EditCategoryContainer>
                    <EditCategoryHeader>Type of Content</EditCategoryHeader>
                    <EditCategoryBody>
                      <SelectStyled
                          className={"upkeep-tag-select"}
                          dropdownClassName={"upkeep-tag-select"}
                          dropdownStyle={{width: "100%", borderRadius: "12px", padding: "0.5rem"}}
                          mode="multiple"
                          placeholder="Add tags"
                          value={selectedItems}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => setSelectedItems(e)}
                          onSelect={(e) => Guid.isGuid(e) ? toggleSelectedContent(e) :
                            setSelectedTags([...selectedTags, e])}
                          onDeselect={(e) => Guid.isGuid(e) ? toggleSelectedContent(e) :
                            setSelectedTags(selectedTags.filter((tag: string) => tag !== e))}
                      >
                        {inventories.content.filter(i => i.name && i.relatedUpkeepsCount).map(i => <Option
                          className={"upkeep-content"}
                          key={i.id.toString()} value={i.id.toString()}>
                          {i.name} (Content)
                        </Option>)}
                        {_.orderBy(contentTypes, 'name', 'asc').map((tag: any) => <Option
                          key={tag.typeId} value={tag.name}>
                          {tag.name}
                        </Option>)}
                      </SelectStyled>
                    </EditCategoryBody>
                  </EditCategoryContainer>
                </EditTagsBody>
              </Panel>
            </Collapse>
          </FilterGroupRow>
        </Filters>}

        {/*<MaintenanceColumnWrapper>*/}
        {/*  <MaintenanceHeaderColumn spanWidth={"4%"}>Type</MaintenanceHeaderColumn>*/}
        {/*  <MaintenanceHeaderColumn style={{justifyContent: "flex-start"}} spanWidth={"36%"}>Item</MaintenanceHeaderColumn>*/}
        {/*  <MaintenanceHeaderColumn spanWidth={"10%"}>Cost</MaintenanceHeaderColumn>*/}
        {/*  <MaintenanceHeaderColumn spanWidth={"16%"}>Once Every</MaintenanceHeaderColumn>*/}
        {/*  <MaintenanceHeaderColumn spanWidth={"18%"}>Next Date</MaintenanceHeaderColumn>*/}
        {/*  <MaintenanceHeaderColumn spanWidth={"8%"}>Sign-up</MaintenanceHeaderColumn>*/}
        {/*  <MaintenanceHeaderColumn spanWidth={"8%"}>Edit</MaintenanceHeaderColumn>*/}
        {/*</MaintenanceColumnWrapper>*/}

      {maintenanceTypes.map((maintenanceType: string) => filteredMaintenances().filter((maintenance: any) =>
        maintenance.category && maintenance.category === maintenanceType).length > 0 && (
          <MaintenancesSublist
            key={maintenanceType+"sublist"}
            type={maintenanceType}
            maintenances={filteredMaintenances(maintenanceType)}
          />))}

        {isEmpty() ? dbMaintenances.fetchingStatus === TFetchingStatus.Success ? emptySection()
          : <EmptyList>Loading maintenances, please wait...</EmptyList> : null}
      </MaintenancesListSection>
    </div>
  );
};

const EmptyList = styled.div`
  width: 75%;
  padding: 3rem 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #666F7A;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
`

const ListStyled = styled(List)`
  & li:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const FilterButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  height: 2.5rem;
  flex-shrink: 0;
  padding: 1rem;
  
  border: 1px solid #DAE0E6;
  border-radius: 8px;
  color: #21272B;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  transition: all .25s ease;
  
  &:hover, &:focus {
    color: #40a9ff;
    border: 1px solid #40a9ff;
    transition: all .25s ease;
  }
  
  @media (max-width: 700px) {
    width: 100%;
    flex-shrink: 1;
  }
`

const SortButton = styled(FilterButton)`
  padding: 0;
  flex-shrink: 0;
  width: 2.5rem;
  
  &:hover, &:focus {
    color: #DAE0E6;
    border: 1px solid #DAE0E6;
  }
`

const MaintenancePageHeader = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f2f5;
  
  @media (max-width: 700px) {
    flex-direction: column;
    row-gap: 0.5rem;
  }
`

// const MaintenanceColumnWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   padding: 0.75rem 1.5rem;
//   column-gap: 0.5rem;
//   border-bottom: 1px solid #f0f2f5;
//
//   @media (max-width: 1300px) {
//     display: none;
//   }
// `

const MaintenancesTabsSection = styled.div`
`;

const MaintenancesListSection = styled.div`
  background-color: #fff;
  border-radius: 10px;
`;

const Search = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
`;

export const MaintenanceDetail = styled.span`
  width: ${(props: { spanWidth: string }) => props.spanWidth};
  text-align: left;
  
  @media (max-width: 875px) {
    width: 100%;
  }
`;

const PanelHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  column-gap: 0.5rem;
`

const MaintenanceColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: ${(props: { spanWidth: string }) => props.spanWidth};
  max-width: ${(props: { spanWidth: string }) => props.spanWidth};
  flex-grow: 1;
  
  font-size: 1rem;
  line-height: 1.5rem;
  color: #45655E;
`

const PanelHeaderColumn = styled(MaintenanceColumn)`
  align-items: center;
  color: #666F7A;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
`

const CollapseStyled = styled(Collapse)`
  width: 100%;
  padding: 0;
  
  border-radius: 10px;
  
  .anticon {
    margin-right: 0.25rem;
  }
  
  .ant-collapse-header {
    background: #F5F6FA;
    padding: 1rem 1.25rem!important;
    height: 4.2rem;
    display: flex;
    align-items: center;
  }
`;

const ItemCount = styled.div`
  background: #9CA4AE;
  border-radius: 16px;
  padding: 0 0.5rem;
  color: white;
`

// const MaintenanceHeaderColumn = styled(MaintenanceColumn)`
//   align-items: center;
//   color: #666F7A;
//   font-size: 0.875rem;
//   line-height: 1.25rem;
// `

const SearchStyled = styled(Search)`
  display: flex;
  flex-shrink: 10;
  height: 2.5rem;
  margin-right: 0.5rem;

  @media (max-width: 700px) {
      max-width: 100%;
      margin-right: 0;
  }
`

const PseudoLink = styled.span`
  cursor: pointer;
  color: #2a80e1;
  
  &:hover {
    color: #1890ff;
  }
`

export default MaintenanceListComp;