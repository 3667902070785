import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TEditNoteResponse = {
  recordId: Guid;
  title: string;
  message: string;
  createdDate: string;
  emergency: boolean;
  attachment: {
    attachmentId: Guid;
    propertyId: Guid;
    parentId: Guid;
    parentType: string;
    name: string;
    size: number;
  };
};

export type TEditNoteRequest = {
  title: string;
  message?: string;
  recordId?: Guid;
  noteId: Guid;
  propertyId: Guid;
  emergency?: boolean | false;
};

export const editNote = (payload: TEditNoteRequest) => {
  const { propertyId, noteId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/note/${noteId}`
  );

  let bodyFormData = new FormData();

  bodyFormData.append("recordId", payload.noteId.toString());
  bodyFormData.append("title", payload.title);
  payload.emergency && bodyFormData.append("emergency", ""+payload.emergency);
  payload.message && bodyFormData.append("message", payload.message);

  return apiPut<TEditNoteResponse, any>(url, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
