import React, {ReactElement} from "react";
import styled from "styled-components";

import {ReactComponent as FilterHouseEcoIcon} from "../../../images/guidance/FilterHouseEcoIcon.svg";
import {ReactComponent as FilterHouseResilienceIcon} from "../../../images/guidance/FilterHouseResilienceIcon.svg";
import {ReactComponent as FilterHouseSafetyIcon} from "../../../images/guidance/FilterHouseSafetyIcon.svg";
import {ReactComponent as FilterHouseSavingsIcon} from "../../../images/guidance/FilterHouseSavingsIcon.svg";
import {ReactComponent as FilterHouseWellbeingIcon} from "../../../images/guidance/FilterHouseWellbeingIcon.svg";
import {ReactComponent as SortEcoIcon} from "../../../images/guidance/GuidanceSortEco.svg";
import {ReactComponent as SortSavingsIcon} from "../../../images/guidance/GuidanceSortSavings.svg";
import {ReactComponent as SortCostsIcon} from "../../../images/guidance/GuidanceSortCosts.svg";
import {ReactComponent as SortEffortIcon} from "../../../images/guidance/GuidanceSortEffort.svg";
import {ReactComponent as SortCheckedIcon} from "../../../images/guidance/GuidanceSortChecked.svg";
import {ReactComponent as SearchIcon} from "../../../images/SearchGray.svg";
import {Input} from "antd";
import {getPropertyPreferencesSelector} from "../../selectors";
import {useSelector} from "react-redux";
import {getCurrencySign} from "../../screens/helpers";

type Props = {
  search: string;
  setSearch: (arg: string) => void;
  ecoPriority: number;
  setEcoPriority: (arg: number) => void;
  effortPriority: number;
  setEffortPriority: (arg: number) => void;
  savingsPriority: number;
  setSavingsPriority: (arg: number) => void;
  safetyPriority: number;
  setSafetyPriority: (arg: number) => void;
  costPriority: number;
  setCostPriority: (arg: number) => void;
  wellbeingPriority: number;
  setWellbeingPriority: (arg: number) => void;
  categories: Array<any>;
  selectedCategories: Array<any>;
  setSelectedCategories: (arg: Array<any>) => void;
}

type PriorityProps = {
  title: ReactElement;
  value: number;
  setValue: (arg: number) => void;
  type: 1 | 2 | 3 | 4;
}

const GuidanceSearchComp = (props: Props) => {
  const {search, setSearch, ecoPriority, setEcoPriority, effortPriority, setEffortPriority, costPriority, setCostPriority,
  wellbeingPriority, setWellbeingPriority, safetyPriority, setSafetyPriority, savingsPriority, setSavingsPriority,
  categories, selectedCategories, setSelectedCategories} = props;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const toggleCategory = (categoryId: number) => {
    const selectedCategory = selectedCategories.filter(c => c.id === categoryId)
    if (selectedCategory.length) {
      setSelectedCategories(selectedCategories.filter(c => c.id !== categoryId))
    } else {
      setSelectedCategories([...selectedCategories, ...categories.filter(c => c.id === categoryId)])
    }
  }

  const isCategorySelected = (categoryId: number) => selectedCategories.filter(c => c.id === categoryId).length > 0

  const prioritySwitch = (props: PriorityProps) => {
    const {title, value, setValue, type} = props
    return <PriorityWrapper>
      <PriorityLabel>{title}</PriorityLabel>
      <PrioritySelector>
        <PrioritySelectorElement className={value === 1 ? "selected blue" : ""} onClick={() => value === 1 ? setValue(0) : setValue(1)}>
          {value === 1 ? <SortCheckedIcon className={"blue-dot"}/> : <Dot className={"blue-dot"} />}
          <span>{type == 2 || type == 3 ? getCurrencySign(propertyPreferences.preferredCurrency) : "LOW"}</span>
        </PrioritySelectorElement>
        <PrioritySelectorElement className={value === 2 ? "selected yellow" : ""} onClick={() => value === 2 ? setValue(0) : setValue(2)}>
          {value === 2 ? <SortCheckedIcon className={"yellow-dot"}/> : <Dot className={"yellow-dot"} />}
          <span>{type == 2 || type == 3 ? `${getCurrencySign(propertyPreferences.preferredCurrency)}${getCurrencySign(propertyPreferences.preferredCurrency)}` : "MID"}</span>
        </PrioritySelectorElement>
        <PrioritySelectorElement className={value === 3 ? "selected "+(type == 3 || type == 4 ? "red": "green") : ""} onClick={() => value === 3 ? setValue(0) : setValue(3)}>
          {value === 3 ? <SortCheckedIcon className={type == 3 || type == 4 ? "red-dot": "green-dot"}/> : <Dot className={type == 3 || type == 4 ? "red-dot": "green-dot"} />}
          <span>{type == 2 || type == 3 ? `${getCurrencySign(propertyPreferences.preferredCurrency)}${getCurrencySign(propertyPreferences.preferredCurrency)}${getCurrencySign(propertyPreferences.preferredCurrency)} ` : "HIGH"}</span>
        </PrioritySelectorElement>
      </PrioritySelector>
    </PriorityWrapper>
  }

  return <>
    <SearchSortBlock style={{marginTop: 0}}>
      <SearchSortTitle>Search guidance</SearchSortTitle>
      <SearchSortDescription>Search our guidance measures and filter by specific terms</SearchSortDescription>
      <SearchStyled
        size={"large"}
        value={search}
        onChange={(val) => setSearch(val.target.value)}
        placeholder={"Search Guidance"}
        prefix={<SearchIcon/>}
      />
    </SearchSortBlock>
    <SearchSortSeparator />
    <SearchSortBlock>
      <SearchSortTitle style={{display: "flex", justifyContent: "space-between"}}>
        Filter guidance
        <ClearSortingButton onClick={() => {
          setSelectedCategories([])
          // setWellbeingPriority(0)
          setEffortPriority(0)
          setEcoPriority(0)
          // setSafetyPriority(0)
          setSavingsPriority(0)
          setCostPriority(0)
        }}>Clear filtering</ClearSortingButton>
      </SearchSortTitle>
      <SearchSortDescription>Select category importance to filter cards</SearchSortDescription>
      <SortPriorityWrapper>
        {prioritySwitch({title: <><SortEcoIcon/> <span>Eco<br/>Impact</span></>, value: ecoPriority, setValue: setEcoPriority, type: 1})}
        {prioritySwitch({title: <><SortSavingsIcon/> <span>Savings</span></>, value: savingsPriority, setValue: setSavingsPriority, type: 2})}
        {prioritySwitch({title: <><SortCostsIcon/> <span>Cost</span></>, value: costPriority, setValue: setCostPriority, type: 3})}
        {prioritySwitch({title: <><SortEffortIcon/> <span>Effort</span></>, value: effortPriority, setValue: setEffortPriority, type: 4})}
        {/*{prioritySwitch({title: "Safety", value: safetyPriority, setValue: setSafetyPriority})}*/}
        {/*{prioritySwitch({title: "Wellbeing", value: wellbeingPriority, setValue: setWellbeingPriority})}*/}
      </SortPriorityWrapper>
    </SearchSortBlock>
    <SearchSortSeparator />
    <SearchSortBlock>
      <SearchSortTitle>Guidance categories</SearchSortTitle>
      <SearchSortDescription>Select categories to see relevant guidance cards</SearchSortDescription>
      <GuidanceCategoryWrapper>
        <GuidanceCategoryButton
          className={isCategorySelected(2) ? "selected" : ""}
          onClick={() => toggleCategory(2)}>
          <FilterHouseEcoIcon /> Eco
        </GuidanceCategoryButton>
        <GuidanceCategoryButton
          className={isCategorySelected(1) ? "selected" : ""}
          onClick={() => toggleCategory(1)}>
          <FilterHouseSavingsIcon /> Savings
        </GuidanceCategoryButton>
        <GuidanceCategoryButton
          className={isCategorySelected(3) ? "selected" : ""}
          onClick={() => toggleCategory(3)}>
          <FilterHouseWellbeingIcon /> Wellbeing
        </GuidanceCategoryButton>
        <GuidanceCategoryButton
          className={isCategorySelected(6) ? "selected" : ""}
          onClick={() => toggleCategory(6)}>
          <FilterHouseSafetyIcon /> Safety
        </GuidanceCategoryButton>
        <GuidanceCategoryButton
          className={isCategorySelected(5) ? "selected" : ""}
          onClick={() => toggleCategory(5)}>
          <FilterHouseResilienceIcon /> Resilience
        </GuidanceCategoryButton>
      </GuidanceCategoryWrapper>
    </SearchSortBlock>
  </>
}

export default GuidanceSearchComp

export const SearchSortBlock = styled.div`
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  
  @media (max-width: 1024px) {
    padding: 0 1rem;
  }
`

export const SearchSortTitle = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #21272B;
`

export const SearchSortDescription = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #666F7A;
`

export const SearchSortSeparator = styled.div`
  width: 100%;
  background-color: #DAE0E6;
  height: 1px;
`

const GuidanceCategoryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  margin-top: 1rem;
`

const GuidanceCategoryButton = styled.div`
  cursor: pointer;
  border: 1px solid #C7C9C7;
  border-radius: 8px;
  width: 6.563rem;
  height: 3.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #45655E;
  transition: all .5s ease;
  
  &.selected {
    color: #67C18B;
    border: 1px solid #67C18B;
    transition: all .5s ease;
  }

  @media (min-width: 1024px) {
    &:hover {
      color: #67C18B;
      border: 1px solid #67C18B;
      transition: all .5s ease;
    }
  }
`

const SortPriorityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-top: 1.5rem;
`

const PriorityWrapper = styled.div`
  display: flex;
  height: 3rem;
`

const PriorityLabel = styled.div`
  display: flex;
  align-items: center;
  
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #000000;
  width: 8rem;
  
  svg {
    width: 2rem;
  }
  
  span {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
`

const PrioritySelector = styled.div`
  display: flex;
  border: 1px solid #DAE0E6;
  border-radius: 40px;
  width: 100%;
  max-width: 18rem;
  justify-content: space-between;
  align-items: center;
`;

const PrioritySelectorElement = styled.div`
  display: flex;
  align-items: center;
  width: 32%;
  font-size: 0.625rem;
  font-weight: 500;
  color: #21272B;
  cursor: pointer;
  height: 100%;

  span {
    opacity: 0.6;
    margin-left: 0.5rem;
  }
  
  &.selected {
    background: #FFFFFF;
    border-radius: 39px;
    
    &.green span {
      color: #67C18B;
      opacity: 0.9;
    }
    &.blue span {
      color: #2A80E1;
      opacity: 0.9;
    }
    &.yellow span {
      color: #EFC35D;
      opacity: 0.9;
    }
    &.red span {
      color: #EF624E;
      opacity: 0.9;
    }
  }
  
  svg {
    flex-shrink: 0;
    margin-left: 0.5rem;
  }
  
  &.selected svg.blue-dot circle {
    fill: #2A80E1;
  }

  &.selected svg.yellow-dot circle {
    fill: #EFC35D;
  }
  
  &.selected svg.green-dot circle {
    fill: #67C18B;
  }
  
  &.selected svg.red-dot circle {
    fill: #EF624E;
  }
`;

const Dot = styled.div`
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 100%;
  border: 1px solid #C7C9C7;
  background-color: #F1F1F2;
  flex-shrink: 0;
  margin-left: 0.5rem;
  
  &.blue-dot {
    background-color: #2A80E1;
  }

  &.yellow-dot {
    background-color: #EFC35D;
  }
  
  &.green-dot {
    background-color: #67C18B;
  }

  &.red-dot {
    background-color: #EF624E;
  }
`

const ClearSortingButton = styled.div`
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #C7C9C7;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #21272B;
  width: 5.5rem;
  height: 1.5rem;
`

const SearchStyled = styled(Input)`
  border: 1px solid #DAE0E6;
  box-sizing: border-box;
  border-radius: 6px;
  height: 3.25rem;
  margin-top: 1rem;
`