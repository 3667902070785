import React, {useEffect, useState} from "react";
import {ApplyButton, ButtonGroup, CancelButton, ModalStyled, Title} from "../maintenances/CustomFrequencyModal";
import {Subtitle} from "../../screens/PasswordReset";
import {DatePicker, notification} from "antd";
import {editWork} from "../../../api-wrapper/works/editWork";
import {getAllWorks} from "../../actions/works";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import moment from "moment";

type Props = {
  work: any;
  isModalOpen: boolean;
  toggleModal: (arg: boolean) => void;
  refreshParent?: () => void;
}

const RescheduleWorkModal = (props: Props) => {
  const {work, isModalOpen, toggleModal, refreshParent} = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const [newDate, setNewDate] = useState<any>(null);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);
  const currentDate = moment().endOf("day");

  useEffect(() => {
    if (work) {
      if (work.isComplete) {
        setNewDate(moment(work.completedDate))
      } else {
        moment(work.dueDate).isBefore(currentDate) ? setNewDate(currentDate) : setNewDate(moment(work.dueDate))
      }
    }
  }, [work])

  return <ModalStyled
    width={469}
    title={""}
    style={{zIndex: 100}}
    visible={isModalOpen}
    closeIcon={<></>}
    footer={null}
  >
    <Title>Reschedule project</Title>
    <Subtitle>Select a preferred date for this project.</Subtitle>
    <DatePicker
      value={newDate}
      size={'large'}
      format={propertyPreferences.preferredDateFormat}
      allowClear={false}
      inputReadOnly={true}
      style={{
        width: "100%",
        border: "1px solid #DAE0E6",
        boxSizing: "border-box",
        borderRadius: "6px",
        color: "rgb(107,113,133)",
        marginBottom: '2rem'
      }}
      onChange={(value) => setNewDate(value)}
    />
    <ButtonGroup>
      <CancelButton onClick={() => {
        setNewDate(null);
        toggleModal(false);
      }}>Cancel</CancelButton>
      <ApplyButton onClick={() => {
        editWork({
          ...work,
          completedDate: work.isComplete && moment().endOf("day").isAfter(newDate.startOf("day")) ? newDate.format("YYYY-MM-DD") : null,
          isComplete: work.isComplete ? moment().endOf("day").isAfter(newDate.startOf("day")) : false,
          dueDate: newDate.format("YYYY-MM-DD")}
        ).then(() => {
            notification.success({
              message: "Action successful",
              description: <>Project <b>{work.name}</b> has been rescheduled successfully on {newDate.format(propertyPreferences.preferredDateFormat)}.
                You can now view it in your Planner or on the Projects page.</>,
              placement: "topRight",
            });
            refreshParent && refreshParent();
            dispatch(getAllWorks(propertyId));
            toggleModal(false);
            setNewDate(null);
          })
      }}>Apply</ApplyButton>
    </ButtonGroup>
  </ModalStyled>
}

export default RescheduleWorkModal;