import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Dropdown, List, Menu, Modal, notification, Popover, Progress} from "antd";
import _ from "lodash";

import {
  getAttachmentsSelector,
  getExpensesSelector,
  getPropertyIdSelector,
  getStorageUsageSelector, getUserProfileSelector,
} from "../selectors";
import {ReactComponent as SearchIcon} from "../../images/SearchGray.svg";
import {getAllNotesByPropertyId} from "../actions/notesByPropertyId";
import {aggregateistOfTags, emptyGuid, hasSomeSelectedTags} from "./helpers";
import {ReactComponent as SortIcon} from "../../images/Sort.svg";

import {
  ButtonSort,
  ButtonText,
  Filters,
  AttachmentsListDesktop,
  AttachmentSearchWrapper,
  FilterGroupRow, ShowFiltersButton, MainListHeader, MainListColumn,
} from "./styled";
import styled from "styled-components";
import {ReactComponent as FileDOC} from "../../images/attachments/FileDoc.svg";
import {ReactComponent as FilePDF} from "../../images/attachments/FilePDF.svg";
import {ReactComponent as FilePPT} from "../../images/attachments/FilePPT.svg";
import {ReactComponent as FileXLS} from "../../images/attachments/FileXLS.svg";
import {ReactComponent as FilesFilterActiveIconMobile} from "../../images/attachments/FilesFilterActiveIconMobile.svg";
import {ReactComponent as FilesFilterIconMobile} from "../../images/attachments/FilesFilterIconMobile.svg";
import {ReactComponent as FilesSortIconMobile} from "../../images/attachments/FilesSortIconMobile.svg";
import {ReactComponent as FileImage} from "../../images/attachments/FileImage.svg";
import {ReactComponent as SelectedFilesAssign} from "../../images/attachments/SelectedFilesAssign.svg";
import {ReactComponent as SelectedFilesCross} from "../../images/attachments/SelectedFilesCross.svg";
import {ReactComponent as SelectedFilesDelete} from "../../images/attachments/SelectedFilesDelete.svg";
import {ReactComponent as SelectedFilesLink} from "../../images/attachments/SelectedFilesLink.svg";
import {ReactComponent as SelectedFilesTag} from "../../images/attachments/SelectedFilesTag.svg";
import {ReactComponent as HouseSmallBlackIcon} from "../../images/attachments/HouseSmallBlack.svg";
import ExpenseDrawer from "../components/expenses/ExpenseDrawer";
import {getAllExpenses, getAllExpenseTypes} from "../actions/expenses";
import {Guid} from "guid-typescript";
import {getAllAttachments} from "../actions/attachments";
import {attachmentsInitial, isSuperset, niceBytes} from "../components/helper";
import UploadFile from "../components/attachments/UploadFile";
import {TFetchingStatus} from "../../helpers";
import {getCurrentUserProfile} from "../actions/getUserProfile";
import FilterTagsNew, {CancelButton, CategoryHeader} from "../components/tags/FilterTagsNew";
import {getMyStorageUsage} from "../actions/storageUsage";
import ForwardAttachmentDrawer from "../components/attachments/ForwardAttachmentDrawer";
import PeriodFilter from "../components/filters/PeriodFilter";
import {ReactComponent as BoxMail} from "../../images/BoxMail.svg";
import {ReactComponent as CloseCircle} from "../../images/CloseCircle.svg";
import {
  getEmailedFilesState,
  getFileEmailedPopupState,
  getFileUploadPopupState,
  setFileEmailedPopupState,
  setFileUploadPopupState
} from "../../localStorage";
import { HeaderControlsWrapper, PageHeader, PageSubtitle, PageTitle, PageWrapper, fileUploadTooltip,
  Search, PaginationStyled, AlertStyled, EmptyStateWrapper} from "./components";
import {ReactComponent as FiltersIconActive} from "../../images/spaces/FiltersIconActive.svg";
import {ReactComponent as FiltersIcon} from "../../images/spaces/FiltersIcon.svg";
import FileItem from "../components/attachments/FileItem";
import {RadioStyled} from "../components/navigation/Header";
import ViewFileDrawer from "../components/attachments/ViewFileDrawer";
import LinkDrawer from "../components/spaces/LinkDrawer";
import InventoryDrawer from "../components/inventories/InventoryDrawer";
import ContactDrawer from "../components/contacts/ContactDrawer";
import NoteDrawer from "../components/notes/NoteDrawer";
import EditSpaceDrawer from "../components/spaces/EditSpaceDrawer";
import WorkDrawer from "../components/works/WorkDrawer";
import {getAllSpaces} from "../actions/spaces";
import EditFileDrawer from "../components/attachments/EditFileDrawer";
import NewlyUploadedDrawer from "../components/attachments/NewlyUploadedDrawer";
import AssignTagsDrawer from "../components/attachments/AssignTagsDrawer";
import {getAllWorks} from "../actions/works";
import {getAllInventories} from "../actions/inventories";
import {removeAttachment} from "../../api-wrapper/attachments/removeAttachment";
import {getAttachments} from "../../api-wrapper/attachments/getAllAttachments";
import AssignPropertySmallDrawer from "../components/attachments/AssignPropertySmallDrawer";
import AssignMultiPropertyDrawer from "../components/attachments/AssignMultiPropertyDrawer";
import {getAllMaintenances} from "../actions/maintenances";
import UpdateTaskDrawer from "../components/planner/UpdateTaskDrawer";
import AddEventDrawer from "../components/planner/AddEventDrawer";
import {CloseOutlined} from "@ant-design/icons";
import DocumentsIntroduction from "../components/attachments/DocumentsIntroduction";
import {ReactComponent as EmptyDocumentsIcons} from "../../images/empty-states/EmptyDocumentsIcons.svg";
import {assignAttachmentProperty} from "../../api-wrapper/attachments/assignAttachmentProperty";
import ViewWorkDrawer from "../components/works/ViewWorkDrawer";
const moment = require("moment");

const Files = () => {
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;

  const expenses = useSelector(getExpensesSelector);
  const attachments = useSelector(getAttachmentsSelector);
  const storageUsage = useSelector(getStorageUsageSelector);
  const userProfile = useSelector(getUserProfileSelector);
  const [attachmentsWithoutProperty, setAttachmentsWithoutProperty] = useState<any>([]);
  const [duplicateAttachments, setDuplicateAttachments] = useState<any>([]);
  const [attachmentsWithoutPropertyToDisplay, setAttachmentsWithoutPropertyToDisplay] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortMenuPopoverVisible, setSortMenuPopoverVisible] = useState(false);
  const [showFileUploadPopup, toggleFileUploadPopup] = useState(getFileUploadPopupState());
  const [showFileEmailedPopup, toggleFileEmailedPopup] = useState(getFileEmailedPopupState());

  const [sort, setSort] = useState("newestToOldest");
  const [search, setSearch] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [editTagsMode, toggleEditTagsMode] = useState(false);

  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [expenseData, setExpenseData] = useState<any>(null);
  const [selectedTags, setSelectedTags] = useState<any>([])

  const [isViewWorkDrawerOpen, toggleViewWorkDrawer] = useState(false);
  const [workToViewId, setWorkToViewId] = useState<any>(null);
  const [isInventoryDrawerOpen, toggleInventoryDrawer] = useState(false);
  const [editingInventoryData, setEditingInventoryData] = useState(null);
  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editingContactData, setEditingContactData] = useState(null);
  const [isNoteDrawerOpen, toggleNoteDrawer] = useState(false);
  const [editingNoteData, setEditingNoteData] = useState(null);
  const [isSpaceDrawerOpen, toggleSpaceDrawer] = useState(false);
  const [editSpaceId, setEditSpaceId] = useState(null);
  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editingWorkData, setEditingWorkData] = useState<any>(null);

  const [isTaskDrawerOpen, toggleTaskDrawer] = useState(false);
  const [editingTaskData, setEditingTaskData] = useState<any>(null);
  const [taskType, setTaskType] = useState("Task");
  const [isEventDrawerOpen, toggleEventDrawer]  = useState(false);

  const [isViewFileDrawerOpen, toggleViewFileDrawer] = useState(false);
  const [viewFileDrawerDefaultTab, setViewFileDrawerDefaultTab] = useState(null);
  const [attachmentToViewId, setAttachmentToViewId] = useState<any>(null);
  const [isLinkDrawerOpen, toggleLinkDrawer] = useState(false);
  const [isEditFileDrawerOpen, toggleEditFileDrawer] = useState(false);

  const [invoiceData, setInvoiceData] = useState<any>(null)
  const [isForwardDrawerOpen, toggleForwardDrawer] = useState(false);
  const [attachmentToForward, setAttachmentToForward] = useState(emptyGuid);

  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [selectedPredefinedDate, setSelectedPredefinedDate] = useState("custom");

  const [newlyUploadedDrawerOpen, toggleNewlyUploadedDrawer] = useState(false);
  const [newlyAttached, setNewlyAttached] = useState<any>();
  const [uploadInProgress, setUploadInProgress] = useState<Array<string>>([]);
  const [selectedFiles, setSelectedFiles] = useState<Set<Guid>>(new Set());
  const [allSelectedFilesSet, setAllSelectedFilesSet] = useState<Set<Guid>>(new Set());
  const [selectedFilesType, setSelectedFilesType] = useState("none");
  const [assignMultiPropertySelectedFiles, setAssignMultiPropertySelectedFiles] = useState<Set<Guid>>(new Set());
  const [allSelectedFiles, toggleAllSelectedFiles] = useState(false);
  const [isAssignTagDrawerOpen, toggleAssignTagDrawerOpen] = useState(false);
  const [presetLinkType, setPresetLinkType] = useState<any>(null);

  const [isAssignPropertySmallDrawerOpen, toggleAssignPropertySmallDrawerOpen] = useState(false);
  const [isAssignMultiPropertyDrawerOpen, toggleAssignMultiPropertyDrawerOpen] = useState(false);
  const [introductionStatus, setIntroductionStatus] = useState<any>(null);

  useEffect(() => {
    if (_.isEqual(propertyId, emptyGuid))
      return;
    dispatch(getAllExpenses(propertyId));
    dispatch(getAllWorks(propertyId));
    dispatch(getAllInventories(propertyId));
    dispatch(getAllNotesByPropertyId(propertyId));
    dispatch(getCurrentUserProfile());
    dispatch(getAllAttachments(propertyId));
    dispatch(getAllExpenseTypes(propertyId));
    dispatch(getAllSpaces(propertyId));
    dispatch(getAllMaintenances(propertyId));
    dispatch(getMyStorageUsage());
    setSelectedFiles(new Set());
    getAttachmentsWithoutProperty();
    setPage(1);
  }, [propertyId]);

  useEffect(() => {
    if (userProfile.fetchingStatus === TFetchingStatus.Success) {
      setIntroductionStatus(userProfile.content.documentsStatus);
    }
  }, [userProfile])

  const refreshAllAttachments = () => {
    dispatch(getAllAttachments(propertyId))
    getAttachmentsWithoutProperty();
    dispatch(getMyStorageUsage())
  };

  const getAttachmentsWithoutProperty = () => {
    getAttachments(propertyId, true).then(payload => {
      let skippedIds: any = []
      let duplicates: any = []
      let attachments: any = []
      payload && payload.map((a: any) => {
        if (a.duplicateFiles && !skippedIds.includes(a.attachmentId)) {
          skippedIds.push(...a.duplicateFiles)
          attachments.push(a)
        } else if (skippedIds.includes(a.attachmentId)) {
          duplicates.push(a)
        } else {
          attachments.push(a)
        }
      })
      setAttachmentsWithoutProperty(payload)
      setAttachmentsWithoutPropertyToDisplay(attachments)
      setDuplicateAttachments(duplicates)
    });
  }

  const assignPropertySingle = (selectedPropertyId: Guid, recordId: Guid) => {
    assignAttachmentProperty(selectedPropertyId, recordId).then(() => {
      refreshAllAttachments();
      toggleAssignPropertySmallDrawerOpen(false);
    });
  }

  const itemRender = (
    page: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
    originalElement: React.ReactElement<HTMLElement>
  ) => {
    if (type === "prev") {
      return (
        <Button
          style={{
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "6px",
          }}
        >
          <ButtonText>Prev</ButtonText>
        </Button>
      );
    } else if (type === "next") {
      return (
        <Button
          style={{
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "6px",
          }}
        >
          <ButtonText>Next</ButtonText>
        </Button>
      );
    }
    return originalElement;
  };

  const parseToLowerCase = (el: string) => el.toLowerCase();

  const filteredAttachments = () => {
    let atts = attachments.content
      .concat(attachmentsWithoutPropertyToDisplay)
      .filter((contentEl) => {
      return !_.isEmpty(search)
        ? (!_.isNil(contentEl.name) &&
          _.includes(
            parseToLowerCase(contentEl.name),
            parseToLowerCase(search)
          )) ||
          (!_.isNil(contentEl.description) &&
          _.includes(
            parseToLowerCase(contentEl.description),
            parseToLowerCase(search)
          )) ||
        (!_.isNil(contentEl.tags) &&
          _.includes(
            parseToLowerCase(aggregateistOfTags(contentEl.tags)),
            parseToLowerCase(search)
          ))
        : true;
    })
    .filter(file => {
      return !_.isNil(startDate)
        ? (moment(file.uploadedDate).add(1, 'day').valueOf() > startDate)
        : true;
    })
    .filter(file => {
      return !_.isNil(endDate)
        ? (moment(file.uploadedDate).valueOf() < endDate)
        : true;
    })
    .filter(contentEl => {
    return selectedTags.length > 0
      ? hasSomeSelectedTags(selectedTags, contentEl.tags)
      : true;
    });

    const topFiles = atts.filter(file => file.propertyId === null || (file.source === "email" && userProfile.content.previousLogin < file.uploadedDate));
    const bottomFiles = atts.filter(file => file.propertyId !== null && !(file.source === "email" && userProfile.content.previousLogin < file.uploadedDate));

    return sort === "newestToOldest"
      ? topFiles.sort(
        (n1, n2) =>
          moment(n2.uploadedDate).valueOf() -
          moment(n1.uploadedDate).valueOf()
      ).concat(bottomFiles.sort(
        (n1, n2) =>
          moment(n2.uploadedDate).valueOf() -
          moment(n1.uploadedDate).valueOf()
      ))
      : sort === "oldestToNewest"
        ? topFiles.sort(
          (n1, n2) =>
            moment(n1.uploadedDate).valueOf() -
            moment(n2.uploadedDate).valueOf()
        ).concat(bottomFiles.sort(
          (n1, n2) =>
            moment(n1.uploadedDate).valueOf() -
            moment(n2.uploadedDate).valueOf()
        ))
        : topFiles.concat(bottomFiles);
  };

  const getAttachmentsToDisplay = () =>
    filteredAttachments().filter(
      (currentValue, index) => index >= (page - 1) * pageSize && index < page * pageSize
    );

  useEffect(() => {
    if (attachments.fetchingStatus === TFetchingStatus.Success && getAttachmentsToDisplay().length === 0 && page !== 1) {
      setPage(page - 1)
    }
  }, [attachments.fetchingStatus, getAttachmentsToDisplay()])

  const sortMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const {key} = event;
      setSortMenuPopoverVisible(false);
      setSort("" + key);
    };

    return (
      <SortMenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="newestToOldest">Newest to oldest</Menu.Item>
        <Menu.Item key="oldestToNewest">Oldest to newest</Menu.Item>
      </SortMenuStyled>
    );
  };

  const deleteSelected = async (filesCount: number) => {
    const deletePromises = Array.from(selectedFiles.keys()).map(async (attachmentId: any) => {
      return await removeAttachment({
        propertyId,
        id: attachmentId,
      })
    });
    await Promise.all(deletePromises);
    setSelectedFiles(new Set());
    refreshAllAttachments();
    notification.success({
      message: "Successfully deleted",
      description:
        `${filesCount > 1 ? "All " : ""}${filesCount} document${filesCount > 1 ? "s" : ""} ha${filesCount > 1 ? "ve" : "s"} been successfully deleted.`,
      placement: "topRight",
    });
  }

  const confirmDelete = async (filesCount: number) => {
    Modal.confirm({
      title: `Delete selected file${filesCount > 1 ? "s" : ""}?`,
      content:
        `Are you sure you want to delete ${filesCount} document${filesCount > 1 ? "s" : ""}? You will not be able to undo this action.`,
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        deleteSelected(filesCount).then()
      },
    });
  }

  const filterSelection = (assignedFiles: boolean) => {
    const selectedIds = Array.from(selectedFiles)
    const selectedFileObjects = filteredAttachments().filter((item: any) => selectedIds.includes(item.attachmentId))

    if (assignedFiles) {
      const filteredFileIds: any = [];
      for (const item of selectedFileObjects) {
        if (item.propertyId) filteredFileIds.push(item.attachmentId)
      }
      setSelectedFiles(new Set(filteredFileIds));
    } else {
      const filteredFileIds: any = [];
      for (const item of selectedFileObjects) {
        if (!item.propertyId) filteredFileIds.push(item.attachmentId)
      }
      setSelectedFiles(new Set(filteredFileIds));
      setAssignMultiPropertySelectedFiles(new Set(filteredFileIds));
    }
  }

  const clearMultiPropertyFileSelection = () => {
    setSelectedFiles(new Set());
    setAssignMultiPropertySelectedFiles(new Set());
  }

  const selectAllFiles = (deselect: boolean) => {
    getAttachmentsToDisplay().filter(attachment => attachment.propertyId !== null).forEach(item => {
      if (deselect) {
        selectedFiles.delete(item.attachmentId);
      } else {
        selectedFiles.add(item.attachmentId);
      }
    });
    setSelectedFiles(new Set(selectedFiles));
  }

  useEffect(() => {
    let allFiles = new Set<Guid>();
    getAttachmentsToDisplay().filter(attachment => attachment.propertyId !== null).forEach(item => allFiles.add(item.attachmentId));
    setAllSelectedFilesSet(allFiles);
  }, [attachments, page, attachmentsWithoutPropertyToDisplay, search, startDate, endDate, selectedTags, sort])

  useEffect(() => {
    const selectedIds = Array.from(selectedFiles)
    const selectedFileObjects = filteredAttachments().filter((item: any) => selectedIds.includes(item.attachmentId))

    let includesUnassignedDocuments = false;
    let includesAssignedDocuments = false;
    for (const item of selectedFileObjects) {
      if (includesAssignedDocuments && includesUnassignedDocuments) break;
      if (item.propertyId) includesAssignedDocuments = true;
      else includesUnassignedDocuments = true
    }

    includesAssignedDocuments && includesUnassignedDocuments && setSelectedFilesType("both")
    includesAssignedDocuments && !includesUnassignedDocuments && setSelectedFilesType("assigned")
    !includesAssignedDocuments && includesUnassignedDocuments && setSelectedFilesType("unassigned")
    !includesAssignedDocuments && !includesUnassignedDocuments && setSelectedFilesType("none")
  }, [selectedFiles])

  useEffect(() => {
    toggleAllSelectedFiles(isSuperset(selectedFiles, allSelectedFilesSet))
  }, [selectedFiles, allSelectedFilesSet, page])

  const haveFiltersBeenUsed = () => selectedTags.length > 0 || startDate || endDate

  const displayEmailedFilesPopup = () => {
    let filesNumber = attachments.content.filter(attachment => attachment.source === "email" && userProfile.content.previousLogin < attachment.uploadedDate).length;
    const state = JSON.parse(getEmailedFilesState()+"");
    if (Array.isArray(state) && state.length > 0) {
      filesNumber = filesNumber - state.length;
    }

    if (!showFileEmailedPopup && filesNumber > 0 )
      return <UnassignedAttachmentsInfo style={{position: "relative"}}>
        <HouseSmallBlackIcon/>
        <div>
            You have received <span>{filesNumber} new file{filesNumber > 1 && "s"}</span> by email.
        </div>
        <CloseOutlined
            style={{position: "absolute", top: "0.25rem", right: 0}}
            onClick={() => {
              setFileEmailedPopupState("hidden");
              toggleFileEmailedPopup("hidden");
            }}
        />
    </UnassignedAttachmentsInfo>;
  }

  const displayFilters = () => (
    <Filters className={window.innerWidth > 1024 ? "" : "search-filters-desktop padded"}>
      <FilterGroupRow>
        <CategoryHeader>
          Filters <CancelButton onClick={() => {
          setEndDate(null);
          setStartDate(null);
          setSelectedPredefinedDate("custom");
          setSelectedTags([]);
        }}>Clear filters</CancelButton>
        </CategoryHeader>
      </FilterGroupRow>
      <PeriodFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedPredefinedDate={selectedPredefinedDate}
        setSelectedPredefinedDate={setSelectedPredefinedDate} />
      <FilterTagsNew
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        parentType={"attachment"}
        hideClearTagsButton={true}
      />
    </Filters>
  );

  const isFilesListEmpty = attachments.content.concat(attachmentsWithoutProperty).length === 0
  const showIntroduction = introductionStatus !== "skipped" && introductionStatus !== "completed"
  const showEmptyStateIntroduction = introductionStatus !== "skipped"

  const searchWrapperDesktop = () => (
    <AttachmentSearchWrapper>
      <Search
        style={{height: "3rem"}}
        size={"large"}
        value={search}
        onChange={(val) => setSearch(val.target.value)}
        placeholder={"Search documents..."}
        suffix={<SearchIcon/>}
      />
      <div style={{display: "flex", columnGap: "0.5rem"}}>
        <ShowFiltersButton
          size={"large"}
          onClick={() => setShowFilters(!showFilters)}
          icon={haveFiltersBeenUsed() ? <FiltersIconActive /> : <FiltersIcon />}
        >
          {showFilters ? "Hide" : "Show"} filters
        </ShowFiltersButton>
        <Popover
          content={sortMenuDropdown()}
          className={"sortMenuNotes"}
          visible={sortMenuPopoverVisible}
          placement="bottom"
          onVisibleChange={setSortMenuPopoverVisible}
          trigger={["click"]}
        >
          <ButtonSort
            size={"large"}
            icon={<SortIcon/>}
            onClick={() =>
              setSortMenuPopoverVisible(!sortMenuPopoverVisible)
            }
          />
        </Popover>
      </div>
    </AttachmentSearchWrapper>
  )

  const searchWrapperMobile = () => (
    <AttachmentSearchWrapper>
      <Search
        style={{height: "2.5rem", borderRadius: "32px"}}
        size={"large"}
        value={search}
        onChange={(val) => setSearch(val.target.value)}
        prefix={<SearchIcon/>}
      />
      <SortFilterIconMobile
        className={showFilters ? "active" : ""}
        onClick={() => setShowFilters(!showFilters)}
      >
        {haveFiltersBeenUsed() ? <FilesFilterActiveIconMobile /> : <FilesFilterIconMobile />}
      </SortFilterIconMobile>

      <Dropdown
        overlay={sortMenuDropdown()}
        className={"sortMenuNotes"}
        placement="bottomCenter"
        trigger={["click"]}
      >
        <SortFilterIconMobile>
          <FilesSortIconMobile />
        </SortFilterIconMobile>
      </Dropdown>
    </AttachmentSearchWrapper>
  )

  return (
    <PageWrapper className={"no-row-gap"}>
      <ForwardAttachmentDrawer
        isOpen={isForwardDrawerOpen}
        toggleDrawerOpen={toggleForwardDrawer}
        attachmentId={attachmentToForward}
      />
      <ExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        toggleDrawerOpen={toggleExpenseDrawer}
        expense={expenseData}
        setEditingExpenseData={setExpenseData}
        // highlightAttachment={highlightAttachment}
        refreshParent={refreshAllAttachments}
        contact={null}
        drawerTitle={null}
        icon={null}
        inventory={null}
        width={null}
        work={null}
        invoiceData={invoiceData}
      />
      <InventoryDrawer
        isOpen={isInventoryDrawerOpen}
        toggleDrawerOpen={toggleInventoryDrawer}
        inventory={editingInventoryData}
        // @ts-ignore
        setEditingInventoryData={setEditingInventoryData}
        refreshParent={() => {}}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
      />
      <ContactDrawer
        isOpen={isContactDrawerOpen}
        toggleDrawerOpen={toggleContactDrawer}
        contact={editingContactData}
        // @ts-ignore
        setEditingContactData={setEditingContactData}
        refreshParent={() => {}}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
      />
      <NoteDrawer
        currentType={"note"}
        data={editingNoteData}
        isOpen={isNoteDrawerOpen}
        toggleDrawerOpen={toggleNoteDrawer}
        refreshParent={() => {}}
        isGlobalNote={true}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        setPresetLinkType={setPresetLinkType}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleSpaceDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <WorkDrawer
        width={null}
        isOpen={isWorkDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        // @ts-ignore
        setEditingWorkData={setEditingWorkData}
        work={editingWorkData}
      />
      <EditSpaceDrawer
        spaceId={editSpaceId}
        isOpen={isSpaceDrawerOpen}
        toggleDrawerOpen={toggleSpaceDrawer}
        setEditingSpaceId={setEditSpaceId}
      />
      <UpdateTaskDrawer
        task={editingTaskData}
        setEditingTaskData={setEditingTaskData}
        isOpen={isTaskDrawerOpen}
        toggleDrawer={toggleTaskDrawer}
        taskType={taskType}
        setTaskType={setTaskType}
      />
      <AddEventDrawer
        isOpen={isEventDrawerOpen}
        toggleDrawer={toggleEventDrawer}
        setSelectedType={setTaskType}
        toggleWorkDrawer={toggleWorkDrawer}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditingWorkData={setEditingWorkData}
      />
      <ViewWorkDrawer
        workId={workToViewId}
        setWorkId={setWorkToViewId}
        isOpen={isViewWorkDrawerOpen}
        toggleDrawer={toggleViewWorkDrawer}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditingWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setLinkedWorkId={() => {}}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setWorkIdToForward={() => {}}
        toggleForwardDrawer={() => {}}
        toggleTradeDrawer={() => {}}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleSpaceDrawer}
        refreshParent={() => {}}
        setPresetLinkType={setPresetLinkType}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <LinkDrawer
        parentId={attachmentToViewId}
        parentSet={selectedFiles}
        setParentSet={setSelectedFiles}
        parentType={"attachment"}
        isOpen={isLinkDrawerOpen}
        presetLinkType={presetLinkType}
        setPresetLinkType={setPresetLinkType}
        toggleDrawerOpen={toggleLinkDrawer}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditingWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleSpaceDrawer}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        toggleEventDrawer={toggleEventDrawer}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <EditFileDrawer
        attachmentId={attachmentToViewId}
        isOpen={isEditFileDrawerOpen}
        toggleDrawerOpen={toggleEditFileDrawer}
      />
      <ViewFileDrawer
        attachmentId={attachmentToViewId}
        attachmentsWithoutProperty={attachmentsWithoutProperty}
        setAttachmentId={setAttachmentToViewId}
        toggleEditDrawerOpen={toggleEditFileDrawer}
        isOpen={isViewFileDrawerOpen}
        toggleDrawer={toggleViewFileDrawer}
        setPresetLinkType={setPresetLinkType}
        viewFileDrawerDefaultTab={viewFileDrawerDefaultTab}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditingWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleSpaceDrawer}
        selectedTags={selectedTags}
        toggleEditTagsMode={toggleEditTagsMode}
        refreshParent={() => {dispatch(getAllAttachments(propertyId))}}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        duplicateAttachments={duplicateAttachments}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
      />
      <NewlyUploadedDrawer
        isOpen={newlyUploadedDrawerOpen}
        toggleDrawerOpen={toggleNewlyUploadedDrawer}
        uploadInProgress={uploadInProgress}
        setUploadInProgress={setUploadInProgress}
        newlyAttached={newlyAttached}
        setNewlyAttached={setNewlyAttached}
        selectedTags={selectedTags}
        editTagsMode={editTagsMode}
        toggleEditTagsMode={toggleEditTagsMode}
        toggleForwardDrawer={toggleForwardDrawer}
        setAttachmentToForward={setAttachmentToForward}
        setInvoiceData={setInvoiceData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setExpenseData={setExpenseData}
        toggleVieFileDrawer={toggleViewFileDrawer}
        setAttachmentToViewId={setAttachmentToViewId}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        toggleAssignTagDrawerOpen={toggleAssignTagDrawerOpen}
        toggleLinkDrawer={toggleLinkDrawer}
        toggleEditDrawerOpen={toggleEditFileDrawer}
      />
      <AssignTagsDrawer
        attachmentIds={selectedFiles}
        setAttachmentIds={setSelectedFiles}
        isOpen={isAssignTagDrawerOpen}
        toggleDrawerOpen={toggleAssignTagDrawerOpen}
        toggleGlobalEditMode={toggleEditTagsMode}
      />
      <AssignPropertySmallDrawer
        recordId={attachmentToViewId}
        isOpen={isAssignPropertySmallDrawerOpen}
        toggleDrawerOpen={toggleAssignPropertySmallDrawerOpen}
        refreshParent={() => refreshAllAttachments()}
        assignPropertyParent={assignPropertySingle}
      />
      <AssignMultiPropertyDrawer
        isOpen={isAssignMultiPropertyDrawerOpen}
        toggleDrawerOpen={toggleAssignMultiPropertyDrawerOpen}
        attachmentsWithoutProperty={attachmentsWithoutProperty}
        refreshParent={() => refreshAllAttachments()}
        preSelectedFiles={assignMultiPropertySelectedFiles}
        clearSelection={clearMultiPropertyFileSelection}
        setViewFileDrawerDefaultTab={setViewFileDrawerDefaultTab}
        toggleViewFileDrawer={toggleViewFileDrawer}
        setAttachmentToPreviewId={setAttachmentToViewId}
      />
      <PageHeader className={"stack-mobile"}>
        <HeaderControlsWrapper>
          <div>
            <div style={{display: "flex", alignItems: "center"}}>
              <PageTitle>Documents</PageTitle>
              {fileUploadTooltip("file")}
            </div>
            <PageSubtitle>View & access all your important documents</PageSubtitle>
          </div>
          {window.innerWidth < 768 && <UploadFile
              fileList={[]}
              refreshParent={() => {dispatch(getAllAttachments(propertyId))}}
              singleAttachment={false}
              parentId={null}
              parentType={null}
              propertyId={propertyId}
              addToFileList={(attachmentId: Guid) => { setNewlyAttached([...newlyAttached, attachmentId]); toggleNewlyUploadedDrawer(true); }}
              uploadImmediately={false}
              isGlobalUpload={true}
              uploadOrigin={"mobile-files-page"}
          />}
        </HeaderControlsWrapper>
        <FilesQuotas>
          <div>
            Documents number limits
          </div>
          <StyledProgress
            type="circle"
            format={() => storageUsage.content.currentFilesAmount + '/' + storageUsage.content.maxFilesAmount}
            strokeColor={"#009966"}
            trailColor={"#DAE0E6"}
            strokeWidth={8}
            percent={100 * (storageUsage.content.currentFilesAmount / storageUsage.content.maxFilesAmount)}
            width={80}
            style={{borderRight: "1px solid #DAE0E6", paddingRight: "1rem"}}
          />

          <div>
            Storage
          </div>
          <StyledProgress
            type="circle"
            format={() => niceBytes((storageUsage.content.maxFilesSize - storageUsage.content.currentFilesSize).toString()) + " free"}
            strokeColor={"#009966"}
            trailColor={"#DAE0E6"}
            strokeWidth={8}
            percent={100 * (storageUsage.content.currentFilesSize / storageUsage.content.maxFilesSize)}
            width={80}
          />
        </FilesQuotas>
      </PageHeader>

      {!showFileUploadPopup && <AlertStyled
          message={<div style={{display: "flex", alignItems: "center"}}>
            <BoxMail style={{flexShrink: 0}}/>
            <span style={{color: "#6B7185", fontSize: "1rem", lineHeight: "1.5rem", marginLeft: "1.25rem"}}>
                You can forward documents that you want to save in Livlet as attachments in emails sent to <a href={`mailto:${process.env.REACT_APP_FILE_EMAIL}`}>
                {process.env.REACT_APP_FILE_EMAIL}</a>.
                <br/>See details in <a href={'https://www.livlet.com/faq?question=files'} target={"_blank"} rel={"noreferrer"}>FAQ</a>.
              </span>
          </div>}
          closable
          closeText={<CloseCircle/>}
          onClose={() => {
            setFileUploadPopupState("hidden")
            toggleFileUploadPopup("hidden")
          }}
      />}

      {displayEmailedFilesPopup()}

      {window.innerWidth >= 768 && <div>
        <UploadFile
          parentType={null}
          parentId={null}
          propertyId={propertyId}
          beforeUploadCallback={(open) => toggleNewlyUploadedDrawer(open)}
          uploadInProgress={uploadInProgress}
          setUploadInProgress={setUploadInProgress}
          addToFileList={(attachmentId: Guid) => setNewlyAttached(attachmentId)}
          uploadImmediately={true}
          singleAttachment={false}
          isAttachmentsPage={true}
          fileList={[]}
          refreshParent={refreshAllAttachments}
          // highlightAttachment={highlightAttachment}
          setShowFileUploadList={() => {}}
        />
      </div>}

      {attachmentsWithoutProperty.length > 0 && window.innerWidth > 1024 &&
        <UnassignedAttachmentsInfo>
          <HouseSmallBlackIcon/>
          <div>
            You have received <span>{attachmentsWithoutProperty.length} new file{attachmentsWithoutProperty.length > 1 && "s"}</span> by email. <a onClick={() => toggleAssignMultiPropertyDrawerOpen(true)}>Click here</a> to assign {attachmentsWithoutProperty.length === 1 ? "it" : "them"} to the correct property.
          </div>
        </UnassignedAttachmentsInfo>
      }

      {(isFilesListEmpty && showEmptyStateIntroduction || !isFilesListEmpty && showIntroduction) && <DocumentsIntroduction
        introductionStatus={introductionStatus}
        toggleNewlyUploadedDrawer={toggleNewlyUploadedDrawer}
        uploadInProgress={uploadInProgress}
        setUploadInProgress={setUploadInProgress}
        setNewlyAttached={setNewlyAttached}
      />}

      {isFilesListEmpty && !showEmptyStateIntroduction && <EmptyStateWrapper className={"unwrapped"} style={{marginBottom: "1rem"}}>
        <EmptyDocumentsIcons />
          <span>Add and manage your documents<br/>
          <span style={{fontWeight: 400}}>Add your document with invoices, drawings and plans.</span>
          </span>
          <UploadFile
              fileList={[]}
              refreshParent={() => dispatch(getAllAttachments(propertyId))}
              singleAttachment={false}
              parentId={null}
              parentType={null}
              propertyId={propertyId}
              beforeUploadCallback={(open) => toggleNewlyUploadedDrawer(open)}
              uploadInProgress={uploadInProgress}
              setUploadInProgress={setUploadInProgress}
              addToFileList={(attachmentId: Guid) => setNewlyAttached(attachmentId)}
              uploadImmediately={true}
              isGlobalUpload={true}
              isFileListEmpty={true}
          />
      </EmptyStateWrapper>}

      {((!_.isEmpty(attachments.content.concat(attachmentsWithoutPropertyToDisplay)) && !_.isEqual(attachments.content, attachmentsInitial)) &&
        (expenses.content.length !== 0 || expenses.fetchingStatus === TFetchingStatus.Success)
      ) && (
        <AttachmentsListDesktop className={selectedFiles.size > 0 ? "selected-files" : ""}>
          {window.innerWidth > 1024 && selectedFiles.size > 0 && <SelectedFilesPopup>
            <div className={"selected-button-wrapper"}>
              <div className={"selected-documents"}>{selectedFiles.size} document{selectedFiles.size > 1 && "s"} selected</div>
              {(selectedFilesType === "assigned" || selectedFilesType === "both") &&
              <div className={"selected-button selected-button-green"} onClick={() => {
                filterSelection(true);
                toggleAssignTagDrawerOpen(true);
              }}>
                <SelectedFilesTag/> Tag
              </div>}
              {(selectedFilesType === "assigned" || selectedFilesType === "both") &&
              <div className={"selected-button selected-button-green"} onClick={() => {
                filterSelection(true);
                setAttachmentToViewId(null);
                toggleLinkDrawer(true);
              }}>
                <SelectedFilesLink/> Link
              </div>}
              {(selectedFilesType === "unassigned" || selectedFilesType === "both") &&
              <div className={"selected-button selected-button-blue"} onClick={() => {
                filterSelection(false);
                toggleAssignMultiPropertyDrawerOpen(true);
              }}>
                <SelectedFilesAssign/> Assign
              </div>}
              <div className={"selected-button selected-button-red"} onClick={() => confirmDelete(selectedFiles.size)}>
                <SelectedFilesDelete/> Delete
              </div>
            </div>
            <SelectedFilesCross style={{cursor: "pointer"}} onClick={() => {
              setSelectedFiles(new Set());
              toggleAllSelectedFiles(false);
            }}/>
          </SelectedFilesPopup>}
          {window.innerWidth > 1024 ? searchWrapperDesktop() : searchWrapperMobile()}
          {showFilters && displayFilters()}
          {window.innerWidth > 1024 && <MainListHeader>
            <MainListColumn style={{justifyContent: "flex-start"}} spanWidth={"40%"}>
              <div style={{padding:"0 1.5rem 0 1rem", height: "100%"}} onClick={() => {
                selectAllFiles(allSelectedFiles)
                toggleAllSelectedFiles(!allSelectedFiles)
              }}>
                <RadioStyled checked={allSelectedFiles}/>
              </div>
              File name
            </MainListColumn>
            <MainListColumn style={{justifyContent: "flex-start"}} spanWidth={"35%"}>
                Tags
            </MainListColumn>
            <MainListColumn style={{justifyContent: "flex-start"}} spanWidth={"25%"}>
                Last updated
            </MainListColumn>
          </MainListHeader>}
          <ListStyled
            dataSource={getAttachmentsToDisplay()}
            renderItem={(item) => (
             <FileItem
               item={item}
               onDrawer={false}
               // highlightAttachmentId={highlightAttachmentId}
               // nameMap={nameMap}
               // setNameMap={setNameMap}
               // descriptionMap={descriptionMap}
               // setDescriptionMap={setDescriptionMap}
               // nameInEditId={nameInEditId}
               // setNameInEditId={setNameInEditId}
               // descriptionInEditId={descriptionInEditId}
               // setDescriptionInEditId={setDescriptionInEditId}
               duplicateFiles={duplicateAttachments}
               selectedFiles={selectedFiles}
               setSelectedFiles={setSelectedFiles}
               setAttachmentToEditId={setAttachmentToViewId}
               toggleEditDrawerOpen={toggleEditFileDrawer}
               selectedTags={selectedTags}
               editTagsMode={editTagsMode}
               toggleEditTagsMode={toggleEditTagsMode}
               toggleForwardDrawer={toggleForwardDrawer}
               setAttachmentToForward={setAttachmentToForward}
               setInvoiceData={setInvoiceData}
               toggleExpenseDrawer={toggleExpenseDrawer}
               setExpenseData={setExpenseData}
               toggleVieFileDrawer={toggleViewFileDrawer}
               setViewFileDrawerDefaultTab={setViewFileDrawerDefaultTab}
               setAttachmentToViewId={setAttachmentToViewId}
               toggleAssignPropertySmallDrawerOpen={toggleAssignPropertySmallDrawerOpen}
               refreshParent={refreshAllAttachments}
             />
            )}
          />
          {filteredAttachments().length > pageSize && <PaginationStyled
            className={filteredAttachments().length < pageSize ? "single-page-pagination files-pagination" : "files-pagination"}
            onChange={(page) => setPage(page)}
            defaultPageSize={pageSize}
            itemRender={itemRender}
            defaultCurrent={1}
            simple={window.innerWidth < 1024}
            showSizeChanger={false}
            total={filteredAttachments().length}
            current={page}
            onShowSizeChange={(current, size) => setPageSize(size)}
          />}
        </AttachmentsListDesktop>
      )}
    </PageWrapper>
  );
};

const SortMenuStyled = styled(Menu)`
  font-weight: 600;
  color: #21272b;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-radius: 6px;

  .ant-menu-item-active {
    color: #6b7185 !important;
  }

  .ant-menu-item {
    height: 1.5rem !important;
    padding-bottom: 2rem !important;
  }
`;

const StyledProgress = styled(Progress)`
  .ant-progress-text {
    font-size: 0.625rem;
  }
`;

const FilesQuotas = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  color: #666F7A;
  font-size: 0.75rem;
`;

export const UnassignedAttachmentsInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3rem;
  margin-bottom: 2rem;

  line-height: 1.125rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #0C2A41;
  letter-spacing: 0.1px;

  background: #EAF3FC;
  border: 1px solid #2A80E1;
  border-radius: 8px;
  
  svg {
    height: 40%;
    margin-right: 0.25rem;
  }
  div {
    margin-top: 0.2rem;
  }
  div span {
    color: #2A80E1;
  }
  div a {
    color: #2A80E1;
  }
`;


export const fileTypeIcon = (item: any) => {
  if (item.name.indexOf(".doc") !== -1) {
    return <FileDOC/>;
  }
  if (item.name.indexOf(".docx") !== -1) {
    return <FileDOC/>;
  }
  if (item.name.indexOf(".ppt") !== -1) {
    return <FilePPT/>;
  }
  if (item.name.indexOf(".xls") !== -1) {
    return <FileXLS/>;
  }
  if (item.name.indexOf(".csv") !== -1) {
    return <FileXLS/>;
  }
  if (item.name.indexOf(".txt") !== -1) {
    return <FileDOC/>;
  }
  if (item.name.indexOf(".rtf") !== -1) {
    return <FileDOC/>;
  }
  if (item.name.indexOf(".pdf") !== -1) {
    return <FilePDF/>;
  }
  return <div><FileImage style={{borderRadius: "48px"}}/></div>;
}

const SelectedFilesPopup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 0.75rem;
  z-index: 10;
  bottom: 2.75rem;
  
  position: fixed;
  height: 3rem;
  
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  
  .selected-documents {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: center;
    color: #0C2A41;
  }
  
  .selected-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.438rem 0.75rem;
    gap: 0.5rem;
    height: 2rem;
    border-radius: 4px;
    
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #FFFFFF;
    transition: .5s all ease;
  }
  
  .selected-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
  }
  
  .selected-button-blue { background: #2A80E1; }
  .selected-button-green { background: #009966; }
  .selected-button-red { background: #EB5757; }
  
  .selected-button-blue:hover { background: #2268B8; }
  .selected-button-green:hover { background: #008558; }
  .selected-button-red:hover { background: #D64F4F; }
  
  .selected-button-blue:active, .selected-button-blue:focus { background: #2A80E0; }
  .selected-button-green:active, .selected-button-green:focus { background: #00AD74; }
  .selected-button-red:active, .selected-button-red:focus { background: #FF5E5E; }
  
  @media (max-width: 1024px) {
    display: none;
  }
  
  @media (max-width: 768px) {
    .selected-button-wrapper { flex-wrap: wrap; }
  }
`

const ListStyled = styled(List)`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const SortFilterIconMobile = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background: #FFFFFF;
  border: 1px solid #DAE0E6;
  border-radius: 100%;
  transition: all .5s ease;
  flex-shrink: 0;
  
  // &.active { border: 1px solid #009966; }
`

export default Files;
