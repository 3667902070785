import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TAddPetRequest = {
  propertyId: Guid,
  name: string,
  petType: string
};

export const addPet = (payload: { petCreateDto: TAddPetRequest, petImage: any }) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/pet`);

  let bodyFormData = new FormData();
  bodyFormData.append('petCreateDto',
    new Blob([JSON.stringify(payload.petCreateDto)], {type: 'application/json; charset=UTF-8'}));
  bodyFormData.append("petImage", payload.petImage);

  return apiPost<any, any>(url, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};