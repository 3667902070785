import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {getNews, TGetNewsResponse} from "../../api-wrapper/news/getNews";
import {Guid} from "guid-typescript";

export const getNewsInitAction = createAction("news/GET_NEWS_INIT")();
export const getNewsSuccessAction = createAction(
  "news/GET_NEWS_SUCCESS"
)<TGetNewsResponse>();
export const getNewsFailureAction = createAction("news/GET_NEWS_FAILURE")();

export type TGetNewsInitAction = ActionType<typeof getNewsInitAction>;
export type TGetNewsSuccessAction = ActionType<typeof getNewsSuccessAction>;
export type TGetNewsFailureAction = ActionType<typeof getNewsFailureAction>;

export type TGetNewsActions =
  | TGetNewsInitAction
  | TGetNewsSuccessAction
  | TGetNewsFailureAction;

export type TGetNewsThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetNewsActions>;

export const getAllNews: TGetNewsThunkActionType = (propertyId: Guid) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getNewsInitAction());
  return getNews(propertyId)
    .then((payload) => {
      return dispatch(getNewsSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getNewsFailureAction()));
};
