import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";
import { push } from "react-router-redux";

import { TRootState } from "../reducers";
import {resetUpdate, TResetUpdateResponse, TResetUpdateRequest} from "../../api-wrapper/auth/resetUpdate";

export const resetUpdateInitAction = createAction("users/RESET_INIT")();
export const resetUpdateSuccessAction = createAction("users/RESET_SUCCESS")();
export const resetUpdateFailureAction = createAction("users/RESET_FAILURE")<TResetUpdateResponse>();

export type TResetUpdateInitAction = ActionType<typeof resetUpdateInitAction>;
export type TResetUpdateSuccessAction = ActionType<typeof resetUpdateSuccessAction>;
export type TResetUpdateFailureAction = ActionType<typeof resetUpdateFailureAction>;

export type TResetUpdateActions =
  | TResetUpdateInitAction
  | TResetUpdateSuccessAction
  | TResetUpdateFailureAction

export type TResetUpdateThunkActionType = ThunkAction<
  void,
  TRootState,
  null,
  TResetUpdateActions
  >;

type TResetUpdateAction = (authToken: string | null, recaptchaToken: string, payload: TResetUpdateRequest) => TResetUpdateThunkActionType;

export const updatePassword: TResetUpdateAction = (authToken: string | null, recaptchaToken: string, payload: TResetUpdateRequest) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(resetUpdateInitAction());
  return resetUpdate(authToken, recaptchaToken, payload)
    .then(() => {
      dispatch(push("/login"));
      return dispatch(resetUpdateSuccessAction());
    })
    .catch((err) => {
      if (err.response.status === 400 && err.response.data && err.response.data.internalCode === 4001)
        return dispatch(resetUpdateFailureAction({ timeoutError: false, captchaError: true, message: "" }));
      if (err.response.status === 400 && err.response.data && err.response.data.message && err.response.data.message.length > 0)
        return dispatch(resetUpdateFailureAction({ timeoutError: false, captchaError: false, message: err.response.data.message }));
      return dispatch(resetUpdateFailureAction({ timeoutError: true, captchaError: false, message: "" }));
    });
};