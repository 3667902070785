import React, {useEffect, useState} from "react";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";
import {ReactComponent as ReturnArrow} from "../../../images/spaces/ReturnArrow.svg";
import {
  ContainerItem,
  ContainerItemWrapper,
  ContainerTitle,
  SpaceContainer
} from "../spaces/AddSpaceOptions";
import {ReactComponent as WorksIcon} from "../../../images/spaces/SpaceLinkWorks.svg";
import styled from "styled-components";
import {CircleIcon, DrawerHeader, DrawerStyled} from "../../screens/components";
import {BoilerDrawerContent} from "./BoilerGasCertificateDrawer";
import _ from "lodash";
import {emptyGuid} from "../../screens/helpers";
import {useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import {ReactComponent as TradeLocksmiths} from "../../../images/partners/TradeLocksmiths.svg";

type Props = {
  isDrawerOpen: boolean;
  toggleDrawer: (arg: boolean) => void;
  includeBoilerServices?: boolean;
  toggleInsulationDrawer?: (arg: boolean) => void;
  selectedType: string;
  setSelectedType: (arg: string) => void;
}

export const Legalese = (hideFirstLine?: boolean, legaleseType?: string) => <LegaleseWrapper>
  {!hideFirstLine && !legaleseType && <>This service is provided by <span className={"company-name"}>Propertyfix Builders Ltd</span>,
  a third-party service provider offering certified builders.<br/><br/></>}
  {!hideFirstLine && legaleseType === "locksmiths" && <>This service is provided by <span className={"company-name"}>Keytek Locksmiths</span>,
      a third-party service provider offering DBS checked locksmiths nationwide.<br/><br/></>}
  {!hideFirstLine && legaleseType === "insulation" && <>This service is provided by <span className={"company-name"}>Instagroup</span>,
      a third-party service provider offering domestic insulation and solar panel installations.<br/><br/></>}
  {!hideFirstLine && legaleseType === "mortgage" && <>This service is provided by <span className={"company-name"}>Ashton Taylor Ltd</span>,
      a third-party mortgage service provider.<br/><br/></>}

  Livlet users are under no obligation to follow this recommendation and are free to procure the services of other service providers.
  <br/><br/>
  Where a Livlet user chooses to engage with affiliate partners, including booking a tradesperson or a service, Livlet does not guarantee
  the quality of the services provided by the relevant third parties and are not responsible for the acts & omissions of such third
  parties vis-à-vis of Livlet users.
</LegaleseWrapper>

const LocksmithsDrawerContent = () => (
  <TradesContainer className={"open stretched"}>
    <ContainerTitle><TradeLocksmiths style={{flexShrink: 0}}/>Locksmiths services</ContainerTitle>
    <ContainerItemWrapper>
      <TradeItem>
        <TradeDetails>
          <span>Organise a site visit by calling<br/><span className={"accent-green"}>0808 164 2928*</span>.</span>
          <span className={"disclaimer"}>*Standard network charges may apply.</span>
        </TradeDetails>
      </TradeItem>
    </ContainerItemWrapper>
  </TradesContainer>
)

export const supportedLocation = "England, United Kingdom"

export const ServiceUnavailable = () => (
  <TradesContainer className={"open"}>
    <ContainerTitle>
      This service is currently only available in England and we hope to expand our services to serve your area soon.
      <br/><br/>
      A valid UK address is required to enable this service - you can input this via the "Build my home" tool or from your Property details
    </ContainerTitle>
  </TradesContainer>
)

const TradeDrawer = (props: Props) => {
  const {isDrawerOpen, toggleDrawer, selectedType, setSelectedType, toggleInsulationDrawer, includeBoilerServices} = props;
  const [propertyLocation, setPropertyLocation] = useState<any>(null);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find((property) => property.propertyId === propertyId);
    if (!currentProperty)
      return;
    setPropertyLocation(`${currentProperty.ukCountry}, ${currentProperty.country}`)
  }, [propertyId])

  const handleCancel = () => {
    setSelectedType("")
    toggleDrawer(false)
  }

  return <DrawerStyled
    push={{ distance: "32px" }}
    closeIcon={false}
    width={window.innerWidth > 1024 ? "680px" : "100%"}
    height={window.innerWidth > 768 ? "100%" : "85%"}
    placement={window.innerWidth > 768 ? "right" : "bottom"}
    visible={isDrawerOpen && propertyLocation}
    onClose={() => handleCancel()}
    title={<DrawerHeader className={"edit-header"}>
      {window.innerWidth <= 768 && <ReturnArrow onClick={() => handleCancel()}/>}
      {selectedType === "Locksmiths" ? selectedType : "Book a trade"}
      {window.innerWidth > 768 && <CircleIcon onClick={() => handleCancel()}><CloseIcon /></CircleIcon>}
    </DrawerHeader>}
    >
    {propertyLocation === supportedLocation ? (selectedType === "" ? <TradesContainer className={"open"}>
      <ContainerTitle><WorksIcon style={{flexShrink: 0}}/>Trades</ContainerTitle>
      <ContainerItemWrapper>
        <ContainerItem className={"bold"} onClick={() => setSelectedType("Plumbing")}>
          Plumbing
        </ContainerItem>
        <ContainerItem className={"bold"} onClick={() => setSelectedType("Handyman")}>
          Handyman
        </ContainerItem>
        <ContainerItem className={"bold"} onClick={() => setSelectedType("Carpentry")}>
          Carpentry
        </ContainerItem>
        <ContainerItem className={"bold"} onClick={() => setSelectedType("Leaks")}>
          Leaks
        </ContainerItem>
        <ContainerItem className={"bold"} onClick={() => setSelectedType("Electrical")}>
          Electrical
        </ContainerItem>
        <ContainerItem className={"bold"} onClick={() => setSelectedType("Roof repairs")}>
          Roof repairs
        </ContainerItem>
        {includeBoilerServices && <ContainerItem className={"bold"} onClick={() => setSelectedType("Boiler")}>
            Boiler service
        </ContainerItem>}
        <ContainerItem className={"bold"} onClick={() => toggleInsulationDrawer && toggleInsulationDrawer(true)}>
          Insulation
        </ContainerItem>
        <ContainerItem className={"bold"} onClick={() => setSelectedType("Locksmiths")}>
          Locksmiths
        </ContainerItem>
        <ContainerItem className={"bold"} style={{width: includeBoilerServices ? "calc(100% / 2 - 0.25rem)" : "100%"}}
          onClick={() => setSelectedType("Drainage")}>
          Drainage
        </ContainerItem>

      </ContainerItemWrapper>
    </TradesContainer> : <>
      {selectedType === "Boiler" ? BoilerDrawerContent() : selectedType === "Locksmiths" ? LocksmithsDrawerContent() : <TradesContainer className={"open"}>
        <ContainerTitle>{selectedType}</ContainerTitle>
        <ContainerItemWrapper>
          <TradeItem>
            <TradeDetails>
              <span>Use number <span className={"accent"}>0117 313 3248*</span> to book a tradesperson or ask for a quote.</span>
              <span className={"disclaimer"}>*Standard network charges may apply.</span>
            </TradeDetails>
          </TradeItem>
        </ContainerItemWrapper>
      </TradesContainer>}
      {Legalese((selectedType === "Boiler"), selectedType === "Locksmiths" ? "locksmiths" : "")}
    </>) : ServiceUnavailable()}
  </DrawerStyled>
}

export const TradesContainer = styled(SpaceContainer)`
  padding: 1rem 4rem;
  
  &.stretched { 
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  
  @media (max-width: 768px) {
    padding: 1rem;
  }
`
export const TradeDisclaimer = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #3E5D58;
  margin-top: 0.5rem;
  
  .company-name {
    color: #3E5D58;
    text-decoration: underline;
  }
`

export const TradeItem = styled(ContainerItem)`
  width: 100%;
  height: auto;
  padding: 0.625rem 1rem;
  
  &:hover {
    box-shadow: none;
    border: 1px solid #F2F2F2;
  }
`

export const TradeDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  width: 100%;
  
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  color: #3E5D58;
  
  .accent { color: #16A540; }
  .accent-red { color: #FF3D00; }
  .accent-green { color: #009966; }
  
  .disclaimer { 
    font-weight: 400;
    font-size: 0.75rem;
 }
`

export const LegaleseWrapper = styled.div`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #3E5D58;
  
  .company-name, a {
    color: #3E5D58;
    text-decoration: underline;
  }
`

export default TradeDrawer