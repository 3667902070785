import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetCertificatesResponse } from "../../api-wrapper/certificates/getCertificates";
import {
  getCertificatesFailureAction,
  getCertificatesInitAction,
  getCertificatesSuccessAction,
  TGetCertificatesActions,
} from "../actions/certificates";
import {emptyGuid} from "../screens/helpers";

export type TCertificates = {
  fetchingStatus: TFetchingStatus;
  content: TGetCertificatesResponse;
};

const initialState: TCertificates = {
  content: [
    {
      propertyId: emptyGuid,
      certificateId: emptyGuid,
      certificateDate: "",
      endOfValidity: "",
      certificateType: "",
      amountPaid: 0,
      certificateNumber: 0,
      attachments: [],
      contacts: [],
      confirmed: true
    }
  ],
  fetchingStatus: TFetchingStatus.Defined,
};

export const certificatesReducer = createReducer<TCertificates, TGetCertificatesActions>(
  initialState
)
  .handleAction(getCertificatesInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getCertificatesSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getCertificatesFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
