import _ from "lodash";
import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TAddMortgageRequest = {
  provider: Guid | null,
  loanTotal: number,
  mortgageDate: string,
  durationYears: number,
  monthlyInstallment: number,
  initialPayment: number,
  fixedPeriodYears: number,
  interestRate: number
};

export const addMortgage = (payload: TAddMortgageRequest & { propertyId: Guid }) => {
  const { propertyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/mortgage`
  );

  return apiPost<any, TAddMortgageRequest>(url, _.omit(payload, ["propertyId"])
  );
};
