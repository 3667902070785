import styled from "styled-components";
import { AutoComplete, Menu } from "antd";

export const MenuStyled = styled(Menu)`
  .ant-dropdown-menu-item-disabled {
    color: rgba(0,0,0,0.25); 
  }
`;

export const MenuItemStyled = styled(Menu.Item)`
  font-weight: 600;
  font-size: 0.875rem;
  color: #21272b;

  display: flex;
  align-items: center;
  text-align: center;
`;

export const NoDataText = styled.span`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #45655E;
  
  a { color: #2F80ED; }
`;

export const NoDataInvoiceText = styled.span`
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #45655E;
  padding: 2rem 0;
  
  a { color: #2F80ED; }
`;

export const AutoCompleteStyled = styled(AutoComplete)`
  width: 100%;
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
  color: rgb(107, 113, 133);
`;