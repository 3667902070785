import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TGetAllAttachmentsResponse} from "../attachments/getAllAttachments";
import {TGetExpensesResponse} from "../expenses/getExpenses";
import {TGetWorksResponse} from "../works/getWorks";
import {TTag} from "../tags/addNewTag";

export type TGetInventoryByIdResponse = {
  inventoryId: Guid;
  inventoryNotes: Array<{
    recordId: Guid;
    title: string;
    message: string;
    createdDate: string;
    emergency: boolean;
    attachment: {
      attachmentId: Guid;
      propertyId: Guid;
      parentId: Guid;
      parentType: string;
      name: string;
      size: number;
    };
  }>;
  attachments: TGetAllAttachmentsResponse;
  inventoryWorks: TGetWorksResponse;
  expenses: TGetExpensesResponse;
  tags: Array<TTag>;
};

export const getInventoryById = (propertyId: Guid, inventoryId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/inventoryDetails/${inventoryId}`,
    {}
  );

  return apiGet<TGetInventoryByIdResponse>(url);
};
