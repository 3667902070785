import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import {TResetUpdateResponse} from "../../api-wrapper/auth/resetUpdate";
import {
  resetUpdateFailureAction,
  resetUpdateInitAction,
  resetUpdateSuccessAction,
  TResetUpdateActions
} from "../actions/resetUpdate";

export type TResetUpdate = {
  fetchingStatus: TFetchingStatus;
  content: TResetUpdateResponse;
};

const initialState: TResetUpdate = {
  content: {
    timeoutError: false,
    captchaError: false,
    message: "",
  },
  fetchingStatus: TFetchingStatus.Defined,
};

export const resetUpdateReducer = createReducer<TResetUpdate, TResetUpdateActions>(
  initialState
)
  .handleAction(resetUpdateInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(resetUpdateSuccessAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
    })
  )
  .handleAction(resetUpdateFailureAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
      draftState.content = action.payload;
    })
  );
