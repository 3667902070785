import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {
  expenseReport, TGetExpenseReportRequest,
  TGetExpenseReportResponse,
} from "../../api-wrapper/reports/expenseReport";
import {Guid} from "guid-typescript";

export const getExpenseReportInitAction = createAction(
  "ExpenseReport/GET_ExpenseReport_INIT"
)();
export const getExpenseReportSuccessAction = createAction(
  "ExpenseReport/GET_ExpenseReport_SUCCESS"
)<TGetExpenseReportResponse>();
export const getExpenseReportFailureAction = createAction(
  "ExpenseReport/GET_ExpenseReport_FAILURE"
)();

export type TGetExpenseReportInitAction = ActionType<
  typeof getExpenseReportInitAction
>;
export type TGetExpenseReportSuccessAction = ActionType<
  typeof getExpenseReportSuccessAction
>;
export type TGetExpenseReportFailureAction = ActionType<
  typeof getExpenseReportFailureAction
>;

export type TGetExpenseReportActions =
  | TGetExpenseReportInitAction
  | TGetExpenseReportSuccessAction
  | TGetExpenseReportFailureAction;

export type TGetExpenseReportThunkActionType = (
  request: TGetExpenseReportRequest,
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetExpenseReportActions>;

export const getExpenseReport: TGetExpenseReportThunkActionType = (
  request: TGetExpenseReportRequest,
  propertyId: Guid
) => (dispatch: (action: Action | Promise<Action>) => any) => {
  dispatch(getExpenseReportInitAction());
  return expenseReport(request, propertyId)
    .then((payload) => {
      return dispatch(getExpenseReportSuccessAction(payload?? {}));
    })
    .catch(() => dispatch(getExpenseReportFailureAction()));
};
