import { apiDelete, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export const removeMaintenance = (propertyId: Guid, maintenanceId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/alerts/trigger/${maintenanceId}`
  );

  return apiDelete(url);
};
