import {apiPatch, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export const updateFileName = (propertyId: Guid, attachmentId: Guid, name: string) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/attachment/${attachmentId}/name`
  );

  let bodyFormData = new FormData();

  bodyFormData.append("name", name);

  return apiPatch<any, any>(url, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
