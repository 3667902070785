import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Button, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import {Guid} from "guid-typescript";
import {SearchPropertyListItem} from "../../screens/SearchPage";
import {setPropertyId} from "../../actions/propertyId";
import { ReactComponent as ArrowRight } from "../../../images/search/ArrowRight.svg";

type Props = {
  isModalOpen: boolean,
  toggleModal: (arg: boolean) => void,
  destPropertyId: Guid;
  showDrawer: (arg: any) => void;
  item: any;
}

const SwitchPropertyModal = (props: Props) => {
  const { isModalOpen, toggleModal, destPropertyId, item, showDrawer } = props
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);
  const dispatch = useDispatch();

  const [propertyFrom, setPropertyFrom] = useState<any>();
  const [propertyTo, setPropertyTo] = useState<any>();

  useEffect(() => {
    if (!isModalOpen)
      return;

    setPropertyFrom(properties.find((property) => property.propertyId === propertyId));
    setPropertyTo(properties.find((property) => property.propertyId === destPropertyId));
  }, [isModalOpen]);

  if (!propertyFrom || !propertyTo || !item)
    return <></>;

  return (
    <ModalStyled
      width={window.innerWidth > 1024 ? 912 : "100%"}
      visible={isModalOpen}
      closable={false}
      footer={null}
    >
      <PropertiesLine>
        <SearchPropertyListItem style={{background:"#F5F6FA"}}>
          <img style={{objectFit: "cover", width:window.innerWidth > 768 ? "11rem" : "7rem", height: window.innerWidth > 768 ? "6rem" : "5rem", borderRadius: "4px"}} alt=""
               src={propertyFrom.imageThumbnail??"/images/NoPropertyImageWhite.png"}
               onError={(e) => {
                 // @ts-ignore
                 e.target.onError = null;
                 // @ts-ignore
                 e.target.src = "/images/search/NoPropertyImageWhite.png"
               }}
          />
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", fontSize: "0.75rem", fontWeight: 500}}>
              {(propertyFrom.nickname && propertyFrom.nickname.length > 0) ? propertyFrom.nickname.substring(0,15) : propertyFrom.address.substring(0,15)}
            </div>
          </div>
        </SearchPropertyListItem>
        <ArrowRight/>
        <SearchPropertyListItem style={{background:"#F5F6FA", border: "1px solid #009966"}}>
          <img style={{objectFit: "cover", width:window.innerWidth > 768 ? "11rem" : "7rem", height: window.innerWidth > 768 ? "6rem" : "5rem", borderRadius: "4px"}} alt=""
               src={propertyTo.imageThumbnail??"/images/NoPropertyImageWhite.png"}
               onError={(e) => {
                 // @ts-ignore
                 e.target.onError = null;
                 // @ts-ignore
                 e.target.src = "/images/search/NoPropertyImageWhite.png"
               }}
          />
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", fontSize: "0.75rem", fontWeight: 500}}>
              {(propertyTo.nickname && propertyTo.nickname.length > 0) ? propertyTo.nickname.substring(0,15) : propertyTo.address.substring(0,15)}
            </div>
          </div>
        </SearchPropertyListItem>
      </PropertiesLine>
      <div style={{fontWeight: 700, fontSize: "1.625rem", lineHeight: "1.75rem", color: "#3E5D58"}}>
        Do you want to switch to this property?
      </div>
      <div style={{fontSize: "0.875rem", lineHeight: "1.25rem", color: "#666F7A"}}>
        This search record is located within another property. Switching property will allow you to open these and review details.
      </div>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "start", columnGap: "1rem"}}>
        <SaveButton onClick={() => toggleModal(false)}>No, Stay here</SaveButton>
        <ConfirmButton onClick={() => {dispatch(setPropertyId(propertyTo.propertyId)); toggleModal(false);  setTimeout(() => showDrawer(true), 1000);}}>Yes, switch</ConfirmButton>
      </div>
    </ModalStyled>
  )
}

const ModalStyled = styled(Modal)`
  & .ant-modal-content {
    width: 36rem;
    border-radius: 8px;
  }
  
  & .ant-modal-body {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    padding: 2rem;
  }
  
  @media (max-width: 1024px) {
    & .ant-modal-body {
      padding: 2rem;
    }
    
    max-width: 100%;
    margin: 0;
    padding: 0;
    top: calc(100% - 27rem);
    & .ant-modal-content {
      width: 100%;
      border-radius: 0;
    }
  }
`;

const PropertiesLine = styled.div`
  color: #6B7185;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  column-gap: 1rem;
  
  @media (max-width: 850px) {
    width: 100%;
    &.illustration {
      display: none;
    }
  }
`


const SaveButton = styled(Button)`
  text-align: center;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  width: 8.75rem;
  height: 3.125rem;
`;

const ConfirmButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  width: 8.75rem;
  height: 3.125rem;
  background: #009966;
  color: white;
  transition: all .5s ease;
  
  &:hover { background: #22AB5B; }
`


export default SwitchPropertyModal;