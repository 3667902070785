import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetBudgetCostsResponse } from "../../api-wrapper/budgetCosts/getBudgetCosts";
import {
  getBudgetCostsFailureAction,
  getBudgetCostsInitAction,
  getBudgetCostsSuccessAction,
  TGetBudgetCostsActions,
} from "../actions/budgetCosts";
import {budgetCostsInitial} from "../components/helper";

export type TBudgetCosts = {
  fetchingStatus: TFetchingStatus;
  content: TGetBudgetCostsResponse;
};

const initialState: TBudgetCosts = {
  content: budgetCostsInitial,
  fetchingStatus: TFetchingStatus.Defined,
};

export const budgetCostsReducer = createReducer<TBudgetCosts, TGetBudgetCostsActions>(
  initialState
)
  .handleAction(getBudgetCostsInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getBudgetCostsSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getBudgetCostsFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
