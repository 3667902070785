import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import { useIntl } from "react-intl";
import {Button, Dropdown, List, Menu, Modal, Popover} from "antd";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";

import {
  getNotesByPropertyIdSelector,
  getPropertyIdSelector, getPropertyPreferencesSelector,
} from "../selectors";
import messages from "./messages";
import { ReactComponent as SearchIcon } from "../../images/SearchGray.svg";
import { getAllNotesByPropertyId } from "../actions/notesByPropertyId";
import {aggregateistOfTags, emptyGuid, hasSomeSelectedTags} from "./helpers";
import { ReactComponent as SortIcon } from "../../images/Sort.svg";
import { ReactComponent as SortIconMobile } from "../../images/SortIconMobile.svg";
import { ReactComponent as FiltersIconMobile } from "../../images/FilterIconMobile.svg";
import { ReactComponent as FiltersActiveIconMobile } from "../../images/FilterActiveIconMobile.svg";
import { ReactComponent as ExportIconMobile } from "../../images/ExportIconMobile.svg";
import {
  ButtonExport,
  ButtonSort,
  ButtonText,
  FilterGroupRow,
  Filters,
  MainListColumn, MainListHeader, MainListItemStyled,
  NotesListDesktop,
  Search,
  ShowFiltersButton,
} from "./styled";
import styled from "styled-components";
import NoteDrawer from "../components/notes/NoteDrawer";
import { ReactComponent as NotesIcon } from "../../images/Notes.svg";
import {getAllContacts} from "../actions/contacts";
import {removeNote} from "../../api-wrapper/notes/removeNote";
import WorkDrawer from "../components/works/WorkDrawer";
import InventoryDrawer from "../components/inventories/InventoryDrawer";
import ExpenseDrawer from "../components/expenses/ExpenseDrawer";
import ContactDrawer from "../components/contacts/ContactDrawer";
import {getAllWorks} from "../actions/works";
import {getAllInventories} from "../actions/inventories";
import {getAllExpenses} from "../actions/expenses";
import {Guid} from "guid-typescript";
import {addNote} from "../../api-wrapper/notes/addNote";
import {downloadExport} from "../components/helper";
import {ReactComponent as ExportIcon} from "../../images/Export.svg";
import {exportNotes} from "../../api-wrapper/notes/exportNotes";
import {MenuStyled} from "../components/works/WorksListComp";
import ForwardNoteDrawer from "../components/notes/ForwardNoteDrawer";
import FilterTagsNew, {CancelButton, CategoryHeader} from "../components/tags/FilterTagsNew";
import PeriodFilter from "../components/filters/PeriodFilter";
import TagContainer from "../components/tags/TagContainer";
import {getAllTagsWithUsageCountByType} from "../actions/tagsWithUsageCountByType";
import {
  AddRecordButton,
  EmptyRecordsDescription,
  EmptyRecordsHeader,
  EmptyRecordsList,
  HeaderControlsWrapper, PageHeader, PageSubtitle,
  PageTitle, PageWrapper, AddButtonStyled, PaginationStyled
} from "./components";
import {ReactComponent as FiltersIconActive} from "../../images/spaces/FiltersIconActive.svg";
import {ReactComponent as FiltersIcon} from "../../images/spaces/FiltersIcon.svg";
import {ReactComponent as LinkFileIcon} from "../../images/attachments/LinkFile.svg";
import {ReactComponent as FileActionsIcon} from "../../images/attachments/FileActions.svg";
import {ReactComponent as NoteNoImageIcon} from "../../images/NoteNoImageIcon.svg";
import {ReactComponent as NoteLinkIcon} from "../../images/NoteLinkIcon.svg";
import {ReactComponent as NoteTagIcon} from "../../images/NoteTagIcon.svg";
import {LinkedItemTag} from "../components/spaces/LinkedItem";
import ScrollContainer from "react-indiana-drag-scroll";
import UpdateTaskDrawer from "../components/planner/UpdateTaskDrawer";
import LinkDrawer from "../components/spaces/LinkDrawer";
import ViewWorkDrawer from "../components/works/ViewWorkDrawer";
import EditSpaceDrawer from "../components/spaces/EditSpaceDrawer";
import ViewFileDrawer from "../components/attachments/ViewFileDrawer";
import {getAllSpaces} from "../actions/spaces";

const moment = require("moment");

export const initialNoteData = {
  recordId: emptyGuid,
  title: "",
  message: "",
  createdDate: "",
  tags: [],
  thumbnails: [],
  attachment: {
    attachmentId: emptyGuid,
    propertyId: emptyGuid,
    parentId: emptyGuid,
    parentType: "",
    name: "",
    size: 0,
  },
};

const Notes = () => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sortMenuPopoverVisible, setSortMenuPopoverVisible] = useState(false);

  const [notesSort, setNotesSort] = useState("newestToOldest");
  const notesByPropertyId = useSelector(getNotesByPropertyIdSelector);
  const [search, setSearch] = useState("");
  const [isNoteDrawerOpen, toggleNoteDrawer] = useState(false);
  const [editingNoteData, setEditingNoteData] = useState<any>({data: initialNoteData});
  const [showFilters, setShowFilters] = useState(false);
  const [isForwardDrawerOpen, toggleForwardDrawer] = useState(false);
  const [noteToForward, setNoteToForward] = useState(emptyGuid);
  const [openInEditMode, toggleOpenInEditMode] = useState(false);

  const [highlightNote, setHighlightNote] = useState<null | Guid>(null);
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [selectedPredefinedDate, setSelectedPredefinedDate] = useState("custom");

  const [isViewWorkDrawerOpen, toggleViewWorkDrawer] = useState(false);
  const [workToViewId, setWorkToViewId] = useState<any>(null);
  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false);
  const [isInventoryDrawerOpen, toggleInventoryDrawer] = useState(false);
  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [expenseData, setExpenseData] = useState<any>(null);
  const [inventoryData, setInventoryData] = useState<any>(null);
  const [workData, setWorkData] = useState<any>(null);
  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [contactData, setContactData] = useState<any>(null);
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [editTagsMode, toggleEditTagsMode] = useState(false);

  const [isTaskDrawerOpen, toggleTaskDrawer] = useState(false);
  const [editingTaskData, setEditingTaskData] = useState<any>(null);
  const [taskType, setTaskType] = useState("Task");
  const [presetLinkType, setPresetLinkType] = useState<any>(null);
  const [isLinkDrawerOpen, toggleLinkDrawer] = useState(false);
  const [isEditSpaceDrawerOpen, toggleEditSpaceDrawer] = useState(false);
  const [editSpaceId, setEditSpaceId] = useState(null);
  const [isViewFileDrawerOpen, toggleViewFileDrawer] = useState(false);
  const [attachmentToViewId, setAttachmentToViewId] = useState<any>(null);

  const haveFiltersBeenUsed = () => selectedTags.length > 0 || startDate || endDate;

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    dispatch(getAllWorks(propertyId));
    dispatch(getAllContacts(propertyId));
    dispatch(getAllInventories(propertyId));
    dispatch(getAllExpenses(propertyId));
    dispatch(getAllNotesByPropertyId(propertyId));
    dispatch(getAllSpaces(propertyId));
    setPage(1);
  }, [propertyId]);

  const addNewNote = () => {
    addNote({
      propertyId,
    }).then((res) => {
      setEditingNoteData({data: res.data});
      dispatch(getAllNotesByPropertyId(propertyId));
      toggleNoteDrawer(true);
    });
  }

  const itemRender = (
    page: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
    originalElement: React.ReactElement<HTMLElement>
  ) => {
    if (type === "prev") {
      return (
        <Button
          style={{
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "6px",
          }}
        >
          <ButtonText>Prev</ButtonText>
        </Button>
      );
    } else if (type === "next") {
      return (
        <Button
          style={{
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "6px",
          }}
        >
          <ButtonText>Next</ButtonText>
        </Button>
      );
    }

    return originalElement;
  };

  const parseToLowerCase = (el: string) => el.toLowerCase();

  const filteredNotes = () => {
    let notes = notesByPropertyId
      .filter(note => note.title !== null)
      .filter((contentEl) => {
        return !_.isEmpty(search)
          ? (!_.isNil(contentEl.title) &&
              _.includes(
                parseToLowerCase(contentEl.title),
                parseToLowerCase(search)
              )) ||
              (!_.isNil(contentEl.message) &&
                _.includes(
                  parseToLowerCase(contentEl.message),
                  parseToLowerCase(search)
                )) ||
              (!_.isNil(contentEl.tags) &&
                _.includes(
                  parseToLowerCase(aggregateistOfTags(contentEl.tags)),
                  parseToLowerCase(search)
                ))
          : true;
      })
      .filter(note => {
        return !_.isNil(startDate)
          ? (moment(note.createdDate).add(1, 'day').valueOf() > startDate)
          : true;
      })
      .filter(note => {
        return !_.isNil(endDate)
          ? (moment(note.createdDate).valueOf() < endDate)
          : true;
      })
      .filter(note => {
        return selectedTags.length > 0
          ? hasSomeSelectedTags(selectedTags, note.tags)
          : true;
      })
    ;

    return notesSort === "newestToOldest"
      ? notes.sort(
          (n1, n2) =>
            moment(n2.createdDate).format("YYYYMMDD") -
            moment(n1.createdDate).format("YYYYMMDD")
        )
      : notesSort === "oldestToNewest"
      ? notes.sort(
          (n1, n2) =>
            moment(n1.createdDate).format("YYYYMMDD") -
            moment(n2.createdDate).format("YYYYMMDD")
        )
      : notes;
  };

  const getNotes = () =>
    filteredNotes()
    .filter(
      (currentValue, index) => index >= (page - 1) * pageSize && index < page * pageSize
    )

  useEffect(() => {
    if (getNotes().length === 0 && page !== 1) {
      setPage(page - 1)
    }
  }, [getNotes()])

  const sortMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      setSortMenuPopoverVisible(false);
      setNotesSort("" + key);
    };

    return (
      <SortMenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="newestToOldest">Newest to oldest</Menu.Item>
        <Menu.Item key="oldestToNewest">Oldest to newest</Menu.Item>
      </SortMenuStyled>
    );
  };

  const displayFilters = () => (
    <Filters className={"padded"}>
      <FilterGroupRow>
        <CategoryHeader>
          Filters <CancelButton onClick={() => {
          setEndDate(null);
          setStartDate(null);
          setSelectedPredefinedDate("custom");
          setSelectedTags([]);
        }}>Clear filters</CancelButton>
        </CategoryHeader>
      </FilterGroupRow>
      <PeriodFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedPredefinedDate={selectedPredefinedDate}
        setSelectedPredefinedDate={setSelectedPredefinedDate} />
      <FilterTagsNew
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        hideClearTagsButton={true}
        parentType={"note"}
      />
    </Filters>
  );

  function confirmDelete(propertyId: Guid, recordId: Guid) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        removeNote(propertyId, recordId).then(
          () => {
            setSearch("");
            dispatch(getAllNotesByPropertyId(propertyId));
            dispatch(getAllTagsWithUsageCountByType(propertyId, "note"))
          }
        );
      }
    });
  }

  const moreMenuDropdown = (item: any) => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;

      if (key === "edit") {
        toggleNoteDrawer(true);
        toggleOpenInEditMode(true);
        setEditingNoteData({ data: item });
      } else if (key === "delete") {
        confirmDelete(propertyId, item.recordId)
      } else if (key === "forward") {
        toggleForwardDrawer(true)
        setNoteToForward(item.recordId)
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="forward">Forward</Menu.Item>
        <Menu.Item key="edit">Edit</Menu.Item>
        <Menu.Item key="delete">Delete</Menu.Item>
      </MenuStyled>
    );
  };

  const noteItemLinks = (item: any) => (
    <NoteItemLinks>
      {item["worksCount"] > 0 && <LinkedItemTag
          className={"work"} onClick={() => toggleNoteDrawer(true)}>
        {item["worksCount"]} Project{item["worksCount"] > 1 && "s"}
      </LinkedItemTag>}
      {item["expensesCount"] > 0 && <LinkedItemTag
          className={"cost"} onClick={() => toggleNoteDrawer(true)}>
        {item["expensesCount"]} Expense{item["expensesCount"] > 1 && "s"}
      </LinkedItemTag>}
      {item["inventoriesCount"] > 0 && <LinkedItemTag
          className={"content"} onClick={() => toggleNoteDrawer(true)}>
        {item["inventoriesCount"]} Content{item["inventoriesCount"] > 1 && "s"}
      </LinkedItemTag>}
      {item["contactsCount"] > 0 && <LinkedItemTag
          className={"contact"} onClick={() => toggleNoteDrawer(true)}>
        {item["contactsCount"]} Contact{item["contactsCount"] > 1 && "s"}
      </LinkedItemTag>}
      {item["attachmentsCount"] > 0 && <LinkedItemTag
          className={"note"} onClick={() => toggleNoteDrawer(true)}>
        {item["attachmentsCount"]} Attachment{item["attachmentsCount"] > 1 && "s"}
      </LinkedItemTag>}
      {item["spacesCount"] > 0 && <LinkedItemTag
          className={"space"} onClick={() => toggleNoteDrawer(true)}>
        {item["spacesCount"]} Space{item["spacesCount"] > 1 && "s"}
      </LinkedItemTag>}
      {item["tasksCount"] > 0 && <LinkedItemTag className={"task"}>
        {item["tasksCount"]} Task{item["tasksCount"] > 1 && "s"}
      </LinkedItemTag>}
      {item["recurringExpensesCount"] > 0 && <LinkedItemTag className={"recurringExpense"}>
        {item["recurringExpensesCount"]} Recurring expense{item["recurringExpense"] > 1 && "s"}
      </LinkedItemTag>}
      {item["remindersCount"] > 0 && <LinkedItemTag className={"reminder"}>
        {item["remindersCount"]} Reminder{item["remindersCount"] > 1 && "s"}
      </LinkedItemTag>}
    </NoteItemLinks>
  )

  const noteLinksCount = (item: any) => {
    return item["worksCount"] + item["expensesCount"] + item["inventoriesCount"] + item["contactsCount"] +
    item["attachmentsCount"] + item["spacesCount"] + item["tasksCount"] + item["recurringExpensesCount"] + item["remindersCount"]
  }

  const noteItemDesktop = (item: any) => {
    return (
      <MainListItemStyled
        onClick={() => {
          toggleNoteDrawer(true);
          setEditingNoteData({ data: item });
        }}
      >
        <MainListColumn style={{justifyContent: "flex-start", flexDirection: "row", alignItems: "center", columnGap: "1rem", marginLeft:"1rem"}} spanWidth={ "40%"}>
          {NoteItemImages(item)}
          <NoteItemTitleWrapper>
            <NoteItemTitle>
              <Ellipsis lines={1}>{item.title}</Ellipsis>
            </NoteItemTitle>
            <NoteItemMessage>
              <Ellipsis lines={3}>{item.message}</Ellipsis>
            </NoteItemMessage>
            {noteItemLinks(item)}
          </NoteItemTitleWrapper>
        </MainListColumn>
        <MainListColumn className="small-tags" style={{justifyContent: "flex-start", flexDirection: "column"}} spanWidth={"35%"}>
          <TagContainer
            parentTags={item.tags}
            parentId={item.recordId}
            parentType={"note"}
            selectedTags={selectedTags}
            toggleGlobalEditMode={toggleEditTagsMode}
            refreshParent={() => dispatch(getAllNotesByPropertyId(propertyId))}
          />
        </MainListColumn>

        <MainListColumn style={{justifyContent: "space-between", alignItems: "center", flexDirection: "row", paddingRight: "1rem", fontWeight: 600, color: "#8E8E89"}} spanWidth={"25%"}>
          {moment(item.createdDate).format(propertyPreferences.preferredDateFormat)}
          <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row"}}>
            {item.propertyId && <LinkFileIcon
                onClick={(e) => {e.stopPropagation(); setEditingNoteData({ data: item }); toggleLinkDrawer(true)}}
                style={{marginRight:"1rem", cursor: "pointer", height: "2.5rem"}}
            />}
            <Dropdown
              overlay={moreMenuDropdown(item)}
              placement="bottomRight"
              trigger={["click"]}
            >
              <FileActionsIcon style={{cursor: "pointer", height: "2.5rem"}} onClick={(e) => e.stopPropagation()}/>
            </Dropdown>
          </div>
        </MainListColumn>
      </MainListItemStyled>
    )
  }

  const noteItemMobile = (item: any) => {
    return (
      <NoteMobileWrapper onClick={() => {
        toggleNoteDrawer(true);
        setEditingNoteData({ data: item });
      }}>
        {NoteItemImages(item)}
        <NoteMobileContentWrapper>
            <Ellipsis lines={1} className={"note-title"}>
              {item.title}
            </Ellipsis>
            <Ellipsis lines={3} className={"note-description"}>
              {item.message}
            </Ellipsis>
          <div className={"notes-tag-wrapper"}>
            <div className={"notes-tag"}><NoteTagIcon /> {item.tags.length} Tags</div>
            <div className={"notes-tag"}><NoteLinkIcon /> {noteLinksCount(item)} Links</div>
          </div>
        </NoteMobileContentWrapper>
      </NoteMobileWrapper>
    )
  }

  const searchWrapperDesktop = () => (
    <SearchWrapper>
      <SearchStyled
        size={"large"}
        value={search}
        onChange={(val) => setSearch(val.target.value)}
        placeholder={"Search notes..."}
        suffix={<SearchIcon />}
      />
      <ShowFiltersButton
        size={"large"}
        onClick={() => setShowFilters(!showFilters)}
        icon={haveFiltersBeenUsed() ? <FiltersIconActive /> : <FiltersIcon />}>
        {showFilters ? "Hide" : "Show"} filters
      </ShowFiltersButton>
      <ButtonExport
        className={"notes"}
        icon={<ExportIcon style={{flexShrink: 0}} />}
        onClick={() => {exportNotes({propertyId}).then((response: any) =>
          downloadExport(response, "application/vnd.openxmlformats-officedocument.wordprocessingml.document"))
        }}>
        {window.innerWidth > 1024 && <span style={{color: '#1890ff'}}>Export</span>}
      </ButtonExport>
      <Popover
        content={sortMenuDropdown()}
        className={"sortMenuNotes"}
        visible={sortMenuPopoverVisible}
        placement="bottom"
        onVisibleChange={setSortMenuPopoverVisible}
        trigger={["click"]}
      >
        <ButtonSort
          style={{width: "3rem"}}
          size={"large"}
          icon={<SortIcon />}
          onClick={() =>
            setSortMenuPopoverVisible(!sortMenuPopoverVisible)
          }
        />
      </Popover>
    </SearchWrapper>
  )

  const searchWrapperMobile = () => (
    <SearchWrapper>
      <SearchStyled
        value={search}
        onChange={(val) => setSearch(val.target.value)}
        placeholder={"Search notes..."}
        prefix={<SearchIcon />}
      />
      <IconWrapper onClick={() => setShowFilters(!showFilters)}>
        {haveFiltersBeenUsed() ? <FiltersActiveIconMobile /> : <FiltersIconMobile />}
      </IconWrapper>
      <Dropdown overlay={sortMenuDropdown()} placement="bottomCenter" trigger={["click"]}>
        <IconWrapper>
          <SortIconMobile />
        </IconWrapper>
      </Dropdown>
      <IconWrapper onClick={() => {exportNotes({propertyId}).then((response: any) =>
        downloadExport(response, "application/vnd.openxmlformats-officedocument.wordprocessingml.document"))
      }}>
        <ExportIconMobile />
      </IconWrapper>
    </SearchWrapper>
  )

  return (
    <PageWrapper>
      <ForwardNoteDrawer
        isOpen={isForwardDrawerOpen}
        toggleDrawerOpen={toggleForwardDrawer}
        noteId={noteToForward} />
      <WorkDrawer
        width={null}
        isOpen={isWorkDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        // @ts-ignore ugh
        setEditingWorkData={setWorkData}
        work={workData}
        highlightNote={highlightNote}
      />
      <InventoryDrawer
        isOpen={isInventoryDrawerOpen}
        toggleDrawerOpen={toggleInventoryDrawer}
        width={null}
        inventory={inventoryData}
        setEditingInventoryData={setInventoryData}
        highlightNote={highlightNote}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
      />
      <ExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        toggleDrawerOpen={toggleExpenseDrawer}
        expense={expenseData}
        setEditingExpenseData={setExpenseData}
        highlightNote={highlightNote}
        contact={null}
        drawerTitle={null}
        icon={null}
        inventory={null}
        width={null}
        work={null} />
      <ContactDrawer
        isOpen={isContactDrawerOpen}
        toggleDrawerOpen={toggleContactDrawer}
        contact={contactData}
        setEditingContactData={setContactData}
      />
      <UpdateTaskDrawer
        task={editingTaskData}
        setEditingTaskData={setEditingTaskData}
        isOpen={isTaskDrawerOpen}
        toggleDrawer={toggleTaskDrawer}
        taskType={taskType}
        setTaskType={setTaskType}
      />
      <ViewWorkDrawer
        workId={workToViewId}
        setWorkId={setWorkToViewId}
        isOpen={isViewWorkDrawerOpen}
        toggleDrawer={toggleViewWorkDrawer}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setLinkedWorkId={() => {}}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setWorkIdToForward={() => {}}
        toggleForwardDrawer={() => {}}
        toggleTradeDrawer={() => {}}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleEditSpaceDrawer}
        setPresetLinkType={setPresetLinkType}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <EditSpaceDrawer
        spaceId={editSpaceId}
        isOpen={isEditSpaceDrawerOpen}
        toggleDrawerOpen={toggleEditSpaceDrawer}
        setEditingSpaceId={setEditSpaceId}
      />
      <ViewFileDrawer
        attachmentId={attachmentToViewId}
        setAttachmentId={setAttachmentToViewId}
        isOpen={isViewFileDrawerOpen}
        toggleDrawer={toggleViewFileDrawer}
        viewFileDrawerDefaultTab={"previewOnly"}
        attachmentsWithoutProperty={[]}
        duplicateAttachments={[]}
        selectedTags={[]}
        toggleEditTagsMode={() => {}}
        toggleEditDrawerOpen={() => {}}
        setPresetLinkType={() => {}}
        toggleExpenseDrawer={() => {}}
        setEditExpenseData={() => {}}
        toggleInventoryDrawer={() => {}}
        setEditInventoryData={() => {}}
        toggleContactDrawer={() => {}}
        setEditContactData={() => {}}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        toggleWorkDrawer={() => {}}
        setEditWorkData={() => {}}
        toggleNoteDrawer={() => {}}
        setEditNoteData={() => {}}
        toggleLinkDrawerOpen={() => {}}
        setEditSpaceId={() => {}}
        toggleSpaceDrawer={() => {}}
        refreshParent={() => {}}
        toggleViewWorkDrawer={() => {}}
        setViewWorkId={() => {}}
      />
      <LinkDrawer
        parentId={editingNoteData?.data.recordId}
        parentType={"note"}
        isOpen={isLinkDrawerOpen}
        presetLinkType={presetLinkType}
        setPresetLinkType={setPresetLinkType}
        toggleDrawerOpen={toggleLinkDrawer}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setEditSpaceId={() => {}}
        toggleSpaceDrawer={() => {}}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        toggleEventDrawer={() => {}}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />

      <PageHeader>
        <HeaderControlsWrapper>
          <div>
            <PageTitle>Notes</PageTitle>
            <PageSubtitle>Use notes to document instructions, ideas & details related to your home</PageSubtitle>
          </div>
        </HeaderControlsWrapper>
        <HeaderControlsWrapper>
          <AddButtonStyled
            icon={<PlusOutlined />}
            onClick={() => addNewNote()}
          >
            {window.innerWidth > 1024 && f(messages.addNoteBtn)}
          </AddButtonStyled>
        </HeaderControlsWrapper>
      </PageHeader>
        <NoteDrawer
          data={editingNoteData.data.recordId === 0
            ? editingNoteData
            : {
            workNotes: {
              ...editingNoteData.data,
              attachment: _.isNil(editingNoteData.data.attachment)
                ? null
                : {
                    attachmentId: editingNoteData.data.attachment.attachmentId,
                    propertyId: editingNoteData.data.attachment.propertyId,
                    parentId: editingNoteData.data.attachment.parentId,
                    parentType: editingNoteData.data.attachment.parentType,
                    name: editingNoteData.data.attachment.name,
                    size: editingNoteData.data.attachment.size,
                  },
            },
            workId: editingNoteData.data.recordId,
            workName: "Notes",
          }}
          setEditWorkData={setEditingNoteData}
          currentType={"note"}
          isOpen={isNoteDrawerOpen}
          toggleDrawerOpen={toggleNoteDrawer}
          openInEditMode={openInEditMode}
          toggleOpenInEditMode={toggleOpenInEditMode}
          refreshParent={() => {}}
          icon={<NotesIcon className={"search-stroke-theme-icon"} />}
          isGlobalNote={true}
          toggleTaskDrawer={toggleTaskDrawer}
          setEditTaskData={setEditingTaskData}
          setPresetLinkType={setPresetLinkType}
          toggleLinkDrawerOpen={toggleLinkDrawer}
          setViewWorkId={setWorkToViewId}
          toggleViewWorkDrawer={toggleViewWorkDrawer}
          toggleExpenseDrawer={toggleExpenseDrawer}
          setEditExpenseData={setExpenseData}
          toggleInventoryDrawer={toggleInventoryDrawer}
          setEditInventoryData={setInventoryData}
          toggleContactDrawer={toggleContactDrawer}
          setEditContactData={setContactData}
          setEditSpaceId={setEditSpaceId}
          toggleSpaceDrawer={toggleEditSpaceDrawer}
          setAttachmentId={setAttachmentToViewId}
          toggleViewFileDrawer={toggleViewFileDrawer}
        />
      {notesByPropertyId.filter(note => !_.isNull(note.title) && note.title.length > 0).length !== 0 &&
        /*!_.isEqual(notesByPropertyId[0], initialNoteData) && */(
          <>
            <NotesListDesktop>
              {window.innerWidth > 768 ? searchWrapperDesktop() : searchWrapperMobile()}
              {showFilters && displayFilters()}
              {window.innerWidth > 1024 && <MainListHeader>
                  <MainListColumn style={{justifyContent: "flex-start", marginLeft:"1rem"}} spanWidth={"40%"}>
                      Note title
                  </MainListColumn>
                  <MainListColumn style={{justifyContent: "flex-start"}} spanWidth={"35%"}>
                      Tags
                  </MainListColumn>
                  <MainListColumn style={{justifyContent: "flex-start"}} spanWidth={"25%"}>
                      Created
                  </MainListColumn>
              </MainListHeader>}
              <ListStyled
                className="no-rounded-list"
                itemLayout="horizontal"
                dataSource={getNotes()}
                renderItem={(item) => noteItemDesktop(item)}
              />
              <ListMobile>
                {getNotes().map((item: any) => noteItemMobile(item))}
              </ListMobile>
              {filteredNotes().length > pageSize && <PaginationStyled
                className={filteredNotes().length < pageSize ? "single-page-pagination" : ""}
                onChange={(page) => setPage(page)}
                defaultPageSize={pageSize}
                simple={window.innerWidth < 1024}
                showSizeChanger={false}
                itemRender={itemRender}
                defaultCurrent={1}
                total={filteredNotes().length}
                current={page}
                onShowSizeChange={(current, size) => setPageSize(size)}
              />}
            </NotesListDesktop>
          </>
        )}
      {(notesByPropertyId.filter(note => !_.isNull(note.title)).length === 0 ||
        _.isEqual(notesByPropertyId[0], initialNoteData)) && (
        <EmptyRecordsList>
          <EmptyRecordsHeader>
            {f(messages.addNotesTitle)}
          </EmptyRecordsHeader>
          <EmptyRecordsDescription>
            {f(messages.addNotesMsg)}
          </EmptyRecordsDescription>
          <AddRecordButton
            onClick={() => addNewNote()}
            size={"large"}
            icon={<PlusOutlined />}
          >
            {f(messages.addNote)}
          </AddRecordButton>
        </EmptyRecordsList>
      )}
    </PageWrapper>
  );
};

export const NoteItemImages = (item: any, size?: string, noImageIcon?: React.ReactNode) => <NoteListImages style={size === "small" ? {height: "3.5rem", width: "3.5rem"} : {}}>
  {(!item || !item.thumbnails || item.thumbnails.length < 1)
    ? <div className={"no-image"}>{noImageIcon ? noImageIcon : <NoteNoImageIcon/>}</div>
    : item.thumbnails.length === 1
      ? <img className={"single-image"} alt="" src={item.thumbnails[0]}
             onError={(e) => {
               // @ts-ignore
               e.target.onError = null;
               // @ts-ignore
               e.target.src = "/images/file.png"
             }}/>
      : item.thumbnails.length === 2
        ? <div className={"double-image-container"}>
          <div className={"double-image-wrapper"}>
            <img className={"double-image"} alt="" src={item.thumbnails[0]}
                 onError={(e) => {
                   // @ts-ignore
                   e.target.onError = null;
                   // @ts-ignore
                   e.target.src = "/images/file.png"
                 }}/>
          </div>
          <div className={"double-image-wrapper"}>
            <img className={"double-image"} alt="" src={item.thumbnails[1]}
                 onError={(e) => {
                   // @ts-ignore
                   e.target.onError = null;
                   // @ts-ignore
                   e.target.src = "/images/file.png"
                 }}/>
          </div>
        </div>
        : <div className={"triple-image-container"}>
          <div className={"triple-image-single-wrapper"}>
            <img className={"triple-image-single"} alt="" src={item.thumbnails[0]}
                 onError={(e) => {
                   // @ts-ignore
                   e.target.onError = null;
                   // @ts-ignore
                   e.target.src = "/images/file.png"
                 }}/>
          </div>
          <div className={"triple-image-double-wrapper"}>
            <img className={"triple-image-double"} alt="" src={item.thumbnails[1]}
                 onError={(e) => {
                   // @ts-ignore
                   e.target.onError = null;
                   // @ts-ignore
                   e.target.src = "/images/file.png"
                 }}/>
            <img className={"triple-image-double triple-image-double-lower"} alt="" src={item.thumbnails[2]}
                 onError={(e) => {
                   // @ts-ignore
                   e.target.onError = null;
                   // @ts-ignore
                   e.target.src = "/images/file.png"
                 }}/>
          </div>
        </div>
  }
  </NoteListImages>

const SortMenuStyled = styled(Menu)`
  font-weight: 600;
  color: #21272b;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-radius: 6px;

  .ant-menu-item-active {
    color: #6b7185 !important;
  }

  .ant-menu-item {
    height: 1.5rem !important;
    padding-bottom: 2rem !important;
  }
`;

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1.25rem;
  padding: 0 1.25rem 0 1.25rem;
  column-gap: 0.5rem;
  
  @media (max-width: 768px) {
    background: #F0F2F5;
    padding: 0;
    margin-top: 0;
  }
`;

const SearchStyled = styled(Search)`
  @media (max-width: 768px) {
    width: 100%;
    borderL 1px solid #DAE0E6;
    border-radius: 8px;
    height: 2.5rem!important;
  }
`

const NoteItemTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.5rem;
  justify-content: space-between;
  width: 100%;
`;

const NoteItemTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: #002A43;
  column-gap: 0.375rem;
`;

const NoteItemMessage = styled.div`
  font-size: 0.625rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  color: #002A43;
  column-gap: 0.375rem;
`;

const NoteItemLinks = styled(ScrollContainer)`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  width: 100%;
  flex-wrap: wrap;
`;

const NoteListImages = styled.div`
  width: 6rem;
  height: 6.875rem;
  
  .no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .single-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  
  .double-image-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    height: 100%;
  }
  
  .double-image-wrapper {
    height: calc(50% - 0.125rem);
  }
  
  .double-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  
  .triple-image-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    height: 100%;
  }
  
  .triple-image-single-wrapper {
    height: calc(50% - 0.125rem);
  }
  
  .triple-image-single {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  
  .triple-image-double-wrapper {
    height: 50%;
    display: flex; 
    flex-direction: row;
    gap: 0.25rem;
  }
  
  .triple-image-double {
    object-fit: cover;
    width: calc(50% - 0.125rem);
    height: 100%;
    border-radius: 4px;
  }
  
  @media (max-width: 768px) {
    padding: 0.75rem;
    padding-bottom: 0;
    width: 100%;
    overflow: hidden;
    
    .single-image {
      border-radius: 6px 6px 0 0;
    }
    
    .double-image-container {
      flex-direction: row;
    }
    
    .double-image-wrapper {
      height: 100%;
      width: calc(50% - 0.125rem);
    }
    
    .double-image {
      border-radius: 6px 6px 0 0;
    }
    
    .triple-image-container {
      flex-direction: row;
    }
    
    .triple-image-single-wrapper {
      height: 100%;
      width: calc(50% - 0.125rem);
    }
    
    .triple-image-single { border-radius: 6px 6px 0 0; }
    
    .triple-image-double-wrapper {
      flex-direction: column;
      width: 50%;
      height: 100%;
    }
    
    .triple-image-double { 
      width: 100%;
      height: calc(50% - 0.125rem);
    }
    
    .triple-image-double-lower { border-radius: 6px 6px 0 0; }
  }
`;

const ListStyled = styled(List)`
  width: 100%;
  align-items: center;
  border-top: 1px solid #f0f0f0;
  
  @media (max-width: 768px) {
    display: none;
  }
`

const ListMobile = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    box-sizing: border-box;
    width: 100%;
    background: #F0F2F5;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  
  @media (max-width: 500px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`

const NoteMobileWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #DAE0E6;
  border-radius: 8px;
  width: 100%;
  padding: 0;
`

const NoteMobileContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  gap: 0.25rem;
  border-top: 1px solid #DAE0E6;
  
  .note-title {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    color: #21272B;
  }
  
  .note-description {
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 1rem;
    color: #000000;
    text-align: center;
  }

  .notes-tag-wrapper {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    width: 100%;
  }
  
  .notes-tag {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.5rem;
    gap: 0.25rem;
    background: #EFF1F5;
    border-radius: 4px;
    
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.75rem;
    color: #666F7A;
  }
`

const IconWrapper = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  border: 1px solid #DAE0E6;
  background: #F5F6FA;
  border-radius: 8px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`

export default Notes;
