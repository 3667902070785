import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TGetCalendarTasksResponse = {};

export const getCalendarTasks = (propertyId: Guid, startDate: string, monthlyView: boolean, amountOfPeriods: number) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/calendar?startDate=${startDate}&monthlyView=${monthlyView}&amountOfPeriods=${amountOfPeriods}`);

  return apiGet<TGetCalendarTasksResponse>(url, {}, propertyId);
};
