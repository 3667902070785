import axios, { AxiosRequestConfig } from "axios";
import _, { isEmpty } from "lodash";
import queryString from "query-string";

import {clearAccessToken, getAccessToken, saveAccessToken} from "./auth/auth";
import store from "../configureStore";
import { push } from "react-router-redux";
import {refreshTokenRequest} from "./auth/logIn";
import {Guid} from "guid-typescript";
import {emptyGuid} from "../app/screens/helpers";

export const defaultAxiosConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

// TODO: uncomment when refresh token will be implemented
let isRefreshing = false;
let failedRequest: any[] = [];

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const currentRequest = err.config;

    if (err?.message === "search")
      return;

    if (!err.response) {
      clearAccessToken();
      store.dispatch(push("/login"));
      return;
    }

    //TODO: change err.response.data === "Session expired!" after BE changes
    if (err.response.status === 401) {
      // clearAccessToken();
      // store.dispatch(push("/login"));
      // TODO: uncomment when refresh token will be implemented
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedRequest.push({ resolve, reject });
        })
          .then((bearerToken) => {
            currentRequest.headers["Authorization"] = bearerToken;
            return axios(currentRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      currentRequest._retry = true;
      isRefreshing = true;
      return new Promise((resolve) => {
        refreshTokenRequest(getAccessToken())
          .then((payload) => {
            const newAccessToken = payload.accessToken;
            const bearerToken = getBearerToken(newAccessToken);

            currentRequest.headers["Authorization"] = bearerToken;
            resolve(axios(currentRequest));

            failedRequest.forEach((promiseRequest) =>
              promiseRequest.resolve(bearerToken)
            );
            failedRequest = [];

            saveAccessToken(payload.accessToken);
          })
          .catch((resolve) => {
            // if (response && response.message === "Invalid token specified") {
            clearAccessToken();
            store.dispatch(push("/login"));
            // }
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    } else if (err.response.status === 406 || err.response.status === 409 ||
      err.response.status === 400 || err.response.status === 424 || err.response.status === 412) {
      throw err.response;
    }
  }
);

const getBearerToken = (token: string) => {
  return `Bearer:${token}`;
}

export function addAuthToken() {
  const accessToken = getAccessToken();
  return accessToken ? { Authorization: `Bearer:${accessToken}` } : {};
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiGet = async <RES extends any>(
  queryUrl: string,
  axiosOptions?: AxiosRequestConfig,
  propertyId?: Guid
) => {
  const params = {
    ...axiosOptions,
    headers: addAuthToken(),
  };

  if (propertyId && (propertyId.toString() === emptyGuid.toString() || _.isEqual(propertyId, emptyGuid.toJSON()))) {
    return;
  }

  const { data } = await axios.get<RES>(queryUrl, {
    ...params,
  });
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiPost = async <RES extends any, REQ extends any>(
  queryUrl: string,
  requestBody?: REQ,
  axiosOptions?: AxiosRequestConfig
) => {
  const registerOrLoginUrl =
    queryUrl.includes("/register") || queryUrl.includes("/login") || queryUrl.includes("/refresh_token");
  const params = registerOrLoginUrl
    ? { ...axiosOptions }
    : {
        ...axiosOptions,
        headers: { ...addAuthToken(), ...axiosOptions?.headers },
      };

  try {
    const {data, headers} = await axios.post<RES>(queryUrl, requestBody, {
      ...params,
    });

    return headers && headers.authorization
      ? {accessToken: headers.authorization.split(":")[1]}
      : {data, accessToken: " "};
  } catch (error) {
    throw error
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiPut = async <RES extends any, REQ extends any>(
  queryUrl: string,
  requestBody?: REQ,
  axiosOptions?: AxiosRequestConfig
) => {
  const params = {
    ...axiosOptions,
    headers: { ...addAuthToken(), ...axiosOptions?.headers },
  };

  const { data } = await axios.put<RES>(queryUrl, requestBody, {
    ...params,
  });
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiPatch = async <RES extends any, REQ extends any>(
  queryUrl: string,
  requestBody?: REQ,
  axiosOptions?: AxiosRequestConfig
) => {
  const params = {
    ...axiosOptions,
    headers: { ...addAuthToken(), ...axiosOptions?.headers },
  };

  const { data } = await axios.patch<RES>(queryUrl, requestBody, {
    ...params,
  });
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiDelete = async <RES extends any, REQ extends any>(
  queryUrl: string,
  //requestBody?: REQ,
  axiosOptions?: AxiosRequestConfig
) => {
  const params = {
    ...axiosOptions,
    headers: addAuthToken(),
  };

  const { data } = await axios.delete<RES>(queryUrl, {
    ...params,
  });
  return data;
};

export const createUrl = (queryUrl: string, queryParams = {}) => {
  if (isEmpty(queryParams)) {
    return queryUrl;
  }
  return queryUrl + "?" + queryString.stringify(queryParams);
};
