import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetStorageUsageResponse } from "../../api-wrapper/attachments/getStorageUsage";
import {
  getStorageUsageFailureAction,
  getStorageUsageInitAction,
  getStorageUsageSuccessAction,
  TGetStorageUsageActions,
} from "../actions/storageUsage";

export type TStorageUsage = {
  fetchingStatus: TFetchingStatus;
  content: TGetStorageUsageResponse;
};

const initialState: TStorageUsage = {
  content: {
    maxFilesSize: 0,
    currentFilesAmount: 0,
    currentFilesSize: 0,
    maxFilesAmount: 0
  },
  fetchingStatus: TFetchingStatus.Defined,
};

export const storageUsageReducer = createReducer<TStorageUsage, TGetStorageUsageActions>(
  initialState
)
  .handleAction(getStorageUsageInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getStorageUsageSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getStorageUsageFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
