import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TGetMaintenanceTotalCostResponse = {
  totalCostYear: number,
  totalCostTaxYear: number
};

export const getMaintenanceTotalCost = (propertyId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/upkeeps/totalCost`);

  return apiGet<TGetMaintenanceTotalCostResponse>(url, {}, propertyId);
};
