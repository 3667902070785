export interface EnvConfig {
  API_URL: string;
  APP_URL: string;
  GOOGLE_RECAPTCHA_SITE_KEY: string;
  GOOGLE_TAG: string;
  PRODUCTION_TRACKING: string;
}

const envConfig: EnvConfig = {
  API_URL: process.env.REACT_APP_API_URL  || "https://stage-api.livlet.com",
  APP_URL: process.env.REACT_APP_APP_URL  || "https://uk.livlet.com",
  GOOGLE_RECAPTCHA_SITE_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || "",
  GOOGLE_TAG :process.env.REACT_APP_GOOGLE_TAG || "",
  PRODUCTION_TRACKING: process.env.REACT_APP_PRODUCTION_TRACKING || "",
};

export default envConfig;
