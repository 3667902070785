import {apiDelete, apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TUpcomingTask} from "./getUpcomingTasks";

export const deleteUpcomingSeria = (payload: { propertyId: Guid, triggerId: Guid }) => {
  const { propertyId, triggerId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/alerts/trigger/${triggerId}`
  );

  return apiDelete(url);
};

export const deleteUpcomingTask = (payload: TUpcomingTask & { propertyId: Guid }) => {
  const { propertyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/alerts/delete`
  );

  return apiPost(url, payload);
};