import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TTag} from "../tags/addNewTag";

type TEditInventoryRequest = {
  id: Guid;
  name: string;
  inventoryType: string;
  propertyId: Guid;
  brand?: string | null;
  model?: string | null;
  installedOrPurchaseDate?: string | null;
  warrantyExpiration?: string | null;
  purchaseCost?: number | null;
  insuredItems?: boolean | null;
  serialNumber?: string | null;
  inventorySubtype?: string | null;
  notes: string;
  tags: Array<TTag>;
};

export const editInventory = (payload: TEditInventoryRequest) => {
  const { propertyId, id } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/inventory/${id}`
  );

  return apiPut<
    {
      brand: string;
      model: string;
      installedOrPurchaseDate: string;
      serialNumber: string;
      applianceType: string;
      warrantyExpiration: string;
      inventoryType: string;
      purchaseCost: number;
      insuredItems: boolean;
      name: string;
      id: Guid;
      notes: string;
    },
    {}
  >(url, payload);
};
