import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TTag} from "../tags/addNewTag";

type TEditContactRequest = {
  name: string;
  surname: string | null;
  propertyId: Guid;
  contactId: Guid;
  contactType: string;
  company: string | null;
  email: string | null;
  phone: string | null;
  website: string | null;
  notes: string;
  emergency: boolean;
  tags: Array<TTag>;
};

export const editContact = (payload: TEditContactRequest) => {
  const { propertyId, contactId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/contacts/${contactId}`
  );

  return apiPut<any, any>(url, payload);
};

export const updateContactWithImage = (payload: { request: TEditContactRequest, contactImage: any; }) => {
  const { propertyId, contactId } = payload.request;
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/contacts/${contactId}/updateWithImage`);

  let bodyFormData = new FormData();
  bodyFormData.append('contactDto',
    new Blob([JSON.stringify(payload.request)], {type: 'application/json; charset=UTF-8'}));
  bodyFormData.append("contactImage", payload.contactImage);

  return apiPut<any, any>(url, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
