import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type UserInterest = {
  name: string,
  id: Guid,
  keywords: string | null,
  picked: boolean,
  showInDetails: boolean,
};

export type TGetUserInterestsResponse = Array<UserInterest>;

export const getUserInterests = () => {
  let url = createUrl(`${EnvConfig.API_URL}/api/interests `);

  return apiGet<TGetUserInterestsResponse>(url)
};
