import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TUserWarnings = Array<{
  warningId: number;
  userId: number;
  warningType: string;
  message: string;
  fileName: string;
  obtainingDate: string;
  dataType: string;
}>;

export const parseInvoiceBatch = (propertyId: Guid, files: Array<any>) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/invoice/process/${propertyId}/batch`);
  let bodyFormData = new FormData();

  files.map((file: any) => bodyFormData.append("file", file))

  return apiPost<TUserWarnings, any>(url, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
