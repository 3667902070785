import React, {useEffect, useState} from "react"
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";

import {ReactComponent as WishlistIcon} from "../../../images/guidance/WishlistIcon.svg";
import {ReactComponent as WishlistIconGreen} from "../../../images/guidance/WishlistIconGreen.svg";
import {ReactComponent as WishlistIconEmpty} from "../../../images/guidance/WishlistIconEmpty.svg";
import {ReactComponent as LivletLogoGuidance} from "../../../images/guidance/LivletLogoGuidance.svg";
import {Button, Dropdown} from "antd";
import {getRatingIcon} from "./GuidanceRating";
import {useSelector} from "react-redux";
import {getImprovementsSelector} from "../../selectors";
import { ListCompWrapper } from "./GuidanceListComp";
import {WishlistOverlay} from "./GuidanceListItem";
import ReactHtmlParser from "react-html-parser";

type Props = {
  improvement: any;
  parentCategory: any;
  createWork: () => void;
  openRelatedWork: () => void;
  selectImprovement: (arg1: number, arg2: string) => void;
  manageWishlistStatus: (arg: any) => void;
  manageImprovementStatus: (arg: any) => void;
}

const GuidanceItem = (props: Props) => {
  const {improvement, parentCategory, createWork, openRelatedWork, selectImprovement,
    manageImprovementStatus, manageWishlistStatus} = props;
  const improvementsConfiguration = useSelector(getImprovementsSelector);
  const [anchorCategory, setAnchorCategory] = useState("")

  document.addEventListener("click", function(e) {
    // @ts-ignore
    if (e.target.tagName == "A" &&
      // @ts-ignore
      !e.target.hasAttribute("target"))
    {
      // @ts-ignore
      e.target.setAttribute("target", "_blank");
    }
  });

  useEffect(() => {
    document.getElementById(anchorCategory)?.scrollIntoView()
  }, [anchorCategory])

  return <ListCompWrapper className={"guidance-item"}>
    <ImageWrapper id={"pageStart"} style={{backgroundImage: `url("https://www.livlet.com/guidance/${improvement.id}.jpg")`}}>
      <ImageActionsWrapper>
        <GuidanceLogoWrapper>
          <LivletLogoGuidance />
          <GuidanceLogoText>Guidance</GuidanceLogoText>
        </GuidanceLogoWrapper>
        {(improvement.doneOn || improvement.doneByAnswerOn) &&  (improvement.isApplicable === undefined || improvement.isApplicable === true) &&
        <Dropdown overlay={<WishlistOverlay>This improvement has been done</WishlistOverlay>} placement="topCenter">
            <WishlistIconGreen />
        </Dropdown>}
        {improvement.wishListedOn && (improvement.isApplicable === undefined || improvement.isApplicable === true)
          && !improvement.doneOn && !improvement.doneByAnswerOn &&
        <Dropdown overlay={<WishlistOverlay>Click to remove from your wishlist</WishlistOverlay>} placement="topCenter">
            <WishlistIcon style={{cursor: "pointer"}} onClick={(e) => {
              e.stopPropagation()
              manageWishlistStatus(improvement)
            }}/>
        </Dropdown>}
        {(!improvement.wishListedOn && !improvement.doneOn && !improvement.doneByAnswerOn) &&
        (improvement.isApplicable === undefined || improvement.isApplicable === true) &&
        <Dropdown overlay={<WishlistOverlay>Click to add to your wishlist</WishlistOverlay>} placement="topCenter">
            <WishlistIconEmpty style={{cursor: "pointer"}} onClick={(e) => {
              e.stopPropagation()
              manageWishlistStatus(improvement)
            }} />
        </Dropdown>}
      </ImageActionsWrapper>
    </ImageWrapper>
    <SummaryWrapper>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <span>{parentCategory.doneImprovements}/{parentCategory.totalImprovements} {parentCategory.name}</span>
        {improvement.relatedWorkId && <ActionButton
          style={{height: "2rem"}} icon={<PlusOutlined />} onClick={() => openRelatedWork()}>
          Open related project
        </ActionButton>}
        {!improvement.relatedWorkId && !improvement.doneOn && !improvement.doneByAnswerOn &&
        (improvement.isApplicable === undefined || improvement.isApplicable === true) && <ActionButton
          style={{height: "2rem"}} onClick={() => createWork()}>
          Create project
        </ActionButton>}
        {!improvement.relatedWorkId && (improvement.doneOn  || improvement.doneByAnswerOn) &&
        (improvement.isApplicable === undefined || improvement.isApplicable === true) && <Dropdown
          overlay={<WishlistOverlay>This improvement has already been done. You cannot create a project for it</WishlistOverlay>}
          placement="topCenter"
        ><ActionButton style={{height: "2rem"}} className={"disabled"} disabled={true}>
            Create project
        </ActionButton></Dropdown>}
      </div>
      <ImprovementTitle>{improvement.name}</ImprovementTitle>
    </SummaryWrapper>
    <DescriptionWrapper>
      <ShortSummary>
        {improvement.summary}
      </ShortSummary>
      <IconsWrapper>
        <IconWrapper>
          {getRatingIcon({type: "ecoImpactRating", value: improvement.guidanceCardSustainability})} Eco
        </IconWrapper>
        <IconWrapper>
          {getRatingIcon({type: "savingsRating", value: improvement.guidanceCardSavings})} Savings
        </IconWrapper>
        <IconWrapper>
          {getRatingIcon({type: "costRating", value: improvement.guidanceCardCost})} Cost
        </IconWrapper>
        <IconWrapper>
          {getRatingIcon({type: "effortRating", value: improvement.guidanceCardEffort})} Effort
        </IconWrapper>
        <IconWrapper>
          {getRatingIcon({type: "safetyRating", value: improvement.guidanceCardSafety})} Safety
        </IconWrapper>
        <IconWrapper>
          {getRatingIcon({type: "wellnessRating", value: improvement.guidanceCardHealthComfort})} Wellbeing
        </IconWrapper>
      </IconsWrapper>
      <ContentWrapper>
        <ImprovementContentHeader>{improvement.name}</ImprovementContentHeader>
        <ImprovementContentTitle>Contents</ImprovementContentTitle>
        <ImprovementContentsWrapper>
          {improvement.description.includes("categoryDescription") &&
          <ImprovementContentsItem>• <a onClick={() => setAnchorCategory("categoryDescription")}>
            Description
          </a></ImprovementContentsItem>}
          {improvement.description.includes("categoryWhy") &&
          <ImprovementContentsItem>• <a onClick={() => setAnchorCategory("categoryWhy")}>
            Why?
          </a></ImprovementContentsItem>}
          {improvement.description.includes("categoryMoneySaving") &&
          <ImprovementContentsItem>• <a onClick={() => setAnchorCategory("categoryMoneySaving")}>
            How much could it save you?
          </a></ImprovementContentsItem>}
          {improvement.description.includes("categoryIsForYou") &&
          <ImprovementContentsItem>• <a onClick={() => setAnchorCategory("categoryIsForYou")}>
            Is it for you?
          </a></ImprovementContentsItem>}
          {improvement.description.includes("categoryIsItDifficult") &&
          <ImprovementContentsItem>• <a onClick={() => setAnchorCategory("categoryIsItDifficult")}>
            Is it difficult?
          </a></ImprovementContentsItem>}
          {improvement.description.includes("categoryRequiredWorks") &&
          <ImprovementContentsItem>• <a onClick={() => setAnchorCategory("categoryRequiredWorks")}>
            What projects are required
          </a></ImprovementContentsItem>}
          {improvement.description.includes("categoryLinkedWorks") &&
          <ImprovementContentsItem>• <a onClick={() => setAnchorCategory("categoryLinkedWorks")}>
            Linked projects
          </a></ImprovementContentsItem>}
          {improvement.description.includes("categoryWhatNext") &&
          <ImprovementContentsItem>• <a onClick={() => setAnchorCategory("categoryWhatNext")}>
            What next
          </a></ImprovementContentsItem>}
          {improvement.description.includes("categoryFindMore") &&
          <ImprovementContentsItem>• <a onClick={() => setAnchorCategory("categoryFindMore")}>
            Find out more
          </a></ImprovementContentsItem>}
        </ImprovementContentsWrapper>
        <div style={{overflowWrap: "break-word"}}>
          {ReactHtmlParser(improvement.description.replaceAll("[br]", ""))}
        </div>
        {improvement.relatedImprovements.length > 0 && <RelatedSectionTitle id={"relatedMeasures"}>Related measures</RelatedSectionTitle>}
        {improvement.relatedImprovements.map((relatedImprovementId: any, index: number) =>
          (improvementsConfiguration.content.improvements.filter(relatedImprovement =>
            relatedImprovement.id === relatedImprovementId).length > 0) &&
            <RelatedSectionWrapper>
              <RelatedSectionIndex>{String.fromCharCode(index + 65)}.</RelatedSectionIndex>
              <RelatedSectionContent>
                  <RelatedMeasureTitle onClick={() => {
                    document.getElementById("pageStart")?.scrollIntoView()
                    selectImprovement(improvementsConfiguration.content.improvements.filter(relatedImprovement =>
                      relatedImprovement.id === relatedImprovementId)[0].category, relatedImprovementId)
                  }}>
                    {improvementsConfiguration.content.improvements.filter(relatedImprovement =>
                      relatedImprovement.id === relatedImprovementId)[0].name}
                  </RelatedMeasureTitle>
                {improvementsConfiguration.content.improvements.filter(relatedImprovement =>
                  relatedImprovement.id === relatedImprovementId)[0].summary}
              </RelatedSectionContent>
            </RelatedSectionWrapper>
        )}

        {improvement.incentives.length > 0 && <RelatedSectionTitle id={"incentives"}>Incentives</RelatedSectionTitle>}
        {improvement.incentives.map((incentiveId: any, index: number) =>
          improvementsConfiguration.content.incentives.filter(incentive => incentive.id === incentiveId).map(incentive =>
            <RelatedSectionWrapper>
              <RelatedSectionIndex>{String.fromCharCode(index + 65)}.</RelatedSectionIndex>
              <RelatedSectionContent>
                <IncentiveTitle>{incentive.title}</IncentiveTitle>
                {incentive.description}
                <span>
                  Source:{" "}
                      <IncentiveLink href={incentive.linkToSource} target={"_blank"}>
                    {new URL(incentive.linkToSource).hostname.replace("www.","")}
                  </IncentiveLink>{" "}↗
                </span>
              </RelatedSectionContent>
            </RelatedSectionWrapper>
        ))}
      </ContentWrapper>
      {(improvement.isApplicable === undefined || improvement.isApplicable === true) && <ActionButtonWrapper>
        {!improvement.doneByAnswerOn && <ActionButton onClick={() => manageImprovementStatus(improvement)}>
          Mark as {improvement.doneOn ? "incomplete" : "complete"}
        </ActionButton>}
        {improvement.doneByAnswerOn && <Dropdown
          overlay={<WishlistOverlay>Done based on provided property details related to this improvement.</WishlistOverlay>}
          placement="topCenter"
        ><ActionButton className={"disabled"} disabled={true}>
          Mark as incomplete
        </ActionButton></Dropdown>}
        {improvement.wishListedOn &&
          <ActionButton onClick={() => (!improvement.doneOn && !improvement.doneByAnswerOn) && manageWishlistStatus(improvement)}>
            Remove from wishlist
          </ActionButton>}
        {!improvement.wishListedOn && !improvement.doneOn && !improvement.doneByAnswerOn &&
          <ActionButton onClick={() => manageWishlistStatus(improvement)}>
            Add to wishlist
          </ActionButton>}

        {improvement.relatedWorkId && <ActionButton icon={<PlusOutlined/>} onClick={() => openRelatedWork()}>
          Open related project
        </ActionButton>}
        {!improvement.relatedWorkId && !improvement.doneOn && !improvement.doneByAnswerOn && <ActionButton
          onClick={() => createWork()}>
          Create project
        </ActionButton>}
        {!improvement.relatedWorkId && (improvement.doneOn  || improvement.doneByAnswerOn) && <Dropdown
            overlay={<WishlistOverlay>This improvement has already been done. You cannot create a project for it</WishlistOverlay>}
            placement="topCenter"
        ><ActionButton className={"disabled"} disabled={true}>
            Create project
        </ActionButton></Dropdown>}
      </ActionButtonWrapper>}
    </DescriptionWrapper>
  </ListCompWrapper>
}

const ImageWrapper = styled.div`
  width: 100%;
  background-color: teal;
  height: 13.5rem;
  border-radius: 4px 4px 0 0;
  
  background-repeat: no-repeat;
  background-position: center center; 
  background-size: cover;
`

const ImageActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.5rem;
`

const GuidanceLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
  row-gap: 0.5rem;
`

const GuidanceLogoText = styled.span`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #FFFFFF;
`

const SummaryWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  background: #45655E;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #67C18B;
  
  @media (max-width: 1024px) {
    padding: 0.75rem;
  }
`

const ImprovementTitle = styled.div`
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.625rem;
  color: #FFFFFF;
  margin-top: 1.5rem;
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 2rem;
  background: #EFFEF7;
  
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #21272B;
  
  @media (max-width: 1024px) {
    padding: 0.75rem;
  }
`

const ShortSummary = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #21272B;
`
const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  column-gap: 2rem;
  
  @media (max-width: 1280px) {
    flex-wrap: wrap;
    row-gap: 1rem;
    justify-content: center;
  }
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.375rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #666F7A;
`

const ContentWrapper = styled.div`
  font-size: 1rem;
`

export const RelatedMeasureTitle = styled.a`
  font-size: 1.125rem;
  font-weight: 600;
  color: #21272B;
  :hover {
    color: #2A80E1;
  }
`;

const IncentiveTitle = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  color: #21272B;
`

const IncentiveLink = styled.a`
  color: #236EC2;
  text-decoration: underline!important;
  :hover {
    color: #2A80E1;
  }
`;

const RelatedSectionTitle = styled.div`
  margin-top: 2rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: #21272B;
`

export const RelatedSectionWrapper = styled.div`
  cursor: pointer;
  display: flex;
  column-gap: 1rem;
  border-radius: 6px;
  padding: 1.5rem;
  margin-top: 1rem;
  background: white;
`

export const RelatedSectionIndex = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #C7C9C7;
`

export const RelatedSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  color: #666F7A;
`

const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 0.25rem;
  
  @media (max-width: 1450px) {
    flex-direction: column;
    row-gap: 0.75rem;
  }
`

const ActionButton = styled(Button)`
  background: #67C18B;
  border-radius: 4px;
  border: 1px solid #67C18B;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.75rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s ease;
  
  &:hover, &.selected {
    color: #67C18B;
    border: 1px solid #67C18B;
    transition: all .5s ease;
  }
  
  &.disabled, &.disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
`

const ImprovementContentHeader = styled.div`
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1.75rem;
  color: #21272B;
`

const ImprovementContentTitle = styled.div`
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #67C18B;
  font-weight: 600;
  margin-top: 1.5rem;
`

const ImprovementContentsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  row-gap: 0.25rem;
  column-gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`

const ImprovementContentsItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem; 
  
  font-weight: 600;
  color: #67C18B;
  font-size: 0.875rem;
  line-height: 1.5rem;
  
  a {
    cursor: pointer;
    color: #45655E;
    text-decoration: underline;
  }
`

export default GuidanceItem