// import React, {useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
// import { FormattedMessage, useIntl } from "react-intl";
import {Checkbox, Divider, Form, Input, Popover, Select, Tooltip} from "antd";
// import countryList from "react-select-country-list";
//
// import messages from "./messages";
// import {emailRegex, passwordTest,} from "./helpers";
// import "../../index.css";
// import {signUp, signUpAcceptInvite, signUpAttempt, signUpPayment} from "../actions/signUp";
// import { useDispatch, useSelector } from "react-redux";
// import { TFetchingStatus } from "../../helpers";
// import { authSelector } from "../selectors";
// import { loginClean } from "../actions/logIn";
// import {
//   ButtonStyled,
//   ButtonText,
//   FormWrapper,
//   ImageWrapper,
//   SecondaryButton,
// } from "./styled";
// import SearchLocationInput from "../components/SearchLocationInput";
// import {useHistory, useLocation} from "react-router-dom";
// import {passwordResult, resultIcon} from "../components/helper";
// import envConfig from "../../config";
// import ReCAPTCHA from "react-google-recaptcha";
// import {ReactComponent as AlertIcon} from "../../images/AlertRed.svg";
// import {ReactComponent as PaymentLogo} from "../../images/PaymentLogo.svg";
// import {Guid} from "guid-typescript";
// import decodeJwt from "jwt-decode";
// import moment from "moment";
// import {useCookies} from "react-cookie";
//
//
const RegistrationPage = () => {
//   const [form] = Form.useForm();
//   const { formatMessage: f } = useIntl();
//   const recaptchaRef = useRef(null);
//   const dispatch = useDispatch();
//   let history = useHistory();
//   const auth = useSelector(authSelector);
//
//   const [cookies, setCookie, removeCookie] = useCookies(['livlet-referral']);
//   const [referral, setReferral] = useState<string|null>(null);
//
//   const [location, setLocation]: any = useState(null);
//   const [stepNumber, setStepNumber] = useState(1);
//
//   const [passwordStrengthResult, setPasswordStrengthResult] = useState<any>({})
//   const [emailError, setEmailError] = useState(false);
//   const [captchaError, setCaptchaError] = useState(false);
//   const [unknownError, setUnknownError] = useState(false);
//   const [acceptingInvite, setAcceptingInvite] = useState(false);
//   const [inviteToken, setInviteToken] = useState<string|null>();
//
//   // step 1: regular flow
//   const [firstStepRegularData, setFirstStepRegularData] = useState({
//     name: "",
//     email: "",
//     password: "",
//     confirmationPassword: "",
//     termsAndConditions: false,
//     marketingCookies: false,
//     marketingEmails: false
//   });
//
//   const [secondStepRegularData, setSecondStepRegularData] = useState({
//     address1: "",
//     address2: "",
//     city: "",
//     state: "",
//     country: "",
//     postcode: ""
//   });
//
//   const pushStepToGoogle = (eventName: string) => {
//     // @ts-ignore
//     window.dataLayer = window.dataLayer || [];
//     // @ts-ignore
//     window.dataLayer.push({
//       'event': eventName
//     });
//   }
//
//   const handleFirstStepRegularOnFinish = (data: {
//     name: string;
//     email: string;
//     password: string;
//     confirmationPassword: string;
//     termsAndConditions: boolean;
//     marketingCookies: boolean;
//     marketingEmails: boolean;
//   }) => {
//     if (passwordStrengthResult.final) {
//       setFirstStepRegularData({
//         ...data,
//         marketingCookies: data.marketingCookies ?? false,
//         marketingEmails: data.marketingEmails ?? false
//       });
//
//       pushStepToGoogle('register-step1');
//
//       // @ts-ignore
//       const token = recaptchaRef.current.getValue();
//
//       if (acceptingInvite) {
//         dispatch(
//           signUpAcceptInvite({
//             name: data.name,
//             email: data.email,
//             password: data.password,
//             // marketingCookies: data.marketingCookies,
//             marketingEmails: data.marketingEmails,
//             captchaToken: token,
//             token: inviteToken ? inviteToken : "",
//           })
//         )
//       } else {
//         dispatch(
//           signUpAttempt({
//             name: data.name,
//             email: data.email,
//             password: data.password,
//             marketingCookies: data.marketingCookies,
//             marketingEmails: data.marketingEmails,
//             token,
//           })
//         )
//       }
//     }
//   }
//
//   // step 1: social media flow
//   const useQuery = (queryType: string) => {
//     let query = new URLSearchParams(useLocation().search);
//     switch (queryType) {
//       case 'externalSourceName':
//         return query.get("externalSourceName");
//       case 'externalId':
//         return query.get("externalId");
//       case 'externalSourceToken':
//         return query.get("externalSourceToken");
//       case 'name':
//         return query.get("name");
//       case 'email':
//         return query.get("email");
//       case 'stripe_session_id':
//         return query.get("stripe_session_id");
//       case 'payment_failed':
//         return query.get("payment_failed");
//       case 'invite_token':
//         return query.get("invite_token");
//       default:
//         return ""
//     }
//   };
//   const externalSourceName = useQuery('externalSourceName')
//   const externalId = useQuery('externalId')
//   const externalSourceToken = useQuery('externalSourceToken')
//   const name = useQuery("name")
//   const email = useQuery("email")
//   const paymentFailed = useQuery("payment_failed");
//   const stripe_session_id = useQuery("stripe_session_id");
//   const invite_token = useQuery("invite_token");
//   const [couponValue, setCouponValue] = useState("");
//   const [couponError, setCouponError] = useState("");
//
//   const [firstStepSocialData, setFirstStepSocialData] = useState({
//     name: "",
//     email: "",
//     termsAndConditions: false,
//     marketingCookies: false,
//     marketingEmails: false,
//   });
//
//   // empty string -> regular registration flow
//   const [registerWithSocial, setRegisterWithSocial] = useState("");
//   // true is "here's your email and username all set up", false is "here have the fields to edit it manually"
//   const [isSocialAccountDetermined, toggleSocialAccount] = useState(true);
//
//   useEffect(() => {
//     if (cookies && cookies['livlet-referral']) {
//       setReferral(cookies['livlet-referral']);
//     }
//   }, []);
//
//   useEffect(() => {
//       const socialData = {
//         socialName: name,
//         socialEmail: email
//       }
//       setFirstStepSocialData({
//         // @ts-ignore
//         name: socialData.socialName,
//         // @ts-ignore
//         email: socialData.socialEmail,
//         termsAndConditions: false,
//         marketingCookies: false,
//         marketingEmails: false,
//       })
//
//       // @ts-ignore
//       setRegisterWithSocial(externalSourceName)
//       form.setFieldsValue({
//         socialName: socialData.socialName,
//         socialEmail: socialData.socialEmail});
//       setAcceptingInvite(false);
//   }, [])
//
//   const handleFirstStepSocialOnFinish = (data: {
//     socialName: string;
//     socialEmail: string;
//     termsAndConditions: boolean;
//     marketingCookies: boolean;
//     marketingEmails: boolean;
//   }) => {
//     pushStepToGoogle('register-step1');
//
//     dispatch(
//       signUpAttempt({
//         externalSourceName, externalId, externalSourceToken,
//         name: firstStepSocialData.name,
//         email: firstStepSocialData.email,
//         password: Guid.create().toString(),
//         marketingCookies: data.marketingCookies ?? false,
//         marketingEmails: data.marketingEmails ?? false
//       })
//     );
//   };
//
//   // step 2
//   const getCountryList = () => {
//     let result = countryList()
//       .getData()
//       .filter(
//         (country: { value: string; label: string }) =>
//           country.value !== "US" && country.value !== "GB"
//       );
//
//     result.unshift({ value: "United Kingdom", label: "United Kingdom" });
//     result.unshift({ value: "US", label: "United States" });
//
//     return result;
//   };
//
//   useEffect(() => {
//     if (location) {
//       form.setFieldsValue({
//         address1: location.formattedAddress,
//         city: location.data.city,
//         state: location.data.state,
//         country: location.data.country,
//         postcode: location.data.zipcode,
//       });
//     }
//   }, [location, form]);
//
//   const options = useMemo(() => getCountryList(), []);
//
//   const handleSecondStepOnFinish = (data: {
//     address1: string;
//     address2: string;
//     city: string;
//     state: string;
//     country: string;
//     postcode: string;
//   }) => {
//     setSecondStepRegularData({...data});
//     setCouponValue("");
//     setCouponError("");
//     pushStepToGoogle('register-step2');
//     setStepNumber(3);
//   }
//
//   const handlePaymentStep = () => {
//     if (couponValue && couponValue.trim().length > 0 && "Livlet2022" !== couponValue.trim()) {
//       // TODO instead of comparing to a hardcoded value, check the code with the backend first
//       setCouponError("Wrong coupon code");
//       return;
//     }
//
//     pushStepToGoogle('register-step3');
//
//     if (!registerWithSocial) {
//       dispatch(
//         signUpPayment({
//           name: firstStepRegularData.name,
//           email: firstStepRegularData.email,
//           externalSourceName: null,
//           password: firstStepRegularData.password,
//           marketingCookies: firstStepRegularData.marketingCookies,
//           marketingEmails: firstStepRegularData.marketingEmails,
//           property: {
//             name: null,
//             address: secondStepRegularData.address1,
//             address2: secondStepRegularData.address2,
//             state: secondStepRegularData.state,
//             country: secondStepRegularData.country,
//             city: secondStepRegularData.city,
//             zip: secondStepRegularData.postcode,
//           },
//           coupon: couponValue,
//           referral: referral,
//         })
//       );
//     }
//     else {
//       dispatch(
//         signUpPayment({
//           // @ts-ignore
//           name, email, externalSourceName, externalId, externalSourceToken,
//           password: Guid.create().toString(),
//           marketingCookies: firstStepRegularData.marketingCookies,
//           marketingEmails: firstStepRegularData.marketingEmails,
//           property: {
//             name: null,
//             address: secondStepRegularData.address1,
//             address2: secondStepRegularData.address2,
//             state: secondStepRegularData.state,
//             country: secondStepRegularData.country,
//             city: secondStepRegularData.city,
//             zip: secondStepRegularData.postcode,
//           },
//           coupon: couponValue,
//           referral: referral,
//         })
//       );
//     }
//   };
//
//   // post-registration processing
//   useEffect(() => {
//     setUnknownError(false);
//     if (auth.fetchingStatus === TFetchingStatus.Failure) {
//       if (auth.emailError) {
//         setEmailError(true);
//         setStepNumber(1);
//         form.setFieldsValue({
//           password: "",
//           confirmationPassword: "",
//         });
//         dispatch(loginClean());
//       }
//       if (auth.captchaError) {
//         setCaptchaError(true);
//         if (recaptchaRef && recaptchaRef.current) {
//           // @ts-ignore
//           recaptchaRef.current.reset();
//         }
//       }
//       if (auth.unknownError) {
//         setUnknownError(true);
//         if (recaptchaRef && recaptchaRef.current) {
//           // @ts-ignore
//           recaptchaRef.current.reset();
//         }
//       }
//     }
//     else if (auth.fetchingStatus === TFetchingStatus.Success) {
//       if (auth.firstStepSuccess && !auth.registrationSuccess && !auth.redirectToPayment) {
//         setStepNumber(2);
//       } else if (auth.redirectToPayment && !stripe_session_id && !paymentFailed) {
//         window.location.assign(auth.paymentRedirectUrl);
//       } else if (auth.registrationSuccess) {
//         setStepNumber(5);
//       } else {
//         setEmailError(false);
//         setCaptchaError(false);
//         setUnknownError(false);
//       }
//       dispatch(loginClean());
//     }
//   }, [auth.fetchingStatus, auth.emailError, auth.unknownError, auth.captchaError, f, form, dispatch]);
//
//   useEffect(() => {
//     if (stripe_session_id) {
//       let referral = null;
//       if (cookies && cookies['livlet-referral']) {
//         referral = cookies['livlet-referral'];
//       }
//       pushStepToGoogle('register-step4');
//       // dispatch(signUp({
//       //   checkoutSessionId: stripe_session_id,
//       //   referral: referral,
//       //   subscriptionOwner: true,
//       // }));
//       setStepNumber(4);
//     }
//   }, [stripe_session_id])
//
//   useEffect(() => {
//     if (paymentFailed) {
//       history.push("/register");
//     }
//   }, [paymentFailed]);
//
//   useEffect(() => {
//     if (invite_token) {
//       const { exp, email, userName, secret } = decodeJwt<{
//         exp: number;
//         email: string;
//         userName: string;
//         secret: string;
//       }>(invite_token);
//
//       if (moment().isAfter(moment(exp*1000))) {
//         setStepNumber(101);
//         return;
//       }
//
//       form.setFieldsValue({
//         name: userName,
//         email: email,
//       });
//
//       setAcceptingInvite(true);
//       setInviteToken(secret);
//     }
//   }, [invite_token]);
//
//   return (
//     <Main>
//       <FormWrapper>
//         <FormHeader>
//           <div style={{width: "175px"}}>
//             <img src={"/images/LivletLogoNew.png"} style={{maxWidth: "100%"}} alt={""} />
//           </div>
//           {(stepNumber <= 2 && !acceptingInvite) && <StepNumber>
//             <FormattedMessage {...messages.steps} values={{ stepNumber }} />
//           </StepNumber>}
//         </FormHeader>
//         {stepNumber === 1 && (
//           <>
//             <>
//               <Title>{f(messages.firstStepTitle)}</Title>
//               <Subtitle>{registerWithSocial ? f(messages.registerWithSocialSubtitle, {socialMedia: registerWithSocial})
//                 : f(messages.firstStepSubtitle)}</Subtitle>
//             </>
//             <Form
//               layout="vertical"
//               form={form}
//               size={"large"}
//               // @ts-ignore
//               onFinish={registerWithSocial ? handleFirstStepSocialOnFinish : handleFirstStepRegularOnFinish}
//             >
//               {!registerWithSocial && <div>
//                   <LabelWrapper>
//                       <Label>{f(messages.name)}</Label>{" "}
//                   </LabelWrapper>
//                   <Form.Item
//                       key={"name"}
//                       name={"name"}
//                       rules={[
//                         {
//                           required: true,
//                           message: f(messages.validationName),
//                         },
//                       ]}
//                   >
//                       <InputStyled
//                           maxLength={100}
//                           placeholder={f(messages.enterFullName)}
//                       />
//                   </Form.Item>
//                   <LabelWrapper>
//                       <Label>{f(messages.emailAddress)}</Label>{" "}
//                   </LabelWrapper>
//                   <Form.Item
//                       key={"email"}
//                       name={"email"}
//                       rules={[
//                         {
//                           required: true,
//                           pattern: emailRegex,
//                           message: f(messages.validationEmail),
//                         },
//                         {
//                           validator: (rule, value, callback) => {
//                             if (emailError) {
//                               callback("");
//                             } else {
//                               callback();
//                             }
//                           }
//                         },
//                       ]}
//                   >
//                       <InputStyled
//                           maxLength={100}
//                           placeholder={f(messages.enterEmailAddress)}
//                           onChange={() => {setEmailError(false)}}
//                           disabled={acceptingInvite}
//                       />
//                   </Form.Item>
//                 {emailError &&
//                 <div
//                     style={{
//                       backgroundColor: "#FCF0F0",
//                       padding: "1rem",
//                       marginTop: "1em",
//                       marginBottom: "1em",
//                       display: "flex",
//                       borderRadius: 6,
//                       fontWeight: 400,
//                     }}
//                 >
//                     <AlertIcon />
//                     <span style={{ paddingLeft: "0.5rem" }}>
//                 Cannot register the user. If you registered before and can't login please <a onClick={() => history.push("/reset/password")}>reset</a> your password.
//                 </span>
//                 </div>}
//                   <InputsWrapper>
//                       <InputWrapper>
//                           <LabelWrapper>
//                               <Label>{f(messages.createPassword)}</Label>{" "}
//                           </LabelWrapper>
//                           <Popover
//                               content={<div style={{padding: '0.7rem'}}>
//                                 <b>Password must include:</b>
//                                 <br/>{resultIcon(passwordStrengthResult.appropriateLength)} 8-20 Characters
//                                 <br/>{resultIcon(passwordStrengthResult.capitalLetters)} At least one capital letter
//                                 <br/>{resultIcon(passwordStrengthResult.numbers)} At least one number
//                                 <br/>{resultIcon(passwordStrengthResult.noSpaces)} No spaces
//                               </div>}
//                               placement="top"
//                               trigger={["hover", "focus"]}
//                           >
//                               <Form.Item
//                                   key={"password"}
//                                   name={"password"}
//                                   rules={[
//                                     {
//                                       required: true,
//                                       message: f(messages.validationPassword),
//                                     },
//                                     {
//                                       validator: (rule, value, callback) => {
//                                         if (!passwordStrengthResult.final) {
//                                           callback("");
//                                         } else {
//                                           callback();
//                                         }
//                                       }
//                                     }
//                                   ]}
//                               >
//                                   <InputPasswordStyled
//                                       maxLength={100}
//                                       placeholder={f(messages.enterPassword)}
//                                       onChange={(e) => {setPasswordStrengthResult(passwordTest(e.target.value))}}
//                                   />
//                               </Form.Item>
//                           </Popover>
//                         {form.getFieldValue("password") &&
//                         <div style={{display: "flex", marginTop: "-0.3rem", marginBottom: "0.3rem"}}>
//                           {passwordResult(passwordStrengthResult.totalScore)}
//                             <Popover
//                                 content={<div style={{padding: '0.7rem'}}>
//                                   <b>Password must include:</b>
//                                   <br/>{resultIcon(passwordStrengthResult.appropriateLength)} 8-20 Characters
//                                   <br/>{resultIcon(passwordStrengthResult.capitalLetters)} At least one capital letter
//                                   <br/>{resultIcon(passwordStrengthResult.numbers)} At least one number
//                                   <br/>{resultIcon(passwordStrengthResult.noSpaces)} No spaces
//                                 </div>}
//                                 placement="top"
//                                 trigger={["hover", "focus"]}
//                             ><span style={{marginLeft: "0.3rem", marginBottom: "-0.3rem"}}>{resultIcon(passwordStrengthResult.final)}</span>
//                             </Popover>
//                         </div>}
//                       </InputWrapper>
//                       <InputWrapper>
//                           <LabelWrapper>
//                               <Label>{f(messages.reEnterPassword)}</Label>{" "}
//                           </LabelWrapper>
//                           <Form.Item
//                               key={"confirmationPassword"}
//                               name={"confirmationPassword"}
//                               rules={[
//                                 {
//                                   validator: (rule, value, callback) => {
//                                     if (
//                                       !form.getFieldValue("password") &&
//                                       !form.getFieldValue("confirmationPassword")
//                                     ) {
//                                       callback(f(messages.validationPassword));
//                                     }
//                                     form.getFieldValue("password") !==
//                                     form.getFieldValue("confirmationPassword")
//                                       ? callback(f(messages.confirmationPasswordError))
//                                       : callback();
//                                   },
//                                 },
//                               ]}
//                           >
//                               <InputPasswordStyled
//                                   maxLength={100}
//                                   placeholder={f(messages.enterPassword)}
//                               />
//                           </Form.Item>
//                       </InputWrapper>
//                   </InputsWrapper>
//               </div>}
//               {unknownError && (
//                 <div
//                   style={{
//                     backgroundColor: "#FCF0F0",
//                     padding: "1rem",
//                     marginBottom: "1em",
//                     display: "flex",
//                     borderRadius: 6,
//                     fontWeight: 400,
//                   }}
//                 >
//                   <AlertIcon />
//                   <span style={{ paddingLeft: "0.5rem" }}>
//                 We were unable to complete your registration. Please
//                 contact <a href={"mailto:support.uk@livlet.com"}>support.uk@livlet.com</a> for help.
//               </span>
//                 </div>
//               )}
//               {registerWithSocial && isSocialAccountDetermined &&
//               <SocialWrapper>
//                   <SocialUsername>{firstStepSocialData.name}</SocialUsername>
//                   <SocialEmail>{firstStepSocialData.email}</SocialEmail>
//                   {/*<PseudoLink onClick={() => toggleSocialAccount(false)}>Change</PseudoLink>*/}
//               </SocialWrapper>}
//               {registerWithSocial && !isSocialAccountDetermined &&
//               <>
//                 <LabelWrapper>
//                     <Label>{f(messages.name)}</Label>{" "}
//                 </LabelWrapper>
//                 <Form.Item
//                     key={"socialName"}
//                     name={"socialName"}
//                     rules={[
//                       {
//                         required: true,
//                         message: f(messages.validationName),
//                       },
//                     ]}
//                 >
//                     <InputStyled
//                         maxLength={100}
//                         placeholder={f(messages.enterFullName)}
//                     />
//                 </Form.Item>
//                   <LabelWrapper>
//                       <Label>{f(messages.emailAddress)}</Label>{" "}
//                   </LabelWrapper>
//                   <Form.Item
//                     key={"socialEmail"}
//                     name={"socialEmail"}
//                     rules={[
//                       {
//                         required: true,
//                         pattern: emailRegex,
//                         message: f(messages.validationEmail),
//                       },
//                       {
//                         validator: (rule, value, callback) => {
//                           if (emailError) {
//                             callback("");
//                           } else {
//                             callback();
//                           }
//                         }
//                       },
//                     ]}
//                   >
//                     <InputStyled
//                       maxLength={100}
//                       placeholder={f(messages.enterEmailAddress)}
//                       onChange={() => setEmailError(false)}
//                     />
//                   </Form.Item>
//                 {emailError &&
//                 <div
//                     style={{
//                       backgroundColor: "#FCF0F0",
//                       padding: "1rem",
//                       marginTop: "1em",
//                       marginBottom: "1em",
//                       display: "flex",
//                       borderRadius: 6,
//                       fontWeight: 400,
//                     }}
//                 >
//                     <AlertIcon />
//                     <span style={{ paddingLeft: "0.5rem" }}>
//                 Cannot register the user. If you registered before and can't login please <a onClick={() => history.push("/reset/password")}>reset</a> your password.
//                 </span>
//                 </div>}
//               </>}
//               <TermsAndConditions>
//                 <Form.Item
//                   style={{marginBottom: '0'}}
//                   key={"termsAndConditions"}
//                   name={"termsAndConditions"}
//                   valuePropName={"checked"}
//                   rules={[
//                     {
//                       required: true,
//                       transform: (value: any) => value || undefined,
//                       type: "boolean",
//                       message: f(messages.validationTermsAndConditions),
//                     },
//                   ]}
//                 >
//                   <Checkbox>
//                     <TermAndConditionsDescription>
//                       {f(messages.termAndConditionsText, {
//                         link: (
//                           <a href={`/terms`} target="_blank">
//                             {f(messages.termAndConditionsLink)}
//                           </a>
//                         ),
//                       })}
//                     </TermAndConditionsDescription>
//                   </Checkbox>
//                 </Form.Item>
//                 <Form.Item
//                   key={"marketingEmails"}
//                   name={"marketingEmails"}
//                   valuePropName={"checked"}
//                   style={{marginBottom: '0'}}
//                 >
//                   <Checkbox>
//                     <TermAndConditionsDescription>
//                       <Tooltip
//                         title={"We send bi-weekly newsletter"}>
//                         Send me occasional emails about new product features and service</Tooltip>
//                     </TermAndConditionsDescription>
//                   </Checkbox>
//                 </Form.Item>
//                 {/*<Form.Item*/}
//                 {/*  style={{marginBottom: '0'}}*/}
//                 {/*>*/}
//                 {/*  <Checkbox checked={true} disabled={true}>*/}
//                 {/*    <TermAndConditionsDescription>*/}
//                 {/*      <Tooltip*/}
//                 {/*        title={"Essential cookies enable core functionality such as Google Captcha and cannot be disabled."}>*/}
//                 {/*        I accept essential cookies</Tooltip>*/}
//                 {/*    </TermAndConditionsDescription>*/}
//                 {/*  </Checkbox>*/}
//                 {/*</Form.Item>*/}
//                 {/*<Form.Item*/}
//                 {/*  key={"marketingCookies"}*/}
//                 {/*  name={"marketingCookies"}*/}
//                 {/*  valuePropName={"checked"}*/}
//                 {/*>*/}
//                 {/*  <Checkbox>*/}
//                 {/*    <TermAndConditionsDescription>*/}
//                 {/*      <Tooltip*/}
//                 {/*        title={"Marketing cookies allow Google Analytics to keep track of your activity on Livlet to improve your experience."}>*/}
//                 {/*        I accept marketing cookies</Tooltip>*/}
//                 {/*    </TermAndConditionsDescription>*/}
//                 {/*  </Checkbox>*/}
//                 {/*</Form.Item>*/}
//               </TermsAndConditions>
//               {captchaError && (
//                 <div
//                   style={{
//                     backgroundColor: "#FCF0F0",
//                     padding: "1rem",
//                     marginBottom: "1em",
//                     display: "flex",
//                     borderRadius: 6,
//                     fontWeight: 400,
//                   }}
//                 >
//                   <AlertIcon />
//                   <span style={{ paddingLeft: "0.5rem" }}>
//                 Please verify again.
//               </span>
//                 </div>
//               )}
//               <ReCAPTCHA
//                 sitekey={envConfig.GOOGLE_RECAPTCHA_SITE_KEY}
//                 ref={recaptchaRef}
//               />
//               {registerWithSocial && firstStepSocialData.email === "undefined" &&
//               <Subtitle style={{maxWidth: "34.625rem", marginTop: "1rem", color: "#c21111"}}>
//                   We were unable to gain access to your email and therefore cannot proceed with the registration.
//                   Please make sure your email has been verified, and that you've granted Livlet access to it.
//               </Subtitle>}
//               <ButtonsWrapper>
//                 <SecondaryButton onClick={() => history.push("/login")}>
//                   <ButtonText>{f(messages.cancel)}</ButtonText>
//                 </SecondaryButton>
//                 <ButtonStyled
//                   disabled={registerWithSocial && firstStepSocialData.email === "undefined" ? true : false}
//                   htmlType="submit" type="primary">
//                   <ButtonText>
//                     {acceptingInvite
//                     ? "Register"
//                     : f(messages.next)
//                     }
//                   </ButtonText>
//                 </ButtonStyled>
//               </ButtonsWrapper>
//             </Form>
//             <Subtitle>
//               {f(messages.registrationFooter, {
//                 loginLink: <a onClick={() => history.push("/login")}>{f(messages.clickHere)}</a>,
//               })}
//             </Subtitle>
//           </>
//         )}
//
//         {stepNumber === 2 && (
//           <>
//             <Title>{f(messages.secondStepTitle)}</Title>
//             <Subtitle>{f(messages.secondStepSubtitle)}</Subtitle>
//             <Form
//               layout="vertical"
//               form={form}
//               size={"large"}
//               onFinish={handleSecondStepOnFinish}
//             >
//               <Label>{f(messages.findAddress)}</Label>
//               <Form.Item key={"findAddress"} name={"findAddress"}>
//                 <SearchLocationInput setValue={setLocation} />
//               </Form.Item>
//               <Divider />
//               <LabelWrapper>
//                 <Label>
//                   <FormattedMessage
//                     {...messages.address}
//                     values={{ addressNumber: "line 1 *" }}
//                   />
//                 </Label>{" "}
//                 {/*<Tooltip*/}
//                 {/*  placement="top"*/}
//                 {/*  title={"Lorem ipsum dolor sit amet, consectetur adipiscing."}*/}
//                 {/*>*/}
//                 {/*  <QuestionCircleStyled />*/}
//                 {/*</Tooltip>*/}
//               </LabelWrapper>
//               <Form.Item
//                 key={"address1"}
//                 name={"address1"}
//                 rules={[
//                   {
//                     required: true,
//                     message: f(messages.validationStreetAddress),
//                   },
//                 ]}
//               >
//                 <InputStyled
//                   maxLength={300}
//                   autoComplete={"newpassword"}
//                   placeholder={f(messages.enterAddress)}
//                 />
//               </Form.Item>
//               <LabelWrapper>
//                 <Label>
//                   <FormattedMessage
//                     {...messages.address}
//                     values={{ addressNumber: "line 2" }}
//                   />
//                 </Label>{" "}
//                 {/*<Tooltip*/}
//                 {/*  placement="top"*/}
//                 {/*  title={"Lorem ipsum dolor sit amet, consectetur adipiscing."}*/}
//                 {/*>*/}
//                 {/*  <QuestionCircleStyled />*/}
//                 {/*</Tooltip>*/}
//               </LabelWrapper>
//               <Form.Item key={"address2"} name={"address2"}>
//                 <InputStyled
//                   maxLength={300}
//                   autoComplete={"newpassword"}
//                   placeholder={f(messages.enterAddress)}
//                 />
//               </Form.Item>
//               <InputsWrapper>
//                 <InputWrapper>
//                   <LabelWrapper>
//                     <Label>{f(messages.city)}</Label>
//                   </LabelWrapper>
//                   <Form.Item
//                     key={"city"}
//                     name={"city"}
//                     rules={[
//                       {
//                         required: true,
//                         message: f(messages.validationCity),
//                       },
//                     ]}
//                   >
//                     <InputStyled
//                       maxLength={100}
//                       autoComplete={"newpassword"}
//                       placeholder={f(messages.enterCity)}
//                     />
//                   </Form.Item>
//                 </InputWrapper>
//                 <InputWrapper>
//                   <LabelWrapper>
//                     <Label>{f(messages.state)}</Label>
//                   </LabelWrapper>
//                   <Form.Item key={"state"} name={"state"}>
//                     <InputStyled
//                       maxLength={100}
//                       autoComplete={"newpassword"}
//                       placeholder={f(messages.enterState)}
//                     />
//                   </Form.Item>
//                 </InputWrapper>
//               </InputsWrapper>
//               <InputsWrapper>
//                 <InputWrapper>
//                   <Label>{f(messages.country)}</Label>
//                   <Form.Item
//                     key={"country"}
//                     name={"country"}
//                     rules={[
//                       { required: true, message: f(messages.countryError) },
//                     ]}
//                   >
//                     <SelectStyled
//                       options={options}
//                       bordered={false}
//                       placeholder={f(messages.pleaseSelect)}
//                     />
//                   </Form.Item>
//                 </InputWrapper>
//                 <InputWrapper>
//                   <LabelWrapper>
//                     <Label>{f(messages.postcode)}</Label>
//                   </LabelWrapper>
//                   <Form.Item
//                     key={"postcode"}
//                     name={"postcode"}
//                     rules={[
//                       {
//                         required: true,
//                         message: f(messages.validationPostcode),
//                       },
//                     ]}
//                   >
//                     <InputStyled
//                       maxLength={100}
//                       autoComplete={"newpassword"}
//                       placeholder={f(messages.enterPostcode)}
//                     />
//                   </Form.Item>
//                 </InputWrapper>
//               </InputsWrapper>
//               <Subtitle style={{marginTop: "0.75rem"}}>
//                 As soon as you sign up, we will send you a confirmation email with a link to activate your account.
//                 Please make sure you activate your account before logging in.
//                 <br />If you don't see the email in your Inbox, check the Spam folder.
//               </Subtitle>
//               {unknownError && (
//                 <div
//                   style={{
//                     backgroundColor: "#FCF0F0",
//                     padding: "1rem",
//                     marginBottom: "1em",
//                     display: "flex",
//                     borderRadius: 6,
//                     fontWeight: 400,
//                   }}
//                 >
//                   <AlertIcon />
//                   <span style={{ paddingLeft: "0.5rem" }}>
//                 We were unable to complete your registration. Please
//                 contact <a href={"mailto:support.uk@livlet.com"}>support.uk@livlet.com</a> for help.
//               </span>
//                 </div>
//               )}
//               <ButtonsWrapper>
//                 <SecondaryButton onClick={() => setStepNumber(1)}>
//                   <ButtonText>{f(messages.back)}</ButtonText>
//                 </SecondaryButton>
//                 <ButtonStyled htmlType="submit" type="primary">
//                   <ButtonText>Next</ButtonText>
//                 </ButtonStyled>
//               </ButtonsWrapper>
//               <Subtitle>
//                 {f(messages.registrationFooter, {
//                   loginLink: <a onClick={() => history.push("/login")}>{f(messages.clickHere)}</a>,
//                 })}
//               </Subtitle>
//             </Form>
//           </>
//         )}
//       {(stepNumber === 3 && <>
//           <div style={{display:"flex", flexDirection: "column", justifyContent:"space-between", textAlign:"left",height:"90%"}}>
//               <div style={{display:"flex"}}><PaymentLogo/><span style={{marginLeft:"1rem", color:"#002A43", fontSize:"1.625rem", fontWeight:"bold"}}>Set up payment</span></div>
//               <ol style={{color:"#6B7185", fontSize:"1rem", paddingLeft:"1rem"}}>
//                   <PaymentLi><span style={{color:"#00B74F", fontWeight:"bold"}}>FREE</span> for the first six months</PaymentLi>
//                   <PaymentLi>We will notify you before the start of the paid subscription.</PaymentLi>
//                   <PaymentLi>No obligation, you can cancel your subscription whenever you want.</PaymentLi>
//               </ol>
//               <div style={{marginBottom:"1rem"}}>
//                   <div style={{background:"#00B74F", borderRadius: "6px", width:"100%", padding:"0.5rem 0", margin: "1.5rem 0", color: "#0C2A41", fontSize: "1rem", fontWeight: 600, textAlign:"center"}}>
//                       Use coupon code <span>Livlet2022</span> for a free 6 months trial.
//                   </div>
//                   <span style={{color:"#6B7185", fontSize:"1rem", fontWeight:"bold", marginBottom:"0.5rem"}}>
//                       If you have a voucher code please use it below for a free 6 months trial
//                   </span>
//                   <div className={couponError.length > 0 ? "ant-form-item-has-error" : ""}>
//                       <InputStyled
//                           maxLength={100}
//                           autoComplete={"newpassword"}
//                           value={couponValue}
//                           onChange={(e) => {setCouponValue(e.target.value); setCouponError("");}}
//                       />
//                     {couponError.length > 0 && <div className={"ant-form-item-explain ant-form-item-explain-error"}>
//                         <div className={"alert"}>{couponError}</div>
//                     </div>}
//                   </div>
//               </div>
//               <div style={{display:"flex", flexDirection:"row", alignItems:"baseline"}}>
//                   <div style={{border:"2px solid #00B74F", borderRadius:"10px", padding:"1.25rem", display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems:"center", width:"50%"}}>
//                       <div style={{color:"#21272B", fontSize:"1rem"}}>Core</div>
//                       <div style={{color:"#21272B", fontSize:"1rem", display:"flex", marginTop:"2rem"}}><span style={{fontSize:"2rem", fontWeight:600, margin:"0 0.5rem", marginTop:"-0.25rem"}}>6.99</span> per month</div>
//                       <div style={{textAlign:"center", marginTop:"1rem"}}><span style={{color:"#00B74F", fontWeight:"bold"}}>FREE</span> <span style={{color:"rgba(21, 20, 57, 0.4)", fontSize:"0.75rem"}}>for the first six months</span></div>
//                   </div>
//                   <ol style={{color:"rgba(21, 20, 57, 0.4)", fontSize:"0.75rem"}}>
//                       <PaymentLiSmall>Single property</PaymentLiSmall>
//                       <PaymentLiSmall>10GB of data</PaymentLiSmall>
//                       <PaymentLiSmall>Financial view</PaymentLiSmall>
//                       <PaymentLiSmall>ECO suggestions</PaymentLiSmall>
//                       <PaymentLiSmall>Upkeep, Improvements, Troubleshooting, Alerts</PaymentLiSmall>
//                   </ol>
//               </div>
//               <div>
//                   <ButtonsWrapper>
//                       <SecondaryButton onClick={() => window.history.back()}>
//                           <ButtonText>{f(messages.cancel)}</ButtonText>
//                       </SecondaryButton>
//                       <ButtonStyled htmlType="submit" type="primary" onClick={() => handlePaymentStep()}>
//                           <ButtonText>Subscribe</ButtonText>
//                       </ButtonStyled>
//                   </ButtonsWrapper>
//               </div>
//           </div>
//       </>)}
//
//       {(stepNumber === 4) && <>
//           <div>
//               Processing your payment...
//           </div>
//       </>}
//       {(stepNumber === 5) && <>
//           <div>
//               <Title>Thank you!</Title>
//               <Subtitle>
//                   Thank you for buying our subscription. Please confirm your email to start using Livlet.
//               </Subtitle>
//               <Subtitle>
//                   You can click <a onClick={() => history.push("/")}>here</a> to return to the login page.
//               </Subtitle>
//           </div>
//       </>}
//       {(stepNumber === 101) && <>
//           <div>
//               <Title>Invite expired!</Title>
//               <Subtitle>
//                   Your invite has expired.
//               </Subtitle>
//               <Subtitle>
//                   You can click <a onClick={() => history.push("/")}>here</a> to return to the login page.
//               </Subtitle>
//           </div>
//       </>}
//       </FormWrapper>
//       <ImageWrapper>
//         {stepNumber === 1 ? (
//           <RegistrationPageFirstStepImageStyled />
//         ) : (
//           <RegistrationPageSecondStepImageStyled />
//         )}
//         <TextOnImage>{f(messages.imageText)}</TextOnImage>
//       </ImageWrapper>
//     </Main>
//   );
};

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StepNumber = styled.span`
  color: #6b7185;

  @media (max-width: 1024px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 1rem;
  }
`;

const FormHeader = styled.div`
  @media (max-width: 1024px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 1rem;
  }

  @media (min-height: 720px) {
    height: 15vh;
  }

  padding: 2rem 0 2rem;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
`;

const TextOnImage = styled.h3`
  font-weight: 700;
  color: #ffffff;
  position: absolute;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1024px) {
    font-size: 2.1rem;
    width: 350px;
    top: 0.5rem;
    right: 0.5rem;
  }
`;

const RegistrationPageImageStyled = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1024px) {
    width: 100%;
    height: 100vh;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(/images/registrationImage.jpg);
    margin: 0 auto;
    position: relative;
  }
`;

const RegistrationPageFirstStepImageStyled = styled(
  RegistrationPageImageStyled
)`
  @media (min-width: 1024px) {
    min-height: 770px;
  }
`;

const RegistrationPageSecondStepImageStyled = styled(
  RegistrationPageImageStyled
)`
  @media (min-width: 1024px) {
    min-height: 970px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  padding-top: 0.5rem;
`;

const Main = styled.main`
  display: flex;
  overflow-y: visible;
  height: 100vh;
  max-height: 100vh;
  justify-content: space-between;
  background-color: white;
`;

export const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > div:first-child {
    margin-right: 2rem;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const SelectStyled = styled(Select)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  height: 3.25rem;
  border-radius: 6px;

  @media (min-width: 1024px) {
    padding-top: 0.25em;
    padding-left: 0.25em;
  }

  @media (max-width: 1024px) {
    height: 2.5rem;
  }
`;

export const InputPasswordStyled = styled(Input.Password)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  height: 3.25rem;
  border-radius: 6px;

  @media (max-width: 1024px) {
    height: 2.5rem;
  }
`;

export const InputStyled = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  height: 3.25rem;
  border-radius: 6px;

  @media (max-width: 1024px) {
    height: 2.5rem;
  }
`;

export const InputWrapper = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (min-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 1024px) {
    padding-top: 0.25rem;
  }
`;

const TermAndConditionsDescription = styled.span`
  font-size: 1rem;
`;

const TermsAndConditions = styled.div`
  @media (min-width: 1024px) {
    //padding: 0.5rem 0;
  }
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const Title = styled.h4`
  font-weight: 700;
  font-size: 1.625rem;
  color: #002a43;
`;

const Subtitle = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: #6b7185;
`;

export const Label = styled.p`
  font-weight: 600;
  font-size: 1rem;
`;

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5rem auto;
  width: 100%;
`

const SocialUsername = styled.span`
  color: #21272B;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1.75rem;
`

const SocialEmail = styled.span`
  color: #6B7185;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
`

const PaymentLi = styled.li`
  &::marker {
   content: url("/images/PaymentCheckpoint.png");
  }
  padding-inline-start: 1ch;
  line-height: 2rem;
`;

const PaymentLiSmall = styled.li`
  &::marker {
   content: url("/images/PaymentCheckpointSmall.png");
  }
  padding-inline-start: 2ch;
  line-height: 2rem;
`;


// const PseudoLink = styled.span`
//   color: #2A80E1;
//   font-size: 1rem;
//   line-height: 1.5rem;
//   cursor: pointer;
// `

export default RegistrationPage;
