import {apiGet, apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {clearAccessToken, saveAccessToken} from "./auth";
import _ from "lodash";

export type TMergeSocialRequest = {
  email: string;
  password: string;
  externalSourceName: string;
  externalId: string;
  externalSourceToken: string;
  token: string;
};

export type TGetSubscriptionsResponse = Array<{
  subscriptionId: string;
  // ignore the other fields for now
}>;

export const mergeSocial = async (payload: TMergeSocialRequest & {token: string}) => {
  let {token} = payload;
  let url = createUrl(`${EnvConfig.API_URL}/api/user/mergeSocial?token=${token}`);
  const tmpAuth: any = await apiPost<{}, any>(url, _.omit(payload, ["token"]));

  saveAccessToken(tmpAuth.accessToken);

  url = createUrl(
    `${EnvConfig.API_URL}/api/authentication/activeSubscriptions`
  );

  //@ts-ignore
  const subscriptions: TGetSubscriptionsResponse = await apiGet<TGetSubscriptionsResponse>(url);

  url = createUrl(
    `${EnvConfig.API_URL}/api/authentication/currentTenant?tenantId=` +
    subscriptions[0].subscriptionId
  );
  const auth: any = await apiPost<{}, any>(url, {});

  clearAccessToken();

  return {...auth, ...subscriptions[0]};
};

