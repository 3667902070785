import React, {ReactNode, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as OnboardingUserTask} from "../../../images/OnboardingUserTask.svg";
import {ReactComponent as OnboardingUserProject} from "../../../images/OnboardingUserProject.svg";
import {ReactComponent as OnboardingStandardTask} from "../../../images/OnboardingStandardTask.svg";

import {ReactComponent as QuestionsHouseDetached} from "../../../images/onboarding/QuestionsHouseDetached.svg";
import {ReactComponent as QuestionsHouseSemiDetached} from "../../../images/onboarding/QuestionsHouseSemiDetached.svg";
import {ReactComponent as QuestionsHouseTerraced} from "../../../images/onboarding/QuestionsHouseTerraced.svg";
import {ReactComponent as QuestionsFlatGroundFloor} from "../../../images/onboarding/QuestionsFlatGroundFloor.svg";
import {ReactComponent as QuestionsFlatTopFloor} from "../../../images/onboarding/QuestionsFlatTopFloor.svg";
import {ReactComponent as QuestionsFlatMidFloor} from "../../../images/onboarding/QuestionsFlatMidFloor.svg";
import {ReactComponent as QuestionsHouseOther} from "../../../images/onboarding/QuestionsHouseOther.svg";

import {ReactComponent as QuestionsAC} from "../../../images/onboarding/QuestionsAC.svg";
import {ReactComponent as QuestionsCCTV} from "../../../images/onboarding/QuestionsCCTV.svg";
import {ReactComponent as QuestionsFireAlarm} from "../../../images/onboarding/QuestionsFireAlarm.svg";
import {ReactComponent as QuestionsFireSprinklers} from "../../../images/onboarding/QuestionsFireSprinklers.svg";
import {ReactComponent as QuestionsAudioVideo} from "../../../images/onboarding/QuestionsAudioVideo.svg";
import {ReactComponent as QuestionsSolarPanels} from "../../../images/onboarding/QuestionsSolarPanels.svg";
import {ReactComponent as QuestionsGarden} from "../../../images/onboarding/QuestionsGarden.svg";
import {ReactComponent as QuestionsGarage} from "../../../images/onboarding/QuestionsGarage.svg";
import {ReactComponent as QuestionsPool} from "../../../images/onboarding/QuestionsPool.svg";
import {ReactComponent as QuestionsJacuzzi} from "../../../images/onboarding/QuestionsJacuzzi.svg";
import {ReactComponent as QuestionsBackUpGenerator} from "../../../images/onboarding/QuestionsBackUpGenerator.svg";
import {ReactComponent as QuestionsAGA} from "../../../images/onboarding/QuestionsAGA.svg";

import {ReactComponent as QuestionsGasBoiler} from "../../../images/onboarding/QuestionsGasBoiler.svg";
import {ReactComponent as QuestionsElectricStorgeHeating} from "../../../images/onboarding/QuestionsElectricStorgeHeating.svg";
import {ReactComponent as QuestionsOilBoiler} from "../../../images/onboarding/QuestionsOilBoiler.svg";
import {ReactComponent as QuestionsHeatPump} from "../../../images/onboarding/QuestionsHeatPump.svg";
import {ReactComponent as QuestionsBiomass} from "../../../images/onboarding/QuestionsBiomass.svg";
import {ReactComponent as QuestionsHeatingUnknown} from "../../../images/onboarding/QuestionsHeatingUnknown.svg";
import {ReactComponent as QuestionsCombiBoiler} from "../../../images/onboarding/QuestionsCombiBoiler.svg";
import {ReactComponent as QuestionsHotWaterBig} from "../../../images/onboarding/QuestionsHotWaterBig.svg";
import {ReactComponent as QuestionsHotWaterSmall} from "../../../images/onboarding/QuestionsHotWaterSmall.svg";
import {ReactComponent as QuestionsHeatingElectric} from "../../../images/onboarding/QuestionsHeatingElectric.svg";
import {ReactComponent as QuestionsYes} from "../../../images/onboarding/QuestionsYes.svg";
import {ReactComponent as QuestionsNo} from "../../../images/onboarding/QuestionsNo.svg";

import {ReactComponent as QuestionsSmartGasMeter} from "../../../images/onboarding/QuestionsSmartGasMeter.svg";
import {ReactComponent as QuestionsSmartElectricMeter} from "../../../images/onboarding/QuestionsSmartElectricMeter.svg";
import {ReactComponent as QuestionsSmartTemperature} from "../../../images/onboarding/QuestionsSmartTemperature.svg";
import {ReactComponent as QuestionsHeatingProgrammer} from "../../../images/onboarding/QuestionsHeatingProgrammer.svg";
import {ReactComponent as QuestionsSmartHeating} from "../../../images/onboarding/QuestionsSmartHeating.svg";

import {ReactComponent as QuestionsWall} from "../../../images/onboarding/QuestionsWall.svg";
import {ReactComponent as QuestionsFloor} from "../../../images/onboarding/QuestionsFloor.svg";
import {ReactComponent as QuestionsRoof} from "../../../images/onboarding/QuestionsRoof.svg";
import {ReactComponent as QuestionsDontKnowSquare} from "../../../images/onboarding/QuestionsDontKnowSquare.svg";
import {ReactComponent as QuestionsSingleGlazed} from "../../../images/onboarding/QuestionsSingleGlazed.svg";
import {ReactComponent as QuestionsDoubleGlazed} from "../../../images/onboarding/QuestionsDoubleGlazed.svg";
import {ReactComponent as QuestionsTripleGlazed} from "../../../images/onboarding/QuestionsTripleGlazed.svg";
import {ReactComponent as QuestionsWallSolid} from "../../../images/onboarding/QuestionsWallSolid.svg";
import {ReactComponent as QuestionsWallCavity} from "../../../images/onboarding/QuestionsWallCavity.svg";

import {ReactComponent as QuestionsIOTMachine} from "../../../images/onboarding/QuestionsIOTMachine.svg";
import {ReactComponent as QuestionsIOTFridge} from "../../../images/onboarding/QuestionsIOTFridge.svg";
import {ReactComponent as QuestionsIOTNetwork} from "../../../images/onboarding/QuestionsIOTNetwork.svg";
import {ReactComponent as Checkmark} from "../../../images/CheckmarkGreen.svg";

import {ReactComponent as ContentsCoffeeMachine} from "../../../images/onboarding/ContentsCoffeeMachine.svg";
import {ReactComponent as ContentsComputer} from "../../../images/onboarding/ContentsComputer.svg";
import {ReactComponent as ContentsCookerElectric} from "../../../images/onboarding/ContentsCookerElectric.svg";
import {ReactComponent as ContentsCookerGas} from "../../../images/onboarding/ContentsCookerGas.svg";
import {ReactComponent as ContentsDishWasher} from "../../../images/onboarding/ContentsDishWasher.svg";

import {ReactComponent as ContentsFreezer} from "../../../images/onboarding/ContentsFreezer.svg";
import {ReactComponent as ContentsFridgeFreezer} from "../../../images/onboarding/ContentsFridgeFreezer.svg";
import {ReactComponent as ContentsHobElectric} from "../../../images/onboarding/ContentsHobElectric.svg";
import {ReactComponent as ContentsHobGas} from "../../../images/onboarding/ContentsHobGas.svg";
import {ReactComponent as ContentsKettle} from "../../../images/onboarding/ContentsKettle.svg";

import {ReactComponent as ContentsKitchenHood} from "../../../images/onboarding/ContentsKitchenHood.svg";
import {ReactComponent as ContentsMicrowave} from "../../../images/onboarding/ContentsMicrowave.svg";
import {ReactComponent as ContentsOvenElectric} from "../../../images/onboarding/ContentsOvenElectric.svg";
import {ReactComponent as ContentsOvenGas} from "../../../images/onboarding/ContentsOvenGas.svg";
import {ReactComponent as ContentsRefrigeratorFridge} from "../../../images/onboarding/ContentsRefrigeratorFridge.svg";

import {ReactComponent as ContentsTumbleDryer} from "../../../images/onboarding/ContentsTumbleDryer.svg";
import {ReactComponent as ContentsTV} from "../../../images/onboarding/ContentsTV.svg";
import {ReactComponent as ContentsVacuumCleaner} from "../../../images/onboarding/ContentsVacuumCleaner.svg";
import {ReactComponent as ContentsWasherDryer} from "../../../images/onboarding/ContentsWasherDryer.svg";
import {ReactComponent as ContentsWashingMachine} from "../../../images/onboarding/ContentsWashingMachine.svg";

import {ReactComponent as QuestionsThankYou} from "../../../images/onboarding/QuestionsThankYouHome.svg";
import {ReactComponent as EPCIconBlue} from "../../../images/EPCIconBlue.svg";
import {ReactComponent as HouseIconBlue} from "../../../images/HouseIconBlue.svg";
import {AutoComplete, Button, Input, InputNumber, List, notification, Select, Spin} from "antd";
import {Link, useHistory, useLocation} from "react-router-dom";
import {deleteAnswer, postAnswer} from "../../../api-wrapper/properties/postAnswer";
import {getAllImprovements} from "../../actions/improvements";
import {
  getExpenseTypesSelector, getImprovementsSelector, getMaintenancesSelector,
  getOnboardingMaintenancesSelector, getPropertiesSelector,
  getPropertyIdSelector, getWorksSelector
} from "../../selectors";
import _ from "lodash";
import {putDetails} from "../../../api-wrapper/properties/putDetails";
import {
  detailsImpactingValuation,
  propertyYearConversion,
  propertyYearOptions,
  propertyYearReversedConversion,
} from "../helper";
import {TFetchingStatus} from "../../../helpers";
import MaintenanceListItemNew from "../maintenances/MaintenanceListItemNew";
import {InputStyled, InputsWrapper, InputWrapper, Label, LabelWrapper} from "../../screens/SimpleRegistration";
import SearchLocationInput from "../SearchLocationInput";
import {ReactComponent as LivLetLogo} from "../../../images/LivletLogoNew.svg";
import countryList from "react-select-country-list";
import {emptyGuid, isUKProperty} from "../../screens/helpers";
import {editProperty} from "../../../api-wrapper/properties/editProperty";
import {LoadingOutlined, PlusCircleOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {ActionButtonDarkGreen} from "../../screens/components";
import OnboardingWorkListItem from "../works/OnboardingWorkListItem";
import moment from "moment";
import OnboardingContents from "./OnboardingContents";
import OnboardingSpaces from "./OnboardingSpaces";

type Props = {
  stepNumber: number;
  setOnboardingStepNumber: (arg: number) => void;
  questions: Array<any>;
  propertyDetails: Array<any>;
  toggleEventDrawer: (arg: boolean) => void;
  toggleUpkeepDrawer: (arg: boolean) => void;
  setEditingUpkeepData: (arg: any) => void;
  toggleWorkDrawer: (arg: boolean) => void;
  setEditingWorkData: (arg: any) => void;
  setAddressChanged: (arg: boolean) => void;
  updateAddress: boolean;
  setUpdateAddress: (arg: boolean) => void;
  showLoader: string|null;
  setShowLoader: (arg: string|null) => void;
  redirectUser: any;
  saveAction: boolean;
};

type QuestionProps = {
  icon: ReactNode;
  title: string |  React.ReactElement<HTMLElement>;
  description: string;
  width: string;
  questionId: number;
  answerYes: number | null;
  answerNo: number | null;
};

const PostcodesIO = require('postcodesio-client');

const QuestionsListComp = (props: Props) => {
  const { stepNumber, setOnboardingStepNumber, questions, propertyDetails, toggleUpkeepDrawer, setEditingUpkeepData, setAddressChanged,
    updateAddress, setUpdateAddress, showLoader, setShowLoader, redirectUser, toggleEventDrawer, toggleWorkDrawer, setEditingWorkData,
    saveAction} = props;

  let history = useHistory();
  const dispatch = useDispatch();

  const postcodes = new PostcodesIO('https://api.postcodes.io');

  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);
  const databaseExpenseTypes = useSelector(getExpenseTypesSelector);
  const works = useSelector(getWorksSelector);
  const maintenances = useSelector(getMaintenancesSelector);
  const onboardingMaintenances = useSelector(getOnboardingMaintenancesSelector);
  const improvementsConfiguration = useSelector(getImprovementsSelector);

  const [preselectedBasedOnYear, setPreselectedBasedOnYear] = useState(new Set<number>());
  const [answersCache, setAnswersCache] = useState(new Map<number, number>());
  const [standardMaintenances, setStandardMaintenances] = useState<any>([])
  const [workProjects, setWorkProjects] = useState<any>([])
  const [customMaintenances, setCustomMaintenances] = useState<any>([])
  const [budgetingMaintenances, setBudgetingMaintenances] = useState<any>([])
  const [selectedMeter, setSelectedMeter] = useState("")
  const [expenseTypes, setExpenseTypes] = useState<any>([])
  const [otherTypeId, serOtherTypeId] = useState("")
  const [yearBuilt, setYearBuilt] = useState<any>()
  const [editing, setEditing] = useState("")

  const [areaSizeM2, setAreaSizeM2] = useState<any>(0)
  const [areaSizeSqft, setAreaSizeSqft] = useState<any>(0)
  const [areaUnit, setAreaUnit] = useState("m2")

  const [location, setLocation]: any = useState(null);
  const [addressStreet, setAddressStreet] = useState<any>("");
  const [addressCity, setAddressCity] = useState<any>("");
  const [addressState, setAddressState] = useState<any>("");
  const [addressCountry, setAddressCountry] = useState<any>("");
  const [addressPostalCode, setAddressPostalCode] = useState<any>("");
  const [postalCodeError, setPostalCodeError] = useState(false);

  const getCountryList = () => {
    let result = countryList()
      .getData()
      .filter(
        (country: { value: string; label: string }) =>
          country.value !== "US" && country.value !== "GB"
      );

    result.unshift({ value: "United Kingdom", label: "United Kingdom" });
    result.unshift({ value: "US", label: "United States" });

    return result;
  };

  const optionsCountryList = useMemo(() => getCountryList(), []);

  useEffect(() => {
    if (location) {
      setAddressStreet(location.formattedAddress);
      setAddressPostalCode(location.data.zipcode);
      setAddressCity(location.data.city);
      setAddressState(location.data.state);
      setAddressCountry(location.data.country);
      setAddressChanged(true);
      setPostalCodeError(false);
    }
  }, [location]);

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find((property) => property.propertyId === propertyId);
    if (!currentProperty)
      return;
    setAddressStreet(currentProperty.address);
    setAddressCity(currentProperty.city);
    setAddressCountry(currentProperty.country);
    setAddressPostalCode(currentProperty.zip);
    setAddressState(currentProperty.state);
  }, [properties]);

  useEffect(() => {
    const saveProperty = (property: any) => {
      setShowLoader("");
      editProperty({
        nickname: property.nickname,
        propertyId,
        address: addressStreet,
        address2: "",
        city: addressCity,
        state: addressState,
        country: addressCountry,
        zip: addressPostalCode,
      }).then((res: any) => {
        if (isUKProperty(addressCountry, addressPostalCode)) {
          if (res.epcFound) {
            notification.success({
              message: "EPC data has been found for your property",
              placement: "topRight",
            })
          } else {
            notification.info({
              message: "No EPC data has been found for your property",
              placement: "topRight",
            })
          }
        }
        setUpdateAddress(false);
        setOnboardingStepNumber(stepNumber + 1);
        dispatch(getAllImprovements(propertyId));
      });
    }

    const currentProperty = properties.find((property) => property.propertyId === propertyId);
    if (!updateAddress || !currentProperty) {
      setUpdateAddress(false);
      return;
    }

    if (addressPostalCode && addressCountry === "United Kingdom") {
      postcodes.lookup(addressPostalCode).then((res: any) => {
        if (res) {
          saveProperty(currentProperty)
        } else {
          setPostalCodeError(true);
          setUpdateAddress(false);
        }
      })
    } else {
      saveProperty(currentProperty);
    }

  }, [updateAddress]);

  useEffect(() => {
    // @ts-ignore
    let recommendedMaintenances = onboardingMaintenances.content.filter(item =>
      item.category !== "Custom" && item.category !== "Budgeting - User created" && item.category !== "Reminder"
      && !item.notApplicable && !item.title.includes("Clean curtains"))
      .sort((i1, i2) => {return i1.title.localeCompare(i2.title)})
    let userMaintenances = onboardingMaintenances.content.filter(item => (item.category === "Custom" || item.category === "Reminder"))

    setStandardMaintenances(recommendedMaintenances)
    setCustomMaintenances(userMaintenances)
  }, [onboardingMaintenances])

  useEffect(() => {
    let userWorks = works.content.filter(work => work.name && !work.isComplete).sort((w1, w2) => {
      let dueDate1 = w1.dueDate ? moment(w1.dueDate).format('YYYYMMDD') : 999999999
      let dueDate2 = w2.dueDate ? moment(w2.dueDate).format('YYYYMMDD') : 999999999
      // @ts-ignore
      return dueDate1 - dueDate2
    })
    setWorkProjects(userWorks)
  }, [works])

  useEffect(() => {
    setBudgetingMaintenances(maintenances.content.filter(item => item.category === "Budgeting"))
  }, [maintenances])

  useEffect(() => {
    if (databaseExpenseTypes.fetchingStatus === TFetchingStatus.Success) {
      setExpenseTypes(Object.values(databaseExpenseTypes.content))
    }
  }, [databaseExpenseTypes])

  useEffect(() => {
    if (expenseTypes.length) {
      const maintenanceCategoryId = expenseTypes.filter((type: any) => type.name.toLowerCase().includes("maintenance"))[0].id
      serOtherTypeId(expenseTypes.filter((type: any) =>
        type.name.toLowerCase().includes("other") && type.parentId === maintenanceCategoryId)[0].id)
    }
  }, [expenseTypes])

  const useQuery = (query: string) => {
    let queryValue = new URLSearchParams(useLocation().search);
    return queryValue.get(query);
  };
  const pageToOpen = useQuery("openPage")

  const getAnswerForQuestion = (questionId: number) => {
    const question = questions.filter(question => question.id === questionId);
    if (!question || question.length !== 1)
      return null;
    if (answersCache.has(questionId)) {
      return answersCache.get(questionId);
    }
    return question[0].givenAnswerId;
  }

  const isQuestionAvailable = (questionId: number) => {
    if (getAnswerForQuestion(1) === 5) {
      return questionId !== 6;
    }
    if (getAnswerForQuestion(1) === 6) {
      return questionId !== 6 && questionId !==3;
    }
    if (getAnswerForQuestion(1) === 7) {
      return questionId !== 3;
    }
    else return true;
  }

  const isEpcAnswer = (questionId: number, answerId: number) => {
    const question = questions.filter(question => question.id === questionId);
    if (!question || question.length !== 1)
      return false;
    if (questionId === 3 && (question[0].epcAnswerId === 16 || question[0].epcAnswerId === 17))
      return true;
    return question[0].epcAnswerId === answerId;
  }

  const setAnswerForQuestion = (questionId: number, answerId: number | null, preventDispatch?: boolean) => {
    if (answerId === null) {
      answersCache.delete(questionId);
      setAnswersCache(new Map(answersCache));
      deleteAnswer(propertyId, questionId)
        .then(() => {
          !preventDispatch && dispatch(getAllImprovements(propertyId));
        })
    } else {
      answersCache.set(questionId, answerId);
      setAnswersCache(new Map(answersCache));
      postAnswer(propertyId, answerId)
        .then(() => {
          !preventDispatch && dispatch(getAllImprovements(propertyId));
        })
    }
  }

  const getPropertyDetailsValue = (detailId: number) => {
    const propertyDetail = propertyDetails.filter(detail => detail.id === detailId)
    if (propertyDetail && propertyDetail.length > 0) {
      return propertyDetail[0].answer
    } else return null;
  }

  const setPropertyDetailsValue = (detailId: number, answer: number | null | undefined | string) => {
    let updateValuation = false;
    if (detailsImpactingValuation.includes(detailId))
      updateValuation = true;
    putDetails(propertyId, [{id: detailId, answer: answer}], updateValuation)
      .then(() => {
        dispatch(getAllImprovements(propertyId));
      })
  }

  useEffect(() => {
    setYearBuilt(propertyYearReversedConversion(getPropertyDetailsValue(3)))
  }, [propertyDetails])

  const selectYearOnClick = (questionId: number, answerId: number) => {
    setAnswerForQuestion(questionId, answerId);
    if (answerId === 158) {
      setPreselectedBasedOnYear(new Set());
      preselectedBasedOnYear.add(11);
      preselectedBasedOnYear.add(23);
      setAnswerForQuestion(2, 11);
      setAnswerForQuestion(4, 23);
      notification.info({
        message: "Selected answers",
        description: "Based on the year your property was built we have pre-selected the Wall Type and Windows that you have, please verify our suggestions on the next screens.",
        placement: "topRight",
      });
      setPreselectedBasedOnYear(new Set(preselectedBasedOnYear));
    } else if (answerId === 159) {
      setPreselectedBasedOnYear(new Set());
      preselectedBasedOnYear.add(12);
      preselectedBasedOnYear.add(23);
      preselectedBasedOnYear.add(25);
      preselectedBasedOnYear.add(27);
      preselectedBasedOnYear.add(16);
      preselectedBasedOnYear.add(34);
      preselectedBasedOnYear.add(119);
      preselectedBasedOnYear.add(123);
      preselectedBasedOnYear.add(75);
      preselectedBasedOnYear.add(99);
      setAnswerForQuestion(2, 12);
      setAnswerForQuestion(4, 23);
      setAnswerForQuestion(5, 27);
      setAnswerForQuestion(3, 16);
      setAnswerForQuestion(6, 34);
      setAnswerForQuestion(27, 119);
      setAnswerForQuestion(28, 123);
      setAnswerForQuestion(15, 75);
      setAnswerForQuestion(21, 99);
      notification.info({
        message: "Selected answers",
        description: "Based on the year your property was built we have pre-selected the Wall Type, Windows, Insulation and Smart meters that you have, please verify our suggestions on the next screens.",
        placement: "topRight",
      });
      setPreselectedBasedOnYear(new Set(preselectedBasedOnYear));
    } else if (answerId === 160) {
      setPreselectedBasedOnYear(new Set());
      preselectedBasedOnYear.add(12);
      preselectedBasedOnYear.add(23);
      preselectedBasedOnYear.add(25);
      preselectedBasedOnYear.add(27);
      preselectedBasedOnYear.add(16);
      preselectedBasedOnYear.add(34);
      preselectedBasedOnYear.add(119);
      preselectedBasedOnYear.add(123);
      preselectedBasedOnYear.add(75);
      preselectedBasedOnYear.add(99);
      preselectedBasedOnYear.add(19);
      preselectedBasedOnYear.add(66);
      setAnswerForQuestion(2, 12);
      setAnswerForQuestion(4, 23);
      setAnswerForQuestion(5, 27);
      setAnswerForQuestion(3, 16);
      setAnswerForQuestion(6, 34);
      setAnswerForQuestion(27, 119);
      setAnswerForQuestion(28, 123);
      setAnswerForQuestion(15, 75);
      setAnswerForQuestion(21, 99);
      setAnswerForQuestion(19, 91);
      setAnswerForQuestion(13, 66);
      notification.info({
        message: "Selected answers",
        description: "Based on the year your property was built we have pre-selected the Wall Type, Windows, Insulation and Smart meters that you have, please verify our suggestions on the next screens.",
        placement: "topRight",
      });
      setPreselectedBasedOnYear(new Set(preselectedBasedOnYear));
    } else if (answerId === 170) {
      setPreselectedBasedOnYear(new Set());
    }
  }

  const checkForQuestion5 = () => {
    if (isQuestionAvailable(6) && isQuestionAvailable(3)) {
      return getAnswerForQuestion(5) === 25 && getAnswerForQuestion(3) === 14 && getAnswerForQuestion(6) === 36
    } else if (isQuestionAvailable(6)) {
      return getAnswerForQuestion(5) === 25 && getAnswerForQuestion(6) === 36
    } else if (isQuestionAvailable(3)) {
      return getAnswerForQuestion(5) === 25 && getAnswerForQuestion(3) === 14
    } else {
      return getAnswerForQuestion(5) === 25;
    }
  }

  const returnAnswerForQ5 = (answerYes: boolean) => {
    if (answerYes) {
      if (getAnswerForQuestion(4) === 22) return 29
      if (getAnswerForQuestion(4) === 23) return 27
      else return 30
    } else {
      if (getAnswerForQuestion(4) === 22) return 28
      if (getAnswerForQuestion(4) === 23) return 26
      else return 25
    }
  }

  const QuestionHoverable = (props: QuestionProps) => {
    const {icon, title, description, width, questionId, answerYes, answerNo} = props;

    const className = () => {
      let val = "question-wrapper ";
      if (questionId === 5 && answerYes === null) {
        if (checkForQuestion5()) {
          val += "selected";
        }
      }
      if (questionId === 3) {
        if (getAnswerForQuestion(3) === 16 || getAnswerForQuestion(3) === 17) {
          val += "selected";
        }
      }
      else if (questionId === 29) {
        if (getAnswerForQuestion(29) === 128) {
          val += "selected";
        } else if (getAnswerForQuestion(questionId) === answerYes) {
          val += "selected";
        }
      }
      else if (questionId === 83) {
        if (getAnswerForQuestion(83) === 271 && getAnswerForQuestion(9) === 51) {
          val += "selected";
        }
      }
      else if (questionId === 84) {
        if (getAnswerForQuestion(84) === 273 && getAnswerForQuestion(9) === 51) {
          val += "selected";
        }
      }
      else {
        if (getAnswerForQuestion(questionId) === answerYes)
          val += "selected";
      }
      return val;
    };

    const onClick = () => {
      if (questionId === 5 && answerYes === null) {
        setAnswerForQuestion(5, 25);
        setAnswerForQuestion(3, 14);
        setAnswerForQuestion(6, 36);
      } else if (questionId === 5 && getAnswerForQuestion(5) === 25) {
        setAnswerForQuestion(5, returnAnswerForQ5(true));
      } else if (questionId === 5 && getAnswerForQuestion(5) === 30) {
        setAnswerForQuestion(5, returnAnswerForQ5(false));
      } else if (questionId === 4) {
        if (answerYes === 22 && getAnswerForQuestion(4) === 22 || answerYes === 23 && getAnswerForQuestion(4) === 23) {
          if (getAnswerForQuestion(5) === 27 || getAnswerForQuestion(5) === 29) {
            setAnswerForQuestion(5, 30); // select unspecified wall insulation
          }
          if (getAnswerForQuestion(5) === 28) {
            setAnswerForQuestion(5, 25); // deselect unspecified wall insulation
          }
          setAnswerForQuestion(4, 24); // wall type: unknown
        } else {
          if (answerYes === 22 && getAnswerForQuestion(4) !== 22) {
            setAnswerForQuestion(4, 22);
            if (getAnswerForQuestion(5) === 27 || getAnswerForQuestion(5) === 30) {
              setAnswerForQuestion(5, 29);
            } else if (getAnswerForQuestion(5) === 26) {
              setAnswerForQuestion(5, 28);
            }
          }
          if (answerYes === 23 && getAnswerForQuestion(4) !== 23) {
            setAnswerForQuestion(4, 23);
            if (getAnswerForQuestion(5) === 29 || getAnswerForQuestion(5) === 30) {
              setAnswerForQuestion(5, 27);
            } else if (getAnswerForQuestion(5) === 28) {
              setAnswerForQuestion(5, 26);
            }
          }
        }
      } else if (questionId === 6) {
        if (getAnswerForQuestion(6) === 35 || getAnswerForQuestion(6) === 36) {
          setAnswerForQuestion(6, 34);
        } else {
          setAnswerForQuestion(6, 35);
        }
      }
      else if (questionId === 29) {
        if (answerYes === 129 && getAnswerForQuestion(29) === 127) {
          setAnswerForQuestion(29, 128);
        }
        else if (answerYes === 127 && getAnswerForQuestion(29) === 129) {
          setAnswerForQuestion(29, 128);
        }
        else if (answerYes === 129 && getAnswerForQuestion(29) === 128) {
          setAnswerForQuestion(29, 127)
        }
        else if (answerYes === 127 && getAnswerForQuestion(29) === 128) {
          setAnswerForQuestion(29, 129)
        }
        else if (answerYes === 127 && getAnswerForQuestion(29) === 127) {
          setAnswerForQuestion(29, 130)
        }
        else if (answerYes === 129 && getAnswerForQuestion(29) === 129) {
          setAnswerForQuestion(29, 130)
        }
        else {
          setAnswerForQuestion(questionId, getAnswerForQuestion(questionId) === answerNo ||
          _.isUndefined(getAnswerForQuestion(questionId)) || (answerNo === null &&
            getAnswerForQuestion(questionId) !== answerYes) ? answerYes : answerNo);
        }
      }
      else {
        setAnswerForQuestion(questionId, getAnswerForQuestion(questionId) === answerNo || _.isUndefined(getAnswerForQuestion(questionId)) || (answerNo === null && getAnswerForQuestion(questionId) !== answerYes) ? answerYes : answerNo);
        if (questionId === 44) {
          if (getAnswerForQuestion(44) === 171) {
            setAnswerForQuestion(50, 184);
          }
        } else if (questionId === 45) {
          if (getAnswerForQuestion(45) === 173) {
            setAnswerForQuestion(50, 184);
          }
        } else if (questionId === 46) {
          if (getAnswerForQuestion(46) === 175) {
            setAnswerForQuestion(50, 184);
          }
        } else if (questionId === 50) {
          if (getAnswerForQuestion(50) === 183) {
            setAnswerForQuestion(44, 172);
            setAnswerForQuestion(45, 174);
            setAnswerForQuestion(46, 176);
          }
        } else if (questionId === 47) {
          if (getAnswerForQuestion(47) === 177) {
            setAnswerForQuestion(51, 186);
          }
        } else if (questionId === 48) {
          if (getAnswerForQuestion(48) === 179) {
            setAnswerForQuestion(51, 186);
          }
        } else if (questionId === 49) {
          if (getAnswerForQuestion(49) === 181) {
            setAnswerForQuestion(51, 186);
          }
        } else if (questionId === 51) {
          if (getAnswerForQuestion(51) === 185) {
            setAnswerForQuestion(47, 178);
            setAnswerForQuestion(48, 180);
            setAnswerForQuestion(49, 182);
          }
        } else if (questionId === 9) {
          if (getAnswerForQuestion(9) !== 51) {
            setAnswerForQuestion(83, 272);
            setAnswerForQuestion(84, 274);
          }
        } else if (questionId === 83) {
          if (getAnswerForQuestion(83) === 271) {
            setAnswerForQuestion(9, 51);
            setAnswerForQuestion(84, 274);
          }
          if (getAnswerForQuestion(83) === 272) {
            setAnswerForQuestion(9, null);
          }
        } else if (questionId === 84) {
          if (getAnswerForQuestion(84) === 273) {
            setAnswerForQuestion(9, 51);
            setAnswerForQuestion(83, 272);
          }
          if (getAnswerForQuestion(84) === 274) {
            setAnswerForQuestion(9, null);
          }
        }
      }
    };

    return (<QuestionButtonTall
        width={width}
        className={className()}
        onClick={onClick}
    >
      {answerYes && preselectedBasedOnYear.has(answerYes) && <PreSelectedIcon><HouseIconBlue /></PreSelectedIcon>}
      {answerYes && isEpcAnswer(questionId, answerYes) && !preselectedBasedOnYear.has(answerYes) && <PreSelectedIcon><EPCIconBlue /></PreSelectedIcon>}
      <QuestionHeader>
        {icon}{title}
        {className().indexOf("selected") > 0 ? <MinusCircleOutlined/> : <PlusCircleOutlined/>}
      </QuestionHeader>
      <QuestionDescription>{description}</QuestionDescription>
      {window.innerWidth >= 1024 && <div className={"question-content"}>
        <QuestionSelect className={className().includes("selected") ? "hidden" : ""}>Select</QuestionSelect>
      </div>}
    </QuestionButtonTall>)
  }

  const displayUpkeepStep = () => (
    <UpkeepWrapper>
      <ContentsPageHeader>
          <span style={{fontWeight: 600, color: "#21272B"}}>
            Now let's pick maintenance & upkeep tasks you would typically undertake in your home. We recommend 2 or 3.
          </span>
          <span>
            With this we can help you stay on top of regular maintenance with timely reminders and populate your calendar.<br/>
            We've suggested a few below you can sign-up to by clicking the sign-up button. You can edit the start date and frequency of these measures.
          </span>
      </ContentsPageHeader>
      <AddEventWrapper>
        <span className={"text-wrapper"}>
          If you can't find any relevant tasks below, you can create your own reminder, task or project.
        </span>
        <span className={"button-wrapper"}>
          <ActionButtonDarkGreen icon={<PlusOutlined />} onClick={() => toggleEventDrawer(true)}>
            Add an event
          </ActionButtonDarkGreen>
        </span>
      </AddEventWrapper>
      {workProjects.length > 0 && <UpkeepListTitle>
        <span className={"list-title"}>
          <OnboardingUserProject /> User-created projects
        </span>
      </UpkeepListTitle>}
      {workProjects.length > 0 && <UpkeepList>
        <List
          itemLayout="horizontal"
          className="rounded-bottom-list"
          style={{background: "white", borderRadius: "6px"}}
          dataSource={workProjects}
          renderItem={(item) => <OnboardingWorkListItem
            item={item}
            toggleWorkDrawer={toggleWorkDrawer}
            setEditingWorkData={setEditingWorkData}
          />}
        />
      </UpkeepList>}
      {customMaintenances.length > 0 && <UpkeepListTitle>
        <span className={"list-title"}>
          <OnboardingUserTask/> User-created tasks and reminders
        </span>
      </UpkeepListTitle>}
      {customMaintenances.length > 0 && <UpkeepList>
        <List
          itemLayout="horizontal"
          className="rounded-bottom-list"
          style={{background: "white", borderRadius: "6px"}}
          dataSource={customMaintenances}
          renderItem={(item) => <MaintenanceListItemNew
            item={item}
            setEditingUpkeepData={setEditingUpkeepData}
            toggleUpkeepDrawer={toggleUpkeepDrawer}
            editing={editing}
            setEditing={setEditing}
            isOnboarding={true}
          />}
        />
      </UpkeepList>}
      <UpkeepListTitle>
        <span className={"list-title"}>
          <OnboardingStandardTask /> Example maintenance & upkeep tasks
        </span>
        <span className={"upkeep-link"} onClick={() => {}}>
          <Link to="/upkeep?tab=All" target="_blank" rel="noopener noreferrer">
            View all typical home maintenances
          </Link>
        </span>
      </UpkeepListTitle>
      <UpkeepList>
        <List
          itemLayout="horizontal"
          className="rounded-bottom-list"
          style={{background: "white", borderRadius: "6px"}}
          dataSource={standardMaintenances}
          renderItem={(item) => <MaintenanceListItemNew
            item={item}
            setEditingUpkeepData={setEditingUpkeepData}
            toggleUpkeepDrawer={toggleUpkeepDrawer}
            editing={editing}
            setEditing={setEditing}
            isOnboarding={true}
          />}
        />
      </UpkeepList>
      <AddEventWrapper>
        <span className={"text-wrapper"}>
          If you can't find any relevant tasks above, you can create your own reminder, task or project.
        </span>
        <span className={"button-wrapper"}>
          <ActionButtonDarkGreen icon={<PlusOutlined />} onClick={() => toggleEventDrawer(true)}>
            Add an event
          </ActionButtonDarkGreen>
        </span>
      </AddEventWrapper>
    </UpkeepWrapper>
  )

  const displayBudgetCostsStep = () => (
    <>
      <ContentsPageHeader>
        <span style={{fontWeight: 600, color: "#21272B"}}>Let's now sign-up to a few regular budgeting upkeep items</span>
        <span>With this we'll start populating your costs forecasts. We've gone ahead and prepared a few of the most regular household budget costs. You can edit the start date, cost & frequency of these measures.</span>
      </ContentsPageHeader>
      <UpkeepList>
        <List
          itemLayout="horizontal"
          className="rounded-bottom-list"
          style={{background: "white", borderRadius: "6px"}}
          dataSource={budgetingMaintenances}
          renderItem={(item) => <MaintenanceListItemNew
            item={item}
            setEditingUpkeepData={setEditingUpkeepData}
            toggleUpkeepDrawer={toggleUpkeepDrawer}
            editing={editing}
            setEditing={setEditing}
            isOnboarding={false}
          />}
        />
      </UpkeepList>
      <UpkeepAfterword>
        You'll then be able to keep track of these and add/edit custom budget costs from your Livlet Upkeep page.
      </UpkeepAfterword>
    </>
  )

  const displayContentsStep = () => (
    <>
      {pageToOpen !== "contents" && <QuestionsTitle>
          Do you have any of the following systems, spaces or appliances in your home? We'll use these to map your data
          home and recommend upkeep measures.
      </QuestionsTitle>}
      {pageToOpen === "contents" && <ContentsPageHeader>
          <span style={{fontWeight: 600, color: "#21272B"}}>Let's start by selecting a few appliances you may have in your home</span>
          <span>We'll help you decide which items deserve to be insured, track end of life and replacement cycles, etc.</span>
      </ContentsPageHeader>}
      {improvementsConfiguration.fetchingStatus === TFetchingStatus.Success ? <QuestionsButtonsSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(31) === 134 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(31,getAnswerForQuestion(31) === 135 || _.isUndefined(getAnswerForQuestion(31)) ? 134 : 135, true)}
        ><QuestionsAC/>AC</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(32) === 136 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(32,getAnswerForQuestion(32) === 137 || _.isUndefined(getAnswerForQuestion(32)) ? 136 : 137, true)}
        ><QuestionsCCTV/>CCTV</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(33) === 138 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(33,getAnswerForQuestion(33) === 139 || _.isUndefined(getAnswerForQuestion(33)) ? 138 : 139, true)}
        ><QuestionsFireAlarm/>Fire alarm</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(34) === 140 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(34,getAnswerForQuestion(34) === 141 || _.isUndefined(getAnswerForQuestion(34)) ? 140 : 141, true)}
        ><QuestionsFireSprinklers/>Fire sprinklers</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(35) === 142 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(35,getAnswerForQuestion(35) === 143 || _.isUndefined(getAnswerForQuestion(35)) ? 142 : 143, true)}
        ><QuestionsAudioVideo/>Audio/video system</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(36) === 144 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(36,getAnswerForQuestion(36) === 145 || _.isUndefined(getAnswerForQuestion(36)) ? 144 : 145, true)}
        ><QuestionsSolarPanels/>Solar panels</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(37) === 146 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(37,getAnswerForQuestion(37) === 147 || _.isUndefined(getAnswerForQuestion(37)) ? 146 : 147, true)}
        ><QuestionsGarden/>Garden</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(38) === 148 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(38,getAnswerForQuestion(38) === 149 || _.isUndefined(getAnswerForQuestion(38)) ? 148 : 149, true)}
        ><QuestionsGarage/>Garage</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(39) === 150 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(39,getAnswerForQuestion(39) === 151 || _.isUndefined(getAnswerForQuestion(39)) ? 150 : 151, true)}
        ><QuestionsPool/>Pool</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(40) === 152 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(40,getAnswerForQuestion(40) === 153 || _.isUndefined(getAnswerForQuestion(40)) ? 152 : 153, true)}
        ><QuestionsJacuzzi/>Jacuzzi</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(41) === 154 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(41,getAnswerForQuestion(41) === 155 || _.isUndefined(getAnswerForQuestion(41)) ? 154 : 155, true)}
        ><QuestionsBackUpGenerator/>Back-up power generator</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(42) === 156 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(42,getAnswerForQuestion(42) === 157 || _.isUndefined(getAnswerForQuestion(42)) ? 156 : 157, true)}
        ><QuestionsAGA/>AGA</QuestionButtonSmall>
      </QuestionsButtonsSmall> : <div style={{display: "flex", justifyContent: "center", marginTop: "1rem", gap: "0.5rem"}}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />} />
      </div>}
      {improvementsConfiguration.fetchingStatus === TFetchingStatus.Success && <QuestionsButtonsSmall style={{marginTop: 0}}>
        <QuestionButtonSmall
          className={getAnswerForQuestion(55) === 198 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(55,getAnswerForQuestion(55) === 199 || _.isUndefined(getAnswerForQuestion(55)) ? 198 : 199, true)}
        ><ContentsWashingMachine /> Washing Machine</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(56) === 200 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(56,getAnswerForQuestion(56) === 201 || _.isUndefined(getAnswerForQuestion(56)) ? 200 : 201, true)}
        ><ContentsDishWasher /> Dishwasher</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(57) === 202 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(57,getAnswerForQuestion(57) === 203 || _.isUndefined(getAnswerForQuestion(57)) ? 202 : 203, true)}
        ><ContentsTumbleDryer /> Tumble Dryer</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(58) === 204 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(58,getAnswerForQuestion(58) === 205 || _.isUndefined(getAnswerForQuestion(58)) ? 204 : 205, true)}
        ><ContentsWasherDryer /> Washer Dryer</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(59) === 206 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(59,getAnswerForQuestion(59) === 207 || _.isUndefined(getAnswerForQuestion(59)) ? 206 : 207, true)}
        ><ContentsRefrigeratorFridge /> Fridge</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(60) === 208 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(60,getAnswerForQuestion(60) === 209 || _.isUndefined(getAnswerForQuestion(60)) ? 208 : 209, true)}
        ><ContentsFreezer/> Freezer</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(61) === 210 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(61,getAnswerForQuestion(61) === 211 || _.isUndefined(getAnswerForQuestion(61)) ? 210 : 211, true)}
        ><ContentsFridgeFreezer /> Fridge Freezer</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(62) === 212 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(62,getAnswerForQuestion(62) === 213 || _.isUndefined(getAnswerForQuestion(62)) ? 212 : 213, true)}
        ><ContentsOvenGas/> Oven (Gas)</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(63) === 214 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(63,getAnswerForQuestion(63) === 215 || _.isUndefined(getAnswerForQuestion(63)) ? 214 : 215, true)}
        ><ContentsMicrowave/> Microwave</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(64) === 216 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(64,getAnswerForQuestion(64) === 217 || _.isUndefined(getAnswerForQuestion(64)) ? 216 : 217, true)}
        ><ContentsHobGas/> Hob (Gas)</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(65) === 218 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(65,getAnswerForQuestion(65) === 219 || _.isUndefined(getAnswerForQuestion(65)) ? 218 : 219, true)}
        ><ContentsTV/> TV</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(66) === 220 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(66,getAnswerForQuestion(66) === 221 || _.isUndefined(getAnswerForQuestion(66)) ? 220 : 221, true)}
        ><ContentsCookerGas/> Cooker (Gas)</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(67) === 222 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(67,getAnswerForQuestion(67) === 223 || _.isUndefined(getAnswerForQuestion(67)) ? 222 : 223, true)}
        ><ContentsCoffeeMachine /> Coffee Machine</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(68) === 224 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(68,getAnswerForQuestion(68) === 225 || _.isUndefined(getAnswerForQuestion(68)) ? 224 : 225, true)}
        ><ContentsVacuumCleaner/> Vacuum Cleaner</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(69) === 226 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(69,getAnswerForQuestion(69) === 227 || _.isUndefined(getAnswerForQuestion(69)) ? 226 : 227, true)}
        ><ContentsComputer /> Computer</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(70) === 228 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(70,getAnswerForQuestion(70) === 229 || _.isUndefined(getAnswerForQuestion(70)) ? 228 : 229, true)}
        ><ContentsKitchenHood /> Kitchen Hood</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(71) === 230 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(71,getAnswerForQuestion(71) === 231 || _.isUndefined(getAnswerForQuestion(71)) ? 230 : 231, true)}
        ><ContentsCookerElectric/> Cooker (Elec)</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(72) === 232 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(72,getAnswerForQuestion(72) === 233 || _.isUndefined(getAnswerForQuestion(72)) ? 232 : 233, true)}
        ><ContentsHobElectric/> Hob (Elec)</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(73) === 234 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(73,getAnswerForQuestion(73) === 235 || _.isUndefined(getAnswerForQuestion(73)) ? 234 : 235, true)}
        ><ContentsKettle/> Kettle (Elec)</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getAnswerForQuestion(74) === 236 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(74,getAnswerForQuestion(74) === 237 || _.isUndefined(getAnswerForQuestion(74)) ? 236 : 237, true)}
        ><ContentsOvenElectric/> Oven (Elec)</QuestionButtonSmall>
      </QuestionsButtonsSmall>}
    </>
  );

  const displayContentsStepMobile = () => (
    <>
      <div style={{width: "100%", padding: "0 2rem"}}>
        <div style={{marginTop: "0.75rem", textAlign: "center", fontSize: "0.75rem", lineHeight: "1.125rem", color: "#666F7A"}}>
          Tap on all relevant systems and appliances to add them to your Livlet product.
        </div>
      </div>

      <QuestionsButtonsMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(31) === 134 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(31,getAnswerForQuestion(31) === 135 || _.isUndefined(getAnswerForQuestion(31)) ? 134 : 135)}
        >AC</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(32) === 136 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(32,getAnswerForQuestion(32) === 137 || _.isUndefined(getAnswerForQuestion(32)) ? 136 : 137)}
        >CCTV</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(33) === 138 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(33,getAnswerForQuestion(33) === 139 || _.isUndefined(getAnswerForQuestion(33)) ? 138 : 139)}
        >Fire alarm</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(34) === 140 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(34,getAnswerForQuestion(34) === 141 || _.isUndefined(getAnswerForQuestion(34)) ? 140 : 141)}
        >Fire sprinklers</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(35) === 142 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(35,getAnswerForQuestion(35) === 143 || _.isUndefined(getAnswerForQuestion(35)) ? 142 : 143)}
        >Audio/video system</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(36) === 144 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(36,getAnswerForQuestion(36) === 145 || _.isUndefined(getAnswerForQuestion(36)) ? 144 : 145)}
        >Solar panels</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(37) === 146 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(37,getAnswerForQuestion(37) === 147 || _.isUndefined(getAnswerForQuestion(37)) ? 146 : 147)}
        >Garden</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(38) === 148 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(38,getAnswerForQuestion(38) === 149 || _.isUndefined(getAnswerForQuestion(38)) ? 148 : 149)}
        >Garage</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(39) === 150 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(39,getAnswerForQuestion(39) === 151 || _.isUndefined(getAnswerForQuestion(39)) ? 150 : 151)}
        >Pool</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(40) === 152 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(40,getAnswerForQuestion(40) === 153 || _.isUndefined(getAnswerForQuestion(40)) ? 152 : 153)}
        >Jacuzzi</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(41) === 154 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(41,getAnswerForQuestion(41) === 155 || _.isUndefined(getAnswerForQuestion(41)) ? 154 : 155)}
        >Back-up power generator</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(42) === 156 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(42,getAnswerForQuestion(42) === 157 || _.isUndefined(getAnswerForQuestion(42)) ? 156 : 157)}
        >AGA</QuestionButtonMobile>

        <QuestionButtonMobile
          style={{marginTop: "1rem"}}
          className={getAnswerForQuestion(55) === 198 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(55,getAnswerForQuestion(55) === 199 || _.isUndefined(getAnswerForQuestion(55)) ? 198 : 199)}
        >Washing Machine</QuestionButtonMobile>
        <QuestionButtonMobile
          style={{marginTop: "1rem"}}
          className={getAnswerForQuestion(56) === 200 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(56,getAnswerForQuestion(56) === 201 || _.isUndefined(getAnswerForQuestion(56)) ? 200 : 201)}
        >Dishwasher</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(57) === 202 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(57,getAnswerForQuestion(57) === 203 || _.isUndefined(getAnswerForQuestion(57)) ? 202 : 203)}
        >Tumble Dryer</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(58) === 204 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(58,getAnswerForQuestion(58) === 205 || _.isUndefined(getAnswerForQuestion(58)) ? 204 : 205)}
        >Washer Dryer</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(59) === 206 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(59,getAnswerForQuestion(59) === 207 || _.isUndefined(getAnswerForQuestion(59)) ? 206 : 207)}
        >Fridge</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(60) === 208 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(60,getAnswerForQuestion(60) === 209 || _.isUndefined(getAnswerForQuestion(60)) ? 208 : 209)}
        >Freezer</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(61) === 210 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(61,getAnswerForQuestion(61) === 211 || _.isUndefined(getAnswerForQuestion(61)) ? 210 : 211)}
        >Fridge Freezer</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(62) === 212 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(62,getAnswerForQuestion(62) === 213 || _.isUndefined(getAnswerForQuestion(62)) ? 212 : 213)}
        >Oven (Gas)</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(63) === 214 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(63,getAnswerForQuestion(63) === 215 || _.isUndefined(getAnswerForQuestion(63)) ? 214 : 215)}
        >Microwave</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(64) === 216 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(64,getAnswerForQuestion(64) === 217 || _.isUndefined(getAnswerForQuestion(64)) ? 216 : 217)}
        >Hob (Gas)</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(65) === 218 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(65,getAnswerForQuestion(65) === 219 || _.isUndefined(getAnswerForQuestion(65)) ? 218 : 219)}
        >TV</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(66) === 220 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(66,getAnswerForQuestion(66) === 221 || _.isUndefined(getAnswerForQuestion(66)) ? 220 : 221)}
        >Cooker (Gas)</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(67) === 222 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(67,getAnswerForQuestion(67) === 223 || _.isUndefined(getAnswerForQuestion(67)) ? 222 : 223)}
        >Coffee Machine</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(68) === 224 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(68,getAnswerForQuestion(68) === 225 || _.isUndefined(getAnswerForQuestion(68)) ? 224 : 225)}
        >Vacuum Cleaner</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(69) === 226 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(69,getAnswerForQuestion(69) === 227 || _.isUndefined(getAnswerForQuestion(69)) ? 226 : 227)}
        >Computer</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(70) === 228 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(70,getAnswerForQuestion(70) === 229 || _.isUndefined(getAnswerForQuestion(70)) ? 228 : 229)}
        >Kitchen Hood</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(71) === 230 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(71,getAnswerForQuestion(71) === 231 || _.isUndefined(getAnswerForQuestion(71)) ? 230 : 231)}
        >Cooker (Elec)</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(72) === 232 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(72,getAnswerForQuestion(72) === 233 || _.isUndefined(getAnswerForQuestion(72)) ? 232 : 233)}
        >Hob (Elec)</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(73) === 234 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(73,getAnswerForQuestion(73) === 235 || _.isUndefined(getAnswerForQuestion(73)) ? 234 : 235)}
        >Kettle (Elec)</QuestionButtonMobile>
        <QuestionButtonMobile
          className={getAnswerForQuestion(74) === 236 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(74,getAnswerForQuestion(74) === 237 || _.isUndefined(getAnswerForQuestion(74)) ? 236 : 237)}
        >Oven (Elec)</QuestionButtonMobile>
      </QuestionsButtonsMobile>
    </>
  );

  const displayStep1 = () => (
    <>
      <QuestionsTitle>
        Add an address to your property
      </QuestionsTitle>
      <QuestionsSubTitle>This will help us to source area specific alerts and search for your energy performance certificate (EPC).</QuestionsSubTitle>
      <QuestionsWrapper>
        <InputsWrapper>
          <InputWrapper style={{margin: window.innerWidth >= 1024 ? "0 2rem 0 0" : "0 0 0 0"}}>
            <LabelWrapper>
              <Label>Find address</Label>{" "}
            </LabelWrapper>
            {
              //@ts-ignore
              window.google && <SearchLocationInput setValue={setLocation} />
            }
          </InputWrapper>
          <InputWrapper style={{margin: window.innerWidth >= 1024 ? "0 2rem 0 0" : "1rem 0 0 0"}}>
            <Label>Enter street address</Label>{" "}
            <InputStyled
              maxLength={300}
              autoComplete={"newpassword"}
              //className={!addressStreet ? "field-error" : ""}
              placeholder={"Enter street address"}
              value={addressStreet}
              onChange={(val) => {setAddressStreet(val.target.value); setAddressChanged(true);}}
            />
            {/*{!addressStreet && <ErrorMessage>Street value is invalid</ErrorMessage>}*/}
          </InputWrapper>
        </InputsWrapper>
        <InputsWrapper>
          <InputsWrapper className={"halved"}>
            <InputWrapper style={{margin: window.innerWidth >= 1024 ? "0 2rem 0 0" : "1rem 0 0 0"}}>
              <LabelWrapper>
                <Label>City</Label>{" "}
              </LabelWrapper>
              <InputStyled
                maxLength={300}
                autoComplete={"newpassword"}
                placeholder={"Enter city"}
                value={addressCity}
                //className={!addressCity ? "field-error" : ""}
                onChange={(val) => {
                  setAddressCity(val.target.value);
                  setAddressChanged(true);
                }}
              />
              {/*{!addressCity && <ErrorMessage>City value is invalid</ErrorMessage>}*/}
            </InputWrapper>
            <InputWrapper style={{margin: window.innerWidth >= 1024 ? "0 2rem 0 0" : "1rem 0 0 0"}}>
              <Label>County</Label>{" "}
              <InputStyled
                maxLength={300}
                autoComplete={"newpassword"}
                placeholder={"Enter county"}
                value={addressState}
                onChange={(val) => {setAddressState(val.target.value); setAddressChanged(true);}}
              />
            </InputWrapper>
          </InputsWrapper>
          <InputsWrapper className={"halved"}>
            <InputWrapper style={{margin: window.innerWidth >= 1024 ? "0 2rem 0 0" : "1rem 0 0 0"}}>
              <LabelWrapper>
                <Label>Country</Label>{" "}
              </LabelWrapper>
              <SelectStyledCountry
                options={optionsCountryList}
                bordered={false}
                placeholder={"Please select"}
                value={addressCountry}
                onChange={(val) => setAddressCountry(val)}
              />
            </InputWrapper>
            <InputWrapper style={{margin: window.innerWidth >= 1024 ? "0 2rem 0 0" : "1rem 0 0 0"}}>
              <Label>Postcode</Label>{" "}
              <InputStyled
                maxLength={300}
                autoComplete={"newpassword"}
                placeholder={"Enter postcode"}
                className={postalCodeError ? "field-error" : ""}
                value={addressPostalCode}
                onChange={(val) => {
                  setAddressPostalCode(val.target.value);
                  setAddressChanged(true);
                }}
              />
              {postalCodeError && <ErrorMessage>Post code is invalid</ErrorMessage>}
          </InputWrapper>
        </InputsWrapper>
      </InputsWrapper>
      {window.innerWidth >= 1024 && <div style={{
        backgroundColor: "#FFFFFF", borderRadius: "6px",
        minHeight:"6rem", color:"#128756", display:"flex",
        flexDirection:"column", alignItems:"center",
        marginRight:"2rem", marginTop:"3rem",
        marginBottom:"8rem", padding:"0.75rem 3rem",
        textAlign:"center", fontSize:"0.75rem", lineHeight: "1.25rem"}}>
        <LivLetLogo className={"livlet-logo"}/>
        <p style={{marginTop:"2rem", marginBottom: 0}}>
          Your address will be used to source your Energy Performance Certificate if available to build our recommendations
          engine and understand key interfaces in your property. We'll also use this to display your purchase price, advise on
          your area's price increase and provide you with relevant flood risks. This will also contribute to populating the
          property value simulator tool.</p>
      </div>}
      </QuestionsWrapper>
    </>
  );

  const displayStep2 = () => (
    <>
      <QuestionsTitle>
        What kind of house do you have?
      </QuestionsTitle>
      <QuestionsSubTitle>This will help us to assume key interfaces and spaces.</QuestionsSubTitle>
      <QuestionsButtons>
        <QuestionButton
          className={getAnswerForQuestion(1) === 2 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(1,2)}
        >
          {isEpcAnswer(1, 2) && <PreSelectedIcon><EPCIconBlue /></PreSelectedIcon>}
          <QuestionsHouseDetached/>House detached
          {getAnswerForQuestion(1) === 2 ? <MinusCircleOutlined/> : <PlusCircleOutlined/>}
        </QuestionButton>
        <QuestionButton
          className={getAnswerForQuestion(1) === 3 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(1,3)}
        >
          {isEpcAnswer(1, 3) && <PreSelectedIcon><EPCIconBlue /></PreSelectedIcon>}
          <QuestionsHouseSemiDetached/>House semi-detached
          {getAnswerForQuestion(1) === 3 ? <MinusCircleOutlined/> : <PlusCircleOutlined/>}
        </QuestionButton>
        <QuestionButton
          className={getAnswerForQuestion(1) === 4 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(1,4)}
        >
          {isEpcAnswer(1, 4) && <PreSelectedIcon><EPCIconBlue /></PreSelectedIcon>}
          <QuestionsHouseTerraced/>House terraced
          {getAnswerForQuestion(1) === 4 ? <MinusCircleOutlined/> : <PlusCircleOutlined/>}
        </QuestionButton>
        <QuestionButton
          className={getAnswerForQuestion(1) === 7 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(1,7)}
        >
          {isEpcAnswer(1, 7) && <PreSelectedIcon><EPCIconBlue /></PreSelectedIcon>}
          <QuestionsFlatGroundFloor/>Flat ground floor
          {getAnswerForQuestion(1) === 7 ? <MinusCircleOutlined/> : <PlusCircleOutlined/>}
        </QuestionButton>
        <QuestionButton
          className={getAnswerForQuestion(1) === 5 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(1,5)}
        >
          {isEpcAnswer(1, 5) && <PreSelectedIcon><EPCIconBlue /></PreSelectedIcon>}
          <QuestionsFlatTopFloor/>Flat top floor
          {getAnswerForQuestion(1) === 5 ? <MinusCircleOutlined/> : <PlusCircleOutlined/>}
        </QuestionButton>
        <QuestionButton
          className={getAnswerForQuestion(1) === 6 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(1,6)}
        >
          {isEpcAnswer(1, 6) && <PreSelectedIcon><EPCIconBlue /></PreSelectedIcon>}
          <QuestionsFlatMidFloor/>Flat mid floor
          {getAnswerForQuestion(1) === 6 ? <MinusCircleOutlined/> : <PlusCircleOutlined/>}
        </QuestionButton>
        <QuestionButton
          style={{paddingRight: window.innerWidth<1024 ? "5rem" : "0"}}
          className={getAnswerForQuestion(1) === 1 ? "selected" : ""}
          onClick={() => setAnswerForQuestion(1,1)}
        >
          {isEpcAnswer(1, 1) && <PreSelectedIcon><EPCIconBlue /></PreSelectedIcon>}
          <QuestionsHouseOther/>Don't know/Other
        </QuestionButton>
      </QuestionsButtons>
    </>
  );

  useEffect(() => {
    setAreaSizeM2(getPropertyDetailsValue(2))
    setAreaSizeSqft(getPropertyDetailsValue(2) * 10.76)

  }, [propertyDetails])

  const displayStep3 = () => (
    <>
      <QuestionsTitle>
        Tell us about your property
      </QuestionsTitle>
      <QuestionsSubTitle>This will help us to assume types of construction and recommend upkeep & maintenance tasks.</QuestionsSubTitle>
      <PropertyDetailsOnboardingWrapper>
        <div style={{display: "flex", flexDirection: "column", rowGap: "0.75rem"}}>
          <QuestionsSubtitle>
            Year of construction <span className={"extra"}>(can be approximate)</span>
          </QuestionsSubtitle>
          <AutoCompleteStyled
            value={yearBuilt}
            options={propertyYearOptions}
            filterOption={(inputValue: any, option: any) =>
              option!.value.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
            }
            onChange={(val: any) => {
              if (!isNaN(propertyYearConversion(val)) || val === "") {
                setYearBuilt(val)
              }
            }}
            onBlur={() => {
              setPropertyDetailsValue(3, propertyYearConversion(yearBuilt));
              setYearBuilt(propertyYearReversedConversion(yearBuilt));
            }}
          />
        </div>
        <div style={{display: "flex", flexDirection: "column", rowGap: "0.75rem"}}>
          <QuestionsSubtitle>
            Property floor area <span className={"extra"}>(leave blank if you don't know)</span>
          </QuestionsSubtitle>
          <Input.Group compact>
            <InputNumberStyled
              min={1}
              style={{margin: 0}}
              maxLength={10}
              precision={0}
              value={areaUnit === "m2" ? areaSizeM2 : areaSizeSqft}
              onChange={(val) => {
                if (typeof(val) === "number") {
                  if (areaUnit === "m2") {
                    setAreaSizeM2(val)
                    // @ts-ignore
                    setAreaSizeSqft(val * 10.76)
                    setPropertyDetailsValue(2, Math.round(val))
                  } else {
                    // @ts-ignore
                    setAreaSizeM2(val / 10.76)
                    setAreaSizeSqft(val)
                    // @ts-ignore
                    setPropertyDetailsValue(2, Math.round(val / 10.76))
                  }
                } else {
                  setAreaSizeM2(null)
                  setAreaSizeSqft(null)
                  setPropertyDetailsValue(2, "")
                }
              }}
            />
            <SelectStyled
              style={{width: '35%'}}
              value={areaUnit}
              onChange={(value: any) => setAreaUnit(value)}
            >
              <Select.Option key={"m2"} value="m2">m2</Select.Option>
              <Select.Option key={"sqft"} value="sqft">sqft</Select.Option>
            </SelectStyled>
          </Input.Group>
        </div>
        <div style={{display: "flex", flexDirection: "column", rowGap: "0.75rem"}}>
          <QuestionsSubtitle>
            {window.innerWidth >= 1024 ? "What is your ownership status with this property?" : "Property ownership"}
          </QuestionsSubtitle>
          <QuestionsButtonsTallProperty style={{margin: 0}}>
            <QuestionButtonPropertySmall
              className={getAnswerForQuestion(14) === 71 ? "selected" : ""}
              onClick={() => setAnswerForQuestion(14,71)}
            >{window.innerWidth >= 1024 ? "I am a tenant" : "Tenant"}</QuestionButtonPropertySmall>
            <QuestionButtonPropertySmall
              className={getAnswerForQuestion(14) === 72 ? "selected" : ""}
              onClick={() => setAnswerForQuestion(14,72)}
            >{window.innerWidth >= 1024 ? "I am a landlord" : "Landlord"}</QuestionButtonPropertySmall>
            <QuestionButtonPropertySmall
              className={getAnswerForQuestion(14) === 70 ? "selected" : ""}
              onClick={() => setAnswerForQuestion(14,70)}
            >{window.innerWidth >= 1024 ? "I am a homeowner" : "Homeowner"}</QuestionButtonPropertySmall>
          </QuestionsButtonsTallProperty>
        </div>
        <div style={{display: "flex", flexDirection: "column", rowGap: "0.75rem"}}>
          <QuestionsSubtitle>
            {window.innerWidth >= 1024 ? "Does anyone in your household work from home?" : "Do you work from home?"}
          </QuestionsSubtitle>
          <QuestionsButtonsDuoProperty style={{margin: 0}}>
            <QuestionButtonProperty style={{width: window.innerWidth >= 1024 ? "90%" : "45%"}}
              className={getAnswerForQuestion(30) === 132 ? "selected" : ""}
              onClick={() => setAnswerForQuestion(30,132)}
            >{window.innerWidth >= 1024  && <QuestionsYes/>} Yes</QuestionButtonProperty>
            <QuestionButtonProperty style={{width: window.innerWidth >= 1024 ? "90%" : "45%"}}
              className={getAnswerForQuestion(30) === 133 ? "selected" : ""}
              onClick={() => setAnswerForQuestion(30,133)}
            >{window.innerWidth >= 1024 && <QuestionsNo/>} No</QuestionButtonProperty>
          </QuestionsButtonsDuoProperty>
        </div>
      </PropertyDetailsOnboardingWrapper>
    </>
  );

  const displayQuestionHeating = () => (
    <>
      <QuestionsTitle>
        What is your heating system?
      </QuestionsTitle>
      <QuestionsSubTitle>This will help us tailor our sustainability or cost saving Guidance or Upkeep tasks we can recommend.</QuestionsSubTitle>
      <QuestionsButtonsTall>
        <QuestionHoverable
          icon={<QuestionsGasBoiler />}
          questionId={9} answerYes={48} answerNo={null}
          title={"Gas boiler"}
          description={"Gas boilers provide heating to your home by burning natural gas. This requires a gas supply."} width={"wide"} />
        <QuestionHoverable
          icon={<QuestionsElectricStorgeHeating/>}
          questionId={9} answerYes={49} answerNo={null}
          title={"Electric storage heating"}
          description={"Electric storage heaters use electricity to store heat and release it when required."}  width={"wide"} />
        <QuestionHoverable
          icon={<QuestionsOilBoiler/>}
          questionId={9} answerYes={50} answerNo={null}
          title={"Oil boiler"}
          description={"Oil boilers provide heating to your home by burning Oil. This requires an oil fuel tank."} width={"wide"} />
        <QuestionHoverable
          icon={<QuestionsHeatPump/>}
          questionId={83} answerYes={271} answerNo={272}
          title={"Heat pump (Ground Source)"}
          description={"Heat pumps use electricity to extract heat from the ground to heat your home."} width={"wide"} />
        <QuestionHoverable
          icon={<QuestionsHeatPump/>}
          questionId={84} answerYes={273} answerNo={274}
          title={"Heat pump (Air Source)"}
          description={"Heat pumps use electricity to extract heat from the air to heat your home."} width={"wide"} />
        <QuestionHoverable
          icon={<QuestionsBiomass/>}
          questionId={9} answerYes={52} answerNo={null}
          title={"Biomass or other low carbon heating"}
          description={"Biomass boilers provide heating to your home by burning organic fuel, such as wood chips & pellets."} width={"wide"} />
        <QuestionHoverable
          icon={<QuestionsHeatingUnknown/>}
          questionId={9} answerYes={53} answerNo={null}
          title={"Don't know/Other"}
          description={"Select this option if you are unsure of your system or if it is not represented above (We'll assume a gas boiler initially for calculation purposes)."} width={"wide"} />
      </QuestionsButtonsTall>
    </>
  )

  const displayQuestionWaterSystem = () => (
    <>
      <QuestionsTitle>
        What type of domestic hot water system do you have?
      </QuestionsTitle>
      <QuestionsSubTitle>This will help us tailor our sustainability or cost saving Guidance or Upkeep tasks we can recommend.</QuestionsSubTitle>
      <QuestionsButtonsTall>
        <QuestionHoverable
          icon={<QuestionsCombiBoiler/>}
          questionId={10} answerYes={56} answerNo={null}
          title={"Combi boiler"}
          description={"Combination boilers generate heating & hot water for your taps. You're likely to have this if you do not have a hot water cylinder."} width={"wide"} />
        <QuestionHoverable
          icon={<QuestionsHotWaterBig/>}
          questionId={10} answerYes={57} answerNo={null}
          title={"Hot water cylinder - insulated (more than 80mm)"}
          description={"Hot water cylinders store your domestic hot water. This assumes a jacket insulation of at least 80mm equivalent to a 25mm thickness pre-Insulated unit."} width={"wide"} />
        <QuestionHoverable
          icon={<QuestionsHotWaterSmall/>}
          questionId={10} answerYes={58} answerNo={null}
          title={"Hot water cylinder - insulated (less than 80mm)"}
          description={"Hot water cylinders store your domestic hot water. This assumes a jacket insulation of at less than 80mm (or less than 25mm thickness pre-Insulated)."} width={"wide"} />
        <QuestionHoverable
          icon={<QuestionsHeatingElectric/>}
          questionId={10} answerYes={59} answerNo={null}
          title={"Other (eg. electric)"}
          description={"Select this option if your domestic hot water system is fed by direct electric units (including electric showers, electric point of use water heaters)."} width={"wide"} />
        <QuestionHoverable
          icon={<QuestionsHeatingUnknown/>}
          questionId={10} answerYes={60} answerNo={null}
          title={"Don't know/Other"}
          description={"Select this option if you are unsure of your system or if it is not represented above (we'll assume a combi-boiler initially)."} width={"wide"} />
      </QuestionsButtonsTall>
    </>
  )

  const displayStep4 = () => (
    <>
      {displayQuestionHeating()}
      {displayQuestionWaterSystem()}
    </>
  );

  const displayStep4Mobile = () => (
    <>
      {displayQuestionHeating()}
    </>
  );

  const displayStep5Mobile = () => (
    <>
      {displayQuestionWaterSystem()}
    </>
  );

  const displayStep5 = () => (
    <>
      <QuestionsTitle>
        Which of the following smart controls or features do you have?
      </QuestionsTitle>
      <QuestionsSubTitle>This will help us tailor our sustainability or cost saving Guidance or Upkeep tasks we can recommend.</QuestionsSubTitle>
      <QuestionsButtonsTall>
        <span onClick={() => setSelectedMeter("gas")}>
          <QuestionHoverable
            icon={<QuestionsSmartGasMeter/>}
            questionId={29} answerYes={129} answerNo={null}
            title={"Smart Gas Meter"}
            description={"Smart gas meters are next-generation gas meters that can provide automatic meter readings and allow you to visualise your energy use."} width={"wide"} />
        </span>
        <span onClick={() => setSelectedMeter("electricity")}>
          <QuestionHoverable
            icon={<QuestionsSmartElectricMeter/>}
            questionId={29} answerYes={127} answerNo={null}
            title={"Smart Electricity Meter"}
            description={"Smart electricity meters are next-generation gas meters that can provide automatic meter readings and allow you to visualise your energy use."} width={"wide"} />
        </span>
           <QuestionHoverable
            icon={<QuestionsSmartTemperature/>}
            questionId={19} answerYes={91} answerNo={92}
            title={"Smart heating time and temperature controls"}
            description={"Time & Temperature controls provide you with the ability to subdivide your home's heating and set individual temperatures and programming settings by room, or group of rooms. This would be controlled via an app or an in-home display device."} width={"wide"} />
          <QuestionHoverable
            icon={<QuestionsHeatPump/>}
            questionId={27} answerYes={119} answerNo={120}
            title={"Thermostatic Radiator Valves"}
            description={"Thermostatic radiator valves are self-regulating radiator valves designed to maintain a specific temperature within a space."} width={"wide"} />
          <QuestionHoverable
            icon={<QuestionsHeatingProgrammer/>}
            questionId={28} answerYes={123} answerNo={124}
            title={"Central Heating Programmer"}
            description={"A central heating programmer allows you to program your boiler to turn on/off at specific times of the day and set target temperatures at which the temperature will turn off."} width={"wide"} />
          <QuestionHoverable
            icon={<QuestionsSmartHeating/>}
            questionId={26} answerYes={115} answerNo={116}
            title={"Smart app heating"}
            description={"Smart heating via phone-enabled smart app that controls your heating system.  Examples are Nest, Hive, Tado."} width={"wide"} />
      </QuestionsButtonsTall>
    </>
  );

  const displayQuestionInsulation = () => (<>
    <QuestionsTitle>
      Is your home insulated?
    </QuestionsTitle>
    <QuestionsButtonsSmall style={{width: window.innerWidth >= 1024 ? "90%" : "100%"}}>
      <QuestionHoverable
        icon={<QuestionsWall/>}
        questionId={5} answerYes={returnAnswerForQ5(true)} answerNo={returnAnswerForQ5(false)}
        title={"Wall"}
        description={"Select this option if your walls are insulated internally, externally or within the cavity."} width={"narrow"} />
      {isQuestionAvailable(6) && <QuestionHoverable
          icon={<QuestionsFloor/>}
          questionId={6} answerYes={34} answerNo={35}
          title={"Floor"}
          description={"Select this option if your ground floor is insulated above slab level."} width={"narrow"} />}
      {isQuestionAvailable(3) && <QuestionHoverable
          icon={<QuestionsRoof/>}
          questionId={3} answerYes={16} answerNo={14}
          title={"Roof"}
          description={"Select this option if your roof is insulated either directly below roof or at loft level."} width={"narrow"} />}
      <QuestionHoverable icon={<QuestionsDontKnowSquare/>}
           questionId={5} answerYes={null} answerNo={null}
           title={"Don't know/Other"}
           description={"Select this option if you are unsure whether your walls, floor or roof are insulated. We'll assume not for the time being - uploading your EPC can help us assess this."} width={"narrow"} />
    </QuestionsButtonsSmall>
  </>)

  const displayQuestionWindows = () => (<>
    <QuestionsTitle>
      What kind of windows do you have?
    </QuestionsTitle>
    <QuestionsSubTitle>This section will help us tailor our Guidance.</QuestionsSubTitle>
    <QuestionsButtonsSmall>
      <QuestionHoverable
        icon={<QuestionsSingleGlazed/>}
        questionId={2} answerYes={10} answerNo={null}
        title={"Mostly single glazed"}
        description={"Select this option if your windows are majoritarily single glazed."} width={"narrow"} />
      <QuestionHoverable
        icon={<QuestionsDoubleGlazed/>}
        questionId={2} answerYes={11} answerNo={null}
        title={"Mostly double glazed before 2002"}
        description={"Select this option if your windows are majoritarily double glazed with windows installed prior to 2002."} width={"narrow"} />
      <QuestionHoverable
        icon={<QuestionsTripleGlazed/>}
        questionId={2} answerYes={12} answerNo={null}
        title={window.innerWidth < 1024 ? <>Mostly triple or double glazed<br/>from after 2002</> : "Mostly triple or double glazed from after 2002"}
        description={"Select this option if your windows are majoritarily double or triple glazed with windows installed from 2002 onwards."} width={"narrow"} />
      <QuestionHoverable
        icon={<QuestionsDontKnowSquare/>}
        questionId={2} answerYes={9} answerNo={null}
        title={"Don't know/Other"}
        description={"Select this option if you are unsure whether your windows are single or multi-glazed. We'll assume not for the time being - uploading your EPC can help us assess this."} width={"narrow"} />
    </QuestionsButtonsSmall>
  </>)

  const displayQuestionWalls = () => (<>
    <QuestionsTitle>
      What wall type do you have?
    </QuestionsTitle>
    <QuestionsSubTitle>This section will help us tailor our Guidance.</QuestionsSubTitle>
    <QuestionsButtonsDuo>
      <QuestionHoverable
        icon={<QuestionsWallSolid/>}
        questionId={4} answerYes={22} answerNo={24}
        title={"Solid"}
        description={"Solid walls are typical of homes built prior 1920-1930. They typically include a width of two wythes of bricks wall with no gap between."} width={"narrow"} />
      <QuestionHoverable
        icon={<QuestionsWallCavity/>}
        questionId={4} answerYes={23} answerNo={24}
        title={"Cavity"}
        description={"Cavity walls are typical of homes built after 1920-1930. They include a gap (cavity) between an external brick wall and internal brick wall."} width={"narrow"} />
    </QuestionsButtonsDuo>
  </>)

  const displayStep6 = () => (<>
    {displayQuestionInsulation()}
    {displayQuestionWindows()}
    {displayQuestionWalls()}
  </>)

  const displayStep7Mobile = () => (<>
    {displayQuestionWalls()}
    {displayQuestionInsulation()}
  </>)

  const displayStep8Mobile = () => (<>
    {displayQuestionWindows()}
  </>)

  const displayStep7 = () => (<>
    <QuestionsTitle>
      Do you have any of the following Internet of Things (IoT) devices?
    </QuestionsTitle>
    <QuestionsSubTitle>This section will help us tailor our Guidance and suggested Upkeep.</QuestionsSubTitle>
    <QuestionsButtonsSmall>
      <QuestionHoverable
        icon={<QuestionsIOTMachine/>}
        questionId={44} answerYes={171} answerNo={172}
        title={"Networked washing machines"}
        description={"A networked washing machine is connected to your home network or internet and can be controlled & monitored via a phone app."} width={"regular"} />
      <QuestionHoverable
        icon={<QuestionsIOTFridge/>}
        questionId={45} answerYes={173} answerNo={174}
        title={"Smart fridges"}
        description={"A networked refrigerator is connected to your home network or internet and can be controlled & monitored via a phone app."} width={"regular"} />
      <QuestionHoverable
        icon={<QuestionsIOTNetwork/>}
        questionId={46} answerYes={175} answerNo={176}
        title={"Network connectivity"}
        description={"Select this if you have a home wi-fi network to which other smart pieces of equipment are connected."} width={"regular"} />
      <QuestionHoverable
        icon={<QuestionsDontKnowSquare/>}
        questionId={50} answerYes={183} answerNo={184}
        title={"Don't know/Other"}
        description={"Select this option if you are unsure about your network status. We will assume you have a wi-fi system in the home."} width={"regular"} />
    </QuestionsButtonsSmall>
  </>)

  const displayFinalStep = () => (<>
    <QuestionsThankYou style={{marginTop: window.innerWidth > 1024 ? '2.5rem' : '1rem'}} />
    <ThankYouTitle>
      Thank you for your answers!
    </ThankYouTitle>
    <ThankYouDescription>
      <div style={{display: "flex", flexDirection: "column"}}>
        <div style={{display: "flex", marginBottom: "1rem"}}>
          <span style={{width: '14px', height: '20px'}}>
            <Checkmark />
          </span>
          <span style={{paddingLeft: '0.75rem', textAlign: 'left'}}>
            We've created a suggested <a onClick={() => history.push("/upkeep?tab=All")}>Maintenance checklist</a> where you can sign up to receive reminders.
          </span>
        </div>
        <div style={{display: "flex", marginBottom: "1rem"}}>
          <span style={{width: '14px', height: '20px'}}>
            <Checkmark />
          </span>
          <span style={{paddingLeft: '0.75rem', textAlign: 'left'}}>
            We've also created the <a onClick={() => history.push("/guidance")}>Guidance</a> section where you can view
            and compare options to reduce bills, improve ECO or protect from hazards.
          </span>
        </div>
        <div style={{display: "flex", marginBottom: "1rem"}}>
          <span style={{width: '14px', height: '20px'}}>
            <Checkmark />
          </span>
          <span style={{paddingLeft: '0.75rem', textAlign: 'left'}}>
            We will aim to offer useful contextual suggestions as you enter new data to Livlet. You can revisit the
            questions on <a onClick={() => history.push("/guidance")}>Guidance</a> or update your answers
            on <a onClick={() => history.push(`/properties/${propertyId}`)}>Property details</a> page.
          </span>
        </div>
        <div style={{display: "flex"}}>
          <span style={{width: '14px', height: '20px'}}>
            <Checkmark />
          </span>
          <span style={{paddingLeft: '0.75rem', textAlign: 'left'}}>
            The next step is to start to build your livlet home, to add your
            home's <a onClick={() => history.push("/spaces")}>Spaces</a> and link your appliances, systems and more in
            your <a onClick={() => history.push("/contents")}>Contents</a>.
          </span>
        </div>
      </div>
      {window.innerWidth > 1024 && <div style={{display: "flex", marginTop: "2rem", gap: "1rem", width: "100%", justifyContent: "center"}}>
        <BlueButton onClick={() => redirectUser()}>Start</BlueButton>
        <BlueButton onClick={() => history.push("/spaces")}>Go to Spaces</BlueButton>
      </div>}

    </ThankYouDescription>
  </>)

  const getBottomMargin = () => {
    if ((pageToOpen === "contents" || pageToOpen === "spaces") && window.innerWidth < 1024) return "8.5rem"
    else if (pageToOpen === "upkeep") return "8.5rem"
    else if ((stepNumber > 1 || pageToOpen === "contents" || pageToOpen === "budgetCosts") && window.innerWidth >= 1024) return "5rem"
    else return 0
  }

  if (showLoader) {
    return <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
      <Spin indicator={<LoadingOutlined style={{fontSize: 72, marginTop: "10rem", marginLeft: 0}} spin />} />
      <div style={{marginTop:"3rem"}}>
        {showLoader.length < 1
          ? isUKProperty(addressCountry, addressPostalCode)
            ? "Loading EPC Data for your house..."
            : "Processing your address, please wait..."
          : showLoader
        }
      </div>
    </div>;
  }

  return (
    <QuestionsContent className={pageToOpen === "upkeep" ? "upkeep" : ""} style={{marginBottom: getBottomMargin()}}>
      {window.innerWidth > 1024 && stepNumber < 8 && pageToOpen !== "contents" && pageToOpen !== "upkeep" && pageToOpen !== "budgetCosts" && pageToOpen !== "spaces" && <QuestionsNumber>
        QUESTION {stepNumber}/7
      </QuestionsNumber>}
      {pageToOpen === "contents" && <OnboardingContents saveAction={saveAction} setShowLoader={setShowLoader} redirectUser={redirectUser}/>}
      {pageToOpen === "spaces" && <OnboardingSpaces saveAction={saveAction} setShowLoader={setShowLoader} redirectUser={redirectUser}/>}
      {pageToOpen === "upkeep" && displayUpkeepStep()}
      {pageToOpen === "budgetCosts" && displayBudgetCostsStep()}
      {stepNumber === 1 && pageToOpen !== "upkeep" && pageToOpen !== "contents" && pageToOpen !== "upkeep" &&pageToOpen !== "spaces" && displayStep1()}
      {stepNumber === 2 && displayStep2()}
      {stepNumber === 3 && displayStep3()}
      {stepNumber === 4 && (window.innerWidth > 1024 ? displayStep4() : displayStep4Mobile())}
      {stepNumber === 5 && (window.innerWidth > 1024 ? displayStep5() : displayStep5Mobile())}
      {stepNumber === 6 && (window.innerWidth > 1024 ? displayStep6() : displayStep5())}
      {stepNumber === 7 && (window.innerWidth > 1024 ? displayStep7() : displayStep7Mobile())}
      {window.innerWidth > 1024
        ? (stepNumber >= 8 && displayFinalStep())
        : (<>
          {stepNumber === 8 && displayStep8Mobile()}
          {stepNumber === 9 && displayStep7()}
          {stepNumber >= 10 && displayFinalStep()}
          </>)
      }
    </QuestionsContent>
  );
};

const QuestionsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60rem;
  
  &.upkeep { width: 100%; }
  
  @media (min-width: 1024px) {
    margin-top: 2rem;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const QuestionsNumber = styled.span`
  color: #128756;
  font-weight: 600;
  font-size: 1.125rem;
  margin-top: 1rem;
`;

const QuestionsTitle = styled.span`
  @media (min-width: 1024px) {
    color: #21272B;
    font-weight: 700;
    font-size: 2rem;
    margin-top: 1rem;
    text-align: center;
  }

  @media (max-width: 1024px) {
    color: #128756;
    font-weight: 600;
    font-size: 1.125rem;
    text-align: left;
    width: 100%;
    padding: 0 1.5rem;
  }
`;

const QuestionsSubTitle = styled.p`
  @media (min-width: 1024px) {
    display: none;
  }

  @media (max-width: 1024px) {
    color: #3E5D58;
    font-weight: 500;
    font-size: 0.75rem;
    text-align: left;
    width: 100%;
    padding: 0 1.5rem;
    margin-bottom: 0;
    margin-top: 0.75rem;
  }
`;

const ThankYouTitle = styled.span`
  color: #21272B;
  font-weight: 700;
  margin-top: 1rem;
  text-align: center;
  
  @media (min-width: 1024px) {
    font-size: 2.25rem;
  }
  
  @media (max-width: 1024px) {
    font-size: 1rem;
  }
`;

const QuestionsSubtitle = styled.span`
  color: #21272B;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 1rem;
  line-height: 1.5rem;
  text-align: left;
  align-self: flex-start;
  
  .extra {
    color: #128756;
    font-size: 0.75rem;
  }
`;

const QuestionsButtons = styled.div`
  @media (min-width: 1024px) {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 0 8rem 0;
  }
`;

const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    margin: 2rem 0;
    row-gap: 2rem;
  }
  
  @media (max-width: 1024px) {
    width: 100%;
    padding: 1rem 1.5rem 8rem 1.5rem;
  }
`;

export const QuestionsButtonsSmall = styled(QuestionsButtons)`
  grid-template-columns: repeat(4, minmax(0, 1fr));
`;

export const QuestionsButtonsMobile = styled(QuestionsButtons)`
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem;
  row-gap: 0.75rem;
  column-gap: 0.75rem;
  justify-content: center;
`;

const QuestionsButtonsDuo = styled(QuestionsButtons)`  
  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  
  @media (max-width: 1024px) {
    padding-bottom: 2rem!important;
  }
`;

const QuestionsButtonsDuoProperty = styled(QuestionsButtonsDuo)`
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0!important;
  }
`;

const QuestionsButtonsTall = styled(QuestionsButtons)`
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const QuestionsButtonsTallProperty = styled(QuestionsButtonsTall)`
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0!important;
  }
`

const QuestionButton = styled.div`
  display: flex;
  position: relative;
  align-content: center;
  align-items: center;
  background: #fff;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #3E5D58;
  border: 2px solid white;
  transition: .5s all ease;
  cursor: pointer;
  
  @media (min-width: 1024px) {
    justify-content: center;
    height: 6rem;
    margin: 0.5rem;
    width: 19rem;
    
    .anticon-plus-circle {
      display: none;
    }
    .anticon-minus-circle {
      display: none;
    }
  
    :hover {
      border: 2px solid #22AB5B!important;
    }
  }

  @media (max-width: 1024px) {
    justify-content: space-between;
    height: 4rem;
    margin-top: 1rem;
    padding-right: 2rem;

    > svg {
      height: 3rem;
    }

    &.selected {
      background: #128756;
      opacity: 0.6;      
      // font-size: 1rem;
      // font-weight: 600;
      color: #fff;
    }
  }
  
  > svg {
    margin-right: 0.5rem;
  }
  
  &.selected {
    border: 2px solid #128756;
  }
`;

export const QuestionButtonSmall = styled(QuestionButton)`
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  width: 14rem;
  height: 4rem;
  border: 2px solid white;
  
  &.disabled {
    color: #92ACA0;
    svg path { stroke: #92ACA0; }
    svg:nth-of-type(2) { margin-left: 0.6rem; }
    transition: none;
    cursor: default;
    :hover {
      border: 2px solid white !important;
    }
  }
  
  &.selected {
    background: #00CC33;
    // font-weight: 600;
    border: none;
  }
`;

export const QuestionButtonMobile = styled(QuestionButtonSmall)`
  border: 1px solid rgba(69, 101, 94, 0.2);
  width: calc(100% / 2 - 1rem);
  margin: 0;
  padding: 0;
  padding-left: 0.5rem;
  height: 2.5rem;
  display: flex;
  // justify-content: center;
  // text-align: center;
  
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #45655E;
  
  &.selected {
    border: 2px solid #128756;
    //background: #EBF9FF;
  }
`;

export const QuestionButtonMobileTall = styled(QuestionButtonMobile)`
  height: 3rem;
`;

export const QuestionButtonMobileSpaces = styled(QuestionButtonMobile)`
  height: 4rem;
  justify-content: left;
  text-align: left;
  svg {
    width: 30%;
    margin-left: 1rem;
  }
`;

const QuestionButtonProperty = styled(QuestionButton)`
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  font-weight: 400;
  padding: 0 1.25rem;

  @media (min-width: 1024px) {
    height: 4rem;
    width: 15rem;
  }
  @media (max-width: 1024px) {
    height: 3rem;
    margin: 0!important;
  }
`;

const QuestionButtonPropertySmall = styled(QuestionButtonProperty)`
  @media (min-width: 1024px) {
    width: 9.75rem;
  }
  font-size: 0.875rem;
`;

const QuestionButtonTall = styled(QuestionButton)`
  flex-direction: column;
  height: 16rem;
  text-align: center;
  color: #21272B;
  position: relative;
  
  @media (min-width: 1024px) {
    padding-top: 0.5rem;
    width: ${(props: { width?: string }) =>
      props.width === "narrow" ? "12.5rem" : (props.width === "regular" ? "14rem" : "19rem")};
  }
  
  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    height: 4rem;
    margin-top: 1rem;
    padding-right: 0!important;
    
    &.selected {
      background: #128756;
      opacity: 0.6;      
      font-size: 1rem;
      font-weight: 600;
      color: #fff;
    }
  }
  
  > svg {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
`;

const QuestionHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem; //2.25rem;
  
  @media (min-width: 1024px) {
    .anticon-plus-circle {
      display: none;
    }
    .anticon-minus-circle {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    > svg {
      height: 2rem;
    }
  }
`

const QuestionDescription = styled.div`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #6B7185;
  margin: 0.5rem 0;
  padding: 0 0.5rem; //2.25rem;

  @media (max-width: 1024px) {
    display: none;
  }
`

const ThankYouDescription = styled.div`
  max-width: 28rem;
  text-align: center;
  color: #6B7185;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1rem;

  @media (max-width: 1024px) {
    padding: 0 1.5rem;
    
    a {
      font-weight: 700;
      color: #128756;
    }
  }
`

const BlueButton = styled(Button)`
  min-width: 10.5rem;
  height: 3.125rem;
  border-radius: 6px;
  background: #128756;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  border: 1px solid #128756;
  transition: .5s all ease;
  
  &:hover { 
    border: 1px solid #22AB5B;
    color: #22AB5B;
  }
`

const PreSelectedIcon = styled.div`
  position: absolute;
  width: 1.5rem;
  height: 1.55rem;
  top: 0;
  right: 0;
  border-radius: 0 10px 0 10px;
  border-color: #fff;
  border-width: 2px;
  border-style: solid;`

export const ContentsPageHeader = styled.div`
  background: white;
  width: 100%;
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  text-align: center;
  margin-bottom: 2rem;
  
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666F7A;
`

export const OnboardingSubtitle = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.625rem;
  color: #21272B;
  margin: 1.5rem 0;
`;

const UpkeepList = styled.div`
  margin: 1rem 0 2rem 0;
  width: 100%;
  background: #DAE0E6;
  padding: 0.25rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`

const UpkeepListTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .list-title {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #21272B;
  }
  
  .upkeep-link {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-decoration-line: underline;
    color: #2979FF;
  }
`

const UpkeepAfterword = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666F7A;
`

const AutoCompleteStyled = styled(AutoComplete)`
  @media (min-width: 1024px) {
    width: 31.125rem;
    .ant-select-selector {
      height: 3rem!important;
    }
  }
  
  @media (max-width: 1024px) {
    .ant-select-selector {
      height: 2.5rem!important;
    }
  }
  
  .ant-select-selector {
    border: 1px solid #dae0e6!important;
    border-radius: 6px!important;
    font-size: 1rem!important;
  }
    
  input { 
    font-size: 1rem!important;
    height: 3rem!important;
   }
`

const InputNumberStyled = styled(InputNumber)`
  @media (min-width: 1024px) {
    height: 3rem!important;
    
    input {
      height: 3rem!important;
    }
  }
  
  @media (max-width: 1024px) {
    height: 2.5rem!important;

    input {
      height: 2.5rem!important;
    }
  }

  width: 65%;
  padding-left: 0;
  padding-right: 0; 
  border-radius: 6px 0 0 6px!important;
  font-size: 1rem!important;
  
  input {
    font-size: 1rem!important;
    padding-top: 0!important;
  }
`

const SelectStyled = styled(Select)`
  @media (min-width: 1024px) {
    .ant-select-selector {
      height: 3rem!important;
    }

    .ant-select-selection-item {
      height: 3rem!important;
      font-size: 1rem!important;
    }
  }

  @media (max-width: 1024px) {
    .ant-select-selector {
      height: 2.5rem!important;
    }
  }

  .ant-select-selector {
    border-radius: 0 6px 6px 0!important;
    padding-top: 1rem;
  }
  
  .ant-select-selection-item {
    display: flex;
    align-items: center; 
   }
`

const SelectStyledCountry = styled(SelectStyled)`
  width:100%;
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  background: white;
  display: flex;
  border-radius: 6px;
  
  .ant-select-selector {
    display: flex;
    align-items: center;
  }  
`

const QuestionSelect = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #22AB5B;
  color: white;
  height: 2.375rem!important;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  align-self: flex-end;
  
  &.hidden { display: none; }
`

const PropertyDetailsOnboardingWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    width: 500px;
    row-gap: 1.5rem;
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 1.5rem;
  }
`;

const AddEventWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 1.5rem 0;
  
  .text-wrapper {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #666F7A;
  }
  
  .button-wrapper {
    padding-left: 1.5rem;
    border-left: 1px solid #DAE0E6;
    margin-left: 1.5rem;
  }
`

const UpkeepWrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1100px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 3.25rem;
  
  @media (max-width: 1024px) {
    padding: 1rem;
  }
`

const ErrorMessage = styled.span`
  color: #ff4d4f;
  padding-top: 0.25rem;
`

export default QuestionsListComp;
