import {Guid} from "guid-typescript";
import {Modal} from "antd";
import _ from "lodash";
import {TTag} from "../../api-wrapper/tags/addNewTag";

export const emailRegex = /\S+@\S+\.\S+/;
export const safetyRegex = /[^a-zA-Z0-9 (),!?:;\\r\\n\\t]/;
export const tagsRegex = /[^a-zA-Z0-9 ]/g;
export const searchRegex = /<em>(.*?)<\/em>/g;

export const parseToLowerCase = (el: string) => el.toLowerCase();

export enum EParentType {
  WORKS = "Works",
  INVENTORIES = "Inventories",
  CONTACTS = "Contacts",
  EXPENSES = "Expenses",
  NOTES = "Notes",
  TENANCIES = "Tenancies",
  ALERTS = "Alerts",
}

export const emptyGuid = Guid.createEmpty()

export function passwordTest(password: string) {
  const result = {
    totalScore: 0,
    appropriateLength: false,
    capitalLetters: false,
    numbers: false,
    noSpaces: true,
    final: false
  }

  let score = 0;
  if (!password) return result

  if (password.includes(" "))
    result.noSpaces = false

  if (password.length >= 8 && password.length <= 20)
    result.appropriateLength = true

  result.capitalLetters = /[A-Z]/.test(password)
  result.numbers = /\d/.test(password)

  let letters = {};
  for (let i = 0; i < password.length; i++) {
    // @ts-ignore
    letters[password[i]] = (letters[password[i]] || 0) + 1;
    // @ts-ignore
    score += 5.0 / letters[password[i]];
  }

  let letiations = {
    digits: /\d/.test(password),
    lower: /[a-z]/.test(password),
    upper: /[A-Z]/.test(password),
    nonWords: /\W/.test(password),
  }

  let letiationCount = 0;
  for (let check in letiations) {
    // @ts-ignore
    letiationCount += (letiations[check] === true) ? 1 : 0;
  }
  score += (letiationCount - 1) * 10;

  result.totalScore = score >= 100 ? 100 : Math.round(score)
  result.final = result.appropriateLength && result.capitalLetters && result.numbers && result.noSpaces
  return result
}

const getRecipientName = (recipient: any) => {
  if (recipient.label === recipient.value) return ""
  else return recipient.label
}

export const transformRecipients = (recipients: any) => {
  return recipients.map((recipient: any) => {
    return {name: getRecipientName(recipient), email: recipient.value}
  })
}

export const getRecipients = (recipients: any) => {
  if (recipients.length) return recipients
  else return [{label: "", value: ""}]
}

export const hasAllSelectedTags = (selectedTags: any, itemTags: any) => {
  let selectedTagIds: any = []
  let itemTagIds: any = []
  selectedTags.map((tag: any) => selectedTagIds.push(tag.tagId))
  itemTags.map((tag: any) => itemTagIds.push(tag.tagId))
  return selectedTagIds.every((tag: any) => itemTagIds.includes(tag))
};

export const hasSomeSelectedTags = (selectedTags: any, itemTags: any) => {
  let selectedTagIds: any = []
  let itemTagIds: any = []
  selectedTags.map((tag: any) => selectedTagIds.push(tag.tagId))
  itemTags.map((tag: any) => itemTagIds.push(tag.tagId))
  return selectedTagIds.some((tag: any) => itemTagIds.includes(tag))
};

export const isValidProperty = (propertyId: Guid) => {
  return JSON.stringify(propertyId) !== JSON.stringify(emptyGuid)
}

export const isUKProperty = (country: string, zipCode: string) => !!(country === "United Kingdom" && zipCode)

const showEmailError = (errorMessage: string) => {
  return Modal.error({
    title: "An error occurred while sending your email",
    content: errorMessage,
    className: "form-confirm-close-modal"
  });
}

export const processForwardingError = (errorResponse: any) => {
  if (_.isArray(errorResponse.data)) {
    let errors: any = []
    // @ts-ignore
    errorResponse.data.map((error: any) => errors.push(error.message))
    showEmailError(errors.join())
  } else showEmailError(errorResponse.data.message)
}

export const aggregateistOfTags = (tags: Array<TTag>) => {
  let stringOfTags = "";
  tags.forEach((tag) => {
    stringOfTags += " " + tag.name;
  })
  return stringOfTags;
}

export const pushStepToGoogle = (eventName: string) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push({
    'event': eventName
  });
}

export const getCurrencyName = (currency: string) => {
  if (currency === "Dollar") return "USD"
  if (currency === "Euro") return "EUR"
  if (currency === "GBP") return "GBP"
  else return ""
}

export const getCurrencySign = (currency: string) => {
  if (currency === "Dollar") return "$"
  if (currency === "Euro") return '\u20AC'
  if (currency === "GBP") return "£"
  else return ""
}

export const months = [
  {name: "January", number: 1, daysInMonth: 31}, {name: "February", number: 2, daysInMonth: 28}, {name: "March", number: 3, daysInMonth: 31},
  {name: "April", number: 4, daysInMonth: 30}, {name: "May", number: 5, daysInMonth: 31}, {name: "June", number: 6, daysInMonth: 30},
  {name: "July", number: 7, daysInMonth: 31}, {name: "August", number: 8, daysInMonth: 31}, {name: "September", number: 9, daysInMonth: 30},
  {name: "October", number: 10, daysInMonth: 31}, {name: "November", number: 11, daysInMonth: 30}, {name: "December", number: 12, daysInMonth: 31}
]