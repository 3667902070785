import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";

export type TGetStorageUsageResponse = {
  maxFilesSize: number;
  currentFilesAmount: number;
  currentFilesSize: number;
  maxFilesAmount: number;
};

export const getStorageUsage = () => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/storageUsage`
  );

  return apiGet<TGetStorageUsageResponse | []>(url);
};
