import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Collapse, Dropdown, List, Menu, Modal, Popover, Skeleton} from "antd";
import styled from "styled-components";
import {useIntl} from "react-intl";
import _ from "lodash";
import messages from "./messages";
import ExpenseDrawer from "../components/expenses/ExpenseDrawer";
import {
  authSelector,
  getContactsSelector,
  getExpensesSelector, getExpenseTypesSelector,
  getInventoriesSelector,
  getPropertyIdSelector, getPropertyPreferencesSelector, getSubscriptionUsersSelector,
  getWorksSelector,
} from "../selectors";
import {getAllExpenses, getAllExpenseTypes} from "../actions/expenses";
import {getAllWorks} from "../actions/works";
import {getAllInventories} from "../actions/inventories";
import {getAllContacts} from "../actions/contacts";
import {PlusOutlined} from "@ant-design/icons";
import {ReactComponent as SearchIcon} from "../../images/Search.svg";
import {ReactComponent as TaxDeductible} from "../../images/TaxDeductible.svg";
import {ReactComponent as ExportIcon} from "../../images/Export.svg";
import {ReactComponent as ConvertToCostRefresh} from "../../images/ConvertToCostRefresh.svg";
import {ReactComponent as ConvertToCostWallet} from "../../images/ConvertToCostWallet.svg";
import {aggregateistOfTags, emptyGuid, getCurrencySign, hasSomeSelectedTags} from "./helpers";
import {removeExpense} from "../../api-wrapper/expenses/removeExpense";
import {TGetExpenseTypesResponse} from "../../api-wrapper/expenses/getExpenseTypes";
import {Guid} from "guid-typescript";
import WorkDrawer from "../components/works/WorkDrawer";
import InventoryDrawer from "../components/inventories/InventoryDrawer";
import {
  downloadExport,
  expensesInitial,
  initialContactData,
  numberWithCommas,
} from "../components/helper";
import {ReactComponent as MoreMenu} from "../../images/More.svg";
import {MenuStyled} from "../components/works/WorksListComp";
import ContactDrawer from "../components/contacts/ContactDrawer";
import {ReactComponent as FilterIcon} from "../../images/Filters.svg";
import {ReactComponent as DotIcon} from "../../images/DotForFilters.svg";

import {
  FilterGroupRow,
  Filters
} from "./styled";
import moment from "moment";
import {addExpense} from "../../api-wrapper/expenses/addExpense";
import {ReactComponent as SortIcon} from "../../images/Sort.svg";
import {useLocation} from "react-router-dom";
import {exportExpenses} from "../../api-wrapper/expenses/exportExpenses";
import TagContainer from "../components/tags/TagContainer";
import FilterTagsNew, {
  CancelButton,
  CategoryHeader, EditCategoryBody,
  EditCategoryContainer, EditCategoryHeader, EditTagsBody, SubcategoryHeader,
  TagStyled
} from "../components/tags/FilterTagsNew";
import ForwardExpenseDrawer from "../components/expenses/ForwardExpenseDrawer";
import {TFetchingStatus} from "../../helpers";
import UploadInvoice, {InvoiceOverlay} from "../components/attachments/UploadInvoice";
import BatchProcessingModal from "../components/attachments/BatchProcessingModal";
import {expenseTypes} from "../components/dashboardWidgets/CostsChart";
import PeriodFilter from "../components/filters/PeriodFilter";
import {getAllTagsWithUsageCountByType} from "../actions/tagsWithUsageCountByType";
import {getAllMaintenances} from "../actions/maintenances";
import {getFileUploadPopupState} from "../../localStorage";
import {
  AddRecordButton, EmptyRecordsDescription, EmptyRecordsHeader, EmptyRecordsList, HeaderControlsWrapper,
  PageHeader, PageSubtitle, PageTitle, PageWrapper, AddButtonStyled, PageInfoColumn,
  PageInfoWrapper, fileUploadPopup, fileUploadTooltip, Search, PaginationStyled, paginationButtons,
} from "./components";
import {getExpensesWithoutProperty} from "../../api-wrapper/expenses/getExpenses";
import {ReactComponent as HouseSmall} from "../../images/attachments/HouseSmall.svg";
import {AssignToPropertyButton} from "../components/attachments/FileItem";
import AssignPropertySmallDrawer from "../components/attachments/AssignPropertySmallDrawer";
import AssignMultiPropertyDrawer from "../components/expenses/AssignMultiPropertyDrawer";
import {assignExpenseProperty} from "../../api-wrapper/expenses/assignExpenseProperty";
import {ReactComponent as HouseSmallBlackIcon} from "../../images/attachments/HouseSmallBlack.svg";
import {UnassignedAttachmentsInfo} from "./Files";
import ViewWorkDrawer from "../components/works/ViewWorkDrawer";
import LinkDrawer from "../components/spaces/LinkDrawer";
import ViewSpaceDrawer from "../components/spaces/ViewSpaceDrawer";
import NoteDrawer from "../components/notes/NoteDrawer";
import {getAllSpaces} from "../actions/spaces";
import ViewFileDrawer from "../components/attachments/ViewFileDrawer";
import {ReactComponent as CollapseIconMinus} from "../../images/CollapseIconMinus.svg";
import {ReactComponent as CollapseIconPlus} from "../../images/CollapseIconPlus.svg";
import {getAllProperties} from "../actions/properties";
import {getAllSubscriptionUsers} from "../actions/subscriptionUsers";
import ViewContactDrawer from "../components/contacts/ViewContactDrawer";
const { Panel } = Collapse;

const Costs = () => {
  const {formatMessage: f} = useIntl();
  const dispatch = useDispatch();

  const auth = useSelector(authSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const expenses = useSelector(getExpensesSelector);
  const works = useSelector(getWorksSelector);
  const contacts = useSelector(getContactsSelector);
  const inventories = useSelector(getInventoriesSelector);
  const databaseExpenseTypes = useSelector(getExpenseTypesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);
  const subscriptionUsers = useSelector(getSubscriptionUsersSelector);

  const [expensesWithoutProperty, setExpensesWithoutProperty] = useState<any>([]);
  const [activeKeys, setActiveKeys] = useState<any>(["categories"]);

  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editingContactData, setEditingContactData] = useState<any>(null);

  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [editingExpenseData, setEditingExpenseData] = useState<any>(null);

  const [workDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editWorkData, setEditWorkData] = useState<any>(null);
  const [isViewWorkDrawerOpen, toggleViewWorkDrawer] = useState(false);
  const [workToViewId, setWorkToViewId] = useState<any>(null);
  const [isViewContactDrawerOpen, toggleViewContactDrawer] = useState(false);
  const [contactToViewId, setContactToViewId] = useState<any>(null);
  const [isViewFileDrawerOpen, toggleViewFileDrawer] = useState(false);
  const [attachmentToViewId, setAttachmentToViewId] = useState<any>(null);
  const [isNoteDrawerOpen, toggleNoteDrawer] = useState(false);
  const [editingNoteData, setEditingNoteData] = useState(null);
  const [isViewSpaceDrawerOpen, toggleViewSpaceDrawer] = useState(false);
  const [viewSpaceId, setViewSpaceId] = useState(null);
  const [isLinkDrawerOpen, toggleLinkDrawer] = useState(false);
  const [presetLinkType, setPresetLinkType] = useState<any>(null);

  const [inventoryDrawerOpen, toggleInventoryDrawer] = useState(false);
  const [editInventoryData, setEditInventoryData] = useState<any>(null);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [topLevelCategory, setTopLevelCategory] = useState<string | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [selectedPredefinedDate, setSelectedPredefinedDate] = useState("custom");
  const [filterTaxDeductible, setFilterTaxDeductible] = useState(false);
  const [selectedExpenseTypes, setSelectedExpenseTypes] = useState<Set<string>>(new Set());
  const [sortMenuPopoverVisible, setSortMenuPopoverVisible] = useState(false);
  const [sortMenuPopoverVisibleMobile, setSortMenuPopoverVisibleMobile] = useState(false);
  const [sortBy, setSortBy] = useState("dateCreated");

  const [expensesTypes, setExpensesTypes] = useState<TGetExpenseTypesResponse>([]);
  const [topLevelExpensesTypes, setTopLevelExpensesTypes] = useState<TGetExpenseTypesResponse>([]);
  const [typeForParentMap, setTypeForParentMap] = useState<Map<string, string>>(new Map());
  const [expensesTypesWithAmounts, setExpensesTypesWithAmounts] = useState<Array<{
    id: string, parentId: string, name: string, amount: number}>>([]);
  const [parentExpenseTypesWithAmounts, setParentExpenseTypesWithAmounts] = useState<Map<Guid, number>>(new Map());
  const [invoiceData, setInvoiceData] = useState(null)
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [editTagsMode, toggleEditTagsMode] = useState(false);
  const [isForwardDrawerOpen, toggleForwardDrawer] = useState(false);
  const [expenseToForward, setExpenseToForward] = useState(emptyGuid);
  const [showUnconfirmedOnly, toggleShowUnconfirmedOnly] = useState(false);
  const [isBatchProcessing, toggleBatchProcessing] = useState(false);

  const [isAssignPropertySmallDrawerOpen, toggleAssignPropertySmallDrawerOpen] = useState(false);
  const [isAssignMultiPropertyDrawerOpen, toggleAssignMultiPropertyDrawerOpen] = useState(false);
  const [expenseToAssignId, setExpenseToAssignId] = useState<any>(null);

  const showFileUploadPopup = getFileUploadPopupState();

  const startOfMonth = moment().clone().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = moment().clone().endOf("month").format("YYYY-MM-DD");

  const totalExpensesCost = expenses.content.reduce(
    (sum, { totalCost, isConfirmed }) => sum + (isConfirmed ? totalCost : 0), 0);
  const currentMonthExpenses = expenses.content.filter(
    (expense) => expense.paymentDate >= startOfMonth && expense.paymentDate <= endOfMonth && expense.isConfirmed);
  const currentMonthExpensesCost = currentMonthExpenses.reduce(
    (sum, { totalCost, isConfirmed }) => sum + (isConfirmed ? totalCost : 0), 0);
  const currentMonthExpensesCount = currentMonthExpenses.length;

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    dispatch(getAllWorks(propertyId));
    dispatch(getAllExpenses(propertyId));
    dispatch(getAllInventories(propertyId));
    dispatch(getAllContacts(propertyId));
    dispatch(getAllExpenseTypes(propertyId))
    dispatch(getAllMaintenances(propertyId));
    dispatch(getAllProperties());
    fetchExpensesWithoutProperty();
    setPage(1);
  }, [propertyId]);

  useEffect(() => {
    dispatch(getAllSubscriptionUsers(auth.tenantId));
  }, [auth]);

  const fetchExpensesWithoutProperty = () => {
    getExpensesWithoutProperty(propertyId).then(payload => {
      if (payload) {
        setExpensesWithoutProperty(payload);
      }
    });
  }

  const refreshAllExpenses = () => {
    dispatch(getAllExpenses(propertyId));
    fetchExpensesWithoutProperty();
  }

  const assignPropertySingle = (selectedPropertyId: Guid, recordId: Guid) => {
    assignExpenseProperty(selectedPropertyId, recordId).then(() => {
      refreshAllExpenses();
      toggleAssignPropertySmallDrawerOpen(false);
    });
  }

  useEffect(() => {
    let types: any = [];
    let topLevel: any = [];
    let typeForParentMapTmp = new Map<string, string>();
    for (const [key, value] of Object.entries(databaseExpenseTypes.content)) {
      types.push(value);
      if (_.isNil(value.parentId)) {
        topLevel.push(value);
      } else {
        typeForParentMapTmp.set("" + value.id, "" + value.parentId);
      }
    }
    setTopLevelExpensesTypes(topLevel);
    setTypeForParentMap(typeForParentMapTmp);
    setExpensesTypes(types);
  }, [propertyId, databaseExpenseTypes]);

  useEffect(() => {
    const expenseItems = selectedTags.length > 0 ? filteredExpenses() : expenses.content
    let amountsMap = new Map();

    expenseItems.forEach((expense => {
      if (amountsMap.has(expense.type)) {
        amountsMap.set(expense.type, amountsMap.get(expense.type)+1);
      } else {
        amountsMap.set(expense.type, 1);
      }
    }));

    let amounts = new Array();
    let parentAmounts = new Array();
    let parentTypesWithAmounts = new Map();

    expensesTypes.forEach((expensesType) => {
      let amount = 0;
      if (amountsMap.has(expensesType.id)) {
        amount = amountsMap.get(expensesType.id);
      }
      amounts.push({id: expensesType.id, parentId: expensesType.parentId, name: expensesType.name, amount: amount});
      if (expensesType.parentId) {
        amount > 0 && parentAmounts.push({id: expensesType.parentId, amount: amount})
      } else {
        parentTypesWithAmounts.set(expensesType.id, 0)
      }
    });

    parentAmounts.forEach(amount => parentTypesWithAmounts.set(amount.id, parentTypesWithAmounts.get(amount.id) + amount.amount));
    setParentExpenseTypesWithAmounts(parentTypesWithAmounts)

    amounts = amounts.sort((e1, e2) => e2.amount - e1.amount);
    setExpensesTypesWithAmounts(amounts);
  }, [expenses, expensesTypes, search, startDate, endDate, filterTaxDeductible, selectedTags]);

  const addNewExpense = () => {
    addExpense({
      propertyId, isConfirmed: true
    }).then((res) => {
      setEditingExpenseData(res);
      dispatch(getAllExpenses(propertyId));
      toggleExpenseDrawer(true);
    });
  };

  function confirmDelete(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        removeExpense(propertyId, item.expenseId).then(() => {
          setSearch("");
          refreshAllExpenses();
          dispatch(getAllTagsWithUsageCountByType(propertyId, "expense"))
        });
      }
    });
  }

  const moreMenuDropdown = (item: any) => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const {key} = event;

      if (key === "edit") {
        toggleExpenseDrawer(true);
        setEditingExpenseData({data: item});
      } else if (key === "delete") {
        confirmDelete(item);
      } else if (key === "forward") {
        toggleForwardDrawer(true)
        setExpenseToForward(item.expenseId)
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        {item.isConfirmed && <Menu.Item key="forward">Forward</Menu.Item>}
        {item.propertyId && <Menu.Item key="edit">Edit</Menu.Item>}
        <Menu.Item key="delete">Delete</Menu.Item>
      </MenuStyled>
    );
  };

  const sortMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      setSortMenuPopoverVisible(false);
      setSortMenuPopoverVisibleMobile(false);
      setSortBy("" + key);
    };

    return (
      <SortMenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="costHighToLow">From highest to lowest</Menu.Item>
        <Menu.Item key="costLowToHigh">From lowest to highest</Menu.Item>
        <Menu.Item key="dateCreatedAsc">By payment date (Latest to oldest)</Menu.Item>
        <Menu.Item key="dateCreatedDesc">By payment date (Oldest to latest)</Menu.Item>
      </SortMenuStyled>
    );
  };

  const totalTopLevel = (parentType: string) =>
    filteredExpenses().filter(expense =>
      parentType === typeForParentMap.get("" + expense.type)
    ).reduce(
      (sumOfExpenses: any, {totalCost}: any) => sumOfExpenses + totalCost,
      0
    );

  const parseToLowerCase = (el: string) => el.toLowerCase();

  const getDateFromUnconfirmed = (name: string) => {
    if (name.includes("Received")) {
      return parseInt(moment(name.replace("Received ", "").split("[")[0].trim()).format("YYYYMMDDHHmmss"))
    } else if (name.includes("Uploaded")) {
      return parseInt(moment(name.replace("Uploaded ", "").split("[")[0].trim()).format("YYYYMMDDHHmmss"))
    } else return 999999999
  }

  const filteredExpenses = () =>
    expenses.content
      .concat(expensesWithoutProperty)
      .filter(expense => !_.isEmpty(expense.name))
      .filter(expense => _.isNil(topLevelCategory) ? true : topLevelCategory === typeForParentMap.get("" + expense.type))
      .filter(expense => showUnconfirmedOnly ? !expense.isConfirmed : true)
      .filter((contentEl) => {
        return !_.isEmpty(search)
          ? (!_.isNil(contentEl.payee) &&
          _.includes(
            parseToLowerCase("" + contentEl.payee),
            parseToLowerCase(search)
          )) ||
          (!_.isNil(contentEl.name) &&
            _.includes(
              parseToLowerCase(contentEl.name),
              parseToLowerCase(search)
            )) ||
          (!_.isNil(contentEl.type) &&
            _.includes(
              parseToLowerCase("" + contentEl.type),
              parseToLowerCase(search)
            )) ||
          (!_.isNil(contentEl.tags) &&
            _.includes(
              parseToLowerCase(aggregateistOfTags(contentEl.tags)),
              parseToLowerCase(search)
            ))
          : true;
      })
      .filter(expense => {
        return !_.isNil(startDate)
          ? (moment(expense.paymentDate).add(1, 'day').valueOf() > startDate)
          : true;
      })
      .filter(expense => {
        return !_.isNil(endDate)
          ? (moment(expense.paymentDate).valueOf() < endDate)
          : true;
      })
      .filter(expense => {
        return filterTaxDeductible
          ? expense.isTaxDeductible
          : true;
      })
      .filter(expense => {
        return selectedExpenseTypes.size > 0
          ? selectedExpenseTypes.has(""+expense.type)
          : true;
      })
      .filter(expense => {
        return selectedTags.length > 0
          ? hasSomeSelectedTags(selectedTags, expense.tags)
          : true;
      })
      .sort((c1, c2) => {
        if (sortBy === "costHighToLow") {
          return c2.totalCost - c1.totalCost
        } else if (sortBy === "costLowToHigh") {
          return c1.totalCost - c2.totalCost
        } else if (sortBy === "dateCreatedDesc") {
          let date1 = c1.isConfirmed ? moment(c1.paymentDate).valueOf() : getDateFromUnconfirmed(c1.name)
          let date2 = c2.isConfirmed ? moment(c2.paymentDate).valueOf() : getDateFromUnconfirmed(c2.name)
          return date1 - date2
        } else {
          let date1 = c1.isConfirmed ? moment(c1.paymentDate).valueOf() : getDateFromUnconfirmed(c1.name)
          let date2 = c2.isConfirmed ? moment(c2.paymentDate).valueOf() : getDateFromUnconfirmed(c2.name)
          return date2 - date1
        }
      })
      .sort((c1, c2) => {
        return (c2.isConfirmed ? 0 : 1) - (c1.isConfirmed ? 0 : 1)
      })
      .sort((c1, c2) => {
        return (c2.propertyId ? 0 : 1) - (c1.propertyId ? 0 : 1)
      })

  const getExpenses = () =>
    filteredExpenses().filter(
      (currentValue, index) =>
        index >= (page - 1) * pageSize && index < page * pageSize
    );

  useEffect(() => {
    if (expenses.fetchingStatus === TFetchingStatus.Success && getExpenses().length === 0 && page !== 1) {
      setPage(page - 1)
    }
  }, [expenses.fetchingStatus, getExpenses()])

  const workFilter = (parentId: Guid) =>
    works.content.filter((work) => work.workId === parentId);

  const inventoryFilter = (parentId: Guid) =>
    inventories.content.filter((inventory) => inventory.id === parentId);

  const parentWork = (item: any) => item.expenseRelations.filter((relation: any) => relation.parentType === "work")[0]
  const parentInventory = (item: any) => item.expenseRelations.filter((relation: any) => relation.parentType === "inventory")[0]

  const displayParentWork = (item: any) => {
    if (parentWork(item)) {
      let workToDisplay = workFilter(parentWork(item).parentId)
      if (workToDisplay[0]?.name) return (<RelatedLabel>
        <span className={"relatedLabel"}>Projects</span>
        <span className={"relatedArrow"}>{" > "}</span>
        <RelatedLink className={"relatedLink"}
          onClick={(e) => {
            e.stopPropagation();
            openRelatedWork(parentWork(item).parentId);
          }}
        >{workToDisplay[0].name}
        </RelatedLink>
      </RelatedLabel>)
    }
  }

  const displayParentInventory = (item: any) => {
    if (parentInventory(item)) {
      let inventoryToDisplay = inventoryFilter(parentInventory(item).parentId)
      if (inventoryToDisplay[0]?.name) return (<RelatedLabel>
          <span className={"relatedLabel"}>Inventories</span>
          <span className={"relatedArrow"}>{" > "}</span>
          <RelatedLink className={"relatedLink"} onClick={(e) => {
              e.stopPropagation();
              openRelatedInventory(parentInventory(item).parentId);
          }}>
            {inventoryToDisplay[0].name}
          </RelatedLink>
      </RelatedLabel>)
    }
  }

  const displayPayee = (item: any) => {
    if (item.payee)
      return <RelatedLabel>
        <span className={"relatedLabel"}>Payee</span>
        <span className={"relatedArrow"}>{" > "}</span>
        <RelatedLink className={"relatedLink"} onClick={(e) => {
          e.stopPropagation();
          openRelatedContact(item.payee);
        }}>
          {showContactName(item.payee)}
        </RelatedLink>
    </RelatedLabel>
  }

  const displayPaidBy = (item: any) => {
    if (item.userPaidBy) {
      return <RelatedLabel>
        <span className={"relatedLabel"}>Paid by</span>
        <span className={"relatedArrow"}>{" > "}</span>
        <span className={"relatedLink"}>
          {showUserName(item.userPaidBy)}
        </span>
      </RelatedLabel>
    }
    if (item.contactPaidBy) {
      return <RelatedLabel>
        <span className={"relatedLabel"}>Paid by</span>
        <span className={"relatedArrow"}>{" > "}</span>
        <RelatedLink className={"relatedLink"}  onClick={(e) => {
          e.stopPropagation();
          openRelatedContact(item.contactPaidBy);
        }}>
          {showContactName(item.contactPaidBy)}
        </RelatedLink>
      </RelatedLabel>
    }
    else return null;
  }

  const separator = () => (
    <div style={{width: "1px", height: "100%", backgroundColor: "#DAE0E6", margin: "0 1rem"}} />
  )

  const openRelatedInventory = (inventoryId: Guid) => {
    setEditInventoryData({
      // @ts-ignore
      data: inventories.content.filter(
        (inventory) => inventory.id === inventoryId
      )[0],
    });
    toggleInventoryDrawer(true);
  };

  const openRelatedWork = (workId: Guid) => {
    toggleViewWorkDrawer(true);
    setWorkToViewId(workId);
  };

  const openRelatedContact = (contactId: Guid) => {
    toggleViewContactDrawer(true);
    setContactToViewId(contactId);
  };

  const showContactName = (contactId: Guid) => {
    const filteredContacts = contacts.content.filter(
      (contact) => contact.contactId === contactId
    );
    return _.isEmpty(filteredContacts) ? "" : filteredContacts[0].name;
  };

  const showUserName = (userId: number) => {
    const filteredUsers = subscriptionUsers.content.filter(
      (user) => user.userId === userId
    );
    return _.isEmpty(filteredUsers) ? "" : filteredUsers[0].name;
  };

  const getExpenseTypeName = (typeId: Guid | undefined) =>
    _.isNull(typeId) ? "" : expensesTypes.filter((exp: any) => exp.id === typeId)[0].name;

  const categoryColor = (categoryName: string) => {
    switch (categoryName) {
      case expenseTypes.maintenance: return "#86cea2";
      case expenseTypes.improvement: return "#5599e7";
      case expenseTypes.lifestyle: return "#ed90cd";
      case expenseTypes.landlord: return "#fece57";
    }
  }

  const categoryId = (categoryName: string) => {
    if (topLevelExpensesTypes.length) {
      return topLevelExpensesTypes.filter((type: any) => type.name === categoryName)[0].id
    } else return null
  }

  const getExpenseParentTypeName = (typeId: Guid) =>
    _.isNull(typeId) ? "" : getExpenseTypeName(expensesTypes.filter((exp: any) => exp.id === typeId)[0].parentId);

  const selectExpenseType = (typeId: string) => {
    const newSet = new Set(selectedExpenseTypes);
    if (selectedExpenseTypes.has(typeId)) {
      newSet.delete(typeId);
    } else {
      newSet.add(typeId);
    }
    setSelectedExpenseTypes(newSet);
  }

  const getClassesForExpenseTypeTag = (expenseType: any) => {
    if (selectedExpenseTypes.has(expenseType.id)) return "tag-blue"
    if (expenseType.amount === 0) return "tag-gray"
  }

  const displayFilters = () => (
    <>
      <Filters>
        <FilterGroupRow>
          <CategoryHeader>
            Filters <CancelButton onClick={() => {
            setEndDate(null);
            setStartDate(null);
            setFilterTaxDeductible(false);
            setSelectedPredefinedDate("custom");
            setSelectedExpenseTypes(new Set());
            setSelectedTags([]);
          }}>Clear filters</CancelButton>
          </CategoryHeader>
        </FilterGroupRow>
        <PeriodFilter
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          selectedPredefinedDate={selectedPredefinedDate}
          setSelectedPredefinedDate={setSelectedPredefinedDate} />
        <FilterGroupRow style={{flexDirection: "column", rowGap: "1rem", border: 0, marginBottom: 0}}>
          <Collapse bordered={false} ghost onChange={(key) => setActiveKeys(key)} defaultActiveKey={activeKeys}>
            <Panel showArrow={false} header={<SubcategoryHeader>
              Categories
              <div className={"button-wrapper"}>
                {activeKeys.includes("categories") ? <CollapseIconMinus /> : <CollapseIconPlus />}
              </div>
            </SubcategoryHeader>} key="categories">
              <>
                <EditTagsBody>
                  <EditCategoryContainer>
                    <EditCategoryHeader>{topLevelExpensesTypes[0].name}</EditCategoryHeader>
                    <EditCategoryBody>
                      {expensesTypesWithAmounts.filter((type: any) =>
                        type.parentId === categoryId(topLevelExpensesTypes[0].name)).map((expenseType) => <TagStyled
                        key={"filterType"+expenseType.id}
                        className={getClassesForExpenseTypeTag(expenseType)}
                        onClick={() => selectExpenseType(expenseType.id)}
                      >{expenseType.name} ({expenseType.amount})</TagStyled>)}
                    </EditCategoryBody>
                    <EditCategoryHeader>{topLevelExpensesTypes[1].name}</EditCategoryHeader>
                    <EditCategoryBody>
                      {expensesTypesWithAmounts.filter((type: any) =>
                        type.parentId === categoryId(topLevelExpensesTypes[1].name)).map((expenseType) => <TagStyled
                        key={"filterType"+expenseType.id}
                        className={getClassesForExpenseTypeTag(expenseType)}
                        onClick={() => selectExpenseType(expenseType.id)}
                      >{expenseType.name} ({expenseType.amount})</TagStyled>)}
                    </EditCategoryBody>
                    <EditCategoryHeader>{topLevelExpensesTypes[2].name}</EditCategoryHeader>
                    <EditCategoryBody>
                      {expensesTypesWithAmounts.filter((type: any) =>
                        type.parentId === categoryId(topLevelExpensesTypes[2].name)).map((expenseType) => <TagStyled
                        key={"filterType"+expenseType.id}
                        className={getClassesForExpenseTypeTag(expenseType)}
                        onClick={() => selectExpenseType(expenseType.id)}
                      >{expenseType.name} ({expenseType.amount})</TagStyled>)}
                    </EditCategoryBody>
                    <EditCategoryHeader>{topLevelExpensesTypes[3].name}</EditCategoryHeader>
                    <EditCategoryBody>
                      {expensesTypesWithAmounts.filter((type: any) =>
                        type.parentId === categoryId(topLevelExpensesTypes[3].name)).map((expenseType) => <TagStyled
                        key={"filterType"+expenseType.id}
                        className={getClassesForExpenseTypeTag(expenseType)}
                        onClick={() => selectExpenseType(expenseType.id)}
                      >{expenseType.name} ({expenseType.amount})</TagStyled>)}
                    </EditCategoryBody>
                  </EditCategoryContainer>
                </EditTagsBody>
                <TaxDeductibleLabel style={{marginTop: "1rem"}}>
                  <input
                    type={"checkbox"}
                    onClick={() => setFilterTaxDeductible(!filterTaxDeductible)}
                    id={"filterTaxDeductible"}
                    checked={filterTaxDeductible}
                  />
                  <label htmlFor={"filterTaxDeductible"}>Tax deductible expenses</label>
                </TaxDeductibleLabel>
              </>
            </Panel>
          </Collapse>
        </FilterGroupRow>
        <FilterTagsNew
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          hideClearTagsButton={true}
          parentType={"expense"}
          selectedCategories={selectedExpenseTypes}
          entries={filteredExpenses()}
        />
      </Filters>
    </>
  );

  const useQuery = (queryType: string) => {
    let query = new URLSearchParams(useLocation().search);
    switch (queryType) {
      case 'type':
        return query.get("type");
      case 'category':
        return query.get("category");
      case 'period':
        return query.get("period");
      case 'invoice':
        return query.get("uploadInvoice");
      default:
        return null
    }
  };
  const selectedType = useQuery('type')
  const selectedCategory = useQuery('category')
  const selectedPeriod = useQuery('period')
  const invoiceUpload = useQuery('invoice')

  const invoiceUploadRef = useRef(null)

  useEffect(() => {
    if (invoiceUpload === "true") {
      // @ts-ignore
      invoiceUploadRef.current && invoiceUploadRef.current.scrollIntoView();
    }
  }, [invoiceUpload]);

  useEffect(() => {
    selectedType && setTopLevelCategory(selectedType)
    selectedCategory && selectExpenseType(selectedCategory)
    if (selectedPeriod) {
      if (selectedPeriod === "taxYear") {
        setSelectedPredefinedDate("year");
        let today = moment()
        let taxYearStartDay = moment().month(3).date(6)
        if (today.isAfter(taxYearStartDay)) {
          setStartDate(moment().month(3).date(6));
          setEndDate(moment().add(1, 'years').month(3).date(5));
        } else {
          setStartDate(moment().subtract(1, 'years').month(3).date(5));
          setEndDate(moment().month(3).date(6));
        }
      }
      if (selectedPeriod === "month") {
        setSelectedPredefinedDate("month")
        setStartDate(moment().startOf( 'month'));
        setEndDate(moment().endOf('month'));
      }
    }
  }, [selectedType, selectedCategory, selectedPeriod])

  const convertToCostButton = () => (
    <div style={{
      background: '#FEF9EA',
      border: '1px solid #EFC45D',
      borderRadius: '4px',
      display: "flex",
      columnGap: "0.375rem",
      fontWeight: 500,
      fontSize: "0.75rem",
      lineHeight: "1.125rem",
      color: "#666F7A",
      padding: "0.125rem 0.375rem"
    }}>
      <ConvertToCostWallet />
      Cost
      <ConvertToCostRefresh />
    </div>
  )

  const haveFiltersBeenUsed = () => startDate || endDate || filterTaxDeductible || selectedExpenseTypes.size > 0

  const searchPanel = () => (
    <>
      <SearchWrapperDesktop>
        <Search
          size={"large"}
          value={search}
          onChange={(val) => {
            setSearch(val.target.value);
          }}
          placeholder={"Search expenses..."}
          suffix={<SearchIcon/>}
        />
        <ButtonFilters
          size={"large"}
          icon={<div style={{position: "relative"}}>
            {haveFiltersBeenUsed() && <DotIcon
                style={{position: "absolute", top: "-0.25rem", left: "-0.3rem"}}/>}
            <FilterIcon style={{marginRight: "0.3rem"}} />
            </div>}
          onClick={() => setShowFilters(!showFilters)}
        >
          {showFilters ? "Hide" : "Show"} filters
        </ButtonFilters>
        <Popover
          content={sortMenuDropdown()}
          className={"sortMenuWorks"}
          visible={sortMenuPopoverVisible}
          placement="bottom"
          onVisibleChange={setSortMenuPopoverVisible}
          trigger={["click"]}
        >
          <ButtonSort
            icon={<SortIcon />}
            onClick={() => {
              setSortMenuPopoverVisible(!sortMenuPopoverVisible);
            }}
          />
        </Popover>
      </SearchWrapperDesktop>
      <SearchWrapperMobile>
        <div style={{display: 'flex'}}>
          <Search
            size={"large"}
            value={search}
            onChange={(val) => {
              setSearch(val.target.value);
            }}
            placeholder={"Search expenses..."}
            suffix={<SearchIcon/>}
          />
        </div>
        <div style={{display: 'flex', justifyContent: "flex-end", marginTop: '1rem'}}>
          <ButtonFilters
            size={"large"}
            icon={<div style={{position: "relative"}}>
              {haveFiltersBeenUsed() && <DotIcon
                  style={{position: "absolute", top: "-0.25rem", left: "-0.3rem"}}/>}
              <FilterIcon style={{marginRight: "0.3rem"}} />
            </div>}
            onClick={() => setShowFilters(!showFilters)}
          >
            {haveFiltersBeenUsed() && (
              <DotIcon
                style={{ position: "absolute", top: "0.2rem", left: "1.8rem" }}
              />
            )}
            {showFilters ? "Hide" : "Show"} Filters
          </ButtonFilters>
          <Popover
            content={sortMenuDropdown()}
            className={"sortMenuWorks"}
            visible={sortMenuPopoverVisibleMobile}
            placement="bottom"
            onVisibleChange={setSortMenuPopoverVisibleMobile}
            trigger={["click"]}
          >
            <ButtonSort
              icon={<SortIcon />}
              onClick={() => {
                setSortMenuPopoverVisibleMobile(!sortMenuPopoverVisibleMobile);
              }}
            />
          </Popover>
        </div>
      </SearchWrapperMobile>
    </>
  )

  const CostItemMobile = (item: any) => {
    return (<ListItemStyled
      onClick={() => {
        if (!editTagsMode) {
          toggleExpenseDrawer(true);
          setEditingExpenseData({data: item});
        }
      }}
    >
      <Skeleton loading={false} title={false} active>
        <div style={{display: "flex", flexDirection: "row", width: "100%", }}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            color: "#6B7185",
            paddingTop: "0.5rem",
          }}>
            <div style={{display: "flex", flexDirection: "column"}}>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <div style={{
                    color: item.isConfirmed ? "#21272B" : "#C4C4C4",
                    fontSize: "1.375rem",
                    fontWeight: 600,
                    wordBreak: "break-word",
                    width: "100%"
                  }}>
                    {item.name}
                  </div>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                {item.isConfirmed && <div style={{marginTop: "0.5rem", fontSize: "0.75rem", fontWeight: 600}}>
                            <span style={{
                              color: categoryColor(getExpenseParentTypeName(item.type)),
                            }}>
                              {getExpenseParentTypeName(item.type)}
                            </span>
                  {" / "}
                  {getExpenseTypeName(item.type)}
                </div>}
                {!item.isConfirmed && <div style={{margin: "0.5rem 0", fontSize: "0.75rem", fontWeight: 600}}>
                    <span style={{color: "#EF624E"}}>Unconfirmed Expense</span>
                  {" / "}
                    <span style={{color: "#45655E"}}>Invoice received via email or uploaded in bulk</span>
                </div>}
              </div>
              {item.propertyId && !item.isConfirmed && <div style={{display: "flex", alignItems: "center", columnGap: "1rem",
                fontSize: "0.75rem", fontWeight: 600}}>
                  <span style={{color: "#2A80E1"}}>Edit to convert to expense</span>
                {convertToCostButton()}
              </div>}
              <div style={{display: "flex", flexDirection: "column", marginTop: "0.5rem"}}>
                {displayParentWork(item)}
                {displayParentInventory(item)}
                {item.propertyId && displayPayee(item)}
                {displayPaidBy(item)}
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: '0.5rem'
                }}>
                  <div style={{color: item.isConfirmed ? "#21272B" : "#C4C4C4", fontWeight: 600, fontSize: "1.375rem"}}>
                    {item.totalCost >= 0 ? getCurrencySign(propertyPreferences.preferredCurrency) + numberWithCommas(item.totalCost) : <span
                      style={{color: "red"}}>{"-" + getCurrencySign(propertyPreferences.preferredCurrency) + numberWithCommas(Math.abs(item.totalCost))}</span>}
                  </div>
                  {item.isTaxDeductible && (
                    <div style={{display: "flex", alignItems: "center", fontWeight: 600, color: "#7CC9B7"}}>
                      <TaxDeductible/>
                      <span>Tax deductible</span>
                    </div>)}
                </div>
              </div>
              <div style={{color: item.isConfirmed ? "#6B7185" : "#C4C4C4", fontWeight: 400, fontSize: "0.875rem", margin: "0.5rem 0"}}>
                {moment(item.paymentDate).format(propertyPreferences.preferredDateFormat)}
              </div>
              {item.propertyId
                ? item.isConfirmed &&
                  <TagContainer
                    parentTags={item.tags}
                    parentId={item.expenseId}
                    parentType={"expense"}
                    selectedTags={selectedTags}
                    toggleGlobalEditMode={toggleEditTagsMode}
                    refreshParent={() => dispatch(getAllExpenses(propertyId))}
                  />
                : <AssignToPropertyButton
                    onClick={(e) => {e.stopPropagation(); setExpenseToAssignId(item.expenseId); toggleAssignPropertySmallDrawerOpen(true);}}
                  >
                    <HouseSmall style={{marginRight:"0.5rem"}} />
                    <span>Assign to a property</span>
                  </AssignToPropertyButton>
              }
            </div>
          </div>
          <div>
            <Popover
              content={moreMenuDropdown(item)}
              placement="bottomRight"
              trigger={["click"]}
              //@ts-ignore
              zIndex={2}
            >
              <MoreMenu
                style={{marginTop: "1rem", marginLeft: "1rem"}}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Popover>
          </div>
        </div>
      </Skeleton>
    </ListItemStyled>)
  }

  const CostItemDesktop = (item: any) => {
    return (<ListItemStyled
      onClick={() => {
        if (!editTagsMode) { //  && item.propertyId
          toggleExpenseDrawer(true);
          setEditingExpenseData({data: item});
        }
      }}
    >
      <Skeleton loading={false} title={false} active>
        <div style={{display: "flex", flexDirection: "row", width: "100%", }}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            color: "#6B7185",
            paddingTop: "0.5rem",
          }}>
            <div style={{display: "flex", flexDirection: "column"}}>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <div style={{
                    color: item.isConfirmed ? "#21272B" : "#C4C4C4",
                    fontSize: "1rem",
                    fontWeight: 600,
                  }}>
                    {item.name}
                  </div>
                </div>
                <div style={{display: "flex", flexDirection: "row"}}>
                  {item.isTaxDeductible && (
                    <div style={{display: "flex", alignItems: "center", margin: "0 1rem", fontWeight: 600, color: "#7CC9B7"}}>
                      <TaxDeductible style={{marginRight: "0.5rem"}}/>
                      <span>Tax deductible</span>
                    </div>)}
                  <div style={{color: item.isConfirmed ? "#21272B" : "#C4C4C4", fontWeight: 600, fontSize: "1.125rem"}}>
                    {item.totalCost >= 0 ? getCurrencySign(propertyPreferences.preferredCurrency) + numberWithCommas(item.totalCost) : <span
                      style={{color: "red"}}>{"-" + getCurrencySign(propertyPreferences.preferredCurrency) + numberWithCommas(Math.abs(item.totalCost))}</span>}
                  </div>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                {item.isConfirmed && <div style={{margin: "0.5rem 0", fontSize: "0.75rem", fontWeight: 600}}>
                  <span style={{
                    color: categoryColor(getExpenseParentTypeName(item.type)),
                  }}>
                    {getExpenseParentTypeName(item.type)}
                  </span>
                  {" / "}
                  {getExpenseTypeName(item.type)}
                </div>}
                {!item.isConfirmed && <div style={{margin: "0.5rem 0", fontSize: "0.75rem", fontWeight: 600}}>
                  <span style={{color: "#EF624E"}}>Unconfirmed Expense</span>
                  {" / "}
                  <span style={{color: "#45655E"}}>Invoice received via email  or uploaded in bulk</span>
                </div>}
                <div style={{color: item.isConfirmed ? "#6B7185" : "#C4C4C4", fontWeight: 400, fontSize: "0.875rem", marginTop: "0.5rem"}}>
                  {moment(item.paymentDate).format(propertyPreferences.preferredDateFormat)}
                </div>
              </div>
              {item.propertyId && !item.isConfirmed && <div style={{display: "flex", alignItems: "center", columnGap: "4rem",
                marginBottom: "0.5rem", fontSize: "0.75rem", fontWeight: 600}}>
                <span style={{color: "#2A80E1"}}>Edit to convert to expense</span>
                {convertToCostButton()}
              </div>}
              <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                {displayParentWork(item)}
                {displayParentInventory(item)}
                {(parentWork(item) || parentInventory(item)) && item.payee && separator()}
                {item.propertyId && displayPayee(item)}
                {(item.payee || parentWork(item) || parentInventory(item)) && (item.userPaidBy || item.contactPaidBy) && separator()}
                {displayPaidBy(item)}
              </div>
              {item.propertyId
                ? item.isConfirmed && <div style={{marginTop: "0.5rem"}}>
                  <TagContainer
                    parentTags={item.tags}
                    parentId={item.expenseId}
                    parentType={"expense"}
                    selectedTags={selectedTags}
                    toggleGlobalEditMode={toggleEditTagsMode}
                    refreshParent={() => dispatch(getAllExpenses(propertyId))}
                  />
                </div>
              : <AssignToPropertyButton
                  onClick={(e) => {e.stopPropagation(); setExpenseToAssignId(item.expenseId); toggleAssignPropertySmallDrawerOpen(true);}}
                >
                  <HouseSmall style={{marginRight:"0.5rem"}} />
                  <span>Assign to a property</span>
                </AssignToPropertyButton>
              }
            </div>
          </div>
          <div>
            <Popover
              content={moreMenuDropdown(item)}
              placement="bottomRight"
              trigger={["click"]}
              //@ts-ignore
              zIndex={2}
            >
              <MoreMenu
                style={{marginTop: "1rem", marginLeft: "1rem"}}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Popover>
          </div>
        </div>
      </Skeleton>
    </ListItemStyled>)
  }

  const overlay = () => {
    return <div style={{display: "flex", width: "100%", justifyContent: "flex-end"}}>
      <InvoiceOverlay style={{right: 0, width: "22rem"}}>
        <div style={{color: "#2a80e1", fontWeight: 600, marginBottom: "0.5rem"}}>
          Hey, we're still working on this new feature!
        </div>
        Our automated invoice reading feature is brand new, and is continuously being improved by our developer team with
        machine learning algorithms - so please reconfirm the outputs in the Add Expense fields.
      </InvoiceOverlay>
    </div>
  }

  return (
    <PageWrapper>
      <ForwardExpenseDrawer
        isOpen={isForwardDrawerOpen}
        toggleDrawerOpen={toggleForwardDrawer}
        expenseId={expenseToForward}
      />
      <WorkDrawer
        isOpen={workDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        work={editWorkData}
        setEditingWorkData={setEditWorkData}
        width={null}
      />
      <ViewWorkDrawer
        workId={workToViewId}
        setWorkId={setWorkToViewId}
        isOpen={isViewWorkDrawerOpen}
        toggleDrawer={toggleViewWorkDrawer}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setLinkedWorkId={() => {}}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setWorkIdToForward={() => {}}
        toggleForwardDrawer={() => {}}
        toggleTradeDrawer={() => {}}
        setEditSpaceId={setViewSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        setPresetLinkType={() => {}}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <ViewContactDrawer
        contactId={contactToViewId}
        isOpen={isViewContactDrawerOpen}
        toggleDrawerOpen={toggleViewContactDrawer}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setLinkedContactId={() => {}}
        setPresetLinkType={() => {}}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setViewWorkId={setWorkToViewId}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
        setEditSpaceId={() => {}}
        toggleEditSpaceDrawer={() => {}}
      />
      <LinkDrawer
        parentId={viewSpaceId}
        parentType={"cost"}
        isOpen={isLinkDrawerOpen}
        toggleDrawerOpen={toggleLinkDrawer}
        presetLinkType={presetLinkType}
        setPresetLinkType={setPresetLinkType}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setEditSpaceId={setViewSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        toggleEventDrawer={() => {}}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <ViewSpaceDrawer
        spaceId={viewSpaceId}
        isOpen={isViewSpaceDrawerOpen}
        toggleDrawerOpen={toggleViewSpaceDrawer}
        toggleEditDrawerOpen={toggleViewSpaceDrawer}
        setEditSpaceId={setViewSpaceId}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setLinkedSpaceId={() => {}}
        setPresetLinkType={setPresetLinkType}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <NoteDrawer
        currentType={"note"}
        data={editingNoteData}
        isOpen={isNoteDrawerOpen}
        toggleDrawerOpen={toggleNoteDrawer}
        refreshParent={() => dispatch(getAllSpaces(propertyId))}
        isGlobalNote={true}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        setPresetLinkType={setPresetLinkType}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        setEditSpaceId={setViewSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <ViewFileDrawer
        attachmentId={attachmentToViewId}
        setAttachmentId={setAttachmentToViewId}
        isOpen={isViewFileDrawerOpen}
        toggleDrawer={toggleViewFileDrawer}
        viewFileDrawerDefaultTab={"preview"}
        attachmentsWithoutProperty={[]}
        duplicateAttachments={[]}
        selectedTags={[]}
        toggleEditTagsMode={() => {}}
        toggleEditDrawerOpen={() => {}}
        setPresetLinkType={() => {}}
        toggleWorkDrawer={() => {}}
        setEditWorkData={() => {}}
        toggleExpenseDrawer={() => {}}
        setEditExpenseData={() => {}}
        toggleInventoryDrawer={() => {}}
        setEditInventoryData={() => {}}
        toggleContactDrawer={() => {}}
        setEditContactData={() => {}}
        toggleNoteDrawer={() => {}}
        setEditNoteData={() => {}}
        toggleLinkDrawerOpen={() => {}}
        setEditSpaceId={() => {}}
        toggleSpaceDrawer={() => {}}
        refreshParent={() => {}}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        toggleViewWorkDrawer={() => {}}
        setViewWorkId={() => {}}
      />
      <InventoryDrawer
        isOpen={inventoryDrawerOpen}
        toggleDrawerOpen={toggleInventoryDrawer}
        inventory={editInventoryData}
        setEditingInventoryData={setEditInventoryData}
        width={null}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
      />
      <ExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        toggleDrawerOpen={toggleExpenseDrawer}
        setEditingExpenseData={setEditingExpenseData}
        expense={editingExpenseData}
        invoiceData={invoiceData}
        refreshExpensesWithoutProperty={fetchExpensesWithoutProperty}
      />
      <ContactDrawer
        isOpen={isContactDrawerOpen}
        toggleDrawerOpen={toggleContactDrawer}
        setEditingContactData={setEditingContactData}
        contact={editingContactData}
      />
      <AssignPropertySmallDrawer
        recordId={expenseToAssignId}
        isOpen={isAssignPropertySmallDrawerOpen}
        toggleDrawerOpen={toggleAssignPropertySmallDrawerOpen}
        refreshParent={() => refreshAllExpenses()}
        assignPropertyParent={assignPropertySingle}
      />
      <AssignMultiPropertyDrawer
        isOpen={isAssignMultiPropertyDrawerOpen}
        toggleDrawerOpen={toggleAssignMultiPropertyDrawerOpen}
        expensesWithoutProperty={expensesWithoutProperty}
        refreshParent={() => refreshAllExpenses()}
      />
      <BatchProcessingModal isBatchProcessing={isBatchProcessing} />
      <div>
        <PageHeader>
          <HeaderControlsWrapper>
            <div style={{display: "flex", alignItems: "center"}}>
              <PageTitle>Expenses</PageTitle>
              {fileUploadTooltip("invoice")}
            </div>
          </HeaderControlsWrapper>
          <HeaderControlsWrapper className={"mini"}>
            <UploadInvoice
              fileList={[]}
              allowBatchUpload={true}
              uploadOrigin={"header"}
              setExpenseData={setEditingExpenseData}
              setInvoiceData={setInvoiceData}
              toggleExpenseDrawer={toggleExpenseDrawer}
              toggleBatchProcessing={toggleBatchProcessing}
            />
            <AddButtonStyled
              icon={<PlusOutlined />}
              onClick={() => addNewExpense()}
            >
              {window.innerWidth > 1024 && f(messages.addExpensesBtn)}
            </AddButtonStyled>
          </HeaderControlsWrapper>
        </PageHeader>
        <PageSubtitle>
          Track your property expenses. Create expenses manually or automatically by uploading invoices
        </PageSubtitle>
      </div>
      <PageInfoWrapper>
        <PageInfoColumn>
          <div className={"column-header"}>Total expenses</div>
          <div className={"column-content"}>
            {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(totalExpensesCost)}
          </div>
        </PageInfoColumn>
        <PageInfoColumn>
          <div className={"column-header"}>Expenses this month</div>
          <div className={"column-content"}>
            {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(currentMonthExpensesCost)}
          </div>
        </PageInfoColumn>
        <PageInfoColumn>
          <div className={"column-header"}>No. Expenses this month</div>
          <div className={"column-content"}>
            {currentMonthExpensesCount}
          </div>
        </PageInfoColumn>
      </PageInfoWrapper>
      {!showFileUploadPopup && fileUploadPopup("invoice")}
      {(expenses.content.length === 0 || expenses.content.filter(expense => !_.isNull(expense.name)).length === 0)
        && expensesWithoutProperty.length === 0 && (
        <EmptyRecordsList>
          <EmptyRecordsHeader>
            {f(messages.addExpenseTitle)}
          </EmptyRecordsHeader>
          <EmptyRecordsDescription>
            {f(messages.addExpenseMsg)}
          </EmptyRecordsDescription>
          <AddRecordButton
            onClick={() => addNewExpense()}
            size={"large"}
            icon={<PlusOutlined/>}
          >
            {f(messages.addExpensesBtn)}
          </AddRecordButton>
        </EmptyRecordsList>
      )}
      {expensesWithoutProperty.length > 0 && window.innerWidth > 1024 &&
        <UnassignedAttachmentsInfo>
          <HouseSmallBlackIcon/>
          <div>
            You have received <span>{expensesWithoutProperty.length} new expense{expensesWithoutProperty.length > 1 && "s"}</span> by email. <a onClick={() => toggleAssignMultiPropertyDrawerOpen(true)}>Click here</a> to assign {expensesWithoutProperty.length === 1 ? "it" : "them"} to the correct property.
          </div>
        </UnassignedAttachmentsInfo>
      }

      {(!_.isEmpty(expenses.content.concat(expensesWithoutProperty)) && !_.isEqual(expenses.content, expensesInitial)
        && expensesTypesWithAmounts.length > 1
          && expenses.content.filter(expense => !_.isNull(expense.name)).concat(expensesWithoutProperty).length > 0
        && !_.isEqual(contacts.content, initialContactData)
      ) && (
        <>
          <ExpensesTopLevelCategoryWrapper>
            <ExpensesTopLevelCategorySelector
              onClick={() => {
                setTopLevelCategory(null);
                setPage(1)
                toggleShowUnconfirmedOnly(false)
              }}
              className={_.isNil(topLevelCategory) && !showUnconfirmedOnly ? "expenses-top-level-category-selected" : ""}
            >
              All
            </ExpensesTopLevelCategorySelector>
            {parentExpenseTypesWithAmounts.size > 0 && topLevelExpensesTypes.map((topLevelType) =>
              // @ts-ignore
              parentExpenseTypesWithAmounts.get(topLevelType.id) > 0 &&
                <ExpensesTopLevelCategorySelector
                  onClick={() => {
                    setTopLevelCategory("" + topLevelType.id);
                    setPage(1)
                    toggleShowUnconfirmedOnly(false)
                  }}
                  className={topLevelCategory === "" + topLevelType.id ? "expenses-top-level-category-selected" : ""}
                  key={"topselector" + topLevelType.id}
                >
                  {topLevelType.name.replace(" Costs", "")}
                </ExpensesTopLevelCategorySelector>
            )}
            {expenses.content.filter(expense => !expense.isConfirmed).length > 0 && <ExpensesTopLevelCategorySelector
              onClick={() => {
                setTopLevelCategory(null);
                setPage(1)
                toggleShowUnconfirmedOnly(true)
              }}
              className={showUnconfirmedOnly ? "expenses-top-level-category-selected" : ""}
              key={"unconfirmed"}
            >
              Unconfirmed
            </ExpensesTopLevelCategorySelector>}
          </ExpensesTopLevelCategoryWrapper>
          <ExpensesListDesktop>
            {searchPanel()}
            {showFilters && displayFilters()}
            {!showUnconfirmedOnly && <div style={{
              display: "flex",
              flexDirection: "row",
              width: '100%',
              paddingLeft: window.innerWidth > 1024 ? "2rem" : "1rem",
              paddingRight: window.innerWidth > 1024 ? "2rem" : "1rem",
              marginBottom: "1rem",
              color: "#6B7185",
            }}>
              <div style={{
                display: "flex",
                width: "100%",
                paddingTop: showFilters ? "1rem" : 0,
                justifyContent: window.innerWidth > 1024 ? "space-between" : "flex-start",
                flexDirection: window.innerWidth > 1024 ? "row" : "column"
              }}>
                {_.isNil(topLevelCategory) &&
                <div style={{display: "flex", flexDirection: window.innerWidth > 1024 ? "row" : "column"}}>
                  {topLevelExpensesTypes.filter(topLevelType => totalTopLevel("" + topLevelType.id) > 0).map((topLevelType, index) =>
                      <div style={{
                        paddingRight: "0.5rem",
                        borderRight: (index !== topLevelExpensesTypes.filter(topLevelType =>
                          totalTopLevel("" + topLevelType.id) > 0).length - 1 && window.innerWidth > 1024) ? "1px solid #DAE0E6" : "none",
                        paddingLeft: (index > 0 && window.innerWidth > 1024) ? "0.75rem" : "0",
                        paddingBottom: window.innerWidth > 1024 ? "0" : "0.75rem",
                      }}
                           key={"summary" + topLevelType.id}
                      >
                        {topLevelType.name}
                        <span style={{fontWeight: 600, color: "#21272B", marginLeft: "0.3rem"}}>
                    {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(totalTopLevel("" + topLevelType.id))}
                  </span>
                      </div>
                  )}
                </div>}
                {!_.isNil(topLevelCategory) && <div style={{display: "flex"}}>
                  {topLevelExpensesTypes.filter((topLevelType: any) => topLevelType.id === topLevelCategory).map((topLevelType, index) =>
                      <div style={{
                        paddingRight: "1rem",
                        paddingLeft: (index > 0 && window.innerWidth > 1024) ? "1rem" : "0",
                        paddingBottom: window.innerWidth > 1024 ? "0" : "0.5rem",
                      }}
                           key={"summary" + topLevelType.id}
                      >
                        {topLevelType.name}
                        <span style={{fontWeight: 600, color: "#21272B", marginLeft: "0.3rem"}}>
                    {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(totalTopLevel("" + topLevelType.id))}
                  </span>
                      </div>
                  )}
                </div>}
                {_.isNil(topLevelCategory) && <ExportButton
                    style={{marginTop: "-0.3rem"}}
                    onClick={() => {
                      let exportedTags: any = []
                      Array.from(selectedTags).map((tag: any) => exportedTags.push(tag.tagId))

                      exportExpenses({
                        propertyId,
                        periodStartDate: startDate,
                        periodEndDate: endDate,
                        taxDeductible: filterTaxDeductible ? filterTaxDeductible : null,
                        typeIds: Array.from(selectedExpenseTypes),
                        tags: exportedTags
                      }).then((response: any) =>
                        downloadExport(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
                    }}
                >
                    <ExportIcon style={{marginRight: '1rem'}}/>Export to Excel
                </ExportButton>}
              </div>
            </div>}
            <List
              style={{width: "100%", borderTop: "1px solid #f0f0f0"}}
              className="no-rounded-list"
              itemLayout="horizontal"
              dataSource={getExpenses()}
              locale={{emptyText: `There are no expenses 
                ${selectedCategory ? `of type ${expensesTypesWithAmounts.filter((expenseType: any) => 
                  expenseType.id === selectedCategory)[0].name}` : ""} matching your selected period.`}}
              renderItem={(item: any) => window.innerWidth > 480 ? CostItemDesktop(item) : CostItemMobile(item)}
            />
            {filteredExpenses().length > pageSize && <PaginationStyled
              className={filteredExpenses().length < pageSize ? "single-page-pagination" : ""}
              onChange={(page) => setPage(page)}
              simple={window.innerWidth < 1024}
              showSizeChanger={false}
              itemRender={paginationButtons}
              defaultCurrent={1}
              current={page}
              total={filteredExpenses().length}
              defaultPageSize={pageSize}
              onShowSizeChange={(current, size) => setPageSize(size)}
            />}
            <div ref={invoiceUploadRef} style={{width: "100%", padding:"2.5rem"}}>
              <Dropdown overlay={overlay}>
                <div>
                  <UploadInvoice
                    fileList={[]}
                    allowBatchUpload={true}
                    uploadOrigin={"costs"}
                    setExpenseData={setEditingExpenseData}
                    setInvoiceData={setInvoiceData}
                    toggleExpenseDrawer={toggleExpenseDrawer}
                    toggleBatchProcessing={toggleBatchProcessing}
                  />
                </div>
              </Dropdown>
            </div>
          </ExpensesListDesktop>
        </>
      )}
    </PageWrapper>
  );
};

const ExpensesTopLevelCategorySelector = styled.div`
  background: #DAE0E6;
  color: #6B7185;
  border-radius: 24px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  
  :hover {
    cursor: pointer;
  }
  
  @media (max-width: 1024px) {
    border-radius: 16px;
    font-size: 0.875rem;
    line-height: 1.125rem;
    padding: 0.25rem 0.75rem;
    font-weight: 500;
  }
`;

const ExpensesTopLevelCategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 1rem;
  width: 100%;
  column-gap: 1rem;
  
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
  }
`

const ExpensesListDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 0.625rem;
`;

export const SearchWrapperDesktop = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const SearchWrapperMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 1rem;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const ListItemStyled = styled(List.Item)`
  padding: 0.5rem 2rem;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0.5rem 1rem;

    .ant-list-item-action > li {
      width: 100%;
    }
  }
  
  @media (min-width: 1024px) {
    min-height: 6rem;
  }
  
  :nth-child(odd) { 
    background: white;    
  }
  
  :hover {
    background: #ECF6FF;
  }
`;

const RelatedLink = styled.a`
  color: #2F80ED;
  text-decoration: underline;
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px !important;
  font-weight: 600;
  @media (max-width: 1024px) {
    display: inline-flex;
    justify-content: center;
  }
`;

export const ButtonFilters = styled(ButtonStyled)`
  margin-left: 1rem;
  margin-top: 0;
  
  @media (min-width: 1024px) {
    svg {
      margin-right: 0.5rem;
    }
  }
  
  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const SortMenuStyled = styled(Menu)`
  font-weight: 600;
  color: #21272b;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-radius: 6px;

  .ant-menu-item-active {
    color: #6b7185 !important;
  }

  .ant-menu-item {
    height: 1.5rem !important;
    padding-bottom: 2rem !important;
  }
`;

const ExportButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: #2F80ED;
  border: 0;
  box-shadow: 0;
  padding: 0;
  max-width: 8rem;
`

export const ButtonSort = styled(ButtonStyled)`
  margin-left: 1rem;
  height: 2.5rem !important;
  width: 3.2rem !important;
  margin-top: 0;
  svg {
    margin-top: 0.3rem;
  }
  
  @media (max-width: 1024px) {
    height: 2.5rem!important;
  }
`;

export const FilterTagsWrapper = styled.div`
  width: 100%;
  border-top: 1px solid #DAE0E6;
  padding: 1.5rem;
  background:  #EBF9FF;
  margin-bottom: 1.5rem;
  
  @media (max-width: 1024px) {
    padding: 1rem 0.5rem;
  }
`

const TaxDeductibleLabel = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.875rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #21272B;
`

const RelatedLabel = styled.div`
  display: flex;
  gap: 0.5rem;
  height: 100%;
  align-items: flex-start;
  margin: 0.5rem 0;
  
  @media (max-width: 480px) {
    margin: 0;
    .relatedLabel { width: 20%; }
    .relatedArrow { width: 10%; }
    .relatedLink { width: 70%; }
  }
`

export default Costs;
