import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";

export type TParseInvoiceResponse = Array<{
    dateOfExpense: string;
    totalCost: string;
    payee: string;
    s3Key: string;
}>;

export type TParseInvoiceRequest = {
  file: any;
};

export const parseInvoice = (payload: TParseInvoiceRequest) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/invoice/process/new`);
  let bodyFormData = new FormData();

  bodyFormData.append("file", payload.file);

  return apiPost<TParseInvoiceResponse, any>(url, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
