import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import { getSubscriptionUsers, TGetSubscriptionUsersResponse } from "../../api-wrapper/user/getSubscriptionUsers";

export const getSubscriptionUsersInitAction = createAction("subscriptionUsers/GET_SUBSCRIPTIONUSERS_INIT")();
export const getSubscriptionUsersSuccessAction = createAction(
  "subscriptionUsers/GET_SUBSCRIPTIONUSERS_SUCCESS"
)<TGetSubscriptionUsersResponse>();
export const getSubscriptionUsersFailureAction = createAction("subscriptionUsers/GET_SUBSCRIPTIONUSERS_FAILURE")();

export type TGetSubscriptionUsersInitAction = ActionType<typeof getSubscriptionUsersInitAction>;
export type TGetSubscriptionUsersSuccessAction = ActionType<typeof getSubscriptionUsersSuccessAction>;
export type TGetSubscriptionUsersFailureAction = ActionType<typeof getSubscriptionUsersFailureAction>;

export type TGetSubscriptionUsersActions =
  | TGetSubscriptionUsersInitAction
  | TGetSubscriptionUsersSuccessAction
  | TGetSubscriptionUsersFailureAction;

export type TGetSubscriptionUsersThunkActionType = (
  subscriptionId: number
) => ThunkAction<void, TRootState, null, TGetSubscriptionUsersActions>;

export const getAllSubscriptionUsers: TGetSubscriptionUsersThunkActionType = (subscriptionId: number) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getSubscriptionUsersInitAction());
  return getSubscriptionUsers(subscriptionId)
    .then((payload) => {
      return dispatch(getSubscriptionUsersSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getSubscriptionUsersFailureAction()));
};
