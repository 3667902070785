import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetExpensesResponse } from "../../api-wrapper/expenses/getExpenses";
import {
  getExpensesFailureAction,
  getExpensesInitAction,
  getExpensesSuccessAction,
  TGetExpensesActions,
} from "../actions/expenses";
import {expensesInitial} from "../components/helper";

export type TExpenses = {
  fetchingStatus: TFetchingStatus;
  content: TGetExpensesResponse;
};

const initialState: TExpenses = {
  content: expensesInitial,
  fetchingStatus: TFetchingStatus.Defined,
};

export const expensesReducer = createReducer<TExpenses, TGetExpensesActions>(
  initialState
)
  .handleAction(getExpensesInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getExpensesSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getExpensesFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
