import {Guid} from "guid-typescript";
import {apiGet, createUrl} from "../apiCall";
import EnvConfig from "../../config";

export type TMortgage = {
  mortgageId: Guid,
  subscriptionId: number,
  propertyId: Guid,
  provider: Guid,
  loanTotal: number,
  mortgageDate: string,
  durationYears: number,
  monthlyInstallment: number,
  initialPayment: number,
  fixedPeriodYears: number,
  creationDate: string,
  totalRepaid: number,
  remainingLoan: number,
  yearsRemaining: number,
  mortgageCloseDate: string,
  expensesInPast: boolean,
  interestRate: number
}

export type TGetMortgagesResponse = Array<TMortgage>;

export const getMortgages = (propertyId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/mortgages`);

  return apiGet<TGetMortgagesResponse | []>(url, {}, propertyId);
};