import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import _ from "lodash";
import {Guid} from "guid-typescript";

export type TRelation = {
  parentId: Guid;
  parentType: string;
  childType: string;
  childId: Guid;
};

export type TAddRelationResponse = {
  entitiesMapping: Array<TRelation>,
  suggestedMappings: Array<TRelation>
}

export type TAddRelationRequest = {
  propertyId: Guid;
  parentId: Guid;
  parentType: string;
  childType: string;
  childId: Guid;
};

export const addRelation = (payload: TAddRelationRequest) => {
  const { propertyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/entity/relation`
  );

  return apiPost<
    TAddRelationResponse,
    {
      parentId: Guid;
      parentType: string;
      childType: string;
      childId: Guid;
    }
  >(url, _.omit(payload, ["propertyId"]));
};
