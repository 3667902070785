import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TGetPropertiesResponse = Array<{
  name?: string | null;
  address: string;
  address2?: string | null;
  city: string;
  state?: string | null;
  country: string;
  ukCountry?: string;
  zip: string;
  propertyId: Guid;
  dateAdded: string;
  nickname?: string | null;
  imageThumbnail?: string | null;
  onboardingUpkeepStatus: string;
  onboardingContentStatus: string;
  onboardingImprovementsStatus: string;
  questionnairePage: number;
  dismissExpenses: boolean;
  dismissHomeValue: boolean;
  dismissSchedule: boolean;
  preferredDateFormat: string;
  preferredCurrency: string;
  taxYearMonth: number;
  taxYearDate: number;
}>;

export const getProperties = () => {
  const url = createUrl(`${EnvConfig.API_URL}/api/properties`);

  return apiGet<TGetPropertiesResponse>(url);
};
