import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TEditMortgageRequest = {
  mortgageId: Guid;
  propertyId: Guid;
  provider: Guid | null,
  loanTotal: number,
  mortgageDate: string,
  durationYears: number,
  monthlyInstallment: number,
  initialPayment: number,
  fixedPeriodYears: number,
  interestRate: number
}

export const editMortgage = (payload: TEditMortgageRequest) => {
  const { propertyId, mortgageId } = payload;
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/mortgage/${mortgageId}`);

  return apiPut<any, {}>(url, payload);
};
