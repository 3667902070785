import React, {useEffect, useState} from "react";
import {
  ActionButtonDarkBlue,
  ActionButtonGreen,
  CircleIcon,
  DrawerHeader,
  DrawerStyled
} from "../../screens/components";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";
import {ReactComponent as ReturnArrow} from "../../../images/spaces/ReturnArrow.svg";
import {
  ContainerItemWrapper,
  ContainerTitle,
} from "../spaces/AddSpaceOptions";
import {ReactComponent as OnboardingIcon} from "../../../images/OnboardingIcon.svg";
import {
  Legalese,
  ServiceUnavailable,
  supportedLocation,
  TradeDetails,
  TradeItem,
  TradesContainer
} from "./TradeDrawer";
import {useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import _ from "lodash";
import {emptyGuid} from "../../screens/helpers";
import styled from "styled-components";
import {useHistory} from "react-router-dom";

type Props = {
  isDrawerOpen: boolean;
  toggleDrawer: (arg: boolean) => void;
  togglePropertyDrawer?: (arg: boolean) => void;
}

const OnboardingDrawer = (props: Props) => {
  const {isDrawerOpen, toggleDrawer, togglePropertyDrawer} = props;
  const [propertyLocation, setPropertyLocation] = useState<any>(null);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);
  const history = useHistory();

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find((property) => property.propertyId === propertyId);
    if (!currentProperty)
      return;
    currentProperty.address === "My home" ? setPropertyLocation(currentProperty.address) :
      setPropertyLocation(`${currentProperty.ukCountry}, ${currentProperty.country}`)
  }, [propertyId, properties])

  const handleCancel = () => {
    toggleDrawer(false)
  }

  return <DrawerStyled
    push={{ distance: "32px" }}
    closeIcon={false}
    width={window.innerWidth > 1024 ? "680px" : "100%"}
    height={window.innerWidth > 768 ? "100%" : "85%"}
    placement={window.innerWidth > 768 ? "right" : "bottom"}
    visible={isDrawerOpen && propertyLocation}
    onClose={() => handleCancel()}
    title={<DrawerHeader className={"edit-header"}>
      {window.innerWidth <= 768 && <ReturnArrow onClick={() => handleCancel()}/>}
      In-person onboarding
      {window.innerWidth > 768 && <CircleIcon onClick={() => handleCancel()}><CloseIcon /></CircleIcon>}
    </DrawerHeader>}
  >
    {propertyLocation === supportedLocation ? (<>
      <TradesContainer className={"open"}>
        <ContainerTitle>
          <OnboardingIcon style={{flexShrink: 0}}/>
          <span>Free site visit for Livlet account on-boarding<br/>(72-hour response time)</span></ContainerTitle>
        <ContainerItemWrapper>
          <TradeItem>
            <TradeDetails>
              <span>Call <span className={"accent-green"}>0117 313 3248*</span> to organise a free site visit to assist in onboarding your property onto Livlet.</span>
              <span>The short visit will include house details, space, key appliances & systems as well as important information for the home (Main valve locations, Meters, etc..)</span>
              <span className={"disclaimer"}>*Standard network charges may apply.</span>
            </TradeDetails>
          </TradeItem>
        </ContainerItemWrapper>
      </TradesContainer>
      {Legalese()}
    </>) :  ( propertyLocation === "My home" && togglePropertyDrawer ? <>
      <TradesContainer className={"open"}>
        <ContainerTitle style={{flexDirection: "column", gap: "0.5rem"}}>
          <span>
            This service is currently only available in England and we hope to expand our services to serve your area soon.
          </span>
          <span>
          A valid UK address is required to enable this service - you can input this in your <span className={"fake-link"}
            onClick={() => togglePropertyDrawer(true)}>Property details</span>.
          Alternatively, you can use the <span className={"fake-link"} onClick={() =>
            history.push("/questions")}>"Build my home"</span> tool to go through the onboarding process yourself.
          </span>
        </ContainerTitle>
      </TradesContainer>
      <ButtonWrapper>
        <ActionButtonDarkBlue onClick={() => togglePropertyDrawer(true)}>Go to Property Details</ActionButtonDarkBlue>
        <ActionButtonGreen onClick={() => history.push("/questions")}>Go to Build my home</ActionButtonGreen>
      </ButtonWrapper>
    </> : ServiceUnavailable())}
  </DrawerStyled>
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export default OnboardingDrawer