import {DatePicker, Drawer, Dropdown, Form, Input, Modal, Tooltip} from "antd";
import { useIntl } from "react-intl";
import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as SaveIcon } from "../../../images/SaveIcon.svg";
import { ReactComponent as MyTenantIcon } from "../../../images/MyProperty.svg";
import {
  getContactsSelector,
  getPropertyIdSelector,
  getPropertyPreferencesSelector,
  getTagsWithUsageCountSelector
} from "../../selectors";
import {
  AttachButtonStyled,
  ButtonsWrapper,
  CloseButton,
  NoItemsText, PlusButtonStyledLists,
  SaveButton,
  SaveButtonWrapper, Separator
} from "../works/WorkDrawer";
import {InputsWrapper, InputWrapper, Label, LabelWrapper} from "../../screens/RegistrationPage";
import messages from "../../screens/messages";
import {addCertificate} from "../../../api-wrapper/certificates/addCertificate";
import {getAllCertificates} from "../../actions/certificates";
import moment from "moment";
import {editCertificate} from "../../../api-wrapper/certificates/editCertificate";
import {ReactComponent as AttachmentIcon} from "../../../images/Attachments.svg";
import {ReactComponent as HelpIcon} from "../../../images/Help.svg";
import _ from "lodash";
import {attachmentsInitial, initialContactData} from "../helper";
import {emptyGuid, getCurrencySign} from "../../screens/helpers";
import UploadFile from "../attachments/UploadFile";
import {BorderSection} from "../inventories/InventoryDrawer";
import {Guid} from "guid-typescript";
import {TUpcomingTask} from "../../../api-wrapper/upcomingTasks/getUpcomingTasks";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import {ReactComponent as WorkIcon} from "../../../images/WorksNew.svg";
import {addRelation} from "../../../api-wrapper/relation/addRelation";
import AttachmentsOnDrawer from "../attachments/AttachmentsOnDrawer";
import {AutoCompleteStyled, MenuItemStyled, MenuStyled} from "../styled";
import ContactsListComp from "../contacts/ContactsListCompDrawers";
import {ReactComponent as ContactsIcon} from "../../../images/ContactsGray.svg";
import {ReactComponent as AddCircle} from "../../../images/AddCircle.svg";
import {getCertificateById} from "../../../api-wrapper/certificates/getCertificateById";
import ContactDrawer from "../contacts/ContactDrawer";
import AddExistingContactDrawer from "../contacts/AddExistingContactDrawer";
import {getEntityTypes} from "../../../api-wrapper/relation/getEntityTypes";
import {removeCertificate} from "../../../api-wrapper/certificates/removeCertificate";
import {addNewTag} from "../../../api-wrapper/tags/addNewTag";
import {setTagsForParent} from "../../../api-wrapper/tags/setTagsForParent";
import {getAllTagsWithUsageCount} from "../../actions/tagsWithUsageCount";
import {InputNumberStyled} from "../expenses/ExpenseDrawer";
import {PropertyDrawerTitle} from "../../screens/components";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  setEditingCertificateData: (certificate: any) => void;
  certificate: { data: any } | null;
  refreshParent?: (arg?: Guid | null) => void;
  highlightAttachment?: Guid | null;
  reminderToRecord?: TUpcomingTask | null;
};

const CertificateDrawer = (props: Props) => {
  const {
    isOpen,
    toggleDrawerOpen,
    setEditingCertificateData,
    certificate,
    highlightAttachment,
    refreshParent,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();

  const propertyId = useSelector(getPropertyIdSelector).value;
  const tags = useSelector(getTagsWithUsageCountSelector);
  const selectFilesRef = useRef(null);
  const fileListRef = useRef(null);
  const contacts = useSelector(getContactsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [saveDisabled, setSaveDisabled] = useState(false);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [showFileUploadList, setShowFileUploadList] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Set<Guid>>(new Set());
  const [certificateTypes, setCertificateTypes] = useState<any>([]);
  const [certificateTypeOptions, setCertificateTypeOptions] = useState<Array<{ key: string; value: string }>>([]);
  const [certificateTag, setCertificateTag] = useState<any>(null);

  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [isAddExistingContactDrawerOpen, toggleAddExistingContactDrawer] = useState(false);
  const [editingContactData, setEditingContactData] = useState<any>(null);
  const [certificateDate, setCertificateDate] = useState<any>(null);

  const [certificateDetails, setCertificateDetails] = useState<any>({
    certificateId: emptyGuid,
    attachments: attachmentsInitial,
    contacts: initialContactData,
  });

  const refreshCertificateTypes = () => {
    getEntityTypes(propertyId, "certificate").then((res: any) => res && setCertificateTypes(res));
  }

  useEffect(() => {
    refreshCertificateTypes()
    dispatch(getAllTagsWithUsageCount(propertyId));
  }, [propertyId, certificate]);

  useEffect(() => {
    setCertificateTypeOptions(certificateTypes.map((type: any) => {
      return {key: type.name, value: type.name}
    }))
  }, [certificateTypes])

  useEffect(() => {
    // certificate is not a standard tag, so if it doesn't exist we need to add it
    let certificateTag = tags.content.filter(tag => tag.name === "CERTIFICATE")
    if (certificateTag.length === 0) {
      addNewTag("CERTIFICATE").then(addedTag => setCertificateTag(addedTag))
    } else {
      setCertificateTag(certificateTag[0])
    }
  }, [tags])


  const onTypeAutocompleteSearch = (searchText: string) => {
    setCertificateTypeOptions(!searchText
      ? certificateTypes.map((type: any) => { return {key: type.name, value: type.name} })
      : certificateTypes
        .filter((type: any) => type.name.toLowerCase().includes(searchText.toLowerCase()))
        .map((type: any) => { return {key: type.name, value: type.name }}))
  }

  useEffect(() => {
    if (certificate) {
      certificate.data.object.certificateDate ?
        setCertificateDate(moment(certificate.data.object.certificateDate)) : setCertificateDate(null);
    } else {
      setCertificateDate(null);
    }
    setSaveDisabled(false);
    form.resetFields();
  }, [certificate, isOpen]);

  useEffect(() => {
    if (certificate && !Object.is(certificate.data.object.certificateId, emptyGuid))
      getCertificateById(propertyId, certificate.data.object.certificateId).then((res: any) =>
        res && setCertificateDetails({
          certificateId: res.object.certificateId,
          attachments: res.attachments,
          contacts: res.contacts
        }))
    else setCertificateDetails({
      certificateId: emptyGuid,
      attachments: attachmentsInitial,
      contacts: initialContactData
    })
  }, [certificate]);

  const refreshCertificate = () => {
    certificate && !Object.is(certificate.data.object.certificateId, emptyGuid) &&
    getCertificateById(propertyId, certificate.data.object.certificateId).then((res: any) => {
      res && setCertificateDetails({
        certificateId: res.object.certificateId,
        attachments: res.attachments,
        contacts: res.contacts
      })
    });
  };

  const refreshCertificateContact = (contactId?: Guid | null) => {
    contactId && certificate && certificate.data &&
    addRelation({
      propertyId,
      parentId: certificate.data.object.certificateId,
      childId: contactId,
      parentType: "certificate",
      childType: "contact",
    }).then(() => {
      refreshCertificate();
      setFormFieldsChanged(false);
    });

    certificate && !Object.is(certificate.data.object.certificateId, emptyGuid) &&
    getCertificateById(propertyId, certificate.data.object.certificateId).then((res) => {
      setCertificateDetails(res);
    });
  };

  const initialValues = () => {
    if (certificate) {
      const {
        certificateDate,
        endOfValidity,
        certificateType,
        amountPaid,
        certificateNumber
      } = certificate.data.object;

      return {
        certificateType,
        amountPaid,
        certificateNumber,
        certificateDate: certificateDate === null ? "" : moment(certificateDate),
        endOfValidity: endOfValidity === null ? "" : moment(endOfValidity),
      };
    } else {
      return {};
    }
  };

  const tagAttachmentsAsCertificates = async (certificateId: Guid) => {
    let details = await getCertificateById(propertyId, certificateId);
    // @ts-ignore
    let attachments = details.attachments;
    attachments.forEach((attachment: any) => {
      if (attachment.tags.filter((tag: any) => tag.name === "CERTIFICATE").length === 0) {
        setTagsForParent([certificateTag.tagId], attachment.attachmentId, "attachment", propertyId).then();
      }
    })
  }

  const handleManageCertificate = async (e: {
    certificateDate: moment.Moment,
    endOfValidity: moment.Moment,
    certificateType: string,
    amountPaid: number,
    certificateNumber: number
  }) => {
    const {
      certificateDate,
      endOfValidity,
      certificateType,
      amountPaid,
      certificateNumber
    } = e

    if (certificate) {
      editCertificate({
        propertyId,
        certificateId: certificate.data.object.certificateId,
        certificateDate: certificateDate ? moment(certificateDate).format('YYYY-MM-DD') : null,
        endOfValidity: endOfValidity ? moment(endOfValidity).format('YYYY-MM-DD') : null,
        certificateType,
        amountPaid,
        certificateNumber
      }).then(() => {
        tagAttachmentsAsCertificates(certificate.data.object.certificateId);
        toggleDrawerOpen(false);
        setEditingCertificateData(null);
        form.resetFields();
        setSaveDisabled(false);
        setFormFieldsChanged(false);
        dispatch(getAllCertificates(propertyId));
        if (refreshParent) refreshParent();
      })
    } else {
      addCertificate({
        propertyId,
        certificateDate: certificateDate ? moment(certificateDate).format('YYYY-MM-DD') : null,
        endOfValidity: endOfValidity ? moment(endOfValidity).format('YYYY-MM-DD') : null,
        certificateType,
        amountPaid,
        certificateNumber
      }).then(() => {
        toggleDrawerOpen(false);
        setSaveDisabled(false);
        setFormFieldsChanged(false);
        setEditingCertificateData(null);
        form.resetFields();
        dispatch(getAllCertificates(propertyId));
        if (refreshParent) refreshParent();
      })
    }
  }

  useEffect(() => {
    if (showFileUploadList && selectFilesRef && selectFilesRef.current) {
      // @ts-ignore
      selectFilesRef.current.scrollIntoView();
    } else if (!showFileUploadList && fileListRef && fileListRef.current) {
      // @ts-ignore
      fileListRef.current.scrollIntoView();
    }
  }, [showFileUploadList])

  function performCleanup() {
    toggleDrawerOpen(false);
    setEditingCertificateData(null);
    setCertificateDate(null);
    form.resetFields();
    if (certificate && _.isEmpty(certificate.data.object.certificateDate)) {
      certificate && removeCertificate(propertyId, certificate.data.object.certificateId)
        .then(() => dispatch(getAllCertificates(propertyId)))
    } else {
      dispatch(getAllCertificates(propertyId));
    }
  }

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() { performCleanup() }
    });
  }

  function handleMenuContactsClick(e: any) {
    const { key } = e;
    key === "1"
      ? toggleAddExistingContactDrawer(true)
      : toggleContactDrawer(true);
  }

  const menuContacts = (
    <MenuStyled onClick={handleMenuContactsClick}>
      <MenuItemStyled key="1" disabled={contacts.content.length < 1}>Existing contact</MenuItemStyled>
      <MenuItemStyled key="2">New contact</MenuItemStyled>
    </MenuStyled>
  );

  return (
    <>
      <AttachmentsOnDrawer
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        alreadyAttachedFiles={certificateDetails.attachments}
        isOpen={showFileUploadList}
        toggleDrawerOpen={setShowFileUploadList}
        icon={<WorkIcon className={"works-theme-icon"}/>}
        drawerTitle={"Certificate > Add Attachment"}
      >
        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
          <AttachButtonStyled onClick={() => {
            selectedFiles.forEach(selectedFile => {
              setShowFileUploadList(false)
              certificate &&
              addRelation({
                propertyId,
                parentId: certificateDetails.certificateId,
                childId: selectedFile,
                parentType: "certificate",
                childType: "attachment",
              }).then(() => {
                refreshCertificate()
              });
            })
            setSelectedFiles(new Set());
          }}>
            Attach
          </AttachButtonStyled>
        </div>
      </AttachmentsOnDrawer>
      <ContactDrawer
        isOpen={isContactDrawerOpen}
        toggleDrawerOpen={toggleContactDrawer}
        setEditingContactData={setEditingContactData}
        contact={editingContactData}
        refreshParent={refreshCertificateContact}
        parentType={"certificate"}
      />
      {certificate && (
        <AddExistingContactDrawer
          parentId={certificateDetails.certificateId}
          isOpen={isAddExistingContactDrawerOpen}
          toggleDrawerOpen={toggleAddExistingContactDrawer}
          refreshParent={refreshCertificate}
          drawerTitle={"Certificate > Add Contact"}
          parentType={"certificate"}
        />)}
      <Drawer
        closeIcon={false}
        width={window.innerWidth > 1024 ? "680px" : "100%"}
        visible={isOpen}
        placement="right"
        onClose={() => {
          if (formFieldsChanged) {
            confirmCloseUnsaved();
            return;
          } else performCleanup()
        }}
        headerStyle={{ backgroundColor: "#ebf9ff", margin: 0, padding: "2rem 2rem" }}
        title={
          <>
            <section
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1.5rem",
              }}
            >
              <TenantIconWrapper>
                <MyTenantIcon className={"my-property-theme-icon"} />{" "}
                <span style={{ paddingRight: "0.25rem", fontSize: "0.75rem" }}>
                MY PROPERTY
              </span>
              </TenantIconWrapper>
              <ButtonsWrapper>
                <CloseButton size={"large"} onClick={() => performCleanup()}>
                  {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
                </CloseButton>
              </ButtonsWrapper>
            </section>
            <PropertyDrawerTitle>Property Certificate</PropertyDrawerTitle>
          </>
        }
      >
        <Form
          form={form}
          name="manageCertificate"
          layout="vertical"
          size={"large"}
          onFinish={handleManageCertificate}
          initialValues={initialValues()}
          onFieldsChange={() => setFormFieldsChanged(true)}
        >
          <section>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Certificate date *</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"certificateDate"}
                  key={"certificateDate"}
                  rules={[{
                    required: true,
                    message: "Certificate date is required",
                  }]}
                >
                  <DatePicker
                    name={"certificateDate"}
                    format={propertyPreferences.preferredDateFormat}
                    allowClear={true}
                    inputReadOnly={true}
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    style={{
                      width: "100%",
                      border: "1px solid #DAE0E6",
                      boxSizing: "border-box",
                      borderRadius: "6px",
                    }}
                    onChange={(value) => {
                      setFormFieldsChanged(true)
                      setCertificateDate(value)
                    }}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Certificate end of validity</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"endOfValidity"}
                  key={"endOfValidity"}
                  // rules={[{
                  //   validator(_, value) {
                  //     if (!value || moment(value).subtract(1, "year").isSameOrAfter(certificateDate)) {
                  //       return Promise.resolve();
                  //     }
                  //     return Promise.reject(new Error('End of validity date must be at least a year after certificate date'));
                  //   },
                  // }]}
                >
                  <DatePicker
                    name={"endOfValidity"}
                    format={propertyPreferences.preferredDateFormat}
                    allowClear={true}
                    inputReadOnly={true}
                    //defaultPickerValue={presetDate}
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    style={{
                      width: "100%",
                      border: "1px solid #DAE0E6",
                      boxSizing: "border-box",
                      borderRadius: "6px",
                    }}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Type of certificate</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"certificateType"}
                  key={"certificateType"}
                >
                  <AutoCompleteStyled
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    showSearch
                    allowClear={true}
                    placeholder={"Select type"}
                    bordered={false}
                    showArrow={false}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={onTypeAutocompleteSearch}
                    // @ts-ignore
                    options={certificateTypeOptions}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Total amount paid</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"amountPaid"}
                  key={"amountPaid"}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/,
                      message: f(messages.validationCostOfInventory),
                    },
                  ]}
                >
                  <InputNumberStyled
                    maxLength={10}
                    className={propertyPreferences.preferredCurrency}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Certificate number</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"certificateNumber"}
                  key={"certificateNumber"}
                >
                  <InputStyled maxLength={100} />
                </Form.Item>
              </InputWrapper>
              <InputWrapper />
            </InputsWrapper>

            {certificate && <>
              <div style={{paddingTop: "1.5rem", display: "flex", justifyContent: "space-between", marginBottom: "1rem"}}>
                <div style={{display: "flex"}}>
                  <ContactsIcon
                      style={{marginRight: "0.75rem", height: "1.5rem", marginTop: "0.2rem"}}
                      className={"works-stroke-theme-icon"}
                  />
                  <span style={{fontSize: "1.125rem", fontWeight: 600, color: "#21272B"}}>Contacts</span>
                </div>
                <Dropdown trigger={["click", "hover"]} overlay={menuContacts}>
                  <PlusButtonStyledLists>
                      <AddCircle/>
                  </PlusButtonStyledLists>
                </Dropdown>
              </div>

              {certificateDetails.contacts !== initialContactData && !_.isEmpty(certificateDetails.contacts) ?
                  <BorderSection>
                    <ContactsListComp
                      contacts={certificateDetails.contacts}
                      drawerTitle={null}
                      refreshParent={refreshCertificate}
                      parentToClose={toggleDrawerOpen}
                      parentId={certificate.data.object.certificateId}
                      parentType={"certificate"}
                    />
                  </BorderSection> : <Separator />}

                <div style={{paddingTop: "3rem", display: "flex", justifyContent: "space-between", marginBottom: "1rem"}}>
                  <div style={{ display: "flex" }}>
                    <AttachmentIcon style={{marginRight: "0.75rem", height: "1.5em", marginTop: "0.2rem"}}/>
                    <span style={{fontSize: "1.125rem", fontWeight: 600, color: "#21272B"}}>Attachments</span>
                  </div>
                  <Tooltip placement="top" title={"You can upload PDF, Image, Word, CSV and Excel files up to 20MB."}>
                    <HelpIcon />
                  </Tooltip>
                </div>

              {_.isEmpty(certificateDetails.attachments) && (
                <BorderSection>
                  <NoItemsText>No attachments added.</NoItemsText>
                </BorderSection>
              )}
              {certificateDetails.attachments !== attachmentsInitial && <UploadFile
                parentType={"certificate"}
                parentId={certificate.data.object.certificateId}
                propertyId={propertyId}
                uploadImmediately={true}
                singleAttachment={false}
                fileList={certificateDetails.attachments}
                refreshParent={refreshCertificate}
                highlightAttachment={highlightAttachment}
                setShowFileUploadList={setShowFileUploadList}
                uploadOrigin={"certificate-drawer"}
              />}
            </>}
          </section>

          <SaveButtonWrapper style={{marginTop: 0}}>
            <SaveButton style={{display: "flex", alignItems: "center"}}
              loading={saveDisabled}
              icon={<SaveIconStyled />}
              type={"primary"}
              htmlType={"submit"}
            >
              Save details
            </SaveButton>
          </SaveButtonWrapper>
        </Form>
      </Drawer>
    </>
  );
};

export const SaveIconStyled = styled(SaveIcon)`
  padding-right: 0.25rem;
  width: 22px;
  height: 22px;
`;

export const TenantIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

export const InputStyled = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
  color: rgb(107, 113, 133);
`;

export default CertificateDrawer;
