import React from "react"
import {ApplyButton, CancelButton, ModalStyled, Title} from "../maintenances/CustomFrequencyModal";
import { Subtitle } from "../../screens/PasswordReset";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector, getUserProfileSelector} from "../../selectors";
import {setUserWindowsState} from "../../../api-wrapper/user/setUserWindowsState";
import {getAllProperties} from "../../actions/properties";

type Props = {
  isModalOpen: boolean;
  toggleModal: (arg: boolean) => void;
  onboardingType: string;
  destination: string | null;
}

const SkipQuestionsModal = (props: Props) => {
  const { isModalOpen, toggleModal, onboardingType, destination } = props;
  let history = useHistory();
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const userProfile = useSelector(getUserProfileSelector);
  const properties = useSelector(getPropertiesSelector);

  const handleOk = () => {
    toggleModal(false);
  }

  const handleCancel = () => {
    const currentProperty = properties.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty)
      return;
    setUserWindowsState({
      onboardingInterestSurveyStatus: userProfile.content.onboardingInterestSurveyStatus,
      onboardingUpkeepStatus: onboardingType === "upkeep" ? "skipped" : currentProperty.onboardingUpkeepStatus,
      onboardingContentStatus: onboardingType === "contents" ? "skipped" : currentProperty.onboardingContentStatus,
      onboardingImprovementsStatus: onboardingType === "improvement" ? (currentProperty.onboardingImprovementsStatus === "completed"
        ? "completed": "skipped") : currentProperty.onboardingImprovementsStatus,
      mobileWelcomeStatus: userProfile.content.mobileWelcomeStatus,
      questionnairePage: currentProperty.questionnairePage,
      handholdingStatus: userProfile.content.handholdingStatus,
      propertyId
    }).then(() => {
      toggleModal(false);
      dispatch(getAllProperties());
      destination === "contents" && history.push("/contents")
      destination === "spaces" && history.push("/spaces");
      destination === "guidance" && history.push("/guidance")
      destination === "propertyDetails" && history.push(`/properties/${propertyId}`)
      destination === "home" && history.push("/home")
      destination === "dashboard" && history.push("/dashboard")
      destination === "upkeep" && history.push("/upkeep?tab=All");
      !destination && history.push("/")
    })
  };

  return (
    <ModalStyled
      width={500}
      title={""}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      <Title style={{marginBottom: '1rem'}}>
        {destination === "spaces"
          ? "Skip Space quick-add feature?"
          : destination === "contents"
            ? "Skip Contents quick-add feature?"
            : "Are you sure?"
        }
      </Title>
      <Subtitle style={{marginBottom: '2rem'}}>
        {destination === "spaces" || destination === "contents"
          ? "Changes made so far won’t be saved."
          : "Are you sure you want to skip the test?All of your data that you have already entered will be saved on the Property Details Page."}
      </Subtitle>

      <ButtonGroup>
        <CancelButton style={{
          width: window.innerWidth > 1024 ? "auto" : "100%",
          margin: window.innerWidth > 1024 ? 'auto' : 0
        }} onClick={() => handleCancel()}>
          {destination === "spaces" || destination === "contents"
            ? "Leave"
            : "Yes, skip test"}
        </CancelButton>
        <ApplyButton style={{
          width: window.innerWidth > 1024 ? "auto" : "100%",
          margin: window.innerWidth > 1024 ? 'auto' : 0
        }} onClick={() => handleOk()}>
          {destination === "spaces" || destination === "contents"
            ? "Stay"
            : "No, continue test"}
        </ApplyButton>
      </ButtonGroup>
    </ModalStyled>
  )
}
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 0.5rem;
  }
`

export default SkipQuestionsModal