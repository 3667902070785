import React from "react";
import styled from "styled-components";
import {List, Modal, Skeleton, Tag} from "antd";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";

import { ReactComponent as DeleteImage } from "../../../images/Delete.svg";
import { ReactComponent as Appliances } from "../../../images/Appliances.svg";
import { ReactComponent as Furniture } from "../../../images/Furniture.svg";
import { ReactComponent as Systems } from "../../../images/Systems.svg";
import { ReactComponent as ToolsAndMachinery } from "../../../images/ToolsAndMachineryNew.svg";
import { ReactComponent as Other } from "../../../images/OtherInventory.svg";
import { TGetInventoriesResponse } from "../../../api-wrapper/inventories/getInventories";
import { getAllInventories } from "../../actions/inventories";
import { TAddWorkResponse } from "../../../api-wrapper/works/addWork";
import { numberWithCommas } from "../helper";
import {Guid} from "guid-typescript";
import {emptyGuid, getCurrencySign} from "../../screens/helpers";
import {removeRelation} from "../../../api-wrapper/relation/removeRelation";
import {getAllTagsWithUsageCountByType} from "../../actions/tagsWithUsageCountByType";
import moment from "moment";
import InventoriesSublistComp from "./InventoriesSublistComp";
import {getPropertyPreferencesSelector} from "../../selectors";

type Props = {
  inventories: TGetInventoriesResponse;
  toggleInventoryDrawer: (arg: boolean) => void;
  setEditInventoryData: (arg: any) => void;
  work: TAddWorkResponse | null;
  drawerTitle: string | null;
  refreshParent: () => void;
  defaultOpenType: string | null;
  setDefaultOpenType: (arg01: string | string[]) => void;
  drawerToClose: (arg01: boolean) => void;
  selectedTags?: any;
  setInventoryToForward?: (arg01: Guid) => void;
  toggleForwardDrawer?: (arg01: boolean) => void;
  isFiltering?: boolean;
};

export const inventoryTypes = [
  "Appliances",
  "Electronics",
  "Furniture",
  "Systems",
  "ToolsAndMachinery",
  "Other",
];

export const showLifeLeft = (item: any) => {
  if (moment(item.warrantyExpiration).isAfter(moment())) {
    return <ContentLabelGreen>Warranty</ContentLabelGreen>
  } else {
    if (!_.isNull(item.applianceLifetimeYearsLeft)) {
      if (item.installedOrPurchaseDate) {
        if (item.applianceLifetimeYearsLeft >= 1) return (
          <ContentLabelGreen>
            {Math.round(item.applianceLifetimeYearsLeft)} yrs Life Left
          </ContentLabelGreen>
        )
        if (item.applianceLifetimeYearsLeft < 1 && item.applianceLifetimeYearsLeft >= 0) return (
          <ContentLabelYellow>
            {"<"}1 yrs Life Left
          </ContentLabelYellow>
        )
        if (item.applianceLifetimeYearsLeft < 0) return (
          <ContentLabelYellow>
            Past Typical Life
          </ContentLabelYellow>
        )
      } else return (
        <ContentLabelGreen>
          Avg Life: {(item.applianceLifetimeYears)} yrs
        </ContentLabelGreen>
      )
    } else if (item.warrantyExpiration) return (<ContentLabelYellow>
      Warranty expired
    </ContentLabelYellow>)
  }
}

const InventoriesListComp = (props: Props) => {
  const dispatch = useDispatch();
  const {
    inventories,
    work,
    refreshParent,
    defaultOpenType,
    setDefaultOpenType,
    toggleInventoryDrawer,
    setEditInventoryData,
    selectedTags,
    setInventoryToForward,
    toggleForwardDrawer,
    isFiltering
  } = props;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  function confirmUnlink(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will still be able to find it in the Contents page.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        const parentId = (work ? work.workId : emptyGuid);
        const parentType = (work ? "work" : null);
        parentId && parentType && removeRelation(item.propertyId, parentId, parentType, item.id, "inventory").then(() => {
          if (refreshParent) refreshParent();
          dispatch(getAllInventories(item.propertyId));
          dispatch(getAllTagsWithUsageCountByType(item.propertyId, "inventory"))
        });
      }
    });
  }

  const InventoryListItem = (item: any) => {
    return (
      <ListItemStyled
        actions={[<a><DeleteImage onClick={(e) => {
            e.stopPropagation();
            confirmUnlink(item);
          }}/></a>]}
        style={{padding: "1rem 2rem 1rem 1.25rem"}}
        key={item.id}
        onClick={() => {
          toggleInventoryDrawer(true);
          setEditInventoryData({ data: item });
        }}>
        <Skeleton loading={false} title={false} active>
          <List.Item.Meta
            title={item.name}
            description={
              <div style={{display: "flex", flexDirection: "column"}}>
                <span>
                {item.brand && item.brand}
                {item.brand && item.model && " + "}
                {item.model && item.model}
                </span>
                {window.innerWidth < 1024 && <div style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  rowGap: "0.5rem",
                  marginTop: "0.75rem"}}>
                  {item.insuredItems && <InsuredTag>Insured</InsuredTag>}
                  {item.purchaseCost !== null && (
                    <TagStyled style={{marginRight: "0.75rem"}}>
                      {getCurrencySign(propertyPreferences.preferredCurrency)}
                      {numberWithCommas(item.purchaseCost)}
                    </TagStyled>
                  )}
                  {showLifeLeft(item)}
                </div>}
              </div>
            }
          />
        </Skeleton>
        {window.innerWidth > 1024 && <div style={{display: "flex", alignItems: "center"}}>
          {item.insuredItems && <InsuredTag>Insured</InsuredTag>}
          {item.purchaseCost !== null && (
            <TagStyled style={{marginRight: "0.75rem"}}>
              {getCurrencySign(propertyPreferences.preferredCurrency)}
              {numberWithCommas(item.purchaseCost)}
            </TagStyled>
          )}
          {showLifeLeft(item)}
        </div>}
      </ListItemStyled>
    );
  };

  return (
    <>
      {work && (
        <List
          style={{ width: "100%", alignItems: "center", borderBottom: "1px" }}
          itemLayout="horizontal"
          dataSource={inventories}
          renderItem={(item) => InventoryListItem(item)}
        />
      )}
      {!work && inventoryTypes.map((type) => inventories.filter((inventory) => inventory.inventoryType === type).length > 0 && (
        <InventoriesListDesktop>
          <InventoriesSublistComp
            type={type}
            inventories={inventories.filter((i) => i.inventoryType === type)}
            isFiltering={isFiltering}
            defaultOpenType={defaultOpenType}
            setDefaultOpenType={setDefaultOpenType}
            refreshParent={refreshParent}
            setEditInventoryData={setEditInventoryData}
            toggleInventoryDrawer={toggleInventoryDrawer}
            toggleForwardDrawer={toggleForwardDrawer}
            setInventoryToForward={setInventoryToForward}
            selectedTags={selectedTags}
          />
        </InventoriesListDesktop>
        ))}
    </>
  );
};

export const ListItemStyled = styled(List.Item)`
  border-color: #f5f6fa;
  @media (max-width: 1024px) {
    width: 100%;

    .ant-list-item-action > li {
      width: 100%;
    }
  }

  .ant-list-item-meta-title {
    font-size: 1rem;
    font-weight: 600;
    color: #21272b;
  }
  
  .ant-list-item-meta-description {
    color: #6b7185;
    font-weight: 400;
    margin-top: 0.4rem;
  }
`;

const InventoriesListDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  background-color: #fff;
  border-radius: 0.625rem;
  border-color: #dae0e6;

`;

const TagStyled = styled(Tag)`
  display: flex;
  align-items: center;
  background-color: #eff1f5;
  color: #21272b;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 0.25rem;
  padding-bottom: 0.3rem;
  margin: 0;
  border-radius: 0.25rem;
  border: 0;
  font-weight: 700;
  font-size: 1.1rem;
`;

const InsuredTag = styled(Tag)`
  display: flex;
  align-items: center;
  background-color: #EFFDF7;
  color: #007F6E;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 0.25rem;
  padding-bottom: 0.3rem;
  margin: 0;
  border-radius: 0.25rem;
  border: 0;
  font-weight: 500;
  font-size: 0.75rem;
  margin-right: 0.75rem;
  text-transform: uppercase;
`;

const ContentLabel = styled.span`
  border: 1px solid #9CA4AE;
  border-radius: 4px;
  padding: 0.125rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #9CA4AE;
  flex-grow: 0;
`

const ContentLabelGreen = styled(ContentLabel)`
  border-color: #00B74F;
  color: #00B74F;
  background: #EFFDF7;
`

const ContentLabelYellow = styled(ContentLabel)`
  border-color: #E8B500;
  color: #E8B500;
  background: #FEF9EA;
`

export default InventoriesListComp;
