import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";

import messages from "./messages";

const LoadingPage = () => {
  const { formatMessage: f } = useIntl();

  return (
    <LoadingPageWrapper>
      <LogoContainer>
        <div style={{width: "150px"}}>
          <img src={"/images/LivletLogoNew.png"} style={{maxWidth: "100%"}} alt={""} />
        </div>
        <SettingUpProfile>{f(messages.settingUpProfile)}</SettingUpProfile>
      </LogoContainer>
    </LoadingPageWrapper>
  );
};

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  align-items: center;
  width: 20rem;
  height: 10rem;
  text-align: center;
  margin-top: 5rem;
`;

const SettingUpProfile = styled.span`
  font-weight: 400;
  color: #6b7185;
  padding-left: 1rem;
`;

const LoadingPageWrapper = styled.main`
  width: 100%;
  position: absolute;
  height: 100%;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default LoadingPage;
