import React, {useState, useEffect} from "react";
import {Dropdown, Input, Radio, Tooltip} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Header } from "antd/es/layout/layout";
import {useHistory, useLocation} from "react-router-dom";
import _ from "lodash";
import {signOut} from "../../actions/logIn";
import {authSelector, getPropertiesSelector, getPropertyIdSelector} from "../../selectors";

import { ReactComponent as NotesIcon } from "../../../images/Notes.svg";
import { ReactComponent as SearchIcon } from "../../../images/Search.svg";
import { ReactComponent as Cross } from "../../../images/Cross.svg";

import { ReactComponent as QuickAddCircle } from "../../../images/menu/QuickAddCircle.svg";
import { ReactComponent as QuickAddContact } from "../../../images/menu/QuickAddContact.svg";
import { ReactComponent as QuickAddNote } from "../../../images/menu/QuickAddNote.svg";
import { ReactComponent as QuickAddReminder } from "../../../images/menu/QuickAddReminder.svg";
import { ReactComponent as QuickAddExpense } from "../../../images/menu/QuickAddExpense.svg";

import { ReactComponent as AccountCaretDown } from "../../../images/menu/AccountCaretDown.svg";
import { ReactComponent as AccountLogout } from "../../../images/menu/AccountLogout.svg";
import { ReactComponent as AccountMenu } from "../../../images/menu/AccountMenu.svg";
import { ReactComponent as AccountProfile } from "../../../images/menu/AccountProfile.svg";
import { ReactComponent as AccountNews } from "../../../images/menu/AccountNews.svg";
import { ReactComponent as AccountTags } from "../../../images/menu/AccountTags.svg";
import {ReactComponent as AccountWishlist} from "../../../images/menu/AccountWishlist.svg";

import { ReactComponent as QuestionMobileIcon } from "../../../images/menu/QuestionMobile.svg";
import { ReactComponent as SearchMobileIcon } from "../../../images/menu/SearchMobileIcon.svg";
import { ReactComponent as MobileMenuIcon } from "../../../images/menu/MobileMenuIcon.svg";
import { ReactComponent as CloseMobileMenuIcon } from "../../../images/menu/CloseMobileMenuIcon.svg";
import { ReactComponent as HeaderHomeIcon } from "../../../images/menu/HeaderHomeIcon.svg";
import { ReactComponent as HeaderBarArrow } from "../../../images/menu/HeaderBarArrow.svg";
import { ReactComponent as HeaderSearchIcon } from "../../../images/menu/HeaderSearchIcon.svg";
import { ReactComponent as HeaderCaretDown } from "../../../images/menu/HeaderCaretDown.svg";

import ExpenseDrawer from "../expenses/ExpenseDrawer";
import ContactDrawer from "../contacts/ContactDrawer";
import moment from "moment";
import { getAllProperties } from "../../actions/properties";
import NoteDrawer from "../notes/NoteDrawer";
import { getAllNotesByPropertyId } from "../../actions/notesByPropertyId";
import {emptyGuid} from "../../screens/helpers";
import UploadFile from "../attachments/UploadFile";
import {getAllAttachments} from "../../actions/attachments";
import {getCurrentUserProfile} from "../../actions/getUserProfile";
import {addExpense} from "../../../api-wrapper/expenses/addExpense";
import {getAllExpenses, getAllExpenseTypes} from "../../actions/expenses";
import {getAllContacts} from "../../actions/contacts";
import {addContact} from "../../../api-wrapper/contacts/addContact";
import ReportProblem from "./ReportProblem";
import {getCurrentPropertyId, setCurrentPropertyId} from "../../../localStorage";
import {ReactComponent as SearchSuggestionArrow} from "../../../images/SearchSuggestionArrow.svg";
import MenuMobile from "./MenuMobile";
import UploadInvoice from "../attachments/UploadInvoice";
import BatchProcessingModal from "../attachments/BatchProcessingModal";
import {search} from "../../../api-wrapper/search/search"
import {getAllUpcomingTasks} from "../../actions/upcomingTasks";
import {getAllCalendarTasks} from "../../actions/calendarTasks";
import {setPropertyId} from "../../actions/propertyId";
import PropertyDrawer from "../properties/PropertyDrawer";
import AddEventDrawer from "../planner/AddEventDrawer";
import UpdateTaskDrawer from "../planner/UpdateTaskDrawer";
import WorkDrawer from "../works/WorkDrawer";
import {getAllSubscriptionUsers} from "../../actions/subscriptionUsers";
import {TooltipStyle} from "../../screens/components";
import {addNote} from "../../../api-wrapper/notes/addNote";
import {initialNoteData} from "../../screens/Notes";
import {setPropertyPreferences} from "../../actions/propertyPreferences";
import {getMyStorageUsage} from "../../actions/storageUsage";
import Ellipsis from "ant-design-pro/lib/Ellipsis";

function HeaderNav() {
  const history = useHistory();
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);
  const auth = useSelector(authSelector);

  useEffect(() => {
    dispatch(getAllProperties());
    dispatch(getCurrentUserProfile());
    dispatch(getMyStorageUsage());
    auth.tenantId !== 0 && dispatch(getAllSubscriptionUsers(auth.tenantId));
  }, [dispatch, auth])

  useEffect(() => {
    if (_.isEmpty(properties) || _.isEqual(properties[0].propertyId, emptyGuid.toJSON())  || _.isEqual(properties[0].propertyId, emptyGuid) || _.isEqual(propertyId, emptyGuid))
      return;
    dispatch(getAllExpenseTypes(propertyId));
    dispatch(getAllContacts(propertyId));
    const currentProperty = properties.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty)
      return;
    if (currentProperty!.nickname)
      setDisplayedPropertyName(currentProperty!.nickname);
    else if (currentProperty!.address2)
      setDisplayedPropertyName(
        `${currentProperty!.address}, ${currentProperty!.address2}`
      );
    else setDisplayedPropertyName(currentProperty!.address)
    dispatch(setPropertyPreferences({
      preferredCurrency: currentProperty.preferredCurrency,
      preferredDateFormat: currentProperty.preferredDateFormat,
      taxYearMonth: currentProperty.taxYearMonth,
      taxYearDate: currentProperty.taxYearDate
    }));
  }, [properties, propertyId]);

  const currentPropertyId = getCurrentPropertyId()

  useEffect(() => {
    if (_.isEmpty(properties) || _.isEqual(properties[0].propertyId, emptyGuid.toJSON())  || _.isEqual(properties[0].propertyId, emptyGuid))
      return;

    if (_.isObject(propertyId) && (_.isEqual(propertyId, emptyGuid))) {
      if (currentPropertyId && properties.filter(property => property.propertyId.toString() === currentPropertyId).length > 0) {
        // @ts-ignore
        dispatch(setPropertyId(currentPropertyId))
        let property = properties.filter(property => property.propertyId.toString() === currentPropertyId)[0]
        dispatch(setPropertyPreferences({
          preferredCurrency: property.preferredCurrency,
          preferredDateFormat: property.preferredDateFormat,
          taxYearMonth: property.taxYearMonth,
          taxYearDate: property.taxYearDate
        }))
      } else {
        setCurrentPropertyId(properties[0].propertyId.toString())
        dispatch(setPropertyId(properties[0].propertyId))
        dispatch(setPropertyPreferences({
          preferredCurrency: properties[0].preferredCurrency,
          preferredDateFormat: properties[0].preferredDateFormat,
          taxYearMonth: properties[0].taxYearMonth,
          taxYearDate: properties[0].taxYearDate
        }));
      }
    }
  }, [properties, currentPropertyId]);

  const [isNoteDrawerOpen, toggleNoteDrawer] = useState(false);
  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [isPropertyDrawerOpen, togglePropertyDrawer] = useState(false);
  const [isEventDrawerOpen, toggleEventDrawer]  = useState(false);
  const [isTaskDrawerOpen, toggleTaskDrawer]  = useState(false);
  const [isSearchModeOn, toggleSearchMode] = useState(false);
  const [displayedPropertyName, setDisplayedPropertyName] = useState("");

  const [editingNoteData, setEditingNoteData] = useState<any>({data: initialNoteData});
  const [editingExpenseData, setEditingExpenseData] = useState<any>(null);
  const [editingContactData, setEditingContactData] = useState<any>(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [isBatchProcessing, toggleBatchProcessing] = useState(false)
  const [selectedEventType, setSelectedEventType] = useState("");
  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editingWorkData, setEditingWorkData] = useState<any>(null);

  const [visible, setVisible] = useState(false);
  const [isPropertySelectionOpen, togglePropertySelection] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState<any>([])
  const [areSuggestionsLoading, toggleSuggestionsLoading] = useState(false)
  const [reportProblemModal, toggleReportProblemModal] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const plannerView = useQuery().get("plannerView");
  const searchParam = useQuery().get("search");

  useEffect(() => {
    setSearchQuery(searchParam??"");
  }, [searchParam]);

  const addNewExpense = () => {
    addExpense({
      propertyId,
      isConfirmed: true
    }).then((res) => {
      setEditingExpenseData(res);
      dispatch(getAllExpenses(propertyId));
      toggleExpenseDrawer(true);
    });
  };

  const addNewContact = () => {
    addContact({
      propertyId,
      request: {},
      contactImage: null
    }).then((res) => {
      setEditingContactData(res);
      dispatch(getAllContacts(propertyId));
      toggleContactDrawer(true);
    });
  }

  const addNewNote = () => {
    addNote({
      propertyId,
    }).then((res) => {
      setEditingNoteData({data: res.data});
      dispatch(getAllNotesByPropertyId(propertyId));
      toggleNoteDrawer(true);
    });
  }

  const performSearch = () => {
    if (!_.isEmpty(searchQuery)) {
      history.push("/search?search=" + searchQuery);
      setSearchQuery("");
      setSearchSuggestions([]);
    }
  };

  const performSearchWithQuery = (query: string) => {
    history.push("/search?search=" + query);
    setSearchQuery("");
    setSearchSuggestions([]);
  };

  if (_.isEmpty(properties) || _.isEqual(properties[0].propertyId, emptyGuid.toJSON())) {
    return <></>;
  }

  const quickAddMenu = () => (<QuickAddOptions>
    <QuickAddHeader>Quick Add</QuickAddHeader>
    <UploadFile
      fileList={[]}
      refreshParent={() => dispatch(getAllAttachments(propertyId))}
      singleAttachment={false}
      parentId={null}
      parentType={null}
      propertyId={propertyId}
      uploadImmediately={false}
      isGlobalUpload={true}
      uploadOrigin={"quick-add"}
    />
    <UploadInvoice
      fileList={[]}
      allowBatchUpload={true}
      uploadOrigin={"quick-add"}
      setExpenseData={setEditingExpenseData}
      setInvoiceData={setInvoiceData}
      toggleExpenseDrawer={toggleExpenseDrawer}
      toggleBatchProcessing={toggleBatchProcessing}
    />
    <QuickAddOption onClick={() => toggleEventDrawer(true)}>
      <QuickAddReminder /> Add an event
    </QuickAddOption>
    <QuickAddOption onClick={() => addNewNote()}>
      <QuickAddNote/> Write a note
    </QuickAddOption>
    <QuickAddOption onClick={() => addNewContact()}>
      <QuickAddContact/> Add a contact
    </QuickAddOption>
    <QuickAddOption onClick={() => addNewExpense()}>
      <span style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "1.625rem",
        height: "1.625rem",
        background: "white",
        borderRadius: "100%"
      }}>
        <QuickAddExpense/></span>
       Add an expense
    </QuickAddOption>
  </QuickAddOptions>)

  const accountOptionsMenu = () => (<AccountOptions>
    <AccountOption className={"account-item account-item-fill"} onClick={() => history.push("/settings")}>
      <AccountProfile /> Account details
    </AccountOption>
    <AccountOption className={"account-item account-item-stroke"} onClick={() => history.push("/news")}>
      <AccountNews style={{display: "flex", flexShrink: 0}} /> My news
    </AccountOption>
    <AccountOption className={"account-item account-item-stroke"} onClick={() => history.push("/tags")}>
      <AccountTags /> My tags
    </AccountOption>
    <AccountOption className={"account-item account-item-stroke"} onClick={() => history.push("/guidance/wishlist")}>
      <AccountWishlist /> My wishlist
    </AccountOption>
    <LogoutButton onClick={() => dispatch(signOut())}>
      <AccountLogout /> Log Out
    </LogoutButton>
  </AccountOptions>)

  const searchSuggestionDropdown = (
    <SearchSuggestionWrapper>
      {searchQuery.length > 0 && searchSuggestions.map((suggestion: any) =>
        <SearchSuggestionItem onClick={() => performSearchWithQuery(suggestion)}>
          <div style={{whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"}}>
            {suggestion}
          </div>
          <SearchSuggestionArrow />
        </SearchSuggestionItem>)}
      {searchQuery.length === 0 &&
      <SearchSuggestionItem>Waiting for a query...</SearchSuggestionItem>}
      {searchQuery.length > 0 && searchSuggestions.length === 0 && areSuggestionsLoading &&
      <SearchSuggestionItem>Loading suggestions...</SearchSuggestionItem>}
      {searchQuery.length !== 0 && searchSuggestions.length === 0 && !areSuggestionsLoading &&
      <SearchSuggestionItem>No suggestions matching your query</SearchSuggestionItem>}
    </SearchSuggestionWrapper>
  )

  const propertySelectionDropdown = (
    <SearchSuggestionWrapper>
      {properties.map((property: any) =>
        <SearchSuggestionItem onClick={() => {
          //
          dispatch(setPropertyId(property.propertyId));
          togglePropertySelection(false);
          setCurrentPropertyId(property.propertyId);
        }}>
          <div style={{whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",}}>
            {(property.nickname && property.nickname.length > 0) ? property.nickname : property.address}
          </div>
          <RadioStyled value={property.propertyId} checked={property.propertyId === propertyId} />
        </SearchSuggestionItem>)}
      {properties.length < 5 && <SearchSuggestionItem  onClick={() => {togglePropertyDrawer(true); togglePropertySelection(false);}}>
        <div style={{whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",}}>
          Add property
        </div>
        <PlusCircleOutlined style={{marginRight: "0.5rem"}}/>
      </SearchSuggestionItem>}
    </SearchSuggestionWrapper>
  )

  const searchDropdown = () => (
    <Dropdown overlay={searchSuggestionDropdown}>
      <InputStyled
        autoFocus
        className="header-search"
        placeholder={"Search..."}
        value={searchQuery}
        onChange={(val) => getSuggestionsForQuery(val.target.value)}
        onPressEnter={performSearch}
        onMouseOver={() => {_.isEmpty(searchQuery) && setSearchSuggestions([])} }
        prefix={<SearchIcon onClick={performSearch}/>}
        suffix={<Cross onClick={() => {
             searchQuery && setSearchQuery("")
             searchQuery && setSearchSuggestions([])
             !searchQuery && toggleSearchMode(false)
           }}/>}
      />
    </Dropdown>
  )

  const propertyDropdown = () => (
    <Dropdown
      overlay={propertySelectionDropdown}
      trigger={["click"]}
      onVisibleChange={() => togglePropertySelection(!isPropertySelectionOpen)}>
      <PropertyBarWrapper>
        <Tooltip title={"Property details"} overlayClassName={"handholding"} placement={"bottom"}
                 overlayInnerStyle={TooltipStyle} mouseEnterDelay={0.5}>
          <PropertyDetailsZone className={isPropertySelectionOpen ? "active" : ""}
            onClick={(e) => {
            e.stopPropagation();
            history.push(`/properties/${propertyId}`)
          }}>
            <div className={"accent"}>
              <HeaderHomeIcon className={"fill"} />
              {window.innerWidth > 1280 && "Property details"}
              {window.innerWidth > 1280 && <div><HeaderBarArrow className={"fill"} /></div>}
            </div>
          </PropertyDetailsZone>
        </Tooltip>
        <Tooltip title={"Select/Add property"} overlayClassName={"handholding"} placement={"bottom"}
                 overlayInnerStyle={TooltipStyle} mouseEnterDelay={0.5}>
          <PropertySelectionZone className={isPropertySelectionOpen ? "active" : ""}
            onClick={() => togglePropertySelection(!isPropertySelectionOpen)}>
            <div className={"address"}>
              <Ellipsis lines={1}>{displayedPropertyName}</Ellipsis>
            </div>
            <div className={"accent"}>
              <HeaderCaretDown className={"stroke"} />
            </div>
          </PropertySelectionZone>
        </Tooltip>
      </PropertyBarWrapper>
    </Dropdown>
  )

  const getSuggestionsForQuery = (query: string) => {
    setSearchQuery(query);
    if (_.isEmpty(query)) {
      setSearchSuggestions([]);
      return;
    }
    toggleSuggestionsLoading(true);
    search({query, filter: {dataTypes: []}, page: 0, size: 10}).then((res: any) => {
      let results: any = []
      res.data.foundItemList.map((item: any) => {
        results.push(item.content.name);
        // if (item.content.name.toLowerCase().includes(query)) {
        //   results.push(item.content.name.toLowerCase())
        // }
        // if (item.highlightFields.textInternal) {
        //   item.highlightFields.textInternal.map((str: string) => {
        //     let match = str.toLowerCase().match(/<em>(.*?)<\/em>/g)
        //     if (match) {
        //       let result = match.map(function (val) {return val.replace(/<\/?em>/g, '')})
        //       results.push(...result)
        //     }
        //   })
        // }
      })
      let uniqueResults = _.uniq([query, ...results])
      toggleSuggestionsLoading(false)
      setSearchSuggestions(uniqueResults.slice(0, 5))
    }).catch(() => {/* do nothing, it's caused by cancelling requests when user types fast and we need to cancel some*/})
  }

  const refreshCalendarAndTasks = () => {
    let periods = plannerView ? (plannerView === "double" ? 8 : 12) : 7;
    dispatch(getAllUpcomingTasks({
      propertyId: propertyId,
      startDate: null,
      endDate: null,
      firstInSeries: true
    }))
    dispatch(getAllCalendarTasks(propertyId, moment().startOf("month").format("YYYY-MM-DD"), true, periods))
  }

  return (
    <CustomHeader className={history.location.pathname.includes("/questions") ? "header-questions" : ""}>
      <BatchProcessingModal isBatchProcessing={isBatchProcessing} />
      <ReportProblem
        isModalOpen={reportProblemModal}
        toggleModal={toggleReportProblemModal} />
      <WorkDrawer
        isOpen={isWorkDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        work={editingWorkData}
        setEditingWorkData={setEditingWorkData}
        refreshParent={() => refreshCalendarAndTasks()}
      />
      <NoteDrawer
        currentType={"note"}
        setEditWorkData={setEditingNoteData}
        data={ {workNotes: {...editingNoteData.data}}}
        isOpen={isNoteDrawerOpen}
        toggleDrawerOpen={toggleNoteDrawer}
        refreshParent={() => {}}
        icon={<NotesIcon className={"search-stroke-theme-icon"} />}
        isGlobalNote={true}
        toggleTaskDrawer={toggleTaskDrawer}
        toggleExpenseDrawer={() => {}}
        setEditExpenseData={() => {}}
        toggleInventoryDrawer={() => {}}
        setEditInventoryData={() => {}}
        toggleContactDrawer={() => {}}
        setEditContactData={() => {}}
        setEditTaskData={() => {}}
        toggleLinkDrawerOpen={() => {}}
        setEditSpaceId={() => {}}
        toggleSpaceDrawer={() => {}}
        toggleViewWorkDrawer={() => {}}
        setViewWorkId={() => {}}
        setAttachmentId={() => {}}
        toggleViewFileDrawer={() => {}}
        setPresetLinkType={() => {}}
      />
      <ExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        toggleDrawerOpen={toggleExpenseDrawer}
        setEditingExpenseData={setEditingExpenseData}
        expense={editingExpenseData}
        invoiceData={invoiceData}
        refreshParent={() => refreshCalendarAndTasks()}
      />
      <ContactDrawer
        isOpen={isContactDrawerOpen}
        toggleDrawerOpen={toggleContactDrawer}
        // @ts-ignore
        setEditingContactData={setEditingContactData}
        contact={editingContactData}
      />
      <PropertyDrawer
        toggleDrawerOpen={togglePropertyDrawer}
        isOpen={isPropertyDrawerOpen}
        editedPropertyId={null}
      />
      <AddEventDrawer
        isOpen={isEventDrawerOpen}
        toggleDrawer={toggleEventDrawer}
        setSelectedType={setSelectedEventType}
        toggleWorkDrawer={toggleWorkDrawer}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditingWorkData={setEditingWorkData}
      />
      <UpdateTaskDrawer
        isOpen={isTaskDrawerOpen}
        toggleDrawer={toggleTaskDrawer}
        task={null}
        setEditingTaskData={() => {}}
        taskType={selectedEventType}
        setTaskType={setSelectedEventType}
        refreshParent={() => refreshCalendarAndTasks()}
      />
      <HeaderWrapper>
        {isSearchModeOn ? searchDropdown() : propertyDropdown()}
        <Tooltip title={"Search"} overlayClassName={"handholding"} placement={"bottom"}
             overlayInnerStyle={TooltipStyle} mouseEnterDelay={0.5}>
          <SearchIconWrapper onClick={() => toggleSearchMode(true)}>
            <HeaderSearchIcon />
          </SearchIconWrapper>
        </Tooltip>
        <Dropdown overlay={quickAddMenu} trigger={["click"]} placement={"bottomRight"}>
          <QuickAddButton>Quick add <QuickAddCircle /></QuickAddButton>
        </Dropdown>
        <Dropdown trigger={["click"]} overlay={accountOptionsMenu} placement={"bottomRight"}>
          <MyAccountButton><AccountMenu /> My account <AccountCaretDown /></MyAccountButton>
        </Dropdown>
      </HeaderWrapper>
      <HeaderWrapperMobile>
        <div style={{width: "104px"}} onClick={() => history.push("/home")}>
          <img src={"/images/LivletLogoNew.png"} style={{maxWidth: "100%"}} alt={""} />
        </div>
        <MobileActionsWrapper>
          <MobileActionsItem>
            <QuestionMobileIcon
              onClick={() => toggleReportProblemModal(true)}
            />
          </MobileActionsItem>
          <MobileActionsItem>
            <SearchMobileIcon
              className={isSearchModeOn ? "icon-green" : ""}
              onClick={() => toggleSearchMode(!isSearchModeOn)}/>
          </MobileActionsItem>
          <MobileActionsLastItem>
            {visible ? <CloseMobileMenuIcon onClick={() => setVisible(false)}/>
              : <MobileMenuIcon onClick={() => setVisible(true)}/>}
          </MobileActionsLastItem>
        </MobileActionsWrapper>
      </HeaderWrapperMobile>
      {isSearchModeOn ? <SearchPropertyWrapperMobile>
        {searchDropdown()}
      </SearchPropertyWrapperMobile> : <SearchPropertyWrapperMobile>
        {propertyDropdown()}
        <Dropdown overlay={quickAddMenu} trigger={["click"]} placement={"bottomRight"}>
          <QuickAddButtonMobile>
            <QuickAddCircle/>
          </QuickAddButtonMobile>
        </Dropdown>
      </SearchPropertyWrapperMobile>}
      <MenuMobile visible={visible} setVisible={setVisible} />
    </CustomHeader>
  );
}

const HeaderWrapperMobile = styled.div`
  @media (max-width: 1024px) {
    z-index: 1000;
    position: absolute;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    height: 3.5rem;
    width: 100%;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

const SearchPropertyWrapperMobile = styled.div`
  @media (max-width: 1024px) {
    display: flex;
    width: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
  }
  
  @media (min-width: 1024px) {
    display: none;
  }
`

const MobileActionsWrapper = styled.div`
  display: flex;
`

const MobileActionsItem = styled.div`
  display: flex;
  padding: 0 0.5rem;
  border-right: 1px solid #EFF1F5;
  height: 1.5rem;
`

const MobileActionsLastItem = styled.div`
  display: flex;
  padding-left: 0.5rem;
  height: 1.5rem;
`

const CustomHeader = styled(Header)`
  max-width: 1100px;
  margin: 0 4.2rem 1.5rem 4.2rem;
  background-color: #F0F2F5;
  color: #21272B;
  padding: 0;
  height: auto;
  
  @media (max-width: 1024px) {
    margin: 0;
    margin-bottom: 0.5rem;
  }
`;

const InputStyled = styled(Input)`
  width: 100%;
  border-radius: 6px;
  box-sizing: border-box;
  height: 3.25rem!important;
  border: 1px solid #DAE0E6;

  & > .ant-input {
    color: #6B7185;
    padding-left: 0.75rem !important;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @media (max-width: 1024px) {
    height: 3rem!important;
    padding-top: 0;
    padding-bottom: 0;
    
    & > .ant-input { padding: 0!important; }
  }

  svg path {
    fill: #6B7185;
  }
`;

export const RadioStyled = styled(Radio)`
  .ant-radio-checked .ant-radio-inner{
    border-color: #8E8E89!important;
  }
  
  .ant-radio-checked .ant-radio-inner:after{
    background-color: #67C18B;
  }
  
  .ant-radio-disabled .ant-radio-inner:after {
    background-color: #8E8E89!important;
  }
  
  .ant-radio:hover .ant-radio-inner {
    border-color: #8E8E89;
  }
`

const QuickAddButtonMobile = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }

  background: #67C18B;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.25rem;
  border-radius: 8px;
  flex-shrink: 0;
  margin-left: 0.5rem;
`

export const FAQSubtitle = styled.p`
  max-width: 40.75rem;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #6b7185;
`

const QuickAddButton = styled.div`
  cursor: pointer;
  height: 3.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.625rem;
  padding: 0.75rem 1rem;
  background: #67C18B;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  transition: background .5s ease;
  flex-shrink: 0;
  
  &:hover {
    background: #45655E; 
    transition: background .5s ease;
  }
`

const QuickAddOptions = styled.div`
  background: white;
  border-radius: 6px 0px 6px 6px;
  padding: 0.75rem 0.25rem 0.25rem;
  width: 20.313rem;
`

const AccountOptions = styled.div`
  background: white;
  border-radius: 8px;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  width: 18.5rem;
`

const QuickAddHeader = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  color: #000000;
  margin-bottom: 0.25rem;
`

export const QuickAddOption = styled.div`
  width: 19.813rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  background: #F5F6FA;
  border-radius: 4px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #45655E;
  margin-top: 0.25rem;
  column-gap: 0.75rem;
  cursor: pointer;
`

const AccountOption = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #45655E;
  margin-top: 0.25rem;
  column-gap: 0.75rem;
  cursor: pointer;
`

const MyAccountButton = styled.div`
  cursor: pointer;
  height: 3.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.625rem;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  color: #45655E;
  background: white;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  transition: background .5s ease;
  flex-shrink: 0;
  
  &:hover {
    background: #DAE0E6;
    transition: background .5s ease;
  }
`

export const LogoutButton = styled.div`
  cursor: pointer;
  margin: 1rem 1.5rem 0 1.5rem;
  padding: 0.625rem 1rem;
  border-radius: 6px;
  border: 1px solid #dae0e6;
  font-size: 1rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  color: #666F7A;
  column-gap: 0.625rem;
`

const SearchSuggestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
  background: #FFFFFF;
  border: 1px solid #DAE0E6;
  box-sizing: border-box;
  box-shadow: 0px 1px 5px rgba(30, 32, 37, 0.05), 0px 10px 24px rgba(26, 28, 31, 0.1);
  border-radius: 0px 0px 10px 10px;
`

const SearchSuggestionItem = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #45655E;
  padding: 1rem;
  border-bottom: 1px solid #EFF1F5;
  transition: all .5s ease;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  :last-child {
    border-bottom: 1px solid white;
  }
  
  :hover {
    color: #21272B;
    background: #F5F6FA;
    border-radius: 8px;
    border-bottom: 1px solid white;
    transition: background .5s ease;
  }
  
  :hover svg path {
    stroke: #21272B;
    transition: stroke .5s ease;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1.5rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  column-gap: 1rem;
  
  @media (max-width: 1024px) {
    display: none;
  }
`

const SearchIconWrapper = styled.div`
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  background: #f0f2f5;
  border: 1px solid #f0f2f5;
  transition: .5s all ease;
  border-radius: 4px;
  
  :hover {
    background: #EAF3FC;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  :hover svg path { stroke: #009966; }
`

const PropertyBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const PropertyDetailsZone = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0.5rem;
  padding-right: 0;
  cursor: pointer;
  height: 3.25rem;

  background: #FFFFFF;
  border: 1px solid #DAE0E6;
  border-right: none;
  border-radius: 8px 0 0 8px;
  
  &.active { background: #F5F6FA; }
  
  .accent {
    height: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid white;
    border-right: 1px solid #EDF0F0;
    transition: .5s all ease;
    padding: 0.5rem;
    
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #8E8E89;
  }
  
  &.active .accent { 
    border: 1px solid #F5F6FA;
    border-right: 1px solid #EDF0F0;
  }
  
  :hover .accent {
    height: 2.25rem;
    background: #EAF3FC;
    border: 1px solid rgba(0, 0, 0, 0.1)!important;
    border-radius: 4px;
    color: #009966;
  }
  
  :hover .fill path { fill: #009966; }
  :hover .stroke path { stroke: #009966; }
  
  @media (max-width: 1024px) {
    height: 3rem;
  }
`

const PropertySelectionZone = styled(PropertyDetailsZone)`
  padding: 0.5rem;
  border: 1px solid #DAE0E6;
  border-left: none;
  border-radius: 0 8px 8px 0;
  flex-shrink: 1;
  justify-content: space-between;
  width: 100%;
  
  .accent {
    border: 1px solid white;
    border-left: 1px solid #EDF0F0;
  }
  
  &.active .accent { 
    border: 1px solid #F5F6FA;
    border-left: 1px solid #EDF0F0;
  }
  
  .address {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #45655E;
    padding-right: 0.5rem;
  }
  
  :hover .address { color: #009966; }
  
  // &.active svg path { 
  //   transform: rotate(180deg);
  //   transform-origin: center;
  // }
`


export default HeaderNav;
