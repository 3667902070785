import React, {useEffect, useState} from "react";
import {CircleIcon, DrawerHeader, DrawerStyled} from "../../screens/components";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";
import {ReactComponent as ReturnArrow} from "../../../images/spaces/ReturnArrow.svg";
import {
  ContainerItemWrapper,
  ContainerTitle,
} from "../spaces/AddSpaceOptions";
import {ReactComponent as WorksIcon} from "../../../images/spaces/SpaceLinkWorks.svg";
import {Legalese, ServiceUnavailable, TradeDetails, TradeDisclaimer, TradeItem, TradesContainer} from "./TradeDrawer";
import {useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import _ from "lodash";
import {emptyGuid} from "../../screens/helpers";

type Props = {
  isDrawerOpen: boolean;
  toggleDrawer: (arg: boolean) => void;
}

export const BoilerDrawerContent = () => (
  <TradesContainer className={"open stretched"}>
    <ContainerTitle><WorksIcon className={"yellow-icon"} style={{flexShrink: 0}}/>Gas certificate with boiler service</ContainerTitle>
    <ContainerItemWrapper>
      <TradeItem>
        <TradeDetails>
          <span>Get a quote by calling <span className={"accent"}>0117 313 3248*</span>.</span>
          <span className={"disclaimer"}>*Standard network charges may apply.</span>
        </TradeDetails>
      </TradeItem>
    </ContainerItemWrapper>
    <TradeDisclaimer style={{marginTop: 0}}>
      This service is provided by <span className={"company-name"}>Propertyfix Builders Ltd</span>, a third-party service provider.
    </TradeDisclaimer>
  </TradesContainer>
)

const BoilerGasCertificateDrawer = (props: Props) => {
  const {isDrawerOpen, toggleDrawer} = props;
  const [propertyLocation, setPropertyLocation] = useState<any>(null);
  const [propertyZipCode, setPropertyZipCode] = useState<any>(null);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find((property) => property.propertyId === propertyId);
    if (!currentProperty)
      return;
    setPropertyLocation(`${currentProperty.country}`)
    setPropertyZipCode(currentProperty.zip)
  }, [propertyId])

  const handleCancel = () => {
    toggleDrawer(false)
  }

  return <DrawerStyled
    push={{ distance: "32px" }}
    closeIcon={false}
    width={window.innerWidth > 1024 ? "680px" : "100%"}
    height={window.innerWidth > 768 ? "100%" : "85%"}
    placement={window.innerWidth > 768 ? "right" : "bottom"}
    visible={isDrawerOpen}
    onClose={() => handleCancel()}
    title={<DrawerHeader className={"edit-header"}>
      {window.innerWidth <= 768 && <ReturnArrow onClick={() => handleCancel()}/>}
      Boiler service
      {window.innerWidth > 768 && <CircleIcon onClick={() => handleCancel()}><CloseIcon /></CircleIcon>}
    </DrawerHeader>}
  >
    {propertyLocation === "United Kingdom" && propertyZipCode ? <>
      {BoilerDrawerContent()}
      {Legalese(true)}
    </> : ServiceUnavailable()}
  </DrawerStyled>
}

export default BoilerGasCertificateDrawer