import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";

export type TDismissWelcomeResponse = {
};

export const dismissWelcome = () => {
  let url = createUrl(`${EnvConfig.API_URL}/api/user/dismissWelcome`);

  return apiPut<TDismissWelcomeResponse, {}>(url)
};
