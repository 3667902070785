import React from "react";
import styled from "styled-components";
import GuidanceRating from "../guidance/GuidanceRating";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

type Props = {
  item: any;
  categories: any;
  onClickAction: any;
  isActive: boolean;
}

const CarouselGuidanceItem = (props: Props) => {
  const {item, categories, onClickAction, isActive} = props;

  const getCategoryName = (categoryId: number) => categories.filter((c: any) => c.id === categoryId)[0].name

  const spinner = () => <div style={{textAlign: "center"}}>
    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
  </div>

  return item ? <GuidanceItemWrapper
    style={{opacity: isActive ? 1 : 1}}
    onClick={onClickAction}
  >
    <GuidanceImage style={{backgroundImage: `url("https://www.livlet.com/guidance/${item.id}.jpg")`}} />
      {isActive ? <GuidanceTextWrappper>
        <GuidanceRatingWrapper>
          <GuidanceRating item={item}/>
        </GuidanceRatingWrapper>
        <GuidanceCategory>{getCategoryName(item.category)}</GuidanceCategory>
        <GuidanceTitle>{item.name}</GuidanceTitle>
      </GuidanceTextWrappper> : <GuidanceTextWrappper />}
  </GuidanceItemWrapper> : spinner()
}

const GuidanceItemWrapper = styled.div`
  position: relative;
  width: 13rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
`

const GuidanceImage = styled.div`
  height: 11rem;
  border-radius: 10px 10px 0 0;
  background-color: teal;
  
  background-repeat: no-repeat;
  background-position: center center; 
  background-size: cover;
`

const GuidanceRatingWrapper = styled.div`
  position: absolute;
  top: 9.75rem;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
`

const GuidanceTextWrappper = styled.div`
  background: #EFFEF7;
  border-radius: 0 0 10px 10px;
  padding: 2rem 1rem 1rem 1rem;
  box-shadow: 0px 0px 21.3793px rgba(0, 0, 0, 0.1);
  height: 9rem;
  
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  //overflow: hidden;
`

const GuidanceCategory = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #666F7A;
  
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
`

const GuidanceTitle = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #21272B;
  
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
`

export default CarouselGuidanceItem