import {emptyGuid} from "../screens/helpers";
import {CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons";
import React, {useState} from "react";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import {Guid} from "guid-typescript";
import {TTag} from "../../api-wrapper/tags/addNewTag";

const moment = require("moment");

export const attachmentsInitial = [
  {
    name: "",
    propertyId: emptyGuid,
    parentId: emptyGuid,
    parentType: "",
    size: 0,
    attachmentId: emptyGuid,
    thumbnailPath: "",
    documentPreviewPath: "",
    uploadedDate: "",
    description: "",
    source: "",
    tags: [],
    attachmentLinksCount: 0,
    relatedDocumentId: null
  },
];

export const worksInitial = [
  {
    name: "",
    type: "",
    completedDate: "",
    dueDate: "",
    recurring: "",
    workId: emptyGuid,
    propertyId: emptyGuid,
    workTotalExpense: 0,
    isComplete: true,
    relatedImprovementId: "",
    reminder: {},
    notes: "",
    tags: []
  },
];

export const mortgagesInitial = [
  {
    mortgageId: emptyGuid,
    subscriptionId: 0,
    propertyId: emptyGuid,
    provider: emptyGuid,
    loanTotal: 0,
    mortgageDate: "",
    durationYears: 0,
    monthlyInstallment: 0,
    initialPayment: 0,
    fixedPeriodYears: 0,
    creationDate: "",
    totalRepaid: 0,
    remainingLoan: 0,
    yearsRemaining: 0,
    mortgageCloseDate: "",
    expensesInPast: false,
    interestRate: 0
  },
];

export const tagsInitial = [
  {
    tagId: emptyGuid,
    name: ""
  }
]

export const notesInitial = [
  {
    recordId: emptyGuid,
    title: "",
    message: "",
    createdDate: "",
    emergency: false,
    attachment: {
      attachmentId: emptyGuid,
      propertyId: emptyGuid,
      parentId: emptyGuid,
      parentType: "",
      name: "",
      size: 0,
    },
  },
];

export const inventoriesInitial = [
  {
    propertyId: emptyGuid,
    brand: "",
    model: "",
    installedOrPurchaseDate: moment.Moment,
    warrantyExpiration: moment.Moment,
    inventoryType: "",
    purchaseCost: 0,
    insuredItems: false,
    serialNumber: "",
    inventorySubtype: "",
    applianceLifetimeYears: null,
    applianceLifetimeYearsLeft: null,
    name: "",
    id: emptyGuid,
    notes: "",
    tags: [],
    relatedUpkeepsCount: 0,
  },
];

export const expensesInitial = [
  {
    expenseId: emptyGuid,
    name: "",
    parentId: emptyGuid,
    parentType: "",
    payee: emptyGuid,
    paymentDate: "",
    propertyId: emptyGuid,
    totalCost: 0,
    type: emptyGuid,
    isTaxDeductible: false,
    notes: "",
    tags: [],
    isConfirmed: false,
    attachmentsCount: 0,
    expenseRelations: [],
    userPaidBy: null,
    contactPaidBy: null
  },
];

export const expenseTypesInitial = [
  {
    id: emptyGuid,
    parentId: emptyGuid,
    name: ""
  }
]

export const petsInitial = [
  {
    propertyId: emptyGuid,
    name: "",
    petType: "",
    petId: emptyGuid,
    subscriptionId: 0,
    createdDate: "",
    imageThumbnail: "",
    tags: []
  }
]

export const budgetCostsInitial = [
  {
    budgetCostId: emptyGuid,
    name: "",
    payee: emptyGuid,
    startDate: "",
    propertyId: emptyGuid,
    totalCost: 0,
    type: emptyGuid,
    isTaxDeductible: false,
    isAutoConvert: false,
    periodicityUnit: "",
    periodicity: 0,
    description: "",
  },
];


export const initialContactData = [
  {
    contactId: emptyGuid,
    name: "",
    surname: "",
    phone: "",
    email: "",
    contactType: "",
    company: "",
    propertyId: emptyGuid,
    website: "",
    expensesCount: 0,
    expensesTotalCost: 0,
    worksCount: 0,
    notes: "",
    emergency: false,
    tags: []
  },
];

export const subscriptionUsersInitial = [
  {
    name: "",
    email: "",
    inviteTime: "",
    acceptedInvite: false,
    userId: 0
  }
];

export const spacesInitial = [
  {
    spaceId: emptyGuid,
    subscriptionId: 0,
    propertyId: emptyGuid,
    name: "",
    category: "",
    floor: "",
    notes: "",
    spaceType: "",
    imageThumbnail: "",
    createdDate: "",
    dimension1: "",
    dimension2: "",
    paint: "",
    spaceNotes: [],
    attachments: [],
    works: [],
    expenses: [],
    inventories: [],
    contacts: []
  }
]

export function cutOffThousand(x: number) {
  if (x < 1000) {
    return x
  }  else {
    return x / 1000 + "k"
  }
}

export function numberWithCommas(x: number) {
  if (x < 999999) {
    return (Math.round(x * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }  else {
    let y = Math.trunc(x / 1000);
    return (Math.round(y * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "k";
  }
}

export function numberWithCommasFull(x: number) {
  return (Math.round(x * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const reminderStatus = (dueDate: string): string => {
  let currentDate = new Date()
  currentDate.setHours( 0,0,0,0 )
  let currentMoment = moment(currentDate)
  let dueMoment = moment(dueDate)
  let diff = dueMoment.diff(currentMoment, 'days')

  if (diff >= 14) return "regular"
  else if (diff >= 0) return "warning"
  else return "danger"
}

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const renderRecurringText = (text: string) => {
  switch (text) {
    case "TwiceAYear":
      return "twice a year"
    case"OnceIn4Months":
      return "once in 4 months"
    case "OnceIn3Years":
      return "once in 3 years"
    default:
      return text.toLowerCase()
  }
}

export function returnUnique(value: any, index: any, self: string | any[]) {
  return self.indexOf(value) === index;
}

export const transformPeriodicityUnit = (periodicityUnit: string) => {
  switch (periodicityUnit) {
    case "day":
      return 1
    case "week":
      return 7
    case "month":
      return 30
    case "year":
      return 365
    default:
      return 0
  }
}

export const getFrequency = (item: any) => {
  let periodicity
  if (item.actualPeriodicity) periodicity = item.actualPeriodicity
  else periodicity = item.periodicity

  let periodicityUnit
  if (item.actualPeriodicityUnit) periodicityUnit = item.actualPeriodicityUnit
  else periodicityUnit = item.periodicityUnit

  return periodicity * transformPeriodicityUnit(periodicityUnit)
}

export const getCost = (item: any) => {
  return item.actualEstimatedCost ?? item.estimatedCost ?? 0
}

const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

export const niceBytes = (x: string, excludeUnits?: boolean) => {
  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  //include a decimal point and a tenths-place digit if presenting
  //less than ten of KB or greater units
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + (excludeUnits? "" : " " + units[l])
};

export function disabledDate(current: any) {
  return current && current <= moment().subtract(1, 'days').endOf('day');
}

export const isStartDateDisabled = (currentDate: any, endDate: any) => {
  return currentDate && currentDate.format("YYYYMMDD") > endDate.format("YYYYMMDD");
}

export const isEndDateDisabled = (currentDate: any, startDate: any) => {
  return currentDate && currentDate.format("YYYYMMDD") < startDate.format("YYYYMMDD");
}

export const downloadExport = (response: any, mimeType: string) => {
  let fileName = response.headers["content-disposition"] ? response.headers["content-disposition"].split("filename=")[1]
    : `notes_export_${Date.now()}.docx`
  let blob = new Blob([response.data], {type: mimeType});
  const href = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}
export const resultIcon = (result: boolean) => {
  return result ? <CheckCircleFilled style={{color: "#53c41a"}} /> : <CloseCircleFilled style={{color: "#ff4d4f"}} />
}

export const passwordResult = (score: any) => {
  let passwordStrength
  if (score >= 75) passwordStrength = <span>Strong</span>
  else if (score >= 50 && score < 75) passwordStrength = <span>Medium</span>
  else passwordStrength = <span>Weak</span>

  return <div style={{fontSize: window.innerWidth > 1024 ? '1rem' : "0.875rem"}}>
    Password strength: {passwordStrength}
  </div>
}

// @ts-ignore
export const ReadMoreReadLess = ({ children, readMoreText, readLessText, charLimit }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div style={{color: '#6B7185', marginBottom: 0}}>
      {isReadMore ? (text.length > charLimit ? <Ellipsis lines={1}>{text}</Ellipsis> : text) : text}
      {text.length > charLimit && <span onClick={toggleReadMore}>
      {isReadMore ? readMoreText : readLessText}
      </span>}
    </div>
  );
};

export const setCostTypes = (expenseTypes: any, setRoots: (arg: any) => void, setLeaves: (arg: any) => void) => {
  const resArray = Object.entries(expenseTypes)
  let roots = resArray.filter((item: any) => !item[1].hasOwnProperty('parentId'))
  let maintRoot = roots.filter((item: any) => item[1].name.toLowerCase().includes("maintenance"))
  let otherRoots = roots.filter((item: any) => !item[1].name.toLowerCase().includes("maintenance"))
  setRoots([...maintRoot, ...otherRoots])

  let leaves = resArray.filter((item: any) => item[1].hasOwnProperty('parentId'));
  const others = leaves.filter((item: any) => item[1].name === "Other");
  leaves = leaves.filter((item: any) => item[1].name !== "Other");
  let orderedLeaves = leaves.sort((l1: any, l2: any) => {
    return l1[1].name.localeCompare(l2[1].name)
  })
  Array.prototype.push.apply(orderedLeaves, others);
  setLeaves(leaves);

}

export const detailsImpactingValuation = [1,2,3,5,6,22,23,24];

export const propertyYearOptions = [
  {key: "1900-1929", value: "1900-1929"},
  {key: "1930-1949", value: "1930-1949"},
  {key: "1950-1966", value: "1950-1966"},
  {key: "1967-1975", value: "1967-1975"},
  {key: "1976-1982", value: "1976-1982"},
  {key: "1983-1990", value: "1983-1990"},
  {key: "1991-1995", value: "1991-1995"},
  {key: "1996-2002", value: "1996-2002"},
  {key: "2003-2006", value: "2003-2006"},
  {key: "2007 onwards", value: "2007 onwards"},
  {key: "before 1900", value: "before 1900"}
]

export const propertyYearConversion = (value: any) => {
  switch (value) {
    case "1900-1929":
      return 1915;
    case "1930-1949":
      return 1940;
    case "1950-1966":
      return 1960;
    case "1967-1975":
      return 1970;
    case "1976-1982":
      return 1980;
    case "1983-1990":
      return 1985;
    case "1991-1995":
      return 1995;
    case "1996-2002":
      return 2000;
    case "2003-2006":
      return 2005;
    case "2007 onwards":
      return 2010;
    case "before 1900":
      return 1900;
    default:
      return parseInt(value);
  }
}

export const propertyYearReversedConversion = (value: any) => {
  if (!value)
    return "";
  if (isNaN(value))
    return value;
  if (value <= 1900)
    return "before 1900";
  if (value <= 1929)
    return "1900-1929";
  if (value <= 1949)
    return "1930-1949";
  if (value <= 1966)
    return "1950-1966";
  if (value <= 1975)
    return "1967-1975";
  if (value <= 1982)
    return "1976-1982";
  if (value <= 1990)
    return "1983-1990";
  if (value <= 1995)
    return "1991-1995";
  if (value <= 2002)
    return "1996-2002";
  if (value <= 2006)
    return "2003-2006";
  return "2007 onwards"
}

export const upkeepTypes = ["task", "recurringexpense", "reminder"]

export const areSetsEqual = (a: any, b: any) => a.size === b.size && [...a].every(value => b.has(value));

export const isSuperset = (set: any, subset: any) => {
  for (let elem of subset) {
    if (!set.has(elem)) return false;
  }
  return true;
}