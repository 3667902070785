import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TAddTenantResponse = {
  propertyId: Guid;
  startDate: string;
  endDate: string;
  rentalPayment: number;
  tenancyContactId: Guid;
  agentFee: number;
  agentContact: Guid;
  propertyManagementFee: number;
  propertyManagementContact: Guid;
  tenancyId: Guid;
};

export type TAddTenantRequest = {
  propertyId: Guid;
  startDate?: string | null;
  endDate?: string | null;
  rentalPayment?: number | null;
  tenancyContactId?: Guid | null;
  agentFee?: number | null;
  agentContact?: Guid | null;
  propertyManagementFee?: number | null;
  propertyManagementContact?: Guid | null;
};

export const addTenant = (
  payload: TAddTenantRequest & { propertyId: Guid }
) => {
  const { propertyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/tenancy`
  );

  return apiPost<TAddTenantResponse, TAddTenantRequest>(url, payload);
};
