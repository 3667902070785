import React, {useEffect, useRef, useState} from "react";
import PropertyDetailsComp from "../components/properties/PropertyDetailsComp";
import styled from "styled-components";
import PropertyDrawer from "../components/properties/PropertyDrawer";
import TenantDrawer from "../components/tenancy/TenantDrawer";
import InsuranceDrawer from "../components/insurance/InsuranceDrawer";
import {getAllContacts} from "../actions/contacts";
import {getAllTenants} from "../actions/tenants";
import {getAllInsurances} from "../actions/insurances";
import {useDispatch, useSelector} from "react-redux";
import {
  getCertificatesSelector,
  getContactsSelector, getFloorPlansSelector,
  getInsurancesSelector, getMortgagesSelector,
  getPropertiesSelector,
  getPropertyIdSelector, getSurveysSelector,
  getTenantsSelector
} from "../selectors";
import TenancyListComp from "../components/tenancy/TenancyListComp";
import InsuranceListComp from "../components/insurance/InsuranceListComp";
import {Guid} from "guid-typescript";
import _ from "lodash";
import PropertyDetailsDrawer from "../components/properties/PropertyDetailsDrawer";
import MyPropertyComp from "../components/properties/MyPropertyComp";
import {getCurrentUserProfile} from "../actions/getUserProfile";
import {emptyGuid} from "./helpers";
import CertificateDrawer from "../components/certificates/CertificateDrawer";
import CertificateListComp from "../components/certificates/CertificateListComp";
import {getAllCertificates} from "../actions/certificates";
import {useLocation} from "react-router-dom";
import {ReactComponent as Check} from "../../images/Check.svg";
import {ReactComponent as Cross} from "../../images/Cross.svg";
import messages from "./messages";
import {setNickname} from "../../api-wrapper/properties/setPropertyNickname";
import {getAllProperties} from "../actions/properties";
import {useIntl} from "react-intl";
import {PageHeader, PageSubtitle, PageTitle, PageWrapper} from "./components";
import DocumentDrawer from "../components/documents/DocumentDrawer";
import DocumentListComp from "../components/documents/DocumentListComp";
import {Button, Input} from "antd";
import {getAllFloorPlans} from "../actions/floorPlans";
import {getAllSurveys} from "../actions/surveys";
import {getAllAttachments} from "../actions/attachments";
import MortgageDrawer from "../components/mortgage/MortgageDrawer";
import MortgageListComp from "../components/mortgage/MortgageListComp";
import {getAllMortgages} from "../actions/mortgages";
import PropertyPreferencesDrawer from "../components/properties/PropertyPreferencesDrawer";
import {TFetchingStatus} from "../../helpers";

const MyProperty = () => {
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const tenants = useSelector(getTenantsSelector);
  const contacts = useSelector(getContactsSelector);
  const properties = useSelector(getPropertiesSelector);
  const insurances = useSelector(getInsurancesSelector);
  const certificates = useSelector(getCertificatesSelector);
  const floorPlans = useSelector(getFloorPlansSelector);
  const surveys = useSelector(getSurveysSelector);
  const mortgages = useSelector(getMortgagesSelector);
  const {formatMessage: f} = useIntl();

  const [property, setProperty] = useState<any>(null);

  const refreshLists = () => {
    dispatch(getCurrentUserProfile());
    dispatch(getAllContacts(propertyId));
    dispatch(getAllTenants(propertyId));
    dispatch(getAllInsurances(propertyId));
    dispatch(getAllCertificates(propertyId));
    dispatch(getAllFloorPlans(propertyId));
    dispatch(getAllSurveys(propertyId));
    dispatch(getAllAttachments(propertyId));
    dispatch(getAllMortgages(propertyId));
  };

  useEffect(() => {
    refreshLists();
    if (properties.filter((property) => property.propertyId === propertyId).length > 0) {
      setProperty(properties.filter((property) => property.propertyId === propertyId)[0]);
    }
  }, [propertyId, properties])

  const [selectedPill, setSelectedPill] = useState("My property")
  const [isPropertyDrawerOpen, togglePropertyDrawer] = useState(false);
  const [isTenantDrawerOpen, toggleTenantDrawer] = useState(false);
  const [tenantData, setTenantData] = useState(null);
  const [isInsuranceDrawerOpen, toggleInsuranceDrawer] = useState(false);
  const [insuranceData, setInsuranceData] = useState(null);
  const [isCertificateDrawerOpen, toggleCertificateDrawer] = useState(false);
  const [certificateData, setCertificateData] = useState(null);
  const [isPropertyDetailsDrawerOpen, togglePropertyDetailsDrawer] = useState(false);
  const [isPropertyPreferencesDrawerOpen, togglePropertyPreferencesDrawer] = useState(false);
  const [mortgageData, setMortgageData] = useState(null);
  const [isMortgageDrawerOpen, toggleMortgageDrawer] = useState(false);

  const [isDocumentDrawerOpen, toggleDocumentDrawer] = useState(false);
  const [document, setDocumentData] = useState(null);
  const [documentType, setDocumentType] = useState("floorPlan");
  const [sortedInsurances, setSortedInsurances] = useState<any>([]);
  const [sortedCertificates, setSortedCertificates] = useState<any>([]);

  const filteredContactName = (contactId: Guid) =>
    contacts.content.filter((contact) => contact.contactId === contactId);

  const showContactName = (contactId: Guid) => {
    return _.isEmpty(filteredContactName(contactId))
      ? ""
      : filteredContactName(contactId)[0].name;
  };

  const useQuery = (q: string) => {
    let query = new URLSearchParams(useLocation().search);
    return query.get(q);
  };
  const typeToOpen = useQuery("openTab")

  useEffect(() => {
    if (typeToOpen) {
      setSelectedPill(typeToOpen)
    }
  }, [typeToOpen])

  const nicknameInputRef = useRef(null);
  const [isPropertyNicknameInputActive, togglePropertyNicknameInput] = useState(false);
  const [propertyNickname, setPropertyNickname] = useState("");

  useEffect(() => {
    if (property) {
      setPropertyNickname(property.nickname ?? "")
    }
  }, [property])

  useEffect(() => {
    if (isPropertyNicknameInputActive) {
      // @ts-ignore
      nicknameInputRef.current.focus();
    }
  }, [isPropertyNicknameInputActive])

  useEffect(() => {
    if (certificates.fetchingStatus === TFetchingStatus.Success) {
      let certificatesCopy = [...certificates.content];
      certificatesCopy.sort((s1, s2) => (s2.confirmed ? 0 : 1) - (s1.confirmed ? 0 : 1))
      setSortedCertificates(certificatesCopy);
    }
  }, [certificates])

  useEffect(() => {
    if (insurances.fetchingStatus === TFetchingStatus.Success) {
      let insurancesCopy = [...insurances.content];
      insurancesCopy.sort((s1, s2) => (s2.confirmed ? 0 : 1) - (s1.confirmed ? 0 : 1))
      setSortedInsurances(insurancesCopy);
    }
  }, [insurances])

  const handlePropertyNicknameSave = () => {
    setNickname(propertyId, { nickname: propertyNickname }).then(() =>
      dispatch(getAllProperties())
    );
    togglePropertyNicknameInput(false);
  };

  const handlePropertyNicknameCancel = () => {
    setPropertyNickname(property.nickname ?? "");
    togglePropertyNicknameInput(false);
  };

  if (_.isEmpty(properties) || (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
    || _.isEqual(properties[0].propertyId, emptyGuid) || !property)
    return <></>;

  const propertyPills = ["My property", "Property details", "Property tenancy", "Insurance", "Certificates", "Surveys", "Plans", "Mortgage"]

  return (
    <PageWrapper>
      <PropertyDrawer
        toggleDrawerOpen={togglePropertyDrawer}
        isOpen={isPropertyDrawerOpen}
        editedPropertyId={propertyId}
      />
      <PropertyDetailsDrawer
        toggleDrawerOpen={togglePropertyDetailsDrawer}
        isOpen={isPropertyDetailsDrawerOpen}
      />
      <PropertyPreferencesDrawer
        isOpen={isPropertyPreferencesDrawerOpen}
        toggleDrawerOpen={togglePropertyPreferencesDrawer}
      />
      <TenantDrawer
        toggleDrawerOpen={toggleTenantDrawer}
        isOpen={isTenantDrawerOpen}
        tenant={tenantData}
        setEditingTenantData={setTenantData}
        refreshParent={refreshLists}
      />
      <InsuranceDrawer
        toggleDrawerOpen={toggleInsuranceDrawer}
        isOpen={isInsuranceDrawerOpen}
        insurance={insuranceData}
        setEditingInsuranceData={setInsuranceData}
        refreshParent={refreshLists}
      />
      <CertificateDrawer
        isOpen={isCertificateDrawerOpen}
        toggleDrawerOpen={toggleCertificateDrawer}
        setEditingCertificateData={setCertificateData}
        certificate={certificateData}
      />
      <DocumentDrawer
        isOpen={isDocumentDrawerOpen}
        toggleDrawerOpen={toggleDocumentDrawer}
        setEditingDocumentData={setDocumentData}
        document={document}
        documentType={documentType}
      />
      <MortgageDrawer
        isOpen={isMortgageDrawerOpen}
        toggleDrawerOpen={toggleMortgageDrawer}
        setEditingMortgageData={setMortgageData}
        mortgage={mortgageData}
      />
      <PageHeader>
        <div style={{width: "100%"}}>
          {isPropertyNicknameInputActive ? (
            <NicknameWrapper>
              <NicknameInput
                ref={nicknameInputRef}
                placeholder={"Enter nickname"}
                value={propertyNickname}
                maxLength={64}
                onChange={(e: any) => setPropertyNickname(e.target.value)}
                onPressEnter={() => handlePropertyNicknameSave()}
                onKeyDown={(key: any) => {
                  if (key.keyCode === 27) handlePropertyNicknameCancel()
                }}
              />
              <NicknameButton
                className={"check"}
                onClick={() => handlePropertyNicknameSave()}>
                <Check />
              </NicknameButton>
              <NicknameButton
                className={"cross"}
                onClick={() => handlePropertyNicknameCancel()}>
                <Cross />
              </NicknameButton>
            </NicknameWrapper>) : (<NicknameWrapper>
                <PageTitle>
                  {propertyNickname ? propertyNickname : f(messages.myProperty)}
                </PageTitle>
              <NicknameButton onClick={() => togglePropertyNicknameInput(true)}>
                {propertyNickname ? f(messages.editNickname) : f(messages.addNickname)}
              </NicknameButton>
            </NicknameWrapper>)}
          <PageSubtitle>Record details, deeds and contracts of properties you own.</PageSubtitle>
        </div>
      </PageHeader>
      <PropertyPills>
        {propertyPills.map(pill => <PropertyPill
          className={selectedPill === pill ? "selected-pill" : ""}
          onClick={() => setSelectedPill(pill)}>
          {pill}
        </PropertyPill>)}
      </PropertyPills>

      {selectedPill === "My property" && <MyPropertyComp
          property={property}
          togglePropertyDrawer={togglePropertyDrawer}
          setSelectedPill={setSelectedPill}
      />}

      {selectedPill === "Property details" && <PropertyDetailsComp
          property={property}
          togglePropertyDetailsDrawer={togglePropertyDetailsDrawer}
          togglePropertyPreferencesDrawer={togglePropertyPreferencesDrawer}
      />}

      {selectedPill === "Property tenancy" && <TenancyListComp
          tenants={tenants.content}
          toggleTenantDrawer={toggleTenantDrawer}
          setTenantData={setTenantData}
          showContactName={showContactName} />}

      {selectedPill === "Insurance" && <InsuranceListComp
          insurance={sortedInsurances}
          toggleInsuranceDrawer={toggleInsuranceDrawer}
          setInsuranceData={setInsuranceData}
          showContactName={showContactName} />}

      {selectedPill === "Certificates" && <CertificateListComp
          certificates={sortedCertificates}
          toggleCertificateDrawer={toggleCertificateDrawer}
          setCertificateData={setCertificateData}
      />}

      {selectedPill === "Surveys" && <DocumentListComp
          documents={surveys}
          toggleDocumentDrawer={toggleDocumentDrawer}
          setDocumentData={setDocumentData}
          documentType={"survey"}
          setDocumentType={setDocumentType}
      />}

      {selectedPill === "Plans" && <DocumentListComp
          documents={floorPlans}
          toggleDocumentDrawer={toggleDocumentDrawer}
          setDocumentData={setDocumentData}
          documentType={"floorPlan"}
          setDocumentType={setDocumentType}
      />}

      {selectedPill === "Mortgage" && <MortgageListComp
          mortgages={mortgages}
          toggleMortgageDrawer={toggleMortgageDrawer}
          setMortgageData={setMortgageData}
      />}
    </PageWrapper>
  );
};

const PropertyPills = styled.div`
  display: flex;
  max-width: 1100px;
  column-gap: 1rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;
  
  @media (max-width: 800px) {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 0.5rem;
  }
`

const PropertyPill = styled.div`
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 1.5rem;
  background: #DAE0E6;
  color: #6B7185;
  
  :hover {
    color: white;
    background: #2A80E1;
    font-weight: 600;
  }
`

const NicknameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`

const NicknameInput = styled(Input)`
  display: flex;
  border: #F0F2F5;
  outline: none;
  border-left: 3px solid white;
  background: #F0F2F5;
  padding: 0 0.5rem;

  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3.5rem;
  color: #002A43;

  @media (max-width: 768px) {
    font-size: 1.625rem;
    line-height: 1.75rem;
  }

  .ant-input: * {
    outline: none;
    box-shadow: none;
    -webkit-box-shadow: none !important;
  }
`;

const NicknameButton = styled(Button)`
  height: 3rem;
  border: 1px solid #21272B;
  background-color: #F0F2F5;
  border-radius: 6px;
  color: #21272B;

  display: flex;
  align-items: center;
  text-align: center;

  :hover,  :active,  :focus {
    color: #21272B;
    background-color: #F0F2F5;
  }
  
  &.check svg path { stroke: #21272B; }
  &.cross svg path { stroke: #21272B; }
`;

export default MyProperty;
