import React, {useEffect, useState} from "react";
import { ReactComponent as ContactsIcon } from "../../../images/ContactsNew.svg";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import {Button, Checkbox, Drawer, Form, Input, notification} from "antd";
import {Label} from "../../screens/RegistrationPage";
import EmailCreatableSelector from "../EmailCreatableSelector";
import {EmailModal, PreviewBox} from "../SendViaEmail";
import ReactHtmlParser from "react-html-parser";
import {useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../selectors";
import {getRecipients, processForwardingError, safetyRegex, transformRecipients} from "../../screens/helpers";
import {ButtonsWrapper, CloseButton, WorksIconWrapper} from "../works/WorkDrawer";
import {
  ButtonOutlined,
  ButtonSolid,
  ButtonWrapper, ControlButton, Controls,
  ControlsWrapper,
  InputWrapper,
  Title
} from "../works/ForwardWorkDrawer";
import {forwardContactPreview} from "../../../api-wrapper/email/forwardContactPreview";
import {forwardContact} from "../../../api-wrapper/email/forwardContact";
import styled from "styled-components";
import AttachFile from "../attachments/AttachFile";
import {getContactById} from "../../../api-wrapper/contacts/getContactById";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  contactId: any;
}

const ForwardContactDrawer = (props: Props) => {
  const propertyId = useSelector(getPropertyIdSelector).value;
  const {isOpen, toggleDrawerOpen, contactId} = props;

  const [form] = Form.useForm();

  const [recipients, setRecipients] = useState<any>([])
  const [customMessage, setCustomMessage] = useState("")
  const [ccSender, setCcSender] = useState(false)
  const [isPreviewModalOpen, togglePreviewModal] = useState(false)
  const [preview, setPreview] = useState("")
  const [includeContactName, setIncludeContactName] = useState(false)
  const [includeCompanyName, setIncludeCompanyName] = useState(false)
  const [includeContactCategory, setIncludeContactCategory] = useState(false)
  const [includePhone, setIncludePhone] = useState(false)
  const [includeEmail, setIncludeEmail] = useState(false)
  const [includeWebsite, setIncludeWebsite] = useState(false)
  const [includeNotes, setIncludeNotes] = useState(false)
  const [includeFiles, setIncludeFiles] = useState(false)
  const [includePropertyAddress, setIncludePropertyAddress] = useState(false)

  const [filesToForward, setFilesToForward] = useState<any>([])
  const [isForwardingAllowed, setIsForwardingAllowed] = useState(true)

  function restoreInitialState() {
    setRecipients([])
    setCustomMessage("")
    setCcSender(false)
    setIncludeContactCategory(false)
    setIncludeCompanyName(false)
    setIncludeEmail(false)
    setIncludePhone(false)
    setIncludeWebsite(false)
    setIncludeContactName(false)
    setIncludeNotes(false)
    setIncludeFiles(false)
    setIncludePropertyAddress(false)
    setPreview("")
    setFilesToForward([])
    toggleDrawerOpen(false)
  }
  
  function selectAll() {
    setIncludeContactCategory(true)
    setIncludeCompanyName(true)
    setIncludeEmail(true)
    setIncludePhone(true)
    setIncludeWebsite(true)
    setIncludeContactName(true)
    setIncludeNotes(true)
    setIncludeFiles(true)
    setIncludePropertyAddress(true)
  }

  function deselectAll() {
    setIncludeContactCategory(false)
    setIncludeCompanyName(false)
    setIncludeEmail(false)
    setIncludePhone(false)
    setIncludeWebsite(false)
    setIncludeContactName(false)
    setIncludeNotes(false)
    setIncludeFiles(false)
    setIncludePropertyAddress(false)
  }

  useEffect(() => {
    isOpen && getContactById(propertyId, contactId).then((res) =>
      res && setFilesToForward(res.attachments)
    );
  }, [contactId, isOpen])

  function handleForward(mode: string) {
    let sanitizedMessage = customMessage.replace(safetyRegex, '')
    let attachments: any = []
    filesToForward.map((file: any) => attachments.push(file.attachmentId))

    let dataToForward = {
      propertyId,
      contactId,
      recipients: getRecipients(transformRecipients(recipients)),
      ccSender,
      customMessage: sanitizedMessage,
      isContactNameEnabled: includeContactName,
      isCompanyNameEnabled: includeCompanyName,
      isContactCategoryEnabled: includeContactCategory,
      isContactPhoneEnabled: includePhone,
      isContactEmailEnabled: includeEmail,
      isContactWebsiteEnabled: includeWebsite,
      isContactNotesEnabled: includeNotes,
      isPropertyAddressEnabled: includePropertyAddress,
      attachments: includeFiles ? attachments : []
    }

    if (mode === "preview") {
      forwardContactPreview(dataToForward).then((res: any) => {
        setPreview(res.data.body)
        togglePreviewModal(true)
      })
    }
    if (mode === "send") {
      forwardContact(dataToForward).then(() => {
        notification.info({
          message: 'Contact has been forwarded successfully',
          placement: "topRight",
        })
        restoreInitialState()
      }).catch((errorResponse: any) => processForwardingError(errorResponse))
    }
  }

  return (<>
      <EmailModal
        width={800}
        style={{top: 10}}
        title={"Send message"}
        visible={isPreviewModalOpen}
        onCancel={() => togglePreviewModal(false)}
        closeIcon={<Button style={{marginLeft: '-3.8rem'}} type={'ghost'}>Cancel</Button>}
        footer={null}>
        <PreviewBox>{ReactHtmlParser(preview)}</PreviewBox>
      </EmailModal>

      <Drawer
        push={{ distance: "32px" }}
        closeIcon={false}
        width={window.innerWidth > 1024 ? "55%" : "100%"}
        visible={isOpen}
        placement="right"
        onClose={restoreInitialState}
        headerStyle={{
          backgroundColor: "#ebf9ff",
          margin: 0,
          padding: "2rem 2rem",
        }}
        title={
          <>
            <section style={{ display: "flex", justifyContent: "space-between" }}>
              <WorksIconWrapper>
                <ContactsIcon className={"works-theme-icon"} />{" "}
                <span style={{ paddingRight: "0.25rem", color: "#7479E9", fontSize: "0.875", textTransform: "uppercase" }}>
                  <b>Contact</b>
                </span>
              </WorksIconWrapper>
              <ButtonsWrapper>
                <CloseButton size={"large"}
                             onClick={restoreInitialState}
                >
                  {window.innerWidth > 1024 ?
                    <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
                </CloseButton>
              </ButtonsWrapper>
            </section>
            <section
              style={{
                marginTop: "1.875rem",
                marginBottom: "0.75rem"
              }}
            >
              <Title>Send message</Title>
            </section>
          </>
        }
      >
        <Form
          form={form}
          name="sendEmail"
          layout="vertical"
          size={"large"}
        >
          <InputWrapper>
            <Label style={{marginBottom: "0.75rem"}}>To:</Label>
            <EmailCreatableSelector emailList={recipients} setEmailList={setRecipients} />
            <div style={{display: "flex", flexDirection: "column"}}>
              <Checkbox
                style={{marginBottom: '1.5rem'}}
                checked={ccSender}
                onClick={() => setCcSender(!ccSender)}>
                CC me on this email
              </Checkbox>
              {recipients.length > 1 && ccSender && <p style={{fontWeight: 700, color: "#2a80e1"}}>
                  Please note that you will receive {recipients.length} copies of this email.
              </p>}
            </div>
          </InputWrapper>
          <InputWrapper style={{display: "flex", flexDirection: "column"}}>
            <ControlsWrapper>
              <Label style={{margin: 0}}>Select contact fields to forward:</Label>
              <Controls>
                <ControlButton className={"select"} onClick={() => selectAll()}>Select all</ControlButton>
                <ControlButton className={"deselect"} onClick={() => deselectAll()}>Deselect all</ControlButton>
              </Controls>
            </ControlsWrapper>
            <CheckboxWrapper>
              <CheckboxStyled
                checked={includeContactName}
                style={{marginBottom: '1rem', marginLeft: 0}}
                onClick={() => setIncludeContactName(!includeContactName)}>
                Contact name
              </CheckboxStyled>
              <CheckboxStyled
                checked={includeContactCategory}
                style={{marginBottom: '1rem', marginLeft: 0}}
                onClick={() => setIncludeContactCategory(!includeContactCategory)}>
                Contact category
              </CheckboxStyled>
              <CheckboxStyled
                checked={includeCompanyName}
                style={{marginBottom: '1rem', marginLeft: 0}}
                onClick={() => setIncludeCompanyName(!includeCompanyName)}>
                Company name
              </CheckboxStyled>
              <CheckboxStyled
                checked={includeEmail}
                style={{marginBottom: '1rem', marginLeft: 0}}
                onClick={() => setIncludeEmail(!includeEmail)}>
                Email
              </CheckboxStyled>
              <CheckboxStyled
                checked={includePhone}
                style={{marginBottom: '1rem', marginLeft: 0}}
                onClick={() => setIncludePhone(!includePhone)}>
                Phone number
              </CheckboxStyled>
              <CheckboxStyled
                checked={includeWebsite}
                style={{marginBottom: '1rem', marginLeft: 0}}
                onClick={() => setIncludeWebsite(!includeWebsite)}>
                Website
              </CheckboxStyled>
              <CheckboxStyled
                checked={includePropertyAddress}
                style={{marginBottom: '1rem', marginLeft: 0}}
                onClick={() => setIncludePropertyAddress(!includePropertyAddress)}>
                Property address
              </CheckboxStyled>
              <CheckboxStyled
                checked={includeNotes}
                style={{marginBottom: '1rem', marginLeft: 0}}
                onClick={() => setIncludeNotes(!includeNotes)}>
                Notes
              </CheckboxStyled>
              <Checkbox
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeFiles}
                onClick={() => setIncludeFiles(!includeFiles)}>
                Documents
              </Checkbox>
            </CheckboxWrapper>
          </InputWrapper>
          <InputWrapper style={{marginBottom: "5.5rem"}}>
            <Label style={{marginBottom: "0.75rem"}}>Your comments:</Label>
            <Form.Item>
              <Input
                style={{borderRadius: "5px"}}
                placeholder={"Add comments here..."}
                value={customMessage}
                onChange={(e) => setCustomMessage(e.target.value)}
              />
            </Form.Item>
            {includeFiles && <AttachFile
                filesToForward={filesToForward}
                setFilesToForward={setFilesToForward}
                isForwardingAllowed={isForwardingAllowed}
                setIsForwardingAllowed={setIsForwardingAllowed}
            />}
          </InputWrapper>
          <ButtonWrapper>
            <ButtonOutlined
              style={{borderRadius: '6px'}}
              type={"primary"}
              onClick={() => handleForward("preview")}
            >Preview</ButtonOutlined>
            <ButtonSolid
              style={{borderRadius: '6px'}}
              type={"primary"}
              onClick={() => handleForward("send")}
              disabled={recipients.length === 0 || !isForwardingAllowed}
            >Send</ButtonSolid>
          </ButtonWrapper>
        </Form>
      </Drawer>
    </>
  )
}

export const CheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  row-gap: 0.5rem;
`

export const CheckboxStyled = styled(Checkbox)`
  width: calc(100% / 3);
`

export default ForwardContactDrawer;