import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";


import { ReactComponent as SpaceTypeMasterBedroom } from "../../../images/spaces/SpaceTypeMasterBedroom.svg";
import { ReactComponent as SpaceTypeBedroom } from "../../../images/spaces/SpaceTypeBedroom.svg";
import { ReactComponent as SpaceTypeOffice } from "../../../images/spaces/SpaceTypeOffice.svg";
import { ReactComponent as SpaceTypeBathroom } from "../../../images/spaces/SpaceTypeBathroom.svg";
import { ReactComponent as SpaceTypeKitchen } from "../../../images/spaces/SpaceTypeKitchen.svg";
import { ReactComponent as SpaceTypeLivingRoom } from "../../../images/spaces/SpaceTypeLivingRoom.svg";
import { ReactComponent as SpaceTypeOtherIndoors } from "../../../images/spaces/SpaceTypeOtherIndoors.svg";
import { ReactComponent as SpaceTypeGarden } from "../../../images/spaces/SpaceTypeGarden.svg";
import { ReactComponent as SpaceTypeGarage } from "../../../images/spaces/SpaceTypeGarage.svg";
import { ReactComponent as SpaceTypeRoof } from "../../../images/spaces/SpaceTypeRoof.svg";
import { ReactComponent as SpaceTypeDriveway } from "../../../images/spaces/SpaceTypeDriveway.svg";
import { ReactComponent as SpaceTypeShed } from "../../../images/spaces/SpaceTypeShed.svg";
import { ReactComponent as SpaceTypeBasement } from "../../../images/spaces/SpaceTypeBasement.svg";
import { ReactComponent as SpaceTypeAttic } from "../../../images/spaces/SpaceTypeAttic.svg";
import { ReactComponent as SpaceTypeUtility } from "../../../images/spaces/SpaceTypeUtility.svg";


import {getPropertyIdSelector,} from "../../selectors";
import _ from "lodash";
import {TFetchingStatus} from "../../../helpers";
import {
  ContentsPageHeader, OnboardingSubtitle,
  QuestionButtonMobile, QuestionButtonMobileSpaces,
  QuestionButtonSmall,
  QuestionsButtonsMobile,
  QuestionsButtonsSmall
} from "./QuestionsListComp";
import {emptyGuid} from "../../screens/helpers";
import {addSpace} from "../../../api-wrapper/spaces/addSpace";

type Props = {
  saveAction: boolean;
  setShowLoader: (arg: string|null) => void;
  redirectUser: any;
};

const spaceNameToType = new Map([
  ['Kitchen', 'Kitchen'],
  ['Bathroom', 'Bathroom'],
  ['Living Room', 'Living Room'],
  ['Office', 'Home Office'],
  ['Master Bedroom', 'Bedroom'],
  ['Bedroom 2', 'Bedroom'],
  ['Bedroom 3', 'Bedroom'],
  ['Bedroom 4', 'Bedroom'],
  ['Roof', 'Attic; Loft'],
  ['Driveway', 'Driveway'],
  ['Shed', 'Shed'],
  ['Garden', 'Garden'],
  ['Basement', 'Basement'],
  ['Attic/Loft', 'Attic; Loft'],
  ['Utility Cupboard', 'Utility Room'],
  ['Garage', 'Garage'],
]);

const OnboardingSpaces = (props: Props) => {
  const { saveAction, setShowLoader, redirectUser } = props;

  const propertyId = useSelector(getPropertyIdSelector).value;

  const [selectedSpaces, setSelectedSpaces] = useState(new Set<string>());


  useEffect(() => {
    if (!saveAction)
      return;

    setShowLoader("Creating spaces of your house");
    createSpaces();
  }, [saveAction]);

  const createSpaces = async () => {
    for (let spaceToCreate of Array.from(selectedSpaces)) {
      await addSpace({
        propertyId,
        request: {
          name: spaceToCreate,
          floor: "",
          // @ts-ignore
          spaceType: spaceNameToType.get(spaceToCreate) ? spaceNameToType.get(spaceToCreate) : '',
          category: (spaceToCreate === 'Driveway' || spaceToCreate === 'Roof' || spaceToCreate === 'Shed' || spaceToCreate === 'Garden') ? 'Outdoors' : 'Indoors',
          notes: "",
          dimension1: "",
          dimension2: "",
          paint: "",
        },
        spaceImage: null
      });
    }

    setShowLoader(null);
    redirectUser && redirectUser();
  }

  const getClassForSpace = (space: string) => {
    if (selectedSpaces.has(space))
      return "selected";
    return "";
  }

  const selectSpace = (space: string) => {
    if (selectedSpaces.has(space))
      selectedSpaces.delete(space);
    else
      selectedSpaces.add(space);
    setSelectedSpaces(new Set(selectedSpaces));
  }

  const displayDesktop = () => (
    <>
      <ContentsPageHeader>
          <span style={{fontWeight: 600, color: "#21272B"}}>Let’s start by selecting a your home’s key spaces.</span>
          <span>Select the main spaces where you have tasks, projects, Spaces and information you want to keep track of.</span>
      </ContentsPageHeader>
      <OnboardingSubtitle>Rooms</OnboardingSubtitle>
      <QuestionsButtonsSmall style={{margin: 0}}>
        <QuestionButtonSmall
          className={getClassForSpace("Kitchen")}
          onClick={() => selectSpace("Kitchen")}
        ><SpaceTypeKitchen/>Kitchen</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getClassForSpace("Bathroom")}
          onClick={() => selectSpace("Bathroom")}
        ><SpaceTypeBathroom/>Bathroom</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getClassForSpace("Living Room")}
          onClick={() => selectSpace("Living Room")}
        ><SpaceTypeLivingRoom/>Living Room</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getClassForSpace("Office")}
          onClick={() => selectSpace("Office")}
        ><SpaceTypeOffice/>Office</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getClassForSpace("Master Bedroom")}
          onClick={() => selectSpace("Master Bedroom")}
        ><SpaceTypeMasterBedroom/>Master Bedroom</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getClassForSpace("Bedroom 2")}
          onClick={() => selectSpace("Bedroom 2")}
        ><SpaceTypeBedroom/>Bedroom 2</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getClassForSpace("Bedroom 3")}
          onClick={() => selectSpace("Bedroom 3")}
        ><SpaceTypeBedroom/>Bedroom 3</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getClassForSpace("Bedroom 4")}
          onClick={() => selectSpace("Bedroom 4")}
        ><SpaceTypeBedroom/>Bedroom 4</QuestionButtonSmall>
      </QuestionsButtonsSmall>
      <OnboardingSubtitle>Outdoors</OnboardingSubtitle>
      <QuestionsButtonsSmall style={{margin: 0}}>
        <QuestionButtonSmall
          className={getClassForSpace("Roof")}
          onClick={() => selectSpace("Roof")}
        ><SpaceTypeRoof /> Roof</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getClassForSpace("Driveway")}
          onClick={() => selectSpace("Driveway")}
        ><SpaceTypeDriveway /> Driveway</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getClassForSpace("Shed")}
          onClick={() => selectSpace("Shed")}
        ><SpaceTypeShed /> Shed</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getClassForSpace("Garden")}
          onClick={() => selectSpace("Garden")}
        ><SpaceTypeGarden /> Garden</QuestionButtonSmall>
      </QuestionsButtonsSmall>
      <OnboardingSubtitle>Other spaces</OnboardingSubtitle>
      <QuestionsButtonsSmall style={{margin: 0}}>
        <QuestionButtonSmall
          className={getClassForSpace("Basement")}
          onClick={() => selectSpace("Basement")}
        ><SpaceTypeBasement /> Basement</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getClassForSpace("Attic/Loft")}
          onClick={() => selectSpace("Attic/Loft")}
        ><SpaceTypeAttic /> Attic/Loft</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getClassForSpace("Utility Cupboard")}
          onClick={() => selectSpace("Utility Cupboard")}
        ><SpaceTypeUtility /> Utility Cupboard</QuestionButtonSmall>
        <QuestionButtonSmall
          className={getClassForSpace("Garage")}
          onClick={() => selectSpace("Garage")}
        ><SpaceTypeGarage /> Garage</QuestionButtonSmall>
      </QuestionsButtonsSmall>
    </>
  );

  const displayMobile = () => (
    <>
      <div style={{width: "100%", padding: "0 2rem", marginBottom: "1rem"}}>
        <div style={{color:"#00CC33", fontWeight: 600, fontSize:"1.125rem"}}>Let’s start by selecting a your home’s key spaces.</div>
        <div style={{marginTop: "0.75rem", fontSize: "0.75rem", lineHeight: "1.125rem", color: "#666F7A"}}>
          Select the main spaces where you have tasks, projects, contents and information you want to keep track of.
        </div>
      </div>

      <div style={{color:"#00CC33", fontWeight: 600, fontSize:"1.125rem", margin:"1rem 0"}}>Indoors</div>
      <QuestionsButtonsMobile style={{padding: 0}}>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Kitchen")}
          onClick={() => selectSpace("Kitchen")}
        ><SpaceTypeKitchen/>Kitchen</QuestionButtonMobileSpaces>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Bathroom")}
          onClick={() => selectSpace("Bathroom")}
        ><SpaceTypeBathroom/>Bathroom</QuestionButtonMobileSpaces>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Living Room")}
          onClick={() => selectSpace("Living Room")}
        ><SpaceTypeLivingRoom/>Living Room</QuestionButtonMobileSpaces>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Office")}
          onClick={() => selectSpace("Office")}
        ><SpaceTypeOffice/>Office</QuestionButtonMobileSpaces>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Master Bedroom")}
          onClick={() => selectSpace("Master Bedroom")}
        ><SpaceTypeMasterBedroom/>Master Bedroom</QuestionButtonMobileSpaces>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Bedroom 2")}
          onClick={() => selectSpace("Bedroom 2")}
        ><SpaceTypeBedroom/>Bedroom 2</QuestionButtonMobileSpaces>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Bedroom 3")}
          onClick={() => selectSpace("Bedroom 3")}
        ><SpaceTypeBedroom/>Bedroom 3</QuestionButtonMobileSpaces>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Bedroom 4")}
          onClick={() => selectSpace("Bedroom 4")}
        ><SpaceTypeBedroom/>Bedroom 4</QuestionButtonMobileSpaces>
      </QuestionsButtonsMobile>

      <div style={{color:"#00CC33", fontWeight: 600, fontSize:"1.125rem", margin:"1rem 0"}}>Outdoors</div>
      <QuestionsButtonsMobile style={{padding: 0}}>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Roof")}
          onClick={() => selectSpace("Roof")}
        ><SpaceTypeRoof /> Roof</QuestionButtonMobileSpaces>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Driveway")}
          onClick={() => selectSpace("Driveway")}
        ><SpaceTypeDriveway /> Driveway</QuestionButtonMobileSpaces>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Shed")}
          onClick={() => selectSpace("Shed")}
        ><SpaceTypeShed /> Shed</QuestionButtonMobileSpaces>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Garden")}
          onClick={() => selectSpace("Garden")}
        ><SpaceTypeGarden /> Garden</QuestionButtonMobileSpaces>
      </QuestionsButtonsMobile>

      <div style={{color:"#00CC33", fontWeight: 600, fontSize:"1.125rem", margin:"1rem 0"}}>Other spaces</div>
      <QuestionsButtonsMobile style={{padding: 0}}>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Basement")}
          onClick={() => selectSpace("Basement")}
        ><SpaceTypeBasement /> Basement</QuestionButtonMobileSpaces>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Attic/Loft")}
          onClick={() => selectSpace("Attic/Loft")}
        ><SpaceTypeAttic /> Attic/Loft</QuestionButtonMobileSpaces>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Utility Cupboard")}
          onClick={() => selectSpace("Utility Cupboard")}
        ><SpaceTypeUtility /> Utility Cupboard</QuestionButtonMobileSpaces>
        <QuestionButtonMobileSpaces
          className={getClassForSpace("Garage")}
          onClick={() => selectSpace("Garage")}
        ><SpaceTypeGarage /> Garage</QuestionButtonMobileSpaces>
      </QuestionsButtonsMobile>
    </>
  );

  if (window.innerWidth < 1024)
    return displayMobile();

  return displayDesktop();
};


export default OnboardingSpaces;
