import { createReducer } from "typesafe-actions";
import produce from "immer";
import { TFetchingStatus } from "../../helpers";
import {emptyGuid} from "../screens/helpers";
import {TGetTagsWithUsageCountResponse} from "../../api-wrapper/tags/getTagsWithUsageCount";
import {
  getTagsWithUsageCountByTypeFailureAction, getTagsWithUsageCountByTypeInitAction,
  getTagsWithUsageCountByTypeSuccessAction, TGetTagsWithUsageCountByTypeActions
} from "../actions/tagsWithUsageCountByType";

export type TTagsWithUsageCountByType = {
  fetchingStatus: TFetchingStatus;
  content: TGetTagsWithUsageCountResponse;
};

const initialState: TTagsWithUsageCountByType = {
  content: [
    {
      tagId: emptyGuid,
      name: "",
      usageCount: 0,
      parentTagId: null,
      subscriptionId: null
    }
  ],
  fetchingStatus: TFetchingStatus.Defined,
};

export const tagsWithUsageCountByTypeReducer = createReducer<TTagsWithUsageCountByType, TGetTagsWithUsageCountByTypeActions>(
  initialState
)
  .handleAction(getTagsWithUsageCountByTypeInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getTagsWithUsageCountByTypeSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getTagsWithUsageCountByTypeFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
