import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

type TEditPropertyRequest = {
  nickname?: string | null;
  address: string;
  address2?: string | null;
  city: string;
  state?: string | null;
  country: string;
  zip: string;
  propertyId: Guid;
};

export const editProperty = (payload: TEditPropertyRequest) => {
  const { propertyId } = payload;
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}`);

  return apiPut<
    {
      nickname: string;
      address: string;
      address2: string;
      city: string;
      state: string;
      country: string;
      zip: string;
    },
    {}
  >(url, payload);
};
