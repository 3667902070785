import React, {useEffect, useState} from "react"
import {Button, Checkbox, Form, Input, Modal} from "antd";
import {Label} from "../screens/RegistrationPage";
import EmailCreatableSelector from "./EmailCreatableSelector";
import styled from "styled-components";
import {referFriends} from "../../api-wrapper/email/referFriends";
import {getRecipients, safetyRegex, transformRecipients} from "../screens/helpers";
import {referFriendsPreview} from "../../api-wrapper/email/referFriendsPreview";
import ReactHtmlParser from "react-html-parser"
import {useDispatch, useSelector} from "react-redux";
import {getUserProfileSelector} from "../selectors";
import {getCurrentUserProfile} from "../actions/getUserProfile";

type Props = {
  modalTitle: string,
  emailType: string,
  parentData: any,
  isModalOpen: boolean,
  toggleModal: (arg: boolean) => void
}

const SendViaEmail = (props: Props) => {
  const { modalTitle, emailType, isModalOpen, toggleModal } = props
  const [form] = Form.useForm();
  const userProfile = useSelector(getUserProfileSelector);
  const dispatch = useDispatch();

  const [username, setUsername] = useState("")
  const [customMessage, setCustomMessage] = useState("")
  const [ccSender, setCcSender] = useState(false)
  const [recipients, setRecipients] = useState<any>([])
  const [preview, setPreview] = useState("")

  const setEmailPreview = (response: any) => {
    response.data.body ? setPreview(response.data.body) : setPreview("<body>Your message will show up here.</body>")
  }

  useEffect(() => {
    userProfile.content.name && setUsername(userProfile.content.name)
  }, [userProfile])

  useEffect(() => {
    if (isModalOpen) {
      let sanitizedMessage = customMessage.replace(safetyRegex, '')
      if (emailType === "referFriends") {
        referFriendsPreview({
          customMessage: sanitizedMessage,
          ccSender,
          recipients: getRecipients(transformRecipients(recipients)),
          userName: username
        }).then((response: any) => setEmailPreview(response))
      }
    }
  }, [emailType, isModalOpen, recipients, customMessage, username])

  const clearForm = () => {
    setRecipients([])
    setCcSender(false)
    setCustomMessage("")
    setPreview("")
    toggleModal(false);
  }

  function showThankYouMessage() {
    Modal.success({
      title: "Thank you!",
      content:
        "Thank you for sharing a word about Livlet! We hope to be useful to your friend.",
      okText: "You're welcome!",
      className: "form-confirm-close-modal"
    });
  }

  function showErrorMessage(errorText: string) {
    Modal.error({
      title: "An error occurred while sending your email",
      content: errorText,
      className: "form-confirm-close-modal"
    });
  }

  function processErrorMessage(e: any) {
    if (e.status === 412) {
      showErrorMessage(e.data.message)
    }
    if (e.status === 424) {
      showErrorMessage(e.data[0].message)
    }
  }

  const handleOk = () => {
    let sanitizedMessage = customMessage.replace(safetyRegex, '')
    if (emailType === "referFriends") {
      referFriends({
        customMessage: sanitizedMessage,
        ccSender,
        recipients: transformRecipients(recipients),
        userName: username
      }).then(() => {
        showThankYouMessage()
        clearForm()
      }).catch(e => processErrorMessage(e))
    }
  };

  const handleCancel = () => {
    clearForm()
  };

  return (
      <EmailModal
        width={800}
        style={{top: 20}}
        title={modalTitle}
        visible={isModalOpen}
        mask={false}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<Button style={{marginLeft: '-3.8rem'}} type={'ghost'}>Cancel</Button>}
        footer={null}>
        <Form
          form={form}
          name="sendEmail"
          layout="vertical"
          size={"large"}
        >
        <section style={{
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "10px",
            padding: "1rem",
            marginTop: "0.5rem",
          }}
        >
          <Label style={{marginBottom: "0.25rem"}}>Your name: *</Label>
          <Form.Item>
            <Input
            style={{borderRadius: '5px'}}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Item>
          <Label style={{marginBottom: "0.25rem"}}>To: *</Label>
          <EmailCreatableSelector emailList={recipients} setEmailList={setRecipients} />
          <Checkbox
            style={{marginBottom: '1rem'}}
            checked={ccSender}
            onClick={() => setCcSender(!ccSender)}>
            CC me on this email
          </Checkbox>
          {recipients.length > 1 && ccSender && <p style={{fontWeight: 700, color: "#2a80e1"}}>
              Please note that you will receive {recipients.length} copies of this email.
          </p>}
          <Label>Message:</Label>
          <PreviewBox>{ReactHtmlParser(preview)}</PreviewBox>
          <Label style={{marginBottom: "0.25rem"}}>Your comments:</Label>
          <Form.Item>
            <Input
            style={{borderRadius: '5px'}}
            placeholder={"Add comments here..."}
            value={customMessage}
            onChange={(e) => setCustomMessage(e.target.value)}
          />
          </Form.Item>
          </section>
          <div style={{textAlign: 'center', marginTop: "1.2rem"}}>
            <Button
              style={{borderRadius: '6px'}}
              type={"primary"}
              onClick={() => handleOk()}
              disabled={recipients.length === 0 || username.length === 0}
            >Send</Button>
          </div>
        </Form>
      </EmailModal>
  )
}

export const EmailModal = styled(Modal)`
  & .ant-modal-header {
    background: #ebf9ff;
  }
  
  & .ant-modal-body {
    padding: 1rem;
    padding-bottom: 1.5rem;
  }
`;

export const PreviewBox = styled.div`
  margin-bottom: 0.75rem;
  
  .preview-wrapper {
    max-width: 100%!important;
  }
`

export default SendViaEmail