import { apiDelete, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export const removeContact = (propertyId: Guid, contactId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/contacts/${contactId}`
  );

  return apiDelete(url);
};
