import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {
  getInventories,
  TGetInventoriesResponse,
} from "../../api-wrapper/inventories/getInventories";
import {Guid} from "guid-typescript";

export const getInventoriesInitAction = createAction(
  "Inventories/GET_Inventories_INIT"
)();
export const getInventoriesSuccessAction = createAction(
  "Inventories/GET_Inventories_SUCCESS"
)<TGetInventoriesResponse>();
export const getInventoriesFailureAction = createAction(
  "Inventories/GET_Inventories_FAILURE"
)();

export type TGetInventoriesInitAction = ActionType<
  typeof getInventoriesInitAction
>;
export type TGetInventoriesSuccessAction = ActionType<
  typeof getInventoriesSuccessAction
>;
export type TGetInventoriesFailureAction = ActionType<
  typeof getInventoriesFailureAction
>;

export type TGetInventoriesActions =
  | TGetInventoriesInitAction
  | TGetInventoriesSuccessAction
  | TGetInventoriesFailureAction;

export type TGetInventoriesThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetInventoriesActions>;

export const getAllInventories: TGetInventoriesThunkActionType = (
  propertyId: Guid
) => (dispatch: (action: Action | Promise<Action>) => any) => {
  dispatch(getInventoriesInitAction());
  return getInventories(propertyId)
    .then((payload) => {
      return dispatch(getInventoriesSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getInventoriesFailureAction()));
};
