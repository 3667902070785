import { defineMessages } from "react-intl";

const messages = defineMessages({
  contactsList: {
    id: "app.contacts.contactsList",
    defaultMessage: "Contacts list",
  },
  addNotesTitle: {
    id: "app.contacts.addNotesTitle",
    defaultMessage: "Add and manage notes",
  },
  addContactTitle: {
    id: "app.contacts.addContactTitle",
    defaultMessage: "Add and manage contacts",
  },
  addNotesMsg: {
    id: "app.works.addNotesMsg",
    defaultMessage:
      "Write down home events, important information, instructions, ideas or details for your future reference.",
  },
  addContactMsg: {
    id: "app.works.addExpenseMsg",
    defaultMessage:
      "Add personal and professional contacts relevant to your home and link them to your works and expenses.",
  },
  addNote: {
    id: "app.notes.addNote",
    defaultMessage: "Add a note",
  },
  addContact: {
    id: "app.myProperty.addContact",
    defaultMessage: "Add a contact",
  },
  addTask: {
    id: "app.myProperty.addTask",
    defaultMessage: "Add task",
  },
  myProperty: {
    id: "app.myProperty.myProperty",
    defaultMessage: "My Property",
  },
  addInventoryBtn: {
    id: "app.inventory.addInventoryBtn",
    defaultMessage: "Add contents",
  },
  validationCostOfExpense: {
    id: "app.worksPage.validationCostOfExpense",
    defaultMessage: "Cost of expense value is invalid.",
  },
  validationCostOfInventory: {
    id: "app.worksPage.validationCostOfInventory",
    defaultMessage: "Cost of purchase value is invalid.",
  },
  validationTypeOfInventory: {
    id: "app.worksPage.validationTypeOfInventory",
    defaultMessage: "Type of inventory value is invalid.",
  },
  validationDateOfExpense: {
    id: "app.worksPage.validationDateOfExpense",
    defaultMessage: "Date of expense is required.",
  },
  validationTypeOfExpense: {
    id: "app.worksPage.validationTypeOfExpense",
    defaultMessage: "Type of expense value is invalid.",
  },
  validationTypeOfWork: {
    id: "app.worksPage.validationTypeOfWork",
    defaultMessage: "Type of project value is invalid.",
  },
  validationCompletedDate: {
    id: "app.worksPage.validationCompletedDate",
    defaultMessage: "Completed date is invalid.",
  },
  dueDate: {
    id: "app.worksPage.dueDate",
    defaultMessage: "Due date",
  },
  recurring: {
    id: "app.worksPage.recurring",
    defaultMessage: "Recurring",
  },
  loginTitle: {
    id: "app.loginPage.loginTitle",
    defaultMessage: "Sign in to continue",
  },
  loginSubtitle: {
    id: "app.loginPage.loginSubtitle1",
    defaultMessage: "Or {registrationLink} your details to start using Livlet.",
  },
  registrationFooter: {
    id: "app.registrationPage.registrationFooter",
    defaultMessage: "Already have an account? {loginLink} to login.",
  },
  loginForgottenPassword: {
    id: "app.loginPage.loginForgottenPassword",
    defaultMessage: "Forgot password?",
  },
  resetPasswordTitle: {
    id: "app.passwordReset.resetPasswordTitle",
    defaultMessage: "Find your account",
  },
  resetPasswordSubtitle: {
    id: "app.passwordReset.resetPasswordSubtitle",
    defaultMessage: "Please enter your email below to reset your password",
  },
  resetPasswordRequestSuccess: {
    id: "app.passwordReset.resetPasswordRequestSuccess",
    defaultMessage:
      "We will send password reset instructions if we have an account with that email address. If you don't receive an email from us, please check your spam folder.",
  },
  updatePasswordTitle: {
    id: "app.passwordReset.updatePasswordTitle",
    defaultMessage: "Reset your password",
  },
  updatePasswordSubtitle: {
    id: "app.passwordReset.updatePasswordSubtitle",
    defaultMessage: "Please enter and confirm your new password",
  },
  updatePasswordExpiredLink: {
    id: "app.passwordReset.updatePasswordExpiredLink",
    defaultMessage:
      "Your password reset link has expired. {passwordResetLink} to reset your password.",
  },
  updatePasswordExpiredRedirect: {
    id: "app.passwordReset.updatePasswordExpiredRedirect",
    defaultMessage:
      "Your password reset link has expired. Use {passwordResetLink} to receive a fresh link.",
  },
  addAWork: {
    id: "app.worksPage.addAWork",
    defaultMessage: "+ Add a project",
  },
  loginSubtitleLink: {
    id: "app.loginPage.loginSubtitle2",
    defaultMessage: "register",
  },
  signIn: {
    id: "app.loginPage.signIn",
    defaultMessage: "Sign in",
  },
  backToDashboard: {
    id: "app.dashboardPage.backToDashboard",
    defaultMessage: "BACK TO DASHBOARD",
  },
  goodMorning: {
    id: "app.registrationPage.goodMorning",
    defaultMessage: "Hi, {userName}",
  },
  error: {
    id: "app.registrationPage.error",
    defaultMessage: "Error.",
  },
  success: {
    id: "app.registrationPage.success",
    defaultMessage: "Success!",
  },
  loginCredentialsError: {
    id: "app.loginPage.loginCredentialsError",
    defaultMessage: "Login or password is incorrect.",
  },
  loginActivationError: {
    id: "app.loginPage.loginActivationError",
    defaultMessage: "Your account has not been activated. Click {activationLink} if you haven't received your activation email.",
  },
  registrationError: {
    id: "app.registrationPage.registrationError",
    defaultMessage: "Inputs data are invalid.",
  },
  registrationComplete: {
    id: "app.registrationPage.registrationComplete",
    defaultMessage: "You have been successfully registered! Please check your email to activate your account.",
  },
  imageText: {
    id: "app.registrationPage.imageText",
    defaultMessage: "Unlock the door to better property management.",
  },
  settingUpProfile: {
    id: "app.loadingPage.settingUpProfile",
    defaultMessage: "Setting up profile...",
  },
  countryError: {
    id: "app.registrationPage.countryError",
    defaultMessage: "Country value is invalid.",
  },
  confirmationPasswordError: {
    id: "app.registrationPage.confirmationPasswordError",
    defaultMessage: "Re-entered password does not match password.",
  },
  validationStreetAddress: {
    id: "app.registrationPage.validationStreetAddress",
    defaultMessage: "Street address is invalid.",
  },
  validationCity: {
    id: "app.registrationPage.validationCity",
    defaultMessage: "Town is invalid.",
  },
  subtitle: {
    id: "app.dashboardPage.subtitle",
    defaultMessage: "Here are some tips to help you get started:",
  },
  city: {
    id: "app.registrationPage.city",
    defaultMessage: "Town *",
  },
  enterCity: {
    id: "app.registrationPage.enterCity",
    defaultMessage: "Enter town",
  },
  state: {
    id: "app.registrationPage.state",
    defaultMessage: "County",
  },
  validationState: {
    id: "app.registrationPage.validationState",
    defaultMessage: "County is invalid.",
  },
  enterState: {
    id: "app.registrationPage.enterState",
    defaultMessage: "Enter Province",
  },
  country: {
    id: "app.registrationPage.country",
    defaultMessage: "Country *",
  },
  validationCountry: {
    id: "app.registrationPage.validationCountry",
    defaultMessage: "Country is invalid.",
  },
  pleaseSelect: {
    id: "app.registrationPage.pleaseSelect",
    defaultMessage: "Please select",
  },
  postcode: {
    id: "app.registrationPage.postcode",
    defaultMessage: "Postcode *",
  },
  validationPostcode: {
    id: "app.registrationPage.validationPostcode",
    defaultMessage: "Postcode is invalid.",
  },
  enterPostcode: {
    id: "app.registrationPage.enterPostcode",
    defaultMessage: "Enter postcode",
  },
  back: {
    id: "app.registrationPage.back",
    defaultMessage: "Back",
  },
  signup: {
    id: "app.registrationPage.signup",
    defaultMessage: "Sign up",
  },
  loginEmailAddress: {
    id: "app.login.emailAddress",
    defaultMessage: "E-mail",
  },
  password: {
    id: "app.login.password",
    defaultMessage: "Password",
  },
  findAddress: {
    id: "app.registrationPage.findAddress",
    defaultMessage: "Find address",
  },
  address: {
    id: "app.registrationPage.address",
    defaultMessage: "Address {addressNumber}",
  },
  validationTermsAndConditions: {
    id: "app.registrationPage.validationTermsAndConditions",
    defaultMessage: "Terms and Conditions should be accepted.",
  },
  validationName: {
    id: "app.registrationPage.validationName",
    defaultMessage: "Name value is invalid.",
  },
  searchForAddress: {
    id: "app.registrationPage.searchForAddress",
    defaultMessage: "Search for address...",
  },
  steps: {
    id: "app.registrationPage.steps",
    defaultMessage: "Step {stepNumber} of 2",
  },
  enterFullName: {
    id: "app.registrationPage.enterFullName",
    defaultMessage: "Enter full name",
  },
  enterAddress: {
    id: "app.registrationPage.enterAddress",
    defaultMessage: "Enter address",
  },
  enterEmailAddress: {
    id: "app.registrationPage.enterEmailAddress",
    defaultMessage: "Enter email address",
  },
  createPassword: {
    id: "app.registrationPage.createPassword",
    defaultMessage: "Create a password *",
  },
  reEnterPassword: {
    id: "app.registrationPage.reEnterPassword",
    defaultMessage: "Re-enter password *",
  },
  enterPassword: {
    id: "app.registrationPage.enterPassword",
    defaultMessage: "Enter password",
  },
  termAndConditionsText: {
    id: "app.registrationPage.termAndConditionsText",
    defaultMessage: "I agree to the {link} *",
  },
  termAndConditionsLink: {
    id: "app.registrationPage.termAndConditionsLink",
    defaultMessage: "terms and conditions",
  },
  cancel: { id: "app.registrationPage.cancel", defaultMessage: "Cancel" },
  next: { id: "app.registrationPage.next", defaultMessage: "Next" },
  reset: { id: "app.resetPasswordPage.reset", defaultMessage: "Reset" },
  clickHere: {
    id: "app.resetPasswordPage.reset",
    defaultMessage: "Click here",
  },
  welcomeToLivLetDescriptionLink: {
    id: "app.dashboard.welcomeToLivLetDescriptionLink",
    defaultMessage: "info@livlet.com",
  },
  firstStepTitle: {
    id: "app.registrationPage.title",
    defaultMessage: "Let’s create your Livlet account",
  },
  firstStepSubtitle: {
    id: "app.registrationPage.subtitle",
    defaultMessage: "Firstly, we need a few details to setup your account.",
  },
  name: { id: "app.registrationPage.name", defaultMessage: "Name" },
  secondStepSubtitle: {
    id: "app.registrationPage.secondStepSubtitle",
    defaultMessage: "Search for an address or enter it manually.",
  },
  emailAddress: {
    id: "app.registrationPage.emailAddress",
    defaultMessage: "Email address",
  },
  secondStepTitle: {
    id: "app.registrationPage.title",
    defaultMessage: "Add a property to your account",
  },
  logIn: {
    id: "app.loginPage.LogIn",
    defaultMessage: "Log in",
  },
  forgotPassword: {
    id: "app.loginPage.forgotPassword",
    defaultMessage: "Forgot password",
  },
  rememberMe: {
    id: "app.loginPage.rememberMe",
    defaultMessage: "Remember me",
  },
  validationPassword: {
    id: "app.loginPage.validationPassword",
    defaultMessage: "Please provide your password.",
  },
  validationEmail: {
    id: "app.loginPage.validationEmail",
    defaultMessage: "Please provide a valid email address.",
  },
  formTitle: {
    id: "app.loginPage.formTitle",
    defaultMessage: "Log in to your account",
  },
  emptyFieldDescription: {
    id: "app.loginPage.emptyFields.error.description",
    defaultMessage: "All fields are mandatory, please try to log in again.",
  },
  emptyFieldTitle: {
    id: "app.loginPage.emptyFields.error.title",
    defaultMessage: "Error message",
  },
  completedRecurringWork: {
    id: "app.works.completedRecurringWorkTitle",
    defaultMessage: "Completed recurring project",
  },
  completedRecurringWorkMsg: {
    id: "app.works.completedRecurringWorkMsg",
    defaultMessage:
      "You’ve completed a recurring project, so we’ve created a new project based on the recurring period.",
  },
  markWorkUncompleted: {
    id: "app.works.markWorkUncompleted",
    defaultMessage: "Mark as uncompleted",
  },
  markWorkCompleted: {
    id: "app.works.markWorkCompleted",
    defaultMessage: "Mark as completed",
  },
  completedWorksTitle: {
    id: "app.works.completedWorksTitle",
    defaultMessage: "Completed",
  },
  todoWorksTitle: {
    id: "app.works.todoWorksTitle",
    defaultMessage: "Current and upcoming",
  },
  addWorkTitle: {
    id: "app.works.addWorkTitle",
    defaultMessage: "Your works control centre",
  },
  addWorkMsg: {
    id: "app.works.addWorkMsg",
    defaultMessage:
      "Thinking about making repairs or improving your home? \n" +
      "This is where you can manage all your projects.\n" +
      "Start adding to your Projects list now.\n",
  },
  addNickname: {
    id: "app.myProperty.addNickname",
    defaultMessage: "Add Name",
  },
  editNickname: {
    id: "app.myProperty.editNickname",
    defaultMessage: "Edit Name",
  },
  addNoteBtn: {
    id: "app.works.addNoteBtn",
    defaultMessage: "Add a note",
  },
  addWorkBtn: {
    id: "app.works.addWorkBtn",
    defaultMessage: "Add a project",
  },
  addExpenseTitle: {
    id: "app.works.addExpenseTitle",
    defaultMessage: "Keep an eye on expenses",
  },
  addExpenseMsg: {
    id: "app.works.addExpenseMsg",
    defaultMessage:
      "Being in control of your outgoings is a smart move. \n" +
      "Here's where you can manage all your property spending.\n" +
      "Start adding to your expenses list now.\n",
  },
  addExpensesBtn: {
    id: "app.works.addExpenseBtn",
    defaultMessage: "Add an Expense",
  },
  addInvoiceBtn: {
    id: "app.works.addInvoiceBtn",
    defaultMessage: "Upload Invoice",
  },
  addInventoryTitle: {
    id: "app.works.addInventoryTitle",
    defaultMessage: "Your inventory checklist",
  },
  addInventoryMsg: {
    id: "app.works.addInventoryMsg",
    defaultMessage:
      "Imagine if you knew everything about things like appliances, electronics, furniture, systems, tools and machinery. \n" +
      "Here's where you can keep tabs on the value of your goods, have a handle on their warranty periods, and " +
      "track expenses on repairs and maintenance. \n Start adding to your Inventory now.",
  },
  noRemindersAddedTitle: {
    id: "app.reminders.noRemindersAddedTitle",
    defaultMessage: "No reminders currently added",
  },
  noRemindersAddedSubtitle: {
    id: "app.reminders.noRemindersAddedSubtitle",
    defaultMessage:
      "This is where you can add and manage projects. This can help you manage and keep track of quotes.",
  },
  addNewReminderBtn: {
    id: "app.reminders.addNewReminderBtn",
    defaultMessage: "Add a new reminder",
  },
  addNewBtn: {
    id: "app.reminders.addNewBtn",
    defaultMessage: "Add new",
  },
  welcomeToLivLetTitle: {
    id: "app.dashboard.welcomeToLivLetTitle",
    defaultMessage: "Welcome to the Livlet community",
  },
  welcomeToLivLetDescription: {
    id: "welcomeToLivLetDescription",
    defaultMessage:
      "We look forward to helping you sort the hassles and headaches of managing your " +
      "home while boosting the performance and value of your property.\n \n" +
      "Help us to help you - the more we know about your home, the better the tips and efficiencies we can pass on.\n" +
      "If you have any questions along the way, just drop us an email at {emailLink}.",
  },
  reminderPopupMsg: {
    id: "app.works.reminderPopupMsg",
    defaultMessage: "Create a reminder with a date one week prior to project due date"
  },
  upcomingTasksAndRemindersMsg: {
    id: "app.header.upcomingTasksAndRemindersHeader",
    defaultMessage: "Upcoming tasks and reminders"
  },
  viewAllTasksAndRemindersMsg: {
    id: "app.header.recentTasksAndRemindersFooter",
    defaultMessage: "View all upcoming tasks and reminders"
  },
  recentRemindersMsg: {
    id: "app.header.recentRemindersMsg",
    defaultMessage: "Recent reminders"
  },
  viewAllRemindersMsg: {
    id: "app.header.viewAllRemindersMsg",
    defaultMessage: "View all reminders"
  },
  cannotCreateReminderDueDateMsg: {
    id: "app.reminders.cannotCreateReminderDueDateMsg",
    defaultMessage: "You cannot create a reminder for a project without a due date"
  },
  cannotCreateReminderCompletedMsg: {
    id: "app.reminders.cannotCreateReminderCompletedMsg",
    defaultMessage: "You cannot create a reminder for a project that has been completed"
  },
  activationSuccessTitle: {
    id: "app.activation.activationSuccessTitle",
    defaultMessage: "Successfully activated!"
  },
  activationSuccessSubtitle: {
    id: "app.activation.activationSuccessSubtitle",
    defaultMessage: "Your account has been activated successfully! A confirmation email will be sent your way."
  },
  activationLoginSubtitle: {
    id: "app.activation.activationLoginSubtitle",
    defaultMessage: "Now, just click {loginLink} to login."
  },
  activationFailureTitle: {
    id: "app.activation.activationFailureTitle",
    defaultMessage: "Expired link"
  },
  activationFailureSubtitle: {
    id: "app.activation.activationFailureSubtitle",
    defaultMessage: "Your activation link has expired. Click {activationLink} to receive a new one."
  },
  resentEmailMessage: {
    id: "app.activation.resentEmailMessage",
    defaultMessage: "We have sent you an email with the link to activate your account. If you don't see it in your Inbox, check the Spam folder."
  },
  registerWithSocialSubtitle: {
    id: "app.register.registerWithSocialSubtitle",
    defaultMessage:
      "We'll use your {socialMedia} details to create your Livlet account.",
  },
});

export default messages;
