import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {ReactComponent as SpaceFloors} from "../../../images/records/SpaceFloors.svg";
import {ReactComponent as SpaceRecord} from "../../../images/records/SpaceRecord.svg";
import {ReactComponent as SpaceRooms} from "../../../images/records/SpaceRooms.svg";
import {ReactComponent as SpaceSpaces} from "../../../images/records/SpaceSpaces.svg";
import {useSelector} from "react-redux";
import {getPropertyIdSelector, getSpacesSelector} from "../../selectors";
import {getFloors} from "../../../api-wrapper/spaces/getFloors";
import {useHistory} from "react-router-dom";
import {ReactComponent as PlusIcon} from "../../../images/records/PlusIcon.svg";
import {Badge} from "../navigation/MenuLivlet";

type Props = {
  toggleDrawerOpen: (arg: boolean) => void;
}

const SpaceRecordsCard = (props: Props) => {
  const {toggleDrawerOpen} = props;

  const spaces = useSelector(getSpacesSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const history = useHistory();

  const [roomCount, setRoomCount] = useState(0);
  const [spaceCount, setSpaceCount] = useState(0);
  const [floorCount, setFloorCount] = useState(0);

  useEffect(() => {
    setRoomCount(spaces.content.filter((space) => space.category === "Indoors").length)
    setSpaceCount(spaces.content.filter((space) => space.category === "Outdoors").length)
    getFloors(propertyId).then((res: any) => setFloorCount(res.length))
  }, [propertyId, spaces])

  return <RecordsCard
    className={"space-records"}
    onClick={() => history.push("/spaces")}
  >
    <CardTitle>
      <SpaceRecord /> Spaces
      <PlusIconWrapper onClick={(e) => {
        e.stopPropagation()
        toggleDrawerOpen(true)
      }}><PlusIcon /></PlusIconWrapper>
    </CardTitle>
    <CardContentsWrapper>
      <CardContentsItem className={"fill"} onClick={(e) => {
        e.stopPropagation()
        history.push("/spaces?type=indoors")
      }}>
        <span className={"item-count"}><SpaceRooms /> {roomCount}</span> Rooms
      </CardContentsItem>
      <CardContentsItem className={"fill"}>
        <span className={"item-count"}><SpaceFloors /> {floorCount}</span> Floors
      </CardContentsItem>
      <CardContentsItem className={"stroke"} onClick={(e) => {
        e.stopPropagation()
        history.push("/spaces?type=outdoors")
      }}>
        <span className={"item-count"}><SpaceSpaces /> {spaceCount}</span> Other spaces
      </CardContentsItem>
    </CardContentsWrapper>
  </RecordsCard>
}

export const RecordsCard = styled.div`
  width: 100%;
  background: #FFFFFF;
  border-radius: 24px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  transition: 1s all ease;
  
  &.space-records { border: 2px solid #7472D7; }
  &.space-records:hover { background: #CCCEF5; }
  &.contact-records { border: 2px solid rgba(255, 73, 73, 0.6); }
  &.contact-records:hover { background: #FFDCDC; }
  &.cost-records { border: 2px solid rgba(66, 222, 232, 0.6); }
  &.cost-records:hover { background: #D3EBF5; }
  &.file-records { border: 2px solid rgba(103, 193, 139, 0.6); }
  &.file-records:hover { background: #CCF5D6; }
  &.note-records { border: 2px solid rgba(239, 195, 93, 0.6); }
  &.note-records:hover { background: #F8ECCA; }
  &.content-records { border: 2px solid rgba(144, 42, 225, 0.6); }
  &.content-records:hover { background: #D0A8F0; }
  &.work-records { border: 2px solid rgba(42, 128, 225, 0.6); }
  &.work-records:hover { background: #A0CDFF; }
  &.property-records { border: 2px solid rgba(88, 162, 145, 0.6); }
  &.property-records:hover { background: #A3E0D2; }
  &:hover { border: 2px solid white!important; }
  
  @media (max-width: 1024px) {
    width: 100%;
    border: 2px solid white!important;
    
    &.space-records { background: #CCCEF5; }
    &.contact-records { background: #FFDCDC; }
    &.cost-records { background: #D3EBF5; }
    &.file-records { background: #CCF5D6; }
    &.note-records { background: #F8ECCA; }
    &.content-records { background: #D0A8F0; }
    &.work-records { background: #A0CDFF; }
    &.property-records { background: #A3E0D2; }
  }
`

export const CardTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 2rem;
  color: #3E5D58;
`

export const CardContentsWrapper = styled.div`
  width: 100%;
  display: flex;
  column-gap: 0.25rem;
`

export const CardContentsItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  background: #F5F6FA;
  border-radius: 6px;
  gap: 0.25rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #3E5D58;
  border: 1px solid #F5F6FA;
  transition: .1s all ease;
  
  .item-count {
    display: flex;
    column-gap: 0.25rem;
  }
  
  &:hover { 
    border: 1px solid #00B74F;
    color: #00B74F;
  }
  
  &.fill:hover svg path { 
    fill: #00B74F;
    transition: .1s all ease;
  }
  
  &.stroke:hover svg path { 
    stroke: #00B74F;
    transition: .1s all ease;
  }
  
  @media (max-width: 1024px) {
    flex-direction: row;
  }
`

export const PlusIconWrapper = styled.div`
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  background-color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover svg path { 
    fill: #00B74F;
    transition: .1s all ease;
  }
`

export const AlertWrapper = styled.div`
  position: relative;
`

export const AlertDot = styled(Badge)`
  position: absolute;
  background: #EFC35D;
`

export const ErrorDot = styled(Badge)`
  position: absolute;
  right: 0;
`

export const AlertOverlay = styled.div`
  background: white;
  padding: 0.25rem 0.5rem;
  border-radius: 8px;
  font-size: 0.75rem;
  color: #3E5D58;
`

export default SpaceRecordsCard