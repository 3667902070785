import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import { getMortgages, TGetMortgagesResponse } from "../../api-wrapper/mortgages/getMortgages";
import {Guid} from "guid-typescript";

export const getMortgagesInitAction = createAction("mortgages/GET_MORTGAGES_INIT")();
export const getMortgagesSuccessAction = createAction(
  "mortgages/GET_MORTGAGES_SUCCESS"
)<TGetMortgagesResponse>();
export const getMortgagesFailureAction = createAction("mortgages/GET_MORTGAGES_FAILURE")();

export type TGetMortgagesInitAction = ActionType<typeof getMortgagesInitAction>;
export type TGetMortgagesSuccessAction = ActionType<typeof getMortgagesSuccessAction>;
export type TGetMortgagesFailureAction = ActionType<typeof getMortgagesFailureAction>;

export type TGetMortgagesActions =
  | TGetMortgagesInitAction
  | TGetMortgagesSuccessAction
  | TGetMortgagesFailureAction;

export type TGetMortgagesThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetMortgagesActions>;

export const getAllMortgages: TGetMortgagesThunkActionType = (propertyId: Guid) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getMortgagesInitAction());
  return getMortgages(propertyId)
    .then((payload) => {
      return dispatch(getMortgagesSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getMortgagesFailureAction()));
};
