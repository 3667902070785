import _ from "lodash";

import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TAddExpenseResponse = {
  name: string;
  expenseId: Guid;
  propertyId: Guid;
  type: Guid | string;
  payee: Guid;
  paidBy: Guid;
  totalCost: number;
  paymentDate: string;
  isTaxDeductible: boolean;
  isConfirmed: boolean;
  parentId: Guid;
  parentType: string;
  notes: string;
  tags: Array<any>;
  userPaidBy: number | null;
  contactPaidBy: Guid | null;
};

export type TAddExpenseRequest = {
  name?: string | null;
  type?: Guid | string;
  payee?: Guid | null;
  totalCost?: number | null;
  paymentDate?: string | null;
  isTaxDeductible?: boolean | null;
  isConfirmed: boolean;
  userPaidBy?: number | null;
  contactPaidBy?: Guid | null;
};

export const addExpense = (
  payload: TAddExpenseRequest & { propertyId: Guid }
) => {
  const { propertyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/expense`
  );

  return apiPost<TAddExpenseResponse, TAddExpenseRequest>(
    url,
    _.omit(payload, ["propertyId"])
  );
};
