import {Button, Drawer, Select} from "antd";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import _ from "lodash";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { ReactComponent as InventoriesIcon } from "../../../images/ContentDrawerIcon.svg";
import { getPropertyIdSelector, getWorksSelector } from "../../selectors";
import { addRelation } from "../../../api-wrapper/relation/addRelation";
import {Guid} from "guid-typescript";
import {CloseButton} from "../works/WorkDrawer";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  refreshParent: () => void;
  width?: number | null;
  parentId: Guid;
  drawerTitle: string;
  parentType: string;
  childType: string;
  icon?: ReactNode | null;
};

const AddExistingWorkDrawer = (props: Props) => {
  const {
    isOpen,
    toggleDrawerOpen,
    parentId,
    refreshParent,
    width,
    drawerTitle,
    parentType,
    childType,
    icon,
  } = props;
  const propertyId = useSelector(getPropertyIdSelector).value;
  const works = useSelector(getWorksSelector);
  const titleInput = useRef(null);

  const [relatedItems, setRelatedItems] = useState<{ key: Guid; value: string }[]>([]);
  const [workId, setWorkId] = useState<any>(null);

  const clearDrawer = () => {
    toggleDrawerOpen(false);
    setWorkId(null);
  }

  const addWork = () => {
    let parentIdTmp = parentId;
    let childId = workId;
    if (childType === "contact") {
      parentIdTmp = workId;
      childId = parentId;
    } else if (childType === "inventory") {
      parentIdTmp = workId;
      childId = parentId;
    }
    workId &&
      addRelation({
        propertyId,
        parentId: parentIdTmp,
        childId,
        parentType,
        childType,
      }).then(() => {
        refreshParent();
        clearDrawer();
      });
  };

  const onWorkAutocompleteSearch = (searchText: string) => {
    setRelatedItems(!searchText ? works.content.filter(work => work.name).map((work) => {
      return {key: work.workId, value: work.name}
    }) : works.content.filter(work => work.name).filter((work) => work.name.toLowerCase().includes(searchText.toLowerCase()))
      .map((work) => {
        return {key: work.workId, value: work.name}
      }))}

  useEffect(() => {
    setRelatedItems(
      works.content.filter(work => work.name).map((work) => {
        return {key: work.workId, value: work.name}
      })
    )
  }, [works]);

  useEffect(() => {
    // @ts-ignore
    titleInput.current !== null && titleInput.current.focus();
    // @ts-ignore
    titleInput.current !== null && titleInput.current.setSelectionRange(0, 0)
  }, [isOpen, titleInput])

  return (
    <Drawer
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? (width ? width + "%" : "55%") : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => clearDrawer()}
      headerStyle={{backgroundColor: "#ebf9ff", margin: 0, padding: "2rem 2rem" }}
      title={<>
          <section style={{ display: "flex", justifyContent: "space-between" }}>
            <InventoriesIconWrapper>
              {icon ? icon : <InventoriesIcon />}{" "}
              <span style={{ paddingRight: "0.25rem" }}>{drawerTitle}</span>
            </InventoriesIconWrapper>
            <CloseButton size={"large"} onClick={() => clearDrawer()}>
              {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
            </CloseButton>
          </section>
          <Title>Add project</Title>
        </>}
    >
        <FormHeader>Select existing project</FormHeader>
        <FormWrapper>
          <FieldLabel>Search your project</FieldLabel>
          <Select
            placeholder={"Search for project..."}
            showSearch
            size={"large"}
            bordered={false}
            showArrow={false}
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={onWorkAutocompleteSearch}
            value={workId}
            style={{
              width: "100%",
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "6px",
              color: "rgb(107,113,133)",
            }}
            onChange={(val) => setWorkId(val)}
          >
            {relatedItems.map((relateItem) => (
              <Select.Option value={relateItem.key.toString()}>
                {relateItem.value}
              </Select.Option>
            ))}
          </Select>
        </FormWrapper>
      <ButtonWrapper>
        <ButtonStyled
          disabled={_.isEmpty(workId)}
          htmlType={"submit"}
          type={"primary"}
          size={"large"}
          onClick={addWork}
        >
          + Add project
        </ButtonStyled>
      </ButtonWrapper>
    </Drawer>
  );
};

export const FormHeader = styled.h5`
  font-size: 1rem;
  color: #21272B;
  padding-bottom: 1rem;
`

export const FormWrapper = styled.div`
  border: 1px solid #DAE0E6;
  border-radius: 10px;
  padding: 1rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
`;

export const FieldLabel = styled.p`
  font-weight: 600;
  color: #21272B;
  font-size: 0.875rem;
`

export const Title = styled.div`
  border: none;
  outline: none;
  background-color: #ebf9ff;
  margin-top: 1.5rem;
  color: #002a43;
  font-size: 2.5rem;

  & > *:focus {
    border-color: #57a8e9;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }

  .ant-input:hover {
    border-color: #ebf9ff;
  }
  
  @media (max-width: 1024px) {
    font-size: 1.625rem;
  }
`;

export const InventoriesIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

export const ButtonStyled = styled(Button)`
  border-radius: 6px;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
  transition: .5s all ease;
  
  &:disabled { color: #d9d9d9!important; }
`;

export default AddExistingWorkDrawer;
