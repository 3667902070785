import React, {useState} from "react";
import {Dropdown, Menu, Popover} from "antd";
import {ReactComponent as MoreMenuGuidanceIcon} from "../../../images/guidance/MoreMenuGuidanceIcon.svg";
import {ReactComponent as WishlistAddedGuidanceIcon} from "../../../images/guidance/WishlistAddedGuidanceIcon.svg";
import {ReactComponent as WishlistGreenGuidanceIcon} from "../../../images/guidance/WishlistGreenGuidanceIcon.svg";
import {ReactComponent as WishlistGuidanceIcon} from "../../../images/guidance/WishlistGuidanceIcon.svg";
import { ReactComponent as MoreMenu } from "../../../images/More.svg";
import {ReactComponent as GuidanceMobileHeartGreen} from "../../../images/guidance/GuidanceMobileHeartGreen.svg";
import {ReactComponent as GuidanceMobileHeartOutline} from "../../../images/guidance/GuidanceMobileHeartOutline.svg";
import {ReactComponent as GuidanceMobileHeartRed} from "../../../images/guidance/GuidanceMobileHeartRed.svg";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import {MenuStyled} from "../works/WorksListComp";
import styled from "styled-components";
import GuidanceRating from "./GuidanceRating";

type Props = {
  item: any;
  categories: any;
  improvements: any;
  selectImprovement: (arg1: number, arg2: string) => void;
  manageWishlistStatus: (arg: any) => void;
  manageImprovementStatus: (arg: any) => void;
  manageApplicableStatus: (arg: any) => void;
}

const GuidanceListItem = (props: Props) => {
  const {item, categories, improvements, selectImprovement,
    manageWishlistStatus, manageImprovementStatus, manageApplicableStatus} = props;

  const [isMenuVisible, toggleMenuVisibility] = useState(false)

  const moreMenuDropdown = (item: any) => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const {key} = event;
      if (key === "markAsDone") {
        manageImprovementStatus(item)
        toggleMenuVisibility(false)
      } else if (key === "markAsNA") {
        manageApplicableStatus(item)
        toggleMenuVisibility(false)
      }
    };

    return (
      isMenuVisible ? <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        {!item.doneByAnswerOn && (item.isApplicable === undefined || item.isApplicable === true) && <Menu.Item key="markAsDone">
          {item.doneOn ? "Mark as incomplete" : "Mark as complete"}
        </Menu.Item>}
        {item.doneByAnswerOn && (item.isApplicable === undefined || item.isApplicable === true) && <Menu.Item key="doneMyAnswer">
            <Dropdown overlay={<WishlistOverlay>
              Done based on provided property details related to this improvement. Status cannot be changed.
            </WishlistOverlay>} placement="topCenter">
                <span style={{color: "#9CA4AE"}}>Mark as incomplete</span>
            </Dropdown>
        </Menu.Item>}
        {!item.doneOn && !item.doneByAnswerOn &&
          <Menu.Item key="markAsNA">
            {item.isApplicable === false && "Mark as Applicable"}
            {(item.isApplicable === undefined || item.isApplicable === true) && "Mark as Not Applicable"}
          </Menu.Item>}
      </MenuStyled> : <></>
    );
  };

  const totalImprovementsFromCategory = (categoryId: number) => improvements
    .filter((i: any) => i.category === categoryId).length

  const doneImprovementsFromCategory = (categoryId: number) => improvements
    .filter((i: any) => i.category === categoryId && (i.doneOn || i.doneByAnswerOn)).length

  const getCategoryName = (categoryId: number) => categories.filter((c: any) => c.id === categoryId)[0].name

  const actionIconsDesktop = () => (
    <ActionIcons>
      {(item.doneOn || item.doneByAnswerOn) && (item.isApplicable === undefined || item.isApplicable === true) &&
      <Dropdown overlay={<WishlistOverlay>This improvement has been done</WishlistOverlay>} placement="topCenter">
        <WishlistGreenGuidanceIcon/>
      </Dropdown>}
      {item.wishListedOn && (item.isApplicable === undefined || item.isApplicable === true) && !item.doneOn && !item.doneByAnswerOn &&
      <Dropdown overlay={<WishlistOverlay>Click to remove from your wishlist</WishlistOverlay>} placement="topCenter">
        <WishlistAddedGuidanceIcon onClick={(e) => {
          e.stopPropagation()
          manageWishlistStatus(item)
        }}/>
      </Dropdown>}
      {(!item.wishListedOn && !item.doneOn && !item.doneByAnswerOn) && (item.isApplicable === undefined || item.isApplicable === true) &&
      <Dropdown overlay={<WishlistOverlay>Click to add to your wishlist</WishlistOverlay>} placement="topCenter">
        <WishlistGuidanceIcon onClick={(e) => {
          e.stopPropagation()
          manageWishlistStatus(item)
        }}/>
      </Dropdown>}
      <Popover
        content={moreMenuDropdown(item)}
        placement="bottomRight"
        arrowContent={null}
        trigger={["click"]}
      >
        <MoreMenuGuidanceIcon onClick={(e) => {
          e.stopPropagation()
          toggleMenuVisibility(true)
        }}/>
      </Popover>
    </ActionIcons>
  )

  const actionIconsMobile = () => (
    <ActionIcons style={{padding: 0, width: "auto"}}>
      {(item.doneOn || item.doneByAnswerOn) && <Dropdown overlay={<WishlistOverlay>This improvement has been done</WishlistOverlay>} placement="topCenter">
        <GuidanceMobileHeartGreen />
      </Dropdown>}
      {item.wishListedOn && !item.doneOn && !item.doneByAnswerOn && <Dropdown
          overlay={<WishlistOverlay>Click to remove from your wishlist</WishlistOverlay>} placement="topCenter">
        <GuidanceMobileHeartRed onClick={(e) => {
          e.stopPropagation()
          manageWishlistStatus(item)
        }}/>
      </Dropdown>}
      {(!item.wishListedOn && !item.doneOn && !item.doneByAnswerOn) &&
      <Dropdown overlay={<WishlistOverlay>Click to add to your wishlist</WishlistOverlay>} placement="topCenter">
        <GuidanceMobileHeartOutline onClick={(e) => {
          e.stopPropagation()
          manageWishlistStatus(item)
        }} />
      </Dropdown>}
      <Popover
        content={moreMenuDropdown(item)}
        placement="bottomRight"
        trigger={["click"]}
      >
        <MoreMenu onClick={(e) => {
          e.stopPropagation()
          toggleMenuVisibility(true)
        }}/>
      </Popover>
    </ActionIcons>
  )

  const guidanceCardDesktop = () => (
    <GuidanceCard
      style={{opacity: item.isApplicable === false ? 0.5 : 1}}
      onClick={() => selectImprovement(item.category, item.id)}>
      <GuidanceImageWrapper style={{backgroundImage: `url("https://www.livlet.com/guidance/${item.id}.jpg")`}}>
        {actionIconsDesktop()}
        <ProgressIcons>
          <GuidanceRating item={item} />
        </ProgressIcons>
      </GuidanceImageWrapper>
      <GuidanceCategoryWrapper>
        <CounterPill className={doneImprovementsFromCategory(item.category) === totalImprovementsFromCategory(item.category) ? "done" : ""}>
          {doneImprovementsFromCategory(item.category)}/{totalImprovementsFromCategory(item.category)}
          {doneImprovementsFromCategory(item.category) === totalImprovementsFromCategory(item.category) && " Done"}
        </CounterPill>
        <CardCategoryTitle>{getCategoryName(item.category)}</CardCategoryTitle>
      </GuidanceCategoryWrapper>
      <ImprovementTitle>{item.name}</ImprovementTitle>
      <ImprovementDescription>
        <Ellipsis lines={2} style={{hyphens: "auto"}}>{item.summary}</Ellipsis>
      </ImprovementDescription>
      <ReadMoreButton>Read More</ReadMoreButton>
    </GuidanceCard>
  )

  const guidanceCardMobile = () => (
    <GuidanceCardMobile
      style={{opacity: item.isApplicable === false ? 0.5 : 1}}
      onClick={() => selectImprovement(item.category, item.id)}>
      <GuidanceImageWrapperMobile style={{backgroundImage: `url("https://www.livlet.com/guidance/${item.id}.jpg")`}} />
      <GuidanceContentWrapperMobile>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: "0.5rem", overflow: "hidden", width: "100%"}}>
          <ImprovementTitle>{item.name}</ImprovementTitle>
          {actionIconsMobile()}
        </div>
        <ImprovementDescription>
          <Ellipsis lines={2} style={{hyphens: "auto"}}>{item.summary}</Ellipsis>
        </ImprovementDescription>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
          <ProgressIcons style={{padding: 0}}><GuidanceRating item={item} /></ProgressIcons>
          <ReadMoreButton style={{flexShrink: 0}}>Read More</ReadMoreButton>
        </div>
      </GuidanceContentWrapperMobile>
    </GuidanceCardMobile>
  )

  return <>
    {guidanceCardDesktop()}
    {guidanceCardMobile()}
  </>
}

export default GuidanceListItem

const GuidanceCard = styled.div`
  cursor: pointer;
  width: calc(50% - 0.25rem);
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 0.5rem;
  background-color: #EFFEF7;
  border-radius: 8px;

  @media (max-width: 1024px) {
    display: none;
  }
`

const GuidanceCardMobile = styled.div`
  display: none;

  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
    background-color: #EFFEF7;
    border-radius: 8px;
  }
`

const GuidanceImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  background-color: teal;
  height: 15.5rem;
  
  background-repeat: no-repeat;
  background-position: center center; 
  background-size: cover;
`

const GuidanceImageWrapperMobile = styled.div`
  display: flex;
  background-color: teal;
  height: 100%;
  border-radius: 8px 0 0 8px;
  height: 6.875rem;
  width: 6.875rem;
  flex-shrink: 0;
  
  background-repeat: no-repeat;
  background-position: center center; 
  background-size: cover;
`

const GuidanceContentWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 0.5rem;
  overflow: hidden;
  width: 100%;
`

const ActionIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  align-items: flex-start;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  column-gap: 0.25rem;
  height: 100px;
  background: radial-gradient(63.98% 79.08% at 88.17% 16.36%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  
  @media (max-width: 1024px) {
    height: auto!important;
    background: none!important;
  }
`

const ProgressIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  column-gap: 0.25rem;
  background: radial-gradient(80.34% 80.34% at 25.15% 100%, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  
  @media (max-width: 1024px) {
    background: none!important;
    height: 1.625rem!important;
     svg { 
       height: 1.875rem; 
       width: 1.875rem; 
     }
     svg path { 
       transform: scale(0.95, 0.95); 
       transform-origin: center center; 
     }
  }
`

const GuidanceCategoryWrapper = styled.div`
  display: flex;
  column-gap: 0.5rem;
`

const CounterPill = styled.div`
  background: #DAE0E6;
  border-radius: 11px;
  padding: 0 0.375rem;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #666F7A;
  height: 1.125rem;
  
  &.done {
    background: #EFFEF7;
    color: #00B74F;
  }
`

const CardCategoryTitle = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #666F7A;
`

const ImprovementTitle = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #21272B;
  
  @media (max-width: 1024px) {
    font-size: 0.75rem;
    line-height: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const ImprovementDescription = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666F7A;
  
  @media (max-width: 1024px) {
    font-size: 0.75rem;
    line-height: 1rem;
  }
`

const ReadMoreButton = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #2A80E1;
`

export const WishlistOverlay = styled.div`
  background: white;
  padding: 0.25rem;
  border-radius: 2px;
  max-width: 250px;
  border: 1px solid #f5f5f5;
  text-align: center;
`