import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TTag} from "../tags/addNewTag";

type TEditWorkRequest = {
  completedDate?: string | null;
  dueDate?: string | null;
  isComplete: boolean;
  name: string;
  propertyId: Guid;
  recurring: string;
  type: string;
  workId: Guid;
  notes: string;
  tags: Array<TTag>;
};

export const editWork = (payload: TEditWorkRequest) => {
  const { propertyId, workId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/works/${workId}`
  );

  return apiPut<
    {
      completedDate: string;
      dueDate: string;
      isComplete: boolean;
      name: string;
      propertyId: Guid;
      recurring: string;
      type: string;
      workId: Guid;
      notes: string;
    },
    {}
  >(url, payload);
};
