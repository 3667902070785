import _ from "lodash";

import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TAddExpenseTypeResponse = {

};

export type TAddExpenseTypeRequest = {
  name: string;
  parentId: Guid | string;
};

export const addExpenseType = (payload: TAddExpenseTypeRequest & { propertyId: Guid }) => {
  const { propertyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/expenseType`
  );

  return apiPost<TAddExpenseTypeResponse, TAddExpenseTypeRequest>(
    url, _.omit(payload, ["propertyId"])
  );
};
