import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getContactsSelector, getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {
  AddRecordButton,
  EmptyRecordsDescription,
  EmptyRecordsHeader,
  EmptyRecordsList,
  SectionLabel
} from "../../screens/components";
import {PlusOutlined} from "@ant-design/icons";
import {
  ListItemStyled,
  PropertyListDesktop, TagStyledRed,
} from "../properties/PropertyDetailsComp";
import {Dropdown, List, Menu, Modal, Skeleton} from "antd";
import moment from "moment";
import {removeMortgage} from "../../../api-wrapper/mortgages/removeMortgage";
import {getAllMortgages} from "../../actions/mortgages";
import {Guid} from "guid-typescript";
import _ from "lodash";
import {ReactComponent as MoreMenu} from "../../../images/More.svg";
import {MenuStyled} from "../works/WorksListComp";
import styled from "styled-components";
import MortgagePartnerDrawer from "../partners/MortgagePartnerDrawer";
import {numberWithCommasFull} from "../helper";
import {getCurrencySign} from "../../screens/helpers";
import {ReactComponent as GreenDot} from "../../../images/GreenDot.svg";

type Props = {
  mortgages: any;
  toggleMortgageDrawer: (arg: boolean) => void;
  setMortgageData: (arg: any) => void;
}

const MortgageListComp = (props: Props) => {
  const {mortgages, toggleMortgageDrawer, setMortgageData} = props;
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const contacts = useSelector(getContactsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);
  const [isMortgagePartnerDrawerOpen, toggleMortgagePartnerDrawer] = useState(false);

  const addNewMortgage = () => {
    setMortgageData(null);
    toggleMortgageDrawer(true);
  }

  function confirmDelete(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        removeMortgage(propertyId, item.mortgageId).then(() => dispatch(getAllMortgages(propertyId)))
      }
    });
  }

  const showContactName = (contactId: Guid) => {
    const filteredContacts = contacts.content.filter(
      (contact) => contact.contactId === contactId
    );
    return _.isEmpty(filteredContacts) ? "" : filteredContacts[0].name;
  };

  const moreMenuDropdown = (item: any) => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      if (key === "edit") {
        setMortgageData({data: item});
        toggleMortgageDrawer(true);
      } else if (key === "delete") {
        confirmDelete(item);
      } else if (key === "contact") {
        toggleMortgagePartnerDrawer(true);
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="edit">Edit</Menu.Item>
        <Menu.Item key="contact">Contact Mortgage Advisor</Menu.Item>
        <Menu.Item key="delete">Delete</Menu.Item>
      </MenuStyled>
    );
  };

  const listItemDesktop = (item: any) => (
    <ListItemStyled
      onClick={() => {
        setMortgageData({data: item});
        toggleMortgageDrawer(true);
      }}
      actions={[
        <Dropdown overlay={moreMenuDropdown(item)} placement="bottomRight" trigger={["click"]}>
          <MoreMenu onClick={(e) => e.stopPropagation()}/>
        </Dropdown>
      ]}
    >
      <Skeleton loading={false} title={false} active>
        <List.Item.Meta
          style={{minWidth: "9.5rem"}}
          title={<span style={{color: item.confirmed ? "#21272B" : "#C4C4C4"}}>{showContactName(item.provider)} Mortgage{" "}</span>}
          description={item.fixedPeriodYears ? <span style={{color: item.confirmed ? "#EF624E" : "#C4C4C4", fontWeight: 600}}>
            Fixed term ends on {moment(item.mortgageDate).add(item.fixedPeriodYears, "years").format(propertyPreferences.preferredDateFormat)}
            </span> : <></>}
        />
        <MortgageDetails>
          {(item.remainingLoan > 0 || item.yearsRemaining > 0 || item.monthlyInstallment > 0 || !item.confirmed) && <div className={"wrapper"}>
            {!item.confirmed && <TagStyledRed>
                <GreenDot style={{marginTop: "0.5rem", marginRight: "0.5rem"}}/> Unconfirmed
            </TagStyledRed>}
            {item.remainingLoan > 0 && <InfoBox className={item.confirmed ? "" : "unconfirmed"}>
                <span className={"dot"}/> Remaining Loan: {getCurrencySign(propertyPreferences.preferredCurrency)} {numberWithCommasFull(item.remainingLoan)}
            </InfoBox>}
            <InfoBox className={item.confirmed ? "" : "unconfirmed"}>
                <span className={"dot"}/> {item.yearsRemaining} yrs remaining
            </InfoBox>
            {item.monthlyInstallment > 0 && <InfoBox className={item.confirmed ? "" : "unconfirmed"}>
                <span className={"dot"}/>Monthly: {getCurrencySign(propertyPreferences.preferredCurrency)} {numberWithCommasFull(item.monthlyInstallment)}
            </InfoBox>}
          </div>}
          {item.mortgageDate !== item.mortgageCloseDate ? <span style={{color: item.confirmed ? "#45655E" : "#C4C4C4"}}>Mortgage total period: {moment(item.mortgageDate)
            .format(propertyPreferences.preferredDateFormat)} - {moment(item.mortgageCloseDate).format(propertyPreferences.preferredDateFormat)}</span> :
            <span style={{color: item.confirmed ? "#45655E" : "#C4C4C4"}}>Mortgage start date: {moment(item.mortgageDate).format(propertyPreferences.preferredDateFormat)}</span>}
        </MortgageDetails>
      </Skeleton>
    </ListItemStyled>
  )

  const listItemMobile = (item: any) => (
    <ListItemStyled
      style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}
      onClick={() => {
        setMortgageData({data: item});
        toggleMortgageDrawer(true);
      }}
    >
      <Skeleton loading={false} title={false} active>
        <MortgageTitleMobile>
            <span style={{color: item.confirmed ? "#21272B" : "#C4C4C4"}}>{showContactName(item.provider)} Mortgage</span>
            <Dropdown overlay={moreMenuDropdown(item)} placement="bottomRight" trigger={["click"]}>
              <MoreMenu onClick={(e) => e.stopPropagation()}/>
            </Dropdown>
        </MortgageTitleMobile>
        <MortgageDetailsMobile>
          {item.monthlyInstallment > 0 && <InfoBox className={item.confirmed ? "" : "unconfirmed"} style={{justifyContent: "space-between"}}>
            <span>Monthly:</span>
            <span>{getCurrencySign(propertyPreferences.preferredCurrency)} {numberWithCommasFull(item.monthlyInstallment)}</span>
          </InfoBox>}
          {item.remainingLoan > 0 && <InfoBox className={item.confirmed ? "" : "unconfirmed"} style={{justifyContent: "space-between"}}>
            <span>Remaining (estimate):</span>
            <span>{getCurrencySign(propertyPreferences.preferredCurrency)} {numberWithCommasFull(item.remainingLoan)}</span>
          </InfoBox>}
          {item.yearsRemaining > 0 && <InfoBox className={item.confirmed ? "" : "unconfirmed"} style={{textAlign: "left"}}>
            Period: {moment(item.mortgageDate).format(propertyPreferences.preferredDateFormat)} - {moment(item.mortgageCloseDate).format(propertyPreferences.preferredDateFormat)}
            <br/>
            {item.yearsRemaining} yrs remaining
          </InfoBox>}
          {item.fixedPeriodYears && <InfoBox className={item.confirmed ? "red-box" : "unconfirmed"}>
            Fixed term ends {moment(item.mortgageDate).add(item.fixedPeriodYears, "years").format(propertyPreferences.preferredDateFormat)}
          </InfoBox>}
          {item.mortgageDate === item.mortgageCloseDate && <InfoBox className={item.confirmed ? "" : "unconfirmed"} style={{textAlign: "left"}}>
              Mortgage start date: {moment(item.mortgageDate).format(propertyPreferences.preferredDateFormat)}
          </InfoBox>}
        </MortgageDetailsMobile>
      </Skeleton>
    </ListItemStyled>
  )

  return (
    <>
      <MortgagePartnerDrawer
        isDrawerOpen={isMortgagePartnerDrawerOpen}
        toggleDrawer={toggleMortgagePartnerDrawer}
      />
      <SectionLabel>
        Mortgage details
        {/*<ButtonOutline onClick={() => addNewMortgage()}>*/}
        {/*  Add mortgage*/}
        {/*</ButtonOutline>*/}
      </SectionLabel>
      {mortgages.content.length === 0 && <EmptyRecordsList>
          <EmptyRecordsHeader>Add your mortgage details</EmptyRecordsHeader>
          <EmptyRecordsDescription>
              Add mortgage information to generate mortgage renewal reminders and get enhanced reporting for your property.
          </EmptyRecordsDescription>
          <AddRecordButton
              onClick={() => addNewMortgage()}
              icon={<PlusOutlined />}
              size={"large"}
          >
            Add mortgage
          </AddRecordButton>
      </EmptyRecordsList>
      }

      {mortgages.content.length > 0 && (
        <>
          <PropertyListDesktop>
            <List
              style={{ width: "100%" }}
              className="rounded-top-bottom-list"
              itemLayout="horizontal"
              dataSource={mortgages.content}
              renderItem={(item: any) => window.innerWidth > 768 ? listItemDesktop(item) : listItemMobile(item)}
            />
          </PropertyListDesktop>
        </>
      )}
    </>
  )
}

const MortgageDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
  
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #45655E;

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 1rem;
    row-gap: 0.25rem;
    flex-wrap: wrap;
  }
`

const MortgageTitleMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  font-size: 1rem;
  font-weight: 600;
  color: #21272b;
  line-height: 1.5rem;
`

const MortgageDetailsMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`

const InfoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  gap: 0.5rem;
  background: #EFF1F5;
  border-radius: 4px;
  
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
  text-transform: uppercase;
  color: #3E5D58;
  
  &.unconfirmed { color: #C4C4C4; }
  
  &.red-box {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-transform: none;
    color: #EF624E;
    justify-content: center;
  }

  .dot {
    background: #67C18B;
    border-radius: 100%;
    width: 0.375rem;
    height: 0.375rem;
  }
  
  @media (max-width: 768px) { justify-content: flex-start; }
`

export default MortgageListComp;