import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {getStorageUsage, TGetStorageUsageResponse} from "../../api-wrapper/attachments/getStorageUsage";

export const getStorageUsageInitAction = createAction("storageUsage/GET_STORAGE_USAGE_INIT")();
export const getStorageUsageSuccessAction = createAction(
  "storageUsage/GET_STORAGE_USAGE_SUCCESS"
)<TGetStorageUsageResponse>();
export const getStorageUsageFailureAction = createAction("storageUsage/GET_STORAGE_USAGE_FAILURE")();

export type TGetStorageUsageInitAction = ActionType<typeof getStorageUsageInitAction>;
export type TGetStorageUsageSuccessAction = ActionType<typeof getStorageUsageSuccessAction>;
export type TGetStorageUsageFailureAction = ActionType<typeof getStorageUsageFailureAction>;

export type TGetStorageUsageActions =
  | TGetStorageUsageInitAction
  | TGetStorageUsageSuccessAction
  | TGetStorageUsageFailureAction;

export type TGetStorageUsageThunkActionType = () => ThunkAction<void, TRootState, null, TGetStorageUsageActions>;

export const getMyStorageUsage: TGetStorageUsageThunkActionType = () => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getStorageUsageInitAction());
  return getStorageUsage()
    .then((payload) => {
      // @ts-ignore
      return dispatch(getStorageUsageSuccessAction(payload));
    })
    .catch(() => dispatch(getStorageUsageFailureAction()));
};
