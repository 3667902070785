import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetSubscriptionUsersResponse } from "../../api-wrapper/user/getSubscriptionUsers";
import {
  getSubscriptionUsersFailureAction,
  getSubscriptionUsersInitAction,
  getSubscriptionUsersSuccessAction,
  TGetSubscriptionUsersActions,
} from "../actions/subscriptionUsers";
import {subscriptionUsersInitial} from "../components/helper";

export type TSubscriptionUsers = {
  fetchingStatus: TFetchingStatus;
  content: TGetSubscriptionUsersResponse;
};

const initialState: TSubscriptionUsers = {
  content: subscriptionUsersInitial,
  fetchingStatus: TFetchingStatus.Defined,
};

export const subscriptionUsersReducer = createReducer<TSubscriptionUsers, TGetSubscriptionUsersActions>(
  initialState
)
  .handleAction(getSubscriptionUsersInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getSubscriptionUsersSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getSubscriptionUsersFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
