import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TGetWorksResponse} from "../works/getWorks";
import {TGetExpensesResponse} from "../expenses/getExpenses";
import {TTag} from "../tags/addNewTag";

export type TGetContactByIdResponse = {
  contactId: Guid;
  notes: Array<{
    recordId: Guid;
    title: string;
    message: string;
    createdDate: string;
    attachment: {
      attachmentId: Guid;
      propertyId: Guid;
      parentId: Guid;
      parentType: string;
      name: string;
      size: number;
    };
  }>;
  attachments: Array<{
    attachmentId: Guid;
    propertyId: Guid;
    parentId: Guid;
    parentType: string;
    storageKey: string;
    name: string;
    size: number;
    path: string;
  }>;
  expenses: TGetExpensesResponse;
  contactWorks: TGetWorksResponse;
  tags: Array<TTag>;
};

export const getContactById = (propertyId: Guid, contactId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/contacts/${contactId}/details`,
    {}
  );

  return apiGet<TGetContactByIdResponse>(url);
};
