import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { ReactComponent as TagsCross } from "../../images/TagsCross.svg";
import { ReactComponent as TagsCustom } from "../../images/TagsCustom.svg";
import { ReactComponent as TagsSystem } from "../../images/TagsSystem.svg";
import { ReactComponent as AddTagsIcon } from "../../images/AddTags.svg";
import { ReactComponent as CustomCostCategoriesIcon } from "../../images/CustomCostCategoriesIcon.svg";
import { ReactComponent as CustomCostCategoriesWarning } from "../../images/CustomCostCategoriesWarning.svg";
import {useDispatch, useSelector} from "react-redux";
import {
  getExpensesSelector,
  getExpenseTypesSelector,
  getPropertyIdSelector,
  getTagsWithUsageCountSelector
} from "../selectors";
import {Button, Input, notification, Popconfirm, Tag, Tooltip} from "antd";
import {addNewTag} from "../../api-wrapper/tags/addNewTag";
import {getAllTagsWithUsageCount} from "../actions/tagsWithUsageCount";
import {Guid} from "guid-typescript";
import {deleteCustomTag} from "../../api-wrapper/tags/deleteCustomTag";
import {
  PageHeader,
  PageInfoColumn,
  PageInfoWrapper,
  PageSubtitle,
  PageTitle,
  PageWrapper
} from "./components";
import {ReactComponent as HelpIcon} from "../../images/Help.svg";
import _ from "lodash";
import {emptyGuid} from "./helpers";
import {getAllExpenses, getAllExpenseTypes} from "../actions/expenses";
import {deleteCustomExpenseType} from "../../api-wrapper/expenses/deleteCustomExpenseType";

const Tags = () => {
  const dispatch = useDispatch();
  const tags = useSelector(getTagsWithUsageCountSelector);
  const expenseTypes = useSelector(getExpenseTypesSelector);
  const expenses = useSelector(getExpensesSelector);

  const propertyId = useSelector(getPropertyIdSelector).value;
  const [inputValue, setInputValue] = useState("");
  const [isAddTagsModeOn, toggleAddTagsMode] = useState(false);
  const [customExpenseTypeCountMap, setCustomExpenseTypeCountMap] = useState(new Map());

  const tagsSystem = tags.content.filter((tag) => !tag.subscriptionId).length;
  const tagsCustom = tags.content.filter((tag) => tag.subscriptionId).length;
  const tagsUnused = tags.content.filter((tag) => tag.usageCount === 0).length;

  const customExpenseTypes = Object.values(expenseTypes.content).filter(type => type.subscriptionId);

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    dispatch(getAllTagsWithUsageCount(propertyId));
    dispatch(getAllExpenseTypes(propertyId));
    dispatch(getAllExpenses(propertyId));
  }, [propertyId])

  useEffect(() => {
    let customExpenseTypeMap = new Map();
    let customExpenseTypeArray: any = [];
    Object.values(expenseTypes.content).filter(type => type.subscriptionId).map(type => {
      customExpenseTypeMap.set(type.id, 0)
      customExpenseTypeArray.push(type.id)
    })

    for (const expense of expenses.content) {
      if (customExpenseTypeArray.includes(expense.type)) {
        customExpenseTypeMap.set(expense.type, customExpenseTypeMap.get(expense.type) + 1)
      }
    }

    setCustomExpenseTypeCountMap(customExpenseTypeMap)
  }, [propertyId, expenseTypes, expenses])

  const getParentCategoryName = (parentId: Guid) => {
    const parentCategories = Object.values(expenseTypes.content).filter(type => !type.parentId);
    return parentCategories.filter(type => type.id === parentId)[0]?.name
  }

  const deleteCustomCategory = (customTypeId: Guid, parentTypeId: Guid) => {
    deleteCustomExpenseType(propertyId, customTypeId, parentTypeId).then(() => dispatch(getAllExpenseTypes(propertyId)))
  }

  function showTagError(tagList: string[], error: string) {
    notification.warning({
      message: "Some tags weren't added",
      description: <p>The following tags {error} and therefore weren't added: {tagList.join(", ")}</p>
    });
  }

  const addNewTags = async (tags: any) => {
    const newTagPromises = tags.map(async (tag: any) => {
      const response = await addNewTag(tag)
      return response.data?.tagId
    });
    return await Promise.all(newTagPromises);
  }

  const saveNewTags = async () => {
    const newTags: string[] = [];
    const databaseTags: string[] = [];
    const tagsToAdd: string[] = [];
    const existingTags: string[] = [];
    const tooLongTags: string[] = [];

    let tempTags = inputValue.split(",")
    tempTags.map((tag: string) => newTags.push(tag.trim().toUpperCase()))
    tags.content.map((tag: any) => databaseTags.push(tag.name))
    newTags.map((tag: string) => tag.length <= 64 ?
      (!databaseTags.includes(tag) ? tagsToAdd.push(tag) : existingTags.push(tag)) : tooLongTags.push(tag))

    let response = await addNewTags(tagsToAdd)
    existingTags.length && showTagError(existingTags, "already exist")
    tooLongTags.length && showTagError(tooLongTags, "are longer than 64 characters")

    if (response) {
      setInputValue("")
      dispatch(getAllTagsWithUsageCount(propertyId))
      toggleAddTagsMode(false)
    }
  }

  const deleteTag = (tagId: Guid) => {
    deleteCustomTag(tagId).then(() => dispatch(getAllTagsWithUsageCount(propertyId)))
  }

  return <PageWrapper>
    <PageHeader>
      <div>
        <div style={{display: "flex", alignItems: "center"}}>
          <PageTitle>Tags</PageTitle>
          <Tooltip
            placement="bottom"
            color={"#2a80e1"}
            title={<span>
            The number next to each tag marks the amount of times this tag has been used.
            Please note that some of the system tags are used by the system implicitly.
          </span>}>
            <HelpIcon
              className={"header-icon"}
              style={{width: '22px', height: '22px', cursor: "pointer", marginLeft: "0.75rem"}}/>
          </Tooltip>
        </div>
        <PageSubtitle>Manage your Custom tags from here.</PageSubtitle>
      </div>
    </PageHeader>
    <PageInfoWrapper>
      <PageInfoColumn>
        <div className={"column-header"}>System tags</div>
        <div className={"column-content"}>
          {tagsSystem}
        </div>
      </PageInfoColumn>
      <PageInfoColumn>
        <div className={"column-header"}>Custom tags</div>
        <div className={"column-content"}>
          {tagsCustom}
        </div>
      </PageInfoColumn>
      <PageInfoColumn>
        <div className={"column-header"}>Never used</div>
        <div className={"column-content"}>
          {tagsUnused}
        </div>
      </PageInfoColumn>
    </PageInfoWrapper>
    <SectionHeader><TagsSystem style={{marginRight: "0.75rem", display: "flex", flexShrink: 0}} /> System tags</SectionHeader>
    <TagContainer>
      <TagWrapper>
        {tags.content.filter((tag: any) => !tag.subscriptionId).map((tag: any) =>
          <TagGray>
            {tag.name} ({tag.usageCount})
          </TagGray>
        )}
      </TagWrapper>
    </TagContainer>
    <SectionHeader><TagsCustom style={{marginRight: "0.75rem", display: "flex", flexShrink: 0}} /> Custom tags</SectionHeader>
    <TagContainer>
      <TagContainerWrapper>
        <TagWrapper>
          {tags.content.filter((tag: any) => tag.subscriptionId).length === 0 && "You haven't created any custom tags yet."}
          {tags.content.filter((tag: any) => tag.subscriptionId).map((tag: any) =>
            <TagGray>
              {tag.name} ({tag.usageCount})
              <Popconfirm title={<span style={{display: 'block', width: '14rem', marginTop: '0.5rem'}}>
                Are you sure you want to delete this custom tag?</span>}
                  onConfirm={() => deleteTag(tag.tagId)}
                  onCancel={(e) => e!.stopPropagation()}
                  icon={""}
                  okText={'Yes'}
                  cancelText={'No'}
              ><TagsCross style={{cursor: "pointer", marginLeft: "0.45rem"}} /></Popconfirm>
            </TagGray>
          )}
        </TagWrapper>
        <AddTagButton className={isAddTagsModeOn ? "add-tag-active" : ""} onClick={() => toggleAddTagsMode(!isAddTagsModeOn)}>
          <AddTagsIcon />
          <span style={{borderBottom: "2px dotted #666F7A", paddingBottom: "0.25rem"}}>Add new</span>
        </AddTagButton>
      </TagContainerWrapper>
      {isAddTagsModeOn && <AddTagField>
        <p style={{fontWeight: 600, fontSize: "1rem", lineHeight: "1.5rem"}}>
          Add new tag <span style={{color: "#E974C1"}}>*</span>
        </p>
        <AddTagForm>
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={{height: "3rem", borderRadius: "6px"}}
            type={"text"}
            placeholder={"Enter tag"}/>
          <CancelButton
              className={"button-desktop"}
              onClick={() => {
                toggleAddTagsMode(false)
                setInputValue("")
              }}
              style={{height: "3rem", background: "white", paddingLeft: "1rem", paddingRight: "1rem"}}>
            Cancel
          </CancelButton>
          <ApplyButton
              className={"button-desktop"}
              onClick={() => saveNewTags()}
              style={{height: "3rem", paddingLeft: "1rem", paddingRight: "1rem"}}>
            Save
          </ApplyButton>
        </AddTagForm>
        <p>Enter 1 or multiple tags, separating them by comma. Eg., "custom tag 1, custom tag 2".</p>
        <ButtonWrapperMobile>
          <CancelButton
            onClick={() => {
              toggleAddTagsMode(false)
              setInputValue("")
            }}
            style={{height: "3rem", background: "white", width: "50%"}}>
            Cancel
          </CancelButton>
          <ApplyButton
              onClick={() => saveNewTags()}
              style={{height: "3rem", width: "50%"}}>
              Save
          </ApplyButton>
        </ButtonWrapperMobile>
      </AddTagField>}
    </TagContainer>
    <SectionHeader><CustomCostCategoriesIcon style={{marginRight: "0.75rem", display: "flex", flexShrink: 0}} /> User cost types</SectionHeader>
    <TagContainer>
      <TagContainerWrapper>
        <TagWrapper>
          {customExpenseTypes.length === 0 && "You haven't created any custom expense types yet."}
          {customExpenseTypes.map((type: any) =>
            <TagGray>
              {type.name} ({customExpenseTypeCountMap.get(type.id)}) [{getParentCategoryName(type.parentId)}]
              <Popconfirm title={<span style={{display: 'block', width: '14rem', marginTop: '0.5rem'}}>
                Are you sure you want to delete this custom expense category?</span>}
                  onConfirm={() => deleteCustomCategory(type.id, type.parentId)}
                  onCancel={(e) => e!.stopPropagation()}
                  icon={""}
                  okText={'Yes'}
                  cancelText={'No'}
              ><TagsCross style={{cursor: "pointer", marginLeft: "0.45rem"}} /></Popconfirm>
            </TagGray>
          )}
          <CustomCategoryDisclaimer>
            <CustomCostCategoriesWarning /> Deleting a cost type will re-assign it to type Other.
          </CustomCategoryDisclaimer>
        </TagWrapper>
      </TagContainerWrapper>
    </TagContainer>
  </PageWrapper>
}

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  color: #45655E;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
`

const TagContainer = styled.div`
  width: 100%;
  padding: 1.5rem;
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`

const TagContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 1rem;
  }
`

const TagWrapper = styled.div`
  max-width: 38.5rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.25rem;
  column-gap: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
`

const AddTagButton = styled.div`
  display: flex;
  align-items: center;
  color: #236EC2;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 600;
  column-gap: 0.375rem;
  cursor: pointer;
  
  svg path {
    fill: #236EC2;
  }
`

const AddTagField = styled.div`
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 1rem;
  margin-top: 1.5rem;
  color: #666F7A;
  font-size: 0.875rem;
  line-height: 1.25rem;
  
  .button-mobile { 
    display: none; 
  }
  
   @media (max-width: 1024px) {
     padding: 0;
     .button-mobile { display: flex; }
  }
`

const AddTagForm = styled.div`
  display: flex;
  column-gap: 0.5rem;
  margin-bottom: 0.75rem;
`

const ButtonWrapperMobile = styled.div`
  @media (max-width: 1024px) {
    display: flex;
    column-gap: 0.5rem;
  }
  
  @media (min-width: 1024px) {
    display: none;
  }
`

export const TagStyled = styled(Tag)`
  display: flex;
  height: 1.75rem;
  width: auto;
  padding: 0.375rem 0.75rem;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
  color: #8E8E89;
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 400;
  border-radius: 5px;
  align-items: center;
  border: 0;
`

const TagGray = styled(TagStyled)`
  background: #EFF1F5;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #666F7A;
`

export const CancelButton = styled(Button)`
  border: 1px solid #DAE0E6;
  color: #21272B;
  background: #EBF9FF;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  border-radius: 6px;
  height: 2.625rem;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ApplyButton = styled(Button)`
  border: 1px solid #2A80E1;
  color: white;
  background: #2A80E1;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  border-radius: 6px;
  height: 2.625rem;
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CustomCategoryDisclaimer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #2A80E1;
`

export default Tags