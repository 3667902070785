import React from "react";
import {useSelector} from "react-redux";
import {getImprovementsSelector} from "../../selectors";
import {RelatedMeasureTitle, RelatedSectionContent, RelatedSectionIndex, RelatedSectionWrapper} from "./GuidanceItem";
import {SearchSortBlock, SearchSortDescription, SearchSortTitle} from "./GuidanceSearchComp";

type Props = {
  improvement: any;
  improvements: any;
  selectImprovement: (arg1: number, arg2: string) => void;
}

const RelatedMeasuresComp = (props: Props) => {
  const {improvement, improvements, selectImprovement} = props;
  const improvementsConfiguration = useSelector(getImprovementsSelector);

  const scrollToTop = () => document.getElementById("pageStart")?.scrollIntoView()

  return improvement.relatedImprovements.length > 0 ? <SearchSortBlock style={{marginTop: 0}}>
    <SearchSortTitle>Related measures</SearchSortTitle>
    <SearchSortDescription style={{marginBottom: "1rem"}}>Other measures related to this one:</SearchSortDescription>
    {improvement.relatedImprovements.map((relatedImprovementId: any, index: number) =>
      (improvementsConfiguration.content.improvements.filter(relatedImprovement =>
        relatedImprovement.id === relatedImprovementId).length > 0) &&
        <RelatedSectionWrapper
            style={{marginTop: "0", background: "#EFFEF7"}}
            onClick={() => {
              scrollToTop()
              selectImprovement(improvementsConfiguration.content.improvements.filter(relatedImprovement =>
                relatedImprovement.id === relatedImprovementId)[0].category, relatedImprovementId)
            }}
        >
            <RelatedSectionIndex>{String.fromCharCode(index + 65)}.</RelatedSectionIndex>
            <RelatedSectionContent>
                <RelatedMeasureTitle>
                  {improvementsConfiguration.content.improvements.filter(relatedImprovement =>
                    relatedImprovement.id === relatedImprovementId)[0].name}
                </RelatedMeasureTitle>
              {improvementsConfiguration.content.improvements.filter(relatedImprovement =>
                relatedImprovement.id === relatedImprovementId)[0].summary}
            </RelatedSectionContent>
        </RelatedSectionWrapper>
    )}
  </SearchSortBlock> : <SearchSortBlock style={{marginTop: 0}}>
    <SearchSortTitle>Other measures</SearchSortTitle>
    <SearchSortDescription style={{marginBottom: "1rem"}}>Other measures:</SearchSortDescription>
    {improvements.filter((otherImprovement: any) => otherImprovement.id !== improvement.id).slice(0, 3)
      .map((otherImprovement: any, index: number) =>
      (improvementsConfiguration.content.improvements.filter(i =>
        i.id === otherImprovement.id).length > 0) &&
        <RelatedSectionWrapper
            style={{marginTop: "0", background: "#EFFEF7"}}
            onClick={() => {
              scrollToTop()
              selectImprovement(improvementsConfiguration.content.improvements.filter(i =>
                i.id === otherImprovement.id)[0].category, otherImprovement.id)
            }}
        >
            <RelatedSectionIndex>{String.fromCharCode(index + 65)}.</RelatedSectionIndex>
            <RelatedSectionContent>
                <RelatedMeasureTitle>
                  {improvementsConfiguration.content.improvements.filter(i =>
                    i.id === otherImprovement.id)[0].name}
                </RelatedMeasureTitle>
              {improvementsConfiguration.content.improvements.filter(i =>
                i.id === otherImprovement.id)[0].summary}
            </RelatedSectionContent>
        </RelatedSectionWrapper>
    )}
  </SearchSortBlock>
}

export default RelatedMeasuresComp