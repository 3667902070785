import React, { useState } from "react";
import {List, Modal, Skeleton} from "antd";
import styled from "styled-components";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import _ from "lodash";

import { TGetContactsResponse } from "../../../api-wrapper/contacts/getContacts";
import { ReactComponent as DeleteImage } from "../../../images/Delete.svg";
import ContactDrawer from "../contacts/ContactDrawer";
import {TagStyledBold} from "../works/WorksListComp";
import {removeRelation} from "../../../api-wrapper/relation/removeRelation";
import {Guid} from "guid-typescript";

type Props = {
  contacts: TGetContactsResponse;
  drawerTitle: string | null;
  refreshParent: () => void;
  parentToClose: (arg01:boolean) => void;
  parentId: Guid | null;
  parentType: string | null;
};

const ContactsListComp = (props: Props) => {
  const {
    refreshParent,
    contacts,
    parentToClose,
    parentId,
    parentType
  } = props;

  const [isOpen, toggleDrawer] = useState(false);
  const [editContactData, setEditContactData] = useState(null);

  function confirmUnlink(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will still be able to find it in the Contacts page.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        parentId && parentType && removeRelation(item.propertyId, parentId, parentType, item.contactId, "contact").then(() => {
          if (refreshParent) refreshParent();
        });
      }
    });
  }

  return (
    <>
      <ContactDrawer
        isOpen={isOpen}
        toggleDrawerOpen={toggleDrawer}
        contact={editContactData}
        //@ts-ignore
        setEditingContactData={setEditContactData}
        toggleViewWorkDrawer={() => {}}
        setViewWorkId={() => {}}
      />
      <ContactListDesktop>
        <List
          style={{ width: "100%", alignItems: "center" }}
          itemLayout="horizontal"
          dataSource={contacts.filter((contact) => contact.contactType !== 'Payee')}
          renderItem={(item) => (
            <ListItemStyled
              actions={[
                <DeleteImage
                  onClick={() => confirmUnlink(item)}
                />,
              ]}
            >
              <Skeleton loading={false} title={false} active>
                <List.Item.Meta
                  title={
                    <a
                      onClick={() => {
                        parentToClose(false);
                        toggleDrawer(true);
                        //@ts-ignore
                        setEditContactData({ data: item });
                      }}
                    >
                      <Ellipsis length={70}>{item.name}</Ellipsis>
                    </a>
                  }
                  description={
                    item.company
                  }
                />
              </Skeleton>
              {!_.isEmpty(item.contactType) && (
                <TagStyledBold>
                  {item.contactType}
                </TagStyledBold>
              )}

            </ListItemStyled>
          )}
        />
      </ContactListDesktop>
      <ContactListMobile>
        <List
          style={{ width: "100%", alignItems: "center" }}
          itemLayout="vertical"
          dataSource={contacts.filter((contact) => contact.contactType !== "Payee")}
          renderItem={(item) => (
            <ListItemStyled>
              <Skeleton loading={false} title={false} active>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <List.Item.Meta
                    style={{ margin: 0 }}
                    title={
                      <a
                        onClick={() => {
                          toggleDrawer(true);
                          //@ts-ignore
                          setEditContactData(item);
                        }}
                      >
                        <Ellipsis length={20}>{item.name}</Ellipsis>
                      </a>
                    }
                    description={
                      item.company
                    }
                  />
                  {/*{item.attachment && (*/}
                  {/*  <TagStyled icon={<AttachmentsMobile />}>1</TagStyled>*/}
                  {/*)}*/}
                  <DeleteImage
                    onClick={() => confirmUnlink(item)}
                  />
                </div>
              </Skeleton>
            </ListItemStyled>
          )}
        />
      </ContactListMobile>
    </>
  );
};

const ContactListDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0.625rem;
  width: 100%;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const ContactListMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0.625rem;
  width: 100%;
  padding: 0 0.75rem;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const ListItemStyled = styled(List.Item)`
  // .ant-list-item-action {
  //   margin-left: 0;
  // }
  @media (max-width: 1024px) {
    width: 100%;

    .ant-list-item-action > li {
      width: 100%;
    }
  }
  @media (min-width: 1024px) {
    height: 6rem;
    padding: 1em 1.25rem;
    padding-right: 2rem;
  }

  .ant-list-item-meta-title {
    font-size: 1rem;
    font-weight: 600;
    color: #21272b;
  }
  .ant-list-item-meta-description {
    color: #6b7185;
    font-weight: 400;
    margin-top: 0.6rem;
  }
  .ant-tag {
    margin: 0;
  }
`;

export default ContactsListComp;
