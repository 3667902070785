import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import React, {useEffect, useState} from "react";
import _ from "lodash";

import {ReactComponent as PencilIcon} from "../../../images/dashboard-homevalue/Pencil.svg";
import {ReactComponent as SaveValueIcon} from "../../../images/dashboard-homevalue/SaveValue.svg";
import {ReactComponent as CancelValueIcon} from "../../../images/dashboard-homevalue/CancelValue.svg";
import {ReactComponent as ArrowRightSmall} from "../../../images/ArrowRightSmall.svg";
import {numberWithCommasFull} from "../helper";
import {
  getImprovementsSelector,
  getPropertiesSelector,
  getPropertyIdSelector,
  getPropertyPreferencesSelector
} from "../../selectors";

import GoogleMapReact, {Coords} from 'google-map-react';
import {InputNumberStyled} from "../expenses/ExpenseDrawer";
import {SecondaryButton} from "../../screens/styled";
import {getAllImprovements} from "../../actions/improvements";
import {putHighlights} from "../../../api-wrapper/properties/putHighlights";
import {TFetchingStatus} from "../../../helpers";
import {putDetails} from "../../../api-wrapper/properties/putDetails";
import PropertyDrawer from "./PropertyDrawer";
import {InputNumberBox} from "../dashboardWidgets/HomeValueWidget";
import {Button, notification, Popover, Spin} from "antd";
import {ReactComponent as ReloadGray} from "../../../images/dashboard-homevalue/ReloadGray.svg";
import {LoadingOutlined} from "@ant-design/icons";
import {SectionLabel} from "../../screens/components";
import {getCurrencySign, isUKProperty} from "../../screens/helpers";

type Props = {
  setSelectedPill?: (arg: string) => void;
  property: any;
}

const EPCDashboardWidget = (props: Props) => {
  const {setSelectedPill, property} = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);
  const improvementsConfiguration = useSelector(getImprovementsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [enterPropertyValue, setEnterPropertyValue] = useState(false);
  const [enterEstimatedValue, setEnterEstimatedValue] = useState(false);
  const [enterPropertyRating, setEnterPropertyRating] = useState(false);

  const [userPurchasedValue, setUserPurchasedValue] = useState<any>();
  const [userEstimatedValue, setUserEstimatedValue] = useState<any>();

  const [lastSoldPriceDate, setLastSoldPriceDate] = useState<any>();
  const [lastSoldPrice, setLastSoldPrice] = useState<any>();
  const [lastSoldPriceSource, setLastSoldPriceSource] = useState<any>();

  const [energyEfficiency, setEnergyEfficiency] = useState<any>();

  const [propertyLocation, setPropertyLocation] = useState<Coords>({lat: 0, lng: 0});
  const [map, setMap] = useState(null);
  const [addressNotFound, setAddressNotFound] = useState(false);
  const [isPropertyDrawerOpen, togglePropertyDrawer] = useState(false);

  const [dataDownloaded, setDataDownloaded] = useState(false);

  useEffect(() => {
    dispatch(getAllImprovements(propertyId));
    setAddressNotFound(false);
  }, [propertyId]);

  useEffect(() => {
    if (improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success) {
      setDataDownloaded(false);
      return;
    }

    if (improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 18)[0].answer) {
      setUserPurchasedValue(improvementsConfiguration.content.propertyDetails.filter((detail) =>
        detail.id === 18)[0].answer);
    } else {
      setUserPurchasedValue(null);
    }
    if (improvementsConfiguration.content.propertyHighlights) {
      if (improvementsConfiguration.content.propertyHighlights.lastSoldPrice)
        setLastSoldPrice(improvementsConfiguration.content.propertyHighlights.lastSoldPrice)
      else
        setLastSoldPrice(null)
      if (improvementsConfiguration.content.propertyHighlights.lastSoldPriceSource)
        setLastSoldPriceSource(improvementsConfiguration.content.propertyHighlights.lastSoldPriceSource)
      else
        setLastSoldPriceSource(null)
      if (improvementsConfiguration.content.propertyHighlights.lastSoldPriceDate)
        setLastSoldPriceDate(improvementsConfiguration.content.propertyHighlights.lastSoldPriceDate)
      else
        setLastSoldPriceDate(null)
    }
    if (improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 19)[0].answer) {
      setUserEstimatedValue(improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 19)[0].answer);
      setEstimatedPropertyValue(improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 19)[0].answer);
      setEstimatedPropertyValueType("user");
    } else if (improvementsConfiguration.content.propertyHighlights.propertyValue) {
      setEstimatedPropertyValue(improvementsConfiguration.content.propertyHighlights.propertyValue);
      setEstimatedPropertyValueType(improvementsConfiguration.content.propertyHighlights.propertyValueType);
    } else {
      setUserEstimatedValue(null);
      setEstimatedPropertyValue(null);
      setEstimatedPropertyValueType(null);
    }

    setDataDownloaded(true);
  }, [improvementsConfiguration]);

  useEffect(() => {
    if (!map)
      return;
    let request = {
      query: property.address+", "+property.city+", "+property.zip,
      fields: ['name', 'geometry'],
    };

    // @ts-ignore
    let service = new window.google.maps.places.PlacesService(map);
    service.findPlaceFromQuery(request, (results: any, status: any) => {
      // @ts-ignore
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setPropertyLocation(results[0].geometry.location);
        // @ts-ignore
        map.setCenter(results[0].geometry.location);
        // @ts-ignore
        new window.google.maps.Marker({
          map: map,
          position: results[0].geometry.location,
          icon: "/images/PinLivLet.svg"
        });
      } else {
        setAddressNotFound(true);
      }
    });
  }, [properties, dispatch, map, property])

  const ukProperty = property && isUKProperty(property?.country, property?.zip)

  const colorForEnergyLetter = (letter: string) => {
    switch (letter) {
      case "A":
        return "#33a357"
      case"B":
        return "#79b752"
      case "C":
        return "#c3d545"
      case "D":
        return "#fff12c"
      case "E":
        return "#edb731"
      case "F":
        return "#d66f2c"
      case "G":
        return "#cc232a"
      default:
        return "white"
    }
  }

  const savePropertyValue = () => {
    const answers = [{id: 18, answer: userPurchasedValue}];
    putDetails(propertyId, answers)
      .then(() => {
        dispatch(getAllImprovements(propertyId));
        setEnterPropertyValue(false);
      });
  }

  const resetValueEstimate = () => {
    const answers = [{id: 19}];

    notification.info({
      message: 'Trying to estimate the home value',
      placement: "topRight",
    });

    putDetails(propertyId, answers)
      .then(() => {
        dispatch(getAllImprovements(propertyId));
      });
  }

  const saveEstimatedValue = () => {
    const answers = [{id: 19, answer: userEstimatedValue}];
    putDetails(propertyId, answers)
      .then(() => {
        dispatch(getAllImprovements(propertyId));
        setEnterEstimatedValue(false);
      });
  }

  const saveEnergyEfficiency = () => {
    putHighlights(propertyId, {currentEnergyEfficiency: energyEfficiency}).then(() => {
      dispatch(getAllImprovements(propertyId));
      setEnterPropertyRating(false);
    })
  }

  // const displayRating = (rating: number) => {
  //   let color = "#EBF9FF";
  //   let text = "Great";
  //   let icon = <RatingGreatIcon/>;
  //   if (rating <= 25) {
  //     color = "#FCF0F0";
  //     text = "Poor";
  //     icon = <RatingPoorIcon/>;
  //   } else if (rating <= 50) {
  //     color = "#FEF9EA";
  //     text = "Average";
  //     icon = <RatingAverageIcon/>;
  //   } else if (rating <= 75) {
  //     color = "#EFFDF7";
  //     text = "Good";
  //     icon = <RatingGoodIcon/>;
  //   }
  //   return <RatingButtonEPC style={{background: color}}>
  //     <span style={{marginRight:"1rem"}}>{text}</span> {icon}
  //   </RatingButtonEPC>
  // }

  const [estimatedPropertyValue, setEstimatedPropertyValue] = useState<any>();
  const [estimatedPropertyValueType, setEstimatedPropertyValueType] = useState<any>();

  return <>
    <PropertyDrawer
      toggleDrawerOpen={togglePropertyDrawer}
      isOpen={isPropertyDrawerOpen}
      editedPropertyId={propertyId}
    />
    <WidgetWrapper>
      <SectionLabel className={"padded"} style={{height: "2.5rem"}}>
        Property Highlights
      </SectionLabel>
      <WidgetBody>
        {addressNotFound
          ? <div>Your address has not been found. Please change
            it <span style={{color:"black", textDecoration:"underline", cursor: "pointer"}}
                  onClick={() => {
                    togglePropertyDrawer(true)
                  }}>here</span>.</div>
          : <>
            <PropertyTitle>{property.address}, {property.city}</PropertyTitle>
            <WidgetContent>
              <MapArea>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: ""+process.env.REACT_APP_GOOGLE_API_KEY, libraries:['places'] }}
                  onGoogleApiLoaded={({map, maps}) => setMap(map)}
                  center={propertyLocation}
                  defaultZoom={16}
                  yesIWantToUseGoogleMapApiInternals={true}
                >
                </GoogleMapReact>
              </MapArea>
              {dataDownloaded ? <PropertyValues>
                <div>
                  <PropertyValuesSubtitle>Purchase Price</PropertyValuesSubtitle>
                  {(!_.isNil(userPurchasedValue) || !_.isNil(lastSoldPrice)) && !enterPropertyValue && <>
                      <PropertyValueNumber>
                        {getCurrencySign(propertyPreferences.preferredCurrency)}
                        {numberWithCommasFull(userPurchasedValue ? userPurchasedValue : lastSoldPrice)}
                          <PencilIcon style={{cursor: "pointer"}} onClick={() => setEnterPropertyValue(true)} />
                      </PropertyValueNumber>
                      <PropertyValueOrigin>
                        Data provided by {userPurchasedValue ? "the user" :
                        `${lastSoldPriceSource} ${lastSoldPriceDate && `on ${lastSoldPriceDate}`}`}
                      </PropertyValueOrigin>
                  </>}
                  {_.isNil(userPurchasedValue) && _.isNil(lastSoldPrice) && !enterPropertyValue && <NoPropertyValue
                      onClick={() => setEnterPropertyValue(true)}>
                      Click here to enter your details</NoPropertyValue>}
                  {enterPropertyValue && <PropertyValueInputWrapper>
                      <PropertyValueNumber>{getCurrencySign(propertyPreferences.preferredCurrency)} </PropertyValueNumber>
                      <InputNumberBox style={{width: "8rem"}} value={userPurchasedValue}
                                      onChange={(val) => setUserPurchasedValue(val)}/>
                      <SaveValueIcon style={{cursor: "pointer"}} onClick={() => savePropertyValue()} />
                      <CancelValueIcon style={{cursor: "pointer"}} onClick={() => setEnterPropertyValue(false)} />
                  </PropertyValueInputWrapper>}
                </div>
                <div>
                  <PropertyValuesSubtitle>{estimatedPropertyValueType === "user" ? "User Value Estimate" : "Current Value Estimate"}</PropertyValuesSubtitle>
                  {!_.isNil(estimatedPropertyValue) && !enterEstimatedValue && <>
                      <div style={{display: "flex", width: "100%",
                        justifyContent: "space-between", alignItems: "center", rowGap: "0.5rem",flexWrap: "wrap"}}>
                        <PropertyValueNumber>
                          {getCurrencySign(propertyPreferences.preferredCurrency)}
                          {numberWithCommasFull(estimatedPropertyValue)}
                          <PencilIcon style={{cursor: "pointer"}} onClick={() => setEnterEstimatedValue(true)} />
                          {estimatedPropertyValueType === "user" && ukProperty &&
                          <Popover
                              content={"Estimate home value"}
                              overlayClassName={"smallTooltipPopover"}
                              placement="bottom"
                              trigger={["hover"]}
                          >
                              <ReloadGray style={{cursor:"pointer"}} onClick={() => resetValueEstimate()}/>
                          </Popover>
                          }
                        </PropertyValueNumber>
                        {estimatedPropertyValueType !== "extended" && estimatedPropertyValueType !== "user" &&
                          <ImproveEstimateButton onClick={() => {
                          setSelectedPill && setSelectedPill("Property details")
                        }}>
                          Improve Estimate <ArrowRightSmall/>
                        </ImproveEstimateButton>}
                      </div>
                      <PropertyValueOrigin>
                        {estimatedPropertyValueType === "user" && "User-entered valuation"}
                        {estimatedPropertyValueType === "simple" && "Simple evaluation based on the area and property size"}
                        {estimatedPropertyValueType === "extended" && "Enhanced evaluation based on all available data"}
                      </PropertyValueOrigin>
                  </>}
                  {_.isNil(estimatedPropertyValue) && !enterEstimatedValue && <NoPropertyValue
                      onClick={() => setEnterEstimatedValue(true)}>
                      Click here to enter your details</NoPropertyValue>}
                  {enterEstimatedValue && <PropertyValueInputWrapper>
                      <PropertyValueNumber>{getCurrencySign(propertyPreferences.preferredCurrency)} </PropertyValueNumber>
                      <InputNumberBox style={{width: "8rem"}} value={userEstimatedValue}
                                      onChange={(val) => setUserEstimatedValue(val)}/>
                      <SaveValueIcon style={{cursor: "pointer"}} onClick={() => saveEstimatedValue()} />
                      <CancelValueIcon style={{cursor: "pointer"}} onClick={() => setEnterEstimatedValue(false)} />
                  </PropertyValueInputWrapper>}
                </div>
                {property && property.country === "United Kingdom" && (
                <div>
                  <PropertyValuesSubtitle>EPC Rating</PropertyValuesSubtitle>
                  {(!_.isNil(improvementsConfiguration.content.propertyHighlights.currentEnergyEfficiency))
                    ? <div className={"epc-widget"} style={{marginTop:"1rem", justifyContent:"space-around"}}>
                      <div style={{
                        display:"flex",
                        flexDirection:"row",
                        justifyContent:"space-between",
                        width: window.innerWidth > 1024 ? "35%" : "100%",
                        maxWidth: window.innerWidth > 1024 ? "auto" : "200px",
                        alignItems:"center"}}>
                        <div>
                          Current
                        </div>
                        <div style={{fontSize:"1.5rem", marginLeft: "0.25rem", fontWeight: 700}}>
                          {improvementsConfiguration.content.propertyHighlights.currentEnergyEfficiency}
                        </div>
                        <div style={{width:"3.5rem", marginLeft: "0.25rem", height:"2.5rem", color: "white", fontSize:"1.5rem", fontWeight:700, display:"flex", justifyContent:"center", backgroundColor:colorForEnergyLetter(improvementsConfiguration.content.propertyHighlights.currentEnergyRating)}}>
                          {improvementsConfiguration.content.propertyHighlights.currentEnergyRating}
                        </div>
                      </div>
                      {(!_.isNil(improvementsConfiguration.content.propertyHighlights.potentialEnergyEfficiency)) &&
                      <div className={"epc-potential-rating"} style={{
                        display:"flex",
                        flexDirection:"row",
                        justifyContent:"space-between",
                        width: window.innerWidth > 1024 ? "35%" : "100%",
                        maxWidth: window.innerWidth > 1024 ? "auto" : "200px",
                        alignItems:"center"}}>
                          <div>
                              Potential
                          </div>
                          <div style={{fontSize:"1.5rem", marginLeft: "0.25rem", fontWeight: 700}}>
                            {improvementsConfiguration.content.propertyHighlights.potentialEnergyEfficiency}
                          </div>
                          <div style={{width:"3.5rem", marginLeft: "0.25rem", height:"2.5rem", color: "white", fontSize:"1.5rem", fontWeight:700, display:"flex", justifyContent:"center", backgroundColor:colorForEnergyLetter(improvementsConfiguration.content.propertyHighlights.potentialEnergyRating)}}>
                            {improvementsConfiguration.content.propertyHighlights.potentialEnergyRating}
                          </div>
                      </div>
                      }
                    </div>
                    : <div style={{display:"flex", flexDirection:"row", marginTop:"1rem"}}>
                      {enterPropertyRating
                        ? <div style={{display:"flex", flexDirection:"column"}}>
                          <>
                            Property Energy Efficiency (for example 72):
                          </>
                          <div style={{display:"flex", flexDirection:"row"}}>
                            <InputNumberStyled
                              style={{width:"8rem"}}
                              value={energyEfficiency}
                              onChange={(val) => {
                                setEnergyEfficiency(val)
                              }}
                            />
                            <SecondaryButton
                              style={{ margin: "0 0.625rem", height:"2rem", width:"5rem" }}
                              onClick={() => saveEnergyEfficiency()}
                            >
                              Save
                            </SecondaryButton>
                            <SecondaryButton
                              style={{ margin: "0 0.625rem", height:"2rem", width:"5rem" }}
                              onClick={() => setEnterPropertyRating(false)}
                            >
                              Cancel
                            </SecondaryButton>
                          </div>
                        </div>
                        : <><span style={{color: "black", textDecoration: "underline", marginRight: "0.2rem"}}
                                  onClick={() => setEnterPropertyRating(true)}>Click here</span> to enter your EPC details.</>
                      }
                    </div>
                  }
                </div>)}
                {/*<div>*/}
                {/*  <PropertyValuesSubtitle>LivLet Rating</PropertyValuesSubtitle>*/}
                {/*  <div style={{display:"flex", flexDirection:"row", marginTop:"1rem", justifyContent:"space-between", alignItems:"center"}}>*/}
                {/*    <div style={{marginRight:"0.5rem"}}>*/}
                {/*      {displayRating(livletRating)}*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*      <a style={{color:"black", textDecoration:"underline"}} onClick={() => history.push("/improvements")}>Click here</a> to see recommendations on improving your rating.*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </PropertyValues> : <SpinnerWrapper>
                <Spin indicator={<LoadingOutlined style={{fontSize: 72}} spin/>}/>
                <PropertyValueOrigin>Loading the data for your property...</PropertyValueOrigin>
              </SpinnerWrapper>}
            </WidgetContent>
          </>
        }
      </WidgetBody>
    </WidgetWrapper>
  </>
}

const WidgetBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem 1.5rem;
`;

const WidgetWrapper = styled.div`
`;

const PropertyTitle = styled.div`
  color: #21272B;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const MapArea = styled.div`
  height: 20rem;
  width: 40%;
  
  @media (max-width: 750px) {
    width: 100%;
    margin-bottom: 1.5rem;
  }
`;

const PropertyValues = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  min-height: 20rem;
  margin-left: 2rem;
  justify-content: flex-start;
  color: #21272B;
  
  @media (max-width: 750px) {
    width: 100%;
    margin-left: 0;
  }
`;

const PropertyValueNumber = styled.div`
  display: flex;
  flex-shrink: 0;

  font-weight: bold;
  font-size: 2.125rem;
  line-height: 2.625rem;
  color: #21272B;
  
  display: flex;
  column-gap: 0.75rem;
  align-items: center;
`

const PropertyValueOrigin = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #21272B;
  margin-top: 0.25rem;
  margin-bottom: 2rem;
`

const PropertyValuesSubtitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  border-bottom: 1px solid #DAE0E6;
  margin-bottom: 0.5rem;
`;

const PropertyValueInputWrapper = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`

const NoPropertyValue = styled.div`
  margin-bottom: 2rem;
  color: black;
  text-decoration: underline;
  cursor: pointer;
`

const ImproveEstimateButton = styled(Button)`
  display: flex;
  column-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  padding: 0 0.875rem;
  background: #2A80E1;
  border-radius: 6px;
  border: 1px solid #2A80E1;
  
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: white;
  
  :hover svg path {
    stroke: #1890ff;
  }
`

const WidgetContent = styled.div`
  display: flex;
  flex-direction: row; 
  justify-content: space-between;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 60%;
  min-height: 20rem;
  margin-left: 2rem;
  
  @media (max-width: 750px) {
    width: 100%;
    margin-left: 0;
  }
`

// const RatingButtonEPC = styled(RatingButton)`
//   box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
// `;

export default EPCDashboardWidget;
