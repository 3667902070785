import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useIntl } from "react-intl";

import {getPropertyIdSelector, getPropertyPreferencesSelector, getWorksSelector} from "../selectors";
import WorksListComp from "../components/works/WorksListComp";
import messages from "./messages";
import { getAllWorks } from "../actions/works";
import WorkDrawer from "../components/works/WorkDrawer";
import { getAllContacts } from "../actions/contacts";
import {PlusOutlined} from "@ant-design/icons";
import {addWork} from "../../api-wrapper/works/addWork";
import {getAllInventories} from "../actions/inventories";
import {aggregateistOfTags, emptyGuid, getCurrencySign, hasSomeSelectedTags} from "./helpers";
import ForwardWorkDrawer from "../components/works/ForwardWorkDrawer";
import {getAllTags} from "../actions/tags";
import {getEntityTypes} from "../../api-wrapper/relation/getEntityTypes";
import {useLocation} from "react-router-dom";
import {
  AddRecordButton,
  EmptyRecordsDescription,
  EmptyRecordsHeader,
  EmptyRecordsList,
  HeaderControlsWrapper,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  AddButtonStyled,
  PageInfoColumn,
  PageInfoWrapper
} from "./components";
import {numberWithCommas} from "../components/helper";
import ViewWorkDrawer from "../components/works/ViewWorkDrawer";
import ExpenseDrawer from "../components/expenses/ExpenseDrawer";
import InventoryDrawer from "../components/inventories/InventoryDrawer";
import ContactDrawer from "../components/contacts/ContactDrawer";
import NoteDrawer from "../components/notes/NoteDrawer";
import LinkDrawer from "../components/spaces/LinkDrawer";
import TradeDrawer from "../components/partners/TradeDrawer";
import {getAllExpenses} from "../actions/expenses";
import {getAllAttachments} from "../actions/attachments";
import {getAllImprovements} from "../actions/improvements";
import EditSpaceDrawer from "../components/spaces/EditSpaceDrawer";
import {getAllSpaces} from "../actions/spaces";
import {getAllProperties} from "../actions/properties";
import {getAllMaintenances} from "../actions/maintenances";
import UpdateTaskDrawer from "../components/planner/UpdateTaskDrawer";
import AddEventDrawer from "../components/planner/AddEventDrawer";
import {getAllNotesByPropertyId} from "../actions/notesByPropertyId";
import ViewFileDrawer from "../components/attachments/ViewFileDrawer";
const moment = require("moment");

const Works = () => {
  const { formatMessage: f } = useIntl();

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const works = useSelector(getWorksSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [isViewWorkDrawerOpen, toggleViewWorkDrawer] = useState(false);
  const [workToViewId, setWorkToViewId] = useState<any>(null);
  const [isLinkDrawerOpen, toggleLinkDrawer] = useState(false);
  const [linkedWorkId, setLinkedWorkId] = useState<any>(null);

  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editingWorkData, setEditingWorkData] = useState<any>(null);
  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [editingExpenseData, setEditingExpenseData] = useState(null);
  const [isInventoryDrawerOpen, toggleInventoryDrawer] = useState(false);
  const [editingInventoryData, setEditingInventoryData] = useState(null);
  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editingContactData, setEditingContactData] = useState(null);
  const [isNoteDrawerOpen, toggleNoteDrawer] = useState(false);
  const [editingNoteData, setEditingNoteData] = useState(null);
  const [isTradeDrawerOpen, toggleTradeDrawer] = useState(false);
  const [isSpaceDrawerOpen, toggleSpaceDrawer] = useState(false);
  const [editSpaceId, setEditSpaceId] = useState(null);
  const [presetLinkType, setPresetLinkType] = useState<any>(null);
  const [isViewFileDrawerOpen, toggleViewFileDrawer] = useState(false);
  const [attachmentToViewId, setAttachmentToViewId] = useState<any>(null);

  const [worksToDoSort, setWorksToDoSort] = useState("");
  const [worksCompletedSort, setWorksCompletedSort] = useState("");
  const [worksToDoSearch, setWorksToDoSearch] = useState("");
  const [worksCompletedSearch, setWorksCompletedSearch] = useState("");
  const [isForwardWorkDrawerOpen, toggleForwardWorkDrawer] = useState(false);
  const [workToForward, setWorkToForward] = useState(emptyGuid);
  const [selectedTagsToDo, setSelectedTagsToDo] = useState<any>([]);
  const [selectedTagsCompleted, setSelectedTagsCompleted] = useState<any>([]);

  const [startDateToDo, setStartDateToDo] = useState<any>(null)
  const [endDateToDo, setEndDateToDo] = useState<any>(null)
  const [selectedPredefinedDateToDo, setSelectedPredefinedDateToDo] = useState("custom");
  const [startDateCompleted, setStartDateCompleted] = useState<any>(null)
  const [endDateCompleted, setEndDateCompleted] = useState<any>(null)
  const [selectedPredefinedDateCompleted, setSelectedPredefinedDateCompleted] = useState("custom");

  const [workTypes, setWorkTypes] = useState<any>([]);
  const [selectedWorkTypesToDo, setSelectedWorkTypesToDo] = useState<Set<string>>(new Set());
  const [selectedWorkTypesCompleted, setSelectedWorkTypesCompleted] = useState<Set<string>>(new Set());
  const [worksToDoTypesWithAmounts, setWorksToDoTypesWithAmounts] = useState<Array<{name: string, amount: number}>>([]);
  const [worksCompletedTypesWithAmounts, setWorksCompletedTypesWithAmounts] = useState<Array<{name: string, amount: number}>>([]);

  const [isTaskDrawerOpen, toggleTaskDrawer] = useState(false);
  const [editingTaskData, setEditingTaskData] = useState<any>(null);
  const [taskType, setTaskType] = useState("Task");
  const [isEventDrawerOpen, toggleEventDrawer]  = useState(false);
  const [preSelectedTab, setPreSelectedTab] = useState("");

  const parseToLowerCase = (el: string) => el.toLowerCase();

  const worksToDoLength = works.content.filter(work => work.name !== null && !work.isComplete).length;
  const worksCompletedLength = works.content.filter(work => work.name !== null && work.isComplete).length;
  const worksTotalCost = works.content.reduce((sumOfCosts, { workTotalExpense }) => sumOfCosts + workTotalExpense, 0);

  const getSearchType = (completedWorks: boolean) => completedWorks ? worksCompletedSearch : worksToDoSearch
  const getSortType = (completedWorks: boolean) => completedWorks ? worksCompletedSort : worksToDoSort
  const getSelectedTagsType = (completedWorks: boolean) => completedWorks ? selectedTagsCompleted : selectedTagsToDo

  const getWorks = (completedWorks: boolean) =>
    works.content
      .filter(work => work.name !== null)
      .filter((work) => completedWorks ? work.isComplete : !work.isComplete)
      .filter((work) => {
        return !_.isEmpty(getSearchType(completedWorks))
          ? _.includes(
            parseToLowerCase(work.name),
            parseToLowerCase(getSearchType(completedWorks))
          ) ||
          (!_.isNil(work.tags) &&
            _.includes(
              parseToLowerCase(aggregateistOfTags(work.tags)),
              parseToLowerCase(getSearchType(completedWorks))
            )) : true;
      })
      .filter((work) => {
        return !_.isEmpty(getSelectedTagsType(completedWorks))
          ? hasSomeSelectedTags(getSelectedTagsType(completedWorks), work.tags) : true;
      })
      .filter(work => {
        if (completedWorks) {
          return selectedWorkTypesCompleted.size > 0
            ? selectedWorkTypesCompleted.has(""+work.type)
            : true;
        } else {
          return selectedWorkTypesToDo.size > 0
            ? selectedWorkTypesToDo.has(""+work.type)
            : true;
        }
      })
      .filter(work => {
        if (completedWorks) {
          return !_.isNil(startDateCompleted)
            ? (moment(work.completedDate).add(1, 'day').format("YYYYMMDD")
            > startDateCompleted.format("YYYYMMDD")) : true;

        } else {
          return !_.isNil(startDateToDo)
            ? (moment(work.dueDate).add(1, 'day').format("YYYYMMDD")
              > startDateToDo.format("YYYYMMDD")) : true;
        }
      })
      .filter(work => {
        if (completedWorks) {
          return !_.isNil(endDateCompleted)
            ? (moment(work.completedDate).add(1, 'day').format("YYYYMMDD")
              < endDateCompleted.format("YYYYMMDD")) : true;

        } else {
          return !_.isNil(endDateToDo)
            ? (moment(work.dueDate).add(1, 'day').format("YYYYMMDD")
              < endDateToDo.format("YYYYMMDD")) : true;
        }
      })
      .sort((w1, w2) => {
        if (getSortType(completedWorks) === "costHighToLow") {
          return w2.workTotalExpense - w1.workTotalExpense
        } else if (getSortType(completedWorks) === "costLowToHigh") {
          return w1.workTotalExpense - w2.workTotalExpense
        } else if (getSortType(completedWorks) === "typeSort") {
          return w1.type.localeCompare(w2.type)
        } else if (getSortType(completedWorks).includes("Desc")) {
          if (completedWorks) {
              return moment(w2.completedDate).format('YYYYMMDD') - moment(w1.completedDate).format('YYYYMMDD')
            } else {
              let dueDate1 = w1.dueDate ? moment(w1.dueDate).format('YYYYMMDD') : 999999999
              let dueDate2 = w2.dueDate ? moment(w2.dueDate).format('YYYYMMDD') : 999999999
              return dueDate2 - dueDate1
            }
        } else if (completedWorks) {
          return moment(w1.completedDate).format('YYYYMMDD') - moment(w2.completedDate).format('YYYYMMDD')
        } else {
          let dueDate1 = w1.dueDate ? moment(w1.dueDate).format('YYYYMMDD') : 999999999
          let dueDate2 = w2.dueDate ? moment(w2.dueDate).format('YYYYMMDD') : 999999999
          return dueDate1 - dueDate2
        }
      })

  useEffect(() => {
    if (_.isEqual(propertyId, emptyGuid))
      return;
    dispatch(getAllWorks(propertyId));
    dispatch(getAllInventories(propertyId));
    dispatch(getAllContacts(propertyId));
    dispatch(getAllExpenses(propertyId));
    dispatch(getAllAttachments(propertyId));
    dispatch(getAllNotesByPropertyId(propertyId));
    dispatch(getAllImprovements(propertyId));
    dispatch(getAllSpaces(propertyId));
    dispatch(getAllMaintenances(propertyId));
    dispatch(getAllProperties());
    dispatch(getAllTags());
  }, [propertyId]);

  useEffect(() => {
    getEntityTypes(propertyId, "work").then((res: any) => setWorkTypes(res))
  }, [propertyId])

  const getWorkTypesWithAmounts = (workItems: any[]) => {
    let amountsMap = new Map();
    workItems.forEach((work => {
      if (amountsMap.has(work.type)) {
        amountsMap.set(work.type, amountsMap.get(work.type)+1);
      } else {
        amountsMap.set(work.type, 1);
      }
    }));
    let amounts = new Array();
    workTypes.forEach((workType: any) => {
      let amount = 0;
      if (amountsMap.has(workType.name)) {
        amount = amountsMap.get(workType.name);
      }
      amounts.push({name: workType.name, amount: amount});
    });
    amounts = amounts.sort((e1, e2) => e2.amount - e1.amount);
    return amounts;
  }

  useEffect(() => {
    if (workTypes && workTypes.length > 0) {
      const toDoAmounts = getWorkTypesWithAmounts(getWorks(false))
      setWorksToDoTypesWithAmounts(toDoAmounts)
    }
  }, [works, workTypes, worksToDoSearch, startDateToDo, endDateToDo, selectedTagsToDo]);

  useEffect(() => {
    if (workTypes && workTypes.length > 0) {
      const completedAmounts = getWorkTypesWithAmounts(getWorks(true))
      setWorksCompletedTypesWithAmounts(completedAmounts)
    }
  }, [works, workTypes, worksCompletedSearch, startDateCompleted, endDateCompleted, selectedTagsCompleted]);

  const addNewWork = () => {
    addWork({
      propertyId,
      name: null,
      type: null,
      completedDate: null,
      dueDate: null,
      recurring: null,
      isComplete: false,
    }).then((res) => {
      setEditingWorkData(res);
      dispatch(getAllWorks(propertyId));
      toggleWorkDrawer(true);
    });
  }

  const useQuery = (q: string) => {
    let query = new URLSearchParams(useLocation().search);
    return query.get(q);
  };
  const typeToOpen = useQuery("type")

  useEffect(() => {
    if (typeToOpen) {
      // @ts-ignore
      document.getElementById("completed-works").scrollIntoView()
    }
  }, [typeToOpen])

  return (
    <PageWrapper>
      <WorkDrawer
        width={null}
        isOpen={isWorkDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        // @ts-ignore
        setEditingWorkData={setEditingWorkData}
        work={editingWorkData}
      />
      <ForwardWorkDrawer
        isOpen={isForwardWorkDrawerOpen}
        toggleDrawerOpen={toggleForwardWorkDrawer}
        workId={workToForward}
      />
      <ViewWorkDrawer
        workId={workToViewId}
        setWorkId={setWorkToViewId}
        isOpen={isViewWorkDrawerOpen}
        toggleDrawer={toggleViewWorkDrawer}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditingWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setLinkedWorkId={setLinkedWorkId}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setWorkIdToForward={setWorkToForward}
        toggleForwardDrawer={toggleForwardWorkDrawer}
        toggleTradeDrawer={toggleTradeDrawer}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleSpaceDrawer}
        setPresetLinkType={setPresetLinkType}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        preSelectedTab={preSelectedTab}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <LinkDrawer
        parentId={linkedWorkId}
        parentType={"work"}
        isOpen={isLinkDrawerOpen}
        toggleDrawerOpen={toggleLinkDrawer}
        presetLinkType={presetLinkType}
        setPresetLinkType={setPresetLinkType}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditingWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleSpaceDrawer}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        toggleEventDrawer={toggleEventDrawer}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <AddEventDrawer
        isOpen={isEventDrawerOpen}
        toggleDrawer={toggleEventDrawer}
        setSelectedType={setTaskType}
        toggleWorkDrawer={toggleWorkDrawer}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditingWorkData={setEditingWorkData}
      />
      <UpdateTaskDrawer
        task={editingTaskData}
        setEditingTaskData={setEditingTaskData}
        isOpen={isTaskDrawerOpen}
        toggleDrawer={toggleTaskDrawer}
        taskType={taskType}
        setTaskType={setTaskType}
      />
      <TradeDrawer
        isDrawerOpen={isTradeDrawerOpen}
        toggleDrawer={toggleTradeDrawer}
        selectedType={""}
        setSelectedType={() => {}}
      />
      <EditSpaceDrawer
        spaceId={editSpaceId}
        isOpen={isSpaceDrawerOpen}
        toggleDrawerOpen={toggleSpaceDrawer}
        setEditingSpaceId={setEditSpaceId}
      />
      <ExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        toggleDrawerOpen={toggleExpenseDrawer}
        expense={editingExpenseData}
        // @ts-ignore
        setEditingExpenseData={setEditingExpenseData}
        // @ts-ignore
        refreshParent={() => {}}
      />
      <InventoryDrawer
        isOpen={isInventoryDrawerOpen}
        toggleDrawerOpen={toggleInventoryDrawer}
        inventory={editingInventoryData}
        // @ts-ignore
        setEditingInventoryData={setEditingInventoryData}
        refreshParent={() => {}}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
      />
      <ContactDrawer
        isOpen={isContactDrawerOpen}
        toggleDrawerOpen={toggleContactDrawer}
        contact={editingContactData}
        // @ts-ignore
        setEditingContactData={setEditingContactData}
        refreshParent={() => {}}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
      />
      <ViewFileDrawer
        attachmentId={attachmentToViewId}
        setAttachmentId={setAttachmentToViewId}
        isOpen={isViewFileDrawerOpen}
        toggleDrawer={toggleViewFileDrawer}
        viewFileDrawerDefaultTab={"previewOnly"}
        attachmentsWithoutProperty={[]}
        duplicateAttachments={[]}
        selectedTags={[]}
        toggleEditTagsMode={() => {}}
        toggleEditDrawerOpen={() => {}}
        setPresetLinkType={() => {}}
        toggleExpenseDrawer={() => {}}
        setEditExpenseData={() => {}}
        toggleInventoryDrawer={() => {}}
        setEditInventoryData={() => {}}
        toggleContactDrawer={() => {}}
        setEditContactData={() => {}}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        toggleWorkDrawer={() => {}}
        setEditWorkData={() => {}}
        toggleNoteDrawer={() => {}}
        setEditNoteData={() => {}}
        toggleLinkDrawerOpen={() => {}}
        setEditSpaceId={() => {}}
        toggleSpaceDrawer={() => {}}
        refreshParent={() => {}}
        toggleViewWorkDrawer={() => {}}
        setViewWorkId={() => {}}
      />
      <NoteDrawer
        currentType={"note"}
        data={editingNoteData}
        isOpen={isNoteDrawerOpen}
        toggleDrawerOpen={toggleNoteDrawer}
        refreshParent={() => {}}
        isGlobalNote={true}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        setPresetLinkType={setPresetLinkType}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleSpaceDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <PageHeader>
        <HeaderControlsWrapper>
          <div>
            <PageTitle>Projects</PageTitle>
            <PageSubtitle>Manage your home improvement and maintenance projects</PageSubtitle>
          </div>
        </HeaderControlsWrapper>
        <HeaderControlsWrapper>
        <AddButtonStyled
          icon={<PlusOutlined />}
          onClick={() => addNewWork()}
        >
          {window.innerWidth > 1024 && f(messages.addWorkBtn)}
        </AddButtonStyled>
      </HeaderControlsWrapper>
      </PageHeader>
      <PageInfoWrapper>
        <PageInfoColumn>
          <div className={"column-header"}>Total project expenses</div>
          <div className={"column-content"}>
            {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(worksTotalCost)}
          </div>
        </PageInfoColumn>
        <PageInfoColumn>
          <div className={"column-header"}>Current and upcoming</div>
          <div className={"column-content"}>
            {worksToDoLength}
          </div>
        </PageInfoColumn>
        <PageInfoColumn>
          <div className={"column-header"}>Completed</div>
          <div className={"column-content"}>
            {worksCompletedLength}
          </div>
        </PageInfoColumn>
      </PageInfoWrapper>

      {(worksToDoLength === 0 && worksCompletedLength === 0) && (
        <EmptyRecordsList>
          <EmptyRecordsHeader>
            {f(messages.addWorkTitle)}
          </EmptyRecordsHeader>
          <EmptyRecordsDescription>
            {f(messages.addWorkMsg)}
          </EmptyRecordsDescription>
          <AddRecordButton
            onClick={() => addNewWork()}
            size={"large"}
            icon={<PlusOutlined/>}
          >
            {f(messages.addWorkBtn)}
          </AddRecordButton>
        </EmptyRecordsList>
      )}
      {worksToDoLength > 0 && (
          <WorksListComp
            completedWorks={false}
            works={getWorks(false)}
            setWorksSort={setWorksToDoSort}
            setWorksSearch={setWorksToDoSearch}
            toggleForwardWorkDrawer={toggleForwardWorkDrawer}
            setWorkToForward={setWorkToForward}
            selectedTags={selectedTagsToDo}
            setSelectedTags={setSelectedTagsToDo}
            startDate={startDateToDo}
            setStartDate={setStartDateToDo}
            endDate={endDateToDo}
            setEndDate={setEndDateToDo}
            selectedPredefinedDate={selectedPredefinedDateToDo}
            setSelectedPredefinedDate={setSelectedPredefinedDateToDo}
            workTypesWithAmounts={worksToDoTypesWithAmounts}
            selectedWorkTypes={selectedWorkTypesToDo}
            setSelectedWorkTypes={setSelectedWorkTypesToDo}
            toggleWorkDrawer={toggleWorkDrawer}
            setEditWorkData={setEditingWorkData}
            toggleViewWorkDrawer={toggleViewWorkDrawer}
            setWorkToViewId={setWorkToViewId}
            setPreSelectedTab={setPreSelectedTab}
          />
        )}
      <span id={"completed-works"}>
        {worksCompletedLength > 0 && (
          <WorksListComp
            completedWorks={true}
            works={getWorks(true)}
            setWorksSort={setWorksCompletedSort}
            setWorksSearch={setWorksCompletedSearch}
            toggleForwardWorkDrawer={toggleForwardWorkDrawer}
            setWorkToForward={setWorkToForward}
            selectedTags={selectedTagsCompleted}
            setSelectedTags={setSelectedTagsCompleted}
            startDate={startDateCompleted}
            setStartDate={setStartDateCompleted}
            endDate={endDateCompleted}
            setEndDate={setEndDateCompleted}
            selectedPredefinedDate={selectedPredefinedDateCompleted}
            setSelectedPredefinedDate={setSelectedPredefinedDateCompleted}
            workTypesWithAmounts={worksCompletedTypesWithAmounts}
            selectedWorkTypes={selectedWorkTypesCompleted}
            setSelectedWorkTypes={setSelectedWorkTypesCompleted}
            toggleWorkDrawer={toggleWorkDrawer}
            setEditWorkData={setEditingWorkData}
            toggleViewWorkDrawer={toggleViewWorkDrawer}
            setWorkToViewId={setWorkToViewId}
            setPreSelectedTab={setPreSelectedTab}
          />
        )}
      </span>
    </PageWrapper>
  );
};

export default Works;
