import React, {useEffect, useState} from "react";

import {ReactComponent as ContentsWidgetIcon} from "../../../images/dashboard-contents/ContentsIcon.svg";
import {ReactComponent as EOLIcon} from "../../../images/dashboard-contents/EOLIcon.svg";
import {ReactComponent as InsuredIcon} from "../../../images/dashboard-contents/InsuredIcon.svg";
import {ReactComponent as TotalValueIcon} from "../../../images/dashboard-contents/TotalValueIcon.svg";
import {ReactComponent as UninsuredIcon} from "../../../images/dashboard-contents/UninsuredIcon.svg";
import {ReactComponent as ViewAllArrow} from "../../../images/dashboard-improvements/ViewAllArrow.svg";
import {ReactComponent as YellowInfoIcon} from "../../../images/dashboard-contents/YellowInfoIcon.svg";
import {ReactComponent as CloseIcon} from "../../../images/dashboard-contents/CloseIcon.svg";

import {ReactComponent as ContentsEmptyIconCoffee} from "../../../images/dashboard-contents/ContentsEmptyIconCoffee.svg";
import {ReactComponent as ContentsEmptyIconFridge} from "../../../images/dashboard-contents/ContentsEmptyIconFridge.svg";
import {ReactComponent as ContentsEmptyOven} from "../../../images/dashboard-contents/ContentsEmptyOven.svg";
import {ReactComponent as ContentsEmptyWashingMachine} from "../../../images/dashboard-contents/ContentsEmptyWashingMachine.svg";

import {numberWithCommasFull} from "../helper";

import {
  borderSetting,
  EmptyWidget,
  EmptyWidgetTitle,
  OuterWidgetWrapper,
  ViewAllButton,
  WidgetItemCount,
  WidgetItemCountDescription,
  WidgetItemCountNumber,
  WidgetItemTitle,
  WidgetTitle,
  WidgetTitleWrapper,
  WidgetWrapper
} from "./ImprovementsWidget";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
  getInventoriesSelector,
  getPropertiesSelector,
  getPropertyIdSelector, getPropertyPreferencesSelector
} from "../../selectors";
import InfoDropdown from "./InfoDropdown";
import _ from "lodash";
import {WidgetPlaceholderText} from "../upcomingTasksCalendar/UpcomingTaskCalendar";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {TFetchingStatus} from "../../../helpers";
import {getCurrencySign, isUKProperty} from "../../screens/helpers";

const ContentsWidget = () => {
  let history = useHistory();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const inventory = useSelector(getInventoriesSelector);
  const properties = useSelector(getPropertiesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const determineEndOfLifeRisk = (applianceLifetimeYearsLeft: number | null) => {
    if (!_.isNull(applianceLifetimeYearsLeft)) {
      if (applianceLifetimeYearsLeft <= 1) return 1
      else return 0
    } else return 0
  }

  const inventoryTotalCost = inventory.content.reduce(
    (sumOfCosts, { purchaseCost }) => sumOfCosts + purchaseCost,
    0
  );
  const insuredInventoryTotalCost = inventory.content.reduce(
    (sumOfCosts, { insuredItems, purchaseCost }) => sumOfCosts + (insuredItems ? purchaseCost : 0),
    0
  );
  const endOfLifeRiskCount = inventory.content.reduce(
    (sumOfCosts, { applianceLifetimeYearsLeft }) => sumOfCosts + determineEndOfLifeRisk(applianceLifetimeYearsLeft),
    0
  );
  const totalUninsuredItems = inventory.content.filter(item => !item.insuredItems).length;
  const totalInsuredItems = inventory.content.filter(item => item.insuredItems).length;

  const [showPlaceholderWidget, setShowPlaceholderWidget] = useState(false)
  const [showEmptyWidget, setShowEmptyWidget] = useState(false)
  const [showPutToWorkAlert, setShowPutToWorkAlert] = useState(true)
  const [onboardingStatus, setOnboardingStatus] = useState("")
  const [dataDownloaded, setDataDownloaded] = useState(false);
  const [ukProperty, setUKProperty] = useState(false);

  useEffect(() => {
    const currentProperty = properties.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty) {
      setDataDownloaded(false);
      return;
    }
    setUKProperty(isUKProperty(currentProperty.country, currentProperty.zip))
    setOnboardingStatus(currentProperty.onboardingContentStatus)
    if (inventory.fetchingStatus !== TFetchingStatus.Success) {
      setDataDownloaded(false);
      return;
    }
    setDataDownloaded(true);
    const totalItemsWithPurchaseDate = inventory.content.filter(item => item.installedOrPurchaseDate).length
    if (inventoryTotalCost > 0 || totalInsuredItems > 0 || totalItemsWithPurchaseDate > 0) {
      setShowEmptyWidget(false)
      setShowPlaceholderWidget(false)
    } else if (inventory.content.length > 0) {
      setShowEmptyWidget(true)
      setShowPlaceholderWidget(false)
    } else {
      setShowEmptyWidget(false)
      setShowPlaceholderWidget(true)
    }
  }, [inventory, onboardingStatus, propertyId, properties])

  if (!dataDownloaded) {
    return <Spin indicator={<LoadingOutlined style={{fontSize: 72}} spin/>}/>
  }

  return <OuterWidgetWrapper style={{width: (ukProperty && window.innerWidth > 1024) ? "50%" : "100%"}}>
    <WidgetTitleWrapper>
      <WidgetTitle><ContentsWidgetIcon/>Contents</WidgetTitle>
      {!showPlaceholderWidget && window.innerWidth > 1024 && <ViewAllButton onClick={() => history.push("/contents")}>
        {showEmptyWidget ? "My contents" : "View all"} <ViewAllArrow/>
      </ViewAllButton>}
    </WidgetTitleWrapper>
    {!showPlaceholderWidget ? <>
      <WidgetWrapper>
      <ContentsWidgetItem
        onClick={() => history.push("/contents")}
        style={{borderRight: borderSetting, borderBottom: borderSetting}}>
        <WidgetItemIconWrapper>
          <TotalValueIcon />
          <InfoDropdown
            description={"This is the user-defined value of contents that have been input into Livlet."} />
        </WidgetItemIconWrapper>
        <WidgetItemTitle>Total value</WidgetItemTitle>
        <WidgetItemCount>
          <CurrencySign>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
          </CurrencySign>
          <WidgetItemCountNumber>{numberWithCommasFull(inventoryTotalCost)}</WidgetItemCountNumber>
        </WidgetItemCount>
      </ContentsWidgetItem>
      <ContentsWidgetItem
        onClick={() => history.push("/contents")}
        style={{borderBottom: borderSetting}}>
        <WidgetItemIconWrapper>
          <InsuredIcon />
          <InfoDropdown
            description={"This is the Value of the Insured items as defined by the user for contents that have been input into Livlet."} />
        </WidgetItemIconWrapper>
        <WidgetItemTitle>Insured</WidgetItemTitle>
        {totalInsuredItems > 0 ? <WidgetItemCount>
          <CurrencySign className={"gray"}>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
          </CurrencySign>
          <WidgetItemCountNumber>{numberWithCommasFull(insuredInventoryTotalCost)}</WidgetItemCountNumber>
        </WidgetItemCount> : <WidgetItemCount>
          <WidgetItemCountNumber>{totalInsuredItems}</WidgetItemCountNumber>
          <WidgetItemCountDescription>items</WidgetItemCountDescription>
        </WidgetItemCount>}
      </ContentsWidgetItem>
      <ContentsWidgetItem
        onClick={() => history.push("/contents")}
        style={{borderRight: borderSetting}}>
        <EOLIcon />
        <WidgetItemTitle>End of life risk</WidgetItemTitle>
        <WidgetItemCount>
          <WidgetItemCountNumber>{endOfLifeRiskCount}</WidgetItemCountNumber>
          <WidgetItemCountDescription>items</WidgetItemCountDescription>
        </WidgetItemCount>
      </ContentsWidgetItem>
      <ContentsWidgetItem onClick={() => history.push("/contents")}>
        <WidgetItemIconWrapper>
          <UninsuredIcon />
          <InfoDropdown
            description={"This is the number of Uninsured items as defined by the user for contents that have been input into Livlet."} />
        </WidgetItemIconWrapper>
        <WidgetItemTitle>Uninsured</WidgetItemTitle>
        <WidgetItemCount>
          <WidgetItemCountNumber>{totalUninsuredItems}</WidgetItemCountNumber>
          <WidgetItemCountDescription>items</WidgetItemCountDescription>
        </WidgetItemCount>
      </ContentsWidgetItem>
      {showEmptyWidget && showPutToWorkAlert && <EmptyWidgetNotification>
        <YellowInfoIcon style={{flexShrink: 0}}/>
        <div>
          <p style={{color: "#002A43", fontWeight: 600}}>Let's put this space to work</p>
          You can now improve the above analytics by adding purchase date, insurance details and content values via your
          Contents page.
        </div>
        <CloseIcon onClick={() => setShowPutToWorkAlert(false)} style={{cursor: "pointer", flexShrink: 0}}/>
      </EmptyWidgetNotification>}
    </WidgetWrapper>
    {window.innerWidth < 1024 && <ViewAllButton
            style={{width: "100%"}}
            onClick={() => history.push("/contents")}>Contents <ViewAllArrow/></ViewAllButton>}</> :
      (window.innerWidth > 1024 ? <EmptyWidget>
      <div style={{display: "flex", columnGap: "0.5rem"}}>
        <ContentsEmptyWashingMachine />
        <ContentsEmptyIconFridge />
        <ContentsEmptyOven />
        <ContentsEmptyIconCoffee />
      </div>
      <EmptyWidgetTitle style={{textAlign: "center"}}>Keep track of and manage your home's contents, appliances & systems</EmptyWidgetTitle>
      <span>We can help you decide which items deserve to be insured, track end of life and replacement cycles, etc.</span>
      <ViewAllButton onClick={() => history.push("/questions?openPage=contents&origin=dashboard")}>
        Let's start by selecting a few appliances <ViewAllArrow />
      </ViewAllButton>
    </EmptyWidget> : <WidgetPlaceholderText style={{margin: 0}}>
        <div style={{display: "flex", columnGap: "0.5rem"}}>
          <ContentsEmptyWashingMachine />
          <ContentsEmptyIconFridge />
          <ContentsEmptyOven />
          <ContentsEmptyIconCoffee />
        </div>
        <EmptyWidgetTitle style={{textAlign: "center"}}>Keep track of and manage your home's contents, appliances & systems</EmptyWidgetTitle>
        <ViewAllButton onClick={() => history.push("/questions?openPage=contents&origin=dashboard")}>
          Let's start by selecting a few appliances <ViewAllArrow />
        </ViewAllButton>
      </WidgetPlaceholderText>)}
  </OuterWidgetWrapper>
}

const ContentsWidgetItem = styled.div`
  width: calc(100% / 2);
  display: flex;
  flex-direction: column;
  row-gap: 0.375rem;
  padding: 1.5rem;
  overflow: hidden;
  cursor: pointer;
  
  @media (max-width: 1024px) {
    padding: 1rem;
  }
`

export const WidgetItemIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const EmptyWidgetNotification = styled.div`
  display: flex;
  column-gap: 1.75rem;
  padding: 1.5rem;
  
  background: #FEF9EA;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666F7A;
  
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: all .3s;
`

const CurrencySign = styled.span`
  color: #7CC9B7;
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.75rem;
  
  &.gray { color: #DAE0E6; }
`

export default ContentsWidget;