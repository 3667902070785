import styled from "styled-components";
import { Button, Input, List, Pagination, Tag } from "antd";

export const ButtonStyled = styled(Button)`
  @media (max-width: 1024px) {
    width: 25%;
    min-width: 9.5rem;
    height: 2.5rem;
  }
  @media (min-width: 1024px) {
    height: 3rem;
    width: 25%;
  }
  border-radius: 6px;
  :hover {
    background-color: #236ec2;
  }
`;

export const ButtonStyledGreen = styled(Button)`
  @media (max-width: 1024px) {
    width: 25%;
    min-width: 9.5rem;
    height: 2.5rem;
  }
  @media (min-width: 1024px) {
    height: 3rem;
    width: 25%;
  }
  border-radius: 6px;
  :hover {
    background-color: #00CC33;
    color: white;
    border: 1px solid #00CC33;
  }
`;

export const ButtonText = styled.span`
  font-weight: 600;
  font-size: 0.875rem;
  color: #21272b;
`;

export const SecondaryButton = styled(ButtonStyled)`
  border: 1px solid #dae0e6;
  :hover {
    border: 1px solid #2a80e1;
  }
`;

export const ImageWrapper = styled.aside`
  @media (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1024px) {
    width: 100%;
    height: 100vh;
  }
`;

export const FormWrapper = styled.div`
  @media (max-width: 1024px) {
    min-width: 325px;
    width: 100%;
    padding: 0 1rem;
  }

  @media (min-width: 1024px) {
    padding: 0 3rem 4rem;
    min-width: 650px;
    min-height: 720px;
  }
  max-height: 100vh;
  height: 100vh;
`;

export const ButtonSort = styled(Button)`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 6px!important;
  font-weight: 600;
  height: 3rem!important;
  width: 3rem!important;
  
`;

export const ButtonExport = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 6px!important;
  font-weight: 600;
  height: 3rem!important;
  
  @media (max-width: 1024px) {
    &.notes { width: 3rem; }
  }
`;

export const PaginationStyledMobile = styled(Pagination)`
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 0 0 10px 10px;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
`;

export const PaginationStyled = styled(Pagination)`
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 0 0 10px 10px;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
`;

export const Search = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
  height: 3rem !important;
`;

export const SearchWrapperMobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  //margin: 0 0.875rem 0 0.875rem;
`;

export const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1.25rem;
  padding: 0 1.25rem 0 1.25rem;
`;

export const AttachmentSearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1.25rem;
  padding: 0 1rem;
  gap: 0.5rem;
  
 @media (max-width: 1024px) {
    padding: 0;
    gap: 0.25rem;
  }
`;

export const TagStyled = styled(Tag)`
  background-color: #eff1f5;
  color: #6b7185;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1rem;
  padding: 0.3rem 0.75rem;
  padding-top: 0.5rem;
  border-radius: 0.25rem;
  border: 0;
  font-size: 0.75rem;
  font-weight: 500;
  
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ListItemStyled = styled(List.Item)`
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0.5rem 1rem;
    &.onboarding { padding: 0 0 1rem 1rem; }

    .ant-list-item-action > li {
      width: 100%;
    }
  }
  
  @media (min-width: 1024px) {
    padding: 0.5rem 2rem;
    &.onboarding { padding: 0 0 1rem 1rem; }
  }

  .ant-list-item-meta-title {
    font-size: 1rem;
    font-weight: 600;
    color: #21272b;
  }
  .ant-list-item-meta-description {
    color: #6b7185;
    font-weight: 400;
    margin-top: 0.4rem;
  }
`;

export const AddNoteButton = styled(Button)`
  border: 1px solid #dae0e6;
  border-radius: 6px;
  font-weight: 600;
  color: #21272b;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const ShowFiltersButton = styled(Button)`
  border: 1px solid #dae0e6;
  border-radius: 6px;
  font-weight: 600;
  color: #21272b;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 3rem!important;
  
  @media (max-width: 1024px) {
    flex-grow: 2;
  }
`;

export const ShowAttachmentFiltersButton = styled(Button)`
  border: 1px solid #dae0e6;
  border-radius: 6px;
  font-weight: 600;
  color: #21272b;
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: center;
  height: 3rem !important;
  
  @media (max-width: 1024px) {
    width: 100%;
  }
  
  @media (min-width: 1024px) {
    margin-left: 1rem;
  }
`;

export const NotesListMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  border-radius: 0.625rem;
  border: 1px solid #dae0e6;
  align-items: center;
  background-color: #fff;
  margin: 0 1rem;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const NotesList = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: 0 2.5rem;
  background-color: #fff;

  @media (max-width: 1024px) {
    margin: 0 1rem;
    padding: 1rem 2rem;
  }
`;

export const EmptyNotesList = styled(NotesList)`
  border-radius: 0.625rem;
  @media (min-width: 1024px) {
    padding: 5vh 20vw;
  }
`;

export const NotesToDoLabel = styled.h5`
  padding-left: 0.5rem;
  font-weight: 600;
  font-size: 1.125rem;
  color: #21272B;
`;

export const Filters = styled.div`
  background: #EBF9FF;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  
  @media (max-width: 1024px) {
    padding: 1rem 0.5rem;
  }
  
  @media (max-width: 768px) {
    &.padded { margin-bottom: 1rem; }
  }
  
  &.search-filters-desktop {
    border: 1px solid #DAE0E6;
    border-radius: 16px;
  }
`;

export const FilterGroupRowNotes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const FilterGroupRow = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #DAE0E6;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  
  .ant-collapse-header { padding: 0!important; }
  
  @media (max-width: 1024px) {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
`;

export const FilterGroupRowSearch = styled(FilterGroupRow)`
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const FilterGroupBottomRow = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  
  @media (max-width: 1024px) {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
`;

export const FilterTypesGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const FilterTypesGroupWrapperSearch = styled(FilterTypesGroupWrapper)`
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const FilterGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterGroupHeader = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 2rem;
`;

export const FilterGroupList = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
`;

export const FilterElements = styled.ul`
  padding-left: 0;
  list-style-type: none;
  line-height: 2.5rem;
  font-size: 1rem;
  input {
    margin-right: 0.5rem;
  }
  .disabled {
    color: #bdbdbd;
  }
`;

export const FilterElementsTypes = styled.ul`
  display: flex;
  flex-wrap: wrap;
  
  padding-left: 0;
  list-style-type: none;
  line-height: 2.5rem;
  font-size: 1rem;
  input {
    margin-right: 0.5rem;
  }
  .disabled {
    color: #bdbdbd;
  }
  
  li {
    width: calc(100% / 2);
  }
  
  @media (max-width: 1024px) {
      li {
      width: calc(100% / 2);
    }
  }
`;

export const NotesListDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  
  @media (max-width: 768px) {
    background: #F0F2F5;
  }
`;

export const AttachmentsListDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #DAE0E6;
  
  &.selected-files { margin-bottom: 5.75rem; }
  
  @media (max-width: 1024px) {
    background-color: #F0F2F5;
    border: none;
  }
`;

export const MainListHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
  
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666F7A;
  column-gap: 1rem;
`

export const MainListColumn = styled.div`
  display: flex;
  width: ${(props: { spanWidth: string }) => props.spanWidth};
  max-width: ${(props: { spanWidth: string }) => props.spanWidth};
`

export const MainListItemStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 1rem;
  transition: all .5s ease!important;
  column-gap: 1rem;
  border-top: 1px solid #DAE0E6;
  width: 100%;
  cursor: pointer;
`;
