import {apiGet, apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {clearAccessToken, saveAccessToken} from "./auth";
import {TGetSubscriptionsResponse} from "./logIn";

export type TLogInSocialRequest = {
  externalSourceName: string;
  externalId: string;
  externalSourceToken: string;
};

export type TSignInSocialRequest = {
  externalSourceName: string;
  externalId: string;
  externalSourceToken: string;
  name: string;
  email: string;
};

export const logInSocial = async (payload: TLogInSocialRequest) => {
  let url = createUrl(`${EnvConfig.API_URL}/api/login`);
  const tmpAuth: any = await apiPost<{}, any>(url, payload);

  saveAccessToken(tmpAuth.accessToken);

  url = createUrl(
    `${EnvConfig.API_URL}/api/authentication/activeSubscriptions`
  );

  //@ts-ignore
  const subscriptions: TGetSubscriptionsResponse = await apiGet<TGetSubscriptionsResponse>(url);

  url = createUrl(
    `${EnvConfig.API_URL}/api/authentication/currentTenant?tenantId=` +
    subscriptions[0].subscriptionId
  );
  const auth: any = await apiPost<{}, any>(url, {});

  clearAccessToken();

  return {...auth, ...subscriptions[0]};
};

