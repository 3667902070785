import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TParseInvoiceResponse} from "./parseInvoice";
import {Guid} from "guid-typescript";

export type TParseInvoiceFromIdRequest = {
  propertyId: Guid;
  fileId: Guid;
};

export const parseInvoiceFromId = (payload: TParseInvoiceFromIdRequest) => {
  const {propertyId, fileId} = payload;

  const url = createUrl(`${EnvConfig.API_URL}/api/invoice/process/existing/${propertyId}/${fileId}`);

  return apiPost<TParseInvoiceResponse, any>(url);
};
