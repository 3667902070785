import React, {useEffect, useState} from "react";

import {ReactComponent as ContentsIcon} from "../../../images/records/ContentsIcon.svg";
import {ReactComponent as SpaceRecord} from "../../../images/records/ContentsRecord.svg";
import {useDispatch, useSelector} from "react-redux";
import {getInventoriesSelector, getPropertyIdSelector} from "../../selectors";
import {
  AlertDot,
  AlertOverlay, AlertWrapper,
  CardContentsItem,
  CardContentsWrapper,
  CardTitle,
  PlusIconWrapper,
  RecordsCard
} from "./SpaceRecordsCard";
import {useHistory} from "react-router-dom";
import {ReactComponent as PlusIcon} from "../../../images/records/PlusIcon.svg";
import {addInventory} from "../../../api-wrapper/inventories/addInventory";
import {getAllInventories} from "../../actions/inventories";
import {Dropdown} from "antd";

type Props = {
  toggleDrawerOpen: (arg: boolean) => void;
  setEditingData: (arg: any) => void;
}

const ContentRecordsCard = (props: Props) => {
  const {toggleDrawerOpen, setEditingData} = props;

  const contents = useSelector(getInventoriesSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const history = useHistory();
  const dispatch = useDispatch();

  const [contentsCount, setContentsCount] = useState(0);
  const [appliancesCount, setAppliancesCount] = useState(0);
  const [systemsCount, setSystemsCount] = useState(0);
  const [pastTypicalLifeCount, setPastTypicalLifeCount] = useState(0);

  useEffect(() => {
    setContentsCount(contents.content.filter((content) => content.name).length)
    setAppliancesCount(contents.content.filter((content) => content.name && content.inventoryType === "Appliances").length)
    setSystemsCount(contents.content.filter((content) => content.name && content.inventoryType === "Systems").length)
    setPastTypicalLifeCount(contents.content.filter((content) =>
      content.applianceLifetimeYearsLeft && content.applianceLifetimeYearsLeft < 0).length)
  }, [propertyId, contents])

  const addNewInventory = () => {
    addInventory({
      propertyId,
    }).then((res) => {
      setEditingData(res);
      dispatch(getAllInventories(propertyId));
      toggleDrawerOpen(true);
    });
  }

  return <RecordsCard
    className={"content-records"}
    onClick={() => history.push("/contents")}
  >
    <CardTitle>
      <AlertWrapper>
        {pastTypicalLifeCount > 0 && <Dropdown
            placement={"topCenter"}
            overlay={<AlertOverlay>{pastTypicalLifeCount} Content item(s) past typical life</AlertOverlay>}>
            <AlertDot>{pastTypicalLifeCount}</AlertDot>
        </Dropdown>}
        <SpaceRecord />
      </AlertWrapper>
       Contents
      <PlusIconWrapper onClick={(e) => {
        e.stopPropagation()
        addNewInventory()
      }}><PlusIcon /></PlusIconWrapper>
    </CardTitle>
    <CardContentsWrapper>
      <CardContentsItem className={"fill"}>
        <span className={"item-count"}><ContentsIcon /> {contentsCount}</span> Total
      </CardContentsItem>
      <CardContentsItem className={"fill"} onClick={(e) => {
        e.stopPropagation()
        history.push("/contents?category=Appliances")
      }}>
        <span className={"item-count"}><ContentsIcon /> {appliancesCount}</span> Appliances
      </CardContentsItem>
      <CardContentsItem className={"fill"} onClick={(e) => {
        e.stopPropagation()
        history.push("/contents?category=Systems")
      }}>
        <span className={"item-count"}><ContentsIcon /> {systemsCount}</span> Systems
      </CardContentsItem>
    </CardContentsWrapper>
  </RecordsCard>
}

export default ContentRecordsCard