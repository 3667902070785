import React, {useEffect, useState} from "react";

import {ReactComponent as CostsDocuments} from "../../../images/records/CostsDocuments.svg";
import {ReactComponent as CostsRecord} from "../../../images/records/CostsRecord.svg";
import {ReactComponent as CostsIcon} from "../../../images/records/CostsIcon.svg";
import {useDispatch, useSelector} from "react-redux";
import {getExpensesSelector, getPropertyIdSelector} from "../../selectors";
import {
  AlertDot,
  AlertOverlay, AlertWrapper,
  CardContentsItem,
  CardContentsWrapper,
  CardTitle, ErrorDot,
  PlusIconWrapper,
  RecordsCard
} from "./SpaceRecordsCard";
import {useHistory} from "react-router-dom";
import {ReactComponent as PlusIcon} from "../../../images/records/PlusIcon.svg";
import {addExpense} from "../../../api-wrapper/expenses/addExpense";
import {getAllExpenses} from "../../actions/expenses";
import {getExpenses} from "../../../api-wrapper/expenses/getExpenses";
import {Dropdown, Modal, notification} from "antd";
import {ReactComponent as FilesRecord} from "../../../images/records/FilesRecord.svg";
import {getUserWarnings} from "../../../api-wrapper/attachments/getUserWarnings";
import {dismissUserWarnings} from "../../../api-wrapper/attachments/dismissUserWarnings";
import {dismissWarnings, showInvoiceUploadErrorPopup} from "../navigation/MenuLivlet";

type Props = {
  toggleDrawerOpen: (arg: boolean) => void;
  setEditingData: (arg: any) => void;
}

const CostRecordsCard = (props: Props) => {
  const {toggleDrawerOpen, setEditingData} = props;

  const costs = useSelector(getExpensesSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const history = useHistory();
  const dispatch = useDispatch();

  const [costsCount, setCostsCount] = useState(0);
  const [documentsCount, setDocumentsCount] = useState(0);
  const [pendingCostsCount, setPendingCostsCount] = useState(0);
  const [invoiceErrors, setInvoiceErrors] = useState([]);

  const refreshWarnings = () => {
    getUserWarnings().then((res: any) => {
      setInvoiceErrors(res.filter((warning: any) => warning.dataType === "expense"))
    })
  }
  useEffect(() => {
    setCostsCount(costs.content.filter((cost) => cost.name).length)
    setDocumentsCount(costs.content.filter((cost) => cost.attachmentsCount).length)
    setPendingCostsCount(costs.content.filter((cost) => !cost.isConfirmed).length)
    refreshWarnings()
  }, [propertyId, costs])

  const addNewExpense = () => {
    addExpense({
      propertyId,
      isConfirmed: true
    }).then((res) => {
      setEditingData(res);
      dispatch(getAllExpenses(propertyId));
      toggleDrawerOpen(true);
    });
  }

  return <RecordsCard
    className={"cost-records"}
    onClick={() => history.push("/expenses")}
  >
    <CardTitle>
      <AlertWrapper>
        {pendingCostsCount > 0 && <Dropdown
            placement={"topCenter"}
            overlay={<AlertOverlay>{pendingCostsCount} Unconfirmed expenses</AlertOverlay>}>
            <AlertDot>{pendingCostsCount}</AlertDot>
        </Dropdown>}
        {invoiceErrors.length > 0 && <Dropdown
            placement={"topCenter"}
            overlay={<AlertOverlay> Uploaded documents whilst away</AlertOverlay>}>
            <ErrorDot onClick={(e) => {
              e.stopPropagation();
              showInvoiceUploadErrorPopup(invoiceErrors, dismissWarnings, refreshWarnings);
            }}>{invoiceErrors.length}</ErrorDot>
        </Dropdown>}
        <CostsRecord />
      </AlertWrapper>
      Expenses
      <PlusIconWrapper onClick={(e) => {
        e.stopPropagation()
        addNewExpense()
      }}><PlusIcon /></PlusIconWrapper>
    </CardTitle>
    <CardContentsWrapper>
      <CardContentsItem className={"fill"}>
        <span className={"item-count"}><CostsIcon /> {costsCount}</span> Expenses
      </CardContentsItem>
      {/*<CardContentsItem className={"fill"}>*/}
      {/*  <span className={"item-count"}><CostsDocuments /> {documentsCount}</span> Documents*/}
      {/*</CardContentsItem>*/}
    </CardContentsWrapper>
  </RecordsCard>
}

export default CostRecordsCard