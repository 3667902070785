import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";

export type TGetUserWarningsResponse = Array<{
  userId: number;
  warningType: string;
  message: string;
  obtainingDate: string;
}>;

export const getUserWarnings = () => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/userWarnings`
  );

  return apiGet<TGetUserWarningsResponse | []>(url);
};
