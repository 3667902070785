import {Guid} from "guid-typescript";
import {apiGet, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {TGetContactsResponse} from "../contacts/getContacts";
import {TGetAllAttachmentsResponse} from "../attachments/getAllAttachments";

type TGetCertificateByIdResponse = {
  object: {
    certificateDate: string,
    endOfValidity: string,
    amountPaid: number,
    certificateNumber: string,
    certificateType: string,
    certificateId: Guid,
    subscriptionId: number,
    propertyId: Guid
  },
  contacts: Array<TGetContactsResponse>,
  attachments: Array<TGetAllAttachmentsResponse>
}

export const getCertificateById = (propertyId: Guid, certificateId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/certificateDetails/${certificateId}`);

  return apiGet<TGetCertificateByIdResponse>(url);
};