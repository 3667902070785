import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import _ from "lodash";

let autoComplete;

function handleScriptLoad(updateQuery, autoCompleteRef, setValue) {
  const center = { lat: 51.5123142, lng: -0.1599539 };
// Create a bounding box with sides ~10km away from the center point
  const defaultBounds = {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1,
  };
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current, {
        origin: center,
        strictBounds: false,
        bounds: defaultBounds,
      }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery, setValue)
  );
}

const mapAddressComponents = (place) => {
  var componentForm = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    administrative_area_level_2: "short_name",
    postal_code: "short_name",
    postal_code_prefix: "short_name",
    country: "long_name",
    postal_town: "long_name",
  };

  for (var i = 0; i < place.address_components.length; i++) {
    var addressType = place.address_components[i].types[0];

    if (componentForm[addressType]) {
      if (addressType === "street_number") {
        var streetNumber =
          place.address_components[i][componentForm[addressType]];
      }

      if (addressType === "route") {
        var route = place.address_components[i][componentForm[addressType]];
      }

      if (addressType === "administrative_area_level_2") {
        var state = place.address_components[i][componentForm[addressType]];
      }

      if (addressType === "country") {
        var country = place.address_components[i][componentForm[addressType]];
      }

      if (addressType === "postal_town" || addressType === "locality") {
        var city = place.address_components[i][componentForm[addressType]];
      }

      if (
        addressType === "postal_code" ||
        addressType === "postal_code_prefix"
      ) {
        var zipcode = place.address_components[i][componentForm[addressType]];
      }
    }
  }

  var formattedAddress =
    !_.isUndefined(route) && !_.isUndefined(streetNumber)
      ? streetNumber + " " + route
      : !_.isUndefined(route)
      ? route
      : streetNumber;

  return {
    formattedAddress: _.isUndefined(formattedAddress)
      ? place.formatted_address
      : formattedAddress,
    data: {
      streetNumber: streetNumber,
      country: country,
      city: city,
      state: state,
      zipcode: zipcode,
    },
  };
};

async function handlePlaceSelect(updateQuery, setValue) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  setValue(mapAddressComponents(addressObject));
}

function SearchLocationInput(props) {
  const { setValue, origin } = props;
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    handleScriptLoad(setQuery, autoCompleteRef, setValue);
  }, []);

  return (
    <StyledInput
      className={origin === "onboarding" ? "onboarding" : ""}
      ref={autoCompleteRef}
      size={"large"}
      type={"search"}
      onChange={(event) => setQuery(event.target.value)}
      placeholder="Search for address..."
      value={query}
      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    />
  );
}

const StyledInput = styled.input`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  height: 3.25rem;
  border-radius: 6px;
  width: 100%;
  padding-left: 0.5rem;

  @media (max-width: 1024px) {
    height: 2.5rem;
  }
  
  &.onboarding {
    display: flex;
    align-items: center;
    
    padding: 0.75rem 1rem;
    background: #FEF9EA;
    border-radius: 6px;
    border: none;
  
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #666F7A;
    margin-bottom: 1.5rem;
  }
`;

export default SearchLocationInput;
