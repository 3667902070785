import { createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import axios from "axios";
import {getAccessToken} from "../auth/auth";

export type TExportExpensesRequest = {
  periodStartDate?: string;
  periodEndDate?: string;
  taxDeductible?: boolean | null;
  typeIds?: Array<any>;
  tags?: Array<any>;
};

export const exportExpenses = async (
  payload: TExportExpensesRequest & { propertyId: Guid }
) => {
  const { periodEndDate, periodStartDate, taxDeductible, propertyId, typeIds, tags } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/expenses/export`
  );

  const uninterceptedAxios = axios.create()

  const response: any = await uninterceptedAxios.post(url, {periodStartDate, periodEndDate, taxDeductible, typeIds, tags},
    {
      headers: {
        "Authorization": `Bearer:${getAccessToken()}`,
      },
      responseType: 'blob'
    })

  return response
};
