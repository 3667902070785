import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TDownloadAttachmentRequest = {
  propertyId: Guid;
  id: Guid;
};

export const downloadAttachment = (payload: TDownloadAttachmentRequest) => {
  const { propertyId, id } = payload;

  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/attachment/${id}/presignedUrl`
  );

  return apiGet<string>(url);
};
