import React from "react";
import styled from "styled-components";
import {Input, InputNumber, Select} from "antd";
import {EditableValueWrapper} from "./MaintenanceCost";
import {capitalize} from "../../helper";

type Props = {
  frequency: number;
  frequencyUnit: string;
  setFrequency: (arg: number) => void;
  setFrequencyUnit: (arg: string) => void;
}

const MaintenanceFrequency = (props: Props) => {
  const {frequency, frequencyUnit, setFrequency, setFrequencyUnit} = props;

  const frequencies = ['day', 'week', 'month', 'year', 'once'];

  return <EditableValueWrapper style={{maxWidth: "10rem"}}>
    Once Every *
    <Input.Group compact>
      <InputNumberStyled
        style={{width: '35%'}}
        value={frequencyUnit !== "once" ? frequency : undefined}
        disabled={frequencyUnit === "once"}
        onChange={(value) => { typeof(value) === 'number' && setFrequency(value) }}
      />
      <SelectStyled
        style={{width: '65%'}}
        value={frequencyUnit}
        onChange={(value) => {
          // @ts-ignore
          setFrequencyUnit(value)
        }}
      >
        {frequencies.map(frequency => <Select.Option key={frequency} value={frequency}>
          {capitalize(frequency)}
          {frequency !== "once" && "s"}
        </Select.Option>)}
      </SelectStyled>
    </Input.Group>
  </EditableValueWrapper>
}

const InputNumberStyled = styled(InputNumber)`
  width: 35%;
  padding-left: 0;
  padding-right: 0;
  height: 32px;
  border-radius: 6px 0 0 6px!important;
  border: 1px solid rgba(42, 128, 225, 0.5);
  font-size: 1rem;
  color: #45655E;
  
  .ant-input-number-input {
    height: 2rem!important;
  }
`

const SelectStyled = styled(Select)`
  .ant-select-selector {
    font-size: 1rem;
    color: #45655E;
    height: 32px!important;
    border-radius: 0 6px 6px 0!important;
    border: 1px solid rgba(42, 128, 225, 0.5)!important;
  }
  
  .ant-select-selection-item {
    line-height: 2rem!important;
  }
`

export default MaintenanceFrequency