import {Guid} from "guid-typescript";
import {apiGet, createUrl} from "../apiCall";
import EnvConfig from "../../config";

export const getUpcomingTaskById = (propertyId: Guid, taskId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/alerts/trigger/${taskId}/details/`,
    {}
  );

  return apiGet<any>(url);
};
