import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import Dragger from "antd/es/upload/Dragger";
import {Button, Dropdown, List, notification, Upload} from "antd";
import _ from "lodash";

import { addAttachment } from "../../../api-wrapper/attachments/addAttachment";
import ItemRenderComp from "./ItemRenderComp";
import { NoDataText } from "../styled";
import {Guid} from "guid-typescript";
import {PlusOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {getAttachmentsSelector, getPropertyPreferencesSelector} from "../../selectors";
import {getAllAttachments} from "../../actions/attachments";
import {fileTypeIcon} from "../../screens/Files";
import {addRelation} from "../../../api-wrapper/relation/addRelation";
import {attachmentsInitial} from "../helper";
import {getMyStorageUsage} from "../../actions/storageUsage";
import {
  allowedExtensions, showBouncedFileUploadNotification,
  showFailedFileUploadNotification, showStartFileUploadNotification,
  showSuccessfulFileUploadNotification
} from "./uploadHelpers";
import {QuickAddOption} from "../navigation/Header";
import {ReactComponent as QuickAddFile} from "../../../images/menu/QuickAddFile.svg";
import { ReactComponent as UploadIcon } from "../../../images/spaces/UploadIcon.svg";
import { ReactComponent as UploadFileIcon } from "../../../images/attachments/UploadFileIcon.svg";
import {ReactComponent as PlusIcon} from "../../../images/records/PlusIcon.svg";
import {PlusIconWrapper} from "../records/SpaceRecordsCard";
import {AddButtonStyled, IntroductionButton} from "../../screens/components";
import {ReactComponent as FilesIcon} from "../../../images/spaces/SpaceLinkFiles.svg";
import {ContainerItem} from "../spaces/AddSpaceOptions";
import {ReactComponent as AddLinkIcon} from "../../../images/spaces/LinkedAdd.svg";
import {ReactComponent as MobileUploadIcon} from "../../../images/attachments/MobileUpload.svg";
import ViewFileDrawer from "./ViewFileDrawer";

const moment = require("moment");

type Props = {
  fileList: Array<any>;
  addToFileList?: (arg: Guid) => void | null;
  refreshParent: (arg?: Guid | null) => void;
  singleAttachment: boolean;
  parentId: Guid | null;
  parentType: string | null;
  propertyId: Guid;
  uploadImmediately: boolean;
  recordId?: Guid | null;
  setAttachment?: (arg: any) => void;
  attachment?: any;
  parentName?: string | null;
  highlightAttachment?: Guid | null;
  isGlobalNote?: boolean;
  isGlobalUpload?: boolean;

  isRecordUpload?: boolean;
  setUploadingStatus?: (arg: string) => void;
  setShowFileUploadList?: (arg: boolean) => void | null;
  isAttachmentsPage?: boolean | false;
  isFileListEmpty?: boolean | false;
  uploadOrigin?: string;
  beforeUploadCallback?: (arg: boolean) => void | null;
  uploadInProgress?: Array<string> | null;
  setUploadInProgress?: (arg: Array<string>) => void | null;
};

const UploadFile = (props: Props) => {
  const {
    parentId,
    propertyId,
    fileList,
    addToFileList,
    singleAttachment,
    parentType,
    uploadImmediately,
    isGlobalNote,
    recordId,
    refreshParent,
    setAttachment,
    attachment,
    parentName,
    highlightAttachment,
    isGlobalUpload,
    setShowFileUploadList,
    isRecordUpload,
    setUploadingStatus,
    isAttachmentsPage,
    isFileListEmpty,
    uploadOrigin,
    beforeUploadCallback,
    uploadInProgress,
    setUploadInProgress,
  } = props;

  const highlightedAttachmentRef = useRef(null);

  const dispatch = useDispatch();
  const attachments = useSelector(getAttachmentsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [top5attachments, setTop5attachments] = useState(attachmentsInitial);
  const [showRecentlyAdded, setShowRecentlyAdded] = useState(false);
  const [localFileList, setLocalFileList] = useState<any>([]);
  const [filesWithErrors, setFilesWithErrors] = useState<any>([]);
  const [uploadList, setUploadList] = useState<Array<string>>([]);

  const [isViewFileDrawerOpen, toggleViewFileDrawer] = useState(false);
  const [attachmentToViewId, setAttachmentToViewId] = useState<any>(null);

  useEffect(() => {
    if (highlightedAttachmentRef && highlightedAttachmentRef.current) {
      // @ts-ignore
      highlighedAttachmentRef.current.scrollIntoView();
    }
    dispatch(getAllAttachments(propertyId));
  }, [propertyId]);

  useEffect(() => {
    let att = attachments.content.slice();
    att.sort(
      (n1, n2) =>
        moment(n2.uploadedDate).valueOf() -
        moment(n1.uploadedDate).valueOf()
    );
    // @ts-ignore
    setTop5attachments(att.slice(0,5));
    setFilesWithErrors([]);
    setUploadList([]);
  }, [attachments.content])

  useEffect(() => {
    let inProgress = uploadList.filter(fileName => !filesWithErrors.includes(fileName));
    if (inProgress.length > 0) {
      setUploadInProgress && setUploadInProgress(inProgress);
      beforeUploadCallback && beforeUploadCallback(true);
    }
  }, [uploadList])

  const RecentlyAdded = () =>
    top5attachments.length > 0 ?
    (<RecentlyAddedModal
      onClick={(e) => {
        e.stopPropagation();
        setShowRecentlyAdded(false);
      }}
    >
      <div style={{color: "#6B7185", fontSize: "1rem", margin: "1rem 1rem"}}>Recently added</div>
      {top5attachments.filter(item => parentType === "document" ? !item.relatedDocumentId : true).map(item => (
        <RecentlyAddedLineItem
          onClick={() => {
            if (fileList.filter((attachment) => attachment.attachmentId === item.attachmentId).length > 0 && uploadOrigin !== "record-page") {
              notification.success({
                message: "Document upload successful",
                description:
                  "The document has been successfully added",
                placement: "topRight",
              });
              return;
            }
            parentId && parentType &&
            addRelation({
              propertyId,
              parentId: parentId,
              childId: item.attachmentId,
              parentType: parentType,
              childType: "attachment",
            }).then(() => {
              refreshParent(parentId);
            });
            if (addToFileList) {
              addToFileList(item.attachmentId);
              // setLocalFileList([...localFileList, item]);
            }
          }}
        >
          {(_.isNil(item.thumbnailPath) || _.isEmpty(item.thumbnailPath))
            ? <div>{fileTypeIcon(item)}</div>
            : <img alt="" src={item.thumbnailPath}
                   style={{width: "3.5rem", height: "3.5rem", borderRadius: "6px"}} onError={(e) => {
              // @ts-ignore
              e.target.onError = null;
              // @ts-ignore
              e.target.src = "/images/file.png"
            }}/>
          }
          <div style={{display: "flex", flexDirection: "column", justifyContent:"space-between", marginLeft:"1rem"}}>
            <div style={{fontSize:"1rem", fontWeight:600, marginBottom:"0.3rem"}}>{item.name}</div>
            {item.uploadedDate && <div style={{fontSize:"0.875rem", color: "#6B7185"}}>
              {moment(item.uploadedDate).format(propertyPreferences.preferredDateFormat)}
            </div>}
          </div>
        </RecentlyAddedLineItem>
      ))}
      <a
        style={{color: "#2A80E1", fontWeight: 600, fontSize: "1rem", margin: "1rem 1rem", textDecoration: "underline"}}
        onClick={() => {
          setShowFileUploadList && setShowFileUploadList(true);
        }}
      >
        View all your files
      </a>
    </RecentlyAddedModal>)
      : (<RecentlyAddedModal>
        <div style={{color: "#6B7185", fontSize: "1rem", margin: "1rem 1rem"}}>
          You don't have any files in Livlet yet.
        </div>
      </RecentlyAddedModal>)

  const UploadAntComponent = isGlobalUpload ? Upload : DraggerStyled;

  return parentType !== "note" && parentType !== "alert" && parentType !== "document" && parentId === null && !isGlobalUpload && !isAttachmentsPage? (
    <></>
  ) : (
    <div style={{width: uploadOrigin === "add-record" ? "calc(100% / 2 - 0.25rem)" : "auto"}}>
      <UploadAntComponent
        accept={ uploadOrigin === "add-image-to-note" ? ".png,.gif,.tiff,.jpg,.jpeg" : "" }
        fileList={uploadImmediately ? fileList : attachment}
        shouldBeHide={
          singleAttachment && attachment.length > 0 ? "none" : "flex"
        }
        name={"file"}
        listType={"picture"}
        onChange={(info) => {
          if (info.fileList) {
            setUploadList(info.fileList.map(fileInfo => fileInfo.name));
          } else if (info.file) {
            if (uploadInProgress && setUploadInProgress) {
              setUploadInProgress([...uploadInProgress, info.file.name]);
            }
            beforeUploadCallback && beforeUploadCallback(true);
          }
        }}
        beforeUpload={(file) => {
          let fileExt = file.name.split(".").pop();
          if (!fileExt) fileExt = "";
          const allowedType = allowedExtensions.includes(fileExt.toLowerCase());
          const allowedSize = file.size / 1024 / 1024 < 20;
          if (!allowedSize || !allowedType) {
            setFilesWithErrors([...filesWithErrors, file.name]);
            setUploadInProgress && uploadInProgress && setUploadInProgress(uploadInProgress.filter(fileName => fileName !== file.name));
            showBouncedFileUploadNotification()
            return false;
          }
          if (isGlobalUpload || (uploadImmediately && propertyId && parentId) || isAttachmentsPage || parentType === "document") {
            if (isRecordUpload && setUploadingStatus) setUploadingStatus("Uploading")
            showStartFileUploadNotification();
            addAttachment({
              propertyId,
              parentType,
              parentId,
              file,
            }).then((res: any) => {
              refreshParent(recordId);
              parentType === "document" && refreshParent(res.data.attachmentId);
              dispatch(getMyStorageUsage());
              dispatch(getAllAttachments(propertyId));
              !beforeUploadCallback && showSuccessfulFileUploadNotification()
              if (addToFileList) {
                addToFileList(res.data.attachmentId);
                // setLocalFileList([...localFileList, res.data]);
              }
            }).catch((err) => {
              let errorMsg = "";
              if (err && err.data && err.data.message) {
                errorMsg = err.data.message;
                showFailedFileUploadNotification(errorMsg);
                if (setUploadInProgress && uploadInProgress && beforeUploadCallback) {
                  setUploadInProgress(uploadInProgress.filter(fileName => fileName !== file.name))
                  beforeUploadCallback(false);
                }
              }
            });
          } else if (
            setAttachment &&
            !uploadImmediately &&
            propertyId &&
            (isGlobalNote || parentId)
          ) {
            setAttachment([file]);
            showSuccessfulFileUploadNotification()
          }
          return false;
        }}
        showUploadList={false}
        multiple={!singleAttachment}
      >
        {(!isGlobalUpload && (uploadImmediately ||
          _.isUndefined(attachment) ||
          (attachment && attachment.length === 0))) && (
          <>
            <AttachmentDescriptionDesktop>
              <NoDataText>
                <UploadIcon />
                <span style={{maxWidth: "18.25rem"}}>Drag and drop file(s) here or choose <a>file from your computer</a>
                  {!isAttachmentsPage && <span>{" or "} <Dropdown
                    overlayStyle={{width: window.innerWidth > 1024 ? 'auto' : '100%'}}
                    overlay={<RecentlyAdded/>}
                    placement={window.innerWidth > 1024 ? "topCenter" : "topLeft"}
                    visible={showRecentlyAdded}
                    onVisibleChange={(flag) => setShowRecentlyAdded(flag)}
                    trigger={["click"]}
                  ><a onClick={(e) => e.stopPropagation()}>Livlet documents
                  </a></Dropdown>
                </span>}
                </span>
              </NoDataText>
            </AttachmentDescriptionDesktop>

            <AttachmentDescriptionMobile size={"large"}>
              + Add attachment
            </AttachmentDescriptionMobile>
          </>
        )}
        {isGlobalUpload && !isRecordUpload && !isFileListEmpty && !uploadOrigin && <AddButtonStyled
          icon={<PlusOutlined />}
        >Upload document</AddButtonStyled>}
        {isGlobalUpload && !isRecordUpload && isFileListEmpty && !uploadOrigin && <IntroductionButton
            size={"large"}
            icon={<PlusOutlined />}
        >Upload document</IntroductionButton>}
        {uploadOrigin === "header" && <HeaderButtonStyled
            type={"primary"}
            size={"large"}
            icon={<UploadIcon />}
        >{window.innerWidth > 1024 && "Upload file"}</HeaderButtonStyled>}
        {uploadOrigin === "quick-add" && <QuickAddOption>
            <QuickAddFile /> Upload documents or pictures
        </QuickAddOption>}
        {uploadOrigin === "add-record" && <ContainerItem className={"files"}>
            <FilesIcon style={{flexShrink: 0}} /> Documents
        </ContainerItem>}
        {uploadOrigin === "record-page" && <PlusIconWrapper><PlusIcon /></PlusIconWrapper>}
        {uploadOrigin === "mobile-files-page" && <MobileUploadIcon/>}
        {uploadOrigin === "add-linked-item" && <AddLinkedRecordButton><AddLinkIcon /> Add new</AddLinkedRecordButton>}
        {uploadOrigin === "add-image-to-note" && <AddLinkedRecordButton><AddLinkIcon/> Add new photo</AddLinkedRecordButton>}
        {uploadOrigin === "view-space-drawer" && <SpaceDrawerUploadButton><UploadFileIcon/> Upload</SpaceDrawerUploadButton>}
      </UploadAntComponent>
      {!isGlobalUpload && <>
        <ViewFileDrawer
            attachmentId={attachmentToViewId}
            setAttachmentId={setAttachmentToViewId}
            isOpen={isViewFileDrawerOpen}
            toggleDrawer={toggleViewFileDrawer}
            viewFileDrawerDefaultTab={"previewOnly"}
            duplicateAttachments={[]}
            selectedTags={[]}
            toggleEditTagsMode={() => {}}
            attachmentsWithoutProperty={[]}
            toggleEditDrawerOpen={() => {}}
            setPresetLinkType={() => {}}
            toggleWorkDrawer={() => {}}
            setEditWorkData={() => {}}
            toggleExpenseDrawer={() => {}}
            setEditExpenseData={() => {}}
            toggleInventoryDrawer={() => {}}
            setEditInventoryData={() => {}}
            toggleContactDrawer={() => {}}
            setEditContactData={() => {}}
            toggleNoteDrawer={() => {}}
            setEditNoteData={() => {}}
            toggleLinkDrawerOpen={() => {}}
            setEditSpaceId={() => {}}
            toggleSpaceDrawer={() => {}}
            refreshParent={() => {}}
            toggleTaskDrawer={() => {}}
            setEditTaskData={() => {}}
            toggleViewWorkDrawer={() => {}}
            setViewWorkId={() => {}}
        />
       <List
        style={{ width: "100%" }}
        itemLayout="horizontal"
        locale={{ emptyText: " " }}
        dataSource={uploadImmediately ? (localFileList.length > 0 ? localFileList : fileList) : attachment}
        renderItem={(file: any) => {
          let addedRef = {};
          if (
            highlightAttachment &&
            // @ts-ignore
            highlightAttachment === file.attachmentId
          ) {
            addedRef = { ref: highlightedAttachmentRef };
          }
          return (
            <ItemRenderComp
              fileList={fileList}
              hideDelete={localFileList.length > 0}
              recordId={recordId}
              parentId={parentId}
              parentType={parentType}
              propertyId={propertyId}
              setFile={uploadImmediately ? null : setAttachment}
              refreshParent={refreshParent}
              file={file}
              parentName={parentName}
              addedRef={addedRef}
              toggleViewFileDrawer={toggleViewFileDrawer}
              setAttachmentToViewId={setAttachmentToViewId}
            />
          );
        }}
      /></>}
    </div>
  );
};

export const AttachmentDescriptionMobile = styled(Button)`
  border: 1px solid rgb(33, 39, 43, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #fff;
  font-weight: bold;
  font-size: 1rem;
  
  @media (min-width: 1024px) {
    display: none;
  }
  
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const AttachmentDescriptionDesktop = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: #002a43;
  
  @media (max-width: 1024px) {
    padding: 0 0.5rem;
  }
`;

export const DraggerStyled = styled(Dragger)`
  padding: 1rem!important;
  background: #EFF1F5!important;
  border: 1px solid rgba(0, 0, 0, 0.1)!important;
  border-radius: 16px!important;
    
  // background-color: #F0F2F5 !important;
  // border: 1px dashed #6b7185 !important;
  // box-sizing: border-box !important;
  // border-radius: 10px !important;
  display: ${(props: { shouldBeHide: string }) =>
    props.shouldBeHide || "flex"};
  margin-top: 1rem;
  // padding: 2rem 0;
  justify-content: center;
  text-align: center;
`;

const HeaderButtonStyled = styled(AddButtonStyled)`
  border-radius: 8px!important;
  
  @media (max-width: 1024px) {
    min-width: 3rem;
    min-height: 3rem;
  }
`

export const RecentlyAddedModal = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 1px 5px rgba(30, 32, 37, 0.05), 0px 10px 24px rgba(26, 28, 31, 0.1);
  border-radius: 8px;
  
  @media (max-width: 1024px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

export const RecentlyAddedLineItem = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   padding-left: 1rem;
   padding-right: 4rem;
   padding-bottom: 0.75rem;
   padding-top: 0.75rem;
   cursor: pointer;
   
   :hover {
     background: #F2F5FF;
   }
`;

const AddLinkedRecordButton = styled.div`
  cursor: pointer;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #00B74F;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`

const SpaceDrawerUploadButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 16px;
  gap: 0.625rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #009966;
  
  @media (max-width: 768px) {
    padding: 0.5rem 0;
    gap: 0.25rem;
  }
`

export default UploadFile;
