import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";
import { push } from "react-router-redux";
import decodeJwt from "jwt-decode";

import {TAuth} from "../reducers/auth";
import EnvConfig from "../../config";
import { TRootState } from "../reducers";
import { logIn, TLogInRequest } from "../../api-wrapper/auth/logIn";
import { clearAccessToken, saveAccessToken } from "../../api-wrapper/auth/auth";
import {clearIdleTimeout} from "../../IdleTimer";
import {getPropertiesResetAction} from "./properties";
import {logInSocial, TSignInSocialRequest} from "../../api-wrapper/auth/logInSocial";
import {mergeSocial, TMergeSocialRequest} from "../../api-wrapper/auth/mergeSocial";
import {getMaintenancesResetAction} from "./maintenances";
import {getPropertyIdInitAction} from "./propertyId";
import {setEmailedFilesState, setFileEmailedPopupState} from "../../localStorage";
import {pushStepToGoogle} from "../screens/helpers";
import {getPropertyPreferencesInitAction} from "./propertyPreferences";
import {replace} from "connected-react-router";

export const logOutAction = createAction("users/LOGOUT")();

export const logInInitAction = createAction("users/LOGIN_INIT")();
export const logInSuccessAction = createAction("users/LOGIN_SUCCESS")<TAuth>();
export const logInFailureAction = createAction("users/LOGIN_FAILURE")();
export const logInCleanAction = createAction("users/LOGIN_CLEAN")();
export const clearPopupAction = createAction("users/CLEAR_POPUP")();
export const badCredentialsAction = createAction("users/BAD_CREDENTIALS")<any>();
export const facebookEmailErrorAction = createAction("users/FACEBOOK_EMAIL_ERROR")();
export const accountNotActivatedAction = createAction("users/ACCOUNT_NOT_ACTIVATED")();
export const resetSubscriptionLevelAction = createAction("users/RESET_SUBSCRIPTION_LEVEL")();

export type TLogInInitAction = ActionType<typeof logInInitAction>;
export type TLogInSuccessAction = ActionType<typeof logInSuccessAction>;
export type TLogInFailureAction = ActionType<typeof logInFailureAction>;
export type TLogOutAction = ActionType<typeof logOutAction>;
export type TLoginCleanAction = ActionType<typeof logInCleanAction>;
export type TClearPopupAction = ActionType<typeof clearPopupAction>;
export type TBadCredentialsAction = ActionType<typeof badCredentialsAction>;
export type TFacebookEmailErrorAction = ActionType<typeof facebookEmailErrorAction>;
export type TAccountNotActivatedAction = ActionType<typeof accountNotActivatedAction>;
export type TResetSubscriptionLevelAction = ActionType<typeof resetSubscriptionLevelAction>;

export type TLogInActions =
  | TLogInInitAction
  | TLogInSuccessAction
  | TLogInFailureAction
  | TLoginCleanAction;

export type TLoginThunkActionType = ThunkAction<
  void,
  TRootState,
  null,
  TLogInActions
>;

export type TLogoutThunkActionType = ThunkAction<
  void,
  TRootState,
  null,
  TLogOutAction
>;

type TLoginAction = (payload: TLogInRequest) => TLoginThunkActionType;
type TLoginSocialAction = (payload: TSignInSocialRequest) => TLoginThunkActionType;
type TMergeAndLoginAction = (payload: TMergeSocialRequest) => TLoginThunkActionType;

export const signIn: TLoginAction = (payload: TLogInRequest) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(logInInitAction());
  clearIdleTimeout();
  return logIn(payload.email, payload.password, payload.token)
    .then((payload) => {
      pushStepToGoogle('login-success');
      saveAccessToken(payload.accessToken);
      setFileEmailedPopupState("");
      setEmailedFilesState("[]");
      dispatch(getPropertiesResetAction());
      dispatch(getPropertyIdInitAction());
      dispatch(getPropertyPreferencesInitAction());
      dispatch(replace("/home"));
      return dispatch(
        logInSuccessAction({...decodeJwt(payload.accessToken), ...payload})
      );
    })
    .catch((error) => {
      pushStepToGoogle('login-error');
      dispatch(logInFailureAction());
      if (error.status === 409 || (error.response && error.response.status === 409))
        dispatch(badCredentialsAction(error.data))
      else dispatch(accountNotActivatedAction())
    });
};

export const signInSocial: TLoginSocialAction = (payload: TSignInSocialRequest) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(logInInitAction());
  clearIdleTimeout();

  return logInSocial({
      externalSourceName: payload.externalSourceName,
      externalId: payload.externalId,
      externalSourceToken: payload.externalSourceToken
    })
    .then((res) => {
      pushStepToGoogle(`login-social-${payload.externalSourceName}-success`);
      // if (window.location.hostname.includes("mcl")) {
      //   const cookieValue=JSON.stringify(res);
      //   document.cookie = `livlet-token=${cookieValue};domain=.livlet.com;path=/`;
      //   window.location.replace(`${EnvConfig.APP_URL}/home`);
      //   return;
      // }
      saveAccessToken(res.accessToken);
      setEmailedFilesState("[]");
      setFileEmailedPopupState("");
      dispatch(replace("/home"));
      dispatch(getPropertiesResetAction ());
      dispatch(getPropertyIdInitAction());
      dispatch(getPropertyPreferencesInitAction());
      return dispatch(
        logInSuccessAction({...decodeJwt(res.accessToken), ...res})
      );
    })
    .catch((error) => {
      if (error.data === "registration-needed") {
        dispatch(replace(`/register?externalSourceName=${payload.externalSourceName}&externalId=${payload.externalId}&externalSourceToken=${payload.externalSourceToken}&name=${payload.name}&email=${payload.email}`))
      }
      if (error.data === "verify-password") {
        dispatch(replace(`/login/connect?externalSourceName=${payload.externalSourceName}&externalId=${payload.externalId}&externalSourceToken=${payload.externalSourceToken}&email=${payload.email}`))
      }
      if (error.data === "Failed to recognize external source in credentials") {
        payload.externalSourceName === "facebook" && dispatch(facebookEmailErrorAction());
        dispatch(replace('/login'));
        payload.externalSourceName === "facebook" && dispatch(logInFailureAction());
      } else {
        dispatch(accountNotActivatedAction())
      }
    });
};

export const mergeAndSignIn: TMergeAndLoginAction = (payload: TMergeSocialRequest) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(logInInitAction());
  clearIdleTimeout();
  return mergeSocial(payload)
    .then((payload) => {
      saveAccessToken(payload.accessToken);
      dispatch(push("/home"));
      dispatch(getPropertiesResetAction ());
      dispatch(getPropertyIdInitAction());
      dispatch(getPropertyPreferencesInitAction());
      return dispatch(
        logInSuccessAction({...decodeJwt(payload.accessToken), ...payload})
      );
    })
    .catch((error) => {
      dispatch(logInFailureAction());
      dispatch(badCredentialsAction(error.data.message ? error.data.message : "Incorrect password."));
    });
};

export const signOut = () => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  clearAccessToken();
  clearIdleTimeout();
  dispatch(getPropertiesResetAction());
  dispatch(getMaintenancesResetAction())
  dispatch(push("/login"));
  return dispatch(logOutAction());
};

export const loginClean = () => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  return dispatch(logInCleanAction());
};

export const clearPopup = () => (
  dispatch: (action: Action) => any
) => {
  return dispatch(clearPopupAction());
};