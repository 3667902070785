import {Guid} from "guid-typescript";
import {apiPut, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {TCertificateRequest} from "./addCertificate";

export const editCertificate = (payload: TCertificateRequest & { propertyId: Guid, certificateId: Guid }) => {
  const { propertyId, certificateId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/certificate/${certificateId}`
  );

  return apiPut<any, TCertificateRequest>(url, payload);
};
