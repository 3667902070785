import {apiPut, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TPutHighlights = {
  potentialEnergyEfficiency?: number;
  currentEnergyEfficiency?: number;
  estimatedValue?: number;
  currentEnergyRating?: string;
};

export const putHighlights = (propertyId: Guid, payload: TPutHighlights) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/highlights/epc`);

  return apiPut(url, payload);
};
