import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TGetTenantsResponse = Array<{
  tenancyId: Guid;
  propertyId: Guid;
  startDate: string;
  endDate: string;
  rentalPayment: number;
  tenancyContactId: Guid;
  agentFee: number;
  agentContact: Guid;
  propertyManagementFee: number;
  propertyManagementContact: Guid;
}>;

export const getTenants = (propertyId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/tenancies`,
    {}
  );

  return apiGet<TGetTenantsResponse | []>(url, {}, propertyId);
};
