import React from "react";
import {ReactComponent as DeleteImage} from "../../../images/Delete.svg";
import {removeExpense} from "../../../api-wrapper/expenses/removeExpense";
import {getAllExpenses} from "../../actions/expenses";
import {List, Menu, Modal, Popover, Skeleton, Tag} from "antd";
import _ from "lodash";
import {ReactComponent as AttachmentIcon} from "../../../images/AttachmentsButton.svg";
import {numberWithCommas} from "../helper";
import {ReactComponent as MoreMenu} from "../../../images/More.svg";
import {TAddWorkResponse} from "../../../api-wrapper/works/addWork";
import {TAddInventoryResponse} from "../../../api-wrapper/inventories/addInventory";
import {TAddContactResponse} from "../../../api-wrapper/contacts/addContact";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {MenuStyled} from "../works/WorksListComp";
import {Guid} from "guid-typescript";
import {
  getContactsSelector,
  getInventoriesSelector,
  getPropertyPreferencesSelector,
  getWorksSelector
} from "../../selectors";
import {removeRelation} from "../../../api-wrapper/relation/removeRelation";
import {emptyGuid, getCurrencySign} from "../../screens/helpers";
import {editExpense} from "../../../api-wrapper/expenses/editExpense";

type Props = {
  item: any,
  work: TAddWorkResponse | null;
  inventory: TAddInventoryResponse | null;
  contact: TAddContactResponse | null;
  drawerToClose: (arg: any) => void,
  refreshParent: () => void,

  setEditExpenseData: (arg: any) => void,
  toggleExpenseDrawer: (arg: any) => void,
  setEditWorkData?: (arg: any) => void | null,
  toggleWorkDrawer?: (arg: any) => void | null,
  setEditInventoryData?: (arg: any) => void | null,
  toggleInventoryDrawer?: (arg: any) => void | null,
}

const ExpenseListItem = (props: Props) => {
  const {
    item,
    work,
    inventory,
    contact,
    drawerToClose,
    refreshParent,
    setEditExpenseData,
    toggleExpenseDrawer,
    setEditWorkData,
    toggleWorkDrawer,
    setEditInventoryData,
    toggleInventoryDrawer,
  } = props;
  const dispatch = useDispatch();

  const works = useSelector(getWorksSelector);
  const contacts = useSelector(getContactsSelector);
  const inventories = useSelector(getInventoriesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  function confirmUnlink(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will still be able to find it in the Expenses page.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        const parentId = (work ? work.workId : contact ? contact.contactId : inventory ? inventory.id : emptyGuid);
        const parentType = (work ? "work" : contact ? "contact" : inventory ? "inventory" : null);
        parentId && parentType && removeRelation(item.propertyId, parentId, parentType, item.expenseId, "expense").then(() => {
          if (refreshParent) refreshParent();
          if (contact) {
            editExpense({
              propertyId: item.propertyId,
              expenseId: item.expenseId,
              name: item.name,
              payee: null,
              type: item.type,
              totalCost: item.totalCost,
              isTaxDeductible: item.isTaxDeductible,
              notes: item.notes,
              paymentDate: item.paymentDate,
              tags: item.tags,
              isConfirmed: true,
              userPaidBy: item.userPaidBy,
              contactPaidBy: item.contactPaidBy
            }).then(() => {
              if (refreshParent) refreshParent();
              dispatch(getAllExpenses(item.propertyId));
            });
          }
          dispatch(getAllExpenses(item.propertyId));
        });
      }
    });
  }

  function confirmDelete(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        removeExpense(item.propertyId, item.expenseId).then(() => {
          if (refreshParent) refreshParent();
          dispatch(getAllExpenses(item.propertyId));
        });
      }
    });
  }

  const moreMenuDropdown = (item: any) => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;

      if (key === "edit") {
        drawerToClose(false);
        toggleExpenseDrawer(true);
        setEditExpenseData({ data: item });
      } else if (key === "Delete") {
        confirmDelete(item);
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="edit">Edit</Menu.Item>
        <Menu.Item key="Delete">Delete</Menu.Item>
      </MenuStyled>
    );
  };

  const workFilter = (parentId: Guid) =>
    works.content.filter((work) => work.workId === parentId);

  const openRelatedWork = (workId: Guid) => {
    if (setEditWorkData && toggleWorkDrawer) {
      setEditWorkData({data: works.content.filter((work) => work.workId === workId)[0],});
      toggleWorkDrawer(true);
    }
  };

  const openRelatedInventory = (inventoryId: Guid) => {
    if (setEditInventoryData && toggleInventoryDrawer) {
      setEditInventoryData({
        // @ts-ignore
        data: inventories.content.filter(
          (inventory) => inventory.id === inventoryId
        )[0],
      });
      toggleInventoryDrawer(true);
    }
  };

  const showContactName = (contactId: Guid) => {
    const filteredContacts = contacts.content.filter(
      (contact) => contact.contactId === contactId
    );
    return _.isEmpty(filteredContacts) ? "" : filteredContacts[0].name;
  };

  return (
      <ListItemStyled
        onClick={() => {
          drawerToClose(false);
          toggleExpenseDrawer(true);
          setEditExpenseData({ data: item });
        }}
        actions={
          (work || inventory || contact || window.innerWidth < 1024)
            ? [
                <DeleteImage
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmUnlink(item);
                  }}
                />,
            ]
            : []
        }
      >
        <Skeleton loading={false} title={false} active>
          <List.Item.Meta
            title={item.name}
            description={
              <>
                {!work && !inventory && !contact && (
                  <>
                    {item.parentType && item.parentType === "work" && (
                      <>
                        <span>Works{" > "}</span>
                        <RelatedLink
                          onClick={(e) => {
                            e.stopPropagation();
                            openRelatedWork(item.parentId);
                          }}
                        >
                          {_.isEmpty(workFilter(item.parentId))
                            ? ""
                            : workFilter(item.parentId)[0].name}
                        </RelatedLink>
                      </>
                    )}
                    {!_.isEmpty(
                      inventories.content.filter(
                        (inventory) => inventory.id === item.parentId
                      )
                    ) &&
                    item.parentType &&
                    item.parentType === "inventory" && (
                      <>
                        <span>Inventories{" > "}</span>
                        <RelatedLink
                          onClick={(e) => {
                            e.stopPropagation();
                            openRelatedInventory(item.parentId);
                          }}
                        >
                          {
                            inventories.content.filter(
                              (inventory) => inventory.id === item.parentId
                            )[0].name
                          }
                        </RelatedLink>
                      </>
                    )}
                  </>
                )}
                {(work || inventory) && (
                  <span>{item.payee && showContactName(item.payee)}</span>
                )}
                <span>{"\u00A0"}</span>
              </>
            }
          />
          {item.attachmentsCount && (
            <AttachmentTagStyled>
              <AttachmentIcon
                style={{
                  height: "75%",
                  marginTop: "0.1rem",
                  marginRight: "0.2rem",
                }}
              />{" "}
              {item.attachmentsCount} ATTACHMENT
              {item.attachmentsCount > 1 && "S"}
            </AttachmentTagStyled>
          )}
          <ExpensePaymentDetails>
            {work || inventory ? (
              <TagStyledHeader>
                {getCurrencySign(propertyPreferences.preferredCurrency) + numberWithCommas(item.totalCost)}
              </TagStyledHeader>
            ) : (
              <TagStyledList>
                {getCurrencySign(propertyPreferences.preferredCurrency) + numberWithCommas(item.totalCost)}
              </TagStyledList>
            )}
            {item.paymentDate && !_.isEmpty(item.paymentDate) && (
              <SubTag>
                Payment date: {moment(item.paymentDate).format(propertyPreferences.preferredDateFormat)}
              </SubTag>
            )}
          </ExpensePaymentDetails>
        </Skeleton>
        {(!work && !inventory && !contact && window.innerWidth > 1024) && (
          <Popover
            content={moreMenuDropdown(item)}
            placement="bottomRight"
            trigger={["click"]}
          >
            <MoreMenu
              style={{ marginLeft: "2rem" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Popover>
        )}
      </ListItemStyled>
  )
}

const ListItemStyled = styled(List.Item)`
  @media (max-width: 1024px) {
    width: 100%;
    padding: 1rem;

    .ant-list-item-action > li {
      width: 100%;
    }
  }
  @media (min-width: 1024px) {
    height: 6rem;
    padding: 1rem 1.25rem;
    border-color: #dae0e6 !important;
    padding-right: 2rem;
  }

  .ant-list-item-meta-title {
    font-size: 1rem;
    font-weight: 600;
    color: #21272b;
  }
  .ant-list-item-meta-description {
    color: #6b7185;
    font-weight: 400;
    margin-top: 0.4rem;
  }
`;

const RelatedLink = styled.a`
  color: #6b7185;
  text-decoration: underline;
`;

const ExpensePaymentDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const TagStyled = styled(Tag)`
  display: flex;
  align-items: center;
  background-color: #eff1f5;
  color: #21272b;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 0.25rem;
  padding-bottom: 0.3rem;
  margin: 0;
  border-radius: 0.25rem;
  border: 0;
  font-weight: 700;
  font-size: 1.1rem;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const TagStyledList = styled(TagStyled)`
  background: #fff;
`;

const SubTag = styled.span`
  color: #6b7185;
  font-weight: 400;
  margin-top: 0.6rem;
`;

const TagStyledHeader = styled(TagStyled)``;

const AttachmentTagStyled = styled(Tag)`
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  align-items: flex-start;
  border: none;

  padding: 0.5rem;
  font-weight: 500;
  font-size: 0.75rem;
  color: #6b7185;
`;

export default ExpenseListItem