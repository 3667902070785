export enum TFetchingStatus {
  Defined = "Defined",
  Initial = "Initial",
  Success = "Success",
  Failure = "Failure",
}

export enum TTaskCategories {
  Upkeep = "Upkeep",
  Budget = "Budgeting",
  CustomTask = "User-created",
  CustomBudget = "Recurring expense - User-created",
  Reminder = "Reminder",
}

export enum TTaskTypes {
  Budget = "Budgeting",
  Task = "Task",
  Reminder = "Reminder",
  Upkeep = "Upkeep"
}