import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TUpcomingTask} from "./getUpcomingTasks";
import _ from "lodash";

export const snoozeUpcomingTask = (payload: TUpcomingTask & { snoozeDate?: string }) => {
  const { propertyId, snoozeDate } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/alerts/snooze${snoozeDate ? `?date=${snoozeDate}` : null}`
  );

  return apiPost<{}, TUpcomingTask>(url, _.omit(payload, 'snoozeDate'));
};