import React, {useEffect, useState} from "react";
import {AutoComplete, Form} from "antd";
import {
  ButtonBlue,
  ButtonWrapper,
  FieldWrapper,
  FormLabel,
  FormSectionTitle,
  FormWrapper, InputStyled,
  PageTitle,
  PageWrapper
} from "./SiteVisitStep";
import {ReactComponent as ArrowDownSelect} from "../../../images/ArrowDownSelect.svg";
import styled from "styled-components";

type Props = {
  setStep: (arg: number) => void;
  setInfoGatheringData: (arg: any) => void;
  reset: boolean;
  toggleReset: (arg: boolean) => void;
  form: any;
}

const InfoGatheringStep = (props: Props) => {
  const {setStep, setInfoGatheringData, reset, toggleReset, form} = props;

  const heatingSystemTypes = [
    {key: "Gas boiler", value: "Gas boiler"},
    {key: "Electric storage heating", value: "Electric storage heating"},
    {key: "Oil boiler", value: "Oil boiler"},
    {key: "Heat pump (Ground Source)", value: "Heat pump (Ground Source)"},
    {key: "Heat pump (Air Source)", value: "Heat pump (Air Source)"},
    {key: "Biomass or other low carbon heating", value: "Biomass or other low carbon heating"},
    {key: "Don't know/Other", value: "Don't know/Other"},
  ]
  const [heatingSystemTypeOptions, setHeatingSystemTypeOptions] = useState(heatingSystemTypes)

    useEffect(() => {
    if (reset) {
      form.resetFields();
      toggleReset(false);
    }
  }, [reset])

  const handleGoBack = () => {
    window.scrollTo(0, 0);
    setStep(1);
  }

  const handleComplete = (formData: any) => {
    setInfoGatheringData({
      systemLocations: [
        {type: "Water stopcock", location: formData.waterStopcockLocation ?? null},
        {type: "Consumer unit", location: formData.consumerUnitLocation ?? null},
        {type: "Gas shut-off valve", location: formData.gasShutOffValveLocation ?? null},
        {type: "Electric meter", location: formData.electricMeterLocation ?? null},
        {type: "Gas meter", location: formData.gasMeterLocation ?? null},
        {type: "Water meter", location: formData.waterMeterLocation ?? null},
        {type: "EV car charging point", location: formData.evCarChargingPoint ?? null},
        {type: "Boiler", location: formData.boilerLocation ?? null},
        {type: "Hot water cylinder or boiler", location: formData.hotWaterCylinderLocation ?? null},
        {type: "Cold water tank", location: formData.coldWaterTank ?? null},
      ],
      keyAppliances: [{
          type: formData.heatingSystemType ?? null,
          location: formData.heatingSystemLocation ?? null,
          brand: formData.heatingSystemBrand ?? null,
          model: formData.heatingSystemModel ?? null,
          serialNr: formData.heatingSystemSerialNr ?? null
        }, {
          type: "Dishwasher",
          location: formData.dishwasherLocation ?? null,
          brand: formData.dishwasherBrand ?? null,
          model: formData.dishwasherModel ?? null,
          serialNr: formData.dishwasherSerialNr ?? null
        }, {
          type: "Washing Machine",
          location: formData.washingMachineLocation ?? null,
          brand: formData.washingMachineBrand ?? null,
          model: formData.washingMachineModel ?? null,
          serialNr: formData.washingMachineSerialNr ?? null
        }, {
          type: "Fridge",
          location: formData.fridgeLocation ?? null,
          brand: formData.fridgeBrand ?? null,
          model: formData.fridgeModel ?? null,
          serialNr: formData.fridgeSerialNr ?? null
        }, {
          type: "Freezer",
          location: formData.freezerLocation ?? null,
          brand: formData.freezerBrand ?? null,
          model: formData.freezerModel ?? null,
          serialNr: formData.freezerSerialNr ?? null
        }, {
          type: "Cooker",
          location: formData.cookerLocation ?? null,
          brand: formData.cookerBrand ?? null,
          model: formData.cookerModel ?? null,
          serialNr: formData.cookerSerialNr ?? null
        }, {
          type: "Oven",
          location: formData.ovenLocation ?? null,
          brand: formData.ovenBrand ?? null,
          model: formData.ovenModel ?? null,
          serialNr: formData.ovenSerialNr ?? null
        }, {
          type: "Hob",
          location: formData.hobLocation ?? null,
          brand: formData.hobBrand ?? null,
          model: formData.hobModel ?? null,
          serialNr: formData.hobSerialNr ?? null
      }]
    });
    window.scrollTo(0, 0);
    setStep(3);
  }

  const onTypeAutocompleteSearch = (searchText: string) => {
    setHeatingSystemTypeOptions(!searchText
      ? heatingSystemTypes.map((type: any) => { return {key: type.key, value: type.value} })
      : heatingSystemTypes
        .filter((type: any) => type.key.toLowerCase().includes(searchText.toLowerCase()))
        .map((type: any) => { return {key: type.key, value: type.value }}))
  }

  return <PageWrapper>
    <PageTitle>Livlet site visit (Page 2 of 4)</PageTitle>
    <FormWrapper>
      <Form
        form={form}
        name="manageProperty"
        layout="vertical"
        size={"large"}
        onFinish={handleComplete}
      >
        <FormSectionTitle>Info Gathering</FormSectionTitle>
        <FormLabel>Water stopcock location</FormLabel>
        <Form.Item key={"waterStopcockLocation"} name={"waterStopcockLocation"}>
          <InputStyled />
        </Form.Item>
        <FormLabel>Consumer unit location</FormLabel>
        <Form.Item key={"consumerUnitLocation"} name={"consumerUnitLocation"}>
          <InputStyled />
        </Form.Item>
        <FormLabel>Gas shut-off valve location</FormLabel>
        <Form.Item key={"gasShutOffValveLocation"} name={"gasShutOffValveLocation"}>
          <InputStyled />
        </Form.Item>
        <FormLabel>Electric meter location</FormLabel>
        <Form.Item key={"electricMeterLocation"} name={"electricMeterLocation"}>
          <InputStyled />
        </Form.Item>
        <FormLabel>Gas meter location</FormLabel>
        <Form.Item key={"gasMeterLocation"} name={"gasMeterLocation"}>
          <InputStyled />
        </Form.Item>
        <FormLabel>Water meter location</FormLabel>
        <Form.Item key={"waterMeterLocation"} name={"waterMeterLocation"}>
          <InputStyled />
        </Form.Item>
        <FormLabel>EV car charging point location</FormLabel>
        <Form.Item key={"evCarChargingPointLocation"} name={"evCarChargingPointLocation"}>
          <InputStyled />
        </Form.Item>
        <FormLabel>Boiler location</FormLabel>
        <Form.Item key={"boilerLocation"} name={"boilerLocation"}>
          <InputStyled />
        </Form.Item>
        <FormLabel>Hot water cylinder or boiler location</FormLabel>
        <Form.Item key={"hotWaterCylinderLocation"} name={"hotWaterCylinderLocation"}>
          <InputStyled />
        </Form.Item>
        <FormLabel>Cold water tank location</FormLabel>
        <Form.Item key={"coldWaterTankLocation"} name={"coldWaterTankLocation"}>
          <InputStyled />
        </Form.Item>

        <FormSectionTitle>Key Appliances / Systems</FormSectionTitle>
        <FormLabel>Heating system type (Boiler, Air source, Elec...)</FormLabel>
        <Form.Item key={"heatingSystemType"} name={"heatingSystemType"}>
          <AutoCompleteYellow
            showSearch
            allowClear={false}
            placeholder={"Select type"}
            bordered={false}
            defaultActiveFirstOption={false}
            filterOption={false}
            showArrow={true}
            onSearch={onTypeAutocompleteSearch}
            // @ts-ignore
            options={heatingSystemTypeOptions}
            suffixIcon={<ArrowDownSelect />}
            dropdownClassName={"upkeep-tag-select"}
            dropdownStyle={{width: "100%", borderRadius: "12px", padding: "0.5rem"}}
          />
        </Form.Item>
        <FieldWrapper className={"col-4"}>
          <div>
            <FormLabel>Location</FormLabel>
            <Form.Item key={"heatingSystemLocation"} name={"heatingSystemLocation"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Brand</FormLabel>
            <Form.Item key={"heatingSystemBrand"} name={"heatingSystemBrand"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Model</FormLabel>
            <Form.Item key={"heatingSystemModel"} name={"heatingSystemModel"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Serial number</FormLabel>
            <Form.Item key={"heatingSystemSerialNr"} name={"heatingSystemSerialNr"}>
              <InputStyled />
            </Form.Item>
          </div>
        </FieldWrapper>

        <FormLabel>Dishwasher</FormLabel>
        <FieldWrapper className={"col-4"}>
          <div>
            <FormLabel>Location</FormLabel>
            <Form.Item key={"dishwasherLocation"} name={"dishwasherLocation"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Brand</FormLabel>
            <Form.Item key={"dishwasherBrand"} name={"dishwasherBrand"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Model</FormLabel>
            <Form.Item key={"dishwasherModel"} name={"dishwasherModel"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Serial number</FormLabel>
            <Form.Item key={"dishwasherSerialNr"} name={"dishwasherSerialNr"}>
              <InputStyled />
            </Form.Item>
          </div>
        </FieldWrapper>

        <FormLabel>Washing Machine</FormLabel>
        <FieldWrapper className={"col-4"}>
          <div>
            <FormLabel>Location</FormLabel>
            <Form.Item key={"washingMachineLocation"} name={"washingMachineLocation"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Brand</FormLabel>
            <Form.Item key={"washingMachineBrand"} name={"washingMachineBrand"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Model</FormLabel>
            <Form.Item key={"washingMachineModel"} name={"washingMachineModel"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Serial number</FormLabel>
            <Form.Item key={"washingMachineSerialNr"} name={"washingMachineSerialNr"}>
              <InputStyled />
            </Form.Item>
          </div>
        </FieldWrapper>

        <FormLabel>Fridge</FormLabel>
        <FieldWrapper className={"col-4"}>
          <div>
            <FormLabel>Location</FormLabel>
            <Form.Item key={"fridgeLocation"} name={"fridgeLocation"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Brand</FormLabel>
            <Form.Item key={"fridgeBrand"} name={"fridgeBrand"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Model</FormLabel>
            <Form.Item key={"fridgeModel"} name={"fridgeModel"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Serial number</FormLabel>
            <Form.Item key={"fridgeSerialNr"} name={"fridgeSerialNr"}>
              <InputStyled />
            </Form.Item>
          </div>
        </FieldWrapper>

        <FormLabel>Freezer</FormLabel>
        <FieldWrapper className={"col-4"}>
          <div>
            <FormLabel>Location</FormLabel>
            <Form.Item key={"freezerLocation"} name={"freezerLocation"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Brand</FormLabel>
            <Form.Item key={"freezerBrand"} name={"freezerBrand"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Model</FormLabel>
            <Form.Item key={"freezerModel"} name={"freezerModel"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Serial number</FormLabel>
            <Form.Item key={"freezerSerialNr"} name={"freezerSerialNr"}>
              <InputStyled />
            </Form.Item>
          </div>
        </FieldWrapper>

        <FormLabel>Cooker</FormLabel>
        <FieldWrapper className={"col-4"}>
          <div>
            <FormLabel>Location</FormLabel>
            <Form.Item key={"cookerLocation"} name={"cookerLocation"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Brand</FormLabel>
            <Form.Item key={"cookerBrand"} name={"cookerBrand"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Model</FormLabel>
            <Form.Item key={"cookerModel"} name={"cookerModel"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Serial number</FormLabel>
            <Form.Item key={"cookerSerialNr"} name={"cookerSerialNr"}>
              <InputStyled />
            </Form.Item>
          </div>
        </FieldWrapper>

        <FormLabel>Oven</FormLabel>
        <FieldWrapper className={"col-4"}>
          <div>
            <FormLabel>Location</FormLabel>
            <Form.Item key={"ovenLocation"} name={"ovenLocation"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Brand</FormLabel>
            <Form.Item key={"ovenBrand"} name={"ovenBrand"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Model</FormLabel>
            <Form.Item key={"ovenModel"} name={"ovenModel"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Serial number</FormLabel>
            <Form.Item key={"ovenSerialNr"} name={"ovenSerialNr"}>
              <InputStyled />
            </Form.Item>
          </div>
        </FieldWrapper>

        <FormLabel>Hob</FormLabel>
        <FieldWrapper className={"col-4"}>
          <div>
            <FormLabel>Location</FormLabel>
            <Form.Item key={"hobLocation"} name={"hobLocation"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Brand</FormLabel>
            <Form.Item key={"hobBrand"} name={"hobBrand"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Model</FormLabel>
            <Form.Item key={"hobModel"} name={"hobModel"}>
              <InputStyled />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Serial number</FormLabel>
            <Form.Item key={"hobSerialNr"} name={"hobSerialNr"}>
              <InputStyled />
            </Form.Item>
          </div>
        </FieldWrapper>

        <ButtonWrapper>
          <ButtonBlue onClick={() => handleGoBack()}>Back</ButtonBlue>
          <ButtonBlue htmlType={"submit"}>Next</ButtonBlue>
        </ButtonWrapper>
      </Form>
    </FormWrapper>
  </PageWrapper>
}

export const AutoCompleteYellow = styled(AutoComplete)`
  width: 100%;
  
  .ant-select-selector { 
    background: #FEF9EA!important;
    height: 3.125rem!important;
    display: flex!important;
    align-items: center!important;
  }
  
  .ant-select-selection-item, .ant-select-selection-search { 
    display: flex!important;
    align-items: center!important;
    height: 3.125rem!important;
  }
  
  .ant-select-selection-placeholder { 
    color: #BFBFC3!important; 
    font-size: 1rem!important;
    font-weight: 600!important;
  }
  
  .ant-select-selection-search-input { 
    color: #666F7A!important;  
    font-size: 1rem!important;
    font-weight: 600!important;
  }
  
  svg { margin-right: 0.5rem; }
  svg path { stroke: #BFBFC3; }
`;

export default InfoGatheringStep