import {apiGet, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TGetEntityTypesResponseItem = {
  typeId: Guid;
  category: string;
  name: string;
};

export type TGetEntityTypesResponse = Array<TGetEntityTypesResponseItem>;

export const getEntityTypes = (propertyId: Guid, category: string) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/entityTypes/${category}`
  );

  return apiGet<TGetEntityTypesResponse | []>(url, {}, propertyId);
};
