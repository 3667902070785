import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetWorksResponse } from "../../api-wrapper/works/getWorks";
import {
  getWorksFailureAction,
  getWorksInitAction,
  getWorksSuccessAction,
  TGetWorksActions,
} from "../actions/works";
import {worksInitial} from "../components/helper";

export type TWorks = {
  fetchingStatus: TFetchingStatus;
  content: TGetWorksResponse;
};

const initialState: TWorks = {
  content: worksInitial,
  fetchingStatus: TFetchingStatus.Defined,
};

export const worksReducer = createReducer<TWorks, TGetWorksActions>(
  initialState
)
  .handleAction(getWorksInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getWorksSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getWorksFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
