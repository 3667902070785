import React, {useEffect, useState} from "react";
import {Form, Drawer, Modal, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {ReactComponent as MyPropertyIcon} from "../../../images/MyProperty.svg";
import {ButtonsWrapper, CloseButton, SaveButton, SaveButtonWrapper} from "../works/WorkDrawer";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import styled from "styled-components";
import {InputsWrapper, InputWrapper, Label, LabelWrapper} from "../../screens/RegistrationPage";
import {editPreferredSettings} from "../../../api-wrapper/properties/editPreferredSettings";
import {getAllProperties} from "../../actions/properties";
import {setPropertyPreferences} from "../../actions/propertyPreferences";
import {PropertyDrawerTitle} from "../../screens/components";
import {InputNumberStyled} from "../expenses/ExpenseDrawer";
import {months} from "../../screens/helpers";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
}

const PropertyPreferencesDrawer = (props: Props) => {
  const { isOpen, toggleDrawerOpen } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<any>(null);

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  useEffect(() => {
    setSelectedMonth(propertyPreferences.taxYearMonth);
  }, [propertyPreferences])

  const initialValues = () => {
    return {
      preferredDateFormat: propertyPreferences.preferredDateFormat,
      preferredCurrency: propertyPreferences.preferredCurrency,
      taxYearMonth: propertyPreferences.taxYearMonth ?? 1,
      taxYearDate: propertyPreferences.taxYearDate
    }
  };

  const handleManagePreferences = async (e: {
    preferredDateFormat: string,
    preferredCurrency: string,
    taxYearMonth: number,
    taxYearDate: number
  }) => {
    const { preferredDateFormat, preferredCurrency, taxYearMonth, taxYearDate } = e;
    editPreferredSettings({
      propertyId,
      preferredDateFormat,
      preferredCurrency,
      taxYearMonth,
      taxYearDate
    }).then(() => {
      dispatch(getAllProperties());
      dispatch(setPropertyPreferences({
        preferredCurrency, preferredDateFormat, taxYearDate, taxYearMonth
      }));
      form.resetFields();
      toggleDrawerOpen(false);
    })
  }

  const confirmCloseUnsaved = () => {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        form.resetFields();
        toggleDrawerOpen(false);
      }
    });
  }

  return (
    <Drawer
      closeIcon={false}
      width={window.innerWidth > 1024 ? "55%" : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => {
        if (formFieldsChanged) {
          confirmCloseUnsaved();
          return;
        }
        form.resetFields();
        toggleDrawerOpen(false);
      }}
      headerStyle={{backgroundColor: "#ebf9ff", margin: 0, padding: "2rem"}}
      title={<>
        <section style={{display: "flex", justifyContent: "space-between", marginBottom: "1.5rem",}}>
          <IconWrapper>
            <MyPropertyIcon className={"my-property-theme-icon"} />{" "}
            <span style={{ paddingRight: "0.25rem", fontSize:"0.75rem" }}>MY PROPERTY</span>
          </IconWrapper>
          <ButtonsWrapper>
            <CloseButton size={"large"}
              onClick={() => {
                toggleDrawerOpen(false);
                form.resetFields();
              }}
            >
              {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
            </CloseButton>
          </ButtonsWrapper>
        </section>
        <PropertyDrawerTitle>Property preferences</PropertyDrawerTitle>
      </>}
    >
      <Form
        form={form}
        name="managePropertyDetails"
        layout="vertical"
        size={"large"}
        initialValues={initialValues()}
        onFieldsChange={() => setFormFieldsChanged(true)}
        onFinish={handleManagePreferences}
      >
        <InputsWrapper>
          <InputWrapper>
            <LabelWrapper>
              <Label>Preferred currency</Label>
            </LabelWrapper>
            <Form.Item name={"preferredCurrency"} key={"preferredCurrency"}>
              <SelectStyled>
                <Select.Option key={"Dollar"} value="Dollar">USD</Select.Option>
                <Select.Option key={"Euro"} value="Euro">EUR</Select.Option>
                <Select.Option key={"GBP"} value="GBP">GBP</Select.Option>
              </SelectStyled>
            </Form.Item>
          </InputWrapper>
          <InputWrapper>
            <LabelWrapper>
              <Label>Preferred date format</Label>
            </LabelWrapper>
            <Form.Item name={"preferredDateFormat"} key={"preferredDateFormat"}>
              <SelectStyled>
                <Select.Option key={"DD/MM/YYYY"} value="DD/MM/YYYY">DD/MM/YYYY</Select.Option>
                <Select.Option key={"MM/DD/YYYY"} value="MM/DD/YYYY">MM/DD/YYYY</Select.Option>
              </SelectStyled>
            </Form.Item>
          </InputWrapper>
        </InputsWrapper>
        <InputsWrapper>
          <InputWrapper>
            <LabelWrapper>
              <Label>Tax year end day</Label>
            </LabelWrapper>
            <Form.Item name={"taxYearDate"} key={"taxYearDate"}>
              <InputNumberStyled
                maxLength={2}
                min={1}
                max={selectedMonth ? months.filter(m => m.number === parseInt(selectedMonth))[0].daysInMonth : 30}
                precision={0}
              />
            </Form.Item>
          </InputWrapper>
          <InputWrapper>
            <LabelWrapper>
              <Label>Tax year end month</Label>
            </LabelWrapper>
            <Form.Item name={"taxYearMonth"} key={"taxYearMonth"}>
              <SelectStyled onChange={(val) => setSelectedMonth(val)}>
                {months.map(m => <Select.Option key={m.number} value={m.number}>{m.name}</Select.Option>)}
              </SelectStyled>
            </Form.Item>
          </InputWrapper>
        </InputsWrapper>
        <p>Please note that the currency format is purely cosmetic - no currency conversion happens as your preferences are changed.</p>
        <SaveButtonWrapper style={{marginTop: 0}}>
          <SaveButton
            style={{
              display: "flex",
              alignItems: "center",
            }}
            type={"primary"}
            htmlType={"submit"}
          >
            Save details
          </SaveButton>
        </SaveButtonWrapper>
      </Form>
    </Drawer>)
}

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

const SelectStyled = styled(Select)`
  .ant-select-selector {
    height: 40px!important;
    border-radius: 6px!important;
  }
`

export default PropertyPreferencesDrawer