import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {
  getTagsWithUsageCount,
  TGetTagsWithUsageCountResponse,
} from "../../api-wrapper/tags/getTagsWithUsageCount";
import {Guid} from "guid-typescript";

export const getTagsWithUsageCountInitAction = createAction("tags/GET_TAGS_WITH_USAGE_COUNT_INIT")();
export const getTagsWithUsageCountSuccessAction = createAction(
  "tags/GET_TAGS_WITH_USAGE_COUNT_SUCCESS"
)<TGetTagsWithUsageCountResponse>();
export const getTagsWithUsageCountFailureAction = createAction("tags/GET_TAGS_WITH_USAGE_COUNT_FAILURE")();

export type TGetTagsWithUsageCountInitAction = ActionType<typeof getTagsWithUsageCountInitAction>;
export type TGetTagsWithUsageCountSuccessAction = ActionType<typeof getTagsWithUsageCountSuccessAction>;
export type TGetTagsWithUsageCountFailureAction = ActionType<typeof getTagsWithUsageCountFailureAction>;

export type TGetTagsWithUsageCountActions =
  | TGetTagsWithUsageCountInitAction
  | TGetTagsWithUsageCountSuccessAction
  | TGetTagsWithUsageCountFailureAction;

export type TGetTagsWithUsageCountThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetTagsWithUsageCountActions>;

export const getAllTagsWithUsageCount: TGetTagsWithUsageCountThunkActionType = (propertyId) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getTagsWithUsageCountInitAction());
  return getTagsWithUsageCount(propertyId)
    .then((payload) => {
      return dispatch(getTagsWithUsageCountSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getTagsWithUsageCountFailureAction()));
};
