import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TUpcomingTask = {
  id: Guid,
  propertyId: Guid,
  templateId: Guid,
  subscriptionId: number,

  type: string,
  date: string,
  title: string,
  message: string,
  periodicity: string,
  periodicityUnit: string,
  parentId: Guid | null,
  parentType: string | null,
  parentName: string | null,
  recordAs: string | null;

  templateType: string,
  templateStartDate: string | null,
  originalDate: string,
  triggerDate: string,
  sendTo: string | null,
  task: any;
}

export type TGetUpcomingTasksResponse = {
  tasks: Array<TUpcomingTask>,
  tasksExists: boolean,
  startDate: string,
  endDate: string
};

export type TGetUpcomingTaskRequest = {
  propertyId: Guid,
  startDate: string | null,
  endDate: string | null,
  firstInSeries: boolean
};

export const getUpcomingTasks = (payload: TGetUpcomingTaskRequest) => {
  const {propertyId, startDate, endDate, firstInSeries} = payload;
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/tasks/upcoming?firstInSeries=${firstInSeries}${startDate ? `&startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}`);

  return apiGet<TGetUpcomingTasksResponse >(url, {}, propertyId);
};
