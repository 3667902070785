import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import _ from "lodash";

export type TAddUpcomingTaskRequest = {
  title: string;
  message: string | null;
  date: string;
  sendTo: string | null;
  periodicity: number | null;
  periodicityUnit: string | null;

  parentId?: Guid;
  parentName?: string;
  parentType?: string;
};

export const addUpcomingTask = (payload: TAddUpcomingTaskRequest & { propertyId: Guid }) => {
  const { propertyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/alerts`
  );

  return apiPost<{}, TAddUpcomingTaskRequest>(
    url,
    _.omit(payload, ["propertyId"])
  );
};