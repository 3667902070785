import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  getImprovementsSelector,
  getMaintenancesSelector,
  getPropertiesSelector,
  getPropertyIdSelector,
} from "../selectors";
import {getAllWorks} from "../actions/works";
import _ from "lodash";
import {emptyGuid, isUKProperty} from "./helpers";
import UpcomingTasksWrapper from "../components/upcomingTasks/UpcomingTasksWrapper";
import moment from "moment";
import ImprovementsWidget from "../components/dashboardWidgets/ImprovementsWidget";
import styled from "styled-components";
import ContentsWidget from "../components/dashboardWidgets/ContentsWidget";
import HomeValueWidget from "../components/dashboardWidgets/HomeValueWidget";
import {getAllImprovements} from "../actions/improvements";
import {getAllMaintenances} from "../actions/maintenances";
import {getAllInventories} from "../actions/inventories";
import CostsWidget from "../components/dashboardWidgets/CostsWidget";
import {getAllExpenses, getAllExpenseTypes} from "../actions/expenses";
import NewsAlertsWidget from "../components/dashboardWidgets/NewsAlertsWidget";
import {getAllNews} from "../actions/news";
import {Alert, Spin} from "antd";
import {getAllConfirmedExpenses} from "../actions/confirmedExpenses";
import {TFetchingStatus} from "../../helpers";
import {LoadingOutlined} from "@ant-design/icons";
import TellUsMoreSurveyModal from "../components/onboarding/TellUsMoreSurveyModal";
import {PageHeader, PageTitle, PageWrapper} from "./components";
import {getAllProperties} from "../actions/properties";
import {getAllMortgages} from "../actions/mortgages";
import {getAllSpaces} from "../actions/spaces";

const Dashboard = () => {
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector)
  const maintenances = useSelector(getMaintenancesSelector)
  const improvementsConfiguration = useSelector(getImprovementsSelector)

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    dispatch(getAllWorks(propertyId));
    dispatch(getAllExpenses(propertyId))
    dispatch(getAllConfirmedExpenses(propertyId));
    dispatch(getAllImprovements(propertyId));
    dispatch(getAllMaintenances(propertyId));
    dispatch(getAllInventories(propertyId));
    dispatch(getAllExpenseTypes(propertyId))
    dispatch(getAllNews(propertyId));
    dispatch(getAllMortgages(propertyId));
    dispatch(getAllSpaces(propertyId))
    dispatch(getAllProperties());
  }, [propertyId]);

  const [startDate, setStartDate] = useState(moment().startOf("month"))
  const [costChanged, setCostChanged] = useState(1);
  const [dataLoadedOnce, setDataLoadedOnce] = useState(false);
  const [isTellUsMoreModalOpen, toggleTellUsMoreModal] = useState(false)
  const [ukProperty, setUkProperty] = useState(false);

  useEffect(() => {
    if (properties.filter((property) => property.propertyId === propertyId).length > 0) {
      let property = properties.filter((property) => property.propertyId === propertyId)[0]
      setUkProperty(isUKProperty(property.country, property.zip))
    }
  }, [propertyId, properties])

  useEffect(() => {
    if (maintenances.fetchingStatus === TFetchingStatus.Success && improvementsConfiguration.fetchingStatus === TFetchingStatus.Success) {
      setDataLoadedOnce(true);
    }
  }, [maintenances.fetchingStatus, improvementsConfiguration.fetchingStatus])

  if ((_.isEmpty(properties) || _.isEqual(properties[0].propertyId, emptyGuid.toJSON())  || _.isEqual(properties[0].propertyId, emptyGuid) || _.isEqual(propertyId, emptyGuid))
    || ((maintenances.fetchingStatus !== TFetchingStatus.Success || improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success) && !dataLoadedOnce) ) {
    // TODO maybe add here other checks for fetching status if there are problems with "blinking" dashboard
    return <Spin indicator={<LoadingOutlined style={{ fontSize: 72, marginTop: "10rem", marginLeft:window.innerWidth > 1024 ? "40rem" : "8rem" }} spin />} />;
  }

  return <PageWrapper>
    <PageHeader>
      <div>
        <PageTitle>Dashboard</PageTitle>
      </div>
    </PageHeader>
    <TellUsMoreSurveyModal
        isModalOpen={isTellUsMoreModalOpen}
        toggleModal={toggleTellUsMoreModal}
    />
    <UpcomingTasksWrapper
      wrapperMode={"calendar"}
      startDate={startDate}
      setStartDate={setStartDate}
      refreshParent={() => {
        dispatch(getAllMaintenances(propertyId))
        dispatch(getAllConfirmedExpenses(propertyId))
        setCostChanged(Date.now())
      }}
      totalColumns={window.innerWidth > 768 ? 7 : 1}
      columnsInRow={window.innerWidth > 768 ? 7 : 1}
    />
    <SingleWidgetWrapper>
      <NewsAlertsWidget/>
    </SingleWidgetWrapper>
    <SingleWidgetWrapper>
      <CostsWidget newData={costChanged}/>
    </SingleWidgetWrapper>
    {ukProperty && <SingleWidgetWrapper>
       <HomeValueWidget/>
    </SingleWidgetWrapper>}
    {ukProperty ? <DoubleWidgetWrapper>
      <ImprovementsWidget/>
      <ContentsWidget/>
    </DoubleWidgetWrapper> : <SingleWidgetWrapper>
      <ContentsWidget/>
    </SingleWidgetWrapper>}
  </PageWrapper>
};

export const DashboardWrapperNew = styled.main`
 display: flex;
 flex-direction: column;
 row-gap: 2rem;
`;

export const SingleWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0;
`;

export const DoubleWidgetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1.5rem;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 1.5rem;
  }
`;

export const WelcomeAlert = styled(Alert)`
  padding: 1.875rem!important;
  border: none;
  border-radius: 10px;
  background-color: #009966!important;

  @media (max-width: 1024px) {
    padding: 1.3rem!important;
    padding-bottom: 1.5rem!important;
  }
 
  .ant-alert-message {
    font-weight: 600;
    font-size: 1.2rem!important;
    color: #fff!important;
    margin-bottom: 1.6rem;
  }
  
  .ant-alert-description {
    color: #fff!important;
    font-size: 1rem!important;
    line-height: 1.625rem!important;
  }
  
  svg {
    color: #fff!important;
  }
  
  a {
    color: #fff;
    text-decoration: underline;
  }
  
  .partners-link {
    cursor: pointer;
    font-weight: 600;
  }
`;

export default Dashboard;
