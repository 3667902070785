import {Drawer, Form, Modal, Select, AutoComplete, InputNumber, Input, Spin} from "antd";
import { useIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import _ from "lodash";

import { ReactComponent as SaveIcon } from "../../../images/SaveIcon.svg";
import { ReactComponent as MyPropertyIcon } from "../../../images/MyProperty.svg";
import messages from "../../screens/messages";
import {
  InputsWrapper,
  InputWrapper,
  Label,
  LabelWrapper,
} from "../../screens/RegistrationPage";
import { useDispatch, useSelector } from "react-redux";
import {getImprovementsSelector, getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {ButtonsWrapper, CloseButton, SaveButton, SaveButtonWrapper} from "../works/WorkDrawer";
import {getAllImprovements} from "../../actions/improvements";
import {InputNumberStyled} from "../expenses/ExpenseDrawer";
import {TFetchingStatus} from "../../../helpers";
import {putDetails} from "../../../api-wrapper/properties/putDetails";
import {PropertyQuestionAnswer} from "../../../api-wrapper/properties/getImprovements";
import { ReactComponent as CrossIcon } from "../../../images/Cross.svg";
import {
  detailsImpactingValuation,
  propertyYearConversion,
  propertyYearOptions,
  propertyYearReversedConversion
} from "../helper";
import {CategoryNumber} from "./PropertyDetailsComp";
import {LoadingOutlined} from "@ant-design/icons";
import {PropertyDrawerTitle} from "../../screens/components";
import {getCurrencySign} from "../../screens/helpers";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  togglePopup?: (arg01: boolean) => void;
};

const PropertyDetailsDrawer = (props: Props) => {
  const { isOpen, toggleDrawerOpen } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const improvementsConfiguration = useSelector(getImprovementsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [questions, setQuestions] = useState<Array<any>>([]);
  const [propertyDetails, setPropertyDetails] = useState<Array<any>>([]);

  const [detailsToDisplay, setDetailsToDisplay] = useState<any>([]);
  const [changedDetailsIds, setChangedDetailsIds] = useState(new Map());
  const [changedDetails, setChangedDetails] = useState(new Map());
  const [propertyValuePurchased, setPropertyValuePurchased] = useState(0);
  const [propertyValueCurrent, setPropertyValueCurrent] = useState(0);
  const [yearBuilt, setYearBuilt] = useState<any>()
  const [areaSizeM2, setAreaSizeM2] = useState<any>(0)
  const [areaSizeSqft, setAreaSizeSqft] = useState<any>(0)
  const [areaUnit, setAreaUnit] = useState("m2")

  useEffect(() => {
    dispatch(getAllImprovements(propertyId));
    setSaveDisabled(false);
    setChangedDetails(new Map());
    setChangedDetailsIds(new Map());
    setFormFieldsChanged(false)
    form.resetFields();
  }, [form, propertyId, isOpen]);

  useEffect(() => {
    if (improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success)
      return;
    setQuestions([...improvementsConfiguration.content.questions]);
    setPropertyDetails([...improvementsConfiguration.content.propertyDetails]);
    setPropertyValueCurrent(parseInt(improvementsConfiguration.content.propertyHighlights.propertyValue))
    setPropertyValuePurchased(improvementsConfiguration.content.propertyHighlights.lastSoldPrice)
    setYearBuilt(propertyYearReversedConversion(improvementsConfiguration.content.propertyDetails[2]?.answer))
    setAreaSizeM2(improvementsConfiguration.content.propertyDetails[1]?.answer)
    setAreaSizeSqft(improvementsConfiguration.content.propertyDetails[1]?.answer * 10.76)
    let listOfGroups = new Array<any>();

    {[...Array(10)].map((x,groupOrder) => {
      if (improvementsConfiguration.content.propertyDetails.filter(group => group.groupOrder === groupOrder).length < 1)
        return;

      listOfGroups.push({
        groupName: improvementsConfiguration.content.propertyDetails.filter(group => group.groupOrder === groupOrder)[0].groupName,
        groupOrder: improvementsConfiguration.content.propertyDetails.filter(group => group.groupOrder === groupOrder)[0].groupOrder,
        items: _.chunk(improvementsConfiguration.content.propertyDetails.filter(group => group.groupOrder === groupOrder), 2),
      });
    })}

    listOfGroups.sort((group1, group2) => group1.groupOrder - group2.groupOrder);
    setDetailsToDisplay(listOfGroups);
  }, [improvementsConfiguration]);

  const handleManageDetails = () => {
    let updateValuation = false;
    const textAnswers = Array.from(changedDetails).map(itemArray => {
      if (detailsImpactingValuation.includes(itemArray[0]))
        updateValuation = true;
      if (_.isNil(itemArray[1]))
        return {
          id: itemArray[0]
        };
      return {
        id: itemArray[0],
        answer: itemArray[1]
      }
    });
    const selectAnswers = Array.from(changedDetailsIds).map(itemArray => {
      if (detailsImpactingValuation.includes(itemArray[0]))
        updateValuation = true;
      return {
        id: itemArray[0],
        questionId: propertyDetails.filter(detail => detail.id === itemArray[0])[0].questionId,
        answerId: itemArray[1]
      }
    });
    setSaveDisabled(true);
    putDetails(propertyId, [...textAnswers, ...selectAnswers], updateValuation)
      .then(() => {
        toggleDrawerOpen(false);
        form.resetFields();
        setSaveDisabled(false);
        dispatch(getAllImprovements(propertyId));
      });
  };

  const getValueForSelect = (id: number) => {
    if (changedDetailsIds.has(id))
      return changedDetailsIds.get(id);

    return propertyDetails.filter(detail => detail.id === id)[0].answerId;
  }

  const setAnswerSelect = (id: number, answerId: string) => {
    let newDetails = new Map(changedDetailsIds);
    newDetails.set(id, answerId);
    setFormFieldsChanged(true)
    setChangedDetailsIds(newDetails);
  };

  const getValue = (id: number) => {
    if (changedDetails.has(id))
      return changedDetails.get(id);
    if ((id === 18 || id === 19) && !propertyDetails.filter(detail => detail.id === id)[0].answer) {
      if (id === 18) return propertyValuePurchased
      if (id === 19) {
        return isNaN(propertyValueCurrent) ? undefined : propertyValueCurrent
      }
    } else return propertyDetails.filter(detail => detail.id === id)[0].answer;
  }

  const setValue = (id: number, answer: number | null | undefined | string) => {
    let newDetails = new Map(changedDetails);
    setFormFieldsChanged(true)
    if (_.isUndefined(answer) || _.isNil(answer) || !_.isNumber(answer)) {
      newDetails.set(id, null);
    } else {
      newDetails.set(id, answer);
    }
    setChangedDetails(newDetails);
  };

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        toggleDrawerOpen(false);
        form.resetFields();
        dispatch(getAllImprovements(propertyId));
      },
    });
  }

  const getItemPlaceholder = (item: any) => {
    if (item.placeholder === "e.g. £1,234") {
      return `e.g. ${getCurrencySign(propertyPreferences.preferredCurrency)}1,234`
    } else return item.placeholder
  }

  const renderQuestion = (item: any) =>
    <InputWrapper>
      <LabelWrapper>
        <Label>{item.label}</Label>
      </LabelWrapper>
      <Form.Item
        key={"itemId"+item.id}
        name={"itemId"+item.id}
        rules={_.isNil(item.questionId)?[
          {
            pattern: /^\d*\.?\d*$/,
            message: f(messages.validationCostOfInventory),
          },
        ]:[]}
      >
        {(_.isNil(item.questionId) && item.id === 2) &&
          <Input.Group compact>
            <InputNumber
              min={1}
              precision={0}
              maxLength={10}
              style={{width: '65%', paddingLeft: '0', paddingRight: '0', height: '40px', borderRadius: '6px 0 0 6px'}}
              placeholder={getItemPlaceholder(item)}
              value={areaUnit === "m2" ? areaSizeM2 : areaSizeSqft}
              onChange={(val) => {
                if (typeof(val) === "number") {
                  if (areaUnit === "m2") {
                    setAreaSizeM2(val)
                    // @ts-ignore
                    setAreaSizeSqft(val * 10.76)
                    setValue(item.id, Math.round(val))
                  } else {
                    // @ts-ignore
                    setAreaSizeM2(val / 10.76)
                    setAreaSizeSqft(val)
                    // @ts-ignore
                    setValue(item.id, Math.round(val / 10.76))
                  }
                } else {
                  setAreaSizeM2(null)
                  setAreaSizeSqft(null)
                  setValue(item.id, "")
                }
              }}
            />
            <SelectStyled
              style={{width: '35%'}}
              value={areaUnit}
              onChange={(value: any) => setAreaUnit(value)}
              >
              <Select.Option key={"m2"} value="m2">m2</Select.Option>
              <Select.Option key={"sqft"} value="sqft">sqft</Select.Option>
            </SelectStyled>
          </Input.Group>}
        {(_.isNil(item.questionId) && item.id === 3) &&
          <AutoCompleteStyled
            value={yearBuilt}
            options={propertyYearOptions}
            placeholder={getItemPlaceholder(item)}
            filterOption={(inputValue: any, option: any) =>
              option!.value.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
            }
            onChange={(val: any) => {
              if (!isNaN(propertyYearConversion(val)) || val === "") {
                setYearBuilt(val)
                setValue(item.id, propertyYearConversion(val))
              }
            }}
            onBlur={() => {setYearBuilt(propertyYearReversedConversion(yearBuilt))}}
          />
        }
        {(_.isNil(item.questionId) && item.id !== 2 && item.id !== 3) &&
        <InputNumberStyled
            maxLength={10}
            precision={0}
            min={0}
            placeholder={getItemPlaceholder(item)}
            value={getValue(item.id)}
            onChange={(val) => setValue(item.id, val)}
        />}

        {!_.isNil(item.questionId) &&
        <Select
            placeholder={getItemPlaceholder(item)}
            bordered={false}
            getPopupContainer={trigger => trigger.parentElement}
            style={{
              width: "100%",
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "6px",
              color: "rgb(107,113,133)",
            }}
            onChange={(val) => setAnswerSelect(item.id, val.toString())}
            defaultValue={getValueForSelect(item.id)}
        >
          {questions.filter(question => question.id === item.questionId).map(question => question.answers.slice()
            .sort((a1: PropertyQuestionAnswer, a2: PropertyQuestionAnswer) => a1.answerOrder - a2.answerOrder)
            .map((answer: PropertyQuestionAnswer) =>
            <Select.Option key={answer.id} value={answer.id}>{answer.answer}</Select.Option>
          ))}
        </Select>
        }
      </Form.Item>
    </InputWrapper>

  return (
    <Drawer
      closeIcon={false}
      width={window.innerWidth > 1024 ? "55%" : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => {
        if (formFieldsChanged) {
          confirmCloseUnsaved();
          return;
        }
        dispatch(getAllImprovements(propertyId));
        toggleDrawerOpen(false);
        form.resetFields();
      }}
      headerStyle={{
        backgroundColor: "#ebf9ff",
        margin: 0,
        padding: "2rem 2rem",
      }}
      title={
        <>
          <section
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.5rem",
            }}
          >
            <InventoriesIconWrapper>
              <MyPropertyIcon className={"my-property-theme-icon"} />{" "}
              <span style={{ paddingRight: "0.25rem", fontSize:"0.75rem" }}>MY PROPERTY</span>
            </InventoriesIconWrapper>
            <ButtonsWrapper>
              <CloseButton
                size={"large"}
                onClick={() => {
                  toggleDrawerOpen(false);
                  form.resetFields();
                }}
              >
                {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
              </CloseButton>
            </ButtonsWrapper>
          </section>
          <PropertyDrawerTitle>Property details</PropertyDrawerTitle>
        </>
      }
    >
      <Form
        form={form}
        name="managePropertyDetails"
        layout="vertical"
        size={"large"}
        onFinish={handleManageDetails}
      >
        {detailsToDisplay.map((group:any) =>
        <>
          <div
            style={{ display: "flex", columnGap: "1rem", fontSize: "1.125rem", fontWeight: 600, color: "#2A80E1" }}
          >
            <CategoryNumber>{group.groupOrder}</CategoryNumber>
            {group.groupName}
          </div>
          <section
            style={{
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "10px",
              padding: "1rem",
              margin: "1rem 0",
            }}
          >
            {group.items.map((itemPair:any) => <InputsWrapper>
              {renderQuestion(itemPair[0])}
              {itemPair.length > 1 && renderQuestion(itemPair[1])}
            </InputsWrapper>)}
          </section>
        </>
        )}
        <SaveButtonWrapper>
          <SaveButton
            style={{
              display: "flex",
              alignItems: "center",
            }}
            icon={saveDisabled ? <Spin indicator={<LoadingOutlined style={{color: "green", fontSize: 16}} spin/>}/> : <SaveIconStyled />}
            type={"primary"}
            htmlType={"submit"}
            //disabled={!formFieldsChanged}
            loading={saveDisabled}
          >
            Save details
          </SaveButton>
        </SaveButtonWrapper>
      </Form>
    </Drawer>
  );
};

const SaveIconStyled = styled(SaveIcon)`
  padding-right: 0.25rem;
  width: 22px;
  height: 22px;
`;

const InventoriesIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

// const InputWithSuffix = styled.div`
//   margin: auto;
//   position: relative;
//   display: inline-block;
//   width: 100%;
//   .suffix {
//     position: absolute;
//     top: 0.5rem;
//     right: 1rem ;
//   }
// `;

const AutoCompleteStyled = styled(AutoComplete)`
  .ant-select-selector {
      border: 1px solid #dae0e6!important;
      border-radius: 6px!important;
    }
`

const SelectStyled = styled(Select)`
  .ant-select-selector {
    height: 40px!important;
    border-radius: 0 6px 6px 0!important;
  }
`

export default PropertyDetailsDrawer;
