import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TMarkNewsAsRead = {
  propertyId: Guid;
  newsId: Guid;
  markAsRead: boolean;
};

export const markNewsAsRead = (payload: TMarkNewsAsRead) => {
  const { newsId, propertyId, markAsRead } = payload;
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/news/${newsId}/markAsRead?markAsRead=${markAsRead}`);

  return apiPost<any, TMarkNewsAsRead>(url);
};
