import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunkMiddleware from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import throttle from "lodash/throttle";
import { Modal } from "antd";

import rootReducer from "./app/reducers";
import { loadState, saveState } from "./localStorage";

export const history = createBrowserHistory();

const composeEnhancer: typeof compose =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

history.listen(() => {
  Modal.destroyAll();
});

const store = createStore(
  rootReducer(history),
  loadState(),
  composeEnhancer(
    applyMiddleware(sagaMiddleware, thunkMiddleware, routerMiddleware(history))
  )
);

store.subscribe(
  throttle(() => {
    const { auth, properties } = store.getState();
    saveState({ auth, properties });
  }, 200)
);

export default store;
