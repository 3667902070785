import React, {useEffect, useState} from "react";
import AddNewRecordDrawer from "../spaces/AddNewRecordDrawer";
import {
  ActionButtonGreen,
  ActionButtonWhite,
  DrawerFooter,
  DrawerStyled, EditDrawerHeader, paginationButtons,
  PaginationStyled
} from "../../screens/components";
import {ReactComponent as EditIcon} from "../../../images/spaces/EditIcon.svg";
import LinkedItemsHeader from "../spaces/LinkedItemsHeader";
import {ReactComponent as LinkIcon} from "../../../images/spaces/LinkNewIcon.svg";
import AddLinkedItem from "../spaces/AddLinkedItem";
import LinkedItemsList from "../spaces/LinkedItemsList";
import {useDispatch, useSelector} from "react-redux";
import {getContactsSelector, getPropertyIdSelector} from "../../selectors";
import {getContactById} from "../../../api-wrapper/contacts/getContactById";
import styled from "styled-components";
import {ReactComponent as EmptyImagePreview} from "../../../images/EmptyImagePreview.svg";
import {ReactComponent as DrawerCloseIcon} from "../../../images/DrawerCloseIcon.svg";
import {getAllContacts} from "../../actions/contacts";
import {LoadingOutlined} from "@ant-design/icons";

type Props = {
  contactId: any;
  setContactId?: (arg: any) => void | null;
  readonly?: boolean | false;
  isOpen: boolean;
  toggleDrawerOpen: (arg: boolean) => void;
  toggleLinkDrawerOpen: (arg: boolean) => void;
  setLinkedContactId: (arg: any) => void;
  setPresetLinkType: (arg: string) => void;
  refreshParent?: any;

  toggleViewWorkDrawer: (arg: boolean) => void;
  setViewWorkId: (arg: any) => void;
  toggleWorkDrawer: (arg: boolean) => void;
  setEditWorkData: (arg: any) => void;
  toggleExpenseDrawer: (arg: boolean) => void;
  setEditExpenseData: (arg: any) => void;
  toggleInventoryDrawer: (arg: boolean) => void;
  setEditInventoryData: (arg: any) => void;
  toggleContactDrawer: (arg: boolean) => void;
  setEditContactData: (arg: any) => void;
  toggleNoteDrawer: (arg: boolean) => void;
  setEditNoteData: (arg: any) => void;
  toggleTaskDrawer: (arg: boolean) => void;
  setEditTaskData: (arg: any) => void;
  setAttachmentId: (arg: any) => void;
  toggleViewFileDrawer: (arg: boolean) => void;
  setEditSpaceId: (arg: any) => void;
  toggleEditSpaceDrawer: (arg: boolean) => void;
}

const ViewContactDrawer = (props: Props) => {
  const {contactId, setContactId, isOpen, toggleDrawerOpen, toggleLinkDrawerOpen, setLinkedContactId, toggleEditSpaceDrawer,
    toggleWorkDrawer, toggleContactDrawer, toggleExpenseDrawer, toggleInventoryDrawer, toggleNoteDrawer, setEditWorkData,
    setEditContactData, setEditExpenseData, setEditInventoryData, setEditNoteData, readonly, setPresetLinkType, setEditSpaceId,
    toggleTaskDrawer, setEditTaskData, setViewWorkId, toggleViewWorkDrawer, setAttachmentId, toggleViewFileDrawer, refreshParent} = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const contacts = useSelector(getContactsSelector);

  const [searchQuery, setSearchQuery] = useState("");
  const [contact, setContact] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [contactLinks, setContactLinks] = useState<any>([]);
  const [isNewRecordDrawerOpen, toggleNewRecordDrawer] = useState(false);
  const [isContactLoaded, toggleContactLoaded] = useState(false);

  const refreshContact = () => {
    toggleContactLoaded(false);
    getContactById(propertyId, contactId).then((res: any) => {
      setContactLinks([...res.contactWorks, ...res.expenses,   ...res.attachments, ...res.notes, ...res.payedExpenses,
        ...res.recurringExpenses, ...res.reminders, ...res.tasks, ...res.contacts, ...res.inventories, ...res.spaces ])
      toggleContactLoaded(true);
    })
    refreshParent && refreshParent()
  }

  useEffect(() => {
    if (contactId) {
      setLinkedContactId(contactId);
      setContact(contacts.content.filter(item => item.contactId === contactId)[0]);
      refreshContact();
    }
  }, [contactId, contacts])

  const filteredItems = () => contactLinks.filter((item: any) => searchQuery ?
    (item.name?.toLowerCase().includes(searchQuery.toLowerCase()) || item.title?.toLowerCase().includes(searchQuery.toLowerCase())): true)

  const getItems = () => filteredItems()
    .filter((currentValue: any, index: any) => index >= (page - 1) * 10 && index < page * 10);

  const closeDrawer = () => {
    toggleDrawerOpen(false);
    setContactLinks([]);
    setContactId && setContactId(null);
    setPage(1);
    dispatch(getAllContacts(propertyId));
  }

  const toggleEditDrawer = () => {
    toggleContactDrawer(true);
    setEditContactData({data: contact});
    closeDrawer();
  }

  useEffect(() => {
    if (getItems().length === 0 && page !== 1) {
      setPage(page - 1)
    }
  }, [getItems()])

  return <>
    <AddNewRecordDrawer
      isOpen={isNewRecordDrawerOpen}
      toggleDrawerOpen={toggleNewRecordDrawer}
      parentId={contact ? contact.contactId : null}
      parentType={"contact"}
      refreshParent={() => dispatch(getAllContacts(propertyId))}
    />
    <ViewContactDrawerStyled
      style={{zIndex: 10}}
      push={{distance: "32px"}}
      closeIcon={null}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "100%" : "85%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      visible={isOpen}
      onClose={() => closeDrawer()}
      title={<>
        <EditDrawerHeader>
          Contact
          <div style={{display: "flex", gap: "0.5rem", alignItems: "center"}}>
            <EditIcon style={{cursor: "pointer"}} onClick={() => toggleEditDrawer()} />
            <DrawerCloseIcon style={{cursor: "pointer"}} onClick={() => closeDrawer()} />
          </div>
        </EditDrawerHeader>
        {contact && <ContactDescription>
          <div onClick={() => toggleEditDrawer()}>
            <div className={"photo-circle"}
                 style={{backgroundImage: contact.imageThumbnail ? `url(${contact.imageThumbnail})` : "none"}}>
              {!contact.imageThumbnail && <EmptyImagePreview/>}
            </div>
            <div className={"photo-text"}>Upload Photo</div>
          </div>
          <div className={"description-rows"}>
            <div className={"row-wrapper"}>
              <div className={"row-title"}>Contact name</div>
              <div className={"row-description"} style={{fontWeight: 600}}>{contact.name} {contact.surname}</div>
            </div>
            <div className={"row-wrapper"}>
              <div className={"row-title"}>Phone</div>
              <div className={"row-description"}>{contact.phone ? contact.phone : "-"}</div>
            </div>
            <div className={"row-wrapper"}>
                <div className={"row-title"}>Email</div>
                <div className={"row-description"}>{contact.email ? contact.email : "-"}</div>
            </div>
            {contact.notes && <div className={"row-wrapper"}>
                <div className={"row-title"}>Notes</div>
                <div className={"row-description"}>{contact.notes}</div>
            </div>}
            {contact.emergency && <div className={"row-wrapper"}>
                <div className={"row-title"} style={{color:"red"}}>Emergency contact</div>
            </div>}
          </div>
        </ContactDescription>}
        {contactLinks.length > 0 && <LinkedItemsHeader
            title={"Linked items"}
            parentId={contactId}
            parentType={"contact"}
            toggleLinkDrawerOpen={toggleDrawerOpen}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            toggleAddNewRecordDrawer={toggleNewRecordDrawer}
            refreshParent={refreshContact}
            showFileUploadButton={true}
        />}
      </>}
      footer={<DrawerFooter>
        {window.innerWidth <= 768 && <ActionButtonWhite onClick={() => closeDrawer()}>Cancel</ActionButtonWhite>}
        {!readonly && <ActionButtonGreen onClick={() => toggleLinkDrawerOpen(true)}><LinkIcon /> Add link</ActionButtonGreen>}
      </DrawerFooter>}
    >
    {!isContactLoaded ?
      <LoadingOutlined style={{ fontSize: 24 }} spin />
      : <>
      {contact && contactLinks.length === 0 && !readonly && <AddLinkedItem
          toggleLinkDrawerOpen={toggleLinkDrawerOpen}
          toggleNewRecordDrawer={toggleNewRecordDrawer}
          setPresetLinkType={setPresetLinkType}
          excludeTypes={[]}
          parentId={contact.contactId}
          parentType={"contact"}
          refreshParent={refreshContact}
      />}
      {contact && contactLinks.length > 0 && <LinkedItemsList
          parentId={contact.contactId}
          parentType={"contact"}
          items={getItems()}
          searchQuery={searchQuery}
          isSelectionMode={false}
          toggleWorkDrawer={toggleWorkDrawer}
          setEditWorkData={setEditWorkData}
          toggleExpenseDrawer={toggleExpenseDrawer}
          setEditExpenseData={setEditExpenseData}
          toggleInventoryDrawer={toggleInventoryDrawer}
          setEditInventoryData={setEditInventoryData}
          toggleContactDrawer={toggleContactDrawer}
          setEditContactData={setEditContactData}
          toggleNoteDrawer={toggleNoteDrawer}
          setEditNoteData={setEditNoteData}
          refreshParent={refreshContact}
          setEditSpaceId={setEditSpaceId}
          toggleSpaceDrawer={toggleEditSpaceDrawer}
          toggleTaskDrawer={toggleTaskDrawer}
          setEditTaskData={setEditTaskData}
          setViewWorkId={setViewWorkId}
          toggleViewWorkDrawer={toggleViewWorkDrawer}
          setAttachmentId={setAttachmentId}
          toggleViewFileDrawer={toggleViewFileDrawer}
      />}
      {filteredItems().length > 10 && <PaginationStyled
          className={filteredItems().length < 10 ? "single-page-pagination" : ""}
          onChange={(page) => setPage(page)}
          simple={window.innerWidth < 1024}
          showSizeChanger={false}
          itemRender={paginationButtons}
          defaultCurrent={1}
          current={page}
          total={filteredItems().length}
          defaultPageSize={10}
      />}
      </>}
    </ViewContactDrawerStyled>
  </>
}

const ViewContactDrawerStyled = styled(DrawerStyled)`
  .ant-drawer-body {
    margin: 0.5rem 0 1.5rem 0!important;
  }
`

export const ContactDescription = styled.div`
  border-bottom: 1px solid #e6e6e6;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #9CA4AE;
  width: 100%;
  display: flex;
  gap: 2.5rem;
  padding: 1.5rem 0;
  margin-bottom: 1.5rem;
  align-items: flex-start;
  
  .description-rows {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    height: 7rem;
    justify-content: flex-end;
  }
  
  .row-wrapper {
    display: flex;
    width: 100%;
  }
  
  .row-title { 
    font-weight: 600; 
    width: 40%; 
    color: #9CA4AE; 
  }
  
  .row-description { 
    width: 60%; 
    color: #666F7A;
  }
  
  .photo-circle {
    flex-shrink: 0;
    width: 7rem;
    height: 7rem;
    background: #F5F6FA;
    border-radius: 100%;
    
    background-repeat: no-repeat;
    background-position: center center; 
    background-size: cover;
    
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .photo-text {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #21272B;
    width: 100%;
    text-align: center;
    padding-top: 0.5rem;
    cursor: pointer;
  }
`

export default ViewContactDrawer;