import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import { getInsurances, TGetInsurancesResponse } from "../../api-wrapper/insurance/getInsurances";
import {Guid} from "guid-typescript";
import _ from "lodash";

export const getInsurancesInitAction = createAction("insurances/GET_INSURANCES_INIT")();
export const getInsurancesSuccessAction = createAction(
  "insurances/GET_INSURANCES_SUCCESS"
)<TGetInsurancesResponse>();
export const getInsurancesFailureAction = createAction("insurances/GET_INSURANCES_FAILURE")();

export type TGetInsurancesInitAction = ActionType<typeof getInsurancesInitAction>;
export type TGetInsurancesSuccessAction = ActionType<typeof getInsurancesSuccessAction>;
export type TGetInsurancesFailureAction = ActionType<typeof getInsurancesFailureAction>;

export type TGetInsurancesActions =
  | TGetInsurancesInitAction
  | TGetInsurancesSuccessAction
  | TGetInsurancesFailureAction;

export type TGetInsurancesThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetInsurancesActions>;

export const getAllInsurances: TGetInsurancesThunkActionType = (propertyId: Guid) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getInsurancesInitAction());
  return getInsurances(propertyId)
    .then((payload) => {
      let insurances = _.orderBy(payload, ['effectivePeriod'], ['desc'])
      return dispatch(getInsurancesSuccessAction(insurances));
    })
    .catch(() => dispatch(getInsurancesFailureAction()));
};