import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";
import { TRootState } from "../reducers";
import { TPropertyPreferences } from "../reducers/propertyPreferences";

export const getPropertyPreferencesInitAction = createAction(
  "properties/GET_PROPERTY_PREFS_INIT"
)();
export const setPropertyPreferencesAction = createAction(
  "propertyId/SET_PROPERTY_PREFS"
)<TPropertyPreferences>();

export type TGetPropertyPreferencesInitAction = ActionType<
  typeof getPropertyPreferencesInitAction
  >;
export type TSetPropertyPreferencesAction = ActionType<
  typeof setPropertyPreferencesAction
  >;

export type TGetPropertyPreferencesActions =
  | TSetPropertyPreferencesAction
  | TGetPropertyPreferencesInitAction;

export type TGetPropertyPreferencesThunkActionType = (propertyPreferences: TPropertyPreferences) => ThunkAction<
  void,
  TRootState,
  null,
  TGetPropertyPreferencesActions
  >;

export const setPropertyPreferences: TGetPropertyPreferencesThunkActionType = (propertyPreferences: TPropertyPreferences) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  return dispatch(setPropertyPreferencesAction(propertyPreferences));
};
