import React from "react";
import {LinkedItemTag} from "./LinkedItem";

type Props = {
  item: any;
}

const ItemLinks = (props: Props) => {
  const { item } = props;

  return <>
    {item["worksCount"] > 0 && <LinkedItemTag className={"work"}>
      {item["worksCount"]} Project{item["worksCount"] > 1 && "s"}
    </LinkedItemTag>}
    {item["expensesCount"] > 0 && <LinkedItemTag className={"cost"}>
      {item["expensesCount"]} Expense{item["expensesCount"] > 1 && "s"}
    </LinkedItemTag>}
    {item["inventoriesCount"] > 0 && <LinkedItemTag className={"content"}>
      {item["inventoriesCount"]} Content{item["inventoriesCount"] > 1 && "s"}
    </LinkedItemTag>}
    {item["contactsCount"] > 0 && <LinkedItemTag className={"contact"}>
      {item["contactsCount"]} Contact{item["contactsCount"] > 1 && "s"}
    </LinkedItemTag>}
    {item["attachmentsCount"] > 0 && <LinkedItemTag className={"file"}>
      {item["attachmentsCount"]} Document{item["attachmentsCount"] > 1 && "s"}
    </LinkedItemTag>}
    {item["notesCount"] > 0 && <LinkedItemTag className={"note"}>
      {item["notesCount"]} Note{item["notesCount"] > 1 && "s"}
    </LinkedItemTag>}
    {item["spacesCount"] > 0 && <LinkedItemTag className={"space"}>
      {item["spacesCount"]} Space{item["spacesCount"] > 1 && "s"}
    </LinkedItemTag>}

    {item["tasksCount"] > 0 && <LinkedItemTag className={"task"}>
      {item["tasksCount"]} Task{item["tasksCount"] > 1 && "s"}
    </LinkedItemTag>}
    {item["recurringExpensesCount"] > 0 && <LinkedItemTag className={"recurringExpense"}>
      {item["recurringExpensesCount"]} Recurring expense{item["recurringExpense"] > 1 && "s"}
    </LinkedItemTag>}
    {item["remindersCount"] > 0 && <LinkedItemTag className={"reminder"}>
      {item["remindersCount"]} Reminder{item["remindersCount"] > 1 && "s"}
    </LinkedItemTag>}

    {item["relatedUpkeepsCount"] > 0 && <LinkedItemTag className={"note"}>
      {item["relatedUpkeepsCount"]} Related upkeep{item["relatedUpkeepsCount"] > 1 && "s"}
    </LinkedItemTag>}
    {item["attachmentWorksCount"] > 0 && <LinkedItemTag className={"work"}>
      {item["attachmentWorksCount"]} Project{item["attachmentWorksCount"] > 1 && "s"}
    </LinkedItemTag>}
    {item["attachmentExpensesCount"] > 0 && <LinkedItemTag className={"cost"}>
      {item["attachmentExpensesCount"]} Expense{item["attachmentExpensesCount"] > 1 && "s"}
    </LinkedItemTag>}
    {item["attachmentContentsCount"] > 0 && <LinkedItemTag className={"content"}>
      {item["attachmentContentsCount"]} Content{item["attachmentContentsCount"] > 1 && "s"}
    </LinkedItemTag>}
    {item["attachmentContactsCount"] > 0 && <LinkedItemTag className={"contact"}>
      {item["attachmentContactsCount"]} Contact{item["attachmentContactsCount"] > 1 && "s"}
    </LinkedItemTag>}
    {item["attachmentNotesCount"] > 0 && <LinkedItemTag className={"note"}>
      {item["attachmentNotesCount"]} Note{item["attachmentNotesCount"] > 1 && "s"}
    </LinkedItemTag>}
    {item["attachmentSpacesCount"] > 0 && <LinkedItemTag className={"space"}>
      {item["attachmentSpacesCount"]} Space{item["attachmentSpacesCount"] > 1 && "s"}
    </LinkedItemTag>}
  </>
}

export default ItemLinks