import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetExpensesResponse } from "../../api-wrapper/expenses/getExpenses";
import {
  getConfirmedExpensesFailureAction,
  getConfirmedExpensesInitAction,
  getConfirmedExpensesSuccessAction,
  TGetConfirmedExpensesActions,
} from "../actions/confirmedExpenses";
import {expensesInitial} from "../components/helper";

export type TConfirmedExpenses = {
  fetchingStatus: TFetchingStatus;
  content: TGetExpensesResponse;
};

const initialState: TConfirmedExpenses = {
  content: expensesInitial,
  fetchingStatus: TFetchingStatus.Defined,
};

export const confirmedExpensesReducer = createReducer<TConfirmedExpenses, TGetConfirmedExpensesActions>(
  initialState
)
  .handleAction(getConfirmedExpensesInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getConfirmedExpensesSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getConfirmedExpensesFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
