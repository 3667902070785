import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";


export const showLast = (propertyId: Guid, maintenanceId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/alerts/maintenance/${maintenanceId}`);

  return apiGet<any | []>(url);
};
