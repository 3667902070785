import { createUrl } from "../apiCall";
import EnvConfig from "../../config";
import axios from "axios";

export type TSignUpCodeConfirmRequest = {
  token: string;
  referral: string | null;
};

export const registrationCodeConfirmation = async (payload: TSignUpCodeConfirmRequest) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/user/registrationConfirm`);

  const uninterceptedAxios = axios.create()

  let {data, headers} = await uninterceptedAxios.post<{}, any>(url, payload);

  return headers && headers.authorization
    ? {...data, accessToken: headers.authorization.split(":")[1], subscriptionOwner: true}
    : {...data, accessToken: " "};
};
