import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TGetContactsResponse} from "../contacts/getContacts";
import {TTag} from "../tags/addNewTag";
import {TGetWorksResponse} from "../works/getWorks";

export type TGetAttachmentByIdResponse = {
  attachmentId: Guid;
  attachmentNotes: Array<{
    recordId: Guid;
    title: string;
    message: string;
    createdDate: string;
    attachment: {
      attachmentId: Guid;
      propertyId: Guid;
      parentId: Guid;
      parentType: string;
      name: string;
      size: number;
    };
  }>;
  attachments: Array<{
    attachmentId: Guid;
    propertyId: Guid;
    parentId: Guid;
    parentType: string;
    storageKey: string;
    name: string;
    size: number;
    path: string;
  }>;
  expenses: Array<{
    name: string;
    propertyId: Guid;
    expenseId: Guid;
    payee: Guid;
    totalCost: number;
    paymentDate: string;
    isTaxDeductible: boolean;
    parentId: Guid;
    parentType: string;
    type: Guid;
  }>;
  inventories: Array<{
    propertyId: Guid;
    brand: string;
    model: string;
    installedOrPurchaseDate: moment.Moment | null;
    warrantyExpiration: moment.Moment | null;
    inventoryType: string;
    purchaseCost: number;
    insuredItems: boolean;
    name: string;
    id: Guid;
  }>;
  contacts: TGetContactsResponse;
  tags: Array<TTag>;
  works: Array<TGetWorksResponse>;
};

export const getAttachmentById = (propertyId: Guid, attachmentId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/attachmentDetails/${attachmentId}`,
    {}
  );

  return apiGet<TGetAttachmentByIdResponse>(url);
};
