import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TTag} from "../tags/addNewTag";

export type TGetWorksResponse = Array<{
  completedDate: string;
  dueDate: string;
  isComplete: boolean;
  name: string;
  propertyId: Guid;
  recurring: string;
  type: string;
  workId: Guid;
  workTotalExpense: number;
  relatedImprovementId: string;
  notes: string;
  reminder: {
    recordId: Guid;
    title: string;
    message: string;
    date: number;
    sendTo?: Array<string>;
    parentId?: Guid;
    parentName?: string;
    parentType?: string;
    attachment: {
      attachmentId: Guid;
      propertyId: Guid;
      parentId: Guid;
      parentType: Guid;
      name: string;
      size: number;
    };
  } | {};
  tags: Array<TTag>;
}>;

export const getWorks = (propertyId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/works`);

  return apiGet<TGetWorksResponse | []>(url, {}, propertyId);
};
