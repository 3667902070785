import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

type TEditTenantRequest = {
  tenancyId: Guid;
  propertyId: Guid;
  startDate?: string | null;
  endDate?: string | null;
  rentalPayment?: number | null;
  tenancyContactId?: Guid | null;
  agentFee?: number | null;
  agentContact?: Guid | null;
  propertyManagementFee?: number | null;
  propertyManagementContact?: Guid | null;
};

export const editTenant = (payload: TEditTenantRequest) => {
  const { propertyId, tenancyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/tenancy/${tenancyId}`
  );

  return apiPut<
    {
      startDate: string;
      endDate: string;
      rentalPayment: number;
      tenancyContactId: Guid;
      agentFee: number;
      agentContact: number;
      propertyManagementFee: number;
      propertyManagementContact: Guid;
    },
    {}
  >(url, payload);
};
