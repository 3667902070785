import React, {useEffect, useState} from "react";

import {ReactComponent as LinkedIcon} from "../../../images/records/LinkedIcon.svg";
import {ReactComponent as FilesRecord} from "../../../images/records/FilesRecord.svg";
import {ReactComponent as FilesIcon} from "../../../images/records/FilesIcon.svg";
import {useDispatch, useSelector} from "react-redux";
import {getAttachmentsSelector, getPropertyIdSelector, getUserProfileSelector} from "../../selectors";
import {
  AlertDot,
  AlertOverlay, AlertWrapper,
  CardContentsItem,
  CardContentsWrapper,
  CardTitle, ErrorDot,
  RecordsCard
} from "./SpaceRecordsCard";
import {useHistory} from "react-router-dom";
import UploadFile from "../attachments/UploadFile";
import {getAllAttachments} from "../../actions/attachments";
import {Dropdown, Modal, notification} from "antd";
import {getCurrentUserProfile} from "../../actions/getUserProfile";
import {getUserWarnings} from "../../../api-wrapper/attachments/getUserWarnings";
import {dismissUserWarnings} from "../../../api-wrapper/attachments/dismissUserWarnings";
import {dismissWarnings, showFileUploadErrorPopup} from "../navigation/MenuLivlet";

const FileRecordsCard = () => {
  const files = useSelector(getAttachmentsSelector);
  const userProfile = useSelector(getUserProfileSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const history = useHistory();
  const dispatch = useDispatch();

  const [filesCount, setFilesCount] = useState(0);
  const [linkedCount, setLinkedCount] = useState(0);
  const [newFilesCount, setNewFilesCount] = useState(0);
  const [fileErrors, setFileErrors] = useState([])

  const refreshWarnings = () => {
    getUserWarnings().then((res: any) => {
      setFileErrors(res.filter((warning: any) => warning.dataType === "attachment"))
    })
  }

  const showCreatedPopup = () => {
    notification.success({
      message: `A file has been added successfully!`,
      description: <>You may now navigate to the <a onClick={() => history.push(`/documents`)}>Documents</a> page to view it.</>,
      placement: "topRight",
      duration: 5
    })
  }

  useEffect(() => {
    setFilesCount(files.content.length)
    setLinkedCount(files.content.filter(file => file.attachmentLinksCount > 0).length)
    setNewFilesCount(files.content.filter(file =>
      file.source === "email" && userProfile.content.previousLogin < file.uploadedDate).length)
    refreshWarnings()
  }, [propertyId, files])

  return <RecordsCard
    className={"file-records"}
    onClick={() => history.push("/documents")}
  >
    <CardTitle>
      <AlertWrapper>
        {newFilesCount > 0 && <Dropdown
            placement={"topCenter"}
            overlay={<AlertOverlay>{newFilesCount} Uploaded documents whilst away</AlertOverlay>}>
            <AlertDot>{newFilesCount}</AlertDot>
        </Dropdown>}
        {fileErrors.length > 0 && <Dropdown
            placement={"topCenter"}
            overlay={<AlertOverlay>{newFilesCount} Uploaded documents whilst away</AlertOverlay>}>
            <ErrorDot onClick={(e) => {
              e.stopPropagation();
              showFileUploadErrorPopup(fileErrors, dismissWarnings, refreshWarnings);
            }}>{fileErrors.length}</ErrorDot>
        </Dropdown>}
        <FilesRecord />
      </AlertWrapper>
      Documents
      <span onClick={(e) => e.stopPropagation()}>
        <UploadFile
          fileList={[]}
          refreshParent={() => {
            dispatch(getAllAttachments(propertyId))
            showCreatedPopup()
          }}
          singleAttachment={false}
          parentId={null}
          parentType={null}
          propertyId={propertyId}
          uploadImmediately={false}
          isGlobalUpload={true}
          uploadOrigin={"record-page"}
        />
      </span>
    </CardTitle>
    <CardContentsWrapper>
      <CardContentsItem className={"fill"}>
        <span className={"item-count"}><FilesIcon /> {filesCount}</span> Documents
      </CardContentsItem>
      {/*<CardContentsItem className={"fill"}>*/}
      {/*  <span className={"item-count"}><LinkedIcon /> {linkedCount}</span> Linked*/}
      {/*</CardContentsItem>*/}
    </CardContentsWrapper>
  </RecordsCard>
}

export default FileRecordsCard