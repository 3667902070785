import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import React, {ReactNode, useEffect, useState} from "react";
import {ReactComponent as SmallArrowDownIcon} from "../../../images/ArrowGreenDown.svg";
import {ReactComponent as SmallArrowUpIcon} from "../../../images/ArrowRedUp.svg";
import {ReactComponent as BigArrowDownIcon} from "../../../images/ArrowGreenDownBig.svg";
import {ReactComponent as ExpensesIcon} from "../../../images/ExpensesNew.svg";
import {numberWithCommas} from "../helper";
import {PlusOutlined} from "@ant-design/icons";
import messages from "../../screens/messages";
import {Button, Collapse, Dropdown, Menu} from "antd";
import {useIntl} from "react-intl";
import { Pie } from "ant-design-pro/lib/Charts";
import {getExpenseReport} from "../../actions/expenseReport";
import {getExpenseReportSelector, getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import ExpenseDrawer from "../expenses/ExpenseDrawer";
import {getExpenseTypes} from "../../../api-wrapper/expenses/getExpenseTypes";
import {useHistory} from "react-router-dom";
import _ from "lodash";
import {getCurrencySign} from "../../screens/helpers";

const {Panel} = Collapse;

type PropsDisplayType = {
  typeName: string,
  icon: ReactNode,
  lineItems: Array<any>
}

const ExpensesReport = () => {
  const {formatMessage: f} = useIntl();
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const expenseReport = useSelector(getExpenseReportSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);
  let history = useHistory();

  const [period, setPeriod] = useState("month");

  const [reportData, setReportData] = useState<any>([]);
  const [reportArray, setReportArray] = useState<any>([]);
  const [chartData, setChartData] = useState<Array<{ x: string, y: number }>>([]);
  const [totalThisPeriod, setTotalThisPeriod] = useState(0);
  const [totalPreviousPeriod, setTotalPreviousPeriod] = useState(0);

  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [editingExpenseData, setEditingExpenseData] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null)

  const [parentExpenseTypes, setParentExpenseTypes] = useState<any>({})
  const [expenseTypes, setExpenseTypes] = useState<any>({})

  useEffect(() => {
    dispatch(getExpenseReport({period}, propertyId));
  }, [period, propertyId]);

  useEffect(() => {
    getExpenseTypes(propertyId).then(res => {
      const entries = Object.entries(res?? {})
      let parentEntries: any = []
      let finalEntries: any = []

      entries.map((entry: any) => {
        let updatedEntry: any = []
        if (!entry[1].parentId) {
          updatedEntry.push(entry[1].name)
          updatedEntry.push(entry[1].id)
          parentEntries.push(updatedEntry)
        }
      })

      parentEntries.map((node: any) => {
        let childNodes: any = []
        entries.map((entry: any) => {
          let updatedEntry: any = []
          if (entry[1].parentId === node[1]) {
            updatedEntry.push(entry[1].name)
            updatedEntry.push(entry[1].id)
            childNodes.push(updatedEntry)
          }
      })
        finalEntries.push([node[0], Object.fromEntries(childNodes)])
      })

      setParentExpenseTypes(Object.fromEntries(parentEntries))
      setExpenseTypes(Object.fromEntries(finalEntries))
    })
  }, [propertyId])

  useEffect(() => {
    if (!_.isEmpty(parentExpenseTypes) && !_.isEmpty(expenseTypes)) {
      let types: Array<any>;
      let reportDataTmp: any = {};
      let chartDataTmp: Array<{x:string, y:number}> = [];
      let totalThisPeriodTmp = 0;
      let totalPreviousPeriodTmp = 0;

      for (let [parentCategory, subcategories] of Object.entries(expenseReport.content)) {
        types = new Array<any>();
        // @ts-ignore
        for (let [childCategory, childData] of Object.entries(subcategories)) {

          types.push({
            name: childCategory,
            parentId: parentExpenseTypes[parentCategory],
            childId: expenseTypes[parentCategory][childCategory],
            // @ts-ignore
            now: childData.now,
            // @ts-ignore
            previous: childData.previous
          });
        }
        reportDataTmp[parentCategory] = types;
        chartDataTmp.push({x: parentCategory, y: types.reduce((sum, { now }) => sum + now, 0)});
        totalThisPeriodTmp += types.reduce((sum, { now }) => sum + now, 0);
        totalPreviousPeriodTmp += types.reduce((sum, { previous }) => sum + previous, 0);
      }

      setReportData(reportDataTmp);
      setReportArray(Object.entries(reportDataTmp));
      setChartData(chartDataTmp);
      setTotalThisPeriod(totalThisPeriodTmp);
      setTotalPreviousPeriod(totalPreviousPeriodTmp);
    }
  }, [expenseReport, parentExpenseTypes, expenseTypes]);

  const selectPeriodMenu = () => {
    const handleMenuClick = () => {
      setPeriod(period==="month"?"taxYear":"month");
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="other">{period!=="month"?"current month":"this tax year"}</Menu.Item>
      </MenuStyled>
    );
  };

  const DisplayType = (props: PropsDisplayType) => {
    const {typeName, icon, lineItems} = props;
    if (!lineItems)
      return <></>;

    const sumNow = lineItems.reduce((sum, { now }) => sum + now, 0);

    const sumPrevious = lineItems.reduce((sum, { previous }) => sum + previous, 0);

    return (
      <CollapseStyled
        ghost
        expandIconPosition={"right"}
      >
        <Panel
          header={
            <ReportGroupWrapper>
              <div style={{fontSize: "1rem", fontWeight: 600, color: "#21272B"}}>
                {icon}
                {typeName}
              </div>
              <ReportGroupValues style={{justifyContent:"flex-end"}}>
                {/*style={{marginLeft: window.innerWidth>1600?"30%":"5%"}}*/}
                <ReportGroupCurrentSpend style={{justifyContent:"flex-end"}}>
                  {sumNow > sumPrevious
                    ? <SmallArrowUpIcon style={{marginRight: "0.75rem", marginBottom: "0"}}/>
                    : sumNow !== sumPrevious
                      ? <SmallArrowDownIcon style={{marginRight: "0.75rem", marginBottom: "0"}}/>
                      : ""
                  }
                  {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(sumNow)}
                </ReportGroupCurrentSpend>
                <ReportGroupCurrentSpend style={{width:"6rem", justifyContent:"flex-end"}}>
                  {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(sumPrevious)}
                </ReportGroupCurrentSpend>
              </ReportGroupValues>
            </ReportGroupWrapper>
          }
          key={typeName+"ExpenseReport"}
          showArrow={typeName !== "Other"}
        >
          {typeName !== "Other" && lineItems.map((item: any) => {
            return <ReportTypeWrapper>
              <div style={{fontSize: "0.9rem", fontWeight: 400}}>
                {icon}
                <span style={{color: '#1890ff', cursor: 'pointer'}} onClick={() => {
                  history.push(`/expenses?period=${period}&?type=${item.parentId}&category=${item.childId}`)
                }}>{item.name}</span>
              </div>
              <ReportTypeValues style={{justifyContent:"flex-end"}}>
                {/*marginLeft: window.innerWidth>1600?"35%":"10%",*/}
                <ReportTypeCurrentSpend style={{ justifyContent:"flex-end"}}>
                  {item.now > item.previous
                    ? <SmallArrowUpIcon style={{height: "0.8rem", marginRight: "0.75rem", marginBottom: "0"}}/>
                    : item.now !== item.previous
                      ? <SmallArrowDownIcon style={{height: "0.8rem", marginRight: "0.75rem", marginBottom: "0"}}/>
                      : ""
                  }
                  {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(item.now)}
                </ReportTypeCurrentSpend>
                <ReportTypeCurrentSpend style={{width:"6rem", justifyContent:"flex-end"}}>
                  {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(item.previous)}
                </ReportTypeCurrentSpend>
              </ReportTypeValues>
            </ReportTypeWrapper>
          })}
        </Panel>
      </CollapseStyled>
    );
  }

  return <ReportWrapper>
    <ExpenseDrawer
      isOpen={isExpenseDrawerOpen}
      toggleDrawerOpen={toggleExpenseDrawer}
      // @ts-ignore
      setEditingExpenseData={setEditingExpenseData}
      expense={editingExpenseData}
      refreshParent={() => {dispatch(getExpenseReport({period}, propertyId));}}
      invoiceData={invoiceData}
    />
    <ReportTitleWrapper>
      <ExpensesIcon className={"expenses-theme-icon"}/>
      <ReportTitle>
        Expenses
      </ReportTitle>
    </ReportTitleWrapper>
    <ReportWidget>
      <ReportWidgetTop>
        <ReportHeading>
          Show your expenditures for:
          <Dropdown trigger={["click", "hover"]} overlay={selectPeriodMenu}>
            <span style={{marginLeft:"0.5rem"}}>{period==="month"?"current month":"this tax year"}</span>
          </Dropdown>
        </ReportHeading>
        <ReportSummary>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <SummaryDetailsLabel>TOTAL THIS PERIOD</SummaryDetailsLabel>
            <SummaryDetailsNumber>
              {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(totalThisPeriod)}
            </SummaryDetailsNumber>
          </div>
          {" "}
          <div style={{display: "flex", flexDirection: "column"}}>
            <SummaryDetailsLabel>TOTAL PREVIOUS PERIOD</SummaryDetailsLabel>
            <SummaryDetailsNumber>{getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(totalPreviousPeriod)}</SummaryDetailsNumber>
          </div>
          {" "}
          <div style={{display: "flex", flexDirection: "column"}}>
            <SummaryDetailsLabel>DIFFERENCE BETWEEN PERIODS</SummaryDetailsLabel>
            <SummaryDetailsNumber>
              {totalThisPeriod > totalPreviousPeriod
                ? <SmallArrowUpIcon style={{marginRight: "0.75rem", marginBottom: "0"}}/>
                : totalThisPeriod !== totalPreviousPeriod
                  ? <BigArrowDownIcon style={{marginRight: "0.75rem", marginBottom: "0"}}/>
                  : ""
              }
              {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(Math.abs(totalPreviousPeriod - totalThisPeriod))}
            </SummaryDetailsNumber>
          </div>
        </ReportSummary>
        <ReportBody>
          <ReportTable>
            {(totalPreviousPeriod > 0 || totalThisPeriod > 0) &&
              <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                <SummaryDetailsLabel style={{marginRight: "4rem", fontSize: "0.875rem"}}>This
                    period</SummaryDetailsLabel>
                <SummaryDetailsLabel style={{fontSize: "0.875rem"}}>Previous period</SummaryDetailsLabel>
              </div>
            }

            {
              reportArray.map((reportLine: any) => <DisplayType
                typeName={reportLine[0]}
                icon={null}
                lineItems={reportData[reportLine[0]]}/>)
            }

          </ReportTable>
          <ReportGraph>
            {totalThisPeriod > 0 && (
              <Pie
                colors={["#7479E9", "#EFC45D", "#E974C1", "#6B7185"]}
                subTitle="Total Expenses"
                total={() => (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getCurrencySign(propertyPreferences.preferredCurrency)+numberWithCommas(chartData.reduce((pre, now) => now.y + pre, 0)),
                    }}
                  />
                )}
                data={chartData}
                valueFormat={val => <span dangerouslySetInnerHTML={{ __html: getCurrencySign(propertyPreferences.preferredCurrency)+numberWithCommas(Number.parseFloat(val)) }} />}
                height={294}
              />
            )}
          </ReportGraph>
        </ReportBody>
      </ReportWidgetTop>
      <ReportWidgetBottom>
        <ReportCallToAction>
          <div style={{display: "flex", alignItems: "center", height: "3rem"}}>
            <div style={{fontSize: "1.125rem", fontWeight: 600, lineHeight: "2rem"}}>Get a more accurate view
              of your property-related expenses
            </div>
            {/*<div style={{fontSize: "1rem", fontWeight: 400, lineHeight: "2rem"}}>Upload your invoices and bills</div>*/}
          </div>
          {/*<UploadFile*/}
          {/*  fileList={[]}*/}
          {/*  refreshParent={() => {}}*/}
          {/*  singleAttachment={false}*/}
          {/*  parentId={null}*/}
          {/*  parentType={null}*/}
          {/*  propertyId={propertyId}*/}
          {/*  uploadImmediately={false}*/}
          {/*  isGlobalUpload={true}*/}
          {/*  isInvoiceUpload={true}*/}
          {/*  isIndirectInvoiceUpload={true}*/}
          {/*  indirectUploadOrigin={"widget"}*/}
          {/*  setExpenseData={setEditingExpenseData}*/}
          {/*  setInvoiceData={setInvoiceData}*/}
          {/*  toggleExpenseDrawer={toggleExpenseDrawer}*/}
          {/*/>*/}
          <AddItemButton
            onClick={() => toggleExpenseDrawer(true)}
            size={"large"}
            icon={<PlusOutlined/>}
          >
            {f(messages.addExpensesBtn)}
          </AddItemButton>
        </ReportCallToAction>
      </ReportWidgetBottom>
    </ReportWidget>
  </ReportWrapper>;
}

const MenuStyled = styled(Menu)`
  border-right: 0;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-radius: 6px;

  .ant-menu-item-active {
    color: #6b7185 !important;
  }

  .ant-dropdown-menu-item {
    height: 1.5rem !important;
    padding-bottom: 2rem !important;
    color: #236EC2 !important;
    font-weight: 600 !important;
    font-size: 1.125rem !important;
  }
`;

export const ReportTitle = styled.div`
  padding-left: 0.5rem;
  font-weight: 600;
  font-size: 1.125rem;
  color: #21272B;
`;

export const ReportTitleWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
`;

export const ReportWidget = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReportWrapper = styled.div`
  @media (min-width: 1024px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 1024px) {
    padding: 1.3rem;
    padding-bottom: 1.5rem;
  }
`;

export const ReportWidgetTop = styled.div`
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
  padding: 1.5rem 1.5rem;
`;

export const ReportWidgetBottom = styled.div`
  background: #2A80E1;
  padding: 1rem 1.5rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const ReportHeading = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
`;

const SummaryDetailsLabel = styled.span`
  font-weight: 500;
  font-size: 0.75rem;
  color: #6B7185;
`;

const SummaryDetailsNumber = styled.h3`
  font-size: 2.125rem;
  font-weight: 700;
  color: 21272B;
  margin-top: 0.5rem;
  padding: 0 0.5rem;
  background-color: #F0F2F5;
  border-radius: 4px;
`;

const ReportSummary = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media (min-width: 1280px) {
    flex-direction: row;

    padding-top: 2rem;
    max-width: 1000px;
    & > *:not(last-child) {
      margin-right: 5rem;
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;

    padding-top: 2rem;
    max-width: 1000px;
    & > *:not(last-child) {
      margin-right: 1.5rem;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    padding-top: 1rem;
  }
`;

const CollapseStyled = styled(Collapse)`
  margin-top: 1rem;
  width: 100%;

  @media (min-width: 1024px) {
    .anticon {
      margin-top: 0.2rem;
    }
  }

  @media (max-width: 1024px) {
    .anticon {
      margin-top: 0.2rem;
      right: -5px !important;
    }
    .ant-collapse-header {
      padding-right: 1rem !important;
    }
  }

  .ant-collapse-header {
    padding-left: 0 !important;
    border-bottom: 1px solid #DAE0E6;
  }
`;

export const ReportBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const ReportGroupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 1.1rem;
  
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ReportGroupValues = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    width: 60%;
  }
  @media (min-width: 1024px) {
    width: 55%;
  }
`;

const ReportTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media (max-width: 1024px) {
    padding-top: 0.5rem;
  }

  @media (min-width: 1024px) {
    margin-right: 1rem;
    margin-left: 1.75rem;
    padding-top: 0.5rem;
  }  
`;

const ReportTypeValues = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    width: 60%;
  }
  @media (min-width: 1024px) {
    margin-right: 2.5rem;
    width: 55%;
  }
`;


const ReportGroupCurrentSpend = styled.div`
  color: #6B7185;
  font-weight: 600;
  // margin-right: 2rem;
  display: flex;
  align-items: center;
`;

// const ReportGroupPreviousSpend = styled.div`
//   color: #6B7185;
//   font-weight: 700;
//   font-size: 1.1rem;
//   // margin-right: 1rem;
//   padding: 0 0.5rem;
//   background-color: #EFF1F5;
//   border-radius: 4px;
// `;

const ReportTypeCurrentSpend = styled.div`
  color: #6B7185;
  font-weight: 500;
  font-size: 0.75rem;
  // margin-right: 2.8rem;
  display: flex;
  align-items: center;
`;

// const ReportTypePreviousSpend = styled.div`
//   color: #6B7185;
//   font-weight: 600;
//   font-size: 0.9rem;
//   // margin-right: 1rem;
//   padding: 0 0.5rem;
//   background-color: #EFF1F5;
//   border-radius: 4px;
// `;

const ReportTable = styled.div`
  @media (min-width: 1024px) {
    flex-basis: 50%;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ReportGraph = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  
  width: 300px;
  margin-left: 8rem;

  .pie-sub-title {
    font-size: 1.1rem !important;
    color: #21272B !important;
    font-weight: 600 !important;
  }
  .pie-stat {
    font-size: 1.7rem !important;
    color: #21272B !important;
    font-weight: 700 !important;
  }
`;

export const AddItemButton = styled(Button)`
  border: 1px solid #dae0e6;
  border-radius: 6px;
  font-weight: 600;
  background: #2A80E1;
  color: #fff;
  
  height: 3rem;
  :hover {
    border-color: #fff;
    background: #2A80E1;
    color: #fff;
  }

  @media (max-width: 1024px) {
    margin-top: 1rem;
  }
`;

export const ReportCallToAction = styled.div`
  display: flex;
  justify-content: space-between;
  color: #fff;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-end;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

export default ExpensesReport;
