import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {getMaintenances, TGetMaintenancesResponse} from "../../api-wrapper/maintenance/getMaintenances";
import _ from "lodash";
import {Guid} from "guid-typescript";

export const getMaintenancesInitAction = createAction("maintenances/GET_MAINTENANCES_INIT")();
export const getMaintenancesSuccessAction = createAction(
  "maintenances/GET_MAINTENANCES_SUCCESS"
)<TGetMaintenancesResponse>();
export const getMaintenancesFailureAction = createAction("maintenances/GET_MAINTENANCES_FAILURE")();
export const getMaintenancesResetAction = createAction(
  "maintenances/GET_MAINTENANCES_RESET"
)();

export type TGetMaintenancesResetAction = ActionType<typeof getMaintenancesResetAction>;
export type TGetMaintenancesInitAction = ActionType<typeof getMaintenancesInitAction>;
export type TGetMaintenancesSuccessAction = ActionType<typeof getMaintenancesSuccessAction>;
export type TGetMaintenancesFailureAction = ActionType<typeof getMaintenancesFailureAction>;

export type TGetMaintenancesActions =
  | TGetMaintenancesInitAction
  | TGetMaintenancesResetAction
  | TGetMaintenancesSuccessAction
  | TGetMaintenancesFailureAction;

export type TGetMaintenancesThunkActionType = (propertyId: Guid) => ThunkAction<void, TRootState, null, TGetMaintenancesActions>;

export const getAllMaintenances: TGetMaintenancesThunkActionType = (propertyId: Guid) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getMaintenancesInitAction());
  return getMaintenances(propertyId)
    .then((payload) => {
      return dispatch(getMaintenancesSuccessAction(_.orderBy(payload, ['title'], ['asc'])));
    })
    .catch(() => dispatch(getMaintenancesFailureAction()));
};
