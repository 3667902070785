import React, {useEffect, useState} from "react";

import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import {ReactComponent as ReturnIcon} from "../../../images/spaces/ReturnIcon.svg";
import {
  ActionButtonGreen,
  ActionButtonWhite,
  DrawerFooter,
  DrawerHeader,
  DrawerStyled
} from "../../screens/components";
import {Guid} from "guid-typescript";
import {Dropdown} from "antd";
import {ReactComponent as SelectPropertyArrow} from "../../../images/homepage-june/SelectPropertyArrow.svg";
import {ReactComponent as PlusIcon} from "../../../images/records/PlusIcon.svg";
import {assignAttachmentProperty} from "../../../api-wrapper/attachments/assignAttachmentProperty";
import {LoadingOutlined} from "@ant-design/icons";

type Props = {
  recordId: Guid;
  isOpen: boolean;
  toggleDrawerOpen: (arg: boolean) => void;
  refreshParent?: () => void;
  assignPropertyParent: (selectedPropertyId: Guid, recordId: Guid) => void;
}

const AssignPropertySmallDrawer = (props: Props) => {
  const {recordId, isOpen, toggleDrawerOpen, refreshParent, assignPropertyParent} = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);

  const [currentPropertyAddress, setCurrentPropertyAddress] = useState("My home");
  const [isSubmittingData, toggleSubmittingData] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState<any>(null);

  useEffect(() => {
    const currentProperty = properties.find((property) => property.propertyId === propertyId);
    if (!currentProperty)
      return;
    setCurrentPropertyAddress(currentProperty.address + (currentProperty.city ? `, ${currentProperty.city}` : "")
      + (currentProperty.zip ? ` ${currentProperty.zip}` : ""));
    setSelectedPropertyId(propertyId);
  }, [properties, propertyId])

  useEffect(() => {
    const currentProperty = properties.find((property) => property.propertyId === selectedPropertyId);
    if (!currentProperty)
      return;
    setCurrentPropertyAddress(currentProperty.address + (currentProperty.city ? `, ${currentProperty.city}` : "")
      + (currentProperty.zip ? ` ${currentProperty.zip}` : ""));
  }, [selectedPropertyId]);

  useEffect(() => {
    toggleSubmittingData(false);
    setSelectedPropertyId(propertyId);
  }, [isOpen]);

  const handleCancel = () => {
    refreshParent && refreshParent();
    toggleDrawerOpen(false);
  }

  const handleAssignProperty = () => {
    toggleSubmittingData(true);
    assignPropertyParent(selectedPropertyId, recordId);
  }

  const propertiesDropdown = () => (
    <PropertySelectionList>
      {properties.map(property => <PropertySelectionListItem onClick={() => setSelectedPropertyId(property.propertyId)}>
        {property.address + (property.city ? `, ${property.city}` : "") + (property.zip ? ` ${property.zip}` : "")}
      </PropertySelectionListItem>)}
    </PropertySelectionList>
  )

  return <SmallDrawerStyled
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "50%" : "35%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      visible={isOpen}
      onClose={() => handleCancel()}
      title={<DrawerHeader style={{flexDirection: "row", alignItems:"flex-start", rowGap:"0.5rem", justifyContent:"flex-start"}}>
        {window.innerWidth > 768 && <ReturnIcon style={{cursor: "pointer", flexShrink: 0}} onClick={() => handleCancel()}/>}
        Assign property to selected
      </DrawerHeader>}
    >
      <div style={{display:"flex", flexDirection:"column", marginTop:"1rem", rowGap:"0.5rem"}}>
        <span style={{fontSize:"1rem", fontWeight:600}}>Assign property</span>

        <Dropdown overlay={propertiesDropdown()} placement={"bottomCenter"} trigger={["click"]}>
          <PropertySelectionDropdown>
            {currentPropertyAddress} <SelectPropertyArrow />
          </PropertySelectionDropdown>
        </Dropdown>

        <DrawerFooter className={"fake-footer"}>
          <ActionButtonGreen onClick={() => handleAssignProperty()} disabled={isSubmittingData}>
            {isSubmittingData && <LoadingOutlined style={{ fontSize: 12 }} spin />}
            <PlusIcon />Assign
          </ActionButtonGreen>
        </DrawerFooter>

      </div>
  </SmallDrawerStyled>
}

const SmallDrawerStyled = styled(DrawerStyled)`
  .ant-drawer-content-wrapper {
      height: 19rem;
      bottom: 0!important;
  }
  
  .ant-drawer-body {
    padding: 0 0 0 0;
    margin: 0 0 0 0!important;
  }
`

const PropertySelectionDropdown = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 16.5rem;
  padding: 0.75rem;
  gap: 0.5rem;
  
  border: 1px solid #009966;
  background: #FFFFFF;
  border-radius: 8px;
  
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #009966;
  
  @media (max-width: 768px) {
    border: 1px solid #00CC33;
    border-radius: 4px;
  }
`

const PropertySelectionList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
  background: #FFFFFF;
  border: 1px solid #DAE0E6;
  box-shadow: 0px 1px 5px rgba(30, 32, 37, 0.05), 0px 10px 24px rgba(26, 28, 31, 0.1);
  border-radius: 0px 0px 10px 10px;
  width: 16.5rem;
`

const PropertySelectionListItem = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #45655E;
  border-bottom: 1px solid #EFF1F5;
  
  :hover {
    color: #21272B;
    background: #F5F6FA;
    border-radius: 8px;
    border-bottom: 1px solid white;
    transition: background .5s ease;
  }
  
  :last-child {
    border-bottom: 1px solid white;
  }
`

export default AssignPropertySmallDrawer