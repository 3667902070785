import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TTag} from "../tags/addNewTag";

export type TGetInventoriesResponse = Array<{
  propertyId: Guid;
  brand: string;
  model: string;
  installedOrPurchaseDate: string;
  warrantyExpiration: string;
  inventoryType: string;
  purchaseCost: number;
  insuredItems: boolean;
  serialNumber: string;
  inventorySubtype: string;
  applianceLifetimeYears: number | null;
  applianceLifetimeYearsLeft: number | null;
  name: string;
  id: Guid;
  notes: string;
  tags: Array<TTag>;
  relatedUpkeepsCount: number;
}>;

export const getInventories = (propertyId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/inventory`,
    {}
  );

  return apiGet<TGetInventoriesResponse | []>(url, {}, propertyId);
};
