import React, {useState} from "react";
import {ReactComponent as OnboardingPopupIcon} from "../../../images/OnboardingPopupIcon.svg";
import {clearPopup} from "../../actions/logIn";
import {TellAFriendAlert, TellAFriendButton} from "./ReferFriendPopup";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import OnboardingDrawer from "../partners/OnboardingDrawer";
import PropertyDrawer from "../properties/PropertyDrawer";
import {getPropertyIdSelector} from "../../selectors";

const OnboardingPopup = () => {
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const [isOnboardingDrawerOpen, toggleOnboardingDrawer] = useState(false);
  const [isPropertyDrawerOpen, togglePropertyDrawer] = useState(false);

  return <>
    <OnboardingDrawer
      isDrawerOpen={isOnboardingDrawerOpen}
      toggleDrawer={toggleOnboardingDrawer}
      togglePropertyDrawer={togglePropertyDrawer}
    />
    <PropertyDrawer
      toggleDrawerOpen={togglePropertyDrawer}
      isOpen={isPropertyDrawerOpen}
      editedPropertyId={propertyId}
    />
    <OnboardingAlert
      message={<div style={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
        <OnboardingPopupIcon />
        At home onboarding
      </div>}
      afterClose={() => {
        dispatch(clearPopup())
      }}
      description={<>
        <p><b>Need help setting up?</b></p>
        <p style={{fontSize: "0.875rem"}}>
          Book a free short visit to onboard your property onto Livlet. They'll help with details, spaces, contents and other important information for your home.
        </p>
        <OnboardingButton onClick={() => {
          toggleOnboardingDrawer(true);
        }}>How do I do this?</OnboardingButton>
      </>}
      type="info"
      closable
    />
  </>
}

const OnboardingAlert = styled(TellAFriendAlert)`
  background-color: #009966!important;
`

const OnboardingButton = styled(TellAFriendButton)`
  background-color: #009966!important;
  border: 1px solid white;
  
  &:hover {
    border: 1px solid #67C18B;
    color: #67C18B;
  }
`

export default OnboardingPopup