import React from "react";
import styled from "styled-components";

import ScrollContainer from "react-indiana-drag-scroll";
import {ReactComponent as MoreMenu} from "../../../images/More.svg";
import { ReactComponent as LinkedContact } from "../../../images/spaces/LinkedContact.svg";
import { ReactComponent as LinkedCost } from "../../../images/spaces/LinkedCost.svg";
import { ReactComponent as LinkedNote } from "../../../images/spaces/LinkedNote.svg";
import { ReactComponent as LinkedWork } from "../../../images/spaces/LinkedWork.svg";
import { ReactComponent as LinkedSpace } from "../../../images/spaces/LinkedSpace.svg";
import {RadioStyled} from "../navigation/Header";
import {useSelector} from "react-redux";
import {
  getContactsSelector,
  getExpensesSelector,
  getExpenseTypesSelector,
  getInventoriesSelector,
  getNotesByPropertyIdSelector,
  getPropertyIdSelector, getPropertyPreferencesSelector,
  getWorksSelector
} from "../../selectors";
import moment from "moment";
import {emptyGuid, getCurrencySign} from "../../screens/helpers";
import {Dropdown, Menu, notification} from "antd";
import {MenuStyled} from "../works/WorksListComp";
import {removeRelation} from "../../../api-wrapper/relation/removeRelation";
import {Guid} from "guid-typescript";
import {confirmDownload, isImage, showImagePreview} from "../attachments/downloadHelpers";
import {downloadAttachment} from "../../../api-wrapper/attachments/downloadAttachment";
import {ReactComponent as Appliances} from "../../../images/Appliances.svg";
import {ReactComponent as Electrical} from "../../../images/ContentsElectronics.svg";
import { ReactComponent as ToolsAndMachinery } from "../../../images/ToolsAndMachineryNew.svg";
import { ReactComponent as Other } from "../../../images/OtherInventory.svg";
import {ReactComponent as Furniture} from "../../../images/Furniture.svg";
import {ReactComponent as Systems} from "../../../images/Systems.svg";
import {maintenanceIconDispenser} from "../maintenances/MaintenanceListComp";
import {numberWithCommasFull} from "../helper";
import {fileTypeIcon} from "../../screens/Files";
import ItemLinks from "./ItemLinks";

type Props = {
  item: any;
  parentId: Guid | null;
  parentType: string | null;
  selectedType?: string;
  isSelectionMode: boolean;
  isItemSelected?: (arg: any) => boolean;
  isSelectedByDefault?: (arg: any) => boolean;
  toggleItemSelection?: (arg: any) => void;
  refreshParent?: any;

  toggleViewWorkDrawer: (arg: boolean) => void;
  setViewWorkId: (arg: any) => void;
  toggleWorkDrawer: (arg: boolean) => void;
  setEditWorkData: (arg: any) => void;
  toggleExpenseDrawer: (arg: boolean) => void;
  setEditExpenseData: (arg: any) => void;
  toggleInventoryDrawer: (arg: boolean) => void;
  setEditInventoryData: (arg: any) => void;
  toggleContactDrawer: (arg: boolean) => void;
  setEditContactData: (arg: any) => void;
  toggleNoteDrawer: (arg: boolean) => void;
  setEditNoteData: (arg: any) => void;
  toggleSpaceDrawer: (arg: boolean) => void;
  setEditSpaceId: (arg: any) => void;
  toggleTaskDrawer: (arg: boolean) => void;
  setEditTaskData: (arg: any) => void;
  setAttachmentId: (arg: any) => void;
  toggleViewFileDrawer: (arg: boolean) => void;
}

const LinkedItem = (props: Props) => {
  const {item, parentId, parentType, selectedType, isSelectionMode, isItemSelected, toggleItemSelection, refreshParent, toggleWorkDrawer,
    toggleContactDrawer, toggleExpenseDrawer, toggleInventoryDrawer, toggleNoteDrawer, setEditWorkData, setEditContactData,
    setEditExpenseData, setEditInventoryData, setEditNoteData, toggleSpaceDrawer, setEditSpaceId, isSelectedByDefault,
    toggleTaskDrawer, setEditTaskData, setViewWorkId, toggleViewWorkDrawer, setAttachmentId, toggleViewFileDrawer} = props;

  const propertyId = useSelector(getPropertyIdSelector).value;
  const expenseTypes = useSelector(getExpenseTypesSelector);
  const works = useSelector(getWorksSelector);
  const costs = useSelector(getExpensesSelector);
  const contacts = useSelector(getContactsSelector);
  const contents = useSelector(getInventoriesSelector);
  const notes = useSelector(getNotesByPropertyIdSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const getItemId = () => {
    const itemType = item.recordType ?? selectedType
    if (itemType === "work" || item.hasOwnProperty("workId")) return item["workId"]
    if (itemType === "expense" || item.hasOwnProperty("expenseId")) return item["expenseId"]
    if (itemType === "inventory" || item.hasOwnProperty("inventoryType")) return item["id"]
    if (itemType === "contact" || item.hasOwnProperty("contactId")) return item["contactId"]
    if (itemType === "attachment" || item.hasOwnProperty("attachmentId")) return item["attachmentId"]
    if (itemType === "note" || item.hasOwnProperty("recordId")) return item["recordId"]
    if (itemType === "space" || item.hasOwnProperty("spaceId")) return item["spaceId"]
    if (itemType === "upkeep" || item.hasOwnProperty("upkeepType")) return item["id"]
    else return null
  }

  const getItemEntityType = () => {
    const itemType = item.recordType ?? selectedType
    if (itemType === "work" || item.hasOwnProperty("workId")) return "work"
    if (itemType === "expense" || item.hasOwnProperty("expenseId")) return "expense"
    if (itemType === "inventory" || item.hasOwnProperty("inventoryType")) return "inventory"
    if (itemType === "contact" || item.hasOwnProperty("contactId")) return "contact"
    if (itemType === "attachment" || item.hasOwnProperty("attachmentId")) return "attachment"
    if (itemType === "note" || item.hasOwnProperty("recordId")) return "note"
    if (itemType === "space" || item.hasOwnProperty("spaceId")) return "space"
    if (itemType === "upkeep" || item.hasOwnProperty("upkeepType")) return item.upkeepType
    else return null
  }

  const getItemType = () => {
    const itemType = item.recordType ?? selectedType
    if (itemType === "work" || item.hasOwnProperty("workId")) return item["type"]
    if (itemType === "expense" || item.hasOwnProperty("expenseId")) return expenseTypes.content[item["type"]]?.name
    if (itemType === "inventory" || item.hasOwnProperty("inventoryType")) {
      return item["inventoryType"] === "ToolsAndMachinery" ? "Tools and Machinery" : item["inventoryType"]
    }
    if (itemType === "contact" || item.hasOwnProperty("contactId")) return item["contactType"]
    if (itemType === "attachment" || item.hasOwnProperty("attachmentId")) return ""
    if (itemType === "note" || item.hasOwnProperty("recordId")) return ""
    if (itemType === "space" || item.hasOwnProperty("spaceId")) return item["spaceType"]
    if (itemType === "upkeep" || item.hasOwnProperty("upkeepType")) return item["category"]
    else return null
  }

  const iconDispenser = () => {
    const itemType = item.recordType ?? selectedType
    if (itemType === "work" || item.hasOwnProperty("workId")) return <LinkedWork />
    if (itemType === "expense" || item.hasOwnProperty("expenseId")) return <LinkedCost />
    if (itemType === "inventory" || item.hasOwnProperty("inventoryType")) {
      if (item.inventoryType === "Appliances") return <Appliances />;
      else if (item.inventoryType === "Electronics") return <Electrical />;
      else if (item.inventoryType === "ToolsAndMachinery") return <ToolsAndMachinery />;
      else if (item.inventoryType === "Furniture") return <Furniture />;
      else if (item.inventoryType === "Systems") return <Systems />;
      else return <Other />
    }
    if (itemType === "contact" || item.hasOwnProperty("contactId")) return <LinkedContact />
    if (itemType === "attachment" || item.hasOwnProperty("attachmentId")) {
      if (item.thumbnailPath) return <img className="circle-image-cropped" alt="" src={item.thumbnailPath} />
      else return fileTypeIcon(item)
    }
    if (itemType === "note" || item.hasOwnProperty("recordId")) return <LinkedNote />
    if (itemType === "space" || item.hasOwnProperty("spaceId")) return <LinkedSpace />
    else return null
  }

  const openInDrawer = () => {
    const itemType = item.recordType ?? selectedType
    if (itemType === "work" || item.hasOwnProperty("workId")) {
      toggleViewWorkDrawer(true);
      setViewWorkId(item.workId);
    } else if (itemType === "expense" || item.hasOwnProperty("expenseId")) {
      toggleExpenseDrawer(true);
      setEditExpenseData({data: costs.content.filter(cost => cost.expenseId === item.expenseId)[0]});
    } else if (itemType === "inventory" || item.hasOwnProperty("inventoryType")) {
      toggleInventoryDrawer(true);
      setEditInventoryData({data: contents.content.filter(content => content.id === item.id)[0]});
    } else if (itemType === "contact" || item.hasOwnProperty("contactId")) {
      toggleContactDrawer(true);
      setEditContactData({data: contacts.content.filter(contact => contact.contactId === item.contactId)[0]});
    } else if (itemType === "note" || item.hasOwnProperty("recordId")) {
      toggleNoteDrawer(true);
      setEditNoteData({
        workName: "",
        workId: emptyGuid,
        workNotes: notes.filter(note => note.recordId === item.recordId)[0]});
    } else if (itemType === "space" || item.hasOwnProperty("spaceId")) {
      toggleSpaceDrawer(true);
      setEditSpaceId(item.spaceId);
    } else if (itemType === "upkeep" || item.hasOwnProperty("upkeepType")) {
      toggleTaskDrawer(true);
      setEditTaskData(item);
    } else if (itemType == "attachment"  || item.hasOwnProperty("attachmentId")) {
      toggleViewFileDrawer(true);
      setAttachmentId(item.attachmentId);
    }
  }

  const moreMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      if (key === "edit") {
        openInDrawer()
      } else if (key === "unlink") {
        const childId = getItemId()
        const childType = getItemEntityType()
        parentId && parentType && childId && childType && removeRelation(propertyId, parentId, parentType, childId, childType).then(() => {
          notification.info({
            message: "Unlinked successfully",
            description: `The selected item has been successfully unlinked.`,
            placement: "topRight",
          })
          refreshParent && refreshParent()
        })
      } else if (key === "preview") {
        downloadAttachment({
          propertyId,
          id: item.attachmentId,
        }).then((res) => {res && showImagePreview(propertyId, item.attachmentId, res)})
      } else if (key === "download") {
        downloadAttachment({
          propertyId,
          id: item.attachmentId,
        }).then((res) => confirmDownload(res))
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        {!item.hasOwnProperty("attachmentId") && <Menu.Item key="edit">Edit</Menu.Item>}
        {item.hasOwnProperty("attachmentId") && isImage(item.name) && <Menu.Item key="preview">Preview</Menu.Item>}
        {item.hasOwnProperty("attachmentId") && <Menu.Item key="download">Download</Menu.Item>}
        <Menu.Item key="unlink">Unlink</Menu.Item>
      </MenuStyled>
    );
  };

  return <LinkedItemWrapper
    className={item.isComplete ? "completed" : ""}
    onClick={() => {
      if (toggleItemSelection) {
        isSelectedByDefault && !isSelectedByDefault(getItemId()) && toggleItemSelection({
          id: getItemId(),
          type: item.upkeepType ?? item.recordType ?? selectedType
        })
      } else openInDrawer()
    }}>
    {item.upkeepType ? <LinkedItemIcon>{maintenanceIconDispenser(item.category)}</LinkedItemIcon>
      : <LinkedItemIcon>{iconDispenser()}</LinkedItemIcon>}
    <LinkedItemContents>
      <LinkedItemTitleWrapper>
        <LinkedItemTitle>
          <LinkedItemTitleText>
            {item.title ?? item.name}
          </LinkedItemTitleText>
          {getItemType() && <LinkedItemType>{getItemType()}</LinkedItemType>}
        </LinkedItemTitle>
        {(item.workTotalExpense || item.totalCost || item.purchaseCost) &&
          <LinkedItemPrice>{getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommasFull(item.workTotalExpense || item.totalCost ||  item.purchaseCost)}</LinkedItemPrice>}
      </LinkedItemTitleWrapper>
      {/*{(item.paymentDate || item.installedOrPurchaseDate || item.dueDate || item.uploadedDate) &&*/}
      {/*  <LinkedItemDate>{moment(item.paymentDate || item.installedOrPurchaseDate ||*/}
      {/*    item.dueDate || item.uploadedDate).format(propertyPreferences.preferredDateFormat)}</LinkedItemDate>}*/}
      <LinkedItemTags>
        {item.tags?.map((tag: any) => <LinkedItemTag className={"tag"}>{tag.name}</LinkedItemTag>)}
        <ItemLinks item={item} />
      </LinkedItemTags>
    </LinkedItemContents>
    {isSelectionMode && toggleItemSelection && isItemSelected && isSelectedByDefault ? <RadioStyled
        checked={isItemSelected(getItemId()) || isSelectedByDefault(getItemId())}
        disabled={isSelectedByDefault(getItemId())}
      /> :
      <Dropdown
        overlay={moreMenuDropdown()}
        placement="bottomRight"
        trigger={["click"]}
      >
        <MoreMenu style={{flexShrink: 0}} onClick={(e) => e.stopPropagation()}/>
      </Dropdown>}
  </LinkedItemWrapper>
}

const LinkedItemWrapper = styled.div`
  width: 100%;
  padding: 1.5rem 1rem;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  column-gap: 1rem;
  transition: all .5s ease!important;

  &.completed {
    border-image-slice: 1;
    border: double 1px transparent;
    border-radius: 16px!important;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #00B74F, #F7FEFA);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    transition: all .5s ease!important;
  }
  
  @media (max-width: 768px) {
    padding: 0.75rem 1rem;
  }
`

const LinkedItemContents = styled.div`
  width: calc(100% - 6rem);
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  align-items: stretch;
`

const LinkedItemIcon = styled.div`
  cursor: pointer;
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.75rem;
  height: 2.75rem;
`

const LinkedItemTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  justify-content: space-between;
  width: 100%;
`

const LinkedItemTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.375rem;
  overflow: hidden;
`

const LinkedItemTitleText = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #002A43;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LinkedItemType = styled.div`
  padding: 0.125rem 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #BDBDBD;
  border-radius: 16px;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #3E5D58;
  flex-shrink: 0;
  
  @media (max-width: 768px) { display: none; }
`

export const LinkedItemTags = styled(ScrollContainer)`
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
  width: 100%;
`

const LinkedItemPrice = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #002A43;
  
  @media (max-width: 768px) { display: none; }
`

export const LinkedItemTag = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.25rem;
  border-radius: 19px;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #21272B;
  flex-shrink: 0;
  
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &.tag { background: #D8DFDE; text-transform: uppercase; }
  &.content { background: #E4C9FA; }
  &.contact { background: #FAC9C9; }
  &.file { background: #C7ECD7; }
  &.work { background: #C9E0FB; }
  &.note { background: #FFE4A5 }
  &.cost { background: #D7FFA5; }
  &.space { background: #C9F7FA; }
  &.task { background: #C9ECFB; }
  &.reminder { background: #FAF9D3; }
  &.recurringExpense { background: #FAC9E1; }
`

const LinkedItemDate = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #666F7A;
`

export default LinkedItem