import {Guid} from "guid-typescript";
import {apiPut, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {TDocumentRequest} from "./addDocument";
import _ from "lodash";

export const editDocument = (payload: TDocumentRequest & { propertyId: Guid, documentId: Guid, category: string }) => {
  const { propertyId, documentId, category } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/document/${category}/${documentId}`
  );

  return apiPut<any, TDocumentRequest>(url, _.omit(payload, ["category"]));
};
