import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetNewsResponse } from "../../api-wrapper/news/getNews";
import {
  getNewsFailureAction,
  getNewsInitAction,
  getNewsSuccessAction,
  TGetNewsActions,
} from "../actions/news";
import {emptyGuid} from "../screens/helpers";

export type TNews = {
  fetchingStatus: TFetchingStatus;
  content: TGetNewsResponse;
};

const initialState: TNews = {
  content: [
    {
      id: emptyGuid,
      relatedItemId: emptyGuid,
      relatedItemType: "",
      rating: "",
      title: "",
      body: "",
      dateTime: "",
      severityLevel: 0,
      markedAsRead: false,
      deleted: false,
      propertyId: emptyGuid,
      subscriptionId: 0
    }
  ],
  fetchingStatus: TFetchingStatus.Defined,
};

export const newsReducer = createReducer<TNews, TGetNewsActions>(
  initialState
)
  .handleAction(getNewsInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getNewsSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getNewsFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
