import React, {useEffect, useState} from "react";

import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getAttachmentsSelector,  getPropertyIdSelector} from "../../selectors";
import {ReactComponent as ReturnIcon} from "../../../images/spaces/ReturnIcon.svg";
import {
  DrawerHeader,
  DrawerStyled
} from "../../screens/components";
import {getAllAttachments} from "../../actions/attachments";
import {Guid} from "guid-typescript";
import TagContainer from "../tags/TagContainer";

type Props = {
  attachmentIds: Set<Guid>;
  setAttachmentIds: (arg: Set<Guid>) => void;
  isOpen: boolean;
  toggleDrawerOpen: (arg: boolean) => void;
  toggleGlobalEditMode: (arg: boolean) => void;
  refreshParent?: () => void;
}

const AssignTagsDrawer = (props: Props) => {
  const {attachmentIds, setAttachmentIds, isOpen, toggleDrawerOpen, refreshParent, toggleGlobalEditMode} = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const attachments = useSelector(getAttachmentsSelector);

  const [parents, setParents] = useState<any>([]);

  useEffect(() => {
    if (isOpen) {
      setParents(attachments.content.filter(attachment => attachmentIds.has(attachment.attachmentId)));
    }
  }, [attachmentIds, isOpen])

  const handleCancel = () => {
    // dispatch(getAllAttachments(propertyId));
    // refreshParent && refreshParent();
    toggleDrawerOpen(false);
  }

  return <SmallDrawerStyled
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "50%" : "50%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      visible={isOpen}
      onClose={() => handleCancel()}
      title={<DrawerHeader style={{flexDirection: "row", alignItems:"flex-start", rowGap:"0.5rem", justifyContent:"flex-start"}}>
        {window.innerWidth > 768 && <ReturnIcon style={{cursor: "pointer", flexShrink: 0}} onClick={() => handleCancel()}/>}
        Add tags to selected
      </DrawerHeader>}
    >

    <div style={{width:"100%"}}>
      <TagContainer
        parentTags={[]}
        parents={parents}
        parentType={"attachment"}
        startsOpen={isOpen}
        selectedTags={[]}
        toggleGlobalEditMode={toggleGlobalEditMode}
        refreshParent={() => {dispatch(getAllAttachments(propertyId)); handleCancel(); setAttachmentIds(new Set()); }}
        disableCallback={true}
      />
    </div>
  </SmallDrawerStyled>
}

const SmallDrawerStyled = styled(DrawerStyled)`
  .ant-drawer-content-wrapper {
      height: 33%;
      bottom: 0!important;
  }
  
  .ant-drawer-body {
    padding: 0 0.5rem 0.25rem 0;
    margin: 0.5rem 0 1.5rem 0!important;
  }
`


export default AssignTagsDrawer