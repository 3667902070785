import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import {
  getExpenseReportFailureAction,
  getExpenseReportInitAction,
  getExpenseReportSuccessAction,
  TGetExpenseReportActions,
} from "../actions/expenseReport";
import {TGetExpenseReportResponse} from "../../api-wrapper/reports/expenseReport";

export type TExpenseReport = {
  fetchingStatus: TFetchingStatus;
  content: TGetExpenseReportResponse;
};

const initialState: TExpenseReport = {
  content: {},
  fetchingStatus: TFetchingStatus.Defined,
};

export const expenseReport = createReducer<
  TExpenseReport,
  TGetExpenseReportActions
>(initialState)
  .handleAction(getExpenseReportInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getExpenseReportSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getExpenseReportFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
