import {Guid} from "guid-typescript";
import {apiGet, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {TTag} from "./addNewTag";

export type TGetTagsResponse = Array<TTag>

export const getTags = () => {
  const url = createUrl(`${EnvConfig.API_URL}/api/tag`);

  return apiGet<TGetTagsResponse | []>(url);
};