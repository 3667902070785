import {
  Carousel,
  Checkbox,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Input,
  List,
  Modal,
  Select,
  Tooltip,
} from "antd";
import {useIntl} from "react-intl";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import _ from "lodash";
import styled from "styled-components";
import CreatableSelect from 'react-select/creatable';

import {ReactComponent as InventoriesIcon} from "../../../images/ContentDrawerIcon.svg";
import {ReactComponent as AttachmentIcon} from "../../../images/AttachmentsPurple.svg";
import {ReactComponent as MaintenanceIcon} from "../../../images/ContentWorksPurple.svg";
import {ReactComponent as HelpIcon} from "../../../images/Help.svg";
import {ReactComponent as YellowDot} from "../../../images/YellowDot.svg";
import {ReactComponent as PinkDot} from "../../../images/PinkDot.svg";
import {ReactComponent as NotesDrawerIcon} from "../../../images/ContentUpkeepPurple.svg";
import {ReactComponent as ArrowLeftBlue} from "../../../images/ArrowLeftBlue.svg";
import {ReactComponent as ArrowRightBlue} from "../../../images/ArrowRightBlue.svg";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import messages from "../../screens/messages";
import {InputsWrapper, InputWrapper, Label, LabelWrapper,} from "../../screens/RegistrationPage";
import {removeInventory} from "../../../api-wrapper/inventories/removeInventory";
import {getAllInventories} from "../../actions/inventories";
import {useDispatch, useSelector} from "react-redux";
import {addInventory, TAddInventoryResponse,} from "../../../api-wrapper/inventories/addInventory";
import {editInventory} from "../../../api-wrapper/inventories/editInventory";
import {
  getExpenseTypesSelector,
  getPropertyIdSelector, getPropertyPreferencesSelector, getSubscriptionUsersSelector, getUserProfileSelector,
  getWorksSelector
} from "../../selectors";
import UploadFile from "../attachments/UploadFile";
import {getInventoryById} from "../../../api-wrapper/inventories/getInventoryById";
import WorkDrawer, {
  AttachButtonStyled,
  CloseButton,
  PlusButtonStyledLists,
  SaveButton,
  SaveButtonWrapper,
  Separator,
  TextAreaStyled,
} from "../works/WorkDrawer";
import {ReactComponent as ExpensesIcon} from "../../../images/ContentCostsPurple.svg";
import ExpenseDrawer, {InputNumberStyled} from "../expenses/ExpenseDrawer";
import {TAddNoteResponse} from "../../../api-wrapper/notes/addNote";
import {
  attachmentsInitial,
  expensesInitial,
  notesInitial,
  numberWithCommas,
  tagsInitial,
  worksInitial,
} from "../helper";
import {AutoCompleteStyled, MenuItemStyled, MenuStyled} from "../styled";
import InventoryWorksListComp from "./InventoryWorksListComp";
import AddExistingWorkDrawer from "./AddExistingWorkDrawer";
import {addRelation} from "../../../api-wrapper/relation/addRelation";
import {ReactComponent as WorksDotIcon} from "../../../images/PurpleDot.svg";
import {Guid} from "guid-typescript";
import {emptyGuid, getCurrencySign} from "../../screens/helpers";
import ExpenseListItem from "../expenses/ExpenseListItem";
import AttachmentsOnDrawer from "../attachments/AttachmentsOnDrawer";
import {ReactComponent as AddCircle} from "../../../images/AddCircle.svg";
import UploadInvoice from "../attachments/UploadInvoice";
import {addExpense} from "../../../api-wrapper/expenses/addExpense";
import {TFetchingStatus} from "../../../helpers";
import {getEntityTypes} from "../../../api-wrapper/relation/getEntityTypes";
import {getBrands} from "../../../api-wrapper/inventories/getBrands";
import moment from "moment";
import InventoryUpkeepListItem from "./InventoryUpkeepListItem";
import {linkToRecord} from "../spaces/AddNewRecordDrawer";
import AddExistingUpkeepDrawer from "./AddExistingUpkeepDrawer";
import UpdateTaskDrawer, {getTaskType} from "../planner/UpdateTaskDrawer";
import AddExistingExpenseDrawer from "../expenses/AddExistingExpenseDrawer";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  inventory?: { data: TAddInventoryResponse } | null;
  setEditingInventoryData?: (
    arg01: TAddInventoryResponse | { accessToken: any } | null
  ) => void;
  width?: number | null;
  drawerTitle?: string | null;
  refreshParent?: (arg?: Guid | null) => void;
  highlightNote?: Guid | null;
  highlightAttachment?: Guid | null;
  icon?: ReactNode | null;
  parentRecordId?: Guid | null;
  parentRecordType?: string;

  toggleViewWorkDrawer?: (arg: boolean) => void;
  setViewWorkId?: (arg: any) => void;
};

const inventoryTypesWithSubtypes = ["Appliances", "Systems", "Electronics"]

const InventoryDrawer = (props: Props) => {
  const {
    isOpen,
    toggleDrawerOpen,
    inventory,
    setEditingInventoryData,
    width,
    drawerTitle,
    refreshParent,
    highlightAttachment,
    icon,
    parentRecordId,
    parentRecordType,
    setViewWorkId, toggleViewWorkDrawer,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [inventoryName, setInventoryName] = useState("");
  const [insuredItems, setInsuredItems] = useState(false);
  const { formatMessage: f } = useIntl();

  const propertyId = useSelector(getPropertyIdSelector).value;
  const works = useSelector(getWorksSelector);
  const expenseTypes = useSelector(getExpenseTypesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);
  const subscriptionUsers = useSelector(getSubscriptionUsersSelector)
  const userProfile = useSelector(getUserProfileSelector);

  const titleInput = useRef(null);
  const [titleError, setTitleError] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [editWorkData, setEditWorkData] = useState<any>(null);
  const [isExpenseDrawerOpen, toggleExpenseDrawerVisibility] = useState(false);
  const [isExistingExpenseDrawerOpen, toggleExistingExpenseDrawer] = useState(false);
  const [editExpenseData, setEditExpenseData] = useState<any>(null);
  const [inventoryDetails, setInventoryDetails] = useState({
    inventoryId: emptyGuid,
    inventoryNotes: notesInitial,
    inventoryWorks: worksInitial,
    attachments: attachmentsInitial,
    expenses: expensesInitial,
    tags: tagsInitial,
    upkeeps: []
  });
  const [selectedInventoryType, setSelectedInventoryType] = useState("")
  const [inventorySubtypes, setInventorySubtypes] = useState<any>([])
  const [inventorySubtypeOptions, setInventorySubtypeOptions] = useState<any>([])
  const [selectedInventorySubtype, setSelectedInventorySubtype] = useState("")
  const [invoiceData, setInvoiceData] = useState(null);
  const [applianceLifetime, setApplianceLifetime] = useState("")
  const [equipmentCategoryId, setEquipmentCategoryId] = useState<any>(null)
  const [brandsList, setBrandsList] = useState<any>([]);
  const [brandOptions, setBrandOptions] = useState<Array<{ key: string; value: string }>>([]);
  const [currentUserTenantId, setCurrentUserTenantId] = useState(0);

  const [inventorySubtypesPerType, setInventorySubtypesPerType] = useState<Map<string, any>>(new Map());
  const [titleHintsList, setTitleHintsList] = useState<any>([]);
  const [isTitleFocused, toggleTitleFocused] = useState(false);
  const [isHintItemFocused, toggleHintItemFocused] = useState(false);

  useEffect( () => {
    if (!isOpen)
      return;

    if (inventorySubtypesPerType.size > 0)
      return;

    downloadAllSubtypes();
  }, [isOpen]);

  const downloadAllSubtypes = async () => {
    const appliancesSubtypes = await getEntityTypes(propertyId, "Appliances");
    const systemsSubtypes = await getEntityTypes(propertyId, "Systems");
    const electronicsSubtypes = await getEntityTypes(propertyId, "Electronics");

    let typesMap = new Map<string, any>();
    typesMap.set("Appliances", appliancesSubtypes);
    typesMap.set("Systems", systemsSubtypes);
    typesMap.set("Electronics", electronicsSubtypes);
    setInventorySubtypesPerType(typesMap);
  }

  const refreshBrandsList = (typeId: Guid) => {
    getBrands(typeId).then((res: any) => res && setBrandsList(res))
  }

  const handleSelectChange = (selectedItem: any) => {
    setSelectedInventorySubtype(selectedItem ? selectedItem.value : "")
  }

  useEffect(() => {
    setCurrentUserTenantId(subscriptionUsers.content.filter(item => item.email === userProfile.content.email)[0]?.userId)
  }, [subscriptionUsers, userProfile])

  useEffect(() => {
    if (propertyId) {
      if (inventoryTypesWithSubtypes.includes(selectedInventoryType)) {
        let subtypes = inventorySubtypesPerType.get(selectedInventoryType);
        if (!subtypes)
          return;
        setInventorySubtypes(subtypes)
        let subtypeOptions: any = []
        subtypes.map((option: any) => option.name !== "Other" && option.name !== ""
          && subtypeOptions.push({value: option.name, label: option.name}))
        let sortedSubtypeOptions = _.orderBy(subtypeOptions, "label")
        sortedSubtypeOptions.push({value: "Other", label: "Other"})
        setInventorySubtypeOptions(sortedSubtypeOptions)
      } else setSelectedInventorySubtype("")
    }
  }, [propertyId, selectedInventoryType, inventorySubtypesPerType])

  useEffect(() => {
    if (expenseTypes.fetchingStatus === TFetchingStatus.Success) {
      const expenseTypesArray = Object.entries(expenseTypes.content)
      if (expenseTypesArray.length > 0) {
        const maintenanceCategory = expenseTypesArray.filter(item =>
          !item[1]?.hasOwnProperty('parentId') && item[1]?.name.toLowerCase().includes("maintenance"))[0][1]
        const equipmentCategory = expenseTypesArray.filter(item =>
          item[1]?.name.includes("Equipment") && item[1]?.parentId === maintenanceCategory.id)[0][1]
        setEquipmentCategoryId(equipmentCategory.id)
      }
    }
  }, [expenseTypes])

  useEffect(() => {
    const lifetime = inventorySubtypes.filter((type: any) => type.name === selectedInventorySubtype)[0]?.lifetime
    lifetime > 0 ? setApplianceLifetime(lifetime) : setApplianceLifetime("Unknown")
  }, [inventorySubtypes, selectedInventorySubtype])

  const [showFileUploadList, setShowFileUploadList] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Set<Guid>>(new Set());
  const selectFilesRef = useRef(null);
  const fileListRef = useRef(null);

  const [editInventoryNote, setEditInventoryNote] = useState<TAddNoteResponse | null>(null);
  const [editInventoryData, setEditInventoryData] = useState<any>(null);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [deleteIfNotSaved, toggleDeleteIfNotSaved] = useState(false)

  const [editing, setEditing] = useState("");
  const [isExistingUpkeepDrawerOpen, toggleAddExistingUpkeepDrawer] = useState(false);
  const [isTaskDrawerOpen, toggleTaskDrawer] = useState(false);
  const [editingTaskData, setEditingTaskData] = useState<any>(null);
  const [taskType, setTaskType] = useState("Task");

  useEffect(() => {
    editingTaskData && setTaskType(getTaskType(editingTaskData))
  }, [editingTaskData])

  const [isExistingWorkDrawerOpen, toggleAddExistingWorkDrawer] = useState(false);
  const [isNewWorkDrawerOpen, toggleAddNewWorkDrawer] = useState(false);

  const proposeTitleHints = (inputValue: string) => {
    let hints: any[] = [];
    inventorySubtypesPerType.forEach((typesList: any, category: string) => {
      if (hints.length >= 5) return;
      typesList.forEach((inventoryType: any) => {
        if (hints.length >= 5) return;
        if (inventoryType.name.toLowerCase().startsWith(inputValue.toLowerCase())) {
          hints.push({name: inventoryType.name, category: category});
        }
      });
    });
    setTitleHintsList(hints);
  }

  function handleWorkMenuClick(e: any) {
    const { key } = e;
    if (key === "1") toggleAddExistingWorkDrawer(true);
    else if (key === "2") {
      toggleAddNewWorkDrawer(true);
    }
  }

  function handleUpkeepMenuClick(e: any) {
    const { key } = e;
    if (key === "1") {
      toggleAddExistingUpkeepDrawer(true);
    }
    else if (key === "2") {
      toggleTaskDrawer(true);
      setEditingTaskData(null);
    }
  }

  const worksMenu = (
    <MenuStyled onClick={handleWorkMenuClick}>
      <MenuItemStyled key="1" disabled={works.content.length < 1}>Existing project</MenuItemStyled>
      <MenuItemStyled key="2">New project</MenuItemStyled>
    </MenuStyled>
  );

  const upkeepMenu = (
    <MenuStyled onClick={handleUpkeepMenuClick}>
      <MenuItemStyled key="1">Add existing</MenuItemStyled>
      <MenuItemStyled key="2">Add custom</MenuItemStyled>
    </MenuStyled>
  );

  function handleMenuExpenseClick(e: any) {
    const { key } = e;
    key === "2" && toggleExpenseDrawerVisibility(true);
    key === "3" && toggleExistingExpenseDrawer(true);
  }

  const expenseMenu = (
    <MenuStyled onClick={handleMenuExpenseClick}>
      <MenuItemStyled key="1" >
        <UploadInvoice
          fileList={[]}
          allowBatchUpload={false}
          uploadOrigin={"drawer"}
          setExpenseData={setEditExpenseData}
          setInvoiceData={setInvoiceData}
          toggleExpenseDrawer={toggleExpenseDrawerVisibility}
        />
      </MenuItemStyled>
      <MenuItemStyled key="2">Add new expense</MenuItemStyled>
      <MenuItemStyled key="3">Add existing expense</MenuItemStyled>
    </MenuStyled>
  );

  const performCleanup = (res?: any) => {
    if (refreshParent && inventory) refreshParent(inventory?.data.id);
    if (refreshParent && res && res.data) refreshParent(res.data.id);
    if (refreshParent && !inventory && !res && !res.data) refreshParent()
    _.isUndefined(setEditingInventoryData)
      ? setEditInventoryData(null)
      : setEditingInventoryData(null);
    form.resetFields();
    setFormFieldsChanged(false);
    setSaveDisabled(false);
    toggleDrawerOpen(false);
    dispatch(getAllInventories(propertyId));
  }

  const promptCostCreation = (payload: {
    purchaseTitle: string,
    purchaseCost: number,
    purchaseDate: string,
    parentId: Guid
  }, result?: any) => {
    Modal.confirm({
      title: "Would you like to create an Expense?",
      content:
        "You have entered a price value and a purchase date for this Content item. Would you like to create " +
        "an Expense to reflect the purchase of this item in your Expenses?",
      okText: "Yes",
      cancelText: "No",
      className: "form-confirm-close-modal",
      onOk() {
        addExpense({
          propertyId,
          name: `${payload.purchaseTitle} Purchase`,
          payee: null,
          type: equipmentCategoryId,
          totalCost: payload.purchaseCost,
          isTaxDeductible: false,
          paymentDate: payload.purchaseDate,
          isConfirmed: true,
          userPaidBy: currentUserTenantId,
          contactPaidBy: null
        }).then((res) => {
          // @ts-ignore
          addRelation({propertyId, parentId: payload.parentId, childId: res.data.expenseId,
            parentType: "inventory", childType: "expense"}).then()
          setEditExpenseData(res)
          toggleExpenseDrawerVisibility(true)
          toggleDeleteIfNotSaved(true)
          result ? performCleanup(result) : performCleanup()
        })
      },
      onCancel() {
        result ? performCleanup(result) : performCleanup()
      },
    });
  }

  const handleManageInventory = async (e: {
    inventoryType: string;
    brand: string;
    model: string;
    purchaseCost: number;
    notes: string;
    applianceType: string,
    serialNumber: string,
    installedOrPurchaseDate: moment.Moment;
    warrantyExpiration: moment.Moment;
  }) => {
    if (_.isEmpty(inventoryName)) {
      setTitleError(true);
      return;
    }
    const {
      brand,
      model,
      purchaseCost,
      inventoryType,
      serialNumber,
      installedOrPurchaseDate,
      warrantyExpiration,
      notes,
    } = e;

    if (inventory) {
      editInventory({
        propertyId,
        id: inventory.data.id,
        name: inventoryName,
        brand,
        model,
        inventoryType,
        purchaseCost,
        insuredItems,
        notes,
        serialNumber,
        inventorySubtype: inventoryTypesWithSubtypes.includes(selectedInventoryType) ?
          selectedInventorySubtype : null,
        installedOrPurchaseDate: _.isEmpty(installedOrPurchaseDate)
          ? null
          : installedOrPurchaseDate.format("YYYY-MM-DD"),
        warrantyExpiration: _.isEmpty(warrantyExpiration)
          ? null
          : warrantyExpiration.format("YYYY-MM-DD"),
        tags: []
      }).then((res: any) => {
        if (parentRecordId && parentRecordType) linkToRecord(propertyId, parentRecordId, parentRecordType, res.id, "inventory", dispatch)
        if ((!inventory.data.name && purchaseCost && installedOrPurchaseDate ||
          !inventory.data.purchaseCost && purchaseCost && installedOrPurchaseDate ||
          !inventory.data.installedOrPurchaseDate && purchaseCost && installedOrPurchaseDate) && inventoryDetails.expenses.length === 0) {
          promptCostCreation({
            purchaseTitle: inventoryName,
            purchaseCost,
            purchaseDate: installedOrPurchaseDate.format("YYYY-MM-DD"),
            parentId: inventory.data.id
          })
        } else {
          performCleanup()
        }
      });
    } else {
      setSaveDisabled(true);
      addInventory({
        propertyId,
        name: inventoryName,
        brand,
        model,
        inventoryType,
        purchaseCost,
        insuredItems,
        serialNumber,
        inventorySubtype: inventoryTypesWithSubtypes.includes(selectedInventoryType) ?
          selectedInventorySubtype : null,
        installedOrPurchaseDate: _.isEmpty(installedOrPurchaseDate)
          ? null
          : installedOrPurchaseDate.format("YYYY-MM-DD"),
        warrantyExpiration: _.isEmpty(warrantyExpiration)
          ? null
          : warrantyExpiration.format("YYYY-MM-DD"),
      }).then((res) => {
        if (res && res.data && purchaseCost && installedOrPurchaseDate && res.data.expensesCount === 0) {
          promptCostCreation({
            purchaseTitle: inventoryName,
            purchaseCost,
            purchaseDate: installedOrPurchaseDate.format("YYYY-MM-DD"),
            parentId: res.data.id
          }, res)
        } else {
          performCleanup(res)
        }
      });
    }
  };
  const initialValues = () => {
    if (inventory) {
      const {
        brand,
        model,
        purchaseCost,
        inventoryType,
        installedOrPurchaseDate,
        warrantyExpiration,
        insuredItems,
        notes,
        serialNumber
      } = inventory.data;
      return {
        inventoryType,
        brand,
        model,
        purchaseCost,
        notes,
        serialNumber,
        installedOrPurchaseDate:
          installedOrPurchaseDate === null
            ? ""
            : moment(installedOrPurchaseDate),
        warrantyExpiration:
          warrantyExpiration === null ? "" : moment(warrantyExpiration),
        insuredItems,
      };
    } else {
      return {};
    }
  };

  useEffect(() => {
    if (showFileUploadList && selectFilesRef && selectFilesRef.current) {
      // @ts-ignore
      selectFilesRef.current.scrollIntoView();
    } else if (!showFileUploadList && fileListRef && fileListRef.current) {
      // @ts-ignore
      fileListRef.current.scrollIntoView();
    }
  }, [showFileUploadList])

  useEffect(() => {
    if (inventory) {
      setInventoryName(inventory.data.name);
      setSelectedInventoryType(inventory.data.inventoryType);
      setSelectedInventorySubtype(inventory.data.inventorySubtype);
      setInsuredItems(inventory.data.insuredItems);
      !_.isNull(inventory.data.applianceLifetimeYears) && setApplianceLifetime(`${inventory.data.applianceLifetimeYears} years`);
      form.resetFields();
    } else {
      form.resetFields();
      setInventoryName("");
      setSelectedInventoryType("");
      setSelectedInventorySubtype("");
      setInsuredItems(false);
      setApplianceLifetime("Unknown")
    }
    setTitleError(false);
    setFormFieldsChanged(false);
  }, [form, inventory, isOpen]);

  useEffect(() => {
    inventory &&
      !Object.is(inventory.data.id, emptyGuid) &&
      getInventoryById(propertyId, inventory.data.id).then((res) =>
        // @ts-ignore
        setInventoryDetails(res)
      );
  }, [inventory]);

  const refreshInventory = (recordId?: Guid | null) => {
    if (refreshParent) refreshParent();
    inventory &&
      !Object.is(inventory.data.id, emptyGuid) &&
      getInventoryById(propertyId, inventory.data.id).then((res) => {
        // @ts-ignore
        setInventoryDetails({ ...res });
        recordId && res &&
          setEditInventoryNote(
            res.inventoryNotes.filter((note) => note.recordId === recordId)[0]
          );
      });
  };

  const refreshInventoryFromWork = (recordId?: Guid | null) => {
    recordId &&
      inventory &&
      inventory.data.id &&
      addRelation({
        propertyId,
        parentId: recordId,
        childId: inventory.data.id,
        parentType: "work",
        childType: "inventory",
      }).then(() => {
        refreshInventory();
        setFormFieldsChanged(false);
        // toggleDrawerOpen(false);
      });

    inventory &&
      !Object.is(inventory.data.id, emptyGuid)  &&
      getInventoryById(propertyId, inventory.data.id).then((res) => {
        // @ts-ignore
        setInventoryDetails({ ...res });
        recordId && res &&
          setEditInventoryNote(
            res.inventoryNotes.filter((note) => note.recordId === recordId)[0]
          );
      });
  };

  const countTotalExpense = (items: any) => {
    return items.reduce((sumOfExpenses: any, {workTotalExpense}: any) =>
        sumOfExpenses + workTotalExpense, 0)
  }

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        toggleDrawerOpen(false);
        if (setEditingInventoryData) {
          setEditingInventoryData(null);
        }
        form.resetFields();
        if (inventory && _.isEmpty(inventory.data.name)) {
          inventory && removeInventory(inventory.data.propertyId, inventory.data.id).then(
            () => {
              inventory && dispatch(getAllInventories(inventory.data.propertyId));
            }
          )
        } else {
          inventory && dispatch(getAllInventories(propertyId));
        }
      },
    });
  }

  useEffect(() => {
    if (isOpen) {
      // @ts-ignore
      titleInput.current.focus();
      // @ts-ignore
      titleInput.current.setSelectionRange(0, 0);
    }
  }, [isOpen, titleInput.current])

  const style = {
    control: (base: any) => ({
      ...base,
      border: '1px solid #dae0e6',
      height: '2.5rem',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #dae0e6',
      }
    }),
    singleValue: (base: any) => ({
      ...base,
      fontSize: '1rem',
      color: '#6b7185',
    }),
    option: (base: any) => ({
      ...base,
      color: 'black',
    })
  };

  useEffect(() => {
    setBrandOptions(brandsList.map((type: any) => {
      return {key: type.name, value: type.name}
    }))
  }, [brandsList])

  const onBrandAutocompleteSearch = (searchText: string) => {
    setBrandOptions(!searchText
      ? brandsList.map((type: any) => { return {key: type.name, value: type.name} })
      : brandsList
        .filter((type: any) => type.name.toLowerCase().includes(searchText.toLowerCase()))
        .map((type: any) => { return {key: type.name, value: type.name }}))
  }

  useEffect(() => {
    if (selectedInventorySubtype) {
      const selectedSubtype = inventorySubtypes.filter((type: any) => type.name === selectedInventorySubtype)[0]
      selectedSubtype && refreshBrandsList(selectedSubtype.typeId)
    } else {
      setBrandOptions([])
    }
  }, [selectedInventorySubtype, inventorySubtypes]);

  const InventoryNameAutocomplete = () =>
    <SearchSuggestionWrapper>
      {titleHintsList.map((titleHint:any) =>
        <SearchSuggestionItem
          onMouseEnter={() => toggleHintItemFocused(true)}
          onMouseLeave={() => toggleHintItemFocused(false)}
          onMouseDown={() => {
            setInventoryName(titleHint.name);
            setSelectedInventoryType(titleHint.category);
            setSelectedInventorySubtype(titleHint.name);
            form.setFieldsValue({inventoryType: titleHint.category});
            toggleHintItemFocused(false);
            toggleTitleFocused(false);
            setTitleHintsList([]);
          }}>
          {titleHint.name}
        </SearchSuggestionItem>
      )}
    </SearchSuggestionWrapper>

  return (
    <>
      <ExpenseDrawer
        work={null}
        setEditingExpenseData={setEditExpenseData}
        expense={editExpenseData}
        isOpen={isExpenseDrawerOpen}
        toggleDrawerOpen={toggleExpenseDrawerVisibility}
        width={width ? width - 5 : 55}
        drawerTitle={_.isEmpty(inventoryName) ? "Content " : inventoryName}
        inventory={inventory ? inventory.data : null}
        refreshParent={refreshInventory}
        icon={<InventoriesIcon />}
        contact={null}
        invoiceData={invoiceData}
        deleteIfNotSaved={deleteIfNotSaved}
        parentRecordName={inventoryName}
      />
    {inventory && <AddExistingExpenseDrawer
        isOpen={isExistingExpenseDrawerOpen}
        toggleDrawerOpen={toggleExistingExpenseDrawer}
        refreshParent={refreshInventory}
        parentId={inventory.data.id}
        parentType={"inventory"}
        drawerTitle={_.isEmpty(inventoryName) ? "Inventory > Add expense" : inventoryName+" > Add expense"}
    />}
    <Drawer
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? (width ? width + "%" : "55%") : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => {
        if (formFieldsChanged) {
          confirmCloseUnsaved();
          return;
        }
        if (inventory && _.isEmpty(inventory.data.name)) {
          inventory && removeInventory(inventory.data.propertyId, inventory.data.id).then(
            () => {
              inventory && dispatch(getAllInventories(inventory.data.propertyId));
            }
          )
        } else {
          inventory && dispatch(getAllInventories(propertyId));
        }
        toggleDrawerOpen(false);
        _.isUndefined(setEditingInventoryData)
          ? setEditInventoryData(null)
          : setEditingInventoryData(null);
        form.resetFields();
      }}
      headerStyle={{
        backgroundColor: "#ebf9ff",
        margin: 0,
        padding: "2rem 2rem",
      }}
      title={
        <>
          <section style={{ display: "flex", justifyContent: "space-between" }}>
            <InventoriesIconWrapper>
              {icon ? icon : <InventoriesIcon />}{" "}
              <span style={{ paddingRight: "0.25rem" }}>
                {drawerTitle ? drawerTitle + " > " : "Content"}
                {drawerTitle && <b>Content</b>}
              </span>
            </InventoriesIconWrapper>
            <ButtonsWrapper>
              <CloseButton
                size={"large"}
                onClick={() => {
                  if (formFieldsChanged) {
                    confirmCloseUnsaved();
                    return;
                  }
                  if (inventory && _.isEmpty(inventory.data.name)) {
                    inventory && removeInventory(inventory.data.propertyId, inventory.data.id).then(
                      () => {
                        inventory && dispatch(getAllInventories(inventory.data.propertyId));
                      }
                    )
                  } else {
                    inventory && dispatch(getAllInventories(propertyId));
                  }
                  toggleDrawerOpen(false);
                  _.isUndefined(setEditingInventoryData)
                    ? setEditInventoryData(null)
                    : setEditingInventoryData(null);
                  form.resetFields();
                }}
              >
                {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
              </CloseButton>
            </ButtonsWrapper>
          </section>
          <Dropdown visible={inventoryName?.length > 0 && titleHintsList?.length > 0 && (isTitleFocused || isHintItemFocused)} overlay={<InventoryNameAutocomplete/>}>
            <Title
              ref={titleInput}
              placeholder={"Content name"}
              value={inventoryName}
              style={{ fontSize: "2.5rem", color: "#002A43" }}
              className={titleError ? "form-title-error" : ""}
              maxLength={100}
              onFocus={() => toggleTitleFocused(true)}
              onBlur={() => toggleTitleFocused(false)}
              onChange={(e) => {
                setFormFieldsChanged(true);
                setInventoryName(e.target.value);
                proposeTitleHints(e.target.value);
                if (e.target.value) {
                  setTitleError(false);
                } else {
                  setTitleError(true);
                }
              }}
            />
          </Dropdown>
          {titleError && (
            <div
              role="alert"
              style={{
                marginBottom: "-1.8rem",
                marginTop: "0.4rem",
                color: "#ff4d4f",
                fontWeight: 400,
                fontSize: "0.9rem",
              }}
            >
              Content name is required
            </div>
          )}
          {inventoryDetails && inventory && (
              <InventoryCostsWrapper>
                {inventory.data.purchaseCost > 0 && (
                  <CostSummaryColumn>
                    <CostSummaryTitle>
                      <YellowDot />
                      <span style={{ marginLeft: "0.3rem", fontWeight: 500 }}> PURCHASE COST </span>
                    </CostSummaryTitle>
                    <CostSummaryNumber>
                      {getCurrencySign(propertyPreferences.preferredCurrency)}
                      {numberWithCommas(inventory.data.purchaseCost)}
                    </CostSummaryNumber>
                  </CostSummaryColumn>
                )}
                {inventoryDetails.expenses && inventoryDetails.expenses.length > 0 && (
                  <CostSummaryColumn>
                    <CostSummaryTitle>
                      <PinkDot />
                      <span style={{ marginLeft: "0.3rem" }}>EXPENSES</span>
                    </CostSummaryTitle>
                    <CostSummaryNumber>
                      {getCurrencySign(propertyPreferences.preferredCurrency)}
                      {numberWithCommas(
                        inventoryDetails.expenses.reduce(
                          (sumOfExpenses, { totalCost }) =>
                            sumOfExpenses + totalCost,
                          0
                        )
                      )}
                    </CostSummaryNumber>
                  </CostSummaryColumn>
                )}
                {inventoryDetails.inventoryWorks && inventoryDetails.inventoryWorks.length > 0
                && countTotalExpense(inventoryDetails.inventoryWorks) > 0 && (
                <CostSummaryColumn>
                  <CostSummaryTitle>
                    <WorksDotIcon/>
                    <span style={{ marginLeft: "0.3rem" }}>PROJECTS</span>
                  </CostSummaryTitle>
                  <CostSummaryNumber>
                    {getCurrencySign(propertyPreferences.preferredCurrency)}
                    {numberWithCommas(countTotalExpense(inventoryDetails.inventoryWorks))}
                  </CostSummaryNumber>
                </CostSummaryColumn>
                )}
              </InventoryCostsWrapper>)}
              {inventoryDetails && inventory && (
                <InventoryCostsCarousel
                  arrows
                  nextArrow={<ArrowRightBlue style={{cursor: "pointer"}} />}
                  prevArrow={<ArrowLeftBlue style={{cursor: "pointer"}} />}
                >
                  {inventory.data.purchaseCost > 0 && (
                    <InventoryCostsSlide>
                      <CostSummaryTitle>
                        <YellowDot />
                        <span style={{ marginLeft: "0.3rem", fontWeight: 500 }}> PURCHASE COST </span>
                      </CostSummaryTitle>
                      <CostSummaryNumberMobile>
                        {getCurrencySign(propertyPreferences.preferredCurrency)}
                        {numberWithCommas(inventory.data.purchaseCost)}
                      </CostSummaryNumberMobile>
                    </InventoryCostsSlide>
                  )}
                  {inventoryDetails.expenses && inventoryDetails.expenses.length > 0 && (
                    <InventoryCostsSlide>
                      <CostSummaryTitle>
                        <PinkDot />
                        <span style={{ marginLeft: "0.3rem" }}>EXPENSES</span>
                      </CostSummaryTitle>
                      <CostSummaryNumberMobile>
                        {getCurrencySign(propertyPreferences.preferredCurrency)}
                        {numberWithCommas(
                        inventoryDetails.expenses.reduce(
                          (sumOfExpenses, { totalCost }) =>
                            sumOfExpenses + totalCost,
                          0
                        )
                      )}
                      </CostSummaryNumberMobile>
                  </InventoryCostsSlide>
                  )}
                  {inventoryDetails.inventoryWorks &&
                  inventoryDetails.inventoryWorks.length > 0 && (
                    <InventoryCostsSlide>
                      <CostSummaryTitle>
                        <WorksDotIcon/>
                        <span style={{ marginLeft: "0.3rem" }}>PROJECTS</span>
                      </CostSummaryTitle>
                      <CostSummaryNumberMobile>
                        {getCurrencySign(propertyPreferences.preferredCurrency)}
                        {numberWithCommas(
                          inventoryDetails.inventoryWorks.reduce(
                            (sumOfExpenses, { workTotalExpense }) =>
                              sumOfExpenses + workTotalExpense,
                            0
                          )
                        )}
                      </CostSummaryNumberMobile>
                    </InventoryCostsSlide>
                  )}

                </InventoryCostsCarousel>
              )}
        </>
      }
    >
      <WorkDrawer
        isOpen={isNewWorkDrawerOpen}
        toggleDrawerOpen={toggleAddNewWorkDrawer}
        work={editWorkData}
        refreshInventoryParent={refreshInventoryFromWork}
        setEditingWorkData={setEditWorkData}
        width={null}
        icon={<InventoriesIcon />}
        drawerTitle={_.isEmpty(inventoryName) ? "Content " : inventoryName}
      />
      <AddExistingWorkDrawer
        isOpen={isExistingWorkDrawerOpen}
        toggleDrawerOpen={toggleAddExistingWorkDrawer}
        refreshParent={refreshInventory}
        parentId={inventoryDetails.inventoryId}
        drawerTitle={_.isEmpty(inventoryName) ? "Content > Add project" : inventoryName+" > Add project"}
        parentType={"work"}
        childType={"inventory"}
      />
      <AddExistingUpkeepDrawer
        isOpen={isExistingUpkeepDrawerOpen}
        toggleDrawerOpen={toggleAddExistingUpkeepDrawer}
        refreshParent={refreshInventory}
        parentId={inventoryDetails.inventoryId}
        drawerTitle={_.isEmpty(inventoryName) ? "Content > Add Upkeep" : inventoryName + " > Add Upkeep"}
        parentType={"inventory"}
        childType={""}
      />
      <UpdateTaskDrawer
        task={editingTaskData}
        setEditingTaskData={setEditingTaskData}
        isOpen={isTaskDrawerOpen}
        toggleDrawer={toggleTaskDrawer}
        taskType={taskType}
        setTaskType={setTaskType}
        inventory={inventory ? inventory.data.id : null}
        refreshParent={refreshInventory}
      />
      <AttachmentsOnDrawer
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        alreadyAttachedFiles={inventoryDetails.attachments}
        isOpen={showFileUploadList}
        toggleDrawerOpen={setShowFileUploadList}
        icon={<InventoriesIcon />}
        drawerTitle={_.isEmpty(inventoryName) ? "Content > Add Attachment" : inventoryName+" > Add Attachment"}
      >
        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
          <AttachButtonStyled onClick={() => {
            selectedFiles.forEach(selectedFile => {
              setShowFileUploadList(false)
              inventory &&
              addRelation({
                propertyId,
                parentId: inventory.data.id,
                childId: selectedFile,
                parentType: "inventory",
                childType: "attachment",
              }).then(() => {
                refreshInventory();
              });
            })
            setSelectedFiles(new Set());
          }}>
            Attach
          </AttachButtonStyled>
        </div>
      </AttachmentsOnDrawer>
      <Form
        form={form}
        name="manageInventory"
        layout="vertical"
        size={"large"}
        onFinish={handleManageInventory}
        initialValues={initialValues()}
        onFieldsChange={() => setFormFieldsChanged(true)}
      >
        <>
          <section>
            <InputsWrapper style={{ paddingBottom: "0.5rem" }}>
              <InputWrapper>
                <Label>Content category *</Label>
                <Form.Item
                  key={"inventoryType"}
                  name={"inventoryType"}
                  rules={[
                    {
                      required: true,
                      message: f(messages.validationTypeOfInventory),
                    },
                  ]}
                >
                  <Select
                    placeholder={"Please select"}
                    getPopupContainer={trigger => trigger.parentElement}
                    bordered={false}
                    style={{
                      width: "100%",
                      border: "1px solid #DAE0E6",
                      boxSizing: "border-box",
                      borderRadius: "6px",
                      color: "rgb(107,113,133)",
                    }}
                    onChange={(val) => setSelectedInventoryType(val.toString())}
                  >
                    <Select.Option key={"Appliances"} value={"Appliances"}>Appliances</Select.Option>
                    <Select.Option key={"Electronics"} value={"Electronics"}>Electronics</Select.Option>
                    <Select.Option key={"Furniture"} value={"Furniture"}>Furniture</Select.Option>
                    <Select.Option key={"Systems"} value={"Systems"}>Systems</Select.Option>
                    <Select.Option key={"ToolsAndMachinery"} value={"ToolsAndMachinery"}>Tools And Machinery</Select.Option>
                    <Select.Option key={"Other"} value={"Other"}>Other</Select.Option>
                  </Select>
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Purchase cost</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"purchaseCost"}
                  key={"purchaseCost"}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/,
                      message: f(messages.validationCostOfInventory),
                    },
                  ]}
                >
                  <InputNumberStyled
                    maxLength={10}
                    className={propertyPreferences.preferredCurrency}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Content type</Label>{" "}
                </LabelWrapper>
                <Form.Item>
                  <CreatableSelect
                    isClearable
                    isValidNewOption={() => false}
                    noOptionsMessage={() => null}
                    onChange={handleSelectChange}
                    placeholder={"Please select"}
                    isDisabled={!inventoryTypesWithSubtypes.includes(selectedInventoryType)}
                    value={inventorySubtypeOptions.filter((type: any) => type.value === selectedInventorySubtype)}
                    options={inventorySubtypeOptions}
                    className={'react-select-container'}
                    classNamePrefix={"react-select"}
                    styles={style}
                    theme={(theme: any) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#e6f7ff',
                        primary: '#f5f5f5'
                      },
                    })}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper style={{ paddingBottom: "0.5rem" }}>
                <Label>Brand</Label>
                <Form.Item key={"brand"} name={"brand"}>
                  <AutoCompleteStyled
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    showSearch
                    allowClear={true}
                    placeholder={"Select type"}
                    bordered={false}
                    showArrow={false}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={onBrandAutocompleteSearch}
                    // @ts-ignore
                    options={brandOptions}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper style={{ paddingBottom: "0.5rem" }}>
                <Label>Model</Label>
                <Form.Item key={"model"} name={"model"}>
                  <InputStyled maxLength={100} />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Serial number</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"serialNumber"}
                  key={"serialNumber"}>
                  <InputStyled maxLength={100} />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Purchase date</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"installedOrPurchaseDate"}
                  key={"installedOrPurchaseDate"}
                >
                  <DatePicker
                    name={"installedOrPurchaseDate"}
                    format={propertyPreferences.preferredDateFormat}
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    allowClear={true}
                    inputReadOnly={true}
                    style={{
                      width: "100%",
                      border: "1px solid #DAE0E6",
                      boxSizing: "border-box",
                      borderRadius: "6px",
                      color: "rgb(107,113,133)",
                    }}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Warranty expiry date</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"warrantyExpiration"}
                  key={"warrantyExpiration"}
                >
                  <DatePicker
                    name={"warrantyExpiration"}
                    format={propertyPreferences.preferredDateFormat}
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    allowClear={true}
                    inputReadOnly={true}
                    style={{
                      width: "100%",
                      border: "1px solid #DAE0E6",
                      boxSizing: "border-box",
                      borderRadius: "6px",
                      color: "rgb(107,113,133)",
                    }}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Insurance</Label>
                  <Tooltip
                    placement="bottomRight"
                    color={"#1890ff"}
                    title={<>
                        <div>Insurance</div>
                        <div>
                          Your appliances can be insured by a Home Warranty Policy.
                          Your personal belongings can be insured by a contents policy.
                        </div>
                      </>}>
                    <HelpIcon style={{marginRight: '1rem'}} />
                  </Tooltip>
                </LabelWrapper>
                <>
                  <Checkbox
                    checked={insuredItems}
                    onClick={() => {
                      setInsuredItems(!insuredItems);
                    }}
                    style={{ paddingRight: "0.5rem", fontSize: "1rem" }}
                  >
                    Include in insured items
                  </Checkbox>
                </>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Lifetime</Label>{" "}
                </LabelWrapper>
                <Form.Item>
                  <InputStyled disabled value={applianceLifetime} />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
          </section>

          {inventory && (
            <>
              <div style={{paddingTop: "2.5rem", display: "flex", justifyContent: "space-between", marginBottom: "1em"}}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <NotesDrawerIcon style={{marginRight: "0.75rem", height: "1.5em", marginTop: "0.2em"}}/>
                  <span style={{fontSize: "1.125rem", fontWeight: 600, color: "#21272B"}}>
                    Notes
                  </span>
                </div>
              </div>
              <div>
                <Form.Item name={"notes"} key={"notes"}>
                <TextAreaStyled
                  showCount
                  maxLength={2500}
                  rows={5}
                  placeholder={"Type your notes..."}
                />
                </Form.Item>
              </div>
              <div style={{paddingTop: "3rem", display: "flex", justifyContent: "space-between", marginBottom: "1em"}}>
                <div style={{ display: "flex"}}>
                  <MaintenanceIcon style={{marginRight: "0.75rem", height: "1.5em", marginTop: "0.2em"}}/>
                  <span style={{fontSize: "1.125rem", fontWeight: 600, color: "#21272B"}}>
                    Related Maintenance
                  </span>
                </div>
                <Dropdown trigger={["click", "hover"]} arrow overlay={upkeepMenu}>
                  <PlusButtonStyledLists>
                    <AddCircle />
                  </PlusButtonStyledLists>
                </Dropdown>
              </div>
              {inventory && inventoryDetails.upkeeps.length > 0 && <List
                itemLayout="vertical"
                dataSource={inventoryDetails.upkeeps.sort((u1: any, u2: any) => u1.title.localeCompare(u2.title))}
                renderItem={(item: any) => <InventoryUpkeepListItem
                  item={item}
                  inventory={inventory.data.id}
                  editing={editing}
                  setEditing={setEditing}
                  toggleUpkeepDrawer={toggleTaskDrawer}
                  setEditingUpkeepData={setEditingTaskData}
                  refreshParent={refreshInventory}
                />}
              />}
              <div style={{paddingTop: "3rem", display: "flex", justifyContent: "space-between", marginBottom: "1em"}}>
                <div style={{ display: "flex" }}>
                  <MaintenanceIcon style={{marginRight: "0.75rem", height: "1.5em", marginTop: "0.2em"}}/>
                  <span style={{fontSize: "1.125rem", fontWeight: 600, color: "#21272B"}}>
                    Projects
                  </span>
                </div>
                <Dropdown trigger={["click", "hover"]} arrow overlay={worksMenu}>
                  <PlusButtonStyledLists>
                    <AddCircle />
                  </PlusButtonStyledLists>
                </Dropdown>
              </div>
                {inventoryDetails.inventoryWorks === worksInitial ||
                inventoryDetails.inventoryWorks.length < 1 ? (
                  <Separator />
                ) : (
                  <BorderSection>
                  <InventoryWorksListComp
                    works={inventoryDetails.inventoryWorks}
                    parentToClose={toggleDrawerOpen}
                    drawerTitle={inventoryName}
                    refreshParent={refreshInventory}
                    inventory={inventory ? inventory.data : null}
                    defaultOpenType={null}
                    setDefaultOpenType={(arg01) => {}}
                    //@ts-ignore
                    setViewWorkId={setViewWorkId}
                    //@ts-ignore
                    toggleViewWorkDrawer={toggleViewWorkDrawer}
                  />
                  </BorderSection>
                )}
              <div style={{paddingTop: "3rem", display: "flex", justifyContent: "space-between", marginBottom: "1em"}}>
                <div style={{ display: "flex" }}>
                  <ExpensesIcon style={{marginRight: "0.75rem", height: "1.5em", marginTop: "0.2em"}}/>
                  <span style={{fontSize: "1.125rem", fontWeight: 600, color: "#21272B"}}>
                    Expenses
                  </span>
                </div>
                <Dropdown trigger={["click", "hover"]} overlay={expenseMenu}>
                  <PlusButtonStyledLists>
                    <AddCircle />
                  </PlusButtonStyledLists>
                </Dropdown>
              </div>
                {inventoryDetails.expenses === expensesInitial ||
                inventoryDetails.expenses.length < 1 ? (<Separator />) : (
                  <BorderSection>
                  <List
                    style={{
                      width: "100%",
                      minWidth: "300px",
                      alignItems: "center",
                      borderBottom: "1px",
                      borderBottomLeftRadius: "6px",
                      borderBottomRightRadius: "6px",
                    }}
                    itemLayout="horizontal"
                    dataSource={inventoryDetails.expenses}
                    renderItem={(item) => <ExpenseListItem
                      item={item}
                      work={null}
                      inventory={inventory ? inventory.data : null}
                      contact={null}
                      drawerToClose={toggleDrawerOpen}
                      refreshParent={refreshInventory}
                      toggleExpenseDrawer={toggleExpenseDrawerVisibility}
                      setEditExpenseData={setEditExpenseData}
                    />
                    }
                  />
                  </BorderSection>
                )}
              <div style={{paddingTop: "3rem", display: "flex", justifyContent: "space-between", marginBottom: "1em",}}>
                <div style={{ display: "flex" }}>
                  <AttachmentIcon style={{marginRight: "0.75rem", height: "1.5em", marginTop: "0.2em"}}/>
                  <span style={{fontSize: "1.125rem", fontWeight: 600, color: "#21272B"}}>
                    Attachments
                  </span>
                </div>
                <Tooltip placement="top" title={"You can upload PDF, Image, Word, CSV and Excel files up to 20MB."}>
                  <HelpIcon />
                </Tooltip>
              </div>
              {inventoryDetails.attachments !== attachmentsInitial &&
                inventory &&
                inventory.data.id &&
                !Object.is(inventoryDetails.inventoryId, emptyGuid) && (
                  <UploadFile
                    parentType={"inventory"}
                    parentId={inventoryDetails.inventoryId}
                    propertyId={propertyId}
                    uploadImmediately={true}
                    singleAttachment={false}
                    fileList={inventoryDetails.attachments}
                    refreshParent={refreshInventory}
                    highlightAttachment={highlightAttachment}
                    setShowFileUploadList={setShowFileUploadList}
                  />
                )}
            </>
          )}
            <SaveButtonWrapper>
              <SaveButton
                disabled={saveDisabled}
                style={{display: "flex", alignItems: "center"}}
                type={"primary"}
                htmlType={"submit"}
                onClick={() => _.isEmpty(inventoryName) && setTitleError(true)}
              >
                Save
              </SaveButton>
            </SaveButtonWrapper>
        </>
      </Form>
    </Drawer>
    </>
  );
};

// const CreateInventoryButtonWrapper = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   padding-top: 1rem;
// `;
//
// const SaveIconStyled = styled(SaveIcon)`
//   padding-right: 0.25rem;
//   width: 22px;
//   height: 22px;
// `;

const Title = styled(Input)`
  border: none;
  outline: none;
  background-color: #ebf9ff;
  margin-top: 0.5rem;

  & > *:focus {
    border-color: #57a8e9;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }

  .ant-input:hover {
    border-color: #ebf9ff;
  }
`;

const InventoriesIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;

  & > *:last-child {
    margin-left: 1.5rem;
  }
`;

export const BorderSection = styled.section`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 10px;
`;

const InputStyled = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
  color: rgb(107, 113, 133);
`;

const CostSummaryTitle = styled.span`
  display: flex;
  align-items: center;
  margin-left: 0.2em;
  font-size: 1em;
`;

const CostSummaryNumber = styled.div`
  font-weight: 500;
  padding: 0.5em 0.5em 0.5em 0;
  margin-top: 0.5em;
  font-size: 2.5em;
  // text-align: right;
`;

const CostSummaryColumn = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1.5em;
  padding-right: 2.5em;
  width: 15em;
`;

const InventoryCostsWrapper = styled.span`
  @media (max-width: 1024px) {
    display: none!important;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const InventoryCostsCarousel = styled(Carousel)`
  @media (min-width: 1024px) {
    display: none!important;
  }

  height: 4rem;
  
  & > ul.slick-dots.slick-dots-bottom li { 
    display: none 
  }

  & > svg.slick-arrow.slick-prev {
    width: 2.5rem; 
    height: 2.5rem;
    z-index: 2;
  }
  
  & > svg.slick-arrow.slick-next {
    width: 2.5rem;
    height: 2.5rem;
    z-index: 2;
  }
`

const InventoryCostsSlide = styled.div`
  text-align: center;
  color: black;
  margin-top: 0.5rem;
`

const CostSummaryNumberMobile = styled.div`
  font-weight: 500;
  // padding: 0.5em 0.5em 0.5em 0;
  // margin-top: 0.5em;
  font-size: 2.5em;
  text-align: center;
`;

const SearchSuggestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
  background: #FFFFFF;
  border: 1px solid #DAE0E6;
  box-sizing: border-box;
  box-shadow: 0px 1px 5px rgba(30, 32, 37, 0.05), 0px 10px 24px rgba(26, 28, 31, 0.1);
  border-radius: 0px 0px 10px 10px;
`

const SearchSuggestionItem = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #45655E;
  padding: 1rem;
  border-bottom: 1px solid #EFF1F5;
  transition: all .5s ease;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  :last-child {
    border-bottom: 1px solid white;
  }
  
  :hover {
    color: #21272B;
    background: #F5F6FA;
    border-radius: 8px;
    border-bottom: 1px solid white;
    transition: background .5s ease;
  }
  
  :hover svg path {
    stroke: #21272B;
    transition: stroke .5s ease;
  }
`

export default InventoryDrawer;
