import React, {useEffect, useState} from "react";
import {Guid} from "guid-typescript";
import {useDispatch, useSelector} from "react-redux";
import {getExpensesSelector, getPropertyIdSelector} from "../../selectors";
import {addRelation} from "../../../api-wrapper/relation/addRelation";
import {
  ButtonStyled,
  ButtonWrapper,
  FieldLabel,
  FormHeader,
  FormWrapper,
  Title
} from "../inventories/AddExistingWorkDrawer";
import {Drawer, Select} from "antd";
import _ from "lodash";
import styled from "styled-components";
import {ReactComponent as ExpensesIcon} from "../../../images/ExpensesPink.svg";
import {editExpense} from "../../../api-wrapper/expenses/editExpense";
import {getAllExpenses} from "../../actions/expenses";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  refreshParent: () => void;
  parentId: Guid;
  parentType: string;
  drawerTitle: string;
};

const AddExistingExpenseDrawer = (props: Props) => {
  const { isOpen, toggleDrawerOpen, parentId, parentType, refreshParent, drawerTitle } = props;
  const propertyId = useSelector(getPropertyIdSelector).value;
  const expenses = useSelector(getExpensesSelector);
  const dispatch = useDispatch();

  const [relatedItems, setRelatedItems] = useState<{ key: Guid; value: string }[]>([]);
  const [expenseId, setExpenseId] = useState<any>(null);

  const filterCondition = (expense: any) => {
    if (parentType === "contact") return !expense.payee
    // @ts-ignore
    if (parentType === "inventory") return !expense.inventoriesCount
    else return true
  }

  const clearDrawer = () => {
    toggleDrawerOpen(false);
    setExpenseId(null);
  }

  const linkToParent = async () => {
    return await addRelation({
      propertyId,
      parentId,
      parentType,
      childId: expenseId,
      childType: "expense",
    })
  }

  const setParentAsPayee = async () => {
    const expenseToLink = expenses.content.filter(expense => expense.expenseId === expenseId)[0]
    return await editExpense({...expenseToLink, payee: parentId})
  }

  const addExpenseRelation = async () => {
    if (parentType === "contact") await setParentAsPayee();
    await linkToParent();
    dispatch(getAllExpenses(propertyId));
    refreshParent();
    clearDrawer();
  };

  const onExpenseAutocompleteSearch = (searchText: string) => {
    setRelatedItems(!searchText ? expenses.content.filter(expense => expense.name && filterCondition(expense)).map((expense) => {
        return {key: expense.expenseId, value: expense.name}
      }) : expenses.content.filter(expense => expense.name && filterCondition(expense)).filter((expense) =>
        expense.name.toLowerCase().includes(searchText.toLowerCase())).map((expense) => {
        return {key: expense.expenseId, value: expense.name}
      })
    )
  }

  useEffect(() => {
    setRelatedItems(
      expenses.content.filter(expense => expense.name && filterCondition(expense)).map((expense) => {
        return {key: expense.expenseId, value: expense.name}
      })
    )
  }, [expenses]);

  return (
    <Drawer
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? "55%" : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => clearDrawer()}
      headerStyle={{backgroundColor: "#ebf9ff", margin: 0, padding: "2rem 2rem"}}
      title={
        <>
          <section style={{ display: "flex", justifyContent: "space-between" }}>
            <ExpensesIconWrapper>
              <ExpensesIcon />
              <span style={{ paddingRight: "0.25rem" }}>{drawerTitle}</span>
            </ExpensesIconWrapper>
            <HeaderButtonStyled size={"large"} onClick={() => clearDrawer()}>
              <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span>
            </HeaderButtonStyled>
          </section>
          <Title>Add an expense item</Title>
        </>
      }
    >
      <FormHeader>Select existing expenses</FormHeader>
      <FormWrapper>
        <FieldLabel>Search your expenses</FieldLabel>
        <Select
          placeholder={"Search your expenses..."}
          value={expenseId}
          showSearch
          size={"large"}
          bordered={false}
          showArrow={false}
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={onExpenseAutocompleteSearch}
          style={{
            width: "100%",
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "6px",
            color: "rgb(107,113,133)",
          }}
          // @ts-ignore
          onChange={(val) => setExpenseId(val)}
        >
          {relatedItems.map((relateItem) => (
            <Select.Option key={relateItem.value} value={relateItem.key.toString()}>
              {relateItem.value}
            </Select.Option>
          ))}
        </Select>
      </FormWrapper>
      <ButtonWrapper>
        <ButtonStyled
          disabled={_.isEmpty(expenseId)}
          htmlType={"submit"}
          type={"primary"}
          size={"large"}
          onClick={addExpenseRelation}
        >+ Add an Expense
        </ButtonStyled>
      </ButtonWrapper>
    </Drawer>
  )
}

const ExpensesIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

const HeaderButtonStyled = styled(ButtonStyled)`
  background-color: #ebf9ff;
`;

export default AddExistingExpenseDrawer