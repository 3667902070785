import { apiDelete, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export const removeInventory = (propertyId: Guid, inventoryId: Guid, deleteReplacementUpkeep?: boolean) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/inventory/${inventoryId}${deleteReplacementUpkeep ? "?deleteReplaceUpkeep=true" : ""}`
  );

  return apiDelete(url);
};
