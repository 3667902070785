import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TTag} from "../tags/addNewTag";

export type TGetContactsResponse = Array<{
  name: string;
  surname: string;
  contactId: Guid;
  propertyId: Guid;
  contactType: string;
  company: string;
  email: string;
  expensesCount: number;
  worksCount: number;
  expensesTotalCost: number;
  phone: string;
  website: string;
  notes: string;
  tags: Array<TTag>;
  emergency: boolean;
}>;

export const getContacts = (propertyId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/contacts`,
    {}
  );

  return apiGet<TGetContactsResponse | []>(url, {}, propertyId);
};
