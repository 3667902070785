import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TRecipient} from "./referFriends";
import {Guid} from "guid-typescript";

export type TForwardInventoryRequest = {
  customMessage: string;
  ccSender: boolean;
  recipients: Array<TRecipient>;
  propertyId: Guid;
  inventoryId: Guid;

  isPropertyAddressEnabled: boolean;
  isInventoryNameEnabled: boolean;
  isInventoryTypeEnabled: boolean;
  isPurchaseCostEnabled: boolean;
  isPurchaseDateEnabled: boolean;
  isApplianceTypeEnabled: boolean;
  isBrandEnabled: boolean;
  isModelEnabled: boolean;
  isSerialNumberEnabled: boolean;
  isWarrantyExpiryDateEnabled: boolean;
  isInsuredEnabled: boolean;
  isInventoryNotesEnabled: boolean;
  attachments: Array<Guid>;
};

export const forwardInventory = (payload: TForwardInventoryRequest) => {
  const {inventoryId} = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/forwardInventory/${inventoryId}/send`
  );

  return apiPost<any, TForwardInventoryRequest>(
    url, payload
  );
};
