import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TGetRecommendedImprovementsResponse = Array< {
  id: string,
  parentId: string,
  name: string,
  summary: string,
  description: string,
  category: number,
  subCategory: number,
  priority: number,
  doneByAnswerOn: string,
  doneOn: string,
  wishListedOn: string,
  givenAnswerContext: string,
  displayOrder: number,
  keywords: Array<string>,
  relatedImprovements:  Array<string>,
  relatedWorkId: Guid,
  incentives: Array<any>,
  ecoImpactRating: number,
  livletRating: number,
  savingsRating: number,
  costRating: number,
  specialtyOfWorkRequired: string,
  relativeEffortOfWorkRequired: string,
  co2ReductionRating: number,
  plantedTreesPerYearEquivalent: number,
  relatedQuestions: Array<any>,
  includedRelatedImprovements: Array<string>,
  doNotRecommendUntil: string,
  guidanceCardCost: number,
  guidanceCardSustainability: number,
  guidanceCardSavings: number,
  guidanceCardSafety: number,
  guidanceCardEffort: number,
  guidanceCardHealthComfort: number,
  guidanceSortingCost: number,
  guidanceSortingSustainability: number,
  guidanceSortingSavings: number,
  guidanceSortingSafety: number,
  guidanceSortingEffort: number,
  guidanceSortingHealthComfort: number
}>;

export const getRecommendedImprovements = (propertyId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/recommendedImprovements`);

  return apiGet<TGetRecommendedImprovementsResponse | []>(url, {}, propertyId);
};

