import React, {useEffect, useState} from "react";

import {ReactComponent as WorksRecord} from "../../../images/records/WorksRecord.svg";
import {ReactComponent as WorksIcon} from "../../../images/records/WorksIcon.svg";
import {ReactComponent as PlusIcon} from "../../../images/records/PlusIcon.svg";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getWorksSelector} from "../../selectors";
import {
  AlertDot, AlertOverlay,
  AlertWrapper,
  CardContentsItem,
  CardContentsWrapper,
  CardTitle,
  PlusIconWrapper,
  RecordsCard
} from "./SpaceRecordsCard";
import {useHistory} from "react-router-dom";
import {addWork} from "../../../api-wrapper/works/addWork";
import {getAllWorks} from "../../actions/works";
import moment from "moment";
import {Dropdown} from "antd";

type Props = {
  toggleDrawerOpen: (arg: boolean) => void;
  setEditingData: (arg: any) => void;
}

const WorkRecordsCard = (props: Props) => {
  const {toggleDrawerOpen, setEditingData} = props;

  const works = useSelector(getWorksSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentCount, setCurrentCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [overdueCount, setOverdueCount] = useState(0);

  const addNewWork = () => {
    addWork({
      propertyId,
      name: null,
      type: null,
      completedDate: null,
      dueDate: null,
      recurring: null,
      isComplete: false,
    }).then((res) => {
      setEditingData(res);
      dispatch(getAllWorks(propertyId));
      toggleDrawerOpen(true);
    });
  }

  useEffect(() => {
    setCurrentCount(works.content.filter((work) => work.name && !work.completedDate).length)
    setCompletedCount(works.content.filter((work) => work.name && work.completedDate).length)
    setOverdueCount(works.content.filter((work) => work.name && moment(work.dueDate).isBefore(moment()) && !work.completedDate).length)
  }, [propertyId, works])

  return <RecordsCard
    className={"work-records"}
    onClick={() => history.push("/projects")}
  >
    <CardTitle>
      <AlertWrapper>
        {overdueCount > 0 && <Dropdown
            placement={"topCenter"}
            overlay={<AlertOverlay>{overdueCount} Project item(s) overdue</AlertOverlay>}>
            <AlertDot>{overdueCount}</AlertDot>
        </Dropdown>}
        <WorksRecord />
      </AlertWrapper>
        Projects
      <PlusIconWrapper onClick={(e) => {
        e.stopPropagation()
        addNewWork()
      }}><PlusIcon /></PlusIconWrapper>
    </CardTitle>
    <CardContentsWrapper>
      <CardContentsItem className={"stroke"}>
        <span className={"item-count"}><WorksIcon /> {currentCount}</span> Current
      </CardContentsItem>
      <CardContentsItem className={"stroke"} onClick={(e) => {
        e.stopPropagation()
        history.push("/projects?type=completed")
      }}>
        <span className={"item-count"}><WorksIcon /> {completedCount}</span> Completed
      </CardContentsItem>
    </CardContentsWrapper>
  </RecordsCard>
}

export default WorkRecordsCard