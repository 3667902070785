import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";
import styled from "styled-components";

import {ReactComponent as MyMaintenancesIcon} from "../../../../images/upkeep-tabs/MyMaintenances.svg";
import {ReactComponent as RecommendedIcon} from "../../../../images/upkeep-tabs/Recommended.svg";
import {ReactComponent as CustomMaintenancesIcon} from "../../../../images/upkeep-tabs/CustomMaintenances.svg";
import {ReactComponent as BudgetingIcon} from "../../../../images/upkeep-tabs/Budgeting.svg";
import {ReactComponent as KitchenLaundryIcon} from "../../../../images/upkeep-tabs/KitchenLaundry.svg";
import {ReactComponent as ElectricityLightningIcon} from "../../../../images/upkeep-tabs/ElectricityLightning.svg";
import {ReactComponent as BathroomsIcon} from "../../../../images/upkeep-tabs/Bathrooms.svg";
import {ReactComponent as ExteriorStructuralIcon} from "../../../../images/upkeep-tabs/ExteriorStructural.svg";
import {ReactComponent as HeatingCoolingGasIcon} from "../../../../images/upkeep-tabs/HeatingCoolingGas.svg";
import {ReactComponent as SafetySecurityIcon} from "../../../../images/upkeep-tabs/SafetySecurity.svg";
import {ReactComponent as InteriorFurnishingIcon} from "../../../../images/upkeep-tabs/InteriorFurnishing.svg";
import {ReactComponent as GardenIcon} from "../../../../images/upkeep-tabs/Garden.svg";
import {ReactComponent as WaterSewageIcon} from "../../../../images/upkeep-tabs/WaterSewage.svg";
import {ReactComponent as HomeCyberSecurityIcon} from "../../../../images/upkeep-tabs/HomeCyberSecurity.svg";
import {ReactComponent as SwimmingPoolIcon} from "../../../../images/upkeep-tabs/SwimmingPool.svg";
import {ReactComponent as RentalRelatedIcon} from "../../../../images/upkeep-tabs/RentalRelated.svg";

export function Card ({itemId, selected, onClick, title}: {
  itemId: string;
  selected: boolean;
  onClick: Function;
  title: string;
}) {
  const visibility = React.useContext(VisibilityContext);
  const visible = visibility.isItemVisible(itemId);

  const iconDispenser = (tabName: string) => {
    switch (tabName) {
      case "My maintenances":
        return <MyMaintenancesIcon />
      case "Recommended":
        return <RecommendedIcon />
      case "Custom":
        return <CustomMaintenancesIcon />
      case "Budgeting":
        return <BudgetingIcon />
      case "Kitchen & Laundry":
        return <KitchenLaundryIcon />
      case "Electricity & Lighting":
        return <ElectricityLightningIcon />
      case "Bathrooms":
        return <BathroomsIcon />
      case "Exterior & Structural":
        return <ExteriorStructuralIcon />
      case "Heating, Cooling & Gas":
        return <HeatingCoolingGasIcon />
      case "Safety & Security":
        return <SafetySecurityIcon />
      case "Interior & Furnishings":
        return <InteriorFurnishingIcon />
      case "Garden":
        return <GardenIcon />
      case "Water & Sewage":
        return <WaterSewageIcon />
      case "Home Cyber security":
        return <HomeCyberSecurityIcon />
      case "Swimming Pool":
        return <SwimmingPoolIcon />
      case "Rental Related":
        return <RentalRelatedIcon />
      default:
        return null;
    }
  }

  return (
    <TabStyled
      onClick={() => onClick()}
      tabIndex={0}
      style={{width: title === "All" ? "5rem" : "11rem"}}
      className={selected ? "upkeep-selected-tab" : ""}>
      {iconDispenser(title)} {title}
    </TabStyled>
  );
}

const TabStyled = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  flex-grow: 10;
  flex-shrink: 0;
  overflow: visible;
  margin-right: 1px;
  cursor: pointer;
  
  background: #DAE0E6;
  padding: 0.75rem;
  border-radius: 6px 6px 0px 0px;
  height: 2.625rem;
  
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #666F7A;
  transition: all .3s;
  
  /*so the user won't select tab contents when dragging*/
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  
  :hover { 
    color: #2A80E1;
    background: rgba(255, 255, 255, 0.5);
    transition: all .3s;
  }
`