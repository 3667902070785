import {Guid} from "guid-typescript";
import {apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";

export type TSetTagsForParentRequest = Array<Guid>;

export const setTagsForParent = (newTags: Array<Guid>, parentId: Guid, parentType: string, propertyId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/tag/property/${propertyId}/set/${parentId}?parentType=${parentType}`
  );

  return apiPost<any, TSetTagsForParentRequest>(url, newTags);
};