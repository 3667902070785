import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TGetNoteByIdResponse = {
  recordId: Guid;
  attachments: Array<{
    attachmentId: Guid;
    propertyId: Guid;
    parentId: Guid;
    parentType: string;
    storageKey: string;
    name: string;
    size: number;
    path: string;
  }>;
};

export const getNoteById = (propertyId: Guid, noteId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/noteDetails/${noteId}`
  );

  return apiGet<TGetNoteByIdResponse>(url, {}, propertyId);
};
