import React, {useEffect, useState} from "react";
import {Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
  authSelector,
  getMaintenancesSelector,
  getPropertyIdSelector, getUpcomingTasksSelector,
} from "../../selectors";

import {TUpcomingTask} from "../../../api-wrapper/upcomingTasks/getUpcomingTasks";
import {getAllUpcomingTasks} from "../../actions/upcomingTasks";
import moment from "moment";
import RescheduleModal from "./RescheduleModal";
import {removeWork} from "../../../api-wrapper/works/removeWork";
import ForwardWorkDrawer from "../works/ForwardWorkDrawer";
import {emptyGuid} from "../../screens/helpers";
import ForwardUpcomingTaskDrawer from "./ForwardUpcomingTaskDrawer";
import UpcomingTaskCalendar from "../upcomingTasksCalendar/UpcomingTaskCalendar";
import WorkDrawer from "../works/WorkDrawer";
import ExpenseDrawer from "../expenses/ExpenseDrawer";
import {getAllCalendarTasks} from "../../actions/calendarTasks";
import TradeDrawer from "../partners/TradeDrawer";
import {confirmDismissSingle, handleSnooze, markReminderAsComplete} from "./UpcomingTaskActions";
import CompletionModal from "./CompletionModal";
import DismissModal from "./DismissModal";
import UpdateTaskDrawer, {getTaskType} from "../planner/UpdateTaskDrawer";
import ViewTaskDrawer, {getTradingOption} from "../planner/ViewTaskDrawer";
import AddEventDrawer from "../planner/AddEventDrawer";
import ViewWorkDrawer from "../works/ViewWorkDrawer";
import LinkDrawer from "../spaces/LinkDrawer";
import EditSpaceDrawer from "../spaces/EditSpaceDrawer";
import InventoryDrawer from "../inventories/InventoryDrawer";
import ContactDrawer from "../contacts/ContactDrawer";
import NoteDrawer from "../notes/NoteDrawer";
import {useHistory} from "react-router-dom";
import ToDoListComp from "../planner/ToDoListComp";
import PlannerCalendar from "../planner/PlannerCalendar";
import {
  ButtonSmallBlue,
  ButtonSmallGreen,
  EmptyRecordsDescription,
  EmptyRecordsHeader,
  EmptyRecordsList, IconWrapper
} from "../../screens/components";
import {ReactComponent as ReminderIcon} from "../../../images/planner/ReminderIconSmall.svg";
import {ReactComponent as TaskIcon} from "../../../images/planner/TaskIconSmall.svg";
import {ReactComponent as WorkIcon} from "../../../images/MaintenceWorksPage.svg";
import {ReactComponent as CostIcon} from "../../../images/planner/CostIconSmall.svg";
import {removeExpense} from "../../../api-wrapper/expenses/removeExpense";
import ViewFileDrawer from "../attachments/ViewFileDrawer";
import { addWork } from "../../../api-wrapper/works/addWork";
import {getAllWorks} from "../../actions/works";
import { addExpense } from "../../../api-wrapper/expenses/addExpense";
import {getAllExpenses} from "../../actions/expenses";
import InsuranceDealDrawer from "../partners/InsuranceDealDrawer";
import MortgagePartnerDrawer from "../partners/MortgagePartnerDrawer";
import InsulationDrawer from "../partners/InsulationDrawer";

type Props = {
  wrapperMode: string;
  upcomingTasks?: Array<TUpcomingTask>;
  dashboardView?: boolean;
  startDate: any;
  setStartDate?: (arg: any) => void;
  refreshParent?: () => void | null;
  totalColumns?: number;
  columnsInRow?: number;
  eventDrawerRequest?: boolean;
  toggleEventDrawerRequest?: (arg: boolean) => void;
};

const UpcomingTasksWrapper = (props: Props) => {
  const { wrapperMode, startDate, setStartDate, refreshParent, totalColumns, columnsInRow,
    eventDrawerRequest, toggleEventDrawerRequest } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const maintenances = useSelector(getMaintenancesSelector);
  const maintenanceTasks = useSelector(getUpcomingTasksSelector);

  const [monthlyView, toggleMonthlyView] = useState(true);
  const [isViewWorkDrawerOpen, toggleViewWorkDrawer] = useState(false);
  const [workToViewId, setWorkToViewId] = useState<any>(null);
  const [isLinkDrawerOpen, toggleLinkDrawer] = useState(false);
  const [linkedRecordId, setLinkedRecordId] = useState<any>(null);
  const [linkedRecordType, setLinkedRecordType] = useState("");
  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editingWorkData, setEditingWorkData] = useState<any>(null);
  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [editingExpenseData, setEditingExpenseData] = useState<any>(null);
  const [isInventoryDrawerOpen, toggleInventoryDrawer] = useState(false);
  const [editingInventoryData, setEditingInventoryData] = useState(null);
  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editingContactData, setEditingContactData] = useState(null);
  const [isNoteDrawerOpen, toggleNoteDrawer] = useState(false);
  const [editingNoteData, setEditingNoteData] = useState(null);
  const [isSpaceDrawerOpen, toggleSpaceDrawer] = useState(false);
  const [editSpaceId, setEditSpaceId] = useState(null);
  const [presetLinkType, setPresetLinkType] = useState<any>(null);
  const [preSelectedTab, setPreSelectedTab] = useState<any>("");
  const [isViewFileDrawerOpen, toggleViewFileDrawer] = useState(false);
  const [attachmentToViewId, setAttachmentToViewId] = useState<any>(null);

  const [taskToComplete, setTaskToComplete] = useState<any>(null);
  const [taskToReschedule, setTaskToReschedule] = useState(null);
  const [taskToDismiss, setTaskToDismiss] = useState(null);
  const [isCompletionModalOpen, toggleCompletionModal] = useState(false);
  const [isRescheduleModalOpen, toggleRescheduleModal] = useState(false);
  const [isDismissModalOpen, toggleDismissModal] = useState(false);

  const [forwardWorkId, setForwardWorkId] = useState(emptyGuid);
  const [forwardReminder, setForwardReminder] = useState(emptyGuid);
  const [isForwardWorkDrawerOpen, toggleForwardWorkDrawer] = useState(false);
  const [isForwardReminderDrawerOpen, toggleForwardReminderDrawer] = useState(false);

  const [taskToEdit, setTaskToEdit] = useState<any>(null);
  const [taskToEditType, setTaskToEditType] = useState("");
  const [isUpdateTaskDrawerOpen, toggleUpdateTaskDrawer] = useState(false);
  const [taskToView, setTaskToView] = useState<any>(null);
  const [taskToViewType, setTaskToViewType] = useState("");
  const [isViewTaskDrawerOpen, toggleViewTaskDrawer] = useState(false);
  const [isEventDrawerOpen, toggleEventDrawer] = useState(false);
  const [isNewEventDrawerOpen, toggleNewEventDrawer]  = useState(false);
  const [isTradeDrawerOpen, toggleTradeDrawer] = useState(false);
  const [selectedTradeType, setSelectedTradeType] = useState("");
  const [isInsuranceDrawerOpen, toggleInsuranceDrawer] = useState(false);
  const [isMortgageDrawerOpen, toggleMortgageDrawer] = useState(false);
  const [isInsulationDrawerOpen, toggleInsulationDrawer] = useState(false);

  const [selectedStartDate, setSelectedStartDate] = useState<any>(undefined);
  const [selectedEndDate, setSelectedEndDate] = useState<any>(undefined);
  const [showRecurring, toggleShowRecurring] = useState(true);
  const [refreshRequest, toggleRefreshRequest] = useState(false);
  const [resetSwiping, toggleResetSwiping] = useState(false);

  const refreshList = (refreshAll?: boolean) => {
    dispatch(getAllUpcomingTasks({
      propertyId,
      startDate: selectedStartDate ? selectedStartDate.format('YYYY-MM-DD') : null,
      endDate: selectedEndDate ? selectedEndDate.format('YYYY-MM-DD') : null,
      firstInSeries: showRecurring
    }))
    if (refreshParent && !refreshAll) refreshParent();
  }

  const refreshCalendar = (refreshAll?: boolean) => {
    dispatch(getAllCalendarTasks(propertyId, startDate.format("YYYY-MM-DD"), monthlyView, totalColumns ?? 7))
    if (refreshParent && !refreshAll) refreshParent();
  }

  const refreshAll = () => {
    refreshCalendar(true);
    refreshList(true);
    if (refreshParent) refreshParent();
  }

  const handleRefresh = () => {
    if (wrapperMode === "calendar" || wrapperMode === "planner-single") refreshCalendar()
    if (wrapperMode === "list" || wrapperMode === "planner-mobile") refreshList()
    if (wrapperMode === "planner-double") refreshAll()
    if (wrapperMode === "calendar-mobile") toggleRefreshRequest(true);
    if (wrapperMode === "planner-mobile" || wrapperMode === "calendar-mobile") toggleResetSwiping(true);
  }

  useEffect(() => {
    handleRefresh()
  }, [propertyId, wrapperMode])

  useEffect(() => {
    refreshList()
  }, [selectedStartDate, selectedEndDate, showRecurring])

  useEffect(() => {
    refreshCalendar()
  }, [startDate, monthlyView])

  useEffect(() => {
    if (eventDrawerRequest) {
      toggleEventDrawer(true);
      toggleEventDrawerRequest && toggleEventDrawerRequest(false);
    }
  }, [eventDrawerRequest])

  function confirmDeleteWork(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        removeWork(item.propertyId, item.workId).then(() => handleRefresh());
      }
    });
  }

  function confirmDeleteExpense(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        removeExpense(item.propertyId, item.expenseId).then(() => handleRefresh());
      }
    });
  }

  const handleReminderAction = (key: any, item: any) => {
    if (key === "snooze") {
      handleSnooze(item.task, handleRefresh)
    }
    if (key === "reschedule") {
      setTaskToReschedule(item.task)
      toggleRescheduleModal(true)
    }
    if (key === "forwardReminder") {
      setForwardReminder(item.task)
      toggleForwardReminderDrawer(true)
    }
    if (key === "dismiss") {
      if (item.periodicity && item.task.periodicityUnit !== "once") {
        setTaskToDismiss(item.task)
        toggleDismissModal(true)
      } else confirmDismissSingle(item.task, handleRefresh)
    }
    if (key === "markAsCompleted") {
      if (item.task.category !== "Reminder") {
        if (moment(item.task.triggerDate).isSameOrBefore(item.task.nextDate)) {
          setTaskToComplete(item.task)
          toggleCompletionModal(true)
        } else {
          Modal.error({
            title: "Error!",
            content: "Only the first active task in the series can be marked as completed.",
            className: "form-confirm-close-modal",
          });
        }
      } else {
        markReminderAsComplete(item.task, handleRefresh)
      }
    }
    if (key === "findTrade") {
      getTradingOption(item.title, auth.subscriptionLevel) === "Find a tradesperson" && toggleTradeDrawer(true)
      getTradingOption(item.title, auth.subscriptionLevel) === "Find a broker" && toggleInsuranceDrawer(true)
      getTradingOption(item.title, auth.subscriptionLevel) === "Find a provider" && toggleInsulationDrawer(true)
      getTradingOption(item.title, auth.subscriptionLevel) === "Mortgage adviser" && toggleMortgageDrawer(true)
    }
    if (key === "redirectToUpkeepPage") {
      history.push("/upkeep?openType=My maintenances");
    }
    if (key.includes("view")) {
      setTaskToViewType(getTaskType(item.task))
      setTaskToView(item.task)
      toggleViewTaskDrawer(true)
    }
    if (key.includes("edit")) {
      setTaskToEditType(getTaskType(item.task))
      setTaskToEdit(maintenances.content.filter(m => m.id === item.task.templateId)[0]);
      toggleUpdateTaskDrawer(true)
    }
  }

  const handleWorkAction = (key: string, item: any) => {
    if (key === "add") {
      addWork({
        propertyId,
        name: null,
        type: null,
        completedDate: null,
        dueDate: null,
        recurring: null,
        isComplete: false,
      }).then((res) => {
        setEditingWorkData({ data: res });
        toggleWorkDrawer(true);
        dispatch(getAllWorks(propertyId));
      });
    }
    if (key === "edit") {
      setEditingWorkData({data: item});
      toggleWorkDrawer(true);
    }
    if (key === "view") {
      setWorkToViewId(item.workId);
      toggleViewWorkDrawer(true);
      setPreSelectedTab("Details")
    }
    if (key === "delete") {
      confirmDeleteWork(item)
    }
    if (key === "forwardWork") {
      setForwardWorkId(item.workId);
      toggleForwardWorkDrawer(true);
    }
    if (key === "addLink") {
      toggleViewWorkDrawer(true)
      setWorkToViewId(item.workId)
      setPreSelectedTab("Links")
    }
  }

  const handleExpenseAction = (key: string, item: any) => {
    if (key === "add") {
      addExpense({
        propertyId,
        isConfirmed: true
      }).then((res) => {
        setEditingExpenseData(res);
        toggleExpenseDrawer(true);
        dispatch(getAllExpenses(propertyId));
      });

    }
    if (key === "edit") {
      setEditingExpenseData({data: item});
      toggleExpenseDrawer(true);
    }
    if (key === "view") {
      setEditingExpenseData({data: item});
      toggleExpenseDrawer(true);
    }
    if (key === "delete") {
      confirmDeleteExpense(item)
    }
  }

  const showPlanner = (wrapperMode === "planner-double" || wrapperMode === "planner-single") && maintenanceTasks.content.tasksExists
  const showToDoList = (wrapperMode === "planner-double" || wrapperMode === "planner-mobile") && maintenanceTasks.content.tasksExists
  const showCalendar = wrapperMode === "calendar-mobile" && setStartDate

  const emptyState = () => (<EmptyRecordsList>
    <IconWrapper>
      <ReminderIcon className={"fill"} />
      <TaskIcon className={"stroke"} />
      <WorkIcon className={"stroke"} />
      <CostIcon className={"fill"} />
    </IconWrapper>
    <EmptyRecordsHeader>
      Here is where you'll be able to view your calendar & to-do list
    </EmptyRecordsHeader>
    <EmptyRecordsDescription>
      The schedule view will highlight recommended and custom maintenance tasks, planned building projects and expenses.
    </EmptyRecordsDescription>

    <EmptyRecordsDescription className={"planner"}>
      <ButtonSmallBlue style={{height: "auto"}} onClick={() => history.push("/questions?openPage=upkeep&origin=planner")}>
        In order to see more, let's start by selecting some maintenance tasks
      </ButtonSmallBlue>
      or
      <ButtonSmallGreen style={{height: "auto"}} onClick={() => toggleEventDrawerRequest && toggleEventDrawerRequest(true)}>
        Create your first event
      </ButtonSmallGreen>
    </EmptyRecordsDescription>
  </EmptyRecordsList>)

  return (
    <>
      <AddEventDrawer
        isOpen={isEventDrawerOpen}
        toggleDrawer={toggleEventDrawer}
        setSelectedType={setTaskToEditType}
        toggleWorkDrawer={toggleWorkDrawer}
        toggleTaskDrawer={toggleUpdateTaskDrawer}
        setEditingWorkData={setEditingWorkData}
      />
      <UpdateTaskDrawer
        task={taskToEdit}
        setEditingTaskData={setTaskToEdit}
        isOpen={isUpdateTaskDrawerOpen}
        toggleDrawer={toggleUpdateTaskDrawer}
        taskType={taskToEditType}
        setTaskType={setTaskToEditType}
        refreshParent={handleRefresh}
      />
      <ViewWorkDrawer
        workId={workToViewId}
        setWorkId={setWorkToViewId}
        isOpen={isViewWorkDrawerOpen}
        toggleDrawer={toggleViewWorkDrawer}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditingWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setLinkedWorkId={setLinkedRecordId}
        setLinkedRecordType={setLinkedRecordType}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setWorkIdToForward={setForwardWorkId}
        toggleForwardDrawer={toggleForwardWorkDrawer}
        toggleTradeDrawer={toggleTradeDrawer}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleSpaceDrawer}
        refreshParent={handleRefresh}
        setPresetLinkType={setPresetLinkType}
        toggleTaskDrawer={toggleUpdateTaskDrawer}
        setEditTaskData={setTaskToEdit}
        preSelectedTab={preSelectedTab}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <AddEventDrawer
        isOpen={isNewEventDrawerOpen}
        toggleDrawer={toggleNewEventDrawer}
        setSelectedType={setTaskToEditType}
        toggleWorkDrawer={toggleWorkDrawer}
        toggleTaskDrawer={toggleUpdateTaskDrawer}
        setEditingWorkData={setEditingWorkData}
      />
      <EditSpaceDrawer
        spaceId={editSpaceId}
        isOpen={isSpaceDrawerOpen}
        toggleDrawerOpen={toggleSpaceDrawer}
        setEditingSpaceId={setEditSpaceId}
      />
      <InventoryDrawer
        isOpen={isInventoryDrawerOpen}
        toggleDrawerOpen={toggleInventoryDrawer}
        inventory={editingInventoryData}
        // @ts-ignore
        setEditingInventoryData={setEditingInventoryData}
        refreshParent={() => {}}
      />
      <ContactDrawer
        isOpen={isContactDrawerOpen}
        toggleDrawerOpen={toggleContactDrawer}
        contact={editingContactData}
        // @ts-ignore
        setEditingContactData={setEditingContactData}
        refreshParent={() => {}}
      />
      <ViewFileDrawer
        attachmentId={attachmentToViewId}
        setAttachmentId={setAttachmentToViewId}
        isOpen={isViewFileDrawerOpen}
        toggleDrawer={toggleViewFileDrawer}
        viewFileDrawerDefaultTab={"previewOnly"}
        attachmentsWithoutProperty={[]}
        duplicateAttachments={[]}
        selectedTags={[]}
        toggleEditTagsMode={() => {}}
        toggleEditDrawerOpen={() => {}}
        setPresetLinkType={() => {}}
        toggleExpenseDrawer={() => {}}
        setEditExpenseData={() => {}}
        toggleInventoryDrawer={() => {}}
        setEditInventoryData={() => {}}
        toggleContactDrawer={() => {}}
        setEditContactData={() => {}}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        toggleWorkDrawer={() => {}}
        setEditWorkData={() => {}}
        toggleNoteDrawer={() => {}}
        setEditNoteData={() => {}}
        toggleLinkDrawerOpen={() => {}}
        setEditSpaceId={() => {}}
        toggleSpaceDrawer={() => {}}
        refreshParent={() => {}}
        toggleViewWorkDrawer={() => {}}
        setViewWorkId={() => {}}
      />
      <NoteDrawer
        currentType={"note"}
        data={editingNoteData}
        isOpen={isNoteDrawerOpen}
        toggleDrawerOpen={toggleNoteDrawer}
        refreshParent={() => {}}
        isGlobalNote={true}
        toggleTaskDrawer={toggleUpdateTaskDrawer}
        setEditTaskData={setTaskToEdit}
        setPresetLinkType={setPresetLinkType}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleSpaceDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <RescheduleModal
        taskToReschedule={taskToReschedule}
        isRescheduleModalOpen={isRescheduleModalOpen}
        toggleRescheduleModal={toggleRescheduleModal}
        handleRefresh={handleRefresh}
      />
      <ForwardWorkDrawer
        isOpen={isForwardWorkDrawerOpen}
        toggleDrawerOpen={toggleForwardWorkDrawer}
        workId={forwardWorkId}
      />
      <ForwardUpcomingTaskDrawer
        isOpen={isForwardReminderDrawerOpen}
        toggleDrawerOpen={toggleForwardReminderDrawer}
        reminder={forwardReminder}
      />
      <TradeDrawer
        isDrawerOpen={isTradeDrawerOpen}
        toggleDrawer={toggleTradeDrawer}
        selectedType={selectedTradeType}
        setSelectedType={setSelectedTradeType}
        includeBoilerServices={true}
      />
      {/*<InsuranceDealDrawer*/}
      {/*  isDrawerOpen={isInsuranceDrawerOpen}*/}
      {/*  toggleDrawer={toggleInsuranceDrawer}*/}
      {/*/>*/}
      <MortgagePartnerDrawer
        isDrawerOpen={isMortgageDrawerOpen}
        toggleDrawer={toggleMortgageDrawer}
      />
      <InsulationDrawer
        isDrawerOpen={isInsulationDrawerOpen}
        toggleDrawer={toggleInsulationDrawer}
        selectedType={"Install solar panels"}
        setSelectedType={() => {}}
      />
      <CompletionModal
        taskToComplete={taskToComplete}
        setTaskToComplete={setTaskToComplete}
        isCompletionModalOpen={isCompletionModalOpen}
        toggleCompletionModal={toggleCompletionModal}
        handleRefresh={handleRefresh}
      />
      <DismissModal
        taskToDismiss={taskToDismiss}
        setTaskToDismiss={setTaskToDismiss}
        isDismissModalOpen={isDismissModalOpen}
        toggleDismissModal={toggleDismissModal}
        handleRefresh={handleRefresh}
      />
      <WorkDrawer
        isOpen={isWorkDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        width={null}
        setEditingWorkData={setEditingWorkData}
        work={editingWorkData}
        refreshInventoryParent={() => {}}
        refreshParent={handleRefresh}
      />
      <ExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        toggleDrawerOpen={toggleExpenseDrawer}
        expense={editingExpenseData}
        setEditingExpenseData={setEditingExpenseData}
        refreshParent={handleRefresh}
      />
      <LinkDrawer
        parentId={linkedRecordId}
        parentType={linkedRecordType}
        isOpen={isLinkDrawerOpen}
        toggleDrawerOpen={toggleLinkDrawer}
        presetLinkType={presetLinkType}
        setPresetLinkType={setPresetLinkType}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditingWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleSpaceDrawer}
        toggleTaskDrawer={toggleUpdateTaskDrawer}
        setEditTaskData={setTaskToEdit}
        toggleEventDrawer={toggleNewEventDrawer}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <ViewTaskDrawer
        task={taskToView}
        setTask={setTaskToView}
        taskType={taskToViewType}
        isOpen={isViewTaskDrawerOpen}
        toggleDrawer={toggleViewTaskDrawer}
        setTaskToEdit={setTaskToEdit}
        setTaskToEditType={setTaskToEditType}
        toggleUpdateDrawer={toggleUpdateTaskDrawer}
        refreshParent={handleRefresh}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditingWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleSpaceDrawer}
        setLinkedTaskId={setLinkedRecordId}
        setLinkedTaskType={setLinkedRecordType}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setPresetLinkType={setPresetLinkType}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      {(wrapperMode.includes("planner") || wrapperMode.includes("mobile")) && !maintenanceTasks.content.tasksExists && emptyState()}
      {(wrapperMode === "calendar" || showPlanner) && <UpcomingTaskCalendar
          startDate={startDate}
          //@ts-ignore
          setStartDate={setStartDate}
          toggleCustomUpkeepDrawer={toggleUpdateTaskDrawer}
          toggleEventDrawer={toggleEventDrawer}
          setSelectedTaskType={setTaskToEditType}
          setCustomUpkeepData={setTaskToEdit}
          handleReminderAction={handleReminderAction}
          handleWorkAction={handleWorkAction}
          handleExpenseAction={handleExpenseAction}
          monthlyView={monthlyView}
          toggleMonthlyView={toggleMonthlyView}
          refreshParent={refreshParent}
          totalColumns={totalColumns ?? 7}
          columnsInRow={columnsInRow ?? 7}
          plannerView={wrapperMode.includes("planner")}
          refreshTasks={handleRefresh}
      />}
      {showToDoList && <ToDoListComp
          handleReminderAction={handleReminderAction}
          handleWorkAction={handleWorkAction}
          handleExpenseAction={handleExpenseAction}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          showRecurring={showRecurring}
          toggleShowRecurring={toggleShowRecurring}
          resetSwiping={resetSwiping}
          toggleResetSwiping={toggleResetSwiping}
      />}
      {showCalendar && <PlannerCalendar
          handleReminderAction={handleReminderAction}
          handleWorkAction={handleWorkAction}
          handleExpenseAction={handleExpenseAction}
          refreshRequest={refreshRequest}
          toggleRefreshRequest={toggleRefreshRequest}
          resetSwiping={resetSwiping}
          toggleResetSwiping={toggleResetSwiping}
      />}
    </>
  );
};

export default UpcomingTasksWrapper;