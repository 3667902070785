import React, {useEffect, useState} from "react";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";

import {Dropdown, Spin} from "antd";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {ReactComponent as BackArrow} from "../../../images/spaces/ReturnArrow.svg";
import _, {at} from "lodash";
import moment from "moment";
import {
  ActionButtonGreen,
  DrawerFooter, DrawerHeader,
  EditDrawerStyled, FormStyled, CircleIcon
} from "../../screens/components";
import {Guid} from "guid-typescript";
import {ReactComponent as AddLinksIcon} from "../../../images/attachments/AddLinks.svg";
import {RadioStyled} from "../navigation/Header";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import {ReactComponent as SelectPropertyArrow} from "../../../images/homepage-june/SelectPropertyArrow.svg";
import {
  PropertySelectionDropdown,
  PropertySelectionList,
  PropertySelectionListItem
} from "../attachments/AssignMultiPropertyDrawer";
import {FileItemSize, FileItemTitle, FileItemTitleWrapper} from "../attachments/FileItem";
import {assignExpenseProperty} from "../../../api-wrapper/expenses/assignExpenseProperty";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg: boolean) => void;
  expensesWithoutProperty: Array<any>;
  refreshParent?: () => void;
}


const AssignMultiPropertyDrawer = (props: Props) => {
  const {
    isOpen,
    toggleDrawerOpen,
    refreshParent,
    expensesWithoutProperty
  } = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [selectedExpenses, setSelectedExpenses] = useState<Set<Guid>>(new Set());
  const [selectedProperties, setSelectedProperties] = useState<Map<Guid, Guid>>(new Map());

  const performCleanup = () => {
    refreshParent && refreshParent();
    toggleDrawerOpen(false);
    setSelectedExpenses(new Set());
    setSelectedProperties(new Map());
  }

  const handleCancel = () => {
    performCleanup();
  }

  const handleLinkMultiProperty = async () => {
    const assignPromises = Array.from(selectedExpenses).map(async (expenseId) => {
      if (selectedProperties.get(expenseId)) {
        // @ts-ignore
        return await assignExpenseProperty(selectedProperties.get(expenseId), expenseId);
      } else {
        return await assignExpenseProperty(propertyId, expenseId);
      }
    });
    await Promise.all(assignPromises);
    handleCancel();
  }

  const LineItem = (item: any) => {
    const selectExpense = (e: any, force: boolean) => {
      e && e.stopPropagation();
      if (!selectedExpenses || !setSelectedExpenses)
        return;

      if (selectedExpenses.has(item.expenseId) && !force) {
        selectedExpenses.delete(item.expenseId);
      } else {
        selectedExpenses.add(item.expenseId);
      }

      setSelectedExpenses(new Set(selectedExpenses));
    }

    const isExpenseSelected = () => {
      if (!selectedExpenses || !setSelectedExpenses)
        return false;
      return selectedExpenses.has(item.expenseId);
    }

    const propertiesDropdown = () => (
      <PropertySelectionList>
        {properties.map(property => <PropertySelectionListItem
          onClick={() => {
            setSelectedProperties(new Map(selectedProperties.set(item.expenseId, property.propertyId)));
            selectExpense(null, true);
          }}
        >
          {property.address + (property.city ? `, ${property.city}` : "") + (property.zip ? ` ${property.zip}` : "")}
        </PropertySelectionListItem>)}
      </PropertySelectionList>
    )

    const currentPropertyAddress = () => {
      let currentProperty = properties.find((property) => property.propertyId === selectedProperties.get(item.expenseId));
      if (!currentProperty)
        currentProperty = properties.find((property) => property.propertyId === propertyId);
      if (!currentProperty)
        return "";
      return currentProperty.address + (currentProperty.city ? `, ${currentProperty.city}` : "")
        + (currentProperty.zip ? ` ${currentProperty.zip}` : "");
    }

    return <div
      key={item.id}
      style={{display:"flex", justifyContent: "flex-start", flexDirection: "row", alignItems: "center", width: "100%", margin:"0.25rem 0"}}
    >
      <div
        onClick={(e) => selectExpense(e, false)}
      >
        <RadioStyled checked={isExpenseSelected()}/>
      </div>
      <div style={{display:"flex", justifyContent: "space-between", flexDirection: "column", alignItems: "flex-start", background:"#F5F6FA", borderRadius:"8px", width: "100%", padding: "1.5rem 1rem", rowGap: "0.75rem"}}>
        <div style={{display:"flex", justifyContent: "flex-start", flexDirection: "row", columnGap: "1rem", alignItems: "center", width: "100%"}}>
          <FileItemTitleWrapper style={{width: "100%", rowGap: "0.1rem"}}>
            <FileItemTitle>
              <Ellipsis lines={1}>{item.name}</Ellipsis>
            </FileItemTitle>
            <FileItemSize>Received {moment(item.paymentDate).format(propertyPreferences.preferredDateFormat)}</FileItemSize>
          </FileItemTitleWrapper>
        </div>

        <Dropdown overlay={propertiesDropdown()} placement={"bottomCenter"} trigger={["click"]}>
          <PropertySelectionDropdown>
            {currentPropertyAddress()} <SelectPropertyArrow />
          </PropertySelectionDropdown>
        </Dropdown>
      </div>
    </div>;
  }

  return <EditDrawerStyled
      style={{zIndex: 10}}
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "100%" : "85%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      visible={isOpen}
      onClose={() => handleCancel()}
      title={<DrawerHeader style={{flexDirection: "row", alignItems:"flex-start", rowGap:"0.5rem"}}>
        {window.innerWidth <= 768 && <BackArrow style={{cursor: "pointer", flexShrink: 0}} onClick={() => handleCancel()}/>}
        <div style={{display: "flex", flexDirection: "row", alignItems:"center"}}>
          <span style={{fontSize:"1.75rem"}}>Received expensess</span>
          <span style={{fontSize:"1.125rem", color:"#009966", fontWeight: 600, marginLeft:"1rem"}}>{expensesWithoutProperty.length} Expense{expensesWithoutProperty.length > 1 && "s"}</span>
        </div>
        {window.innerWidth > 768 && <CircleIcon onClick={() => handleCancel()}><CloseIcon /></CircleIcon>}
      </DrawerHeader>}
      footer={<DrawerFooter className={"fake-footer"}>
        <ActionButtonGreen onClick={() => handleLinkMultiProperty()}><AddLinksIcon/> Assign property to selected</ActionButtonGreen>
      </DrawerFooter>}
    >
     <div style={{width:"100%"}}>
      <EditSpaceForm>
        <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
          {expensesWithoutProperty.map((file: any) => LineItem(file))}
        </div>
      </EditSpaceForm>
     </div>
    </EditDrawerStyled>
}

const EditSpaceForm = styled.div`
  width: 100%;
`

export default AssignMultiPropertyDrawer