import { createReducer } from "typesafe-actions";
import produce from "immer";

import {
  getPropertyPreferencesInitAction,
  setPropertyPreferencesAction,
  TGetPropertyPreferencesActions,
} from "../actions/propertyPreferences";

export type TPropertyPreferences = {
  preferredDateFormat: string;
  preferredCurrency: string;
  taxYearMonth: number;
  taxYearDate: number;
}

const initialState: TPropertyPreferences = {
  preferredDateFormat: "",
  preferredCurrency: "",
  taxYearMonth: 0,
  taxYearDate: 0
};

export const propertyPreferencesReducer = createReducer<
  TPropertyPreferences,
  TGetPropertyPreferencesActions
  >(initialState)
  .handleAction(getPropertyPreferencesInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.preferredDateFormat = "";
      draftState.preferredCurrency = "";
      draftState.taxYearMonth = 0;
      draftState.taxYearDate = 0;
    })
  )
  .handleAction(setPropertyPreferencesAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.preferredDateFormat = action.payload.preferredDateFormat;
      draftState.preferredCurrency = action.payload.preferredCurrency;
      draftState.taxYearMonth = action.payload.taxYearMonth;
      draftState.taxYearDate = action.payload.taxYearDate;
    })
  );
