import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {
  getContacts,
  TGetContactsResponse,
} from "../../api-wrapper/contacts/getContacts";
import {Guid} from "guid-typescript";

export const getContactsInitAction = createAction(
  "Contacts/GET_Contacts_INIT"
)();
export const getContactsSuccessAction = createAction(
  "Contacts/GET_Contacts_SUCCESS"
)<TGetContactsResponse>();
export const getContactsFailureAction = createAction(
  "Contacts/GET_Contacts_FAILURE"
)();

export type TGetContactsInitAction = ActionType<typeof getContactsInitAction>;
export type TGetContactsSuccessAction = ActionType<
  typeof getContactsSuccessAction
>;
export type TGetContactsFailureAction = ActionType<
  typeof getContactsFailureAction
>;

export type TGetContactsActions =
  | TGetContactsInitAction
  | TGetContactsSuccessAction
  | TGetContactsFailureAction;

export type TGetContactsThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetContactsActions>;

export const getAllContacts: TGetContactsThunkActionType = (
  propertyId: Guid
) => (dispatch: (action: Action | Promise<Action>) => any) => {
  dispatch(getContactsInitAction());
  return getContacts(propertyId)
    .then((payload) => {
      return dispatch(getContactsSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getContactsFailureAction()));
};
