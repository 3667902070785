import React, {ReactNode, useEffect, useRef, useState} from "react";
import {ReactComponent as InventoriesIcon} from "../../../images/ContentDrawerIcon.svg";
import {CloseButton} from "../works/WorkDrawer";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import {Drawer, Select} from "antd";
import {Guid} from "guid-typescript";
import {useSelector} from "react-redux";
import {getMaintenancesSelector, getPropertyIdSelector} from "../../selectors";
import {
  ButtonStyled,
  ButtonWrapper,
  FieldLabel,
  FormHeader,
  FormWrapper,
  InventoriesIconWrapper,
  Title
} from "./AddExistingWorkDrawer";
import _ from "lodash";
import {addRelation} from "../../../api-wrapper/relation/addRelation";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  refreshParent: () => void;
  width?: number | null;
  parentId: Guid;
  drawerTitle: string;
  parentType: string;
  childType: string;
  icon?: ReactNode | null;
}

const AddExistingUpkeepDrawer = (props: Props) => {
  const {
    isOpen,
    toggleDrawerOpen,
    parentId,
    refreshParent,
    width,
    drawerTitle,
    parentType,
    childType,
    icon,
  } = props;
  const propertyId = useSelector(getPropertyIdSelector).value;
  const maintenances = useSelector(getMaintenancesSelector);
  const titleInput = useRef(null);
  const [relatedItems, setRelatedItems] = useState<{ key: Guid; value: string, type: string }[]>([]);
  const [upkeepId, setUpkeepId] = useState<any>(null);

  const clearDrawer = () => {
    toggleDrawerOpen(false);
    setUpkeepId(null);
  }

  const addUpkeep = () => {
    upkeepId && addRelation({
      propertyId,
      parentId,
      childId: upkeepId,
      parentType,
      childType: relatedItems.filter(item => item.key === upkeepId)[0].type,
    }).then(() => {
      refreshParent();
      clearDrawer();
    });
  };

  const onUpkeepAutocompleteSearch = (searchText: string) => {
    setRelatedItems(!searchText ? maintenances.content.map((upkeep) => {
      return {key: upkeep.id, value: upkeep.title, type: upkeep.upkeepType }})
        : maintenances.content.filter((upkeep) =>
          upkeep.title.toLowerCase().includes(searchText.toLowerCase()))
          .map((upkeep) => {return {key: upkeep.id, value: upkeep.title, type: upkeep.upkeepType}}))
  }

  useEffect(() => {
    setRelatedItems(maintenances.content.map((upkeep) => {
      return {key: upkeep.id, value: upkeep.title, type: upkeep.upkeepType}
    }))
  }, [maintenances]);

  useEffect(() => {
    // @ts-ignore
    titleInput.current !== null && titleInput.current.focus();
    // @ts-ignore
    titleInput.current !== null && titleInput.current.setSelectionRange(0, 0)
  }, [isOpen, titleInput])

  return <Drawer
    push={{ distance: "32px" }}
    closeIcon={false}
    width={window.innerWidth > 1024 ? (width ? width + "%" : "55%") : "100%"}
    visible={isOpen}
    placement="right"
    onClose={() => clearDrawer()}
    headerStyle={{backgroundColor: "#ebf9ff", margin: 0, padding: "2rem 2rem"}}
    title={<>
        <section style={{ display: "flex", justifyContent: "space-between" }}>
          <InventoriesIconWrapper>
            {icon ? icon : <InventoriesIcon />}{" "}
            <span style={{ paddingRight: "0.25rem" }}>{drawerTitle}</span>
          </InventoriesIconWrapper>
          <CloseButton size={"large"} onClick={() => clearDrawer()}>
            {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
          </CloseButton>
        </section>
        <Title>Add upkeep item</Title>
      </>}
  >
    <FormHeader>Select existing upkeep</FormHeader>
    <FormWrapper>
      <FieldLabel>Search your upkeep</FieldLabel>
      <Select
        placeholder={"Search for upkeep..."}
        showSearch
        size={"large"}
        bordered={false}
        showArrow={false}
        defaultActiveFirstOption={false}
        filterOption={false}
        value={upkeepId}
        //@ts-ignore
        defaultValue={null}
        onSearch={onUpkeepAutocompleteSearch}
        style={{
          width: "100%",
          border: "1px solid #DAE0E6",
          boxSizing: "border-box",
          borderRadius: "6px",
          color: "rgb(107,113,133)",
        }}
        onChange={(val) => setUpkeepId(val)}
      >
        {relatedItems.map((relateItem) => (
          <Select.Option value={relateItem.key.toString()}>
            {relateItem.value}
          </Select.Option>
        ))}
      </Select>
    </FormWrapper>
    <ButtonWrapper>
      <ButtonStyled
        disabled={_.isEmpty(upkeepId)}
        htmlType={"submit"}
        type={"primary"}
        size={"large"}
        onClick={addUpkeep}
      >
        + Add upkeep
      </ButtonStyled>
    </ButtonWrapper>
  </Drawer>
}

export default AddExistingUpkeepDrawer