import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import { getImprovements, TGetImprovementsResponse } from "../../api-wrapper/properties/getImprovements";
import {Guid} from "guid-typescript";
import {
  getRecommendedImprovements,
  TGetRecommendedImprovementsResponse
} from "../../api-wrapper/properties/getRecommendedImprovements";
import {initialState} from "../reducers/improvements";

export const getImprovementsInitAction = createAction("improvements/GET_IMPROVEMENTS_INIT")();
export const getImprovementsSuccessAction = createAction(
  "improvements/GET_IMPROVEMENTS_SUCCESS"
)<TGetImprovementsResponse>();
export const getImprovementsFailureAction = createAction("improvements/GET_IMPROVEMENTS_FAILURE")();

export const getRecommendedImprovementsInitAction = createAction("improvements/GET_RECOMMENDED_IMPROVEMENTS_INIT")();
export const getRecommendedImprovementsSuccessAction = createAction(
  "improvements/GET_RECOMMENDED_IMPROVEMENTS_SUCCESS"
)<TGetRecommendedImprovementsResponse>();
export const getRecommendedImprovementsFailureAction = createAction("improvements/GET_RECOMMENDED_IMPROVEMENTS_FAILURE")();

export type TGetImprovementsInitAction = ActionType<typeof getImprovementsInitAction>;
export type TGetImprovementsSuccessAction = ActionType<typeof getImprovementsSuccessAction>;
export type TGetImprovementsFailureAction = ActionType<typeof getImprovementsFailureAction>;

export type TGetRecommendedImprovementsInitAction = ActionType<typeof getRecommendedImprovementsInitAction>;
export type TGetRecommendedImprovementsSuccessAction = ActionType<typeof getRecommendedImprovementsSuccessAction>;
export type TGetRecommendedImprovementsFailureAction = ActionType<typeof getRecommendedImprovementsFailureAction>;

export type TGetImprovementsActions =
  | TGetImprovementsInitAction
  | TGetImprovementsSuccessAction
  | TGetImprovementsFailureAction;

export type TGetRecommendedImprovementsActions =
  | TGetRecommendedImprovementsInitAction
  | TGetRecommendedImprovementsSuccessAction
  | TGetRecommendedImprovementsFailureAction;

export type TGetImprovementsThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetImprovementsActions>;

export type TGetRecommendedImprovementsThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetImprovementsActions>;

export const getAllImprovements: TGetImprovementsThunkActionType = (propertyId: Guid) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getImprovementsInitAction());
  return getImprovements(propertyId)
    .then((payload) => {
      return dispatch(getImprovementsSuccessAction(payload?? initialState.content));
    })
    .catch(() => dispatch(getImprovementsFailureAction()));
};

export const getAllRecommendedImprovements: TGetRecommendedImprovementsThunkActionType = (propertyId: Guid) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getRecommendedImprovementsInitAction());
  return getRecommendedImprovements(propertyId)
    .then((payload) => {
      if (payload) {
        return dispatch(getRecommendedImprovementsSuccessAction(payload));
      }
    })
    .catch(() => dispatch(getRecommendedImprovementsFailureAction()));
};