import {apiPut, createUrl} from "../apiCall";
import EnvConfig from "../../config";

export type TChangePasswordRequest = {
  oldPassword: string;
  newPassword: string;
};

export const changePassword = async (payload: TChangePasswordRequest) => {
  let url = createUrl(`${EnvConfig.API_URL}/api/user/changePassword`);

  return apiPut<{}, any>(url, payload);
};