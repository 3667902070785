import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import { getPets, TGetPetsResponse } from "../../api-wrapper/pets/getPets";
import {Guid} from "guid-typescript";

export const getPetsInitAction = createAction("pets/GET_PETS_INIT")();
export const getPetsSuccessAction = createAction("pets/GET_PETS_SUCCESS")<TGetPetsResponse>();
export const getPetsFailureAction = createAction("pets/GET_PETS_FAILURE")();

export type TGetPetsInitAction = ActionType<typeof getPetsInitAction>;
export type TGetPetsSuccessAction = ActionType<typeof getPetsSuccessAction>;
export type TGetPetsFailureAction = ActionType<typeof getPetsFailureAction>;

export type TGetPetsActions =
  | TGetPetsInitAction
  | TGetPetsSuccessAction
  | TGetPetsFailureAction;

export type TGetPetsThunkActionType = () => ThunkAction<void, TRootState, null, TGetPetsActions>;

export const getAllPets: TGetPetsThunkActionType = () => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getPetsInitAction());
  return getPets()
    .then((payload) => {
      return dispatch(getPetsSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getPetsFailureAction()));
};
