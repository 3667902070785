import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type ImprovementCategory = {
  id: number;
  parentCategory: number;
  name: string;
  description: string;
};

export type ImprovementItem = {
  id: string;
  parentId: number | null;
  name: string;
  summary: string;
  category: number;
  subCategory: number;
  priority: number;
  doneByAnswerOn: number | null,
  doneOn: number | null;
  wishListedOn: number | null;
  displayOrder: number;
  keywords: Array<string>;
  relatedImprovements: Array<string>;
  incentives: Array<number>;
  relatedWorkId: null,
  ecoImpactRating: number;
  livletRating: number;
  savingsRating: number;
  costRating: number;
  relativeEffortOfWorkRequired: string;
  specialtyOfWorkRequired: string;
  description: string;
};

export type PropertyQuestionAnswer = {
  id: number;
  questionId: number;
  answer: string;
  answerOrder: number;
};

export type PropertyQuestion = {
  id: number;
  question: string;
  label: string;
  type: string;
  givenAnswerId: number;
  givenAnswerContext: string;
  locationOnImg: string;
  locationOnImgText: string;
  answers: Array<PropertyQuestionAnswer>;
  improvementsPriorities: Array<number>;
};

export type IncentiveItem = {
  id: number;
  title: string;
  description: string;
  linkToSource: string;
  improvements: Array<string>;
};

export type PropertyDetailItem = {
  id: number;
  groupName: string;
  groupOrder: number;
  answerId: number;
  answer: number;
  label: string;
  date: string;
  order: number;
  placeholder: string;
  questionId: number;
};

export type PropertyHighlightsData = {
  estimatedMax: number;
  estimatedMin: number;
  estimatedValue: number;
  valuationDate: string;
  currentEnergyEfficiency: number;
  currentEnergyRating: string;
  potentialEnergyEfficiency: number;
  potentialEnergyRating: string;
  valueSource: string;

  lastSoldPrice: number;
  lastSoldPriceSource: string;
  lastSoldPriceDate: string;
  propertyValue: string;
  propertyValueType: string;
  growth: string;
};

export type TGetImprovementsResponse = {
  categories: Array<ImprovementCategory>;
  improvements: Array<ImprovementItem>;
  questions: Array<PropertyQuestion>;
  incentives: Array<IncentiveItem>;
  propertyDetails: Array<PropertyDetailItem>;
  propertyHighlights: PropertyHighlightsData;
};

export const getImprovements = (propertyId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/improvements`);

  return apiGet<TGetImprovementsResponse>(url, {}, propertyId);
};
