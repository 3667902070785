import React, {useEffect, useState} from "react";
import {ReactComponent as WorkIcon} from "../../../images/WorksNew.svg";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import {ButtonsWrapper, CloseButton, WorksIconWrapper} from "./WorkDrawer";
import {Button, Checkbox, Drawer, Form, Input, notification, Radio} from "antd";
import {Label} from "../../screens/RegistrationPage";
import EmailCreatableSelector from "../EmailCreatableSelector";
import styled from "styled-components";
import {EmailModal, PreviewBox} from "../SendViaEmail";
import ReactHtmlParser from "react-html-parser";
import {Guid} from "guid-typescript";
import {useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../selectors";
import {forwardWorkPreview} from "../../../api-wrapper/email/forwardWorkPreview";
import {
  getRecipients,
  processForwardingError,
  safetyRegex,
  transformRecipients
} from "../../screens/helpers";
import {forwardWork} from "../../../api-wrapper/email/forwardWork";
import AttachFile from "../attachments/AttachFile";
import {getWorkById} from "../../../api-wrapper/works/getWorkById";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  workId: Guid;
}

const ForwardWorkDrawer = (props: Props) => {
  const propertyId = useSelector(getPropertyIdSelector).value;
  const {isOpen, toggleDrawerOpen, workId} = props;

  const [form] = Form.useForm();

  const [recipients, setRecipients] = useState<any>([])
  const [customMessage, setCustomMessage] = useState("")
  const [ccSender, setCcSender] = useState(false)
  const [requestFor, setRequestFor] = useState("")
  const [includeWorkTitle, setIncludeWorkTitle] = useState(false)
  const [includePropertyAddress, setIncludePropertyAddress] = useState(false)
  const [includeStartDate, setIncludeStartDate] = useState(false)
  const [includeNotes, setIncludeNotes] = useState(false)
  const [includeFiles, setIncludeFiles] = useState(false)
  const [isPreviewModalOpen, togglePreviewModal] = useState(false)
  const [preview, setPreview] = useState("")
  const [filesToForward, setFilesToForward] = useState<any>([])
  const [isForwardingAllowed, setIsForwardingAllowed] = useState(true)

  useEffect(() => {
    isOpen && getWorkById(propertyId, workId).then((res) =>
      res && setFilesToForward(res.attachments)
    );
  }, [workId, isOpen])

  function restoreInitialState() {
    setRecipients([])
    setCustomMessage("")
    setCcSender(false)
    setRequestFor("")
    setIncludeWorkTitle(false)
    setIncludePropertyAddress(false)
    setIncludeStartDate(false)
    setIncludeNotes(false)
    setIncludeFiles(false)
    setPreview("")
    setFilesToForward([])
    toggleDrawerOpen(false)
  }
  
  function selectAll() {
    setIncludeWorkTitle(true)
    setIncludePropertyAddress(true)
    setIncludeStartDate(true)
    setIncludeNotes(true)
    setIncludeFiles(true)
  }

  function deselectAll() {
    setIncludeWorkTitle(false)
    setIncludePropertyAddress(false)
    setIncludeStartDate(false)
    setIncludeNotes(false)
    setIncludeFiles(false)
  }

  function handleForward(mode: string) {
    let sanitizedMessage = customMessage.replace(safetyRegex, '')
    let attachments: any = []
    filesToForward.map((file: any) => attachments.push(file.attachmentId))

    let dataToForward = {
      workId,
      propertyId,
      recipients: getRecipients(transformRecipients(recipients)),
      ccSender,
      customMessage: sanitizedMessage,
      isQuote: requestFor === "Send a quote",
      isWorkNameEnabled: includeWorkTitle,
      isPropertyAddressEnabled: includePropertyAddress,
      isStartDateEnabled: includeStartDate,
      isWorkNotesEnabled: includeNotes,
      attachments: includeFiles ? attachments : []
    }

    if (mode === "preview") {
      forwardWorkPreview(dataToForward).then((res: any) => {
        setPreview(res.data.body)
        togglePreviewModal(true)
      })
    }
    if (mode === "send") {
      forwardWork(dataToForward).then(() => {
        notification.info({
          message: 'Work has been forwarded successfully',
          placement: "topRight",
        })
        restoreInitialState()
      }).catch((errorResponse: any) => processForwardingError(errorResponse))
    }
  }

  return (<>
    <EmailModal
      width={800}
      style={{top: 10}}
      title={"Send message"}
      visible={isPreviewModalOpen}
      onCancel={() => togglePreviewModal(false)}
      closeIcon={<Button style={{marginLeft: '-3.8rem'}} type={'ghost'}>Cancel</Button>}
      footer={null}>
      <PreviewBox>{ReactHtmlParser(preview)}</PreviewBox>
    </EmailModal>

    <Drawer
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? "55%" : "100%"}
      visible={isOpen}
      placement="right"
      onClose={restoreInitialState}
      headerStyle={{
        backgroundColor: "#ebf9ff",
        margin: 0,
        padding: "2rem 2rem",
      }}
      title={
        <>
          <section style={{ display: "flex", justifyContent: "space-between" }}>
            <WorksIconWrapper>
              <WorkIcon className={"works-theme-icon"} />{" "}
              <span style={{ paddingRight: "0.25rem", color: "#7479E9", fontSize: "0.875", textTransform: "uppercase" }}>
                  <b>Projects</b>
                </span>
            </WorksIconWrapper>
            <ButtonsWrapper>
              <CloseButton size={"large"}
                onClick={restoreInitialState}
              >
                {window.innerWidth > 1024 ?
                  <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
              </CloseButton>
            </ButtonsWrapper>
          </section>
          <section
            style={{
              marginTop: "1.875rem",
              marginBottom: "0.75rem"
            }}
          >
            <Title>Send message</Title>
          </section>
        </>
      }
    >
      <Form
        form={form}
        name="sendEmail"
        layout="vertical"
        size={"large"}
      >
        <InputWrapper>
          <Label style={{marginBottom: "0.75rem"}}>To:</Label>
          <EmailCreatableSelector emailList={recipients} setEmailList={setRecipients} />
          <Checkbox
            style={{marginBottom: '1rem'}}
            checked={ccSender}
            onClick={() => setCcSender(!ccSender)}>
            CC me on this email
          </Checkbox>
          {recipients.length > 1 && ccSender && <p style={{fontWeight: 700, color: "#2a80e1"}}>
              Please note that you will receive {recipients.length} copies of this email.
          </p>}
        </InputWrapper>
        <InputWrapper>
          <Label style={{marginBottom: "0.75rem"}}>Request for</Label>
          <Radio.Group style={{marginBottom: '1rem'}}>
            <Radio
              value={"Send a quote"}
              checked={requestFor === "Send a quote"}
              onClick={() => setRequestFor("Send a quote")}
            >Send a quote</Radio>
            <Radio
              value={"Work request"}
              checked={requestFor === "Work request"}
              onClick={() => setRequestFor("Work request")}
            >Work request</Radio>
          </Radio.Group>
        </InputWrapper>
        <InputWrapper style={{display: "flex", flexDirection: "column"}}>
          <ControlsWrapper>
            <Label style={{margin: 0}}>Include work data:</Label>
            <Controls>
              <ControlButton className={"select"} onClick={() => selectAll()}>Select all</ControlButton>
              <ControlButton className={"deselect"} onClick={() => deselectAll()}>Deselect all</ControlButton>
            </Controls>
          </ControlsWrapper>
          <Checkbox
            style={{marginBottom: '1rem', marginLeft: 0}}
            checked={includeWorkTitle}
            onClick={() => setIncludeWorkTitle(!includeWorkTitle)}>
            Project title
          </Checkbox>
          <Checkbox
            style={{marginBottom: '1rem', marginLeft: 0}}
            checked={includePropertyAddress}
            onClick={() => setIncludePropertyAddress(!includePropertyAddress)}>
            Property address
          </Checkbox>
          <Checkbox
            style={{marginBottom: '1rem', marginLeft: 0}}
            checked={includeStartDate}
            onClick={() => setIncludeStartDate(!includeStartDate)}>
            Start date
          </Checkbox>
          <Checkbox
            style={{marginBottom: '1rem', marginLeft: 0}}
            checked={includeNotes}
            onClick={() => setIncludeNotes(!includeNotes)}>
            Notes
          </Checkbox>
          <Checkbox
            style={{marginBottom: '1rem', marginLeft: 0}}
            checked={includeFiles}
            onClick={() => setIncludeFiles(!includeFiles)}>
            Documents
          </Checkbox>
        </InputWrapper>
        <InputWrapper style={{marginBottom: "5.5rem"}}>
          <Label style={{marginBottom: "0.75rem"}}>Your comments:</Label>
          <Form.Item>
            <Input
              style={{borderRadius: "5px"}}
              placeholder={"Add comments here..."}
              value={customMessage}
              onChange={(e) => setCustomMessage(e.target.value)}
            />
            </Form.Item>
            {includeFiles && <AttachFile
                filesToForward={filesToForward}
                setFilesToForward={setFilesToForward}
                isForwardingAllowed={isForwardingAllowed}
                setIsForwardingAllowed={setIsForwardingAllowed}
            />}
        </InputWrapper>
        <ButtonWrapper>
          <ButtonOutlined
            style={{borderRadius: '6px'}}
            type={"primary"}
            onClick={() => handleForward("preview")}
          >Preview</ButtonOutlined>
          <ButtonSolid
            style={{borderRadius: '6px'}}
            type={"primary"}
            onClick={() => handleForward("send")}
            disabled={recipients.length === 0 || !isForwardingAllowed}
          >Send</ButtonSolid>
        </ButtonWrapper>
      </Form>
  </Drawer>
</>
)
}

export const Title = styled.span`
  color: #21272B;
  font-size: 2.5rem;
  font-weight: 700;
`

export const InputWrapper = styled.div`
  margin-bottom: 1rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
 
  margin-top: 1.2rem;
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  background: white;
`

export const ButtonSolid = styled(Button)`
  background: #2A80E1;
  border: 2px solid #2A80E1;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  height: 3.25rem;
  min-width: 8.25rem;
  text-align: center;
  margin: 1rem 0.625rem;
`

export const ButtonOutlined = styled(Button)`
  background: white;
  border: 2px solid #2A80E1;
  color: #2A80E1;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  height: 3.25rem;
  min-width: 8.25rem;
  text-align: center;
  padding: 0.875rem 1.25rem;
  margin: 1rem 0.625rem;
`

export const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.5rem;
  }
`

export const Controls = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`

export const ControlButton = styled.button`
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-weight: 600;

  &.select {
    border: 1px solid #2A80E1;
    background: #2A80E1;
    color: white;
  }
  
  &.select:hover {
    border: 1px solid #40a9ff;
    background: #40a9ff;
    color: white;
  }
  
  &.deselect {
    border: 1px solid #2A80E1;
    color: #2A80E1;
    background: white;
  }
  
  &.deselect:hover {
    border: 1px solid #40a9ff;
    color: #40a9ff;
    background: white;
  }
`


export default ForwardWorkDrawer;