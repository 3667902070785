import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import {
  getNotesByPropertyIdFailureAction,
  getNotesByPropertyIdInitAction,
  getNotesByPropertyIdSuccessAction,
  TGetNotesByPropertyIdActions,
} from "../actions/notesByPropertyId";
import { TGetNotesByPropertyIdResponse } from "../../api-wrapper/notes/getNotesByPropertyId";
import {emptyGuid} from "../screens/helpers";

export type TNotesByPropertyId = {
  fetchingStatus: TFetchingStatus;
  content: TGetNotesByPropertyIdResponse;
};

const initialState: TNotesByPropertyId = {
  content: [
    {
      recordId: emptyGuid,
      title: "",
      message: "",
      createdDate: "",
      emergency: false,
      attachment: {
        attachmentId: emptyGuid,
        propertyId: emptyGuid,
        parentId: emptyGuid,
        parentType: "",
        name: "",
        size: 0,
        searchable: false,
        lexemesFound: false,
      },
      tags: [],
      thumbnails: [],
    },
  ],
  fetchingStatus: TFetchingStatus.Defined,
};

export const notesByPropertyIdReducer = createReducer<
  TNotesByPropertyId,
  TGetNotesByPropertyIdActions
>(initialState)
  .handleAction(getNotesByPropertyIdInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getNotesByPropertyIdSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getNotesByPropertyIdFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
