import React, {useEffect, useState} from "react";
import {
  ColumnWrapper,
  FieldWrapper,
  Label,
  PropertyData,
  PropertyLocationData,
  PropertyLocationWrapper
} from "../components/properties/PropertyDetailsComp";
import styled from "styled-components";
import {Button, Checkbox, Collapse, Dropdown, Form, Input, Menu, Modal, notification} from "antd";
import {ReactComponent as DeleteImage} from "../../images/Delete.svg";
import AccountSettingsDrawer from "../components/properties/AccountSettingsDrawer";
import {useDispatch, useSelector} from "react-redux";
import {
  authSelector,
  getPetsSelector,
  getPropertiesSelector, getPropertyPreferencesSelector,
  getSubscriptionUsersSelector,
  getUserProfileSelector
} from "../selectors";
import {getCurrentUserProfile} from "../actions/getUserProfile";
import {ReactComponent as SaveIcon} from "../../images/SaveIcon.svg";
import {deleteSubscription} from "../../api-wrapper/user/deleteSubscription";
import {signOut} from "../actions/logIn";
import {dataDownload} from "../../api-wrapper/user/dataDownload";
import {editUser} from "../../api-wrapper/auth/editUser";
import {getUserInterests} from "../../api-wrapper/user/getUserInterests";
import {setUserInterests} from "../../api-wrapper/user/setUserInterests";
import {FormStyled, SaveButton} from "../components/onboarding/WelcomeSurveyModal";
import {getAllSubscriptionUsers} from "../actions/subscriptionUsers";
import ContributorDrawer from "../components/properties/ContributorDrawer";
import {ReactComponent as ContributorActiveIcon} from "../../images/ContributorActive.svg";
import {ReactComponent as ContributorResendIcon} from "../../images/ContributorResend.svg";
import {ReactComponent as CloseSection} from "../../images/CloseSection.svg";
import {inviteUser} from "../../api-wrapper/user/inviteUser";
import {EmailModal} from "../components/SendViaEmail";
import {ButtonOutline, HeaderControlsWrapper, PageHeader, PageTitle, PageWrapper, SectionLabel} from "./components";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {getAllPets} from "../actions/pets";
import {getAllProperties} from "../actions/properties";
import PetDrawer from "../components/pets/PetDrawer";
import {ReactComponent as MoreMenu} from "../../images/More.svg";
import {MenuStyled} from "../components/works/WorksListComp";
import {removePet} from "../../api-wrapper/pets/removePet";
import {TFetchingStatus} from "../../helpers";
import {ReactComponent as EmptyImagePreview} from "../../images/EmptyImagePreview.svg";

const { Panel } = Collapse;
const moment = require("moment");

const Settings = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector(getUserProfileSelector);
  const subscriptionUsers = useSelector(getSubscriptionUsersSelector);
  const auth = useSelector(authSelector);
  const properties = useSelector(getPropertiesSelector);
  const pets = useSelector(getPetsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [isAccountDetailsDrawerOpen, toggleAccountDetailsDrawer] = useState(false);
  const [interests, setInterests] = useState<any>([])
  const [saveButtonText, setSaveButtonText] = useState("Confirm")
  const [userName, setUserName] = useState("");
  const [contributorName, setContributorName] = useState("");
  const [inviteToResend, setInviteToResend] = useState<any>(null);
  const [isResendModalOpen, toggleResendModal] = useState(false)
  const [userNameError, toggleUserNameError] = useState(false);
  const [contributorNameError, toggleContributorNameError] = useState(false);
  const [isDeleting, toggleDeleting] = useState(false);

  const [sortedPets, setSortedPets] = useState<any>([]);
  const [isPetDrawerOpen, togglePetDrawer] = useState(false);
  const [selectedPet, setSelectedPet] = useState<any>(null);
  const none = "None of the above"

  const [isContributorDrawerOpen, toggleContributorDrawer] = useState(false);
  const [contributorEmailToEdit, setContributorEmailToEdit] = useState<string | null>(null);

  const [deleteReasonText, setDeleteReasonText] = useState<string>("");
  const [isDeleteReasonModalOpen, toggleDeleteReasonModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getCurrentUserProfile());
    dispatch(getAllPets());
    dispatch(getAllProperties());
  }, []);

  useEffect(() => {
    getUserInterests().then(res => res && setInterests(res.filter(interest => interest.showInDetails)));
    setDeleteReasonText("");
    toggleDeleteReasonModalOpen(false);
  }, [])

  useEffect(() => {
    setUserName(userProfile.content.name)
  }, [userProfile, isResendModalOpen])

  useEffect(() => {
    if (pets.fetchingStatus === TFetchingStatus.Success) {
      let clonedPets = [...pets.content]
      setSortedPets(clonedPets.sort((p1, p2) => p1.name?.localeCompare(p2.name)))
    }
  }, [pets])

  useEffect(() => {
    if (auth.subscriptionOwner) {
      dispatch(getAllSubscriptionUsers(auth.tenantId));
    }
  }, [auth]);

  useEffect(() => {
    setSaveButtonText("Confirm")
  }, [interests])

  const closePetsSection = () => {
    editUser({
      name: userProfile.content.name,
      email: userProfile.content.email,
      emailCommunication: userProfile.content.emailCommunication,
      marketingCookies: userProfile.content.marketingCookies,
      marketingEmails: !userProfile.content.marketingEmails,
      showPets: false
    }).then(() => dispatch(getCurrentUserProfile()))
  }

  const handleSubmit = () => {
    let basicInterestIds: any = []
    interests.map((interest: any) => interest.picked && basicInterestIds.push(interest.id))
    setUserInterests({basicInterestIds}).then(() => setSaveButtonText("Saved!"))
  }

  const areAnyOptionsSelected = () => {
    return interests.filter((interest: any) => interest.name !== none && interest.picked).length > 0
  }

  const isNoneOfTheAboveSelected = () => {
    return interests.filter((interest: any) => interest.name === none && interest.picked).length > 0
  }

  const deleteReason = () => (
    <EmailModal
      width={400}
      style={{top: 20}}
      title={"Delete account"}
      visible={isDeleteReasonModalOpen}
      mask={false}
      onCancel={() => toggleDeleteReasonModalOpen(false)}
      closeIcon={<Button style={{marginLeft: '-3.8rem'}} type={'ghost'}>Cancel</Button>}
      footer={null}>
      <p>To help us improve our product, please tell us why you are cancelling your account with us.</p>
      <div><textarea rows={3} cols={30} value={deleteReasonText} maxLength={90} onChange={event => setDeleteReasonText(event.target.value)}/></div>
      <Button
        style={{borderRadius: '6px'}}
        disabled={isDeleting}
        type={"primary"}
        onClick={() => {
          toggleDeleting(true)
          deleteSubscription(deleteReasonText).then(() => dispatch(signOut()))
        }}
      >{isDeleting ? "Deleting..." : "Delete account"}</Button>
    </EmailModal>
  )

  function confirmDelete() {
    Modal.confirm({
      title: "Are you sure you want to delete your account?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() { setDeleteReasonText(""); toggleDeleteReasonModalOpen(true); }
    });
  }

  function confirmDeletePet(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this pet?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() { removePet(item.propertyId, item.petId).then(() => dispatch(getAllPets())) }
    });
  }

  const moreMenuDropdown = (item: any) => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const {key} = event;
      if (key === "edit") {
        setSelectedPet(item);
        togglePetDrawer(true);
      } else if (key === "remove") {
        confirmDeletePet(item);
      }
    }

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="edit">Edit</Menu.Item>
        <Menu.Item key="remove">Remove</Menu.Item>
      </MenuStyled>)
  };

  const SubscriptionUsers = () => (
    <SubscriptionWrapperDesktop>
      <div style={{display: "flex", flexDirection: "row", color: "#666F7A", borderBottom: "1px solid #DAE0E6"}}>
        <SubscriptionUsersColumn spanWidth={"8%"} />
        <SubscriptionUsersColumn spanWidth={"20%"} style={{fontWeight: 400}}>Name</SubscriptionUsersColumn>
        <SubscriptionUsersColumn spanWidth={"25%"} style={{fontWeight: 400}}>Email</SubscriptionUsersColumn>
        <SubscriptionUsersColumn spanWidth={"14%"} style={{fontWeight: 400}}>Role</SubscriptionUsersColumn>
        <SubscriptionUsersColumn spanWidth={"20%"} style={{fontWeight: 400}}>Status</SubscriptionUsersColumn>
        <SubscriptionUsersColumn spanWidth={"15%"} style={{justifyContent: "center", fontWeight: 400}}>Added</SubscriptionUsersColumn>
      </div>

      <div style={{borderBottom: "1px solid #DAE0E6"}}>
        {(subscriptionUsers.content.length !== 0 && userProfile.content) && [...subscriptionUsers.content]
          .sort((user1, user2) => user1.email === userProfile.content.email ? -1 : 1)
          .map(item => (
          <div style={{display: "flex", flexDirection: "row", cursor: "pointer"}}
             onClick={() => {
               if (item.email === userProfile.content.email) {
                 toggleAccountDetailsDrawer(true);
               } else {
                 setContributorEmailToEdit(item.email);
                 toggleContributorDrawer(true);
               }
             }}
          >
            <SubscriptionUsersColumn spanWidth={"8%"} style={{justifyContent: "center"}}>
              <IconCircle style={{background: item.acceptedInvite ? "#00B74F" : "#ef624e"}} />
            </SubscriptionUsersColumn>
            <SubscriptionUsersColumn spanWidth={"20%"}>
              {item.name ? item.name : <span style={{color: "#C7C9C7", fontWeight: 600}}>Name not provided</span>}
            </SubscriptionUsersColumn>
            <SubscriptionUsersColumn spanWidth={"25%"} style={{overflow: "hidden"}}>
              <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                <a href={`mailto:${item.email}`}
                   style={{color: "#21272B", fontWeight: 400, textDecoration: "underline"}}>{item.email}</a>
              </div>
            </SubscriptionUsersColumn>
            <SubscriptionUsersColumn spanWidth={"14%"}>
              {item.email === userProfile.content.email ?
                <span style={{color: "#2A80E1", fontWeight: 600}}>Owner</span> :
                <span style={{color: "#45655E", fontWeight: 600}}>Contributor</span>}
            </SubscriptionUsersColumn>
            <SubscriptionUsersColumn spanWidth={"20%"}>
              {item.acceptedInvite ?
                <span style={{display: "flex", alignItems: "center", color: "#45655E", fontWeight: 600}}>
                  <ContributorActiveIcon style={{marginRight: "0.5rem"}} />Active</span> :
                <span style={{display: "flex", alignItems: "center", color: "#C7C9C7", cursor: "pointer", fontWeight: 600}}
                      onClick={(event) => {
                        event.stopPropagation();
                        setInviteToResend(item);
                        toggleResendModal(true);
                      }}>
                  <ContributorResendIcon style={{marginRight: "0.5rem"}} />Invitation sent</span>}
            </SubscriptionUsersColumn>
            <SubscriptionUsersColumn spanWidth={"15%"} style={{justifyContent: "center", color: "#666F7A", fontWeight: 400, flexShrink: 0}}>
              {moment(item.inviteTime).format(propertyPreferences.preferredDateFormat)}
            </SubscriptionUsersColumn>
          </div>
        ))}
        {subscriptionUsers.content.length < 2 && <div style={{display: "flex", justifyContent: "center", padding: "1rem"}}>
          <DeleteButtonStyled
            type={"primary"}
            size={"large"}
            style={{minHeight: "2.375rem", minWidth: "11.375rem"}}
            onClick={() => {
              setContributorEmailToEdit(null)
              toggleContributorDrawer(true)
            }}>
            Add User
          </DeleteButtonStyled>
        </div>}
      </div>
    </SubscriptionWrapperDesktop>
  );

  const SubscriptionUsersMobile = () => (
    <SubscriptionWrapperMobile>
      {(subscriptionUsers.content.length !== 0 && userProfile.content) && [...subscriptionUsers.content]
        .sort((user1, user2) => user1.email === userProfile.content.email ? -1 : 1)
        .map(item => (<div style={{cursor: "pointer"}} onClick={() => {
          if (item.email === userProfile.content.email) {
            toggleAccountDetailsDrawer(true);
          } else {
            setContributorEmailToEdit(item.email);
            toggleContributorDrawer(true);
          }
        }}>
          <SubscriptionTitleMobile>
            <IconCircle style={{background: item.acceptedInvite ? "#00B74F" : "#ef624e"}} />
            <SubscriptionNameMobile>{item.name ? item.name : <span style={{color: "#C7C9C7", fontWeight: 600}}>Name not provided</span>}</SubscriptionNameMobile>
          </SubscriptionTitleMobile>
          <div style={{display: "flex", flexDirection: "column", rowGap: "0.75rem"}}>
            <SubscriptionRowMobile>
              <SubscriptionColumnTitleMobile>
                Email
              </SubscriptionColumnTitleMobile>
              <SubscriptionColumnContentMobile>
                <a href={`mailto:${item.email}`}
                   style={{color: "#21272B", fontWeight: 400, textDecoration: "underline"}}>{item.email}</a>
              </SubscriptionColumnContentMobile>
            </SubscriptionRowMobile>
            <SubscriptionRowMobile>
              <SubscriptionColumnTitleMobile>
                Role
              </SubscriptionColumnTitleMobile>
              <SubscriptionColumnContentMobile>
                {item.email === userProfile.content.email ?
                  <span style={{color: "#2A80E1", fontWeight: 600}}>Owner</span> :
                  <span style={{color: "#45655E", fontWeight: 600}}>Contributor</span>}
              </SubscriptionColumnContentMobile>
            </SubscriptionRowMobile>
            <SubscriptionRowMobile>
              <SubscriptionColumnTitleMobile>
                Status
              </SubscriptionColumnTitleMobile>
              <SubscriptionColumnContentMobile>
                {item.acceptedInvite ?
                  <span style={{display: "flex", alignItems: "center", color: "#45655E"}}>
                  <ContributorActiveIcon style={{marginRight: "0.5rem", fontWeight: 600}} />Active</span> :
                  <span style={{display: "flex", alignItems: "center", color: "#C7C9C7", cursor: "pointer", fontWeight: 600}}
                        onClick={(event) => {
                          event.stopPropagation();
                          setInviteToResend(item);
                          toggleResendModal(true);
                        }}>
                  <ContributorResendIcon style={{marginRight: "0.5rem"}} />Invitation sent</span>}
              </SubscriptionColumnContentMobile>
            </SubscriptionRowMobile>
            <SubscriptionRowMobile>
              <SubscriptionColumnTitleMobile>
                Added
              </SubscriptionColumnTitleMobile>
              <SubscriptionColumnContentMobile>
                {moment(item.inviteTime).format(propertyPreferences.preferredDateFormat)}
              </SubscriptionColumnContentMobile>
            </SubscriptionRowMobile>
          </div>
        </div>))}
    </SubscriptionWrapperMobile>
  )

  const getPropertyForPet = (pet: any) => properties.filter(p => p.propertyId === pet.propertyId)[0]?.address

  const UserPets = () => (
    <PetList>
      <div className={"list-header"}>My Pets</div>
      {sortedPets.map((pet: any) => <div className={"list-item"} onClick={() => {
        setSelectedPet(pet);
        togglePetDrawer(true);
      }}>
        <div className={"list-item-content"}>
          <PetItemIcon style={{backgroundImage: `url(${pet.imageThumbnail})`}}>
            {!pet.imageThumbnail && <EmptyImagePreview />}
          </PetItemIcon>
          <div className={"list-item-info"}>
            <div className={"list-item-title"}>
              {pet.name}
              {getPropertyForPet(pet) && <div className={"list-item-property"}>{getPropertyForPet(pet)}</div>}
            </div>
            <div className={"list-item-description"}>{pet.petType}</div>
          </div>
        </div>
        <Dropdown
          overlay={moreMenuDropdown(pet)}
          placement="bottomRight"
          trigger={["click"]}
        >
          <MoreMenu
            style={{ marginLeft: "2rem" }}
            onClick={(e) => e.stopPropagation()}
          />
        </Dropdown>
      </div>)}

    </PetList>
  )

  return (
    <PageWrapper>
      {deleteReason()}
      <Modal
        className={"form-confirm-close-modal dismiss-modal"}
        visible={isResendModalOpen}
        closable={false}
        maskClosable={false}
        closeIcon={<></>}
        width={416}
        okText={"Send"}
        onOk={() => {
          if (userName === "" || contributorName === "") {
            userName === "" ? toggleUserNameError(true) : toggleUserNameError(false)
            contributorName === "" ? toggleContributorNameError(true) : toggleContributorNameError(false)
          } else {
            inviteUser({
              ownerName: userName,
              name: inviteToResend.name ? inviteToResend.name : contributorName,
              email: inviteToResend.email,
              subscriptionId: auth.tenantId
            }).then(() => {
              notification.success({
                message: "Invitation email sent!",
                description: "Invitation email sent!\n" +
                  "We’ve sent an invitation email to "+inviteToResend.email+" to join your Livlet account as an additional user. By following the link they will be able to contribute to your Livlet account.",
                placement: "topRight",
                duration: 4
              });
              dispatch(getAllSubscriptionUsers(auth.tenantId));
              setInviteToResend(null);
              toggleResendModal(false);
              toggleUserNameError(false);
              toggleContributorNameError(false);
              setUserName("");
              setContributorName("");
            }).catch(() => {
              notification.error({
                message: "Error",
                description: "Error while sending invitation.",
                placement: "topRight",
                duration: 2
              });
            });
          }
        }}
        onCancel={() => {
          setInviteToResend(null);
          toggleResendModal(false);
          toggleUserNameError(false);
          toggleContributorNameError(false);
          setUserName("");
          setContributorName("");
        }}
      >
        <div>
          <QuestionCircleOutlined className={"question-icon"} />
        </div>
        <div style={{marginLeft: "1rem"}}>
          <p>Would you like to send the invitation?</p>
          We’ll resend your additional user an invitation. Please make sure to ask the user to check their junk mail. The email will remain in use for 24 hours.
          <div style={{marginTop: "0.5rem"}}>Please provide your name so we can send the invitation.</div>
          <Input
            value={userName}
            placeholder={"Input your user name"}
            style={{marginTop: "0.25rem"}}
            onChange={(e) => setUserName(e.target.value)} />
          {userNameError && <div style={{color: "#fa4b48"}}>Please provide a valid user name</div>}
          {inviteToResend && !inviteToResend.name && <>
            <div style={{marginTop: "0.5rem"}}>Last time you did not fill the contributor's name. Please provide it as well.</div>
            <Input
              value={contributorName}
              placeholder={"Input the contributor's name"}
              style={{marginTop: "0.25rem"}}
              onChange={(e) => setContributorName(e.target.value)}/>
            {contributorNameError && <div style={{color: "#fa4b48"}}>Please provide a valid contributor name</div>}
          </>}
        </div>
      </Modal>
      <PetDrawer
        isDrawerOpen={isPetDrawerOpen}
        toggleDrawer={togglePetDrawer}
        pet={selectedPet}
        setPet={setSelectedPet}
      />
      <AccountSettingsDrawer
        isOpen={isAccountDetailsDrawerOpen}
        toggleDrawerOpen={toggleAccountDetailsDrawer}
      />
      <ContributorDrawer
        isOpen={isContributorDrawerOpen}
        toggleDrawerOpen={toggleContributorDrawer}
        contributorEmail={contributorEmailToEdit}
      />
      <PageHeader>
        <HeaderControlsWrapper>
          <PageTitle>Settings</PageTitle>
        </HeaderControlsWrapper>
      </PageHeader>
      <SectionLabel>
        Account details
        <ButtonOutline onClick={() => toggleAccountDetailsDrawer(true)}>Edit details
        </ButtonOutline>
      </SectionLabel>
      <PropertyLocationWrapper>
        <PropertyLocationData style={{ alignItems: "stretch" }}>
          <ColumnWrapper>
            <FieldWrapper>
              <Label>Name</Label>
              <PropertyData>
                {userProfile.content.name ? userProfile.content.name : <span style={{color: "#C7C9C7"}}>Name not provided</span>}
              </PropertyData>
            </FieldWrapper>
            <FieldWrapper>
              <Label>Password</Label>
              <PropertyData>
                **************
              </PropertyData>
            </FieldWrapper>
          </ColumnWrapper>
          <ColumnWrapper>
            <FieldWrapper>
              <Label>Email</Label>
              <PropertyData>
                {userProfile.content.email}
              </PropertyData>
            </FieldWrapper>
          </ColumnWrapper>
        </PropertyLocationData>
      </PropertyLocationWrapper>
      <SectionLabel>
        Notifications
      </SectionLabel>
      <NotificationSettingsWrapper>
        <div style={{fontWeight:600}}>
          How would you like to receive Livlet notifications?
        </div>
        <div style={{marginTop:"1rem",display:"flex",flexDirection:"row"}}>
          <div style={{marginRight: window.innerWidth > 1024 ? "10rem" : "1rem", display:"flex", alignItems:"center"}}>
            <input id={"notificationEmail"} type={"checkbox"} style={{marginRight:"0.4rem", cursor: "pointer"}}
               checked={userProfile.content.emailCommunication}
               onClick={() => {
                 notification.success({
                   message: "Change saved",
                   description: "Successfully changed the notifications setting",
                   placement: "topRight",
                   duration: 2
                 });
                 editUser({
                   name: userProfile.content.name,
                   email: userProfile.content.email,
                   emailCommunication: !userProfile.content.emailCommunication,
                   marketingCookies: userProfile.content.marketingCookies,
                   marketingEmails: userProfile.content.marketingEmails,
                   showPets: userProfile.content.showPets
                 }).then(() => {
                   dispatch(getCurrentUserProfile());
                 });
               }}
            />
            <label htmlFor={"notificationEmail"}>
              Email
            </label>
          </div>
          <div style={{display:"flex", alignItems:"center"}}>
            <input id={"notificationSMS"} type={"checkbox"} style={{marginRight:"0.4rem", cursor: "pointer"}} disabled={true}/>
            <label htmlFor={"notificationSMS"}>
              SMS (coming soon)
            </label>
          </div>
        </div>
        <div style={{fontWeight:600, marginTop:"2rem"}}>
          <div style={{display:"flex", alignItems:"center", flexShrink: 0}}>
            <input id={"marketingEmails"} type={"checkbox"} style={{marginRight:"0.4rem", flexShrink: 0, cursor: "pointer"}}
                   checked={userProfile.content.marketingEmails}
                   onClick={() => {
                     notification.success({
                       message: "Change saved",
                       description: "Successfully changed the notifications setting",
                       placement: "topRight",
                       duration: 2
                     });
                     editUser({
                       name: userProfile.content.name,
                       email: userProfile.content.email,
                       emailCommunication: userProfile.content.emailCommunication,
                       marketingCookies: userProfile.content.marketingCookies,
                       marketingEmails: !userProfile.content.marketingEmails,
                       showPets: userProfile.content.showPets
                     }).then(() => {
                       dispatch(getCurrentUserProfile());
                     });
                   }}
            />
            <label htmlFor={"marketingEmails"}>
              Send me occasional emails about new product features and service
            </label>
          </div>
        </div>
      </NotificationSettingsWrapper>

      <SectionLabel>
        Subscription details
      </SectionLabel>
      {auth.subscriptionOwner
      ? <PropertyLocationWrapper>
          <PropertyLocationData style={{alignItems: "stretch"}}>
              <ColumnWrapper style={{width: window.innerWidth > 575 ? "45%" : "100%"}}>
                  <FieldWrapper>
                      <Label>Subscription level</Label>
                      <PropertyData>
                        {auth.subscriptionLevel === "mcl" ? "McLaren employee" :
                         auth.subscriptionLevel === "mcla" ? "McL-A employee" :
                         auth.subscriptionLevel === "mclr" ? "McL-R employee" :
                        properties.length === 1
                          ? "Subscription level 1: Core; Single property"
                          : "Subscription level 2: Core; Multi-property"
                        }
                          {/*Basic, free*/}
                          {/*until {auth.freeUntil && auth.freeUntil.length > 0 ? auth.freeUntil : moment(userProfile.content.dateCreated).add(180, 'days').format("YYYY-MM-DD")}*/}
                      </PropertyData>
                  </FieldWrapper>
                  <FieldWrapper>
                      <Label>Account registration date</Label>
                      <PropertyData>
                        {moment(userProfile.content.dateCreated).format(propertyPreferences.preferredDateFormat)}
                      </PropertyData>
                  </FieldWrapper>
              </ColumnWrapper>
              <ColumnWrapper>
                  <FieldWrapper>
                      <Label>Payment</Label>
                      <PropertyData>
                        Free
                        {(auth.subscriptionLevel === "mcl"
                          || auth.subscriptionLevel === "mcla"
                          || auth.subscriptionLevel === "mclr")
                          && " for life"}
                        {/*{auth.freeUntil && auth.freeUntil.length > 0 ? "None" : "Stripe"}*/}
                      </PropertyData>
                  </FieldWrapper>
              </ColumnWrapper>
          </PropertyLocationData>
      </PropertyLocationWrapper>
      : <PropertyLocationWrapper>
          <PropertyLocationData style={{alignItems: "stretch"}}>
            <ColumnWrapper>
              <FieldWrapper>
                <Label>Subscription level</Label>
                <PropertyData>
                  Contributor
                  {auth.subscriptionLevel === "mcl" ? " (McLaren employee)" :
                    auth.subscriptionLevel === "mcla" ? " (McL-A employee)" :
                      auth.subscriptionLevel === "mclr" ? " (McL-R employee)" : ""}
                </PropertyData>
              </FieldWrapper>
              <FieldWrapper>
                <Label>Account registration date</Label>
                <PropertyData>
                  {moment(userProfile.content.dateCreated).format(propertyPreferences.preferredDateFormat)}
                </PropertyData>
              </FieldWrapper>
            </ColumnWrapper>
          </PropertyLocationData>
        </PropertyLocationWrapper>
      }

      {auth.subscriptionOwner && <>
      <SectionLabel>
          Users
        <ButtonOutline onClick={() => {setContributorEmailToEdit(null); toggleContributorDrawer(true)}}>
          Add User
        </ButtonOutline>
      </SectionLabel>
      <SubscriptionUsersWrapper>
        <SubscriptionUsers />
        <SubscriptionUsersMobile />
      </SubscriptionUsersWrapper>
      </>}

      <>
        <SectionLabel>
          Pets
          <ButtonOutline onClick={() => {
            setSelectedPet(null);
            togglePetDrawer(true);
          }}>
            Add Pets
          </ButtonOutline>
        </SectionLabel>
        {(userProfile.content.showPets || pets.content.length > 0) && <SubscriptionUsersWrapper>
          {(userProfile.content.showPets && pets.content.length === 0) && <SectionCloseCross onClick={() => closePetsSection()}>
              <CloseSection />
          </SectionCloseCross>}
          {pets.content.length > 0 ? <UserPets/> : <>
            <div className={"empty-section-text"}>
              <span>Do you have any pets? What are they? Add them to your home!</span>
              <div className={"empty-section-button"} onClick={() => togglePetDrawer(true)}>Add a pet</div>
            </div>
          </>}
        </SubscriptionUsersWrapper>}
      </>

      {auth.subscriptionOwner && <>
      <SectionLabel>
        User interests
      </SectionLabel>
      <PropertyLocationWrapper>
        <PropertyLocationData style={{ alignItems: "stretch" }}>
          <ColumnWrapper style={{width: "100%"}}>
            <Label>Pick one or more interests, this will help us recommend appropriate home tips that meet your interests.</Label>
            <PropertyData>
              <FormStyled onFinish={handleSubmit}>
                <Form.Item name="interests">
                  {interests.map((item: any, index: number) =>
                    <p>
                      <Checkbox
                        style={{cursor: "pointer"}}
                        value={item.name}
                        key={item.id}
                        disabled={item.name === none ? areAnyOptionsSelected() : isNoneOfTheAboveSelected()}
                        checked={item.picked}
                        onChange={() => {
                          let interestsTmp = [...interests]
                          interestsTmp[index].picked = !interestsTmp[index].picked;
                          setInterests(interestsTmp)
                        }}>
                        <span style={{marginLeft: "0.25rem"}}>{item.name}</span>
                      </Checkbox>
                    </p>)}
                </Form.Item>
                <SaveButton type={"primary"} htmlType={"submit"}>
                  {saveButtonText}
                </SaveButton>
              </FormStyled>
            </PropertyData>
          </ColumnWrapper>
        </PropertyLocationData>
      </PropertyLocationWrapper>
      </>}
      <CollapseDelete ghost>
        <Panel header="Additional settings" key="1">
          <DeleteSettingsWrapper>
            <div style={{
              fontWeight: 600,
              textAlign: window.innerWidth > 1024 ? "left" : "center",
              marginBottom: window.innerWidth > 1024 ? "0" : "0.75rem"
            }}>
              Would you like to download your data?
            </div>
            <DeleteButtonStyled
              type={"primary"}
              size={"large"}
              icon={<SaveIconStyled />}
              onClick={() =>
                dataDownload()
                  .then((res) => {
                    let blob = new Blob([res.data]);
                    const href = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', 'livlet-data.zip');
                    document.body.appendChild(link);
                    link.click();
                  })
              }
            >
              Download data
            </DeleteButtonStyled>
          </DeleteSettingsWrapper>
          <DeleteSettingsWrapper style={{marginTop: "1rem"}}>
            <div style={{
              fontWeight: 600,
              textAlign: window.innerWidth > 1024 ? "left" : "center",
              marginBottom: window.innerWidth > 1024 ? "0" : "0.75rem"
            }}>
              Would you like to delete your account?
            </div>
            <DeleteButtonStyled
              type={"primary"}
              size={"large"}
              danger
              icon={<DeleteImage/>}
              onClick={() => confirmDelete()}
            >
              Delete account
            </DeleteButtonStyled>
          </DeleteSettingsWrapper>
        </Panel>
      </CollapseDelete>
    </PageWrapper>
  );
};

export default Settings;

const NotificationSettingsWrapper = styled.section`
  background: #ffffff;
  padding: 20px 20px 18px 26px;
  border-radius: 10px;
  font-size: 1rem;
`;

const DeleteSettingsWrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  background: #fff;
  padding: 20px 20px 18px 26px;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #21272B;
  
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const DeleteButtonStyled = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 1rem!important;

  border-radius: 6px;
  font-weight: 600;
  background-color: #2a80e1;
  border-color: #2a80e1;
  
  svg {
    margin-right: 0.5rem
  }
  svg path{
    stroke: #fff;
  }
`;

const SaveIconStyled = styled(SaveIcon)`
  padding-right: 0.25rem;
  width: 22px;
  height: 22px;
`;

const CollapseDelete = styled(Collapse)`
  span {
    padding: 0!important;
  }
`;

const SubscriptionUsersWrapper = styled.section`
  background: #ffffff;
  border-radius: 10px;
  padding: 1rem;
  position: relative;
  
  .empty-section-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    row-gap: 1.5rem;
    padding: 1rem;
    
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #3E5D58;
  }
  
  .empty-section-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    background: #2A80E1;
    border-radius: 4px;
    
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #FFFFFF;
  }
`;

const SectionCloseCross = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
`

const PetList = styled.div`
  .list-header {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #21272B;
    
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: 1px solid #F6F7F7;
  }
  
  .list-item, .list-item-content {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .list-item {
    justify-content: space-between;
    padding: 1.5rem 0.5rem;
  }
  
  .list-item-icon {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
    background: #F5F6FA;
    background-repeat: no-repeat;
    background-position: center center; 
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .list-item-icon svg path {
    transform: scale(0.5);
    transform-origin: center;
  }
  
  .list-item-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .list-item-title {
    display: flex;
    gap: 1rem;
    align-items: center;
    
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #21272B;
  }
  
  .list-item-property {
    padding: 0.25rem 0.5rem;
    background: #EBF9FF;
    border: 1px solid #236EC2;
    border-radius: 19px;
    font-size: 0.625rem;
    line-height: 1rem;
    color: #236EC2;
  }
  
  .list-item-description {
    font-size: 0.875rem
    line-height: 1.325rem;
    color: #6B7185;
  }
`

export const PetItemIcon = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100%;
  background: #F5F6FA;
  background-repeat: no-repeat;
  background-position: center center; 
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg path {
    transform: scale(0.5);
    transform-origin: center;
  }
`

const SubscriptionUsersColumn = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 1.5rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-right: 0.5rem;
  width: ${(props: { spanWidth: string }) => props.spanWidth};
`

const IconCircle = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  flex-shrink: 0;
`

const SubscriptionTitleMobile = styled.div`
  display: flex;
  padding-bottom: 1rem;
  margin-bottom: 1.625rem;
  border-bottom: 1px solid #DAE0E6;
  width: 100%;
`

const SubscriptionNameMobile = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #21272B;
  padding: 0 0.5rem;
`

const SubscriptionColumnTitleMobile = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666F7A;
  width: 3.5rem;
`

const SubscriptionRowMobile = styled.div`
  display: flex;
`

const SubscriptionColumnContentMobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const SubscriptionWrapperDesktop = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (max-width: 1200px) {
    display: none;
  }
`

const SubscriptionWrapperMobile = styled.div`
  display: none;
  
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    padding: 0.25rem;
  }
`