import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TGetInsuranceByIdResponse = {
  object: {
    propertyId: Guid;
    id: Guid;
    subscriptionId: number;
    startDate: string;
    effectivePeriod: string;
    insured: string;
    insurer: string;
    policyNumber: string;
    insurancePremiumTax: number;
    administrationFee: number;
    discountReceived: string;
    totalAmountPaid: number;
    valueOfInsuredItems: number;
  }
  attachments: Array<{
    attachmentId: Guid;
    propertyId: Guid;
    parentId: Guid;
    parentType: string;
    storageKey: string;
    name: string;
    size: number;
    path: string;
  }>;
};

export const getInsuranceById = (propertyId: Guid, insuranceId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/insurance/${insuranceId}/details`);

  return apiGet<TGetInsuranceByIdResponse>(url);
};
