import {Guid} from "guid-typescript";
import {apiGet, createUrl} from "../apiCall";
import EnvConfig from "../../config";

export type TTagWithUsageCount = {
  tagId: Guid,
  name: string,
  usageCount: number,
  parentTagId: Guid | null,
  subscriptionId: Guid | null,
}

export type TGetTagsWithUsageCountResponse = Array<TTagWithUsageCount>

export const getTagsWithUsageCount = (propertyId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/tag/usageCount`);

  return apiGet<TGetTagsWithUsageCountResponse | []>(url);
};