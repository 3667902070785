import React from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

import {ReactComponent as ArrowLeft} from "../../../../images/ArrowLeftGray.svg";
import {ReactComponent as ArrowRight} from "../../../../images/ArrowRightGray.svg";

function Arrow({ children, disabled, onClick }: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={disabled ? "upkeep-tabs-arrow-disabled" : "upkeep-tabs-arrow"}
      style={{
        background: "#F0F2F5",
        border: 0,
        cursor: "pointer",
        //display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        right: "1%",
        userSelect: "none",
        display: disabled ? "none" : "flex"
          //width: disabled ? 0 : "auto"
        //opacity: disabled ? "0" : "1",
      }}
    >
      {children}
    </button>
  );
}

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );

  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <ArrowLeft />
    </Arrow>
  );
}

export function RightArrow() {
  const {
    isLastItemVisible,
    scrollNext,
    visibleItemsWithoutSeparators
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <ArrowRight />
    </Arrow>
  );
}