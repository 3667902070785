import {apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {TMortgage} from "./getMortgages";


export const createPastExpenses = (payload: TMortgage) => {
  const { propertyId } = payload;
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/mortgage/pastExpenses`);

  return apiPost<any, {}>(url, payload);
};
