import React, {useEffect, useState} from "react";
import {completeTask} from "../../../api-wrapper/upcomingTasks/completeTask";
import moment from "moment";
import {getAllMaintenances} from "../../actions/maintenances";
import {getAllExpenses} from "../../actions/expenses";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {DatePicker, Form, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {getAllWorks} from "../../actions/works";
import {InputNumberStyled} from "../expenses/ExpenseDrawer";

type Props = {
  taskToComplete: any;
  setTaskToComplete?: (arg: any) => void;
  isCompletionModalOpen: boolean;
  toggleCompletionModal: (arg: boolean) => void;
  handleRefresh: () => void;
}

const CompletionModal = (props: Props) => {
  const {taskToComplete, setTaskToComplete, isCompletionModalOpen, toggleCompletionModal, handleRefresh} = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);
  const [form] = Form.useForm();

  const [completionDate, setCompletionDate] = useState(moment())
  const [completionPrice, setCompletionPrice] = useState<any>(0)
  const [completionModalStep, setCompletionModalStep] = useState(1)
  const [completionPriceError, setCompletionPriceError] = useState<any>(false)

  useEffect(() => {
    if (taskToComplete) {
      setCompletionPrice(taskToComplete.actualEstimatedCost ?? taskToComplete.estimatedCost ?? 0)
    }
  }, [taskToComplete])

  return <Modal
    className={"form-confirm-close-modal dismiss-modal"}
    visible={isCompletionModalOpen}
    closable={false}
    maskClosable={false}
    closeIcon={<></>}
    width={416}
    okText={"Complete"}
    onOk={() => {
      if (completionModalStep === 1 && taskToComplete.category !== "Reminder" && taskToComplete.expenseType) {
        setCompletionModalStep(2)
      } else {
        if (!completionPriceError) {
          completeTask({
            propertyId,
            task: taskToComplete,
            completionDate: completionDate.format("YYYY-MM-DD"),
            totalCost: completionPrice
          }).then(() => {
            toggleCompletionModal(false)
            setTaskToComplete && setTaskToComplete(null)
            setCompletionDate(moment())
            setCompletionPrice(null)
            setCompletionModalStep(1)
            dispatch(getAllMaintenances(propertyId))
            dispatch(getAllExpenses(propertyId))
            dispatch(getAllWorks(propertyId))
            handleRefresh()
          })}
      }}}
    onCancel={() => {
      setTaskToComplete && setTaskToComplete(null)
      setCompletionModalStep(1)
      setCompletionPriceError(false)
      toggleCompletionModal(false)
    }}
  >
    <div>
      <QuestionCircleOutlined className={"question-icon"} />
    </div>
    {completionModalStep === 1 ? <div style={{marginLeft: "1rem"}}>
      <p>Almost completed!</p>
      Set a completion date for this task.
      <DatePicker
        value={completionDate}
        format={propertyPreferences.preferredDateFormat}
        allowClear={false}
        inputReadOnly={true}
        style={{
          marginTop: "0.5rem",
          width: "100%",
          border: "1px solid #DAE0E6",
          borderRadius: "6px",
          color: "rgb(107,113,133)",
        }}
        // @ts-ignore
        onChange={(event) => setCompletionDate(moment(event._d))}
      />
    </div> : <div style={{marginLeft: "1rem"}}>
      <p>Almost completed!</p>
      Now please confirm the expense for this task.
      <Form form={form} name="completionPrice">
        <Form.Item
          name={"expense"}
          key={"expense"}
          style={{marginTop: "0.5rem"}}
          rules={[
            { required: true, pattern: /^\d*\.?\d*$/, message: "Please input a valid number"},
          ]}>
          <InputNumberStyled
            defaultValue={completionPrice}
            onChange={(e) => {
              // @ts-ignore
              e && setCompletionPrice(parseInt(e))
              // @ts-ignore
              e && setCompletionPriceError(isNaN(parseInt(e)) || !/^\d*\.?\d*$/.test(e))
            }}
            maxLength={10}
            className={propertyPreferences.preferredCurrency}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          />
        </Form.Item>
      </Form>
      If the amount of money you've spent on this task is equal to zero, the expense will not be created.
    </div>}
  </Modal>
}

export default CompletionModal;