import React from "react";
import {NestedNumberedList, PremiseWrapper, TermsWrapper, Title, UnnumberedList} from "./Terms";

const PrivacyPolicy = () => {

  return (
    <TermsWrapper>
      <Title>Privacy Policy - Livlet</Title>
      <PremiseWrapper>
        <p>LAST UPDATED DATE: July 27, 2021</p>
      </PremiseWrapper>
      <NestedNumberedList>
        <li>This Privacy Policy sets out the terms on which we collect, process and handle personal data which you provide
          to us while using the site at <a href={"https://www.livlet.com/"} target={"blank"}>www.Livlet.com</a> ("<strong>Site</strong>")
          and the services provided from the Site ("<strong>Service</strong>").
          The Site, together with all content posted on the Site, is owned or controlled by Livlet Ltd (company number 13033019),
          a company incorporated in England with its registered office at 20-22 Wenlock Road, London N1 7GU, UK ("<strong>we</strong>"
          or "<strong>our</strong>" or "<strong>Company</strong>"). </li>
        <li>To provide the Service, it is necessary for us to collect and process certain personal information ("<strong>Data</strong>"),
          including some or all of the following: name, address, date of birth, gender, email address, IP addresses, device and
          web-browser details, operating system details, financial information, details relating to your property and activities
          and personal preferences relating to the Site and Service. You are responsible for ensuring that all personal information
          you provide to us is updated and correct at all times.</li>
        <li>The Data will be stored together with any additional information you provide to us, and will be used to provide you with,
          and to improve, the Service. We may collect, store and process certain personal information to provide you with information
          and tools to allow you to use the Service, to perform marketing and research related activities, to supply other
          administrative services (e.g. setting up new accounts), to respond to enquiries and/or for other record-keeping purposes
          and to improve the Site and the Service. Additionally, from time to time we and/or our trusted partners may contact you
          with offers that may interest you or to inform you of other products and services. All Data is processed by us in accordance
          with applicable data protection legislation and we will keep your personal information only for as long as is necessary.</li>
        <li>We may, from time to time, expand or reduce our business which may involve the transfer of certain divisions or assets
          of our company to other parties, and Data, where relevant, may be transferred to such third parties. Additionally, from
          time to time we may transfer Data obtained from Users located in the UK and/or the EU to locations outside the UK and/or
          the European Economic Area, some of which may have different data protection laws to those within the UK and/or the EEA,
          or no data protection laws.  In all such cases, we transmit such information only to entities that comply with this policy
          and applicable law.</li>
        <li>The Site may display links to certain third party websites. We encourage you to read the individual privacy policies
          of those third party websites before providing any of your personal information to them.</li>
        <li>We may use "cookies" while you access the Site to avoid the need to re-enter details on different occasions.
          Cookies are also used to collect general usage and volume statistical information. We may use local "shared objects",
          also known as "Flash" cookies. Examples of other reasons include:
          <UnnumberedList style={{marginBottom: "1rem"}}>
            <li>enabling us to recognise your device so that you don't have to give the same information to us several times;</li>
            <li>recognising that you may already have given a username and/or password; and/or,</li>
            <li>measuring how you are using the Service, to make the Service easier to use and to ensure sufficient capacity for quick delivery.</li>
          </UnnumberedList>
          <p>A "cookie" is a small amount of data sent from the server and stored on your computer's hard drive. Most web browsers
          are automatically set up to accept cookies, but you can set your browser to refuse cookies or ask your browser to show
          you where a cookie has been set up. These settings will typically be found in the 'options' or 'preferences' menu of
          your browser. In order to understand these settings, the following links may be helpful (otherwise you should use the
          'Help' option in your browser for more details):</p>
          <UnnumberedList style={{marginBottom: "1rem"}}>
            <li>Cookie settings in Internet Explorer</li>
            <li>Cookie settings in Firefox</li>
            <li>Cookie settings in Chrome</li>
            <li>Cookie settings in Safari</li>
          </UnnumberedList>
          <p>Please note that we do not recommend turning cookies off when using the Service as this may prevent you from using
          certain aspects of the Services and/or the Site. For further information about cookies and how to control their use,
          please visit the following third party educational
          resources: <a href={"http://www.youronlinechoices.eu/"} target={"blank"}>www.youronlinechoices.eu</a> and <a href={"https://ico.org.uk/make-a-complaint/"} target={"blank"}>https://ico.org.uk/concerns.</a></p>
          <p>Click <a href={"http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html#117118"} target={"blank"}>here</a> to learn how to manage privacy and storage settings for Flash cookies.</p>
        </li>
        <li>Please note you have the right to request access to and rectification or erasure of Data or to object to or request
          the restriction of processing of your Data. Subject to applicable law, you may withdraw your consent (i.e. "opt out"),
          by contacting us at <a href={"mailto:support.uk@Livlet.com"}>support.uk@Livlet.com</a>. If
          you are not interested in receiving email notices or advertisements from us, you should unsubscribe via your "Account Settings"
          on the Site (or by emailing us at <a href={"mailto:support.uk@Livlet.com"}>support.uk@Livlet.com</a> with
          "Unsubscribe" in the subject line). We reserve the rights in all cases to retain Data necessary to
          provide the Service and other legal reasons in full compliance with applicable Data Protection Law.
        </li>
        <li>We may change this Privacy Policy from time to time and such changes shall be effective from the date and time
          the revised Privacy Policy is posted on the Site.
          We encourage you to review our Privacy Policy on a regular basis so that you will be aware of any changes to it.</li>
        <li>If you have further questions about our privacy practices, please contact us
          by emailing <a href={"mailto:support.uk@Livlet.com"}>support.uk@Livlet.com</a>.</li>
      </NestedNumberedList>
    </TermsWrapper>
  );
};

export default PrivacyPolicy