import React, {useEffect} from "react";
import {ReactComponent as SurveyTellUsMore} from "../../../images/tour/SurveyTellUsMore.svg";
import {ReactComponent as AlarmIcon} from "../../../images/Alarm.svg";
import {SurveyModal, SurveyModalColumn, SurveyModalHeader} from "./WelcomeSurveyModal";
import {Button} from "antd";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {setUserWindowsState} from "../../../api-wrapper/user/setUserWindowsState";
import {useDispatch, useSelector} from "react-redux";
import {
  getImprovementsSelector,
  getPropertiesWithFetchingStatusSelector,
  getPropertyIdSelector,
  getUserProfileSelector
} from "../../selectors";
import _ from "lodash";
import {emptyGuid} from "../../screens/helpers";
import {getAllProperties} from "../../actions/properties";
import {TFetchingStatus} from "../../../helpers";

type Props = {
  isModalOpen: boolean,
  toggleModal: (arg: boolean) => void,
}

const TellUsMoreSurveyModal = (props: Props) => {
  const {isModalOpen, toggleModal} = props;
  let history = useHistory();
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const userProfile = useSelector(getUserProfileSelector);
  const properties = useSelector(getPropertiesWithFetchingStatusSelector);
  const improvementsConfiguration = useSelector(getImprovementsSelector);

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.content.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty)
      return;

    if (properties.fetchingStatus === TFetchingStatus.Success && improvementsConfiguration.fetchingStatus === TFetchingStatus.Success) {
      if (currentProperty.onboardingImprovementsStatus === "none" || currentProperty.onboardingImprovementsStatus === null) {
        toggleModal(true)
      }
    }
  }, [properties, improvementsConfiguration, propertyId])

  const handleOnboardingStatus = (status: string) => {
    const currentProperty = properties.content.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty)
      return;
    setUserWindowsState({
      onboardingInterestSurveyStatus: userProfile.content.onboardingInterestSurveyStatus,
      onboardingUpkeepStatus: currentProperty.onboardingUpkeepStatus,
      onboardingContentStatus: currentProperty.onboardingContentStatus,
      onboardingImprovementsStatus: status,
      mobileWelcomeStatus: userProfile.content.mobileWelcomeStatus,
      questionnairePage: currentProperty.questionnairePage,
      handholdingStatus: userProfile.content.handholdingStatus,
      propertyId
    }).then(() => {
      dispatch(getAllProperties())
      toggleModal(false)
    })
  }

  return (
    <SurveyModal
      width={933}
      visible={isModalOpen}
      closable={false}
      footer={null}
    >
      <SurveyModalColumn>
        <SurveyModalHeader>
          <p>Tell us more about your <span style={{color: "#2A80E1"}}>house</span></p>
        </SurveyModalHeader>
        <p>We would like to ask you a few questions so that we could configure Livlet
          to help you better. We'll always show you where in the process you are;
          you can stop and come back to answering your questions</p>
        <p><AlarmIcon style={{marginBottom: '-0.25rem', marginRight: '0.5rem'}} />This will not take longer than 2-5 minutes</p>

        <ButtonWrapper>
          <ButtonStyled
            style={{marginRight: "1.5rem"}}
            type={"primary"}
            onClick={() => history.push(`/questions?origin=dashboard`)}>
            Start</ButtonStyled>
          <ButtonStyled
            onClick={() => handleOnboardingStatus("skipped")}>
            Skip for now</ButtonStyled>
        </ButtonWrapper>
      </SurveyModalColumn>
      <SurveyModalColumn className={"illustration"}>
        <SurveyTellUsMore style={{marginLeft: "2.5rem"}} />
      </SurveyModalColumn>
    </SurveyModal>
  )
}

const ButtonStyled = styled(Button)`
  text-align: center;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  width: 11.25rem;
  height: 3.125rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
`

export default TellUsMoreSurveyModal