import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TAddMaintenanceResponse = {
  id: Guid;
  title?: string;
  message?: string;
  actualEstimatedCost?: number;
  actualPeriodicityUnit?: string;
  actualPeriodicity?: number;
  startDate?: string;
  subscribed?: boolean;
};

export type TAddMaintenanceRequest = {
  propertyId: Guid;
  title?: string;
  message?: string;
  actualEstimatedCost?: number;
  actualPeriodicityUnit?: string;
  actualPeriodicity?: number;
  startDate?: string;
  subscribed?: boolean;
};

export const addMaintenance = (payload: TAddMaintenanceRequest) => {
  const { propertyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/alerts/trigger`
  );

  return apiPost<TAddMaintenanceRequest, TAddMaintenanceRequest>(
    url, payload
  );
};
