import {notification} from "antd";
import React from "react";

export const allowedExtensions = ["pdf", "png", "svg", "tiff", "tif", "webp", "bmp", "jfif",
  "gif", "jpeg", "jpg", "ico", "doc", "docx", "odt", "rtf", "txt", "csv", "xls", "xlsx"];

export function showStartFileUploadNotification() {
  notification.success({
    message: "Document upload started",
    description:
      "The document is being uploaded",
    placement: "topRight",
  });
}

export function showSuccessfulFileUploadNotification() {
  notification.success({
    message: "Document upload successful",
    description:
      "The document has been successfully uploaded",
    placement: "topRight",
  });
}

export function showFailedFileUploadNotification(errorMsg: string) {
  notification.error({
    message: "Document upload error",
    description: <>The document has not been uploaded. <br/>{errorMsg}</>,
    placement: "topRight",
  })
}

export function showBouncedFileUploadNotification() {
  notification.error({
    message: "Error",
    description:
      "This file format is not allowed or the file size exceeds 20MB ; You can upload PDF, Image, Word, CSV and Excel files.",
    placement: "topRight",
  });
}

export function showBouncedImageUploadNotification() {
  notification.error({
    message: "Error",
    description:
      "This file format is not allowed or the file size exceeds 20MB ; You can upload JPG and PNG files.",
    placement: "topRight",
  });
}

export function showBouncedBatchUploadNotification() {
  notification.error({
    message: "Error",
    description:
      "The batch you're trying to upload is exceeding the storage limits.",
    placement: "topRight",
  });
}

export function showSuccessfulInvoiceBatchUploadNotification() {
  notification.success({
    message: "Invoice upload successful",
    description:
      "All your invoices have been successfully uploaded and processed!",
    placement: "topRight",
  });
}

export function showInvoiceBatchUploadErrorsNotification(errorMsg: string) {
  notification.warning({
    message: "Invoice upload warning",
    description: `We were unable to process the following invoices: ${errorMsg}`,
    placement: "topRight",
  })
}