import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {Guid} from "guid-typescript";
import _ from "lodash";
import {getDocuments, TGetDocumentsResponse} from "../../api-wrapper/documents/getDocuments";

export const getFloorPlansInitAction = createAction("floorPlans/GET_FLOOR_PLANS_INIT")();
export const getFloorPlansSuccessAction = createAction("floorPlans/GET_FLOOR_PLANS_SUCCESS")<TGetDocumentsResponse>();
export const getFloorPlansFailureAction = createAction("floorPlans/GET_FLOOR_PLANS_FAILURE")();

export type TGetFloorPlansInitAction = ActionType<typeof getFloorPlansInitAction>;
export type TGetFloorPlansSuccessAction = ActionType<typeof getFloorPlansSuccessAction>;
export type TGetFloorPlansFailureAction = ActionType<typeof getFloorPlansFailureAction>;

export type TGetFloorPlansActions =
  | TGetFloorPlansInitAction
  | TGetFloorPlansSuccessAction
  | TGetFloorPlansFailureAction;

export type TGetFloorPlansThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetFloorPlansActions>;

export const getAllFloorPlans: TGetFloorPlansThunkActionType = (propertyId: Guid) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getFloorPlansInitAction());
  return getDocuments(propertyId, "floorPlans")
    .then((payload) => {
      let floorPlans = _.orderBy(payload, ['documentDate'], ['desc'])
      return dispatch(getFloorPlansSuccessAction(floorPlans));
    })
    .catch(() => dispatch(getFloorPlansFailureAction()));
};