import {apiPut, createUrl} from "../apiCall";
import EnvConfig from "../../config";

export type TEditUserRequest = {
  name: string;
  email: string;
  emailCommunication: boolean;
  marketingCookies: boolean;
  marketingEmails: boolean;
  showPets: boolean;
};

export const editUser = async (payload: TEditUserRequest) => {
  let url = createUrl(`${EnvConfig.API_URL}/api/user/profile`);

  return apiPut<{}, any>(url, payload);
};