import React from 'react'
import {List, Modal, Skeleton} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {ReactComponent as DeleteImage} from "../../../images/Delete.svg";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import {ReactComponent as GreenDot} from "../../../images/GreenDot.svg";
import {numberWithCommas} from "../helper";
import {TGetInsurancesResponse} from "../../../api-wrapper/insurance/getInsurances";
import {
  ListItemStyled,
  PropertyListDesktop,
  PropertyPaymentDetails, SubTag, TagList, TagStyledBold,
  TagStyledGreen, TagStyledRed
} from '../properties/PropertyDetailsComp';
import moment from "moment";
import {removeInsurance} from "../../../api-wrapper/insurance/removeInsurance";
import {getAllInsurances} from "../../actions/insurances";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {emptyGuid, getCurrencySign} from "../../screens/helpers";
import {
  AddRecordButton,
  ButtonOutline,
  EmptyRecordsDescription,
  EmptyRecordsHeader,
  EmptyRecordsList, SectionLabel
} from "../../screens/components";

type Props = {
  insurance: TGetInsurancesResponse;
  toggleInsuranceDrawer: (arg: boolean) => void;
  setInsuranceData: (arg: any) => void;
  showContactName: (arg: any) => any;
};

const InsuranceListComp = (props: Props) => {
  const {insurance, toggleInsuranceDrawer, setInsuranceData} = props
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const isInsuranceListEmpty = (insurance: TGetInsurancesResponse) => {
    if (insurance.length === 0) return true
    else if (insurance[0].propertyId === emptyGuid) return true
    else return false
  }

  function confirmDelete(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        removeInsurance(propertyId, item.id)
          .then(() => {dispatch(getAllInsurances(propertyId))})
      }
    });
  }

  return (
    <>
      <SectionLabel>
        Insurance
        <ButtonOutline onClick={() => toggleInsuranceDrawer(true)}>
          Add Insurance
        </ButtonOutline>
      </SectionLabel>
      {isInsuranceListEmpty(insurance) && <EmptyRecordsList>
        <EmptyRecordsHeader>
          Add property insurance
        </EmptyRecordsHeader>
        <EmptyRecordsDescription>
          Add insurance information about your property so you can easily keep track of your insurance records.
        </EmptyRecordsDescription>
        <AddRecordButton
          onClick={() => toggleInsuranceDrawer(true)}
          icon={<PlusOutlined />}
          size={"large"}
        >
          Add property insurance
        </AddRecordButton>
      </EmptyRecordsList>
      }

      {!isInsuranceListEmpty(insurance) && (
        <>
          <PropertyListDesktop>
            <List
              style={{ width: "100%" }}
              className="rounded-top-bottom-list"
              itemLayout="horizontal"
              dataSource={insurance}
              renderItem={(item) => (
                <ListItemStyled
                  onClick={() => {
                    setInsuranceData({data: item});
                    toggleInsuranceDrawer(true);
                  }}
                  actions={[
                    <DeleteImage style={{ cursor: "pointer" }} onClick={(e) => {
                        e.stopPropagation();
                        confirmDelete(item)
                      }}/>
                  ]}
                >
                  <Skeleton loading={false} title={false} active>
                    <List.Item.Meta
                      title={<span style={{color: item.confirmed ? "#21272B" : "#C4C4C4"}}>
                        {item.insuranceType ? item.insuranceType : "No insurance type entered"}
                      </span>}
                      description={<span style={{color: item.confirmed ? "#6b7185" : "#C4C4C4"}}>
                        {item.insurer ? <Ellipsis style={{display: "inline"}} length={25}>{item.insurer}</Ellipsis> : "No insurer entered"}
                        <SubTag className={"display-mobile"}>
                          {item.startDate && item.effectivePeriod && (<span style={{color: item.confirmed ? "#21272B" : "#C4C4C4"}}>
                              Insurance period:{" "}
                              {moment(item.startDate).format(propertyPreferences.preferredDateFormat)} -{" "}
                              {moment(item.effectivePeriod).format(propertyPreferences.preferredDateFormat)}
                          </span>)}
                        </SubTag>
                      </span>}
                    />
                    <PropertyPaymentDetails>
                      <TagList>
                        {!item.confirmed && <TagStyledRed>
                          <GreenDot style={{marginTop: "0.5rem", marginRight: "0.5rem"}}/> Unconfirmed
                        </TagStyledRed>}
                        {moment().isBetween(item.startDate, item.effectivePeriod) && (<TagStyledGreen>
                          <GreenDot style={{marginTop: "0.5rem", marginRight: "0.5rem"}}/> CURRENT
                        </TagStyledGreen>)}
                        {item.totalAmountPaid > 0 && <TagStyledBold style={{color: item.confirmed ? "#6b7185" : "#C4C4C4"}}>
                          {getCurrencySign(propertyPreferences.preferredCurrency)}
                          {numberWithCommas(item.totalAmountPaid)}
                        </TagStyledBold>}
                      </TagList>
                      <SubTag className={"display-desktop"}>
                        {item.startDate && item.effectivePeriod && (
                          <span style={{color: item.confirmed ? "#6b7185" : "#C4C4C4"}}>
                            Insurance period:{" "}
                            {moment(item.startDate).format(propertyPreferences.preferredDateFormat)} -{" "}
                            {moment(item.effectivePeriod).format(propertyPreferences.preferredDateFormat)}
                          </span>
                        )}
                      </SubTag>
                    </PropertyPaymentDetails>
                  </Skeleton>
                </ListItemStyled>
              )}
            />
          </PropertyListDesktop>
        </>
      )}
    </>
  )
}

export default InsuranceListComp