import {apiGet, apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {clearAccessToken, decodeToken, saveAccessToken} from "./auth";
import axios from "axios";

export type TLogInRequest = {
  email: string;
  password: string;
  token: string;
};

export type TGetSubscriptionsResponse = Array<{
  subscriptionId: string;
  // ignore the other fields for now
}>;

export const logIn = async (email: string, password: string, token: string) => {
  let url = createUrl(`${EnvConfig.API_URL}/api/login?token=${token}`);
  const tmpAuth: any = await apiPost<{}, any>(url, {
    email,
    password,
  });

  saveAccessToken(tmpAuth.accessToken);

  url = createUrl(
    `${EnvConfig.API_URL}/api/authentication/activeSubscriptions`
  );

  //@ts-ignore
  const subscriptions: TGetSubscriptionsResponse = await apiGet<TGetSubscriptionsResponse>(url);

  url = createUrl(
    `${EnvConfig.API_URL}/api/authentication/currentTenant?tenantId=` +
      subscriptions[0].subscriptionId
  );
  const auth: any = await apiPost<{}, any>(url, {});

  clearAccessToken();

  return {...auth, ...subscriptions[0]};
};


export const refreshTokenRequest = async (refreshToken: string) => {
  const tenantId = decodeToken(refreshToken).tenantId;
  let url = createUrl(
    `${EnvConfig.API_URL}/api/authentication/refresh_token?tenantId=`
    + tenantId
  );

  const uninterceptedAxios = axios.create()

  const {data, headers} = await uninterceptedAxios.post<{}, any>(url, {}, {headers: {Authorization: `Bearer:${refreshToken}`}});

  return {accessToken: headers.authorization.split(":")[1]}
}

