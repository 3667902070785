import React from "react";
import {HandholdingCircle} from "../../screens/Home";
import {ReactComponent as HandholdingArrowRight} from "../../../images/HandholdingArrowRight.svg";

import {ReactComponent as EmptySpacesIcons} from "../../../images/empty-states/EmptySpacesIcons.svg";
import {ReactComponent as EmptySpacesLinkIcon} from "../../../images/empty-states/EmptySpacesLinkIcons.svg";
import {ReactComponent as ChevronLeft} from "../../../images/ChevronLeft.svg";
import {ReactComponent as ChevronRight} from "../../../images/ChevronRight.svg";

import EmptySpaces1 from "../../../images/empty-states/EmptySpaces1.png";
import EmptySpaces2 from "../../../images/empty-states/EmptySpaces2.png";
import EmptySpaces3 from "../../../images/empty-states/EmptySpaces3.png";
import EmptySpacesMobile1 from "../../../images/empty-states/EmptySpacesMobile1.png";
import EmptySpacesMobile2 from "../../../images/empty-states/EmptySpacesMobile2.png";
import EmptySpacesMobile3 from "../../../images/empty-states/EmptySpacesMobile3.png";
import {ButtonSmallBlue, ButtonSmallWhite, EmptyStateWrapper, IntroductionButton} from "../../screens/components";
import {AlertContent, AlertStyled, ContentWrapper, Dots, FlexWrapper} from "../inventories/InventoryIntroduction";
import {PlusOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {getUserProfileSelector} from "../../selectors";
import {setUserIntroductions} from "../../../api-wrapper/user/setUserIntroductions";
import {getCurrentUserProfile} from "../../actions/getUserProfile";
import {useHistory} from "react-router-dom";

type Props = {
  handholdingStatus: string;
  introductionStatus: string | null;
  toggleSpacesDrawer: (arg: boolean) => void;
}

const SpacesIntroduction = (props: Props) => {
  let history = useHistory();

  const {handholdingStatus, introductionStatus, toggleSpacesDrawer} = props;

  const dispatch = useDispatch();
  const userProfile = useSelector(getUserProfileSelector);

  const handleStateChange = (status: string) => {
    setUserIntroductions({
      contents: userProfile.content.contentsStatus,
      spaces: status,
      documents: userProfile.content.documentsStatus
    }).then(() => dispatch(getCurrentUserProfile()))
  }

  const navigationDots = () => <Dots>
    <span className={`dot ${(introductionStatus === "step1" || introductionStatus === null) && "active"}`} onClick={() => handleStateChange("step1")} />
    <span className={`dot ${introductionStatus === "step2" && "active"}`} onClick={() => handleStateChange("step2")} />
    <span className={`dot ${introductionStatus === "step3" && "active"}`} onClick={() => handleStateChange("step3")} />
  </Dots>

  const slide1 = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>What is the <span className={"highlight"}>Spaces</span> feature for?</span>
      <span className={"content-section"}>Spaces is a way of organising and thinking about your home data. Connecting documents, events,
        plans for the future and other data to actual spaces allows for a much more powerful way of managing your home.</span>
      {navigationDots()}
      <FlexWrapper className={"buttons"}>
        <ButtonSmallBlue onClick={() => handleStateChange("step2")}>
          Next <ChevronRight />
        </ButtonSmallBlue>
      </FlexWrapper>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptySpaces1} /></ContentWrapper>
  </AlertContent>

  const slide1Mobile = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>What is the <span className={"highlight"}>Spaces</span> feature for?</span>
      <span className={"content-section"}>Spaces is a way of organising and connecting
        your <b>home information</b> and <b>events</b> to your home's spaces for a much more powerful way to manage your home.</span>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptySpacesMobile1} /></ContentWrapper>
    {navigationDots()}
    <FlexWrapper className={"buttons"}>
      <ButtonSmallBlue onClick={() => handleStateChange("step2")}>
        Next <ChevronRight />
      </ButtonSmallBlue>
    </FlexWrapper>
  </AlertContent>

  const slide2 = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>How to use <span className={"highlight"}>Spaces</span>...</span>
      <span className={"content-section"}>You can create a custom space or select one from a list of presets.
        Further personalise it with an image and then start adding and linking related home information.
        <br /><br />
        Define key data about the space, link contents and projects & add notes (like paint colours).</span>
      <FlexWrapper className={"vertical"}>
        <FlexWrapper>
          <EmptySpacesLinkIcon />
          <span className={"content-section"}>Once you've added a space, use the <span className={"highlight"}>Link</span> button
            to connect contents, expenses, projects, tasks & notes to your Space.</span>
        </FlexWrapper>
      </FlexWrapper>
      {navigationDots()}
      <FlexWrapper className={"buttons"}>
        <ButtonSmallWhite onClick={() => handleStateChange("step1")}>
          <ChevronLeft /> Back
        </ButtonSmallWhite>
        <ButtonSmallBlue onClick={() => handleStateChange("step3")}>
          Next <ChevronRight />
        </ButtonSmallBlue>
      </FlexWrapper>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptySpaces2} /></ContentWrapper>
  </AlertContent>

  const slide2Mobile = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>How to use <span className={"highlight"}>Spaces</span>...</span>
      <FlexWrapper className={"vertical"}>
        <FlexWrapper>
          <EmptySpacesLinkIcon />
          <span className={"content-section"}>Once you've added a space, use the <span className={"highlight"}>Link</span> button
            to connect contents, expenses, projects, tasks & notes to your Space.</span>
        </FlexWrapper>
      </FlexWrapper>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptySpacesMobile2} /></ContentWrapper>
    {navigationDots()}
    <FlexWrapper className={"buttons"}>
      <ButtonSmallWhite onClick={() => handleStateChange("step1")}>
        <ChevronLeft /> Back
      </ButtonSmallWhite>
      <ButtonSmallBlue onClick={() => handleStateChange("step3")}>
        Next <ChevronRight />
      </ButtonSmallBlue>
    </FlexWrapper>
  </AlertContent>

  const slide3 = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>Making the most of <span className={"highlight"}>Spaces</span>...</span>
      <span className={"content-section"}>Over time you can use spaces to help <b>manage your home information</b>.
        <br/><br/>
        Keep on top of projects, tasks, home information, see which spaces are costing the
        most money and generally have a much higher level of awareness and control over the different areas of your home.</span>
      {navigationDots()}
      <FlexWrapper className={"buttons"}>
        <ButtonSmallWhite onClick={() => handleStateChange("step2")}>
          <ChevronLeft /> Back
        </ButtonSmallWhite>
        <ButtonSmallBlue onClick={() => handleStateChange("completed")}>
          Get started!
        </ButtonSmallBlue>
      </FlexWrapper>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptySpaces3} /></ContentWrapper>
  </AlertContent>

  const slide3Mobile = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>Making the most of <span className={"highlight"}>Spaces</span>...</span>
      <span className={"content-section"}>Keep on top of projects, tasks, home information, see which spaces are costing
        the most money and generally have a much higher level of awareness and control over the different areas of your home.</span>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptySpacesMobile3} /></ContentWrapper>
    {navigationDots()}
    <FlexWrapper className={"buttons"}>
      <ButtonSmallWhite onClick={() => handleStateChange("step2")}>
        <ChevronLeft /> Back
      </ButtonSmallWhite>
      <ButtonSmallBlue onClick={() => handleStateChange("completed")}>
        Get started!
      </ButtonSmallBlue>
    </FlexWrapper>
  </AlertContent>

  const emptyState = () => <EmptyStateWrapper>
    <EmptySpacesIcons />
    <span>Add spaces to view your home information by room and location.</span>
    <IntroductionButton onClick={() => history.push("/questions?openPage=spaces&origin=spaces")} size={"large"}>
      Let’s start by selecting a few items
      <ChevronRight style={{marginLeft:"0.5rem", height:"0.75rem"}} />
    </IntroductionButton>
  </EmptyStateWrapper>

  const introContentDesktop = () => {
    if (introductionStatus == "completed") return emptyState()
    if (introductionStatus == "step3") return slide3()
    if (introductionStatus == "step2") return slide2()
    if (introductionStatus === null || introductionStatus == "step1") return slide1()
    return ""
  }

  const introContentMobile = () => {
    if (introductionStatus == "completed") return emptyState()
    if (introductionStatus == "step3") return slide3Mobile()
    if (introductionStatus == "step2") return slide2Mobile()
    if (introductionStatus === null || introductionStatus == "step1") return slide1Mobile()
    return ""
  }

  return <AlertStyled
    message={window.innerWidth > 768 ? introContentDesktop() : introContentMobile()}
    closable={introductionStatus !== "completed"}
    onClose={() => handleStateChange("skipped")}
  />
}

export default SpacesIntroduction