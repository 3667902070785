import { apiDelete, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export const removeTenant = (propertyId: Guid, tenantId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/tenancy/${tenantId}`
  );

  return apiDelete(url);
};
