import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { useSwipeable } from 'react-swipeable';
import {ReactComponent as WorkIcon} from "../../../images/MaintenceWorksPage.svg";
import {ReactComponent as CostIcon} from "../../../images/planner/CostIconSmall.svg";
import {ReactComponent as ExpenseIcon} from "../../../images/planner/ExpenseIcon.svg";
import {ReactComponent as ReminderIcon} from "../../../images/planner/ReminderIconSmall.svg";
import {ReactComponent as TaskIcon} from "../../../images/planner/TaskIconSmall.svg";
import {ReactComponent as ArrowRight} from "../../../images/planner/ArrowRight.svg";
import {ReactComponent as RecurrenceIconGray} from "../../../images/planner/RecurrenceIconGray.svg";
import {ReactComponent as PlannerEdit} from "../../../images/planner/PlannerEdit.svg";
import {ReactComponent as PlannerDelete} from "../../../images/planner/PlannerDelete.svg";
import moment from "moment";

type Props = {
  item: any;
  handleReminderAction: (arg1: any, arg2: any) => void;
  handleWorkAction: (arg1: any, arg2: any) => void;
  handleExpenseAction: (arg1: any, arg2: any) => void;
  resetSwiping: boolean;
  toggleResetSwiping: (arg: boolean) => void;
}

const ToDoListItem = (props: Props) => {
  const {item, handleReminderAction, handleWorkAction, handleExpenseAction, resetSwiping, toggleResetSwiping} = props;
  const [hasBeenSwiped, toggleSwiped] = useState(false);

  useEffect(() => {
    if (resetSwiping) {
      toggleSwiped(false);
      toggleResetSwiping(false);
    }
  }, [resetSwiping])

  const handlers = useSwipeable({
    onSwipedLeft: () => toggleSwiped(true),
    onSwipedRight: () => toggleSwiped(false),
    trackMouse: true
  });

  const iconDispenser = (item: any) => {
    if (item.type === "work") return <WorkIcon />
    if (item.type === "alert") {
      if (item.task.upkeepType === "recurringexpense") return <CostIcon />
      if (item.task.category === "Reminder") return <ReminderIcon />
      else return <TaskIcon />
    }
    if (item.type === "expense") return <ExpenseIcon />
    else return null
  }

  const classDispenser = (item: any) => {
    if (item.type === "work") return "work"
    if (item.type === "expense") return "expense"
    if (item.type === "alert") {
      if (item.task.upkeepType === "recurringexpense") return "budget"
      if (item.task.category === "Reminder") return "reminder"
      else return "task"
    }
    else return ""
  }

  const isOverdue = () => moment(item.date).isBefore(moment().subtract(1, "day"))

  const handleViewAction = (item: any) => {
    if (item.type === "work") handleWorkAction("view", item.task)
    if (item.type === "expense") handleExpenseAction("view", item.task)
    if (item.type === "alert") handleReminderAction("view", item)
  }

  const handleEditAction = (item: any) => {
    if (item.type === "work") handleWorkAction("edit", item.task)
    if (item.type === "expense") handleExpenseAction("edit", item.task)
    if (item.type === "alert") handleReminderAction("edit", item)
  }

  const handleDismissAction = (item: any) => {
    if (item.type === "work") handleWorkAction("delete", item.task)
    if (item.type === "expense") handleExpenseAction("delete", item.task)
    if (item.type === "alert") handleReminderAction("dismiss", item)
  }

  return <ToDoItemWrapper>
    <ToDoItem {...handlers}
      onClick={() => handleViewAction(item)}
      className={(hasBeenSwiped ? "swiped " : "") + (item.type !== "expense" && isOverdue() ? "overdue" : "")}>
      <ItemInfo>
        {iconDispenser(item)}
        <span className={"info"}>
          <span className={"title"}>{item.title}</span>
          <span className={"description"}>
            <span className={"date"}>{moment(item.date).format("MMMM DD, YYYY")}</span>
            {item.periodicity && <RecurrenceIconWrapper className={classDispenser(item)}><RecurrenceIconGray/></RecurrenceIconWrapper>}
            {item.periodicity && <span className={"date"}>
              {item.type === "work" ? item.periodicity : `Every ${item.periodicity}(s)`}
            </span>}
          </span>
        </span>
      </ItemInfo>
      <ArrowRight />
    </ToDoItem>
    <ActionIcons>
      <PlannerEdit onClick={() => handleEditAction(item)} />
      <PlannerDelete onClick={() => handleDismissAction(item)} />
    </ActionIcons>
  </ToDoItemWrapper>
}

const ToDoItemWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
`

const ActionIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  
  svg { cursor: pointer; }
`

const ToDoItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #E6E6E6;
  border-radius: 16px;
  padding: 1rem;
  margin-left: 0;
  flex-shrink: 0;
  transition: .25s all ease;
  background: white;
  cursor: pointer;
  
  /* no selection for seamless swiping */
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  
  &.completed {
    border-image-slice: 1;
    border: double 2px transparent;
    border-radius: 16px!important;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #00B74F, #F7FEFA);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
  
  &.overdue {
    border-image-slice: 1;
    border: double 2px transparent;
    border-radius: 16px!important;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #FCC800, #FFFFFF);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
  
  @media (max-width: 768px) {
    &.swiped { margin-left: -9rem; }
  }
`

const ItemInfo = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;
  
  .info {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
  }
  
  .title {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #002A43;
  }
  
  .description {
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    align-items: center;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 1rem;
  }
  
  .date { color: #666F7A; }
  
  .recurrence { color: #C7C9C7; }
`

const RecurrenceIconWrapper = styled.span`
  &.work svg path { fill: #2A80E1;}
  &.budget svg path { fill: #7479E9;}
  &.reminder svg path { fill: #EFC35D;}
  &.task svg path { fill: #00CC33;}
`

export default ToDoListItem