import React, {useEffect, useState} from "react";
import {selectStyle} from "../spaces/EditSpaceDrawer";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";
import {ReactComponent as ReminderIcon} from "../../../images/planner/ReminderIcon.svg";
import {ReactComponent as TaskIcon} from "../../../images/planner/TaskIcon.svg";
import {ReactComponent as CostIcon} from "../../../images/planner/CostIcon.svg";
import {ReactComponent as Checkmark} from "../../../images/planner/Checkmark.svg";
import {ReactComponent as CalendarIcon} from "../../../images/planner/CalendarIcon.svg";
import {ReactComponent as RecurrenceIcon} from "../../../images/planner/RecurrenceIcon.svg";
import {ReactComponent as CostEstimateIcon} from "../../../images/planner/CostEstimateIcon.svg";
import {ReactComponent as InfoIcon} from "../../../images/planner/InfoIcon.svg";
import styled from "styled-components";
import moment from "moment";
import {Dropdown, Form, Input, Modal, Select, TreeSelect} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
  getContactsSelector,
  getExpenseTypesSelector,
  getPropertyIdSelector,
  getPropertyPreferencesSelector
} from "../../selectors";
import _ from "lodash";
import {editMaintenanceApi} from "../../../api-wrapper/maintenance/editMaintenance";
import {addMaintenance} from "../../../api-wrapper/maintenance/addMaintenance";
import {getAllMaintenances} from "../../actions/maintenances";
import {getAllOnboardingMaintenances} from "../../actions/onboardingMaintenances";
import {getEntityTypes} from "../../../api-wrapper/relation/getEntityTypes";
import ContactDrawer from "../contacts/ContactDrawer";
import {TreeNode} from "antd/es/tree-select";
import {AutoCompleteStyled} from "../styled";
import {addContact} from "../../../api-wrapper/contacts/addContact";
import TaskCalendar from "./TaskCalendar";
import {isRecurringTask} from "./ViewTaskDrawer";
import {setCostTypes} from "../helper";
import {Guid} from "guid-typescript";
import {addRelation} from "../../../api-wrapper/relation/addRelation";
import {
  ActionButtonGreen,
  ActionButtonWhite,
  CheckboxStyled,
  CircleIcon,
  DrawerFooter,
  DrawerHeader,
  EditTaskDrawer,
  FormStyled
} from "../../screens/components";
import {ReactComponent as CheckmarkIcon} from "../../../images/planner/CheckmarkIcon.svg";
import {Overlay} from "../../screens/Home";
import {linkToRecord} from "../spaces/AddNewRecordDrawer";
import {getAllContacts} from "../../actions/contacts";
import {TTaskCategories, TTaskTypes} from "../../../helpers";
import {emptyGuid, getCurrencySign} from "../../screens/helpers";

export const getTaskType = (task: any) => {
  if (task) {
    if (task.category === TTaskCategories.Budget || task.category === TTaskCategories.CustomBudget) return TTaskTypes.Budget
    if (task.category === TTaskCategories.Reminder) return TTaskTypes.Reminder
    if (task.category === TTaskCategories.CustomTask) return TTaskTypes.Task
    else return TTaskTypes.Upkeep
  }
  else return ""
}

export const getUpkeepType = (taskType: any) => {
  if (taskType === TTaskCategories.Budget || taskType === TTaskCategories.CustomBudget) return "recurringexpense"
  if (taskType === TTaskCategories.Reminder) return "reminder"
  else return "task"
}

export const getDrawerTitle = (type: any) => {
  if (type === TTaskCategories.Budget || type === TTaskCategories.CustomBudget) return "Recurring expense"
  if (type === TTaskCategories.Reminder) return "Reminder"
  else return "Task"
}

export const getDrawerIcon = (type: string) => {
  if (type === TTaskCategories.Reminder) return <ReminderIcon />
  if (type === TTaskCategories.Budget || type === TTaskCategories.CustomBudget) return <CostIcon />
  else return <TaskIcon />
}

const getTaskCategory = (taskType: any) => {
  if (taskType === TTaskTypes.Reminder) return TTaskCategories.Reminder
  if (taskType === TTaskTypes.Budget) return TTaskCategories.CustomBudget
  else return TTaskCategories.CustomTask
}

type Props = {
  task: any;
  setEditingTaskData: (arg01: any) => void;
  isOpen: boolean;
  toggleDrawer: (arg: boolean) => void;
  taskType: string;
  setTaskType: (arg: string) => void;
  refreshParent?: () => void | null;
  inventory?: Guid | null
  parentRecordId?: Guid | null;
  parentRecordType?: string;
}

const UpdateTaskDrawer = (props: Props) => {
  const {isOpen, toggleDrawer, task, setEditingTaskData, taskType, setTaskType, refreshParent, inventory, parentRecordId, parentRecordType} = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const expenseTypes = useSelector(getExpenseTypesSelector);
  const contacts = useSelector(getContactsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [hasDateBeenPicked, setPickedDate] = useState(true);
  const [selectedDate, setSelectedDate] = useState<any>(moment());
  const [showRecurrenceFields, toggleRecurrenceFields] = useState(false);
  const [showExpenseFields, toggleExpenseFields] = useState(false);
  const [showDescriptionField, toggleDescriptionField] = useState(false);
  const [autocomplete, toggleAutocomplete] = useState(false);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);

  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editContactData, setEditContactData] = useState<any>(null);

  const [workTypes, setWorkTypes] = useState<any>([]);
  const [leaves, setLeaves] = useState<any>([]);
  const [roots, setRoots] = useState<any>([]);
  const [contactList, setContactList] = useState<Array<{ value: string; label: string }>>([]);
  const [saveAsContact, setSaveAsContact] = useState(false);
  const [isExistingPayee, setIsExistingPayee] = useState(false);
  const [frequency, setFrequency] = useState<any>(null);
  const [frequencyUnit, setFrequencyUnit] = useState<any>("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [cost, setCost] = useState<any>(null);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  })

  const disabledEdit = (task && task.category !== TTaskCategories.CustomTask && task.category !== TTaskCategories.CustomBudget
    && task.category !== TTaskCategories.Reminder);

  useEffect(() => {
    setCostTypes(expenseTypes.content, setRoots, setLeaves)
  }, [expenseTypes])

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    if (!isOpen)
      return;

    getEntityTypes(propertyId, "work").then((res: any) => { setWorkTypes(res) })
  }, [propertyId, isOpen])

  useEffect(() => {
    if (task) {
      form.resetFields();
      setPickedDate(true);
      setSelectedDate(task.startDate ? moment(task.startDate) : moment());
      toggleRecurrenceFields(isRecurringTask(task));
      toggleExpenseFields(!!task.expenseType);
      toggleAutocomplete(task.autocomplete);
      setTaskType(getTaskType(task));
      setFrequency(task.actualPeriodicity ?? task.periodicity);
      setFrequencyUnit(task.actualPeriodicityUnit ?? task.periodicityUnit);
      setCost(task.actualEstimatedCost ?? task.estimatedCost);
      setIsExistingPayee(contacts.content.filter((contact: any) =>
        contact.contactId === task.payee)[0]?.contactType === ("Payee"));
      setFormFieldsChanged(false);
    } else {
      form.resetFields();
      setPickedDate(false);
      setSelectedDate(moment());
      toggleRecurrenceFields(false);
      toggleExpenseFields(false);
      toggleAutocomplete(false);
      setIsExistingPayee(false);
      setFrequency(null);
      setFrequencyUnit("");
      setCost(null);
      setFormFieldsChanged(false);
    }
  }, [task])

  const initialValues = () => {
    if (task) {
      const { title, message, estimatedCost, actualEstimatedCost, actualPeriodicity, periodicity,
        periodicityUnit, actualPeriodicityUnit, expenseType, workType, payee } = task;
      let payeeName = undefined
      if (payee) {
        payeeName = contacts.content.filter(contact => contact.contactId === payee)[0]?.name
      }
      return {
        title,
        description: message,
        cost: actualEstimatedCost ? actualEstimatedCost : estimatedCost,
        frequency: isRecurringTask(task) ? (actualPeriodicity ? actualPeriodicity : periodicity) : undefined,
        frequencyUnit: actualPeriodicityUnit ? actualPeriodicityUnit : periodicityUnit,
        costType: expenseType?.id,
        taskWorkType: workType?.name,
        payee: payeeName
      }
    } else {
      return {}
    }
  };

  const performCleanup = () => {
    if (refreshParent) refreshParent();
    setFormFieldsChanged(false);
    setPickedDate(false);
    setSelectedDate(moment());
    toggleRecurrenceFields(false);
    toggleExpenseFields(false);
    toggleAutocomplete(false);
    toggleDrawer(false);
    setEditingTaskData(null);
    setTaskType("");
    setIsExistingPayee(false);
    setFrequency(null);
    setFrequencyUnit("");
    setCost(null);
    form.resetFields();
    dispatch(getAllMaintenances(propertyId));
    dispatch(getAllOnboardingMaintenances(propertyId))
  }

  const handleCancel = () => {
    if (formFieldsChanged) {
      confirmCloseUnsaved();
      return;
    }
    performCleanup();
  }

  const createNewContact = async (payee: string) => {
    return await addContact({
      propertyId,
      request: {
        contactType: saveAsContact ? '' : 'Payee',
        name: payee,
        // @ts-ignore
        tags: []
      },
      contactImage: null
    })
  }

  const getContact = async (payee: string) => {
    if (!payee) return null
    else {
      let contactSearch = contacts.content.filter(contact => contact.name === payee)
      if (contactSearch.length > 0) {
        return { contact: contactSearch[0], isNewContact: false }
      } else {
        let newContact = await createNewContact(payee)
        return { contact: newContact.data, isNewContact: true }
      }
    }
  }

  const getTaskWorkType = (taskWorkType: string) => {
    if (taskType === TTaskCategories.Reminder) return workTypes.filter((type: any) => type.name.includes("Maintenance"))[0]
    if (taskType === TTaskCategories.Budget || taskType === TTaskCategories.CustomBudget) return workTypes.filter((type: any) => type.name.includes("Expense"))[0]
    else return workTypes.filter((type: any) => type.name === taskWorkType)[0]
  }

  const handleManageTask = async (e: {
    title: string;
    frequency: number;
    frequencyUnit: string;
    taskWorkType: string;
    cost: number;
    costType: string;
    payee: string;
    description: string;
  }) => {
    const { title, frequency, frequencyUnit, taskWorkType, cost, costType, payee, description } = e;

    let payeeContact = await getContact(payee)
    const maintenanceToSave = {
      propertyId,
      title,
      category: getTaskCategory(taskType),
      message: description,
      startDate: selectedDate.format("YYYY-MM-DD"),
      actualPeriodicity: frequency ?? null,
      actualPeriodicityUnit: frequencyUnit ?? "once",
      actualEstimatedCost: cost ?? 0,
      autocomplete: autocomplete,
      // @ts-ignore
      expenseType: costType ? expenseTypes.content[costType] : null,
      workType: getTaskWorkType(taskWorkType),
      upkeepType: getUpkeepType(taskType),
      // @ts-ignore
      payee: payeeContact ? payeeContact.contact.contactId : null
    }
    if (task && task.id) {
      editMaintenanceApi({...task, ...maintenanceToSave}, task.id)
        .then(() => {
          payeeContact && dispatch(getAllContacts(propertyId));
          performCleanup();
        });
    } else {
      addMaintenance({...maintenanceToSave, subscribed: true})
        .then((res) => {
          if (inventory) {
            addRelation({
              propertyId,
              parentId: inventory,
              parentType: "inventory",
              // @ts-ignore
              childId: res.data.id,
              // @ts-ignore
              childType: res.data.upkeepType,
            }).then(() => performCleanup())
          }
          if (parentRecordId && parentRecordType) {
            // @ts-ignore
            linkToRecord(propertyId, parentRecordId, parentRecordType, res.data.id, res.data.upkeepType, dispatch)
          }
          if (refreshParent) refreshParent()
          if (payeeContact) dispatch(getAllContacts(propertyId))
          performCleanup()
        });
    }
    if (payeeContact && saveAsContact) {
      toggleContactDrawer(true)
      setEditContactData({data: payeeContact.contact})
    }
  };

  useEffect(() => {
    let unsortedContactList = contacts.content.filter(contact => contact.name).map((contact) => {
      return {value: contact.name, label: contact.name +
        (_.isEmpty(contact.company) ? "" : (contact.company === contact.name ? "" : " - " + contact.company)) +
        (_.isEmpty(contact.contactType) ? "" : " (" + contact.contactType+")")}})
    setContactList(unsortedContactList.sort((a: any, b: any) => {
      if(a.label.toLowerCase() < b.label.toLowerCase()) { return -1; }
      if(a.label.toLowerCase() > b.label.toLowerCase()) { return 1; }
      return 0;
    }))
  }, [contacts.content]);

  const onPayeeAutocompleteSearch = (searchText: string) => {
    setContactList(!searchText ? contacts.content.map((contact) => {
      return {value: contact.name, label: contact.name + (_.isEmpty(contact.contactType) ? "" : " (" + contact.contactType+")")}})
        : contacts.content.filter((contact) => contact.name && contact.name.toLowerCase().includes(searchText.toLowerCase())).map((contact) => {
          return {value: contact.name, label: contact.name + (_.isEmpty(contact.contactType) ? "" : " (" + contact.contactType+")")}}))
  };

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        performCleanup();
      },
    });
  }

  const costFields = () => <CostFieldsWrapper className={"budget"}>
    <Label><Accent>Cost</Accent></Label>
    <Form.Item
      key={"cost"}
      name={"cost"}
      className={"no-underline"}
      rules={[{
        pattern: (/^[0-9]+$/),
        required: (taskType === "Budget"),
        message: "Please input a valid cost value (you can input zero if you don't know the value)"
      }]}>
      <Input
        prefix={getCurrencySign(propertyPreferences.preferredCurrency)}
        autoComplete={"off"}
        style={{...selectStyle, border: "1px solid #00B74F"}}
        placeholder={"Enter cost estimate value"}
        onChange={(e) => setCost(e.target.value)}
      />
    </Form.Item>
    <Label><Accent>Cost type</Accent></Label>
    <Form.Item
      key={"costType"}
      name={"costType"}
      className={taskType === TTaskTypes.Budget ? "no-underline" : ""}
      rules={[{
        required: (cost || taskType === TTaskTypes.Budget),
        message: 'Please pick an expense type'
      }]}>
      <TreeSelect
        showSearch={false}
        style={{ width: '100%' }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        listHeight={250}
        placeholder="Please select"
        allowClear
        disabled={disabledEdit}
        treeDefaultExpandAll
        getPopupContainer={trigger => trigger.parentElement}
        dropdownRender={(menu) => (<div>{menu}</div>)}
      >
        {roots.map((root: any) => {
          return (<TreeNode value={root[1].id} title={root[1].name} selectable={false}>
            {leaves.map((leaf: any) => {
              if (leaf[1].parentId === root[1].id)
                return <TreeNode value={leaf[1].id} title={leaf[1].name}/>
            })}
          </TreeNode>)
        })}
      </TreeSelect>
    </Form.Item>
    <Label><Accent>Payee</Accent></Label>
    <Form.Item key={"payee"} name={"payee"}>
      <AutoCompleteStyled
        // @ts-ignore
          getPopupContainer={trigger => trigger.parentElement}
          showSearch
          allowClear={true}
          placeholder={"Please select a payee"}
          bordered={false}
          showArrow={false}
          defaultActiveFirstOption={false}
          filterOption={false}
          style={{border: "none"}}
          onSearch={onPayeeAutocompleteSearch}
          onChange={(val) => { setIsExistingPayee(contactList.filter((contact: any) =>
            contact.value === val)[0]?.label?.includes("Payee"))}}
      >
        {contactList.map((contact: any) =>
          <Select.Option value={contact.value}>
            <span style={{fontWeight: contact.label.includes("Payee") ? 400 : 600}}>
              {contact.label}
            </span>
          </Select.Option>)}
      </AutoCompleteStyled>
    </Form.Item>
    {form.getFieldValue("payee") && (!contactList.filter((contact: any) =>
      contact.value === form.getFieldValue("payee")).length || isExistingPayee) &&
    <div style={{display: "flex", alignItems: "center", columnGap: "0.75rem", marginTop: "-0.5rem", marginBottom: "0.5rem"}}>
      <CheckboxStyled
        className={saveAsContact ? "checked" : ""}
        onClick={() => setSaveAsContact(!saveAsContact)}>
        <Checkmark/>
      </CheckboxStyled>
      Add this Payee to your Contacts list
    </div>}
  </CostFieldsWrapper>

  return <>
    <ContactDrawer
      isOpen={isContactDrawerOpen}
      toggleDrawerOpen={toggleContactDrawer}
      setEditingContactData={setEditContactData}
      contact={editContactData}
    />
    <EditTaskDrawer
      push={{distance: "32px"}}
      closeIcon={false}
      visible={isOpen}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "100%" : "85%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      onClose={() => handleCancel()}
      title={<DrawerHeader className={`task-header ${hasDateBeenPicked ? "underlined" : ""}`}>
        <DrawerTitle>
          {getDrawerIcon(taskType)} {getDrawerTitle(taskType)}
        </DrawerTitle>
        {windowWidth > 768 && <CircleIcon onClick={() => handleCancel()}><CloseIcon/></CircleIcon>}
      </DrawerHeader>}
    >
      <FormStyled
      form={form}
      style={{justifyContent: (!hasDateBeenPicked && !task) ? "center" : "space-between"}}
      name="manageTask"
      layout="vertical"
      size={"large"}
      // @ts-ignore
      onFinish={handleManageTask}
      initialValues={initialValues()}
      onFieldsChange={() => setFormFieldsChanged(true)}
    >
      {!hasDateBeenPicked ? <TaskCalendar
          arePassedDatesAllowed={false}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setPickedDate={setPickedDate}
          handleCancel={handleCancel}
        /> : <FormWrapper>
        <Form.Item key={"title"} name={"title"}
          rules={[{required: true, message: `${taskType} title is required`}]}>
          <Input autoComplete={"off"} className={"title-input"} disabled={disabledEdit} placeholder={`Enter ${taskType.toLowerCase()} title*`} />
        </Form.Item>
        {windowWidth <= 768 && !showDescriptionField &&
        <TextButtonWrapper
          style={{paddingTop: "1rem"}}
          onClick={() => toggleDescriptionField(true)}
          className={"green"}>
          Add description</TextButtonWrapper>}

        <SpaceBetweenWrapper className={(showRecurrenceFields || taskType === TTaskCategories.Reminder && !showDescriptionField) ? "" : "underlined"}>
          <TextButtonWrapper onClick={() => setPickedDate(false)}>
            <CalendarIcon />{selectedDate.format("DD MMM YYYY")}
          </TextButtonWrapper>
          {!showRecurrenceFields && <TextButtonWrapper className={"green"} onClick={() => toggleRecurrenceFields(true)}>
              <RecurrenceIcon/>Add recurrence?
          </TextButtonWrapper>}
        </SpaceBetweenWrapper>

        {showRecurrenceFields && <Label><Accent>Frequency</Accent></Label>}
        {showRecurrenceFields && <FrequencyWrapper className={(taskType !== TTaskCategories.Reminder && !showDescriptionField) ? "" : "underlined"}>
          <span style={{height: "3rem", display: "flex", alignItems: "center"}}>Every</span>
          <Form.Item
            key={"frequency"}
            name={"frequency"}
            className={"no-underline"}
            rules={[{
              required: frequencyUnit && frequencyUnit !== "once",
              pattern: (/^[0-9]+$/),
              message: 'Please input a valid frequency',
          }]}>
            <Input
                disabled={frequencyUnit === "once"}
                style={{...selectStyle}}
                placeholder={"Enter number"}
                onChange={(e) => setFrequency(e.target.value)}
            />
          </Form.Item>
          <Form.Item
              key={"frequencyUnit"}
              name={"frequencyUnit"}
              className={"no-underline"}
              rules={[{required: frequency, message: 'Please pick a valid period'}]}
          ><Select
              getPopupContainer={trigger => trigger.parentElement}
              placeholder={"Select period"}
              bordered={false}
              style={{...selectStyle, width: "10rem", border: "1px solid #00B74F"}}
              onChange={(v) => setFrequencyUnit(v)}
            >
              <Select.Option key={"day"} value={"day"}>Days</Select.Option>
              <Select.Option key={"week"} value={"week"}>Weeks</Select.Option>
              <Select.Option key={"month"} value={"month"}>Months</Select.Option>
              <Select.Option key={"year"} value={"year"}>Years</Select.Option>
              <Select.Option key={"once"} value={"once"}>Once</Select.Option>
            </Select>
          </Form.Item>
        </FrequencyWrapper>}

        {taskType === TTaskTypes.Task && <Label>Task type <Accent>*</Accent></Label>}
        {taskType === TTaskTypes.Task && <Form.Item
            key={"taskWorkType"}
            name={"taskWorkType"}
            rules={[{required: taskType === TTaskTypes.Task, message: 'Please pick a task type'}]}
        >
          <Select
            getPopupContainer={trigger => trigger.parentElement}
            placeholder={"Select category"}
            bordered={false}
            style={{...selectStyle}}
            disabled={disabledEdit}
          >
            {workTypes && workTypes.filter((type: any) => type.name !== "Expenses").map((type: any) =>
              <Select.Option key={type.name} value={type.name}>{type.name}</Select.Option>)}
          </Select>
        </Form.Item>}

        {(taskType === TTaskTypes.Task || taskType === TTaskTypes.Upkeep) && (!showExpenseFields ?
          <SpaceBetweenWrapper className={showExpenseFields ? "" : "underlined"}>
          <TextButtonWrapper className={"green"} onClick={() => toggleExpenseFields(true)}>
          <CostEstimateIcon/>Add cost estimate?
          </TextButtonWrapper>
          </SpaceBetweenWrapper> : costFields())}

        {taskType === TTaskTypes.Budget && costFields()}

        {(taskType === TTaskTypes.Task || taskType === TTaskTypes.Budget) &&
        <SpaceBetweenWrapper className={showDescriptionField ? "underlined" : ""}>
          <TextButtonWrapper className={"green"}>
            <CheckboxStyled
              className={autocomplete ? "checked" : ""}
              onClick={() => toggleAutocomplete(!autocomplete)}>
                <Checkmark/>
            </CheckboxStyled>
            Add autocomplete?<Dropdown placement="bottomCenter" overlay={<Overlay>
            If you turn this option on, the task will be marked as completed automatically when it is due
          </Overlay>}><InfoIcon/></Dropdown>
          </TextButtonWrapper>
        </SpaceBetweenWrapper>}

        {(windowWidth > 768 || showDescriptionField) && <Label>Description <Accent>(optional)</Accent></Label>}
        {(windowWidth > 768 || showDescriptionField) && <Form.Item key={"description"} name={"description"} className={"no-underline"}>
          <Input autoComplete={"off"} style={selectStyle} disabled={disabledEdit} placeholder={"Enter text"}/>
        </Form.Item>}
      </FormWrapper>}
      {hasDateBeenPicked && <div>
        {task && <Label>
          By editing this task, you are editing the entire series. If you change the frequency or the start date, the
          entire series will be recreated.
        </Label>}
        <DrawerFooter className={"fake-footer desktop-footer"}>
          <ActionButtonWhite onClick={() => handleCancel()}>Cancel</ActionButtonWhite>
          <ActionButtonGreen htmlType={"submit"}>Save</ActionButtonGreen>
        </DrawerFooter>
        <DrawerFooter className={"fake-footer task-footer mobile-footer"}>
          <CircleIcon onClick={() => handleCancel()}><CloseIcon/></CircleIcon>
          <CircleIcon className={"green-fill"} htmlType={"submit"}><CheckmarkIcon /></CircleIcon>
        </DrawerFooter>
      </div>}
    </FormStyled>
  </EditTaskDrawer>
  </>
}

export const DrawerTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`

export const FormWrapper = styled.div`
  width: 100%;
  padding: 0.125rem;
  
  .ant-form-item-has-error { 
    border-color: none!important;
  }
  
  .ant-form-item-has-error .ant-input-prefix {
    color: #BBBBBB!important;
  }
  
  .ant-input :not(textarea), .ant-select-selector {
    height: 3rem!important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 6px;
  }
  
  .ant-select-disabled { 
    background: #F5F5F5; 
  }
  
  .ant-input-disabled {
    color: #BBBBBB!important;
  }
  
  .title-input {
    padding: 0.75rem 1rem;
    border: 1px solid #DAE0E6;
    border-radius: 6px;
    font-weight: 700;
    font-size: 1.625rem;
    line-height: 1.75rem;
    height: 3.5rem!important;
    display: flex;
    align-items: center;
    color: #45655E;
  }
  
  .ant-select-selection-search-input { 
    height: 2.875rem!important; 
  }
  
  textarea.ant-input { 
  }
  
  @media (max-width: 768px) {
    .ant-input, .ant-select-selector {
      height: 2.25rem!important;
    }
    
    .ant-form-item { 
      margin-top: 0.25rem;
      margin-bottom: 1rem;
    }
    
    .title-input {
      border: none;
      padding-left: 0;
      background: white;
    }
      
    .ant-form-item, .underlined { 
      padding-bottom: 1.5rem;
      border-bottom: 1px solid #E6E6E6; 
    }

    .no-underline { 
      padding-bottom: 0;
      border-bottom: none; 
    }
  }
`

export const Label = styled.div`
  font-weight: 600;
  font-size: 1rem;
`;

export const Accent = styled.span`
  color: #009966;
`

export const FrequencyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #45655E;
  gap: 0.5rem;
  width: 60%;
  margin-bottom: 1.5rem;
  
  .ant-input {
    border: 1px solid #00B74F!important; 
  }
  
  .ant-form-item {
    margin-bottom: 0!important;
  }
  
  @media (max-width: 768px) {
    width: 100%;
  }
`

const CostFieldsWrapper = styled.div`
 &.budget .ant-select-selector {
  border: 1px solid #00B74F!important;
 }
  
 .ant-input, .ant-select-selector {
  border-radius: 6px!important;
 }
 
 .ant-input-affix-wrapper-lg { 
   height: 3rem!important; 
   display: flex; 
   align-items: center; 
 }
 
 .ant-input-lg { 
   margin-left: 0.25rem; 
   height: 2.25rem!important; 
 }
 
 @media (max-width: 768px) {
    .ant-input-affix-wrapper-lg { 
      height: 2.25rem!important; 
   }
   
   .ant-input-lg { 
      margin-left: 0.25rem; 
      height: 2.125rem!important; 
    }
 }
`

export const SpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
`

export const TextButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #45655E;
  
  &.green { color: #009966; }
  
  @media (max-width: 768px) {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`

export default UpdateTaskDrawer;