import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TRecipient} from "./referFriends";
import {Guid} from "guid-typescript";

export type TForwardReminderRequest = {
  customMessage: string;
  ccSender: boolean;
  recipients: Array<TRecipient>;
  id: Guid;
  propertyId: Guid;
  templateId: Guid;
  originalDate: string;
  triggerDate: string;
  isPropertyAddressEnabled: boolean;
  isReplyToEnabled: boolean;
};

export const forwardReminder = (payload: TForwardReminderRequest) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/forwardReminder`
  );

  return apiPost<any, TForwardReminderRequest>(
    url, payload
  );
};
