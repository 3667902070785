import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input, Modal, Select,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";

import { ReactComponent as UpcomingTaskIcon } from "../../../images/NotificationPurple.svg";

import {InputsWrapper, InputWrapper, Label} from "../../screens/RegistrationPage";
import { useDispatch, useSelector } from "react-redux";
import {getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import styled from "styled-components";
import {addUpcomingTask} from "../../../api-wrapper/upcomingTasks/addUpcomingTask";
import {getAllUpcomingTasks} from "../../actions/upcomingTasks";
import {editUpcomingTask} from "../../../api-wrapper/upcomingTasks/editUpcomingTask";
import EmailCreatableSelector from "../EmailCreatableSelector";
import {CloseButton, SaveButton, SaveButtonWrapper } from "../works/WorkDrawer";
import moment from "moment";
import {TUpcomingTask} from "../../../api-wrapper/upcomingTasks/getUpcomingTasks";
import {InputNumberStyled} from "../expenses/ExpenseDrawer";
import {disabledDate} from "../helper";
import { ReactComponent as CrossIcon } from "../../../images/Cross.svg";
import {getAllCalendarTasks} from "../../actions/calendarTasks";

const { TextArea } = Input;

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  upcomingTask: { data: TUpcomingTask, parentPage?: string | null } | null;
  setEditingTaskData: (arg01: any) => void;
  startDate?: any;
  endDate?: any;
  refreshParent?: () => void | null;
  monthlyView?: boolean;
};

const UpcomingTaskDrawer = (props: Props) => {
  const {
    isOpen,
    toggleDrawerOpen,
    upcomingTask,
    setEditingTaskData,
    startDate,
    endDate,
    refreshParent,
    monthlyView
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const propertyId = useSelector(getPropertyIdSelector).value;
  const titleInput = useRef(null);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [title, setTitle] = useState<any>("");
  const [periodicity, setPeriodicity] = useState<any>(null);
  const [periodicityUnit, setPeriodicityUnit] = useState<any>("");
  const [emailList, setEmailList] = useState<any>([]);

  const [titleError, setTitleError] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);

  const emailArrayIntoString = (emails: any[]) => {
    let emailString: any[] = []
    if (emails) emails.map(email => emailString.push(email.value))
    return emailString.join()
  }

  const emailArrayIntoOptions = (emails: Array<string>) => {
    let emailOptions: any[] = []
    if (emails) emails.map(email => emailOptions.push({value: email, label: email}))
    return emailOptions
  }

  const refreshUpcomingTasks = () => {
    if (startDate) {
      // if (endDate) {
      //   dispatch(getAllUpcomingTasks({
      //     propertyId: propertyId,
      //     startDate: startDate.format('YYYY-MM-DD'),
      //     endDate: endDate.format('YYYY-MM-DD'),
      //     firstInSeries: false
      //   })
      //   // @ts-ignore
      //   dispatch(getAllCalendarTasks(propertyId, moment().format("YYYY-MM-DD"), monthlyView))
      // } else {
      //   // @ts-ignore
      //   dispatch(getAllCalendarTasks(propertyId, startDate.format("YYYY-MM-DD"), monthlyView))
      // }
    } else dispatch(getAllUpcomingTasks({
        propertyId: propertyId,
        startDate: "",
        endDate: "",
        firstInSeries: true
      }))
  }

  const handleManageUpcomingTask = (e: {
    date: moment.Moment;
    message: string;
  }) => {
    const { date, message } = e;
    if (_.isEmpty(title)) {
      setTitleError(true);
      return;
    }

    const taskToSave = {
      title,
      propertyId,
      periodicity: periodicity || null,
      periodicityUnit: periodicityUnit || null,
      message,
      sendTo: emailArrayIntoString(emailList),
    }

    if (upcomingTask && upcomingTask.data.templateId) {
      editUpcomingTask({
        ...taskToSave,
        startDate: moment(date).format('YYYY-MM-DD'),
        templateId: upcomingTask.data.templateId
        }
      ).then(() => {
        toggleDrawerOpen(false);
        setTitle("");
        setEditingTaskData(null);
        form.resetFields();
        refreshUpcomingTasks();
        setFormFieldsChanged(false);
      })
    }
    // @ts-ignore
    else if (upcomingTask && upcomingTask.parentPage === "search") {
      editUpcomingTask({
          ...taskToSave,
          startDate: moment(date).format('YYYY-MM-DD'),
          // @ts-ignore
          templateId: upcomingTask.data.id
        }
      ).then(() => {
        toggleDrawerOpen(false);
        setTitle("");
        setEditingTaskData(null);
        form.resetFields();
        // dispatch(getAllUpcomingTasks(propertyId));
        setFormFieldsChanged(false);
        refreshParent && refreshParent();
      })
    }
    else {
      setSaveDisabled(true);
        addUpcomingTask({
        ...taskToSave,
          date: moment(date).format('YYYY-MM-DD'),
      }).then(() => {
        toggleDrawerOpen(false);
        setTitle("");
        setEditingTaskData(null);
        form.resetFields();
        refreshUpcomingTasks();
        setFormFieldsChanged(false);
        setSaveDisabled(false);
      });
    }
  };

  useEffect(() => {
    if (upcomingTask && upcomingTask.data.templateId ||
      //@ts-ignore
      upcomingTask && upcomingTask.parentPage === "search") {
      setTitle(upcomingTask.data.title)
      setPeriodicity(upcomingTask.data.periodicity)
      setPeriodicityUnit(upcomingTask.data.periodicityUnit)
      // @ts-ignore
      setEmailList(emailArrayIntoOptions(upcomingTask.data.sendTo))
      form.resetFields();
    } else {
      form.resetFields()
      setTitle("");
      setEditingTaskData(null);
      setPeriodicity(null);
      setPeriodicityUnit("")
      setEmailList([])
    }
  }, [form, isOpen, upcomingTask]);

  const getTaskDate = (upcomingTask: any) => {
    if (upcomingTask.data.templateStartDate) return moment(upcomingTask.data.templateStartDate)
    if (upcomingTask.data.triggerDate) return moment(upcomingTask.data.triggerDate)
    else return ""
  }

  const initialValues = () => {
    if (upcomingTask) {
      // @ts-ignore
      if (upcomingTask.parentPage === "search") {
        // @ts-ignore
        const { startDate, message } = upcomingTask.data;
        return {
          date: startDate ? moment(startDate) : "",
          message
        }
      }
      else {
        const { message } = upcomingTask.data;
         return {
          date: getTaskDate(upcomingTask),
          message
        }
      }
    } else {
      return {}
    }
  };

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        toggleDrawerOpen(false);
        setTitle("");
        setEditingTaskData(null);
        form.resetFields();
        refreshUpcomingTasks();
      },
    });
  }

  useEffect(() => {
    // @ts-ignore
    titleInput.current !== null && titleInput.current.focus();
    // @ts-ignore
    titleInput.current !== null && titleInput.current.setSelectionRange(0, 0)
  }, [isOpen, titleInput])

  return (
    <Drawer
      push={{distance: "32px"}}
      closeIcon={false}
      width={window.innerWidth > 1024 ? "55%" : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => {
        if (formFieldsChanged) {
          confirmCloseUnsaved();
          return;
        }
        toggleDrawerOpen(false);
        setTitle("");
        setEditingTaskData(null);
        setTitleError(false);
        form.resetFields();
        refreshUpcomingTasks();

        let drawer = document.getElementsByClassName('ant-drawer-body');
        drawer[0].scrollTo({top: 0})
      }}
      headerStyle={{
        backgroundColor: "#ebf9ff",
        margin: 0,
        padding: "2rem 2rem",
      }}
      title={
        <>
          <section style={{display: "flex", justifyContent: "space-between"}}>
            <UpcomingTasksIconWrapper>
              <UpcomingTaskIcon style={{marginRight: '0.6rem'}} /> REMINDERS
            </UpcomingTasksIconWrapper>
            <ButtonsWrapper>
              {/*{upcomingTask && !Object.is(upcomingTask.data.templateId, emptyGuid) && <ButtonStyled*/}
              {/*    size={"large"}*/}
              {/*    onClick={() => confirmDelete({...upcomingTask.data, propertyId})}*/}
              {/*    icon={<DeleteIcon style={{opacity: 1, marginRight: "0.3rem"}}/>}*/}
              {/*>*/}
              {/*    <span style={{opacity: 1, color: "#21272B"}}>Delete</span>*/}
              {/*</ButtonStyled>}*/}
              <CloseButton
                size={"large"}
                onClick={() => {
                  toggleDrawerOpen(false);
                  setTitle("");
                  setEditingTaskData(null);
                  form.resetFields();
                  upcomingTask && refreshUpcomingTasks()
                }}
              >{window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}</CloseButton>
            </ButtonsWrapper>
          </section>
          <Title
            placeholder={"Enter reminder title"}
            value={title}
            style={{fontSize: "2.5rem", color: "#002A43"}}
            className={titleError ? "form-title-error" : ""}
            maxLength={100}
            onChange={(e) => {
              setFormFieldsChanged(true);
              setTitle(e.target.value);
              if (e.target.value) {
                setTitleError(false);
              } else {
                setTitleError(true);
              }
            }}
            ref={titleInput}
          />
          {titleError &&
          <div
              role="alert"
              style={{
                marginBottom: "-1.8rem",
                marginTop: "0.4rem",
                color: "#ff4d4f",
                fontWeight: 400,
                fontSize: "0.9rem"
              }}
          >Reminder title is required</div>
          }
        </>
      }
    >
      <Form
        form={form}
        name="manageUpcomingTask"
        layout="vertical"
        size={"large"}
        onFinish={handleManageUpcomingTask}
        initialValues={initialValues()}
        onFieldsChange={() => setFormFieldsChanged(true)}
      >
        <>
          <span
            style={{
              fontSize: "1.125rem",
              fontWeight: 600,
              color: "#21272B",
            }}
          >
            Reminder details
          </span>
          <section
            style={{
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "10px",
              padding: "1rem",
              marginTop: "1em",
            }}
          >
            <InputsWrapper style={{ paddingBottom: "0.5rem" }}>
              <InputWrapper>
                <Label>Reminder date *</Label>
                <Form.Item
                  key={"date"}
                  name={"date"}
                  rules={[
                    {
                      required: true,
                      message: "Reminder date is required",
                    },
                  ]}
                >
                  <DatePicker
                    name={"date"}
                    format={propertyPreferences.preferredDateFormat}
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    allowClear={true}
                    inputReadOnly={true}
                    disabledDate={disabledDate}
                    disabled={(upcomingTask !== null && (!_.isNil(upcomingTask.data.templateId) || !_.isNil(upcomingTask.parentPage)))}
                    style={{
                      width: "100%",
                      border: "1px solid #DAE0E6",
                      boxSizing: "border-box",
                      borderRadius: "6px",
                      color: "rgb(107,113,133)",
                    }}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <Label>Reminder frequency</Label>
                  <Input.Group compact>
                    <InputNumberStyled
                      value={periodicity}
                      precision={0}
                      min={1}
                      maxLength={10}
                      style={{width: '35%'}}
                      disabled={(upcomingTask !== null && (!_.isNil(upcomingTask.data.templateId) || !_.isNil(upcomingTask.parentPage)))}
                      onChange={(value) => setPeriodicity(value)}
                    />
                    <Select
                      style={{width: '65%'}}
                      value={periodicityUnit}
                      disabled={(upcomingTask !== null && (!_.isNil(upcomingTask.data.templateId) || !_.isNil(upcomingTask.parentPage)))}
                      onChange={(value) => {
                        setPeriodicityUnit(value)
                      }}
                    >
                      <Select.Option key={"day"} value={"day"}>Days</Select.Option>
                      <Select.Option key={"week"} value={"week"}>Weeks</Select.Option>
                      <Select.Option key={"month"} value={"month"}>Months</Select.Option>
                      <Select.Option key={"year"} value={"year"}>Years</Select.Option>
                    </Select>
                  </Input.Group>
              </InputWrapper>
            </InputsWrapper>
            <Label>Message</Label>
            <Form.Item key={"message"} name={"message"}>
              <TextArea
                name={"message"}
                rows={5}
                placeholder={"Input text..."}
                maxLength={1000}
                style={{
                  width: "100%",
                  border: "1px solid #DAE0E6",
                  boxSizing: "border-box",
                  borderRadius: "6px",
                  color: "rgb(107,113,133)",
                }}
              />
            </Form.Item>
            <Label>Email address</Label>
            <p>You can pick contacts from your Contacts list or type in custom emails.</p>
            <EmailCreatableSelector emailList={emailList} setEmailList={setEmailList} />
          </section>
          <SaveButtonWrapper>
            <SaveButton
              disabled={saveDisabled}
              style={{
                display: "flex",
                alignItems: "center",
              }}
              type={"primary"}
              htmlType={"submit"}
              loading={saveDisabled}
              onClick={() => _.isEmpty(title) && setTitleError(true)}
            >
              Save
            </SaveButton>
          </SaveButtonWrapper>
        </>
      </Form>
    </Drawer>
  );
}

// const SaveIconStyled = styled(SaveIcon)`
//   padding-right: 0.25rem;
//   width: 22px;
//   height: 22px;
// `;
//
// const CreateUpcomingTaskButtonWrapper = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   padding-top: 1rem;
// `;

const Title = styled(Input)`
  border: none;
  outline: none;
  background-color: #ebf9ff;
  margin-top: 0.5rem;

  & > *:focus {
    border-color: #57a8e9;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }

  .ant-input:hover {
    border-color: #ebf9ff;
  }
`;

const UpcomingTasksIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;

  & > *:last-child {
    margin-left: 1.5rem;
  }
`;

const ButtonStyled = styled(Button)`
  border: 1px solid rgb(33, 39, 43, 0.25);
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background-color: #ebf9ff;
  color: #21272b;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

export const ButtonStyledLists = styled(ButtonStyled)`
  background-color: #fff;
`;

export const FormToolkitWrapper = styled.div`
  display: flex;
  position: sticky;
  z-index: 1;
  position: -webkit-sticky; /* Safari */
  bottom: 0;
  padding-top: 1rem;
  align-items: center;
  justify-content: center;
`;

export const FormToolkitDivider = styled.span`
  height: 1.8rem;
  margin-top: 0.3rem;
  border-left: 1px solid #DAE0E6;
`;

export default UpcomingTaskDrawer;