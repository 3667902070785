import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import _ from "lodash";

export type TEditUpcomingTaskResponse = {

};

export type TEditUpcomingTaskRequest = {
  title: string;
  startDate: string;
  periodicity: number | null;
  periodicityUnit: string | null;
  message: string | null;
  sendTo: string | null;
};

export const editUpcomingTask = (payload: TEditUpcomingTaskRequest & { propertyId: Guid, templateId: Guid}) => {
  const { propertyId, templateId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/alerts/series/${templateId}`
  );

  return apiPut<TEditUpcomingTaskResponse, TEditUpcomingTaskRequest>(url, _.omit(payload, ["propertyId", "templateId"]));
};
