import { combineReducers } from "redux";
import { connectRouter as router, RouterState } from "connected-react-router";
import { History } from "history";

import { TAuth, authReducer as auth } from "./auth";
import { TProperties, propertiesReducer as properties } from "./properties";
import { TPropertyId, propertyIdReducer as propertyId } from "./propertyId";
import { TWorks, worksReducer as works } from "./works";
import {TNotesByPropertyId, notesByPropertyIdReducer as notesByPropertyId } from "./notesByPropertyId";
import { TExpenses, expensesReducer as expenses } from "./expenses";
import { TConfirmedExpenses, confirmedExpensesReducer as confirmedExpenses } from "./confirmedExpenses";
import { TInventories, inventoriesReducer as inventories } from "./inventories";
import { TContacts, contactsReducer as contacts } from "./contacts";
import { TResetUpdate, resetUpdateReducer as resetUpdate } from "./resetUpdate";
import { TTenants, tenantsReducer as tenants } from "./tenants";
import {TUpcomingTasks, upcomingTasksReducer as upcomingTasks} from "./upcomingTasks";
import { TUserProfile, userProfileReducer as userProfile } from "./userProfile";
import { TExpenseReport, expenseReport as expenseReport } from "./expenseReport";
import { TInventoryReport, inventoryReport as inventoryReport } from "./inventoryReport";
import { TMaintenances, maintenancesReducer as maintenances} from "./maintenances";
import { TOnboardingMaintenances, onboardingMaintenancesReducer as onboardingMaintenances} from "./onboardingMaintenances";
import { TImprovements, improvementsReducer as improvements} from "./improvements";
import { TRecommendedImprovements, recommendedImprovementsReducer as recommendedImprovements} from "./recommendedImprovements";
import { TInsurances, insurancesReducer as insurances } from "./insurances";
import { TAttachments, attachmentsReducer as attachments } from "./attachments";
import { TTags, tagsReducer as tags } from "./tags";
import { TExpenseTypes, expenseTypesReducer as expenseTypes } from "./expenseTypes";
import { TCalendarTasks, calendarTasksReducer as calendarTasks } from "./calendarTasks";
import { TTagsWithUsageCount, tagsWithUsageCountReducer as tagsWithUsageCount } from "./tagsWithUsageCount";
import { TTagsWithUsageCountByType, tagsWithUsageCountByTypeReducer as tagsWithUsageCountByType } from "./tagsWithUsageCountByType";
import { TStorageUsage, storageUsageReducer as storageUsage } from "./storageUsage";
import { TNews, newsReducer as news } from "./news";
import { TBudgetCosts, budgetCostsReducer as budgetCosts } from "./budgetCosts";
import { TSubscriptionUsers, subscriptionUsersReducer as subscriptionUsers } from "./subscriptionUsers";
import { TCertificates, certificatesReducer as certificates } from "./certificates";
import { TSpaces, spacesReducer as spaces } from "./spaces";
import { TSurveys, surveysReducer as surveys } from "./surveys";
import { TFloorPlans, floorPlansReducer as floorPlans } from "./floorPlans";
import { TMortgages, mortgagesReducer as mortgages } from "./mortgages";
import { TPropertyPreferences, propertyPreferencesReducer as propertyPreferences } from "./propertyPreferences";
import { TPets, petsReducer as pets } from "./pets";

export interface TRootState {
  auth: TAuth;
  router: RouterState;
  properties: TProperties;
  propertyId: TPropertyId;
  propertyPreferences: TPropertyPreferences;
  works: TWorks;
  notesByPropertyId: TNotesByPropertyId;
  expenses: TExpenses;
  confirmedExpenses: TConfirmedExpenses;
  inventories: TInventories;
  contacts: TContacts;
  resetUpdate: TResetUpdate;
  upcomingTasks: TUpcomingTasks;
  tenants: TTenants;
  userProfile: TUserProfile;
  expenseReport: TExpenseReport;
  inventoryReport: TInventoryReport;
  maintenances: TMaintenances;
  onboardingMaintenances: TOnboardingMaintenances;
  improvements: TImprovements;
  recommendedImprovements: TRecommendedImprovements;
  insurances: TInsurances;
  attachments: TAttachments;
  tags: TTags;
  expenseTypes: TExpenseTypes;
  calendarTasks: TCalendarTasks;
  tagsWithUsageCount: TTagsWithUsageCount;
  tagsWithUsageCountByType: TTagsWithUsageCountByType;
  storageUsage: TStorageUsage;
  news: TNews;
  budgetCosts: TBudgetCosts;
  subscriptionUsers: TSubscriptionUsers;
  certificates: TCertificates;
  spaces: TSpaces;
  surveys: TSurveys;
  floorPlans: TFloorPlans;
  mortgages: TMortgages;
  pets: TPets;
}

const rootReducer = (history: History) =>
  combineReducers<TRootState>({
    auth,
    router: router(history),
    properties,
    propertyId,
    propertyPreferences,
    works,
    notesByPropertyId,
    expenses,
    confirmedExpenses,
    budgetCosts,
    contacts,
    inventories,
    resetUpdate,
    tenants,
    userProfile,
    upcomingTasks,
    expenseReport,
    inventoryReport,
    maintenances,
    improvements,
    recommendedImprovements,
    insurances,
    attachments,
    tags,
    expenseTypes,
    calendarTasks,
    tagsWithUsageCount,
    tagsWithUsageCountByType,
    storageUsage,
    news,
    onboardingMaintenances,
    subscriptionUsers,
    certificates,
    spaces,
    surveys,
    floorPlans,
    mortgages,
    pets
  });

export default rootReducer;
