import {apiPut, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TEditInsuranceResponse = {
  propertyId: Guid;
  id: Guid;
  subscriptionId: number;
  startDate: string;
  effectivePeriod: string;
  insuranceType: string;
  insurer: string;
  policyNumber: string;
  premium: number;
  insurancePremiumTax: number;
  administrationFee: number;
  discountReceived: string;
  totalAmountPaid: number;
  valueOfInsuredItems: number;
};

export type TEditInsuranceRequest = {
  propertyId: Guid;
  id: Guid;
  startDate: string;
  effectivePeriod: string;
  insuranceType: string;
  insurer: string;
  policyNumber: string;
  premium: number;
  insurancePremiumTax: number;
  administrationFee: number;
  discountReceived: string;
  totalAmountPaid: number;
  valueOfInsuredItems: number;
};

export const editInsurance = (payload: TEditInsuranceRequest & { propertyId: Guid, id: Guid }) => {
  const { propertyId, id } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/insurance/${id}`
  );

  return apiPut<TEditInsuranceResponse, TEditInsuranceRequest>(url, payload);
};
