import React, {useEffect, useRef, useState} from "react";
import {Form, notification} from "antd";

import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";

import {loginClean, mergeAndSignIn} from "../actions/logIn";
import {
  MAX_LENGTH_LOGIN_FORM,
  NOTIFICATION_TOP_RIGHT_PLACEMENT,
} from "../helpers/constants";
import messages from "./messages";
import { emailRegex } from "./helpers";
import { TFetchingStatus } from "../../helpers";
import { authSelector } from "../selectors";
import LoadingPage from "./LoadingPage";
import {
  InputPasswordStyled,
  InputStyled,
  Label,
  LabelWrapper
} from "./RegistrationPage";
import { ReactComponent as AlertIcon } from "../../images/AlertRed.svg";
import {
  ButtonStyled,
  ButtonText,
  FormWrapper,
  ImageWrapper,
  SecondaryButton,
} from "./styled";
import {useHistory, useLocation} from "react-router-dom";
import {reset} from "../../api-wrapper/auth/reset";
import {capitalize} from "../components/helper";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const { Item } = Form;

const ConnectAccounts = () => {
  const { formatMessage: f } = useIntl();

  const { executeRecaptcha } = useGoogleReCaptcha();

  let history = useHistory();
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const passwordInput = useRef(null);
  const auth = useSelector(authSelector);

  const useQuery = (queryType: string) => {
    let query = new URLSearchParams(useLocation().search);
    switch (queryType) {
      case 'externalSourceName':
        return query.get("externalSourceName");
      case 'externalId':
        return query.get("externalId");
      case 'externalSourceToken':
        return query.get("externalSourceToken");
      case 'email':
        return query.get("email");
      default:
        return ""
    }
  };
  const externalSourceName = useQuery('externalSourceName')
  const externalId = useQuery('externalId')
  const externalSourceToken = useQuery('externalSourceToken')
  const email = useQuery("email")

  const [showLoginError, setShowLoginError] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [emailSent, setEmailSent] = useState(false)

  const handleLogIn = async (data: { password: string }) => {
    const { password } = data;

    let token;
    if (executeRecaptcha) {
      token = await executeRecaptcha('yourAction');
    }

    isEmpty(password) ? api.error({
        message: f(messages.emptyFieldTitle),
        description: f(messages.emptyFieldDescription),
        placement: NOTIFICATION_TOP_RIGHT_PLACEMENT,
      })
      : dispatch(mergeAndSignIn({
        // @ts-ignore
        email, password, externalSourceName, externalId, externalSourceToken, token
      }))
  };

  useEffect(() => {
    if (auth.fetchingStatus === TFetchingStatus.Failure) {
      setShowLoginError(true);
      dispatch(loginClean());
    }
  }, [auth.fetchingStatus, f, dispatch]);

  useEffect(() => {
    if (auth.loginError)
      setLoginError(auth.loginError)
  }, [auth.loginError])

  useEffect(() => {
    if (email) {
      // @ts-ignore
      passwordInput.current && passwordInput.current.focus();
    }
  }, [email])

  // if (auth.fetchingStatus === TFetchingStatus.Initial) {
  //   return <LoadingPage />;
  // }

  return (
    <Main>
      {contextHolder}
      <FormWrapper>
        <FormHeader>
          <div style={{width: "175px"}}>
            <img src={"/images/LivletLogoNew.png"} style={{maxWidth: "100%"}} alt={""} />
          </div>
        </FormHeader>
        <TitlesWrapper>
          <Title>Link an existing account</Title>
          <Subtitle>
            Sign into Livlet to finish linking your account with {capitalize(externalSourceName ?? "")}.
          </Subtitle>
        </TitlesWrapper>
        <Form
          form={form}
          name="connect-accounts"
          size={"large"}
          initialValues={{ email }}
          onFinish={handleLogIn}
        >
          <LabelWrapper>
            <Label>{f(messages.loginEmailAddress)}</Label>{" "}
          </LabelWrapper>
          <Item
            key={"email"}
            name={"email"}
            rules={[
              {
                required: true,
                pattern: emailRegex,
                message: f(messages.validationEmail),
              },
            ]}
          >
            <InputStyled
              disabled
              placeholder={f(messages.loginEmailAddress)}
              maxLength={MAX_LENGTH_LOGIN_FORM}
            />
          </Item>
          <LabelWrapper>
            <Label>{f(messages.password)}</Label>{" "}
          </LabelWrapper>
          <Item
            key={"password"}
            name={"password"}
            rules={[
              {
                required: true,
                message: f(messages.validationPassword),
              },
            ]}
          >
            <InputPasswordStyled
              ref={passwordInput}
              maxLength={MAX_LENGTH_LOGIN_FORM}
              placeholder={f(messages.password)}
              onChange={() => setShowLoginError(false)}
            />
          </Item>
          {showLoginError && (
            <div
              style={{
                backgroundColor: "#FCF0F0",
                padding: "1rem",
                marginTop: "1em",
                marginBottom: "1em",
                display: "flex",
                borderRadius: 6,
                fontWeight: 400,
              }}
            >
              <AlertIcon />
              {loginError !== "activation" && <span style={{ paddingLeft: "0.5rem" }}>
                {loginError}
              </span>}
              {loginError === "activation" && !emailSent && <span style={{ paddingLeft: "0.5rem" }}>
                {f(messages.loginActivationError, {
                  activationLink: (<span style={{color: '#1890ff', cursor: "pointer"}} onClick={() => {
                    reset(form.getFieldValue("email")).then(() => setEmailSent(true))
                  }}>here</span>)
                })}
              </span>}
              {loginError === "activation" && emailSent && <span style={{ paddingLeft: "0.5rem" }}>
                {f(messages.resentEmailMessage)}
              </span>}
            </div>
          )}
          <ButtonsWrapper>
            <SecondaryButton onClick={() => window.history.back()}>
              <ButtonText>{f(messages.cancel)}</ButtonText>
            </SecondaryButton>
            <ButtonStyled htmlType="submit" type="primary">
              <ButtonText> {f(messages.signIn)}</ButtonText>
            </ButtonStyled>
          </ButtonsWrapper>
          <span>
            <a onClick={() => history.push("/reset/password")}>{f(messages.loginForgottenPassword)}</a>
          </span>
          <div>
          <span>
            Other issues with login? Contact us: <a href={`mailto:support.uk@livlet.com`}>support.uk@livlet.com</a>
          </span>
          </div>
        </Form>
      </FormWrapper>
      <ImageWrapper>
        <LoginPageImageStyled />
        <TextOnImage>{f(messages.imageText)}</TextOnImage>
      </ImageWrapper>
    </Main>
  );
};

const FormHeader = styled.div`
  @media (max-width: 1024px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 1rem;
  }

  @media (min-height: 720px) {
    height: 15vh;
  }

  padding: 2rem 0 2rem;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
`;

const LoginPageImageStyledInner = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1024px) {
    width: 100%;
    height: 100vh;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(/images/registrationImage.jpg);
    margin: 0 auto;
    position: relative;
  }
`;

const LoginPageImageStyled = styled(LoginPageImageStyledInner)`
  @media (min-width: 1024px) {
    min-height: 720px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  padding-top: 0.5rem;
`;

const Main = styled.main`
  display: flex;
  overflow-y: visible;
  height: 100vh;
  max-height: 100vh;
  justify-content: space-between;
  background-color: white;
`;

const TitlesWrapper = styled.div`
  @media (min-width: 1024px) and (min-height: 720px) {
    height: 15vh;
  }
`;

const Title = styled.h4`
  font-weight: 700;
  font-size: 1.625rem;
  color: #002a43;
`;

const Subtitle = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: #6b7185;
`;

const TextOnImage = styled.h3`
  font-weight: 700;
  color: #ffffff;
  position: absolute;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1024px) {
    font-size: 2.1rem;
    width: 350px;
    top: 0.5rem;
    right: 0.5rem;
  }
`;

export default ConnectAccounts;
