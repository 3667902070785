import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TTag} from "../tags/addNewTag";

export type TGetExpensesResponse = Array<{
  name: string;
  propertyId: Guid;
  expenseId: Guid;
  type: Guid;
  payee: Guid;
  totalCost: number;
  paymentDate: string;
  isTaxDeductible: boolean;
  parentId: Guid;
  parentType: string;
  notes: string;
  tags: Array<TTag>;
  isConfirmed: boolean;
  attachmentsCount: number;
  expenseRelations: Array<any>;
  userPaidBy: number | null;
  contactPaidBy: Guid | null;
}>;

export const getExpenses = (propertyId: Guid, confirmed?: boolean) => {
  let query = ""
  if (confirmed !== undefined) {
    query = `?confirmed=${confirmed}`
  }

  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/expenses${query}`,
    {}
  );

  return apiGet<TGetExpensesResponse | []>(url, {}, propertyId);
};

export const getExpensesWithoutProperty = (propertyId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/expenses?withoutProperty=true`,
    {}
  );

  return apiGet<TGetExpensesResponse | []>(url, {}, propertyId);
};
