import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {
  getNotesByPropertyId,
  TGetNotesByPropertyIdResponse,
} from "../../api-wrapper/notes/getNotesByPropertyId";
import {Guid} from "guid-typescript";

export const getNotesByPropertyIdInitAction = createAction(
  "notes/GET_NOTES_PROPERTY_ID_INIT"
)();
export const getNotesByPropertyIdSuccessAction = createAction(
  "notes/GET_NOTES_PROPERTY_ID_SUCCESS"
)<TGetNotesByPropertyIdResponse>();
export const getNotesByPropertyIdFailureAction = createAction(
  "notes/GET_NOTES_PROPERTY_ID_FAILURE"
)();

export type TGetNotesByPropertyIdInitAction = ActionType<
  typeof getNotesByPropertyIdInitAction
>;
export type TGetNotesByPropertyIdSuccessAction = ActionType<
  typeof getNotesByPropertyIdSuccessAction
>;
export type TGetNotesByPropertyIdFailureAction = ActionType<
  typeof getNotesByPropertyIdFailureAction
>;

export type TGetNotesByPropertyIdActions =
  | TGetNotesByPropertyIdInitAction
  | TGetNotesByPropertyIdSuccessAction
  | TGetNotesByPropertyIdFailureAction;

export type TGetNotesByPropertyIdThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetNotesByPropertyIdActions>;

export const getAllNotesByPropertyId: TGetNotesByPropertyIdThunkActionType = (
  propertyId: Guid
) => (dispatch: (action: Action | Promise<Action>) => any) => {
  dispatch(getNotesByPropertyIdInitAction());
  return getNotesByPropertyId(propertyId)
    .then((payload) => {
      return dispatch(getNotesByPropertyIdSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getNotesByPropertyIdFailureAction()));
};
