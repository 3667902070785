import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import styled from "styled-components";
import { useIntl } from "react-intl";
import {Button, Divider, Input, List, Menu, Modal, Popover, Skeleton, Tag} from "antd";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";

import { getAllContacts } from "../actions/contacts";
import {getContactsSelector, getPropertyIdSelector, getPropertyPreferencesSelector} from "../selectors";
import {ReactComponent as FilterIcon} from "../../images/Filters.svg";
import messages from "./messages";
import ContactDrawer from "../components/contacts/ContactDrawer";
import { removeContact } from "../../api-wrapper/contacts/removeContact";
import { ReactComponent as SearchIcon } from "../../images/Search.svg";
import { ReactComponent as ExpensesIcon } from "../../images/ExpensesPink.svg";
import {ReactComponent as MaintenanceIcon} from "../../images/Maintence.svg";
import { getAllWorks } from "../actions/works";
import {initialContactData, numberWithCommas} from "../components/helper";
import {ButtonSort, MenuStyled, SortMenuStyled, TagStyledBold} from "../components/works/WorksListComp";
import {addContact} from "../../api-wrapper/contacts/addContact";
import {ReactComponent as SortIcon} from "../../images/Sort.svg";
import {
  AddRecordButton,
  EmptyRecordsDescription,
  EmptyRecordsHeader,
  EmptyRecordsList,
  HeaderControlsWrapper,
  PageHeader,
  PageTitle,
  PageWrapper,
  AddButtonStyled,
  PaginationStyled, PageSubtitle
} from "./components";
import TagContainer from "../components/tags/TagContainer";
import {Filters} from "./styled";
import FilterTagsNew from "../components/tags/FilterTagsNew";
import {aggregateistOfTags, emptyGuid, getCurrencySign, hasSomeSelectedTags} from "./helpers";
import ForwardContactDrawer from "../components/contacts/ForwardContactDrawer";
import {ReactComponent as MoreMenu} from "../../images/More.svg";
import {TFetchingStatus} from "../../helpers";
import {getAllTagsWithUsageCountByType} from "../actions/tagsWithUsageCountByType";
import {ReactComponent as DotIcon} from "../../images/DotForFilters.svg";
import {ButtonFilters} from "./Costs";
import {getAllExpenses} from "../actions/expenses";
import ViewWorkDrawer from "../components/works/ViewWorkDrawer";
import LinkDrawer from "../components/spaces/LinkDrawer";
import ViewSpaceDrawer from "../components/spaces/ViewSpaceDrawer";
import NoteDrawer from "../components/notes/NoteDrawer";
import {getAllSpaces} from "../actions/spaces";
import ViewFileDrawer from "../components/attachments/ViewFileDrawer";
import InventoryDrawer from "../components/inventories/InventoryDrawer";
import ExpenseDrawer from "../components/expenses/ExpenseDrawer";
import WorkDrawer from "../components/works/WorkDrawer";
import ViewContactDrawer from "../components/contacts/ViewContactDrawer";
import EditSpaceDrawer from "../components/spaces/EditSpaceDrawer";
import UpdateTaskDrawer from "../components/planner/UpdateTaskDrawer";
import AddEventDrawer from "../components/planner/AddEventDrawer";
import {getAllNotesByPropertyId} from "../actions/notesByPropertyId";
import {getAllInventories} from "../actions/inventories";

const Contacts = () => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const contacts = useSelector(getContactsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortMenuPopoverDesktop, setSortMenuPopoverDesktop] = useState(false);
  const [sortMenuPopoverMobile, setSortMenuPopoverMobile] = useState(false);
  const [sortBy, setSortBy] = useState("dateCreated");

  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editingContactData, setEditingContactData] = useState<any>(null);
  const [isViewContactDrawerOpen, toggleViewContactDrawer] = useState(false);
  const [viewContactId, setViewContactId] = useState<any>(null);
  const [linkedContactId, setLinkedContactId] = useState(null);

  const [showTagFilters, setShowTagFilters] = useState(false);
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [editTagsMode, toggleEditTagsMode] = useState(false)
  const [contactToForward, setContactToForward] = useState(emptyGuid);
  const [isForwardDrawerOpen, toggleForwardDrawer] = useState(false);

  const [workDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editWorkData, setEditWorkData] = useState<any>(null);
  const [isViewWorkDrawerOpen, toggleViewWorkDrawer] = useState(false);
  const [workToViewId, setWorkToViewId] = useState<any>(null);
  const [isViewFileDrawerOpen, toggleViewFileDrawer] = useState(false);
  const [attachmentToViewId, setAttachmentToViewId] = useState<any>(null);
  const [isNoteDrawerOpen, toggleNoteDrawer] = useState(false);
  const [editingNoteData, setEditingNoteData] = useState(null);
  const [isViewSpaceDrawerOpen, toggleViewSpaceDrawer] = useState(false);
  const [viewSpaceId, setViewSpaceId] = useState(null);
  const [isLinkDrawerOpen, toggleLinkDrawer] = useState(false);
  const [presetLinkType, setPresetLinkType] = useState<any>(null);
  const [inventoryDrawerOpen, toggleInventoryDrawer] = useState(false);
  const [editInventoryData, setEditInventoryData] = useState<any>(null);
  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [editingExpenseData, setEditingExpenseData] = useState<any>(null);
  const [isSpaceDrawerOpen, toggleSpaceDrawer] = useState(false);
  const [editSpaceId, setEditSpaceId] = useState(null);
  const [isTaskDrawerOpen, toggleTaskDrawer] = useState(false);
  const [editingTaskData, setEditingTaskData] = useState<any>(null);
  const [taskType, setTaskType] = useState("Task");
  const [isEventDrawerOpen, toggleEventDrawer]  = useState(false);

  const displayFilters = () => (
    <Filters style={{minHeight: "7rem"}}>
      <FilterTagsNew selectedTags={selectedTags} setSelectedTags={setSelectedTags} parentType={"contact"} disableCollapse={true} />
    </Filters>
  );

  useEffect(() => {
    dispatch(getAllContacts(propertyId));
    dispatch(getAllWorks(propertyId));
    dispatch(getAllExpenses(propertyId));
    dispatch(getAllSpaces(propertyId));
    dispatch(getAllNotesByPropertyId(propertyId));
    dispatch(getAllInventories(propertyId));
  }, [propertyId]);

  function confirmDelete(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        removeContact(item.propertyId, item.contactId).then(
          () => {
            setSearch("");
            dispatch(getAllContacts(item.propertyId));
            dispatch(getAllTagsWithUsageCountByType(item.propertyId, "contact"))
          }
        );
      }
    });
  }

  const sortMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      setSortMenuPopoverDesktop(false);
      setSortMenuPopoverMobile(false);
      event.domEvent && event.domEvent.stopPropagation();
      const { key } = event;
      if (key === "title")
        return;
      setSortBy("" + key);
    };

    return (
      <SortMenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <MenuTitle key="title" className={"ant-menu-item ant-menu-item-only-child"}>Paid to a contact</MenuTitle>
        <Menu.Item key="costHighToLow">From highest to lowest</Menu.Item>
        <Menu.Item key="costLowToHigh">From lowest to highest</Menu.Item>
      </SortMenuStyled>
    );
  };

  const addNewContact = () => {
    addContact({
      propertyId,
      request: {},
      contactImage: null
    }).then((res) => {
      setEditingContactData(res);
      dispatch(getAllContacts(propertyId));
      toggleContactDrawer(true);
    });
  }

  const itemRender = (
    page: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
    originalElement: React.ReactElement<HTMLElement>
  ) => {
    if (type === "prev") {
      return (
        <Button
          style={{
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "6px",
          }}
        >
          <ButtonText>Prev</ButtonText>
        </Button>
      );
    } else if (type === "next") {
      return (
        <Button
          style={{
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "6px",
          }}
        >
          <ButtonText>Next</ButtonText>
        </Button>
      );
    }

    return originalElement;
  };

  const parseToLowerCase = (el: string) => el.toLowerCase();

  const filteredContacts = () =>
    contacts.content
      .filter(contact => contact.name !== null)
      .filter((contact) => contact.contactType !== "Payee" && contact.contactType !== "Payer")
      .filter((contentEl) => {
        return !_.isEmpty(search)
          ? (!_.isNil(contentEl.company) &&
          _.includes(
            parseToLowerCase(contentEl.company),
            parseToLowerCase(search)
          )) ||
          (!_.isNil(contentEl.name) &&
            _.includes(
              parseToLowerCase(contentEl.name),
              parseToLowerCase(search)
            )) ||
          (!_.isNil(contentEl.contactType) &&
            _.includes(
              parseToLowerCase(contentEl.contactType),
              parseToLowerCase(search)
            )) ||
          (!_.isNil(contentEl.tags) &&
            _.includes(
              parseToLowerCase(aggregateistOfTags(contentEl.tags)),
              parseToLowerCase(search)
            ))
          : true;
      })
      .filter(contact => {
        return selectedTags.length > 0
          ? hasSomeSelectedTags(selectedTags, contact.tags)
          : true;
      })
      .sort((c1, c2) => {
        if (sortBy === "costHighToLow") {
          return c2.expensesTotalCost - c1.expensesTotalCost
        } else if (sortBy === "costLowToHigh") {
          return c1.expensesTotalCost - c2.expensesTotalCost
        } else {
          return c1.name.localeCompare(c2.name)
        }
      })
  ;

  const getContacts = () =>
    filteredContacts()
      .filter(
      (currentValue, index, array) =>
        index >= (page - 1) * pageSize && index < page * pageSize
    );

  useEffect(() => {
    if (contacts.fetchingStatus === TFetchingStatus.Success && getContacts().length === 0 && page !== 1) {
      setPage(page - 1)
    }
  }, [contacts.fetchingStatus, getContacts()])


  const moreMenuDropdown = (item: any) => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      if (key === "edit") {
        toggleContactDrawer(true);
        setEditingContactData({ data: item });
      } else if (key === "delete") {
        confirmDelete(item)
      } else if (key === "forward") {
        toggleForwardDrawer(true);
        setContactToForward(item.contactId);
      } else if (key === "view") {
        toggleViewContactDrawer(true);
        setViewContactId(item.contactId);
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="view">View</Menu.Item>
        <Menu.Item key="forward">Forward</Menu.Item>
        <Menu.Item key="edit">Edit</Menu.Item>
        <Menu.Item key="delete">Delete</Menu.Item>
      </MenuStyled>
    );
  };

  return (
    <PageWrapper>
      <ForwardContactDrawer
        isOpen={isForwardDrawerOpen}
        toggleDrawerOpen={toggleForwardDrawer}
        contactId={contactToForward}
      />
      <ContactDrawer
        isOpen={isContactDrawerOpen}
        toggleDrawerOpen={toggleContactDrawer}
        // @ts-ignore
        setEditingContactData={setEditingContactData}
        contact={editingContactData}
      />
      <ViewContactDrawer
        contactId={viewContactId}
        setContactId={setViewContactId}
        isOpen={isViewContactDrawerOpen}
        toggleDrawerOpen={toggleViewContactDrawer}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setLinkedContactId={setLinkedContactId}
        setPresetLinkType={setPresetLinkType}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setViewWorkId={setWorkToViewId}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
        setEditSpaceId={setEditSpaceId}
        toggleEditSpaceDrawer={toggleSpaceDrawer}
      />
      <UpdateTaskDrawer
        task={editingTaskData}
        setEditingTaskData={setEditingTaskData}
        isOpen={isTaskDrawerOpen}
        toggleDrawer={toggleTaskDrawer}
        taskType={taskType}
        setTaskType={setTaskType}
      />
      <WorkDrawer
        isOpen={workDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        work={editWorkData}
        setEditingWorkData={setEditWorkData}
        width={null}
      />
      <ViewWorkDrawer
        workId={workToViewId}
        setWorkId={setWorkToViewId}
        isOpen={isViewWorkDrawerOpen}
        toggleDrawer={toggleViewWorkDrawer}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setLinkedWorkId={() => {}}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setWorkIdToForward={() => {}}
        toggleForwardDrawer={() => {}}
        toggleTradeDrawer={() => {}}
        setEditSpaceId={setViewSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        setPresetLinkType={() => {}}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <LinkDrawer
        parentId={linkedContactId}
        parentType={"contact"}
        isOpen={isLinkDrawerOpen}
        toggleDrawerOpen={toggleLinkDrawer}
        presetLinkType={presetLinkType}
        setPresetLinkType={setPresetLinkType}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setEditSpaceId={setViewSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        toggleEventDrawer={() => {}}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <AddEventDrawer
        isOpen={isEventDrawerOpen}
        toggleDrawer={toggleEventDrawer}
        setSelectedType={setTaskType}
        toggleWorkDrawer={toggleWorkDrawer}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditingWorkData={setEditWorkData}
      />
      <EditSpaceDrawer
        spaceId={editSpaceId}
        isOpen={isSpaceDrawerOpen}
        toggleDrawerOpen={toggleSpaceDrawer}
        setEditingSpaceId={setEditSpaceId}
      />
      <ViewSpaceDrawer
        spaceId={viewSpaceId}
        isOpen={isViewSpaceDrawerOpen}
        toggleDrawerOpen={toggleViewSpaceDrawer}
        toggleEditDrawerOpen={toggleViewSpaceDrawer}
        setEditSpaceId={setViewSpaceId}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setLinkedSpaceId={() => {}}
        setPresetLinkType={setPresetLinkType}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <NoteDrawer
        currentType={"note"}
        data={editingNoteData}
        isOpen={isNoteDrawerOpen}
        toggleDrawerOpen={toggleNoteDrawer}
        refreshParent={() => dispatch(getAllSpaces(propertyId))}
        isGlobalNote={true}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        setPresetLinkType={setPresetLinkType}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        setEditSpaceId={setViewSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <ViewFileDrawer
        attachmentId={attachmentToViewId}
        setAttachmentId={setAttachmentToViewId}
        isOpen={isViewFileDrawerOpen}
        toggleDrawer={toggleViewFileDrawer}
        viewFileDrawerDefaultTab={"preview"}
        attachmentsWithoutProperty={[]}
        duplicateAttachments={[]}
        selectedTags={[]}
        toggleEditTagsMode={() => {}}
        toggleEditDrawerOpen={() => {}}
        setPresetLinkType={() => {}}
        toggleWorkDrawer={() => {}}
        setEditWorkData={() => {}}
        toggleExpenseDrawer={() => {}}
        setEditExpenseData={() => {}}
        toggleInventoryDrawer={() => {}}
        setEditInventoryData={() => {}}
        toggleContactDrawer={() => {}}
        setEditContactData={() => {}}
        toggleNoteDrawer={() => {}}
        setEditNoteData={() => {}}
        toggleLinkDrawerOpen={() => {}}
        setEditSpaceId={() => {}}
        toggleSpaceDrawer={() => {}}
        refreshParent={() => {}}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        toggleViewWorkDrawer={() => {}}
        setViewWorkId={() => {}}
      />
      <InventoryDrawer
        isOpen={inventoryDrawerOpen}
        toggleDrawerOpen={toggleInventoryDrawer}
        inventory={editInventoryData}
        setEditingInventoryData={setEditInventoryData}
        width={null}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
      />
      <ExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        toggleDrawerOpen={toggleExpenseDrawer}
        setEditingExpenseData={setEditingExpenseData}
        expense={editingExpenseData}
      />

      <PageHeader>
        <HeaderControlsWrapper>
          <div>
            <PageTitle>Contacts</PageTitle>
            <PageSubtitle>Manage personal and professional contact details associated with your property</PageSubtitle>
          </div>
        </HeaderControlsWrapper>
        <HeaderControlsWrapper>
          <AddButtonStyled
            className={"spaces"}
            icon={<PlusOutlined />}
            onClick={() => addNewContact()}
          >{window.innerWidth > 1024 && "Add a contact"}</AddButtonStyled>
        </HeaderControlsWrapper>
      </PageHeader>
      {contacts.content.filter(contact => !_.isNull(contact.name)).length !== 0 &&
      !_.isEqual(contacts.content, initialContactData) && (
        <>
          <ContactsListDesktop>
            <SearchWrapper>
              <Search
                size={"large"}
                value={search}
                onChange={(val) => {
                  setSearch(val.target.value);
                }}
                placeholder={"Search..."}
                suffix={<SearchIcon />}
              />
              <ButtonFilters
                size={"large"}
                onClick={() => setShowTagFilters(!showTagFilters)}
                icon={<div style={{position: "relative"}}>
                  {selectedTags.length > 0 && <DotIcon
                      style={{position: "absolute", top: "-0.25rem", left: "-0.3rem"}}/>}
                  <FilterIcon style={{marginRight: "0.5rem"}} />
                </div>}
              >
                {showTagFilters ? "Hide" : "Show"} tag filters
              </ButtonFilters>
              <Popover
                content={sortMenuDropdown()}
                className={"sortMenuWorks"}
                visible={sortMenuPopoverDesktop}
                placement="bottom"
                onVisibleChange={setSortMenuPopoverDesktop}
                trigger={["click"]}
              >
                <ButtonSort
                  icon={<SortIcon />}
                  onClick={() => {
                    setSortMenuPopoverDesktop(!sortMenuPopoverDesktop);
                  }}
                />
              </Popover>
            </SearchWrapper>
            {showTagFilters && displayFilters()}
            <List
              style={{ width: "100%", borderTop: "1px solid #f0f0f0" }}
              className="no-rounded-list"
              itemLayout="horizontal"
              dataSource={getContacts()}
              renderItem={(item) => (
                <ListItemStyled
                  onClick={() => {
                    if (!editTagsMode) {
                      toggleViewContactDrawer(true);
                      setViewContactId(item.contactId);
                    }
                  }}
                >
                  <Skeleton loading={false} title={false} active>
                    <List.Item.Meta
                      title={<TitleWrapper>
                        <ContactDetail spanWidth="14rem">
                          <ContactName>{item.name}&nbsp;{item.surname}</ContactName>
                        </ContactDetail>
                        <ContactDetail spanWidth="14rem">
                          {item.contactType && <TagStyledBold style={{display: 'inline-block'}}>
                            {item.contactType}
                          </TagStyledBold> }
                        </ContactDetail>
                      </TitleWrapper>
                      }
                      description={
                        <>
                          <DescriptionWrapper>
                            <ContactDetail style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "flex-start"
                            }} spanWidth="14rem">
                              <OverflowWrapper>
                                {item.company}
                              </OverflowWrapper>
                              <OverflowWrapper
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open(item.website, "_blank");
                                }}>
                                {item.website}
                              </OverflowWrapper>
                            </ContactDetail>
                            <ContactDetail spanWidth="14rem">
                              <div style={{display: "flex", flexDirection: "column"}}>
                                <div style={{display: "flex", alignItems: "center", marginBottom: "0.15rem"}}>
                                <span style={{fontWeight: 700, fontSize: "0.875rem", marginRight: "0.5rem",}}>
                                  {item.phone && "Ph:"}
                                </span>
                                  <OverflowWrapper>{item.phone}</OverflowWrapper>
                                </div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                <span style={{fontWeight: 700, fontSize: "0.875rem", marginRight: "0.5rem",}}>
                                  {item.email && "Email:"}
                                </span>
                                  <OverflowWrapper onClick={(e) => {e.stopPropagation()}}>
                                    {item.email}
                                  </OverflowWrapper>
                                </div>
                              </div>
                            </ContactDetail>
                          </DescriptionWrapper>
                          <div style={{marginTop: "0.5rem"}}>
                            <TagContainer
                              parentTags={item.tags}
                              parentId={item.contactId}
                              parentType={"contact"}
                              selectedTags={selectedTags}
                              toggleGlobalEditMode={toggleEditTagsMode}
                              refreshParent={() => dispatch(getAllContacts(propertyId))}
                            />
                          </div>
                        </>
                      }
                    />
                  </Skeleton>
                  <ContactTagWrapper>
                    {item.worksCount > 0 && (
                      <TagStyled>
                        {" "}
                        <MaintenanceIcon
                          className={"works-stroke-theme-icon"}
                        />{" "}
                        {item.worksCount} PROJECT
                        {item.worksCount > 1 && "S"}
                      </TagStyled>
                    )}
                    {item.expensesCount > 0 && (
                      <TagStyled>
                        {" "}
                        <ExpensesIcon
                        />{" "}
                        {item.expensesCount} EXPENSE
                        {item.expensesCount > 1 && "S"}
                      </TagStyled>
                    )}
                    {item.expensesTotalCost > 0 && (
                      <TagStyledBold style={{padding:"0.5rem"}}>
                        {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(item.expensesTotalCost)}
                      </TagStyledBold>
                    )}
                    {/*{item && <DeleteImage*/}
                    {/*    style={{ cursor: "pointer" }}*/}
                    {/*    onClick={(e) => {*/}
                    {/*      e.stopPropagation();*/}
                    {/*      confirmDelete(item)*/}
                    {/*    }}*/}
                    {/*/>}*/}
                    <Popover
                      content={moreMenuDropdown(item)}
                      placement="bottomRight"
                      trigger={["click"]}
                      //@ts-ignore
                      zIndex={2}
                    >
                      <MoreMenu
                        style={{ marginLeft: "2rem" }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    </Popover>

                  </ContactTagWrapper>
                </ListItemStyled>
              )}
            />
            {filteredContacts().length > pageSize && <PaginationStyled
                className={filteredContacts().length < pageSize ? "single-page-pagination" : ""}
                onChange={(page) => setPage(page)}
                defaultPageSize={pageSize}
                simple={window.innerWidth < 1024}
                showSizeChanger={false}
                itemRender={itemRender}
                defaultCurrent={1}
                total={filteredContacts().length}
                current={page}
                onShowSizeChange={(current, size) => setPageSize(size)}
            />}
          </ContactsListDesktop>
          <ContactsListMobile>
            <div style={{ padding: "1rem 1rem", width: "100%" }}>
              <SearchWrapperMobile style={{flexDirection: "column"}}>
                <Search
                  size={"large"}
                  value={search}
                  onChange={(val) => setSearch(val.target.value)}
                  placeholder={"Search contacts..."}
                  suffix={<SearchIcon />}
                />
                <div style={{display: "flex", marginTop: "1rem"}}>
                  <ButtonFilters style={{height: "3rem"}}
                     size={"large"}
                     onClick={() => setShowTagFilters(!showTagFilters)}
                     icon={<div style={{position: "relative"}}>
                       {selectedTags.length > 0 && <DotIcon
                           style={{position: "absolute", top: "-0.25rem", left: "-0.3rem"}}/>}
                       <FilterIcon style={{marginRight: "0.5rem"}} />
                     </div>}>
                      {showTagFilters ? "Hide" : "Show"} tag filters
                  </ButtonFilters>
                  <Popover
                    content={sortMenuDropdown()}
                    className={"sortMenuWorks"}
                    visible={sortMenuPopoverMobile}
                    placement="bottom"
                    onVisibleChange={setSortMenuPopoverMobile}
                    trigger={["click"]}
                  >
                    <ButtonSort
                      icon={<SortIcon />}
                      onClick={() => {
                        setSortMenuPopoverMobile(!sortMenuPopoverMobile);
                      }}
                    />
                  </Popover>
                </div>
              </SearchWrapperMobile>
            </div>
            {showTagFilters && displayFilters()}
            <List
              style={{ width: "100%" }}
              itemLayout="vertical"
              dataSource={getContacts()}
              renderItem={(item) => (
                <>
                  <Divider style={{ margin: "0" }} />
                  <div style={{ padding: "0rem 1rem" }}>
                    <ListItemStyled actions={[]}>
                      <Skeleton loading={false} title={false} active>
                        <div style={{ display: "flex" }}>
                          <List.Item.Meta
                            title={<>
                              <span
                                onClick={() => {
                                  if (!editTagsMode) {
                                    toggleViewContactDrawer(true);
                                    setViewContactId(item.contactId);
                                  }
                                }}
                              >
                                <Ellipsis
                                  style={{ display: "inline", marginRight: '0.7rem' }}
                                  lines={1}
                                >
                                  {item.name} {item.surname}
                                </Ellipsis>
                              </span>
                              {item.contactType && <TagStyledBold style={{display: 'inline-block'}}>
                                {item.contactType}
                              </TagStyledBold> }
                            </>
                            }
                            description={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {!_.isNil(item.company) &&
                                !_.isEmpty(item.company) && (
                                  <span>{item.company}</span>
                                )}

                                {!_.isNil(item.phone) &&
                                !_.isEmpty(item.phone) && (
                                  <span style={{ marginTop: "0.5rem" }}>
                                        <span
                                          style={{
                                            fontWeight: 700,
                                            fontSize: "0.875rem",
                                            marginRight: "0.5rem",
                                          }}
                                        >
                                          {item.phone && "Ph:"}
                                        </span>
                                        <a
                                          href={"tel:" + item.phone}
                                          style={{
                                            textDecoration: "underline",
                                          }}
                                        >
                                          {item.phone}
                                        </a>
                                      </span>
                                )}

                                {!_.isNil(item.email) &&
                                !_.isEmpty(item.email) && (
                                  <span style={{ marginTop: "0.5rem" }}>
                                        {" "}
                                    <span
                                      style={{
                                        fontWeight: 700,
                                        fontSize: "0.875rem",
                                        marginRight: "0.5rem",
                                      }}
                                    >
                                          {item.email && "Email:"}
                                        </span>
                                        <a
                                          href={"mailto:" + item.email}
                                          style={{
                                            textDecoration: "underline",
                                          }}
                                        >
                                          <Ellipsis
                                            style={{ display: "inline" }}
                                            length={20}
                                          >
                                            {item.email}
                                          </Ellipsis>
                                        </a>
                                      </span>
                                )}
                                {!_.isNil(item.email) &&
                                !_.isEmpty(item.email) && (
                                  <span style={{ marginTop: "0.5rem" }}>
                                        {" "}
                                        <a
                                          href={item.website}
                                          style={{
                                            textDecoration: "underline",
                                          }}
                                        >
                                          <Ellipsis
                                            style={{ display: "inline" }}
                                            length={20}
                                          >
                                            {item.website}
                                          </Ellipsis>
                                        </a>
                                      </span>
                                )}
                              </div>
                            }
                          />
                          <Popover
                            content={moreMenuDropdown(item)}
                            placement="bottomRight"
                            trigger={["click"]}
                            //@ts-ignore
                            zIndex={2}
                          >
                            <MoreMenu
                              style={{ marginLeft: "2rem" }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          </Popover>
                        </div>
                        <MobileTagWrapper>
                          {item.worksCount > 0 && (<TagStyledMobile>
                              <MaintenanceIcon className={"works-stroke-theme-icon"}/>
                              {item.worksCount} PROJECT{item.worksCount > 1 && "S"}
                            </TagStyledMobile>)}
                          {item.expensesCount > 0 && (<TagStyledMobile>
                              <ExpensesIcon/>
                              {item.expensesCount} EXPENSE
                              {item.expensesCount > 1 && "S"}
                            </TagStyledMobile>)}
                          {item.expensesTotalCost > 0 && (
                            <PriceTagMobile>
                              {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(item.expensesTotalCost)}
                            </PriceTagMobile>
                          )}
                        </MobileTagWrapper>
                        <TagContainer
                          parentTags={item.tags}
                          parentId={item.contactId}
                          parentType={"contact"}
                          selectedTags={selectedTags}
                          toggleGlobalEditMode={toggleEditTagsMode}
                          refreshParent={() => dispatch(getAllContacts(propertyId))}
                        />
                      </Skeleton>
                    </ListItemStyled>
                  </div>
                </>
              )}
            />
            {filteredContacts().length > pageSize && <PaginationStyled
              className={filteredContacts().length < pageSize ? "single-page-pagination" : ""}
              onChange={(page) => setPage(page)}
              defaultPageSize={pageSize}
              simple={window.innerWidth < 1024}
              showSizeChanger={false}
              itemRender={itemRender}
              defaultCurrent={1}
              total={filteredContacts().length}
              style={{marginBottom: "1.5rem"}}
            />}
          </ContactsListMobile>
        </>
      )}
      {(contacts.content.filter(contact => !_.isNull(contact.name)).length === 0 ||
        _.isEqual(contacts.content[0], initialContactData)) && (
        <EmptyRecordsList>
          <EmptyRecordsHeader>
            {f(messages.addContactTitle)}
          </EmptyRecordsHeader>
          <EmptyRecordsDescription>
            {f(messages.addContactMsg)}
          </EmptyRecordsDescription>
          <AddRecordButton
            onClick={() => addNewContact()}
            size={"large"}
            icon={<PlusOutlined/>}
          >
            {f(messages.addContact)}
          </AddRecordButton>
        </EmptyRecordsList>)}
    </PageWrapper>
  );
};

const MenuTitle = styled.li`
  color: #21272b;
  cursor: default;
  margin-left: 1rem;
  font-weight: 700;
  :hover {
    color: #21272b;
  }
`;

const ButtonText = styled.span`
  font-weight: 600;
  font-size: 0.875rem;
  color: #21272b;
`;

export const ContactDetail = styled.span`
  font-size: 0.875rem;
  width: ${(props: { spanWidth: string }) => props.spanWidth || "20%"};
  align-items: center;
  font-weight: 400;
  font-style: normal;
  color: #6b7185;
  margin-right: 1rem;
  display: flex;
`;

const OverflowWrapper = styled.span`
  text-decoration: underline;
  display: inline-block;
  max-width: 225px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Search = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
`;

const SearchWrapperMobile = styled.div`
  width: 100%;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  width: 100%;
  margin: 1.25rem;
  padding: 0 1.25rem 0 1.25rem;
`;

const TagStyledMobile = styled(Tag)`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.25rem;
  background-color: #eff1f5;
  color: #6b7185;
  padding: 0.3rem 0.75rem;
  border-radius: 0.25rem;
  border: 0;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
`;

const PriceTagMobile = styled(TagStyledMobile)`
  font-weight: 700;
  color: #21272b;
  font-size: 1rem;
`

const MobileTagWrapper = styled.div`
  display: flex; 
  column-gap: 0.5rem; 
  row-gap: 0.25rem;
  margin-bottom: 0.75rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`

const TagStyled = styled(Tag)`
  background-color: #eff1f5;
  color: #6b7185;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1rem;
  padding: 0.3rem 0.75rem;
  padding-top: 0.5rem;
  border-radius: 0.25rem;
  border: 0;
  font-size: 0.75rem;
  font-weight: 500;
  height: 2.3rem;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const ListItemStyled = styled(List.Item)`
  @media (max-width: 1024px) {
    width: 100%;

    .ant-list-item-action > li {
      width: 100%;
    }
  }
  
  @media (min-width: 1024px) {
    padding: 0.75rem 2rem;
    display: flex;
    align-items: flex-start;
  }

  .ant-list-item-meta-title {
    font-size: 1rem;
    font-weight: 600;
    color: #21272b;
  }
  .ant-list-item-meta-description {
    color: #6b7185;
    font-weight: 400;
    margin-top: 0.4rem;
  }
`;

const ContactsListMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  border-radius: 0.625rem;
  border: 1px solid #dae0e6;
  align-items: center;
  background-color: #fff;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const ContactsListDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px 10px 10px 10px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const ContactName = styled.div`
  display: inline;
  font-size: 16px;
  font-weight: 600;

  & > a { color: black; }
  & > a:hover { color: #40a9ff; }
`

const ContactTagWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export default Contacts;
