import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {
  inventoryReport,
  TGetInventoryReportResponse,
} from "../../api-wrapper/reports/inventoryReport";
import {Guid} from "guid-typescript";

export const getInventoryReportInitAction = createAction(
  "InventoryReport/GET_InventoryReport_INIT"
)();
export const getInventoryReportSuccessAction = createAction(
  "InventoryReport/GET_InventoryReport_SUCCESS"
)<TGetInventoryReportResponse>();
export const getInventoryReportFailureAction = createAction(
  "InventoryReport/GET_InventoryReport_FAILURE"
)();

export type TGetInventoryReportInitAction = ActionType<
  typeof getInventoryReportInitAction
>;
export type TGetInventoryReportSuccessAction = ActionType<
  typeof getInventoryReportSuccessAction
>;
export type TGetInventoryReportFailureAction = ActionType<
  typeof getInventoryReportFailureAction
>;

export type TGetInventoryReportActions =
  | TGetInventoryReportInitAction
  | TGetInventoryReportSuccessAction
  | TGetInventoryReportFailureAction;

export type TGetInventoryReportThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetInventoryReportActions>;

export const getInventoryReport: TGetInventoryReportThunkActionType = (
  propertyId: Guid
) => (dispatch: (action: Action | Promise<Action>) => any) => {
  dispatch(getInventoryReportInitAction());
  return inventoryReport(propertyId)
    .then((payload) => {
      if (payload) {
        return dispatch(getInventoryReportSuccessAction(payload));
      }
    })
    .catch(() => dispatch(getInventoryReportFailureAction()));
};
