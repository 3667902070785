import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TSetPropertyNicknameRequest = {
  nickname: string
};

export const setNickname = (propertyId: Guid, payload: TSetPropertyNicknameRequest) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/nickname`);

  return apiPut<{
    nickname: string
  }, {}>(url, payload);
};
