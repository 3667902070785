import React, {Dispatch, useEffect,  useState} from "react";
import {Form, notification, Popover} from "antd";
import {useHistory, useLocation} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";

import { TLoginThunkActionType } from "../actions/logIn";
import {
  MAX_LENGTH_LOGIN_FORM,
  NOTIFICATION_TOP_RIGHT_PLACEMENT,
} from "../helpers/constants";
import messages from "./messages";
import { authSelector, getResetUpdateSelector } from "../selectors";
import LoadingPage from "./LoadingPage";
import {
  InputPasswordStyled,
  Label,
  LabelWrapper
} from "./RegistrationPage";
import { ReactComponent as AlertIcon } from "../../images/AlertRed.svg";
import { updatePassword } from "../actions/resetUpdate";
import {ButtonStyled, FormWrapper, ImageWrapper} from "./styled";
import moment from "moment";
import decodeJwt from "jwt-decode";
import {
  ButtonsWrapper,
  FormHeader,
  LoginPageImageStyled,
  Main,
  Subtitle,
  TextOnImage,
  Title,
  TitlesWrapper
} from "./PasswordReset";
import {TFetchingStatus} from "../../helpers";
import {passwordResult, resultIcon} from "../components/helper";
import {passwordTest} from "./helpers";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const { Item } = Form;

const PasswordUpdate = () => {
  const { formatMessage: f } = useIntl();

  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const dispatch: Dispatch<TLoginThunkActionType> = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const auth = useSelector(authSelector);
  const resetUpdateError = useSelector(getResetUpdateSelector);
  let history = useHistory();

  const [showPasswordUpdateError, setPasswordUpdateError] = useState(false);
  const [
    showPasswordUpdateExpirationError,
    setPasswordUpdateExpirationError,
  ] = useState(false);

  const [showCaptchaError, setCaptchaError] = useState(false);

  const [showServerError, setServerError] = useState(false);
  const [serverError, setServerErrorMessage] = useState("");

  const [passwordStrengthResult, setPasswordStrengthResult] = useState<any>({})

  const [isMcLarenWebsite, toggleMcLarenWebsite] = useState(false);

  const useQuery = () => {
    let query = new URLSearchParams(useLocation().search);
    return query.get("auth_token");
  };
  const authToken = useQuery();

  useEffect(() => {
    console.log("window.location.hostname=" + window.location.hostname)
    if (window.location.hostname.includes("mcl") || window.location.hostname.includes("localhost")) {
      // toggleBusinessWebsite(true);
      toggleMcLarenWebsite(true);
    }
  }, [])

  const handleUpdate = async (data: {
    password: string;
    confirmationPassword: string;
  }) => {
    const { password, confirmationPassword } = data;

    setServerError(false);

    let recaptchaToken = "";
    if (executeRecaptcha) {
      recaptchaToken = await executeRecaptcha('yourAction');
    }

    if (isEmpty(password) && isEmpty(confirmationPassword)) {
      api.error({
        message: f(messages.emptyFieldTitle),
        description: f(messages.emptyFieldDescription),
        placement: NOTIFICATION_TOP_RIGHT_PLACEMENT,
      });
    } else {
      if (passwordStrengthResult.final)
        dispatch(updatePassword(authToken, recaptchaToken, { newPassword: password }));
    }
  };

  useEffect(() => {
    if (typeof authToken === "string") {
      const { exp } = decodeJwt<{
        exp: number;
      }>(authToken);

      let currentMoment = moment()
      let expirationMoment = moment(exp * 1000)

      if (expirationMoment.diff(currentMoment) < 0)
        history.push('/reset/password?tokenExpired=true')
    }
  }, [authToken])

  useEffect(() => {
    setPasswordUpdateExpirationError(resetUpdateError.content.timeoutError);
  }, [resetUpdateError.content.timeoutError]);

  useEffect(() => {
    if (resetUpdateError.fetchingStatus === TFetchingStatus.Failure) {
      notification.error({
        message: f(messages.error),
        description: "New password must be different than the old password.",
        placement: "topRight",
      });
      //dispatch(loginClean());
    }
  }, [resetUpdateError.fetchingStatus, f, dispatch]);

  if (auth.fetchingStatus === TFetchingStatus.Initial) {
    return <LoadingPage />;
  }

  return (
    <Main>
      {contextHolder}
      <FormWrapper>
        <FormHeader>
          <div style={{width: "175px"}}>
            <img src={"/images/LivletLogoNew.png"} style={{maxWidth: "100%"}} alt={""} />
          </div>
        </FormHeader>
        <TitlesWrapper>
          <Title>{f(messages.updatePasswordTitle)}</Title>
          <Subtitle>{f(messages.updatePasswordSubtitle)}</Subtitle>
        </TitlesWrapper>
        <Form
          form={form}
          name="login"
          size={"large"}
          initialValues={{ remember: true }}
          onFinish={handleUpdate}
        >
          <LabelWrapper>
            <Label>{f(messages.createPassword)}</Label>{" "}
          </LabelWrapper>
          <Popover
            content={<div style={{padding: '0.7rem'}}>
              <b>Password must include:</b>
              <br/>{resultIcon(passwordStrengthResult.appropriateLength)} 8-20 Characters
              <br/>{resultIcon(passwordStrengthResult.capitalLetters)} At least one capital letter
              <br/>{resultIcon(passwordStrengthResult.numbers)} At least one number
              <br/>{resultIcon(passwordStrengthResult.noSpaces)} No spaces
            </div>}
            placement="top"
            trigger={["hover", "focus"]}
          >
            <Item
              key={"password"}
              name={"password"}
              rules={[
                {
                  required: true,
                  message: f(messages.validationPassword),
                },
              ]}
            >
              <InputPasswordStyled
                maxLength={MAX_LENGTH_LOGIN_FORM}
                placeholder={f(messages.password)}
                onChange={(e) => {
                  setPasswordUpdateError(false)
                  setPasswordStrengthResult(passwordTest(e.target.value))
                }}
              />
            </Item>
          </Popover>
          {form.getFieldValue("password") &&
          <div style={{display: "flex", marginTop: "-0.3rem", marginBottom: "0.3rem"}}>
            {passwordResult(passwordStrengthResult.totalScore)}
              <Popover
                  content={<div style={{padding: '0.7rem'}}>
                    <b>Password must include:</b>
                    <br/>{resultIcon(passwordStrengthResult.appropriateLength)} 8-20 Characters
                    <br/>{resultIcon(passwordStrengthResult.capitalLetters)} At least one capital letter
                    <br/>{resultIcon(passwordStrengthResult.numbers)} At least one number
                    <br/>{resultIcon(passwordStrengthResult.noSpaces)} No spaces
                  </div>}
                  placement="top"
                  trigger={["hover", "focus"]}
              ><span style={{marginLeft: "0.3rem", marginBottom: "-0.3rem"}}>{resultIcon(passwordStrengthResult.final)}</span>
              </Popover>
          </div>}
          <LabelWrapper>
            <Label>{f(messages.reEnterPassword)}</Label>{" "}
          </LabelWrapper>
          <Item
            key={"confirmationPassword"}
            name={"confirmationPassword"}
            rules={[
              {
                validator: (rule, value, callback) => {
                  if (
                    !form.getFieldValue("password") &&
                    !form.getFieldValue("confirmationPassword")
                  ) {
                    callback(f(messages.validationPassword));
                  }
                  form.getFieldValue("password") !==
                  form.getFieldValue("confirmationPassword")
                    ? callback(f(messages.confirmationPasswordError))
                    : callback();
                },
              },
            ]}
          >
            <InputPasswordStyled
              maxLength={MAX_LENGTH_LOGIN_FORM}
              placeholder={f(messages.password)}
              onChange={() => setPasswordUpdateError(false)}
            />
          </Item>
          {showPasswordUpdateError && (
            <div
              style={{
                backgroundColor: "#FCF0F0",
                padding: "1rem",
                marginBottom: "1em",
                display: "flex",
                borderRadius: 6,
                fontWeight: 400,
              }}
            >
              <AlertIcon />
              <span style={{ paddingLeft: "0.5rem" }}>
                {f(messages.loginCredentialsError)}
              </span>
            </div>
          )}
          {showCaptchaError && (
            <div
              style={{
                backgroundColor: "#FCF0F0",
                padding: "1rem",
                marginBottom: "1em",
                display: "flex",
                borderRadius: 6,
                fontWeight: 400,
              }}
            >
              <AlertIcon />
              <span style={{ paddingLeft: "0.5rem" }}>
                Please verify again.
              </span>
            </div>
          )}
          <ButtonsWrapper>
            <ButtonStyled onClick={() => history.push("/login")}>
              {f(messages.cancel)}
            </ButtonStyled>
            <ButtonStyled htmlType="submit" type="primary">
              {f(messages.reset)}
            </ButtonStyled>
          </ButtonsWrapper>
        </Form>
        {showPasswordUpdateExpirationError && (
          <div
            style={{
              backgroundColor: "#FCF0F0",
              padding: "1rem",
              marginBottom: "1em",
              display: "flex",
              borderRadius: 6,
              fontWeight: 400,
            }}
          >
            <AlertIcon />
            <span style={{ paddingLeft: "0.5rem" }}>
              {f(messages.updatePasswordExpiredLink, {
                passwordResetLink: (
                  <a onClick={() => history.push("/reset/password")}>{f(messages.clickHere)}</a>
                ),
              })}
            </span>
          </div>
        )}
        {showServerError && (
          <div
            style={{
              backgroundColor: "#FCF0F0",
              padding: "1rem",
              marginBottom: "1em",
              display: "flex",
              borderRadius: 6,
              fontWeight: 400,
            }}
          >
            <AlertIcon />
            <span style={{ paddingLeft: "0.5rem" }}>
              {serverError}
            </span>
          </div>
        )}
      </FormWrapper>
      <ImageWrapper>
        <LoginPageImageStyled imgSrc={isMcLarenWebsite ? "/images/registrationImageBeach.jpg" : undefined} />
        <TextOnImage>{f(messages.imageText)}</TextOnImage>
      </ImageWrapper>
    </Main>
  );
};

export default PasswordUpdate;