import React, {useEffect, useState} from "react";
import {List, Modal, notification, Skeleton} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {ReactComponent as DeleteImage} from "../../../images/Delete.svg";
import {removeTenant} from "../../../api-wrapper/tenants/removeTenant";
import {getAllTenants} from "../../actions/tenants";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import {ReactComponent as GreenDot} from "../../../images/GreenDot.svg";
import {numberWithCommas} from "../helper";
import {TGetTenantsResponse} from "../../../api-wrapper/tenants/getTenants";
import {useDispatch, useSelector} from "react-redux";
import {
  PropertyListDesktop,
  PropertyPaymentDetails,
  SubTag,
  TagList,
  TagStyledBoldRed,
  TagStyledBold,
  TagStyledGreen
} from "../properties/PropertyDetailsComp";
import {ListItemStyled} from "../../screens/styled";
import {emptyGuid, getCurrencySign} from "../../screens/helpers";
import {
  AddRecordButton,
  ButtonOutline,
  EmptyRecordsDescription,
  EmptyRecordsHeader,
  EmptyRecordsList, SectionLabel
} from "../../screens/components";
import {getAllMaintenances} from "../../actions/maintenances";
import {TFetchingStatus} from "../../../helpers";
import {getMaintenancesSelector, getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {unsubscribeFromMaintenance} from "../../../api-wrapper/maintenance/unsubscribeFromMaintenance";
const moment = require("moment");

export const tenantInitialState = {
  tenancyId: emptyGuid,
  propertyId: emptyGuid,
  startDate: "",
  endDate: "",
  rentalPayment: 0,
  tenancyContactId: emptyGuid,
  agentFee: 0,
  agentContact: emptyGuid,
  propertyManagementFee: 0,
  propertyManagementContact: emptyGuid,
};

type Props = {
  tenants: TGetTenantsResponse;
  toggleTenantDrawer: (arg: boolean) => void;
  setTenantData: (arg: any) => void;
  showContactName: (arg: any) => any;
};

const TenancyListComp = (props: Props) => {
  const { tenants, toggleTenantDrawer, setTenantData, showContactName } = props;
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const maintenances = useSelector(getMaintenancesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [checkInUpkeep, setCheckInUpkeep] = useState<any>(null);
  const [checkOutUpkeep, setCheckOutUpkeep] = useState<any>(null);
  const [legionellaUpkeep, setLegionellaUpkeep] = useState<any>(null);

  useEffect(() => {
    dispatch(getAllMaintenances(propertyId));
  }, [propertyId])

  useEffect(() => {
    if (maintenances.fetchingStatus === TFetchingStatus.Success) {
      setCheckInUpkeep(maintenances.content.filter(m => m.standardInternalId === 81)[0])
      setCheckOutUpkeep(maintenances.content.filter(m => m.standardInternalId === 82)[0])
      setLegionellaUpkeep(maintenances.content.filter(m => m.standardInternalId === 83)[0])
    }
  }, [maintenances])

  const isTenantsListEmpty = (tenants: TGetTenantsResponse) => {
    if (tenants.length === 0) return true
    else if (tenants[0].propertyId === emptyGuid) return true
    else return false
  }

  const currentTenants = tenants.filter(t => moment().isBetween(t.startDate, t.endDate))
    .sort((t1, t2) => {
      return moment(t2.endDate).format("YYYYMMDD") - moment(t1.endDate).format("YYYYMMDD")
    })

  const otherTenants = tenants.filter(t => !moment().isBetween(t.startDate, t.endDate))
    .sort((t1, t2) => {
      return moment(t2.endDate).format("YYYYMMDD") - moment(t1.endDate).format("YYYYMMDD")
    })

  const confirmUnsubscribe = async () => {
    Modal.confirm({
      title: "You have removed your tenancy",
      content: "Do you want to also unsubscribe from your tenancy-related maintenance tasks?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk: async () => {
        checkInUpkeep.subscribed && await unsubscribeFromMaintenance({propertyId, maintenanceId: checkInUpkeep.id})
        checkOutUpkeep.subscribed && await unsubscribeFromMaintenance({propertyId, maintenanceId: checkOutUpkeep.id})
        legionellaUpkeep.subscribed && await unsubscribeFromMaintenance({propertyId, maintenanceId: legionellaUpkeep.id})
        notification.success({
          message: "Unsubscribed successfully!",
          description: "You have been successfully unsubscribed from all tenancy-related upkeep tasks.",
          placement: "topRight",
        })
      }
    });
  }

  const confirmDelete = async (item: any) =>  {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk: async () => {
        await removeTenant(item.propertyId, item.tenancyId);
        (checkInUpkeep.subscribed || checkOutUpkeep.subscribed || legionellaUpkeep.subscribed) && await confirmUnsubscribe();
        dispatch(getAllTenants(item.propertyId));
      }
    });
  }

  return (
    <>
      <SectionLabel>
        Property Tenancy
        <ButtonOutline onClick={() => toggleTenantDrawer(true)}>
          Add Tenancy
        </ButtonOutline>
      </SectionLabel>
      {isTenantsListEmpty(tenants) && (
        <EmptyRecordsList>
          <EmptyRecordsHeader>
            Add and manage tenancy
          </EmptyRecordsHeader>
          <EmptyRecordsDescription>
            Add tenancy information so that you can easily keep track of the
            contacts related to your rental and get enhanced reporting for your
            property.
          </EmptyRecordsDescription>
          <AddRecordButton
            onClick={() => toggleTenantDrawer(true)}
            icon={<PlusOutlined />}
            size={"large"}
          >
            Add Tenancy
          </AddRecordButton>
        </EmptyRecordsList>
      )}
      {!isTenantsListEmpty(tenants) && (
        <>
          <PropertyListDesktop>
            <List
              style={{ width: "100%" }}
              className="rounded-top-bottom-list"
              itemLayout="horizontal"
              dataSource={[...currentTenants, ...otherTenants]}
              renderItem={(item) => (
                <ListItemStyled
                  onClick={() => {
                    // @ts-ignore
                    setTenantData({ data: item });
                    toggleTenantDrawer(true);
                  }}
                  actions={[
                    <DeleteImage
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmDelete(item)
                      }}
                    />,
                  ]}
                >
                  <Skeleton loading={false} title={false} active>
                    <List.Item.Meta
                      title={
                        <span>
                              {item.tenancyContactId ? (
                                <>
                                  <Ellipsis
                                    style={{ display: "inline" }}
                                    length={25}
                                  >
                                    {showContactName(item.tenancyContactId)}
                                  </Ellipsis>
                                </>
                              ) : (
                                "No tenant entered"
                              )}
                            </span>
                      }
                      description={
                        <>
                        {item.agentContact ? (<p>
                              <Ellipsis style={{display: "inline"}} length={25}>
                                {showContactName(item.agentContact)}
                              </Ellipsis>
                        </p>) : (<p>No rental agency entered</p>)}
                        <SubTag className={"display-mobile"}>
                        {item.startDate && item.endDate && (
                          <>
                            Tenancy period:{" "}
                            {moment(item.startDate).format(propertyPreferences.preferredDateFormat)} -{" "}
                            {moment(item.endDate).format(propertyPreferences.preferredDateFormat)}
                          </>
                        )}
                        </SubTag>
                        </>}
                    />
                    <PropertyPaymentDetails>
                      <TagList>
                        {moment().isBetween(item.startDate, item.endDate) && (
                          <TagStyledGreen>
                            <GreenDot
                              style={{
                                marginTop: "0.5rem",
                                marginRight: "0.5rem",
                              }}
                            />{" "}
                            CURRENT
                          </TagStyledGreen>
                        )}
                        {item.rentalPayment > 0 ? (
                          <TagStyledBold>
                            {getCurrencySign(propertyPreferences.preferredCurrency)}
                            {numberWithCommas(item.rentalPayment)}
                          </TagStyledBold>
                        ) : (
                          "\u00A0"
                        )}
                        {item.propertyManagementFee > 0 || item.agentFee > 0 ? (
                          <TagStyledBoldRed>
                            {getCurrencySign(propertyPreferences.preferredCurrency)}
                            {numberWithCommas(
                              item.propertyManagementFee + item.agentFee
                            )}
                          </TagStyledBoldRed>
                        ) : (
                          "\u00A0"
                        )}
                      </TagList>
                      <SubTag className={"display-desktop"}>
                        {item.startDate && item.endDate && (
                          <>
                            Tenancy period:{" "}
                            {moment(item.startDate).format(propertyPreferences.preferredDateFormat)} -{" "}
                            {moment(item.endDate).format(propertyPreferences.preferredDateFormat)}
                          </>
                        )}
                      </SubTag>
                    </PropertyPaymentDetails>
                  </Skeleton>
                </ListItemStyled>
              )}
            />
          </PropertyListDesktop>
        </>
      )}
    </>
  )
}

export default TenancyListComp