import {apiDelete, apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export const postAnswer = (propertyId: Guid, answerId: number) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/answers/${answerId}`);

  return apiPost(url);
};

export const deleteAnswer = (propertyId: Guid, questionId: number) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/questions/${questionId}/answers`);

  return apiDelete(url);
};
