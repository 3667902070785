import { Form, Input, Modal } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";

import Slider from "react-slick";
import { Label } from "../../screens/RegistrationPage";
import {getNotesByPropertyIdSelector, getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import { removeNote } from "../../../api-wrapper/notes/removeNote";
import { editNote } from "../../../api-wrapper/notes/editNote";
import { addNote, TAddNoteResponse } from "../../../api-wrapper/notes/addNote";
import UploadFile from "../attachments/UploadFile";
import {Guid} from "guid-typescript";
import {emptyGuid} from "../../screens/helpers";
import {getNoteById} from "../../../api-wrapper/notes/getNoteById";
import AddNewRecordDrawer, {linkToRecord} from "../spaces/AddNewRecordDrawer";
import {getAllNotesByPropertyId} from "../../actions/notesByPropertyId";
import {
  ActionButtonDarkGreen, ActionButtonDarkGreenOutline,
  ActionButtonGreen, CircleIconGreen,
  DrawerFooter,
  DrawerHeader,
  DrawerStyled, FormStyled,
  paginationButtons,
  PaginationStyled
} from "../../screens/components";
import {ReactComponent as ReturnIcon} from "../../../images/spaces/ReturnIcon.svg";
import {ReactComponent as EditIcon} from "../../../images/spaces/EditIcon.svg";
import {ReactComponent as CancelIcon} from "../../../images/CancelIcon.svg";
import {SpaceDescription} from "../spaces/ViewSpaceDrawer";
import LinkedItemsList from "../spaces/LinkedItemsList";
import AddLinkedItem from "../spaces/AddLinkedItem";
import LinkedItemsHeader, {
  LinkedRecordsHeader,
} from "../spaces/LinkedItemsHeader";
import {ReactComponent as LinkIcon} from "../../../images/spaces/LinkNewIcon.svg";
import {ReactComponent as ImageDesktopDownload} from "../../../images/ImageDesktopDownload.svg";
import {ReactComponent as ImageDesktopRemove} from "../../../images/ImageDesktopRemove.svg";
import {ReactComponent as ImageDesktopView} from "../../../images/ImageDesktopView.svg";
import {ReactComponent as ImageMobileRemove} from "../../../images/ImageMobileRemove.svg";
import {ReactComponent as ArrowRightGrayIcon} from "../../../images/ArrowRightGray.svg";
import {ReactComponent as ArrowLeftGrayIcon} from "../../../images/ArrowLeftGray.svg";
import {ReactComponent as AddPlusIcon} from "../../../images/attachments/AddPlus.svg";
import {selectStyle} from "../attachments/EditFileDrawer";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {getAllAttachments} from "../../actions/attachments";
import moment from "moment/moment";
import TagContainer from "../tags/TagContainer";
import {ReactComponent as UploadIcon} from "../../../images/spaces/UploadIcon.svg";
import {DraggerStyled, DraggerText} from "../spaces/UploadImage";
import {checkFile} from "../publicOnboarding/DocumentUploadStep";
import Dragger from "antd/es/upload/Dragger";
import {showFailedFileUploadNotification, showStartFileUploadNotification} from "../attachments/uploadHelpers";
import {addAttachment} from "../../../api-wrapper/attachments/addAttachment";
import {ReactComponent as ReturnArrow} from "../../../images/spaces/ReturnArrow.svg";
import {SpaceTitle} from "../spaces/EditSpaceDrawer";
import {initialNoteData} from "../../screens/Notes";
import {removeRelation} from "../../../api-wrapper/relation/removeRelation";
import {downloadAttachment} from "../../../api-wrapper/attachments/downloadAttachment";

const { TextArea } = Input;

type Props = {
  drawerTitle?: string | null;
  currentType?: string;
  isOpen: boolean;
  toggleDrawerOpen: (arg: boolean) => void;
  openInEditMode?: boolean | null;
  toggleOpenInEditMode?: (arg: boolean) => void;
  note?: { data: TAddNoteResponse } | null;
  data?: {
    workNotes: {
      tags?: any;
      recordId: Guid;
      title: string;
      message: string;
      createdDate: string;
      emergency: boolean;
    } | null;
  } | null;
  setEditWorkData?: (arg: any) => void;
  refreshParent: (arg?: Guid | null) => void;
  icon?: ReactNode;
  highlightAttachment?: Guid | null;
  isGlobalNote?: boolean | null;
  parentRecordId?: Guid | null;
  parentRecordType?: string;
  toggleTaskDrawer: (arg: boolean) => void;
  setEditTaskData: (arg: any) => void;
  toggleLinkDrawerOpen: (arg: boolean) => void;
  setPresetLinkType: (arg: string) => void;
  parentPage?: string;

  toggleViewWorkDrawer: (arg: boolean) => void;
  setViewWorkId: (arg: any) => void;
  toggleExpenseDrawer: (arg: boolean) => void;
  setEditExpenseData: (arg: any) => void;
  toggleInventoryDrawer: (arg: boolean) => void;
  setEditInventoryData: (arg: any) => void;
  toggleContactDrawer: (arg: boolean) => void;
  setEditContactData: (arg: any) => void;
  toggleSpaceDrawer: (arg: boolean) => void;
  setEditSpaceId: (arg: any) => void;
  setAttachmentId: (arg: any) => void;
  toggleViewFileDrawer: (arg: boolean) => void;
};

const NoteDrawer = (props: Props) => {
  const {
    isOpen, toggleDrawerOpen, openInEditMode, toggleOpenInEditMode, data, refreshParent, setEditWorkData,
    setViewWorkId, toggleViewWorkDrawer, toggleExpenseDrawer, setEditExpenseData, toggleContactDrawer, setEditContactData,
    toggleInventoryDrawer, setEditInventoryData, toggleSpaceDrawer, setEditSpaceId, toggleTaskDrawer, setEditTaskData, toggleLinkDrawerOpen,
    setPresetLinkType, setAttachmentId, toggleViewFileDrawer, parentRecordId, parentRecordType, parentPage
  } = props;
  const [form] = Form.useForm();
  const properties = useSelector(getPropertiesSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const notesByPropertyId = useSelector(getNotesByPropertyIdSelector);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const dispatch = useDispatch()

  const [isNewRecordDrawerOpen, toggleNewRecordDrawer] = useState(false);
  const [noteLinks, setNoteLinks] = useState<any>([]);
  const [noteLoaded, toggleNoteLoaded] = useState(false);

  const [emergency, setEmergency] = useState(false);
  const [message, setMessage] = useState<any>("")
  const [file, setFile] = useState<Array<any>>([]);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [isEditMode, toggleEditMode] = useState(false);
  const [property, setProperty] = useState("");

  const filteredItems = () => noteLinks.filter((item: any) => searchQuery ?
    (item.name?.toLowerCase().includes(searchQuery.toLowerCase()) || item.title?.toLowerCase().includes(searchQuery.toLowerCase())): true)

  const getItems = () => filteredItems()
    .filter((currentValue: any, index: any) => index >= (page - 1) * 10 && index < page * 10);

  const handleManageNote = (e: { title: string }) => {
    const { title } = e;
    setSaveDisabled(true);
    if (data && data.workNotes && data.workNotes.recordId) {
      editNote({
        propertyId,
        noteId: data.workNotes.recordId,
        title,
        message,
        emergency,
      })
        .then((res) => {
          toggleOpenInEditMode && toggleOpenInEditMode(false);
          form.resetFields();
          if (parentRecordId && parentRecordType) linkToRecord(propertyId, parentRecordId, parentRecordType, res.recordId, "note", dispatch);
          dispatch(getAllNotesByPropertyId(propertyId));
          toggleDrawerOpen(false);
          refreshParent();
          setSaveDisabled(false);
          setFormFieldsChanged(false);
        })
        .catch();
    } else {
      if (file && file.length > 0) {
        data &&
          addNote({
            attachment: file[0],
            message,
            propertyId,
            title,
            emergency,
          }).then((res) => {
            toggleOpenInEditMode && toggleOpenInEditMode(false);
            setSaveDisabled(false);
            toggleDrawerOpen(false);
            form.resetFields();
            refreshParent();
            setFormFieldsChanged(false);
          });
      } else {
        addNote({
          message,
          propertyId,
          title,
          attachment: file[0],
          emergency,
        })
          .then(() => {
            toggleOpenInEditMode && toggleOpenInEditMode(false);
            setSaveDisabled(false);
            toggleDrawerOpen(false);
            form.resetFields();
            refreshParent();
            setFormFieldsChanged(false);
          })
          .catch();
      }
    }
  };

  const initialValues = () => {
    if (data) {
      return {
        title: data.workNotes?.title,
        message: data.workNotes?.message,
      };
    } else { return {} }
  };

  useEffect(() => {
    if (data && data.workNotes && data.workNotes.title) {
      toggleEditMode(false);
      setMessage(data.workNotes.message)
      setEmergency(data.workNotes.emergency);
    } else {
      toggleEditMode(true);
      setEmergency(false);
      setMessage("")
    }
    setFormFieldsChanged(false);
  }, [form, isOpen]);

  useEffect(() => {
    if (openInEditMode) {
      toggleEditMode(true);
    }
  }, [openInEditMode])

  useEffect(() => {
    form.resetFields();
  }, [isEditMode])

  useEffect(() => {
    if (!isOpen)
      return;

    refreshNote();
  }, [isOpen, notesByPropertyId]);

  const refreshNote = () => {
    if (!data || !data.workNotes)
      return;
    toggleNoteLoaded(false);
    if (refreshParent && parentPage !== "records") refreshParent();
    if (propertyId) {
      const noteProperty = properties.find((property) => property.propertyId === propertyId)
      if (noteProperty) {
        setProperty(noteProperty.address)
      }
    }
    // @ts-ignore
    const note = notesByPropertyId.filter(note => note.recordId === data.workNotes.recordId)[0];
    // @ts-ignore
    note && setEditWorkData && setEditWorkData({data: {...note}});
    !Object.is(data.workNotes.recordId, emptyGuid) &&
    getNoteById(propertyId, data.workNotes.recordId).then((res: any) => {
      setNoteLinks([...res.spaces, ...res.works, ...res.expenses, ...res.inventories, ...res.contacts, ...res.attachments,
        ...res.recurringExpenses, ...res.reminders, ...res.tasks]);
      toggleNoteLoaded(true);
    });
  };

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        toggleDrawerOpen(false);
        if (setEditWorkData) {
          setEditWorkData({data: initialNoteData});
        }
        form.resetFields();
        if (data && data.workNotes && _.isEmpty(data.workNotes.title)) {
          data && data.workNotes && removeNote(propertyId, data.workNotes.recordId).then(
            () => {
              dispatch(getAllNotesByPropertyId(propertyId));
            }
          )
        } else {
          dispatch(getAllNotesByPropertyId(propertyId));
        }
      },
    });
  }

  const closeDrawer = () => {
    if (formFieldsChanged) {
      confirmCloseUnsaved();
      return;
    }
    toggleOpenInEditMode && toggleOpenInEditMode(false);
    form.resetFields();
    if (data && data.workNotes && _.isEmpty(data.workNotes.title)) {
      data && data.workNotes && removeNote(propertyId, data.workNotes.recordId).then(
        () => {
          dispatch(getAllNotesByPropertyId(propertyId));
        }
      )
    } else {
      dispatch(getAllNotesByPropertyId(propertyId));
    }
    toggleDrawerOpen(false);
    setEditWorkData && setEditWorkData({data: initialNoteData});
    setFile([]);
  }

  const uploadImage = (file: any) => {
    if (!checkFile(file))
      return false;

    showStartFileUploadNotification();

    addAttachment({
      propertyId,
      parentType: "note",
      parentId: data && data.workNotes ? data.workNotes.recordId : null,
      file,
    }).then((res: any) => {
      dispatch(getAllAttachments(propertyId));
      dispatch(getAllNotesByPropertyId(propertyId));
      refreshNote();
    }).catch((err) => {
      let errorMsg = "";
      if (err && err.data && err.data.message) {
        errorMsg = err.data.message;
        showFailedFileUploadNotification(errorMsg);
      }});
    return false;
  }

  const confirmUnlinkImage = (imageId: Guid) => {
    Modal.confirm({
      title: "Would you like to remove this image from this Note?",
      content: "You will still be able to find this image in your Documents section.",
      okText: "Yes",
      cancelText: "No",
      className: "form-confirm-close-modal",
      onOk() {
        if (data && data.workNotes) {
          removeRelation(propertyId, data.workNotes.recordId, "note", imageId, "attachment").then(() => {
            dispatch(getAllNotesByPropertyId(propertyId));
            refreshNote();
          })
        }
      }
    })
  }

  const actionIconsDesktop = (imageId: Guid) => (
    <div className={"action-icons"}>
      <ImageDesktopView onClick={() => {
        setAttachmentId(imageId);
        toggleViewFileDrawer(true);
      }} />
      <ImageDesktopDownload onClick={() =>
        downloadAttachment({propertyId, id: imageId})
          .then((res) => window.open(res, "_blank"))}/>
      <ImageDesktopRemove onClick={() => confirmUnlinkImage(imageId)} />
    </div>
  )

  const actionIconsMobile = (imageId: Guid) => (
    <div className={"action-icons"}>
      {isEditMode && <ImageMobileRemove onClick={(e) => {
        e.stopPropagation();
        confirmUnlinkImage(imageId)
      }}/>}
    </div>
  )

  const showImageCarousel = () => {
    let imagesCarousel = [];
    if (data && data.workNotes) {
      imagesCarousel = noteLinks.filter((link: any) => link.thumbnailPath);
      imagesCarousel.sort((l1: any, l2: any) => l2.name.localeCompare(l1.name));
      // @ts-ignore
      imagesCarousel.sort((l1: any, l2: any) => moment(l2.uploadedDate).format("YYYYMMDD") - moment(l1.uploadedDate).format("YYYYMMDD"));
    }

    return <>
      {(isEditMode || imagesCarousel.length > 0) && <LinkedRecordsHeader className={"note-drawer"}>
          <div style={{fontWeight: 600, fontSize: "1rem"}}>
              Images
          </div>
        {!isEditMode && <UploadFile
              fileList={[]}
              refreshParent={() => {
                dispatch(getAllAttachments(propertyId));
                dispatch(getAllNotesByPropertyId(propertyId));
                refreshNote();
              }}
              singleAttachment={false}
              parentId={data && data.workNotes ? data.workNotes.recordId : null}
              parentType={"note"}
              propertyId={propertyId}
              uploadImmediately={false}
              isGlobalUpload={true}
              uploadOrigin={"add-image-to-note"}
          />}
      </LinkedRecordsHeader>}

      {isEditMode && <DraggerStyled
          customRequest={() => {}}
          multiple={false}
          accept={".png,.gif,.tiff,.jpg,.jpeg"}
          showUploadList={false}
          style={{background: "white", border: "none", marginTop: "1rem"}}
          beforeUpload={(file) => uploadImage(file)}
      >
          <div className={"drag-inner"}>
              <UploadIcon />
              <DraggerText>
                  Drag and drop image file here or choose
                  <span className={"link"}>file from your computer</span>
                  <span className={"small"}>(Accepted file types .jpg or .png)</span>
              </DraggerText>
          </div>
      </DraggerStyled>}

      {imagesCarousel.length > (window.innerWidth > 768 ? 3 : 2)
        ? <SliderStyled
            infinite={true}
            arrows={true}
            nextArrow={<div><ArrowRightGrayIcon /></div>}
            prevArrow={<ArrowLeftGrayIcon />}
            slidesToShow={window.innerWidth > 768 ? 3 : 2}
            slidesToScroll={1}
            className={"slider variable-width"}
          >
            {imagesCarousel.map((linkedImage:any) =>
              <CarouselImageWrapper>
                <div
                  className={"image-wrapper"}
                  style={{backgroundImage: `url(${linkedImage.thumbnailPath})`}}
                  onError={(e) => {
                    // @ts-ignore
                    e.target.onError = null;
                    // @ts-ignore
                    e.target.src = "/images/file.png"
                  }}
                  onClick={() => {
                    window.innerWidth <= 768 && setAttachmentId(linkedImage.attachmentId);
                    window.innerWidth <= 768 && toggleViewFileDrawer(true);
                  }}
                >
                  {window.innerWidth > 768 ? actionIconsDesktop(linkedImage.attachmentId) : actionIconsMobile(linkedImage.attachmentId)}
                </div>
              </CarouselImageWrapper>
            )}
          </SliderStyled>

        : <NoteImages>
          {imagesCarousel.map((linkedImage: any) =>
            <CarouselImageWrapper>
              <div
                className={"image-wrapper"}
                style={{backgroundImage: `url(${linkedImage.thumbnailPath})`}}
                onError={(e) => {
                  // @ts-ignore
                  e.target.onError = null;
                  // @ts-ignore
                  e.target.src = "/images/file.png"
                }}
                onClick={() => {
                  window.innerWidth <= 768 && setAttachmentId(linkedImage.attachmentId);
                  window.innerWidth <= 768 && toggleViewFileDrawer(true);
                }}
              >
                {window.innerWidth > 768 ? actionIconsDesktop(linkedImage.attachmentId) : actionIconsMobile(linkedImage.attachmentId)}
              </div>
            </CarouselImageWrapper>
          )}
            {isEditMode && <DraggerStyledSlide
                customRequest={() => {}}
                multiple={false}
                accept={".png,.gif,.tiff,.jpg,.jpeg"}
                showUploadList={false}
                beforeUpload={(file) => uploadImage(file)}
            >
                <div className={"add-image-slide"}>
                    <AddPlusIcon/>
                </div>
            </DraggerStyledSlide>}
        </NoteImages>
      }
    </>;
  }

  const showViewNote = () => {
    if (!noteLoaded)
      return <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return <>
      <NoteDescription>
        {window.innerWidth > 768 && <div className={"row-title"}>Note title</div>}
        {window.innerWidth > 768 && <div className={"row-description"}>{data && data.workNotes ? data.workNotes.title : null}</div>}
        {window.innerWidth <= 768 && <div className={"row-title"}>Property</div>}
        {window.innerWidth <= 768 && <div className={"row-description"} style={{color: "#9CA4AE"}}>{property}</div>}
        <div className={"row-title"}>Message</div>
        <div className={"row-description"}>{data && data.workNotes ? data.workNotes.message : null}</div>
      </NoteDescription>

      {showImageCarousel()}

      {(data && data.workNotes && noteLinks.length === 0) && <AddLinkedItem
          toggleLinkDrawerOpen={toggleLinkDrawerOpen}
          toggleNewRecordDrawer={toggleNewRecordDrawer}
          setPresetLinkType={setPresetLinkType}
          excludeTypes={["note"]}
      />}
      {(data && data.workNotes && noteLinks.length > 0) && <LinkedItemsHeader
          title={"Linked items"}
          toggleLinkDrawerOpen={toggleDrawerOpen}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          toggleAddNewRecordDrawer={toggleNewRecordDrawer}
          parentId={data.workNotes.recordId}
          parentType={"note"}
          refreshParent={refreshNote}
      />}
      {(data && data.workNotes && noteLinks.length > 0) && <LinkedItemsList
          parentId={data.workNotes.recordId}
          parentType={"note"}
          items={getItems()}
          searchQuery={searchQuery}
          isSelectionMode={false}
          toggleWorkDrawer={() => {}}
          setEditWorkData={() => {}}
          toggleExpenseDrawer={toggleExpenseDrawer}
          setEditExpenseData={setEditExpenseData}
          toggleInventoryDrawer={toggleInventoryDrawer}
          setEditInventoryData={setEditInventoryData}
          toggleContactDrawer={toggleContactDrawer}
          setEditContactData={setEditContactData}
          toggleNoteDrawer={() => {}}
          setEditNoteData={() => {}}
          refreshParent={refreshNote}
          setEditSpaceId={setEditSpaceId}
          toggleSpaceDrawer={toggleSpaceDrawer}
          toggleTaskDrawer={toggleTaskDrawer}
          setEditTaskData={setEditTaskData}
          setViewWorkId={setViewWorkId}
          toggleViewWorkDrawer={toggleViewWorkDrawer}
          setAttachmentId={setAttachmentId}
          toggleViewFileDrawer={toggleViewFileDrawer}
      />}
      {filteredItems().length > 10 && <PaginationStyled
          className={filteredItems().length < 10 ? "single-page-pagination" : ""}
          onChange={(page) => setPage(page)}
          simple={window.innerWidth < 1024}
          showSizeChanger={false}
          itemRender={paginationButtons}
          defaultCurrent={1}
          current={page}
          total={filteredItems().length}
          defaultPageSize={10}
      />}
    </>
  }

  const showEditForm = () =>
    <FormStyled
      form={form}
      name="manageNote"
      layout="vertical"
      size={"large"}
      // @ts-ignore
      onFinish={handleManageNote}
      initialValues={initialValues()}
      onFieldsChange={() => setFormFieldsChanged(true)}
    >
      <div>
        {window.innerWidth > 768 ? <>
          <Label>Note title *</Label>
          <Form.Item key={"title"} name={"title"} rules={[{
            required: true,
            message: "Note title is required",
          }]}>
            <Input
              autoFocus={true}
              style={{...selectStyle, height: "2.625rem"}}
              maxLength={98}
              placeholder={"Title"}
            />
          </Form.Item>
        </> : <div style={{display: "flex", alignItems: "center", gap: "0.5rem", width: "100%", marginBottom: "1rem"}}>
          <ReturnArrow style={{cursor: "pointer", flexShrink: 0, marginTop: "0.25rem"}} onClick={() => closeDrawer()} />
          <Form.Item key={"title"} name={"title"} style={{marginBottom: 0, width: "100%"}} rules={[{
            required: true,
            message: "Note title is required",
          }]}>
            <NoteTitle
              autoFocus={true}
              maxLength={98}
              placeholder={"Title"}
              suffix={<CancelIcon style={{cursor: "pointer", flexShrink: 0}} onClick={() => {
                data && data.workNotes && data.workNotes.title ? toggleEditMode(false) : closeDrawer()
              }} />}
            />
          </Form.Item>
        </div>}

        <Label>Message</Label>
        <Form.Item key={"message"} name={"message"}>
          <TextArea
            name={"message"}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={window.innerWidth > 768 ? 5 : 1}
            placeholder={"Input text..."}
            maxLength={900}
            style={{
              width: "100%",
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "6px",
              color: "rgb(107,113,133)",
            }}
          />
        </Form.Item>
      </div>

      {showImageCarousel()}

      {data && data.workNotes &&
        <>
          <LinkedRecordsHeader className={"note-drawer"} style={{margin:"1rem 0"}}>
            <div style={{fontWeight: 600, fontSize: "1rem"}}>
              Tags
            </div>
          </LinkedRecordsHeader>
          <TagContainer
            parentTags={data.workNotes.tags}
            parentId={data.workNotes.recordId}
            parentType={"note"}
            selectedTags={[]}
            toggleGlobalEditMode={() => {}}
            refreshParent={() => dispatch(getAllNotesByPropertyId(propertyId))}
          />
        </>}

      <DrawerFooter style={{marginTop: "1rem"}}>
        <ActionButtonGreen disabled={saveDisabled} htmlType={"submit"}>Save</ActionButtonGreen>
      </DrawerFooter>
    </FormStyled>

  return <>
    <AddNewRecordDrawer
      isOpen={isNewRecordDrawerOpen}
      toggleDrawerOpen={toggleNewRecordDrawer}
      parentId={data && data.workNotes ? data.workNotes.recordId : null}
      parentType={"note"}
      refreshParent={() => refreshNote()}
    />
    <ViewDrawerStyled
      push={{distance: "32px"}}
      closeIcon={null}
      visible={isOpen}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "100%" : "85%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      onClose={() => closeDrawer()}
      title={<>
        <DrawerHeader
          className={"header-desktop"}
          style={{flexDirection: "column", alignItems: "flex-start", rowGap: "0.5rem", marginBottom: "1.5rem"}}>
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%"
          }}>
            <div style={{display: "flex", alignItems: "center", columnGap: "1rem"}}>
              <ReturnIcon style={{cursor: "pointer"}} onClick={() => closeDrawer()}/>
              Notes
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
              {data && data.workNotes && data.workNotes.recordId && <EditIcon style={{cursor: "pointer"}} onClick={() => {
                toggleEditMode(true)
              }} />}
            </div>
          </div>
        </DrawerHeader>

        {!isEditMode && <DrawerHeader
          className={"task-header header-mobile"}
          style={{borderBottom: "1px solid #E7E7E7", paddingBottom: "1rem", marginBottom: "1rem"}}
        >
          <div style={{display: "flex", alignItems: "center", columnGap: "1.5rem", justifyContent: "space-between", width: "100%"}}>
            <div style={{display: "flex", alignItems: "center", gap: "1rem"}}>
              <ReturnArrow style={{cursor: "pointer", flexShrink: 0}} onClick={() => closeDrawer()} />
              {data && data.workNotes && <span className={"reminder-title"}>{data.workNotes.title}</span>}
            </div>
            <div style={{display: "flex", alignItems: "center", gap: "1rem"}}>
              <EditIcon onClick={() => toggleEditMode(true)} style={{cursor: "pointer"}}/>
              <CircleIconGreen style={{cursor: "pointer"}} onClick={() => {
                toggleLinkDrawerOpen(true)
              }}><LinkIcon /></CircleIconGreen>
            </div>
          </div>
        </DrawerHeader>}
      </>
      }
      footer={<DrawerFooter>
        {!isEditMode && data && data.workNotes && data.workNotes.recordId && (window.innerWidth > 768 ? <ActionButtonGreen onClick={() =>
          toggleLinkDrawerOpen(true)}><LinkIcon /> Link new</ActionButtonGreen> : <div
            style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <ActionButtonDarkGreenOutline onClick={() =>
            closeDrawer()}>Cancel</ActionButtonDarkGreenOutline>
          <ActionButtonDarkGreen onClick={() =>
            toggleLinkDrawerOpen(true)}><PlusOutlined /> Link</ActionButtonDarkGreen>
        </div>)}
      </DrawerFooter>}
    >
      {isEditMode && showEditForm()}
      {!isEditMode && showViewNote()}
    </ViewDrawerStyled>
  </>
};

const ViewDrawerStyled = styled(DrawerStyled)`
  // z-index: 1;
  .ant-drawer-body {
    margin: 0.5rem 0 1.5rem 0!important;
    overflow-x: hidden;
  }
`
const NoteDescription = styled(SpaceDescription)`
  font-size: 1rem;
  line-height: 1.5rem;

  @media (max-width: 768px) { 
    border: none;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
  }
`

const SliderStyled = styled(Slider)`
  margin: 1rem 0;

  .slick-next { 
    //width: 6.25rem; 
    height: 100%;
    right: 0;
    display: flex!important;
    align-items: center;
    justify-content: end;
    
    ::before {
      display: none;
    }    
    div {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const NoteImages = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;

  margin: 1rem 0;

  .ant-upload {
  }
`;

export const DraggerStyledSlide = styled(Dragger)`
  border: none!important;

  .ant-upload, .ant-upload-drag {
    padding: 0!important;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    width: 11.5rem;
    background: #F5F6FA;
    border: 1px solid #DAE0E6;
    border-radius: 4px;
  }
  
  .drag-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  
  .ant-upload-drag-container {
    height: 5rem;
  }
`

const NoteTitle = styled(SpaceTitle)`
  margin-top: 0.25rem;
  padding-left: 0.5rem;
  border: 1px solid rgba(41, 121, 255, 0.6);
  border-radius: 8px;
  box-shadow: none;
  height: 2.75rem;

  input {
    font-weight: 700;
    font-size: 1.625rem;
    line-height: 1.75rem;
    color: #21272B;
  }
  
  input::placeholder { color: #92ACA0; }
`

const CarouselImageWrapper = styled.div`
  position: relative;

  .action-icons {
    position: absolute;
    top: 0.25rem;
    right: 0.375rem;
    display: flex;
    gap: 0.375rem;
  }
  
  .image-wrapper {
    background-size: cover;
    width: 11.5rem;
    height: 5rem;
    border-radius: 4px;
    position: relative;
  }
  
  // &.carousel .action-icons { right: 0.875rem; }

  .action-icons svg { 
    opacity: 0;
    transition: all .25s ease;
  }
  
  &:hover .action-icons svg { 
    cursor: pointer;
    z-index: 50;
    opacity: 1;
  }
  
  @media (max-width: 768px) {
    .action-icons svg { opacity: 1; }
    .image-wrapper { width: 10rem; }
  }
`

export default NoteDrawer;
