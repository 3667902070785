import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TAddExpenseResponse} from "./addExpense";
import {TParseInvoiceResponse} from "./parseInvoice";

type TInvoiceParsingRequest = {
  invoiceDigitizerResponseDto: TParseInvoiceResponse,
  expenseDto: TAddExpenseResponse
}

export const saveInvoiceParsingResult = (payload: TInvoiceParsingRequest) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/invoice/parsing/data`
  );

  return apiPost<{}, TInvoiceParsingRequest>(url, payload);
};
