import React from "react";
import {
  ControlBox,
  ItemFrequency,
  ItemStartDate,
  ItemTitle,
  MaintenanceColumn,
  MaintenanceColumnMobile, MaintenanceItemWrapper,
  MaintenanceItemWrapperMobile,
  MaintenanceMobileBlockWrapper,
  MaintenanceRowMobile, MobileColumnTitle, ReadMoreText,
} from "../maintenances/MaintenanceListItemNew";
import {ReadMoreReadLess} from "../helper";
import {ReactComponent as ReadMoreArrow} from "../../../images/ReadMoreArrow.svg";
import {Dropdown, Menu, Modal} from "antd";
import {Overlay} from "../../screens/Home";
import moment from "moment";
import {ReactComponent as MaintenanceCalendar} from "../../../images/MaintenanceCalendar.svg";
import {ReactComponent as CrossIcon} from "../../../images/CrossIcon.svg";
import {removeWork} from "../../../api-wrapper/works/removeWork";
import {getAllWorks} from "../../actions/works";

import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {MenuStyled} from "./WorksListComp";
import {ReactComponent as MoreMenu} from "../../../images/More.svg";

type Props = {
  item: any;
  toggleWorkDrawer: (arg: boolean) => void;
  setEditingWorkData: (arg: any) => void;
}

const OnboardingWorkListItem = (props: Props) => {
  const {item, toggleWorkDrawer, setEditingWorkData} = props;
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const itemTitleAndDescription = (item: any) => (
    <div style={{display: "flex", flexDirection: "column", rowGap: "0.5rem"}}>
      <ItemTitle style={{color: item.recommended ? "#2A80E1" : "#45655E"}}>
        {item.name}
      </ItemTitle>
      <ReadMoreReadLess
        charLimit={45}
        readMoreText={<ReadMoreText>Read more <ReadMoreArrow style={{marginLeft: '0.3rem'}} /></ReadMoreText>}
        readLessText={<ReadMoreText>Hide all <ReadMoreArrow style={{marginLeft: '0.3rem', transform: 'scaleY(-1)'}} /></ReadMoreText>}>
        {item.notes !== null ? item.notes : ""}
      </ReadMoreReadLess>
    </div>
  )

  const listItemFrequency = (item: any) => <>
    <Dropdown placement="bottomCenter" overlay={<Overlay>
      <div>The recurrence or frequency of the project. If there is no recurrence, "Once" is displayed.</div>
      <div>If a project doesn't happen as you planned, you can always reschedule the task or delete it later.</div>
    </Overlay>}>
      <MobileColumnTitle>Recurring</MobileColumnTitle>
    </Dropdown>
    <ItemFrequency>
      {(!item.recurring || item.recurring === "None") ? "Once" : item.recurring}
    </ItemFrequency>
  </>

  const listItemDate = (item: any) => <>
    <Dropdown placement="bottomCenter" overlay={<Overlay>
      <div>The date by which the project is due.</div>
      <div>If a project doesn't happen as you planned, you can always reschedule the task or delete it later.</div>
    </Overlay>}>
      <MobileColumnTitle>Due Date</MobileColumnTitle>
    </Dropdown>
    <ItemStartDate>
      {moment(item.dueDate).format(propertyPreferences.preferredDateFormat)}
      <MaintenanceCalendar style={{flexShrink: 0}}/>
    </ItemStartDate>
  </>

  function confirmDelete(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        removeWork(item.propertyId, item.workId).then(() => {
          dispatch(getAllWorks(propertyId));
        });
      }
    });
  }

  const listItemRemove = (item: any) => <>
    <Dropdown placement="bottomCenter" overlay={<Overlay>
      <div>Remove a work from your list of upcoming works.</div>
    </Overlay>}>
      <MobileColumnTitle>Remove</MobileColumnTitle>
    </Dropdown>
    <ControlBox onClick={() => confirmDelete(item)}>
      <CrossIcon style={{flexShrink: 0}} />
    </ControlBox>
  </>

  const listItemEdit = () => <>
    <Dropdown placement="bottomCenter" overlay={<Overlay>
      <div>You can edit the project by clicking here. You can choose to save or cancel your changes.</div>
    </Overlay>}>
      <MobileColumnTitle>Edit</MobileColumnTitle>
    </Dropdown>
    <Dropdown overlay={moreMenu(item)} placement="bottomRight" trigger={["click"]}>
      <ControlBox onClick={(e) => e.stopPropagation()}>
        <MoreMenu />
      </ControlBox>
    </Dropdown>
  </>

  const editItem = (item: any) => {
    setEditingWorkData({data: item});
    toggleWorkDrawer(true);
  }

  const moreMenu = (item: any) => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      if (key === "edit") {
        editItem(item);
      } else if (key === "delete") {
        confirmDelete(item)
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="edit">Edit</Menu.Item>
        <Menu.Item key="delete">Delete</Menu.Item>
      </MenuStyled>
    );
  };

  return <div>
    {/*1300px and wider - regular*/}
    <MaintenanceItemWrapper
        className={"onboarding"}
        style={{cursor: "pointer"}}>
        <MaintenanceColumn spanWidth={"40%"} style={{
          flexDirection: "column",
          alignItems: "flex-start",
          paddingRight: "1rem"
        }}>
          {itemTitleAndDescription(item)}
        </MaintenanceColumn>
        <MaintenanceColumnMobile spanWidth={"16%"} onClick={() => editItem(item)} />
        <MaintenanceColumnMobile spanWidth={"8%"} />
        <MaintenanceColumnMobile spanWidth={"16%"} onClick={() => editItem(item)}>
          {listItemFrequency(item)}
        </MaintenanceColumnMobile>
        <MaintenanceColumnMobile spanWidth={"16%"} onClick={() => editItem(item)}>
          {listItemDate(item)}
        </MaintenanceColumnMobile>
        <MaintenanceColumnMobile spanWidth={"8%"}>
          {listItemEdit()}
        </MaintenanceColumnMobile>
        <MaintenanceColumnMobile spanWidth={"5%"}>
        {listItemRemove(item)}
      </MaintenanceColumnMobile>
    </MaintenanceItemWrapper>

    {/*less than 1300px - regular*/}
    <MaintenanceItemWrapperMobile style={{cursor: "pointer"}}>
      <MaintenanceMobileBlockWrapper spanWidth={"40%"} style={{rowGap: 0}}>
        {itemTitleAndDescription(item)}
      </MaintenanceMobileBlockWrapper>
      <MaintenanceMobileBlockWrapper spanWidth={"60%"}>
        <MaintenanceRowMobile>
          <MaintenanceColumnMobile spanWidth={"50%"} onClick={() => editItem(item)}>
            {listItemFrequency(item)}
          </MaintenanceColumnMobile>
          <MaintenanceColumnMobile spanWidth={"50%"} onClick={() => editItem(item)}>
            {listItemDate(item)}
          </MaintenanceColumnMobile>
        </MaintenanceRowMobile>
        <MaintenanceRowMobile>
          <MaintenanceColumnMobile spanWidth={"50%"} onClick={() => editItem(item)}>
            {listItemEdit()}
          </MaintenanceColumnMobile>
          <MaintenanceColumnMobile spanWidth={"50%"}>
            {listItemRemove(item)}
          </MaintenanceColumnMobile>
        </MaintenanceRowMobile>
      </MaintenanceMobileBlockWrapper>
    </MaintenanceItemWrapperMobile>
  </div>
}

export default OnboardingWorkListItem;