import {apiPut, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

type TPutDismissOnboardingRequest = {
  propertyId: Guid,
  dismissExpenses?: boolean | null;
  dismissHomeValue?: boolean | null;
  dismissSchedule?: boolean | null;
};

export const putDismissOnboarding = (payload: TPutDismissOnboardingRequest ) => {
  let requestParam = "";
  if (payload.dismissExpenses)
    requestParam = "dismissExpenses=true";
  if (payload.dismissHomeValue)
    requestParam = "dismissHomeValue=true";
  if (payload.dismissSchedule)
    requestParam = "dismissSchedule=true";
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${payload.propertyId}/dismissOnboarding?${requestParam}`);

  return apiPut(url, payload);
};
