import React, {useEffect, useState} from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar} from "react-chartjs-2";
import {useSelector} from "react-redux";
import {getExpensesSelector, getExpenseTypesSelector, getPropertyIdSelector} from "../../selectors";
import moment from "moment";
import {TUpcomingTask} from "../../../api-wrapper/upcomingTasks/getUpcomingTasks";
import {TFetchingStatus} from "../../../helpers";
import {cutOffThousand} from "../helper";
import _ from "lodash";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type Props = {
  periodToShow: any;
  categoryToShow: any;
  upcomingCosts: any;
  setCostTotals: (arg: any) => void;
}

export const expenseTypes = {
  improvement: "Improvement & Renovation",
  maintenance: "Maintenance & Repair",
  lifestyle: "Lifestyle",
  landlord: "Landlord"
}

const CostsChart = (props: Props) => {
  const {periodToShow, categoryToShow, upcomingCosts, setCostTotals} = props;
  const propertyId = useSelector(getPropertyIdSelector).value;
  const expenses = useSelector(getExpensesSelector);
  const databaseExpenseTypes = useSelector(getExpenseTypesSelector);

  const [upcomingCostsLocal, setUpcomingCostsLocal] = useState<any>([])
  const [parentExpenseTypes, setParentExpenseTypes] = useState<any>(new Map())

  const [chartData, setChartData] = useState<any>();
  const [chartDataReady, setChartDataReady] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  })

  useEffect(() => {
    if (databaseExpenseTypes.fetchingStatus !== TFetchingStatus.Success)
      return;

    if (parentExpenseTypes.length > 0)
      return;

    let localExpenseTypes: any = []
    Object.entries(databaseExpenseTypes.content).filter(type => localExpenseTypes.push(type[1]))

    let parentMap = new Map();
    Object.entries(databaseExpenseTypes.content).filter(type => type[1].parentId).forEach(type => {
      parentMap.set(type[1].id,localExpenseTypes.filter((parentType: any) => parentType.id === type[1].parentId)[0].name);
    });
    setParentExpenseTypes(parentMap)
  }, [databaseExpenseTypes])

  useEffect(() => {
    if (parentExpenseTypes.length === 0)
      return;
    if (expenses.fetchingStatus !== TFetchingStatus.Success)
      return;

    if (upcomingCosts) {
      const data = upcomingCosts.filter((task: TUpcomingTask) => (!_.isNil(task.task.estimatedCost) || !_.isNil(task.task.totalCost))
        && !_.isNil(task.date) && moment(task.date).isBefore(moment().add(Number.parseInt(periodToShow)+1, 'month'), 'month'))
        .filter((task:TUpcomingTask) => !task.task.expenseId || expenses.content.findIndex(exp => exp.expenseId === task.task.expenseId) < 0)
        .map((task:TUpcomingTask) => ({totalCost: !_.isNil(task.task.estimatedCost) ?
            task.task.estimatedCost : task.task.totalCost, paymentDate: task.date, type: !_.isNil(task.task.type) ?
            task.task.type : !_.isNil(task.task.expenseType) && !_.isNil(task.task.expenseType.parentExpenseType) ? task.task.expenseType.parentExpenseType.name : ""}));

      setUpcomingCostsLocal(data);
    }
  }, [propertyId, periodToShow, parentExpenseTypes, upcomingCosts, expenses])

  const quarters = {
    q1: ["Jan", "Feb", "Mar"],
    q2: ["Apr", "May", "Jun"],
    q3: ["Jul", "Aug", "Sep"],
    q4: ["Oct", "Nov", "Dec"]
  }

  const getInitialQuarter = (month: string) => {
    if (quarters.q1.includes(month)) return 1
    else if (quarters.q2.includes(month)) return 2
    else if (quarters.q3.includes(month)) return 3
    else return 4
  }

  const getLabels = () => {
    let labelArray = []

    let periodStart = moment().startOf("month").subtract(periodToShow - 1, "months")
    let periodEnd = moment().startOf("month").add(periodToShow, "months")

    while (periodStart.isSameOrBefore(periodEnd)) {
      if (periodStart.format("MMM") === "Jan") {
        labelArray.push(["JAN", periodStart.format("YYYY")]) // array is needed for line break in tick name
      } else {
        labelArray.push(periodStart.format("MMM").toUpperCase())
      }
      periodStart.add(1, "months")
    }

    return labelArray
  }

  const getQuarterLabels = () => {
    let periodStart = moment().startOf("month").subtract(periodToShow - 1, "months")
    let periodEnd = moment().startOf("month").add(periodToShow, "months")

    let labelArray = []
    let quarterCounter = getInitialQuarter(periodStart.format("MMM"))

    while (periodStart.isSameOrBefore(periodEnd)) {
      if (windowWidth > 1024) {
        labelArray.push(`Q${quarterCounter}'${periodStart.format("YY")}`)
      } else {
        labelArray.push([`Q${quarterCounter}`, periodStart.format("YY")])
      }

      if (quarterCounter === 4) { quarterCounter = 1 }
      else { quarterCounter++ }
      periodStart.add(3, "months")
    }

    return labelArray
  }

  const isPastExpenseMonthly = (date: any, isPastExpense: boolean) => {
    let endOfMonth = moment().endOf("month")
    return isPastExpense ? !moment(date).isAfter(endOfMonth) : moment(date).isAfter(endOfMonth)
  }

  const isPastExpenseQuarterly = (date: any, isPastExpense: boolean) => {
    let endOfQuarter = moment().endOf("quarter")
    return isPastExpense ? !moment(date).isAfter(endOfQuarter) : moment(date).isAfter(endOfQuarter)
  }

  const monthlyCheck = (date: any, periodStart: any) => {
    return moment(date).format("MM YYYY") === periodStart.format("MM YYYY")
  }

  const quarterlyCheck = (date: any, periodStart: any, quarter: number) => {
    return moment(date).format("YYYY") === periodStart.format("YYYY") &&
      // @ts-ignore
      quarters[`q${quarter}`].includes(moment(date).format("MMM"))
  }

  const parentIdCheck = (itemType: any, categoryType: string) => {
    return itemType === categoryType || parentExpenseTypes.get(itemType) === categoryType;
  }

  const getMonthlyDataFor = (isPastExpense: boolean, type: string) => {
    let dataArray: any = []

    let periodStart = moment().startOf("month").subtract(periodToShow - 1, "months")
    let periodEnd = moment().startOf("month").add(periodToShow, "months")

    while (periodStart.isSameOrBefore(periodEnd)) {
      let currentMonthExpenses = expenses.content.filter(expense => expense.isConfirmed &&
        monthlyCheck(expense.paymentDate, periodStart) &&
        isPastExpenseMonthly(expense.paymentDate, isPastExpense) &&
        parentIdCheck(expense.type, type)
      )

      let currentMonthExpensesPrice = currentMonthExpenses.reduce(
        (sumOfExpenses, { totalCost }) => sumOfExpenses + totalCost, 0)

      let currentMonthTaskExpenses = upcomingCostsLocal.filter((expense: any) => expense.totalCost &&
        isPastExpenseMonthly(expense.paymentDate, isPastExpense) && monthlyCheck(expense.paymentDate, periodStart) &&
        parentIdCheck(expense.type, type)
    )

      let currentMonthTaskExpensesPrice = currentMonthTaskExpenses.reduce(
        // @ts-ignore
        (sumOfExpenses, { totalCost }) => sumOfExpenses + totalCost, 0)

      dataArray.push(currentMonthExpensesPrice + currentMonthTaskExpensesPrice)
      periodStart.add(1, "months")
    }
    return dataArray
  }

  const getMonthlyDataForUnconfirmed = (isPastExpense: boolean) => {
    let dataArray: any = []

    let periodStart = moment().startOf("month").subtract(periodToShow - 1, "months")
    let periodEnd = moment().startOf("month").add(periodToShow, "months")

    while (periodStart.isSameOrBefore(periodEnd)) {
      let currentMonthExpenses = expenses.content.filter(expense => !expense.isConfirmed &&
        monthlyCheck(expense.paymentDate, periodStart) &&
        isPastExpenseMonthly(expense.paymentDate, isPastExpense))

      let currentMonthExpensesPrice = currentMonthExpenses.reduce(
        (sumOfExpenses, { totalCost }) => sumOfExpenses + totalCost, 0)
      let currentMonthUpcomingTasksPrice = 0

      dataArray.push(currentMonthExpensesPrice + currentMonthUpcomingTasksPrice)
      periodStart.add(1, "months")
    }
    return dataArray
  }

  const getQuarterlyDataFor = (isPastExpense: boolean, type: string) => {
    let periodStart = moment().startOf("month").subtract(periodToShow - 1, "months")
    let periodEnd = moment().startOf("month").add(periodToShow, "months")

    let dataArray: any = []
    let quarterCounter = getInitialQuarter(periodStart.format("MMM"))

    while (periodStart.isSameOrBefore(periodEnd)) {
      let currentQuarterExpenses = expenses.content.filter(expense => expense.isConfirmed &&
        quarterlyCheck(expense.paymentDate, periodStart, quarterCounter) &&
        isPastExpenseQuarterly(expense.paymentDate, isPastExpense) &&
        parentIdCheck(expense.type, type)
      )

      let currentQuarterExpensesPrice = currentQuarterExpenses.reduce(
        (sumOfExpenses, { totalCost }) => sumOfExpenses + totalCost, 0)

      let currentQuarterTaskExpenses = upcomingCostsLocal.filter((expense: any) => expense.totalCost &&
        isPastExpenseQuarterly(expense.paymentDate, isPastExpense) &&
        quarterlyCheck(expense.paymentDate, periodStart, quarterCounter) &&
        parentIdCheck(expense.type, type)
      )

      let currentQuarterTaskExpensesPrice = currentQuarterTaskExpenses.reduce(
        // @ts-ignore
        (sumOfExpenses, { totalCost }) => sumOfExpenses + totalCost, 0)

      dataArray.push(currentQuarterExpensesPrice + currentQuarterTaskExpensesPrice)

      if (quarterCounter === 4) { quarterCounter = 1 }
      else { quarterCounter++ }
      periodStart.add(3, "months")
    }
    return dataArray
  }

  const getQuarterlyDataForUnconfirmed = (isPastExpense: boolean) => {
    let periodStart = moment().startOf("month").subtract(periodToShow - 1, "months")
    let periodEnd = moment().startOf("month").add(periodToShow, "months")

    let dataArray: any = []
    let quarterCounter = getInitialQuarter(periodStart.format("MMM"))

    while (periodStart.isSameOrBefore(periodEnd)) {
      let currentMonthExpenses = expenses.content.filter(expense => !expense.isConfirmed &&
        quarterlyCheck(expense.paymentDate, periodStart, quarterCounter) &&
        isPastExpenseQuarterly(expense.paymentDate, isPastExpense)
      )

      let currentMonthExpensesPrice = currentMonthExpenses.reduce(
        (sumOfExpenses, { totalCost }) => sumOfExpenses + totalCost, 0)
      let currentMonthUpcomingTasksPrice = 0

      dataArray.push(currentMonthExpensesPrice + currentMonthUpcomingTasksPrice)
      if (quarterCounter === 4) { quarterCounter = 1 }
      else { quarterCounter++ }
      periodStart.add(3, "months")
    }
    return dataArray
  }

  useEffect(() => {
    if (parentExpenseTypes.length === 0) {
      return;
    }
    else {
      let pastMaintenances, pastImprovements, pastLifestyle, pastLandlord, pastUnconfirmed, futureImprovements, futureMaintenances, futureUnconfirmed, futureLifestyle, futureLandlord;
      if (periodToShow > 12 || windowWidth < 1024 && periodToShow > 6) {
        pastMaintenances = (categoryToShow === "All" || categoryToShow === expenseTypes.maintenance) ?
          getQuarterlyDataFor(true, expenseTypes.maintenance) : [];
        pastImprovements = (categoryToShow === "All" || categoryToShow === expenseTypes.improvement) ?
          getQuarterlyDataFor(true, expenseTypes.improvement) : [];
        pastLifestyle = (categoryToShow === "All" || categoryToShow === expenseTypes.lifestyle) ?
          getQuarterlyDataFor(true, expenseTypes.lifestyle) : [];
        pastLandlord = (categoryToShow === "All" || categoryToShow === expenseTypes.landlord) ?
          getQuarterlyDataFor(true, expenseTypes.landlord) : [];

        futureMaintenances = (categoryToShow === "All" || categoryToShow === expenseTypes.maintenance) ?
          getQuarterlyDataFor(false, expenseTypes.maintenance) : [];
        futureImprovements = (categoryToShow === "All" || categoryToShow === expenseTypes.improvement) ?
          getQuarterlyDataFor(false, expenseTypes.improvement): [];
        futureLifestyle = (categoryToShow === "All" || categoryToShow === expenseTypes.lifestyle) ?
          getQuarterlyDataFor(false, expenseTypes.lifestyle) : [];
        futureLandlord = (categoryToShow === "All" || categoryToShow === expenseTypes.landlord) ?
          getQuarterlyDataFor(false, expenseTypes.landlord): [];

        pastUnconfirmed = (categoryToShow === "All") ? getQuarterlyDataForUnconfirmed(true) : [];
        futureUnconfirmed = (categoryToShow === "All") ? getQuarterlyDataForUnconfirmed(false) : [];
      } else {
        pastMaintenances = (categoryToShow === "All" || categoryToShow === expenseTypes.maintenance) ?
          getMonthlyDataFor(true, expenseTypes.maintenance) : [];
        pastImprovements = (categoryToShow === "All" || categoryToShow === expenseTypes.improvement) ?
          getMonthlyDataFor(true, expenseTypes.improvement) : [];
        pastLifestyle = (categoryToShow === "All" || categoryToShow === expenseTypes.lifestyle) ?
          getMonthlyDataFor(true, expenseTypes.lifestyle) : [];
        pastLandlord = (categoryToShow === "All" || categoryToShow === expenseTypes.landlord) ?
          getMonthlyDataFor(true, expenseTypes.landlord) : [];

        futureMaintenances = (categoryToShow === "All" || categoryToShow === expenseTypes.maintenance) ?
          getMonthlyDataFor(false, expenseTypes.maintenance) : [];
        futureImprovements = (categoryToShow === "All" || categoryToShow === expenseTypes.improvement) ?
          getMonthlyDataFor(false, expenseTypes.improvement) : [];
        futureLifestyle = (categoryToShow === "All" || categoryToShow === expenseTypes.lifestyle) ?
          getMonthlyDataFor(false, expenseTypes.lifestyle) : [];
        futureLandlord = (categoryToShow === "All" || categoryToShow === expenseTypes.landlord) ?
          getMonthlyDataFor(false, expenseTypes.landlord) : [];

        pastUnconfirmed = (categoryToShow === "All") ? getMonthlyDataForUnconfirmed(true) : [];
        futureUnconfirmed = (categoryToShow === "All") ? getMonthlyDataForUnconfirmed(false) : [];
      }

      setChartData({
        labels: (periodToShow > 12 || (windowWidth < 1024 && periodToShow > 6)) ? getQuarterLabels() : getLabels(),
        datasets: [{
          label: 'Past Improvement',
          backgroundColor: '#5599E7',
          data: pastImprovements
        },
          {
            label: 'Past Maintenance',
            backgroundColor: '#86CEA2',
            data: pastMaintenances
          },
          {
            label: 'Past Lifestyle',
            backgroundColor: '#ED90CD',
            data: pastLifestyle
          },
          {
            label: 'Past Landlord',
            backgroundColor: '#FECE57',
            data: pastLandlord
          },
          {
            label: 'Past Unconfirmed',
            backgroundColor: '#F28172',
            data: pastUnconfirmed
          },
          {
            label: 'Present Improvement',
            backgroundColor: '#88b7ee',
            data: futureImprovements
          },
          {
            label: 'Present Maintenance',
            backgroundColor: '#aaddbe',
            data: futureMaintenances
          },
          {
            label: 'Present Unconfirmed',
            backgroundColor: '#F28172',
            data: futureUnconfirmed
          },
          {
            label: 'Present Lifestyle',
            backgroundColor: '#f2b1dc',
            data: futureLifestyle
          },
          {
            label: 'Present Landlord',
            backgroundColor: '#fedd89',
            data: futureLandlord
          }
        ]
      });
      setChartDataReady(true);

      setCostTotals({
        improvements: pastImprovements.reduce((total: number, current: number, ) => total + current, 0) +
          futureImprovements.reduce((total: number, current: number, ) => total + current, 0),
        maintenances: pastMaintenances.reduce((total: number, current: number, ) => total + current, 0) +
          futureMaintenances.reduce((total: number, current: number, ) => total + current, 0),
        lifestyle: pastLifestyle.reduce((total: number, current: number, ) => total + current, 0) +
          futureLifestyle.reduce((total: number, current: number, ) => total + current, 0),
        landlord: pastLandlord.reduce((total: number, current: number, ) => total + current, 0) +
          futureLandlord.reduce((total: number, current: number, ) => total + current, 0),
        unconfirmed: pastUnconfirmed.reduce((total: number, current: number, ) => total + current, 0) +
          futureUnconfirmed.reduce((total: number, current: number, ) => total + current, 0)
      })
    }
  }, [parentExpenseTypes, upcomingCostsLocal, periodToShow, categoryToShow, expenses, windowWidth])

  ChartJS.defaults.font.size = 10;
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins:{
      legend: {
        display: false
        }
    },
    animation: {
      duration: 0
    },
    scales: {
      x: {
        stacked: true,
        grid: { display: false },
        ticks: {
          color: "#45655E",
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0
        }
      },
      y: {
        stacked: true,
        grid: {
          color: (line: any) => (line.index === 0 ? 'rgba(0, 0, 0, 0.0)' : 'rgba(0, 0, 0, 0.1)'),
          borderDash: [4, 4],
        },
        ticks: {
          color: "#45655E",
          callback: function(value: any) {
            return cutOffThousand(value)
          }
        }
      },
    },
  };

  if (chartDataReady)
    return <div style={{width: "100%", height: "100%"}}>
      <Bar options={options} data={chartData}/>
    </div>
  else
    return <Spin indicator={<LoadingOutlined style={{fontSize: 72}} spin/>}/>

}

export default CostsChart