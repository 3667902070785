import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TAddInsuranceResponse = {
  propertyId: Guid;
  id: Guid;
  subscriptionId: number;
  startDate: string;
  effectivePeriod: string;
  insuranceType: string;
  insurer: string;
  policyNumber: string;
  premium: number;
  insurancePremiumTax: number;
  administrationFee: number;
  discountReceived: string;
  totalAmountPaid: number;
  valueOfInsuredItems: number;
};

export type TAddInsuranceRequest = {
  propertyId: Guid;
  startDate: string;
  effectivePeriod: string;
  insuranceType: string;
  insurer: string;
  policyNumber: string;
  premium: number;
  insurancePremiumTax: number;
  administrationFee: number;
  discountReceived: string;
  totalAmountPaid: number;
  valueOfInsuredItems: number;
};

export const addInsurance = (payload: TAddInsuranceRequest & { propertyId: Guid }) => {
  const { propertyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/insurance`
  );

  return apiPost<TAddInsuranceResponse, TAddInsuranceRequest>(url, payload);
};
