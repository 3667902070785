import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TTag} from "../tags/addNewTag";

export type TGetPetsResponse = Array<{
  propertyId: Guid,
  name: string,
  petType: string,
  petId: Guid,
  subscriptionId: number,
  createdDate: string,
  imageThumbnail: string,
  tags: Array<TTag>
}>;

export const getPets = () => {
  const url = createUrl(`${EnvConfig.API_URL}/api/pets`);

  return apiGet<TGetPetsResponse | []>(url, {});
};