import React, {useEffect, useState} from "react";
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import envConfig from "./config";
import {Button, Layout, Modal} from "antd";
import styled from "styled-components";
import "antd/dist/antd.css";
import "ant-design-pro/dist/ant-design-pro.css";
import { Footer } from "antd/es/layout/layout";
import { InfoCircleOutlined } from "@ant-design/icons";
import SideBar from "./app/components/navigation/MenuDesktop";
import LoginPage from "./app/screens/LoginPage";
import { PrivateRoute } from "./app/helpers/PrivateRoute";
import HeaderNav from "./app/components/navigation/Header";
import Terms from "./app/screens/Terms";
import Works from "./app/screens/Works";
import Costs from "./app/screens/Costs";
import Contents from "./app/screens/Contents";
import MyProperty from "./app/screens/MyProperty";
import Contacts from "./app/screens/Contacts";
import Settings from "./app/screens/Settings";
import PasswordReset from "./app/screens/PasswordReset";
import PasswordUpdate from "./app/screens/PasswordUpdate";
import Todo from "./app/screens/Todo";
import SearchPage from "./app/screens/SearchPage";
import IdleTimer from "./IdleTimer";
import { refreshToken, saveAccessToken } from "./api-wrapper/auth/auth";
import { useDispatch, useSelector } from "react-redux";
import {authSelector, getUserProfileSelector} from "./app/selectors";
import {logInSuccessAction, signOut} from "./app/actions/logIn";
import Notes from "./app/screens/Notes";
import Upkeep from "./app/screens/Upkeep";
import ImproveMyProperty from "./app/screens/unused/ImproveMyProperty";
import Files from "./app/screens/Files";
import ActivationPage from "./app/screens/ActivationPage";
import Questions from "./app/screens/Questions";
import PrivacyPolicy from "./app/screens/PrivacyPolicy";
import {getCurrentUserProfile} from "./app/actions/getUserProfile";
import {setMarketingCookies} from "./localStorage";
import ConnectAccounts from "./app/screens/ConnectAccounts";
import Tags from "./app/screens/Tags";
import Dashboard from "./app/screens/Dashboard";
import News from "./app/screens/News";
import ValueSimulator from "./app/screens/ValueSimulator";
import SimpleRegistration from "./app/screens/SimpleRegistration";
import Guidance from "./app/screens/Guidance";
import Spaces from "./app/screens/Spaces";
import Records from "./app/screens/Records";
import Home from "./app/screens/Home";
import Partners from "./app/screens/Partners";
import PublicOnboarding from "./app/screens/PublicOnboarding";
import Planner from "./app/screens/Planner";
import KeytekOnboarding from "./app/screens/KeytekOnboarding";
import {useCookies} from "react-cookie";
import {getPropertiesResetAction} from "./app/actions/properties";
import {getPropertyIdInitAction} from "./app/actions/propertyId";
import {push} from "react-router-redux";
import decodeJwt from "jwt-decode";
import {getPropertyPreferencesInitAction} from "./app/actions/propertyPreferences";
import {PublicRoute} from "./app/helpers/PublicRoute";

const { Content } = Layout;

const FORCE_LOGOUT_SECONDS = 30 * 60;
const REFRESH_TOKEN_INTERVAL_SECONDS = 10 * 60;

const MainLayout = styled(Layout)`
  min-height: 100vh;
  @media (min-width: 1024px) {
    min-width: 750px;
  }
`;

function App(props: RouteProps) {
  const { location } = props;
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const userProfile = useSelector(getUserProfileSelector);

  const [cookies, setCookie, removeCookie] = useCookies(['livlet-token']);

  const [isSignOutModalOpen, toggleSignOutModal] = useState(false)

  const [isMapLoaded, toggleMapLoaded] = useState(false);
  const [isMapRequested, toggleMapRequested] = useState(false);

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: FORCE_LOGOUT_SECONDS, //expire after 30 minutes
      onTimeout: () => {
        dispatch(signOut());
        toggleSignOutModal(true);
      },
      onExpired: () => {
        dispatch(signOut());
        toggleSignOutModal(true);
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, [auth]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (auth.accessToken) {
        refreshToken()
          .then((payload) => {
            saveAccessToken(payload.accessToken);
          })
          .catch(() => {
            dispatch(signOut());
          });
      }
    }, REFRESH_TOKEN_INTERVAL_SECONDS * 1000);
    return () => clearInterval(interval);
  }, [auth]);

  useEffect(() => {
    const getCookie = (cname: string) => {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    const livletTokenCookie = getCookie('livlet-token');

    if (livletTokenCookie) {
      const accessToken = JSON.parse(livletTokenCookie);
      removeCookie('livlet-token');
      saveAccessToken(accessToken.accessToken);
      dispatch(getPropertiesResetAction());
      dispatch(getPropertyIdInitAction());
      dispatch(getPropertyPreferencesInitAction());
      dispatch(push("/home"));
      dispatch(
        logInSuccessAction({...decodeJwt(accessToken.accessToken), ...accessToken})
      );
    }
  }, []);

  const loadScript = (url: string) => {
    let script = document.createElement("script");
    script.id = "googleMaps";
    script.type = "text/javascript";
    script.src = url;

    document.getElementsByTagName("head")[0].appendChild(script);
  };

  useEffect(() => {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    location && window.dataLayer.push({
      "pageview": location.pathname
    });

      if (!isMapRequested) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&language=en-GB`
        );
        toggleMapRequested(true);
        const mapLoadStatus = () => {
          // @ts-ignore
          if (typeof window.google != 'undefined') {
            toggleMapLoaded(true);
          } else {
            setTimeout(function () {
              mapLoadStatus();
            }, 500);
          }
        }
        mapLoadStatus();
      }
  }, [dispatch, location, isMapRequested]);

  useEffect(() => {
    document.title = "Livlet";
  });

  useEffect(() => {
    dispatch(getCurrentUserProfile)
  }, []);

  useEffect(() => {
    userProfile && userProfile.content && userProfile.content.marketingCookies
      && setMarketingCookies(userProfile.content.marketingCookies.toString())
  }, [userProfile])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <MainLayout>
      <Modal
        className={"form-confirm-close-modal dismiss-modal"}
        visible={isSignOutModalOpen}
        closable={false}
        closeIcon={<></>}
        width={416}
        footer={<Button
          onClick={() => toggleSignOutModal(false)}
          className={"ant-btn-primary"}>OK</Button>}
      >
        <div>
          <InfoCircleOutlined className={"info-icon"} />
        </div>
        <div style={{marginLeft: "1rem"}}>
          <p>Please Sign in again</p>
          <p style={{fontSize: "0.875rem", fontWeight: 400}}>
            For privacy and safety your previous session was closed after 30 minutes of inactivity. Please sign in again.
          </p>
        </div>
      </Modal>
      {location &&
      (location.pathname === "/login" ||
        location.pathname === "/register" ||
        location.pathname === "/user/activate" ||
        location.pathname === "/reset/password" ||
        location.pathname === "/reset/password/reset" ||
        location.pathname === "/login/connect" ||
        location.pathname === "/questions" ||
        location.pathname === "/terms" ||
        location.pathname === "/onboarding" ||
        location.pathname === "/keytek" ||
        location.pathname === "/privacypolicy") ? null : (
        <SideBar />
      )}
      <Layout>
        {location &&
        (location.pathname === "/login" ||
          location.pathname === "/register" ||
          location.pathname === "/user/activate" ||
          location.pathname === "/reset/password" ||
          location.pathname === "/reset/password/reset" ||
          location.pathname === "/login/connect" ||
          location.pathname === "/onboarding" ||
          location.pathname === "/terms" ||
          location.pathname === "/keytek" ||
          location.pathname === "/privacypolicy") ? null : (
          <HeaderNav />
        )}

        <GoogleReCaptchaProvider reCaptchaKey={envConfig.GOOGLE_RECAPTCHA_SITE_KEY}>
        <ContentStyled>
          <Switch>
            <PublicRoute exact path="/login" render={() => <LoginPage/>} component={null}/>
            <Route exact path="/register" render={() => <SimpleRegistration/>} />
            <Route exact path="/reset/password" component={PasswordReset} />
            <Route exact path="/reset/password/reset" component={PasswordUpdate}/>
            <Route exact path="/login/connect" component={ConnectAccounts} />
            <Route exact path="/user/activate" component={ActivationPage} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacypolicy" component={PrivacyPolicy} />
            <Route exact path="/onboarding" render={() => <PublicOnboarding isMapLoaded={isMapLoaded} />} />
            <Route exact path="/keytek" render={() => <KeytekOnboarding isMapLoaded={isMapLoaded} />} />
            <PrivateRoute exact path="/projects" component={Works} />
            <PrivateRoute exact path="/properties/:id" component={MyProperty} />
            <PrivateRoute exact path="/properties" component={MyProperty} />
            <PrivateRoute exact path="/expenses" component={Costs} />
            <PrivateRoute exact path="/contents" component={Contents} />
            <PrivateRoute exact path="/value-simulator" component={ValueSimulator} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/notes" component={Notes} />
            <PrivateRoute exact path="/contacts" component={Contacts} />
            <PrivateRoute exact path="/upkeep" component={Upkeep} />
            <PrivateRoute exact path="/search" component={SearchPage} />
            <PrivateRoute path="/improvements" component={ImproveMyProperty} />
            <PrivateRoute path="/guidance" component={Guidance} />
            <PrivateRoute exact path="/to-do" component={Todo} />
            <PrivateRoute exact path="/documents" component={Files} />
            <PrivateRoute exact path="/settings" component={Settings} />
            <PrivateRoute exact path="/questions" component={Questions} />
            <PrivateRoute exact path="/news" component={News} />
            <PrivateRoute exact path="/tags" component={Tags} />
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/spaces" component={Spaces} />
            <PrivateRoute exact path="/records" component={Records} />
            {/*<PrivateRoute exact path="/partners" component={Partners} />*/}
            <PrivateRoute exact path="/planner" component={Planner} />
            <Redirect from="*" to="/home" />
          </Switch>
        </ContentStyled>
        </GoogleReCaptchaProvider>{" "}
        {location &&
        (location.pathname === "/login" ||
          location.pathname === "/register" ||
          location.pathname === "/activate" ||
          location.pathname === "/questions" ||
          location.pathname === "/terms" ||
          location.pathname === "/privacypolicy") ? null : (
          <FooterStyled />
        )}
      </Layout>
    </MainLayout>
  );
}

const FooterStyled = styled(Footer)`
  background-color: #F0F2F5;
`;

const ContentStyled = styled(Content)`
  background-color: #F0F2F5;
`;

export default withRouter(App);
