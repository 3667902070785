import React, {useEffect, useMemo, useState} from "react";
import {
  ActionButtonGreen,
  CheckboxStyled,
  DrawerFooter,
  DrawerHeader,
  DrawerStyled,
  LinkedItemsContainer, LoadingWrapper,
  paginationButtons,
  PaginationStyled,
  ViewDrawerTabs
} from "../../screens/components";
import {ReactComponent as ReturnIcon} from "../../../images/spaces/ReturnIcon.svg";
import {ReactComponent as EditIcon} from "../../../images/spaces/EditIcon.svg";
import {SpaceDescription} from "../spaces/ViewSpaceDrawer";
import moment from "moment";
import styled from "styled-components";
import ForwardUpcomingTaskDrawer from "../upcomingTasks/ForwardUpcomingTaskDrawer";
import CompletionModal from "../upcomingTasks/CompletionModal";
import DismissModal from "../upcomingTasks/DismissModal";
import {confirmDismissSingle, handleSnooze, markReminderAsComplete} from "../upcomingTasks/UpcomingTaskActions";
import {Modal, Spin} from "antd";
import RescheduleModal from "../upcomingTasks/RescheduleModal";
import {getDrawerIcon, getDrawerTitle} from "./UpdateTaskDrawer";
import {ReactComponent as Checkmark} from "../../../images/planner/Checkmark.svg";
import {ReactComponent as AddToCalendarIcon} from "../../../images/planner/AddToCalendarIcon.svg";
import {ReactComponent as CalendarDismiss} from "../../../images/dashboard-calendar/CalendarDismiss.svg";
import {ReactComponent as CalendarSnooze} from "../../../images/dashboard-calendar/CalendarSnooze.svg";
import {ReactComponent as CalendarReschedule} from "../../../images/dashboard-calendar/CalendarReschedule.svg";
import {ReactComponent as CalendarForward} from "../../../images/dashboard-calendar/CalendarForward.svg";
import {ReactComponent as FindTradespersonIcon} from "../../../images/dashboard-calendar/FindTradespersonIcon.svg";
import TradeDrawer from "../partners/TradeDrawer";
import {useSelector} from "react-redux";
import {
  authSelector,
  getMaintenancesSelector,
  getPropertiesSelector,
  getPropertyIdSelector, getPropertyPreferencesSelector,
  getUserProfileSelector
} from "../../selectors";
import ICalendarLink from "react-icalendar-link";
import {CALENDAR_FREQUENCY} from "../upcomingTasksCalendar/types";
import {ReactComponent as ReturnArrow} from "../../../images/spaces/ReturnArrow.svg";
import {ReadMoreReadLess} from "../helper";
import {ReadMoreText} from "../maintenances/MaintenanceListItemNew";
import AddLinkedItem from "../spaces/AddLinkedItem";
import LinkedItemsList from "../spaces/LinkedItemsList";
import LinkedItemsHeader from "../spaces/LinkedItemsHeader";
import AddNewRecordDrawer from "../spaces/AddNewRecordDrawer";
import {Guid} from "guid-typescript";
import {getUpcomingTaskById} from "../../../api-wrapper/upcomingTasks/getUpcomingTaskById";
import {ReactComponent as LinkIcon} from "../../../images/spaces/LinkNewIcon.svg";
import {getCurrencySign} from "../../screens/helpers";
import InsuranceDealDrawer from "../partners/InsuranceDealDrawer";
import MortgagePartnerDrawer from "../partners/MortgagePartnerDrawer";
import {LoadingOutlined} from "@ant-design/icons";
import InsulationDrawer from "../partners/InsulationDrawer";

type Props = {
  task: any;
  setTask: (arg: any) => void;
  taskType: string;
  isOpen: boolean;
  toggleDrawer: (arg: boolean) => void;
  setTaskToEdit: (arg: any) => void;
  setTaskToEditType: (arg: string) => void;
  toggleUpdateDrawer: (arg: boolean) => void;
  refreshParent: () => void;

  toggleViewWorkDrawer: (arg: boolean) => void;
  setViewWorkId: (arg: any) => void;
  toggleWorkDrawer: (arg: boolean) => void;
  setEditWorkData: (arg: any) => void;
  toggleExpenseDrawer: (arg: boolean) => void;
  setEditExpenseData: (arg: any) => void;
  toggleInventoryDrawer: (arg: boolean) => void;
  setEditInventoryData: (arg: any) => void;
  toggleContactDrawer: (arg: boolean) => void;
  setEditContactData: (arg: any) => void;
  toggleNoteDrawer: (arg: boolean) => void;
  setEditNoteData: (arg: any) => void;
  toggleSpaceDrawer: (arg: boolean) => void;
  setEditSpaceId: (arg: any) => void;

  setLinkedTaskId: (arg: Guid) => void;
  setLinkedTaskType: (arg: string) => void;
  toggleLinkDrawerOpen: (arg: boolean) => void;
  setPresetLinkType: (arg: string) => void;
  setAttachmentId: (arg: any) => void;
  toggleViewFileDrawer: (arg: boolean) => void;
}

export const isRecurringTask = (task: any) => {
  return !(task.actualPeriodicityUnit === "once" || !task.actualPeriodicityUnit && task.periodicityUnit === "once")
}

export const getTradingOption = (taskName: string, subscriptionLevel: string) => {
  const findTradesperson = ["Clean jacuzzi & hot tub", "Re-seal natural stone surfaces", "Replace silicon sealant (Recaulking)", "Roof survey",
    "Air conditioning system service", "Bleed radiators", "Gas boiler service", "Gas safety record", "Heating system power flush",
    "Hot water cylinder service", "Leak detection device maintenance", "Oil boiler service", "Replace water conditioner",
    "Water conditioner service", "Clean kitchen extractor ductwork"]
  const solarTradesperson = ["Solar panel service"]
  const findBroker = ["Buildings & Contents Insurance", "Buildings Insurance", "Contents Insurance", "Insurance"]
  const mortgageAdviser = ["Mortgage"]

  if (findTradesperson.includes(taskName)) return "Find a tradesperson"
  else if ((subscriptionLevel === null || !subscriptionLevel.includes("mcl")) && findBroker.includes(taskName)) return "Find a broker"
  else if (solarTradesperson.includes(taskName)) return "Find a provider"
  else if ((subscriptionLevel === null || !subscriptionLevel.includes("mcl")) && mortgageAdviser.includes(taskName)) return "Mortgage adviser"
  else return null
}

const ViewTaskDrawer = (props: Props) => {
  const {task, setTask, taskType, isOpen, toggleDrawer, setTaskToEdit, setTaskToEditType,
    toggleWorkDrawer, setEditWorkData, toggleExpenseDrawer,
    setEditExpenseData, toggleNoteDrawer, setEditNoteData, toggleContactDrawer, setEditContactData, toggleInventoryDrawer,
    setEditInventoryData, toggleSpaceDrawer, setEditSpaceId, setLinkedTaskType, setViewWorkId, toggleViewWorkDrawer,
    toggleUpdateDrawer, setLinkedTaskId, setPresetLinkType, toggleLinkDrawerOpen, refreshParent, setAttachmentId, toggleViewFileDrawer} = props;

  const propertyId = useSelector(getPropertyIdSelector).value;
  const auth = useSelector(authSelector);
  const maintenances = useSelector(getMaintenancesSelector);
  const { content: user } = useSelector(getUserProfileSelector);
  const properties = useSelector(getPropertiesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [isCompletionModalOpen, toggleCompletionModal] = useState(false)
  const [isRescheduleModalOpen, toggleRescheduleModal] = useState(false)
  const [isForwardReminderDrawerOpen, toggleForwardReminderDrawer] = useState(false)
  const [isDismissModalOpen, toggleDismissModal] = useState(false)
  const [isTradeDrawerOpen, toggleTradeDrawer] = useState(false);
  const [isInsuranceDrawerOpen, toggleInsuranceDrawer] = useState(false);
  const [isMortgageDrawerOpen, toggleMortgageDrawer] = useState(false);
  const [isInsulationDrawerOpen, toggleInsulationDrawer] = useState(false);
  const [selectedTradeType, setSelectedTradeType] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("Details");
  const [isNewRecordDrawerOpen, toggleNewRecordDrawer] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [taskLinks, setTaskLinks] = useState<any>([]);
  const [isLoading, toggleLoading] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  })

  // ! warning - don't indent the raw string
  // @ts-ignore
  const calendarRawContent = useMemo(() => `RRULE:FREQ=${CALENDAR_FREQUENCY[task?.periodicityUnit]};INTERVAL=${task?.periodicity}
ATTENDEE;ROLE=REQ-PARTICIPANT:MAILTO:${user.email}`, [task, user]);

  const calendarEvent = useMemo(() => {
    const property = properties.find((p) => p.propertyId === task?.propertyId);
    return {
      title: `${task?.title} - ${property?.address}@Livlet`,
      startTime: moment(task?.date).startOf('day').toString(),
      endTime: moment(task?.date).endOf('day').toString(),
      description: task?.message,
      location: `${property?.address}@ https://uk.livlet.com/`,
    }
  }, [task, properties]);

  const handleRefreshPage = () => {
    refreshParent();
    toggleDrawer(false);
  }

  const handleRefreshTask = (updatedTask: any) => {
    setTask({...task, triggerDate: updatedTask.triggerDate});
  }

  const refreshTaskLinks = () => {
    toggleLoading(true);
    getUpcomingTaskById(propertyId, task.templateId).then((res: any) => {
      setTaskLinks([...res.spaces, ...res.works, ...res.expenses, ...res.inventories, ...res.contacts, ...res.attachments,
        ...res.notes, ...res.recurringExpenses, ...res.reminders, ...res.tasks]);
      toggleLoading(false);
    })
  }

  const setTaskToLink = () => {
    setLinkedTaskId(task.templateId); // alert.id and task.templateId are the same uuid
    setLinkedTaskType(task.upkeepType);
  }

  useEffect(() => {
    task && refreshTaskLinks()
  }, [task, maintenances])

  useEffect(() => {
    task && setTaskToLink()
  }, [task])

  const closeDrawer = () => {
    setTask(null);
    setTaskLinks([]);
    setSelectedTab("Details")
    toggleDrawer(false);
  }

  const editTask = () => {
    setTaskToEdit(maintenances.content.filter(m => m.id === task.templateId)[0]);
    setTaskToEditType(taskType);
    toggleUpdateDrawer(true);
    toggleDrawer(false);
  }

  const displayFrequency = () => {
    if (isRecurringTask(task)) {
      return `Every ${task.actualPeriodicity ? task.actualPeriodicity :
        (task.periodicity ? task.periodicity : "")} ${task.actualPeriodicityUnit ?? task.periodicityUnit}(s)`
    } else return "Once"
  }

  const handleTaskAction = (key: any) => {
    if (key === "snooze") handleSnooze(task, () => {}, handleRefreshTask)
    if (key === "reschedule") toggleRescheduleModal(true)
    if (key === "forward") toggleForwardReminderDrawer(true)
    if (key === "dismiss") {
      if (task.periodicity && task.periodicityUnit !== "once") {
        toggleDismissModal(true)
      } else confirmDismissSingle(task, handleRefreshPage)
    }
    if (key === "markAsCompleted") {
      if (task.category !== "Reminder") {
        if (moment(task.triggerDate).isSameOrBefore(task.nextDate)) {
          toggleCompletionModal(true)
        } else {
          Modal.error({
            title: "Error!",
            content: "Only the first active task in the series can be marked as completed.",
            className: "form-confirm-close-modal",
          });
        }
      } else {
        markReminderAsComplete(task, handleRefreshPage)
      }
    }
    if (key === "findTrade") {

      getTradingOption(task.title, auth.subscriptionLevel) === "Find a tradesperson" && toggleTradeDrawer(true)
      getTradingOption(task.title, auth.subscriptionLevel) === "Find a broker" && toggleInsuranceDrawer(true)
      getTradingOption(task.title, auth.subscriptionLevel) === "Find a provider" && toggleInsulationDrawer(true)
      getTradingOption(task.title, auth.subscriptionLevel) === "Mortgage adviser" && toggleMortgageDrawer(true)
    }
  }

  const filteredItems = () => taskLinks.filter((item: any) => searchQuery ?
    (item.name?.toLowerCase().includes(searchQuery.toLowerCase()) || item.title?.toLowerCase().includes(searchQuery.toLowerCase())): true)

  const getItems = () => filteredItems()
    .filter((currentValue: any, index: any) => index >= (page - 1) * 10 && index < page * 10);

  const taskDetails = () => (<TaskDescription>
    {windowWidth > 768 && <div className={"row-title"}>{taskType} name</div>}
    {windowWidth > 768 && <div className={"row-description"} style={{fontWeight: 600}}>{task.title}</div>}
    <div className={"row-title"}>Due date</div>
    <div className={"row-description"}>{moment(task.triggerDate).format(propertyPreferences.preferredDateFormat)}</div>
    <div className={"row-title"}>{taskType} frequency</div>
    <div className={"row-description"}>{displayFrequency()}</div>
    {task.category === "Custom" && <div className={"row-title"}>Task type</div>}
    {task.category === "Custom" && <div className={"row-description"}>{task.workType.name}</div>}
    {task.expenseType && <>
        <div className={"row-title"}>Cost</div>
        <div className={"row-description"}>
          {task.actualEstimatedCost > 0 ? getCurrencySign(propertyPreferences.preferredCurrency)+task.actualEstimatedCost :
            (task.estimatedCost > 0 ? getCurrencySign(propertyPreferences.preferredCurrency)+task.estimatedCost : "Not specified")}
        </div>
        <div className={"row-title"}>Expense type</div>
        <div className={"row-description"}>{task.expenseType.name}</div>
    </>}
    {task.payee && <>
        <div className={"row-title"}>Payee</div>
        <div className={"row-description"}>{task.payee}</div>
    </>}
    {task.message && <div className={"row-title"}>Notes</div>}
    {task.message && <div className={"row-description"}>
      <ReadMoreReadLess
          charLimit={45}
          readMoreText={<ReadMoreText>{"View more >"}</ReadMoreText>}
          readLessText={<ReadMoreText>View less</ReadMoreText>}>
        {task.message ? task.message : ""}
      </ReadMoreReadLess>
    </div>}
    {taskType !== "Reminder" && <>
        <div className={"row-title"}>Autocomplete?</div>
        <div className={"row-description"}>{task.autocomplete ? "Yes" : "No"}</div>
    </>}
  </TaskDescription>)

  const taskActions = () => (<div>
    <ActionButtonsRow>
      <ActionButton className={"gray"} onClick={() => handleTaskAction("markAsCompleted")}>
        <CheckboxStyled className={"small"}><Checkmark/></CheckboxStyled>
        Mark as complete?
      </ActionButton>
      <ActionButton onClick={() => handleTaskAction("dismiss")}><CalendarDismiss /> Dismiss</ActionButton>
    </ActionButtonsRow>
    <ActionButtonsRow>
      <ActionButton onClick={() => handleTaskAction("snooze")}><CalendarSnooze /> Snooze</ActionButton>
      <ActionButton onClick={() => handleTaskAction("reschedule")}><CalendarReschedule /> Reschedule</ActionButton>
    </ActionButtonsRow>
    <ActionButtonsRow>
      <ActionButton onClick={() => handleTaskAction("forward")}><CalendarForward /> Forward</ActionButton>
      {task && <ActionButton><AddToCalendarIcon /><ICalendarLink event={calendarEvent} rawContent={calendarRawContent}>
          Add to calendar
      </ICalendarLink></ActionButton>}
    </ActionButtonsRow>
    {/*{getTradingOption(task.title, auth.subscriptionLevel) && <ActionButtonsRow>*/}
    {/*  <ActionButton onClick={() => handleTaskAction("findTrade")} className={"green"}>*/}
    {/*      <FindTradespersonIcon/> {getTradingOption(task.title, auth.subscriptionLevel)}*/}
    {/*  </ActionButton>*/}
    {/*</ActionButtonsRow>}*/}
  </div>)

  const detailsAndActions = () => (
    <SpacedContainer style={{marginTop: "1rem"}}>
      {task && taskDetails()}
      {task && taskActions()}
    </SpacedContainer>
  )

  const linkedItemsHeader = () => <LinkedItemsHeader
    title={"Linked items"}
    toggleLinkDrawerOpen={toggleLinkDrawerOpen}
    searchQuery={searchQuery}
    setSearchQuery={setSearchQuery}
    toggleAddNewRecordDrawer={toggleNewRecordDrawer}
    parentId={task && task.templateId}
    parentType={task.upkeepType}
    refreshParent={refreshTaskLinks}
  />

  const linkedItems = () => (
    isLoading ? <LoadingWrapper>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
    </LoadingWrapper> : <LinkedItemsContainer>
      {task && taskLinks.length === 0 && <div style={{paddingTop: task && taskLinks.length === 0 ? "1rem" : 0}}>
        <AddLinkedItem
        toggleLinkDrawerOpen={toggleLinkDrawerOpen}
        toggleNewRecordDrawer={toggleNewRecordDrawer}
        setPresetLinkType={setPresetLinkType}
        excludeTypes={["upkeep"]}
        />
      </div>}
      {task && taskLinks.length > 0 && <LinkedItemsList
          parentId={task.templateId}
          parentType={task.upkeepType}
          items={getItems()}
          searchQuery={searchQuery}
          isSelectionMode={false}
          toggleWorkDrawer={toggleWorkDrawer}
          setEditWorkData={setEditWorkData}
          toggleExpenseDrawer={toggleExpenseDrawer}
          setEditExpenseData={setEditExpenseData}
          toggleInventoryDrawer={toggleInventoryDrawer}
          setEditInventoryData={setEditInventoryData}
          toggleContactDrawer={toggleContactDrawer}
          setEditContactData={setEditContactData}
          toggleNoteDrawer={toggleNoteDrawer}
          setEditNoteData={setEditNoteData}
          refreshParent={refreshTaskLinks}
          setEditSpaceId={setEditSpaceId}
          toggleSpaceDrawer={toggleSpaceDrawer}
          toggleTaskDrawer={toggleUpdateDrawer}
          setEditTaskData={setTaskToEdit}
          setViewWorkId={setViewWorkId}
          toggleViewWorkDrawer={toggleViewWorkDrawer}
          setAttachmentId={setAttachmentId}
          toggleViewFileDrawer={toggleViewFileDrawer}
      />}
      {filteredItems().length > 10 && <PaginationStyled
          className={filteredItems().length < 10 ? "single-page-pagination" : ""}
          onChange={(page) => setPage(page)}
          simple={window.innerWidth < 1024}
          showSizeChanger={false}
          itemRender={paginationButtons}
          defaultCurrent={1}
          current={page}
          total={filteredItems().length}
          defaultPageSize={10}
      />}
    </LinkedItemsContainer>
  )

  const desktopDrawerContent = () => <>
    <SpacedContainer>
      {task && detailsAndActions()}
    </SpacedContainer>
    <SpacedContainer>
      {taskLinks.length > 0 && linkedItemsHeader()}
      {task && linkedItems()}
    </SpacedContainer>
  </>

  const mobileDrawerContent = () => <>
    {task && selectedTab === "Details" && detailsAndActions()}
    {task && selectedTab === "Links" && linkedItems()}
  </>

  return <>
    <AddNewRecordDrawer
      isOpen={isNewRecordDrawerOpen}
      toggleDrawerOpen={toggleNewRecordDrawer}
      parentId={task ? task.taskId : null}
      parentType={"upkeep"}
      refreshParent={() => {}}
    />
    <ForwardUpcomingTaskDrawer
      isOpen={isForwardReminderDrawerOpen}
      toggleDrawerOpen={toggleForwardReminderDrawer}
      reminder={task}
    />
    <RescheduleModal
      taskToReschedule={task}
      isRescheduleModalOpen={isRescheduleModalOpen}
      toggleRescheduleModal={toggleRescheduleModal}
      handleRefreshTask={handleRefreshTask}
    />
    <CompletionModal
      taskToComplete={task}
      isCompletionModalOpen={isCompletionModalOpen}
      toggleCompletionModal={toggleCompletionModal}
      handleRefresh={handleRefreshPage}
    />
    <DismissModal
      taskToDismiss={task}
      isDismissModalOpen={isDismissModalOpen}
      toggleDismissModal={toggleDismissModal}
      handleRefresh={handleRefreshPage}
    />
    <TradeDrawer
      isDrawerOpen={isTradeDrawerOpen}
      toggleDrawer={toggleTradeDrawer}
      selectedType={selectedTradeType}
      setSelectedType={setSelectedTradeType}
      includeBoilerServices={true}
    />
    {/*<InsuranceDealDrawer*/}
    {/*  isDrawerOpen={isInsuranceDrawerOpen}*/}
    {/*  toggleDrawer={toggleInsuranceDrawer}*/}
    {/*/>*/}
    <MortgagePartnerDrawer
      isDrawerOpen={isMortgageDrawerOpen}
      toggleDrawer={toggleMortgageDrawer}
    />
    <InsulationDrawer
      isDrawerOpen={isInsulationDrawerOpen}
      toggleDrawer={toggleInsulationDrawer}
      selectedType={"Install solar panels"}
      setSelectedType={() => {}}
    />
    <ViewTaskDrawerStyled
      push={{distance: "32px"}}
      zIndex={999}
      closeIcon={false}
      visible={isOpen}
      width={windowWidth > 1024 ? "680px" : "100%"}
      height={windowWidth > 768 ? "100%" : "85%"}
      placement={windowWidth > 768 ? "right" : "bottom"}
      onClose={() => closeDrawer()}
      title={<SpacedContainer>
        <DrawerHeader className={"header-desktop"}>
          <div style={{display: "flex", alignItems: "center", columnGap: "1rem"}}>
            <ReturnIcon style={{cursor: "pointer"}} onClick={() => closeDrawer()} />
            {getDrawerIcon(taskType)} {getDrawerTitle(taskType)}
          </div>
          <div style={{display: "flex", alignItems: "center", columnGap: "1.5rem"}}>
            <EditIcon onClick={() => editTask()} style={{cursor: "pointer"}}/>
          </div>
        </DrawerHeader>
        <DrawerHeader className={"task-header header-mobile underlined"}>
          <div style={{display: "flex", alignItems: "center", columnGap: "1rem", width: "100%"}}>
            {getDrawerIcon(taskType)} {getDrawerTitle(taskType)}
          </div>
          <div style={{display: "flex", alignItems: "center", columnGap: "1.5rem", justifyContent: "space-between",  width: "100%"}}>
            <div style={{display: "flex", alignItems: "center", gap: "1rem"}}>
              <ReturnArrow style={{cursor: "pointer", flexShrink: 0}} onClick={() => closeDrawer()} />
              {task && <span className={"reminder-title"}>{task.title}</span>}
            </div>
            <EditIcon onClick={() => editTask()} style={{cursor: "pointer"}}/>
          </div>
        </DrawerHeader>
        <ViewDrawerTabs>
          <span className={selectedTab === "Details" ? "selected" : "default"} onClick={() => setSelectedTab("Details")}>Details</span>
          <span className={selectedTab === "Links" ? "selected" : "default"} onClick={() => {
            setSelectedTab("Links");
            setTaskToLink();
          }}>Links</span>
        </ViewDrawerTabs>
        {taskLinks.length > 0 && selectedTab === "Links" && linkedItemsHeader()}
      </SpacedContainer>}
      footer={<DrawerFooter>
        <ActionButtonGreen onClick={() => {
          setTaskToLink();
          toggleLinkDrawerOpen(true);
        }}><LinkIcon/> Add links</ActionButtonGreen>
      </DrawerFooter>}
      >
      <SpacedContainer>
        {windowWidth > 768 ? desktopDrawerContent() : mobileDrawerContent()}
      </SpacedContainer>
    </ViewTaskDrawerStyled>
  </>
}

const ViewTaskDrawerStyled = styled(DrawerStyled)`
  .ant-drawer-body {
    margin: 0.5rem 0 1.5rem 0!important;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .ant-drawer-body {
      margin: 0 0 1.5rem 0!important;
    }
  }
`

export const TaskDescription = styled(SpaceDescription)`
  border-top: none;
  padding-top: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0;
  
  .title { font-weight: 600; }
  
  @media (max-width: 1024px) {
    margin: 0;
  }
  
  @media (max-width: 768px) {
    font-size: 0.75rem;
    line-height: 1.125rem;
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 1rem;
  }
`

export const ActionButtonsRow = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
`

export const ActionButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.625rem 0.75rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #45655E;
  
  &.mini {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  
  a { color: #45655E; }
  
  &.gray {
    background: #F5F6FA;
    border-radius: 8px;
    color: #21272B;
    font-weight: 600;
  }
  
  &.light-gray {
    background: #F5F6FA;
    border-radius: 8px;
    color: #45655E;
    font-weight: 600;
  }
 
  &.green {
    color: #009966;
  }
  
  &.acid-green {
    border: 1px solid #EFF1F5;
    border-radius: 6px;
    color: #00B74F;
  }
  
  &.acid-green svg path { stroke: #00B74F; }
  
  @media (max-width: 768px) {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
`

const SpacedContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`

export default ViewTaskDrawer;