import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import { getSpaces, TGetSpacesResponse } from "../../api-wrapper/spaces/getSpaces";
import {Guid} from "guid-typescript";

export const getSpacesInitAction = createAction("spaces/GET_SPACES_INIT")();
export const getSpacesSuccessAction = createAction(
  "spaces/GET_SPACES_SUCCESS"
)<TGetSpacesResponse>();
export const getSpacesFailureAction = createAction("spaces/GET_SPACES_FAILURE")();

export type TGetSpacesInitAction = ActionType<typeof getSpacesInitAction>;
export type TGetSpacesSuccessAction = ActionType<typeof getSpacesSuccessAction>;
export type TGetSpacesFailureAction = ActionType<typeof getSpacesFailureAction>;

export type TGetSpacesActions =
  | TGetSpacesInitAction
  | TGetSpacesSuccessAction
  | TGetSpacesFailureAction;

export type TGetSpacesThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetSpacesActions>;

export const getAllSpaces: TGetSpacesThunkActionType = (propertyId: Guid) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getSpacesInitAction());
  return getSpaces(propertyId)
    .then((payload) => {
      return dispatch(getSpacesSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getSpacesFailureAction()));
};
