import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {
  getProperties,
  TGetPropertiesResponse,
} from "../../api-wrapper/properties/getProperties";
import _ from "lodash";

export const getPropertiesInitAction = createAction(
  "properties/GET_PROPERTIES_INIT"
)();
export const getPropertiesSuccessAction = createAction(
  "properties/GET_PROPERTIES_SUCCESS"
)<TGetPropertiesResponse>();
export const getPropertiesFailureAction = createAction(
  "properties/GET_PROPERTIES_FAILURE"
)();
export const getPropertiesResetAction = createAction(
  "properties/GET_PROPERTIES_RESET"
)();

export type TGetPropertiesInitAction = ActionType<
  typeof getPropertiesInitAction
>;
export type TGetPropertiesSuccessAction = ActionType<
  typeof getPropertiesSuccessAction
>;
export type TGetPropertiesFailureAction = ActionType<
  typeof getPropertiesFailureAction
>;
export type TGetPropertiesResetAction = ActionType<
  typeof getPropertiesResetAction
>;

export type TGetPropertiesActions =
  | TGetPropertiesInitAction
  | TGetPropertiesSuccessAction
  | TGetPropertiesFailureAction
  | TGetPropertiesResetAction;

export type TGetPropertiesThunkActionType = () => ThunkAction<
  void,
  TRootState,
  null,
  TGetPropertiesActions
>;

export const getAllProperties: TGetPropertiesThunkActionType = () => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getPropertiesInitAction());
  return getProperties()
    .then((payload) => {
      if (payload) {
        return dispatch(getPropertiesSuccessAction(_.sortBy(payload, ["dateAdded"])));
      }
    })
    .catch(() => dispatch(getPropertiesFailureAction()));
};
