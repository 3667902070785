import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import { getWorks, TGetWorksResponse } from "../../api-wrapper/works/getWorks";
import {Guid} from "guid-typescript";

export const getWorksInitAction = createAction("works/GET_WORKS_INIT")();
export const getWorksSuccessAction = createAction(
  "works/GET_WORKS_SUCCESS"
)<TGetWorksResponse>();
export const getWorksFailureAction = createAction("works/GET_WORKS_FAILURE")();

export type TGetWorksInitAction = ActionType<typeof getWorksInitAction>;
export type TGetWorksSuccessAction = ActionType<typeof getWorksSuccessAction>;
export type TGetWorksFailureAction = ActionType<typeof getWorksFailureAction>;

export type TGetWorksActions =
  | TGetWorksInitAction
  | TGetWorksSuccessAction
  | TGetWorksFailureAction;

export type TGetWorksThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetWorksActions>;

export const getAllWorks: TGetWorksThunkActionType = (propertyId: Guid) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getWorksInitAction());
  return getWorks(propertyId)
    .then((payload) => {
      return dispatch(getWorksSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getWorksFailureAction()));
};
