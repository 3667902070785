import _ from "lodash";

import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TAddWorkResponse = {
  name: string;
  type: string;
  completedDate: string;
  dueDate: string;
  recurring: string;
  workId: Guid;
  propertyId: Guid;
  isComplete: boolean;
  relatedImprovementId?: string | null | undefined;
  notes: string;
};

export type TAddWorkRequest = {
  name?: string | null;
  type?: string | null;
  completedDate?: string | null;
  dueDate?: string | null;
  recurring?: string | null;
  isComplete: boolean;
};

export const addWork = (payload: TAddWorkRequest & { propertyId: Guid }) => {
  const { propertyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/works`
  );

  return apiPost<TAddWorkResponse, TAddWorkRequest>(
    url,
    _.omit(payload, ["propertyId"])
  );
};
