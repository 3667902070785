import {apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import axios from "axios";

export type TAcceptInviteRequest = {
  name?: string | null;
  email: string;
  password: string;
  marketingEmails: boolean;
  token: string;
  captchaToken?: string | null;
}

export type TAcceptInviteResponse = {

}

export const acceptInvite = async (payload: TAcceptInviteRequest) => {
  const { captchaToken } = payload;

  let url = createUrl(`${EnvConfig.API_URL}/api/user/acceptInvite?token=${captchaToken}`);

  const uninterceptedAxios = axios.create()

  const {data, headers} = await uninterceptedAxios.post<{}, any>(url, payload);

  return headers && headers.authorization
    ? {...data, accessToken: headers.authorization.split(":")[1]}
    : {...data, accessToken: " "};
  //
  // return apiPost<TAcceptInviteResponse, TAcceptInviteRequest>(url, payload);
};
