const ACCESS_TOKEN_LS_KEY = "livlet-localStorage-token";
const LIVLET_TOUR = "livlet-localStorage-tour";
const DISPLAY_INSURANCE_POPUP = "livlet-localStorage-display-insurance-popup"
const INSURANCE_POPUP_TRIGGER = "livlet-localStorage-insurance-popup-trigger"
const MARKETING_COOKIES = "livlet-localStorage-marketing-cookies"
const FILE_UPLOAD_POPUP = "livlet-localStorage-file-upload-popup"
const CURRENT_PROPERTY = "livlet-localStorage-current-property"
const SHOW_EXPENSES = "livlet-localStorage-show-expenses"
const FILE_EMAILED_ARRAY = "livlet-localStorage-emailed-files"
const FILE_EMAILED_POPUP = "livlet-localStorage-emailed-files-popup"

export function loadState() {
  try {
    const state = localStorage.getItem(ACCESS_TOKEN_LS_KEY);
    if (!state) return undefined;
    return JSON.parse(state);
  } catch (e) {
    return undefined;
  }
}

export function saveState(state: Object) {
  try {
    const serialized = JSON.stringify(state);
    localStorage.setItem(ACCESS_TOKEN_LS_KEY, serialized);
  } catch (e) {
    console.error("Could not save state!", e);
  }
}

export function showInsurancePopup() {
  localStorage.setItem(DISPLAY_INSURANCE_POPUP, "true");
}

export function hideInsurancePopup() {
  localStorage.setItem(DISPLAY_INSURANCE_POPUP, "false");
}

export function isInsurancePopupDisplayed() {
  const insurancePopup = localStorage.getItem(DISPLAY_INSURANCE_POPUP);
  return (insurancePopup === "true")
}

export function getInsurancePopupTrigger() {
  return localStorage.getItem(INSURANCE_POPUP_TRIGGER);
}

export function setInsurancePopupTrigger(triggerValue: string) {
  localStorage.setItem(INSURANCE_POPUP_TRIGGER, triggerValue);
}

export function getMarketingCookies() {
  return localStorage.getItem(MARKETING_COOKIES);
}

export function setMarketingCookies(triggerValue: string) {
  localStorage.setItem(MARKETING_COOKIES, triggerValue);
}

export function getFileUploadPopupState() {
  return localStorage.getItem(FILE_UPLOAD_POPUP);
}

export function setFileUploadPopupState(triggerValue: string) {
  localStorage.setItem(FILE_UPLOAD_POPUP, triggerValue);
}

export function getFileEmailedPopupState() {
  return localStorage.getItem(FILE_EMAILED_POPUP);
}

export function setFileEmailedPopupState(triggerValue: string) {
  localStorage.setItem(FILE_EMAILED_POPUP, triggerValue);
}
export function getEmailedFilesState() {
  return localStorage.getItem(FILE_EMAILED_ARRAY);
}

export function setEmailedFilesState(stateArray: any) {
  localStorage.setItem(FILE_EMAILED_ARRAY, JSON.stringify(stateArray));
}

export function getTourStatus() {
  return localStorage.getItem(LIVLET_TOUR);
}

export function setTourStatus(value: string) {
  localStorage.setItem(LIVLET_TOUR, value);
}

export function getCurrentPropertyId() {
  return localStorage.getItem(CURRENT_PROPERTY);
}

export function setCurrentPropertyId(value: string) {
  localStorage.setItem(CURRENT_PROPERTY, value);
}

export function clearCurrentPropertyId() {
  localStorage.removeItem(CURRENT_PROPERTY);
}

export function getShowExpenses() {
  return localStorage.getItem(SHOW_EXPENSES);
}

export function setShowExpenses(value: boolean) {
  localStorage.setItem(SHOW_EXPENSES, value.toString());
}