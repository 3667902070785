import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TNewsObject = {
    id: Guid,
    relatedItemId: Guid,
    relatedItemType: string,
    rating: string,
    title: string,
    body: string,
    dateTime: string,
    severityLevel: number,
    markedAsRead: boolean,
    deleted: boolean,
    propertyId: Guid,
    subscriptionId: number
}

export type TGetNewsResponse = Array<TNewsObject>;

export const getNews = (propertyId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/news`);

  return apiGet<TGetNewsResponse | []>(url, {}, propertyId);
};
