import React, {useState} from "react";
import {ReactComponent as WorkIcon} from "../../../images/WorksNew.svg";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import {Button, Checkbox, Drawer, Form, Input, Modal, notification} from "antd";
import {Label} from "../../screens/RegistrationPage";
import EmailCreatableSelector from "../EmailCreatableSelector";
import styled from "styled-components";
import {EmailModal, PreviewBox} from "../SendViaEmail";
import ReactHtmlParser from "react-html-parser";
import {useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../selectors";
import {getRecipients, safetyRegex, transformRecipients} from "../../screens/helpers";
import {ButtonsWrapper, CloseButton, WorksIconWrapper} from "../works/WorkDrawer";
import {forwardReminderPreview} from "../../../api-wrapper/email/forwardReminderPreview";
import {forwardReminder} from "../../../api-wrapper/email/forwardReminder";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  reminder: any;
}

const ForwardUpcomingTaskDrawer = (props: Props) => {
  const propertyId = useSelector(getPropertyIdSelector).value;
  const {isOpen, toggleDrawerOpen, reminder} = props;

  const [form] = Form.useForm();

  const [recipients, setRecipients] = useState<any>([])
  const [customMessage, setCustomMessage] = useState("")
  const [ccSender, setCcSender] = useState(false)
  const [includePropertyAddress, setIncludePropertyAddress] = useState(false)
  const [replyToMyEmail, setReplyToMyEmail] = useState(false)
  const [isPreviewModalOpen, togglePreviewModal] = useState(false)
  const [preview, setPreview] = useState("")

  function restoreInitialState() {
    setRecipients([])
    setCustomMessage("")
    setCcSender(false)
    setIncludePropertyAddress(false)
    setReplyToMyEmail(false)
    setPreview("")
    toggleDrawerOpen(false)
  }

  function handleForward(mode: string) {
    let sanitizedMessage = customMessage.replace(safetyRegex, '')
    let dataToForward = {
      propertyId,
      recipients: getRecipients(transformRecipients(recipients)),
      ccSender,
      customMessage: sanitizedMessage,
      isPropertyAddressEnabled: includePropertyAddress,
      isReplyToEnabled: replyToMyEmail,
      id: reminder.id,
      templateId: reminder.templateId,
      originalDate: reminder.originalDate,
      triggerDate: reminder.triggerDate
    }

    if (mode === "preview") {
      forwardReminderPreview(dataToForward).then((res: any) => {
        setPreview(res.data.body)
        togglePreviewModal(true)
      })
    }
    if (mode === "send") {
      forwardReminder(dataToForward).then(() => {
        notification.info({
          message: 'Reminder has been forwarded successfully',
          placement: "topRight",
        })
        restoreInitialState()
      }).catch((errorResponse: any) => {
        let errors: any = []
        errorResponse.data.map((error: any) => errors.push(error.message))
        Modal.error({
          title: "An error occurred while sending your email",
          content: errors.join(),
          className: "form-confirm-close-modal"
        });
      })
    }
  }

  return (<>
      <EmailModal
        width={800}
        style={{top: 10}}
        title={"Send message"}
        visible={isPreviewModalOpen}
        onCancel={() => togglePreviewModal(false)}
        closeIcon={<Button style={{marginLeft: '-3.8rem'}} type={'ghost'}>Cancel</Button>}
        footer={null}>
        <PreviewBox>{ReactHtmlParser(preview)}</PreviewBox>
      </EmailModal>

      <Drawer
        push={{ distance: "32px" }}
        closeIcon={false}
        width={window.innerWidth > 1024 ? "55%" : "100%"}
        visible={isOpen}
        placement="right"
        onClose={restoreInitialState}
        headerStyle={{
          backgroundColor: "#ebf9ff",
          margin: 0,
          padding: "2rem 2rem",
        }}
        title={
          <>
            <section style={{ display: "flex", justifyContent: "space-between" }}>
              <WorksIconWrapper>
                <WorkIcon className={"works-theme-icon"} />{" "}
                <span style={{ paddingRight: "0.25rem", color: "#7479E9", fontSize: "0.875", textTransform: "uppercase" }}>
                  <b>Reminder</b>
                </span>
              </WorksIconWrapper>
              <ButtonsWrapper>
                <CloseButton size={"large"}
                             onClick={restoreInitialState}
                >
                  {window.innerWidth > 1024 ?
                    <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
                </CloseButton>
              </ButtonsWrapper>
            </section>
            <section
              style={{
                marginTop: "1.875rem",
                marginBottom: "0.75rem"
              }}
            >
              <Title>Send message</Title>
            </section>
          </>
        }
      >
        <Form
          form={form}
          name="sendEmail"
          layout="vertical"
          size={"large"}
        >
          <InputWrapper>
            <Label style={{marginBottom: "0.75rem"}}>To:</Label>
            <EmailCreatableSelector emailList={recipients} setEmailList={setRecipients} />
            <div style={{display: "flex", flexDirection: "column"}}>
              <Checkbox
                style={{marginBottom: '1.5rem'}}
                checked={ccSender}
                onClick={() => setCcSender(!ccSender)}>
                CC me on this email
              </Checkbox>
              {recipients.length > 1 && ccSender && <p style={{fontWeight: 700, color: "#2a80e1"}}>
                  Please note that you will receive {recipients.length} copies of this email.
              </p>}
              <Checkbox
                style={{marginBottom: '1.5rem', marginLeft: 0}}
                checked={includePropertyAddress}
                onClick={() => setIncludePropertyAddress(!includePropertyAddress)}>
                Add property address
              </Checkbox>
              <Checkbox
                style={{marginBottom: '0.75rem', marginLeft: 0}}
                checked={replyToMyEmail}
                onClick={() => setReplyToMyEmail(!replyToMyEmail)}>
                Reply to your email address
              </Checkbox>
            </div>

          </InputWrapper>
          <InputWrapper style={{marginBottom: "5.5rem"}}>
            <Label style={{marginBottom: "0.75rem"}}>Your comments:</Label>
            <Form.Item>
              <Input
                style={{borderRadius: "5px"}}
                placeholder={"Add comments here..."}
                value={customMessage}
                onChange={(e) => setCustomMessage(e.target.value)}
              />
            </Form.Item>
          </InputWrapper>
          <ButtonWrapper>
            <ButtonOutlined
              style={{borderRadius: '6px'}}
              type={"primary"}
              onClick={() => handleForward("preview")}
            >Preview</ButtonOutlined>
            <ButtonSolid
              style={{borderRadius: '6px'}}
              type={"primary"}
              onClick={() => handleForward("send")}
              disabled={recipients.length === 0}
            >Send</ButtonSolid>
          </ButtonWrapper>
        </Form>
      </Drawer>
    </>
  )
}

const Title = styled.span`
  color: #21272B;
  font-size: 2.5rem;
  font-weight: 700;
`

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
 
  margin-top: 1.2rem;
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  background: white;
`

const ButtonSolid = styled(Button)`
  background: #2A80E1;
  border: 2px solid #2A80E1;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  height: 3.25rem;
  min-width: 8.25rem;
  text-align: center;
  margin: 1rem 0.625rem;
`

const ButtonOutlined = styled(Button)`
  background: white;
  border: 2px solid #2A80E1;
  color: #2A80E1;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  height: 3.25rem;
  min-width: 8.25rem;
  text-align: center;
  padding: 0.875rem 1.25rem;
  margin: 1rem 0.625rem;
`

export default ForwardUpcomingTaskDrawer;