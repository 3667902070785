import React, {useEffect, useState} from 'react'
import styled from "styled-components";

import {ReactComponent as HomeIcon} from "../../../images/dashboard-homevalue/Home.svg";
import {ReactComponent as PurchasePriceIcon} from "../../../images/dashboard-homevalue/PurchasePrice.svg";
import {ReactComponent as ValueIcon} from "../../../images/dashboard-homevalue/Value.svg";
import {ReactComponent as GrowthIcon} from "../../../images/dashboard-homevalue/Growth.svg";
import {ReactComponent as MortgageIcon} from "../../../images/dashboard-homevalue/Mortgage.svg";
import {ReactComponent as InsuredValueIcon} from "../../../images/dashboard-homevalue/InsuredValue.svg";
import {ReactComponent as EPCIcon} from "../../../images/dashboard-homevalue/EPC.svg";
import {ReactComponent as PencilIcon} from "../../../images/dashboard-homevalue/Pencil.svg";
import {ReactComponent as SaveValueIcon} from "../../../images/dashboard-homevalue/SaveValue.svg";
import {ReactComponent as CancelValueIcon} from "../../../images/dashboard-homevalue/CancelValue.svg";
import {ReactComponent as ViewAllArrow} from "../../../images/dashboard-improvements/ViewAllArrow.svg";
import {ReactComponent as GrowthUpIcon} from "../../../images/dashboard-homevalue/GrowthUp.svg";
import {ReactComponent as GrowthDownIcon} from "../../../images/dashboard-homevalue/GrowthDown.svg";
import {ReactComponent as GrowthSteadyIcon} from "../../../images/dashboard-homevalue/GrowthSteady.svg";
import {ReactComponent as ReloadBlue} from "../../../images/dashboard-homevalue/ReloadBlue.svg";

import moment from "moment";

import {useDispatch, useSelector} from "react-redux";
import {
  getImprovementsSelector, getMortgagesSelector,
  getPropertiesSelector,
  getPropertyIdSelector, getPropertyPreferencesSelector,
} from "../../selectors";
import {TFetchingStatus} from "../../../helpers";
import _ from "lodash";
import {useHistory} from "react-router-dom";
import {
  ViewAllButton,
  WidgetItemCount,
  WidgetItemCountNumber,
  WidgetItemTitle, WidgetTitle, WidgetTitleWrapper,
  WidgetWrapper
} from "./ImprovementsWidget";
import {InputNumber, notification, Popover, Spin} from "antd";
import {putDetails} from "../../../api-wrapper/properties/putDetails";
import {getAllImprovements} from "../../actions/improvements";
import {numberWithCommasFull} from "../helper";
import {putHighlights} from "../../../api-wrapper/properties/putHighlights";
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons";
import {WidgetItemIconWrapper} from "./ContentsWidget";
import PropertyValueDetailsDrawer from "../properties/PropertyValueDetailsDrawer";
import {CalendarPlaceholder, WidgetPlaceholderText} from "../upcomingTasksCalendar/UpcomingTaskCalendar";
import {putDismissOnboarding} from "../../../api-wrapper/properties/putDismissOnboarding";
import {getAllProperties} from "../../actions/properties";
import MortgageDrawer from "../mortgage/MortgageDrawer";
import {getCurrencySign} from "../../screens/helpers";

const HomeValueWidget = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);
  const improvementsConfiguration = useSelector(getImprovementsSelector);
  const mortgages = useSelector(getMortgagesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [propertyValueDetailsModalIsOpen, togglePropertyValueDetailsModal] = useState(false);
  const [isWidgetAvailable, setIsWidgetAvailable] = useState(false);

  const [isMortgageDrawerOpen, toggleMortgageDrawer] = useState(false);
  const [mortgage, setMortgage] = useState<any>(null);
  const [mortgageData, setEditingMortgageData] = useState<any>(null);

  const [purchasedPrice, setPurchasedPrice] = useState<any>();
  const [purchasedPriceDate, setPurchasedPriceDate] = useState<any>();
  const [purchasedPriceSource, setPurchasedPriceSource] = useState<any>();
  const [purchasedPriceCTA, setPurchasedPriceCTA] = useState(false);
  const [purchasedPriceEdit, setPurchasedPriceEdit] = useState(false);
  const [purchasedPriceEditValue, setPurchasedPriceEditValue] = useState<any>();

  const [insuranceValue, setInsuranceValue] = useState<any>();
  const [insuranceValueCTA, setInsuranceValueCTA] = useState(false);
  const [insuranceValueEdit, setInsuranceValueEdit] = useState(false);
  const [insuranceValueEditValue, setInsuranceValueEditValue] = useState<any>();
  const [insuranceValueDate, setInsuranceValueDate] = useState<any>();

  const [energyEfficiencyValue, setEnergyEfficiencyValue] = useState<any>();
  const [energyEfficiencyRatingValue, setEnergyEfficiencyRatingValue] = useState<any>();
  const [energyEfficiencyEdit, setEnergyEfficiencyEdit] = useState(false);
  const [energyEfficiencyEditValue, setEnergyEfficiencyEditValue] = useState<any>();
  const [energyPotentialValue, setEnergyPotentialValue] = useState<any>();
  const [energyPotentialRatingValue, setEnergyPotentialRatingValue] = useState<any>();

  const [propertyValue, setPropertyValue] = useState<any>();
  const [propertyValueType, setPropertyValueType] = useState<any>();
  const [propertyValueGrowth, setPropertyValueGrowth] = useState<any>();

  const [dataDownloaded, setDataDownloaded] = useState(false);
  const [homeValueRefreshing, setHomeValueRefreshing] = useState(false);

  const borderSetting = "2px solid #eff1f5";

  useEffect(() => {
    if (improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success) {
      if (homeValueRefreshing && dataDownloaded) {
        setDataDownloaded(true);
      } else {
        setDataDownloaded(false);
      }
      return;
    }

    if (improvementsConfiguration.content.propertyDetails.length === 0)
      return;

    if (improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 18)[0].answer) {
      setPurchasedPrice(improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 18)[0].answer);
      setPurchasedPriceEditValue(improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 18)[0].answer);
      setPurchasedPriceSource("User input");
      setPurchasedPriceDate(moment(improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 18)[0].date).format("YYYY-MM-DD"));
      setPurchasedPriceCTA(false);
    } else {
      if (improvementsConfiguration.content.propertyHighlights.lastSoldPriceSource === "User") {
        setPurchasedPriceCTA(true);
        setPurchasedPriceDate(null);
        setPurchasedPrice(null);
      } else {
        setPurchasedPrice(improvementsConfiguration.content.propertyHighlights.lastSoldPrice);
        setPurchasedPriceDate(improvementsConfiguration.content.propertyHighlights.lastSoldPriceDate);
        setPurchasedPriceSource(improvementsConfiguration.content.propertyHighlights.lastSoldPriceSource);
        setPurchasedPriceCTA(false);
      }
    }

    if (improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 26)[0].answer) {
      setInsuranceValue(improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 26)[0].answer);
      setInsuranceValueEditValue(improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 26)[0].answer);
      setInsuranceValueCTA(false);
      setInsuranceValueDate(moment(improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 26)[0].date).format("YYYY-MM-DD"));
    } else {
      setInsuranceValue(null);
      setInsuranceValueEditValue(null);
      setInsuranceValueCTA(true);
      setInsuranceValueDate(null);
    }

    if (!_.isNil(improvementsConfiguration.content.propertyHighlights.potentialEnergyEfficiency)) {
      setEnergyPotentialValue(improvementsConfiguration.content.propertyHighlights.potentialEnergyEfficiency);
    }

    if (!_.isNil(improvementsConfiguration.content.propertyHighlights.currentEnergyEfficiency)) {
      setEnergyEfficiencyValue(improvementsConfiguration.content.propertyHighlights.currentEnergyEfficiency);
      setEnergyEfficiencyRatingValue(improvementsConfiguration.content.propertyHighlights.currentEnergyRating);
    }
    if (!_.isNil(improvementsConfiguration.content.propertyHighlights.potentialEnergyEfficiency)) {
      setEnergyPotentialValue(improvementsConfiguration.content.propertyHighlights.potentialEnergyEfficiency);
      setEnergyPotentialRatingValue(improvementsConfiguration.content.propertyHighlights.potentialEnergyRating);
    }

    if (improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 19)[0].answer) {
      setPropertyValue(improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 19)[0].answer);
      setPropertyValueType("user");
    } else if (improvementsConfiguration.content.propertyHighlights.propertyValue) {
      setPropertyValue(improvementsConfiguration.content.propertyHighlights.propertyValue);
      setPropertyValueType(improvementsConfiguration.content.propertyHighlights.propertyValueType);
    } else {
      setPropertyValue(null);
    }

    if (improvementsConfiguration.content.propertyHighlights.growth) {
      setPropertyValueGrowth(""+improvementsConfiguration.content.propertyHighlights.growth);
    } else {
      setPropertyValueGrowth(null);
    }

    setDataDownloaded(true);
    setHomeValueRefreshing(false);
  }, [improvementsConfiguration]);

  useEffect(() => {
    setIsWidgetAvailable(purchasedPrice || energyEfficiencyValue
      || energyPotentialValue || propertyValue || properties.find((property) => property.propertyId === propertyId)?.dismissHomeValue)
  }, [improvementsConfiguration, purchasedPrice, energyEfficiencyValue, energyPotentialValue, propertyValue, properties])

  useEffect(() => {
    if (mortgages.content.length > 0) {
      setMortgage(mortgages.content[0])
    }
  }, [mortgages])

  const savePurchasedPrice = () => {
    const answers = [{id: 18, answer: purchasedPriceEditValue}];

    putDetails(propertyId, answers)
      .then(() => {
        dispatch(getAllImprovements(propertyId));
        setPurchasedPriceEdit(false);
      });
  }

  const resetValueEstimate = () => {
    const answers = [{id: 19}];

    notification.info({
      message: 'Trying to estimate the home value',
      placement: "topRight",
    });

    putDetails(propertyId, answers)
      .then(() => {
        dispatch(getAllImprovements(propertyId));
      });
  }

  const refreshHomeValue = (saveDataPromise: Promise<any>) => {
    setHomeValueRefreshing(true);
    saveDataPromise.finally(() => {
      dispatch(getAllImprovements(propertyId));
      setHomeValueRefreshing(false);
    });
  }

  const colorForEnergyLetter = (letter: string) => {
    switch (letter) {
      case "A":
        return "#33a357"
      case"B":
        return "#79b752"
      case "C":
        return "#c3d545"
      case "D":
        return "#fff12c"
      case "E":
        return "#edb731"
      case "F":
        return "#d66f2c"
      case "G":
        return "#cc232a"
      default:
        return "white"
    }
  }

  const purchasedPriceBox = () => {
    if (purchasedPriceEdit) {
      return <ValueEditWrapper>
          <ValueEditPrice>
            <CurrencySign style={{marginRight: "0.5rem", marginBottom: "0.25rem"}}>
              {getCurrencySign(propertyPreferences.preferredCurrency)}
            </CurrencySign>
            <InputNumberBox
              style={{width: "9.5rem"}}
              value={purchasedPriceEditValue}
              onChange={(val) => {
                setPurchasedPriceEditValue(val)
              }}
            />
          </ValueEditPrice>
          <ValueEditButtons>
            <SaveValueIcon
              style={{marginLeft:"0.5rem"}}
              onClick={() => savePurchasedPrice()}
            />
            <CancelValueIcon
              style={{marginLeft:"0.25rem"}}
              onClick={() => setPurchasedPriceEdit(false)}
            />
          </ValueEditButtons>
        </ValueEditWrapper>
    }
    if (purchasedPriceCTA) {
      return <span style={{color: "black", textDecoration: "underline", marginRight: "0.2rem", cursor: "pointer"}}
                   onClick={() => setPurchasedPriceEdit(true)}>Click to enter your details</span>;
    } else {
      return <WidgetItemCount>
        <CurrencySign>
          {getCurrencySign(propertyPreferences.preferredCurrency)}
        </CurrencySign>
        <Popover
          content={<div style={{
            fontSize: "0.75rem",
            fontWeight: 500,
            padding: "0.5rem",
            maxWidth: "17rem",
            textAlign: "center"
          }}>
            {!_.isNil(purchasedPriceSource) && purchasedPriceSource}
            {!_.isNil(purchasedPriceDate) && " on "+purchasedPriceDate}
          </div>}
          overlayClassName={"smallTooltipPopover"}
          placement="top"
          trigger={!_.isNil(purchasedPriceSource) ? ["hover"] : []}
        >
          <WidgetItemCountNumber>{purchasedPrice && numberWithCommasFull(purchasedPrice)}</WidgetItemCountNumber>
        </Popover>
      </WidgetItemCount>
    }
  }

  const openMortgageDrawer = () => {
    if (mortgage) {
      setEditingMortgageData({data: mortgage});
      toggleMortgageDrawer(true);
    } else {
      toggleMortgageDrawer(true);
    }
  }

  const mortgageValueBox = () => {
    if (mortgage) {
      return <WidgetItemCount onClick={() => openMortgageDrawer()}>
        <CurrencySign>
          {getCurrencySign(propertyPreferences.preferredCurrency)}
        </CurrencySign>
        <WidgetItemCountNumber>{numberWithCommasFull(mortgage.loanTotal)}</WidgetItemCountNumber>
      </WidgetItemCount>
    } else {
      return <span style={{color: "black", textDecoration: "underline", marginRight: "0.2rem", cursor: "pointer"}}
        onClick={() => openMortgageDrawer()}>Click to enter your details</span>
    }
  }

  const saveInsuranceValue = () => {
    const answers = [{id: 26, answer: insuranceValueEditValue}];

    putDetails(propertyId, answers)
      .then(() => {
        dispatch(getAllImprovements(propertyId));
        setInsuranceValueEdit(false);
      });
  }

  const insuranceValueBox = () => {
    if (insuranceValueEdit) {
      return <ValueEditWrapper>
        <ValueEditPrice>
          <CurrencySign style={{marginRight: "0.5rem", marginBottom: "0.25rem"}}>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
          </CurrencySign>
          <InputNumberBox
            style={{width: "9.5rem"}}
            value={insuranceValueEditValue}
            onChange={(val) => {
              setInsuranceValueEditValue(val)
            }}
          />
        </ValueEditPrice>
        <ValueEditButtons>
          <SaveValueIcon
            style={{marginLeft:"0.5rem"}}
            onClick={() => saveInsuranceValue()}
          />
          <CancelValueIcon
            style={{marginLeft:"0.25rem"}}
            onClick={() => setInsuranceValueEdit(false)}
          />
        </ValueEditButtons>
      </ValueEditWrapper>
    }
    if (insuranceValueCTA) {
      return <span style={{color: "black", textDecoration: "underline", marginRight: "0.2rem", cursor: "pointer"}}
                   onClick={() => setInsuranceValueEdit(true)}>Click to enter your details</span>;
    } else {
      return <WidgetItemCount>
        <CurrencySign>
          {getCurrencySign(propertyPreferences.preferredCurrency)}
        </CurrencySign>
        <Popover
          content={<div style={{
            fontSize: "0.75rem",
            fontWeight: 500,
            padding: "0.5rem",
            maxWidth: "17rem",
            textAlign: "center"
          }}>
            {!_.isNil(insuranceValueDate) && "User input on "+insuranceValueDate}
          </div>}
          overlayClassName={"smallTooltipPopover"}
          placement="top"
          trigger={!_.isNil(insuranceValue) ? ["hover"] : []}
        >
          <WidgetItemCountNumber>{insuranceValue && numberWithCommasFull(insuranceValue)}</WidgetItemCountNumber>
        </Popover>
      </WidgetItemCount>
    }
  }

  const saveEnergyEfficiency = () => {
    let currentEnergyRating;
    if (energyEfficiencyEditValue >= 92) currentEnergyRating = "A";
    else if (energyEfficiencyEditValue >= 81) currentEnergyRating = "B";
    else if (energyEfficiencyEditValue >= 69) currentEnergyRating = "C";
    else if (energyEfficiencyEditValue >= 55) currentEnergyRating = "D";
    else if (energyEfficiencyEditValue >= 39) currentEnergyRating = "E";
    else if (energyEfficiencyEditValue >= 21) currentEnergyRating = "F";
    else currentEnergyRating = "G";
    putHighlights(propertyId, {currentEnergyEfficiency: energyEfficiencyEditValue, currentEnergyRating: currentEnergyRating}).then(() => {
      dispatch(getAllImprovements(propertyId));
      setEnergyEfficiencyEdit(false);
    })
  }

  const epcBox = () => {
    if (!properties[0] || properties[0].country !== "United Kingdom")
      return;

    if (energyEfficiencyEdit) {
      return <>
        <EPCIcon/>
        <WidgetItemTitle>
          EPC Rating
        </WidgetItemTitle>
        <div style={{display: "flex", flexDirection: "column"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
            <InputNumberBox
              style={{width: "9.5rem"}}
              value={energyEfficiencyEditValue}
              onChange={(val) => {
                setEnergyEfficiencyEditValue(val)
              }}
            />
            <SaveValueIcon
              style={{marginLeft:"0.5rem"}}
              onClick={() => saveEnergyEfficiency()}
            />
            <CancelValueIcon
              style={{marginLeft:"0.25rem"}}
              onClick={() => setEnergyEfficiencyEdit(false)}
            />
          </div>
        </div>
        </>;
    }

    if (energyEfficiencyValue) {
      return <>
        <EPCIcon/>
        <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
          <div style={{width: window.innerWidth > 1024 ? "100%" : "auto"}}>
            <WidgetItemTitle>
              EPC Rating
            </WidgetItemTitle>
            <WidgetItemCount style={{display: "flex", width: "100%", marginTop: "0.25rem",
              flexDirection: window.innerWidth > 1024 ? "row" : "column", alignItems: window.innerWidth > 1024 ? "center" : "flex-start"}}>
              <EPCRating style={{
                backgroundColor: colorForEnergyLetter(energyEfficiencyRatingValue)
              }}>
                {energyEfficiencyRatingValue}
              </EPCRating>
              <WidgetItemCountNumber>{energyEfficiencyValue}</WidgetItemCountNumber>
            </WidgetItemCount>
          </div>
          {(!_.isNil(energyPotentialValue) && <div style={{width: window.innerWidth > 1024 ? "100%" : "auto"}}>
            <WidgetItemTitle>
              Potential
            </WidgetItemTitle>
            <WidgetItemCount style={{display: "flex", width: "100%", marginTop: "0.25rem",
              flexDirection: window.innerWidth > 1024 ? "row" : "column", alignItems: window.innerWidth > 1024 ? "center" : "flex-start"}}>
              <EPCRating style={{
                backgroundColor: colorForEnergyLetter(energyPotentialRatingValue)
              }}>
                {energyPotentialRatingValue}
              </EPCRating>
              <WidgetItemCountNumber>{energyPotentialValue}</WidgetItemCountNumber>
            </WidgetItemCount>
          </div>)}
        </div>
      </>;
    } else {
      return <>
        <EPCIcon/>
        <WidgetItemTitle>
          EPC Rating
        </WidgetItemTitle>
        <span style={{color: "black", textDecoration: "underline", marginRight: "0.2rem", cursor: "pointer"}}
                     onClick={() => setEnergyEfficiencyEdit(true)}>Click to enter your details</span>
      </>;
    }
  }

  const valueEstimateBox = () => {
    if (homeValueRefreshing)
      return <>
        <ValueIcon/>
        <WidgetItemTitle>Calculating value</WidgetItemTitle>
        <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
      </>

    if (!propertyValue)
      return <>
        <ValueIcon/>
        <WidgetItemTitle>Current value estimate</WidgetItemTitle>
        <WidgetItemCount>
        <CTAButton onClick={() => togglePropertyValueDetailsModal(true)}>
          {window.innerWidth > 1024 ? "Estimate my home’s current value"
            : "Estimate"}
           <ViewAllArrow /></CTAButton>
      </WidgetItemCount></>;

    return <>
      <div style={{display: "flex", flexDirection: "row",
        justifyContent: window.innerWidth < 1024 && propertyValueType !== "extended" ? "center" : propertyValueType !== "user" ? "flex-start" : "space-between",
        alignItems: "center"}}>
        {window.innerWidth < 1024 && propertyValueType !== "extended" ? null : <ValueIcon/>}
        {propertyValueType !== "extended" && propertyValueType !== "user" &&
          <CTAButton onClick={() => togglePropertyValueDetailsModal(true)} style={{
            marginLeft: window.innerWidth > 1024 ? "3rem" : "0"}}>
            {window.innerWidth > 1024 ? "Improve estimate" : "Improve"} <ViewAllArrow/>
          </CTAButton>
        }
        {propertyValueType === "user" &&
          <Popover
            content={"Estimate home value"}
            overlayClassName={"smallTooltipPopover"}
            placement="bottom"
            trigger={["hover"]}
          >
              <ReloadBlue style={{cursor:"pointer"}} onClick={() => resetValueEstimate()}/>
          </Popover>
        }
      </div>
      <WidgetItemTitle>{propertyValueType === "user" ? "User value estimate" : "Current value estimate"}</WidgetItemTitle>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems:"center"}}>
        <WidgetItemCount>
          <CurrencySign>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
          </CurrencySign>
          <Popover
            content={<div style={{
              fontSize: "0.75rem",
              fontWeight: 500,
              padding: "0.5rem",
              maxWidth: "25rem",
              textAlign: "center"
            }}>
              {propertyValueType === "user" && "User input"}
              {propertyValueType === "simple" && "Simple valuation based on the area and property size"}
              {propertyValueType === "extended" && "Enhanced valuation based on all available data"}
            </div>}
            overlayClassName={"smallTooltipPopover"}
            placement="top"
            trigger={["hover"]}
          >
            <WidgetItemCountNumber style={{color: (propertyValueType !== "extended" && propertyValueType !== "user") ? "#C7C9C7" : "#666F7A"}}>
              {numberWithCommasFull(propertyValue)}
            </WidgetItemCountNumber>
        </Popover>
       </WidgetItemCount>
      </div>
    </>;
  }

  const growthBox = () => {
    if (homeValueRefreshing)
      return <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>;

    if (!propertyValueGrowth)
      return <></>;

    let growthIcon;
    let growthVal = propertyValueGrowth.substring(0, propertyValueGrowth.length - 1);
    if (Number(growthVal) < 0) {
      growthIcon = <GrowthDownIcon/>
    } else if (Number(growthVal) > 0) {
      growthIcon = <GrowthUpIcon/>
      growthVal = "+" + growthVal;
    } else {
      growthIcon = <GrowthSteadyIcon/>
    }

    return <WidgetItemCount>
      <span style={{color:"#7CC9B7", fontWeight: 900, fontSize: "1.5rem"}}>%</span>
      <WidgetItemCountNumber>{growthVal}</WidgetItemCountNumber>
      {growthIcon}
    </WidgetItemCount>
  }

  const widgetPlaceholder = () => (<WidgetPlaceholder>
      <WidgetPlaceholderText>
        <CloseOutlined
          style={{position:"absolute", top: "0.5rem", right: "0.5rem", cursor:"pointer"}}
          onClick={() => putDismissOnboarding({propertyId, dismissHomeValue: true})
            .then(() => {dispatch(getAllProperties());})}
        />
        <span style={{fontWeight: 600, color: "#21272B"}}>Here is where you'll get an overview of your home's value and key financial and performance metrics</span>
        <span>To start with, let's estimate your home's current value.</span>
        <ViewAllButton onClick={() => togglePropertyValueDetailsModal(true)}>
          Estimate my home's current value <ViewAllArrow/>
        </ViewAllButton>
      </WidgetPlaceholderText>
    </WidgetPlaceholder>
  )

  if (!dataDownloaded) {
    return <Spin indicator={<LoadingOutlined style={{fontSize: 72}} spin/>}/>
  }

  return <>
    <MortgageDrawer
      isOpen={isMortgageDrawerOpen}
      toggleDrawerOpen={toggleMortgageDrawer}
      setEditingMortgageData={setEditingMortgageData}
      mortgage={mortgageData}
    />
    <PropertyValueDetailsDrawer
      isOpen={propertyValueDetailsModalIsOpen}
      toggleDrawerOpen={togglePropertyValueDetailsModal}
      refreshParent={refreshHomeValue}
    />
    <WidgetTitleWrapper style={{width: "100%"}}>
      <WidgetTitle><HomeIcon />My home value</WidgetTitle>
      {window.innerWidth > 1024 && isWidgetAvailable && <div style={{display: "flex", columnGap: "0.5rem"}}>
        <ViewAllButton onClick={() => history.push("/value-simulator")}>
          Go to property value simulator<ViewAllArrow/></ViewAllButton>
        <ViewAllButtonWhite onClick={() => history.push("/properties/" + propertyId)}>
          My property details<ViewAllArrow/></ViewAllButtonWhite>
      </div>}
    </WidgetTitleWrapper>

    {(!dataDownloaded)
      ?
      <Spin indicator={<LoadingOutlined style={{fontSize: 72}} spin/>}/>
      :
      (isWidgetAvailable ? <>
        <WidgetWrapper style={{width: "100%"}}>
        <WidgetItem style={{borderBottom: borderSetting}}>
          <WidgetItemIconWrapper>
            <PurchasePriceIcon/>
            {purchasedPrice && <PencilIcon style={{cursor: "pointer"}} onClick={() => setPurchasedPriceEdit(true)}/>}
          </WidgetItemIconWrapper>
          <WidgetItemTitle>Purchase price</WidgetItemTitle>
          {purchasedPriceBox()}
        </WidgetItem>
        <WidgetItem style={{
          borderLeft: borderSetting,
          borderRight: window.innerWidth > 1024 ? borderSetting : 0,
          borderBottom: borderSetting}}>
          {valueEstimateBox()}
        </WidgetItem>
        <WidgetItem style={{
          borderBottom: borderSetting
        }}>
          <GrowthIcon/>
          <WidgetItemTitle>Area price change in last year</WidgetItemTitle>
          {growthBox()}
        </WidgetItem>
        <WidgetItem style={{
          borderLeft: window.innerWidth > 1024 ? 0 : borderSetting,
          borderBottom: window.innerWidth > 1024 ? 0 : borderSetting
        }}>
          <WidgetItemIconWrapper>
           <MortgageIcon/>
            {mortgage && <PencilIcon style={{cursor: "pointer"}} onClick={() => openMortgageDrawer()}/>}
          </WidgetItemIconWrapper>
          <WidgetItemTitle>Mortgage</WidgetItemTitle>
          {mortgageValueBox()}
        </WidgetItem>
        <WidgetItem style={{
          borderLeft: window.innerWidth > 1024 ? borderSetting : 0,
          borderRight: window.innerWidth > 1024 ? borderSetting : 0}}>
          <WidgetItemIconWrapper>
            <InsuredValueIcon/>
            {insuranceValue && <PencilIcon style={{cursor: "pointer"}} onClick={() => setInsuranceValueEdit(true)}/>}
          </WidgetItemIconWrapper>
          <WidgetItemTitle>Building Insured value</WidgetItemTitle>
          {insuranceValueBox()}
        </WidgetItem>
        <WidgetItem style={{borderLeft: window.innerWidth > 1024 ? 0 : borderSetting}}>
          {epcBox()}
        </WidgetItem>
      </WidgetWrapper>
      {window.innerWidth < 1024 && <div style={{display: "flex", columnGap: "0.125rem"}}>
          <ViewAllButton style={{width: "100%"}} onClick={() => history.push("/value-simulator")}>Property Value <ViewAllArrow/></ViewAllButton>
          <ViewAllButton style={{width: "100%"}} onClick={() => history.push("/properties/" + propertyId)}>Property details <ViewAllArrow/></ViewAllButton>
      </div>}
      </> : widgetPlaceholder())
    }
    </>
}

const WidgetItem = styled.div`
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  row-gap: 0.375rem;
  padding: 0.75rem;
  
  @media (max-width: 1024px) {
    width: calc(100% / 2);
  }
`

export const InputNumberBox = styled(InputNumber)`
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #21272B;
  box-shadow: none;
  box-sizing: border-box;

  color: #21272B;
  font-size: 1.625rem;
  line-height: 1.75rem;
  
  :hover {
    border: none;
    border-bottom: 1px solid #21272B;
    box-shadow: none;
  }
  
  input {
    font-weight: bold;
  }
`;

const EPCRating = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 6px;
  color: white;
  line-height: 1.5rem;
  font-size: 1.125rem;
  font-weight: 700;
`;

const CTAButton = styled.div`
  cursor: pointer;
  display: flex;
  background: #2A80E1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.875rem;
  height: 1.75rem;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: white;
`

const WidgetPlaceholder = styled(CalendarPlaceholder)`
  margin: 0;
  height: 16rem;
  background-image: url(/images/HomeValuePlaceholderBlurred.png);
  
  @media (max-width: 1024px) {
    background-image: none;
    height: auto;
  }
`

export const ViewAllButtonWhite = styled(ViewAllButton)`
  background: white;
  color: #2A80E1;
  border: 2px solid #2A80E1;
  
  svg path { stroke: #2A80E1; }
`

const ValueEditWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-end;
  }
`

const ValueEditPrice = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%; 
  align-items: center; 
  margin-bottom: 0.5rem;
`

const ValueEditButtons = styled.div`
  display: flex; 
  flex-direction: row; 
  align-items: flex-end;
`

const CurrencySign = styled.span`
  color: #7CC9B7;
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.75rem;
`

export default HomeValueWidget