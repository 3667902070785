import {Guid} from "guid-typescript";
import {Modal} from "antd";
import {downloadAttachment} from "../../../api-wrapper/attachments/downloadAttachment";
import React from "react";

import {Document, Page} from 'react-pdf';

export const imageTypes = ["png", "svg", "tiff", "tif", "webp", "bmp", "gif", "jpeg", "jpg", "ico"];

export const isImage = (linkToFile: string) =>
  imageTypes.filter(type => linkToFile.indexOf(type) !== -1).length > 0;

export function showImagePreview(propertyId: Guid, attachmentIdToPreview: Guid, linkToPreview: string) {
  Modal.success({
    width: 670,
    closable: true,
    className: "attachment-preview-modal",
    content: isImage(linkToPreview) ?
      <div style={{display:"flex", flexDirection:"column", alignItems:"flex-begin"}}>
          <span
            style={{marginBottom:"1rem", fontWeight: 600, fontSize:"1rem"}}
            onClick={(e) => {
              e.stopPropagation();
              propertyId &&
              attachmentIdToPreview &&
              downloadAttachment({
                propertyId,
                id: attachmentIdToPreview,
              }).then((res) => window.open(res, "_blank"))}}
          >
            Download
          </span>
        <img alt="example" style={{maxWidth: window.innerWidth > 1024 ? "600px" : "300px"}} src={linkToPreview}/>
      </div> : <div>Couldn't load your image. Please try again.</div>,
  });
}

export const pdfTypes = ["pdf"];

export const isPdf = (linkToFile: string) =>
  pdfTypes.filter(type => linkToFile.indexOf(type) !== -1).length > 0;

export function showPdfPreview(propertyId: Guid, attachmentIdToPreview: Guid, linkToPreview: string) {
  Modal.success({
    width: 670,
    closable: true,
    className: "attachment-preview-modal",
    content: isPdf(linkToPreview) ?
      <div style={{display:"flex", flexDirection:"column", alignItems:"flex-begin"}}>
          <span
            style={{marginBottom:"1rem", fontWeight: 600, fontSize:"1rem"}}
            onClick={(e) => {
              e.stopPropagation();
              propertyId &&
              attachmentIdToPreview &&
              downloadAttachment({
                propertyId,
                id: attachmentIdToPreview,
              }).then((res) => window.open(res, "_blank"))}}
          >
            Download
          </span>
        <Document  file={linkToPreview}>
          <Page pageNumber={1} width={window.innerWidth > 1024 ? 600 : 300} />
        </Document>
      </div> : <div>Couldn't load your document. Please try again.</div>,
  });
}


export const previewTypes = ["docx"];

export const isPreviewable = (linkToFile: string) =>
  previewTypes.filter(type => linkToFile.indexOf(type) !== -1).length > 0;

export function confirmDownload(resourceLink: any) {
  Modal.confirm({
    title: "Download ready",
    content:
      "Your document is ready for download",
    okText: "OK",
    cancelText: "Cancel",
    className: "form-confirm-close-modal",
    onOk() {
      window.open(resourceLink, "_blank");
    }
  });
}