import React, {useEffect, useState} from "react";
import {ReactComponent as EditIcon} from "../../../images/planner/EditIcon.svg";
import {ReactComponent as ArrowLeft} from "../../../images/planner/ArrowLeft.svg";
import {ReactComponent as ArrowRight} from "../../../images/planner/ArrowRight.svg";
import moment from "moment";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";
import {ReactComponent as CheckmarkIcon} from "../../../images/planner/CheckmarkIcon.svg";
import styled from "styled-components";
import { DatePicker } from 'antd-mobile'
import {CircleIcon} from "../../screens/components";

type Props = {
  selectedDate: any;
  setSelectedDate: (arg: any) => void;
  setPickedDate: (arg: boolean) => void;
  arePassedDatesAllowed: boolean;
  handleCancel: () => void;
}

const TaskCalendar = (props: Props) => {
  const {selectedDate, setSelectedDate, setPickedDate, handleCancel, arePassedDatesAllowed} = props;
  const [calendarDays, setCalendarDays] = useState<any>([]);
  const [shownDate, setShownDate] = useState(selectedDate);
  const [showMobilePicker, toggleMobilePicker] = useState(false);

  const weekDays = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  const now = new Date();

  const getClassName = (calendarDate: any) => {
    if (calendarDate.format("DDMMYYYY") === selectedDate.format("DDMMYYYY")) return "selected"
    else {
      if (calendarDate.format("MM") === shownDate.format("MM")) {
        if (arePassedDatesAllowed) return "date"
        else if (calendarDate.isBefore(now.setHours(0, 0, 0, 0))) return "date-passed"
        else return "date"
      } else {
        if (arePassedDatesAllowed) return "date-transparent"
        else if (calendarDate.isBefore(now.setHours(0, 0, 0, 0))) return "date-passed"
        else return "date-transparent"
      }
    }
  }

  const generateCalendarDays = (selectedDate: any) => {
    let days = [];

    let calendarDate = selectedDate.clone();
    let lastDate = selectedDate.clone();
    calendarDate.startOf("month").startOf("isoWeek");
    lastDate.endOf("month").endOf("isoWeek");

    while (calendarDate.isSameOrBefore(lastDate)) {
      days.push(calendarDate.format("YYYY-MM-DD"))
      calendarDate.add(1, "day")
    }
    setCalendarDays(days);
  }

  const changeMonth = (direction: string) => {
    const newDate = shownDate.clone()
    if (direction === "back") {
      setShownDate(newDate.subtract(1, "month"))
    } else {
      setShownDate(newDate.add(1, "month"))
    }
  }

  useEffect(() => {
    generateCalendarDays(shownDate)
  }, [shownDate])

  useEffect(() => {
    if (selectedDate.format("MM") !== shownDate.format("MM")) {
      const newDate = selectedDate.clone()
      setShownDate(newDate)
    }
  }, [selectedDate])

  const isGoingBackAllowed = () => {
    if (arePassedDatesAllowed) {
      return true
    } else {
      const currentMonth = moment().format("MM YYYY")
      const shownMonth = shownDate.format("MM YYYY")
      return !(currentMonth === shownMonth)
    }
  }

  return <ComponentWrapper>
    <DateWrapper>
      <DateHeader>Set date</DateHeader>
      <DateField>
        {selectedDate.format("D MMM, YYYY")}
        <EditIcon onClick={() => toggleMobilePicker(true)} />
      </DateField>
      <MonthTitleWrapper>
        <ArrowLeft className={isGoingBackAllowed() ? "" : "disabled"}
           style={{cursor: isGoingBackAllowed() ? "pointer" : "not-allowed", flexShrink: 0}}
           onClick={() => isGoingBackAllowed() && changeMonth("back")} />
        {shownDate.format("MMM")}
        <ArrowRight style={{cursor: "pointer", flexShrink: 0}} onClick={() => changeMonth("forward")} />
      </MonthTitleWrapper>
      <CalendarWrapper>
        {weekDays.map(day => <CalendarCell className={"title"}>{day}</CalendarCell>)}
        {calendarDays.map((day: any) => <CalendarCell
          className={getClassName(moment(day))}
          onClick={() => getClassName(moment(day)) !== "date-passed" && setSelectedDate(moment(day))}
        >
          {moment(day).format("D")}
        </CalendarCell>)}
      </CalendarWrapper>
      <DatePicker
        min={now}
        cancelText={<PickerButton>Cancel</PickerButton>}
        confirmText={<PickerButton>OK</PickerButton>}
        visible={showMobilePicker}
        onClose={() => toggleMobilePicker(false)}
        defaultValue={now}
        onConfirm={(val) => setSelectedDate(moment(val))}
      />
    </DateWrapper>
    <ButtonsWrapper>
      <CircleIcon onClick={() => handleCancel()}><CloseIcon/></CircleIcon>
      <CircleIcon className={"green-outline"} onClick={() => setPickedDate(true)}><CheckmarkIcon /></CircleIcon>
    </ButtonsWrapper>
  </ComponentWrapper>
}

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 22rem;
  margin: 0 auto;
  gap: 1.5rem;
`

const ComponentWrapper = styled(DateWrapper)`
  @media (max-width: 1024px) {
   justify-content: space-between;
  }
`

const DateHeader = styled.div`
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.625rem;
  color: #21272B;
`

const DateField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 1rem;
  gap: 1rem;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.625rem;
  color: #009966;
`

const MonthTitleWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #21272B;
  
  .disabled path { fill: #f5f5f5; }
`

const CalendarWrapper = styled.div`
  display: grid;
  column-gap: 0.5rem;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  padding: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 16px;
`

const CalendarCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  
  &.title { font-weight: 600; color: #45655E; }
  &.date { font-weight: 700; color: #333333; }
  &.date-transparent { font-weight: 700; color: #d6d6d6; }
  &.date-passed { color: #f5f5f5; }
  &.selected { background: #009966; color: white; border-radius: 100%; }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
`

const PickerButton = styled.div`
  font-size: 1rem;
  color: #009966;
`

export default TaskCalendar;