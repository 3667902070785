import React, {Dispatch, useEffect, useRef, useState} from "react";
import {Form, notification} from "antd";

import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";

import {loginClean, signIn, signInSocial, signOut, TLoginThunkActionType} from "../actions/logIn";
import {
  MAX_LENGTH_LOGIN_FORM,
  NOTIFICATION_TOP_RIGHT_PLACEMENT,
} from "../helpers/constants";
import messages from "./messages";
import {emailRegex, emptyGuid, pushStepToGoogle} from "./helpers";
import { TFetchingStatus } from "../../helpers";
import { authSelector } from "../selectors";
import LoadingPage from "./LoadingPage";
import {
  InputPasswordStyled,
  InputStyled,
  Label,
  LabelWrapper,
} from "./RegistrationPage";
import { ReactComponent as AlertIcon } from "../../images/AlertRed.svg";
import { ReactComponent as LoginFacebook } from "../../images/LoginFacebook.svg";
import {
  ButtonStyled,
  ButtonText,
  FormWrapper,
  ImageWrapper,
} from "./styled";
import {useHistory, useLocation} from "react-router-dom";
import {reset} from "../../api-wrapper/auth/reset";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {clearCurrentPropertyId} from "../../localStorage";
import {setPropertyId} from "../actions/propertyId";
import envConfig from "../../config";

const { Item } = Form;

const LoginPage = () => {
  const { formatMessage: f } = useIntl();

  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const dispatch: Dispatch<TLoginThunkActionType> = useDispatch();
  const passwordInput = useRef(null);

  const auth = useSelector(authSelector);
  let history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [showLoginError, setShowLoginError] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [emailSent, setEmailSent] = useState(false)

  const [logoutUser, toggleLogoutUser] = useState(false);

  const [isBusinessWebsite, toggleBusinessWebsite] = useState(false);
  const [isMcLarenWebsite, toggleMcLarenWebsite] = useState(false);
  const [isMacLarenDomainError, toggleMacLarenDomainError] = useState(false);

  useEffect(() => {
    console.log("window.location.hostname=" + window.location.hostname)
    if (window.location.hostname.includes("mcl") /*|| window.location.hostname.includes("localhost")*/) {
      toggleBusinessWebsite(true);
      toggleMcLarenWebsite(true);
    }
  }, [])

  const handleLogIn = async (data: { email: string; password: string }) => {
    const { password, email } = data;

    let token = "";
    if (executeRecaptcha) {
      token = await executeRecaptcha('yourAction');
    }

    clearCurrentPropertyId();
    dispatch(setPropertyId(emptyGuid))

    isEmpty(password) || isEmpty(email)
      ? api.error({
          message: f(messages.emptyFieldTitle),
          description: f(messages.emptyFieldDescription),
          placement: NOTIFICATION_TOP_RIGHT_PLACEMENT,
        })
      : dispatch(signIn({ email, password, token }))
  };

  useEffect(() => {
    if (auth.fetchingStatus === TFetchingStatus.Failure) {
      setShowLoginError(true);
      dispatch(loginClean());
    }
  }, [auth.fetchingStatus]);

  useEffect(() => {
    if (auth.loginError)
      setLoginError(auth.loginError);
  }, [auth.loginError])

  const useQuery = () => {
    let query = new URLSearchParams(useLocation().search);
    return query.get("email")
  }

  const email = useQuery()

  useEffect(() => {
    if (email) {
      // @ts-ignore
      passwordInput.current && passwordInput.current.focus();
    }
  }, [email])

  useEffect(() => {
    if (auth.fetchingStatus === TFetchingStatus.Initial) {
      dispatch(signOut());
    }
  }, [logoutUser])

  if (auth.fetchingStatus === TFetchingStatus.Initial) {
    setTimeout(() => {
      toggleLogoutUser(true);
    }, 6000);
    return <LoadingPage />;
  }

  const responseFacebook = (response: any) => {
    pushStepToGoogle('login-facebook-start');

    clearCurrentPropertyId();
    dispatch(signInSocial({
      externalSourceName: "facebook",
      externalId: response.id,
      externalSourceToken: response.accessToken,
      name: response.name,
      email: response.email,
    }))
  }

  const responseGoogle = (response: any) => {
    pushStepToGoogle('login-google-start');

    clearCurrentPropertyId();
    dispatch(signInSocial({
      externalSourceName: "google",
      externalId: response.googleId,
      externalSourceToken: response.accessToken,
      name: response.profileObj.name,
      email: response.profileObj.email,
    }))
  }

  const responseMicrosoft = (error: any, response: any) => {
    pushStepToGoogle('login-microsoft-start');

    localStorage.clear();
    sessionStorage.clear();

    clearCurrentPropertyId();

    if (
      (window.location.hostname.includes("mcl") /*|| window.location.hostname.includes("localhost")*/)
      && (!response?.account?.idToken?.email
          || (!response.account.idToken.email.includes("@mclaren")
              && !response.account.idToken.email.includes("arby12@outlook")
              && !response.account.idToken.email.includes("@email"))
      )
    ) {
      toggleMacLarenDomainError(true);
      return;
    }

    dispatch(signInSocial({
      externalSourceName: "microsoft",
      externalId: "",
      externalSourceToken: response.accessToken,
      name: response?.account?.name,
      email: response?.account?.idToken?.email,
    }))
  }


  return (
    <Main>
      {contextHolder}
      <FormWrapper>
        <FormHeader>
          <div style={{width: "175px"}}>
            <img src={"/images/LivletLogoNew.png"} style={{maxWidth: "100%"}} alt={""} />
          </div>
        </FormHeader>
        {!isBusinessWebsite && <TitlesWrapper>
          <Title>{f(messages.loginTitle)}</Title>
          <Subtitle>
            {f(messages.loginSubtitle, {
              registrationLink: (
                <a className={"login-page-register"} onClick={() => history.push("/register")}>{f(messages.loginSubtitleLink)}</a>
              ),
            })}
          </Subtitle>
        </TitlesWrapper>}
        {!isBusinessWebsite && <Form
          form={form}
          name="login"
          size={"large"}
          initialValues={{ email, remember: true }}
          onFinish={handleLogIn}
        >
          <LabelWrapper>
            <Label>{f(messages.loginEmailAddress)}</Label>{" "}
          </LabelWrapper>
          <Item
            key={"email"}
            name={"email"}
            rules={[
              {
                required: true,
                pattern: emailRegex,
                message: f(messages.validationEmail),
              },
            ]}
          >
            <InputStyled
              placeholder={f(messages.loginEmailAddress)}
              maxLength={MAX_LENGTH_LOGIN_FORM}
              onChange={() => setShowLoginError(false)}
            />
          </Item>
          <LabelWrapper>
            <Label>{f(messages.password)}</Label>{" "}
          </LabelWrapper>
          <Item
            key={"password"}
            name={"password"}
            rules={[
              {
                required: true,
                message: f(messages.validationPassword),
              },
            ]}
          >
            <InputPasswordStyled
              ref={passwordInput}
              maxLength={MAX_LENGTH_LOGIN_FORM}
              placeholder={f(messages.password)}
              onChange={() => setShowLoginError(false)}
            />
          </Item>
          {showLoginError && (
            <ErrorWrapper>
              <AlertIcon />
                {loginError !== "activation" && loginError !== "captcha failed" && loginError !== "facebook email not verified" &&
                <span style={{ paddingLeft: "0.5rem" }}>
                  {loginError}
                </span>}
                {loginError === "captcha failed" && <span style={{ paddingLeft: "0.5rem" }}>
                    The reCAPTCHA verification failed. Please try again.
                  </span>}
                {loginError === "activation" && !emailSent && <span style={{ paddingLeft: "0.5rem" }}>
                  {f(messages.loginActivationError, {
                    activationLink: (<span style={{color: '#1890ff', cursor: "pointer"}} onClick={() => {
                      reset(form.getFieldValue("email")).then(() => setEmailSent(true))
                    }}>here</span>)
                  })}
                </span>}
              {loginError === "activation" && emailSent && <span style={{ paddingLeft: "0.5rem" }}>
                {f(messages.resentEmailMessage)}
              </span>}
              {loginError === "facebook email not verified" && <span style={{ paddingLeft: "0.5rem" }}>
                  We were unable to gain access to your email details via your Facebook settings. Please try an alternative sign-in method.
              </span>}
              {isMacLarenDomainError && <div style={{color:"red", paddingLeft: "0.5rem", marginTop:"1rem"}}>
                  Please use a valid McLaren email address to proceed.
              </div>}
            </ErrorWrapper>
          )}
          <ButtonsWrapper>
            <ButtonStyled htmlType="submit" type="primary">
              <ButtonText> {f(messages.signIn)}</ButtonText>
            </ButtonStyled>
          </ButtonsWrapper>
          <span>
            <a className={"login-page-forgotten"} onClick={() => history.push('/reset/password')}>{f(messages.loginForgottenPassword)}</a>
          </span>
          {!isMcLarenWebsite && <div className={"social-login-wrapper"}>
              <GoogleLogin
                clientId="915262851651-pk10djnhnvhplsmqaqkn6giefvcceka8.apps.googleusercontent.com"
                buttonText={"Login with Google"}
                onSuccess={responseGoogle}
                cookiePolicy={'single_host_origin'}
                className={"social-login-button"}
              />
              <FacebookLogin
                appId="442122783795366"
                autoLoad={false}
                fields="id,email,name"
                callback={responseFacebook}
                version={"12.0"}
                disableMobileRedirect
                isMobile={false}
                cssClass={"social-login-button"}
                textButton={"Login with Facebook"}
                icon={<LoginFacebook style={{marginRight: "1rem", marginTop: "-0.1rem"}} />}
              />
          </div>}
          {/*{!isMcLarenWebsite && <div className={"social-login-wrapper"}>*/}
          {/*    <MicrosoftLogin*/}
          {/*      debug={true}*/}
          {/*      clientId={azureLivletTestAppId}*/}
          {/*      graphScopes={["user.read", "email"]}*/}
          {/*      authCallback={responseMicrosoft}*/}
          {/*    />*/}
          {/*</div>}*/}
          <div>
          <p>Other issues with login? Contact us: <a href={`mailto:support.uk@livlet.com`}>support.uk@livlet.com</a>
          <br/>Any questions about Livlet? Please refer to our <a className={"login-page-faq"} href={'https://www.livlet.com/faq'} target={"_blank"} rel={"noreferrer"}>FAQ section</a>.</p>
          </div>
        </Form>}
        {isBusinessWebsite && <>
          <TitlesWrapper>
            <Title>Sign into your LivLet account</Title>
            <Subtitle>
              By proceeding, you agree to our <a className={"footer-terms"} onClick={() => history.push("/terms")}>Terms and Conditions</a>. Please read our <a className={"footer-privacypolicy"} onClick={() => history.push("/privacypolicy")}>Privacy Policy</a>.
            </Subtitle>
          </TitlesWrapper>
          {isMacLarenDomainError && <div style={{color:"red", paddingLeft: "0.5rem", marginTop:"1rem"}}>
              Please use a valid McLaren email address to proceed.
          </div>}
          {/*<div style={{display:"flex", flexDirection: "row",justifyContent: "center", marginTop: "2rem"}}>*/}
              {/*<MicrosoftLogin*/}
              {/*  debug={true}*/}
              {/*  clientId={envConfig.AZURE_SSO_APP_ID}*/}
              {/*  graphScopes={["user.read", "email"]}*/}
              {/*  buttonTheme={"dark"}*/}
              {/*  authCallback={responseMicrosoft}*/}
              {/*/>*/}
          {/*</div>*/}
          <div style={{position: "absolute", bottom:"2rem"}}>
            This site is protected by reCAPTCHA and the Google <a className={"footer-privacypolicy"} href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a className={"footer-terms"} href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
          </div>
        </>}
      </FormWrapper>
      <ImageWrapper>
        <LoginPageImageStyled imgSrc={isMcLarenWebsite ? "/images/registrationImageBeach.jpg" : undefined} />
        <TextOnImage>{f(messages.imageText)}</TextOnImage>
      </ImageWrapper>
    </Main>
  );
};

const FormHeader = styled.div`
  @media (max-width: 1024px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 1rem;
  }

  @media (min-height: 720px) {
    height: 15vh;
  }

  padding: 2rem 0 2rem;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
`;

const LoginPageImageStyled = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1024px) {
    min-height: 800px;
    width: 100%;
    height: 100vh;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: ${(props: { imgSrc?: string }) => props.imgSrc ? "url("+props.imgSrc+")" : "url(/images/registrationImage.jpg)"}; 
    margin: 0 auto;
    position: relative;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1.5rem;
  padding-top: 0.5rem;
`;

const Main = styled.main`
  display: flex;
  overflow-y: visible;
  height: 100vh;
  max-height: 100vh;
  justify-content: space-between;
  background-color: white;
`;

const TitlesWrapper = styled.div`
  @media (min-width: 1024px) and (min-height: 720px) {
    height: 15vh;
  }
`;

const Title = styled.h4`
  font-weight: 700;
  font-size: 1.625rem;
  color: #002a43;
`;

const Subtitle = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: #6b7185;
`;

const TextOnImage = styled.h3`
  font-weight: 700;
  color: #ffffff;
  position: absolute;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1024px) {
    font-size: 2.1rem;
    width: 350px;
    top: 0.5rem;
    right: 0.5rem;
  }
`;


export const ErrorWrapper = styled.div`
  background-color: #FCF0F0;
  padding: 1rem;
  margin: 1rem 0;
  display: flex;
  border-radius: 6px;
  font-weight: 400;
`

export default LoginPage;
