import React from "react";
import styled from "styled-components";
import { ReactComponent as MoreMenu } from "../../../images/More.svg";
import SpaceContents from "./SpaceContents";
import {Menu, Modal, Dropdown, Tooltip} from "antd";
import {MenuStyled} from "../works/WorksListComp";
import {ReactComponent as SpacesBathroom} from "../../../images/spaces/SpaceTypeBathroom.svg";
import {ReactComponent as SpacesBedroom} from "../../../images/spaces/SpaceTypeBedroom.svg";
import {ReactComponent as SpacesKitchen} from "../../../images/spaces/SpaceTypeKitchen.svg";
import {ReactComponent as SpacesOffice} from "../../../images/spaces/SpaceTypeOffice.svg";
import {ReactComponent as SpacesLivingRoom} from "../../../images/spaces/SpaceTypeLivingRoom.svg";
import {ReactComponent as SpacesMasterBedroom} from "../../../images/spaces/SpaceTypeMasterBedroom.svg";
import {ReactComponent as SpacesOther} from "../../../images/spaces/SpaceTypeOtherIndoors.svg";
import {removeSpace} from "../../../api-wrapper/spaces/removeSpace";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../selectors";
import {getAllSpaces} from "../../actions/spaces";
import {TooltipStyle} from "../../screens/components";

type Props = {
  space: any;
  toggleViewSpaceDrawer: (arg: boolean) => void;
  setViewSpaceId: (arg: any) => void;
  toggleEditSpaceDrawer: (arg: boolean) => void;
  setEditSpaceId: (arg: any) => void;
  toggleLinkSpaceDrawer: (arg: boolean) => void;
  setLinkedSpaceId: (arg: any) => void;

  toggleViewWorkDrawer: (arg: boolean) => void;
  setViewWorkId: (arg: any) => void;
  toggleWorkDrawer: (arg: boolean) => void;
  setEditWorkData: (arg: any) => void;
  toggleExpenseDrawer: (arg: boolean) => void;
  setEditExpenseData: (arg: any) => void;
  toggleInventoryDrawer: (arg: boolean) => void;
  setEditInventoryData: (arg: any) => void;
  toggleContactDrawer: (arg: boolean) => void;
  setEditContactData: (arg: any) => void;
  toggleNoteDrawer: (arg: boolean) => void;
  setEditNoteData: (arg: any) => void;
  toggleViewFileDrawer: (arg: boolean) => void;
  setAttachmentId: (arg: any) => void;
}

const SpaceCard = (props: Props) => {
  const {space, toggleViewSpaceDrawer, setViewSpaceId, toggleLinkSpaceDrawer, setLinkedSpaceId, toggleEditSpaceDrawer, setViewWorkId, toggleViewWorkDrawer,
    setEditSpaceId, toggleWorkDrawer, toggleContactDrawer, toggleExpenseDrawer, toggleInventoryDrawer, toggleViewFileDrawer, setAttachmentId,
    toggleNoteDrawer, setEditWorkData, setEditContactData, setEditExpenseData, setEditInventoryData, setEditNoteData} = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;

  function confirmDelete() {
    Modal.confirm({
      title: "Are you sure you want to delete this space?",
      content: "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() { removeSpace(propertyId, space.spaceId).then(() => dispatch(getAllSpaces(propertyId))) }
    });
  }

  const moreMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      if (key === "view") {
        toggleViewSpaceDrawer(true)
        setViewSpaceId(space.spaceId)
      } else if (key === "edit") {
        toggleEditSpaceDrawer(true)
        setEditSpaceId(space.spaceId)
      } else if (key === "link") {
        toggleLinkSpaceDrawer(true)
        setLinkedSpaceId(space.spaceId)
      } else if (key === "delete") {
        confirmDelete()
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="view">View</Menu.Item>
        <Menu.Item key="edit">Edit</Menu.Item>
        <Menu.Item key="link">Link new</Menu.Item>
        <Menu.Item key="delete">Delete</Menu.Item>
      </MenuStyled>
    );
  };

  const spaceIconDispenser = (spaceType: string) => {
    switch (spaceType) {
      case "Bathroom":
        return <SpacesBathroom/>
      case "Bedroom":
        return <SpacesBedroom/>
      case "Kitchen":
        return <SpacesKitchen/>
      case "Office":
        return <SpacesOffice/>
      case "Living room":
        return <SpacesLivingRoom/>
      case "Master bedroom":
        return <SpacesMasterBedroom/>
      default:
        return <SpacesOther />
    }
  }

  return <SpaceCardWrapper onClick={() => {
    toggleViewSpaceDrawer(true)
    setViewSpaceId(space.spaceId)
    }}>
    {space.imageThumbnail ? <SpacePhoto style={{backgroundImage: `url(${space.imageThumbnail}`}}>
      <SpaceIconWrapper>
        {spaceIconDispenser(space.spaceType)}
      </SpaceIconWrapper>
      {space.floor && <SpaceFloor>{space.floor}</SpaceFloor>}
    </SpacePhoto> : spaceIconDispenser(space.spaceType)}
    <SpaceTitle>
      {space.name}
      <Tooltip title={"Edit"} overlayClassName={"handholding"} overlayInnerStyle={TooltipStyle} mouseEnterDelay={0.5}>
        <Dropdown
          overlay={moreMenuDropdown()}
          placement="bottomRight"
          trigger={["click"]}
        >
          <MoreMenu style={{cursor: "pointer"}} onClick={(e) => e.stopPropagation()} />
        </Dropdown>
      </Tooltip>
    </SpaceTitle>
    <div onClick={(e) => e.stopPropagation()}>
      <SpaceContents
        space={space}
        openViewSpaceDrawer={() => {
          toggleViewSpaceDrawer(true)
          setViewSpaceId(space.spaceId)
        }}
        setViewWorkId={setViewWorkId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditNoteData}
        setAttachmentId={setAttachmentId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
    </div>
  </SpaceCardWrapper>
}

const SpaceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  background: white;
  border-radius: 24px;
  padding: 1.5rem;
  max-width: calc(1100px / 3);
  cursor: pointer;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0);
  transition: .1s all ease;
  
  &:hover {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    transition: .1s all ease;
  }
  
  @media (max-width: 768px) {
    padding: 0.5rem;
    border-radius: 8px;
    max-width: calc(1100px / 2);
    row-gap: 0.5rem;
  }
`

const SpacePhoto = styled.div`
  width: 100%;
  height: 10.75rem;
  border-radius: 16px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  @media (max-width: 768px) {
    border-radius: 4px;
    height: 6rem;
  }
`

const SpaceIconWrapper = styled.div`
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
`

const SpaceFloor = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  padding: 0.25rem 0.5rem;
  background: #FBFBFC;
  border-radius: 24px;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #000000;
`

const SpaceTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 2rem;
  color: #21272B;
  
  svg:hover path { fill: #009966; }
  
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`

export default SpaceCard
