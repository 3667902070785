import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import {getPropertyIdSelector, getPropertyPreferencesSelector, getUpcomingTasksSelector} from "../selectors";
import {getAllUpcomingTasks} from "../actions/upcomingTasks";
import {getAllContacts} from "../actions/contacts";
import {getAllWorks} from "../actions/works";
import {TFetchingStatus} from "../../helpers";
import _ from "lodash";
import {ReactComponent as SearchIcon} from "../../images/SearchLightGray.svg";
import {Button, DatePicker, Input, Menu, Popover, Select} from "antd";
import moment from "moment";
import styled from "styled-components";
import {PlusOutlined} from "@ant-design/icons";
import {ReactComponent as UpcomingTasksIcon} from "../../images/Checklist.svg";
import {ReactComponent as ArrowRightIcon} from "../../images/ArrowRight.svg";
import {Link} from "react-router-dom";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {ReactComponent as SortIcon} from "../../images/Sort.svg";
import {SortMenuStyled} from "../components/works/WorksListComp";
import UpcomingTasksWrapper from "../components/upcomingTasks/UpcomingTasksWrapper";
import {getAllExpenseTypes} from "../actions/expenses";
import {getAllMaintenances} from "../actions/maintenances";
import {
  AddButtonStyled,
  HeaderControlsWrapper,
  PageHeader,
  PageSubtitle, PageTitle,
  PageWrapper,
  PaginationStyled, ButtonText
} from "./components";
import AddEventDrawer from "../components/planner/AddEventDrawer";
import UpdateTaskDrawer from "../components/planner/UpdateTaskDrawer";
import WorkDrawer from "../components/works/WorkDrawer";
import {getAllProperties} from "../actions/properties";

type Props = {
  dashboardView: boolean;
}

const Todo = (props: Props) => {
  const {dashboardView} = props;
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const upcomingTasks = useSelector(getUpcomingTasksSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);
  const taskHeaderRef = useRef(null);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [search, setSearch] = useState("");
  const [taskType, setTaskType] = useState<any>("all");
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [upcomingTasksSearch, setUpcomingTasksSearch] = useState("");
  const [sortingType, setSortingType] = useState("");
  const [sortMenuPopoverVisible, setSortMenuPopoverVisible] = useState<any>(false);
  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editingWorkData, setEditingWorkData] = useState<any>(null);
  const [isEventDrawerOpen, toggleEventDrawer]  = useState(false);
  const [isTaskDrawerOpen, toggleTaskDrawer]  = useState(false);
  const [selectedEventType, setSelectedEventType] = useState("");

  const filteredUpcomingTasks = () =>
    upcomingTasks.content.tasks
      .filter((reminder) => {
        return !_.isEmpty(upcomingTasksSearch) ? reminder.title.toLowerCase().includes(upcomingTasksSearch.toLowerCase()) : true;
      })
      .filter(reminder => {
        if (taskType !== "all") {
          if (taskType === "project") return (reminder.type === "work")
          if (taskType === "recurringExpense") return (reminder.type === "alert"
            && reminder.task.workType !== null && reminder.task.workType.name === "Expenses")
          if (taskType === "reminder") return (reminder.type === "alert" && reminder.task.category === "Reminder")
          if (taskType === "task") return (reminder.type === "alert"
            && reminder.task.category !== "Reminder" && reminder.task.workType.name !== "Expenses")
        } else return true
      })
      .sort((r1, r2) => {
        if (sortingType === "typeSort") {
          let sortingResult = r2.type.localeCompare(r1.type)
          return sortingResult === 0 ? r1.title.toLowerCase().localeCompare(r2.title) : sortingResult
        } else if (sortingType === "dueDateOldest") {
          let sortingResult = moment(r2.date).valueOf() - moment(r1.date).valueOf()
          return sortingResult === 0 ? r1.title.toLowerCase().localeCompare(r2.title) : sortingResult
        } else {
          // sortingType === dueDateNewest
          let sortingResult = moment(r1.date).valueOf() - moment(r2.date).valueOf()
          return sortingResult === 0 ? r1.title.toLowerCase().localeCompare(r2.title) : sortingResult
        }
      })

  const getUpcomingTasks = () =>
    filteredUpcomingTasks().filter(
      (currentValue: any, index: any) =>
        index >= (page - 1) * pageSize && index < page * pageSize
    );

  useEffect(() => {
    if (upcomingTasks.fetchingStatus === TFetchingStatus.Success && getUpcomingTasks().length === 0 && page !== 1) {
      setPage(page - 1)
    }
  }, [upcomingTasks.fetchingStatus, getUpcomingTasks()])

  const refreshUpcomingTasks = () => {
    dispatch(getAllUpcomingTasks({
      propertyId: propertyId,
      startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
      endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
      firstInSeries: false
    }))
  }

  useEffect(() => {
    refreshUpcomingTasks()
    dispatch(getAllExpenseTypes(propertyId));
    dispatch(getAllContacts(propertyId));
    dispatch(getAllWorks(propertyId));
    dispatch(getAllMaintenances(propertyId));
    dispatch(getAllProperties());
  }, [propertyId])

  useEffect(() => {
    upcomingTasks.content.endDate && setEndDate(moment(upcomingTasks.content.endDate))
  }, [upcomingTasks])

  const sortMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      setSortMenuPopoverVisible(false);
      setSortingType("" + key);
    };

    return (
      <SortMenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="typeSort">Type</Menu.Item>
        <Menu.Item key="dueDateOldest">Due date (Newest to oldest)</Menu.Item>
        <Menu.Item key="dueDateNewest">Due date (Oldest to newest)</Menu.Item>
      </SortMenuStyled>
    );
  };

  return (
    <PageWrapper>
      <WorkDrawer
        isOpen={isWorkDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        work={editingWorkData}
        setEditingWorkData={setEditingWorkData}
      />
      <AddEventDrawer
        isOpen={isEventDrawerOpen}
        toggleDrawer={toggleEventDrawer}
        setSelectedType={setSelectedEventType}
        toggleWorkDrawer={toggleWorkDrawer}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditingWorkData={setEditingWorkData}
      />
      <UpdateTaskDrawer
        isOpen={isTaskDrawerOpen}
        toggleDrawer={toggleTaskDrawer}
        task={null}
        setEditingTaskData={() => {}}
        taskType={selectedEventType}
        setTaskType={setSelectedEventType}
        refreshParent={() => refreshUpcomingTasks()}
      />
      {!dashboardView && <PageHeader>
        <HeaderControlsWrapper>
          <div>
            <PageTitle>Schedule & calendar</PageTitle>
            <PageSubtitle>Ensure pending projects and tasks get completed on time</PageSubtitle>
          </div>
        </HeaderControlsWrapper>
        <HeaderControlsWrapper>
          <AddButtonStyled
            icon={<PlusOutlined />}
            onClick={() => toggleEventDrawer(true)}
          >
            {window.innerWidth > 1024 && "Add an event"}
          </AddButtonStyled>
        </HeaderControlsWrapper>
      </PageHeader>}

      {dashboardView &&
      <UpcomingTasksTitle>
          <UpcomingTasksIcon style={{marginRight: '0.3rem', marginBottom: '-0.2rem'}} /> Upcoming tasks
      </UpcomingTasksTitle>}

      <UpcomingTasksDesktop
        style={{
          marginBottom: dashboardView ? "1rem" : 0,
          maxWidth: dashboardView ? "1100px" : 'auto',
        }}>

        {!dashboardView && upcomingTasks.content.tasksExists && upcomingTasks.fetchingStatus === TFetchingStatus.Success &&
        <UpcomingTasksHeader>
          <Search
              size={"large"}
              value={search}
              onChange={(val) => {
                setSearch(val.target.value);
                setUpcomingTasksSearch(val.target.value);
              }}
              placeholder={"Search to-do list..."}
              suffix={<SearchIcon/>}
          />
          <SelectStyled
              style={{
                marginLeft: window.innerWidth > 1024 ? '0.5rem' : 0,
                marginRight: window.innerWidth > 1024 ? '1rem' : 0,
                marginTop: window.innerWidth > 1024 ? 0 : '1rem',
                marginBottom: window.innerWidth > 1024 ? 0 : '1rem',
                width: window.innerWidth > 1024 ? '20rem' : "100%"}}
              placeholder="Select type"
              size={'large'}
              value={taskType}
              onChange={(value) => {
                setTaskType(value)
              }}>
              <Select.Option value={"all"}>All</Select.Option>
              <Select.Option value={"reminder"}>Reminders</Select.Option>
              <Select.Option value={"task"}>Tasks</Select.Option>
              <Select.Option value={"project"}>Projects</Select.Option>
              <Select.Option value={"recurringExpense"}>Recurring expenses</Select.Option>
          </SelectStyled>
          <div style={{display: "flex"}}>
              <DatePicker
                  value={startDate}
                  format={propertyPreferences.preferredDateFormat}
                  style={{borderRadius: '6px', height: '2.5rem', marginRight: '0.5rem', minWidth: '8rem'}}
                  inputReadOnly={true}
                  allowClear={false}
                  onChange={(value) => {
                    if (value) {
                      setStartDate(value);
                      setPage(1);
                      dispatch(getAllUpcomingTasks({
                        propertyId: propertyId,
                        startDate: value?.format('YYYY-MM-DD'),
                        endDate: endDate ? endDate.format('YYYY-MM-DD') : moment().add(2, "months").format('YYYY-MM-DD'),
                        firstInSeries: false
                      }));
                    }
                  }}
              />
            {window.innerWidth > 1024 && <ArrowRightIcon className={"contacts-stroke-theme-icon"}/>}
              <DatePicker
                  value={endDate}
                  format={propertyPreferences.preferredDateFormat}
                  style={{borderRadius: '6px', height: '2.5rem', marginLeft: '0.5rem', minWidth: '8rem'}}
                  allowClear={false}
                  inputReadOnly={true}
                  onChange={(value) => {
                    if (value) {
                      setEndDate(value);
                      setPage(1);
                      dispatch(getAllUpcomingTasks({
                        propertyId: propertyId,
                        startDate: startDate ? startDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
                        endDate: value?.format('YYYY-MM-DD'),
                        firstInSeries: false
                      }));
                    }
                  }}
              />
              <Popover
                  content={sortMenuDropdown()}
                  className={"sortMenuWorks"}
                  visible={sortMenuPopoverVisible}
                  placement="bottom"
                  onVisibleChange={setSortMenuPopoverVisible}
                  trigger={["click"]}
              >
                  <ButtonSortStyled
                      style={{padding: '0 0.5rem'}}
                      icon={<SortIcon />}
                      onClick={() => {
                        setSortMenuPopoverVisible(!sortMenuPopoverVisible);
                      }}
                  />
              </Popover>
          </div>
        </UpcomingTasksHeader>
        }

        {upcomingTasks.fetchingStatus === TFetchingStatus.Initial &&
        <EmptyTasksList style={{
          marginTop: 0,
          maxWidth: dashboardView ? "1100px" : 'auto'}}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />} />
            <p style={{
              paddingTop: "1rem",
              paddingBottom: "1rem",
              paddingLeft: "1rem",
              fontWeight: 400,
              fontSize: "1rem",
              color: "#6B7185",
              textAlign: "center"
            }}>Loading your upcoming tasks...</p>
        </EmptyTasksList>
        }

        {!upcomingTasks.content.tasksExists && upcomingTasks.fetchingStatus === TFetchingStatus.Success &&
        <EmptyTasksList style={{
          marginTop: 0,
          maxWidth: dashboardView ? "1100px" : 'auto'}}
        >
            <h5 style={{ fontWeight: 600, fontSize: "1rem", color: "#21272B" }}>
                You're 100% task-free
            </h5>
            <p style={{
              paddingTop: "1rem",
              paddingBottom: "1rem",
              fontWeight: 400,
              fontSize: "1rem",
              color: "#6B7185",
              textAlign: "center"
            }}
            >
                You don't have any upcoming tasks right now.
                <br />But it could be the perfect time to create some.
                <br />Just subscribe to Maintenances or add a new Event.
            </p>
            <AddReminderButton
                onClick={() => toggleEventDrawer(true)}
                size={"large"}
                icon={<PlusOutlined />}>
                Add an event
            </AddReminderButton>
        </EmptyTasksList>
        }


        {!dashboardView && !_.isEmpty(filteredUpcomingTasks()) && upcomingTasks.fetchingStatus === TFetchingStatus.Success &&
        <UpcomingTasksColumnHeader>
            <UpcomingTaskDetail spanWidth={'6%'}>
              {" "}
            </UpcomingTaskDetail>
            <UpcomingTaskDetail spanWidth={'59%'}>
              {"Task description"}
            </UpcomingTaskDetail>
            <UpcomingTaskDetail spanWidth={'15%'}>
              {"Frequency"}
            </UpcomingTaskDetail>
            <UpcomingTaskDetail spanWidth={'15%'}>
              {"Due date"}
            </UpcomingTaskDetail>
            <UpcomingTaskDetail spanWidth={'5%'}>
              {" "}
            </UpcomingTaskDetail>
        </UpcomingTasksColumnHeader>
        }

        <span ref={taskHeaderRef} />
        {upcomingTasks.content.tasksExists && upcomingTasks.fetchingStatus === TFetchingStatus.Success &&
          <UpcomingTasksWrapper
            wrapperMode={"list"}
            upcomingTasks={getUpcomingTasks()}
            dashboardView={dashboardView}
            startDate={startDate}
        />}

        {!dashboardView && upcomingTasks.content.tasksExists && upcomingTasks.fetchingStatus === TFetchingStatus.Success &&
        filteredUpcomingTasks().length > pageSize && <PaginationStyled
            className={filteredUpcomingTasks().length < pageSize ? "single-page-pagination" : ""}
            onChange={(page, pageSize) => {
              setPage(page)
              if (pageSize) setPageSize(pageSize)
              // @ts-ignore
              taskHeaderRef.current.scrollIntoView();
            }}
            simple={window.innerWidth < 1024}
            showSizeChanger={false}
            itemRender={itemRender}
            defaultCurrent={1}
            total={filteredUpcomingTasks().length}
            current={page}
            onShowSizeChange={(current, size) => setPageSize(size)}
        />
        }

        {dashboardView && upcomingTasks.fetchingStatus === TFetchingStatus.Success && filteredUpcomingTasks().length > 5 &&
        <UpcomingTasksFooter>
          <span style={{margin: "auto"}}>
            <Link to={"/todo"}>View all upcoming tasks and reminders</Link>
          </span>
        </UpcomingTasksFooter>
        }
      </UpcomingTasksDesktop>
    </PageWrapper>
  );
};

const Search = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 0.5rem;
  
  @media (max-width: 1024px) {
    margin: 0;
    width: 100%;
  }
`;

export const UpcomingTasksDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0.625rem;
`;

const UpcomingTasksFooter = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 1rem;
  padding-bottom: 1.3rem;
  width: 100%;
  border-top: 1px solid #dae0e6;

  color: #21272b;
  font-weight: 600;
  font-size: 1.125rem;
`;

export const EmptyTasksList = styled(UpcomingTasksDesktop)`
  @media (min-width: 1024px) {
    padding: 5rem 0;
  }
`;

const AddReminderButton = styled(Button)`
  border: 1px solid #dae0e6;
  border-radius: 6px;
  font-weight: 600;
  color: 21272B;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const UpcomingTasksHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2.5rem;
  padding-top: 2rem;

  align-items: center;
  width: 100%;
  
  @media (max-width: 1024px) {
    padding: 1.5rem 1rem;
    flex-direction: column;
  }
`

const UpcomingTasksColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2.5rem;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #DAE0E6;
  
  @media (max-width: 1024px) {
    display: none;
  }
`

const SelectStyled = styled(Select)`
  width: 100%;
  color: #bfbfbf;
  
  .ant-select-selector {
    border-radius: 6px!important;
  }
`

export const UpcomingTasksTitle = styled.div`
  padding-left: 0.5rem;
  font-weight: 600;
  font-size: 1.125rem;
  color: #21272B;
`;

export const TopHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  
  @media (max-width: 1024px) {
    margin-bottom: 0.5rem;
  }
`

export const UpcomingTaskDetail = styled.span`
  width: ${(props: { spanWidth: string }) => props.spanWidth};
  text-align: left;
`;

const ButtonSortStyled = styled(Button)`
  margin-left: 1rem;
  height: 2.5rem !important;
  width: 3.2rem !important;
  margin-top: 0;
  svg {
    margin-top: 0.3rem;
  }
  
  @media (max-width: 1024px) {
    height: 2.5rem!important;
    width: 2.5rem!important;
  }
`

export const itemRender = (
  page: number,
  type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
  originalElement: React.ReactElement<HTMLElement>
) => {
  if (type === "prev") {
    return (
      <Button
        style={{
          border: "1px solid #DAE0E6",
          boxSizing: "border-box",
          borderRadius: "6px",
        }}
      >
        <ButtonText>Prev</ButtonText>
      </Button>
    );
  } else if (type === "next") {
    return (
      <Button
        style={{
          border: "1px solid #DAE0E6",
          boxSizing: "border-box",
          borderRadius: "6px",
        }}
      >
        <ButtonText>Next</ButtonText>
      </Button>
    );
  }

  return originalElement;
};

export default Todo;
