import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TRecipient} from "./referFriends";
import {Guid} from "guid-typescript";

export type TForwardContactRequest = {
  customMessage: string;
  ccSender: boolean;
  recipients: Array<TRecipient>;
  propertyId: Guid;
  contactId: Guid;

  isContactNameEnabled: boolean;
  isCompanyNameEnabled: boolean;
  isContactCategoryEnabled: boolean;
  isContactPhoneEnabled: boolean;
  isContactEmailEnabled: boolean;
  isContactWebsiteEnabled: boolean;
  isContactNotesEnabled: boolean;
  isPropertyAddressEnabled: boolean;
  attachments: Array<Guid>;
};

export const forwardContact = (payload: TForwardContactRequest) => {
  const {contactId} = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/forwardContact/${contactId}/send`
  );

  return apiPost<any, TForwardContactRequest>(
    url, payload
  );
};
