import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TGetMaintenancesResponse = Array<{
  id: Guid,
  propertyId: Guid,
  standardTriggerId: Guid,
  subscriptionId: number,
  category: string,
  title: string,
  message: string,
  estimatedCost: number,
  actualEstimatedCost: number,
  recordAs: any,
  periodicity: number,
  periodicityUnit: string,
  actualPeriodicity: number,
  actualPeriodicityUnit: string,
  startDate: string,
  startDateTemplate: number,
  startDateTemplateMonthToAdd: number,
  notApplicable: boolean,
  subscribed: boolean,
  recommended: boolean,
  savedRecordsCount: number,
  maintenanceKeywords: string,
  existingKeywords: string,
  foundByKeywords: boolean,
  alertTriggerType: string,
  triggerAlgorithmType: string,
  triggerJson: string,
  autocomplete: boolean,
  expenseType: any,
  workType: any,
  payee: any,
  nextDate: string,
  nextDateOriginal: string,
  standardInternalId: number,
  upkeepType: string
}>;

export const getMaintenances = (propertyId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/alerts/triggers`);

  return apiGet<TGetMaintenancesResponse | []>(url, {}, propertyId);
};
