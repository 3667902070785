import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {dismissWelcome, TDismissWelcomeResponse} from "../../api-wrapper/user/dismissWelcome";

export const dismissWelcomeInitAction = createAction(
  "userProfile/DISMISS_WELCOME_INIT"
)();
export const dismissWelcomeSuccessAction = createAction(
  "userProfile/DISMISS_WELCOME_SUCCESS"
)<TDismissWelcomeResponse>();
export const dismissWelcomeFailureAction = createAction(
  "userProfile/DISMISS_WELCOME_FAILURE"
)();

export type TDismissWelcomeInitAction = ActionType<
  typeof dismissWelcomeInitAction
  >;
export type TDismissWelcomeSuccessAction = ActionType<
  typeof dismissWelcomeSuccessAction
  >;
export type TDismissWelcomeFailureAction = ActionType<
  typeof dismissWelcomeFailureAction
  >;

export type TDismissWelcomeActions =
  | TDismissWelcomeInitAction
  | TDismissWelcomeSuccessAction
  | TDismissWelcomeFailureAction;

export type TDismissWelcomeThunkActionType = () => ThunkAction<
  void,
  TRootState,
  null,
  TDismissWelcomeActions
  >;

export const dismissLivLetWelcome: TDismissWelcomeThunkActionType = () => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(dismissWelcomeInitAction());
  return dismissWelcome()
    .then((payload) => {
      return dispatch(dismissWelcomeSuccessAction(payload));
    })
    .catch(() => dispatch(dismissWelcomeFailureAction()));
};

