import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";
import { TRootState } from "../reducers";
import {getUserProfile, TGetUserProfileResponse} from "../../api-wrapper/user/getUserProfile";

export const getUserProfileInitAction = createAction(
  "UserProfile/GET_UserProfile_INIT"
)();
export const getUserProfileSuccessAction = createAction(
  "UserProfile/GET_UserProfile_SUCCESS"
)<TGetUserProfileResponse>();
export const getUserProfileFailureAction = createAction(
  "UserProfile/GET_UserProfile_FAILURE"
)();

export type TGetUserProfileInitAction = ActionType<typeof getUserProfileInitAction>;
export type TGetUserProfileSuccessAction = ActionType<typeof getUserProfileSuccessAction>;
export type TGetUserProfileFailureAction = ActionType<typeof getUserProfileFailureAction>;

export type TGetUserProfileActions =
  | TGetUserProfileInitAction
  | TGetUserProfileSuccessAction
  | TGetUserProfileFailureAction;

export type TGetUserProfileThunkActionType = () => ThunkAction<void, TRootState, null, TGetUserProfileActions>;

export const getCurrentUserProfile: TGetUserProfileThunkActionType = () =>
  (dispatch: (action: Action | Promise<Action>) => any) => {
  dispatch(getUserProfileInitAction());
  return getUserProfile()
    .then((payload) => {
        if (payload) {
          return dispatch(getUserProfileSuccessAction(payload));
        } else {
          return dispatch(getUserProfileFailureAction())
        }
    })
    .catch(() => dispatch(getUserProfileFailureAction()));
};
