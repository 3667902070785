import React, {useState} from "react";
import {Button, Input, Modal, notification} from "antd";
import styled from "styled-components";
import {addNewTag} from "../../../api-wrapper/tags/addNewTag";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getTagsWithUsageCountSelector} from "../../selectors";
import {getAllTagsWithUsageCount} from "../../actions/tagsWithUsageCount";
import {ApplyButton, CancelButton} from "../../screens/Tags";

type Props = {
  isNewTagModalOpen: boolean;
  toggleNewTagModal: (arg: boolean) => void;
}

const NewTagsModal = (props: Props) => {
  const {isNewTagModalOpen, toggleNewTagModal} = props;
  const dispatch = useDispatch();
  const tags = useSelector(getTagsWithUsageCountSelector)
  const propertyId = useSelector(getPropertyIdSelector).value;
  const [inputValue, setInputValue] = useState("");

  function showTagError(tagList: string[], error: string) {
    notification.warning({
      message: "Some tags weren't added",
      description: <p>The following tags {error} and therefore weren't added: {tagList.join(", ")}</p>
    });
  }

  const addNewTags = async (tags: any) => {
    const newTagPromises = tags.map(async (tag: any) => {
      const response = await addNewTag(tag)
      return response.data?.tagId
    });
    return await Promise.all(newTagPromises);
  }

  const saveNewTags = async () => {
    const newTags: string[] = [];
    const databaseTags: string[] = [];
    const tagsToAdd: string[] = [];
    const existingTags: string[] = [];
    const tooLongTags: string[] = [];

    let tempTags = inputValue.split(",")
    tempTags.map((tag: string) => newTags.push(tag.trim().toUpperCase()))
    tags.content.map((tag: any) => databaseTags.push(tag.name))
    newTags.map((tag: string) => tag.length <= 64 ?
      (!databaseTags.includes(tag) ? tagsToAdd.push(tag) : existingTags.push(tag)) : tooLongTags.push(tag))

    let response = await addNewTags(tagsToAdd)
    existingTags.length && showTagError(existingTags, "already exist")
    tooLongTags.length && showTagError(tooLongTags, "are longer than 64 characters")

    if (response) {
      setInputValue("")
      toggleNewTagModal(false)
      dispatch(getAllTagsWithUsageCount(propertyId))
    }
  }

  const closeModal = () => {
    setInputValue("")
    toggleNewTagModal(false)
  }

  return <ModalStyled
    visible={isNewTagModalOpen}
    style={{ top: 10 }}
    closeIcon={<></>}
    onCancel={() => closeModal()}
    footer={null}
  >
    <ModalHeader>Add new tag(s)</ModalHeader>
    Enter 1 or multiple tags, separating them by comma. Eg., "custom tag 1, custom tag 2".
    <p style={{fontWeight: 600, lineHeight: "1.5rem", marginTop: "1.5rem"}}>
      Add new tag <span style={{color: "#E974C1"}}>*</span>
    </p>
    <Input
      style={{height: "3rem", borderRadius: "6px"}}
      type={"text"}
      placeholder={"Enter tag"}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
    />

    <div style={{
      marginTop: "1.5rem",
      paddingTop: "1.5rem",
      marginBottom: "0.5rem",
      borderTop: "1px solid #DAE0E6",
      fontWeight: 600,
      lineHeight: "1.5rem"}}>
      The following tags are in use in your Livlet Account.
    </div>
    <ExistingTagsWrapper>
      {tags.content.map(tag => tag.name.includes(inputValue.toUpperCase()) && <ExistingTagItem>
        {tag.name} ({tag.usageCount}) {!tag.subscriptionId ? "[System Tag]" : "[Custom Tag]"}
      </ExistingTagItem>)}
    </ExistingTagsWrapper>

    <ModalFooter>
      <CancelButton
        onClick={() => closeModal()}
        style={{background: "white", width: "50%"}}>
        Cancel</CancelButton>
      <ApplyButton
        onClick={() => saveNewTags()}
        style={{width: "50%"}}>
        Save</ApplyButton>
    </ModalFooter>
  </ModalStyled>
}

const ModalStyled = styled(Modal)`
  padding: 2rem;
  width: 36.75rem;
  
  & .ant-modal-body {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #21272B;
  }
  
  & .ant-modal-content {
    border-radius: 8px;
    width: 36.75rem;
  }
  
  @media (max-width: 1024px) {
    & .ant-modal-content {
      margin-left: -2rem;
      width: 22.5rem;
    }
  }
`

const ModalHeader = styled.div`
  font-size: 1.625rem;
  line-height: 1.75rem;
  color: #21272B;
  font-weight: 700;
  margin-bottom: 1.5rem;
`

const ModalFooter = styled.div`
  padding-top: 1.5rem;
  display: flex;
  column-gap: 1rem;
  width: 100%;
`

const ExistingTagsWrapper = styled.div` 
  height: 22.5rem;
  overflow-y: scroll;
  border: 1px solid #DAE0E6;
  border-radius: 6px;
  padding: 0.5rem;
  
  @media (max-width: 1024px) {
    height: 9.5rem;
  }
`

const ExistingTagItem = styled.div`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #EFF1F5;
  
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #45655E;
`

export default NewTagsModal;