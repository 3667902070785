
import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TRecipient} from "./referFriends";
import {Guid} from "guid-typescript";

export type TForwardAttachmentRequest = {
  customMessage: string;
  ccSender: boolean;
  recipients: Array<TRecipient>;
  propertyId: Guid;
  attachmentId: Guid;

  isPropertyAddressEnabled: boolean;
  isDescriptionEnabled: boolean;
};

export const forwardAttachment = (payload: TForwardAttachmentRequest) => {
  const {attachmentId} = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/forwardAttachment/${attachmentId}/send`
  );

  return apiPost<any, TForwardAttachmentRequest>(
    url, payload
  );
};
