import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TAddPropertyImageRequest = {
  propertyImage: any;
};

export const addPropertyImage = (payload: TAddPropertyImageRequest & {propertyId: Guid}) => {
  const {propertyId} = payload;
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/image`);
  let bodyFormData = new FormData();

  bodyFormData.append("propertyImage", payload.propertyImage);

  return apiPost<any, any>(url, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
