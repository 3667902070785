import { createUrl } from "../apiCall";
import EnvConfig from "../../config";
import _ from "lodash";
import axios from "axios";

export type TSignUpRequest = {
  name?: string | null;
  email: string;
  password: string;
  marketingEmails: boolean;
  token?: string | null;
  externalSourceName?: string | null;
  externalId?: string | null;
  externalSourceToken?: string | null;
  referral: string | null;
  website?: string | null;
};

export const registrationSimple = async (payload: TSignUpRequest) => {
  const { token } = payload;

  const url = createUrl(`${EnvConfig.API_URL}/api/user/register?token=${token}`);

  const uninterceptedAxios = axios.create()

  let {data, headers} = await uninterceptedAxios.post<{}, any>(url,
    _.omit(payload, ["token"]));

  return headers && headers.authorization
    ? {...data, accessToken: headers.authorization.split(":")[1], subscriptionOwner: true}
    : {...data, accessToken: " "};
};
