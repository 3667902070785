import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {ReactComponent as LivLetLogo} from "../../images/LivletLogoNew.svg";
import {ReactComponent as QuestionsInfo} from "../../images/onboarding/QuestionsInfo.svg";
import {Button, Progress} from "antd";
import {useHistory, useLocation} from "react-router-dom";
import QuestionsListComp from "../components/questions/QuestionsListComp";
import SkipQuestionsModal from "../components/questions/SkipQuestionsModal";
import {getAllImprovements} from "../actions/improvements";
import {TFetchingStatus} from "../../helpers";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as SaveReturnArrowBlue} from "../../images/SaveReturnArrowBlue.svg";
import {ReactComponent as BackButton} from "../../images/onboarding/BackButton.svg";
import {ReactComponent as LogoDots} from "../../images/onboarding/LogoDots.svg";
import {
  getImprovementsSelector,
  getPropertiesSelector,
  getPropertyIdSelector,
  getUserProfileSelector
} from "../selectors";
import {setUserWindowsState} from "../../api-wrapper/user/setUserWindowsState";
import {getCurrentUserProfile} from "../actions/getUserProfile";
import {getAllExpenseTypes} from "../actions/expenses";
import {getAllOnboardingMaintenances} from "../actions/onboardingMaintenances";
import {getAllMaintenances} from "../actions/maintenances";
import _ from "lodash";
import {emptyGuid} from "./helpers";
import {getAllProperties} from "../actions/properties";
import UpdateTaskDrawer, {getTaskType} from "../components/planner/UpdateTaskDrawer";
import AddEventDrawer from "../components/planner/AddEventDrawer";
import WorkDrawer from "../components/works/WorkDrawer";
import {getAllWorks} from "../actions/works";

const Questions = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);
  const userProfile = useSelector(getUserProfileSelector)
  const improvementsConfiguration = useSelector(getImprovementsSelector)

  const [questions, setQuestions] = useState<Array<any>>([]);
  const [propertyDetails, setPropertyDetails] = useState<Array<any>>([]);
  const [addressChanged, setAddressChanged] = useState(false);
  const [updateAddress, setUpdateAddress] = useState(false);
  const [showLoader, setShowLoader] = useState<string|null>(null);

  const [stepNumber, setStepNumber] = useState(1);
  const [isModalOpen, toggleModal] = useState(false)
  const [onboardingType, setOnboardingType] = useState("improvement");

  const [isTaskDrawerOpen, toggleTaskDrawer] = useState(false);
  const [editingTaskData, setEditingTaskData] = useState<any>(null);
  const [taskType, setTaskType] = useState("");
  const [isEventDrawerOpen, toggleEventDrawer] = useState(false);
  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editingWorkData, setEditingWorkData] = useState<any>(null);

  const [saveAction, toggleSaveAction] = useState(false);

  useEffect(() => {
    setTaskType(getTaskType(editingTaskData))
  }, [editingTaskData])

  const redirectUser = () => {
    pageOrigin === "contents" && history.push("/contents");
    pageOrigin === "spaces" && history.push("/spaces");
    pageOrigin === "guidance" && history.push("/guidance");
    pageOrigin === "home" && history.push("/home");
    pageOrigin === "dashboard" && history.push("/dashboard");
    pageOrigin === "upkeep" && history.push("/upkeep?tab=All");
    pageOrigin === "propertyDetails" && history.push(`/properties/${propertyId}`);
    pageOrigin === "planner" && history.push(`/planner`);
    !pageOrigin && history.push("/dashboard");
  }

  useEffect(() => {
    toggleSaveAction(false);
    dispatch(getAllWorks(propertyId));
    dispatch(getAllImprovements(propertyId));
    dispatch(getAllMaintenances(propertyId));
    dispatch(getAllOnboardingMaintenances(propertyId));
    dispatch(getCurrentUserProfile());
    dispatch(getAllExpenseTypes(propertyId));
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty)
      return;
    if (!currentProperty.questionnairePage) {
      setUserWindowsState({
        onboardingInterestSurveyStatus: userProfile.content.onboardingInterestSurveyStatus,
        onboardingUpkeepStatus: currentProperty.onboardingUpkeepStatus,
        onboardingContentStatus: currentProperty.onboardingContentStatus,
        onboardingImprovementsStatus: currentProperty.onboardingImprovementsStatus,
        mobileWelcomeStatus: userProfile.content.mobileWelcomeStatus,
        questionnairePage: 0,
        handholdingStatus: userProfile.content.handholdingStatus,
        propertyId
      }).then(() => {
        dispatch(getAllProperties());
      })
    }
    if (!currentProperty.questionnairePage || currentProperty.questionnairePage === 0) {
      setStepNumber(1);
    }
    setUpdateAddress(false);
  }, [dispatch, propertyId]);

  const setOnboardingStepNumber = (step: number) => {
    toggleSaveAction(false);
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty)
      return;
    if (currentProperty.questionnairePage > 7) {
      setStepNumber(step);
      return;
    }
    setUserWindowsState({
      onboardingInterestSurveyStatus: userProfile.content.onboardingInterestSurveyStatus,
      onboardingUpkeepStatus: currentProperty.onboardingUpkeepStatus,
      onboardingContentStatus: currentProperty.onboardingContentStatus,
      onboardingImprovementsStatus: currentProperty.onboardingImprovementsStatus,
      mobileWelcomeStatus: userProfile.content.mobileWelcomeStatus,
      questionnairePage: step,
      handholdingStatus: userProfile.content.handholdingStatus,
      propertyId
    }).then(() => {
      setStepNumber(step)
      dispatch(getAllProperties())
    })
  }

  const setContentOnboardingStatus = (status: string) => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty)
      return;
    setUserWindowsState({
      onboardingInterestSurveyStatus: userProfile.content.onboardingInterestSurveyStatus,
      onboardingUpkeepStatus: currentProperty.onboardingUpkeepStatus,
      onboardingContentStatus: status,
      onboardingImprovementsStatus: currentProperty.onboardingImprovementsStatus,
      mobileWelcomeStatus: userProfile.content.mobileWelcomeStatus,
      questionnairePage: currentProperty.questionnairePage,
      handholdingStatus: userProfile.content.handholdingStatus,
      propertyId
    }).then(() => dispatch(getAllProperties()))
  }

  const setImprovementsOnboardingStatus = (status: string) => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty)
      return;
    setUserWindowsState({
      onboardingInterestSurveyStatus: userProfile.content.onboardingInterestSurveyStatus,
      onboardingUpkeepStatus: currentProperty.onboardingUpkeepStatus,
      onboardingContentStatus: currentProperty.onboardingContentStatus,
      onboardingImprovementsStatus: status,
      mobileWelcomeStatus: userProfile.content.mobileWelcomeStatus,
      questionnairePage: 8,
      handholdingStatus: "spaces",
      propertyId
    }).then(() => {
      dispatch(getAllProperties())
      setStepNumber(8)
    })
  }

  const setUpkeepOnboardingStatus = (status: string) => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty)
      return;
    setUserWindowsState({
      onboardingInterestSurveyStatus: userProfile.content.onboardingInterestSurveyStatus,
      onboardingUpkeepStatus: status,
      onboardingContentStatus: currentProperty.onboardingContentStatus,
      onboardingImprovementsStatus: currentProperty.onboardingImprovementsStatus,
      mobileWelcomeStatus: userProfile.content.mobileWelcomeStatus,
      questionnairePage: currentProperty.questionnairePage,
      handholdingStatus: userProfile.content.handholdingStatus,
      propertyId
    }).then(() => dispatch(getAllProperties()))
  }

  useEffect(() => {
    if (improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success)
      return;
    setQuestions([...improvementsConfiguration.content.questions]);
    setPropertyDetails([...improvementsConfiguration.content.propertyDetails]);
    setShowLoader(null);
  }, [improvementsConfiguration]);

  const useQuery = (query: string) => {
    let queryValue = new URLSearchParams(useLocation().search);
    return queryValue.get(query);
  };
  const pageToOpen = useQuery("openPage")
  const pageOrigin = useQuery("origin")

  useEffect(() => {
    pageToOpen && setOnboardingType(pageToOpen)
  }, [pageToOpen])

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty)
      return;
    if (pageToOpen === "improvement" && currentProperty.onboardingImprovementsStatus !== "completed") {
      setStepNumber(currentProperty.questionnairePage)
    }
  }, [pageToOpen, userProfile])

  const nextPageClicked = () => {
    window.scrollTo(0, 0);
    if (stepNumber === 1 && addressChanged) {
      setUpdateAddress(true);
      // taken from here
    } else if (stepNumber === 7) {
      setUpdateAddress(false);
      setImprovementsOnboardingStatus("completed");
    } else {
      setUpdateAddress(false);
      setOnboardingStepNumber(stepNumber + 1)
    }
  }

  return (
    <>
      <WorkDrawer
        isOpen={isWorkDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        setEditingWorkData={setEditingWorkData}
        work={editingWorkData}
      />
      <AddEventDrawer
        isOpen={isEventDrawerOpen}
        toggleDrawer={toggleEventDrawer}
        setSelectedType={setTaskType}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditingWorkData={setEditingWorkData}
        toggleWorkDrawer={toggleWorkDrawer}
        isOnboarding={true}
      />
      <UpdateTaskDrawer
        task={editingTaskData}
        setEditingTaskData={setEditingTaskData}
        isOpen={isTaskDrawerOpen}
        toggleDrawer={toggleTaskDrawer}
        taskType={taskType}
        setTaskType={setTaskType}
      />
      <QuestionsWrapper>
        <SkipQuestionsModal
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          onboardingType={onboardingType}
          destination={pageOrigin} />
        {window.innerWidth > 1024 && <QuestionsHeader>
          <LivLetLogo className={"livlet-logo"} style={{flexShrink: 0.5, marginRight: "2rem"}}/>
          {stepNumber <= 7 && pageToOpen !== "contents" && pageToOpen !== "upkeep" && pageToOpen !== "spaces" && pageToOpen !== "budgetCosts" &&
          <PreviousButton onClick={() => setOnboardingStepNumber(stepNumber - 1)} disabled={stepNumber <= 1}>
              Previous
          </PreviousButton>}
          {stepNumber <= 7 && pageToOpen !== "contents" && pageToOpen !== "upkeep" && pageToOpen !== "spaces" && pageToOpen !== "budgetCosts" &&
          <StepsContainer>
              <StepsNumbers>
                  <StepItem className={"active"}>
                      <StepTitle>Address</StepTitle>
                      <StepCircle
                          className={"first-step"}
                          onClick={() => setOnboardingStepNumber(1)}
                      >1</StepCircle>
                  </StepItem>
                  <StepLine/>
                  <StepItem
                      className={stepNumber >= 2 ? "active" : ""}
                  >
                      <StepTitle>House Type</StepTitle>
                      <StepCircle
                          onClick={() => setOnboardingStepNumber(2)}
                      >2</StepCircle>
                  </StepItem>
                  <StepLine/>
                  <StepItem
                      className={stepNumber >= 3 ? "active" : ""}
                  >
                      <StepTitle>General</StepTitle>
                      <StepCircle
                          onClick={() => setOnboardingStepNumber(3)}
                      >3</StepCircle>
                  </StepItem>
                  <StepLine/>
                  <StepItem
                      className={stepNumber >= 4 ? "active" : ""}
                  >
                      <StepTitle>Heating system</StepTitle>
                      <StepCircle
                          onClick={() => setOnboardingStepNumber(4)}
                      >4</StepCircle>
                  </StepItem>
                  <StepLine/>
                  <StepItem
                      className={stepNumber >= 5 ? "active" : ""}
                  >
                      <StepTitle>Smart controls</StepTitle>
                      <StepCircle
                          onClick={() => setOnboardingStepNumber(5)}
                      >5</StepCircle>
                  </StepItem>
                  <StepLine/>
                  <StepItem
                      className={stepNumber >= 6 ? "active" : ""}
                  >
                      <StepTitle>Insulation</StepTitle>
                      <StepCircle
                          onClick={() => setOnboardingStepNumber(6)}
                      >6</StepCircle>
                  </StepItem>
                  <StepLine/>
                  <StepItem
                      className={stepNumber >= 7 ? "active" : ""}
                  >
                      <StepTitle>Other</StepTitle>
                      <StepCircle
                          className={"last-step"}
                          onClick={() => setOnboardingStepNumber(7)}
                      >7</StepCircle>
                  </StepItem>
              </StepsNumbers>
          </StepsContainer>}
          {pageToOpen !== "contents" && pageToOpen !== "upkeep" && pageToOpen !== "spaces" && pageToOpen !== "budgetCosts" && stepNumber <= 7 &&
          <NextButton disabled={showLoader !== null} onClick={() => nextPageClicked()}>
            {stepNumber === 7 ? "Done" : showLoader ? "Please wait..." : "Next"}
          </NextButton>}
          {pageToOpen !== "contents" && pageToOpen !== "upkeep" && pageToOpen !== "spaces" && pageToOpen !== "budgetCosts" && stepNumber === 8 &&
          <NextButton onClick={() => redirectUser()}>Close</NextButton>}
          {(pageToOpen === "contents" || pageToOpen === "upkeep" || pageToOpen === "spaces" || pageToOpen === "budgetCosts") &&
          <SaveAndReturnButton onClick={() => {
            pageToOpen === "contents" && setContentOnboardingStatus("completed")
            pageToOpen === "upkeep" && setUpkeepOnboardingStatus("completed")
            if (pageToOpen === "contents" || pageToOpen === "spaces")
              toggleSaveAction(true);
            else
              redirectUser();
          }}>
              Save and Return <SaveReturnArrowBlue style={{marginLeft: "0.5rem", flexShrink: 0}}/>
          </SaveAndReturnButton>}
        </QuestionsHeader>}
        <QuestionsMain>
          {window.innerWidth <= 1024 && stepNumber <= 9 && <div style={{width: "100%", padding:"1rem 1.5rem"}}>
              <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", fontWeight: 700, fontSize:"1.5rem"}}>
                  <BackButton onClick={() => stepNumber > 1 ? setOnboardingStepNumber(stepNumber - 1) : toggleModal(true)}/>
                  <span>Build your data home</span>
              </div>
              {pageToOpen !== "contents" && pageToOpen !== "upkeep" && pageToOpen !== "spaces" && pageToOpen !== "budgetCosts" && <div>
                  <div style={{color: "#6B7185", fontSize: "0.75rem", marginTop: "1.5rem", marginBottom: "0.5rem"}}>{stepNumber} of 9 questions about your home</div>
                  <Progress
                      percent={Math.floor(stepNumber/9 * 100)}
                      size="small" showInfo={false} strokeColor={"#00B74F"}/>
              </div>}
          </div>}
          <QuestionsListComp
            stepNumber={stepNumber}
            setOnboardingStepNumber={setOnboardingStepNumber}
            questions={questions}
            propertyDetails={propertyDetails}
            toggleEventDrawer={toggleEventDrawer}
            toggleUpkeepDrawer={toggleTaskDrawer}
            setEditingUpkeepData={setEditingTaskData}
            setAddressChanged={setAddressChanged}
            updateAddress={updateAddress}
            setUpdateAddress={setUpdateAddress}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            redirectUser={redirectUser}
            toggleWorkDrawer={toggleWorkDrawer}
            setEditingWorkData={setEditingWorkData}
            saveAction={saveAction}
          />
        </QuestionsMain>
        {window.innerWidth > 1024 && stepNumber < 8 && <QuestionsFooter>
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{
                  background: "#67C18B",
                  borderRadius: "8px",
                  width: "2rem",
                  height: "2rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                    <QuestionsInfo/>
                </div>
                <div style={{maxWidth: "20rem", marginLeft: "1rem", color: "#67C18B", fontWeight: 500, fontSize: "1rem"}}>
                  {window.innerWidth > 1024 ? "We are gathering this information to configure Livlet for your property"
                    : "This information will help to configure Livlet for your property"}
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
              {stepNumber < 8 && pageToOpen !== "upkeep" && pageToOpen !== "budgetCosts" &&  window.innerWidth > 1024 &&
                <SkipButton type={"link"} onClick={() => toggleModal(true)}>Skip for now</SkipButton>}
              {window.innerWidth < 1024 &&
              <SkipButton type={"link"} style={{fontSize: "1rem", lineHeight: "1.5rem"}} onClick={() => toggleModal(true)}>Skip</SkipButton>}

            </div>
        </QuestionsFooter>}
        {pageToOpen !== "contents" && pageToOpen !== "upkeep"&& pageToOpen !== "spaces" && pageToOpen !== "budgetCosts" && window.innerWidth <= 1024 && <QuestionsFooter>
            <div style={{display: "flex", flexDirection: "row", justifyContent:"space-between", alignItems:"center", width:"100%"}}>
              {stepNumber <= 9 && <PreviousButton onClick={() => stepNumber > 1 ? setOnboardingStepNumber(stepNumber - 1) : toggleModal(true)}>Previous</PreviousButton>}
              <LogoDots/>
              {stepNumber <= 9 &&
              <NextButton onClick={() => nextPageClicked()}>Next</NextButton>}
              {stepNumber === 10 &&
              <NextButton onClick={() => redirectUser()}>Close</NextButton>}
            </div>
        </QuestionsFooter>}
        {(pageToOpen === "contents" || pageToOpen === "upkeep"|| pageToOpen === "spaces" || pageToOpen === "budgetCosts") && window.innerWidth <= 1024 &&
        <QuestionsFooter>
            <SaveAndReturnButton
                style={{width:"10rem"}}
                onClick={() => {
                  pageToOpen === "contents" && setContentOnboardingStatus("completed")
                  pageToOpen === "upkeep" && setUpkeepOnboardingStatus("completed")
                  if (pageToOpen === "contents" || pageToOpen === "spaces")
                    toggleSaveAction(true);
                  else
                    redirectUser();
                }}>
                Save and Return <SaveReturnArrowBlue style={{marginLeft: "0.5rem", flexShrink: 0}}/>
            </SaveAndReturnButton>
        </QuestionsFooter>}
      </QuestionsWrapper>
    </>
  );
};

const QuestionsWrapper = styled.main`
  display: flex;
  flex-direction: column;
  color: #21272B;
  min-height: 100vh;
`;

const QuestionsHeader = styled.div`
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background: white;
  width: 100%;
  z-index: 100;
  position: fixed;
  border-bottom: 1px solid #EDEDED;

  @media (max-width: 1024px) {
    padding: 1rem;
    height: 3.5rem;
  }
`;

const QuestionsMain = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  background: #EDEDED;
  margin-top 5rem;
  
  @media (max-width: 1024px) {
    margin-top: 0rem;
  }
`;

const QuestionsFooter = styled.div`
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background: white;
  position: fixed;
  width: 100%;
  bottom: 0;  
  
  @media (max-width: 1024px) {
    padding: 1rem 1.375rem;
    height: auto;
    flex-direction: column;
    row-gap: 0.75rem;
  }
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 2;
`;

const StepsNumbers = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 60rem;
`;

const StepLine = styled.div`
  width: 100%;
  border-bottom: 3px solid #EDEDED; // rgba(42, 128, 225, 0.15);
  margin-bottom: 0.95rem;
`;

const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 6rem;
  
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  
  &.active > span {
    color: #002A43;
  }

  &.active > div {
    background-color: #128756;
    color: #fff;
    border: 0;
  }

`;

const StepTitle = styled.span`
  white-space: nowrap;
  font-size: 0.75rem;
  color: #6B7185;
  margin-bottom: 0.5rem;
`;

const StepCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-weight: 600;
  color: #128756;
  border: 1px solid #128756;
  background-color: #fff;
  border-radius: 100%;
  cursor: pointer;
  
  step-done: {
  }

  &:before {
    content: '';
    border-bottom: 3px solid #EDEDED; // rgba(42, 128, 225, 0.15);
    position: absolute;
    width: calc(50% - 1rem);
    left: 0;
  }

  &:after {
    content: '';
    border-bottom: 3px solid #EDEDED; // rgba(42, 128, 225, 0.15);
    position: absolute;
    width: calc(50% - 1rem);
    right: 0;
  }
  
  &.last-step:after {
   display: none;
  }

  &.first-step:before {
   display: none;
  }
`;

const NextButton = styled(Button)`
  display: flex;
  align-items: center;
  height: 3rem;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #128756;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  border: 1px solid #128756;
  border-radius: 6px;
  min-width: 6.25rem;
  transition: .5s ease all;
  
  &:hover { 
    border: 1px solid #128756;
    color: white;
    background-color: #22AB5B;
  }
  
 @media (max-width: 1024px) {
  width: 6rem;
 }
 @media (min-width: 1024px) {
  width: 10rem;
 }
`;

const PreviousButton = styled(NextButton)`
  background-color: #fff;
  border: 1px solid #128756;
  color: #128756;
  
  &:hover, &:focus { 
    background: white;
    border: 1px solid #22AB5B;
    color: #22AB5B;
  }
`;

const SaveAndReturnButton = styled(NextButton)`
  border: 2px solid #128756;
  background-color: #fff;
  color: #128756;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
 
  svg path { stroke: #128756; }
`;

const SkipButton = styled(Button)`
  color: black;
  &:hover { color: #128756; }
`

export default Questions;
