import React, {useEffect, useState} from "react";
import { ReactComponent as InventoryIcon } from "../../../images/AppliancesNew.svg";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import {ButtonsWrapper, CloseButton, WorksIconWrapper} from "../works/WorkDrawer";
import {Button, Checkbox, Drawer, Form, Input, notification} from "antd";
import {Label} from "../../screens/RegistrationPage";
import EmailCreatableSelector from "../EmailCreatableSelector";
import {EmailModal, PreviewBox} from "../SendViaEmail";
import ReactHtmlParser from "react-html-parser";
import {useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../selectors";
import {getRecipients, processForwardingError, safetyRegex, transformRecipients} from "../../screens/helpers";
import {
  ButtonOutlined,
  ButtonSolid,
  ButtonWrapper, ControlButton, Controls,
  ControlsWrapper,
  InputWrapper,
  Title
} from "../works/ForwardWorkDrawer";
import {forwardInventoryPreview} from "../../../api-wrapper/email/forwardInventoryPreview";
import {forwardInventory} from "../../../api-wrapper/email/forwardInventory";
import {CheckboxStyled, CheckboxWrapper} from "../contacts/ForwardContactDrawer";
import AttachFile from "../attachments/AttachFile";
import {getInventoryById} from "../../../api-wrapper/inventories/getInventoryById";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  inventoryId: any;
}

const ForwardInventoryDrawer = (props: Props) => {
  const propertyId = useSelector(getPropertyIdSelector).value;
  const {isOpen, toggleDrawerOpen, inventoryId} = props;

  const [form] = Form.useForm();

  const [recipients, setRecipients] = useState<any>([])
  const [customMessage, setCustomMessage] = useState("")
  const [ccSender, setCcSender] = useState(false)
  const [isPreviewModalOpen, togglePreviewModal] = useState(false)
  const [preview, setPreview] = useState("")

  const [includePropertyAddress, setIncludePropertyAddress] = useState(false)
  const [includeName, setIncludeName] = useState(false)
  const [includePurchaseCost, setIncludePurchaseCost] = useState(false)
  const [includeApplianceType, setIncludeApplianceType] = useState(false)
  const [includeInsured, setIncludeInsured] = useState(false)
  const [includeType, setIncludeType] = useState(false)
  const [includeBrand, setIncludeBrand] = useState(false)
  const [includeModel, setIncludeModel] = useState(false)
  const [includeSerialNumber, setIncludeSerialNumber] = useState(false)
  const [includePurchaseDate, setIncludePurchaseDate] = useState(false)
  const [includeWarrantyExpiryDate, setIncludeWarrantyExpiryDate] = useState(false)
  const [includeNotes, setIncludeNotes] = useState(false)
  const [includeFiles, setIncludeFiles] = useState(false)
  const [filesToForward, setFilesToForward] = useState<any>([])
  const [isForwardingAllowed, setIsForwardingAllowed] = useState(true)

  useEffect(() => {
    isOpen && getInventoryById(propertyId, inventoryId).then((res) =>
      res && setFilesToForward(res.attachments)
    );
  }, [inventoryId, isOpen])

  function restoreInitialState() {
    setRecipients([])
    setCustomMessage("")
    setCcSender(false)
    setPreview("")
    toggleDrawerOpen(false)
    setIncludeType(false)
    setIncludeBrand(false)
    setIncludeModel(false)
    setIncludeSerialNumber(false)
    setIncludePurchaseDate(false)
    setIncludeWarrantyExpiryDate(false)
    setIncludeNotes(false)
    setIncludePropertyAddress(false)
    setIncludeName(false)
    setIncludePurchaseCost(false)
    setIncludeApplianceType(false)
    setIncludeInsured(false)
    setIncludeFiles(false)
    setFilesToForward([])
  }
  
  function selectAll() {
    setIncludeType(true)
    setIncludeBrand(true)
    setIncludeModel(true)
    setIncludeSerialNumber(true)
    setIncludePurchaseDate(true)
    setIncludeWarrantyExpiryDate(true)
    setIncludeNotes(true)
    setIncludePropertyAddress(true)
    setIncludeName(true)
    setIncludePurchaseCost(true)
    setIncludeApplianceType(true)
    setIncludeInsured(true)
    setIncludeFiles(true)
  }

  function deselectAll() {
    setIncludeType(false)
    setIncludeBrand(false)
    setIncludeModel(false)
    setIncludeSerialNumber(false)
    setIncludePurchaseDate(false)
    setIncludeWarrantyExpiryDate(false)
    setIncludeNotes(false)
    setIncludePropertyAddress(false)
    setIncludeName(false)
    setIncludePurchaseCost(false)
    setIncludeApplianceType(false)
    setIncludeInsured(false)
    setIncludeFiles(false)
  }

  function handleForward(mode: string) {
    let sanitizedMessage = customMessage.replace(safetyRegex, '')
    let attachments: any = []
    filesToForward.map((file: any) => attachments.push(file.attachmentId))

    let dataToForward = {
      propertyId,
      inventoryId,
      recipients: getRecipients(transformRecipients(recipients)),
      ccSender,
      customMessage: sanitizedMessage,

      isPropertyAddressEnabled: includePropertyAddress,
      isInventoryNameEnabled: includeName,
      isInventoryTypeEnabled: includeType,
      isPurchaseCostEnabled: includePurchaseCost,
      isPurchaseDateEnabled: includePurchaseDate,
      isApplianceTypeEnabled: includeApplianceType,
      isBrandEnabled: includeBrand,
      isModelEnabled: includeModel,
      isSerialNumberEnabled: includeSerialNumber,
      isWarrantyExpiryDateEnabled: includeWarrantyExpiryDate,
      isInsuredEnabled: includeInsured,
      isInventoryNotesEnabled: includeNotes,
      attachments: includeFiles ? attachments : []
    }

    if (mode === "preview") {
      forwardInventoryPreview(dataToForward).then((res: any) => {
        setPreview(res.data.body)
        togglePreviewModal(true)
      })
    }
    if (mode === "send") {
      forwardInventory(dataToForward).then(() => {
        notification.info({
          message: 'Content has been forwarded successfully',
          placement: "topRight",
        })
        restoreInitialState()
      }).catch((errorResponse: any) => processForwardingError(errorResponse))
    }
  }

  return (<>
      <EmailModal
        width={800}
        style={{top: 10}}
        title={"Send message"}
        visible={isPreviewModalOpen}
        onCancel={() => togglePreviewModal(false)}
        closeIcon={<Button style={{marginLeft: '-3.8rem'}} type={'ghost'}>Cancel</Button>}
        footer={null}>
        <PreviewBox>{ReactHtmlParser(preview)}</PreviewBox>
      </EmailModal>

      <Drawer
        push={{ distance: "32px" }}
        closeIcon={false}
        width={window.innerWidth > 1024 ? "55%" : "100%"}
        visible={isOpen}
        placement="right"
        onClose={restoreInitialState}
        headerStyle={{
          backgroundColor: "#ebf9ff",
          margin: 0,
          padding: "2rem 2rem",
        }}
        title={
          <>
            <section style={{ display: "flex", justifyContent: "space-between" }}>
              <WorksIconWrapper>
                <InventoryIcon className={"works-theme-icon"} />{" "}
                <span style={{ paddingRight: "0.25rem", color: "#7479E9", fontSize: "0.875", textTransform: "uppercase" }}>
                  <b>Contents</b>
                </span>
              </WorksIconWrapper>
              <ButtonsWrapper>
                <CloseButton size={"large"}
                             onClick={restoreInitialState}
                >
                  {window.innerWidth > 1024 ?
                    <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
                </CloseButton>
              </ButtonsWrapper>
            </section>
            <section
              style={{
                marginTop: "1.875rem",
                marginBottom: "0.75rem"
              }}
            >
              <Title>Send message</Title>
            </section>
          </>
        }
      >
        <Form
          form={form}
          name="sendEmail"
          layout="vertical"
          size={"large"}
        >
          <InputWrapper>
            <Label style={{marginBottom: "0.75rem"}}>To:</Label>
            <EmailCreatableSelector emailList={recipients} setEmailList={setRecipients} />
            <Checkbox
              style={{marginBottom: '1rem'}}
              checked={ccSender}
              onClick={() => setCcSender(!ccSender)}>
              CC me on this email
            </Checkbox>
            {recipients.length > 1 && ccSender && <p style={{fontWeight: 700, color: "#2a80e1"}}>
                Please note that you will receive {recipients.length} copies of this email.
            </p>}
          </InputWrapper>
          <InputWrapper style={{display: "flex", flexDirection: "column"}}>
            <ControlsWrapper>
              <Label style={{margin: 0}}>Select content fields to forward:</Label>
              <Controls>
                <ControlButton className={"select"} onClick={() => selectAll()}>Select all</ControlButton>
                <ControlButton className={"deselect"} onClick={() => deselectAll()}>Deselect all</ControlButton>
              </Controls>
            </ControlsWrapper>
            <CheckboxWrapper>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeName}
                onClick={() => setIncludeName(!includeName)}>
                Name
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeType}
                onClick={() => setIncludeType(!includeType)}>
                Type
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeApplianceType}
                onClick={() => setIncludeApplianceType(!includeApplianceType)}>
                Appliance type
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeBrand}
                onClick={() => setIncludeBrand(!includeBrand)}>
                Brand
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeModel}
                onClick={() => setIncludeModel(!includeModel)}>
                Model
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeSerialNumber}
                onClick={() => setIncludeSerialNumber(!includeSerialNumber)}>
                Serial number
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includePurchaseDate}
                onClick={() => setIncludePurchaseDate(!includePurchaseDate)}>
                Purchase date
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includePurchaseCost}
                onClick={() => setIncludePurchaseCost(!includePurchaseCost)}>
                Purchase cost
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeWarrantyExpiryDate}
                onClick={() => setIncludeWarrantyExpiryDate(!includeWarrantyExpiryDate)}>
                Warranty expiry date
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeInsured}
                onClick={() => setIncludeInsured(!includeInsured)}>
                Insured status
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeNotes}
                onClick={() => setIncludeNotes(!includeNotes)}>
                Notes
              </CheckboxStyled>
              <CheckboxStyled
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includePropertyAddress}
                onClick={() => setIncludePropertyAddress(!includePropertyAddress)}>
                Property address
              </CheckboxStyled>
              <Checkbox
                style={{marginBottom: '1rem', marginLeft: 0}}
                checked={includeFiles}
                onClick={() => setIncludeFiles(!includeFiles)}>
                Documents
              </Checkbox>
            </CheckboxWrapper>
          </InputWrapper>
          <InputWrapper style={{marginBottom: "5.5rem"}}>
            <Label style={{marginBottom: "0.75rem"}}>Your comments:</Label>
            <Form.Item>
              <Input
                style={{borderRadius: "5px"}}
                placeholder={"Add comments here..."}
                value={customMessage}
                onChange={(e) => setCustomMessage(e.target.value)}
              />
            </Form.Item>
            {includeFiles && <AttachFile
              filesToForward={filesToForward}
              setFilesToForward={setFilesToForward}
              isForwardingAllowed={isForwardingAllowed}
              setIsForwardingAllowed={setIsForwardingAllowed}
            />}
          </InputWrapper>
          <ButtonWrapper>
            <ButtonOutlined
              style={{borderRadius: '6px'}}
              type={"primary"}
              onClick={() => handleForward("preview")}
            >Preview</ButtonOutlined>
            <ButtonSolid
              style={{borderRadius: '6px'}}
              type={"primary"}
              onClick={() => handleForward("send")}
              disabled={recipients.length === 0 || !isForwardingAllowed}
            >Send</ButtonSolid>
          </ButtonWrapper>
        </Form>
      </Drawer>
    </>
  )
}

export default ForwardInventoryDrawer;