import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TUpcomingTask} from "./getUpcomingTasks";

type TCompleteTaskRequest = {
  task: TUpcomingTask,
  propertyId: Guid,
  completionDate: string,
  totalCost: number | null
}

export const completeTask = (payload: TCompleteTaskRequest) => {
  const { propertyId, completionDate, totalCost } = payload;

  let url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/alerts/complete?completionDate=${completionDate}&totalCost=${totalCost}`)

  return apiPost<{}, TUpcomingTask>(url, payload.task);
};