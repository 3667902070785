import React, {useEffect, useState} from "react";
import {Menu, Modal, notification, Tooltip} from "antd";
import styled from "styled-components";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  getAttachmentsSelector,
  getExpensesSelector,
  getPropertiesSelector,
  getPropertyIdSelector,
  getUserProfileSelector
} from "../../selectors";
import _ from "lodash";
import {emptyGuid, isUKProperty} from "../../screens/helpers";

import {ReactComponent as DashboardIconOutline} from "../../../images/menu/DashboardIconOutline.svg";
import {ReactComponent as CaretUpBlue} from "../../../images/menu/CaretUpBlue.svg";
import {ReactComponent as HouseIconOutline} from "../../../images/menu/HouseIconOutline.svg";
import {ReactComponent as PartnerServicesIcon} from "../../../images/menu/PartnerServicesIcon.svg";
import {ReactComponent as NotificationRedIcon} from "../../../images/NotificationRed.svg";
import {ReactComponent as MyAccountIcon} from "../../../images/menu/MyAccountIcon.svg";
import {ReactComponent as GuidanceIcon} from "../../../images/menu/GuidanceIcon.svg";
import {ReactComponent as SubmenuContentsIcon} from "../../../images/menu/MenuContents.svg";
import {ReactComponent as SubmenuExpensesIcon} from "../../../images/menu/MenuExpenses.svg";
import {ReactComponent as SubmenuDocumentsIcon} from "../../../images/menu/MenuDocuments.svg";
import {ReactComponent as SubmenuSpacesIcon} from "../../../images/menu/MenuSpaces.svg";
import {ReactComponent as SubmenuContactsIcon} from "../../../images/menu/MenuContacts.svg";
import {ReactComponent as SubmenuNotesIcon} from "../../../images/menu/MenuNotes.svg";
import {ReactComponent as SubmenuMyPropertyIcon} from "../../../images/menu/MenuMyProperty.svg";
import {ReactComponent as SubmenuScheduleIcon} from "../../../images/menu/MenuSchedule.svg";
import {ReactComponent as SubmenuProjectsIcon} from "../../../images/menu/MenuProjects.svg";
import {ReactComponent as SubmenuMaintenancesIcon} from "../../../images/menu/MenuMaintenances.svg";
import {ReactComponent as SubmenuSimulatorIcon} from "../../../images/menu/MenuSimulator.svg";
import {ReactComponent as HomePlannerIcon} from "../../../images/menu/HomePlannerIcon.svg";

import {getUpcomingTasks} from "../../../api-wrapper/upcomingTasks/getUpcomingTasks";
import {getAllAttachments} from "../../actions/attachments";
import {getCurrentUserProfile} from "../../actions/getUserProfile";
import {getUserWarnings} from "../../../api-wrapper/attachments/getUserWarnings";
import {getExpenses, getExpensesWithoutProperty} from "../../../api-wrapper/expenses/getExpenses";
import {dismissUserWarnings} from "../../../api-wrapper/attachments/dismissUserWarnings";
import {ReactComponent as HandholdingArrowRight} from "../../../images/HandholdingArrowRight.svg";
import {HandholdingCircle} from "../../screens/Home";
import {TFetchingStatus} from "../../../helpers";
import {getAttachments} from "../../../api-wrapper/attachments/getAllAttachments";
import {getEmailedFilesState} from "../../../localStorage";

import moment from "moment";

const { SubMenu } = Menu;

const pageConfiguration = {
  dashboard: "my-dashboard",
  // home-information
  records: "home-information",
  contents: "home-information",
  documents: "home-information",
  notes: "home-information",
  contacts: "home-information",
  expenses: "home-information",
  spaces: "home-information",
  properties: "home-information",
  // plans-projects
  planner: "plans-projects",
  upkeep: "plans-projects",
  projects: "plans-projects",
  "value-simulator": "plans-projects",
  // guidance
  guidance: "guidance",
  // my-account
  account: "my-account",
  news: "my-account",
  tags: "my-account",
  //partners
  partners: "partners"
}

type Props = {
  setVisible?: (arg: boolean) => void;
}

export const dismissWarnings = (warnings: Array<any>, refreshWarnings: any) => {
  let idsToDismiss: any = []
  warnings.map((error: any) => idsToDismiss.push(error.warningId))
  dismissUserWarnings(idsToDismiss.join(',')).then(() => {
    notification.success({
      message: "Dismissed warnings",
      description: "All warnings have been dismissed successfully!",
      placement: "topRight",
    })
    refreshWarnings()
  })
}

export function showFileUploadErrorPopup(fileUploadErrors: Array<any>, dismissErrors: any, refreshWarnings: any) {
  Modal.confirm({
    title: "Error during document upload",
    width: window.innerWidth > 768 ? "600px" : "auto",
    content:
      <>
        Some of the files you sent us via email could not be uploaded for the following reasons:
        <ul style={{marginTop: "0.75rem"}}>
          {fileUploadErrors.map((error: any) => <li key={error.warningId}><b>{error.fileName} [{moment(error.obtainingDate).format("YYYY-MM-DD")}]:</b> {error.message}</li>)}
        </ul>
        You can find out more about our file size limits in
        the <a href={'https://www.livlet.com/faq?question=files'} target={"_blank"} rel={"noreferrer"}>FAQ</a>. We will store your email for 7 days just in case. If you need the
        attachment back - contact us at <a href="mailto:support.uk@livlet.com">support.uk@livlet.com</a>.</>,
    okText: "Dismiss all",
    onOk: () => dismissWarnings(fileUploadErrors, refreshWarnings),
    className: "form-confirm-close-modal"
  });
}

export function showInvoiceUploadErrorPopup(invoiceUploadErrors: Array<any>, dismissErrors: any, refreshWarnings: any) {
  Modal.confirm({
    title: "Error during invoice upload",
    content:
      <>
        Some of the files you sent us via email could not be uploaded for the following reasons:
        <ul style={{marginTop: "0.75rem"}}>
          {invoiceUploadErrors.map((error: any) => <li key={error.warningId}><b>{error.fileName} [{moment(error.obtainingDate).format("YYYY-MM-DD")}]:</b> {error.message}</li>)}
        </ul>
        You can find out more about our file size limits in
        the <a href={'https://www.livlet.com/faq?question=files'} target={"_blank"} rel={"noreferrer"}>FAQ</a>.
      </>,
    okText: "Dismiss all",
    onOk: () => dismissWarnings(invoiceUploadErrors, refreshWarnings),
    className: "form-confirm-close-modal"
  });
}

const MenuLivlet = (props: Props) => {
  const {setVisible} = props;

  let history = useHistory();
  const location = useLocation();
  const properties = useSelector(getPropertiesSelector)
  const propertyId = useSelector(getPropertyIdSelector).value;
  let dispatch = useDispatch();

  const attachments = useSelector(getAttachmentsSelector);
  const expenses = useSelector(getExpensesSelector);
  const userProfile = useSelector(getUserProfileSelector);

  const [ringingBell, setRingingBell] = useState(false);
  const [pendingAttachments, setPendingAttachments] = useState(0);
  const [pendingCosts, setPendingCosts] = useState(0);
  const [openedPage, setOpenedPage] = useState<any>("");
  const [openedTabs, setOpenedTabs] = useState<any>(["my-dashboard"]);
  const [openedTabsMobile, setOpenedTabsMobile] = useState<any>([]);
  const [fileUploadErrors, setFileUploadErrors] = useState([]);
  const [invoiceUploadErrors, setInvoiceUploadErrors] = useState([]);
  const [currentProperty, setCurrentProperty] = useState<any>(null);
  const [handholdingStatus, setHandholdingStatus] = useState<any>(null);

  const ukProperty = currentProperty && isUKProperty(currentProperty?.country, currentProperty?.zip)

  useEffect(() => {
    if (userProfile.fetchingStatus === TFetchingStatus.Success) {
      setHandholdingStatus(userProfile.content.handholdingStatus);
    }
  }, [userProfile])

  useEffect(() => {
    const current = properties.find((property) => property.propertyId === propertyId);
    if (!current)
      return;
    setCurrentProperty(current);
  }, [properties, propertyId])

  const refreshWarnings = () => {
    dispatch(getAllAttachments(propertyId));
    dispatch(getCurrentUserProfile());
    getUserWarnings().then((res: any) => {
      setFileUploadErrors(res.filter((warning: any) => warning.dataType === "attachment"))
      setInvoiceUploadErrors(res.filter((warning: any) => warning.dataType === "expense"))
    })
  }

  useEffect(() => {
    refreshWarnings()
  }, [])

  const isTabOpen = (tabName: string) =>
    window.innerWidth > 1024 ? openedTabs.includes(tabName) : openedTabsMobile.includes(tabName)

  const toggleTab = (tabName: string) => {
    window.innerWidth > 1024 ?
      (isTabOpen(tabName)) ? setOpenedTabs([]) : setOpenedTabs([tabName]) :
      (isTabOpen(tabName)) ? setOpenedTabsMobile([]) : setOpenedTabsMobile([tabName])
  }

  const openPage = (pageName: string, pageUrl: string) => {
    setOpenedPage(pageName)
    history.push(pageUrl)
    setVisible && setVisible(false)
  }

  useEffect(() => {
    if (attachments.fetchingStatus !== TFetchingStatus.Success || userProfile.fetchingStatus !== TFetchingStatus.Success)
      return;
    if (properties.length === 1) {
      let filesNumber = attachments.content.filter(attachment => attachment.source === "email" && userProfile.content.lastLogin < attachment.uploadedDate).length;
      const state = JSON.parse(getEmailedFilesState()+"");
      if (Array.isArray(state) && state.length > 0) {
        filesNumber = filesNumber - state.length;
      }
      setPendingAttachments(filesNumber);
    } else {
      getAttachments(propertyId, true).then(payload => payload && setPendingAttachments(payload.length));
    }
  }, [attachments.content, userProfile.content])

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    getExpenses(propertyId, false).then((pending) => {
      if (!pending)
        return;
      getExpensesWithoutProperty(propertyId).then(withoutProperty => {
        if (withoutProperty) {
          setPendingCosts(pending.length+withoutProperty.length);
        } else {
          setPendingCosts(pending.length)
        }
      });
    })
  }, [expenses])

  useEffect(() => {
    const currentPage = location.pathname.split("/")[1].split("?")[0];

    // @ts-ignore
    if (currentPage) {
      setOpenedPage(currentPage)
      // @ts-ignore
      setOpenedTabs([pageConfiguration[currentPage]])
    }
  }, [location])

  useEffect(() => {
    if (_.isEmpty(properties) || _.isEqual(properties[0].propertyId, emptyGuid.toJSON()))
      return;
    getUpcomingTasks({
      propertyId: propertyId,
      startDate: null,
      endDate: null,
      firstInSeries: true
    }).then(res => res && setRingingBell(res.tasks.length > 0))
  }, [propertyId])

  return <Menu
    mode="inline"
    className={"livlet-navigation"}
    defaultSelectedKeys={["dashboard"]}
    defaultOpenKeys={['my-dashboard']}
    selectedKeys={[openedPage]}
    openKeys={window.innerWidth > 1024 ? openedTabs : openedTabsMobile}
  >
    <SubMenu
      key="my-dashboard"
      onTitleClick={() => {
        toggleTab("my-dashboard")
        openPage("my-dashboard", "/dashboard")
      }}
      className={"livlet-navigation-icon"}
      icon={<DashboardIconOutline  />}
      title={<SubmenuTitleWrapper>Dashboard</SubmenuTitleWrapper>}>
    </SubMenu>
    <SubMenu
      key="home-information"
      icon={<HouseIconOutline />}
      onTitleClick={() => {
        toggleTab("home-information")
        openedPage !== "records" && window.innerWidth > 1024 && openPage("records", "/records")
      }}
      title={<SubmenuTitleWrapper>
        Home information
        {(handholdingStatus === "spaces" || handholdingStatus === "contents") && !isTabOpen("home-information")
          && <HandholdingCircle><HandholdingArrowRight /></HandholdingCircle>}
        {isTabOpen("home-information") && <CaretUpBlue/>}
      </SubmenuTitleWrapper>}>
      <Menu.Item key="contents" onClick={() => openPage("contents", "/contents")}>
        <SubmenuContentsIcon/> Contents
        {openedPage !== "contents" && handholdingStatus === "contents" &&
        <HandholdingCircle style={{marginLeft: "1rem", paddingLeft: "0.5rem"}}><HandholdingArrowRight /></HandholdingCircle>}
      </Menu.Item>
      <Menu.Item key="expenses" onClick={() => openPage("expenses", "/expenses")}>
        <SubmenuExpensesIcon/> Expenses
        <div style={{display: "flex"}}>
          {pendingCosts > 0 ? pendingCosts < 10 ? <Badge>{pendingCosts}</Badge> : <BadgeSmall>{pendingCosts}</BadgeSmall> : <></>}
          {invoiceUploadErrors.length > 0 &&
          <Badge style={{marginLeft: '0.75rem'}}
                 onClick={(e) => {
                   e.stopPropagation();
                   showInvoiceUploadErrorPopup(invoiceUploadErrors, dismissWarnings, refreshWarnings);
                 }}
          >!</Badge>}
        </div>
      </Menu.Item>
      <Menu.Item
        style={{ display: "flex", alignItems: "center" }}
        key="documents"
        onClick={() => openPage("documents", "/documents")}
      >
        <SubmenuDocumentsIcon/> Documents
        <div style={{display: "flex"}}>
          {pendingAttachments > 0 ? pendingAttachments < 10 ? <Badge>{pendingAttachments}</Badge> : <BadgeSmall>{pendingAttachments}</BadgeSmall> : <></>}
          {fileUploadErrors.length > 0 &&
          <Badge style={{marginLeft: '0.75rem'}}
                 onClick={(e) => {
                   e.stopPropagation();
                   showFileUploadErrorPopup(fileUploadErrors, dismissWarnings, refreshWarnings);
                 }}
          >!</Badge>}
        </div>
      </Menu.Item>
      <Menu.Item key="spaces" onClick={() => openPage("spaces", "/spaces")}>
        <SubmenuSpacesIcon/> Spaces
        {openedPage !== "home" && openedPage !== "spaces" && handholdingStatus === "spaces" &&
          <HandholdingCircle style={{marginLeft: "1rem", paddingLeft: "0.5rem"}}><HandholdingArrowRight /></HandholdingCircle>}
      </Menu.Item>
      <Menu.Item key="contacts" onClick={() => openPage("contacts", "/contacts")}>
        <SubmenuContactsIcon/> Contacts
      </Menu.Item>
      <Menu.Item key="notes" onClick={() => openPage("notes", "/notes")}>
        <SubmenuNotesIcon/> Notes
      </Menu.Item>
      <Menu.Item key="properties" onClick={() => openPage("properties", `/properties/${propertyId}`)}>
        <SubmenuMyPropertyIcon/> Property details
      </Menu.Item>
    </SubMenu>
    <SubMenu
      key="plans-projects"
      icon={<HomePlannerIcon />}
      title={<SubmenuTitleWrapper>
        Plans & projects {isTabOpen("plans-projects") && <CaretUpBlue/>}
      </SubmenuTitleWrapper>}
      onTitleClick={() => {
        toggleTab("plans-projects")
        openedPage !== "planner" && window.innerWidth > 1024 && openPage("planner", "/planner")
      }}
    >
      <Menu.Item key="planner" onClick={() => openPage("planner", "/planner")}>
        <SubmenuScheduleIcon/> Schedule & calendar
        {ringingBell && <Tooltip
            className={"bell-icon"}
            placement="right"
            title={"You have overdue reminders!"}>
            <BellIcon><NotificationRedIcon style={{marginLeft: '0.5rem', marginBottom: '-0.3rem', width: '12px'}} /></BellIcon>
        </Tooltip>}
      </Menu.Item>
      <Menu.Item key="projects" onClick={() => openPage("projects", "/projects")}>
        <SubmenuProjectsIcon/> Projects
      </Menu.Item>
      <Menu.Item key="upkeep" onClick={() => openPage("upkeep", "/upkeep?tab=My maintenances")}>
        <SubmenuMaintenancesIcon/> Maintenance checklist
      </Menu.Item>
      {ukProperty &&
      <Menu.Item key="value-simulator" onClick={() => openPage("value-simulator", "/value-simulator")}>
        <SubmenuSimulatorIcon/> Home value Simulator
      </Menu.Item>}
    </SubMenu>
    {ukProperty && <SubMenu
      key="guidance"
      icon={<GuidanceIcon/>}
      title={<SubmenuTitleWrapper>
        Guidance
      </SubmenuTitleWrapper>}
      onTitleClick={() => {
        openPage("guidance", "/guidance")
        toggleTab("guidance")
      }}>
    </SubMenu>}
    {/*{ukProperty && <SubMenu*/}
    {/*  key="partners"*/}
    {/*  onTitleClick={() => {*/}
    {/*    toggleTab("partners")*/}
    {/*    openPage("partners", "/partners")*/}
    {/*  }}*/}
    {/*  className={"livlet-navigation-icon"}*/}
    {/*  icon={<PartnerServicesIcon/>}*/}
    {/*  title={<SubmenuTitleWrapper>Partner services</SubmenuTitleWrapper>}>*/}
    {/*</SubMenu>}*/}
    {window.innerWidth < 1024 && <SubMenu
      key="my-account"
      icon={<MyAccountIcon/>}
      onTitleClick={() => toggleTab("my-account")}
      title={<SubmenuTitleWrapper>
        My Account {isTabOpen("my-account") && <CaretUpBlue/>}
      </SubmenuTitleWrapper>}>
      <Menu.Item key="account" onClick={() => openPage("account", "/settings")}>
        Account details
      </Menu.Item>
      <Menu.Item key="propertyDetails" onClick={() => openPage("propertyDetails", `/properties/${propertyId}`)}>
          Property details
      </Menu.Item>
      <Menu.Item key="news" onClick={() => openPage("news", "/news")}>
        My news
      </Menu.Item>
      <Menu.Item key="tags" onClick={() => openPage("tags", "/tags")}>
        My tags
      </Menu.Item>
    </SubMenu>}
  </Menu>
}

const SubmenuTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem!important;
`

const BellIcon = styled.span`
  svg path {
    fill: #eb4034!important;
  }
`

export const Badge = styled.div`
  background: #2979FF;
  width: 1.3rem;
  height: 1.3rem;
  margin-left: 1rem;
  font-size: 1.125rem;
  border-radius: 100%;
  color: white;
  line-height: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
`

export const BadgeSmall = styled(Badge)`
  font-size: 0.75rem;
`


export default MenuLivlet;