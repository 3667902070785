import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TRecipient} from "./referFriends";
import {Guid} from "guid-typescript";

export type TForwardNoteRequest = {
  customMessage: string;
  ccSender: boolean;
  recipients: Array<TRecipient>;
  propertyId: Guid;
  noteId: Guid;

  isTitleEnabled: boolean;
  isMessageEnabled: boolean;
  isPropertyAddressEnabled: boolean;
};

export const forwardNote = (payload: TForwardNoteRequest) => {
  const {noteId} = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/forwardNote/${noteId}/send`
  );

  return apiPost<any, TForwardNoteRequest>(
    url, payload
  );
};
