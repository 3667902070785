import React, {useEffect, useState} from "react"
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getMaintenancesSelector, getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import {getUpcomingTasks} from "../../../api-wrapper/upcomingTasks/getUpcomingTasks";
import moment from "moment";
import {ReactComponent as PlannerArrowLeft} from "../../../images/planner/PlannerArrowLeft.svg";
import {ReactComponent as PlannerArrowRight} from "../../../images/planner/PlannerArrowRight.svg";
import ToDoListItem from "./ToDoListItem";
import {getYearlySummary} from "../../../api-wrapper/upcomingTasks/getYearlySummary";
import {capitalize} from "../helper";
import {getShowExpenses, setShowExpenses} from "../../../localStorage";
import _ from "lodash";
import {emptyGuid} from "../../screens/helpers";
import {TFetchingStatus, TTaskCategories} from "../../../helpers";
import {CloseOutlined} from "@ant-design/icons";
import {putDismissOnboarding} from "../../../api-wrapper/properties/putDismissOnboarding";
import {getAllProperties} from "../../actions/properties";
import {ViewAllButton} from "../dashboardWidgets/ImprovementsWidget";
import {ReactComponent as ViewAllArrow} from "../../../images/dashboard-improvements/ViewAllArrow.svg";
import {WidgetPlaceholderText} from "../upcomingTasksCalendar/UpcomingTaskCalendar";
import {useHistory} from "react-router-dom";

type Props = {
  handleReminderAction: (arg1: any, arg2: any) => void;
  handleWorkAction: (arg1: any, arg2: any) => void;
  handleExpenseAction: (arg1: any, arg2: any) => void;
  refreshRequest: boolean;
  toggleRefreshRequest: (arg: boolean) => void;
  resetSwiping: boolean;
  toggleResetSwiping: (arg: boolean) => void;
}

const PlannerCalendar = (props: Props) => {
  const { handleReminderAction, handleWorkAction, handleExpenseAction, refreshRequest,
    toggleRefreshRequest, resetSwiping, toggleResetSwiping } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const maintenances = useSelector(getMaintenancesSelector);
  const properties = useSelector(getPropertiesSelector);

  const weekDays = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  const monthsMap = {
    JANUARY: 0,
    FEBRUARY: 1,
    MARCH: 2,
    APRIL: 3,
    MAY: 4,
    JUNE: 5,
    JULY: 6,
    AUGUST: 7,
    SEPTEMBER: 8,
    OCTOBER: 9,
    NOVEMBER: 10,
    DECEMBER: 11
  }
  const now = new Date();

  const [selectedDate, setSelectedDate] = useState<any>(moment());
  const [userSelectedDate, setUserSelectedDate] = useState<any>(null);
  const [calendarUnits, setCalendarUnits] = useState<any>([]);
  const [selectedScope, setSelectedScope] = useState("month");
  const [tasksToShow, setTasksToShow] = useState<any>([]);
  const [showPlaceholderWidget, setShowPlaceholderWidget] = useState(false)

  const localstorageExpenseTasks = getShowExpenses();
  const [showExpenseTasks, toggleShowExpenseTasks] = useState(false);

  useEffect(() => {
    if (!localstorageExpenseTasks || localstorageExpenseTasks === "false") {
      toggleShowExpenseTasks(false)
    } else toggleShowExpenseTasks(true)
  }, [localstorageExpenseTasks])

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    if (maintenances.fetchingStatus === TFetchingStatus.Success) {
      if (maintenances.content.filter(item => item.subscribed).length >= 1) {
        setShowPlaceholderWidget(false)
      } else {
        if (properties.find((property) => property.propertyId === propertyId)?.dismissSchedule)
          setShowPlaceholderWidget(false)
        else
          setShowPlaceholderWidget(true)
      }
    }
  }, [maintenances, properties])

  const generateCalendarDays = async (selectedDate: any) => {
    let calendarDate = selectedDate.clone();
    let lastDate = selectedDate.clone();
    let startOfScope = selectedDate.clone();
    let endOfScope = selectedDate.clone();

    calendarDate.startOf(selectedScope).startOf("isoWeek");
    lastDate.endOf(selectedScope).endOf("isoWeek");
    startOfScope.startOf(selectedScope);
    endOfScope.endOf(selectedScope);

    let worksMap = new Map();
    let remindersMap = new Map();
    let budgetCostsMap = new Map();
    let tasksMap = new Map();
    let expensesMap = new Map();
    let overdueItemsMap = new Map();
    let overdueExpensesMap = new Map();

    while (calendarDate.isSameOrBefore(lastDate)) {
      worksMap.set(calendarDate.format("YYYY-MM-DD"), false);
      remindersMap.set(calendarDate.format("YYYY-MM-DD"), false);
      budgetCostsMap.set(calendarDate.format("YYYY-MM-DD"), false);
      tasksMap.set(calendarDate.format("YYYY-MM-DD"), false);
      expensesMap.set(calendarDate.format("YYYY-MM-DD"), false);
      overdueItemsMap.set(calendarDate.format("YYYY-MM-DD"), false);
      overdueExpensesMap.set(calendarDate.format("YYYY-MM-DD"), false);
      calendarDate.add(1, "day")
    }

    const tasks = await getUpcomingTasks({
        propertyId: propertyId,
        startDate: startOfScope.format("YYYY-MM-DD"),
        endDate: endOfScope.format("YYYY-MM-DD"),
        firstInSeries: false
      })

    if (tasks && tasks.tasks) {
      setTasksToShow(tasks.tasks);
      tasks.tasks.map(item => {
        if (!worksMap.get(item.date)) {
          if (item.type === "work") worksMap.set(item.date, true)
        }
        if (!expensesMap.get(item.date)) {
          if (item.type === "expense") expensesMap.set(item.date, true)
        }
        if (!remindersMap.get(item.date)) {
          if (item.task.category === "Reminder") remindersMap.set(item.date, true)
        }
        if (!budgetCostsMap.get(item.date)) {
          if (item.task.upkeepType && item.task.upkeepType === "recurringexpense") budgetCostsMap.set(item.date, true)
        }
        if (!tasksMap.get(item.date)) {
          if (item.task.category && item.task.upkeepType && !(item.task.category === "Reminder") && !(item.task.upkeepType === "recurringexpense"))
            tasksMap.set(item.date, true)
        }
        if (!overdueItemsMap.get(item.date) && moment(item.date).isBefore(now.setHours(0, 0, 0, 0))) {
          if (item.type === "work" && !item.task.completedDate) overdueItemsMap.set(item.date, true)
          if (item.type === "alert" && !item.task.completed) {
            if (item.task.category === TTaskCategories.Budget || item.task.category === TTaskCategories.CustomBudget) overdueExpensesMap.set(item.date, true)
            else overdueItemsMap.set(item.date, true)
          }
        }
      })
    }

    let days: any = []
    calendarDate = selectedDate.clone();
    calendarDate.startOf(selectedScope).startOf("isoWeek");

    while (calendarDate.isSameOrBefore(lastDate)) {
      let date = calendarDate.format("YYYY-MM-DD");
      days.push({
        date: date,
        works: worksMap.get(date),
        reminders: remindersMap.get(date),
        budgetCosts: budgetCostsMap.get(date),
        expenses: expensesMap.get(date),
        tasks: tasksMap.get(date),
        overdueItems: overdueItemsMap.get(date),
        overdueExpenses: overdueExpensesMap.get(date)
      })
      calendarDate.add(1, "day")
    }
    setCalendarUnits(days);
    toggleRefreshRequest(false);
  }

  const generateCalendarMonths = async (selectedDate: any) => {
    let summary = await getYearlySummary(propertyId, selectedDate.format("YYYY"), showExpenseTasks);
    setCalendarUnits(summary);
    toggleRefreshRequest(false);
  }

  useEffect(() => {
    if (selectedScope !== "year") {
      generateCalendarDays(selectedDate).then()
    }
  }, [selectedDate, selectedScope])

  useEffect(() => {
    if (selectedScope === "year") {
      generateCalendarMonths(selectedDate).then()
    }
  }, [selectedDate, selectedScope, showExpenseTasks])

  useEffect(() => {
    if (refreshRequest) {
      selectedScope === "year" ? generateCalendarMonths(selectedDate).then() : generateCalendarDays(selectedDate).then()
    }
  }, [refreshRequest])

  useEffect(() => {
    if (selectedScope !== "year" && userSelectedDate) {
      if (userSelectedDate.format("MM") !== selectedDate.format("MM")) {
        const newDate = userSelectedDate.clone()
        setSelectedDate(newDate)
      }
    }
  }, [userSelectedDate, selectedScope])

  const getClassName = (item: any) => {
    return `${selectedScope === "year" ? "month" : "day"} 
     ${showExpenseTasks && (item.overdueItems || item.overdueExpenses) && "overdue"}
     ${!showExpenseTasks && item.overdueItems && "overdue"}
     ${selectedScope !== "year" && item.date === moment(now.setHours(0, 0, 0, 0)).format("YYYY-MM-DD") && "today"}
     ${
      // @ts-ignore
      selectedScope !== "year" && moment.isMoment(userSelectedDate) && item.date === userSelectedDate.format("YYYY-MM-DD") && "selected"} 
     ${selectedScope !== "year" && moment(item.date).format("MM") !== selectedDate.format("MM") && "outbound"}`
  }

  const changeUnit = (direction: string, unit: string) => {
    const newDate = selectedDate.clone()
    if (direction === "back") {
      setSelectedDate(newDate.subtract(1, unit));
      setUserSelectedDate(null);
    } else {
      setSelectedDate(newDate.add(1, unit));
      setUserSelectedDate(null);
    }
  }

  const getHeaderDate = () => {
    if (userSelectedDate) {
      // @ts-ignore
      return userSelectedDate.format("MMMM D, YYYY")
    } else {
      return selectedScope === "year" ? selectedDate.format("YYYY") : selectedDate.format("MMMM YYYY")
    }
  }

  const getTasksToShow = () => tasksToShow
    .filter((task: any) => showExpenseTasks ? true : (task && task.task && task.task.upkeepType ? task.task.upkeepType !== "recurringexpense" : true))
    .filter((task: any) => showExpenseTasks ? true : task.type !== "expense")
    .slice(0, (selectedScope === "isoWeek" || userSelectedDate) ? tasksToShow.length : 3)
    .filter((task: any) => userSelectedDate ? userSelectedDate.format("YYYY-MM-DD") === task.date : true)

  const getMonthName = (monthName: string) => {
    if (monthName) return capitalize(monthName.toLowerCase())
  }

  const toggleMonthlyView = (isMonthlyView: boolean) => {
    if (isMonthlyView) {
      setSelectedScope("month");
    } else {
      if (!userSelectedDate) {
        let newDate = selectedDate.clone()
        newDate.startOf("month");
        setSelectedDate(newDate);
      }
      setSelectedScope("isoWeek");
    }
  }

  // const swipeView = useSwipeable({
  //   onSwipedUp: () => {
  //     setSelectedScope("month");
  //   },
  //   onSwipedDown: () => {
  //     let newDate = selectedDate.clone()
  //     newDate.startOf("month").startOf("isoWeek");
  //     setSelectedDate(newDate);
  //     setSelectedScope("isoWeek");
  //   },
  //   trackMouse: true
  // });
  //
  // const swipeChangeUnit = useSwipeable({
  //   onSwipedRight: () => changeUnit("back", selectedScope),
  //   onSwipedLeft: () => changeUnit("forward", selectedScope),
  //   trackMouse: true
  // });

  const placeholderWidget = () => (<WidgetPlaceholderText style={{margin: "0.25rem auto"}}>
    <CloseOutlined
      style={{position:"absolute", top: "0.5rem", right: "0.5rem", cursor:"pointer"}}
      onClick={() => putDismissOnboarding({propertyId, dismissSchedule: true})
        .then(() => dispatch(getAllProperties()))}
    />
    <span>The schedule view will highlight recommend and custom maintenance tasks, planned building projects and expenses.</span>
    <span style={{fontWeight: 600, color: "#21272B"}}>Let's start by selecting some maintenance tasks.</span>
    <ViewAllButton onClick={() => history.push("/questions?openPage=upkeep")}>
      Select tasks from maintenance checklist <ViewAllArrow/>
    </ViewAllButton>
  </WidgetPlaceholderText>)

  return showPlaceholderWidget ? placeholderWidget() : <OuterWrapper>
    <CalendarHeader>
      <PlannerArrowLeft
        style={{cursor: "pointer"}}
        onClick={() => changeUnit("back", selectedScope)}/>
      <span style={{cursor: "pointer"}} onClick={() => {
        if (selectedScope !== "year") {
          setUserSelectedDate(null);
          setSelectedScope("year");
        }
      }}>
        {getHeaderDate()}
      </span>
      <PlannerArrowRight
        style={{cursor: "pointer"}}
        onClick={() => changeUnit("forward", selectedScope)} />
    </CalendarHeader>
    <CalendarHeader style={{padding: "0 0.5rem"}}>
      <div className={`header-pill ${showExpenseTasks && "active"}`}
            onClick={() => {
              toggleShowExpenseTasks(!showExpenseTasks)
              setShowExpenses(!showExpenseTasks)
            }}>
        {showExpenseTasks ? "Hide" : "Show"} Expenses
      </div>
      <div style={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
        <div className={`header-pill ${selectedScope === "month" && "active"}`}
             onClick={() => toggleMonthlyView(true)}>Monthly</div>
        <div className={`header-pill ${selectedScope === "isoWeek" && "active"}`}
             onClick={() => toggleMonthlyView(false)}>Weekly</div>
      </div>
    </CalendarHeader>
    <CalendarWrapper className={selectedScope}>
      {selectedScope !== "year" && weekDays.map(day => <CalendarItem className={"month-name"}>{day}</CalendarItem>)}
      {calendarUnits.map((item: any) => <CalendarItem
        className={getClassName(item)}
        onClick={() => {
          if (selectedScope === "year") {
            toggleResetSwiping(true);
            setSelectedScope("month")
            let selectedMonth = selectedDate.clone()
            // @ts-ignore
            selectedMonth.set("month", monthsMap[item.monthName]).startOf("month")
            setSelectedDate(selectedMonth)
          } else {
            setSelectedDate(moment(item.date))
            // @ts-ignore
            userSelectedDate && userSelectedDate.format("YYYY-MM-DD") === item.date ?
              setUserSelectedDate(null) : setUserSelectedDate(moment(item.date))
          }
        }}
      >
        {selectedScope === "year" ? getMonthName(item.monthName) : moment(item.date).format("D")}
        <span style={{display: "flex", gap: "0.125rem"}}>
          {item.works && <Dot className={"works"} />}
          {item.reminders && <Dot className={"reminders"} />}
          {item.budgetCosts && showExpenseTasks && <Dot className={"budgets"} />}
          {item.tasks && <Dot className={"tasks"} />}
          {item.expenses && showExpenseTasks && <Dot className={"expenses"} />}
          {!item.works && !item.reminders && (!item.budgetCosts || !showExpenseTasks) && (!item.expenses || !showExpenseTasks)
            && !item.tasks && <Dot className={"placeholder"} />}
        </span>
      </CalendarItem>)}
    </CalendarWrapper>
    {selectedScope !== "year" && getTasksToShow().length > 0 && <TasksWrapper className={selectedScope === "isoWeek" ? "weekly" : ""}>
      {getTasksToShow().map((task: any) => <ToDoListItem
          item={task}
          handleReminderAction={handleReminderAction}
          handleWorkAction={handleWorkAction}
          handleExpenseAction={handleExpenseAction}
          resetSwiping={resetSwiping}
          toggleResetSwiping={toggleResetSwiping}
        />
      )}
    </TasksWrapper>}
  </OuterWrapper>
}

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // height: calc(100vh - 276px);
`

const CalendarHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 0 2rem;
  justify-content: space-between;
  align-items: center;
  
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #21272B;
   
  .header-pill {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    
    cursor: pointer;
    background: white;
    border-radius: 8px;
    color: #8A8A8A;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
  }
  
  .active { color: #009966; }
`

const CalendarWrapper = styled.div`
  width: 100%;
  background: #FFFFFF;
  border-radius: 24px;
  display: grid;
  grid-auto-rows: 1fr;
  transition: .5s all ease;

  &.year {
    padding: 24px 24px 16px;
    gap: 1rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  
  &.month, &.isoWeek {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
`

const CalendarItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
  
  &.selected { 
    color: white!important;
    background: #009966!important;
  }
  
  &.today {
    background: #EAF3FC;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #0C2A41;
  }

  &.overdue { background: rgba(239, 195, 93, 0.2); }
  &.outbound { color: #D6D6D6!important; }
  &.month-name { color: #45655E; }

  &.month {
    padding: 0.5rem;
    border-radius: 24px;
    color: #009966;
  }
  
  &.day {
    border-radius: 100%;
    color: #8A8A8A;
    aspect-ratio: 1 / 1;   
  }
`

const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  border: 1px solid #FFFFFF;
  
  &.works { background: #2A80E1; }
  &.tasks { background: #009966; }
  &.reminders { background: #EFC35D; }
  &.budgets { background: #7479E9; }
  &.placeholder { border: none; } 
  &.expenses { background: #E974C1; }
`

const TasksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-x: hidden;
  padding-bottom: 0.5rem;
  
  // &.weekly {
  //   height: calc(100vh - 435px);
  //   overflow-y: scroll;
  //   -ms-overflow-style: none;
  //   scrollbar-width: none;
  // }
  //
  // &.weekly::-webkit-scrollbar { display: none; }
`

export default PlannerCalendar