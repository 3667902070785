import React from "react";
import styled from "styled-components";
import {Input} from "antd";
import {useSelector} from "react-redux";
import {getPropertyPreferencesSelector} from "../../../selectors";
import { getCurrencySign } from "../../../screens/helpers";

type Props = {
  cost: number;
  setCost: (arg: number) => void;
}

const MaintenanceCost = (props: Props) => {
  const {cost, setCost} = props;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  return <EditableValueWrapper style={{maxWidth: "8.25rem"}}>
    Cost ({getCurrencySign(propertyPreferences.preferredCurrency)} )
    <EditableValueInputField prefix={getCurrencySign(propertyPreferences.preferredCurrency)} value={cost} onChange={(event) => {
      // @ts-ignore
      setCost(event.target.value)
    }} />
  </EditableValueWrapper>
}

export const EditableValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.25rem;
  width: 100%;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #0C2A41;
`

export const EditableValueInputField = styled(Input)`
  background: #FFFFFF;
  border: 1px solid rgba(42, 128, 225, 0.5);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  height: 2rem;
  
  font-size: 1rem;
  line-height: 1.5rem;
  color: #45655E;
  
  .ant-input {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #45655E;
    text-align: right;
  }
`

export default MaintenanceCost