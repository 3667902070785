import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {getTags, TGetTagsResponse} from "../../api-wrapper/tags/getTags";

export const getTagsInitAction = createAction("tags/GET_TAGS_INIT")();
export const getTagsSuccessAction = createAction(
  "tags/GET_TAGS_SUCCESS"
)<TGetTagsResponse>();
export const getTagsFailureAction = createAction("tags/GET_TAGS_FAILURE")();

export type TGetTagsInitAction = ActionType<typeof getTagsInitAction>;
export type TGetTagsSuccessAction = ActionType<typeof getTagsSuccessAction>;
export type TGetTagsFailureAction = ActionType<typeof getTagsFailureAction>;

export type TGetTagsActions =
  | TGetTagsInitAction
  | TGetTagsSuccessAction
  | TGetTagsFailureAction;

export type TGetTagsThunkActionType = () => ThunkAction<void, TRootState, null, TGetTagsActions>;

export const getAllTags: TGetTagsThunkActionType = () => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getTagsInitAction());
  return getTags()
    .then((payload) => {
      return dispatch(getTagsSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getTagsFailureAction()));
};
