import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Button, Input, List, Spin, Tag} from "antd";
import _ from "lodash";
import styled from "styled-components";

import {
  getContactsSelector,
  getExpensesSelector,
  getImprovementsSelector,
  getInsurancesSelector,
  getInventoriesSelector,
  getMaintenancesSelector,
  getNotesByPropertyIdSelector,
  getPropertyIdSelector,
  getPropertyPreferencesSelector,
  getPropertiesSelector,
  getTenantsSelector,
  getWorksSelector, getAttachmentsSelector, getPetsSelector,
} from "../selectors";
import {ReactComponent as SubmenuNotesIcon} from "../../images/menu/MenuNotes.svg";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import {
  search,
  TSearchFilters,
  TSearchItem,
  TSearchResponse,
} from "../../api-wrapper/search/search";
import { ReactComponent as WorkIcon } from "../../images/WorksNew.svg";
import { ReactComponent as ExpensesIcon } from "../../images/ExpensesNew.svg";
import { ReactComponent as FilterIcon } from "../../images/Filters.svg";
import { ReactComponent as DotIcon } from "../../images/DotForFilters.svg";
import { ReactComponent as InventoryIcon } from "../../images/AppliancesNew.svg";
import { ReactComponent as AttachmentIcon } from "../../images/AttachmentsYellow.svg";
import { ReactComponent as NoteIcon } from "../../images/Notes.svg";
import { ReactComponent as ContactIcon } from "../../images/Contacts.svg";
import { ReactComponent as HomeIcon } from "../../images/MyPropertyDashboard.svg";
import { ReactComponent as ReminderIcon } from "../../images/NotificationGray.svg";
import { ReactComponent as BudgetCostsIcon } from "../../images/BudgetCostsDrawerIcon.svg";
import { ReactComponent as MortgageIcon } from "../../images/search/Mortgage.svg";
import { ReactComponent as CertificateIcon } from "../../images/search/Certificate.svg";
import { getAllWorks } from "../actions/works";
import { getAllContacts } from "../actions/contacts";
import { useHistory, useLocation } from "react-router-dom";
import WorkDrawer from "../components/works/WorkDrawer";
import { getAllInventories } from "../actions/inventories";
import InventoryDrawer from "../components/inventories/InventoryDrawer";
import ExpenseDrawer from "../components/expenses/ExpenseDrawer";
import {getAllExpenses, getAllExpenseTypes} from "../actions/expenses";
import ContactDrawer from "../components/contacts/ContactDrawer";
import TenantDrawer from "../components/tenancy/TenantDrawer";
import { ReactComponent as GotoContactIcon } from "../../images/search/Goto.svg";
import {
  SubTag,
  TagStyledBoldRed,
} from "../components/properties/PropertyDetailsComp";
import { getAllTenants } from "../actions/tenants";
import {
  FilterElements, FilterElementsTypes,
  FilterGroupBottomRow,
  FilterGroupHeader,
  FilterGroupList,
  FilterGroupRow, FilterGroupRowSearch,
  Filters,
  FilterTypesGroupWrapperSearch, MainListColumn, MainListItemStyled,
} from "./styled";
import NoteDrawer from "../components/notes/NoteDrawer";
import {getAllNotesByPropertyId} from "../actions/notesByPropertyId";
import {Guid} from "guid-typescript";
import {emptyGuid, getCurrencySign} from "./helpers";
import {numberWithCommas} from "../components/helper";
import {TagStyledBold} from "../components/works/WorksListComp";
import InsuranceDrawer from "../components/insurance/InsuranceDrawer";
import {getAllInsurances} from "../actions/insurances";
import {ReactComponent as ECOIcon} from "../../images/ECO.svg";
import {getAllImprovements} from "../actions/improvements";
import {tenantInitialState} from "../components/tenancy/TenancyListComp";
import UpcomingTaskDrawer from "../components/upcomingTasks/UpcomingTaskDrawer";
import {getAllUpcomingTasks} from "../actions/upcomingTasks";
import {getAllMaintenances} from "../actions/maintenances";
import {
  CheckboxStyledDark,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  PaginationStyled
} from "./components";
import {ReactComponent as SpaceSearch} from "../../images/spaces/SpaceSearch.svg";
import ViewSpaceDrawer from "../components/spaces/ViewSpaceDrawer";
import {getAllSpaces} from "../actions/spaces";
import UpdateTaskDrawer, {getTaskType} from "../components/planner/UpdateTaskDrawer";
import ViewFileDrawer from "../components/attachments/ViewFileDrawer";
import {getAllAttachments} from "../actions/attachments";
import {getAttachments} from "../../api-wrapper/attachments/getAllAttachments";
import ViewWorkDrawer from "../components/works/ViewWorkDrawer";
import {initialNoteData, NoteItemImages} from "./Notes";
import {ReactComponent as Checkmark} from "../../images/planner/Checkmark.svg";
import TagContainer from "../components/tags/TagContainer";
import SwitchPropertyModal from "../components/search/SwitchPropertyModal";
import ViewTaskDrawer from "../components/planner/ViewTaskDrawer";
import {CancelButton, CategoryHeader} from "../components/tags/FilterTagsNew";
import PeriodFilter from "../components/filters/PeriodFilter";
import {ReactComponent as ArrowRightGrayIcon} from "../../images/ArrowRightGray.svg";
import {ReactComponent as ArrowLeftGrayIcon} from "../../images/ArrowLeftGray.svg";
import Slider from "react-slick";
import {LoadingOutlined} from "@ant-design/icons";
import {ReactComponent as CalendarTaskIcon} from "../../images/dashboard-calendar/CalendarTask.svg";
import {ReactComponent as CalendarReminderIcon} from "../../images/dashboard-calendar/CalendarReminder.svg";
import {ReactComponent as CalendarBudgetCostIcon} from "../../images/dashboard-calendar/CalendarBudgetCost.svg";
import {TFetchingStatus} from "../../helpers";
import LinkDrawer from "../components/spaces/LinkDrawer";
import EditFileDrawer from "../components/attachments/EditFileDrawer";
import EditSpaceDrawer from "../components/spaces/EditSpaceDrawer";
import {getExpensesWithoutProperty} from "../../api-wrapper/expenses/getExpenses";
import MortgageDrawer from "../components/mortgage/MortgageDrawer";
import CertificateDrawer from "../components/certificates/CertificateDrawer";
import DocumentDrawer from "../components/documents/DocumentDrawer";
import ItemLinks from "../components/spaces/ItemLinks";
import {LinkedItemTags} from "../components/spaces/LinkedItem";
import {ReactComponent as EmptyImagePreview} from "../../images/EmptyImagePreview.svg";
import {PetItemIcon} from "./Settings";
import PetDrawer from "../components/pets/PetDrawer";
import {getAllPets} from "../actions/pets";

const moment = require("moment");

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SearchPage = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const properties = useSelector(getPropertiesSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const works = useSelector(getWorksSelector);
  const expenses = useSelector(getExpensesSelector);
  const inventories = useSelector(getInventoriesSelector);
  const contacts = useSelector(getContactsSelector);
  const tenants = useSelector(getTenantsSelector);
  const notes = useSelector(getNotesByPropertyIdSelector);
  const insurances = useSelector(getInsurancesSelector);
  const attachments = useSelector(getAttachmentsSelector);
  const databaseMaintenances = useSelector(getMaintenancesSelector);
  const improvementsConfiguration = useSelector(getImprovementsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);
  const pets = useSelector(getPetsSelector);
  const searchInputElement = useRef(null);

  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState<moment.Moment|null>(null);
  const [endDate, setEndDate] = useState<moment.Moment|null>(null);
  const [selectedPredefinedDate, setSelectedPredefinedDate] = useState("custom");
  const [searchInput, setSearchInput] = useState("");
  const [searchSubmitted, setSearchSubmitted] = useState("");
  const [searchResults, setSearchResults] = useState<TSearchResponse | null>(
    null
  );
  const [topTotals, setTopTotals] = useState<any>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [isViewWorkDrawerOpen, toggleViewWorkDrawer] = useState(false);
  const [workToViewId, setWorkToViewId] = useState<any>(null);
  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false);
  const [isInventoryDrawerOpen, toggleInventoryDrawer] = useState(false);
  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [isTenantDrawerOpen, toggleTenantDrawer] = useState(false);
  const [expensesWithoutProperty, setExpensesWithoutProperty] = useState<any>([]);
  const [expenseData, setExpenseData] = useState<any>({
    data: {
      name: "",
      propertyId: emptyGuid,
      expenseId: emptyGuid,
      paymentDate: "",
      payee: emptyGuid,
      totalCost: 0,
      expenseType: "",
      taxDeductible: false,
      parentId: emptyGuid,
      parentType: "",
    },
  });
  const [inventoryData, setInventoryData] = useState<any>(null);

  const [workData, setWorkData] = useState<any>({
    data: {
      name: "",
      type: "",
      completedDate: "",
      dueDate: "",
      recurring: "",
      workId: emptyGuid,
      propertyId: emptyGuid,
      isComplete: false,
    },
  });
  const [tenantData, setTenantData] = useState({
    data: tenantInitialState,
  });

  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [contactData, setContactData] = useState<any>(null);

  const [isNoteDrawerOpen, toggleNoteDrawer] = useState(false);
  const [noteData, setNoteData] = useState<any>({data: initialNoteData});

  const [highlightNote, setHighlightNote] = useState<null | Guid>(null);
  const [highlightAttachment, setHighlightAttachment] = useState<null | Guid>(
    null
  );

  const [selectedProperties, setSelectedProperties] = useState<Array<Guid>>([]);
  const [sortedProperties, setSortedProperties] = useState<any>([]);
  const [dataTypes, setDataTypes] = useState<Array<string>>([]);
  const [todoWorks, setTodoWorks] = useState(false);
  const [completedWorks, setCompletedWorks] = useState(false);
  const [taxDeductibleExpenses, setTaxDeductibleExpenses] = useState(false);
  const [insuredItemsInventory, setInsuredItemsInventory] = useState(false);

  const [isInsuranceDrawerOpen, toggleInsuranceDrawer] = useState(false);
  const [insuranceData, setInsuranceData] = useState<any>(null)

  const [isReminderDrawerOpen, toggleReminderDrawer] = useState(false);
  const [reminderData, setReminderData] = useState<any>(null)

  const [isTaskDrawerOpen, toggleTaskDrawer] = useState(false);
  const [editingTaskData, setEditingTaskData] = useState<any>(null);
  const [isTaskViewDrawerOpen, toggleTaskViewDrawer] = useState(false);
  const [taskViewData, setTaskViewData] = useState<any>(null);
  const [taskType, setTaskType] = useState("");

  const [presetLinkType, setPresetLinkType] = useState<any>(null);
  const [isLinkDrawerOpen, toggleLinkDrawer] = useState(false);

  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [editTagsMode, toggleEditTagsMode] = useState(false);
  const [parentTypeForLinking, setParentTypeForLinking] = useState<any>();
  const [parentIdForLinking, setParentIdForLinking] = useState<any>();

  const [isSwitchPropertyModalOpen, toggleSwitchPropertyModal] = useState(false);
  const [switchPropertyDestPropertyId, setSwitchPropertyDestPropertyId] = useState<any>(null);
  const [switchPropertyForItem, setSwitchPropertyForItem] = useState<any>(null);
  const [isShowDrawer, toggleShowDrawer] = useState(false);

  const [isMortgageDrawerOpen, toggleMortgageDrawer] = useState(false);
  const [mortgageData, setEditingMortgageData] = useState<any>(null);
  const [isCertificateDrawerOpen, toggleCertificateDrawer] = useState(false);
  const [certificateData, setCertificateData] = useState<any>(null);
  const [isDocumentDrawerOpen, toggleDocumentDrawer] = useState(false);
  const [document, setDocumentData] = useState<any>(null);
  const [documentType, setDocumentType] = useState("floorPlan");
  const [isPetDrawerOpen, togglePetDrawer] = useState(false);
  const [petData, setPetData] = useState<any>(null);


  useEffect(() => {
    setTaskType(getTaskType(editingTaskData))
  }, [editingTaskData])

  const [isEditSpaceDrawerOpen, toggleEditSpaceDrawer] = useState(false);
  const [isViewSpaceDrawerOpen, toggleViewSpaceDrawer] = useState(false);
  const [editingSpaceId, setEditingSpaceId] = useState<any>(null);

  const [isViewFileDrawerOpen, toggleViewFileDrawer] = useState(false);
  const [attachmentToViewId, setAttachmentToViewId] = useState<any>(null);
  const [isEditFileDrawerOpen, toggleEditFileDrawer] = useState(false);
  const [attachmentsWithoutProperty, setAttachmentsWithoutProperty] = useState<any>([]);

  let queryParams = useQuery();
  const searchParam = queryParams.get("search");

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    dispatch(getAllWorks(propertyId));
    dispatch(getAllContacts(propertyId));
    dispatch(getAllInventories(propertyId));
    dispatch(getAllExpenses(propertyId));
    getExpensesWithoutProperty(propertyId).then(payload => {
      if (payload) {
        setExpensesWithoutProperty(payload);
      }
    });
    dispatch(getAllTenants(propertyId));
    dispatch(getAllNotesByPropertyId(propertyId));
    dispatch(getAllInsurances(propertyId))
    dispatch(getAllImprovements(propertyId));
    dispatch(getAllUpcomingTasks({
      propertyId: propertyId,
      startDate: null,
      endDate: null,
      firstInSeries: false
    }));
    dispatch(getAllMaintenances(propertyId));
    dispatch(getAllExpenseTypes(propertyId));
    dispatch(getAllSpaces(propertyId));
    dispatch(getAllPets());
    dispatch(getAllAttachments(propertyId));
    getAttachments(propertyId, true).then(payload => setAttachmentsWithoutProperty(payload));
    setSelectedProperties(Array.from(new Set([...selectedProperties, propertyId])));
    setSortedProperties(properties.filter(object => object.propertyId+"" === propertyId+"").concat(properties.filter(object => object.propertyId+"" !== propertyId+"")));
  }, [dispatch, propertyId]);

  useEffect(() => {
    if (searchParam !== null) {
      setSearchInput(searchParam);
      setSearchSubmitted(searchParam);
      history.push(`/search?search=${searchParam}`);
    }
    if (searchInputElement && searchInputElement.current) {
      // @ts-ignore
      searchInputElement.current.focus();
    }
    setPage(1);
  }, [searchParam]);

  useEffect(() => {
    refreshSearch();
  }, [
    searchSubmitted,
    dataTypes,
    completedWorks,
    todoWorks,
    taxDeductibleExpenses,
    insuredItemsInventory,
    page,
    selectedProperties,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (!searchResults || !searchResults.resultCountByType)
      return;

    const totals = Object.keys(searchResults.resultCountByType)
      // @ts-ignore
      .map(type => {if (searchResults.resultCountByType[type].total) return {dataType: type, total: searchResults.resultCountByType[type].total}})
      .filter(item => item !== null && item !== undefined)
      // @ts-ignore
      .sort((item1, item2) => item2.total - item1.total)
      .slice(0, 4);

    if (dataTypes.length > 0) {
      const intersection =
        Object.keys(searchResults.resultCountByType)
          // @ts-ignore
          .map(type => {if (searchResults.resultCountByType[type].total) return type; })
          .filter(type => type !== null && type !== undefined)
          // @ts-ignore
          .filter(type => dataTypes.includes(type));
      if (intersection.length < 1) {
        setDataTypes([]);
        return;
      }
    }
    setTopTotals(totals);
  }, [searchResults])

  const refreshSearch = () => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;

    if (!_.isEmpty(searchSubmitted)) {
      let filter: TSearchFilters = { dataTypes: dataTypes, properties: selectedProperties };
      if (completedWorks && !todoWorks) {
        filter = { ...filter, isWorkCompleted: true };
      } else if (todoWorks && !completedWorks) {
        filter = { ...filter, isWorkCompleted: false };
      }
      if (taxDeductibleExpenses) {
        filter = { ...filter, isTaxDeductible: true };
      }
      if (insuredItemsInventory) {
        filter = { ...filter, isInsuredItem: true };
      }
      if (startDate) {
        filter = { ...filter, startDate: startDate.format("YYYY-MM-DD") };
      }
      if (endDate) {
        filter = { ...filter, endDate: endDate.format("YYYY-MM-DD") };
      }
      search({ query: searchInput, filter: filter, page: page-1, size: pageSize })
        .then((res) => {
        if (res.data !== undefined) return setSearchResults(res.data);
      }).catch(() => {/* do nothing, it's caused by cancelling requests when user types fast and we need to cancel some*/});
    }
  }

  const setDataType = (typeChecked: string) => {
    if (dataTypes.filter((dt) => dt === typeChecked).length > 0) {
      setDataTypes(dataTypes.filter((dt) => dt !== typeChecked));
    } else {
      setDataTypes([...dataTypes, typeChecked]);
    }
    setPage(1);
  };

  const isDataTypeSelected = (type: string) => {
    return dataTypes.filter((dt) => dt === type).length > 0;
  };

  const selectProperty = (selectedPropertyId: Guid) => {
    if (selectedProperties.filter((id) => id === selectedPropertyId).length > 0) {
      setSelectedProperties(selectedProperties.filter((id) => id !== selectedPropertyId));
    } else {
      setSelectedProperties([...selectedProperties, selectedPropertyId]);
    }
    setPage(1);
  }

  const isPropertySelected = (selectedPropertyId: Guid) => {
    return selectedProperties.filter((id) => id === selectedPropertyId).length > 0;
  }

  const showDrawerForItemInCurrentProperty = (item: TSearchItem) => {
    setHighlightNote(null);
    setHighlightAttachment(null);
    setParentTypeForLinking(item.type !== "upkeep" ? item.type : item.object.upkeepType);
    setParentIdForLinking(item.id);
    if (item.type === "work") {
      setWorkData({
        data: works.content.filter((work) => work.workId === item.id)[0],
      });
      setWorkToViewId(item.id);
      toggleViewWorkDrawer(true);
    } else if (item.type === "expense") {
      setExpenseData({
        data: expenses.content.concat(expensesWithoutProperty).filter(
          (expense) => expense.expenseId === item.id
        )[0],
      });
      toggleExpenseDrawer(true);
    } else if (item.type === "inventory") {
      setInventoryData({
        data: inventories.content.filter(
          (inventory) => inventory.id === item.id
        )[0],
      });
      toggleInventoryDrawer(true);
    } else if (item.type === "contact") {
      setContactData({
        data: contacts.content.filter(
          (contact) => contact.contactId === item.id
        )[0],
      });
      toggleContactDrawer(true);
    } else if (item.type === "tenancy") {
      setTenantData({
        data: tenants.content.filter(
          (tenant) => tenant.tenancyId === item.id
        )[0],
      });
      toggleTenantDrawer(true);
    } else if (item.type === "insurance") {
      setInsuranceData({
        data: insurances.content.filter(
          (insurance) => insurance.id === item.id
        )[0],
      });
      toggleInsuranceDrawer(true);
    } else if (item.type === "improvement") {
      history.push(`/guidance/improvement/${item.object.category}/${item.improvementId}`)
    } else if (item.type === "upkeep") {
      const dbMaintenanceItem = databaseMaintenances.content.filter(
        (upkeep) => upkeep.id === item.id
      )[0];
      if (dbMaintenanceItem.subscribed) {
        setTaskViewData({...dbMaintenanceItem, templateId: dbMaintenanceItem.id});
        setTaskType(dbMaintenanceItem.category);
        toggleTaskViewDrawer(true);
      } else {
        history.push(`/upkeep?tab=All&maintenanceId=${item.id}`);
      }
    } else if (item.type === "space") {
      setEditingSpaceId(item.id);
      toggleViewSpaceDrawer(true);
    } else if (item.type === "note") {
      if (item.parentType === null) {
        const foundNote = notes.filter(
          (note) => note.recordId === item.id
        )[0]
        setNoteData({data: foundNote});
        toggleNoteDrawer(true);
      } else if (item.parentType === "work") {
        setWorkData({
          data: works.content.filter(
            (work) => work.workId === item.parentId
          )[0],
        });
        setHighlightNote(item.id);
        setWorkToViewId(item.id);
        toggleViewWorkDrawer(true);
      } else if (item.parentType === "expense") {
        setExpenseData({
          data: expenses.content.filter(
            (expense) => expense.expenseId === item.parentId
          )[0],
        });
        setHighlightNote(item.id);
        toggleExpenseDrawer(true);
      } else if (item.parentType === "inventory") {
        setInventoryData({
          data: inventories.content.filter(
            (inventory) => inventory.id === item.parentId
          )[0],
        });
        setHighlightNote(item.id);
        toggleInventoryDrawer(true);
      } else if (item.parentType === "contact") {
        setContactData({
          data: contacts.content.filter(
            (contact) => contact.contactId === item.parentId
          )[0],
        });
        setHighlightNote(item.id);
        toggleContactDrawer(true);
      }
    } else if (item.type === "attachment") {
      setAttachmentToViewId(item.id);
      toggleViewFileDrawer(true);
    } else if (item.type === "customreminder") {
      setReminderData({
        parentPage: "search",
        data: item.object
      });
      toggleReminderDrawer(true);
    } else if (item.type === "mortgage") {
      setEditingMortgageData({data: item.object});
      toggleMortgageDrawer(true);
    } else if (item.type === "certificate") {
      setCertificateData({data: item.object});
      toggleCertificateDrawer(true);
    } else if (item.type === "survey") {
      setDocumentType("survey")
      setDocumentData({data: item.object});
      toggleDocumentDrawer(true);
    } else if (item.type === "floorplan") {
      setDocumentType("floorplan")
      setDocumentData({data: item.object});
      toggleDocumentDrawer(true);
    } else if (item.type === "pet") {
      setPetData(item.object);
      togglePetDrawer(true);
    }
  }

  useEffect(() => {
    if (!isShowDrawer)
      return;

    if (improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success)
      return;


    // setTimeout(() => {
      showDrawerForItemInCurrentProperty(switchPropertyForItem);
      toggleShowDrawer(false);
    // }, 1250);
  }, [isShowDrawer, improvementsConfiguration.fetchingStatus])


  const showDrawerForItem = (item: TSearchItem) => {
    if (item.propertyId !== null && item.propertyId+"" !== propertyId+"") {
      setSwitchPropertyDestPropertyId(item.propertyId);
      setSwitchPropertyForItem(item);
      toggleSwitchPropertyModal(true);
      return;
    }

    showDrawerForItemInCurrentProperty(item);
  };

  const filteredSearchItems = () => {
    if (!searchResults)
      return [];
    return searchResults.foundItemList.filter(item => {
      if (item.content.type !== "improvement") {
        return true;
      }
      return improvementsConfiguration.content.improvements.filter(improvementItem => improvementItem.id === item.content.improvementId).length > 0;
    }).map(item => {
      if (item.content.type === "work") {
        item.fullItem = works.content.filter((work) => work.workId === item.content.id)[0];
      } else if (item.content.type === "expense") {
        item.fullItem = expenses.content.filter((expense) => expense.expenseId === item.content.id)[0];
      } else if (item.content.type === "inventory") {
        item.fullItem = inventories.content.filter((inventory) => inventory.id === item.content.id)[0];
      } else if (item.content.type === "contact") {
        item.fullItem = contacts.content.filter((contact) => contact.contactId === item.content.id)[0];
      } else if (item.content.type === "tenancy") {
        item.fullItem = tenants.content.filter((tenant) => tenant.tenancyId === item.content.id)[0];
      } else if (item.content.type === "insurance") {
        item.fullItem = insurances.content.filter((insurance) => insurance.id === item.content.id)[0];
      } else if (item.content.type === "attachment") {
        item.fullItem = attachments.content.filter((attachment) => attachment.attachmentId === item.content.id)[0];
      // } else if (item.type === "improvement") {
      //   history.push(`/guidance/improvement/${item.object.category}/${item.improvementId}`)
      } else if (item.content.type === "upkeep") {
        item.fullItem = databaseMaintenances.content.filter((upkeep) => upkeep.id === item.content.id)[0];
      // } else if (item.type === "space") {
      //   setEditingSpaceId(item.id);
      //   toggleEditSpaceDrawer(true);
      } else if (item.content.type === "note") {
        item.fullItem = notes.filter((note) => note.recordId === item.content.id)[0];
      } else if (item.content.type === "pet") {
        item.fullItem = pets.content.filter((pet) => pet.petId === item.content.id)[0];
      } else if (item.content.type === "attachment") {
      } else if (item.content.type === "customreminder") {
        // setReminderData({
        //   parentPage: "search",
        //   data: item.object
        // });
      }
      return item;
    });
  };

  const performSearch = () => {
    if (!_.isEmpty(searchInput)) {
      setSearchSubmitted(searchInput);
    }
  };

  const itemRender = (
    page: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
    originalElement: React.ReactElement<HTMLElement>
  ) => {
    if (type === "prev") {
      return (
        <Button
          style={{
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "6px",
            marginRight: "1rem"
          }}
        >
          <ButtonText>Prev</ButtonText>
        </Button>
      );
    } else if (type === "next") {
      return (
        <Button
          style={{
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "6px",
            marginLeft: "1rem"
          }}
        >
          <ButtonText>Next</ButtonText>
        </Button>
      );
    }

    return originalElement;
  };

  const displaySearchHeader = () => {
    if (!searchResults)
      return (
        <PageHeader>
          <div>
            <PageSubtitle>No results for <b>"{searchSubmitted}"</b></PageSubtitle>
          </div>
        </PageHeader>
      );

    return (
      <PageHeader style={{flexDirection:"column", alignItems:"start"}}>

          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems:"center"}}>
            <PageTitle>Search</PageTitle>

            <ButtonFilters
              size={"large"}
              icon={<FilterIcon />}
              onClick={() => setShowFilters(!showFilters)}
            >
              {(todoWorks ||
                completedWorks ||
                insuredItemsInventory ||
                taxDeductibleExpenses ||
                dataTypes.length > 0) && (
                <DotIcon
                  style={{ position: "absolute", top: "0.2rem", left: "1.8rem" }}
                />
              )}
              {showFilters ? "Hide" : "Show"} filters
            </ButtonFilters>

          </div>
          <PageSubtitle>{searchResults.totalItems} results for <b>"{searchSubmitted}"</b></PageSubtitle>
      </PageHeader>
    );
  };

  const displaySearchMobileHeader = () => {
    if (!searchResults)
      return (
        <div>
          <PageHeader>No results for <b>"{searchSubmitted}"</b></PageHeader>
        </div>
      );

    return (
      <PageHeader style={{flexDirection:"column", alignItems:"start"}}>
        <PageTitle>Search</PageTitle>
        <span>
          {searchResults.totalItems} results for <b>"{searchSubmitted}"</b>
        </span>
        <ButtonFilters
          size={"large"}
          icon={<FilterIcon />}
          onClick={() => setShowFilters(!showFilters)}
        >
          {(todoWorks ||
            completedWorks ||
            insuredItemsInventory ||
            taxDeductibleExpenses ||
            dataTypes.length > 0) && (
            <DotIcon
              style={{ position: "absolute", top: "0.2rem", left: "1.8rem" }}
            />
          )}
          Filters
        </ButtonFilters>
      </PageHeader>
    );
  };

  const propertiesSelector = () =>
    <SearchPropertyList>
      {sortedProperties.map((property: any) =>
        <SearchPropertyListItem
          onClick={() => property.propertyId !== propertyId && selectProperty(property.propertyId)}
        >
          <img style={{objectFit: "cover", width:window.innerWidth > 768 ? "11rem" : "8.5rem", height: "6rem", borderRadius: "4px"}} alt=""
               src={property.imageThumbnail??"/images/NoPropertyImage.png"}
               onError={(e) => {
                 // @ts-ignore
                 e.target.onError = null;
                 // @ts-ignore
                 e.target.src = "/images/search/NoPropertyImage.png"
               }}
          />
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", fontSize: "0.75rem", fontWeight: 500}}>
              {property.propertyId !== propertyId && <CheckboxStyledDark className={isPropertySelected(property.propertyId) ? "checked small" : "small"}>
                <Checkmark/>
              </CheckboxStyledDark>}
              {(property.nickname && property.nickname.length > 0) ? property.nickname.substring(0,15) : property.address.substring(0,15)}
            </div>
            <SearchPropertyResultsNumber>
              {searchResults && searchResults.resultCountByType && searchResults.resultCountByType.property && searchResults.resultCountByType.property[property.propertyId]
                ? property.propertyId === propertyId
                  ? searchResults.resultCountByType.property[property.propertyId] + (searchResults.resultCountByType.property[""] ? searchResults.resultCountByType.property[""] : 0)
                  : searchResults.resultCountByType.property[property.propertyId]
                : "0"}
            </SearchPropertyResultsNumber>
          </div>
        </SearchPropertyListItem>
      )}
    </SearchPropertyList>

  const propertiesSelectorMobile = () => {
    if (sortedProperties.length === 2)
      return <SearchPropertyList>
        {sortedProperties.map((property: any) =>
          <SearchPropertyListItem
            onClick={() => property.propertyId !== propertyId && selectProperty(property.propertyId)}
          >
            <img style={{objectFit: "cover", width:window.innerWidth > 768 ? "11rem" : "8.5rem", height: "6rem", borderRadius: "4px"}} alt=""
                 src={property.imageThumbnail??"/images/NoPropertyImage.png"}
                 onError={(e) => {
                   // @ts-ignore
                   e.target.onError = null;
                   // @ts-ignore
                   e.target.src = "/images/search/NoPropertyImage.png"
                 }}
            />
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
              <div style={{display: "flex", flexDirection: "row", alignItems: "center", fontSize: "0.75rem", fontWeight: 500}}>
                {property.propertyId !== propertyId && <CheckboxStyledDark className={isPropertySelected(property.propertyId) ? "checked small" : "small"}>
                    <Checkmark/>
                </CheckboxStyledDark>}
                {(property.nickname && property.nickname.length > 0) ? property.nickname.substring(0,15) : property.address.substring(0,15)}
              </div>
              <SearchPropertyResultsNumber>
                {searchResults && searchResults.resultCountByType && searchResults.resultCountByType.property && searchResults.resultCountByType.property[property.propertyId] ? searchResults.resultCountByType.property[property.propertyId] : "0"}
              </SearchPropertyResultsNumber>
            </div>
          </SearchPropertyListItem>
        )}
      </SearchPropertyList>

    return <SliderStyled
      infinite={true}
      arrows={true}
      nextArrow={<div><ArrowRightGrayIcon /></div>}
      prevArrow={<ArrowLeftGrayIcon />}
      slidesToShow={2}
      slidesToScroll={1}
      className={"slider variable-width"}
    >
      {sortedProperties.map((property: any) =>
        <SearchPropertyListItem
          onClick={() => property.propertyId !== propertyId && selectProperty(property.propertyId)}
        >
          <img style={{objectFit: "cover", width:window.innerWidth > 768 ? "11rem" : "8.5rem", height: "6rem", borderRadius: "4px"}} alt=""
               src={property.imageThumbnail??"/images/NoPropertyImage.png"}
               onError={(e) => {
                 // @ts-ignore
                 e.target.onError = null;
                 // @ts-ignore
                 e.target.src = "/images/search/NoPropertyImage.png"
               }}
          />
          {property.propertyId !== propertyId && <CheckboxStyledDarkMobile className={isPropertySelected(property.propertyId) ? "checked" : ""}>
              <Checkmark/>
          </CheckboxStyledDarkMobile>}
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between",  width: "100%", marginTop: "0.5rem"}}>
            <div style={{fontSize: "0.75rem", fontWeight: 500}}>

              {(property.nickname && property.nickname.length > 0) ? property.nickname.substring(0,15) : property.address.substring(0,15)}
            </div>
            <SearchPropertyResultsNumber>
              {searchResults && searchResults.resultCountByType && searchResults.resultCountByType.property && searchResults.resultCountByType.property[property.propertyId] ? searchResults.resultCountByType.property[property.propertyId] : "0"}
            </SearchPropertyResultsNumber>
          </div>
        </SearchPropertyListItem>
      )}
    </SliderStyled>
  }

  const displayFilters = () => (
    <Filters className={window.innerWidth > 1024 ? "search-filters-desktop" : "search-filters"}>
      <FilterGroupRow>
        <CategoryHeader>
          Filters <CancelButton onClick={() => {
            setDataTypes([]);
            setTodoWorks(false);
            setCompletedWorks(false);
            setTaxDeductibleExpenses(false);
            setInsuredItemsInventory(false);
            setSelectedPredefinedDate("custom");
            setStartDate(null);
            setEndDate(null);
        }}>Clear filters</CancelButton>
        </CategoryHeader>
      </FilterGroupRow>
      <PeriodFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedPredefinedDate={selectedPredefinedDate}
        setSelectedPredefinedDate={setSelectedPredefinedDate} />
      <FilterGroupRowSearch>
        <FilterTypesGroupWrapperSearch>
          <FilterGroupHeader>Home information</FilterGroupHeader>
          <FilterGroupList>
            <FilterElementsTypes>
              {!searchResults || !searchResults.resultCountByType.attachment ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Documents (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("attachment")}
                    id={"attachmentsFilter"}
                    checked={isDataTypeSelected("attachment")}
                  />
                  <label htmlFor={"attachmentsFilter"}>
                    Documents (
                    {searchResults.resultCountByType.attachment.total})
                  </label>
                </li>
              )}
              {!searchResults || !searchResults.resultCountByType.contact ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Contacts (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("contact")}
                    id={"contactFilter"}
                    checked={isDataTypeSelected("contact")}
                  />
                  <label htmlFor={"contactFilter"}>
                    Contacts ({searchResults.resultCountByType.contact.total})
                  </label>
                </li>
              )}
              {!searchResults || !searchResults.resultCountByType.expense ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Expenses (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("expense")}
                    id={"expenseFilter"}
                    checked={isDataTypeSelected("expense")}
                  />
                  <label htmlFor={"expenseFilter"}>
                    Expenses ({searchResults.resultCountByType.expense.total})
                  </label>
                </li>
              )}
              {!searchResults || !searchResults.resultCountByType.inventory ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Contents (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("inventory")}
                    id={"inventoryFilter"}
                    checked={isDataTypeSelected("inventory")}
                  />
                  <label htmlFor={"inventoryFilter"}>
                    Contents ({searchResults.resultCountByType.inventory.total}
                    )
                  </label>
                </li>
              )}
              {!searchResults || !searchResults.resultCountByType.note ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Notes (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("note")}
                    id={"noteFilter"}
                    checked={isDataTypeSelected("note")}
                  />
                  <label htmlFor={"noteFilter"}>
                    Notes ({searchResults.resultCountByType.note.total})
                  </label>
                </li>
              )}
              {!searchResults || !searchResults.resultCountByType.tenancy ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Tenancy (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("tenancy")}
                    id={"tenancyFilter"}
                    checked={isDataTypeSelected("tenancy")}
                  />
                  <label htmlFor={"tenancyFilter"}>
                    Tenancy ({searchResults.resultCountByType.tenancy.total})
                  </label>
                </li>
              )}
              {!searchResults || !searchResults.resultCountByType.insurance ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Insurance (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("insurance")}
                    id={"insuranceFilter"}
                    checked={isDataTypeSelected("insurance")}
                  />
                  <label htmlFor={"tenancyFilter"}>
                    Insurance ({searchResults.resultCountByType.insurance.total})
                  </label>
                </li>
              )}
              {!searchResults || !searchResults.resultCountByType.space ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Space (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("space")}
                    id={"spaceFilter"}
                    checked={isDataTypeSelected("space")}
                  />
                  <label htmlFor={"spaceFilter"}>
                    Space ({searchResults.resultCountByType.space.total})
                  </label>
                </li>
              )}
              {!searchResults || !searchResults.resultCountByType.pet ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Pet (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("pet")}
                    id={"spaceFilter"}
                    checked={isDataTypeSelected("pet")}
                  />
                  <label htmlFor={"spaceFilter"}>
                    Pet ({searchResults.resultCountByType.pet.total})
                  </label>
                </li>
              )}
            </FilterElementsTypes>
          </FilterGroupList>
        </FilterTypesGroupWrapperSearch>
        <FilterTypesGroupWrapperSearch>
          <FilterGroupHeader>Plans & Projects</FilterGroupHeader>
          <FilterGroupList>
            <FilterElementsTypes>
              {!searchResults || !searchResults.resultCountByType.work ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Projects (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("work")}
                    id={"workFilter"}
                    checked={isDataTypeSelected("work")}
                  />
                  <label htmlFor={"workFilter"}>
                    Projects ({searchResults.resultCountByType.work.total})
                  </label>
                </li>
              )}
              {!searchResults || !searchResults.resultCountByType.improvement ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Guidances (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("improvement")}
                    id={"improvementFilter"}
                    checked={isDataTypeSelected("improvement")}
                  />
                  <label htmlFor={"improvementFilter"}>
                    Guidances ({searchResults.resultCountByType.improvement.total})
                  </label>
                </li>
              )}
              {!searchResults || !searchResults.resultCountByType.customreminder ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Tasks (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("customreminder")}
                    id={"customreminderFilter"}
                    checked={isDataTypeSelected("customreminder")}
                  />
                  <label htmlFor={"customreminderFilter"}>
                    Tasks ({searchResults.resultCountByType.customreminder.total})
                  </label>
                </li>
              )}
              {!searchResults || !searchResults.resultCountByType.upkeep ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Maintenance suggestions (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("upkeep")}
                    id={"upkeepFilter"}
                    checked={isDataTypeSelected("upkeep")}
                  />
                  <label htmlFor={"upkeepFilter"}>
                    Maintenance suggestions ({searchResults.resultCountByType.upkeep.total})
                  </label>
                </li>
              )}
            </FilterElementsTypes>
          </FilterGroupList>
        </FilterTypesGroupWrapperSearch>
      </FilterGroupRowSearch>
      <FilterGroupBottomRow>
        <FilterGroupWrapper>
          <FilterGroupHeader>Status</FilterGroupHeader>
          <FilterGroupList>
            <FilterElements>
              {!searchResults ||
              !searchResults.resultCountByType.work ||
              !searchResults.resultCountByType.work.total ||
              searchResults.resultCountByType.work.total -
                searchResults.resultCountByType.work.completed ===
                0 ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  To Do (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => {setTodoWorks(!todoWorks); setPage(1)}}
                    id={"todoFilter"}
                    checked={todoWorks}
                  />
                  <label htmlFor={"todoFilter"}>
                    To Do (
                    {searchResults.resultCountByType.work.total -
                      searchResults.resultCountByType.work.completed}
                    )
                  </label>
                </li>
              )}
              {!searchResults ||
              !searchResults.resultCountByType.work ||
              !searchResults.resultCountByType.work.completed ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Completed (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => {setCompletedWorks(!completedWorks); setPage(1)}}
                    id={"completedFilter"}
                    checked={completedWorks}
                  />
                  <label htmlFor={"completedFilter"}>
                    Completed ({searchResults.resultCountByType.work.completed})
                  </label>
                </li>
              )}
            </FilterElements>
          </FilterGroupList>
        </FilterGroupWrapper>
        <FilterGroupWrapper>
          <FilterGroupHeader>Other</FilterGroupHeader>
          <FilterGroupList>
            <FilterElements>
              {!searchResults ||
              !searchResults.resultCountByType.expense ||
              !searchResults.resultCountByType.expense.taxDeductible ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Tax deductible (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => {
                      setTaxDeductibleExpenses(!taxDeductibleExpenses);
                      setPage(1)
                    }}
                    id={"taxDeductibleFilter"}
                    checked={taxDeductibleExpenses}
                  />
                  <label htmlFor={"taxDeductibleFilter"}>
                    Tax deductible (
                    {searchResults.resultCountByType.expense.taxDeductible})
                  </label>
                </li>
              )}
              {!searchResults ||
              !searchResults.resultCountByType.inventory ||
              !searchResults.resultCountByType.inventory.insuredItems ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true} />
                  Insured items (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => {
                      setInsuredItemsInventory(!insuredItemsInventory);
                      setPage(1)
                    }}
                    id={"insuredItemsFilter"}
                    checked={insuredItemsInventory}
                  />
                  <label htmlFor={"insuredItemsFilter"}>
                    Insured items (
                    {searchResults.resultCountByType.inventory.insuredItems})
                  </label>
                </li>
              )}
            </FilterElements>
          </FilterGroupList>
        </FilterGroupWrapper>
      </FilterGroupBottomRow>
    </Filters>
  );

  const showHighlight = (highlights: any) => {
    if (highlights && highlights.textInternal) {
      return <HighlightedSearchResult dangerouslySetInnerHTML={{ __html: highlights.textInternal[0]}}/>
    }
    if (highlights && highlights.textExternal) {
      return <HighlightedSearchResult dangerouslySetInnerHTML={{ __html: highlights.textExternal[0]}}/>
    }
  }

  const topTotalNameAndIcon = (dataType: string, total: number) => {
    let typeName = "";
    switch (dataType) {
      case "work": typeName = "Projects"; break;
      case "note": typeName = "Notes"; break;
      case "upkeep": typeName = "Maintenances"; break;
      case "improvement": typeName = "Guidances"; break;
      case "inventory": typeName = "Contents"; break;
      case "contact": typeName = "Contacts"; break;
      case "expense": typeName = "Expenses"; break;
      case "space": typeName = "Spaces"; break;
      case "attachment": typeName = "Documents"; break;
      case "mortgage": typeName = "Mortgages"; break;
      case "tenancy": typeName = "Tenancies"; break;
      case "insurance": typeName = "Insurances"; break;
      case "pet": typeName = "Pets"; break;
    }

    return <SearchTypeItem
      className={isDataTypeSelected(dataType) ? "active" : ""}
      onClick={() => setDataType(dataType)}
    >
      {typeName}
      <SearchPropertyResultsNumber>
        {total}
      </SearchPropertyResultsNumber>
    </SearchTypeItem>
  }

  const isNoteItem = (item: any) => item.content.type === "note" && item.fullItem && item.fullItem.thumbnails && item.fullItem.thumbnails.length > 0
  const isPetItem = (item: any) => item.content.type === "pet"
  const isContactItem = (item: any) => item.content.type === "contact"&& item.fullItem && item.fullItem.imageThumbnail

  const itemIcon = (item: any) => (<>
    {isNoteItem(item) ? NoteItemImages(item.fullItem, "small") :
      isPetItem(item) ? <PetItemIcon style={{backgroundImage: `url(${item.fullItem?.imageThumbnail})`}}>
          {!item.fullItem?.imageThumbnail && <EmptyImagePreview />}
      </PetItemIcon>
        : isContactItem(item) ? <PetItemIcon style={{backgroundImage: `url(${item.fullItem.imageThumbnail})`}}/>
        : <SearchElementIcon>
        {item.content.type === "note" && (
          <SubmenuNotesIcon className={"search-theme-icon"}/>
        )}
        {item.content.type === "work" && (
          <WorkIcon className={"search-theme-icon"} />
        )}
        {item.content.type === "expense" && (
          <ExpensesIcon className={"search-theme-icon"} />
        )}
        {item.content.type === "budgetcost" && (
          <BudgetCostsIcon className={"search-theme-icon"} />
        )}
        {item.content.type === "inventory" && (
          <InventoryIcon className={"search-theme-icon"} />
        )}
        {item.content.type === "contact" && (
          <ContactIcon className={"search-theme-icon"} />
        )}
        {item.content.type === "attachment" && <>
          {!item.fullItem || (_.isNil(item.fullItem.thumbnailPath) || _.isEmpty(item.fullItem.thumbnailPath)) ? <AttachmentIcon className={"search-theme-icon"} />
          : <div style={{width:"3rem"}}>
              <img className="circle-image-cropped" alt="" src={item.fullItem.thumbnailPath}
                   onError={(e) => {
                     // @ts-ignore
                     e.target.onError = null;
                     // @ts-ignore
                     e.target.src = "/images/file.png"
                   }}/>
          </div>}
        </>}
        {(item.content.type === "tenancy" || item.content.type === "mortgage" || item.content.type === "insurance") && (
          <MortgageIcon />
        )}
        {item.content.type === "improvement" && (
          <ECOIcon className={"search-stroke-theme-icon"} />
        )}
        {item.content.type === "customreminder" && (
          <ReminderIcon className={"search-stroke-theme-icon"} />
        )}
        {(item.content.type === "certificate" || item.content.type === "survey") && (
          <CertificateIcon />
        )}
        {(item.content.type === "upkeep" && item.content.object.upkeepType === "task") && (
          <CalendarTaskIcon className={"search-stroke-theme-icon"} />)}
        {(item.content.type === "upkeep" && item.content.object.upkeepType === "recurringexpense") && (
          <CalendarBudgetCostIcon className={"search-theme-icon"} />)}
        {(item.content.type === "upkeep" && item.content.object.upkeepType === "reminder") && (
          <CalendarReminderIcon className={"search-theme-icon"} />)}
        {item.content.type === "space" && (
          <SpaceSearch />
        )}
      </SearchElementIcon>}
  </>);

/*  const itemDescription = (item: any) => (
    <>
      {item.content.type === "work" && <>
        {item.content.object.recurring && item.content.object.recurring.toLowerCase() !== "none" &&
        `Recurring ${getRecurringPeriod(item.content.object.recurring.toLowerCase())} - `}
        {item.content.object.isComplete ? `Completed on: ${item.content.object.completedDate}` : (
          item.content.object.dueDate && `Due ${item.content.object.dueDate}`)}
      </>}
      {item.content.type === "expense" && <>
        {item.content.object.paymentDate && `Payment date: ${item.content.object.paymentDate}`}
      </>}
      {item.content.type === "budgetcost" && <>
        {`Payments ${item.content.object.periodicityUnit === "once" ? "once" : `every ${item.content.object.periodicity} 
          ${item.content.object.periodicityUnit}(s)`}  since ${item.content.object.startDate}`}
      </>}
      {item.content.type === "inventory" && <>
        {item.content.object.brand && `${item.content.object.brand} + `}
        {item.content.object.model && `${item.content.object.model}`}
        {item.content.object.warrantyExpiration && ` - Warranty expiration: ${item.content.object.warrantyExpiration}`}
      </>}
      {item.content.type === "insurance" && <>
          Insurer: {item.content.object.insurer}
      </>}
      {item.content.type === "attachment" && <>
        {item.content.object.description}
      </>}
      {item.content.type === "contact" && (
        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
          {item.content.object.contactType && window.innerWidth < 1024 && (
            <TagStyledBold style={{display: 'inline-block', marginBottom: '0.5rem'}}>
              {item.content.object.contactType}
            </TagStyledBold>)}
          <SearchDescriptionWrapper>
            <SearchDetail>
              <Ellipsis style={{ display: "inline" }} lines={1}>
                {item.content.object.company}
              </Ellipsis>
            </SearchDetail>
            <SearchDetail>
              <span style={{fontWeight: 700, marginRight: "0.25rem"}}>{item.content.object.phone && "Ph:"}</span>
              <span style={{textDecoration: "underline"}}>{item.content.object.phone}</span>
            </SearchDetail>
            <SearchDetail>
              <span style={{fontWeight: 700, marginRight: "0.25rem"}}>{item.content.object.email && "Email:"}</span>
              <span><Ellipsis style={{ display: "inline" }} lines={1}>{item.content.object.email}</Ellipsis></span>
            </SearchDetail>
            <SearchDetail>
              <span style={{ textDecoration: "underline" }}
              onClick={(e) => {
                e.stopPropagation();
                window.open(item.content.object.website, "_blank")}}>
              <Ellipsis style={{ display: "inline" }} lines={1}>{item.content.object.website}</Ellipsis>
              </span>
            </SearchDetail>
          </SearchDescriptionWrapper>
        </div>
      )}
      {item.content.type === "note" && <>
        {item.content.object && item.content.object.message && <Ellipsis lines={1}>{item.content.object.message}</Ellipsis>}
      </>}
      {item.content.type === "tenancy" && <>
        {item.content.object.tenancyContactId ?
          <Ellipsis
            style={{ display: "inline" }}
            length={25}>
            {showContactName(item.content.object.tenancyContactId)}
          </Ellipsis>
          : "No tenant entered"}
        {" "}
        {item.content.object.agentContact ?
          <Ellipsis style={{ display: "inline" }} length={25}>
            {showContactName(item.content.object.agentContact)}
          </Ellipsis>
          : "No rental agency entered"}
        {" "}
          Tenancy period:{" "}
        {moment(item.content.object.startDate).format(propertyPreferences.preferredDateFormat)} -{" "}
        {moment(item.content.object.endDate).format(propertyPreferences.preferredDateFormat)}
      </>
      }
      {item.content.type === "customreminder" && <div style={{display: "flex"}}>
        {item.content.object.message && <div
            style={{
              maxWidth: '200px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginRight: '0.5rem'
            }}>{item.content.object.message}</div>}
        {item.content.object.periodicity && item.content.object.periodicityUnit &&
        `Once in ${item.content.object.periodicity} ${item.content.object.periodicityUnit}(s) - `}
        {item.content.object.startDate && `Due ${item.content.object.startDate}`}
      </div>}
      {item.content.parentId &&
      <>{capitalize(item.content.parentType)}{" > "}{item.content.parentName}</>}
      {item.content.type !== "insurance" && showHighlight(item.highlightFields)}
    </>
  )*/

  const itemExtras = (item: any) => (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: window.innerWidth > 1024 ? "flex-end" : "flex-start",
      }}
    >
      {item.content.type === "work" && <>
        {item.content.object.workTotalExpense > 0 ?
          <TagStyledBold>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
            {numberWithCommas(item.content.object.workTotalExpense)}
          </TagStyledBold>
          : item.content.object.dueDate && <span style={{marginRight: "1rem",color: "#6B7185"}}>{moment(item.content.object.dueDate).format("YYYY-MM-DD")}</span>
        }
      </>}
      {item.content.type === "expense" && <>
        {item.content.object.totalCost > 0 && (
          <TagStyledBold>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
            {numberWithCommas(item.content.object.totalCost)}
          </TagStyledBold>)}
      </>}
      {item.content.type === "budgetcost" && <>
        {item.content.object.totalCost > 0 && (
          <TagStyledBold>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
            {numberWithCommas(item.content.object.totalCost)}
          </TagStyledBold>)}
      </>}
      {item.content.type === "upkeep" && <>
        {item.content.object.actualEstimatedCost > 0 && (
          <TagStyledBold>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
            {numberWithCommas(item.content.object.actualEstimatedCost)}
          </TagStyledBold>)}
      </>}
      {item.content.type === "inventory" && <>
        {item.content.object.purchaseCost > 0 && (
          <TagStyledBold>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
            {numberWithCommas(item.content.object.purchaseCost)}
          </TagStyledBold>)}
      </>}
      {item.content.type === "tenancy" && <>
        {item.content.object.rentalPayment > 0 ? (
          <TagStyledBold>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
            {numberWithCommas(item.content.object.rentalPayment)}
          </TagStyledBold>
        ) : (
          "\u00A0"
        )}
        {item.content.object.propertyManagementFee > 0 || item.content.object.agentFee > 0 ? (
          <TagStyledBoldRed>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
            {numberWithCommas(item.content.object.propertyManagementFee + item.content.object.agentFee)}
          </TagStyledBoldRed>
        ) : (
          "\u00A0"
        )}
      </>}
      {item.content.type === "insurance" && <div style={{display:"flex", alignItems:"center"}}>
        {item.content.object.totalAmountPaid > 0 ? (
          <TagStyledBold>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
            {numberWithCommas(item.content.object.totalAmountPaid)}
          </TagStyledBold>
        ) : (
          "\u00A0"
        )}
          <SubTag style={{marginRight: "1rem", textAlign: "right"}}>
            {item.content.object.startDate && item.content.object.effectivePeriod && (<>
              Insurance period:<br />
              {moment(item.content.object.startDate).format(propertyPreferences.preferredDateFormat)} -{" "}
              {moment(item.content.object.effectivePeriod).format(propertyPreferences.preferredDateFormat)}
            </>)}
          </SubTag>
      </div>}
      {item.content.type === "contact" && <>
        {/*{item.content.object.worksCount > 0 && <TagStyled>*/}
        {/*    <MaintenanceIcon className={"works-stroke-theme-icon"}/>*/}
        {/*    {item.content.object.worksCount} PROJECT{item.content.object.worksCount > 1 && "S"}*/}
        {/*  </TagStyled>}*/}
        {/*{item.content.object.expensesCount > 0 && <TagStyled>*/}
        {/*    <ExpensesIconPink /> {item.content.object.expensesCount} EXPENSE {item.content.object.expensesCount > 1 && "S"}*/}
        {/*  </TagStyled>}*/}
        {item.content.object.expensesTotalCost > 0 && (
          <TagStyledBold style={{padding:"0.5rem"}}>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
            {numberWithCommas(item.content.object.expensesTotalCost)}
          </TagStyledBold>
        )}
      </>}
      {item.content.type === "note" && <>
          <span style={{marginRight: "1rem",color: '#6b7185'}}>{moment(item.content.object.createdDate).format("YYYY-MM-DD")}</span>
      </>}
      {(item.content.type === "attachment" && item.content.object.uploadedDate) &&
          <span style={{marginRight: "1rem",color: "#6B7185"}}>{moment(item.content.object.uploadedDate).format("YYYY-MM-DD")}</span>}
    </div>
  )

  const propertyTag = (propertyId: Guid) => {
    const propertyNumber = properties.findIndex(object => object.propertyId+"" === propertyId+"");
    if (propertyNumber < 0) return <></>;
    let background = "#EBF9FF";
    let color = "#2A80E1";
    switch (propertyNumber) {
      case 1: background = "#FCF0F9"; color = "#E974C1"; break;
      case 2: background = "#EFF2FF"; color = "#7479E9"; break;
      case 3: background = "#FEF9EA"; color = "#EFC45D"; break;
      case 4: background = "#F1FFFB"; color = "#7CC9B7"; break;
    }

    let name;
    if (properties[propertyNumber].nickname)
      name = properties[propertyNumber].nickname;
    else if (properties[propertyNumber].address2)
      name = `${properties[propertyNumber].address}, ${properties[propertyNumber].address2}`;
    else
      name = properties[propertyNumber].address;

    name = name + "";
    return <PropertyTag style={{background: background, borderColor: color, color: color}}>{name.substr(0,24)}</PropertyTag>;
  }


  const searchItemDesktop = (item: any) => {
    return (
      <MainListItemStyled onClick={() => showDrawerForItem(item.content)}>
        <MainListColumn
          style={{justifyContent: "flex-start", flexDirection: "row", alignItems: "center", columnGap: "1rem", width:"3.5rem"}}
          spanWidth={"10%"}>
          {itemIcon(item)}
        </MainListColumn>

        <MainListColumn
          style={{justifyContent: "flex-start", flexDirection: "row", alignItems: "center", columnGap: "1rem", flexGrow: 0, overflow: "hidden"}}
          spanWidth={"40%"}>
          <SearchItemTitleWrapper>
            <SearchItemTitle>
              <div style={{display: "flex", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flex: 2, justifyContent: "flex-start", flexDirection: "row", alignItems: "center"}}>
                <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                  {item.content.type === "contact" && item.content.object.surname ? item.content.name+" "+item.content.object.surname :
                  item.content.type !== "insurance" ? item.content.name : item.content.object.insuranceType}
                </div>
                {properties.length > 1 && propertyTag(item.content.propertyId)}
              </div>
            </SearchItemTitle>
            <SearchItemMessage>
              <Ellipsis lines={1}>
                {showHighlight(item.highlightFields)}
              </Ellipsis>
            </SearchItemMessage>
          </SearchItemTitleWrapper>
        </MainListColumn>

        <MainListColumn
          className="small-tags"
          style={{justifyContent: "flex-start", flexDirection: "column", rowGap: "0.5rem", overflow: "hidden"}}
          onClick={(e) => e.stopPropagation()}
          spanWidth={"30%"}
        >
          {item.content && item.content.object && <LinkedItemTags style={{maxWidth: "100%"}}>
              <ItemLinks item={item.content.object} />
          </LinkedItemTags>}
          {item.content && item.content.object.tags && item.content.object.tags.length > 0 && <TagContainer
            parentTags={item.content.object.tags}
            parentId={item.id}
            parentType={"note"}
            selectedTags={selectedTags}
            toggleGlobalEditMode={toggleEditTagsMode}
            refreshParent={() => dispatch(getAllNotesByPropertyId(propertyId))}
            editDisabled={true}
          />}
        </MainListColumn>

        <MainListColumn
          style={{justifyContent: "flex-end", flexDirection: "row", alignItems: "center", columnGap: "1rem"}}
          spanWidth={"20%"}>
          {itemExtras(item)}
        </MainListColumn>

      </MainListItemStyled>
    )
  }

  const searchItemMobile = (item: any) => {
    return (
      <SearchMobileContentWrapper onClick={() => showDrawerForItem(item.content)}>
        <SearchMobileTitleWrapper>
          {itemIcon(item)}
          <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: "1rem", fontSize:"1.125rem", fontWeight:600, rowGap: "0.25rem"}}>
            <Ellipsis lines={1}>
              {item.content.type !== "insurance" && <>
                {item.content.name}
              </>}
              {item.content.type === "insurance" && <>
                {item.content.object.insured}
              </>}
            </Ellipsis>
            {properties.length > 1 && propertyTag(item.content.propertyId)}
          </div>
        </SearchMobileTitleWrapper>

        <Ellipsis lines={2}>
          {showHighlight(item.highlightFields)}
        </Ellipsis>
        {item.content && item.content.object && <LinkedItemTags style={{maxWidth: "100%"}}>
            <ItemLinks item={item.content.object} />
        </LinkedItemTags>}
        {item.content && item.content.object.tags && item.content.object.tags.length > 0 && <TagContainer
            parentTags={item.content.object.tags}
            parentId={item.id}
            parentType={"note"}
            selectedTags={selectedTags}
            toggleGlobalEditMode={toggleEditTagsMode}
            refreshParent={() => dispatch(getAllNotesByPropertyId(propertyId))}
            editDisabled={true}
        />}
        {itemExtras(item)}
      </SearchMobileContentWrapper>
    )
  }

  const showShortcutToAnotherPage = () => {
    if (!searchInput || searchInput.length < 1)
      return <></>;

    let IconShortcut = null;
    let pageTitle = "";
    let link = "";

    if (searchInput.match(/work|project/gi)) {
      IconShortcut = WorkIcon;
      pageTitle = "Projects";
      link = "/projects";
    } else if (searchInput.match(/contact/gi)) {
      IconShortcut = GotoContactIcon;
      pageTitle = "Contacts";
      link = "/contacts";
    } else if (searchInput.match(/guidance|improvement/gi)) {
      IconShortcut = ECOIcon;
      pageTitle = "Guidance";
      link = "/guidance";
    } else if (searchInput.match(/content/gi)) {
      IconShortcut = InventoryIcon;
      pageTitle = "Contents";
      link = "/contents";
    } else if (searchInput.match(/expense|cost/gi)) {
      IconShortcut = ExpensesIcon;
      pageTitle = "Expenses";
      link = "/expenses";
    } else if (searchInput.match(/document|file/gi)) {
      IconShortcut = AttachmentIcon;
      pageTitle = "Documents";
      link = "/documents";
    } else if (searchInput.match(/space/gi)) {
      IconShortcut = SpaceSearch;
      pageTitle = "Spaces";
      link = "/spaces";
    } else if (searchInput.match(/note/gi)) {
      IconShortcut = NoteIcon;
      pageTitle = "Notes";
      link = "/notes";
    } else if (searchInput.match(/property/gi)) {
      IconShortcut = HomeIcon;
      pageTitle = "Property details";
      link = "/properties/"+propertyId;
    }/* else if (searchInput.match(/a/gi)) {
      IconShortcut = I;
      pageTitle = "s";
      link = "/";
    } else if (searchInput.match(/a/gi)) {
      IconShortcut = I;
      pageTitle = "s";
      link = "/";
    } else if (searchInput.match(/a/gi)) {
      IconShortcut = I;
      pageTitle = "s";
      link = "/";
    }*/

    if (!IconShortcut)
      return <></>;

    return <LinkToPage onClick={() => history.push(link)}>
      <IconShortcut style={{marginRight: "0.5rem"}}/> Go to {pageTitle} →
    </LinkToPage>
  }

  if (!searchResults)
    return <Spin indicator={<LoadingOutlined style={{ fontSize: 72, marginTop: "10rem", marginLeft:window.innerWidth > 1024 ? "40rem" : "8rem" }} spin />} />;

  return (
    <PageWrapper>
      <SwitchPropertyModal
        isModalOpen={isSwitchPropertyModalOpen}
        toggleModal={toggleSwitchPropertyModal}
        destPropertyId={switchPropertyDestPropertyId}
        showDrawer={toggleShowDrawer}
        item={switchPropertyForItem}
      />
      <LinkDrawer
        parentId={parentIdForLinking}
        parentType={parentTypeForLinking}
        isOpen={isLinkDrawerOpen}
        presetLinkType={presetLinkType}
        setPresetLinkType={setPresetLinkType}
        toggleDrawerOpen={toggleLinkDrawer}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setNoteData}
        setEditSpaceId={setEditingSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        toggleEventDrawer={() => {}}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
        refreshParent={refreshSearch}
      />
      <WorkDrawer
        isOpen={isWorkDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        work={workData}
        setEditingWorkData={setWorkData}
        refreshParent={() => {}}
      />
      <ViewWorkDrawer
        workId={workToViewId}
        setWorkId={setWorkToViewId}
        isOpen={isViewWorkDrawerOpen}
        toggleDrawer={toggleViewWorkDrawer}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setNoteData}
        setLinkedWorkId={() => {}}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setWorkIdToForward={() => {}}
        toggleForwardDrawer={() => {}}
        toggleTradeDrawer={() => {}}
        setEditSpaceId={setEditingSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        setPresetLinkType={setPresetLinkType}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
        refreshSearch={refreshSearch}
      />
      <InventoryDrawer
        isOpen={isInventoryDrawerOpen}
        toggleDrawerOpen={toggleInventoryDrawer}
        width={null}
        inventory={inventoryData}
        setEditingInventoryData={setInventoryData}
        highlightNote={highlightNote}
        highlightAttachment={highlightAttachment}
        refreshParent={refreshSearch}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
      />
      <ExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        toggleDrawerOpen={toggleExpenseDrawer}
        expense={expenseData}
        setEditingExpenseData={setExpenseData}
        highlightNote={highlightNote}
        highlightAttachment={highlightAttachment}
        refreshParent={refreshSearch}
        contact={null}
        drawerTitle={null}
        icon={null}
        inventory={null}
        width={null}
        work={null}
      />
      <ContactDrawer
        isOpen={isContactDrawerOpen}
        toggleDrawerOpen={toggleContactDrawer}
        contact={contactData}
        setEditingContactData={setContactData}
        refreshParent={refreshSearch}
      />
      <TenantDrawer
        isOpen={isTenantDrawerOpen}
        toggleDrawerOpen={toggleTenantDrawer}
        tenant={tenantData}
        setEditingTenantData={setTenantData}
      />
      <InsuranceDrawer
        toggleDrawerOpen={toggleInsuranceDrawer}
        isOpen={isInsuranceDrawerOpen}
        insurance={insuranceData}
        setEditingInsuranceData={setInsuranceData}
        refreshParent={refreshSearch}
      />
      <NoteDrawer
        data={{workNotes: {...noteData.data}}}
        setEditWorkData={setNoteData}
        currentType={"note"}
        isOpen={isNoteDrawerOpen}
        toggleDrawerOpen={toggleNoteDrawer}
        refreshParent={refreshSearch}
        icon={<NoteIcon className={"notes-theme-icon"} />}
        highlightAttachment={highlightAttachment}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        setPresetLinkType={setPresetLinkType}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setContactData}
        setEditSpaceId={setEditingSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <UpcomingTaskDrawer
        isOpen={isReminderDrawerOpen}
        toggleDrawerOpen={toggleReminderDrawer}
        upcomingTask={reminderData}
        setEditingTaskData={setReminderData}
        refreshParent={refreshSearch}
      />
      <UpdateTaskDrawer
        task={editingTaskData}
        setEditingTaskData={setEditingTaskData}
        isOpen={isTaskDrawerOpen}
        toggleDrawer={toggleTaskDrawer}
        taskType={taskType}
        setTaskType={setTaskType}
      />
      <ViewTaskDrawer
        task={taskViewData}
        setTask={setTaskViewData}
        isOpen={isTaskViewDrawerOpen}
        toggleDrawer={toggleTaskViewDrawer}
        taskType={taskType}
        setTaskToEdit={setEditingTaskData}
        setTaskToEditType={setTaskType}
        toggleUpdateDrawer={toggleTaskDrawer}
        refreshParent={refreshSearch}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setNoteData}
        setEditSpaceId={setEditingSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        setLinkedTaskId={() => {}}
        setLinkedTaskType={() => {}}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setPresetLinkType={setPresetLinkType}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <EditSpaceDrawer
        spaceId={editingSpaceId}
        isOpen={isEditSpaceDrawerOpen}
        toggleDrawerOpen={toggleEditSpaceDrawer}
        setEditingSpaceId={setEditingSpaceId}
        setViewSpaceId={setEditingSpaceId}
        toggleViewDrawerOpen={toggleViewSpaceDrawer}
      />
      <ViewSpaceDrawer
        spaceId={editingSpaceId}
        setEditSpaceId={setEditingSpaceId}
        isOpen={isViewSpaceDrawerOpen}
        toggleDrawerOpen={toggleViewSpaceDrawer}
        setPresetLinkType={setPresetLinkType}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setContactData}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        setEditNoteData={setNoteData}
        setEditWorkData={setWorkData}
        setLinkedSpaceId={() => {}}
        toggleEditDrawerOpen={toggleEditSpaceDrawer}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        toggleNoteDrawer={toggleNoteDrawer}
        toggleWorkDrawer={toggleWorkDrawer}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
        refreshParent={refreshSearch}
      />
      <EditFileDrawer
        attachmentId={attachmentToViewId}
        isOpen={isEditFileDrawerOpen}
        toggleDrawerOpen={toggleEditFileDrawer}
      />
      <ViewFileDrawer
        attachmentId={attachmentToViewId}
        setAttachmentId={setAttachmentToViewId}
        isOpen={isViewFileDrawerOpen}
        toggleDrawer={toggleViewFileDrawer}
        viewFileDrawerDefaultTab={"preview"}
        attachmentsWithoutProperty={attachmentsWithoutProperty}
        duplicateAttachments={[]}
        selectedTags={[]}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setNoteData}
        setEditSpaceId={setEditingSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        toggleEditTagsMode={toggleEditTagsMode}
        toggleEditDrawerOpen={toggleEditFileDrawer}
        setPresetLinkType={setPresetLinkType}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditingTaskData}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        refreshSearch={refreshSearch}
        refreshParent={refreshSearch}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setViewWorkId={setWorkToViewId}
      />
      <MortgageDrawer
        isOpen={isMortgageDrawerOpen}
        toggleDrawerOpen={toggleMortgageDrawer}
        setEditingMortgageData={setEditingMortgageData}
        mortgage={mortgageData}
      />
      <CertificateDrawer
        isOpen={isCertificateDrawerOpen}
        toggleDrawerOpen={toggleCertificateDrawer}
        setEditingCertificateData={setCertificateData}
        certificate={certificateData}
      />
      <DocumentDrawer
        isOpen={isDocumentDrawerOpen}
        toggleDrawerOpen={toggleDocumentDrawer}
        setEditingDocumentData={setDocumentData}
        document={document}
        documentType={documentType}
      />
      <PetDrawer
        isDrawerOpen={isPetDrawerOpen}
        toggleDrawer={togglePetDrawer}
        pet={petData}
        setPet={setPetData}
      />
      {window.innerWidth > 1024 && <>
        {displaySearchHeader()}
        {properties.length > 1 && propertiesSelector()}
        {showFilters && displayFilters()}
      </>}
      <SearchListDesktop>
        <SearchWrapper>
          {/*<Search*/}
          {/*  size={"large"}*/}
          {/*  ref={searchInputElement}*/}
          {/*  value={searchInput}*/}
          {/*  onChange={(val) => {*/}
          {/*    setSearchInput(val.target.value);*/}
          {/*  }}*/}
          {/*  onPressEnter={performSearch}*/}
          {/*  placeholder={"Search..."}*/}
          {/*  suffix={<SearchIcon onClick={performSearch} />}*/}
          {/*/>*/}
          <SearchTypeItem
            className={dataTypes.length < 1 ? "active" : ""}
            onClick={() => setDataTypes([])}
          >
            All
            <SearchPropertyResultsNumber>
              {searchResults.totalItems}
            </SearchPropertyResultsNumber>
          </SearchTypeItem>
          {topTotals.map((total:any) =>
              topTotalNameAndIcon(total.dataType, total.total)
          )}
        </SearchWrapper>
        {showShortcutToAnotherPage()}
        <ListStyled
          className="no-rounded-list"
          itemLayout="horizontal"
          dataSource={filteredSearchItems()}
          renderItem={(item) => searchItemDesktop(item)}
        />
        {searchResults.totalItems > pageSize && <PaginationStyled
          className={searchResults.totalItems < pageSize ? "single-page-pagination" : ""}
          onChange={(page) => setPage(page)}
          defaultPageSize={10}
          responsive={true}
          simple={window.innerWidth < 1024}
          showSizeChanger={false}
          showLessItems={true}
          itemRender={itemRender}
          defaultCurrent={1}
          total={searchResults.totalMatching}
          current={page}
        />}
      </SearchListDesktop>
      <SearchListMobile>
        <div style={{ width: "100%" }}>
          <SearchWrapperMobile>
            {displaySearchMobileHeader()}
          </SearchWrapperMobile>
        </div>
        {properties.length > 1 && propertiesSelectorMobile()}
        {showFilters && displayFilters()}
        <ListMobile>
          {showShortcutToAnotherPage()}
          {filteredSearchItems().map((item: any) => searchItemMobile(item))}
        </ListMobile>
        {searchResults.totalItems > 10 && <PaginationStyled
          className={searchResults.totalItems < 10 ? "single-page-pagination" : ""}
          style={{marginBottom: "1rem"}}
          onChange={(page) => setPage(page)}
          defaultPageSize={10}
          simple={window.innerWidth < 1024}
          responsive={true}
          showSizeChanger={false}
          showLessItems={true}
          itemRender={itemRender}
          defaultCurrent={1}
          total={searchResults.totalItems}
        />}
      </SearchListMobile>
    </PageWrapper>
  );
};

const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px !important;
  font-weight: 600;
  @media (max-width: 1024px) {
    display: inline-flex;
    justify-content: center;
  }
`;

const SearchListMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  // border-radius: 0.625rem;
  // border: 1px solid #dae0e6;
  // align-items: center;
  // background-color: #fff;
  // margin: 0 1rem;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const SearchListDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: start;
  background-color: #fff;
  border-radius: 10px 10px 10px 10px;
  padding: 2rem 1rem 2rem 1rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Search = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
`;

const SearchWrapperMobile = styled.div`
  width: 100%;
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0.75rem 1.25rem 0 1.25rem;
  
  border-bottom: 2px solid rgba(33, 39, 43, 0.04);
`;

const SearchTypeItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  
  margin-top: 0.5rem;
  padding: 0 2rem 0.5rem 2rem;
  margin-bottom: -0.1rem;
  min-width: 8rem;
  
  color: #666F7A;
  font-weight: 600;
  cursor: pointer;
  
  &.active {
    border-bottom: 2px solid #21272B;
    color: #21272B;
    cursor: auto;
  }
`;

const ButtonFilters = styled(ButtonStyled)`
  margin-top: 0;
  background: #2a80e1;
  border-color: #2a80e1;
  color: white;
  svg {
    margin-right: 0.5rem;
  }
  svg path {
    stroke: white;
  }

  @media (max-width: 1024px) {
    :hover {
      color: #fff;
      background: #1890ff;
    }
  }
  
  @media (min-width: 1024px) {
    margin-left: 1rem;

    :hover {
      svg path {
        stroke: #2a80e1;
      }
    }
    :focus {
      svg path {
        stroke: #2a80e1;
      }
    }
  }
`;

const SearchMobileContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  font-size: 0.875rem;
  color: #6B7185;
  border-bottom: 1px solid #EFF1F5;
  padding: 1rem 0;
`;

const SearchMobileTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonText = styled.span`
  font-weight: 600;
  font-size: 0.875rem;
  color: #21272b;
`;

const InsuredTag = styled(Tag)`
  align-items: center;
  background-color: #EFFDF7;
  margin-left: 0.5rem;
  color: #007F6E;
  padding-top: 0.25rem;
  padding-bottom: 0.3rem;
  margin: 0;
  border-radius: 0.25rem;
  border: 0;
  font-weight: 500;
  font-size: 0.75rem;
  margin-right: 0.75rem;
  text-transform: uppercase;
`;

const HighlightedSearchResult = styled.div`
  margin-top: 0.5rem;
  font-style: italic;
  em {
    font-style: normal;
    font-weight: 600;
    background: #B9FFDE;
  }
`;

const FilterGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 4);
  @media (max-width: 1024px) {
    width: calc(100% / 2);
  }
`;

const ListStyled = styled(List)`
  width: 100%;
  align-items: center;
  
  ul div:first-child {
    border-top: 0!important;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const ListMobile = styled.div`
  display: none;
  
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 1rem;
  }
`
const SearchPropertyList = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;

  margin: 1rem 0;
`;

export const SearchPropertyListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem;
  gap: 0.5rem;

  width: 12rem;
  height: 9rem;

  background: #FFFFFF;
  border-radius: 8px;
  
  @media (max-width: 1024px) {
    width: 9.5rem !important;
    position: relative;
  }
`;

const SearchPropertyResultsNumber = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 8px;

  width: 1.5rem;
  height: 1.5rem;
  
  font-weight: 700;
  font-size: 0.625rem;

  background: rgba(33, 39, 43, 0.04);
  border-radius: 4px;
`;

const SearchItemTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.5rem;
  justify-content: space-between;
  width: 100%;
`;

const SearchItemTitle = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #002A43;
  column-gap: 0.375rem;
`;

const SearchItemMessage = styled.div`
  width: 100%;
  font-size: 0.625rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  color: #002A43;
  column-gap: 0.375rem;
`;

const SearchElementIcon = styled.div`
  box-sizing: border-box;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
  flex-shrink: 0;
  
  width: 3.5rem;
  height: 3.5rem;

  border: 1px solid #D8DFDE;
  border-radius: 3.5rem;
`;

const LinkToPage = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 3rem;
  
  background: #F5F6FA;
  border: 1px solid #DAE0E6;
  border-radius: 8px;
  
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  
  font-weight: 600;
  font-size: 1.125;
  line-height: 1.5px;
  letter-spacing: 0.1px;
  color: #2A80E1;
  cursor: pointer;
  
  @media (min-width: 1024px) {
    margin: 1.25rem;
  }
`;

const PropertyTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0.5rem;

  height: 1.5rem;

  border: 1px solid #7CC9B7;
  border-radius: 19px;

  font-weight: 400;
  font-size: 0.625rem;
  line-height: 1rem;
  letter-spacing: 0.1px;
  
  @media (min-width: 1024px) {
    margin-left: 0.5rem;
    max-width: 10rem;
  }
`;

const SliderStyled = styled(Slider)`
  margin: 1rem 0;

  .slick-next { 
    width: 6.25rem; 
    height: 100%;
    right: 0;
    display: flex!important;
    align-items: center;
    justify-content: end;
    
    ::before {
      display: none;
    }    
    div {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const CheckboxStyledDarkMobile = styled(CheckboxStyledDark)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

export default SearchPage;
