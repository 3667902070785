import React from "react";
import {Button, Dropdown, Upload} from "antd";
import {addExpense} from "../../../api-wrapper/expenses/addExpense";
import {addRelation} from "../../../api-wrapper/relation/addRelation";
import {parseInvoice} from "../../../api-wrapper/expenses/parseInvoice";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getStorageUsageSelector} from "../../selectors";
import {AttachmentDescriptionDesktop, AttachmentDescriptionMobile, DraggerStyled} from "./UploadFile";
import {addAttachment} from "../../../api-wrapper/attachments/addAttachment";
import {getMyStorageUsage} from "../../actions/storageUsage";
import {NoDataInvoiceText} from "../styled";
import {ReactComponent as UploadInvoiceIcon} from "../../../images/UploadInvoice.svg";
import {AddItemButton} from "../reports/ExpensesReport";
import {QuickAddOption} from "../navigation/Header";
import {ReactComponent as QuickAddInvoice} from "../../../images/menu/QuickAddInvoice.svg";
import {
  allowedExtensions, showBouncedBatchUploadNotification, showBouncedFileUploadNotification,
  showFailedFileUploadNotification, showInvoiceBatchUploadErrorsNotification,
  showSuccessfulFileUploadNotification, showSuccessfulInvoiceBatchUploadNotification
} from "./uploadHelpers";
import styled from "styled-components";
import {parseInvoiceBatch} from "../../../api-wrapper/expenses/parseInvoiceBatch";
import {getAllExpenses} from "../../actions/expenses";
import {CardItem, infoDropdown, InfoIconWrapper} from "../homePage/HomeCard";
import {ReactComponent as InfoIcon} from "../../../images/homepage/InfoIcon.svg";
import {ReactComponent as QuickActionsBill} from "../../../images/homepage/QuickActionsBill.svg";

type Props = {
  fileList: Array<any>;
  allowBatchUpload: boolean;
  uploadOrigin: string;

  setInvoiceData: (arg: any) => void;
  setExpenseData: (arg: any) => void;
  toggleExpenseDrawer: (arg: boolean) => void;
  toggleBatchProcessing?: (arg: boolean) => void;
}

const UploadInvoice = (props: Props) => {
  const {fileList, allowBatchUpload, uploadOrigin, setInvoiceData, setExpenseData, toggleExpenseDrawer, toggleBatchProcessing} = props;
  const propertyId = useSelector(getPropertyIdSelector).value;
  const storageUsage = useSelector(getStorageUsageSelector);
  const dispatch = useDispatch();

  const overlay = () => {
    return <InvoiceOverlay>
      <div style={{color: "#2a80e1", fontWeight: 600, marginBottom: "0.5rem"}}>
        Hey, we're still working on this new feature!
      </div>
      Our automated invoice reading feature is brand new, and is continuously being improved by our developer team with
      machine learning algorithms - so please reconfirm the outputs in the Add Cost fields.
    </InvoiceOverlay>
  }

  const resolveInvoiceUpload = async (res: any, file: any) => {
    let newExpense = await addExpense({propertyId, isConfirmed: true})

    if (res) {
      let newRelation = await addRelation({
        propertyId,
        //@ts-ignore
        parentId: newExpense.data.expenseId,
        childId: res.data.attachmentId,
        parentType: "expense",
        childType: "attachment",
      });
    }
    //@ts-ignore
    setInvoiceData({
      invoice: null,
      fileName: file.name,
      isProcessing: true,
      isComplete: false,
      error: null
    })
    //@ts-ignore
    toggleExpenseDrawer(true)
    //@ts-ignore
    setExpenseData(newExpense)

    try {
      let invoiceResponse = await parseInvoice({file})
      invoiceResponse.data && setInvoiceData && setInvoiceData({
        invoice: invoiceResponse.data[0],
        fileName: file.name,
        isProcessing: false,
        isComplete: true,
        error: null
      })
    } catch (error) {
      setInvoiceData && error && setInvoiceData({
        invoice: null,
        fileName: null,
        isProcessing: false,
        isComplete: true,
        error: error
      })
    }
  }

  const isBatchAllowed = (fileList: any) => {
    const batchSize = fileList.length
    // @ts-ignore
    const batchFileSize = fileList.reduce((sum, { size }) => sum + size, 0)

    const isWithinFileLimit = batchSize + storageUsage.content.currentFilesAmount < storageUsage.content.maxFilesAmount
    const isWithinSizeLimit = batchFileSize + storageUsage.content.currentFilesSize < storageUsage.content.maxFilesSize
    return isWithinFileLimit && isWithinSizeLimit
  }

  const UploadAntComponent = allowBatchUpload && uploadOrigin === "costs" ? DraggerStyled : Upload;

  return <UploadAntComponent
    fileList={fileList}
    shouldBeHide={""}
    name={"file"}
    listType={"picture"}
    beforeUpload={(file, fileList) => {
      let batchSize = fileList.length
      let fileExt = file.name.split(".").pop();
      if (!fileExt) fileExt = "";
      const allowedType = allowedExtensions.includes(fileExt.toLowerCase());
      const allowedSize = file.size / 1024 / 1024 < 20;

      if (!allowedSize || !allowedType) {
        showBouncedFileUploadNotification()
        return false;
      }

      if (batchSize > 1 && !isBatchAllowed(fileList)) {
        let lastFileId = fileList[batchSize - 1].uid
        if (file.uid === lastFileId) {
          showBouncedBatchUploadNotification()
        }
        return false;
      }

      if (batchSize > 1) {
        // beforeUpload gets executed for every file, which defies the purpose of batching
        // therefore we check for the last file in batch to only execute it once
        window.scrollTo(0, 0);
        let lastFileId = fileList[batchSize - 1].uid
        if (file.uid === lastFileId) {
          toggleBatchProcessing && toggleBatchProcessing(true)
          parseInvoiceBatch(propertyId, fileList)
            .then((res) => {
              toggleBatchProcessing && toggleBatchProcessing(false)
              dispatch(getAllExpenses(propertyId))
              if (res.data && res.data.length > 0) {
                let fileNames: any = []
                res.data.map((error: any) => fileNames.push(error.fileName))
                showInvoiceBatchUploadErrorsNotification(fileNames.join(", "))
              } else {
                showSuccessfulInvoiceBatchUploadNotification()
              }
              return false;
            })
            .catch((err) => showFailedFileUploadNotification(err))
        }
        return false;
      } else {
        addAttachment({propertyId, parentId: null, parentType: null, file}).then((res: any) => {
          dispatch(getMyStorageUsage());
          showSuccessfulFileUploadNotification()
          resolveInvoiceUpload(res, file).then()
        }).catch((err) => {
          let errorMsg = "";
          if (err && err.data && err.data.message)
            errorMsg = err.data.message;
          showFailedFileUploadNotification(errorMsg)
          resolveInvoiceUpload(null, file).then()
        });
      }
      return false;
    }}
    showUploadList={false}
    multiple={allowBatchUpload}
  >
    {uploadOrigin === "costs" && <>
      <AttachmentDescriptionDesktop>
        <NoDataInvoiceText>
          Drag and drop documents here or choose <a>files from your computer</a>
        </NoDataInvoiceText>
      </AttachmentDescriptionDesktop>
      <AttachmentDescriptionMobile size={"large"}>
        + Add document
      </AttachmentDescriptionMobile>
    </>}

    {uploadOrigin === "drawer" &&
    <Dropdown overlay={overlay} placement="topCenter">
        <div style={{height: "100%", width: "100%"}}>Upload invoice</div>
    </Dropdown>}

    {uploadOrigin === "record" &&
    <Dropdown overlay={overlay} placement="topCenter">
        <div className={"record-as-invoice-button"}>Yes</div>
    </Dropdown>}

    {uploadOrigin === "header" &&
    <Dropdown overlay={overlay} placement="topCenter">
        <HeaderButtonStyled
            type={"primary"}
            size={"large"}
            icon={<UploadInvoiceIcon />}
            style={{marginBottom: 0}}>
          {window.innerWidth > 1024 && "Upload invoices"}
        </HeaderButtonStyled>
    </Dropdown>}

    {uploadOrigin === "widget" && <AddItemButton
        style={{marginRight: "1rem"}}
        size={"large"}
        icon={<UploadInvoiceIcon style={{marginRight: "0.5rem", marginBottom: "-0.25rem"}} />}
    >Upload Invoice</AddItemButton>}

    {uploadOrigin === "quick-add" && <QuickAddOption>
        <QuickAddInvoice /> Upload invoices
    </QuickAddOption>}

    {uploadOrigin === "homepage" && <HomepageWrapper>
        <InfoIconWrapper>
          {infoDropdown(
            "Upload a bill",
            "Uploading an invoice or bill to your Livlet account will create an expense for " +
            "you which you can view from Home information > Expenses. You can add payee details, document, or invoices to your cost.",
            <InfoIcon />)}
        </InfoIconWrapper>
        <QuickActionsBill /> Upload a Bill
    </HomepageWrapper>}
  </UploadAntComponent>
}

const HomepageWrapper = styled(CardItem)`
  width: auto;
  height: 100%;
  padding: 0 1rem;
  border: none;
`

const HeaderButtonStyled = styled(Button)`
  height: 3rem;
  min-width: 7rem;
  border-radius: 6px!important;
  font-weight: 600;
  background-color: #2a80e1;
  border-color: #2a80e1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  
  @media (max-width: 1024px) {
    min-width: 3rem;
    min-height: 3rem;
  }
`;

export const InvoiceOverlay = styled.div`
  background: white;
  padding: 0.75rem;
  width: 12.5rem;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #6B7185;
  text-align: center;
  border: 1px solid #2a80e1;
`

export default UploadInvoice