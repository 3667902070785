import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetDocumentsResponse } from "../../api-wrapper/documents/addDocument";
import {
  getFloorPlansFailureAction,
  getFloorPlansInitAction,
  getFloorPlansSuccessAction,
  TGetFloorPlansActions,
} from "../actions/floorPlans";
import {emptyGuid} from "../screens/helpers";

export type TFloorPlans = {
  fetchingStatus: TFetchingStatus;
  content: TGetDocumentsResponse;
};

const initialState: TFloorPlans = {
  content: [
    {
      name: "",
      documentDate: "",
      documentType: "",
      description: "",
      category: "",
      relatedFileId: emptyGuid,
      documentId: emptyGuid,
      subscriptionId: 0,
      propertyId: emptyGuid
    }
  ],
  fetchingStatus: TFetchingStatus.Defined,
};

export const floorPlansReducer = createReducer<TFloorPlans, TGetFloorPlansActions>(
  initialState
)
  .handleAction(getFloorPlansInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getFloorPlansSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getFloorPlansFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
