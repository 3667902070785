import React, {useEffect, useState} from "react";
import {Modal} from "antd";
import {editMaintenanceApi} from "../../../../api-wrapper/maintenance/editMaintenance";
import {getAllMaintenances} from "../../../actions/maintenances";
import {getAllOnboardingMaintenances} from "../../../actions/onboardingMaintenances";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../../selectors";
import {ReactComponent as CheckIcon} from "../../../../images/CheckIcon.svg";
import styled from "styled-components";
import {getStartDate} from "../MaintenanceListItemNew";

type Props = {
  item: any;
  itemFrequency: number;
  itemFrequencyUnit: string;
  itemCost: number;
  itemStartDate: string;
  isOnboarding: boolean;
  setEditing: (arg: any) => void;
}

const MaintenanceSaveButton = (props: Props) => {
  const {item, itemCost, itemFrequency, itemFrequencyUnit, itemStartDate, isOnboarding, setEditing} = props;

  const [initialFrequency, setInitialFrequency] = useState(1)
  const [initialFrequencyUnit, setInitialFrequencyUnit] = useState("")
  const [initialStartDate, setInitialStartDate] = useState("")

  const setDefaults = (item: any) => {
    setInitialFrequency(item.actualPeriodicity ?? item.periodicity)
    setInitialFrequencyUnit(item.actualPeriodicityUnit ?? item.periodicityUnit)
    setInitialStartDate(getStartDate(item))
  }

  useEffect(() => {
    setDefaults(item)
  }, [item])

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value

  const handleResetSeries = () => {
    Modal.confirm({
      title: "Warning",
      content: "You are editing the task series. Changing the start date and/or recurrence " +
        "of the series will reset the series, except for completed tasks. Are you sure you want to proceed?",
      okText: "Yes",
      onOk: () => handleSave(true),
      cancelText: "No",
      onCancel: () => handleSave(false),
      className: "form-confirm-close-modal",
    });
  }

  const handleSave = (editSeries: boolean) => {
    if ((!itemFrequency || itemFrequency === 0) && itemFrequencyUnit !== "once") {
      Modal.error({
        title: "Error",
        content: "The entry form cannot be saved with the proposed frequency.",
        okText: "OK",
        className: "form-confirm-close-modal",
      });
      return;
    }

    const updatedItem = {
      ...item,
      actualEstimatedCost: itemCost,
      actualPeriodicity: editSeries ? (itemFrequencyUnit !== "once" ? itemFrequency : null) : initialFrequency,
      actualPeriodicityUnit: editSeries ? itemFrequencyUnit : initialFrequencyUnit,
      startDate: editSeries ? itemStartDate : initialStartDate,
    }

    editMaintenanceApi({
      propertyId,
      ...updatedItem
    }, item.id).then(() => {
      dispatch(getAllMaintenances(propertyId))
      isOnboarding && dispatch(getAllOnboardingMaintenances(propertyId))
      setEditing("")
    })
  }

  return <SaveButton onClick={() => {
      (item.subscribed && (itemFrequencyUnit !== initialFrequencyUnit || itemFrequency !== initialFrequency
        || itemStartDate !== initialStartDate)) ? handleResetSeries() : handleSave(true)
    }}><CheckIcon/></SaveButton>
}

const SaveButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 2rem;
  width: 2rem;
  
  background: #009966;
  border: 1px solid #009966;
  svg path { stroke: white; }
`

export default MaintenanceSaveButton