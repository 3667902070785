import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import { getCertificates, TGetCertificatesResponse } from "../../api-wrapper/certificates/getCertificates";
import {Guid} from "guid-typescript";
import _ from "lodash";

export const getCertificatesInitAction = createAction("certificates/GET_CERTIFICATES_INIT")();
export const getCertificatesSuccessAction = createAction("certificates/GET_CERTIFICATES_SUCCESS")<TGetCertificatesResponse>();
export const getCertificatesFailureAction = createAction("certificates/GET_CERTIFICATES_FAILURE")();

export type TGetCertificatesInitAction = ActionType<typeof getCertificatesInitAction>;
export type TGetCertificatesSuccessAction = ActionType<typeof getCertificatesSuccessAction>;
export type TGetCertificatesFailureAction = ActionType<typeof getCertificatesFailureAction>;

export type TGetCertificatesActions =
  | TGetCertificatesInitAction
  | TGetCertificatesSuccessAction
  | TGetCertificatesFailureAction;

export type TGetCertificatesThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetCertificatesActions>;

export const getAllCertificates: TGetCertificatesThunkActionType = (propertyId: Guid) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getCertificatesInitAction());
  return getCertificates(propertyId)
    .then((payload) => {
      let certificates = _.orderBy(payload, ['endOfValidity'], ['desc'])
      return dispatch(getCertificatesSuccessAction(certificates));
    })
    .catch(() => dispatch(getCertificatesFailureAction()));
};