import React from "react";
import {uploadAccountContent} from "../../../api-wrapper/publicOnboarding/uploadAccountContent";
import {notification} from "antd";
import {
  ButtonBlue,
  ButtonWrapper,
  FormSectionTitle,
  FormWrapper,
  PageTitle,
  PageWrapper
} from "../publicOnboarding/SiteVisitStep";
import {UploadedFile} from "../spaces/UploadImage";
import {ReactComponent as ImageUpload} from "../../../images/spaces/ImageUpload.svg";
import { InfoLine } from "../publicOnboarding/SummaryStep";
import {uploadKeytekContent} from "../../../api-wrapper/publicOnboarding/uploadKeytekContent";

type Props = {
  setStep: (arg: number) => void;
  isMobile: boolean;
  userData: any;
  workData: any;
  uploadedFiles: Array<any>;
}

const KeytekSummaryStep = (props: Props) => {
  const {setStep, isMobile, userData, workData, uploadedFiles} = props;

  const handleGoBack = () => {
    window.scrollTo(0, 0);
    isMobile ? setStep(2) : setStep(1);
  }

  const handleComplete = () => {
    const files: any = []
    uploadedFiles.map(upload => files.push(upload.file))
      uploadKeytekContent({
      content: JSON.stringify({...userData, ...workData},null,'\t'),
      files
    }).then(() => {
      notification.success({
        message: "Success!",
        description: "Your data has been uploaded successfully.",
        placement: "topRight",
      })
    }).catch((e) => {
      notification.error({
        message: "Error",
        description: e.data && e.data.message ? e.data.message : "Something went wrong",
        placement: "topRight",
      })
    })
  }

  return <PageWrapper>
    <PageTitle>Summary (Page 3 of 3)</PageTitle>
    <FormWrapper>
      <FormSectionTitle>Property & user info</FormSectionTitle>
      <InfoLine>
        <span className={"title"}>Keytek User code</span>
        <span className={"description"}>{userData.propertyUserInfo.keytekCode ?? "None"}</span>
      </InfoLine>
      <InfoLine>
        <span className={"title"}>Email address</span>
        <span className={"description"}>{userData.propertyUserInfo.email ?? "None"}</span>
      </InfoLine>
      <InfoLine>
        <span className={"title"}>User preferred name</span>
        <span className={"description"}>{userData.propertyUserInfo.username ?? "None"}</span>
      </InfoLine>
      <InfoLine>
        <span className={"title"}>Property address</span>
        <span className={"description"}>
          {userData.propertyUserInfo.address ?? "None"}
          <br/>{userData.propertyUserInfo.zip ?? "None"}
          <br/>{userData.propertyUserInfo.city ?? "None"}
          <br/>{userData.propertyUserInfo.country ?? "None"}
          </span>
      </InfoLine>

      <FormSectionTitle>Work record</FormSectionTitle>
      <InfoLine>
        <span className={"title"}>Project</span>
        <span className={"description"}>
          Name: {workData.works[0].name ?? "None"}
          <br />Date: {workData.works[0].date ?? "None"}
          <br />Name: {workData.works[0].type ?? "None"}
          <br />Name: {workData.works[0].notes ?? "None"}
        </span>
      </InfoLine>
      <InfoLine>
        <span className={"title"}>Cost</span>
        <span className={"description"}>
          Cost: £{workData.works[0].cost ?? 0}
          <br />Payee: Keytek
        </span>
      </InfoLine>
    </FormWrapper>

    <FormWrapper style={{marginTop: "1.5rem", gap: 0}}>
      <FormSectionTitle>
        Attached files
        <div className={"subtitle"}>Files and assets that have been attached to this project.</div>
      </FormSectionTitle>
      <div style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
        {uploadedFiles.length > 0 && uploadedFiles.map((file: any) => <UploadedFile key={file.id}>
          <span className={"file-title"}>
            <ImageUpload />{file.name}
          </span>
        </UploadedFile>)}
        {uploadedFiles.length === 0 && <FormSectionTitle style={{borderBottom: "none"}}>
            <div className={"subtitle"}>You haven't uploaded any files.</div>
        </FormSectionTitle>}
      </div>
    </FormWrapper>
    <ButtonWrapper>
      <ButtonBlue onClick={() => handleGoBack()}>Back</ButtonBlue>
      <ButtonBlue onClick={() => handleComplete()}>Complete</ButtonBlue>
    </ButtonWrapper>
  </PageWrapper>
}

export default KeytekSummaryStep