import React, {useState} from "react";
import {ReactComponent as Appliances} from "../../../images/Appliances.svg";
import {ReactComponent as Electrical} from "../../../images/ContentsElectronics.svg";
import {ReactComponent as ToolsAndMachinery} from "../../../images/ToolsAndMachineryNew.svg";
import {ReactComponent as Furniture} from "../../../images/Furniture.svg";
import {ReactComponent as Systems} from "../../../images/Systems.svg";
import {ReactComponent as Other} from "../../../images/OtherInventory.svg";
import {InventoryListColumn} from "../../screens/Contents";
import {numberWithCommas} from "../helper";
import {Collapse, List, Menu, Modal, Popover} from "antd";
import {inventoryTypes, ListItemStyled, showLifeLeft} from "./InventoriesListComp";
import styled from "styled-components";
import {TGetInventoriesResponse} from "../../../api-wrapper/inventories/getInventories";
import TagContainer from "../tags/TagContainer";
import {getAllInventories} from "../../actions/inventories";
import {getAllMaintenances} from "../../actions/maintenances";
import {ReactComponent as ContentsUpkeep} from "../../../images/dashboard-calendar/CalendarToDo.svg";
import {ReactComponent as MoreMenu} from "../../../images/More.svg";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {MenuStyled} from "../works/WorksListComp";
import {removeInventory} from "../../../api-wrapper/inventories/removeInventory";
import {getAllTagsWithUsageCountByType} from "../../actions/tagsWithUsageCountByType";
import {Guid} from "guid-typescript";
import {getCurrencySign} from "../../screens/helpers";

const { Panel } = Collapse;

type Props = {
  type: string;
  inventories: TGetInventoriesResponse;
  isFiltering: any;
  defaultOpenType: any;
  setDefaultOpenType: (arg: any) => void;

  toggleInventoryDrawer: (arg: boolean) => void;
  setEditInventoryData: (arg: any) => void;
  refreshParent: () => void;
  selectedTags?: any;
  setInventoryToForward?: (arg01: Guid) => void;
  toggleForwardDrawer?: (arg01: boolean) => void;
}

const InventoriesSublistComp = (props: Props) => {
  const { type, inventories, isFiltering, defaultOpenType, setDefaultOpenType, toggleInventoryDrawer,
    setEditInventoryData, refreshParent, setInventoryToForward, toggleForwardDrawer, selectedTags } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [editTagsMode, toggleEditTagsMode] = useState(false)

  const displayIcon = (type: string) => {
    if (type === "Appliances") return <Appliances />;
    else if (type === "Electronics") return <Electrical />;
    else if (type === "ToolsAndMachinery") return <ToolsAndMachinery />;
    else if (type === "Furniture") return <Furniture />;
    else if (type === "Systems") return <Systems />;
    else return <Other />
  };

  function confirmDeleteReplacement(item: any) {
    Modal.confirm({
      title: "Do you want to delete the replacement upkeep for this item?",
      content:
        "You are currently subscribed to the replacement upkeep for this item. Do you want to delete it alongside the content item?",
      okText: "Yes, delete both",
      cancelText: "No, only delete content",
      className: "form-confirm-close-modal",
      onOk() {
        removeInventory(item.propertyId, item.id, true).then(() => {
          if (refreshParent) refreshParent();
          dispatch(getAllInventories(item.propertyId));
          dispatch(getAllTagsWithUsageCountByType(item.propertyId, "inventory"));
        });
      },
      onCancel() {
        removeInventory(item.propertyId, item.id).then(() => {
          if (refreshParent) refreshParent();
          dispatch(getAllInventories(item.propertyId));
          dispatch(getAllTagsWithUsageCountByType(item.propertyId, "inventory"));
        });
      }
    });
  }

  function confirmDelete(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        if (item.subscribedToReplacementUpkeep) {
          confirmDeleteReplacement(item)
        } else {
          removeInventory(item.propertyId, item.id).then(() => {
            if (refreshParent) refreshParent();
            dispatch(getAllInventories(item.propertyId));
            dispatch(getAllTagsWithUsageCountByType(item.propertyId, "inventory"));
          });
        }
      }
    });
  }

  const moreMenuDropdown = (item: any) => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      if (key === "edit") {
        toggleInventoryDrawer(true);
        setEditInventoryData({ data: item });
      } else if (key === "delete") {
        confirmDelete(item)
      } else if (key === "forward") {
        setInventoryToForward && setInventoryToForward(item.id)
        toggleForwardDrawer && toggleForwardDrawer(true)
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="forward">Forward</Menu.Item>
        <Menu.Item key="edit">Edit</Menu.Item>
        <Menu.Item key="delete">Delete</Menu.Item>
      </MenuStyled>
    );
  };

  const InventoryListItemNew = (item: any) => {
    return (
      <ListItemStyled
        key={item.id}
        onClick={() => {
          if (!editTagsMode) {
            toggleInventoryDrawer(true);
            setEditInventoryData({ data: item });
          }
        }}>
        <InventoryListColumn style={{justifyContent: "center", marginLeft: "1rem"}} spanWidth={"5%"} />
        <InventoryListColumn style={{justifyContent: "flex-start", flexDirection: "column", rowGap: "0.25rem"}} spanWidth={"45%"}>
          <div><ItemName>{item.name}</ItemName><ItemLocation>{item.brand}</ItemLocation></div>
          <TagContainer
            parentTags={item.tags}
            parentId={item.id}
            parentType={"inventory"}
            selectedTags={selectedTags}
            toggleGlobalEditMode={toggleEditTagsMode}
            refreshParent={() => dispatch(getAllInventories(propertyId))}
          />
        </InventoryListColumn>
        <InventoryListColumn style={{justifyContent: "center", alignItems: "flex-end", height: "3.375rem"}} spanWidth={"15%"}>
          {item.relatedUpkeepsCount > 0 && <ContentUpkeepLabel
              style={{cursor: "pointer"}}
              onClick={() => {
                dispatch(getAllMaintenances(propertyId));
                history.push(`/upkeep?tab=All&contentId=${item.id}`)
              }}>
              <ContentsUpkeep/>
              <div style={{display: "flex"}}>
                {item.subscribedRelatedUpkeepsCount}
                {" / "}
                {item.relatedUpkeepsCount}</div>
          </ContentUpkeepLabel>}
        </InventoryListColumn>
        <InventoryListColumn style={{
          alignItems: "flex-end",
          justifyContent: "flex-end",
          flexDirection: "column",
          rowGap: "0.25rem",
          height: "3.375rem"
        }} spanWidth={"16%"}>
          {item.purchaseCost > 0 && <ContentPriceTag>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
            {item.purchaseCost}
          </ContentPriceTag>}
          {item.insuredItems ? <ContentLabelGreen>Insured</ContentLabelGreen> :
            <ContentLabelGray>Not insured</ContentLabelGray>}
        </InventoryListColumn>
        <InventoryListColumn style={{
          alignItems: "flex-start",
          justifyContent: "flex-end",
          flexDirection: "column",
          height: "3.375rem",
          minWidth: "6.25rem"
        }} spanWidth={"15%"}>
          {showLifeLeft(item)}
        </InventoryListColumn>
        <InventoryListColumn style={{
          alignItems: "flex-start",
          flexDirection: "row",
          height: "3.375rem",
          justifyContent: "flex-end",
          marginRight: "1rem"
        }} spanWidth={"5%"}>
          <Popover
            content={moreMenuDropdown(item)}
            placement="bottomRight"
            trigger={["click"]}
            //@ts-ignore
            zIndex={2}
          >
            <MoreMenu onClick={(e) => e.stopPropagation()}/>
          </Popover>
        </InventoryListColumn>
      </ListItemStyled>)
  }

  const InventoryListItemMobile = (item: any) => {
    return (
      <ListItemStyled
        key={item.id}
        onClick={() => {
          if (!editTagsMode) {
            toggleInventoryDrawer(true);
            setEditInventoryData({ data: item });
          }
        }}>
        <div style={{width: "100%", padding: "0.25rem 1rem"}}>
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "0.75rem"}}>
            <ItemName>{item.name}</ItemName>
            <div style={{display: "flex", alignItems: "center", columnGap: "0.5rem"}}>
              {item.purchaseCost > 0 && <ContentPriceTag>
                {getCurrencySign(propertyPreferences.preferredCurrency)}
                {item.purchaseCost}
              </ContentPriceTag>}
              <Popover
                content={moreMenuDropdown(item)}
                placement="bottomRight"
                trigger={["click"]}
                //@ts-ignore
                zIndex={2}
              >
                <MoreMenu onClick={(e) => e.stopPropagation()}/>
              </Popover>
            </div>
          </div>
          <TagContainer
            parentTags={item.tags}
            parentId={item.id}
            parentType={"inventory"}
            selectedTags={selectedTags}
            toggleGlobalEditMode={toggleEditTagsMode}
            refreshParent={() => dispatch(getAllInventories(propertyId))}
          />
          <div style={{
            display: "flex", alignItems: "flex-start", columnGap: "0.75rem",
            color: "#666F7A", fontSize: "0.875rem", lineHeight: "1.25rem", marginTop: "0.75rem"}}>
            {item.relatedUpkeepsCount.length > 0 && <div style={{display: "flex", flexDirection: "column", rowGap: "0.375rem", flexShrink: 0}}>
                Upkeep
                <ContentUpkeepLabel
                    style={{cursor: "pointer"}}
                    onClick={() => history.push(`/upkeep?tab=All&contentId=${item.id}`)}>
                    <ContentsUpkeep/>
                    <div style={{display: "flex"}}>
                      {item.subscribedRelatedUpkeepsCount}
                      {" / "}
                      {item.relatedUpkeepsCount}</div>
                </ContentUpkeepLabel>
            </div>}
            <div style={{display: "flex", flexDirection: "column", rowGap: "0.375rem", flexShrink: 0}}>
              Insured status
              {item.insuredItems ? <ContentLabelGreen>Insured</ContentLabelGreen> :
                <ContentLabelGray>Not insured</ContentLabelGray>}
            </div>
            {showLifeLeft(item) &&
            <div style={{display: "flex", flexDirection: "column", rowGap: "0.375rem"}}>
                Life status
              {showLifeLeft(item)}
            </div>}
          </div>
        </div>
      </ListItemStyled>)
  }

  if (inventories.length < 1) return <></>;

  return (
    <CollapseStyled
      ghost
      key={type}
      expandIconPosition={"right"}
      activeKey={isFiltering ? inventoryTypes : (defaultOpenType ? defaultOpenType : "")}
      onChange={(key) => setDefaultOpenType(key)}
    >
      <Panel header={
        window.innerWidth > 1024 ? <div style={{display: "flex", justifyContent: "space-between"}}>
          <InventoryListColumn style={{justifyContent: "center", alignItems: "center"}} spanWidth={"5%"}>
            {displayIcon(type)}
          </InventoryListColumn>
          <InventoryListColumn style={{justifyContent: "flex-start", alignItems: "center"}} spanWidth={"45%"}>
            <ColumnTitle>
              {type === "ToolsAndMachinery" ? "Tools and Machinery" : type}
            </ColumnTitle>
            <ItemCountLabel>{inventories.length}</ItemCountLabel>
          </InventoryListColumn>
          <InventoryListColumn style={{justifyContent: "center", alignItems: "center", minWidth: "6.25rem"}} spanWidth={"15%"} />
          <InventoryListColumn style={{justifyContent: "flex-end", alignItems: "center"}} spanWidth={"16%"}>
            <ColumnTitle>
              {inventories.reduce(
                (sumOfInventories, {purchaseCost}) => sumOfInventories + purchaseCost, 0) > 0 &&
              <>{getCurrencySign(propertyPreferences.preferredCurrency)} {numberWithCommas(inventories.reduce(
                (sumOfInventories, {purchaseCost}) => sumOfInventories + purchaseCost, 0))}</>}
            </ColumnTitle>
          </InventoryListColumn>
          <InventoryListColumn style={{justifyContent: "flex-start", alignItems: "center"}} spanWidth={"20%"} />
        </div> : <div style={{width: "100%", display: "flex", color: "#666F7A", justifyContent: "space-between", alignItems: "center"}}>
          <div style={{display: "flex", alignItems: "center", columnGap: "0.5rem", fontSize: "1rem!important"}}>
            {displayIcon(type)}
            <ColumnTitle>
              {type === "ToolsAndMachinery" ? "Tools and Machinery" : type}
            </ColumnTitle>
            <ItemCountLabel style={{marginLeft: 0}}>{inventories.length}</ItemCountLabel>
          </div>
          <ColumnTitle style={{paddingRight: "1.65rem"}}>
            {inventories.reduce(
              (sumOfInventories, {purchaseCost}) => sumOfInventories + purchaseCost, 0) > 0 &&
            <>{getCurrencySign(propertyPreferences.preferredCurrency)} {numberWithCommas(inventories.reduce(
              (sumOfInventories, {purchaseCost}) => sumOfInventories + purchaseCost, 0))}</>}
          </ColumnTitle>
        </div>}
             key={type}>
        <List
          itemLayout="horizontal"
          className="rounded-bottom-list"
          dataSource={inventories.filter(inv => inv.name !== null)}
          renderItem={(item) => window.innerWidth > 1024 ?
            InventoryListItemNew(item) : InventoryListItemMobile(item)}
        />
      </Panel>
    </CollapseStyled>
  );
}

const CollapseStyled = styled(Collapse)`
  width: 100%;
  
  .ant-collapse-header {
    background: #F5F6FA;
    padding: 1.25rem 1rem!important;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #666F7A;
  }
  
  .anticon {
    margin-right: 0.75rem;
  }
  
  @media (max-width: 1024px) {
    .anticon {
      margin-right: 0;
    }
  }
`;

const ItemCountLabel = styled.div`
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #9CA4AE;
  padding: 0 0.5rem;
  border-radius: 1rem;
  font-weight: 600;
  font-size: 1.125rem!important;
  line-height: 1.5rem;
  color: #FFFFFF;
`

const ColumnTitle = styled.div`
  font-weight: 600;
  font-size: 1.125rem!important;
  line-height: 1.5rem;
  color: #666F7A;
`

const ItemName = styled.span`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #21272B;
`

const ItemLocation = styled.span`
  margin-left: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgba(14, 20, 29, 0.5);
`

export const ContentLabel = styled.span`
  border: 1px solid #9CA4AE;
  border-radius: 4px;
  padding: 0.125rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #9CA4AE;
  flex-grow: 0;
`

export const ContentLabelGreen = styled(ContentLabel)`
  border-color: #00B74F;
  color: #00B74F;
  background: #EFFDF7;
`

const ContentLabelGray = styled(ContentLabel)`
  border-color: #9CA4AE;
  color: #9CA4AE;
  background: #F5F6FA;
`

const ContentUpkeepLabel = styled(ContentLabel)`
  border-color: #00B74F;
  color: #666F7A;
  background: #EFFDF7;
  font-size: 0.75rem;
  line-height: 1.125rem;
  column-gap: 0.625rem;
  flex-shrink: 0;
  height: 1.625rem;
`

const ContentPriceTag = styled.div`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #21272B;
`

export default InventoriesSublistComp