import React from "react";
import {toggleNotApplicable} from "../../../../api-wrapper/maintenance/toggleNotApplicable";
import {getAllMaintenances} from "../../../actions/maintenances";
import {getAllOnboardingMaintenances} from "../../../actions/onboardingMaintenances";
import {showLast} from "../../../../api-wrapper/maintenance/showLast";
import {Dropdown, Menu, Modal, notification, Tooltip} from "antd";
import {removeMaintenance} from "../../../../api-wrapper/maintenance/removeMaintenance";
import {ReactComponent as MoreMenu} from "../../../../images/More.svg";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../../selectors";
import {editMaintenanceApi} from "../../../../api-wrapper/maintenance/editMaintenance";
import {Guid} from "guid-typescript";
import {disableRelation} from "../../../../api-wrapper/relation/disableRelation";
import {isCustom} from "../MaintenanceListItemNew";

type Props = {
  item: any;
  inventory?: Guid;
  isOnboarding: boolean;
  editItem: (arg: any) => void;
  toggleViewWorkDrawer?: (arg: boolean) => void;
  setViewWorkId?: (arg: any) => void;
  refreshParent?: any;
}

export function noRecordsFound() {
  Modal.error({
    title: "No records found",
    content: "There are no past records for this item.",
    okText: "OK",
    className: "form-confirm-close-modal",
  });
}

export function showApplicableNotification(notApplicable: boolean, title: string) {
  notification.success({
    message: notApplicable ? "Moved to Applicable" : "Moved to Not applicable",
    description:
      notApplicable ? `${title} has been made applicable again.` : `${title} has been made not applicable.`,
    duration: 5
  });
}

export function showAutocompleteNotification(autocomplete: boolean, title: string) {
  notification.success({
    message: autocomplete ? "Task set to auto-complete" : "Auto-complete removed from task",
    description:
      autocomplete ? `${title} is now set to auto-complete. This means that the task will automatically 
        be marked as completed when it is due. Any associated expenses will also be generated at that time.` :
        `${title} is no longer set to auto-complete. To complete the task you will need to manually mark 
        it as completed from your dashboard Schedule view or from the To-do list.`,
    duration: 5
  });
}

const MaintenanceMoreMenu = (props: Props) => {
  const {item, inventory, isOnboarding, editItem, setViewWorkId, toggleViewWorkDrawer, refreshParent} = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;

  const toggleAutocomplete = (item: any) => {
    editMaintenanceApi({
      propertyId,
      ...item,
      autocomplete: !item.autocomplete
    }, item.id).then(() => {
      showAutocompleteNotification(!item.autocomplete, item.title)
      dispatch(getAllMaintenances(propertyId))
      isOnboarding && dispatch(getAllOnboardingMaintenances(propertyId))
    })
  }

  const moreMenuDropdown = (item: any) => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;

      if (key === "notApplicable") {
        toggleNotApplicable({
          propertyId,
          maintenanceId: item.id,
          applicable: !item.notApplicable
        }).then(() => {
          showApplicableNotification(item.notApplicable, item.title)
          dispatch(getAllMaintenances(propertyId))
          isOnboarding && dispatch(getAllOnboardingMaintenances(propertyId))
          refreshParent && refreshParent()
        })
      }
      else if (key === "showLast" && item.savedRecordsCount) {
        showLast(propertyId, item.id).then((res: any) => {
          if (res === "") noRecordsFound()
          else {
            toggleViewWorkDrawer && toggleViewWorkDrawer(true);
            setViewWorkId && setViewWorkId(res.workId);
          }
        })
      }
      else if (key === "edit") {
        editItem(item)
      }
      else if (key === "autocomplete") {
        toggleAutocomplete(item)
      }
      else if (key === "unlink") {
        inventory && disableRelation({
          propertyId,
          parentId: inventory,
          childId: item.id,
          parentType: "inventory",
          childType: item.upkeepType,
          disabled: true
        }).then(() => {
          dispatch(getAllMaintenances(propertyId))
          refreshParent && refreshParent()
        })
      }
      else if (key === "delete") {
        Modal.confirm({
          title: "Are you sure you want to delete this item?",
          content:
            "You will not be able to restore it after deletion.",
          okText: "OK",
          cancelText: "Cancel",
          className: "form-confirm-close-modal",
          onOk() {
            removeMaintenance(item.propertyId, item.id).then(() => {
              dispatch(getAllMaintenances(propertyId));
              isOnboarding && dispatch(getAllOnboardingMaintenances(propertyId))
              refreshParent && refreshParent()
            });
          }
        });
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="edit">Edit</Menu.Item>
        {!item.subscribed && !isOnboarding && <Menu.Item key="notApplicable">
            Move to {!item.notApplicable && "Not"} Applicable
        </Menu.Item>}
        {item.subscribed && !isOnboarding && <Menu.Item key="unavailable">
            <Tooltip title={"You cannot move an item you are subscribed to into Not Applicable"}>
                <span style={{color: "#d6d6d6"}}>Move to Not Applicable</span>
            </Tooltip>
        </Menu.Item>}
        {!isOnboarding && !inventory && <Menu.Item key="showLast">
          {item.savedRecordsCount ? "Show last" : <span style={{color: "#d6d6d6"}}>Show last</span>}
        </Menu.Item>}
        {item.category !== 'Custom' && !inventory && <Menu.Item key="autocomplete">
          {item.autocomplete ? "Remove auto-complete" : "Set to auto-compete"}
        </Menu.Item>}
        {inventory && <Menu.Item key="unlink">Unlink</Menu.Item>}
        {isCustom(item) && <Menu.Item key="delete">Delete</Menu.Item>}
      </MenuStyled>
    );
  };

  return <Dropdown
    overlay={moreMenuDropdown(item)}
    placement="bottomRight"
    trigger={["click"]}
  >
    <MoreButton onClick={(e: any) => e.stopPropagation()}><MoreMenu/></MoreButton>
  </Dropdown>
}

const MoreButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 2rem;
  width: 2rem;
`

const MenuStyled = styled(Menu)`
  border-right: 0;
  font-weight: 600;
  border: 1px solid rgba(0,0,0,0) !important;;
  border-radius: 10px;
  color: #21272b;

  .ant-menu-item-active {
    color: #6b7185 !important;
  }
  
  .ant-menu-item {
    height: 1.5rem !important;
    padding-bottom: 2rem !important;
  }
`;

export default MaintenanceMoreMenu