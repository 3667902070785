import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TRecipient} from "./referFriends";
import {Guid} from "guid-typescript";

export type TForwardExpenseRequest = {
  customMessage: string;
  ccSender: boolean;
  recipients: Array<TRecipient>;
  propertyId: Guid;
  expenseId: Guid;

  isPropertyAddressEnabled: boolean;
  isExpenseNameEnabled: boolean;
  isExpenseTypeEnabled: boolean;
  isPayeeNameEnabled: boolean;
  isTotalCostEnabled: boolean;
  isPaymentDateEnabled: boolean;
  isTaxDeductibleEnabled: boolean;
  isExpenseNotesEnabled: boolean;
  attachments: Array<Guid>;
};

export const forwardExpense = (payload: TForwardExpenseRequest) => {
  const {expenseId} = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/forwardExpense/${expenseId}/send`
  );

  return apiPost<any, TForwardExpenseRequest>(
    url, payload
  );
};
