import axios from "axios";
import {apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import _ from "lodash";
import {Guid} from "guid-typescript";

export type TSearchFilters = {
  dataTypes?: Array<string>;
  properties?: Array<Guid>;
  isWorkCompleted?: boolean;
  isInsuredItem?: boolean;
  isTaxDeductible?: boolean;
  startDate?: string;
  endDate?: string;
}

export type TSearchRequest = {
  query: string;
  filter: TSearchFilters;
  page?: number;
  size?: number;
};

export type TSearchItem = {
  id: Guid;
  type: string;
  parentId: Guid;
  parentType: string;
  parentName: string;
  name: string;
  createdDate: string | null;
  relevance: number;
  object: any;
  improvementId: string;
  propertyId: Guid;
}

export type TElasticSearchItem = {
  content: TSearchItem;
  fullItem: any;
  highlightFields: {
    textInternal: Array<string>;
    textExternal: Array<string>;
  }
}

export type TResultCountDetails = {
  attachment?: { total: number };
  note?: { total: number };
  contact?: { total: number };
  budgetcost?: { total: number };
  work?: { total: number; completed: number; };
  expense?: { total: number; taxDeductible: number; };
  inventory?: { total: number; insuredItems: number; };
  tenancy?: { total: number; insuredItems: number; };
  insurance?: { total: number; insuredItems: number; };
  improvement?: { total: number; insuredItems: number; };
  customreminder?: { total: number; insuredItems: number; };
  upkeep?: { total: number; insuredItems: number; };
  space?: { total: number };
  pet?: { total: number };
  property?: any;
}

export type TSearchResponse = {
  foundItemList: Array<TElasticSearchItem>;
  totalItems: number;
  totalMatching: number;
  totalPages: number;
  currentPage: number;
  resultCountByType: TResultCountDetails;
};

let call:any = null;

export const search = async (searchRequest: TSearchRequest) => {
  let url = createUrl(`${EnvConfig.API_URL}/api/search?page=`+searchRequest.page+'&size='+searchRequest.size);

  if (call) {
    // error of type search is caught in the interceptor, line 29 apiCall.tsx
    call.cancel("search");
  }
  call = axios.CancelToken.source();

  return apiPost<TSearchResponse, TSearchRequest>(url,_.omit(searchRequest,["page", "size"]),
    {cancelToken: call.token});
};
