import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux";
import {getContactsSelector} from "../selectors";
import {emailRegex} from "../screens/helpers";
import Creatable from "react-select/creatable";
import {Form} from "antd";

type Props = {
  emailList: any,
  setEmailList: (arg: any) => void
}

const EmailCreatableSelector = (props: Props) => {
  const {emailList, setEmailList} = props
  const contacts = useSelector(getContactsSelector);
  const [contactsList, setContactsList] = useState([])
  const [customEmailError, setCustomEmailError] = useState(false)
  const [invalidCustomEmailList, setInvalidCustomEmailList] = useState([])
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    // @ts-ignore
    let availableContacts = []
    contacts.content.map(contact => {
      if (contact.email)
        availableContacts.push({ value: contact.email, label: contact.name })
      // @ts-ignore
      setContactsList(availableContacts);
    })
  }, [contacts])

  const handleSelectChange = (newValue: any) => {
    // @ts-ignore
    let selectedEmails = []
    // @ts-ignore
    let invalidEmails = []
    setCustomEmailError(false)

    newValue.map((email: { label: string; value: string }) => {
      if (emailRegex.test(email.value)) {
        selectedEmails.push({label: email.label, value: email.value})
      }
      else {
        invalidEmails.push(email.value)
        setCustomEmailError(true)
      }
    })

    // @ts-ignore
    setEmailList(selectedEmails)
    // @ts-ignore
    setInvalidCustomEmailList(invalidEmails)
  };

  const handleKeyDown = (e: any) => {
    if (!inputValue) return;
    switch (e.key) {
      case 'Enter':
      case ' ':
      case 'Tab':
        handleSelectChange([...emailList, {label: inputValue, value: inputValue}]);
        setInputValue('');
    }
  };

  return (<Form.Item key={"email"} name={"email"}>
    <Creatable
      isClearable
      isMulti
      value={emailList}
      onChange={handleSelectChange}
      onKeyDown={handleKeyDown}
      inputValue={inputValue}
      onInputChange={setInputValue}
      options={contactsList}
      formatCreateLabel={userInput => `Send to ${userInput}`}
      hideSelectedOptions={false}
    />
    {customEmailError &&
    <div
        role="alert"
        style={{
          marginTop: '0.4rem',
          color: "#ff4d4f",
          fontWeight: 400,
          fontSize: "0.9rem"
        }}
    >{invalidCustomEmailList.join(", ")} is not a valid email address.</div>
    }
  </Form.Item>)
}

export default EmailCreatableSelector