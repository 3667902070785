import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetCalendarTasksResponse } from "../../api-wrapper/upcomingTasks/getCalendarTasks";
import {
  getCalendarTasksFailureAction,
  getCalendarTasksInitAction,
  getCalendarTasksSuccessAction,
  TGetCalendarTasksActions,
} from "../actions/calendarTasks";

export type TCalendarTasks = {
  fetchingStatus: TFetchingStatus;
  content: TGetCalendarTasksResponse;
};

const initialState: TCalendarTasks = {
  content: {},
  fetchingStatus: TFetchingStatus.Defined,
};

export const calendarTasksReducer = createReducer<TCalendarTasks, TGetCalendarTasksActions>(
  initialState
)
  .handleAction(getCalendarTasksInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getCalendarTasksSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getCalendarTasksFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
