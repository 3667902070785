import React from "react";
import styled from "styled-components";
import LinkedItem from "./LinkedItem";
import LinkEntityOptions from "./LinkEntityOptions";
import {Guid} from "guid-typescript";

type Props = {
  parentId: Guid | null;
  parentType: string;
  items: Array<any>
  searchQuery: string;
  isSelectionMode: boolean;
  selectedType?: string;
  setSelectedType?: (arg: string) => void;
  isItemSelected?: (arg: any) => boolean;
  isSelectedByDefault?: (arg: any) => boolean;
  toggleItemSelection?: (arg: any) => void;
  refreshParent?: any;

  toggleViewWorkDrawer: (arg: boolean) => void;
  setViewWorkId: (arg: any) => void;
  toggleWorkDrawer: (arg: boolean) => void;
  setEditWorkData: (arg: any) => void;
  toggleExpenseDrawer: (arg: boolean) => void;
  setEditExpenseData: (arg: any) => void;
  toggleInventoryDrawer: (arg: boolean) => void;
  setEditInventoryData: (arg: any) => void;
  toggleContactDrawer: (arg: boolean) => void;
  setEditContactData: (arg: any) => void;
  toggleNoteDrawer: (arg: boolean) => void;
  setEditNoteData: (arg: any) => void;
  toggleSpaceDrawer: (arg: boolean) => void;
  setEditSpaceId: (arg: any) => void;
  toggleTaskDrawer: (arg: boolean) => void;
  setEditTaskData: (arg: any) => void;
  setAttachmentId: (arg: any) => void;
  toggleViewFileDrawer: (arg: boolean) => void;
}

const LinkedItemsList = (props: Props) => {
  const {
    parentId,
    parentType,
    items,
    searchQuery,
    isSelectionMode,
    isItemSelected,
    isSelectedByDefault,
    toggleItemSelection,
    toggleWorkDrawer,
    toggleContactDrawer,
    toggleExpenseDrawer,
    toggleInventoryDrawer,
    toggleNoteDrawer,
    setEditWorkData,
    setEditContactData,
    setEditExpenseData,
    setEditInventoryData,
    setEditNoteData,
    toggleSpaceDrawer,
    setEditSpaceId,
    toggleTaskDrawer,
    setEditTaskData,
    selectedType,
    setSelectedType,
    refreshParent,
    setViewWorkId,
    toggleViewWorkDrawer,
    setAttachmentId,
    toggleViewFileDrawer,
  } = props;

  return <LinkedItems>
    {selectedType === "" && setSelectedType && searchQuery.length === 0 &&
    <LinkEntityOptions
      selectedType={selectedType}
      setSelectedType={setSelectedType}
      parentType={parentType}
    />}
    {selectedType !== "" && searchQuery.length === 0 && items.length === 0 && "No items available for linking."}
    {searchQuery.length > 0 && items.length === 0 && "No results matching your search query."}
    {(selectedType !== "" || searchQuery.length > 0) && items.map(item => <LinkedItem
      item={item}
      parentId={parentId}
      parentType={parentType}
      refreshParent={refreshParent}
      selectedType={selectedType}
      isSelectionMode={isSelectionMode}
      isItemSelected={isItemSelected}
      isSelectedByDefault={isSelectedByDefault}
      toggleItemSelection={toggleItemSelection}
      toggleWorkDrawer={toggleWorkDrawer}
      setEditWorkData={setEditWorkData}
      toggleExpenseDrawer={toggleExpenseDrawer}
      setEditExpenseData={setEditExpenseData}
      toggleInventoryDrawer={toggleInventoryDrawer}
      setEditInventoryData={setEditInventoryData}
      toggleContactDrawer={toggleContactDrawer}
      setEditContactData={setEditContactData}
      toggleNoteDrawer={toggleNoteDrawer}
      setEditNoteData={setEditNoteData}
      setEditSpaceId={setEditSpaceId}
      toggleSpaceDrawer={toggleSpaceDrawer}
      toggleTaskDrawer={toggleTaskDrawer}
      setEditTaskData={setEditTaskData}
      setViewWorkId={setViewWorkId}
      toggleViewWorkDrawer={toggleViewWorkDrawer}
      setAttachmentId={setAttachmentId}
      toggleViewFileDrawer={toggleViewFileDrawer}
    />)}
  </LinkedItems>
}

const LinkedItems = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  
  font-size: 1rem;
  line-height: 1.5rem;
`

export default LinkedItemsList