import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetTenantsResponse } from "../../api-wrapper/tenants/getTenants";
import {
  getTenantsFailureAction,
  getTenantsInitAction,
  getTenantsSuccessAction,
  TGetTenantsActions,
} from "../actions/tenants";
import {emptyGuid} from "../screens/helpers";

export type TTenants = {
  fetchingStatus: TFetchingStatus;
  content: TGetTenantsResponse;
};

const initialState: TTenants = {
  content: [
    {
      tenancyId: emptyGuid,
      propertyId: emptyGuid,
      startDate: "",
      endDate: "",
      rentalPayment: 0,
      tenancyContactId: emptyGuid,
      agentFee: 0,
      agentContact: emptyGuid,
      propertyManagementFee: 0,
      propertyManagementContact: emptyGuid,
    },
  ],
  fetchingStatus: TFetchingStatus.Defined,
};

export const tenantsReducer = createReducer<TTenants, TGetTenantsActions>(
  initialState
)
  .handleAction(getTenantsInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getTenantsSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getTenantsFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
