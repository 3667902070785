import {addAuthToken, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import axios from "axios";

export const dataDownload = () => {
  let url = createUrl(`${EnvConfig.API_URL}/api/data/download`);

  const params = {
    headers: addAuthToken(),
  };

  return axios.get(url, {
    ...params, responseType: 'blob',
  });
};
