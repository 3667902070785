import { Guid } from "guid-typescript";

export const CALENDAR_FREQUENCY = {
  day: 'DAILY',
  week: 'WEEKLY',
  month: 'MONTHLY',
  year: 'YEARLY',
};

export type Task = {
  title: string,
  message: string,
  type: string,
  date: string,
  parentName: string,
  parentType: string,
  periodicity: string,
  task: {
    propertyId: Guid,
    templateId: Guid,
    periodicity: number,
    category: string,
    periodicityUnit: keyof typeof CALENDAR_FREQUENCY,
    triggerDate: string,
    workType: Record<string, any>,
    estimatedCost: number;
    actualEstimatedCost: number;
  }
}