import {List, Modal, Popconfirm, Skeleton} from "antd";
import React from "react";
import styled from "styled-components";
import _ from "lodash";

import { ReactComponent as DownloadCircle } from "../../../images/DownloadCircle.svg";
import { downloadAttachment } from "../../../api-wrapper/attachments/downloadAttachment";
import { ReactComponent as DeleteImage } from "../../../images/Delete.svg";
import { ReactComponent as ViewImage } from "../../../images/attachments/View.svg";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import {Guid} from "guid-typescript";
import {niceBytes} from "../helper";
import {removeRelation} from "../../../api-wrapper/relation/removeRelation";

type Props = {
  file: any;
  propertyId: Guid;
  fileList: Array<any>;
  refreshParent: (arg?: Guid | null) => void;
  parentId: Guid | null;
  parentType: string | null;
  recordId?: Guid | null;
  setFile?: ((arg: any) => void) | null;
  parentName?: string | null;
  addedRef: any;
  hideDelete?: boolean | null;
  toggleViewFileDrawer?: (arg: boolean) => void | null;
  setAttachmentToViewId?: (arg: any) => void | null;
};

const ItemRenderComp = (props: Props) => {
  const {
    file,
    setFile,
    propertyId,
    fileList,
    parentId,
    parentType,
    recordId,
    refreshParent,
    parentName,
    addedRef,
    hideDelete,
    toggleViewFileDrawer,
    setAttachmentToViewId,
  } = props;

  const getParentType = parentName ? parentName : file.name;

  function confirmDownload(resourceLink: any) {
    Modal.confirm({
      title: "Download ready",
      content:
        "Your document is ready for download",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        window.open(resourceLink, "_blank");
      }
    });
  }


  const displayFilePath = file.path && <FilePath>{file.path}</FilePath>;
  return (
    <ListItemStyled
      className={!_.isEmpty(addedRef) ? "highlight-from-search" : ""}
      actions={[
        <FileSizeAction>{niceBytes(file.size)}</FileSizeAction>,
        <a>
          <ViewImage onClick={() => {
            if (propertyId && file.attachmentId && toggleViewFileDrawer && setAttachmentToViewId) {
              setAttachmentToViewId(file.attachmentId);
              toggleViewFileDrawer(true);
            }
          }}/>
        </a>,
        <a {...addedRef}>
          <DownloadCircle
            onClick={() => {
              propertyId &&
                file.attachmentId &&
                downloadAttachment({
                  propertyId,
                  id: file.attachmentId,
                }).then((res) => {
                  window.innerWidth > 1024
                    ? window.open(res, "_blank")
                    : confirmDownload(res)
                });
            }}
          />
        </a>,
        !hideDelete && <a>
          <Popconfirm
            title={
              <span
                style={{display: 'block', width: '14rem', marginTop: '0.5rem'}}>
            Are you sure you want to delete this file?
          </span>}
            onConfirm={() => {
              const index = fileList.indexOf(file);
              if (propertyId && parentId && parentType && fileList && fileList[index]) {
                removeRelation(
                  propertyId,
                  parentId,
                  parentType,
                  fileList[index].attachmentId,
                  "attachment"
                ).then(() => refreshParent(recordId));
              } else if (setFile) {
                setFile([]);
              }
            }}
            onCancel={(e) => {e!.stopPropagation()}}
            icon={""}
            okText={'Yes'}
            cancelText={'No'}
          >
          <DeleteImage/>
          </Popconfirm>
        </a>,
      ]}
    >
      <Skeleton loading={false} title={false} active>
        <>{file.originFileObj}</>
        {getParentType && (
          <List.Item.Meta
            title={
              <span onClick={() => {
                if (propertyId && file.attachmentId && toggleViewFileDrawer && setAttachmentToViewId) {
                  setAttachmentToViewId(file.attachmentId);
                  toggleViewFileDrawer(true);
                }
                // downloadAttachment({
                //   propertyId,
                //   id: file.attachmentId,
                // }).then((res) => {
                //   window.innerWidth > 1024
                //     ? window.open(res, "_blank")
                //     : confirmDownload(res)
                // });
              }}>
                {" "}
                <Ellipsis length={window.innerWidth > 1024 ? 40 : 20}>
                  {file.name}
                </Ellipsis>
              </span>
            }
            description={
              <>
                <AttachmentDescriptionMobile>
                  {displayFilePath}
                </AttachmentDescriptionMobile>
                <AttachmentDescriptionDesktop>
                  {niceBytes(file.size)}
                </AttachmentDescriptionDesktop>
              </>
            }
          />
        )}
      </Skeleton>
    </ListItemStyled>
  );
};

export const FileSizeAction = styled.span`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ListItemStyled = styled(List.Item)`
  border: 1px solid #dae0e6;
  border-radius: 10px;
  align-items: center;
  margin-top: 1rem;
  border-bottom: 1px solid #f0f0f0 !important;

  @media (min-width: 1024px) {
    padding: 1rem;
  }

  @media (max-width: 1024px) {
    height: 6rem;
    padding: 0.5rem;
  }

  .ant-list-item-action {
    margin-left: 0;
    display: flex;
  }

  .ant-list-item-meta-title {
    font-size: 1rem;
    font-weight: 600;
    color: #21272b;
  }
  .ant-list-item-meta-description {
    color: #6b7185;
    font-weight: 400;
    margin-top: 0.6rem;
  }
`;

export const AttachmentDescriptionDesktop = styled.span`
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const AttachmentDescriptionMobile = styled.span`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const FilePath = styled.span`
  text-decoration: underline;
`;

export default ItemRenderComp;
