import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import EnvConfig from "../../config";
import {register, TSignUpFinalRequest} from "../../api-wrapper/auth/signUp";
import {registrationAttempt, TSignUpStep1Request} from "../../api-wrapper/auth/registrationAttempt";
import {registrationPayment, TSignUpStep2Request} from "../../api-wrapper/auth/registrationPayment";
import {saveAccessToken} from "../../api-wrapper/auth/auth";
import {push} from "react-router-redux";
import decodeJwt from "jwt-decode";
import {logInSuccessAction} from "./logIn";
import {acceptInvite, TAcceptInviteRequest} from "../../api-wrapper/user/acceptInvite";
import {registrationSimple, TSignUpRequest} from "../../api-wrapper/auth/registrationSimple";
import {getPropertiesResetAction} from "./properties";
import {getPropertyIdInitAction} from "./propertyId";
import {
  registrationCodeConfirmation,
  TSignUpCodeConfirmRequest
} from "../../api-wrapper/auth/registrationCodeConfirmation";
import {pushStepToGoogle} from "../screens/helpers";
import {getPropertyPreferencesInitAction} from "./propertyPreferences";
import {replace} from "connected-react-router";

export type TRegistrationError = {
  emailError: boolean;
  captchaError: boolean;
  unknownError: boolean;
}

export const signUpInitAction = createAction("auth/SIGN_UP_INIT")();
export const signUpSuccessAction = createAction("auth/SIGN_UP_SUCCESS")();
export const signUpFailureAction = createAction("auth/SIGN_UP_FAILURE")<TRegistrationError>();
export const signUpStep1SuccessAction = createAction("auth/SIGN_UP_STEP1_SUCCESS")();
export const signUpStep2SuccessAction = createAction("auth/SIGN_UP_STEP2_SUCCESS")<string>();
export const signUpAcceptInviteAction = createAction("auth/SIGN_UP_ACCEPT_INVITE_SUCCESS")();

export type TSignUpInitAction = ActionType<typeof signUpInitAction>;
export type TSignUpSuccessAction = ActionType<typeof signUpSuccessAction>;
export type TSignUpFailureAction = ActionType<typeof signUpFailureAction>;
export type TSignUpStep1SuccessAction = ActionType<typeof signUpStep1SuccessAction>;
export type TSignUpStep2SuccessAction = ActionType<typeof signUpStep2SuccessAction>;
export type TSignUpAcceptInviteAction = ActionType<typeof signUpAcceptInviteAction>;

export type TSignUpActions =
  | TSignUpInitAction
  | TSignUpSuccessAction
  | TSignUpFailureAction
  | TSignUpStep1SuccessAction
  | TSignUpStep2SuccessAction
  | TSignUpAcceptInviteAction
  ;

export type TSignUpThunkActionType = ThunkAction<
  void,
  TRootState,
  null,
  TSignUpActions
>;

type TLoginAction = (payload: TSignUpRequest) => TSignUpThunkActionType;
type TSignUpAttemptAction = (payload: TSignUpStep1Request) => TSignUpThunkActionType;
type TSignUpCodeConfirmAction = (payload: TSignUpCodeConfirmRequest) => TSignUpThunkActionType;
type TSignUpPaymentAction = (payload: TSignUpStep2Request) => TSignUpThunkActionType;
type TAcceptInviteAction = (payload: TAcceptInviteRequest) => TSignUpThunkActionType;

export const signUp: TLoginAction = (payload: TSignUpRequest) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(signUpInitAction());
  return registrationSimple(payload)
    .then((res) => {
      pushStepToGoogle(`register-success-${payload.externalSourceName}`);
      if (res && res.accessToken && res.accessToken.length > 1) {
        // if (window.location.hostname.includes("mcl")) {
        //   const cookieValue=JSON.stringify(res);
        //   document.cookie = `livlet-token=${cookieValue};domain=.livlet.com;path=/`;
        //   window.location.replace(`${EnvConfig.APP_URL}/home`);
        //   return;
        // }
        saveAccessToken(res.accessToken);
        dispatch(getPropertiesResetAction());
        dispatch(getPropertyIdInitAction());
        dispatch(getPropertyPreferencesInitAction());
        dispatch(replace("/home"));
        return dispatch(
          logInSuccessAction({...decodeJwt(res.accessToken), ...res})
        );
      } else {
        dispatch(signUpSuccessAction())
      }
    })
    .catch((err) => {
      console.log("registration error", err)
      if (err.data === "registration-needed") {
        dispatch(replace(`/register?externalSourceName=${payload.externalSourceName}&externalId=${payload.externalId}&externalSourceToken=${payload.externalSourceToken}&name=${payload.name}&email=${payload.email}`))
      }
      if (err.response.data && err.response.data.internalCode === 4001)
        return dispatch(signUpFailureAction({ emailError: false, captchaError: true, unknownError: false }));
      if (err.response.data && err.response.data.internalCode === 4002)
        return dispatch(signUpFailureAction({ emailError: true, captchaError: false, unknownError: false}));
      return dispatch(signUpFailureAction({ emailError: false, captchaError: false, unknownError: true}));
  })
};

export const signUpAttempt: TSignUpAttemptAction = (payload: TSignUpStep1Request) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(signUpInitAction());
  return registrationAttempt(payload)
    .then(() => {pushStepToGoogle('register-code-start'); dispatch(signUpStep1SuccessAction());})
    .catch((err) => {
      pushStepToGoogle('register-email-error');
      if (err.response.data && err.response.data.internalCode === 4001)
        return dispatch(signUpFailureAction({ emailError: false, captchaError: true, unknownError: false }));
      if (err.response.data && err.response.data.internalCode === 4002)
        return dispatch(signUpFailureAction({ emailError: true, captchaError: false, unknownError: false}));
      return dispatch(signUpFailureAction({ emailError: false, captchaError: false, unknownError: true}));
    })
};

export const signUpCodeConfirmation: TSignUpCodeConfirmAction = (payload: TSignUpCodeConfirmRequest) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(signUpInitAction());
  return registrationCodeConfirmation(payload)
    .then((payload) => {
      if (payload && payload.accessToken && payload.accessToken.length > 1) {
        pushStepToGoogle('register-code-confirmed');
        if (window.location.hostname.includes("partner")) {
          const cookieValue=JSON.stringify(payload);
          document.cookie = `livlet-token=${cookieValue};domain=.livlet.com;path=/`;
          // @ts-ignore
          window.location=`${EnvConfig.API_URL}/home`;
          return;
        }
        saveAccessToken(payload.accessToken);
        dispatch(getPropertiesResetAction ());
        dispatch(getPropertyIdInitAction());
        dispatch(getPropertyPreferencesInitAction());
        dispatch(replace(`${EnvConfig.API_URL}/home`));
        dispatch(push(`${EnvConfig.API_URL}/home`));
        return dispatch(
          logInSuccessAction({...decodeJwt(payload.accessToken), ...payload})
        );
      } else {
        dispatch(signUpSuccessAction())
      }
    })
    .catch((err) => {
      pushStepToGoogle('register-code-error');
      if (err.response.data && err.response.data.internalCode === 4001)
        return dispatch(signUpFailureAction({ emailError: false, captchaError: true, unknownError: false }));
      if (err.response.data && err.response.data.internalCode === 4002)
        return dispatch(signUpFailureAction({ emailError: true, captchaError: false, unknownError: false}));
      return dispatch(signUpFailureAction({ emailError: false, captchaError: false, unknownError: true}));
    })
};

export const signUpPayment: TSignUpPaymentAction = (payload: TSignUpStep2Request) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(signUpInitAction());
  return registrationPayment(payload)
    .then((res) => {
      if (res.data && res.data.redirectUrl) {
        dispatch(signUpStep2SuccessAction(res.data.redirectUrl))
      } else if (res.data && res.data.couponRegistrationValidTo) {
        if (res.accessToken && res.accessToken.length > 1) {
          saveAccessToken(res.accessToken);
          dispatch(push("/home"));
          return dispatch(
            logInSuccessAction({...decodeJwt(res.accessToken), ...res})
          );
        } else {
          dispatch(signUpSuccessAction())
        }
      }
    })
    .catch((err) => {
      console.log("err="+JSON.stringify(err))
      return dispatch(signUpFailureAction({ emailError: false, captchaError: false, unknownError: true}));
    })
};


export const signUpAcceptInvite: TAcceptInviteAction = (payload: TAcceptInviteRequest) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(signUpInitAction());
  return acceptInvite(payload)
    .then((payload) => {
      if (payload && payload.accessToken && payload.accessToken.length > 1) {
        saveAccessToken(payload.accessToken);
        dispatch(getPropertiesResetAction ());
        dispatch(getPropertyIdInitAction());
        dispatch(getPropertyPreferencesInitAction());
        dispatch(replace("/home"));
        return dispatch(
          logInSuccessAction({...decodeJwt(payload.accessToken), ...payload})
        );
      } else {
        dispatch(signUpSuccessAction())
      }
    })
    .catch((err) => {
      if (err.response.data && err.response.data.internalCode === 4001)
        return dispatch(signUpFailureAction({ emailError: false, captchaError: true, unknownError: false }));
      if (err.response.data && err.response.data.internalCode === 4002)
        return dispatch(signUpFailureAction({ emailError: true, captchaError: false, unknownError: false}));
      return dispatch(signUpFailureAction({ emailError: false, captchaError: false, unknownError: true}));
    })
};
