import React from "react";
import styled from "styled-components";

import GuidanceListItem from "./GuidanceListItem";
import {useSelector} from "react-redux";
import {getImprovementsSelector} from "../../selectors";
import {TFetchingStatus} from "../../../helpers";

type Props = {
  categories: Array<any>;
  improvements: Array<any>;
  selectedCategories: Array<any>;
  selectedCategoryIds: Array<any>;
  search: string;
  isWishlist: boolean;
  selectImprovement: (arg1: number, arg2: string) => void;
  manageWishlistStatus: (arg1: any) => void;
  manageImprovementStatus: (arg: any) => void;
  manageApplicableStatus: (arg: any) => void;

  ecoPriority: number;
  effortPriority: number;
  savingsPriority: number;
  safetyPriority: number;
  costPriority: number;
  wellbeingPriority: number;
}

const GuidanceListComp = (props: Props) => {
  const {categories, improvements, selectedCategories, selectedCategoryIds, search, isWishlist,
    selectImprovement, manageWishlistStatus, manageImprovementStatus, manageApplicableStatus,
    effortPriority, ecoPriority, costPriority, savingsPriority} = props;

  const improvementsConfiguration = useSelector(getImprovementsSelector);

  const filteredImprovements = (status: string, categoryId?: number | null, notApplicable?: boolean, ) => improvements
    .filter(i => categoryId ? i.category === categoryId : true)
    .filter(i => selectedCategoryIds.length > 0 ? selectedCategoryIds.includes(i.category) : true)
    .filter(i => search ? i.name.toLowerCase().includes(search.toLowerCase()) : true)
    .filter(i => isWishlist ? i.wishListedOn : true)
    .filter(i => {
      if (status === "completed") {return (i.doneOn || i.doneByAnswerOn)}
      else if (status === "incomplete") {return (!i.doneOn && !i.doneByAnswerOn)}
      else {return true}
    })
    .filter(i => notApplicable ? i.isApplicable === false : (i.isApplicable === undefined || i.isApplicable === true))
    .filter(i => {
      let effortResult = true;
      if (effortPriority > 1)
        effortResult = i.guidanceCardEffort === effortPriority;
      else if (effortPriority > 0)
        effortResult = i.guidanceCardEffort <= 1;

      let costResult = true;
      if (costPriority > 1)
        costResult = i.guidanceCardCost === costPriority;
      else if (costPriority > 0)
        costResult = i.guidanceCardCost <= 1;

      let ecoResult = true;
      if (ecoPriority > 1)
        ecoResult = i.guidanceCardSustainability === ecoPriority;
      else if (ecoPriority > 0)
        ecoResult = i.guidanceCardSustainability <= 1;

      let savingsResult = true;
      if (savingsPriority > 1)
        savingsResult = i.guidanceCardSavings === savingsPriority;
      else if (savingsPriority > 0)
        savingsResult = i.guidanceCardSavings <= 1;

      return effortResult && costResult && ecoResult && savingsResult;
    })
    // .sort((i1, i2) => {
    //   if (effortPriority !== 1 || ecoPriority !== 1 || safetyPriority !== 1 ||
    //     wellbeingPriority !== 1 || costPriority !== 1 || savingsPriority !== 1) {
    //     let i1rating = i1.guidanceSortingCost * costPriority + i1.guidanceSortingHealthComfort * wellbeingPriority +
    //       i1.guidanceSortingEffort * effortPriority + i1.guidanceSortingSafety * safetyPriority +
    //       i1.guidanceCardSustainability * ecoPriority + i1.guidanceSortingSavings * savingsPriority
    //     let i2rating = i2.guidanceSortingCost * costPriority + i2.guidanceSortingHealthComfort * wellbeingPriority +
    //       i2.guidanceSortingEffort * effortPriority + i2.guidanceSortingSafety * safetyPriority +
    //       i2.guidanceCardSustainability * ecoPriority + i2.guidanceSortingSavings * savingsPriority
    //     return i2rating - i1rating
    //   } else return 0;
    // })

  const recommendedLength = filteredImprovements("incomplete", null, false).length
  const completedLength = filteredImprovements("completed", null, false).length
  const notApplicableLength = filteredImprovements("all", null, true).length
  const wishlistLength = filteredImprovements("all", null, false).length

  const emptySection = () => {
    if (improvementsConfiguration.fetchingStatus === TFetchingStatus.Success) {
      return <CategoryTitle>There are no improvements to display.</CategoryTitle>
    } else {
      return <CategoryTitle>Loading improvements...</CategoryTitle>
    }
  }

  const sectionTitle = (title: string) => <CategoryTitleWrapper>
    <CategoryTitle>{title}</CategoryTitle>
    <CategoryLine/>
  </CategoryTitleWrapper>

  const guidanceItem = (item: any) => <GuidanceListItem
    item={item}
    categories={categories}
    improvements={improvements}
    selectImprovement={selectImprovement}
    manageWishlistStatus={manageWishlistStatus}
    manageImprovementStatus={manageImprovementStatus}
    manageApplicableStatus={manageApplicableStatus}
  />

  const guidanceCategory = (category: any) => (
    <>
      {sectionTitle(category.name)}
      <GuidanceCardWrapper>
      {filteredImprovements("all", category.id, false).map((item) => guidanceItem(item))}
      </GuidanceCardWrapper>
    </>
  )

  const mainPage = () => (
    <ListCompWrapper className={"guidance"}>
      {!recommendedLength && !completedLength && !notApplicableLength && emptySection()}
      {recommendedLength > 0 && sectionTitle("All")}
      <GuidanceCardWrapper>
        {filteredImprovements("incomplete", null, false).map((item) => guidanceItem(item))}
      </GuidanceCardWrapper>
      {completedLength > 0 && sectionTitle("Completed")}
      <GuidanceCardWrapper>
        {filteredImprovements("completed", null, false).map((item) => guidanceItem(item))}
      </GuidanceCardWrapper>
      {notApplicableLength > 0 && sectionTitle("Not applicable")}
      <GuidanceCardWrapper>
        {filteredImprovements("all", null, true).map((item) => guidanceItem(item))}
      </GuidanceCardWrapper>
    </ListCompWrapper>
  )

  const wishlistPage = () => (
    <ListCompWrapper className={"guidance"}>
      {!wishlistLength && emptySection()}
      {wishlistLength > 0 && sectionTitle("Wish List")}
      <GuidanceCardWrapper>
        {filteredImprovements("all", null, false).map((item) => guidanceItem(item))}
      </GuidanceCardWrapper>
    </ListCompWrapper>
  )

  const categorizedImprovements = () => (
    <ListCompWrapper className={"guidance"}>
      {!filteredImprovements("all").length && emptySection()}
      {selectedCategories.map(category =>
        filteredImprovements("all", category.id).length > 0 && guidanceCategory(category))}
    </ListCompWrapper>
  )

  return isWishlist ? wishlistPage() : selectedCategories.length > 0 ? categorizedImprovements() : mainPage()
}

export const ListCompWrapper = styled.div`
  padding-right: 1.5rem;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  
  @media (max-width: 1024px) {
    overflow-y: auto;
    height: auto;
    padding-right: 0;
    
    &.guidance {
      overflow-y: scroll;
      height: 70vh;
      padding-right: 0.5rem;
      margin-left: 1rem;
      margin-right: 0.5rem;
    }
    
    &.guidance-item {
      margin: 0 1rem;
    }
  }
 
  &::-webkit-scrollbar {
    width: 10px;
  }
  
  &::-webkit-scrollbar-track {
    background: #F0F2F5;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #E5E5E5;
    border-radius: 7px;
    border: 3px solid #E5E5E5;
  }
`

const GuidanceCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  margin-bottom: 1.5rem;
  
  @media (max-width: 1024px) {
    row-gap: 0.75rem;
  }
`

export const CategoryTitleWrapper = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  margin-bottom: 1.5rem;
`

export const CategoryTitle = styled.div`
  flex-shrink: 0;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #000000;
  
  &.link-title {
    cursor: pointer;
  }
  
  &.link-title:hover {
    color: #67C18B;
  }
`

export const CategoryLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #DAE0E6;
`

export default GuidanceListComp