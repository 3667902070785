import React from "react";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import {
  ColumnWrapper,
  FieldWrapper,
  Label,
  PropertyData,
  PropertyDetailsSection,
  PropertyLocationData,
  PropertyLocationWrapper
} from "./PropertyDetailsComp";
import EPCDashboardWidget from "./EPCDashboardWidget";
import {ReactComponent as MyHomeIcon} from "../../../images/MyHomeIcon.svg";
import styled from "styled-components";
import {ReactComponent as ViewAllArrow} from "../../../images/dashboard-improvements/ViewAllArrow.svg";
import {ViewAllButton} from "../dashboardWidgets/ImprovementsWidget";
import {ButtonOutline, SectionLabel} from "../../screens/components";
import CrimeDataWidget from "./CrimeDataWidget";
import {isUKProperty} from "../../screens/helpers";

type Props = {
  property: any;
  togglePropertyDrawer: (arg: boolean) => void;
  setSelectedPill: (arg: string) => void;
};

const MyPropertyComp = (props: Props) => {
  const {property, togglePropertyDrawer, setSelectedPill} = props;

  const emptyProperty = () => (
    <EmptyPropertyWrapper>
      <div><MyHomeIcon /></div>
      <EmptyPropertyText>
        <span style={{color: "#002A43", fontWeight: 600}}>Enter home address</span>
        <span>We'll use this to	source your EPC data if available to start building your home systems' profile and
          display your energy performance certificate information. We can also advise the postcode price increase
          in last 12 months, your house purchased price,  and your address is also part of the inputs required for
          us to evaluate your home's current value.</span>
        <span> Finally our flood alerts system is connected to your location. We're working on other features to
          maximise this home data point to give our users important information</span>
      </EmptyPropertyText>
      <div style={{display: "flex", alignItems: "center", flexShrink: 0}}>
        <ViewAllButton style={{fontSize: "1rem"}} onClick={() => togglePropertyDrawer(true)}>
        Add address <ViewAllArrow/>
        </ViewAllButton>
      </div>
    </EmptyPropertyWrapper>
  )

  const existingProperty = () => (
    <>
      <EPCDashboardWidget setSelectedPill={setSelectedPill} property={property}/>
      <PropertyDetailsSection>
        <SectionLabel className={"padded"}>
          Property location
          <ButtonOutline onClick={() => togglePropertyDrawer(true)}>
            Edit
          </ButtonOutline>
        </SectionLabel>
        <PropertyLocationWrapper>
          <PropertyLocationData style={{ alignItems: "stretch" }}>
            <ColumnWrapper>
              <FieldWrapper>
                <Label>Address</Label>
                <PropertyData>
                  <Ellipsis style={{ display: "inline" }} length={35}>
                    {property.address}
                  </Ellipsis>
                  <Ellipsis style={{ display: "inline" }} length={35}>
                    {property.address2
                      ? ",\u00A0" + property.address2
                      : ""}
                  </Ellipsis>
                </PropertyData>
              </FieldWrapper>
              <FieldWrapper>
                <Label>Postcode</Label>
                <PropertyData>
                  <Ellipsis style={{ display: "inline" }} length={8}>
                    {property.zip}
                  </Ellipsis>
                </PropertyData>
              </FieldWrapper>
            </ColumnWrapper>
            <ColumnWrapper>
              <FieldWrapper>
                <Label>City</Label>
                <PropertyData>
                  <Ellipsis style={{ display: "inline" }} length={15}>
                    {property.city}
                  </Ellipsis>
                </PropertyData>
              </FieldWrapper>
              <FieldWrapper>
                <Label>
                  Province
                </Label>
                <PropertyData>
                  <Ellipsis style={{ display: "inline" }} length={15}>
                    {property.state}
                  </Ellipsis>
                </PropertyData>
              </FieldWrapper>
            </ColumnWrapper>
            <ColumnWrapper>
              <FieldWrapper>
                <Label>Country</Label>
                <PropertyData>
                  <Ellipsis style={{ display: "inline" }} length={15}>
                    {property.country}
                  </Ellipsis>
                </PropertyData>
              </FieldWrapper>
            </ColumnWrapper>
          </PropertyLocationData>
        </PropertyLocationWrapper>
      </PropertyDetailsSection>
      {isUKProperty(property.country, property.zip) && <CrimeDataWidget/>}
    </>
  )
  return property.address === "My home" ? emptyProperty() : existingProperty()
}

const EmptyPropertyWrapper = styled.div`
  display: flex;
  column-gap: 1.5rem;
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 10px;
  margin-top: 2rem;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 0.75rem;
  }
`

const EmptyPropertyText = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #666F7A;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  flex-shrink: 3;
`

export default MyPropertyComp