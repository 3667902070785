import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TAddAttachmentResponse = {
  name: string;
  expenseId: Guid;
  propertyId: Guid;
};

export type TAddAttachmentRequest = {
  propertyId: Guid;
  parentId: Guid | null;
  parentType: string | null;
  file: any;
};

export const addAttachment = (payload: TAddAttachmentRequest) => {
  const { propertyId, parentId, parentType } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/attachment`,
    (parentId && parentType) ? {
      parentId,
      parentType,
    } : {}
  );
  let bodyFormData = new FormData();

  payload.file && bodyFormData.append("file", payload.file);

  return apiPost<TAddAttachmentResponse, any>(url, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
