import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import { reset, TResetRequest } from "../../api-wrapper/auth/reset";

export const resetInitAction = createAction("users/RESET_INIT")();
export const resetSuccessAction = createAction("users/RESET_SUCCESS")();
export const resetFailureAction = createAction("users/RESET_FAILURE")();

export type TResetInitAction = ActionType<typeof resetInitAction>;
export type TResetSuccessAction = ActionType<typeof resetSuccessAction>;
export type TResetFailureAction = ActionType<typeof resetFailureAction>;

export type TResetActions =
    | TResetInitAction
    | TResetSuccessAction
    | TResetFailureAction

export type TResetThunkActionType = ThunkAction<
    void,
    TRootState,
    null,
    TResetActions
    >;

type TResetAction = (payload: TResetRequest) => TResetThunkActionType;

export const resetPassword: TResetAction = (payload: TResetRequest) => (
    dispatch: (action: Action | Promise<Action>) => any
    ) => {
        dispatch(resetInitAction());
        return reset(payload.email)
            .then(() => {
                return dispatch(resetSuccessAction());
            })
            .catch(() => {
                dispatch(resetFailureAction());
            });
};