import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetImprovementsResponse } from "../../api-wrapper/properties/getImprovements";
import {
  getImprovementsFailureAction,
  getImprovementsInitAction,
  getImprovementsSuccessAction,
  TGetImprovementsActions,
} from "../actions/improvements";

export type TImprovements = {
  fetchingStatus: TFetchingStatus;
  content: TGetImprovementsResponse;
};

export const initialState: TImprovements = {
  content: {
    categories: [],
    improvements: [],
    questions: [],
    incentives: [],
    propertyDetails: [],
    propertyHighlights: {
      estimatedMax: 0,
      estimatedMin: 0,
      estimatedValue: 0,
      valuationDate: "",
      currentEnergyEfficiency: 0,
      currentEnergyRating: "",
      potentialEnergyEfficiency: 0,
      potentialEnergyRating: "",
      valueSource: "",

      lastSoldPrice: 0,
      lastSoldPriceSource: "",
      lastSoldPriceDate: "",
      propertyValue: "",
      propertyValueType: "",
      growth: "",
    },
  },
  fetchingStatus: TFetchingStatus.Defined,
};

export const improvementsReducer = createReducer<TImprovements, TGetImprovementsActions>(
  initialState
)
  .handleAction(getImprovementsInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getImprovementsSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getImprovementsFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
