import { createUrl } from "../apiCall";
import EnvConfig from "../../config";
import _ from "lodash";
import axios from "axios";

export type TSignUpStep1Request = {
  email: string;
  password: string;
  marketingEmails: boolean;
  token?: string | null;
  externalSourceName?: string | null;
  externalId?: string | null;
  externalSourceToken?: string | null;
  partnerCode?: string | null;
  website?: string | null;
};

export const registrationAttempt = async (payload: TSignUpStep1Request) => {
  const { token } = payload;

  const url = createUrl(`${EnvConfig.API_URL}/api/user/registrationAttempt?token=${token}`);

  const uninterceptedAxios = axios.create()

  const auth: any = await uninterceptedAxios.post<{}, any>(url,
    _.omit(payload, ["token"]), {
      headers: {
        Authorization: `Bearer:${token}`
      }})

  return auth;
};
