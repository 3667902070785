import {apiPatch, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export const updateAttachment = (propertyId: Guid, attachmentId: Guid, name: string, description: string) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/attachment/${attachmentId}`
  );

  let bodyFormData = new FormData();

  bodyFormData.append("name", name);
  bodyFormData.append("description", description);

  return apiPatch<any, any>(url, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
