import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetTagsResponse } from "../../api-wrapper/tags/getTags";
import {
  getTagsFailureAction,
  getTagsInitAction,
  getTagsSuccessAction,
  TGetTagsActions,
} from "../actions/tags";
import {emptyGuid} from "../screens/helpers";

export type TTags = {
  fetchingStatus: TFetchingStatus;
  content: TGetTagsResponse;
};

const initialState: TTags = {
  content: [
    {
      tagId: emptyGuid,
      name: ""
    }
  ],
  fetchingStatus: TFetchingStatus.Defined,
};

export const tagsReducer = createReducer<TTags, TGetTagsActions>(
  initialState
)
  .handleAction(getTagsInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getTagsSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getTagsFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
