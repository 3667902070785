import {apiDelete, apiPatch, apiPost, apiPut, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export const addToWishlist = (propertyId: Guid, improvementId: string) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/improvements/${improvementId}/wishlist`
  );

  return apiPut(url);
};

export const removeFromWishlist = (propertyId: Guid, improvementId: string) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/improvements/${improvementId}/wishlist`
  );

  return apiDelete(url);
};


export const markImprovementAsDone = (propertyId: Guid, improvementId: string) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/improvements/${improvementId}/done`
  );

  return apiPatch(url);
};

export const markImprovementAsUndone = (propertyId: Guid, improvementId: string) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/improvements/${improvementId}/undone`
  );

  return apiPatch(url);
};

export const markImprovementAsNotApplicable = (propertyId: Guid, improvementId: string) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/improvements/${improvementId}/nonApplicable`
  );

  return apiPatch(url);
};

export const markImprovementAsApplicable = (propertyId: Guid, improvementId: string) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/improvements/${improvementId}/applicable`
  );

  return apiPatch(url);
};


export const linkImprovementWithWork = (propertyId: Guid, improvementId: string, workId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/improvements/${improvementId}/workLink/${workId}`
  );

  return apiPost(url);
};

export const skipRecommendedImprovementForever = (propertyId: Guid, improvementId: string) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/improvements/${improvementId}/skipForever`
  );

  return apiPatch(url);
};

export const skipRecommendedImprovementUntilDate = (propertyId: Guid, improvementId: string) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/improvements/${improvementId}/skipUntilDate`
  );

  return apiPatch(url);
};