import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {
  TGetTagsWithUsageCountResponse,
} from "../../api-wrapper/tags/getTagsWithUsageCount";
import {getTagsWithUsageCountByType} from "../../api-wrapper/tags/getTagsWithUsageCountByType";
import {Guid} from "guid-typescript";

export const getTagsWithUsageCountByTypeInitAction = createAction("tags/GET_TAGS_WITH_USAGE_COUNT_BY_TYPE_INIT")();
export const getTagsWithUsageCountByTypeSuccessAction = createAction(
  "tags/GET_TAGS_WITH_USAGE_BY_TYPE_COUNT_SUCCESS"
)<TGetTagsWithUsageCountResponse>();
export const getTagsWithUsageCountByTypeFailureAction = createAction("tags/GET_TAGS_WITH_USAGE_COUNT_BY_TYPE_FAILURE")();

export type TGetTagsWithUsageCountByTypeInitAction = ActionType<typeof getTagsWithUsageCountByTypeInitAction>;
export type TGetTagsWithUsageCountByTypeSuccessAction = ActionType<typeof getTagsWithUsageCountByTypeSuccessAction>;
export type TGetTagsWithUsageCountByTypeFailureAction = ActionType<typeof getTagsWithUsageCountByTypeFailureAction>;

export type TGetTagsWithUsageCountByTypeActions =
  | TGetTagsWithUsageCountByTypeInitAction
  | TGetTagsWithUsageCountByTypeSuccessAction
  | TGetTagsWithUsageCountByTypeFailureAction;

export type TGetTagsWithUsageCountByTypeThunkActionType = (propertyId: Guid, type: string) =>
  ThunkAction<void, TRootState, null, TGetTagsWithUsageCountByTypeActions>;

export const getAllTagsWithUsageCountByType: TGetTagsWithUsageCountByTypeThunkActionType = (propertyId: Guid, type: string) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getTagsWithUsageCountByTypeInitAction());
  return getTagsWithUsageCountByType(propertyId, type)
    .then((payload) => {
      return dispatch(getTagsWithUsageCountByTypeSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getTagsWithUsageCountByTypeFailureAction()));
};
