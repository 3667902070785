import {Checkbox, DatePicker, Divider, Drawer, Form, Input, InputNumber, Modal, notification, Select} from "antd";
import {useIntl} from "react-intl";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import _ from "lodash";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";

import {ReactComponent as ExpensesIcon} from "../../../images/ExpensesPink.svg";
import {ReactComponent as InvoiceSpinnerIcon} from "../../../images/InvoiceSpinner.svg";
import {ReactComponent as AttachmentIcon} from "../../../images/AttachmentsPink.svg";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import {ReactComponent as NotesDrawerIcon} from "../../../images/NotesDrawerPink.svg";
import messages from "../../screens/messages";
import {InputsWrapper, InputWrapper, Label, LabelWrapper,} from "../../screens/RegistrationPage";
import {removeExpense} from "../../../api-wrapper/expenses/removeExpense";
import {getAllExpenses, getAllExpenseTypes} from "../../actions/expenses";
import {addExpense, TAddExpenseResponse,} from "../../../api-wrapper/expenses/addExpense";
import {editExpense} from "../../../api-wrapper/expenses/editExpense";
import {
  getContactsSelector,
  getExpenseTypesSelector,
  getMaintenancesSelector, getPropertiesSelector,
  getPropertyIdSelector,
  getPropertyPreferencesSelector,
  getSubscriptionUsersSelector,
  getUserProfileSelector,
} from "../../selectors";
import UploadFile from "../attachments/UploadFile";
import {addWork, TAddWorkResponse} from "../../../api-wrapper/works/addWork";
import {getExpenseById} from "../../../api-wrapper/expenses/getExpenseById";
import WorkDrawer, {
  AttachButtonStyled,
  CloseButton,
  SaveButton,
  SaveButtonWrapper,
  TextAreaStyled,
} from "../works/WorkDrawer";
import {TAddInventoryResponse} from "../../../api-wrapper/inventories/addInventory";
import InventoryDrawer from "../inventories/InventoryDrawer";
import ContactDrawer from "../contacts/ContactDrawer";
import {addContact, TAddContactResponse,} from "../../../api-wrapper/contacts/addContact";
import {getAllContacts} from "../../actions/contacts";
import {AutoCompleteStyled} from "../styled";
import {Guid} from "guid-typescript";
import {emptyGuid} from "../../screens/helpers";
import TreeSelect, {TreeNode} from "antd/es/tree-select";
import {TUpcomingTask} from "../../../api-wrapper/upcomingTasks/getUpcomingTasks";
import AttachmentsOnDrawer from "../attachments/AttachmentsOnDrawer";
import {addRelation} from "../../../api-wrapper/relation/addRelation";
import {PlusOutlined} from "@ant-design/icons";
import {addExpenseType} from "../../../api-wrapper/expenses/addExpenseType";
import {getAllConfirmedExpenses} from "../../actions/confirmedExpenses";
import {saveInvoiceParsingResult} from "../../../api-wrapper/expenses/saveInvoiceParsingResult";
import {linkToRecord} from "../spaces/AddNewRecordDrawer";
import moment from "moment";
import {getAllWorks} from "../../actions/works";
import UpdateTaskDrawer, {getTaskType} from "../planner/UpdateTaskDrawer";
import {TFetchingStatus} from "../../../helpers";
import {assignExpenseProperty} from "../../../api-wrapper/expenses/assignExpenseProperty";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  expense: { data: TAddExpenseResponse } | null;
  setEditingExpenseData: (
    arg01: TAddExpenseResponse | { accessToken: any } | null
  ) => void;
  width?: number | null;
  drawerTitle?: string | null;
  work?: TAddWorkResponse | null;
  inventory?: TAddInventoryResponse | null;
  contact?: TAddContactResponse | null;
  refreshParent?: () => void | null;
  icon?: ReactNode | null;
  highlightNote?: Guid | null;
  highlightAttachment?: Guid | null;
  reminderToRecord?: TUpcomingTask | null;
  invoiceData?: any;
  deleteIfNotSaved?: boolean;
  parentRecordId?: Guid | null;
  parentRecordType?: string;
  parentRecordName?: string;
  refreshExpensesWithoutProperty?: any;
  preventWorkCreation?: boolean;
};

type Payer = {
  userId?: number;
  contactId?: Guid;
  value: string;
  label: string,
}

const attachmentsInitial = [
  {
    propertyId: emptyGuid,
    parentId: emptyGuid,
    parentType: "",
    attachmentId: emptyGuid,
    storageKey: "",
    name: "",
    size: 0,
    path: "",
  },
];

const notesInitial = [
  {
    recordId: emptyGuid,
    title: "",
    message: "",
    createdDate: "",
    attachment: {
      attachmentId: emptyGuid,
      propertyId: emptyGuid,
      parentId: emptyGuid,
      parentType: "",
      name: "",
      size: 0,
    },
  },
];

const ExpenseDrawer = (props: Props) => {
  const {
    isOpen,
    toggleDrawerOpen,
    expense,
    setEditingExpenseData,
    width,
    drawerTitle,
    work,
    inventory,
    refreshParent,
    icon,
    contact,
    highlightAttachment,
    invoiceData,
    deleteIfNotSaved,
    parentRecordId,
    parentRecordType,
    parentRecordName,
    refreshExpensesWithoutProperty,
    preventWorkCreation
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [expenseName, setExpenseName] = useState("");
  const [isTaxDeductible, setIsTaxDeductible] = useState(false);
  const { formatMessage: f } = useIntl();
  const propertyId = useSelector(getPropertyIdSelector).value;
  // const works = useSelector(getWorksSelector);
  // const inventories = useSelector(getInventoriesSelector);
  const contacts = useSelector(getContactsSelector);
  const expenseTypes = useSelector(getExpenseTypesSelector);
  const maintenances = useSelector(getMaintenancesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);
  const properties = useSelector(getPropertiesSelector);

  const titleInput = useRef(null);
  const [titleError, setTitleError] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [typeOfExpenseTmp, setTypeOfExpense] = useState<any>(null);
  const [contactName, setContactName] = useState<string | null>(null);
  const [expenseDetails, setExpenseDetails] = useState({
    expenseId: emptyGuid,
    notes: notesInitial,
    attachments: attachmentsInitial,
  });

  const showRelatedContact =
    !contact || (expense && !Object.is(expense.data.expenseId, emptyGuid));
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);

  const [leaves, setLeaves] = useState<any>([]);
  const [roots, setRoots] = useState<any>([]);

  const [showFileUploadList, setShowFileUploadList] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Set<Guid>>(new Set());
  const [savePayeeAsContact, setSavePayeeAsContact] = useState(false);
  const [savePayerAsContact, setSavePayerAsContact] = useState(false);
  const [showInvoiceOverlay, toggleInvoiceOverlay] = useState(false)
  const [invoiceFileName, setInvoiceFileName] = useState("")
  const selectFilesRef = useRef(null);
  const fileListRef = useRef(null);

  const [newTypeName, setNewTypeName] = useState("");
  const [newTypeParentCategory, setNewTypeParentCategory] = useState<any>(null);
  const [isExistingPayee, setIsExistingPayee] = useState(false);
  const [isExistingPayer, setIsExistingPayer] = useState(false);
  const [currentUserTenantId, setCurrentUserTenantId] = useState(0);

  const subscriptionUsers = useSelector(getSubscriptionUsersSelector)
  const userProfile = useSelector(getUserProfileSelector);

  useEffect(() => {
    const resArray = Object.entries(expenseTypes.content)
    let roots = resArray.filter((item: any) => !item[1].hasOwnProperty('parentId'))
    let maintRoot = roots.filter(item => item[1].name.toLowerCase().includes("maintenance"))
    let otherRoots = roots.filter(item => !item[1].name.toLowerCase().includes("maintenance"))
    setRoots([...maintRoot, ...otherRoots])

    let leaves = resArray.filter((item: any) => item[1].hasOwnProperty('parentId'));
    const others = leaves.filter((item: any) => item[1].name === "Other");
    leaves = leaves.filter((item: any) => item[1].name !== "Other");
    let orderedLeaves = leaves.sort((l1, l2) => {
      return l1[1].name.localeCompare(l2[1].name)
    })
    Array.prototype.push.apply(orderedLeaves, others);
    setLeaves(leaves);
  }, [propertyId, expenseTypes])

  useEffect(() => {
    setCurrentUserTenantId(subscriptionUsers.content.filter(item => !item.acceptedInvite)
      .filter(item => item.email === userProfile.content.email)[0]?.userId)
  }, [subscriptionUsers, userProfile])

  function showNewTypeError(errorText: string) {
    Modal.error({
      title: "Cannot add type",
      content: errorText,
      okText: "OK",
      className: "form-confirm-close-modal"
    });
  }

  function addNewType (typeName: string, parentCategory: any) {
    if (typeName && parentCategory) {
      if (leaves.filter((leaf: any) =>
        leaf[1].name.toUpperCase() === typeName.toUpperCase() && leaf[1].parentId === parentCategory).length) {
        showNewTypeError(`Type ${typeName} already exists in the provided category. Please pick another name.`)
      } else {
        addExpenseType({
          propertyId,
          name: typeName,
          parentId: parentCategory
        }).then((res: any) => {
          setTypeOfExpense(res.data.id)
          form.setFieldsValue({ typeOfExpense: res.data.id });
          dispatch(getAllExpenseTypes(propertyId))
        })
      }
    } else showNewTypeError("Please make sure you've provided both a name and a parent category for your new Expense type.")
  }

  // const [editExpenseData, setEditExpenseData] = useState<{
  //   recordId: Guid;
  //   title: string;
  //   message: string;
  //   createdDate: string;
  //   attachment: {
  //     attachmentId: Guid;
  //     propertyId: Guid;
  //     parentId: Guid;
  //     parentType: string;
  //     name: string;
  //     size: number;
  //   };
  //   tags: any;
  // } | null>(null);

  const [workDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editWorkData, setEditWorkData] = useState<any>(null);

  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editContactData, setEditContactData] = useState<any>({
    data: {
      name: "",
      contactId: emptyGuid,
      propertyId: emptyGuid,
      contactType: "",
      company: "",
      email: "",
      phone: "",
      website: "",
      notes: "",
      tags: []
    },
  });

  const [inventoryDrawerOpen, toggleInventoryDrawer] = useState(false);
  const [editInventoryData, setEditInventoryData] = useState({
    data: {
      propertyId: emptyGuid,
      brand: "",
      model: "",
      installedOrPurchaseDate: "",
      warrantyExpiration: "",
      inventoryType: "",
      purchaseCost: 0,
      insuredItems: false,
      serialNumber: "",
      inventorySubtype: "",
      applianceLifetimeYears: null,
      applianceLifetimeYearsLeft: null,
      name: "",
      id: emptyGuid,
      notes: "",
      tags: [],
      isConfirmed: false
    },
  });

  const [isTaskDrawerOpen, toggleTaskDrawer] = useState(false);
  const [editingTaskData, setEditingTaskData] = useState<any>(null);
  const [taskType, setTaskType] = useState("");

  useEffect(() => {
    setTaskType(getTaskType(editingTaskData))
  }, [editingTaskData])

  const [contactList, setContactList] = useState<Array<{ value: string; label: string }>>([]);
  const [contactsAndUsersList, setContactsAndUsersList] = useState<Array<Payer>>([]);
  const [paidByList, setPaidByList] = useState<Array<Payer>>([]);

  const [existingPayee, setExistingPayee] = useState<any>(null);
  const [payeeName, setPayeeName] = useState("");
  const [existingPayer, setExistingPayer] = useState<any>(null);
  const [payerName, setPayerName] = useState("");

  const expenseTypeCanBeRecordedAsWork = (typeOfExpense: string) => {
    const matchedType = leaves.filter((expenseType: any) => expenseType[0] === typeOfExpense);
    if (matchedType && matchedType[0][1] && matchedType[0][1].name && (
      matchedType[0][1].name === "Agent Fees" ||
      matchedType[0][1].name === "Consultant Fees" ||
      matchedType[0][1].name === "Income Tax" ||
      matchedType[0][1].name === "Inventory" ||
      matchedType[0][1].name === "Labour" ||
      matchedType[0][1].name === "Materials" ||
      matchedType[0][1].name === "Property Survey" ||
      matchedType[0][1].name === "Valuation"
    )) {
      return matchedType[0][1].name;
    } else {
      return null;
    }
  }

  const recordAsWork = (expenseId: Guid, costName: string) => {
    Modal.confirm({
      title: "Creating a project",
      content:
        "Do you want to record this as a completed task?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        addWork({
          propertyId,
          name: costName,
          type: "Improvement & Renovation",
          completedDate: moment().format("YYYY-MM-DD"),
          dueDate: null,
          recurring: null,
          isComplete: true,
        }).then((res) => {
          res && res.data && addRelation({
            propertyId,
            parentId: res.data.workId,
            parentType: "work",
            childId: expenseId,
            childType: "expense"}).then(() => {
              dispatch(getAllWorks(propertyId));
              setEditWorkData(res);
              toggleWorkDrawer(true);
          });
        });
      }
    });
  }

  const expenseTypeCanBeUpkeep = (typeOfExpense: string) => {
    const matchedType = leaves.filter((expenseType: any) => expenseType[0] === typeOfExpense);
    if (!matchedType || !matchedType[0][1] || !matchedType[0][1].name)
      return null;
    const matchedMaintenance = maintenances.content.filter(maintenance => maintenance.title.toLowerCase() === matchedType[0][1].name.toLowerCase());
    if (matchedMaintenance.length > 0) {
      if (matchedMaintenance[0].subscribed) {
        return null;
      } else {
        return matchedType[0][1].name;
      }
    } else {
      return null;
    }
  }

  const openAsUpkeep = (paymentDate: moment.Moment, totalCost: number, typeOfExpense: string, contactId: any) => {
    Modal.confirm({
      title: "Recurring expense",
      content:
        "Do you want to create a "+typeOfExpense+" recurring expense?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        let matchedMaintenance = {...maintenances.content.filter(maintenance => maintenance.title.toLowerCase() === typeOfExpense.toLowerCase())[0]};
        while (paymentDate.isBefore(moment())) {
          paymentDate = paymentDate.add(1, "months");
        }
        matchedMaintenance.startDate = paymentDate.format("YYYY-MM-DD");
        matchedMaintenance.actualEstimatedCost = totalCost;
        matchedMaintenance.subscribed = true;
        matchedMaintenance.payee = contactId;

        setEditingTaskData(matchedMaintenance);
        toggleTaskDrawer(true);
      }
    });
  }

  const linkToParent = async (expenseId: Guid) => {
    if (work) {
      return await addRelation({propertyId, parentId: work.workId,
        childId: expenseId, parentType: "work", childType: "expense"})
    }
    if (inventory) {
      return await addRelation({propertyId, parentId: inventory.id, childId: expenseId,
        parentType: "inventory", childType: "expense"})
    }
    if (contact) {
      return await addRelation({propertyId, parentId: contact.contactId,
        childId: expenseId, parentType: "contact", childType: "expense"})
    }
  }

  const performCleanup = async (res: any, clearDrawer: boolean) => {
    if (work || inventory || contact) await linkToParent(res.expenseId);
    if (refreshParent) refreshParent();
    if (parentRecordId && parentRecordType) linkToRecord(propertyId, parentRecordId, parentRecordType, res.expenseId, "expense", dispatch)
    if (invoiceData?.isComplete) {
      saveInvoiceParsingResult({
        invoiceDigitizerResponseDto: invoiceData.invoice,
        // @ts-ignore
        expenseDto: res
      }).then()
    }

    form.resetFields();
    setSaveDisabled(false);
    setSavePayeeAsContact(false);
    setSavePayerAsContact(false);
    setFormFieldsChanged(false);

    if (clearDrawer) {
      setEditingExpenseData(null);
      toggleDrawerOpen(false);
    }

    dispatch(getAllExpenses(propertyId));
    dispatch(getAllConfirmedExpenses(propertyId));
    dispatch(getAllContacts(propertyId));
    dispatch(getAllConfirmedExpenses(propertyId));
  }

  const handleAddContact = async (name: string, type: string) => {
    let newContact = await addContact({
      propertyId,
      request: {
        contactType: type,
        name: name
      },
      contactImage: null
    })
    return newContact.data;
  }

  const getPayee = async () => {
    if (!payeeName) return null;
    if (payeeName && !existingPayee || payeeName && existingPayee && existingPayee.value !== payeeName)
      return await handleAddContact(payeeName, savePayeeAsContact ? "" : "Payee");
    // @ts-ignore
    if (payeeName && existingPayee && existingPayee.value === payeeName) return contacts.content.filter(item => item.name === payeeName)[0];
  }

  const getPayer = async () => {
    if (!payerName) return null;
    if (payerName && !existingPayer || payerName && existingPayer && existingPayer.value !== payerName)
      return await handleAddContact(payerName, savePayerAsContact ? "" : "Payer");
    // @ts-ignore
    if (payerName && existingPayer && existingPayer.value === payerName) {
      let payerObject = paidByList.filter(item => item.value === payerName)[0]
      if (payerObject.hasOwnProperty("contactId")) return contacts.content.filter(item => item.contactId === payerObject.contactId)[0];
      else return subscriptionUsers.content.filter(item => item.userId === payerObject.userId)[0];
    }
  }

  const getPayerId = (payerObject: any, payerType: string) => {
    if (payerType === "user") {
      return payerObject && payerObject.hasOwnProperty("contactId") ? null :
        (payerObject && payerObject.hasOwnProperty("userId") ? payerObject.userId : currentUserTenantId);
    }
    if (payerType === "contact") {
      return payerObject && payerObject.hasOwnProperty("contactId") ? payerObject.contactId : null;
    }
  }

  const handleSaveAsContact = async (newContact: any) => {
    toggleContactDrawer(true);
    setEditContactData({data: newContact});
  }

  const displayAssignedNotification = (assignedToId: any) => {
    notification.success({
      message: "Assigned successfully!",
      description: <>Your cost has been successfully assigned to {properties.filter(p => p.propertyId === assignedToId)[0].address}.
        <br/>If you assigned your Cost to a Property that isn't selected right now, kindly switch to it to view the cost.</>,
      placement: "topRight",
    });
    refreshExpensesWithoutProperty && refreshExpensesWithoutProperty();
  }

  const handleManageExpense = async (e: {
    expensePropertyId: Guid;
    typeOfExpense: string;
    totalCost: number;
    paymentDate: moment.Moment;
    notes: string;
  }) => {
    if (_.isEmpty(expenseName)) {
      setTitleError(true);
      return;
    }
    let { expensePropertyId, paymentDate, totalCost, notes, typeOfExpense } = e;
    if (_.isEmpty(typeOfExpense)) typeOfExpense = "other"

    let payeeObject = await getPayee();
    let payerObject = await getPayer();
    // @ts-ignore
    let payee = payeeObject ? payeeObject.contactId : null;

    setSaveDisabled(true);
    setFormFieldsChanged(false);

    if (expense && expense.data.expenseId && expensePropertyId) {
      await assignExpenseProperty(expensePropertyId, expense.data.expenseId);
    }

    if (expense && expense.data.expenseId) {
      editExpense({
        propertyId: expensePropertyId ? expensePropertyId : expense.data.propertyId,
        expenseId: expense.data.expenseId,
        name: expenseName,
        payee: payee,
        userPaidBy: getPayerId(payerObject, "user"),
        contactPaidBy: getPayerId(payerObject, "contact"),
        type: typeOfExpense,
        totalCost,
        isTaxDeductible,
        notes,
        paymentDate: _.isEmpty(paymentDate) ? null : paymentDate.format("YYYY-MM-DD"),
        tags: [],
        isConfirmed: true
      }).then((res) => {
        const possibleUpkeep = expenseTypeCanBeUpkeep(typeOfExpense);
        if (possibleUpkeep && expense.data.name === null) {
          openAsUpkeep(paymentDate, totalCost, possibleUpkeep, payee);
        } else if (expenseTypeCanBeRecordedAsWork(typeOfExpense) && expense.data.name === null && !preventWorkCreation) {
          recordAsWork(expense.data.expenseId, expenseName)
        }
        if (savePayeeAsContact && savePayerAsContact) {
          notification.success({
            message: "Added successfully!",
            description: "Payer and Paid have been added to your Contacts. You can view and edit them on the Contacts page.",
            placement: "topRight",
          });
        }
        if (savePayeeAsContact && !savePayerAsContact) {
          handleSaveAsContact(payeeObject)
        }
        if (savePayerAsContact && !savePayeeAsContact) {
          handleSaveAsContact(payerObject)
        }
        if (expensePropertyId) displayAssignedNotification(expensePropertyId);
        performCleanup(res, true)
      });
    } else {
      addExpense({
        propertyId,
        name: expenseName,
        payee: payee,
        userPaidBy: getPayerId(payerObject, "user"),
        contactPaidBy: getPayerId(payerObject, "contact"),
        type: typeOfExpense,
        totalCost,
        isTaxDeductible,
        paymentDate: _.isEmpty(paymentDate) ? null : paymentDate.format("YYYY-MM-DD"),
        isConfirmed: true,
      }).then((res) => {
        setEditingExpenseData(res);
        if (refreshParent) {
          const possibleUpkeep = expenseTypeCanBeUpkeep(typeOfExpense);
          if (possibleUpkeep)
            openAsUpkeep(paymentDate, totalCost, possibleUpkeep, payee);
          if (expenseTypeCanBeRecordedAsWork(typeOfExpense) && !preventWorkCreation)
            res && res.data && recordAsWork(res.data.expenseId, expenseName)
        }
        // @ts-ignore
        performCleanup(res.data, _.isEmpty(refreshParent))
      })
    }
  };

  const initialValues = () => {
    if (expense) {
      const {
        type,
        payee,
        paymentDate,
        totalCost,
        parentId,
        parentType,
        isTaxDeductible,
        notes,
      } = expense.data;

      const payeeContact = contacts.content.filter(
        (contact) => contact.contactId === payee
      );

      return {
        typeOfExpense: type,
        payee: !_.isNil(payee) && !Object.is(payee, emptyGuid) && !_.isEmpty(payeeContact) ? payeeContact[0].name : contactName,
        totalCost,
        isTaxDeductible,
        parentId,
        parentType,
        notes,
        paymentDate: paymentDate === null ? "" : moment(paymentDate),
        expensePropertyId: propertyId
      };
    } else {
      return {
        expensePropertyId: propertyId,
      };
    }
  };

  useEffect(() => {
    if (showFileUploadList && selectFilesRef && selectFilesRef.current) {
      // @ts-ignore
      selectFilesRef.current.scrollIntoView();
    } else if (!showFileUploadList && fileListRef && fileListRef.current) {
      // @ts-ignore
      fileListRef.current.scrollIntoView();
    }
  }, [showFileUploadList])

  useEffect(() => {
    if (expense) {
      setExpenseName(expense.data.name);
      setTypeOfExpense(expense.data.type);
      setIsTaxDeductible(expense.data.isTaxDeductible);
      form.resetFields();
    } else {
      form.resetFields();
      setExpenseName("");
      setIsTaxDeductible(false);
      setTypeOfExpense(null);
      setNewTypeName("");
      setNewTypeParentCategory(null);
    }
    setTitleError(false);
    setFormFieldsChanged(false);
  }, [expense, contact]);

  useEffect(() => {
    if (expense) {
      if (expense.data.userPaidBy && contactsAndUsersList.length > 0) {
        let payer = contactsAndUsersList.filter(item => item.userId === expense.data.userPaidBy)[0];
        payer && form.setFieldsValue({payer: payer.value});
        payer && setPayerName(payer.value);
        setIsExistingPayer(false);
      }
      if (expense.data.contactPaidBy && contactsAndUsersList.length > 0) {
        let payer = contactsAndUsersList.filter(item => item.contactId === expense.data.contactPaidBy)[0]
        payer && form.setFieldsValue({payer: payer.value});
        setIsExistingPayer(contacts.content.filter((contact: any) =>
          contact.contactId === expense.data.contactPaidBy)[0]?.contactType === ("Payer"));
      }
      if (!expense.data.userPaidBy && !expense.data.contactPaidBy && contactsAndUsersList.length > 0) {
        let payer;
        if (currentUserTenantId) {
          payer = contactsAndUsersList.filter(item => item.userId === currentUserTenantId)[0];
        } else {
          payer = contactsAndUsersList[0];
        }
        payer && form.setFieldsValue({payer: payer.value});
        setIsExistingPayer(false);
      }
      setIsExistingPayee(contacts.content.filter((contact: any) =>
        contact.contactId === expense.data.payee)[0]?.contactType === ("Payee"));
      setPayeeName(contacts.content.filter((contact: any) =>
        contact.contactId === expense.data.payee)[0]?.name ?? "")
    } else {
      setIsExistingPayer(false);
      setIsExistingPayee(false);
    }
    if (contact) {
      form.setFieldsValue({ payee: contact.name });
    }

  }, [expense, contact, contactsAndUsersList]);

  useEffect(() => {
    if (invoiceData) {
      window.scrollTo(0, 0);
      toggleInvoiceOverlay(invoiceData.isProcessing)
      setInvoiceFileName(invoiceData.fileName)
    }
    if (invoiceData && invoiceData.isComplete && !invoiceData.error) {
      notification.success({
        message: "Invoice parsed successfully!",
        placement: "topRight",
      });
      form.setFieldsValue({ payee: invoiceData.invoice.payee });
      setPayeeName(invoiceData.invoice.payee);
      parentRecordName && setExpenseName(`[${parentRecordName}] Purchase`)
      invoiceData.invoice.dateOfExpense && form.setFieldsValue({ paymentDate: moment(invoiceData.invoice.dateOfExpense) });
      invoiceData.invoice.totalCost >= 0 && form.setFieldsValue({ totalCost: invoiceData.invoice.totalCost });
    }
    if (invoiceData && invoiceData.isComplete && invoiceData.error) {
      notification.error({
        message: "Invoice parsing error",
        description:
          "We were unable to obtain the data from the invoice you've provided.",
        placement: "topRight",
      });
    }
  }, [invoiceData])

  useEffect(() => {
    let unsortedContactList =
      contacts.content.filter(contact => contact.name && contact.contactType !== "Payer").map((contact) => {
        return {
          id: contact.contactId,
          value: contact.name,
          label: contact.name +
            (_.isEmpty(contact.company) ? "" : (contact.company === contact.name ? "" : " - " + contact.company)) +
            (_.isEmpty(contact.contactType) ? "" : " (" + contact.contactType+")"),
        };
      })

    setContactList(
      unsortedContactList.sort((a: any, b: any) => {
        if(a.label && b.label && a.label.toLowerCase() < b.label.toLowerCase()) { return -1; }
        if(a.label && b.label && a.label.toLowerCase() > b.label.toLowerCase()) { return 1; }
        return 0;
      })
    )
  }, [contacts.content]);

  useEffect(() => {
    if (contacts.fetchingStatus === TFetchingStatus.Success && subscriptionUsers.fetchingStatus === TFetchingStatus.Success) {
      let unsortedContactList = contacts.content.filter(contact => contact.name && contact.contactType !== "Payee")
        .map((contact) => {
          return {
            contactId: contact.contactId,
            value: contact.name,
            label: contact.name +
              (_.isEmpty(contact.company) ? "" : (contact.company === contact.name ? "" : " - " + contact.company)) +
              (_.isEmpty(contact.contactType) ? "" : " (" + contact.contactType+")"),
          };
        })

      unsortedContactList.sort((a: any, b: any) => {
        if(a.label && b.label && a.label.toLowerCase() < b.label.toLowerCase()) { return -1; }
        if(a.label && b.label && a.label.toLowerCase() > b.label.toLowerCase()) { return 1; }
        return 0;
      })

      let unsortedUsersList = subscriptionUsers.content.filter(user => user.userId !== 0).map((user) => {
        return {
          userId: user.userId,
          value: user.name ?? "No name provided" + " (" + user.email + ")",
          label: user.name ?? "No name provided" + " (" + user.email + ")",
        };
      })

      unsortedUsersList.sort((a: any, b: any) => {
        if(a.label && b.label && a.label.toLowerCase() < b.label.toLowerCase()) { return -1; }
        if(a.label && b.label && a.label.toLowerCase() > b.label.toLowerCase()) { return 1; }
        return 0;
      })

      setContactsAndUsersList([...unsortedUsersList, ...unsortedContactList]);
      setPaidByList([...unsortedUsersList, ...unsortedContactList]);
    }
  }, [contacts, subscriptionUsers])

  const onPayeeAutocompleteSearch = (searchText: string) => {
    setContactList(
      !searchText
        ? contacts.content.filter(contact => contact.name).map((contact) => {
          return {
            id: contact.contactId,
            value: contact.name,
            label: contact.name + (_.isEmpty(contact.contactType) ? "" : " (" + contact.contactType+")"),
          };
        })
        : contacts.content
          .filter((contact) =>
            contact.name && contact.name.toLowerCase().includes(searchText.toLowerCase())
          )
          .map((contact) => {
            return {
              id: contact.contactId,
              value: contact.name,
              label: contact.name + (_.isEmpty(contact.contactType) ? "" : " (" + contact.contactType+")"),
            };
          })
    );
  };

  const onPayerAutocompleteSearch = (searchText: string) => {
    if (searchText) {
      setPaidByList(contactsAndUsersList.filter(contact => contact.value.toLowerCase().includes(searchText.toLowerCase())));
    } else {
      setPaidByList([...contactsAndUsersList]);
    }
  };

  useEffect(() => {
    expense && expense.data.expenseId &&
    !Object.is(expense.data.expenseId, emptyGuid)  &&
      getExpenseById(propertyId, expense.data.expenseId).then((res) =>
        res && setExpenseDetails(res)
      );
  }, [expense]);

  const refreshExpense = (recordId?: Guid | null) => {
    if (refreshParent) refreshParent();
    // expense && expense.data.expenseId &&
    //   !Object.is(expense.data.expenseId, emptyGuid) &&
    //   getExpenseById(propertyId, expense.data.expenseId).then((res) => {
    //     res && setExpenseDetails(res);
    //     recordId &&
    //       setEditExpenseData(
    //         // @ts-ignore
    //         res.notes.filter((note) => note.recordId === recordId)[0]
    //       );
    //   });
  };

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        toggleDrawerOpen(false);
        setSavePayeeAsContact(false);
        setSavePayerAsContact(false);
        setEditingExpenseData(null);
        form.resetFields();
        if (expense && _.isEmpty(expense.data.name)) {
          expense && removeExpense(expense.data.propertyId, expense.data.expenseId)
            .then(() => {
              dispatch(getAllExpenses(propertyId));
              dispatch(getAllConfirmedExpenses(propertyId));
            })
        } else if (deleteIfNotSaved) {
          expense && removeExpense(expense.data.propertyId, expense.data.expenseId)
            .then(() => {
              dispatch(getAllExpenses(propertyId));
              dispatch(getAllConfirmedExpenses(propertyId));
            })
        } else {
          dispatch(getAllExpenses(propertyId));
          dispatch(getAllConfirmedExpenses(propertyId));
        }
      },
    });
  }

  useEffect(() => {
    if (isOpen) {
      // @ts-ignore
      titleInput.current.focus();
      // @ts-ignore
      titleInput.current.setSelectionRange(0, 0);
    }
  }, [isOpen, titleInput.current])

  const addNewExpenseTypeDesktop = () => {
    return <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: "center", padding: 8 }}>
      <Input
        style={{
          flex: 'auto',
          marginRight: "0.75rem",
        }}
        placeholder={"Enter new type name"}
        onChange={(e) => setNewTypeName(e.target.value)} />
      <Select
        getPopupContainer={trigger => trigger.parentElement}
        style={{width: "300px"}}
        placeholder={"Select parent category"}
        onChange={(value) => setNewTypeParentCategory(value)}
      >
        {roots.map((root: any) =>
          <Select.Option key={root[1].id} value={root[1].id}>{root[1].name}</Select.Option>)}
      </Select>
      <a
        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
        onClick={() => addNewType(newTypeName, newTypeParentCategory)}
      >
        <PlusOutlined /> Add item
      </a>
    </div>
  }

  const addNewExpenseTypeMobile = () => {
    return <div style={{
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: "center",
      justifyContent: "center",
      padding: 8 }}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "70%"
      }}>
      <Input
        style={{
          marginRight: "0.75rem",
          marginBottom: "0.5rem"
        }}
        placeholder={"Enter new type name"}
        onChange={(e) => setNewTypeName(e.target.value)} />
      <Select
        getPopupContainer={trigger => trigger.parentElement}
        placeholder={"Select parent category"}
        onChange={(value) => setNewTypeParentCategory(value)}
      >
        {roots.map((root: any) =>
          <Select.Option key={root[1].id} value={root[1].id}>{root[1].name}</Select.Option>)}
      </Select>
      </div>
      <a
        style={{ flex: 'none', paddingLeft: '0.75rem', display: 'block', cursor: 'pointer' }}
        onClick={() => addNewType(newTypeName, newTypeParentCategory)}
      >
        <PlusOutlined /> Add item
      </a>
    </div>
  }

  return (
    <>
    {isOpen && showInvoiceOverlay &&
      <InvoiceOverlay style={{
        width: window.innerWidth > 1024 ? (width ? width + "%" : "55%") : "100%",
      }}>
        <InvoiceTextWrapper>
          <InvoiceSpinnerIcon className={"custom-spinner"} />
          <span className={"title"}>Parsing invoice, please wait</span>
          <span className={"description"}>{invoiceFileName}</span>
        </InvoiceTextWrapper>
      </InvoiceOverlay>
    }
    <Drawer
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? (width ? width + "%" : "55%") : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => {
        if (formFieldsChanged) {
          confirmCloseUnsaved();
          return;
        }
        if (expense && _.isEmpty(expense.data.name)) {
          expense && removeExpense(expense.data.propertyId, expense.data.expenseId).then(
            () => {
              expense && dispatch(getAllExpenses(expense.data.propertyId));
              dispatch(getAllConfirmedExpenses(propertyId));
            }
          )
        } else if (_.isEmpty(expense?.data.type) && expense?.data.isConfirmed) {
          expense && removeExpense(expense.data.propertyId, expense.data.expenseId).then(
            () => {
              expense && dispatch(getAllExpenses(expense.data.propertyId));
              dispatch(getAllConfirmedExpenses(propertyId));
            })
        } else {
          expense && dispatch(getAllExpenses(propertyId));
          dispatch(getAllConfirmedExpenses(propertyId));
        }
        setContactName(null);
        form.resetFields();
        setEditingExpenseData(null);
        toggleDrawerOpen(false);
        setSavePayeeAsContact(false);
        setSavePayerAsContact(false);
      }}
      headerStyle={{
        backgroundColor: "#ebf9ff",
        margin: 0,
        padding: "2rem 2rem",
      }}
      title={
        <>
          <section style={{ display: "flex", justifyContent: "space-between" }}>
            <ExpensesIconWrapper>
              {icon ? icon : <ExpensesIcon />}{" "}
              <span style={{ paddingRight: "0.25rem" }}>
                {drawerTitle ? drawerTitle + " > " : "Expenses"}
                {drawerTitle && <b>Expenses</b>}
              </span>
            </ExpensesIconWrapper>
            <ButtonsWrapper>
              <CloseButton
                size={"large"}
                onClick={() => {
                  if (formFieldsChanged) {
                    confirmCloseUnsaved();
                    return;
                  }
                  if (expense && _.isEmpty(expense.data.name)) {
                    expense && removeExpense(expense.data.propertyId, expense.data.expenseId).then(
                      () => {
                        expense && dispatch(getAllExpenses(expense.data.propertyId));
                        dispatch(getAllConfirmedExpenses(propertyId));
                      }
                    )
                  } else if (_.isEmpty(expense?.data.type) && expense?.data.isConfirmed) {
                    expense && removeExpense(expense.data.propertyId, expense.data.expenseId).then(
                      () => {
                        expense && dispatch(getAllExpenses(expense.data.propertyId));
                        dispatch(getAllConfirmedExpenses(propertyId));
                      })
                  } else {
                    expense && dispatch(getAllExpenses(propertyId));
                    dispatch(getAllConfirmedExpenses(propertyId));
                  }
                  setContactName(null);
                  form.resetFields();
                  setEditingExpenseData(null);
                  toggleDrawerOpen(false);
                  setSavePayeeAsContact(false);
                  setSavePayerAsContact(false);
                }}
              >
                {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
              </CloseButton>
            </ButtonsWrapper>
          </section>
          <Title
            ref={titleInput}
            placeholder={"Expense name"}
            value={expenseName}
            style={{ fontSize: "2.5rem", color: "#002A43" }}
            maxLength={100}
            className={titleError ? "form-title-error" : ""}
            onChange={(e) => {
              setFormFieldsChanged(true);
              setExpenseName(e.target.value)
              if (e.target.value) {
                setTitleError(false);
              } else {
                setTitleError(true);
              }
            }}
          />
          {titleError && (<TitleError role="alert">Expense name is required</TitleError>)}
        </>
      }
    >
      <ContactDrawer
        isOpen={isContactDrawerOpen}
        toggleDrawerOpen={toggleContactDrawer}
        contact={editContactData}
        // @ts-ignore
        setEditingContactData={setEditContactData}
      />
      <WorkDrawer
        isOpen={workDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        work={editWorkData}
        // @ts-ignore
        setEditingWorkData={setEditWorkData}
        allowSave={true}
        refreshParent={refreshParent ? refreshParent : () => {}}
      />
      <InventoryDrawer
        drawerTitle={expenseName}
        isOpen={inventoryDrawerOpen}
        toggleDrawerOpen={toggleInventoryDrawer}
        inventory={editInventoryData}
        // @ts-ignore
        setEditingInventoryData={setEditInventoryData}
        toggleViewWorkDrawer={() => {}}
        setViewWorkId={() => {}}
      />
      <UpdateTaskDrawer
        task={editingTaskData}
        setEditingTaskData={setEditingTaskData}
        isOpen={isTaskDrawerOpen}
        toggleDrawer={toggleTaskDrawer}
        taskType={taskType}
        setTaskType={setTaskType}
        refreshParent={refreshParent}
      />
      <AttachmentsOnDrawer
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        alreadyAttachedFiles={expenseDetails.attachments}
        isOpen={showFileUploadList}
        toggleDrawerOpen={setShowFileUploadList}
        icon={ <ExpensesIcon />}
        drawerTitle={_.isEmpty(expenseName) ? "Expenses > Add Attachment" : expenseName+" > Add Attachment"}
      >
        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
          <AttachButtonStyled onClick={() => {
            selectedFiles.forEach(selectedFile => {
              setShowFileUploadList(false)
              expense &&
              addRelation({
                propertyId,
                parentId: expense.data.expenseId,
                childId: selectedFile,
                parentType: "expense",
                childType: "attachment",
              }).then(() => {
                refreshExpense();
              });
            })
            setSelectedFiles(new Set());
          }}>
            Attach
          </AttachButtonStyled>
        </div>
      </AttachmentsOnDrawer>
      <Form
        form={form}
        name="manageExpense"
        layout="vertical"
        size={"large"}
        onFinish={handleManageExpense}
        initialValues={initialValues()}
        onFieldsChange={() => setFormFieldsChanged(true)}
      >
        <>
          <section>
            <div
              style={{
                paddingBottom: "0.5rem",
              }}
            >
              <Label>Type of Expense *</Label>
              <Form.Item
                key={"typeOfExpense"}
                name={"typeOfExpense"}
                rules={[
                  {
                    required: true,
                    message: f(messages.validationTypeOfExpense),
                  },
                ]}
              >
                <TreeSelect
                  showSearch={false}
                  style={{ width: "100%", borderRadius: "6px" }}
                  value={typeOfExpenseTmp}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  listHeight={250}
                  placeholder="Please select"
                  allowClear
                  treeDefaultExpandAll
                  onChange={(value) => setTypeOfExpense(value)}
                  //getPopupContainer={trigger => trigger.parentElement}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      {window.innerWidth > 550 ? addNewExpenseTypeDesktop() : addNewExpenseTypeMobile()}
                    </div>
                  )}
                >
                  {roots.map((root: any) => {
                    return (<TreeNode value={root[1].id} title={root[1].name} selectable={false}>
                      {leaves.map((leaf: any) => {
                        if (leaf[1].parentId === root[1].id)
                          return <TreeNode value={leaf[1].id} title={leaf[1].name}/>
                      })}
                    </TreeNode>)
                  })}
                </TreeSelect>
              </Form.Item>
            </div>
            <div
              style={{
                paddingBottom: "0.5rem",
                display: showRelatedContact ? "block" : "none",
              }}
            >
              {" "}
              <div
                style={{
                  paddingBottom: "0.5rem",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Label>Payee</Label>
                </div>
              </div>
              <Form.Item key={"payee"} name={"payee"}>
                <AutoCompleteStyled
                  // @ts-ignore
                  getPopupContainer={trigger => trigger.parentElement}
                  showSearch
                  allowClear={true}
                  placeholder={"Please select"}
                  bordered={false}
                  showArrow={false}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  onSearch={onPayeeAutocompleteSearch}
                  onClear={() => setExistingPayee(null)}
                  onSelect={(value, option) => setExistingPayee(option)}
                  onChange={(val) => {
                    setPayeeName(val)
                    setIsExistingPayee(contactList.filter((contact: any) =>
                    contact.value === val)[0]?.label?.includes("Payee"))}}
                >
                  {contactList.map((contact: any) =>
                    <Select.Option key={contact.value} value={contact.value}>
                      <span style={{fontWeight: contact.label.includes("Payee") ? 400 : 600}}>
                        {contact.label}
                      </span>
                    </Select.Option>)}
                </AutoCompleteStyled>
              </Form.Item>
              {form.getFieldValue("payee") && (!contactList.filter((contact: any) =>
                contact.value === form.getFieldValue("payee")).length || isExistingPayee) &&
                <div style={{marginTop: "-0.25rem", marginBottom: "0.75rem"}}>
                  <Checkbox checked={savePayeeAsContact} onClick={() => setSavePayeeAsContact(!savePayeeAsContact)} style={{ paddingRight: "0.5rem" }}/>
                  Add this Payee to your Contacts list
                </div>}
            </div>
            <InputsWrapper style={{ paddingBottom: "0.5rem" }}>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Total cost *</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"totalCost"}
                  key={"totalCost"}
                  rules={[
                    { required: true, message: f(messages.validationCostOfExpense)},
                    { pattern: /^\d*\.?\d*$/, message: f(messages.validationCostOfInventory)}
                  ]}>
                  <InputNumberStyled
                    maxLength={10}
                    className={propertyPreferences.preferredCurrency}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Date of expense *</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"paymentDate"} key={"paymentDate"}
                  rules={[{required: true, message: f(messages.validationDateOfExpense)}]}
                >
                  <DatePicker
                    name={"paymentDate"}
                    format={propertyPreferences.preferredDateFormat}
                    allowClear={true}
                    inputReadOnly={true}
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    style={{
                      width: "100%",
                      border: "1px solid #DAE0E6",
                      boxSizing: "border-box",
                      borderRadius: "6px",
                    }}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Tax</Label>{" "}
                </LabelWrapper>
                <div style={{height: "2.52rem", display: "flex", alignItems: "center"}}>
                  <Checkbox
                    checked={isTaxDeductible}
                    onClick={() => {
                      setIsTaxDeductible(!isTaxDeductible);
                    }}
                    style={{ paddingRight: "0.5rem" }}
                  />
                  Include as tax deductible expense
                </div>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Paid by</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"payer"}
                  key={"payer"}
                >
                    <AutoCompleteStyled
                      // @ts-ignore
                        getPopupContainer={trigger => trigger.parentElement}
                        showSearch
                        allowClear={true}
                        placeholder={"Please select"}
                        bordered={false}
                        showArrow={false}
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        onSearch={onPayerAutocompleteSearch}
                        onClear={() => setExistingPayer(null)}
                        onSelect={(value, option) => setExistingPayer(option)}
                        onChange={(val) => {
                          setPayerName(val)
                          setIsExistingPayer(paidByList.filter((contact: any) =>
                            contact.value === val)[0]?.label?.includes("Payer"))}}
                    >
                      {paidByList.map((contact: any) =>
                        <Select.Option key={contact.value+contact.contactId} value={contact.value}>
                      <span style={{fontWeight: contact.label.includes("Payer") ? 400 : 600}}>
                        {contact.label}
                      </span>
                        </Select.Option>)}
                    </AutoCompleteStyled>
                </Form.Item>
                {form.getFieldValue("payer") && (!contactsAndUsersList.filter((contact: any) =>
                  contact.value === form.getFieldValue("payer")).length || isExistingPayer) &&
                <div style={{marginTop: "-0.25rem", marginBottom: "0.75rem"}}>
                    <Checkbox checked={savePayerAsContact} onClick={() => {setSavePayerAsContact(!savePayerAsContact);}}
                        style={{ paddingRight: "0.5rem" }}
                    />
                    Add this Payer to your Contacts list
                </div>}
              </InputWrapper>
            </InputsWrapper>
            {!expense?.data.propertyId && <InputsWrapper>
              <InputWrapper style={{width: "100%", margin: 0}}>
                <LabelWrapper>
                  <Label>Property *</Label>
                </LabelWrapper>
                <Form.Item
                    name={"expensePropertyId"} key={"expensePropertyId"}
                    rules={[{ required: true, message: "Please select a property to attach this expense to"}]}
                >
                  <SelectStyled
                    // bordered={false}
                    placeholder={"Please select"}
                    getPopupContainer={trigger => trigger.parentElement}
                  >
                    {properties.map(property => <Select.Option
                      value={property.propertyId.toString()}>{property.address}</Select.Option>)}
                  </SelectStyled>
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>}
          </section>

          {expense && (
            <>
              <div
                style={{
                  paddingTop: "2.5rem",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1em",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <NotesDrawerIcon
                    style={{
                      marginRight: "0.75rem",
                      height: "1.5em",
                      marginTop: "0.2em",
                    }}
                    className={"expenses-stroke-theme-icon"}
                  />
                  <span
                    style={{
                      fontSize: "1.125rem",
                      fontWeight: 600,
                      color: "#21272B",
                    }}
                  >
                    Notes
                  </span>
                </div>
              </div>
              <div>
                <Form.Item name={"notes"} key={"notes"}>
                <TextAreaStyled
                  showCount
                  maxLength={2500}
                  rows={5}
                  placeholder={"Type your notes..."}
                />
                </Form.Item>
              </div>

              <div
                style={{
                  paddingTop: "3rem",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1em",
                }}
              >
                <div style={{ display: "flex" }}>
                  <AttachmentIcon
                    style={{
                      marginRight: "0.75rem",
                      height: "1.5em",
                      marginTop: "0.2em",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "1.125rem",
                      fontWeight: 600,
                      color: "#21272B",
                    }}
                  >
                    Attachments
                  </span>
                </div>
              </div>
              {expenseDetails.attachments !== attachmentsInitial &&
                expense && expense.data.expenseId &&
                !Object.is(expense.data.expenseId, emptyGuid) &&
                !Object.is(expenseDetails.expenseId, emptyGuid) && (
                  <UploadFile
                    parentName={expense.data.name}
                    parentType={"expense"}
                    parentId={expenseDetails.expenseId}
                    propertyId={propertyId}
                    uploadImmediately={true}
                    singleAttachment={false}
                    fileList={expenseDetails.attachments}
                    refreshParent={refreshExpense}
                    highlightAttachment={highlightAttachment}
                    setShowFileUploadList={setShowFileUploadList}
                  />
                )}
            </>
          )}
            <SaveButtonWrapper>
              <SaveButton
                disabled={saveDisabled}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                type={"primary"}
                htmlType={"submit"}
                onClick={() => _.isEmpty(expenseName) && setTitleError(true)}
              >
                Save
              </SaveButton>
            </SaveButtonWrapper>
        </>
      </Form>
    </Drawer>
    </>
  );
};

// const CreateExpenseButtonWrapper = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   padding-top: 1rem;
// `;

// const SaveIconStyled = styled(SaveIcon)`
//   padding-right: 0.25rem;
//   width: 22px;
//   height: 22px;
// `;

export const Title = styled(Input)`
  border: none;
  outline: none;
  background-color: #ebf9ff;
  margin-top: 0.5rem;

  & > *:focus {
    border-color: #57a8e9;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }

  .ant-input:hover {
    border-color: #ebf9ff;
  }
`;

const ExpensesIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;

  & > *:last-child {
    margin-left: 1.5rem;
  }
`;

export const InputNumberStyled = styled(InputNumber)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  color: rgb(107, 113, 133);
  display: flex;
  align-items: center;
  
  &.Dollar::before {
    content: '$';
    padding-left: 0.5rem;
  }
  
  &.Euro::before {
    content: '\u20AC';
    padding-left: 0.5rem;
  }
  
  &.GBP::before {
    content: "£";
    padding-left: 0.5rem;
  }
  
  &.percent::after {
    content: "%";
    padding-right: 0.5rem;
  }
`;

const InvoiceOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(235, 249, 255, 0.8);
  border-left: 32px solid rgba(0, 0, 0, .45);
  height: 100vh;
  display: flex;
  align-items: center;
`

export const InvoiceTextWrapper = styled.div`
  margin: auto;
  width: 20.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: #21272B;
    fontSize: 1.125rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-top: 1.875rem;
    margin-bottom: 0.625rem;
  }

  .description {
    color: #6B7185;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
`
export const TitleError = styled.div`
  margin-bottom: -1.8rem;
  margin-top: 0.4rem;
  color: #ff4d4f;
  font-weight: 400;
  font-size: 0.9rem;
`

const SelectStyled = styled(Select)`
  .ant-select-selector {
    width: 100%!important;
    border: 1px solid #DAE0E6!important;
    box-sizing: border-box;
    border-radius: 6px!important;
    color: rgb(107,113,133);
    height: 2.5rem;
  }
`

export default ExpenseDrawer;
