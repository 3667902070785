import React from "react";
import {Dropdown, Menu} from "antd";
import {ReactComponent as MoreMenu} from "../../../images/More.svg";
import {ReactComponent as UploadIcon} from "../../../images/spaces/UploadIcon.svg";
import {ReactComponent as ImageUpload} from "../../../images/spaces/ImageUpload.svg";
import {ReactComponent as ImageRemove} from "../../../images/spaces/ImageRemove.svg";
import {ReactComponent as EmptyImagePreview} from "../../../images/EmptyImagePreview.svg";
import {ReactComponent as DeleteIcon} from "../../../images/ImageMobileRemove.svg";
import {showBouncedImageUploadNotification} from "../attachments/uploadHelpers";
import {MenuStyled} from "../works/WorksListComp";
import styled from "styled-components";
import Dragger from "antd/es/upload/Dragger";

type Props = {
  image: any;
  imageName: string;
  imageLink: string;
  setImageLink?: (arg: any) => void;
  previewLink?: string;
  setPreviewLink?: (arg: any) => void;
  setImage: (arg: any) => void;
  setImageName: (arg: any) => void;
  editImage: boolean;
  toggleEditImage: (arg: boolean) => void;
  removeImage: boolean;
  toggleRemoveImage: (arg: boolean) => void;
  uploadType: string;
  space?: any;
}

const UploadImage = (props: Props) => {
  const {image, imageName, imageLink, setImageLink, setImage, setImageName, editImage, previewLink, setPreviewLink,
    toggleEditImage, removeImage, toggleRemoveImage, space, uploadType} = props;
  
  const isFileAllowed = (file: any) => {
    let fileExt = file.name.split(".").pop();
    if (!fileExt) fileExt = "";
    const allowedExtensions = ["jpeg", "jpg", "png"]
    const allowedType = allowedExtensions.includes(fileExt.toLowerCase());
    const allowedSize = file.size / 1024 / 1024 < 20;
    return !(!allowedSize || !allowedType)
  }

  const checkFile = (file: any) => {
    if (isFileAllowed(file)) {
      return true;
    } else {
      showBouncedImageUploadNotification()
      return false;
    }
  }

  const processFile = (file: any) => {
    if (isFileAllowed(file)) {
      setImage(file.originFileObj);
      setImageName(file.name);
      setPreviewLink && setPreviewLink(URL.createObjectURL(file.originFileObj));
      setImageLink && setImageLink("");
      toggleRemoveImage(false);
    }
  }

  const moreMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      if (key === "edit") {
        toggleEditImage(true);
      } else if (key === "delete") {
        toggleRemoveImage(true);
        setImage(null);
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="edit">Select new</Menu.Item>
        <Menu.Item key="delete">Remove</Menu.Item>
      </MenuStyled>
    );
  };
  
  return <>
    {(!imageLink || editImage) && !image && uploadType === "thumbnail" && <div>
        <DraggerStyled
            customRequest={() => {}}
            multiple={false}
            showUploadList={false}
            style={{background: "white", border: "none"}}
            beforeUpload={(file) => checkFile(file)}
            onChange={(file) => processFile(file.file)}
        >
            <div className={"drag-inner"}>
                <UploadIcon />
                <DraggerText>
                    Drag and drop image file here or choose
                    <span className={"link"}>file from your computer</span>
                    <span className={"small"}>(Accepted file types .jpg or .png)</span>
                </DraggerText>
            </div>
        </DraggerStyled>
    </div>}

    {(!(imageLink || previewLink) || removeImage) && uploadType === "photo" && <div>
        <DraggerPhoto
            customRequest={() => {}}
            multiple={false}
            showUploadList={false}
            style={{background: "white", border: "none"}}
            beforeUpload={(file) => checkFile(file)}
            onChange={(file) => processFile(file.file)}
        >
            <div className={"drag-inner"}>
                <EmptyImagePreview />
            </div>
            <PhotoText>Upload Photo</PhotoText>
        </DraggerPhoto>
    </div>}

    {imageLink && !editImage && !removeImage && uploadType === "thumbnail" && <SpaceImagePreview>
        <div className={"description"}>
            <img style={{height: "2rem"}} src={imageLink} alt={""}/>
          {space ? space.name : null}
          {imageName ?? null}
        </div>
        <Dropdown overlay={moreMenuDropdown()} placement="bottomRight" trigger={["click"]}>
            <MoreMenu onClick={(e) => e.stopPropagation()}/>
        </Dropdown>
    </SpaceImagePreview>}

    {(imageLink || previewLink) && !removeImage && uploadType === "photo" &&
      <PhotoCircle style={{backgroundImage: `url(${imageLink ? imageLink : previewLink})`}}>
        <DeleteIcon onClick={() => {
          setImage(null);
          setImageName("");
          previewLink && setPreviewLink && setPreviewLink(null);
          imageLink.length && toggleRemoveImage(true);
        }}/>
      </PhotoCircle>}

    {image && imageName && uploadType === "thumbnail" && <UploadedFile>
              <span className={"file-title"}>
                  <ImageUpload />{imageName}
              </span>
        <ImageRemove style={{cursor: "pointer"}} onClick={() => {
          setImage(null);
          setImageName("")
        }} />
    </UploadedFile>}

    {removeImage && uploadType === "thumbnail" && "Your image will be removed upon saving this entry."}
  </>
}

export const DraggerStyled = styled(Dragger)`
  .ant-upload, .ant-upload-drag {
    padding: 1rem;
    background: #EFF1F5;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }
  
  .drag-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
`

export const DraggerPhoto = styled(Dragger)`
  .ant-upload {
    padding: 0!important;
  }
  
  .drag-inner {
    width: 7rem!important;
    height: 7rem!important;
    background: #F5F6FA;
    border-radius: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

const PhotoText = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #21272B;
  width: 100%;
  text-align: center;
  padding-top: 0.5rem;
`

const PhotoCircle = styled.div`
  width: 7rem;
  height: 7rem;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center center; 
  background-size: cover;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg { 
    opacity: 0;
    transition: .5s all ease;
    cursor: pointer;
  }
  
  &:hover svg { 
    opacity: 1;
  }
`

export const UploadedFile = styled.div`
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  color: #45655E;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .file-title {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }
`

export const DraggerText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #45655E;
  
  .link { color: #2F80ED; }
  .small { font-size: 0.75rem; }
`

const SpaceImagePreview = styled.div`
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .description {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0.5rem;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #45655E;
  }
`

export default UploadImage