import React, {useState} from "react";
import SiteVisitStep from "../components/publicOnboarding/SiteVisitStep";
import InfoGatheringStep from "../components/publicOnboarding/InfoGatheringStep";
import DocumentUploadStep from "../components/publicOnboarding/DocumentUploadStep";
import SummaryStep from "../components/publicOnboarding/SummaryStep";
import {Form} from "antd";

type Props = {
  isMapLoaded: boolean;
}

const PublicOnboarding = (props: Props) => {
  const { isMapLoaded } = props;

  const [siteVisitForm] = Form.useForm();
  const [infoGatheringForm] = Form.useForm();

  const [step, setStep] = useState(1);
  const [siteVisitData, setSiteVisitData] = useState<any>(null);
  const [infoGatheringData, setInfoGatheringData] = useState<any>(null);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [reset, toggleReset] = useState(false);

  const [location, setLocation]: any = useState(null);
  const [selectedUserInterests, setSelectedUserInterests] = useState<any>([]);
  const [selectedSpaces, setSelectedSpaces] = useState<any>([]);
  const [selectedHouseType, setSelectedHouseType] = useState("");

  const currentStep = (step: number) => {
    if (step === 1) return <SiteVisitStep
      form={siteVisitForm}
      setStep={setStep}
      setSiteVisitData={setSiteVisitData}
      toggleReset={toggleReset}
      location={location}
      setLocation={setLocation}
      selectedUserInterests={selectedUserInterests}
      setSelectedUserInterests={setSelectedUserInterests}
      selectedSpaces={selectedSpaces}
      setSelectedSpaces={setSelectedSpaces}
      selectedHouseType={selectedHouseType}
      setSelectedHouseType={setSelectedHouseType}
    />
    if (step === 2) return <InfoGatheringStep
      form={infoGatheringForm}
      setStep={setStep}
      setInfoGatheringData={setInfoGatheringData}
      reset={reset}
      toggleReset={toggleReset}
    />
    if (step === 3) return <DocumentUploadStep
      setStep={setStep}
      uploadedFiles={uploadedFiles}
      setUploadedFiles={setUploadedFiles}
    />
    if (step === 4 && siteVisitData && infoGatheringData) return <SummaryStep
      setStep={setStep}
      siteVisitData={siteVisitData}
      infoGatheringData={infoGatheringData}
      uploadedFiles={uploadedFiles}
    />
    else return <></>
  }

  if (!isMapLoaded)
    return <></>
  else
    return currentStep(step)
}

export default PublicOnboarding