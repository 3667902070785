import React from "react";
import {ButtonBlue, ButtonWrapper, FormWrapper, PageTitle, PageWrapper} from "./SiteVisitStep";
import {ReactComponent as UploadIcon} from "../../../images/spaces/UploadIcon.svg";
import {allowedExtensions, showBouncedImageUploadNotification} from "../attachments/uploadHelpers";
import {DraggerStyled, DraggerText, UploadedFile} from "../spaces/UploadImage";
import {ReactComponent as ImageUpload} from "../../../images/spaces/ImageUpload.svg";
import {ReactComponent as ImageRemove} from "../../../images/spaces/ImageRemove.svg";

type Props = {
  setStep: (arg: number) => void;
  uploadedFiles: Array<any>;
  setUploadedFiles: (arg: any) => void;
}

export const isFileAllowed = (file: any) => {
  let fileExt = file.name.split(".").pop();
  if (!fileExt) fileExt = "";
  const allowedType = allowedExtensions.includes(fileExt.toLowerCase());
  const allowedSize = file.size / 1024 / 1024 < 20;
  return !(!allowedSize || !allowedType)
}

export const checkFile = (file: any) => {
  if (isFileAllowed(file)) {
    return true;
  } else {
    showBouncedImageUploadNotification()
    return false;
  }
}

export const processFile = (files: any, setUploadedFiles: any) => {
  let processedFiles: any = []
  files.map((file: any) => {
    if (isFileAllowed(file)) {
      processedFiles.push({
        file: file.originFileObj,
        name: file.name,
        id: file.uid
      })
    }
  })
  setUploadedFiles(processedFiles)
}

const DocumentUploadStep = (props: Props) => {
  const {setStep, uploadedFiles, setUploadedFiles} = props;

  const handleGoBack = () => {
    window.scrollTo(0, 0);
    setStep(2);
  }

  const handleComplete = () => {
    window.scrollTo(0, 0);
    setStep(4);
  }

  return <PageWrapper>
    <PageTitle>Upload Documents (Page 3 of 4)</PageTitle>
    <FormWrapper>
      <DraggerStyled
        customRequest={() => {}}
        multiple={true}
        showUploadList={false}
        style={{background: "white", border: "none"}}
        beforeUpload={(file) => checkFile(file)}
        onChange={(files) => processFile(files.fileList, setUploadedFiles)}
      >
        <div className={"drag-inner"}>
          <UploadIcon />
          <DraggerText>
            Drag and drop your files here or choose
            <span className={"link"}>documents from your computer</span>
          </DraggerText>
        </div>
      </DraggerStyled>
      {uploadedFiles.length > 0 && uploadedFiles.map((file: any) => <UploadedFile key={file.id}>
        <span className={"file-title"}>
          <ImageUpload />{file.name}
        </span>
        <ImageRemove style={{cursor: "pointer"}} onClick={() => {
          setUploadedFiles(uploadedFiles.filter((f: any) => f.id !== file.id))
      }} />
      </UploadedFile>)}
    </FormWrapper>
    <ButtonWrapper>
      <ButtonBlue onClick={() => handleGoBack()}>Back</ButtonBlue>
      <ButtonBlue onClick={() => handleComplete()}>Next</ButtonBlue>
    </ButtonWrapper>
  </PageWrapper>
}

export default DocumentUploadStep