import React, {useState} from "react";
import styled from "styled-components";
import {Button, Collapse, Input, List, Menu, Modal, notification, Popover, Skeleton, Tag, Tooltip} from "antd";
import { useIntl } from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";

import { ReactComponent as DotIcon } from "../../../images/DotForFilters.svg";
import { ReactComponent as WorkIcon } from "../../../images/WorksNew.svg";
import { ReactComponent as CheckIcon } from "../../../images/CheckBlack.svg";
import { ReactComponent as CrossIcon } from "../../../images/CrossBlack.svg";
import { ReactComponent as SortIcon } from "../../../images/Sort.svg";
import { ReactComponent as EcoIcon } from "../../../images/ECO.svg";
import { TGetWorksResponse } from "../../../api-wrapper/works/getWorks";
import { ReactComponent as MoreMenu } from "../../../images/More.svg";
import { editWork } from "../../../api-wrapper/works/editWork";
import { removeWork } from "../../../api-wrapper/works/removeWork";
import messages from "../../screens/messages";
import { getAllWorks } from "../../actions/works";
import {ReactComponent as SearchIcon} from "../../../images/Search.svg";
import {numberWithCommas, renderRecurringText} from "../helper";
import {Guid} from "guid-typescript";
import TagContainer from "../tags/TagContainer";
import {ReactComponent as FilterIcon} from "../../../images/Filters.svg";
import {FilterGroupRow, Filters} from "../../screens/styled";
import {ButtonFilters} from "../../screens/Costs";
import FilterTagsNew, {
  CancelButton,
  CategoryHeader, EditCategoryBody,
  EditCategoryContainer, EditCategoryHeader,
  SubcategoryHeader
} from "../tags/FilterTagsNew";
import PeriodFilter from "../filters/PeriodFilter";
import {getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {getAllTagsWithUsageCountByType} from "../../actions/tagsWithUsageCountByType";
import {SectionLabel} from "../../screens/components";
import {getCurrencySign} from "../../screens/helpers";
import {ReactComponent as CollapseIconMinus} from "../../../images/CollapseIconMinus.svg";
import {ReactComponent as CollapseIconPlus} from "../../../images/CollapseIconPlus.svg";
const { Panel } = Collapse;

const moment = require("moment");

type Props = {
  works: TGetWorksResponse;
  completedWorks: boolean;
  setWorksSort: (arg01: string) => void;
  setWorksSearch: (arg01: string) => void;
  toggleForwardWorkDrawer: (arg01: boolean) => void;
  setWorkToForward: (arg01: Guid) => void;
  selectedTags: any;
  setSelectedTags: (arg01: any) => void;
  startDate: any;
  setStartDate: (arg: any) => void;
  endDate: any;
  setEndDate: (arg: any) => void;
  selectedPredefinedDate: any;
  setSelectedPredefinedDate: (arg: any) => void;
  workTypesWithAmounts: any;
  selectedWorkTypes: any;
  setSelectedWorkTypes: (arg: any) => void;
  toggleWorkDrawer: (arg: boolean) => void;
  setEditWorkData: (arg: any) => void;
  toggleViewWorkDrawer: (arg: boolean) => void;
  setWorkToViewId: (arg: Guid) => void;
  setPreSelectedTab: (arg: string) => void;
};

const WorksListComp = (props: Props) => {
  const { formatMessage: f } = useIntl();

  const dispatch = useDispatch();
  const { works, completedWorks, setWorksSort, setWorksSearch, toggleForwardWorkDrawer, setWorkToForward,
    selectedTags, setSelectedTags, startDate, setStartDate, endDate, setEndDate, selectedPredefinedDate,
    setSelectedPredefinedDate, workTypesWithAmounts, selectedWorkTypes, setSelectedWorkTypes, toggleWorkDrawer,
    toggleViewWorkDrawer, setWorkToViewId, setEditWorkData, setPreSelectedTab } = props;

  const propertyId = useSelector(getPropertyIdSelector).value;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [search, setSearch] = useState("");
  const [sortMenuPopoverVisible, setSortMenuPopoverVisible] = useState(false);
  const [sortMenuPopoverMobileVisible, setSortMenuPopoverMobileVisible] = useState(false);
  const [showTagFilters, setShowTagFilters] = useState(false);
  const [editTagsMode, toggleEditTagsMode] = useState(false);
  const [activeKeys, setActiveKeys] = useState<any>(["categories"]);

  function confirmDelete(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        removeWork(item.propertyId, item.workId).then(() => {
          setSearch("");
          setWorksSearch("");
          dispatch(getAllWorks(item.propertyId));
          dispatch(getAllTagsWithUsageCountByType(item.propertyId, "work"))
        });
      }
    });
  }

  const moreMenuDropdown = (item: any) => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      if (key === "edit") {
        toggleWorkDrawer(true);
        setEditWorkData({ data: item });
      } else if (key === "delete") {
        confirmDelete(item)
      } else if (key === "forward") {
        toggleForwardWorkDrawer(true)
        setWorkToForward(item.workId)
      } else if (key === "view") {
        toggleViewWorkDrawer(true)
        setWorkToViewId(item.workId)
        setPreSelectedTab("Details")
      } else if (key === "addLink") {
        toggleViewWorkDrawer(true)
        setWorkToViewId(item.workId)
        setPreSelectedTab("Links")
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="view">View</Menu.Item>
        <Menu.Item key="addLink">Add link</Menu.Item>
        {!item.isComplete && <Menu.Item key="forward">Forward</Menu.Item>}
        <Menu.Item key="edit">Edit</Menu.Item>
        <Menu.Item key="delete">Delete</Menu.Item>
      </MenuStyled>
    );
  };

  const sortMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      setSortMenuPopoverVisible(false);
      setSortMenuPopoverMobileVisible(false);
      setWorksSort("" + key);
    };

    return (
      <SortMenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="typeSort">Type</Menu.Item>
        <Menu.Item key="costHighToLow">Cost (High to low)</Menu.Item>
        <Menu.Item key="costLowToHigh">Cost (Low to high)</Menu.Item>
        {!completedWorks ? (
          <Menu.Item key="dueDateAsc">Due date (Oldest to latest)</Menu.Item>
        ) : (
          <Menu.Item key="completedDateAsc">Completed date (Oldest to latest)</Menu.Item>
        )}
        {!completedWorks ? (
          <Menu.Item key="dueDateDesc">Due date (Latest to oldest)</Menu.Item>
        ) : (
          <Menu.Item key="completedDateDesc">Completed date (Latest to oldest)</Menu.Item>
        )}
      </SortMenuStyled>
    );
  };

  const returnDescription = (completedWorks: boolean, item: any) => {
    let description

    if (completedWorks)
      description = <div style={{marginBottom: "1rem"}}>Completed on: {moment(item.completedDate).format(propertyPreferences.preferredDateFormat)}</div>

    if (!completedWorks && (item.completedDate || item.recurring && item.recurring !== "None"))
    description = <div style={{marginBottom: "1rem"}}>
      {item.recurring && item.recurring !== "None" ? "Recurring " + renderRecurringText(item.recurring)
        + (item.dueDate ? " - Due " + moment(item.dueDate).format(propertyPreferences.preferredDateFormat) : "\u00A0") :
          item.dueDate ? "Due " + moment(item.dueDate).format(propertyPreferences.preferredDateFormat) : "\u00A0"}
    </div>

    return <div>
      {description}
    </div>
  }

  const getClassesForWorkTypeTag = (workType: any) => {
    if (selectedWorkTypes.has(workType.name)) return "tag-blue"
    if (workType.amount === 0) return "tag-gray"
    else return "tag-white"
  }

  const selectWorkType = (type: string) => {
    const newSet = new Set(selectedWorkTypes);
    if (selectedWorkTypes.has(type)) {
      newSet.delete(type);
    } else {
      newSet.add(type);
    }
    setSelectedWorkTypes(newSet);
  }

  const displayFilters = () => (
    <Filters style={{minHeight: "7rem"}}>
      <FilterGroupRow>
        <CategoryHeader>
          Filters <CancelButton onClick={() => {
          setEndDate(null);
          setStartDate(null);
          setSelectedWorkTypes(new Set());
          setSelectedPredefinedDate("custom");
          setSelectedTags([]);
        }}>Clear filters</CancelButton>
        </CategoryHeader>
      </FilterGroupRow>
      <PeriodFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedPredefinedDate={selectedPredefinedDate}
        setSelectedPredefinedDate={setSelectedPredefinedDate} />
      <FilterGroupRow style={{flexDirection: "column", rowGap: "1rem", border: 0, marginBottom: 0}}>
        <Collapse bordered={false} ghost onChange={(key) => setActiveKeys(key)} defaultActiveKey={activeKeys}>
          <Panel showArrow={false} header={<SubcategoryHeader>
            Categories
            <div className={"button-wrapper"}>
              {activeKeys.includes("categories") ? <CollapseIconMinus /> : <CollapseIconPlus />}
            </div>
          </SubcategoryHeader>} key="categories">
            <EditCategoryContainer>
              <EditCategoryHeader>Categories</EditCategoryHeader>
              <EditCategoryBody>
                {workTypesWithAmounts.map((type: any) => <TagStyled
                  key={"filterType"+type.name}
                  className={getClassesForWorkTypeTag(type)}
                  onClick={() => selectWorkType(type.name)}
                >{type.name} ({type.amount})</TagStyled>)}
              </EditCategoryBody>
            </EditCategoryContainer>
          </Panel>
        </Collapse>
      </FilterGroupRow>
      <FilterTagsNew
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        parentType={"work"}
        hideClearTagsButton={true}
        workType={completedWorks ? "comleted" : "uncompleted"}
        selectedCategories={selectedWorkTypes}
        entries={works}
      />
    </Filters>
  );

  const haveFiltersBeenUsed = () => selectedTags.length > 0 || startDate || endDate || selectedWorkTypes.size > 0

  return (
    <>
      <SectionLabel className={`with-icon ${completedWorks && "padded"}`}>
        <WorkIcon className={"works-theme-icon"}/>
        {completedWorks
          ? f(messages.completedWorksTitle)
          : f(messages.todoWorksTitle)}
      </SectionLabel>
      <WorksListDesktop>
        <SearchWrapper>
          <Search
            size={"large"}
            value={search}
            onChange={(val) => {
              setSearch(val.target.value);
              setWorksSearch(val.target.value);
            }}
            placeholder={"Search projects..."}
            suffix={<SearchIcon />}
          />
          <ButtonFilters
            size={"large"}
            onClick={() => setShowTagFilters(!showTagFilters)}
            icon={<div style={{position: "relative"}}>
              {haveFiltersBeenUsed() && <DotIcon
                  style={{position: "absolute", top: "-0.25rem", left: "-0.3rem"}}/>}
              <FilterIcon style={{marginRight: "0.3rem"}} />
            </div>}
          >
            <span style={{paddingLeft: "0.5rem"}}>
              {showTagFilters ? "Hide" : "Show"} filters
            </span>
          </ButtonFilters>
          <Popover
            content={sortMenuDropdown()}
            className={"sortMenuWorks"}
            visible={sortMenuPopoverVisible}
            placement="bottom"
            onVisibleChange={setSortMenuPopoverVisible}
            trigger={["click"]}
          >
            <ButtonSort
              icon={<SortIcon />}
              onClick={() => {
                setSortMenuPopoverVisible(!sortMenuPopoverVisible);
              }}
            />
          </Popover>
        </SearchWrapper>
        {showTagFilters && displayFilters()}
        <List
          style={{ width: "100%" }}
          className="rounded-bottom-list"
          itemLayout="horizontal"
          dataSource={works.filter(work => !_.isNull(work.name))}
          renderItem={(item) => (
            <ListItemStyled
              onClick={() => {
                if (!editTagsMode) {
                  toggleViewWorkDrawer(true)
                  setPreSelectedTab("Details")
                  setWorkToViewId(item.workId)
                }}}>
              <ListItemContent>
                <Skeleton loading={false} title={false} active>
                  <List.Item.Meta
                    title={<span>{item.name}
                      {item.relatedImprovementId && <Tooltip
                          title={<span style={{color: "black"}}>
                            Livlet improvement</span>}
                          color={"white"}>
                          <EcoIcon
                              style={{marginLeft: "0.75rem", marginBottom: "-0.25rem"}}
                              className={"eco-icon"} />
                      </Tooltip>}
                    </span>}
                    description={<>
                      <p>{completedWorks
                        ? "Completed on: " + moment(item.completedDate).format(propertyPreferences.preferredDateFormat)
                        : item.recurring && item.recurring !== "None"
                          ? "Recurring " +
                          renderRecurringText(item.recurring) +
                          (item.dueDate ? " - Due " + moment(item.dueDate).format(propertyPreferences.preferredDateFormat) : "\u00A0")
                          : item.dueDate
                            ? "Due " + moment(item.dueDate).format(propertyPreferences.preferredDateFormat)
                            : "\u00A0"}</p>
                      <div style={{marginRight: "1.5rem"}}>
                        <TagContainer
                          parentTags={item.tags}
                          parentId={item.workId}
                          parentType={"work"}
                          selectedTags={selectedTags}
                          toggleGlobalEditMode={toggleEditTagsMode}
                          refreshParent={() => dispatch(getAllWorks(propertyId))}
                        />
                      </div>
                    </>
                    }
                  />
                </Skeleton>
                <ListItemActions>
                  {item.type && <TagStyled>{item.type.toUpperCase()}</TagStyled>}
                  {(item.workTotalExpense > 0 || item.workTotalExpense < 0) && (
                    <TagStyledBold>
                      {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(item.workTotalExpense)}
                    </TagStyledBold>
                  )}
                  <Popover
                    content={<div style={{
                      fontSize: "0.75rem",
                      fontWeight: 500,
                      padding: "0.5rem",
                      maxWidth: "17rem",
                      textAlign: "center"
                    }}>
                      {!completedWorks ? "Mark as done" : "Unmark as done"}
                    </div>}
                    overlayClassName={"smallTooltipPopover"}
                    placement="top"
                    trigger={["hover"]}
                  >
                  <ButtonStyled
                    icon={completedWorks ? (<CheckIcon
                        style={{display: "inline-block", verticalAlign: "middle", height: "1.8rem", width: "1.8rem",}}/>) : (" ")}
                    size={"large"}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!completedWorks && item.recurring && item.recurring !== "None") {
                        notification.info({
                          message: f(messages.completedRecurringWork),
                          description: f(messages.completedRecurringWorkMsg),
                          placement: "topRight",
                        });
                      }
                      editWork({
                        ...item,
                        isComplete: !completedWorks,
                        completedDate: completedWorks ? null : moment().format("YYYY-MM-DD"),
                        //dueDate: completedWorks ? moment().format("YYYY-MM-DD") : null
                      })
                        .then(() => dispatch(getAllWorks(item.propertyId)))
                      }}
                    />
                  </Popover>
                  <Popover
                    content={moreMenuDropdown(item)}
                    placement="bottomRight"
                    trigger={["click"]}
                    //@ts-ignore
                    zIndex={2}
                  >
                    <MoreMenu
                      style={{ marginLeft: "2rem" }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </Popover>
                </ListItemActions>
              </ListItemContent>
            </ListItemStyled>
          )}
        />
      </WorksListDesktop>
      <WorksListMobile>
        <SearchWrapperMobile style={{flexDirection: "column", rowGap: "1rem"}}>
          <Search
            style={{height: "3rem"}}
            size={"large"}
            value={search}
            onChange={(val) => {
              setSearch(val.target.value);
              setWorksSearch(val.target.value);
            }}
            placeholder={"Search projects..."}
            suffix={<SearchIcon />}
          />
          <div style={{display: "flex"}}>
            <ButtonFilters style={{height: "3rem"}}
              size={"large"}
              onClick={() => setShowTagFilters(!showTagFilters)}
              icon={<div style={{position: "relative"}}>
                {haveFiltersBeenUsed() && <DotIcon
                    style={{position: "absolute", top: "-0.25rem", left: "-0.3rem"}}/>}
                <FilterIcon style={{marginRight: "0.3rem"}} />
              </div>}
            >
            <span style={{paddingLeft: "0.5rem"}}>
              {showTagFilters ? "Hide" : "Show"} tag filters
            </span>
            </ButtonFilters>
            <Popover
              content={sortMenuDropdown()}
              className={"sortMenuWorks"}
              visible={sortMenuPopoverMobileVisible}
              placement="bottom"
              onVisibleChange={setSortMenuPopoverMobileVisible}
              trigger={["click"]}
            >
              <ButtonSort
                icon={<SortIcon />}
                onClick={() => {
                  setSortMenuPopoverMobileVisible(!sortMenuPopoverMobileVisible);
                }}
              />
            </Popover>
          </div>
        </SearchWrapperMobile>
        {showTagFilters && displayFilters()}
        <List
          style={{ width: "100%" }}
          itemLayout="vertical"
          dataSource={works}
          renderItem={(item) => (
            <List.Item style={{ padding: "0 1rem" }} onClick={() => {
              if (!editTagsMode) {
                toggleViewWorkDrawer(true)
                setWorkToViewId(item.workId)
              }}}>
              <Skeleton loading={false} title={false} active>
                  <List.Item.Meta
                    title={
                      <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "0.75rem",
                        overflow: "hidden"
                      }}>
                        <div style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontWeight: 600,
                          fontSize: '1.375rem',
                          lineHeight: '1.75rem'
                        }}>{item.name}</div>
                        <Popover
                          content={moreMenuDropdown(item)}
                          placement="bottomRight"
                          trigger={["click"]}
                          //@ts-ignore
                          zIndex={2}
                        >
                          <MoreMenu
                            style={{ marginLeft: "2rem", flexShrink: 0 }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        </Popover>
                      </div>
                    }
                    description={
                      <div>
                        {returnDescription(completedWorks, item)}
                        <div style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginTop: "1.25rem",
                          marginBottom: "1.25rem"
                        }}>
                          {item.type && <TagStyled>{item.type.toUpperCase()}</TagStyled>}
                          {(item.workTotalExpense > 0 || item.workTotalExpense < 0) && (
                            <TagStyledBold>
                              {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(item.workTotalExpense)}
                            </TagStyledBold>
                          )}
                        </div>
                        <TagContainer
                          parentTags={item.tags}
                          parentId={item.workId}
                          parentType={"work"}
                          selectedTags={selectedTags}
                          toggleGlobalEditMode={toggleEditTagsMode}
                          refreshParent={() => dispatch(getAllWorks(propertyId))}
                        />
                        <ButtonStyled
                          style={{marginTop: "0.75rem"}}
                          icon={completedWorks ? <CrossIcon style={{marginRight: '0.5rem'}} /> : <CheckIcon style={{marginRight: '0.5rem'}} />}
                          onClick={() => {
                            if (!completedWorks) {
                              notification.info({
                                message: f(messages.completedRecurringWork),
                                description: f(messages.completedRecurringWorkMsg),
                                placement: "topRight",
                              });
                            }
                            editWork({
                              ...item,
                              isComplete: !completedWorks,
                              completedDate: completedWorks ? null : moment().format("YYYY-MM-DD"),
                              //dueDate: completedWorks ? moment().format("YYYY-MM-DD") : null
                            })
                              .then(() => dispatch(getAllWorks(item.propertyId)))
                              .catch();
                          }}
                        >
                          {completedWorks
                            ? f(messages.markWorkUncompleted)
                            : f(messages.markWorkCompleted)}
                        </ButtonStyled>
                      </div>
                    }
                  />
              </Skeleton>
            </List.Item>
          )}
        />
      </WorksListMobile>
    </>
  );
};

const ListItemStyled = styled(List.Item)`
  display: flex;
  flex-direction: column;
  
  @media (max-width: 1024px) {
    width: 100%;
    
    .ant-list-item {
      padding: 0;
    }
    
    .ant-list-vertical, .ant-list-item-action > li { 
      padding: 0; 
    }
  }
  
  @media (min-width: 1024px) {  
    padding: 0.5rem 2rem;
    min-height: 6rem;
  }

  .ant-list-item-meta-title {
    font-size: 1rem;
    font-weight: 600;
    color: #21272b;
  }
  .ant-list-item-meta-description {
    color: #6b7185;
    font-weight: 400;
    margin-top: 0.4rem;
  }
`;

const WorksListDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  max-width: 1100px;
  background-color: #fff;
  border-radius: 0.625rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const WorksListMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #dae0e6;
  border-radius: 0.625rem;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const ButtonStyled = styled(Button)`
  display: inline;
  border-radius: 6px !important;
  font-weight: 600;
  
  @media (max-width: 1024px) {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
    font-size: 1.125rem;
    height: auto;
  }
`;

export const TagStyled = styled(Tag)`
  background-color: #eff1f5;
  color: #6b7185;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  margin-right: 1rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 0;
  font-size: 0.75rem;
  font-weight: 500;
  height: 1.75rem;
`;

export const TagStyledBold = styled(TagStyled)`
  font-weight: 700;
  color: #21272b;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
`;

export const MenuStyled = styled(Menu)`
  border-right: 0;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-radius: 6px;
  color: #21272b;
  
  .ant-dropdown-menu-item {
    font-weight: 600;
    border: 1px solid rgba(0, 0, 0, 0) !important;
    color: #21272b;
  }

  .ant-menu-item-active, .ant-dropdown-menu-item-active {
    color: #6b7185 !important;
  }

  .ant-menu-item {
    height: 1.5rem !important;
    padding-bottom: 2rem !important;
  }
`;

export const Search = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1.25rem;
  padding: 0 1.25rem 0 1.25rem;
`;

const SearchWrapperMobile = styled.div`
  display: flex;
  width: 100%;
  padding: 0.75rem 1rem 1rem 1rem;
  border-bottom: 1px solid #f0f0f0;
`;

export const ButtonSort = styled(ButtonStyled)`
  margin-left: 1rem;
  height: 2.5rem !important;
  width: 3.2rem !important;
  margin-top: 0;
  svg {
    margin-top: 0.3rem;
  }
  
  @media (max-width: 1024px) {
    height: 3rem!important;
  }
`;

export const SortMenuStyled = styled(Menu)`
  font-weight: 600;
  color: #21272b;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-radius: 6px;

  .ant-menu-item-active {
    color: #6b7185 !important;
  }

  .ant-menu-item {
    height: 1.5rem !important;
    padding-bottom: 2rem !important;
  }
`;

export const ListItemContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ListItemActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export default WorksListComp;
