import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetSpacesResponse } from "../../api-wrapper/spaces/getSpaces";
import {
  getSpacesFailureAction,
  getSpacesInitAction,
  getSpacesSuccessAction,
  TGetSpacesActions,
} from "../actions/spaces";
import {spacesInitial} from "../components/helper";

export type TSpaces = {
  fetchingStatus: TFetchingStatus;
  content: TGetSpacesResponse;
};

const initialState: TSpaces = {
  content: spacesInitial,
  fetchingStatus: TFetchingStatus.Defined,
};

export const spacesReducer = createReducer<TSpaces, TGetSpacesActions>(
  initialState
)
  .handleAction(getSpacesInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getSpacesSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getSpacesFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
