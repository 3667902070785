import React, {useEffect, useState} from "react";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";
import {ReactComponent as ReturnArrow} from "../../../images/spaces/ReturnArrow.svg";
import {Label} from "../../screens/RegistrationPage";
import UploadImage from "../spaces/UploadImage";
import {useDispatch, useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import {addPropertyImage} from "../../../api-wrapper/properties/addPropertyImage";
import {getAllProperties} from "../../actions/properties";
import {
  ActionButtonGreen,
  ActionButtonWhite,
  CircleIcon,
  DrawerFooter,
  DrawerHeader,
  DrawerStyled
} from "../../screens/components";

type Props = {
  isDrawerOpen: boolean;
  toggleDrawer: (arg: boolean) => void;
}

const PropertyPhotoDrawer = (props: Props) => {
  const {isDrawerOpen, toggleDrawer} = props;

  const dispatch = useDispatch();
  const properties = useSelector(getPropertiesSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;

  const [image, setImage] = useState<any>(null);
  const [imageName, setImageName] = useState("");
  const [imageLink, setImageLink] = useState<any>(null);
  const [removeImage, toggleRemoveImage] = useState(false);
  const [editImage, toggleEditImage] = useState(false);

  useEffect(() => {
    const currentProperty = properties.find((property) => property.propertyId === propertyId);
    if (!currentProperty)
      return;
    setImageLink(currentProperty.imageThumbnail);
    setImageName(currentProperty.address + (currentProperty.city ? `, ${currentProperty.city}` : "")
      + (currentProperty.zip ? ` ${currentProperty.zip}` : ""));
  }, [properties, propertyId, isDrawerOpen])

  const performCleanup = () => {
    toggleDrawer(false)
    setImage(null);
    setImageName("");
    setImageLink("");
    toggleRemoveImage(false);
    toggleEditImage(false);
  }

  const handleSave = () => {
    addPropertyImage({
      propertyId,
      propertyImage: image
    }).then(() => {
      dispatch(getAllProperties());
      performCleanup();
    })
  }

  return <DrawerStyled
    push={{ distance: "32px" }}
    closeIcon={false}
    width={window.innerWidth > 1024 ? "680px" : "100%"}
    height={window.innerWidth > 768 ? "100%" : "85%"}
    placement={window.innerWidth > 768 ? "right" : "bottom"}
    visible={isDrawerOpen}
    onClose={() => performCleanup()}
    title={<DrawerHeader className={"edit-header"}>
      {window.innerWidth <= 768 && <ReturnArrow onClick={() => performCleanup()}/>}
      My home
      {window.innerWidth > 768 && <CircleIcon onClick={() => performCleanup()}><CloseIcon /></CircleIcon>}
    </DrawerHeader>}
    footer={<DrawerFooter>
      <ActionButtonWhite
        onClick={() => performCleanup()}>Cancel</ActionButtonWhite>
      <ActionButtonGreen
        onClick={() => handleSave()}>Save</ActionButtonGreen>
    </DrawerFooter>}
  >
    <Label>Add/Edit banner image</Label>
    <UploadImage
      image={image}
      imageName={imageName}
      imageLink={imageLink}
      setImage={setImage}
      setImageName={setImageName}
      editImage={editImage}
      toggleEditImage={toggleEditImage}
      removeImage={removeImage}
      toggleRemoveImage={toggleRemoveImage}
      uploadType={"thumbnail"}
    />
  </DrawerStyled>
}

export default PropertyPhotoDrawer