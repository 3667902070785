import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import _ from "lodash";
import {emptyGuid} from "../../screens/helpers";
import {CircleIcon, DrawerHeader, DrawerStyled} from "../../screens/components";
import {ReactComponent as ReturnArrow} from "../../../images/spaces/ReturnArrow.svg";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";
import {ContainerItem, ContainerItemWrapper, ContainerTitle} from "../spaces/AddSpaceOptions";
import {
  Legalese,
  ServiceUnavailable,
  supportedLocation,
  TradeDetails,
  TradeItem,
  TradesContainer
} from "./TradeDrawer";
import SolidInsulation from "../../../images/partners/SolidInsulation.png";
import CavityInsulation from "../../../images/partners/CavityInsulation.png";
import RoofInsulation from "../../../images/partners/RoofInsulation.png";
import {ReactComponent as SolarPanels} from "../../../images/partners/SolarPanels.svg";

type Props = {
  isDrawerOpen: boolean;
  toggleDrawer: (arg: boolean) => void;
  selectedType: string;
  setSelectedType: (arg: string) => void;
}

const InsulationDrawer = (props: Props) => {
  const {isDrawerOpen, toggleDrawer, selectedType, setSelectedType} = props;
  const [propertyLocation, setPropertyLocation] = useState<any>(null);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find((property) => property.propertyId === propertyId);
    if (!currentProperty)
      return;
    setPropertyLocation(`${currentProperty.ukCountry}, ${currentProperty.country}`)
  }, [propertyId])

  const handleCancel = () => {
    setSelectedType("")
    toggleDrawer(false)
  }

  const iconDispenser = (type: any) => {
    if (type === "Solid wall insulation") return <img src={SolidInsulation} style={{width: "auto", height: "auto"}} alt={""} />
    if (type === "Cavity wall insulation") return <img src={CavityInsulation} style={{width: "auto", height: "auto"}} alt={""} />
    if (type === "Roof Insulation") return <img src={RoofInsulation} style={{width: "auto", height: "auto"}} alt={""} />
    if (type === "Install solar panels") return <SolarPanels />
    else return null
  }

  return <DrawerStyled
    push={{ distance: "32px" }}
    closeIcon={false}
    width={window.innerWidth > 1024 ? "680px" : "100%"}
    height={window.innerWidth > 768 ? "100%" : "85%"}
    placement={window.innerWidth > 768 ? "right" : "bottom"}
    visible={isDrawerOpen && propertyLocation}
    onClose={() => handleCancel()}
    title={<DrawerHeader className={"edit-header"}>
      {window.innerWidth <= 768 && <ReturnArrow onClick={() => handleCancel()}/>}
      {selectedType ? selectedType : "Insulation"}
      {window.innerWidth > 768 && <CircleIcon onClick={() => handleCancel()}><CloseIcon /></CircleIcon>}
    </DrawerHeader>}
  >
    {propertyLocation === supportedLocation ? (selectedType === "" ? <TradesContainer className={"open"}>
      <ContainerTitle>Insulation</ContainerTitle>
      <ContainerItemWrapper>
        <ContainerItem className={"bold"} onClick={() => setSelectedType("Solid wall insulation")}>
          Solid wall insulation
        </ContainerItem>
        <ContainerItem className={"bold"} onClick={() => setSelectedType("Cavity wall insulation")}>
          Cavity wall insulation
        </ContainerItem>
        <ContainerItem className={"bold"} onClick={() => setSelectedType("Roof Insulation")}>
          Roof Insulation
        </ContainerItem>
        <ContainerItem className={"bold"} onClick={() => setSelectedType("Install solar panels")}>
          Install solar panels
        </ContainerItem>
      </ContainerItemWrapper>
    </TradesContainer> : <>
      {<TradesContainer className={"open stretched"}>
        <ContainerTitle>{iconDispenser(selectedType)}{selectedType}</ContainerTitle>
        <ContainerItemWrapper>
          <TradeItem>
            <TradeDetails>
              <span>Organise a site visit by calling<br/><span className={"accent-green"}>0800 526 023*</span></span>
              <span className={"disclaimer"}>*Standard network charges may apply.</span>
            </TradeDetails>
          </TradeItem>
        </ContainerItemWrapper>
      </TradesContainer>}
      {Legalese(false, "insulation")}
    </>) : ServiceUnavailable()}
  </DrawerStyled>
}

export default InsulationDrawer