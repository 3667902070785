import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {
  getExpenses,
  TGetExpensesResponse,
} from "../../api-wrapper/expenses/getExpenses";
import {Guid} from "guid-typescript";

export const getConfirmedExpensesInitAction = createAction(
  "Expenses/GET_Confirmed_Expenses_INIT"
)();
export const getConfirmedExpensesSuccessAction = createAction(
  "Expenses/GET_Confirmed_Expenses_SUCCESS"
)<TGetExpensesResponse>();
export const getConfirmedExpensesFailureAction = createAction(
  "Expenses/GET_Confirmed_Expenses_FAILURE"
)();

export type TGetConfirmedExpensesInitAction = ActionType<typeof getConfirmedExpensesInitAction>;
export type TGetConfirmedExpensesSuccessAction = ActionType<
  typeof getConfirmedExpensesSuccessAction
  >;
export type TGetConfirmedExpensesFailureAction = ActionType<
  typeof getConfirmedExpensesFailureAction
  >;

export type TGetConfirmedExpensesActions =
  | TGetConfirmedExpensesInitAction
  | TGetConfirmedExpensesSuccessAction
  | TGetConfirmedExpensesFailureAction;

export type TGetConfirmedExpensesThunkActionType = (propertyId: Guid) => ThunkAction<void, TRootState, null, TGetConfirmedExpensesActions>;

export const getAllConfirmedExpenses: TGetConfirmedExpensesThunkActionType = (propertyId: Guid) =>
  (dispatch: (action: Action | Promise<Action>) => any) => {
  dispatch(getConfirmedExpensesInitAction());
  return getExpenses(propertyId, true)
    .then((payload) => {
      return dispatch(getConfirmedExpensesSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getConfirmedExpensesFailureAction()));
};

