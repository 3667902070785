import styled from "styled-components";
import {Alert, Button, Drawer, Form, Input, Pagination, Tooltip} from "antd";
import {ReactComponent as HelpIcon} from "../../images/Help.svg";
import {ReactComponent as BoxMail} from "../../images/BoxMail.svg";
import {ReactComponent as CloseCircle} from "../../images/CloseCircle.svg";
import {setFileUploadPopupState} from "../../localStorage";
import React from "react";

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-close {
    display: none!important;
  }

  .ant-drawer-content-wrapper {
    box-shadow: none;
    border: 0;
  }
  
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: none;
  }

  .ant-drawer-header, .ant-drawer-footer {
    border: 0;
    padding: 0;
  }

  .ant-drawer-content {
    padding: 2.375rem 2.5rem 1.75rem 2.25rem;
    border-radius: 24px 0px 0px 24px;
  }
  
  @media (max-width: 768px) {
    .ant-drawer-content {
      padding: 1rem;
      border-radius: 24px 24px 0px 0px;
    }
  }
  
  .ant-drawer-body {
    margin: 1.5rem 0!important;
    padding: 0 1rem 0.25rem 0;
    
    &::-webkit-scrollbar {
      width: 10px;
    }
    
    &::-webkit-scrollbar-track {
      background: white;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #E5E5E5;
      border-radius: 7px;
      border: 3px solid #E5E5E5;
    }
  }
`

export const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.625rem;
  color: #21272B;
  column-gap: 0.5rem;
  
  &.edit-header { column-gap: 1rem; }
  &.header-mobile { display: none; }
  
  .reminder-title {
    font-size: 1.625rem;
    line-height: 1.75rem;
    color: #21272B;
  }
  
  @media (max-width: 768px) {
    font-size: 1.625rem;
    line-height: 1.75rem;
    
    &.event-drawer { 
      font-weight: 700;
      justify-content: center;
      font-size: 2.125rem;
      line-height: 2.5rem;
    }
    
    &.event-drawer .close-icon { display: none; }
    
    &.header-desktop { display: none; }
    &.header-mobile { 
      gap: 1rem;
      display: flex;
      flex-direction: column;
    }
    
    &.edit-header { justify-content: flex-start; }
    &.task-header { 
      color: #45655E;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    &.underlined {
      border-bottom: 1px solid #E6E6E6;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }
  }
`

export const DrawerFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
  
  &.fake-footer {
    margin-top: 1.5rem;
  }
  
  &.task-footer { 
    justify-content: center;
    column-gap: 3rem;
  }
  
  &.mobile-footer { display: none; }
  @media (max-width: 1024px) {
    &.desktop-footer { display: none; }
    &.mobile-footer { display: flex; }
  }
`

export const CircleIcon = styled(Button)`
  cursor: pointer;
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &.green-outline { border: 1px solid #009966; }
  &.green-fill { 
    border: 1px solid #009966; 
    background: #009966; 
  }
  &.green-fill svg path { fill: white; }
`

const ActionButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.25rem;
  gap: 8px;
  border-radius: 4px;
  transition: all .5s ease;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  height: 3rem;
  border: 1px solid #00B74F!important;
  min-width: 9.375rem;
`

export const ActionButtonGreen = styled(ActionButton)`
  background: #00B74F;
  color: white;
  
  &:disabled {
    border: 1px solid #d9d9d9!important;
  }
  
  &:disabled svg path {
    fill: rgba(0, 0, 0, 0.25);
    transition: all .5s ease;
  }
  
  &:hover, &:focus {
    background: #22AB5B;
    color: white;
  }
  
  & svg path {
    fill: white;
  }
`

export const ActionButtonDarkBlue = styled(ActionButton)`
  background: #2A80E1;
  color: white;
  border: 1px solid #2A80E1!important;
  
  &:disabled {
    border: 1px solid #d9d9d9!important;
  }
  
  &:disabled svg path {
    fill: rgba(0, 0, 0, 0.25);
    transition: all .5s ease;
  }
  
  &:hover, &:focus { 
    background: #40a9ff;
    color: white;
    border: 1px solid #40a9ff!important;
  }
  
  & svg path {
    fill: white;
  }
`

export const ActionButtonDarkGreen = styled(ActionButtonGreen)`
  background: #009966;
`

export const ActionButtonDarkGreenOutline = styled(ActionButtonGreen)`
  border: 1px solid #009966;
  color: #009966;
  background: white;
  
  &:hover, &:focus {
    border: 1px solid #22AB5B;
    color: #22AB5B;
    background: white;
  }
`

export const FileActionButton = styled(ActionButton)`
  background: white;
  color: #3E5D58;
  border: 1px solid #F5F6FA!important;
  
  &:hover, &:focus {
    background: white;
    color: #3E5D58;
    border: 1px solid #F5F6FA!important;
  }
  
  &.active {
    background: #00B74F;
    color: white;
  }
  
  &.active svg path { stroke: white; }
  
  &.active:disabled {
    border: 1px solid #d9d9d9!important;
  }

  &.active:hover, &.active:focus {
    background: #22AB5B;
    color: white;
  }
`

export const ActionButtonWhite = styled(ActionButton)`
  background: white;
  color: #009966;
  
  &:hover, &:focus {
    background: white;
    color: #22AB5B;
    border: 1px solid #22AB5B!important;
  }
`

export const ViewDrawerTabs = styled.div`
  width: 100%;
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 1rem;
  display: flex;
  gap: 1.5rem;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #7A7D80;
  display: none;
  
  .default, .selected { cursor: pointer; }
  
  &.planner {
    border-bottom: none;
    padding-bottom: 0;
  }
  
  .selected {
    color: #21272B;
    text-decoration: underline;
  }
  
  &.planner { display: none; }
  
  @media (max-width: 768px) {
    display: flex;
    &.planner { display: flex; }
  }
`

export const LinkedItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-top: 0;
`

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const EditDrawerStyled = styled(DrawerStyled)`
  .ant-drawer-body {
    display: flex;
    align-items: stretch;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`

export const EditDrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1.75rem;
  color: #21272B;
`

export const PhotoNameFieldsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  
  .field-wrapper {
    height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
  }
  
  .full-name-wrapper {
    display: flex;
    width: 100%;
    gap: 0.5rem;
  }
  
  .ant-form-item { margin-bottom: 0; }
`

export const EditDrawerSaveButton = styled(Button)`
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background: #009966;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #FFFFFF;
  transition: .5s all ease;
  height: 3rem;
  
  &:hover, &:active, &:focus { 
    background: #22AB5B;
    color: #FFFFFF;
  }
`

export const EditTaskDrawer = styled(EditDrawerStyled)`
  @media (max-width: 1024px) {
    .ant-drawer-body {
      margin-top: 0!important;
      margin-bottom: 0!important;
    }
  }
`

export const FormStyled = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const CircleIconGreen = styled(CircleIcon)`
  width: 3.25rem;
  height: 3.25rem;
  border: none;
  background: #00B74F;
  &:hover { background: #00B74F; } 
`

export const EmptyRecordsList = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0.625rem;
  padding: 3rem;
  
  @media (max-width: 1024px) {
    padding: 1.5rem;
  }
`;

export const EmptyRecordsHeader = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #21272B;
  text-align: center;
  max-width: 30vw;
  
  @media (max-width: 1024px) {
   max-width: 50vw;
  }
  
  @media (max-width: 480px) {
   max-width: 80vw;
  }
`

export const EmptyRecordsDescription = styled.div`
  padding: 1rem;
  font-weight: 400;
  font-size: 1rem;
  color: #6B7185;
  text-align: center;
  max-width: 30vw;
  
  &.planner {
    display: flex;
    gap: 0.5rem; 
    align-items: center;
    max-width: 80vw;
  }
  
  @media (max-width: 1024px) {
   max-width: 50vw;
  }
  
  @media (max-width: 768px) {
    &.planner { 
      max-width: 95vw;
      align-items: stretch;
      flex-direction: column;
    }
  }
  
  @media (max-width: 480px) {
   max-width: 80vw;
  }
`

export const AddRecordButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  
  border: 1px solid #dae0e6;
  border-radius: 6px;
  font-weight: 600;
  color: #21272b;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 4.2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  
  @media (max-width: 1024px) {
    margin: 0 1rem;
  }
  
  &.no-row-gap {
    row-gap: 0rem!important;
  }
`

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  align-items: center;
  
  @media (max-width: 768px) {
    row-gap: 0.5rem;
    
    &.stack-mobile { 
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1rem;
    }
  }
`

export const HeaderControlsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1.5rem;
  
  &.mini {
    column-gap: 0.5rem;
  }
  
  &.active {
    padding: 1rem;
    background: #FFFFFF;
    border-radius: 16px;
  }
  
  &.mobile { display: none; }
  
  @media (max-width: 768px) {
    &.desktop { display: none; }
    &.mobile { display: flex; }
    &.active { padding: 0.5rem; }
    
    &.active svg path { 
      transform: scale(0.75);
      transform-origin: center;
    }
  }
`

export const PageTitle = styled.div`
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3.5rem;
  color: #002A43;
  
  @media (max-width: 768px) {
    font-size: 1.625rem;
    line-height: 1.75rem;
  }
`

export const PageSubtitle = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #666F7A;
  
  @media (max-width: 768px) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }  
`

export const AddButtonStyled = styled(Button)`
  height: 3rem;
  min-width: 7rem;
  border-radius: 8px!important;
  font-weight: 600;
  background-color: #2a80e1;
  border-color: #2a80e1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: white;
  font-size: 1rem;
  
  @media (max-width: 1024px) {
    min-width: 3rem;
    min-height: 3rem;
  }
  
  @media (max-width: 768px) {
    &.spaces {
      font-size: 0.75rem;
      line-height: 1.125rem;
      padding: 0.5rem 0.75rem!important;
      height: 2.125rem!important;
    }
  }
`;

export const PageInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3.5rem;
  
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
  }
`

export const PageInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  .column-header {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    color: #6B7185;
  }
  
  .column-content {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #21272B;
  }
`

export const AlertStyled = styled(Alert)`
  background: white;
  border-radius: 10px;
  padding: 1.25rem;
  margin: 1rem 0!important;
  border: none;
`

export const fileUploadTooltip = (type: string) => {
  const fileEmail = process.env.REACT_APP_FILE_EMAIL
  const invoiceEmail = process.env.REACT_APP_INVOICE_EMAIL

  return <Tooltip
    placement="bottom"
    color={"#2a80e1"}
    title={<span>You can forward {type}s that you want to save in Livlet as attachments in emails sent to <a
      style={{color: "white", textDecoration: "underline"}} href={`mailto:${type === "file" ? fileEmail : invoiceEmail}`}>
        {type === "file" ? fileEmail : invoiceEmail}</a>.
        <br/>See details in <a
        style={{color: "white", textDecoration: "underline"}}
        href={'https://www.livlet.com/faq?question=files'} target={"_blank"} rel={"noreferrer"}>FAQ</a>.</span>}>
    <HelpIcon
      className={"header-icon"}
      style={{width: '22px', height: '22px', cursor: "pointer", marginLeft: "0.75rem"}}/>
  </Tooltip>
}

export const fileUploadPopup = (type: string) => {
  const fileEmail = process.env.REACT_APP_FILE_EMAIL
  const invoiceEmail = process.env.REACT_APP_INVOICE_EMAIL

  return <AlertStyled
    message={<div style={{display: "flex", alignItems: "center"}}>
      <BoxMail style={{flexShrink: 0}} />
      <span style={{color: "#6B7185", fontSize: "1rem", lineHeight: "1.5rem", marginLeft: "1.25rem"}}>
          You can forward {type}s that you want to save in Livlet as attachments in emails
          sent to <a href={`mailto:${type === "file" ? fileEmail : invoiceEmail}`}>{type === "file" ? fileEmail : invoiceEmail}</a>.
          <br/>See details in <a href={'https://www.livlet.com/faq?question=files'} target={"_blank"} rel={"noreferrer"}>FAQ</a>.
        </span>
    </div>}
    closable
    closeText={<CloseCircle/>}
    onClose={() => {
      setFileUploadPopupState("hidden")
    }}
  />
}

export const Search = styled(Input)`
  width: 100%;
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
`;

export const PaginationStyled = styled(Pagination)`
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 0 0 10px 10px;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  
  .ant-pagination-item {
    border: 0!important;
    background: white;
  }
  
  .ant-pagination-item-active {
    background: #2A80E1;
    border-radius: 100%;
  }
  
  .ant-pagination-item-active a {
    color: white;
  }
  
  @media (max-width: 1024px) {
    padding-bottom: 0;
    .ant-pagination-simple-pager { height: 2rem; }
    .ant-pagination-simple-pager input { height: 2rem; width: 2.5rem; }
    li { height: 2rem!important;}
    
    &.files-pagination { 
      background: #F0F2F5; 
      padding-bottom: 1rem;
    }
  }
`;

export const PaginationPlanner = styled(PaginationStyled)`
  background: none;
  
  .ant-btn {
    border: 1px solid rgba(0, 0, 0, 0.1)!important;
    border-radius: 16px!important;
    height: 2rem;
    box-shadow: none!important;
    display: none;
  }
  
  .ant-pagination-item {
    border-radius: 100%;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1)!important;
    color: #3E5D58!important;
    font-weight: 800;
    font-size: 0.75rem!important;
    box-shadow: none!important;
  }
  
  .ant-pagination-item-active {
    background: #00CC33;
    border-radius: 100%;
    border: 1px solid #00CC33!important;
    box-shadow: none!important;
  }
`

export const ButtonText = styled.span`
  cursor: pointer;
  font-weight: 600;
  font-size: 0.875rem;
  color: #21272b;
`;

export const paginationButtons = (
  page: number,
  type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
  originalElement: React.ReactElement<HTMLElement>
) => {
  if (type === "prev") {
    return <Button style={{border: "1px solid #DAE0E6", boxSizing: "border-box", borderRadius: "6px",}}>
      <ButtonText>Prev</ButtonText>
    </Button>
  } else if (type === "next") {
    return <Button style={{border: "1px solid #DAE0E6", boxSizing: "border-box", borderRadius: "6px",}}>
      <ButtonText>Next</ButtonText>
    </Button>
  }
  return originalElement;
};

export const SectionLabel = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  color: #21272B;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  &.with-icon {
    justify-content: flex-start;
    gap: 0.5rem;
  }
  
  &.padded { margin-bottom: 1.5rem; }
`

export const ButtonOutline = styled(Button)`
  height: 2.5rem;
  min-width: 7rem;
  border: 1px solid #21272B;
  background-color: #F0F2F5;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #21272B;
`

export const TooltipStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "white",
  color: "#45655E",
  padding: "0.25rem 0.5rem",
  border: "1px solid #45655E",
  borderRadius: "4px",
  boxShadow: "none",
  fontWeight: 400,
  fontSize: "0.75rem",
  lineHeight: "1.125rem",
  marginTop: "-0.5rem"
}

export const CheckboxStyled = styled.div`
  margin: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 2px solid #45655E;
  border-radius: 4px;
  
  &.small { height: 1rem; width: 1rem; }
  
  svg path { fill: white; }
  &.checked svg path { fill: #00B74F; } 
`

export const CheckboxStyledDark = styled.div`
  margin: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid #DAE0E6;
  border-radius: 4px;
  
  &.small { height: 1.125rem; width: 1.125rem; }
  
  &.checked { background: #009966; }

  svg path { fill: white; }
  svg { height: 1rem; width: 0.75rem; }
  &.checked svg path { fill: white; } 
`

const ButtonSmall = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.35rem 0.75rem;
  gap: 0.75rem;
  height: 2rem;
  border-radius: 4px;
  border: none;
  transition: all .5s ease;
  
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  
  @media (max-width: 768px) {
    height: 2.5rem;
  }
`

export const ButtonSmallBlue = styled(ButtonSmall)`
  background: #2A80E1;
  color: white;
  svg path { fill: white; }
  
  &:hover { 
    background: #40A9FF;
    color: white;
  }
  
  @media (max-width: 768px) {
    &.empty-state {
      height: auto;
    }
    height: 2.5rem;
  }
`

export const ButtonSmallGreen = styled(ButtonSmall)`
  background: #009966;
  color: white;
  svg path { fill: white; }
  
  &:hover { 
    background: #22AB5B;
    color: white;
  }
`

export const ButtonSmallWhite = styled(ButtonSmall)`
  background: white;
  color: #2A80E1;
  border: 1px solid #2A80E1;
  svg path { fill: #2A80E1; }
  
  &:hover { 
    color: #40A9FF;
    border: 1px solid #40A9FF;
  }
  
  &:hover svg path { fill: #40A9FF; }
`

export const EmptyStateWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 0.5rem;
  
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #21272B;
  
  &.unwrapped {
    background: #FFFFFF;
    border-radius: 16px;
    border: none;
    padding: 2rem;
  }
  
  @media (max-width: 1024px) {
    &.unwrapped { padding: 1.5rem; }
  }
`

export const IntroductionButton = styled(Button)`
  background: #009966;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 600;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  transition: .5s all ease;
  
  :hover {
    background: #00b74f;
    color: white;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1.5rem;
  
  .stroke path { stroke: #45655E!important; }
  .fill path { fill: #45655E!important; }
`

export const PropertyDrawerTitle = styled.span`
  border: none;
  outline: none;
  background-color: #ebf9ff;
  font-size: 2.5rem;
  line-height: 3rem;
  @media (max-width: 1024px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }  
`;