import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TTag} from "../tags/addNewTag";

export type TGetAllAttachmentsResponse = Array<{
  attachmentId: Guid;
  propertyId: Guid;
  parentId: Guid;
  parentType: string;
  name: string;
  size: number;
  thumbnailPath: string;
  documentPreviewPath: string;
  uploadedDate: string;
  description: string;
  source: string;
  tags: Array<TTag>;
  attachmentLinksCount: number;
  relatedDocumentId: Guid | null;
  duplicateFiles?: Array<Guid>;
}>;

export const getAttachments = (propertyId: Guid, withoutProperty?: boolean | false) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/attachments`+(withoutProperty ? '?withoutProperty=true' : '')
  );

  return apiGet<TGetAllAttachmentsResponse | []>(url);
};
