import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import {TGetUserProfileResponse} from "../../api-wrapper/user/getUserProfile";
import {
  getUserProfileFailureAction,
  getUserProfileInitAction,
  getUserProfileSuccessAction,
  TGetUserProfileActions
} from "../actions/getUserProfile";

export type TUserProfile = {
  fetchingStatus: TFetchingStatus;
  content: TGetUserProfileResponse;
};

const initialState: TUserProfile = {
  content: {
    address: null,
    defaultStartPage: null,
    name: "",
    email: "",
    phoneNumber: null,
    welcomeMessageDismissedTs: null,
    dateCreated: "",
    emailCommunication: false,
    marketingCookies: false,
    previousLogin: "",
    lastLogin: "",
    onboardingInterestSurveyStatus: "",
    mobileWelcomeStatus: "",
    marketingEmails: false,
    handholdingStatus: null,
    contentsStatus: null,
    spacesStatus: null,
    documentsStatus: null,
    showPets: false
    },
  fetchingStatus: TFetchingStatus.Defined,
};

export const userProfileReducer = createReducer<
  TUserProfile,
  TGetUserProfileActions
  >(initialState)
  .handleAction(getUserProfileInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getUserProfileSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getUserProfileFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  )
