import React, {useState} from "react";
import styled from "styled-components";
import {ReactComponent as LinkedSearch} from "../../../images/spaces/LinkedSearch.svg";
import {ReactComponent as FiltersIcon} from "../../../images/planner/FiltersIcon.svg";
import {ReactComponent as FiltersIconActive} from "../../../images/planner/FiltersIconActive.svg";
import {ReactComponent as SortIcon} from "../../../images/planner/SortIcon.svg";
import {SearchStyled} from "../spaces/LinkedItemsHeader";
import {useSelector} from "react-redux";
import {getUpcomingTasksSelector} from "../../selectors";
import ToDoListItem from "./ToDoListItem";
import _ from "lodash";
import moment from "moment";
import {
  PaginationPlanner
} from "../../screens/components";
import {itemRender} from "../../screens/Todo";
import {Checkbox, Collapse, DatePicker, Dropdown, Menu} from "antd";
import {FilterGroupRow, Filters } from "../../screens/styled";
import {CancelButton, CategoryHeader, EditTagsBody, SubcategoryHeader} from "../tags/FilterTagsNew";
import {SortMenuStyled} from "../works/WorksListComp";
import {ReactComponent as CollapseIconMinus} from "../../../images/CollapseIconMinus.svg";
import {ReactComponent as CollapseIconPlus} from "../../../images/CollapseIconPlus.svg";
const { Panel } = Collapse;

type Props = {
  handleReminderAction: (arg1: any, arg2: any) => void;
  handleWorkAction: (arg1: any, arg2: any) => void;
  handleExpenseAction: (arg1: any, arg2: any) => void;
  selectedStartDate: any;
  setSelectedStartDate: (arg: any) => void;
  selectedEndDate: any;
  setSelectedEndDate: (arg: any) => void;
  showRecurring: boolean;
  toggleShowRecurring: (arg: boolean) => void;
  resetSwiping: boolean;
  toggleResetSwiping: (arg: boolean) => void;
}

const ToDoListComp = (props: Props) => {
  const { handleWorkAction, handleReminderAction, handleExpenseAction, selectedStartDate, setSelectedStartDate, selectedEndDate, setSelectedEndDate,
    showRecurring, toggleShowRecurring, resetSwiping, toggleResetSwiping } = props;

  const pageSize = 5;
  const upcomingTasks = useSelector(getUpcomingTasksSelector);

  const [showExpenses, toggleShowExpenses] = useState(false);
  const [showFilters, toggleFilters] = useState(false);
  const [sortingType, setSortingType] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [activeKeys, setActiveKeys] = useState<any>(["visibility", "period"]);

  const areFiltersInUse = selectedStartDate || selectedEndDate || showExpenses || !showRecurring

  const filteredUpcomingTasks = () => upcomingTasks.content.tasks
    .filter(task => task.type !== "expense")
    .filter(task => !_.isEmpty(searchQuery) ? task.title.toLowerCase().includes(searchQuery.toLowerCase()) : true)
    .filter(task => showExpenses ? true : (task.task.upkeepType !== "recurringexpense"))
    .sort((r1, r2) => {
      if (sortingType === "typeSort") {
        let sortingResult = r2.type.localeCompare(r1.type)
        return sortingResult === 0 ? r1.title.toLowerCase().localeCompare(r2.title) : sortingResult
      } else if (sortingType === "dueDateOldest") {
        let sortingResult = moment(r2.date).valueOf() - moment(r1.date).valueOf()
        return sortingResult === 0 ? r1.title.toLowerCase().localeCompare(r2.title) : sortingResult
      } else {
        let sortingResult = moment(r1.date).valueOf() - moment(r2.date).valueOf()
        return sortingResult === 0 ? r1.title.toLowerCase().localeCompare(r2.title) : sortingResult
      }
    })

  const pagedUpcomingTasks = () =>
    filteredUpcomingTasks().filter(
      (currentValue: any, index: any) =>
        index >= (page - 1) * pageSize && index < page * pageSize
    );

  const displayFilters = () => (
    <Filters style={{border: "1px solid #DAE0E6", borderRadius: "16px", padding: "1rem"}}>
      <FilterGroupRow>
        <CategoryHeader>
          Filters <CancelButton onClick={() => {
          areFiltersInUse && setPage(1);
          setSelectedStartDate(undefined);
          setSelectedEndDate(undefined);
          toggleShowRecurring(true);
          toggleShowExpenses(false);
          //refreshUpcomingTasks();
        }}>Clear filters</CancelButton>
        </CategoryHeader>
      </FilterGroupRow>
      <FilterGroupRow style={{flexDirection: "column", gap: "0.5rem", borderBottom: "none", paddingBottom: 0}}>
        <Collapse bordered={false} ghost onChange={(key) => setActiveKeys(key)} defaultActiveKey={activeKeys}>
          <Panel showArrow={false} header={<SubcategoryHeader>
            Visibility
            <div className={"button-wrapper"}>
              {activeKeys.includes("visibility") ? <CollapseIconMinus /> : <CollapseIconPlus />}
            </div>
          </SubcategoryHeader>} key="visibility">
            <EditTagsBody>
              <FilterControls>
            <span className={"control-item"}>
              <Checkbox checked={!showRecurring} onClick={() => {
                toggleShowRecurring(!showRecurring)
                setPage(1)
                toggleResetSwiping(true)
                //refreshUpcomingTasks();
              }}/>
              Show recurrence in list
            </span>
                <span className={"control-item"}>
              <Checkbox checked={showExpenses} onClick={() => {
                toggleShowExpenses(!showExpenses)
                setPage(1)
                toggleResetSwiping(true)
              }}/>
              Show expenses in list
            </span>
              </FilterControls>
            </EditTagsBody>
          </Panel>
          <Panel showArrow={false} header={<SubcategoryHeader style={{marginTop: "1rem"}}>
            Period
            <div className={"button-wrapper"}>
              {activeKeys.includes("period") ? <CollapseIconMinus /> : <CollapseIconPlus />}
            </div>
          </SubcategoryHeader>} key="period">
            <EditTagsBody style={{flexDirection: "column", gap: "0.5rem"}}>
              <DatePicker
                placeholder={"From"}
                className={"planner-date-picker"}
                value={selectedStartDate}
                onChange={(e) => {
                  setSelectedStartDate(e)
                  setPage(1)
                  toggleResetSwiping(true)
                  //refreshUpcomingTasks();
                }}
              />
              <DatePicker
                placeholder={"To"}
                className={"planner-date-picker"}
                value={selectedEndDate}
                onChange={(e) => {
                  setSelectedEndDate(e)
                  setPage(1)
                  toggleResetSwiping(true)
                  //refreshUpcomingTasks();
                }}
              />
            </EditTagsBody>
          </Panel>
        </Collapse>
      </FilterGroupRow>
    </Filters>
  )

  const sortMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      setSortingType("" + key);
    };

    return (
      <SortMenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="typeSort">Type</Menu.Item>
        <Menu.Item key="dueDateOldest">Due date (Newest to oldest)</Menu.Item>
        <Menu.Item key="dueDateNewest">Due date (Oldest to newest)</Menu.Item>
      </SortMenuStyled>
    );
  };

  return <ToDoListWrapper>
    <div className={"wrapper-content"}>
      <ToDoListHeader>
        <span className={"title"}>To-do list</span>
      </ToDoListHeader>

      <SearchWrapper>
        <LocalSearchStyled
          placeholder={"Search..."}
          value={searchQuery}
          onChange={(val) => setSearchQuery(val.target.value)}
          prefix={<LinkedSearch />}
        />
        <IconWrapper onClick={() => toggleFilters(!showFilters)}>
          {areFiltersInUse ? <FiltersIconActive /> : <FiltersIcon/>}
        </IconWrapper>
        <Dropdown overlay={sortMenuDropdown()} placement="bottomCenter" trigger={["click"]}>
          <IconWrapper>
            <SortIcon />
          </IconWrapper>
        </Dropdown>
      </SearchWrapper>
      {showFilters && displayFilters()}

      {pagedUpcomingTasks().length === 0 && <EmptyState>No tasks to display.</EmptyState>}

      {pagedUpcomingTasks().map((task: any) => <ToDoListItem
        item={task}
        handleWorkAction={handleWorkAction}
        handleReminderAction={handleReminderAction}
        handleExpenseAction={handleExpenseAction}
        resetSwiping={resetSwiping}
        toggleResetSwiping={toggleResetSwiping}
      />)}
    </div>

    {filteredUpcomingTasks().length > pageSize && <PaginationPlanner
        className={filteredUpcomingTasks().length < pageSize ? "single-page-pagination" : ""}
        onChange={(page) => {
          setPage(page)
          toggleResetSwiping(true)
        }}
        showSizeChanger={false}
        itemRender={itemRender}
        defaultCurrent={1}
        total={filteredUpcomingTasks().length}
        current={page}
        defaultPageSize={pageSize}
        showLessItems={true}
    />}
  </ToDoListWrapper>
}

const ToDoListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  gap: 1rem;
  background: #FFFFFF;
  border: 2px solid #DAE0E6;
  border-radius: 16px;
  position: relative;
  overflow-x: hidden;
  
  .wrapper-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem; 
  }
  
  @media (max-width: 768px) {
     padding: 0;
     border: none; 
     background: #F0F2F5;
     gap: 0.5rem;
     border-radius: 0;
  }
`

const ToDoListHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
  
  .title {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #21272B;
  }
  
  @media (max-width: 768px) {
     display: none;
  }
`

const FilterControls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  
  .control-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #21272B;
  }
`

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  svg { 
    cursor: pointer;
    flex-shrink: 0;
  }
  overflow-y: hidden;
`

const IconWrapper = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  background: #F5F6FA;
  border-radius: 16px;
  
  @media (max-width: 768px) {
     background: white;
  }
`

const LocalSearchStyled = styled(SearchStyled)`
  @media (max-width: 768px) {
     background: white;
     .ant-input { background: white; }
  }
`

const EmptyState = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #7A7D80;
`


export default ToDoListComp