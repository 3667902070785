import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TForwardExpenseRequest} from "./forwardExpense";

export const forwardExpensePreview = (payload: TForwardExpenseRequest) => {
  const {expenseId} = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/forwardExpense/${expenseId}/preview`
  );

  return apiPost<any, TForwardExpenseRequest>(
    url, payload
  );
};
