import {createUrl} from "../apiCall";
import EnvConfig from "../../config";
import axios from "axios";

export type TResetUpdateRequest = {
  newPassword: string;
};

export type TResetUpdateResponse = {
  timeoutError: boolean,
  captchaError: boolean,
  message: string,
};

export const resetUpdate = async (authToken: string | null, recaptchaToken: string, payload: TResetUpdateRequest) => {
  let url = createUrl(`${EnvConfig.API_URL}/api/user/restorePassword?token=${recaptchaToken}`);

  const uninterceptedAxios = axios.create()

  const auth: any = await uninterceptedAxios.put<TResetUpdateResponse, TResetUpdateRequest>(url,
    {newPassword: payload.newPassword}, {
      headers: {
        Authorization: `Bearer:${authToken}`
      }})

  return auth;
};