import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TForwardInventoryRequest} from "./forwardInventory";

export const forwardInventoryPreview = (payload: TForwardInventoryRequest) => {
  const {inventoryId} = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/forwardInventory/${inventoryId}/preview`
  );

  return apiPost<any, TForwardInventoryRequest>(
    url, payload
  );
};
