import React, {useState} from "react";
import {Collapse, Tooltip} from 'antd';
import styled from "styled-components";

import { ReactComponent as WorksIcon } from "../../../images/spaces/SpaceLinkWorks.svg";
import { ReactComponent as ContactsIcon } from "../../../images/spaces/SpaceLinkContacts.svg";
import { ReactComponent as ContentsIcon } from "../../../images/spaces/SpaceLinkContents.svg";
import { ReactComponent as FilesIcon } from "../../../images/spaces/SpaceLinkFiles.svg";
import { ReactComponent as NotesIcon } from "../../../images/spaces/SpaceLinkNotes.svg";
import { ReactComponent as CostsIcon } from "../../../images/spaces/SpaceLinkCosts.svg";

import { ReactComponent as ViewAllArrow } from "../../../images/spaces/ViewAllArrow.svg";
import { ReactComponent as ViewItemArrow } from "../../../images/spaces/SpaceArrow.svg";
import {useSelector} from "react-redux";
import {getExpenseTypesSelector, getPropertyPreferencesSelector} from "../../selectors";
import {emptyGuid, getCurrencySign} from "../../screens/helpers";
import {ReactComponent as Appliances} from "../../../images/spaces/SpaceLinkContentAppliances.svg";
import {ReactComponent as Electrical} from "../../../images/spaces/SpaceLinkContentElectronics.svg";
import {ReactComponent as ToolsAndMachinery} from "../../../images/spaces/SpaceLinkContentTools.svg";
import {ReactComponent as Furniture} from "../../../images/spaces/SpaceLinkContentFurniture.svg";
import {ReactComponent as Systems} from "../../../images/spaces/SpaceLinkContentSystems.svg";
import {ReactComponent as Other} from "../../../images/spaces/SpaceLinkContentOther.svg";
import {TooltipStyle} from "../../screens/components";
import {maintenanceIconDispenser} from "../maintenances/MaintenanceListComp";

const { Panel } = Collapse;

type Props = {
  space: any;
  openViewSpaceDrawer: () => void;

  toggleViewWorkDrawer: (arg: boolean) => void;
  setViewWorkId: (arg: any) => void;
  toggleWorkDrawer: (arg: boolean) => void;
  setEditWorkData: (arg: any) => void;
  toggleExpenseDrawer: (arg: boolean) => void;
  setEditExpenseData: (arg: any) => void;
  toggleInventoryDrawer: (arg: boolean) => void;
  setEditInventoryData: (arg: any) => void;
  toggleContactDrawer: (arg: boolean) => void;
  setEditContactData: (arg: any) => void;
  toggleNoteDrawer: (arg: boolean) => void;
  setEditNoteData: (arg: any) => void;
  toggleViewFileDrawer: (arg: boolean) => void;
  setAttachmentId: (arg: any) => void;
}

const SpaceContents = (props: Props) => {
  const {space, openViewSpaceDrawer, toggleContactDrawer, toggleExpenseDrawer, toggleViewFileDrawer, setAttachmentId, setViewWorkId, toggleViewWorkDrawer,
    toggleInventoryDrawer, toggleNoteDrawer, setEditContactData, setEditExpenseData, setEditInventoryData, setEditNoteData} = props;

  const expenseTypes = useSelector(getExpenseTypesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [openedPanel, setOpenedPanel] = useState("");

  const openInDrawer = (type: string, item: any) => {
    switch (type) {
      case "work":
        toggleViewWorkDrawer(true);
        setViewWorkId(item.workId);
        break;
      case "cost":
        toggleExpenseDrawer(true);
        setEditExpenseData({data: item});
        break;
      case "content":
        toggleInventoryDrawer(true);
        setEditInventoryData({data: item});
        break;
      case "contact":
        toggleContactDrawer(true);
        setEditContactData({data: item});
        break;
      case "file":
        toggleViewFileDrawer(true);
        setAttachmentId(item.attachmentId);
        break;
      case "note":
        toggleNoteDrawer(true);
        setEditNoteData({workName: "", workId: emptyGuid, workNotes: item});
        break;
      default:
        break;
    }
  }

  const iconDispenser = (type: string) => {
    switch (type) {
      case "work":
        return <WorksIcon style={{flexShrink: 0}} />
      case "cost":
        return <CostsIcon style={{flexShrink: 0}} />
      case "content":
        return <ContentsIcon style={{flexShrink: 0}} />
      case "contact":
        return <ContactsIcon style={{flexShrink: 0}} />
      case "file":
        return <FilesIcon style={{flexShrink: 0}} />
      case "note":
        return <NotesIcon style={{flexShrink: 0}} />
      default:
        return null
    }
  }

  const nameDispenser = (type: string) => {
    switch (type) {
      case "work":
        return "Project"
      case "cost":
        return "Expense"
      case "content":
        return "Content"
      case "contact":
        return "Contact"
      case "file":
        return "Document"
      case "note":
        return "Note"
      case "maintenance":
        return "Upkeep & Maintenance Item"
      default:
        return ""
    }
  }

  const contentIconDispenser = (type: string) => {
    if (type === "Appliances") return <Appliances style={{flexShrink: 0}} />;
    else if (type === "Electronics") return <Electrical style={{flexShrink: 0}} />;
    else if (type === "ToolsAndMachinery") return <ToolsAndMachinery style={{flexShrink: 0}} />;
    else if (type === "Furniture") return <Furniture style={{flexShrink: 0}} />;
    else if (type === "Systems") return <Systems style={{flexShrink: 0}} />;
    else return <Other style={{flexShrink: 0}} />
  }

  const getItemType = (panelType: string, item: any) => {
    switch (panelType.toLowerCase()) {
      case "work":
        return item["type"]
      case "cost":
        return expenseTypes.content[item["type"]]?.name
      case "content":
        return item["inventoryType"]
      case "contact":
        return item["contactType"]
      case "maintenance":
        return item["category"]
      default:
        return ""
    }
  }

  // @ts-ignore
  const expensesTotalCost = space.expenses.reduce((sumOfExpenses: number, { totalCost }) => sumOfExpenses + totalCost, 0)

  const panel = (panelType: string, contents: Array<any>, totalCount: number) => {
    return <PanelStyled
      className={panelType.toLowerCase()}
      key={panelType}
      showArrow={false}
      header={<Tooltip title={openedPanel === panelType ? "Collapse" : "Expand"} overlayClassName={"handholding"} placement={"bottom"}
        overlayInnerStyle={TooltipStyle} mouseEnterDelay={0.5}>
        <PanelHeader onClick={() => openedPanel === panelType ? setOpenedPanel("") : setOpenedPanel(panelType)}>
          <div className={"panel-title"}>
            <PanelDot className={panelType.toLowerCase()} /> {totalCount} {nameDispenser(panelType)}{totalCount > 1 && "s"}
          </div>
          {panelType === "cost" && expensesTotalCost > 0 && <TotalCostLabel>
            {getCurrencySign(propertyPreferences.preferredCurrency)}{Math.round(expensesTotalCost * 100) / 100}
          </TotalCostLabel>}
        </PanelHeader>
      </Tooltip>}>
      {contents.length && contents.map((item: any) =>
      <Tooltip title={panelType === "file" ? "Preview" : "Open"} overlayClassName={"handholding"} placement={"bottom"}
               overlayInnerStyle={{...TooltipStyle, marginTop: "-1.5rem"}} mouseEnterDelay={0.5}>
        <PanelItem onClick={() => openInDrawer(panelType, item)}>
          {panelType === "content" ? contentIconDispenser(getItemType(panelType, item)) :
            panelType === "maintenance" ? maintenanceIconDispenser(item.category) : iconDispenser(panelType)}
          <PanelItemWrapper>
            <span style={{wordBreak: "break-word"}}>{item.title ?? item.name}</span>
            {getItemType(panelType, item) && <PanelItemType>{getItemType(panelType, item)}</PanelItemType>}
          </PanelItemWrapper>
          <ViewItemArrow style={{flexShrink: 0}} />
        </PanelItem>
      </Tooltip>)}
      <ViewAllButton onClick={() => openViewSpaceDrawer()}>
        View all <ViewAllArrow />
      </ViewAllButton>
    </PanelStyled>
  }

  return <CollapseStyled accordion ghost>
    {space.works.length > 0 && panel("work", space.works.slice(0, 3), space.works.length)}
    {space.contacts.length > 0 && panel("contact", space.contacts.slice(0, 3), space.contacts.length)}
    {space.inventories.length > 0 && panel("content", space.inventories.slice(0, 3), space.inventories.length)}
    {space.attachments.length > 0 && panel("file", space.attachments.slice(0, 3), space.attachments.length)}
    {space.spaceNotes.length > 0 && panel("note", space.spaceNotes.slice(0, 3), space.spaceNotes.length)}
    {space.expenses.length > 0 && panel("cost", space.expenses.slice(0, 3), space.expenses.length)}
    {space.upkeeps && space.upkeeps.length > 0 && panel("maintenance", space.upkeeps.slice(0, 3), space.upkeeps.length)}
  </CollapseStyled>
}

export const CollapseStyled = styled(Collapse)`
  padding: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  row-gap: 0.375rem;

  & .ant-collapse-header {
    padding: 0.75rem!important;
  }
`

export const PanelStyled = styled(Panel)`
  .ant-collapse-content-box {
    margin: 0 0.75rem;
    border-top: 1px solid white;
    padding: 1rem 0!important;
    display: flex;
    flex-direction: column;
    row-gap: 0.125rem;
  }
  
  border-radius: 4px!important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0);
  transition: .1s all ease;
  
  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    transition: .1s all ease;
  }
  
  &:hover .panel-title { font-weight: 600; }
  
  &.work { background: #EBF9FF; }
  &.contact { background: #FFEDED; }
  &.content { background: #FAEBFF; }
  &.file { background: #EFFDF7; }
  &.note { background: #FEF9EA; }
  &.cost { background: rgba(66, 222, 232, 0.1); }
  &.task { background: #C9ECFB; }
  &.reminder { background: #FAF9D3; }
  &.recurringExpense { background: #FAC9E1; }
  &.maintenance { background: rgba(0, 153, 102, 0.1); }
`

const PanelHeader = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #666F7A;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.375rem;
  
  .panel-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.375rem;
  }
`

export const PanelDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  
  &.work { background: #2A80E1; }
  &.contact { background: #FF4949; }
  &.content { background: #902AE1; }
  &.file { background: #67C18B; }
  &.note { background: #EFC35D; }
  &.cost { background: #42DEE8; }
  &.maintenance { background: #009966; }
`

export const PanelItem = styled.div`
  padding: 0.25rem;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: #45655E;
`

export const PanelItemWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.5rem;
  row-gap: 0.25rem;
  width: 100%;
  flex-wrap: wrap;
`

export const PanelItemType = styled.div`
  padding: 0.125rem 0.375rem;
  background: white;
  border: 1px solid #7CC9B7;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #45655E;
`

export const ViewAllButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2979FF;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.125rem;
  
`

const TotalCostLabel = styled.div`
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 1rem;
  color: #666F7A;
`

export default SpaceContents