import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import {
  getExpenseTypesFailureAction,
  getExpenseTypesInitAction,
  getExpenseTypesSuccessAction,
  TGetExpenseTypesActions,
} from "../actions/expenses";
import {TGetExpenseTypesResponse} from "../../api-wrapper/expenses/getExpenseTypes";
import {expenseTypesInitial} from "../components/helper";

export type TExpenseTypes = {
  fetchingStatus: TFetchingStatus;
  content: TGetExpenseTypesResponse;
};

const initialState: TExpenseTypes = {
  content: expenseTypesInitial,
  fetchingStatus: TFetchingStatus.Defined,
};

export const expenseTypesReducer = createReducer<TExpenseTypes, TGetExpenseTypesActions>(
  initialState
)
  .handleAction(getExpenseTypesInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getExpenseTypesSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getExpenseTypesFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
