import React from "react";
import {ReactComponent as CrossIcon} from "../../../../images/CrossIcon.svg";
import {ControlBox, isCustom} from "../MaintenanceListItemNew";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../../selectors";
import {Modal, notification} from "antd";
import {removeMaintenance} from "../../../../api-wrapper/maintenance/removeMaintenance";
import {getAllOnboardingMaintenances} from "../../../actions/onboardingMaintenances";
import {toggleNotApplicable} from "../../../../api-wrapper/maintenance/toggleNotApplicable";
import {showApplicableNotification} from "./MaintenanceMoreMenu";

type Props = {
  item: any;
  refreshParent?: any;
}

const MaintenanceRemove = (props: Props) => {
  const { item, refreshParent } = props;
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;

  const removeItem = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        removeMaintenance(item.propertyId, item.id).then(() => {
          dispatch(getAllOnboardingMaintenances(propertyId))
          refreshParent && refreshParent()
        });
      }
    });
  }

  const setAsNonApplicable = () => {
    if (item.subscribed) {
      notification.warning({
        message: "Cannot remove",
        description: "You cannot remove an item if you are subscribed to it",
        duration: 5
      });

      return;
    }
    toggleNotApplicable({
      propertyId,
      maintenanceId: item.id,
      applicable: !item.notApplicable
    }).then(() => {
      showApplicableNotification(item.notApplicable, item.title)
      dispatch(getAllOnboardingMaintenances(propertyId))
      refreshParent && refreshParent()
    })
  }

  return <ControlBox onClick={() => isCustom(item) ? removeItem() : setAsNonApplicable()}>
    <CrossIcon style={{flexShrink: 0}} />
  </ControlBox>
}

export default MaintenanceRemove