import React from "react";
import {EditableValueWrapper} from "./MaintenanceCost";
import styled from "styled-components";
import {DatePicker} from "antd";
import {ReactComponent as MaintenanceCalendar} from "../../../../images/MaintenanceCalendar.svg";
import moment from "moment";
import {disabledDate} from "../../helper";
import {useSelector} from "react-redux";
import {getPropertyPreferencesSelector} from "../../../selectors";

type Props = {
  startDate: string;
  setStartDate: (arg: string) => void;
}

const MaintenanceStartDate = (props: Props) => {
  const {startDate, setStartDate} = props;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  return <EditableValueWrapper style={{maxWidth: "8.875rem"}}>
    Start Date *
    <FakeInputField>
      <CalendarWrapper>
        <DatePickerStyled
          format={propertyPreferences.preferredDateFormat}
          value={startDate ? moment(startDate) : moment()}
          allowClear={false}
          inputReadOnly={true}
          disabledDate={disabledDate}
          onChange={(event) => {
            // @ts-ignore
            let newDate = event ? moment(event._d) : moment()
            setStartDate(newDate.format("YYYY-MM-DD"))
          }}
        />
        <MaintenanceCalendar style={{flexShrink: 0, marginTop: "0.125rem"}} />
      </CalendarWrapper>
    </FakeInputField>
  </EditableValueWrapper>
}

export const FakeInputField = styled.div`
  width: 100%;
  height: 32px; 
  display: flex; 
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 6px;
  border: 1px solid rgba(42, 128, 225, 0.5);
  padding: 4px;
`

const CalendarWrapper = styled.div`
  display: flex;
  width: 8rem;
  column-gap: 0.5rem;
`

const DatePickerStyled = styled(DatePicker)`
  border: 0;
  padding: 0!important;
  
  .ant-picker-input input { 
    color: #45655E!important;
    font-size: 1rem;
    line-height: 1.5rem;
   }
  .anticon { display: none; }
`

export default MaintenanceStartDate