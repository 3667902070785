import React, {useState} from "react";
import moment from "moment";
import {Dropdown} from "antd";
import {ReactComponent as CalendarAddNew} from "../../../images/dashboard-calendar/CalendarAddNew.svg";
import styled from "styled-components";
import {AddCalendarItem} from "./UpcomingTaskCalendar";
import CalendarItem from "./CalendarItem";
import {Droppable} from "react-beautiful-dnd";

type Props = {
  period: any;
  columnId: any;
  elementId: number;
  monthlyView: boolean;
  handleReminderAction: (arg1: any, arg2: any) => void;
  handleWorkAction: (arg1: any, arg2: any) => void;
  handleExpenseAction: (arg1: any, arg2: any) => void;
  addNewItemDropdown: any;
  showExpenseTasks: boolean;
}

const CalendarColumn = (props: Props) => {
  const {period, columnId, elementId, handleReminderAction, monthlyView, addNewItemDropdown,
    showExpenseTasks, handleWorkAction, handleExpenseAction} = props;

  const [expanded, toggleExpanded] = useState(false);

  const columnLength = period.tasks.filter((task: any) => showExpenseTasks ? true :
    !(task.task.workType !== null && task.task.workType?.name === "Expenses") && task.type !== "expense").length

  const getColumnHeader = (period: any, monthlyView: boolean) => {
    if (monthlyView) {
      return <>{moment(period.startDate).format("MMM YYYY")}</>
    } else {
      return <>{moment(period.startDate).format("D MMM")} - {moment(period.endDate).format("D MMM")}</>
    }
  }

  return <CalendarColumnStyled>
    <CalendarColumnHeader>
      {getColumnHeader(period, monthlyView)}
    </CalendarColumnHeader>
    <Droppable droppableId={columnId} key={columnId}>
    {(provided: any, snapshot: any) => {
      return <CalendarDroppableArea
        {...provided.droppableProps}
        ref={provided.innerRef}
        style={{
          background: snapshot.isDraggingOver ? "#f7faff" : "white",
          borderRadius: "16px"
        }}
        >
        {period.tasks.filter((task: any) => showExpenseTasks ? true :
          !(task.task.workType !== null && task.task.workType?.name === "Expenses") && task.type !== "expense")
          .slice(0, columnLength > 7 ? (expanded ? columnLength : (elementId === 0 ? 6 : 7)) : 7)
          .map((task: any, index: any) =>
            <CalendarItem
            task={task}
            index={index}
            handleExpenseAction={handleExpenseAction}
            handleWorkAction={handleWorkAction}
            handleReminderAction={handleReminderAction}
          />
          )}
        {elementId === 0 && <Dropdown placement="bottomLeft" overlay={addNewItemDropdown} trigger={["click"]}>
            <AddCalendarItem>
                <div style={{display: "flex", flexShrink: 0}}><CalendarAddNew style={{marginRight: "0.375rem"}} /></div>
                Add an event
            </AddCalendarItem>
        </Dropdown>}
        {columnLength > 7 && <AddCalendarItem onClick={() => toggleExpanded(!expanded)}>{expanded ? "Show less" : "Show all"}</AddCalendarItem>}
        {provided.placeholder}
      </CalendarDroppableArea>
    }}
  </Droppable></CalendarColumnStyled>
}

const CalendarColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #DAE0E6;
  border-radius: 16px;
  background: none;
  
  @media (max-width: 700px) {
    width: 100%;
    min-height: auto;
  }
`

const CalendarDroppableArea = styled.div`
  padding: 0.75rem 0.625rem;
  height: 100%;
`

const CalendarColumnHeader = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #21272B;
  font-weight: 400;
  text-align: center;
  margin-top: 0.75rem;
`

export default CalendarColumn