import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {
  getTenants,
  TGetTenantsResponse,
} from "../../api-wrapper/tenants/getTenants";
import {Guid} from "guid-typescript";

export const getTenantsInitAction = createAction(
  "Tenants/GET_Tenants_INIT"
)();
export const getTenantsSuccessAction = createAction(
  "Tenants/GET_Tenants_SUCCESS"
)<TGetTenantsResponse>();
export const getTenantsFailureAction = createAction(
  "Tenants/GET_Tenants_FAILURE"
)();

export type TGetTenantsInitAction = ActionType<typeof getTenantsInitAction>;
export type TGetTenantsSuccessAction = ActionType<
  typeof getTenantsSuccessAction
>;
export type TGetTenantsFailureAction = ActionType<
  typeof getTenantsFailureAction
>;

export type TGetTenantsActions =
  | TGetTenantsInitAction
  | TGetTenantsSuccessAction
  | TGetTenantsFailureAction;

export type TGetTenantsThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetTenantsActions>;

export const getAllTenants: TGetTenantsThunkActionType = (
  propertyId: Guid
) => (dispatch: (action: Action | Promise<Action>) => any) => {
  dispatch(getTenantsInitAction());
  return getTenants(propertyId)
    .then((payload) => {
      return dispatch(getTenantsSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getTenantsFailureAction()));
};
