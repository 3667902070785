import React, {ReactNode, useState} from "react";
import {useSelector} from "react-redux";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import {Button, Checkbox, Drawer, List, Menu, Popover, Skeleton} from "antd";
import _ from "lodash";
import {
  getAttachmentsSelector, getPropertyPreferencesSelector,
} from "../../selectors";
import {ReactComponent as SearchIcon} from "../../../images/SearchGray.svg";
import {ReactComponent as FilterIcon} from "../../../images/Filters.svg";
import {ReactComponent as SortIcon} from "../../../images/Sort.svg";

import {
  ButtonSort,
  ButtonText,
  FilterGroupHeader,
  FilterGroupList,
  FilterGroupRowNotes,
  FilterGroupWrapper,
  Filters,
  ListItemStyled,
  PaginationStyled,
  Search,
  SearchWrapper,
  ShowFiltersButton,
} from "../../screens/styled";
import styled from "styled-components";
import {ReactComponent as DotIcon} from "../../../images/DotForFilters.svg";
import {niceBytes} from "../helper";
import {fileTypeIcon} from "../../screens/Files";
import {Guid} from "guid-typescript";
import {ReactComponent as InventoriesIcon} from "../../../images/InventoryYellow.svg";
import {CloseButton, SaveButtonWrapper} from "../works/WorkDrawer";
import { ReactComponent as CrossIcon } from "../../../images/Cross.svg";

const moment = require("moment");

type Props = {
  selectedFiles: Set<Guid>;
  setSelectedFiles: (arg01: Set<Guid>) => void;
  alreadyAttachedFiles: Array<any>;
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  icon: ReactNode;
  drawerTitle: string;
  children: React.ReactNode;
  isSingleFileAttachment?: boolean;
}

const AttachmentsOnDrawer = (props: Props) => {
  const {
    selectedFiles,
    setSelectedFiles,
    alreadyAttachedFiles,
    isOpen,
    toggleDrawerOpen,
    icon,
    drawerTitle,
    children,
    isSingleFileAttachment
} = props;
  const attachments = useSelector(getAttachmentsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [dataTypes, setDataTypes] = useState<Array<string | null>>([]);
  const [sortMenuPopoverVisible, setSortMenuPopoverVisible] = useState(false);

  const [sort, setSort] = useState("newestToOldest");
  const [search, setSearch] = useState("");
  const [showFilters, setShowFilters] = useState(false);

  const itemRender = (
    page: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
    originalElement: React.ReactElement<HTMLElement>
  ) => {
    if (type === "prev") {
      return (
        <Button
          style={{
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "6px",
          }}
        >
          <ButtonText>Prev</ButtonText>
        </Button>
      );
    } else if (type === "next") {
      return (
        <Button
          style={{
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "6px",
          }}
        >
          <ButtonText>Next</ButtonText>
        </Button>
      );
    }

    return originalElement;
  };

  const parseToLowerCase = (el: string) => el.toLowerCase();

  const filteredAttachments = () => {
    let atts = attachments.content.filter((contentEl) => {
      return !_.isEmpty(search)
        ? (!_.isNil(contentEl.name) &&
          _.includes(
            parseToLowerCase(contentEl.name),
            parseToLowerCase(search)
          ))
        : true;
    });

    if (isSingleFileAttachment) {
      atts = attachments.content.filter((attachment) => !attachment.relatedDocumentId);
    }

    if (!_.isEmpty(dataTypes)) {
      atts = attachments.content.filter((attachment) => _.includes(dataTypes, attachment.parentType));
    }

    return sort === "newestToOldest"
      ? atts.sort(
        (n1, n2) =>
          moment(n2.uploadedDate).valueOf() -
          moment(n1.uploadedDate).valueOf()
      )
      : sort === "oldestToNewest"
        ? atts.sort(
          (n1, n2) =>
            moment(n1.uploadedDate).valueOf() -
            moment(n2.uploadedDate).valueOf()
        )
        : atts;

  };

  const getAttachments = () =>
    filteredAttachments().filter(
      (currentValue, index) => index >= (page - 1) * pageSize && index < page * pageSize
    );

  const sortMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const {key} = event;
      setSortMenuPopoverVisible(false);
      setSort("" + key);
    };

    return (
      <SortMenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="newestToOldest">Newest to oldest</Menu.Item>
        <Menu.Item key="oldestToNewest">Oldest to newest</Menu.Item>
      </SortMenuStyled>
    );
  };

  const setDataType = (typeChecked: string | null) => {
    setPage(1);
    if (dataTypes.filter((dt) => dt === typeChecked).length > 0) {
      setDataTypes(dataTypes.filter((dt) => dt !== typeChecked));
    } else {
      setDataTypes([...dataTypes, typeChecked]);
    }
  };

  const isDataTypeSelected = (type: string | null) => {
    return dataTypes.filter((dt) => dt === type).length > 0;
  };

  const globalAttachmentsLength = filteredAttachments().filter((attachment) =>
    _.isNil(attachment.parentType)
  ).length;

  const expenseAttachmentsLength = filteredAttachments().filter(
    (attachment) => attachment.parentType === "expense"
  ).length;

  const workAttachmentsLength = filteredAttachments().filter(
    (attachment) => attachment.parentType === "work"
  ).length;

  const contactAttachmentsLength = filteredAttachments().filter(
    (attachment) => attachment.parentType === "contact"
  ).length;

  const inventoryAttachmentsLength = filteredAttachments().filter(
    (attachment) => attachment.parentType === "inventory"
  ).length;

  const isFileAreadyAttached = (attachmentId: Guid) =>
    alreadyAttachedFiles.filter((attachment) => attachment.attachmentId === attachmentId).length > 0

  const selectFile = (attachmentId: Guid) => {
    if (selectedFiles.has(attachmentId)) {
      selectedFiles.delete(attachmentId)
    } else {
      selectedFiles.add(attachmentId)
    }
    setSelectedFiles(new Set(selectedFiles));
  }

  const displayFilters = () => (
    <Filters style={{minHeight: "7rem"}}>
      <FilterGroupRowNotes>
        <FilterGroupWrapper style={{width: "100%"}}>
          <FilterGroupHeader>Content type</FilterGroupHeader>
          <FilterGroupList>
            <FilterNoteElements>
              {globalAttachmentsLength === 0 ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true}/>
                  Global (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType(null)}
                    id={"globalFilter"}
                    checked={isDataTypeSelected(null)}
                  />
                  <label htmlFor={"globalFilter"}>
                    Global ({globalAttachmentsLength})
                  </label>
                </li>
              )}

              {expenseAttachmentsLength === 0 ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true}/>
                  Expense (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("expense")}
                    id={"expenseFilter"}
                    checked={isDataTypeSelected("expense")}
                  />
                  <label htmlFor={"expenseFilter"}>
                    Expense ({expenseAttachmentsLength})
                  </label>
                </li>
              )}

              {workAttachmentsLength === 0 ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true}/>
                  Project (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("work")}
                    id={"workFilters"}
                    checked={isDataTypeSelected("work")}
                  />
                  <label htmlFor={"workFilters"}>
                    Project ({workAttachmentsLength})
                  </label>
                </li>
              )}

              {contactAttachmentsLength === 0 ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true}/>
                  Contact (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("contact")}
                    id={"contactFilter"}
                    checked={isDataTypeSelected("contact")}
                  />
                  <label htmlFor={"contactFilter"}>
                    Contact ({contactAttachmentsLength})
                  </label>
                </li>
              )}

              {inventoryAttachmentsLength === 0 ? (
                <li className="disabled">
                  <input type={"checkbox"} disabled={true}/>
                  Inventory (0)
                </li>
              ) : (
                <li>
                  <input
                    type={"checkbox"}
                    onClick={() => setDataType("inventory")}
                    id={"inventoryFilter"}
                    checked={isDataTypeSelected("inventory")}
                  />
                  <label htmlFor={"inventoryFilter"}>
                    Inventory ({inventoryAttachmentsLength})
                  </label>
                </li>
              )}
            </FilterNoteElements>
          </FilterGroupList>
        </FilterGroupWrapper>
      </FilterGroupRowNotes>
    </Filters>
  );

  return (
    <Drawer
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => {
        setSelectedFiles(new Set());
        toggleDrawerOpen && toggleDrawerOpen(false);
      }}
      headerStyle={{
        backgroundColor: "#ebf9ff",
        margin: 0,
        padding: "2rem 2rem",
      }}
      title={
        <>
          <section style={{ display: "flex", justifyContent: "space-between" }}>
            <IconWrapper>
              {icon ? icon : <InventoriesIcon />}{" "}
              <span style={{ paddingRight: "0.25rem" }}>{drawerTitle}</span>
            </IconWrapper>
            <CloseButton
              size={"large"}
              onClick={() => {
                setSelectedFiles(new Set());
                toggleDrawerOpen && toggleDrawerOpen(false);
              }}
            >
              {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
            </CloseButton>
          </section>
          <Title>Add an attachment</Title>
        </>
      }
    >
    <section>
      <SearchWrapper style={{padding: 0, marginLeft: 0}}>
        <Search
          size={"large"}
          value={search}
          onChange={(val: any) => setSearch(val.target.value)}
          placeholder={"Search files..."}
          suffix={<SearchIcon/>}
          style={{}}
        />
        <ShowFiltersButton
          size={"large"}
          onClick={() => setShowFilters(!showFilters)}
          icon={<FilterIcon/>}
        >
          {" "}
          {!_.isEmpty(dataTypes) && (
            <DotIcon
              style={{
                position: "absolute",
                top: "0.5rem",
                left: "1.8rem",
              }}
            />
          )}
          <span style={{paddingLeft: "0.5rem"}}>
                    {showFilters ? "Hide" : "Show"} filters
                  </span>
        </ShowFiltersButton>{" "}
        <Popover
          content={sortMenuDropdown()}
          className={"sortMenuNotes"}
          visible={sortMenuPopoverVisible}
          placement="bottom"
          onVisibleChange={setSortMenuPopoverVisible}
          trigger={["click"]}
        >
          <ButtonSort
            size={"large"}
            icon={<SortIcon/>}
            onClick={() =>
              setSortMenuPopoverVisible(!sortMenuPopoverVisible)
            }
          />
        </Popover>
      </SearchWrapper>
      {showFilters && displayFilters()}
      <div style={{fontSize:"1.125rem", fontWeight: 600}}>
        {selectedFiles.size === 0 && filteredAttachments().length+" result"+(filteredAttachments().length !== 1 ? "s":"")}
        {selectedFiles.size !== 0 && selectedFiles.size+" file"+(selectedFiles.size > 1 ? "s":"")+" selected"}
      </div>
      <List
        style={{
          width: "100%",
          alignItems: "center",
        }}
        className="no-rounded-list"
        itemLayout="horizontal"
        dataSource={getAttachments()}
        renderItem={(item) => (
          <ListItemStyledAttachments
            onClick={() => selectFile(item.attachmentId)}
            actions={[
              <Checkbox
                checked={selectedFiles.has(item.attachmentId) || isFileAreadyAttached(item.attachmentId)}
                disabled={isSingleFileAttachment ? selectedFiles.size > 0
                  && !selectedFiles.has(item.attachmentId) : isFileAreadyAttached(item.attachmentId)}
              />
            ]}>
            <Skeleton loading={false} title={false} active>
              <List.Item.Meta
                description={
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    {(_.isNil(item.thumbnailPath) || _.isEmpty(item.thumbnailPath))
                      ? <div>{fileTypeIcon(item)}</div>
                      : <img alt="" src={item.thumbnailPath}
                             style={{width: "3.5rem", height: "3.5rem", borderRadius: "6px"}} onError={(e) => {
                        // @ts-ignore
                        e.target.onError = null;
                        // @ts-ignore
                        e.target.src = "/images/file.png"
                      }}/>
                    }
                    <div style={{marginLeft: "1rem", width: "100%"}}>
                      <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <h4 className="ant-list-item-meta-title">
                          <ElementTitle>
                            <Ellipsis style={{display: "inline"}} length={35}>
                              {item.name}
                            </Ellipsis>
                          </ElementTitle>
                        </h4>
                        <div style={{color: "#6B7185", marginLeft: "0.5rem"}}>
                          ({niceBytes("" + item.size)})
                        </div>
                      </div>
                      <div
                        style={{
                          marginRight: "0.9rem",
                          overflowWrap: "break-word",
                          hyphens: "auto",
                        }}
                      >
                        <p style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                }
              />
              {item.uploadedDate && <span style={{color: "#6B7185"}}>
                        {moment(item.uploadedDate).format(propertyPreferences.preferredDateFormat)}
                      </span>}
            </Skeleton>
          </ListItemStyledAttachments>
        )}
      />
      {filteredAttachments().length > pageSize && <PaginationStyled
        className={filteredAttachments().length < pageSize ? "single-page-pagination" : ""}
        onChange={(page: any) => setPage(page)}
        defaultPageSize={10}
        itemRender={itemRender}
        defaultCurrent={1}
        simple={window.innerWidth < 1024}
        showSizeChanger={false}
        total={filteredAttachments().length}
        style={{background: "#fff"}}
        current={page}
        onShowSizeChange={(current, size) => setPageSize(size)}
      />}
      <SaveButtonWrapper>
        {children}
      </SaveButtonWrapper>
    </section>
    </Drawer>
  );
};

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

const Title = styled.div`
  border: none;
  outline: none;
  background-color: #ebf9ff;
  margin-top: 0.5rem;
  color: #002a43;

  & > *:focus {
    border-color: #57a8e9;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }

  .ant-input:hover {
    border-color: #ebf9ff;
  }

  @media (min-width: 1024px) {
    font-size: 2.5rem;
  }

  @media (max-width: 1024px) {
    font-size: 1.625rem;
  }
`;

const SortMenuStyled = styled(Menu)`
  font-weight: 600;
  color: #21272b;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-radius: 6px;

  .ant-menu-item-active {
    color: #6b7185 !important;
  }

  .ant-menu-item {
    height: 1.5rem !important;
    padding-bottom: 2rem !important;
  }
`;

const FilterNoteElements = styled.ul`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  line-height: 2.5rem;
  font-size: 1rem;

  @media (max-width: 1024px) {
    justify-content: flex-start;
    li {
      margin-right: 0.5rem;
    }
  }
  @media (min-width: 1024px) {
    justify-content: space-between;
    paddingRight: 1rem;
  }
  
  
  input {
    margin-right: 0.5rem;
  }
  .disabled {
    color: #bdbdbd;
  }
`;

const ElementTitle = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`;

const ListItemStyledAttachments = styled(ListItemStyled)`
  border-bottom: none;
  padding: 0.5rem 0;
  background: #fff !important;
  ul :hover {
    background: #fff !important;
  }
  li :hover {
    background: #fff !important;
  }
`;

export default AttachmentsOnDrawer;
