import React, {useState} from "react";
import moment from "moment";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {DatePicker, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {editWork} from "../../../api-wrapper/works/editWork";
import {getAllWorks} from "../../actions/works";

type Props = {
  work: any;
  isModalOpen: boolean;
  toggleModal: (arg: boolean) => void;
  refreshParent?: () => void;
}

const CompleteWorkModal = (props: Props) => {
  const {work, isModalOpen, toggleModal, refreshParent} = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);
  const [completionDate, setCompletionDate] = useState(moment())

  return <Modal
    className={"form-confirm-close-modal dismiss-modal"}
    visible={isModalOpen}
    closeIcon={<></>}
    width={416}
    okText={"Complete"}
    onOk={() => {
      editWork({
        ...work,
        isComplete: true,
        completedDate: completionDate.format("YYYY-MM-DD")})
        .then(() => {
          dispatch(getAllWorks(propertyId));
          refreshParent && refreshParent();
          toggleModal(false);
        })
    }}
    onCancel={() => toggleModal(false)}
  >
    <div>
      <QuestionCircleOutlined className={"question-icon"} />
    </div>
    <div style={{marginLeft: "1rem"}}>
      <p>Almost done!</p>
      Set a completion date for this project.
      <DatePicker
        value={completionDate}
        format={propertyPreferences.preferredDateFormat}
        allowClear={false}
        inputReadOnly={true}
        style={{
          marginTop: "0.5rem",
          width: "100%",
          border: "1px solid #DAE0E6",
          borderRadius: "6px",
          color: "rgb(107,113,133)",
        }}
        // @ts-ignore
        onChange={(event) => setCompletionDate(moment(event._d))}
      />
    </div>
  </Modal>
}

export default CompleteWorkModal;