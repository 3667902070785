import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TGetExpenseTypesResponse = Array<{
  id: Guid,
  parentId?: Guid,
  name: string,
  propertyId?: Guid,
  subscriptionId?: number
}>;

export const getExpenseTypes = (propertyId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/expense/types`,
    {}
  );

  return apiGet<TGetExpenseTypesResponse | []>(url, {}, propertyId);
};
