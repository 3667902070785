import React, {useEffect, useState} from 'react'
import styled from "styled-components";

import {ReactComponent as LeakDetection} from "../../../images/dashboard-improvements/LeakDetection.svg";
import {ReactComponent as CostOptimisation} from "../../../images/dashboard-improvements/CostOptimisation.svg";
import {ReactComponent as CyberSecurity} from "../../../images/dashboard-improvements/CyberSecurity.svg";
import {ReactComponent as GoGreen} from "../../../images/dashboard-improvements/GoGreen.svg";
import {ReactComponent as ImprovementsWidgetIcon} from "../../../images/dashboard-improvements/ImprovementsWidget.svg";
import {ReactComponent as IncreaseComfort} from "../../../images/dashboard-improvements/IncreaseComfort.svg";
import {ReactComponent as RecommendedUpkeep} from "../../../images/dashboard-improvements/RecommendedUpkeep.svg";
import {ReactComponent as ViewAllArrow} from "../../../images/dashboard-improvements/ViewAllArrow.svg";
import {ReactComponent as ImprovementsEmptyIcon} from "../../../images/dashboard-improvements/ImprovementsEmptyIcon.svg";

import {useSelector} from "react-redux";
import {
  getImprovementsSelector,
  getMaintenancesSelector, getPropertiesSelector, getPropertyIdSelector,
  getUserProfileSelector,
} from "../../selectors";
import {TFetchingStatus} from "../../../helpers";
import _ from "lodash";
import {useHistory} from "react-router-dom";
import {ReactComponent as YellowInfoIcon} from "../../../images/dashboard-contents/YellowInfoIcon.svg";
import {ReactComponent as CloseIcon} from "../../../images/dashboard-contents/CloseIcon.svg";
import {EmptyWidgetNotification} from "./ContentsWidget";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {emptyGuid} from "../../screens/helpers";

const ImprovementsWidget = () => {
  let history = useHistory();

  const maintenances = useSelector(getMaintenancesSelector);
  const improvementsConfiguration = useSelector(getImprovementsSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);

  const [improvements, setImprovements] = useState<Array<any>>([]);
  const [improvementCategoriesWithCount, seImprovementCategoriesWithCount] = useState<Array<any>>([]);
  const [onboardingStatus, setOnboardingStatus] = useState("")
  const [showPutToWorkAlert, setShowPutToWorkAlert] = useState(true)
  const [dataDownloaded, setDataDownloaded] = useState(false);

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty)
      return;
    setOnboardingStatus(currentProperty.onboardingImprovementsStatus)
  }, [properties,propertyId])

  useEffect(() => {
    if (improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success)
      return;
    setImprovements([...improvementsConfiguration.content.improvements].sort((impr1, impr2) =>
      impr1.displayOrder - impr2.displayOrder));
    }, [improvementsConfiguration]);

  const improvementsFromCategoryWithoutParent = (categoryId: number) =>
    improvements.filter(improvement => improvement.category === categoryId && _.isNil(improvement.parentId));

  const getNumberOfNotDoneImprovements = (categoryId: number) =>
    improvementsFromCategoryWithoutParent(categoryId).filter(improvement =>
      (_.isNil(improvement.doneOn) && _.isNil(improvement.doneByAnswerOn))).length

  useEffect(() => {
    let categoriesWithCount: any = []
    if (improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success)
      return;
    improvementsConfiguration.content.categories.map(category =>
      category.name && categoriesWithCount.push({
        id: category.id,
        name: category.name,
        notDoneCount: getNumberOfNotDoneImprovements(category.id)
      }))
    seImprovementCategoriesWithCount(categoriesWithCount)
    setDataDownloaded(true);
  }, [improvements, improvementsConfiguration]);

  const getNumberOfNotDoneImprovementsByCategoryId = (categoryId: number) => {
    if (improvementsConfiguration.fetchingStatus === TFetchingStatus.Success && improvementCategoriesWithCount.length) {
      return improvementCategoriesWithCount.filter(category => category.id === categoryId)[0].notDoneCount
    } else {
      return "-"
    }
  }

  if (!dataDownloaded) {
    return <Spin indicator={<LoadingOutlined style={{fontSize: 72}} spin/>}/>
  }

  return <OuterWidgetWrapper>
    <WidgetTitleWrapper>
      <WidgetTitle><ImprovementsWidgetIcon/>Improvements</WidgetTitle>
      {onboardingStatus === "completed" && window.innerWidth > 1024 && <ViewAllButton
          onClick={() => history.push("/guidance")}>Go to Guidance <ViewAllArrow/></ViewAllButton>}
    </WidgetTitleWrapper>

    {(onboardingStatus === "completed" || window.innerWidth < 1024) && <WidgetWrapper>
      <WidgetItem className={"clickable"} style={{
        borderBottom: window.innerWidth > 1024 ? borderSetting : 0
      }} onClick={() => history.push("/guidance?category=3")}>
        <IncreaseComfort />
        <WidgetItemTitle>Increase comfort</WidgetItemTitle>
        <WidgetItemCount>
          <WidgetItemCountNumber>{getNumberOfNotDoneImprovementsByCategoryId(3)}</WidgetItemCountNumber>
          <WidgetItemCountDescription>options</WidgetItemCountDescription>
        </WidgetItemCount>
      </WidgetItem>
      <WidgetItem className={"clickable"} style={{
        borderLeft: borderSetting,
        borderRight: window.innerWidth > 1024 ? borderSetting : 0,
        borderBottom: window.innerWidth > 1024 ? borderSetting : 0
      }} onClick={() => history.push("/guidance?category=1")}>
        <CostOptimisation />
        <WidgetItemTitle>Cost optimization</WidgetItemTitle>
        <WidgetItemCount>
          <WidgetItemCountNumber>{getNumberOfNotDoneImprovementsByCategoryId(1)}</WidgetItemCountNumber>
          <WidgetItemCountDescription>options</WidgetItemCountDescription>
        </WidgetItemCount>
      </WidgetItem>
      <WidgetItem className={"clickable"} style={{
        borderTop: window.innerWidth > 1024 ? 0 : borderSetting,
        borderBottom: borderSetting
      }} onClick={() => history.push("/guidance?category=2")}>
        <GoGreen />
        <WidgetItemTitle>Go green</WidgetItemTitle>
        <WidgetItemCount>
          <WidgetItemCountNumber>{getNumberOfNotDoneImprovementsByCategoryId(2)}</WidgetItemCountNumber>
          <WidgetItemCountDescription>options</WidgetItemCountDescription>
        </WidgetItemCount>
      </WidgetItem>
      <WidgetItem className={"clickable"} style={{
        borderTop: window.innerWidth > 1024 ? 0 : borderSetting,
        borderBottom: window.innerWidth > 1024 ? 0 : borderSetting,
        borderLeft: window.innerWidth > 1024 ? 0 : borderSetting
      }} onClick={() => history.push("/guidance?category=5")}>
        <LeakDetection />
        <WidgetItemTitle>Leak detection</WidgetItemTitle>
        <WidgetItemCount>
          <WidgetItemCountNumber>{getNumberOfNotDoneImprovementsByCategoryId(5)}</WidgetItemCountNumber>
          <WidgetItemCountDescription>options</WidgetItemCountDescription>
        </WidgetItemCount>
      </WidgetItem>
      <WidgetItem className={"clickable"} style={{
        borderLeft: window.innerWidth > 1024 ? borderSetting : 0,
        borderRight: window.innerWidth > 1024 ? borderSetting : 0
      }} onClick={() => history.push("/guidance?category=6")}>
        <CyberSecurity/>
        <WidgetItemTitle>Cyber security</WidgetItemTitle>
        <WidgetItemCount>
          <WidgetItemCountNumber>{getNumberOfNotDoneImprovementsByCategoryId(6)}</WidgetItemCountNumber>
          <WidgetItemCountDescription>options</WidgetItemCountDescription>
        </WidgetItemCount>
      </WidgetItem>
      <WidgetItem className={"clickable"} style={{
        borderLeft: window.innerWidth > 1024 ? 0 : borderSetting
      }} onClick={() => history.push("/upkeep?tab=Recommended")}>
        <RecommendedUpkeep/>
        <WidgetItemTitle>Upkeep</WidgetItemTitle>
        <WidgetItemCount>
          <WidgetItemCountNumber>{maintenances.content.filter((maintenance: any) =>
            maintenance.recommended && !maintenance.subscribed && !maintenance.notApplicable).length}</WidgetItemCountNumber>
          <WidgetItemCountDescription>recommended</WidgetItemCountDescription>
        </WidgetItemCount>
      </WidgetItem>
    </WidgetWrapper>}
    {onboardingStatus !== "completed" && window.innerWidth < 1024 && showPutToWorkAlert && <EmptyWidgetNotification>
        <YellowInfoIcon style={{flexShrink: 0}}/>
        <div>
            <p style={{color: "#002A43", fontWeight: 600}}>The suggestions above are typical</p>
            After you provide more details about your property, you'll start receiving personalized improvement
            suggestions. You can access our onboarding tool to receive tailored recommendations via the desktop version of Livlet.
        </div>
        <CloseIcon onClick={() => setShowPutToWorkAlert(false)} style={{cursor: "pointer", flexShrink: 0}}/>
    </EmptyWidgetNotification>}
    {window.innerWidth < 1024 && <div style={{display: "flex", columnGap: "0.125rem"}}>
        <ViewAllButton style={{width: "100%"}} onClick={() => history.push("/guidance")}>Guidance <ViewAllArrow/></ViewAllButton>
        <ViewAllButton style={{width: "100%"}} onClick={() => history.push("/upkeep")}>Upkeep <ViewAllArrow/></ViewAllButton>
    </div>}
    {onboardingStatus !== "completed" && window.innerWidth > 1024 && <EmptyWidget>
        <ImprovementsEmptyIcon />
        <EmptyWidgetTitle style={{textAlign: "center"}}>
            Find out what you can do to reduce your home's carbon footprint, reduce your bills and health and comfort in your home
        </EmptyWidgetTitle>
        In order to tailor our advice to your needs, we'll need to ask a few questions about your home and its systems. It shouldn't take longer than 5 minutes.
        <ViewAllButton onClick={() => history.push("/questions?origin=dashboard")}>
            Let's go through my home systems <ViewAllArrow />
        </ViewAllButton>
    </EmptyWidget>}
    </OuterWidgetWrapper>
}

export const OuterWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  
  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const borderSetting = "2px solid #eff1f5";

export const WidgetTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const WidgetTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #21272B;
  height: 2.5rem;
`

export const WidgetWrapper = styled.div`
  background: white;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
`

export const WidgetItem = styled.div`
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  row-gap: 0.375rem;
  padding: 1.5rem;
  overflow: hidden;
  
  &.clickable { cursor: pointer; }
  
  @media (max-width: 1024px) {
    width: calc(100% / 2);
    padding: 1rem;
  }
`

export const WidgetItemTitle = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #45655E;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const WidgetItemCount = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`

export const WidgetItemCountNumber = styled.div`
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.75rem;
  color: #666F7A;
  
  @media (max-width: 1024px) {
    font-size: 1.45rem;
  }
`

export const WidgetItemCountDescription = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1.125rem;
  color: #8E8E89;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ViewAllButton = styled.div`
  cursor: pointer;
  display: flex;
  background: #2A80E1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.875rem;
  height: 2.5rem;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: white;
  column-gap: 0.875rem;
`

export const EmptyWidget = styled.div`
  height: 16.375rem;
  border-radius: 4px;
  padding: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background: white;
  color: #666F7A;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const EmptyWidgetTitle = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #21272B;
`

export default ImprovementsWidget