import React, {useState} from "react";
import {Drawer} from "antd";
import {signOut} from "../../actions/logIn";
import {
  ExtraOption,
  ExtraOptions,
  ExtraOptionStyled,
  ExtraOptionTitle, showReferPopup,
} from "./MenuDesktop";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {authSelector, getUserProfileSelector} from "../../selectors";
import MenuLivlet from "./MenuLivlet";
import {ReactComponent as AccountLogout} from "../../../images/menu/AccountLogout.svg";
import {LogoutButton} from "./Header";
import {ReactComponent as FAQCircle} from "../../../images/menu/FAQ.svg";
import {ReactComponent as CaretGray} from "../../../images/menu/CaretGray.svg";
import {ReactComponent as ReportTechIssue} from "../../../images/menu/ReportTechIssue.svg";
import ReportProblem from "./ReportProblem";
import ReferFriendPopup from "./ReferFriendPopup";
import OnboardingPopup from "./OnboardingPopup";

type Props = {
  visible: boolean;
  setVisible: (arg: boolean) => void;
}

const MenuMobile = (props: Props) => {
  const {visible, setVisible} = props;

  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const userProfile = useSelector(getUserProfileSelector);

  const [reportProblemModal, toggleReportProblemModal] = useState(false);

  return <DrawerStyled
    width={window.innerWidth > 480 ? "360px" : "100%"}
    placement="left"
    onClose={() => setVisible(false)}
    visible={visible}
    className={"mobile-navigation-drawer"}
    footer={
      <LogoutButton onClick={() => dispatch(signOut())}>
        <AccountLogout /> Log Out
      </LogoutButton>
    }
  >
    <ReportProblem
      isModalOpen={reportProblemModal}
      toggleModal={toggleReportProblemModal} />
    <MenuLivlet setVisible={setVisible} />
    {auth.showPopup && (showReferPopup(userProfile.content.dateCreated) ? <ReferFriendPopup /> : <OnboardingPopup />)}
    <ExtraOptions>
      <a href={"https://www.livlet.com/faq"} target={"_blank"}><ExtraOption>
        <ExtraOptionTitle>
          <FAQCircle /> FAQs
        </ExtraOptionTitle>
        <CaretGray />
      </ExtraOption></a>
      {/*<ExtraOptionStyled onClick={() => toggleReportProblemModal(true)}>*/}
      {/*  <ExtraOptionTitle>*/}
      {/*    <ReportTechIssue /> Report a tech issue*/}
      {/*  </ExtraOptionTitle>*/}
      {/*  <CaretGray />*/}
      {/*</ExtraOptionStyled>*/}
    </ExtraOptions>
  </DrawerStyled>
}

const DrawerStyled = styled(Drawer)`
  z-index: 10;
  
  .ant-drawer-body {
    padding: 0;
    margin-top: 48px;
    box-shadow: none!important;
    overflow-x: hidden;
  }
  
  .ant-drawer-footer {
    padding: 0;
    padding-bottom: 1rem;
  }
`

export default MenuMobile;