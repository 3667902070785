import React, {useEffect, useState} from "react";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";

import {Form, Input, Modal, Select} from "antd";
import {Label} from "../../screens/RegistrationPage";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getAttachmentsSelector, getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import {ReactComponent as BackArrow} from "../../../images/spaces/ReturnArrow.svg";
import _ from "lodash";
import {
  ActionButtonGreen,
  ActionButtonWhite,
  DrawerFooter, DrawerHeader,
  EditDrawerStyled, FormStyled, CircleIcon
} from "../../screens/components";
import {updateAttachment} from "../../../api-wrapper/attachments/updateAttachment";
import {getAllAttachments} from "../../actions/attachments";

type Props = {
  attachmentId: any;
  isOpen: boolean;
  toggleDrawerOpen: (arg: boolean) => void;
  refreshParent?: () => void;
}

export const selectStyle = {
  width: "100%",
  border: "1px solid #DAE0E6",
  borderRadius: "6px",
  color: "rgb(107,113,133)",
}

const EditFileDrawer = (props: Props) => {
  const {attachmentId, isOpen, toggleDrawerOpen, refreshParent} = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const attachments = useSelector(getAttachmentsSelector);

  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [attachment, setAttachment] = useState<any>(null);
  const [suffix, setSuffix] = useState("");

  const initialValues = () => {
    if (attachment) {
      const {name, description} = attachment;
      return {name, description};
    } else {
      return {};
    }
  };

  useEffect(() => {
    if (attachmentId) {
      let att = attachments.content.filter(item => item.attachmentId === attachmentId)[0];
      if (!att)
        return;
      setAttachment(att);
      form.setFieldsValue({
        name: att.name.replace(att.name.substr(att.name.lastIndexOf('.')), ""),
        description: att.description
      });
      setSuffix(att.name.substr(att.name.lastIndexOf('.')));
      setFormFieldsChanged(false);
    }
  }, [attachments, attachmentId, isOpen])

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        performCleanup()
      },
    });
  }

  const performCleanup = () => {
    dispatch(getAllAttachments(propertyId));
    refreshParent && refreshParent();
    setAttachment(null);
    form.resetFields();
    setFormFieldsChanged(false);
    toggleDrawerOpen(false);
  }

  const handleCancel = () => {
    if (formFieldsChanged) {
      confirmCloseUnsaved();
      return;
    } else {
      performCleanup();
    }
  }

  const handleManageAttachment = (e: {
    name: string,
    description: string,
  }) => {
    const {name, description} = e;

    updateAttachment(
      propertyId,
      attachmentId,
      name.slice(0, 63),
      description ? description : "",
    ).then(() => performCleanup());
  }

  return <EditDrawerStyled
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "100%" : "85%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      visible={isOpen}
      onClose={() => handleCancel()}
      title={<DrawerHeader style={{flexDirection: "row", alignItems:"flex-start", rowGap:"0.5rem"}}>
        {window.innerWidth <= 768 && <BackArrow style={{cursor: "pointer", flexShrink: 0}} onClick={() => handleCancel()}/>}
        Edit document details
        {window.innerWidth > 768 && <CircleIcon onClick={() => handleCancel()}><CloseIcon /></CircleIcon>}
      </DrawerHeader>}
    >
    <FormStyled
        form={form}
        name="editSpace"
        layout="vertical"
        size={"large"}
        // @ts-ignore
        onFinish={handleManageAttachment}
        initialValues={initialValues()}
        onFieldsChange={() => setFormFieldsChanged(true)}
      >
        <EditSpaceForm>
        <Label>File name *</Label>
        <Form.Item key={"name"} name={"name"} rules={[{
          required: true,
          message: "File name is required",
        }]}>
          <Input
            autoFocus={true}
            suffix={suffix}
            style={{...selectStyle, height: "2.625rem"}}
            placeholder={"File name"}
          />
        </Form.Item>
        <Label>Description</Label>
        <Form.Item key={"description"} name={"description"}>
          <Input
            style={{...selectStyle}}
            placeholder={"Enter text"}
          />
        </Form.Item>
      </EditSpaceForm>
        <DrawerFooter className={"fake-footer"}>
          <ActionButtonWhite onClick={() => handleCancel()}>Cancel</ActionButtonWhite>
          <ActionButtonGreen htmlType={"submit"}>Save</ActionButtonGreen>
        </DrawerFooter>
      </FormStyled>
    </EditDrawerStyled>
}

const EditSpaceForm = styled.div`
  
`

export default EditFileDrawer