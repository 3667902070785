import React from "react";
import {AddLinkedRecordButton, LinkedHeader, LinkedRecordsHeader, LinkedRecordsWrapper} from "./LinkedItemsHeader";

import {ReactComponent as LinkNewSection} from "../../../images/spaces/LinkNewSection.svg";
import {ReactComponent as AddLinkIcon} from "../../../images/spaces/LinkedAdd.svg";
import {ReactComponent as SubmenuContentsIcon} from "../../../images/menu/MenuContents.svg";
import {ReactComponent as SubmenuExpensesIcon} from "../../../images/menu/MenuExpenses.svg";
import {ReactComponent as SubmenuDocumentsIcon} from "../../../images/menu/MenuDocuments.svg";
import {ReactComponent as SubmenuSpacesIcon} from "../../../images/menu/MenuSpaces.svg";
import {ReactComponent as SubmenuContactsIcon} from "../../../images/menu/MenuContacts.svg";
import {ReactComponent as SubmenuNotesIcon} from "../../../images/menu/MenuNotes.svg";
import {ReactComponent as SubmenuProjectsIcon} from "../../../images/menu/MenuProjects.svg";
import {ReactComponent as SubmenuMaintenancesIcon} from "../../../images/menu/MenuMaintenances.svg";

import styled from "styled-components";
import UploadFile from "../attachments/UploadFile";
import {getAllAttachments} from "../../actions/attachments";
import {Guid} from "guid-typescript";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../selectors";

type Props = {
  toggleLinkDrawerOpen: (arg: boolean) => void;
  toggleNewRecordDrawer: (arg: boolean) => void;
  setPresetLinkType: (arg: string) => void;
  excludeTypes?: Array<string>;
  parentId?: Guid;
  parentType?: string;
  refreshParent?: () => void;
}

const AddLinkedItem = (props: Props) => {
  const {toggleLinkDrawerOpen, toggleNewRecordDrawer, setPresetLinkType, excludeTypes, parentId, parentType, refreshParent} = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;

  const openLinkDrawer = (type: string) => {
    setPresetLinkType(type);
    toggleLinkDrawerOpen(true);
  }

  const linkedItemOptions = [
    {type: "expense", title: "Expenses", icon: <SubmenuExpensesIcon />},
    {type: "inventory", title: "Contents", icon: <SubmenuContentsIcon />},
    {type: "space", title: "Spaces", icon: <SubmenuSpacesIcon />},
    {type: "contact", title: "Contacts", icon: <SubmenuContactsIcon />},
    {type: "attachment", title: "Documents", icon: <SubmenuDocumentsIcon />},
    {type: "note", title: "Notes", icon: <SubmenuNotesIcon />},
    {type: "upkeep", title: "Maintenance", icon: <SubmenuMaintenancesIcon />},
    {type: "work", title: "Projects", icon: <SubmenuProjectsIcon />},
  ]

  return <LinkedHeader>
    <LinkedRecordsHeader>
      <LinkedRecordsWrapper style={{gap: "0.75rem"}}>
        <LinkNewSection /> Add link
      </LinkedRecordsWrapper>
      <div className={"button-wrapper"}>
        <AddLinkedRecordButton onClick={() => toggleNewRecordDrawer(true)}>
          <AddLinkIcon/> Add new
        </AddLinkedRecordButton>
        {parentId && parentType && refreshParent && <UploadFile
            fileList={[]}
            refreshParent={() => {
              dispatch(getAllAttachments(propertyId));
              refreshParent && refreshParent();
            }}
            singleAttachment={false}
            parentId={parentId}
            parentType={parentType}
            propertyId={propertyId}
            uploadImmediately={false}
            isGlobalUpload={true}
            uploadOrigin={"view-space-drawer"}
        />}
      </div>
    </LinkedRecordsHeader>
    <LinkedItemTypeWrapper>
      {linkedItemOptions.filter(item => excludeTypes ? !excludeTypes.includes(item.type) : true).map(item => <LinkedItemType
          onClick={() => openLinkDrawer(item.type)}>
        {item.icon} {item.title}
      </LinkedItemType>)}
    </LinkedItemTypeWrapper>
  </LinkedHeader>
}

const LinkedItemTypeWrapper = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: 1rem;
`

const LinkedItemType = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666F7A;
`

export default AddLinkedItem