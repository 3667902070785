import React, { Dispatch, useEffect, useState } from "react";
import { Button, Form, notification } from "antd";

import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";

import { loginClean, TLoginThunkActionType } from "../actions/logIn";
import {
  MAX_LENGTH_LOGIN_FORM,
  NOTIFICATION_TOP_RIGHT_PLACEMENT,
} from "../helpers/constants";
import messages from "./messages";
import { emailRegex } from "./helpers";
import { TFetchingStatus } from "../../helpers";
import { authSelector } from "../selectors";
import LoadingPage from "./LoadingPage";
import {InputStyled, Label, LabelWrapper} from "./RegistrationPage";
import { ReactComponent as AlertIcon } from "../../images/AlertRed.svg";
import { resetPassword } from "../actions/reset";
import { FormWrapper, ImageWrapper } from "./styled";
import {useHistory, useLocation} from "react-router-dom";

const { Item } = Form;

const PasswordReset = () => {
  const { formatMessage: f } = useIntl();
  let history = useHistory();

  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const dispatch: Dispatch<TLoginThunkActionType> = useDispatch();

  const auth = useSelector(authSelector);

  const [isMcLarenWebsite, toggleMcLarenWebsite] = useState(false);

  const useQuery = () => {
    let query = new URLSearchParams(useLocation().search);
    return query.get("tokenExpired");
  };
  const token = useQuery();

  useEffect(() => {
    console.log("window.location.hostname=" + window.location.hostname)
    if (window.location.hostname.includes("mcl") || window.location.hostname.includes("localhost")) {
      // toggleBusinessWebsite(true);
      toggleMcLarenWebsite(true);
    }
  }, [])

  const [showPasswordResetSuccessMessage, setPasswordResetSuccessMessage] = useState(false);
  const [showPasswordResetError, setPasswordResetError] = useState(false);

  const handleReset = (data: { email: string }) => {
    const { email } = data;
    if (isEmpty(email)) {
      api.error({
        message: f(messages.emptyFieldTitle),
        description: f(messages.emptyFieldDescription),
        placement: NOTIFICATION_TOP_RIGHT_PLACEMENT,
      });
    } else {
      dispatch(resetPassword({ email }));
      setPasswordResetSuccessMessage(true);
    }
  };

  useEffect(() => {
    if (auth.fetchingStatus === TFetchingStatus.Failure) {
      setPasswordResetError(true);
      // notification.error({
      //   message: f(messages.error),
      //   description: f(messages.loginError),
      //   placement: "topRight",
      // });
      dispatch(loginClean());
    }
  }, [auth.fetchingStatus, f, dispatch]);

  if (auth.fetchingStatus === TFetchingStatus.Initial) {
    return <LoadingPage />;
  }

  return (
    <Main>
      {contextHolder}
      <FormWrapper>
        <FormHeader>
          <div style={{width: "175px"}}>
            <img src={"/images/LivletLogoNew.png"} style={{maxWidth: "100%"}} alt={""} />
          </div>
        </FormHeader>
        <TitlesWrapper>
          <Title>{f(messages.resetPasswordTitle)}</Title>
          <Subtitle>{f(messages.resetPasswordSubtitle)}</Subtitle>
        </TitlesWrapper>
        {token && (
          <div
            style={{
              backgroundColor: "#FCF0F0",
              padding: "1rem",
              marginBottom: "1em",
              display: "flex",
              borderRadius: 6,
              fontWeight: 400,
            }}
          >
            <AlertIcon />
            <span style={{ paddingLeft: "0.5rem" }}>
              {f(messages.updatePasswordExpiredRedirect, {
                  passwordResetLink: (
                    <a onClick={() => history.push("/reset/password")}>this form</a>
                  ),
                })}
            </span>
          </div>
        )}
        <Form
          form={form}
          name="login"
          size={"large"}
          initialValues={{ remember: true }}
          onFinish={handleReset}
        >
          <LabelWrapper>
            <Label>{f(messages.loginEmailAddress)}</Label>{" "}
          </LabelWrapper>
          <Item
            key={"email"}
            name={"email"}
            rules={[
              {
                required: true,
                pattern: emailRegex,
                message: f(messages.validationEmail),
              },
            ]}
          >
            <InputStyled
              placeholder={f(messages.loginEmailAddress)}
              maxLength={MAX_LENGTH_LOGIN_FORM}
              onChange={() => setPasswordResetError(false)}
            />
          </Item>
          {showPasswordResetError && (
            <div
              style={{
                backgroundColor: "#FCF0F0",
                padding: "1rem",
                marginBottom: "1em",
                display: "flex",
                borderRadius: 6,
                fontWeight: 400,
              }}
            >
              <AlertIcon />
              <span style={{ paddingLeft: "0.5rem" }}>
                {f(messages.loginCredentialsError)}
              </span>
            </div>
          )}
          <ButtonsWrapper>
            <ButtonStyled onClick={() => history.push('/login')}>
              {f(messages.cancel)}
            </ButtonStyled>
            <ButtonStyled htmlType="submit" type="primary" disabled={showPasswordResetSuccessMessage}>
              {showPasswordResetSuccessMessage ? "Email sent!" : f(messages.reset)}
            </ButtonStyled>
          </ButtonsWrapper>
          <>
            {f(messages.resetPasswordRequestSuccess)}
            <br/><br/>Click <a onClick={() => history.push('/login')}>here</a> to return to the Login page.
          </>
        </Form>
      </FormWrapper>
      <ImageWrapper>
        <LoginPageImageStyled imgSrc={isMcLarenWebsite ? "/images/registrationImageBeach.jpg" : undefined} />
        <TextOnImage>{f(messages.imageText)}</TextOnImage>
      </ImageWrapper>
    </Main>
  );
};

export const FormHeader = styled.div`
  @media (max-width: 1024px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 1rem;
  }

  @media (min-height: 720px) {
    height: 15vh;
  }

  padding: 2rem 0 2rem;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
`;

export const LoginPageImageStyled = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1024px) {
    min-height: 720px;
    width: 100%;
    height: 100vh;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: ${(props: { imgSrc?: string }) => props.imgSrc ? "url("+props.imgSrc+")" : "url(/images/registrationImage.jpg)"}; 
    margin: 0 auto;
    position: relative;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  padding-top: 0.5rem;
`;

export const ButtonStyled = styled(Button)`
  @media (max-width: 1024px) {
    width: 25%;
    height: 2.5rem;
  }
  @media (min-width: 1024px) {
    height: 3rem;
    width: 25%;
  }
  border-radius: 6px;
`;

export const Main = styled.main`
  display: flex;
  overflow-y: visible;
  height: 100vh;
  max-height: 100vh;
  justify-content: space-between;
  background-color: white;
`;

export const TitlesWrapper = styled.div`
  @media (min-width: 1024px) and (min-height: 720px) {
    height: 15vh;
  }
`;

export const Title = styled.h4`
  font-weight: 700;
  font-size: 1.625rem;
  color: #002a43;
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: #6b7185;
`;

export const TextOnImage = styled.h3`
  font-weight: 700;
  color: #ffffff;
  position: absolute;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1024px) {
    font-size: 2.1rem;
    width: 350px;

    top: 0.5rem;
    right: 0.5rem;
  }
`;

export default PasswordReset;
