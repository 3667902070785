import React from "react"

import {ReactComponent as AlertsFlood} from "../../../images/news-page/AlertsFlood.svg";
import {ReactComponent as AlertContentRisk} from "../../../images/news-page/AlertContentRisk.svg";
import {ReactComponent as AlertNews} from "../../../images/news-page/AlertNews.svg";
import {ReactComponent as AlertDelete} from "../../../images/news-page/AlertDelete.svg";
import {ReactComponent as AlertUnread} from "../../../images/news-page/AlertUnread.svg";
import {ReactComponent as AlertClose} from "../../../images/news-page/AlertCloseIcon.svg";
import {ReactComponent as CaretDown} from "../../../images/CaretDown.svg";
import {ReactComponent as CaretUp} from "../../../images/CaretUp.svg";

import {Collapse, Drawer} from "antd";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import {TNewsObject} from "../../../api-wrapper/news/getNews";
import {Guid} from "guid-typescript";
import {markNewsAsRead} from "../../../api-wrapper/news/markNewsAsRead";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {deleteNews} from "../../../api-wrapper/news/deleteNews";
import {getAllNews} from "../../actions/news";
import {useHistory} from "react-router-dom";

const { Panel } = Collapse;

type Props = {
  news: TNewsObject | null;
  isDrawerOpen: boolean;
  toggleDrawer: (arg: boolean) => void;
  toggleGasCertificateDrawer: (arg: boolean) => void;
  toggleMortgageDrawer: (arg: boolean) => void;
}

const floodActions = {
  actions: "<p><b>A flood alert means you need to prepare: flooding is possible. If you haven't already done so, you should:</b></p>" +
    "<ul><li>sign up for flood warnings</li>" +
    "<li>keep up to date with the latest flood risk situation call Floodline on 0345988 1188 or follow @EnvAgency and " +
    "#floodaware on Twitter for the latest flood updates</li>" +
    "<li>have a bag ready with vital items like insurance documents and medications in case you need to leave your home</li>" +
    "<li>check you know how to turn off your gas, electricity and water mains supplies</li>" +
    "<li>plan how you'll move family and pets to safety</li></ul>"
}

const NewsDrawer = (props: Props) => {
  const {news, isDrawerOpen, toggleDrawer, toggleGasCertificateDrawer, toggleMortgageDrawer} = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const titleDispenser = (type: string) => {
    if (type === "Critical Alert") return "Critical alert"
    if (type === "Risk of Failure") return "Risk of Failure"
    if (type === "News" || type === "General News") return "News"
  }

  const iconDispenser = (type: string) => {
    if (type === "Critical Alert") return <AlertsFlood />
    if (type === "Risk of Failure") return <AlertContentRisk />
    if (type === "News" || type === "General News") return <AlertNews />
  }

  const markNewsArticleAsUnread = (newsId: Guid) => {
    markNewsAsRead({markAsRead: false, newsId, propertyId})
      .then(() => {
        dispatch(getAllNews(propertyId))
        toggleDrawer(false)
      })
  }

  const deleteNewsArticle = (newsId: Guid) => {
      deleteNews(propertyId, newsId)
        .then(() => {
          dispatch(getAllNews(propertyId))
          toggleDrawer(false)
        })
  }

  let gasCertificateDrawer = document.getElementById("gas-certificate-adviser")
  let mortgageDrawer = document.getElementById("mortgage-adviser")
  let gasCertificateLink = document.getElementById("gas-certificate-update")
  let mortgageLink = document.getElementById("mortgage-update")
  if (gasCertificateDrawer) gasCertificateDrawer.addEventListener("click", function() {
    toggleGasCertificateDrawer(true);
  })
  if (mortgageDrawer) mortgageDrawer.addEventListener("click", function() {
    toggleMortgageDrawer(true);
  })
  if (gasCertificateLink) gasCertificateLink.addEventListener("click", function() {
    history.push(`/properties/${propertyId}?openTab=Certificates`)
  })
  if (mortgageLink) mortgageLink.addEventListener("click", function() {
    history.push(`/properties/${propertyId}?openTab=Mortgage`)
  })

  return news ? <DrawerStyled
    onClose={() => toggleDrawer(false)}
    push={{ distance: "32px" }}
    width={window.innerWidth > 1024 ? "55%" : "100%"}
    closeIcon={false}
    visible={isDrawerOpen}
    placement="right"
    footer={<FooterButtons>
      <div style={{display: "flex", columnGap: window.innerWidth > 1024 ? "1rem" : "0.25rem", rowGap: "0.5rem"}}>
        <FooterButton onClick={() => markNewsArticleAsUnread(news.id)}><AlertUnread />Mark as Unread</FooterButton>
        <FooterButton onClick={() => deleteNewsArticle(news.id)}><AlertDelete /> Delete</FooterButton>
      </div>
      <FooterButton onClick={() => toggleDrawer(false)}>Close</FooterButton>
    </FooterButtons>}
  >
    <AlertWrapper style={{background: news.rating === "Critical Alert" ? "#FEF9EA" : "#F5F6FA"}}>
      <AlertHeader style={{color: news.rating === "Critical Alert" ? "#EF624E" : "black"}}>
        <span style={{display: "flex", alignItems: "center", columnGap: "0.5rem"}}>
          {iconDispenser(news.rating)}
          {titleDispenser(news.rating)}
        </span>
        <AlertClose onClick={() => toggleDrawer(false)} />
      </AlertHeader>
      <AlertTitle>{news.title}</AlertTitle>
      <AlertDate>Added date: <span style={{color: "#21272B"}}>
        {moment(news.dateTime).format(propertyPreferences.preferredDateFormat)}
      </span></AlertDate>
    </AlertWrapper>
    <AlertDescription>
      {ReactHtmlParser(news.body)}
    </AlertDescription>
    {news.relatedItemType === "alert" && <Collapse
      style={{borderTop: "1px solid #EFF1F5", marginTop: "1.25rem", paddingTop: "0.75rem"}}
      defaultActiveKey={[]} ghost expandIcon={({isActive}) => isActive ? <CaretDown/> : <CaretUp/>}>
      <AlertCollapsible
        header={<CollapsibleTitle>What to do if you get a flood alert</CollapsibleTitle>}
        key="1">
        {ReactHtmlParser(floodActions.actions)}
      </AlertCollapsible>
    </Collapse>}
  </DrawerStyled> : null
}

const DrawerStyled = styled(Drawer)`
  .ant-drawer-body {
    padding: 2rem;
  }
  
  .ant-drawer-footer {
    margin: 2rem;
    margin-top: 0;
    padding-top: 1rem;
    display: flex;
    justify-content: flex-end;
  }
  
  @media (max-width: 1024px) {
    .ant-drawer-footer {
      padding: 0;
      padding-top: 1rem;
    }
  }
`

const AlertWrapper = styled.div`
  border-radius: 0.5rem;
  background: #EF624E;
  padding: 1.5rem;
  margin-bottom: 1rem;
`

const AlertHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #EF624E;
  font-weight: 700;
  size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
`

const AlertTitle = styled.div`
  font-weight: bold;
  font-size: 2.125rem;
  line-height: 3rem;
  color: #000000;
`

const AlertDate = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #666F7A;
  margin-top: 1rem;
`

const AlertDescription = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  
  #mortgage-update, #mortgage-adviser, #gas-certificate-update, #gas-certificate-adviser { 
    cursor: pointer;
    color: #1890ff;
  }
  
  #mortgage-update:hover, #mortgage-adviser:hover, #gas-certificate-update:hover, #gas-certificate-adviser:hover { 
    cursor: pointer;
    color: #40a9ff;
  }
`

const CollapsibleTitle = styled.span`
  border-bottom: 1px dashed #236EC2;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #236EC2;
  margin-left: -1rem!important;
`

const AlertCollapsible = styled(Panel)`
  font-size: 1rem;
  line-height: 1.5rem;
  
  .ant-collapse-arrow {
    margin-top: 0!important;
    margin-left: -1rem!important;
  }
`

const FooterButton = styled.div`
  cursor: pointer;
  border: 1px solid #DAE0E6;
  border-radius: 6px;
  color: #21272B;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.5rem 2rem;
  
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  column-gap: 0.5rem;
  
  @media (max-width: 1024px) {
    width: auto!important;
    padding: 0.5rem;
  }
  
  @media (max-width: 400px) {
    height: 4.125rem;
  }
`
const FooterButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 1024px) {
    column-gap: 0.5rem;
  }
`

export default NewsDrawer