import React, {useEffect, useState} from "react"
import {Button, InputNumber, Modal, Select} from "antd";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../selectors";
import {capitalize} from "../helper";
import {Guid} from "guid-typescript";
import {getAllMaintenances} from "../../actions/maintenances";

import _ from "lodash";

type Props = {
  maintenanceId: Guid | null;
  initialFrequency: number | null;
  initialFrequencyUnit: string | null;
  frequencies: any;
  isModalOpen: boolean;
  toggleModal: (arg: boolean) => void;
}

const CustomFrequencyModal = (props: Props) => {
  const { isModalOpen, toggleModal, maintenanceId, initialFrequency, initialFrequencyUnit, frequencies } = props

  const propertyId = useSelector(getPropertyIdSelector).value
  const dispatch = useDispatch();

  const [frequency, setFrequency] = useState<any>(1)
  const [frequencyUnit, setFrequencyUnit] = useState<any>("")

  useEffect(() => {
    !_.isNull(initialFrequency)
      ? setFrequency(initialFrequency)
      : setFrequency(1);
    setFrequencyUnit(initialFrequencyUnit);
  }, [isModalOpen])

  const handleOk = () => {
    // maintenanceId && changeMaintenance({
    //   propertyId,
    //   maintenanceId,
    //   data: {
    //     actualPeriodicity: frequency,
    //     actualPeriodicityUnit: frequencyUnit
    //   }
    // }).then(() => {
    //   dispatch(getAllMaintenances(propertyId))
    //   toggleModal(false);
    // })
  }

  const handleCancel = () => {
    toggleModal(false);
  };

  return (
    <ModalStyled
      width={500}
      title={""}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      closeIcon={<></>}
      footer={null}
      >
    <Title>Customize the frequency</Title>
    <Subtitle>Set the notification according to your personal needs to keep your property in order.</Subtitle>

    <FrequencyGroup>
      <FrequencySelect>
        Once in
      <InputNumberStyled
        value={frequency || 1}
        precision={0}
        min={1}
        style={{width: '35%', paddingLeft: '0', paddingRight: '0'}}
        onChange={(value: any) => setFrequency(value)} />
      </FrequencySelect>

      <Select
        className={'frequency-selector'}
        value={frequencyUnit || ""}
        style={{width: '65%'}}
        onChange={(value: any) => setFrequencyUnit(value)}
      >
        {frequencies.map((frequency: any) =>
          <Select.Option style={{border: 0}} key={frequency} value={frequency}>{capitalize(frequency)}s</Select.Option>)}
      </Select>
    </FrequencyGroup>

    <ButtonGroup>
      <CancelButton onClick={() => handleCancel()}>Cancel</CancelButton>
      <ApplyButton disabled={_.isEmpty(frequencyUnit)} onClick={() => handleOk()}>Apply</ApplyButton>
    </ButtonGroup>
  </ModalStyled>
)
}

export const ModalStyled = styled(Modal)`
  & .ant-modal-content {
    border-radius: 6px!important;
    padding: 1.5rem;
  }

  & .ant-modal-header {
    display: none;
  }
`;

export const Title = styled.span`
  display: block;
  color: black;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
`

export const Subtitle = styled.span`
  display: block;
  color: #6b7185;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 2rem;
`

export const ApplyButton = styled(Button)`
  background: #2a80ff;
  color: white;
  border: 0;
  border-radius: 6px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 3rem;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 1.5rem;
  min-width: 7rem;
`

export const CancelButton = styled(Button)`
  background: white;
  color: #21272b;
  border: 1px solid #dae0e6;
  border-radius: 6px;
  height: 3rem;
  min-width: 7rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
`

const FrequencySelect = styled.div`
  display: flex;
  width: 40%;
  background: #F0F2F5;
  color: #21272b;
  border: 1px solid #dae0e6;
  border-radius: 6px;
  height: 3rem;
  padding: 0.5rem;
  font-size: 0.8rem;
  align-items: center;
  justify-content: space-around;
  margin-right: 2rem;
`

const FrequencyGroup = styled.div`
  display: flex;
  margin-bottom: 3rem;
`

const InputNumberStyled = styled(InputNumber)`
  border: 0;
  background: #F0F2F5;
  border-left: 1px solid #dae0e6;
  padding-left: 0.2rem!important;
`

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`

export default CustomFrequencyModal