import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Button, Progress} from "antd";
import {ReactComponent as UnfinishedAnswersIcon} from "../../../images/UnfinishedAnswersIcon.svg";
import {useHistory} from "react-router-dom";
import {setUserWindowsState} from "../../../api-wrapper/user/setUserWindowsState";
import {useDispatch, useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import {getCurrentUserProfile} from "../../actions/getUserProfile";
import EnvConfig from "../../../config";
import _ from "lodash";
import {emptyGuid} from "../../screens/helpers";
import {getAllProperties} from "../../actions/properties";

const UnansweredQuestionsComp = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);

  const [percentage, setPercentage] = useState(0)
  const [onboardingStatus, setOnboardingStatus] = useState("")

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty)
      return;
    setPercentage(currentProperty.questionnairePage / 9 * 100)
    setOnboardingStatus(currentProperty.onboardingImprovementsStatus)
  }, [propertyId])

  return (<UnansweredQuestionsWrapper>
    <div style={{display: "flex"}}>
      <Progress
        type="circle"
        percent={onboardingStatus === "completed" ? 100 : percentage}
        width={55}
        trailColor={"white"}
        strokeColor={"#2A80E1"}
        format={() => <UnfinishedAnswersIcon/>}
      />
      <TextWrapper>
        <p style={{fontWeight: 600, color: "black"}}>{onboardingStatus === "completed" ? "Onboarding" : "Unfinished Answers"}</p>
        <p>{onboardingStatus === "completed" ? "Revisit your answers" : "Let's finish"} and we will tailor the improvements to suit your need</p>
        {EnvConfig.API_URL !== "https://uk.livlet.com" && <p>[TEST AND STAGE] <span
            style={{color: "#2A80E1", cursor: "pointer"}}
            onClick={() => {
              setUserWindowsState({
                onboardingInterestSurveyStatus: null,
                onboardingUpkeepStatus: null,
                onboardingContentStatus: null,
                onboardingImprovementsStatus: null,
                mobileWelcomeStatus: null,
                questionnairePage: 1,
                handholdingStatus: null,
                propertyId
              }).then(() => {dispatch(getCurrentUserProfile()); dispatch(getAllProperties())})
            }}
        >Click here to restore your defaults.</span></p>}
      </TextWrapper>
    </div>
    <div>
      <ContinueButton onClick={() => history.push('/questions?origin=propertyDetails')}>
        Continue
      </ContinueButton>
    </div>
  </UnansweredQuestionsWrapper>)
}

const UnansweredQuestionsWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  padding-bottom: 1rem;
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media (max-width: 575px) {
    flex-direction: column;
  }
`

const TextWrapper = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #6B7185;
  margin: 0 1rem;
`

const ContinueButton = styled(Button)`
  border-radius: 6px;
  font-size: 1rem;
  color: white;
  font-weight: 600;
  background: #2A80E1;
  border: 0;
  width: 8.75rem;
  height: 2.5rem;
`

export default  UnansweredQuestionsComp