import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TForwardAttachmentRequest} from "./forwardAttachment";

export const forwardAttachmentPreview = (payload: TForwardAttachmentRequest) => {
  const {attachmentId} = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/forwardAttachment/${attachmentId}/preview`
  );

  return apiPost<any, TForwardAttachmentRequest>(
    url, payload
  );
};
