import React from "react";
import {ContainerItem, ContainerItemWrapper} from "./AddSpaceOptions";
import {ReactComponent as ContentsIcon} from "../../../images/spaces/SpaceLinkContents.svg";
import {ReactComponent as FilesIcon} from "../../../images/spaces/SpaceLinkFiles.svg";
import {ReactComponent as WorksIcon} from "../../../images/spaces/SpaceLinkWorks.svg";
import {ReactComponent as SpacesIcon} from "../../../images/spaces/SpaceLinkSpaces.svg";
import {ReactComponent as NotesIcon} from "../../../images/spaces/SpaceLinkNotes.svg";
import {ReactComponent as CostsIcon} from "../../../images/spaces/SpaceLinkCosts.svg";
import {ReactComponent as ContactsIcon} from "../../../images/spaces/SpaceLinkContacts.svg";
import {ReactComponent as MaintenanceIcon} from "../../../images/spaces/SpaceLinkMaintenances.svg";
import {upkeepTypes} from "../helper";

type Props = {
  parentType: string;
  selectedType: string;
  setSelectedType: (arg: string) => void;
}

const LinkEntityOptions = (props: Props) => {
  const {parentType, selectedType, setSelectedType} = props;

  return <ContainerItemWrapper style={{maxWidth: "100%", marginTop: "0.5rem"}}>
    <ContainerItem
      className={selectedType === "inventory" ? "selected stretched" : "stretched"}
      onClick={() => setSelectedType("inventory")}>
      <ContentsIcon style={{flexShrink: 0}} /> Contents
    </ContainerItem>
    {parentType !== "attachment" && <ContainerItem
      className={selectedType === "attachment" ? "selected stretched" : "stretched"}
      onClick={() => setSelectedType("attachment")}>
      <FilesIcon style={{flexShrink: 0}} /> Documents
    </ContainerItem>}
    <ContainerItem
      className={selectedType === "work" ? "selected stretched" : "stretched"}
      onClick={() => setSelectedType("work")}>
      <WorksIcon style={{flexShrink: 0}}/> Projects
    </ContainerItem>
    <ContainerItem
      className={selectedType === "note" ? "selected stretched" : "stretched"}
      onClick={() => setSelectedType("note")}>
      <NotesIcon style={{flexShrink: 0}} /> Notes
    </ContainerItem>
    <ContainerItem
      className={selectedType === "expense" ? "selected stretched" : "stretched"}
      onClick={() => setSelectedType("expense")}>
      <CostsIcon style={{flexShrink: 0}} /> Expenses
    </ContainerItem>
    <ContainerItem
      className={selectedType === "contact" ? "selected stretched" : "stretched"}
      onClick={() => setSelectedType("contact")}>
      <ContactsIcon style={{flexShrink: 0}} /> Contacts
    </ContainerItem>
    {parentType !== "space" && <ContainerItem
        style={{width: (parentType !== "attachment" && parentType !== "work") ? "100%" : ""}}
        className={selectedType === "space" ? "selected stretched" : "stretched"}
        onClick={() => setSelectedType("space")}>
        <SpacesIcon style={{flexShrink: 0}}/> Spaces
    </ContainerItem>}
    {!upkeepTypes.includes(parentType) && <ContainerItem
      style={{width: parentType !== "work" ? "100%" : ""}}
      className={upkeepTypes.includes(selectedType) ? "selected stretched" : "stretched"}
      onClick={() => setSelectedType("upkeep")}>
        <MaintenanceIcon /> Maintenance
    </ContainerItem>}
  </ContainerItemWrapper>
}

export default LinkEntityOptions;