import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
  paginationButtons,
  ActionButtonGreen,
  ActionButtonWhite,
  DrawerFooter,
  DrawerHeader,
  DrawerStyled,
  PaginationStyled,
  FileActionButton,
  ViewDrawerTabs
} from "../../screens/components";
import {useDispatch, useSelector} from "react-redux";
import {
  getAttachmentsSelector,
  getPropertiesSelector,
  getPropertyIdSelector,
  getPropertyPreferencesSelector
} from "../../selectors";
import AddNewRecordDrawer from "../spaces/AddNewRecordDrawer";
import LinkedItemsList from "../spaces/LinkedItemsList";
import LinkedItemsHeader from "../spaces/LinkedItemsHeader";
import {Guid} from "guid-typescript";
import {ReactComponent as ReturnIcon} from "../../../images/spaces/ReturnIcon.svg";
import {ReactComponent as LinkIcon} from "../../../images/spaces/LinkNewIcon.svg";
import {ReactComponent as EditIcon} from "../../../images/spaces/EditIcon.svg";
import {ReactComponent as DownloadFileIcon} from "../../../images/attachments/DownloadFile.svg";
import {ReactComponent as PreviewIcon} from "../../../images/attachments/Preview.svg";
import {ReactComponent as InformationIcon} from "../../../images/attachments/Information.svg";
import {SpaceDescription} from "../spaces/ViewSpaceDrawer";
import {getAllAttachments} from "../../actions/attachments";
import moment from "moment";
import {isImage, isPdf, isPreviewable} from "./downloadHelpers";
import {downloadAttachment} from "../../../api-wrapper/attachments/downloadAttachment";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import _ from "lodash";
import {Document, Page} from "react-pdf";
import TagContainer from "../tags/TagContainer";
import {getAttachmentById} from "../../../api-wrapper/attachments/getAttachmentById";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import AddLinkedItem from "../spaces/AddLinkedItem";

type Props = {
  attachmentId: Guid | null;
  attachmentsWithoutProperty: Array<any>;
  duplicateAttachments: Array<any>;
  isOpen: boolean;
  toggleDrawer: (arg: boolean) => void;
  toggleEditDrawerOpen: (arg: boolean) => void;
  setAttachmentId: (arg: any) => void;
  selectedTags: Array<any>;
  toggleEditTagsMode: (arg: boolean) => void;
  viewFileDrawerDefaultTab: string | null;

  toggleViewWorkDrawer: (arg: boolean) => void;
  setViewWorkId: (arg: any) => void;
  toggleLinkDrawerOpen: (arg: boolean) => void;
  setPresetLinkType: (arg: string) => void;
  toggleSpaceDrawer: (arg: boolean) => void;
  setEditSpaceId: (arg: any) => void;
  toggleWorkDrawer: (arg: boolean) => void;
  setEditWorkData: (arg: any) => void;
  toggleExpenseDrawer: (arg: boolean) => void;
  setEditExpenseData: (arg: any) => void;
  toggleInventoryDrawer: (arg: boolean) => void;
  setEditInventoryData: (arg: any) => void;
  toggleContactDrawer: (arg: boolean) => void;
  setEditContactData: (arg: any) => void;
  toggleNoteDrawer: (arg: boolean) => void;
  setEditNoteData: (arg: any) => void;
  toggleTaskDrawer: (arg: boolean) => void;
  setEditTaskData: (arg: any) => void;
  refreshParent: any;
  refreshSearch?: () => void;
}

const ViewFileDrawer = (props: Props) => {
  const {attachmentId, isOpen, toggleDrawer, viewFileDrawerDefaultTab, toggleLinkDrawerOpen, toggleEditDrawerOpen, setAttachmentId,
    selectedTags, toggleEditTagsMode, setPresetLinkType, toggleWorkDrawer, toggleContactDrawer, toggleExpenseDrawer, toggleInventoryDrawer,
    toggleNoteDrawer, setEditWorkData, attachmentsWithoutProperty, setEditContactData, setEditExpenseData, setEditInventoryData, setViewWorkId,
    toggleViewWorkDrawer, setEditNoteData, toggleSpaceDrawer, setEditSpaceId, refreshParent, toggleTaskDrawer, setEditTaskData,
    duplicateAttachments, refreshSearch} = props;

  const dispatch = useDispatch();
  const properties = useSelector(getPropertiesSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const attachments = useSelector(getAttachmentsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [searchQuery, setSearchQuery] = useState("");
  const [attachment, setAttachment] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [property, setProperty] = useState("");
  const [attachmentLinks, setAttachmentLinks] = useState<any>([]);
  const [isNewRecordDrawerOpen, toggleNewRecordDrawer] = useState(false);

  const [showPreview, toggleShowPreview] = useState(true);
  const [showInfo, toggleShowInfo] = useState(false);
  const [showLinks, toggleShowLinks] = useState(false);
  const [previewLoading, togglePreviewLoading] = useState(true);
  const [previewLoadingS3Image, togglePreviewLoadingS3Image] = useState(true);
  const [previewLink, setPreviewLink] = useState<any>(null);
  const [numPreviewPages, setPreviewNumPages] = useState<any>(null);
  const [previewOnly, setPreviewOnly] = useState(false);

  const refreshAttachment = () => {
    getAttachmentById(propertyId, attachment.attachmentId).then((res: any) => {
      setAttachmentLinks([...res.spaces, ...res.works, ...res.expenses, ...res.inventories, ...res.contacts, ...res.attachments,
        ...res.attachmentNotes, ...res.recurringExpenses, ...res.reminders, ...res.tasks]);
    });
    // refreshParent && refreshParent();
  }

  useEffect(() => {
    if (viewFileDrawerDefaultTab === "links") {
      toggleShowPreview(false);
      toggleShowLinks(true);
      toggleShowInfo(false);
      setPreviewOnly(false);
    } else if (viewFileDrawerDefaultTab === "details") {
      toggleShowPreview(false);
      toggleShowLinks(false);
      toggleShowInfo(true);
      setPreviewOnly(false);
    } else {
      if (viewFileDrawerDefaultTab === "previewOnly") {
        setPreviewOnly(true);
      } else {
        setPreviewOnly(false);
      }
      togglePreviewLoading(true);
      toggleShowPreview(true);
      toggleShowInfo(false);
      toggleShowLinks(false);
      setPreviewLink(null);
      togglePreviewLoadingS3Image(true);
    }
  }, [isOpen, viewFileDrawerDefaultTab])

  useEffect(() => {
    if (attachmentId) {
      setAttachment(attachments.content.concat(attachmentsWithoutProperty).concat(duplicateAttachments)
        .filter(item => item.attachmentId === attachmentId)[0])
    }
  }, [attachments, attachmentId])

  useEffect(() => {
    if (!isOpen)
      return;
    if (attachment && attachment.attachmentId === attachmentId) {
      const attachmentProperty = properties.find((property) => property.propertyId === attachment.propertyId)
      if (attachmentProperty) {
        setProperty(attachmentProperty.address)
      }
      preparePreview();
      refreshAttachment();
    }
  }, [isOpen, attachment, properties])

  const filteredItems = () => attachmentLinks.filter((item: any) => searchQuery ?
    (item.name?.toLowerCase().includes(searchQuery.toLowerCase()) || item.title?.toLowerCase().includes(searchQuery.toLowerCase())): true)

  const getItems = () => filteredItems()
    .filter((currentValue: any, index: any) => index >= (page - 1) * 10 && index < page * 10);

  const closeDrawer = () => {
    toggleDrawer(false);
    setPage(1);
    togglePreviewLoading(true);
    toggleShowPreview(true);
    toggleShowInfo(false);
    toggleShowLinks(false);
    setPreviewLink(null);
    setAttachmentId(null);
    setPreviewNumPages(null);
    refreshParent();
  }

  useEffect(() => {
    if (getItems().length === 0 && page !== 1) {
      setPage(page - 1)
    }
  }, [getItems()])

  const preparePreview = () => {
    setPreviewLink(null);
    togglePreviewLoading(true);
    if (isPdf(attachment.name) || isImage(attachment.name)) {
      propertyId &&
      attachment.attachmentId &&
      downloadAttachment({
        propertyId,
        id: attachment.attachmentId,
      }).then((res) => {
        setPreviewLink(res);
        togglePreviewLoading(false);
      });
    } else if (isPreviewable(attachment.name) && attachment.documentPreviewPath) {
      setPreviewLink(attachment.documentPreviewPath);
      togglePreviewLoading(false);
    } else {
      togglePreviewLoading(false);
    }
  }

  const previewContent = () => {
    if (previewLoading)
      return <Spin indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />} />
    if (_.isNil(previewLink) || previewLink.length < 5)
      return <p style={{marginTop:"1.5rem"}}>Preview not available</p>

    if (isPreviewable(attachment.name) || isPdf(attachment.name))
      return <Document
          loading={"Loading document…"}
          error={"Failed to load document, please download it to see the content"}
          file={previewLink}
          onLoadSuccess={({ numPages }) => setPreviewNumPages(numPages)}
        >
          {Array.from(
            new Array(numPreviewPages),
            (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className={"livlet-pdf-page"}
              />
            ),
          )}
        </Document>

    if (isImage(attachment.name)) {
      return <>
        <Spin
          style={{display: previewLoadingS3Image ? "block" : "none"}}
          indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
        />
          <img
            style={{display: previewLoadingS3Image ? "none" : "block", width: "100%"}}
            onLoad={() => togglePreviewLoadingS3Image(false)}
            alt="preview"
            src={previewLink}
          />
        </>
    }

    return <p style={{marginTop:"1.5rem"}}>Preview not available</p>
  }

  const fileInfo = () => <FileDescription>
    <div className={"row-title"}>File name</div>
    <div className={"row-description"}>{attachment ? attachment.name : null}</div>
    <div className={"row-title"}>Address</div>
    <div className={"row-description"} style={{color: "#9CA4AE"}}>{property}</div>
    <div className={"row-title"}>Date uploaded</div>
    <div className={"row-description"}>{attachment ? moment(attachment.uploadedDate).format(propertyPreferences.preferredDateFormat) ?? "-" : null}</div>
    <div className={"row-title"}>Description</div>
    <div className={"row-description"}>{attachment ? attachment.description ?? "-" : null}</div>
    <div className={"row-title"}>Tags</div>
    {attachment && attachment.propertyId && <div className={"row-description"}>
      <TagContainer
        parentTags={attachment.tags}
        parentId={attachment.attachmentId}
        parentType={"attachment"}
        selectedTags={selectedTags}
        toggleGlobalEditMode={toggleEditTagsMode}
        refreshParent={() => {
          dispatch(getAllAttachments(propertyId));
          refreshSearch && refreshSearch();
        }}
      />
    </div>}
  </FileDescription>

  const desktopTabs = () => <div style={{display: "flex", alignItems: "center", columnGap: "1rem"}}>
    <FileActionButton className={showPreview ? "active" : ""} onClick={() => {
      toggleShowPreview(true);
      toggleShowInfo(false);
      toggleShowLinks(false);
    }}><PreviewIcon/> Preview</FileActionButton>
    <FileActionButton className={showInfo ? "active" : ""} onClick={() => {
      toggleShowPreview(false);
      toggleShowInfo(true);
      toggleShowLinks(true);
    }}><InformationIcon/> Document information</FileActionButton>
  </div>

  const mobileTabs = () => <ViewDrawerTabs style={{marginBottom: "1rem"}}>
    <span className={showPreview ? "selected" : "default"} onClick={() => {
      toggleShowPreview(true);
      toggleShowInfo(false);
      toggleShowLinks(false);
    }}>Preview</span>
    <span className={showInfo ? "selected" : "default"} onClick={() => {
      toggleShowPreview(false);
      toggleShowInfo(true);
      toggleShowLinks(false);
    }}>Details</span>
    {!previewOnly && attachment && attachment.propertyId && <span className={showLinks ? "selected" : "default"} onClick={() => {
      toggleShowPreview(false);
      toggleShowInfo(false);
      toggleShowLinks(true);
    }}>Links</span>}
  </ViewDrawerTabs>

  return <>
    <AddNewRecordDrawer
      isOpen={isNewRecordDrawerOpen}
      toggleDrawerOpen={toggleNewRecordDrawer}
      parentId={attachment ? attachment.attachmentId : null}
      parentType={"attachment"}
      refreshParent={() => dispatch(getAllAttachments(propertyId))}
    />
    <ViewSpaceDrawerStyled
      push={{distance: "32px"}}
      closeIcon={null}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "100%" : "85%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      visible={isOpen}
      onClose={() => closeDrawer()}
      title={<>
        <DrawerHeader style={{flexDirection: "column", alignItems:"flex-start", rowGap:"0.5rem", marginBottom:"1.5rem"}}>
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection:"row", width:"100%"}}>
            <div style={{display: "flex", alignItems: "center", columnGap: "1rem"}}>
              <ReturnIcon style={{cursor: "pointer"}} onClick={() => closeDrawer()} />
              <Ellipsis lines={1}>{attachment ? attachment.name : null}</Ellipsis>
            </div>
            {!previewOnly && <div style={{display: "flex", alignItems: "center"}}>
              {attachment && attachment.propertyId && <EditIcon style={{cursor: "pointer"}} onClick={() => {
                toggleEditDrawerOpen(true)
              }} />}
            </div>}
          </div>
        </DrawerHeader>
        {window.innerWidth > 768 ? desktopTabs() : mobileTabs()}
        {showInfo && window.innerWidth > 768 && fileInfo()}
        {(!previewOnly && showLinks && attachmentLinks.length > 0) && <LinkedItemsHeader
            title={"Linked items"}
            toggleLinkDrawerOpen={toggleDrawer}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            toggleAddNewRecordDrawer={toggleNewRecordDrawer}
            parentId={attachment.attachmentId}
            parentType={"attachment"}
        />}
      </>}
      footer={<DrawerFooter>
        <ActionButtonWhite onClick={() => {
          propertyId &&
          attachment.attachmentId &&
          downloadAttachment({
            propertyId,
            id: attachment.attachmentId,
          }).then((res) => window.open(res, "_blank"))
        }}><DownloadFileIcon /> Download</ActionButtonWhite>
        {!previewOnly && attachment && attachment.propertyId && <ActionButtonGreen onClick={() =>
          toggleLinkDrawerOpen(true)}><LinkIcon /> Link new</ActionButtonGreen>}
      </DrawerFooter>}
    >
      {showInfo && window.innerWidth <= 768 && fileInfo()}
      {showPreview && <PreviewWrapper>{previewContent()}</PreviewWrapper>}
      {(!previewOnly && showLinks && attachment && attachment.propertyId && attachmentLinks.length === 0) && <AddLinkedItem
          toggleLinkDrawerOpen={toggleLinkDrawerOpen}
          toggleNewRecordDrawer={toggleNewRecordDrawer}
          setPresetLinkType={setPresetLinkType}
          excludeTypes={["attachment"]}
      />}
      {(!previewOnly && showLinks && attachment && attachment.propertyId && attachmentLinks.length > 0) && <LinkedItemsList
        parentId={attachment.attachmentId}
        parentType={"attachment"}
        items={getItems()}
        searchQuery={searchQuery}
        isSelectionMode={false}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditNoteData}
        refreshParent={refreshAttachment}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleSpaceDrawer}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditTaskData}
        setViewWorkId={setViewWorkId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentId}
        toggleViewFileDrawer={toggleDrawer}
      />}
      {showLinks && filteredItems().length > 10 && <PaginationStyled
          className={filteredItems().length < 10 ? "single-page-pagination" : ""}
          onChange={(page) => setPage(page)}
          simple={window.innerWidth < 1024}
          showSizeChanger={false}
          itemRender={paginationButtons}
          defaultCurrent={1}
          current={page}
          total={filteredItems().length}
          defaultPageSize={10}
      />}
    </ViewSpaceDrawerStyled>
  </>
}

const ViewSpaceDrawerStyled = styled(DrawerStyled)`
  .ant-drawer-body {
    margin: 0.5rem 0 1.5rem 0!important;
    overflow-x: hidden;
  }
`

const PreviewWrapper = styled.div`
  border-top: 1px solid #E6E6E6; 
  padding-top: 1rem; 
  margin-top: 0.5rem;
  
  @media (max-width: 768px) { 
    border: none;
    margin: 0;
    padding: 0;
  }
`

const FileDescription = styled(SpaceDescription)`
  @media (max-width: 768px) { 
    border: none;
    margin: 0;
    padding: 0;
  }
`

export default ViewFileDrawer