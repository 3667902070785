import React, {useEffect, useState} from "react";
import {ReactComponent as SimulatorHomeIcon} from "../../images/SimulatorHome.svg";
import {ReactComponent as SimulatorImprovedHomeIcon} from "../../images/SimulatorImprovedHome.svg";
import styled from "styled-components";
import {SaveButton} from "../components/works/WorkDrawer";
import {Button, Radio, Select, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
  getImprovementsSelector,
  getPropertiesSelector,
  getPropertyIdSelector,
  getPropertyPreferencesSelector
} from "../selectors";
import {getAllImprovements} from "../actions/improvements";
import {TFetchingStatus} from "../../helpers";
import {PropertyQuestionAnswer} from "../../api-wrapper/properties/getImprovements";
import {numberWithCommas, propertyYearReversedConversion} from "../components/helper";
import {InputNumberStyled} from "../components/expenses/ExpenseDrawer";
import {LoadingOutlined} from "@ant-design/icons";
import {getAllProperties} from "../actions/properties";
import {simulateValue} from "../../api-wrapper/properties/simulateValue";
import PropertyValueDetailsDrawer from "../components/properties/PropertyValueDetailsDrawer";
import {ReactComponent as GrowthUpIcon} from "../../images/dashboard-homevalue/GrowthUp.svg";
import {ReactComponent as GrowthDownIcon} from "../../images/dashboard-homevalue/GrowthDown.svg";
import {ReactComponent as ValueSimulatorHomeIcon} from "../../images/ValueSimulatorHomeIcon.svg";
import {InputStyled} from "./RegistrationPage";
import {PageHeader, PageSubtitle, PageTitle, PageWrapper} from "./components";
import {getCurrencySign} from "./helpers";

const ValueSimulator = () => {
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const improvementsConfiguration = useSelector(getImprovementsSelector);
  const properties = useSelector(getPropertiesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [questions, setQuestions] = useState<Array<any>>([]);

  const [propertyValueDetailsModalIsOpen, togglePropertyValueDetailsModal] = useState(false);

  const [propertyTypeAnswerTxt, setPropertyTypeAnswerTxt] = useState<any>(null);
  const [buildYear, setBuildYear] = useState<any>(null);
  const [propertySize, setPropertySize] = useState<any>(null);
  const [bedrooms, setBedrooms] = useState<any>(null);
  const [bathrooms, setBathrooms] = useState<any>(null);
  const [finishQualityAnswerTxt, setFinishQualityAnswerTxt] = useState<any>(null);
  const [parkingPlacesAnswerTxt, setParkingPlacesAnswerTxt] = useState<any>(null);
  const [outdoorSpaceAnswerTxt, setOutdoorSpaceAnswerTxt] = useState<any>("garden");
  const [propertyValue, setPropertyValue] = useState<any>(null);

  const [propertyPostcodeSimulated, setPropertyPostcodeSimulated] = useState<any>(null);
  const [propertyTypeSimulated, setPropertyTypeSimulated] = useState<any>(null);
  const [propertySizeSimulated, setPropertySizeSimulated] = useState<any>(null);
  const [buildYearSimulated, setBuildYearSimulated] = useState<any>(null);
  const [previousPropertySizeUnitSimulated, setPreviousPropertySizeUnitSimulated] = useState("");
  const [propertySizeUnitSimulated, setPropertySizeUnitSimulated] = useState("m2");
  const [bedroomsSimulated, setBedroomsSimulated] = useState<any>(null);
  const [bathroomsSimulated, setBathroomsSimulated] = useState<any>(null);
  const [finishQualitySimulated, setFinishQualitySimulated] = useState<any>(null);
  const [parkingPlacesSimulated, setParkingPlacesSimulated] = useState<any>(null);
  const [outdoorSpaceSimulated, setOutdoorSpaceSimulated] = useState<any>("garden");
  const [propertyValueSimulated, setPropertyValueSimulated] = useState<any>(null);

  const [estimatingLoader, setEstimatingLoader] = useState(false);
  const [estimatingError, setEstimatingError] = useState(false);

  const [showEmptyState, setShowEmptyState] = useState(true);
  const [homeValueRefreshing, setHomeValueRefreshing] = useState(false);

  useEffect(() => {
    dispatch(getAllImprovements(propertyId));
    dispatch(getAllProperties());
  }, [propertyId]);

  useEffect(() => {
    if (improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success)
      return;

    setQuestions([...improvementsConfiguration.content.questions]);
    const questions = [...improvementsConfiguration.content.questions];

    let questionsAnswered = 0;

    if (improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 1)[0]?.answerId) {
      const propertyType = questions.filter(question => question.id === 1).map(question => question.answers
        .filter((answer: PropertyQuestionAnswer) => answer.id === improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 1)[0].answerId)[0].answer)[0];
      setPropertyTypeAnswerTxt(propertyType);
      if (propertyType.startsWith("Flat")) {
        setPropertyTypeSimulated("flat");
      } else if (propertyType === "House Semi-Detached") {
        setPropertyTypeSimulated("semi-detached_house");
      } else if (propertyType === "House Detached") {
        setPropertyTypeSimulated("detached_house");
      } else if (propertyType === "House Terraced") {
        setPropertyTypeSimulated("terraced_house");
      }
      questionsAnswered++;
    } else {
      setPropertyTypeAnswerTxt(null);
    }

    if (improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 3)[0]?.answer) {
      const buildYear = improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 3)[0].answer;
      setBuildYear(propertyYearReversedConversion(buildYear));
      if (buildYear < 1914) {
        setBuildYearSimulated("pre_1914");
      } else if (buildYear < 2000) {
        setBuildYearSimulated("1914_2000");
      } else {
        setBuildYearSimulated("2000_onwards");
      }
      questionsAnswered++;
    } else {
      setBuildYear(null);
    }

    if (improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 2)[0]?.answer) {
      setPropertySize(improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 2)[0].answer);
      setPropertySizeSimulated(improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 2)[0].answer);
      setPropertySizeUnitSimulated("m2");
      questionsAnswered++;
    } else {
      setPropertySize(null);
    }

    if (improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 5)[0]?.answer) {
      const bedrooms = improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 5)[0].answer
      setBedrooms(bedrooms);
      if (bedrooms < 0)
        setBedroomsSimulated(0);
      else if (bedrooms > 5)
        setBedroomsSimulated(0);
      else
        setBedroomsSimulated(bedrooms);
      questionsAnswered++;
    } else {
      setBathrooms(null);
    }

    if (improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 6)[0]?.answer) {
      const bathrooms = improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 6)[0].answer;
      setBathrooms(bathrooms);
      if (bathrooms < 0)
        setBathroomsSimulated(0);
      else if (bathrooms > 5)
        setBathroomsSimulated(0);
      else
        setBathroomsSimulated(bathrooms);
      questionsAnswered++;
    } else {
      setBathrooms(null);
    }

    if (improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 22)[0]?.answerId) {
      const finishQuality = questions.filter(question => question.id === 52).map(question => question.answers
        .filter((answer: PropertyQuestionAnswer) => answer.id === improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 22)[0].answerId)[0].answer)[0];
      setFinishQualityAnswerTxt(finishQuality);
      if (finishQuality === "Below average")
        setFinishQualitySimulated("below_average")
      else if (finishQuality === "Average")
        setFinishQualitySimulated("average")
      else
        setFinishQualitySimulated("high")
      questionsAnswered++;
    } else {
      setFinishQualityAnswerTxt(null);
    }

    if (improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 24)[0]?.answerId) {
      const parkingPlaces = questions.filter(question => question.id === 54).map(question => question.answers
        .filter((answer: PropertyQuestionAnswer) => answer.id === improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 24)[0].answerId)[0].answer)[0];
      setParkingPlacesAnswerTxt(parkingPlaces);
      setParkingPlacesSimulated(parkingPlaces.slice(0,1));
      questionsAnswered++;
    } else {
      setParkingPlacesAnswerTxt(null);
    }

    if (improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 23)[0]?.answerId) {
      const outdoorSpacer = questions.filter(question => question.id === 53).map(question => question.answers
        .filter((answer: PropertyQuestionAnswer) => answer.id === improvementsConfiguration.content.propertyDetails.filter((detail) => detail.id === 23)[0].answerId)[0].answer)[0];
      setOutdoorSpaceAnswerTxt(outdoorSpacer);
      if (outdoorSpacer === "None") {
        setOutdoorSpaceSimulated("none");
      } else if (outdoorSpacer === "Balcony / Terrace") {
        setOutdoorSpaceSimulated("balcony_terrace");
      } else if (outdoorSpacer === "Garden") {
        setOutdoorSpaceSimulated("garden");
      } else if (outdoorSpacer === "Garden (1+ Acre)") {
        setOutdoorSpaceSimulated("garden_very_large");
      }
      questionsAnswered++;
    } else {
      setOutdoorSpaceAnswerTxt(null);
    }

    if (questionsAnswered === 8 && improvementsConfiguration.content.propertyHighlights.propertyValueType === "extended") {
      setPropertyValue(improvementsConfiguration.content.propertyHighlights.propertyValue);
      setPropertyValueSimulated(improvementsConfiguration.content.propertyHighlights.propertyValue);
      setShowEmptyState(false);
    } else {
      setShowEmptyState(true);
    }

    const currentProperty = properties.find((property) => property.propertyId === propertyId);
    if (!currentProperty)
      return;
    setPropertyPostcodeSimulated(currentProperty.zip);

  }, [improvementsConfiguration]);

  const refreshHomeValueEmptyScreen = (saveDataPromise: Promise<any>) => {
    setHomeValueRefreshing(true);
    saveDataPromise.finally(() => {
      dispatch(getAllImprovements(propertyId));
      setHomeValueRefreshing(false);
    });
  }

  const handleCalculate = async () => {
    const internalArea = propertySizeUnitSimulated === "sqft" ? propertySizeSimulated : Math.round(propertySizeSimulated * 10.76);

    const query = {
      propertyId,
      postCode: propertyPostcodeSimulated,
      propertyType: propertyTypeSimulated,
      constructionDate: buildYearSimulated,
      internalArea,
      bedrooms: bedroomsSimulated,
      bathrooms: bathroomsSimulated,
      finishQuality: finishQualitySimulated,
      offStreetParking: parkingPlacesSimulated,
      outdoorSpace: outdoorSpaceSimulated,
    }

    setEstimatingLoader(true);
    setEstimatingError(false);
    await new Promise(resolve => setTimeout(resolve, 3000));
    simulateValue(query).then((res) => {
      if (!res.data) {
        setEstimatingError(true);
      } else {
        setPropertyValueSimulated(res.data.propertyValue);
      }
      setEstimatingLoader(false);
    });
  }

  const convertSize = (propertySizeUnit: string) => {
    if (propertySizeUnit === "m2") {
      setPropertySizeSimulated(Math.round(propertySizeSimulated / 10.76))
    }
    if (propertySizeUnit === "sqft") {
      setPropertySizeSimulated(Math.round(propertySizeSimulated * 10.76))
    }
  };

  const currentPropertyHeader = () => (
    <ValueSimulatorLeftColumnItem>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems:"center", padding: "1rem 0", borderBottom: "1px solid #EFF1F5"}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", fontSize: "1.625rem", fontWeight: 700}}>
          <SimulatorHomeIcon style={{marginRight:"0.5rem"}}/>I have
        </div>
        <div>
          {window.innerWidth > 1024 ? <a style={{color: "#236EC2"}}
            onClick={() => togglePropertyValueDetailsModal(true)}>Edit property</a> :
          <ButtonStyled onClick={() => togglePropertyValueDetailsModal(true)}>Edit property</ButtonStyled>}
        </div>
      </div>
    </ValueSimulatorLeftColumnItem>
  )

  const estimatedPropertyHeader = () => (
    <ValueSimulatorRightColumnItem>
      <div style={{display: "flex", flexDirection: "row", alignItems: "center", fontSize: "1.625rem", fontWeight: 700, padding: "1rem 0", borderBottom: "1px solid #EFF1F5"}}>
        <SimulatorImprovedHomeIcon style={{marginRight:"0.5rem"}}/>What if I had
      </div>
    </ValueSimulatorRightColumnItem>
  )

  const currentPropertyType = () => (
    <ValueSimulatorLeftColumnItem>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems:"center"}}>
        <div style={{width:"50%", marginRight:"0.5rem", paddingRight:"0.5rem"}}>
          <ValueSimulatorLeftFieldLabel>Property type</ValueSimulatorLeftFieldLabel>
          <ValueSimulatorLeftField>{propertyTypeAnswerTxt}</ValueSimulatorLeftField>
        </div>
        <div style={{width:"50%"}}>
          <ValueSimulatorLeftFieldLabel>Construction Year</ValueSimulatorLeftFieldLabel>
          <ValueSimulatorLeftField>{buildYear}</ValueSimulatorLeftField>
        </div>
      </div>
    </ValueSimulatorLeftColumnItem>
  )

  const estimatedPropertyType = () => (
    <ValueSimulatorRightColumnItem style={{display: "flex", flexDirection: "column",  justifyContent: "flex-end", height: "3rem"}}>
      See how your property value estimate changes as a result of changing the following parameters:
    </ValueSimulatorRightColumnItem>
  )

  const estimatedPostcodeArea = () => (
    <ValueSimulatorRightColumnItem>
      <ValueSimulatorLeftFieldLabel>Postcode</ValueSimulatorLeftFieldLabel>
      <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}>
        <InputStyled
          bordered={false}
          maxLength={10}
          autoComplete={"newpassword"}
          style={{
            width: "100%",
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "6px",
            color: "rgb(107,113,133)",
            height: "2.5rem",
            display: "flex",
            alignItems: "center"
          }}
          onChange={(e) => setPropertyPostcodeSimulated(e.target.value)}
          value={propertyPostcodeSimulated}
        />
      </div>
    </ValueSimulatorRightColumnItem>
  )

  const currentInternalArea = () => (
    <ValueSimulatorLeftColumnItem>
      <ValueSimulatorLeftFieldLabel>Internal area</ValueSimulatorLeftFieldLabel>
      <ValueSimulatorLeftField>
        {propertySizeUnitSimulated === "m2" ? propertySize : Math.round(propertySize * 10.76)}
        {" "}
        {propertySizeUnitSimulated}
      </ValueSimulatorLeftField>
    </ValueSimulatorLeftColumnItem>
  )

  const estimatedInternalArea = () => (
    <ValueSimulatorRightColumnItem>
      <ValueSimulatorLeftFieldLabel>Internal area</ValueSimulatorLeftFieldLabel>
      <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}>
        <InputNumberStyled
          bordered={false}
          maxLength={10}
          precision={0}
          min={0}
          style={{
            width: "100%",
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "6px",
            color: "rgb(107,113,133)",
            height: "2.5rem",
            display: "flex",
            alignItems: "center"
          }}
          onChange={(val) => setPropertySizeSimulated(val)}
          value={propertySizeSimulated}
        />
        <Radio.Group
          style={{display:"flex", flexDirection:"row", alignItems: "center", height: "2.5rem", fontSize:"1rem", marginLeft:"1rem"}}
          value={propertySizeUnitSimulated}
          onChange={e => {setPropertySizeUnitSimulated(e.target.value); convertSize(e.target.value)}}
        >
          <div>
            <Radio value={"m2"}>m2</Radio>
          </div>
          <div>
            <Radio value={"sqft"}>sqft</Radio>
          </div>
        </Radio.Group>
      </div>
    </ValueSimulatorRightColumnItem>
  )

  const currentBedrooms = () => (
    <ValueSimulatorLeftColumnItem>
      <ValueSimulatorLeftFieldLabel>Number of bedrooms</ValueSimulatorLeftFieldLabel>
      <ValueSimulatorLeftField>{bedrooms}</ValueSimulatorLeftField>
    </ValueSimulatorLeftColumnItem>
  )

  const estimatedBedrooms = () => (
    <ValueSimulatorRightColumnItem>
      <ValueSimulatorLeftFieldLabel>Number of bedrooms</ValueSimulatorLeftFieldLabel>
      <Select
        bordered={false}
        getPopupContainer={trigger => trigger.parentElement}
        style={{
          width: "100%",
          border: "1px solid #DAE0E6",
          boxSizing: "border-box",
          borderRadius: "6px",
          color: "rgb(107,113,133)",
          height: "2.5rem",
        }}
        onChange={(val) => setBedroomsSimulated(val)}
        value={bedroomsSimulated}
      >
        <Select.Option key={0} value={0}>0</Select.Option>
        <Select.Option key={1} value={1}>1</Select.Option>
        <Select.Option key={2} value={2}>2</Select.Option>
        <Select.Option key={3} value={3}>3</Select.Option>
        <Select.Option key={4} value={4}>4</Select.Option>
        <Select.Option key={5} value={5}>5 or more</Select.Option>
      </Select>
    </ValueSimulatorRightColumnItem>
  )

  const currentBathrooms = () => (
    <ValueSimulatorLeftColumnItem>
      <ValueSimulatorLeftFieldLabel>Number of bathrooms</ValueSimulatorLeftFieldLabel>
      <ValueSimulatorLeftField>{bathrooms}</ValueSimulatorLeftField>
    </ValueSimulatorLeftColumnItem>
  )

  const estimatedBathrooms = () => (
    <ValueSimulatorRightColumnItem>
      <ValueSimulatorLeftFieldLabel>Number of bathrooms</ValueSimulatorLeftFieldLabel>
      <Select
        bordered={false}
        getPopupContainer={trigger => trigger.parentElement}
        style={{
          width: "100%",
          border: "1px solid #DAE0E6",
          boxSizing: "border-box",
          borderRadius: "6px",
          color: "rgb(107,113,133)",
          height: "2.5rem",
        }}
        onChange={(val) => setBathroomsSimulated(val)}
        value={bathroomsSimulated}
      >
        <Select.Option value={0}>0</Select.Option>
        <Select.Option value={1}>1</Select.Option>
        <Select.Option value={2}>2</Select.Option>
        <Select.Option value={3}>3</Select.Option>
        <Select.Option value={4}>4</Select.Option>
        <Select.Option value={5}>5 or more</Select.Option>
      </Select>
    </ValueSimulatorRightColumnItem>
  )

  const currentInteriorFinish = () => (
    <ValueSimulatorLeftColumnItem>
      <ValueSimulatorLeftFieldLabel>Interior finish quality</ValueSimulatorLeftFieldLabel>
      <ValueSimulatorLeftField>{finishQualityAnswerTxt}</ValueSimulatorLeftField>
    </ValueSimulatorLeftColumnItem>
  )

  const estimatedInteriorFinish = () => (
    <ValueSimulatorRightColumnItem>
      <ValueSimulatorLeftFieldLabel>Interior finish quality</ValueSimulatorLeftFieldLabel>
      <Radio.Group
        style={{display:"flex", flexDirection:"row", alignItems: "center", height: "2.5rem", fontSize:"1rem", width:"100%"}}
        value={finishQualitySimulated}
        onChange={e => setFinishQualitySimulated(e.target.value)}
      >
        <div>
          <Radio value={"below_average"}>Below average</Radio>
        </div>
        <div>
          <Radio value={"average"}>Average</Radio>
        </div>
        <div>
          <Radio value={"high"}>High</Radio>
        </div>
      </Radio.Group>
    </ValueSimulatorRightColumnItem>
  )

  const currentParkingSpaces = () => (
    <ValueSimulatorLeftColumnItem>
      <ValueSimulatorLeftFieldLabel>Number of off-street parking spaces</ValueSimulatorLeftFieldLabel>
      <ValueSimulatorLeftField>{parkingPlacesAnswerTxt}</ValueSimulatorLeftField>
    </ValueSimulatorLeftColumnItem>
  )

  const estimatedParkingSpaces = () => (
    <ValueSimulatorRightColumnItem>
      <ValueSimulatorLeftFieldLabel>Number of off-street parking spaces</ValueSimulatorLeftFieldLabel>
      <Select
        bordered={false}
        getPopupContainer={trigger => trigger.parentElement}
        style={{
          width: "100%",
          border: "1px solid #DAE0E6",
          boxSizing: "border-box",
          borderRadius: "6px",
          color: "rgb(107,113,133)",
          height: "2.5rem",
        }}
        onChange={(val) => setParkingPlacesSimulated(val)}
        value={parkingPlacesSimulated}
      >
        <Select.Option value={0}>0</Select.Option>
        <Select.Option value={1}>1</Select.Option>
        <Select.Option value={2}>2</Select.Option>
        <Select.Option value={3}>3 or more</Select.Option>
      </Select>
    </ValueSimulatorRightColumnItem>
  )

  const currentOutdoorSpacer = () => (
    <ValueSimulatorLeftColumnItem>
      <ValueSimulatorLeftFieldLabel>Outdoor Space</ValueSimulatorLeftFieldLabel>
      <ValueSimulatorLeftField>{outdoorSpaceAnswerTxt}</ValueSimulatorLeftField>
    </ValueSimulatorLeftColumnItem>
  )

  const estimatedOutdoorSpacer = () => (
    <ValueSimulatorRightColumnItem>
      <ValueSimulatorLeftFieldLabel>Outdoor Space</ValueSimulatorLeftFieldLabel>
      <Select
        bordered={false}
        getPopupContainer={trigger => trigger.parentElement}
        style={{
          width: "100%",
          border: "1px solid #DAE0E6",
          boxSizing: "border-box",
          borderRadius: "6px",
          color: "rgb(107,113,133)",
          height: "2.5rem",
        }}
        onChange={(val) => setOutdoorSpaceSimulated(val)}
        value={outdoorSpaceSimulated}
      >
        <Select.Option value={"none"}>None</Select.Option>
        <Select.Option value={"balcony_terrace"}>Balcony / Terrace</Select.Option>
        <Select.Option value={"garden"}>Garden</Select.Option>
        <Select.Option value={"garden_very_large"}>Garden (1+ Acre)</Select.Option>
      </Select>
    </ValueSimulatorRightColumnItem>
  )

  const currentDivider = () => (
    <ValueSimulatorLeftColumnItem>
      <div>
        &nbsp;
      </div>
    </ValueSimulatorLeftColumnItem>
  )

  const calculateEstimateButton = () => (
    <ValueSimulatorRightColumnItem style={{display: "flex", justifyContent: "flex-end", padding: "2rem 1rem"}}>
      <>
        <SaveButton
          style={{
            display: "flex",
            alignItems: "center",
            padding: "1.25rem 1.25rem",
            fontSize: "1rem",
            fontWeight: 600,
          }}
          disabled={estimatingLoader}
          type={"primary"}
          htmlType={"submit"}
          onClick={() => handleCalculate()}
        >
          Calculate
        </SaveButton>
      </>
    </ValueSimulatorRightColumnItem>
  )

  const currentValue = () => (
    <ValueSimulatorLeftColumnItem>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems:"center", padding: "1.5rem 0", borderTop: "1px solid #EFF1F5"}}>
        <span style={{fontSize: "1rem"}}>Current estimated value:</span>
        <span style={{fontSize:"1.625rem", fontWeight:700, marginLeft: "1rem"}}>{getCurrencySign(propertyPreferences.preferredCurrency) + numberWithCommas(propertyValue)}</span>
      </div>
    </ValueSimulatorLeftColumnItem>
  )

  const estimatedValue = () => (
    <ValueSimulatorRightColumnItem>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems:"center", padding: "1.5rem 0", borderTop: "1px solid #EFF1F5"}}>
        {estimatingLoader ? <>
            <span style={{fontSize: "1rem"}}>Estimating value...</span>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 50, marginLeft: "1rem" }} spin />} />
          </>
          : estimatingError ? <>
              <span style={{fontSize: "1rem", color: "red"}}>Cannot estimate the value, please change parameters and try again</span>
            </>
            : <>
              <span style={{fontSize: "1rem"}}>Estimated value:</span>
              <span style={{fontSize:"1.625rem", fontWeight:700, marginLeft: "1rem"}}>{getCurrencySign(propertyPreferences.preferredCurrency) + numberWithCommas(propertyValueSimulated)}</span>
              {propertyValueSimulated > propertyValue && <GrowthUpIcon/>}
              {propertyValueSimulated < propertyValue && <GrowthDownIcon/>}
            </>
        }
      </div>
    </ValueSimulatorRightColumnItem>
  )

  const valueSimulatorDesktop = () => (
    <ValueSimulatorWrapperDesktop>
      <ValueSimulatorRow>
        {currentPropertyHeader()}
        {estimatedPropertyHeader()}
      </ValueSimulatorRow>
      <ValueSimulatorRow>
        <ValueSimulatorLeftColumnItem>&nbsp;</ValueSimulatorLeftColumnItem>
        {estimatedPropertyType()}
      </ValueSimulatorRow>
      <ValueSimulatorRow>
        {currentPropertyType()}
        {estimatedPostcodeArea()}
      </ValueSimulatorRow>
      <ValueSimulatorRow>
        {currentInternalArea()}
        {estimatedInternalArea()}
      </ValueSimulatorRow>
      <ValueSimulatorRow>
        {currentBedrooms()}
        {estimatedBedrooms()}
      </ValueSimulatorRow>
      <ValueSimulatorRow>
        {currentBathrooms()}
        {estimatedBathrooms()}
      </ValueSimulatorRow>
      <ValueSimulatorRow>
        {currentInteriorFinish()}
        {estimatedInteriorFinish()}
      </ValueSimulatorRow>
      <ValueSimulatorRow>
        {currentParkingSpaces()}
        {estimatedParkingSpaces()}
      </ValueSimulatorRow>
      <ValueSimulatorRow>
        {currentOutdoorSpacer()}
        {estimatedOutdoorSpacer()}
      </ValueSimulatorRow>
      <ValueSimulatorRow >
        {currentDivider()}
        {calculateEstimateButton()}
      </ValueSimulatorRow>
      <ValueSimulatorRow style={{color: "#666F7A"}}>
        {currentValue()}
        {estimatedValue()}
      </ValueSimulatorRow>
    </ValueSimulatorWrapperDesktop>
  )

  const valueSimulatorMobile = () => (
    <>
      <ValueSimulatorWrapperMobile style={{marginBottom: "2.5rem"}}>
        {currentPropertyHeader()}
        <ValueSimulatorLeftColumnItem style={{marginTop: "1rem"}}>
          Here's what we know about your property.
        </ValueSimulatorLeftColumnItem>
        {currentPropertyType()}
        {currentInternalArea()}
        {currentBedrooms()}
        {currentBathrooms()}
        {currentInteriorFinish()}
        {currentParkingSpaces()}
        {currentOutdoorSpacer()}
        {currentDivider()}
        {currentValue()}
      </ValueSimulatorWrapperMobile>
      <ValueSimulatorWrapperMobile>
        {estimatedPropertyHeader()}
        <div style={{marginTop: "1rem"}} />
        {estimatedPropertyType()}
        {estimatedPostcodeArea()}
        {estimatedInternalArea()}
        {estimatedBedrooms()}
        {estimatedBathrooms()}
        {estimatedInteriorFinish()}
        {estimatedParkingSpaces()}
        {estimatedOutdoorSpacer()}
        {calculateEstimateButton()}
        {estimatedValue()}
      </ValueSimulatorWrapperMobile>
    </>
  )

  if (showEmptyState)
    return (<PageWrapper>
      <PropertyValueDetailsDrawer
        isOpen={propertyValueDetailsModalIsOpen}
        toggleDrawerOpen={togglePropertyValueDetailsModal}
        refreshParent={refreshHomeValueEmptyScreen}
      />
      <PageHeader>
        <div>
          <PageTitle>Property Value Simulator</PageTitle>
          <PageSubtitle>See how your property value changes as a result of home upgrades</PageSubtitle>
        </div>
      </PageHeader>
      <ValueSimulatorWrapper style={{alignItems: "center"}}>
        {window.innerWidth > 1024 && <PropertyValuePageHeader>Welcome to our property value simulator</PropertyValuePageHeader>}
        {window.innerWidth < 1024 && <p><ValueSimulatorHomeIcon/></p>}
        <p style={{maxWidth:"45rem", textAlign:"center", color:"#666F7A"}}>
          Here you can estimate how your property value would change by changing key parameters in your home, such as internal area, bedrooms, bathrooms, refurbishing interior quality as well as external space.
        </p>
        <p style={{maxWidth:"45rem", textAlign:"center", color:"#2A80E1", fontWeight: 600, marginTop:"1rem"}}>
          Before we get started, let’s evaluate your current home value.
        </p>
        <SaveButton
          style={{
            display: "flex",
            alignItems: "center",
            padding: "1.25rem 1.25rem",
            fontSize: "1rem",
            fontWeight: 600,
            marginTop: "1rem",
            marginBottom: window.innerWidth > 1024 ? "5rem" : "0"
          }}
          disabled={estimatingLoader || homeValueRefreshing}
          type={"primary"}
          htmlType={"submit"}
          onClick={() => togglePropertyValueDetailsModal(true)}
        >
          {homeValueRefreshing ?
            <>
              Evaluating <Spin indicator={<LoadingOutlined style={{fontSize: 24, marginLeft:"1rem"}} spin/>}/>
            </>
          : <>Estimate my home’s current value &nbsp; &gt;</>}
        </SaveButton>
      </ValueSimulatorWrapper></PageWrapper>)
  else return (<PageWrapper>
    <PropertyValueDetailsDrawer
      isOpen={propertyValueDetailsModalIsOpen}
      toggleDrawerOpen={togglePropertyValueDetailsModal}/>
    <PageHeader>
      <div>
        <PageTitle>Property Value Simulator</PageTitle>
        <PageSubtitle>See how your property value changes as a result of home upgrades</PageSubtitle>
      </div>
    </PageHeader>
    {valueSimulatorDesktop()}
    {valueSimulatorMobile()}
  </PageWrapper>)
}

const ValueSimulatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.625rem;
`;

const ValueSimulatorWrapperDesktop = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.625rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const ValueSimulatorWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.625rem;
  margin: 0 1rem;
  padding: 1rem;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const ValueSimulatorRow = styled.div`
  display: flex;
  flex-direction: row;
  
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const ValueSimulatorColumnItem = styled.div`
  width: 50%;
  padding: 0 1rem;
  
  @media (max-width: 1024px) {
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #666F7A;
  }
`;

const ValueSimulatorLeftColumnItem = styled(ValueSimulatorColumnItem)`
  border-right: 3px solid rgb(240, 242, 245);
  
  @media (max-width: 1024px) {
    border-right: 0;
  }
`;

const ValueSimulatorRightColumnItem = styled(ValueSimulatorColumnItem)`
`;

const ValueSimulatorLeftFieldLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  color: #666F7A;
  padding-bottom: 0.25rem;
  padding-top: 1rem;
`;

const ValueSimulatorLeftField = styled.div`
  width: 100%;
  color: #666F7A;
  background: #EFF1F5;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 0.5rem;
`;

const PropertyValuePageHeader = styled.h3`
  color: #2A80E1;
  margin-top: 5rem;
  margin-bottom: 2.125rem;
  font-size: 2rem;
  font-weight: 700;
  
  @media (max-width: 1024px) {
    text-align: center;
    margin-top: 0;
    margin-bottom: 1rem;
  }
`

const ButtonStyled = styled(Button)`
  color: #2A80E1;
  font-size: 1rem;
  line-height: 1.5rem;
  border: 1px solid #2A80E1;
  border-radius: 6px;
  padding: 0.5rem;
  width: 7.75rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ValueSimulator