import {Guid} from "guid-typescript";
import {apiGet, createUrl} from "../apiCall";
import EnvConfig from "../../config";

export type TGetDocumentsResponse = Array<{
  name: string,
  documentDate: string,
  documentType: string,
  description: string,
  category: string,
  relatedFileId: Guid,
  documentId: Guid
  subscriptionId: number,
  propertyId: Guid
}>

export const getDocuments = (propertyId: Guid, documentType: string) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/${documentType}`);

  return apiGet<TGetDocumentsResponse | []>(url, {}, propertyId);
};