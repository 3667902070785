import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TSetUserWindowsStateRequest = {
  onboardingInterestSurveyStatus: string | null;
  onboardingUpkeepStatus: string | null;
  onboardingContentStatus: string | null;
  onboardingImprovementsStatus: string | null;
  mobileWelcomeStatus: string | null;
  questionnairePage: number;
  handholdingStatus: string | null;
  propertyId: Guid;
};

export const setUserWindowsState = (payload: TSetUserWindowsStateRequest) => {
  const { propertyId } = payload;
  let url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/windowsState`);

  return apiPut<any, TSetUserWindowsStateRequest>(url, payload)
};
