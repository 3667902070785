import React from "react";
import styled from "styled-components";

const Terms = () => {

  return (
    <TermsWrapper>
      <Title>Terms and Conditions - Livlet</Title>
      <PremiseWrapper>
        <p>LAST UPDATED DATE: October 19, 2022</p>
        <p>ANY PERSON OR ENTITY ("<strong>User</strong>" or "<strong>You</strong>") USING OR OTHERWISE ACCESSING THE SITE
          AT <a href={"https://www.livlet.com/"} target={"blank"}>www.Livlet.com</a> ("<strong>Site</strong>") OR ANY OF THE INFORMATION CONTAINED
          WITHIN THE SITE MUST AGREE TO BE BOUND BY THE FOLLOWING TERMS AND CONDITIONS OF THIS WEBSITE USER AGREEMENT ("<strong>Agreement</strong>").</p>
        <p>PERSONAL DATA: You agree to provide only true, accurate and complete information to us via the Site.
          Any personal information provided by Users will be treated with appropriate care
          and security in accordance with, and by agreeing to this Agreement you
          expressly agree to the terms of, our <a href={"/privacypolicy"} target={"blank"}>Privacy Policy.</a></p>
      </PremiseWrapper>
      <NestedNumberedList>
        <li><strong><u>Contracting parties.</u></strong> The Site and all content, data and other materials contained on the Site
          ("<strong>Content</strong>") are owned or controlled by Livlet Ltd (registration number 13033019),
          a company incorporated in England with its registered office at 20-22 Wenlock Road, London N1 7GU, UK.
          Livlet Ltd is referred to in these terms and conditions as "<strong>we</strong>", "<strong>us</strong>", "<strong>our</strong>"
          or "<strong>Livlet</strong>". When you register with (or otherwise access) the Site, you are contracting with Livlet.
          You must be at least 18 years old to register with the Site.</li>
        <li><strong><u>Passwords.</u></strong> You agree: to keep your password secure and confidential and to not
          allow anyone else to use your email address or password to access the Site or any Content; to not do
          anything which would assist anyone who is not a Registered User to gain access to any secured area of the
          Site, and; to not create additional registration accounts for the purpose of abusing the functionality of the
          Site or any Content, or other Users, or to seek to pass yourself off as another User. If you think someone else
          has obtained your account details, please let us know immediately via <a href={"mailto:support.uk@Livlet.com"}>support.uk@Livlet.com</a> and
          we will close your account as quickly as possible. Please note that you will be responsible to Livlet and to others for
          all activity that occurs under your registration account.</li>
        <li><strong><u>The Livlet Service.</u></strong> Livlet is an online service which collates information relating to your own residence
          (or residences that you own, manage or operate, as well to service providers and professionals) and provides
          communication, facilitation and other functionalities intended to allow you to manage, maintain and understand
          your property in a more cost-efficient manner ("<strong>Service</strong>"). The Service is offered on subscription basis
          subject to the terms posted on the Site from time to time. The Service allows you to upload and securely store
          many types of information relating to your property, including information sent to you by others, e.g. planning
          consents and your own details (e.g. costs, expenses, contents, fixtures and fittings, purchases and maintenance)
          and uses artificial intelligence to offer suggestions about improvements and eco-friendliness. The Service is
          designed to simplify tax reporting and to provide convenient cross-referable storage of information and documents
          regarding your property. Please note that there are size limitations for storage of your content on the Service
          (as posted on the Site from time to time) and we shall not be liable for any loss of data so you should retain
          your own copies of all data uploaded to the Service.
        </li>
        <li><strong><u>User Content.</u></strong> You may upload, post, and display documents on the Service relating to your residence, such as
          appliance and equipment information, home improvement and repairs, invoices, user manuals, photos, mortgage-related
          documents, financial information, video, photos, text messages, other materials, items and user-generated content
          (collectively, "<strong>User Content</strong>"). The Site also features areas where you may interact with other
          users such as message boards, chat rooms and other interactive features ("<strong>Interactive Features</strong>").
          You are solely responsible for your use of any Interactive Feature and you use them at your own risk. By submitting
          User Content to the Site, you grant Livlet a royalty-free and non-exclusive right and license to use, publish,
          reproduce, modify, adapt, edit, translate, create derivative works from, incorporate into other works, distribute,
          sub-license and otherwise exploit such User Content (in whole or in part) worldwide in any media, and at times in
          compliance with our <a href={"/privacypolicy"} target={"blank"}>Privacy Policy</a>. Livlet shall have no obligation
          to provide you any opportunity to review or approve its uses of your User Content or to pay you any compensation
          relating to its use of User Content, or to attribute authorship of the User Content to you. User Content submitted
          by you will be considered non-confidential and Livlet is under no obligation to treat such User Content as
          proprietary information. You hereby warrant and represent that you have (and will continue to have during your use
          of the Service) all necessary licences, rights, consents, and permissions which are required to enable Livlet to use
          your User Content for the purposes of the Site and that you personally created or collected all content (and all
          materials contained within content) that you upload to the Site, and that you own or control all rights in your content.
        </li>
        <li><strong><u>Paid Subscriptions.</u></strong> The Subscription Fee is payable by credit/debit card (Visa, MasterCard,
          American Express, and Discover) or PayPal. The payment details you provide will be stored securely for the duration of
          your subscription (or if you choose a subscription basis that includes a free-trial period, we will charge your card for
          your first subscription fee upon the expiration date of the applicable free-trial period). All subscriptions will renew
          automatically (and your payment method will be automatically debited/credited) unless terminated by following the correct
          procedure as detailed on the Site. Subscription Fees are non-refundable unless we are unable to fulfil your subscription
          (or in other exceptional circumstances in our reasonable discretion). You may cancel your subscription at any time on 14
          days' notice. In consideration of payment of the applicable Subscription Fee, we grant to you a licence to access and use
          the Site and Services on a non-exclusive, non-transferable basis, for your personal use in accordance with applicable
          laws and the term hereunder.
          <UnnumberedList>
            <li>Current prices can be found at <a href={"https://www.livlet.com/pricing"}
                target={"_blank"} rel={"noreferrer"}>https://www.livlet.com/pricing</a></li>
            <li>The prices may be changed by Livlet at any time subject to 90 days' notice which will be sent to you by email if you
              have registered a user account on our website.</li>
          </UnnumberedList>
        </li>
        <li><strong><u>Free Subscriptions.</u></strong> Free subscriptions are made available by Livlet to a user on a free basis and
          constitute a free trial of unconfirmed duration. Livlet reserves the right to suspend, adapt, limit or cancel the free subscription
          for any reason without prior notice to you. Livlet also reserves the right to make this service payable at any time subject to 90 days'
          notice which will be sent to you by email if you have registered a user account on our website. A free subscription user may continue
          to access the product during this time, after which they will be requested to engage in a paid subscription to continue using the product.
          <br/><br/>
          In consideration of the above restrictions of free subscriptions, we grant to you a licence to access and use the Site and Services on
          a non-exclusive, non-transferable basis, for your personal use in accordance with applicable laws and the term hereunder.
        </li>
        <li><strong><u>Content.</u></strong> The Content is intended only for the purposes specified or implied therein, and your use of the
          Site and/or all Content is entirely at your own risk. Please note, whilst we endeavour to provide accurate
          and up-to-date information, the Content may not be wholly accurate or up-to-date, complete or free of defects,
          and is subject to change, often at very short notice. All Content is provided without any representations or
          warranties of any kind (implied or express) to the fullest extent permitted by applicable law. Livlet, unless
          otherwise stated herein, owns or controls all relevant intellectual property rights in the Site and the Content.
          You may not publish, distribute, extract, re-utilise, or reproduce any part of the Site or the Content in any
          form (including storing it in any medium) other than as expressly allowed herein or as set out in the Site and
          the Content (or under UK or local law). The Site and the Content are for your personal, non-commercial use only,
          and are not for re-distribution, transfer, assignment or sublicense.
        </li>
        <li><strong><u>Take Downs.</u></strong> We retain the right to remove any User Content of any kind that, in our judgement, does not
          comply with this Agreement and any other rules of conduct for the Service, or is otherwise harmful, objectionable,
          or inaccurate. We are not responsible for any failure or delay in removing such content. You hereby consent to
          such removal and waive any claim against us arising out of such removal of content.</li>
        <li><strong><u>Code of Conduct for User Content.</u></strong> You agree to obey all applicable laws in using the Site, and agree
          that you are responsible for the Content and/or communications you upload to or initiate via the Site. You agree
          that you are responsible for everything that you post or transmit to the Site and you agree (in relation to the Site):
          <UnnumberedList>
            <li>not to post content or participate in any form of activity which is unlawful, harassing, libellous,
              defamatory, abusive, threatening, harmful, vulgar, obscene, profane, sexually-oriented, racially-offensive
              or otherwise includes objectionable material;</li>
            <li>not to post content which you do not have the right to use; </li>
            <li>not to abuse other Users or anyone else;</li>
            <li>not to use the Site to engage in any activities other than as approved by Livlet;</li>
            <li>not to register more than one account for yourself or anyone else;</li>
            <li>not to post content that contains software viruses or any other computer code, files, or programs
              that are designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or
              telecommunications equipment or to damage or obtain unauthorised access to any data or other information of any third party;</li>
            <li>not to post content that contains anything which could be used to determine or alter the architecture
              of the Site, or could be used to decompile, disassemble, or reverse engineer the Site; </li>
            <li>not to post anything that constitutes or contains "affiliate marketing," "link referral code,"
              "junk mail," "spam," "chain letters," "pyramid schemes," or unsolicited commercial advertisement or that
              constitutes or contains any form of advertising or solicitation or that includes links to commercial
              services or web sites or that advertises any illegal service or the sale of any items the sale of which
              is prohibited or restricted by any applicable law or that disrupts the normal flow of dialogue with an
              excessive amount of Content (flooding attack) to the Service, or that otherwise negatively affects other
              users' ability to use the Service or that employs misleading email addresses, or forged headers or otherwise
              manipulated identifiers in order to disguise the origin of Content transmitted through the Service; </li>
            <li>not to contact anyone who has asked not to be contacted;</li>
            <li>not to "stalk" or otherwise harass any other User;</li>
            <li>not to collect personal data about other users for commercial or unlawful purposes;</li>
            <li>not to use automated means, including spiders, robots, crawlers, data mining tools,
              or the like to download data from the Service, unless expressly permitted by Livlet;</li>
            <li>not to post irrelevant Content, repeatedly post the same or similar Content or otherwise impose
              an unreasonable or disproportionately large load on our infrastructure;</li>
            <li>not to attempt to gain unauthorized access to Livlet's computer systems or engage in any
              activity that disrupts, diminishes the quality of, interferes with the performance of, or impairs
              the functionality of, the Service or Livlet website; or</li>
            <li>not to use any form of automated device or computer program that enables the submission
              of postings on Livlet without each posting being manually entered by the author thereof (an "automated posting device"),
              including without limitation, the use of any such automated posting device to submit postings in bulk, or for automatic
              submission of postings at regular intervals.</li>
          </UnnumberedList>
        </li>
        <li><strong><u>Termination of this Agreement.</u></strong> We may at any time terminate your access, your subscription or
          this Agreement generally, if we believe that you may have breached (or acted in a manner indicating that you do not
          intend to or are unable to comply with) any term herein, or if we are legally required to do so by law, or if continuation
          is likely to be no longer commercially viable. You acknowledge that we will cooperate fully with investigations of
          violations of systems or network security at other sites, including cooperating with law enforcement authorities in
          investigating suspected criminal violations. For the avoidance of doubt, we reserve the right to take down any item
          of content at any time for any reason.
        </li>
        <li><strong><u>Liability.</u></strong> Please note that Livlet does not provide any services and/or advice in relation
          to tax matters and/or remodeling advice. In the event that you use our Services to obtain any prices and/or potential
          savings, such figures provided by Livlet shall be estimates only. The Services do not include the provision of any Energy
          Performance Certificate grading or any other form of official grading certificates. You agree that the liability of Livlet
          to you hereunder shall be limited to the amount you have actually paid to us for its products or services hereunder of, if
          greater, GBP£100. Except as set out herein, we shall not be liable for any indirect or consequential loss of any kind
          in contract, tort or otherwise arising out of your use of the Site or the Content or in relation to the goods and/or
          services that we provide. Nothing in this Agreement shall operate to limit or exclude liability for death or personal
          injury caused by negligence of Livlet.
          <br/><br/>
          Property value simulator, and other house price valuations are strictly estimates only and should be used on that basis.
          The automated valuation model we use analyses prices for similar properties sold or offered in the area. These properties
          may differ in ways which we do not or cannot take into account. Valuations are subjective and based on market forces which
          may change. Valuations must not be used for property sale, purchase, mortgage or related purposes. You should seek a
          professional valuation from a qualified surveyor before making any decision whether to buy or sell a property. We shall
          not be liable for any losses you or anyone else suffers as a result of relying on the valuations. This includes not being
          liable for any loss of profit, loss of bargain, loss of capital through over-payment or under-sale or for any indirect,
          special or consequential loss.
        </li>
        <li><strong><u>Warranties.</u></strong> To the extent permitted under applicable law in your territory, ALL LIVLET
          PRODUCTS AND SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTY OF ANY KIND, EITHER
          EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
          PURPOSE. Without limiting the foregoing, Livlet, makes no warranty that (a) the Service will meet your requirements,
          (b) the Service will be uninterrupted, timely, secure, or error-free, (c) the results obtained from the use of the
          Service will be effective, accurate or reliable, or (d) the quality of any materials or services obtained by you
          from the Site, from us, or from any third parties' websites to which the Site is linked, will meet your expectations
          or be free from mistakes, errors or defects. The use of the Service, the uploading of User Content, or the downloading
          or other acquisition of any materials or content through the Service or through third parties' or provider's websites,
          is done at your own risk and with your agreement that you will be solely responsible for any damage to your computer
          device or system or loss of data that results from such activities.
        </li>
        <li><strong><u>Indemnity.</u></strong> You agree to indemnify us for any loss or damage that may be incurred by Livlet, including without
          limitation legal fees, arising from any breach by you of any warranty or other term herein or your misuse of any
          material or information obtained through the Site or the Content. You further undertake to indemnify us for all
          loss or damage incurred by Livlet in relation to any third party claim against us for infringement of intellectual
          property rights arising in relation to your provision of materials to the Site and/or the Content.
        </li>
        <li><strong><u>Complaints.</u></strong> If you have any complaint about the Service or would like to notify us of content which you believe
          does not comply with this Agreement or otherwise is objectionable, please contact us via <a href={"mailto:support.uk@Livlet.com"}>support.uk@Livlet.com</a>
          (making sure to include both the Uniform Resource Locator ("URL") for the non-complying content and the reasons
          you believe it does not comply).  Please note that there may be adverse legal consequences if you make a false or
          bad faith allegation through this process.
        </li>
        <li><strong><u>Trade Marks.</u></strong> The brands, products and service names used in the Site and the Content
          (including without limitation, "Livlet") are trademarks or trade names of Livlet or its trading partners unless otherwise stated.
        </li>
        <li><strong><u>Hacking.</u></strong> You agree and undertake not to attempt to damage, deny service to, hack,
          crack, reverse-engineer, or otherwise interfere (collectively, "<strong>Interfere</strong>") with the Site and/or
          the Content in any manner. If you in any way Interfere with of these, you agree to pay all damages we incur as a
          result. We will cooperate with the authorities in prosecuting any User who Interferes with the Site, the Content
          or otherwise attempts to defraud Livlet or any other parties through your use of the Site, the Content or any services
          provided hereunder. We reserve the right to deny any or all access or service to any User for any reason, at any time,
          at our sole discretion. You agree that we may block your access, and at our sole discretion to disallow your continued
          use of the Site and/or the Content. We reserve the right to take any action we may deem appropriate in our sole discretion
          with respect to violations or enforcement of the terms of this Agreement, and we expressly reserve all rights and remedies
          available to us at law or in equity.
        </li>
        <li><strong><u>No Partnership.</u></strong> Your use of the Site and/or the Content creates no partnership, client,
          fiduciary or other professional relationship.
        </li>
        <li><strong><u>Entire Agreement.</u></strong> This Agreement constitutes the entire agreement between the
          parties on the subject matter hereof. There are no understandings, agreements, or representations,
          oral or written, not specified herein regarding this Agreement.
        </li>
        <li><strong><u>Force Majeure.</u></strong> We will not be liable or responsible for any failure to perform, or delay
          in performance of, any of our obligations hereunder that is caused by events outside our reasonable control.
        </li>
        <li><strong><u>Severance.</u></strong> If any part, term, or provision of this Agreement shall be held by a court
          of competent jurisdiction to be illegal, unenforceable, or in conflict with any relevant law, the remaining portions
          or provisions shall still remain valid and continue in full force and effect.
        </li>
        <li><strong><u>No Waiver.</u></strong> No waiver, express or implied, by either party of any term or condition or of
          any breach by the other of any of the provisions of this Agreement shall operate as a waiver of any breach of the
          same or any other provision of this Agreement.
        </li>
        <li><strong><u>Variation.</u></strong> This Agreement may be varied from time to time by our posting new terms
          on the Site, and any such amendment will be applicable to all Users from the date and time such revised terms
          have been posted on the Site. Your continued use of the Site and/or the Service constitutes agreement with and
          acceptance of any such amendment or other changes. We constantly experiment and innovate with the Site in order
          to provide a better experience for Users and you hereby acknowledge and agree that the form and nature of the
          Service may change from time to time without prior notice to you.
        </li>
        <li><strong><u>Law and Jurisdiction.</u></strong> This Agreement shall be governed by and construed in
          accordance with the laws of England. Any disputes arising under or in connection with this Agreement shall be
          subject to the exclusive jurisdiction of the Courts of London, England.
        </li>
        <li><strong><u>Using Third-party services.</u></strong> Nothing on the Livlet platform/website constitutes a recommendation
          or endorsement by Livlet in respect of any third party or "Partner" or any products and/or services which they may provide.
          <br/>
          Where Livlet recommend the use of third party suppliers' or "Partner" services, including tradespersons, certificates
          and services, a Livlet user is under no obligation to follow this recommendation and are free to procure the services of other service providers.
          <br/>
          Where a Livlet client or user decides to engage with affiliate partners, including booking a tradesperson or a service,
          Livlet does not guarantee the quality of the services provided by the relevant third parties and are not responsible for
          the acts & omissions of such third parties vis-à-vis of Livlet users.
          <br/>
          Information on the Livlet platform/website is provided for general information purposes only, should not be relied upon by
          you, and is provided so that you can select the third party or Partner products and/or services which you feel are most
          appropriate to meet your needs.
          <br/>
          You must ensure that you carefully read all the terms and conditions which apply to any third party or Partner products
          and/or services which you select before engaging the relevant third party/Partner.
          <br/>
          Nothing in these terms and conditions excludes or limits Livlet's liability for death or personal injury caused by our
          negligence or for our fraud, or excludes or limits Livlet's duties or any liability under the Financial Services and
          Markets Act 2000, as amended, (<b>FSMA</b>) or any conduct of business rules developed pursuant to FSMA. Subject to this,
          Livlet does not accept any responsibility or liability for any loss or damage that you may incur (i) in the event that
          any third party or Partner products and/or services which you apply for do not meet your requirements or are not suitable
          for you, or (ii) for any acts, omissions, errors, or defaults of any third party or Partner in connection with their supply
          of any third party or Partner products and/or services to you.
        </li>
        <li><strong><u>Contacting Us.</u></strong> If you have any questions, please contact us at the following address:
          Livlet Ltd, a company registered in England with offices at 20-22 Wenlock Road, London N1 7GU, UK, or email us
          via <a href={"mailto:support.uk@Livlet.com"}>support.uk@Livlet.com</a>.
        </li>
      </NestedNumberedList>
    </TermsWrapper>
  );
};

export const TermsWrapper = styled.main`
  margin: 20px;
  color: #21272B;
  font-family: Montserrat, sans-serif!important;
`;

export const PremiseWrapper = styled.div`
  margin: 2rem 1rem;
`;

export const Title = styled.h4`
  font-weight: 700;
  font-size: 1.625rem;
  color: #21272B;
  margin: 1rem;
  margin-top: 0;
`;

export const NestedNumberedList = styled.ol`
  counter-reset: item;

  li {
    display: block;
    margin: 1rem;

    &::before {
      content: counters(item, ".") ". ";
      counter-increment: item;
      font-weight: bold;
    }
`;

// const NumberedList = styled.ol`
//   counter-reset: item;
//
//     & > li {
//       display: block;
//
//       &::before {
//         content: counters(item, ".") " ";
//         counter-increment: item;
//         font-weight: bold;
//       }
//     }
// `;

export const UnnumberedList = styled.ul`
    margin-top: 1rem;
    
     & > li {
     list-style: disc;
     margin: 0;
     
      &::before {
        counter-increment: none!important;
        content: "• ";
      }
    }
   }
`;

export default Terms;
