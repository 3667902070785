import React, {useEffect} from "react";
import {Button, Form, Input, Select} from "antd";
import styled from "styled-components";
import { ReactComponent as ArrowDownSelect } from "../../../images/ArrowDownSelect.svg";
import SearchLocationInput from "../SearchLocationInput";
import {emailRegex} from "../../screens/helpers";
import messages from "../../screens/messages";
import {useIntl} from "react-intl";
const { Option } = Select;

const userInterests = ["Organise my home", "Plan maintenance tasks & projects", "Find tradespersons", "Digitise my files and folders",
  "Reduce my environmental impact", "Reduce my energy bills", "Leak detection and prevention",
  "Leasing my home (Rental management)", "Cyber security"]
const spacesPresent = ["Living room", "Garage", "Kitchen", "Roof", "Conservatory"]
const houseTypes = ["House Detached", "House Semi-Detached", "House Terraced", "Flat Top floor", "Flat Mid floor", "Flat ground floor", "Unknown"]
const numberOfBathroomsOptions = ["0", "1", "2", "3", "4", "5+"]
const offStreetParkingOptions = ["None", "1 space", "2 spaces", "3+ spaces"]
const gardenOptions = ["None", "Balcony / Terrace", "Garden", "Garden (1+ Acre)"]

type Props = {
  setStep: (arg: number) => void;
  setSiteVisitData: (arg: any) => void;
  toggleReset: (arg: boolean) => void;
  form: any;
  location: any;
  setLocation: (arg: any) => void;
  selectedUserInterests: Array<any>;
  setSelectedUserInterests: (arg: any) => void;
  selectedSpaces: Array<any>;
  setSelectedSpaces: (arg: any) => void;
  selectedHouseType: string;
  setSelectedHouseType: (arg: string) => void;
}

const SiteVisitStep = (props: Props) => {
  const {setStep, setSiteVisitData, toggleReset, form, location, setLocation, selectedUserInterests,
    setSelectedUserInterests, selectedSpaces, setSelectedSpaces, selectedHouseType, setSelectedHouseType} = props;

  const { formatMessage: f } = useIntl();

  useEffect(() => {
    if (location) {
      form.setFieldsValue({
        address: location.formattedAddress,
        city: location.data.city,
        country: location.data.country,
        zip: location.data.zipcode,
      });
    }
  }, [location, form]);

  const handleReset = () => {
    window.scrollTo(0, 0);
    form.resetFields();
    toggleReset(true);
    setSelectedUserInterests([]);
    setSelectedSpaces([]);
    setSelectedHouseType("");
  }

  const handleNextStep = (formData: any) => {
    setSiteVisitData({
      propertyUserInfo: {
        email: formData.email ?? null,
        username: formData.username ?? null,
        address: formData.address ?? null,
        city: formData.city ?? null,
        country: formData.country ?? null,
        zip: formData.zip ?? null,
        userInterests: selectedUserInterests ?? [],
        houseType: selectedHouseType ?? null
      },
      spacesInfo: {
        numberOfBedrooms: formData.numberOfBedrooms ?? null,
        numberOfBathrooms: formData.numberOfBathrooms ?? null,
        garden: formData.garden ?? null,
        offStreetParking: formData.offStreetParking ?? null,
        otherSpaces: selectedSpaces ?? []
      }
    })
    window.scrollTo(0, 0);
    setStep(2);
  }

  const toggleUserInterests = (item: any) => {
    if (selectedUserInterests.includes(item)) {
      setSelectedUserInterests(selectedUserInterests.filter((i: string) => i !== item))
    } else {
      setSelectedUserInterests([...selectedUserInterests, item])
    }
  }

  const toggleSpaces = (item: any) => {
    if (selectedSpaces.includes(item)) {
      setSelectedSpaces(selectedSpaces.filter((i: string) => i !== item))
    } else {
      setSelectedSpaces([...selectedSpaces, item])
    }
  }

  return <PageWrapper>
    <PageTitle>Livlet site visit (Page 1 of 4)</PageTitle>
    <FormWrapper>
      <Form
        form={form}
        name="manageProperty"
        layout="vertical"
        size={"large"}
        onFinish={handleNextStep}
      >
        <FormSectionTitle>Property & user info</FormSectionTitle>
        <FormLabel>Email address <span className={"accent"}>* (Livlet account email address)</span></FormLabel>
        <Form.Item
          key={"email"}
          name={"email"}
          rules={[{
            pattern: emailRegex,
            message: f(messages.validationEmail),
          }, {
            required: true,
            message: "Email address is required",
          }]}>
          <InputStyled placeholder={"Input your email"} />
        </Form.Item>
        <FormLabel>User preferred name <span className={"accent"}>* (Personalises account)</span></FormLabel>
        <Form.Item key={"username"} name={"username"} rules={[{
          required: true,
          message: "Username is required",
        }]}>
          <InputStyled placeholder={"Input your username"} />
        </Form.Item>

        <FormLabel>User interests <span className={"accent"}>(Personalises account - click all relevant)</span></FormLabel>
        <SelectionWrapper>
          {userInterests.map(interest => <SelectionItem
            className={selectedUserInterests.includes(interest) ? "selected" : ""}
            onClick={() => toggleUserInterests(interest)}>{interest}</SelectionItem>)}
        </SelectionWrapper>

        <FormLabel>Property address<br/><span className={"accent"}>(This will help us to source area specific alerts and search for your energy performance certificate (EPC).)</span></FormLabel>
        <AddressSearch>
          <FormLabel>Find address</FormLabel>
          <SearchLocationInput setValue={setLocation} origin={"onboarding"} />
          <FormLabel>Enter street address <span className={"accent"}>*</span></FormLabel>
          <Form.Item key={"address"} name={"address"} rules={[{
            required: true,
            message: "Address is required",
          }]}>
            <InputStyled placeholder={"Input your address"} />
          </Form.Item>
          <FormLabel>Enter city <span className={"accent"}>*</span></FormLabel>
          <Form.Item key={"city"} name={"city"} rules={[{
            required: true,
            message: "City is required",
          }]}>
            <InputStyled placeholder={"Input your city"} />
          </Form.Item>
          <FormLabel>Enter country <span className={"accent"}>*</span></FormLabel>
          <Form.Item key={"country"} name={"country"} rules={[{
            required: true,
            message: "Country is required",
          }]}>
            <InputStyled placeholder={"Input your country"} />
          </Form.Item>
          <FormLabel>Enter postcode <span className={"accent"}>*</span></FormLabel>
          <Form.Item key={"zip"} name={"zip"} style={{marginBottom: 0}} rules={[{
            required: true,
            message: "Postcode is required",
          }]}>
            <InputStyled placeholder={"Input your postcode"} />
          </Form.Item>
        </AddressSearch>
        <FormLabel>House type <span className={"accent"}>(Used for recommendation engine)</span></FormLabel>
        <SelectionWrapper>
          {houseTypes.map(type => <SelectionItem
            className={selectedHouseType === type ? "selected" : ""}
            onClick={() => setSelectedHouseType(type)}>{type}</SelectionItem>)}
        </SelectionWrapper>

        <FormSectionTitle>Spaces</FormSectionTitle>
        <FieldWrapper className={"col-2"}>
          <div>
            <FormLabel>Number of bedrooms</FormLabel>
            <Form.Item
              key={"numberOfBedrooms"}
              name={"numberOfBedrooms"} rules={[{
                pattern: (/^[0-9]+$/),
                message: 'Please input a valid number of bedrooms.',
              }]}>
              <InputStyled placeholder={"Input number of bedrooms"} />
            </Form.Item>
          </div>
          <div>
            <FormLabel>Number of bathrooms</FormLabel>
            <Form.Item key={"numberOfBathrooms"} name={"numberOfBathrooms"}>
              <SelectStyled
                placeholder={"Select"}
                suffixIcon={<ArrowDownSelect />}
                dropdownClassName={"upkeep-tag-select"}
                dropdownStyle={{width: "100%", borderRadius: "12px", padding: "0.5rem"}}
              >
                {numberOfBathroomsOptions.map(option => <Option value={option}>{option}</Option>)}
              </SelectStyled>
            </Form.Item>
          </div>
          <div>
            <FormLabel>Garden</FormLabel>
            <Form.Item key={"garden"} name={"garden"}>
              <SelectStyled
                placeholder={"Select"} suffixIcon={<ArrowDownSelect />}
                dropdownClassName={"upkeep-tag-select"}
                dropdownStyle={{width: "100%", borderRadius: "12px", padding: "0.5rem"}}
              >
                {gardenOptions.map(option => <Option value={option}>{option}</Option>)}
              </SelectStyled>
            </Form.Item>
          </div>
          <div>
            <FormLabel>Off-street parking</FormLabel>
            <Form.Item key={"offStreetParking"} name={"offStreetParking"}>
              <SelectStyled
                placeholder={"Select"} suffixIcon={<ArrowDownSelect />}
                dropdownClassName={"upkeep-tag-select"}
                dropdownStyle={{width: "100%", borderRadius: "12px", padding: "0.5rem"}}
              >
                {offStreetParkingOptions.map(option => <Option value={option}>{option}</Option>)}
              </SelectStyled>
            </Form.Item>
          </div>
        </FieldWrapper>
        <FormLabel>Spaces present?</FormLabel>
        <SelectionWrapper>
          {spacesPresent.map(space => <SelectionItem
            className={selectedSpaces.includes(space) ? "selected" : ""}
            onClick={() => toggleSpaces(space)}
          >{space}</SelectionItem>)}
        </SelectionWrapper>

        <ButtonWrapper>
          <ButtonGreen onClick={() => handleReset()}>Restart</ButtonGreen>
          <ButtonBlue htmlType={"submit"}>Next</ButtonBlue>
        </ButtonWrapper>
      </Form>
    </FormWrapper>
  </PageWrapper>
}

export const PageWrapper = styled.div`
  padding: 1.5rem;
  max-width: 1100px;
  margin: auto;
`

export const PageTitle = styled.div`
  font-weight: 700;
  font-size: 3.375rem;
  line-height: 3.5rem;
  color: #2A80E1;
  padding-bottom: 1.5rem;
  
  @media (max-width: 1024px) {
    font-size: 1.625rem;
    line-height: 1.75rem;
  }
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1rem;
  background: #FFFFFF;
  border: 1px solid #DAE0E6;
  border-radius: 16px;
`

export const FormSectionTitle = styled.div`
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 2rem;
  color: #21272B;

  width: 100%;
  border-bottom: 1px solid #D8DFDE;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  
  .subtitle {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #666F7A;
  }
  
  @media (max-width: 1024px) {
    border-bottom: none;
  }
`

export const FormLabel = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666F7A;
  padding-bottom: 0.5rem;
  
  .accent { color: #009966; }
`

export const InputStyled = styled(Input)`
  display: flex;
  align-items: center;
  
  padding: 0.75rem 1rem;
  background: #FEF9EA!important;
  border-radius: 6px;
  border: none;

  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #666F7A;
`

export const SelectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0.5rem;
  gap: 0.5rem;
  margin-bottom: 1rem;
  
  background: #F0F2F5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`

export const SelectionItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1rem;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 1.125rem 1rem;
  border: 1px solid white;
  text-align: center;
  
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #45655E;
  transition: all .5s ease;
  
  &:hover, &.selected {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #2E2D68;
  }
  
  width: calc(100% / 2 - 0.25rem);
  &:last-child { width: 100%; }
`

export const FieldWrapper = styled.div`
  width: 100%;
  display: grid;
  column-gap: 0.5rem;
  
  &.col-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }
  &.col-4 { grid-template-columns: repeat(4, minmax(0, 1fr)); }
  
  @media (max-width: 1024px) {
    &.col-2, &.col-4 { grid-template-columns: repeat(2, minmax(0, 1fr)); }
  }
`

export const SelectStyled = styled(Select)`
  width: 100%;
  
  .ant-select-selector {
    height: 3.125rem!important;
    display: flex!important;
    padding-left: 1rem!important;
    align-items: center!important;
    background: #67C18B!important;
    border-radius: 6px!important;
    border: none!important;
  }
  
  .ant-select-selection-item, .ant-select-selection-placeholder {
    color: white!important;
    font-weight: 600!important;
    font-size: 0.875rem!important;
    line-height: 1.25rem!important;
  }
  
  svg { 
    margin-right: 0.5rem; 
  }
`

export const AddressSearch = styled.div`
  padding: 0.75rem;
  background: #F0F2F5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
`

const ButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1rem;
  height: 3rem;
  border-radius: 6px;
  border: none;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #FFFFFF;
  min-width: 7.75rem;
  transition: .5s all ease;
  :hover { color: white; }
`

export const ButtonGreen = styled(ButtonStyled)`
  background: #009966;
  :hover, :active { background: #22AB5B; }
`

export const ButtonBlue = styled(ButtonStyled)`
  background: #2A80E1;
  :hover, :active { background: #40a9ff; }
`

export default SiteVisitStep