import React from "react";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";
import {ReactComponent as AddCostIcon} from "../../../images/planner/AddCostIcon.svg";
import {ReactComponent as AddReminderIcon} from "../../../images/planner/AddReminderIcon.svg";
import {ReactComponent as AddTaskIcon} from "../../../images/planner/AddTaskIcon.svg";
import {ReactComponent as AddWorkIcon} from "../../../images/planner/AddWorkIcon.svg";
import {ReactComponent as AddEventProjectIcon} from "../../../images/planner/AddEventProjectIcon.svg";
import {ReactComponent as AddEventReminderIcon} from "../../../images/planner/AddEventReminderIcon.svg";
import {ReactComponent as AddEventCostIcon} from "../../../images/planner/AddEventCostIcon.svg";
import {ReactComponent as AddEventTaskIcon} from "../../../images/planner/AddEventTaskIcon.svg";

import {
  ContainerItem,
  ContainerItemWrapper,
  SpaceContainer,
  SpaceOptions
} from "../spaces/AddSpaceOptions";
import styled from "styled-components";
import {CircleIcon, DrawerFooter, DrawerHeader, DrawerStyled} from "../../screens/components";
import {addWork} from "../../../api-wrapper/works/addWork";
import {getAllWorks} from "../../actions/works";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../selectors";
import {Overlay} from "../../screens/Home";
import { Dropdown } from "antd";
import {TTaskTypes} from "../../../helpers";

type Props = {
  isOpen: boolean;
  toggleDrawer: (arg: boolean) => void;
  setSelectedType: (arg: string) => void;
  toggleTaskDrawer: (arg: boolean) => void;
  setEditingWorkData: (arg: any) => void;
  toggleWorkDrawer: (arg: boolean) => void;
  isOnboarding?: boolean;
}

const AddEventDrawer = (props: Props) => {
  const {isOpen, toggleDrawer, setSelectedType, toggleTaskDrawer, setEditingWorkData, toggleWorkDrawer, isOnboarding} = props;
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;

  const handleCancel = () => {
    toggleDrawer(false);
  }

  const selectType = (type: string) => {
    setSelectedType(type);
    toggleTaskDrawer(true);
    toggleDrawer(false);
  }

  const addNewWork = () => {
    addWork({
      propertyId,
      name: null,
      type: null,
      completedDate: null,
      dueDate: null,
      recurring: null,
      isComplete: false,
    }).then((res) => {
      setEditingWorkData(res);
      dispatch(getAllWorks(propertyId));
      toggleWorkDrawer(true);
      toggleDrawer(false);
    });
  }

  return <AddDrawerStyled
    push={{distance: "32px"}}
    closeIcon={false}
    visible={isOpen}
    width={window.innerWidth > 1024 ? "55%" : "100%"}
    height={window.innerWidth > 768 ? "100%" : "55%"}
    placement={window.innerWidth > 768 ? "right" : "bottom"}
    onClose={() => handleCancel()}
    title={<DrawerHeader className={"event-drawer"}>
      {window.innerWidth > 768 ? "Add event" : "Add"}
      <CircleIcon className={"close-icon"} onClick={() => handleCancel()}><CloseIcon/></CircleIcon>
    </DrawerHeader>}
    footer={<DrawerFooter className={"fake-footer task-footer mobile-footer"}>
      <CircleIcon className={"close-icon"} onClick={() => handleCancel()}><CloseIcon/></CircleIcon>
    </DrawerFooter>}
  >
    <SpaceOptions className={"event-drawer"}>
      <SpaceContainer className={"open event-drawer"}>
        <ContainerItemWrapper className={"event-drawer"}>
          <Dropdown placement="bottomCenter" overlay={isOnboarding ? <Overlay>
            <div>Reminders are typically tasks with no cost value, which may or may not be recurring.
              These will be visible from your Calendar and To-do lists. Upon completion, reminders are not recorded.</div>
          </Overlay> : <></>}>
            <EventContainer onClick={() => selectType(TTaskTypes.Reminder)}>
              <AddReminderIcon className={"desktop-icon"} />
              <AddEventReminderIcon className={"mobile-icon"} />
              <ContainerText>Reminder</ContainerText>
            </EventContainer>
          </Dropdown>
          <Dropdown placement="bottomCenter" overlay={isOnboarding ? <Overlay>
            <div>Tasks are an event marking maintenance or an improvement to a property. They may have a cost and recurrence.
              These will be visible from your Calendar and To-do lists. Upon completion, tasks may be recorded as projects.</div>
          </Overlay> : <></>}>
            <EventContainer onClick={() => selectType(TTaskTypes.Task)}>
              <AddTaskIcon className={"desktop-icon"} />
              <AddEventTaskIcon className={"mobile-icon"} />
              <ContainerText>Task</ContainerText>
            </EventContainer>
          </Dropdown>
          <Dropdown placement="bottomCenter" overlay={isOnboarding ? <Overlay>
            <div>Projects allow you to link expenses, contacts and numerous tasks. Any work to your
              property - whether an improvement project, maintenance or repair - can be recorded on Livlet as a Projects.</div>
          </Overlay> : <></>}>
            <EventContainer onClick={() => addNewWork()}>
              <AddWorkIcon className={"desktop-icon"} />
              <AddEventProjectIcon className={"mobile-icon"} />
              <ContainerText>Project</ContainerText>
            </EventContainer>
          </Dropdown>
          {!isOnboarding && <EventContainer onClick={() => selectType(TTaskTypes.Budget)}>
            <AddCostIcon className={"desktop-icon"} />
            <AddEventCostIcon className={"mobile-icon"} />
            <ContainerText>Recurring expense</ContainerText>
          </EventContainer>}
        </ContainerItemWrapper>
      </SpaceContainer>
    </SpaceOptions>
  </AddDrawerStyled>
}

const AddDrawerStyled = styled(DrawerStyled)`
  @media (max-width: 768px) {
    .ant-drawer-content {
      padding: 1rem;
      border-radius: 24px 24px 0px 0px;
    }
  }
  
  .ant-drawer-body {
    margin: 1.5rem 0!important;
    padding: 0; 
    overflow-x: hidden;
    &::-webkit-scrollbar { width: 0; }
  }
`

const EventContainer = styled(ContainerItem)`
  gap: 1rem;
  svg { flex-shrink: 0; }
  
  .mobile-icon { display: none; }
  
  @media (max-width: 768px) {
    .desktop-icon { display: none; }
    .mobile-icon { display: inline; }
    
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-top;
    padding: 1rem 0;
    height: auto;
  }
`

const ContainerText = styled.div`
  width: 6rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #45655E;
  text-align: left;
  
  @media (max-width: 768px) {
    width: auto;
    display: flex;
    text-align: center;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #45655E;
  }
`

export default AddEventDrawer;