import React, {useEffect, useState} from "react";
import {ReactComponent as ReturnIcon} from "../../../images/spaces/ReturnIcon.svg";
import {ReactComponent as LinkIcon} from "../../../images/spaces/LinkNewIcon.svg";
import {ReactComponent as EditIcon} from "../../../images/spaces/EditIcon.svg";
import styled from "styled-components";
import {
  ActionButtonGreen, ActionButtonWhite,
  DrawerFooter, DrawerHeader,
  DrawerStyled,
  paginationButtons,
  PaginationStyled
} from "../../screens/components";
import {useDispatch, useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector, getSpacesSelector} from "../../selectors";
import {getSpaceById} from "../../../api-wrapper/spaces/getSpaceById";
import AddNewRecordDrawer from "./AddNewRecordDrawer";
import {getAllSpaces} from "../../actions/spaces";
import LinkedItemsList from "./LinkedItemsList";
import LinkedItemsHeader from "./LinkedItemsHeader";
import {Guid} from "guid-typescript";
import AddLinkedItem from "./AddLinkedItem";

type Props = {
  spaceId: Guid | null;
  readonly?: boolean | false;
  isOpen: boolean;
  toggleDrawerOpen: (arg: boolean) => void;
  toggleEditDrawerOpen: (arg: boolean) => void;
  setEditSpaceId: (arg: any) => void;
  toggleLinkDrawerOpen: (arg: boolean) => void;
  setLinkedSpaceId: (arg: any) => void;
  setPresetLinkType: (arg: string) => void;
  refreshParent?: any;

  toggleViewWorkDrawer: (arg: boolean) => void;
  setViewWorkId: (arg: any) => void;
  toggleWorkDrawer: (arg: boolean) => void;
  setEditWorkData: (arg: any) => void;
  toggleExpenseDrawer: (arg: boolean) => void;
  setEditExpenseData: (arg: any) => void;
  toggleInventoryDrawer: (arg: boolean) => void;
  setEditInventoryData: (arg: any) => void;
  toggleContactDrawer: (arg: boolean) => void;
  setEditContactData: (arg: any) => void;
  toggleNoteDrawer: (arg: boolean) => void;
  setEditNoteData: (arg: any) => void;
  toggleTaskDrawer: (arg: boolean) => void;
  setEditTaskData: (arg: any) => void;
  setAttachmentId: (arg: any) => void;
  toggleViewFileDrawer: (arg: boolean) => void;
}

const ViewSpaceDrawer = (props: Props) => {
  const {spaceId, isOpen, toggleDrawerOpen, toggleLinkDrawerOpen, toggleEditDrawerOpen, setLinkedSpaceId, setEditSpaceId,
    toggleWorkDrawer, toggleContactDrawer, toggleExpenseDrawer, toggleInventoryDrawer, toggleNoteDrawer, setEditWorkData,
    setEditContactData, setEditExpenseData, setEditInventoryData, setEditNoteData, readonly, setPresetLinkType,
    toggleTaskDrawer, setEditTaskData, setViewWorkId, toggleViewWorkDrawer, setAttachmentId, toggleViewFileDrawer, refreshParent} = props;

  const dispatch = useDispatch();
  const properties = useSelector(getPropertiesSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const spaces = useSelector(getSpacesSelector);

  const [searchQuery, setSearchQuery] = useState("");
  const [space, setSpace] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [property, setProperty] = useState("");
  const [spaceLinks, setSpaceLinks] = useState<any>([]);
  const [isNewRecordDrawerOpen, toggleNewRecordDrawer] = useState(false);

  const refreshSpace = () => {
    getSpaceById(propertyId, space.spaceId).then((res: any) => {
      setSpaceLinks([...res.works, ...res.expenses, ...res.inventories, ...res.contacts, ...res.attachments, ...res.spaceNotes,
        ...res.recurringExpenses, ...res.reminders, ...res.tasks])
    })
    refreshParent && refreshParent()
  }

  useEffect(() => {
    if (spaceId) {
      setLinkedSpaceId(spaceId)
      setSpace(spaces.content.filter(item => item.spaceId === spaceId)[0])
    }
  }, [spaces, spaceId])

  useEffect(() => {
    if (space) {
      const spaceProperty = properties.find((property) => property.propertyId === space.propertyId)
      if (spaceProperty) {
        setProperty(spaceProperty.address)
      }
      refreshSpace();
    }
  }, [space, properties])

  const filteredItems = () => spaceLinks.filter((item: any) => searchQuery ?
    (item.name?.toLowerCase().includes(searchQuery.toLowerCase()) || item.title?.toLowerCase().includes(searchQuery.toLowerCase())): true)

  const getItems = () => filteredItems()
    .filter((currentValue: any, index: any) => index >= (page - 1) * 10 && index < page * 10);

  const closeDrawer = () => {
    toggleDrawerOpen(false);
    setPage(1);
    dispatch(getAllSpaces(propertyId));
  }

  useEffect(() => {
    if (getItems().length === 0 && page !== 1) {
      setPage(page - 1)
    }
  }, [getItems()])

  return <>
    <AddNewRecordDrawer
      isOpen={isNewRecordDrawerOpen}
      toggleDrawerOpen={toggleNewRecordDrawer}
      parentId={space ? space.spaceId : null}
      parentType={"space"}
      refreshParent={() => dispatch(getAllSpaces(propertyId))}
    />
    <ViewSpaceDrawerStyled
      style={{zIndex: 10}}
      push={{distance: "32px"}}
      closeIcon={null}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "100%" : "85%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      visible={isOpen}
      onClose={() => closeDrawer()}
      title={<>
        <DrawerHeader>
          <div style={{display: "flex", alignItems: "center", columnGap: "1rem"}}>
            <ReturnIcon style={{cursor: "pointer"}} onClick={() => closeDrawer()} />
            {space ? space.name : null}
          </div>
          <div style={{display: "flex", alignItems: "center", columnGap: "1.5rem"}}>
            {!readonly && <EditIcon style={{cursor: "pointer"}} onClick={() => {
              toggleEditDrawerOpen(true)
              setEditSpaceId(space.spaceId)
            }} />}
          </div>
        </DrawerHeader>
        <SpaceDescription>
          <div className={"row-title"}>Property</div>
          <div className={"row-description"} style={{color: "#9CA4AE"}}>{property}</div>
          <div className={"row-title"}>Type</div>
          <div className={"row-description"}>{space ? space.spaceType : null}</div>
          <div className={"row-title"}>Category</div>
          <div className={"row-description"}>{space ? space.category ?? "-" : null}</div>
          <div className={"row-title"}>Floor</div>
          <div className={"row-description"}>{space ? space.floor ?? "-" : null}</div>
          {space && space.notes && <> <div className={"row-title"}>Notes</div>
              <div className={"row-description"}>{space.notes}</div></>}
          {(space && (space.dimension1 || space.dimension2)) && <> <div className={"row-title"}>Dimensions</div>
              <div className={"row-description"}>{space.dimension1 ? space.dimension1 : ""}{space.dimension1 && space.dimension2 ? " x " : ""}{space.dimension2 ? space.dimension2 : ""}</div></>}
          {space && space.paint && <> <div className={"row-title"}>Paint</div>
              <div className={"row-description"}>{space.paint}</div></>}
        </SpaceDescription>
        {spaceLinks.length > 0 && <LinkedItemsHeader
            title={"Linked items"}
            parentId={spaceId}
            parentType={"space"}
            toggleLinkDrawerOpen={toggleDrawerOpen}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            toggleAddNewRecordDrawer={toggleNewRecordDrawer}
            refreshParent={refreshSpace}
            showFileUploadButton={true}
        />}
      </>}
      footer={<DrawerFooter>
        {window.innerWidth <= 768 && <ActionButtonWhite onClick={() => closeDrawer()}>Cancel</ActionButtonWhite>}
        {!readonly && <ActionButtonGreen onClick={() => toggleLinkDrawerOpen(true)}><LinkIcon /> Add link</ActionButtonGreen>}
      </DrawerFooter>}
    >
      {space && spaceLinks.length === 0 && !readonly && <AddLinkedItem
        toggleLinkDrawerOpen={toggleLinkDrawerOpen}
        toggleNewRecordDrawer={toggleNewRecordDrawer}
        setPresetLinkType={setPresetLinkType}
        excludeTypes={["space"]}
        parentId={space.spaceId}
        parentType={"space"}
        refreshParent={refreshSpace}
      />}
      {space && spaceLinks.length > 0 && <LinkedItemsList
        parentId={space.spaceId}
        parentType={"space"}
        items={getItems()}
        searchQuery={searchQuery}
        isSelectionMode={false}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditNoteData}
        refreshParent={refreshSpace}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleEditDrawerOpen}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditTaskData}
        setViewWorkId={setViewWorkId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />}
      {filteredItems().length > 10 && <PaginationStyled
          className={filteredItems().length < 10 ? "single-page-pagination" : ""}
          onChange={(page) => setPage(page)}
          simple={window.innerWidth < 1024}
          showSizeChanger={false}
          itemRender={paginationButtons}
          defaultCurrent={1}
          current={page}
          total={filteredItems().length}
          defaultPageSize={10}
      />}
    </ViewSpaceDrawerStyled>
  </>
}

const ViewSpaceDrawerStyled = styled(DrawerStyled)`
  .ant-drawer-body {
    margin: 0.5rem 0 1.5rem 0!important;
  }
`

export const SpaceDescription = styled.div`
  border-top: 1px solid #e6e6e6;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #9CA4AE;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  margin: 1rem 0 1.5rem 0;
  padding-top: 1rem;
  
  .row-title { width: 40%; }
  .row-description { width: 60%; color: #666F7A; }
`

export default ViewSpaceDrawer