import {Carousel, Drawer, Popover} from "antd";
import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
// @ts-ignore
import * as bbobReactRender from '@bbob/react/es/render'
// @ts-ignore
import * as presetReact from '@bbob/preset-react'

import { ReactComponent as CrossIcon } from "../../../images/Cross.svg";
import {useDispatch, useSelector} from "react-redux";
import {
  getImprovementsSelector,
  getPropertyIdSelector,
  getPropertyPreferencesSelector,
  getWorksSelector
} from "../../selectors";
import WorkDrawer, {ButtonStyled, ButtonsWrapper, CloseButton} from "../works/WorkDrawer";
import {ReactComponent as ECOIcon} from "../../../images/ECO.svg";
import {ReactComponent as ArrowRightBlue} from "../../../images/ArrowRightBlue.svg";
import {ReactComponent as ArrowLeftBlue} from "../../../images/ArrowLeftBlue.svg";
import {ReactComponent as LeafIcon} from "../../../images/Leaf.svg";
import {ReactComponent as AddIcon} from "../../../images/Add.svg";
import {ReactComponent as CheckIcon} from "../../../images/Check.svg";
import {ButtonChecked} from "../../screens/unused/ImproveMyProperty";
import {ImprovementItem} from "../../../api-wrapper/properties/getImprovements";
import {addWork} from "../../../api-wrapper/works/addWork";
import {getAllWorks} from "../../actions/works";
import {linkImprovementWithWork} from "../../../api-wrapper/properties/improvementsOptions";
import _ from "lodash";
import {getAllImprovements} from "../../actions/improvements";
import {getEntityTypes} from "../../../api-wrapper/relation/getEntityTypes";
import {getCurrencySign} from "../../screens/helpers";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  improvementId: string;
  setImprovementId: (arg01: string) => void;
  setImprovementDone: (arg01: string) => void;
};

const ImprovePropertyDrawer = (props: Props) => {
  const {isOpen, toggleDrawerOpen, improvementId, setImprovementDone, setImprovementId} = props;

  document.addEventListener("click", function(e) {
    // @ts-ignore
    if (e.target.tagName == "A" &&
      // @ts-ignore
      !e.target.hasAttribute("target"))
    {
      // @ts-ignore
      e.target.setAttribute("target", "_blank");
    }
  });

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const improvementsConfiguration = useSelector(getImprovementsSelector);
  const works = useSelector(getWorksSelector);
  const descriptionRef = useRef(null);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [workType, setWorkType] = useState<any>("")
  const [improvementItem, setImprovementItem] = useState<ImprovementItem>({
    id: "",
    parentId: 0,
    name: "",
    summary: "",
    category: 0,
    subCategory: 0,
    priority: 0,
    doneByAnswerOn: null,
    doneOn: null,
    wishListedOn: null,
    displayOrder: 0,
    keywords: [],
    relatedImprovements: [],
    incentives: [],
    relatedWorkId: null,
    ecoImpactRating: 0,
    livletRating: 0,
    savingsRating: 0,
    costRating: 0,
    relativeEffortOfWorkRequired: "",
    specialtyOfWorkRequired: "",
    description: "",
  });

  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false);
  const [isNewWork, setIsNewWork] = useState(false);
  const [editingWorkData, setEditingWorkData] = useState<any>({
    data: {
      name: "",
      type: "",
      completedDate: "",
      dueDate: "",
      recurring: "",
      workId: 0,
      propertyId: 0,
      isComplete: false,
    },
  });

  useEffect(() => {
    if (improvementId)
      setImprovementItem(improvementsConfiguration.content.improvements.filter(improvement =>
        improvement.id === improvementId)[0]);
  }, [improvementsConfiguration, improvementId]);

  const setLocalImprovementDone = () => {
    if (_.isNil(improvementItem.doneOn)) {
      setImprovementItem({...improvementItem, doneOn: 1});
    } else {
      setImprovementItem({...improvementItem, doneOn: null});
    }
  }

  useEffect(() => {
    getEntityTypes(propertyId, "work").then((res: any) => {
      res && setWorkType(res.filter((type: any) => type.name.toLowerCase().includes("improvement"))[0].name)
    })
  }, [propertyId])


  const createWork = () => {
    addWork({
      propertyId,
      name: improvementItem.name,
      type: workType,
      completedDate: null,
      dueDate: null,
      recurring: null,
      isComplete: false,
    }).then((res) => {
      setIsNewWork(true);
      toggleDrawerOpen(false);
      setEditingWorkData(res);
      res.data && linkImprovementWithWork(propertyId, improvementItem.id, res.data.workId).then(() => {
        dispatch(getAllWorks(propertyId));
        dispatch(getAllImprovements(propertyId));
        toggleWorkDrawer(true);
      });
    });
  }

  return <>
    <WorkDrawer
      width={null}
      isOpen={isWorkDrawerOpen}
      toggleDrawerOpen={toggleWorkDrawer}
      // @ts-ignore
      setEditingWorkData={setEditingWorkData}
      work={editingWorkData}
      parentDrawer={"improvement"}
      isNewWork={isNewWork}
      parentImprovement={improvementItem}
      toggleParentDrawer={toggleDrawerOpen}
      refreshParent={() => {
        dispatch(getAllImprovements(propertyId));
      }}
    />
    <Drawer
      closeIcon={false}
      width={window.innerWidth > 1024 ? "55%" : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => {
        toggleDrawerOpen(false);
      }}
      headerStyle={{
        backgroundColor: "#ebf9ff",
        margin: 0,
        padding: "2rem 2rem",
      }}
      title={
        <>
          <section
            style={{
              display: "flex",
              flexDirection: window.innerWidth > 1024 ? "row" : "column",
              justifyContent: "space-between",
              marginBottom: "1.5rem",
            }}
          >
            <InventoriesIconWrapper
              style={{marginBottom: window.innerWidth > 1024 ? "0" : "0.5rem"}}>
              <ECOIcon className={"improve-property-theme-icon"}/>{" "}
              <span style={{paddingRight: "0.25rem", fontSize: "0.75rem"}}>MY IMPROVEMENTS</span>
            </InventoriesIconWrapper>
            <ButtonsWrapper>
              <Popover
                content={<div style={{
                  fontSize: "0.75rem",
                  fontWeight: 500,
                  padding: "0.5rem",
                  maxWidth: "17rem",
                  textAlign: "center"
                }}>
                  {!_.isNil(improvementItem.doneByAnswerOn) && "Done based on provided property details related to this improvement."}
                  {!_.isNil(improvementItem.doneOn) && _.isNil(improvementItem.doneByAnswerOn) && "Unmark as done"}
                  {_.isNil(improvementItem.doneOn) && _.isNil(improvementItem.doneByAnswerOn) && "Mark as done"}
                </div>}
                overlayClassName={"smallTooltipPopover"}
                placement="bottom"
                trigger={["hover"]}
              >
                <ButtonChecked
                  icon={
                    (!_.isNil(improvementItem.doneOn) || !_.isNil(improvementItem.doneByAnswerOn)) && <CheckIcon/>
                  }
                  onClick={() => {
                    setImprovementDone(improvementItem.id)
                    setLocalImprovementDone();
                  }}
                  disabled={!_.isNil(improvementItem.doneByAnswerOn)}
                >
                  {_.isNil(improvementItem.doneOn) && " "}
                </ButtonChecked>
              </Popover>
              <ButtonStyled
                style={{marginLeft: "1.5rem"}}
                size={"large"}
                icon={_.isNil(improvementItem.relatedWorkId) && <AddIcon className={"search-stroke-theme-icon"}/>}
                onClick={() => {
                  if (_.isNil(improvementItem.relatedWorkId)) {
                    createWork();
                  } else {
                    setEditingWorkData({
                      data: works.content.filter((work) => work.workId === improvementItem.relatedWorkId)[0],
                    });
                    toggleDrawerOpen(false);
                    setIsNewWork(false);
                    toggleWorkDrawer(true);
                  }
                }}
              >
                <span style={{opacity: 1, color: "#21272B", marginLeft: "0.5rem"}}>
                  {_.isNil(improvementItem.relatedWorkId)
                    ? "Create work"
                    : "View work"}
                </span>
              </ButtonStyled>
              {window.innerWidth < 1024 && <CloseButton
                size={"large"}
                onClick={() => toggleDrawerOpen(false)}>
                {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
              </CloseButton>}
            </ButtonsWrapper>
          </section>
          <Title>{improvementItem.name}</Title>
          <div style={{fontSize: "1rem", fontWeight: 400, marginTop: "1.5rem", color: "#21272B"}}>
            {improvementItem.summary}
          </div>
          <ImprovementRatingsWrapper>
            <ImprovementRatingColumn>
              <ImprovementRatingTitle>
                <span>ECO BENEFITS</span>
              </ImprovementRatingTitle>
              <ImprovementRatingValue>
                {improvementItem.ecoImpactRating > 0
                  ? [...Array(improvementItem.ecoImpactRating)].map(() =>
                    <LeafIcon style={{marginTop: "-0.5rem"}}/>
                  )
                  : <span style={{color: "#67C18B"}}>-</span>
                }
              </ImprovementRatingValue>
            </ImprovementRatingColumn>
            <ImprovementRatingColumn>
              <ImprovementRatingTitle>
                <span>SAVINGS</span>
              </ImprovementRatingTitle>
              <ImprovementRatingValue style={{color: "#EFC45D"}}>
                {improvementItem.savingsRating > 0
                  ? [...Array(improvementItem.savingsRating)].map(() => {
                    return getCurrencySign(propertyPreferences.preferredCurrency)
                  })
                  : "-"
                }
              </ImprovementRatingValue>
            </ImprovementRatingColumn>
            <ImprovementRatingColumn>
              <ImprovementRatingTitle>
                <span>COST</span>
              </ImprovementRatingTitle>
              <ImprovementRatingValue style={{color: "#EF624E"}}>
                {improvementItem.costRating > 0
                  ? [...Array(improvementItem.costRating)].map(() => {
                    return getCurrencySign(propertyPreferences.preferredCurrency)
                  })
                  : "-"
                }
              </ImprovementRatingValue>
            </ImprovementRatingColumn>
            <ImprovementRatingColumn>
              <ImprovementRatingTitle>
                <span>COMPLEXITY</span>
              </ImprovementRatingTitle>
              <ImprovementRatingValue style={{color: "#6B7185"}}>
                {improvementItem.relativeEffortOfWorkRequired}
              </ImprovementRatingValue>
            </ImprovementRatingColumn>
          </ImprovementRatingsWrapper>
          <ImprovementRatingsCarousel
            arrows
            nextArrow={<ArrowRightBlue style={{cursor: "pointer"}}/>}
            prevArrow={<ArrowLeftBlue style={{cursor: "pointer"}}/>}
          >
            <ImprovementRatingsSlide>
              <ImprovementRatingTitle>
                <span>ECO BENEFITS</span>
              </ImprovementRatingTitle>
              <ImprovementRatingValue>
                {improvementItem.ecoImpactRating > 0
                  ? [...Array(improvementItem.ecoImpactRating)].map(() =>
                    <LeafIcon style={{marginTop: "-0.5rem"}}/>
                  )
                  : <span style={{color: "#67C18B"}}>-</span>
                }
              </ImprovementRatingValue>
            </ImprovementRatingsSlide>
            <ImprovementRatingsSlide>
              <ImprovementRatingTitle>
                <span>SAVINGS</span>
              </ImprovementRatingTitle>
              <ImprovementRatingValue style={{color: "#EFC45D"}}>
                {improvementItem.savingsRating > 0
                  ? [...Array(improvementItem.savingsRating)].map(() => {
                    return getCurrencySign(propertyPreferences.preferredCurrency)
                  })
                  : "-"
                }
              </ImprovementRatingValue>
            </ImprovementRatingsSlide>
            <ImprovementRatingsSlide>
              <ImprovementRatingTitle>
                <span>COST</span>
              </ImprovementRatingTitle>
              <ImprovementRatingValue style={{color: "#EF624E"}}>
                {improvementItem.costRating > 0
                  ? [...Array(improvementItem.costRating)].map(() => {
                    return getCurrencySign(propertyPreferences.preferredCurrency)
                  })
                  : "-"
                }
              </ImprovementRatingValue>
            </ImprovementRatingsSlide>
            <ImprovementRatingsSlide>
              <ImprovementRatingTitle>
                <span>COMPLEXITY</span>
              </ImprovementRatingTitle>
              <ImprovementRatingValue style={{color: "#6B7185"}}>
                {improvementItem.relativeEffortOfWorkRequired}
              </ImprovementRatingValue>
            </ImprovementRatingsSlide>
            <ImprovementRatingsSlide>
              <ImprovementRatingTitle>
                <span>REQUIRED</span>
              </ImprovementRatingTitle>
              <ImprovementRatingValue style={{color: "#6B7185"}}>
                {improvementItem.specialtyOfWorkRequired}
              </ImprovementRatingValue>
            </ImprovementRatingsSlide>
          </ImprovementRatingsCarousel>
        </>
      }
    >
      <>
        <div ref={descriptionRef} />
        {/*{improvementItem.ecoImpactRating > 0 && <div style={{margin: "1rem 0", fontSize:"1rem", display:"flex", alignItems:"center"}}>*/}
        {/*  <LeafIcon style={{marginRight: "1rem"}}/>Implementing this improvement is the equivalent of planting {improvementItem.ecoImpactRating} tree{improvementItem.ecoImpactRating > 1 && "s"} a year.*/}
        {/*</div>}*/}
        <div style={{margin: "0", fontSize:"1rem"}}>
          {bbobReactRender.render(improvementItem.description, presetReact(), {})}
        </div>
        {improvementItem.relatedImprovements.length > 0 &&
          <div style={{marginTop: "2rem", fontSize: "1.125rem", fontWeight: 600, color: "#21272B"}}>
            Related measures
          </div>
        }
        {improvementItem.relatedImprovements.map(relatedImprovementId =>
          (improvementsConfiguration.content.improvements.filter(relatedImprovement => relatedImprovement.id === relatedImprovementId).length > 0) &&
            <section
                style={{
                  border: "1px solid #DAE0E6",
                  boxSizing: "border-box",
                  borderRadius: "6px",
                  padding: "1.5rem",
                  marginTop: "1em",
                }}
            >
                <RelatedMeasureTitle onClick={() => {
                  window.scrollTo(0, 0)
                  // @ts-ignore
                  descriptionRef.current.scrollIntoView()
                  setImprovementId(relatedImprovementId)
                }}>
                  {improvementsConfiguration.content.improvements.filter(relatedImprovement => relatedImprovement.id === relatedImprovementId)[0].name}
                </RelatedMeasureTitle>
                <div style={{marginTop: "1rem"}}>
                  {improvementsConfiguration.content.improvements.filter(relatedImprovement => relatedImprovement.id === relatedImprovementId)[0].summary}
                </div>
            </section>
        )}
        {improvementItem.incentives.length > 0 &&
          <div style={{fontSize: "1.125rem", fontWeight: 600, color: "#21272B", marginTop:"1.5rem"}}>
            Incentives
          </div>
        }
        {improvementItem.incentives.map(incentiveId => improvementsConfiguration.content.incentives.filter(incentive => incentive.id === incentiveId).map(incentive =>
          <section
            style={{
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "6px",
              padding: "1.5rem",
              marginTop: "1em",
            }}
          >
            <span style={{fontSize: "1.125rem", fontWeight: 600, color: "#21272B"}}>
              {incentive.title}
            </span>
            <div style={{marginTop: "1rem"}}>
              {incentive.description}
            </div>
            <div style={{marginTop: "1rem"}}>
              Source: <IncentiveLink href={incentive.linkToSource} target={"_blank"}>{new URL(incentive.linkToSource).hostname.replace("www.","")}</IncentiveLink>{" "}↗
            </div>
          </section>
        ))}
      </>
    </Drawer>
  </>
};


const Title = styled.span`
  border: none;
  outline: none;
  background-color: #ebf9ff;
  font-size: 2.5rem;
  line-height: normal;
  @media (max-width: 1024px) {
    font-size: 2rem;
  }  
`;

const InventoriesIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

const ImprovementRatingTitle = styled.span`
  display: flex;
  align-items: center;
  margin-left: 0.2em;
  font-size: 0.75em;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const ImprovementRatingValue = styled.div`
  font-weight: 500;
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: 700;
`;

const ImprovementRatingColumn = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2.5em;
  padding-right: 2.5em;
  width: 12em;
`;

const ImprovementRatingsWrapper = styled.span`
  @media (max-width: 1024px) {
    display: none!important;
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const ImprovementRatingsCarousel = styled(Carousel)`
  @media (min-width: 1024px) {
    display: none!important;
  }

  height: 4rem;
  
  & > ul.slick-dots.slick-dots-bottom li { 
    display: none 
  }

  & > svg.slick-arrow.slick-prev {
    width: 2.5rem; 
    height: 2.5rem;
    z-index: 2;
  }
  
  & > svg.slick-arrow.slick-next {
    width: 2.5rem;
    height: 2.5rem;
    z-index: 2;
  }
`

const ImprovementRatingsSlide = styled.div`
  text-align: center;
  color: black;
  margin-top: 0.5rem;
`

// const ImprovementRatingValueMobile = styled.div`
//   font-weight: 500;
//   // padding: 0.5em 0.5em 0.5em 0;
//   // margin-top: 0.5em;
//   font-size: 2.5em;
//   text-align: center;
// `;

const RelatedMeasureTitle = styled.a`
  font-size: 1.125rem;
  font-weight: 600;
  color: #21272B;
  :hover {
    color: #2A80E1;
  }
`;

const IncentiveLink = styled.a`
  color: #21272B;
  text-decoration: underline !important;
  :hover {
    color: #2A80E1;
  }
`;

export default ImprovePropertyDrawer;
