import {Drawer, Form, Input, Modal, notification} from "antd";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import styled from "styled-components";

import {ReactComponent as MyPropertyIcon} from "../../../images/MyProperty.svg";
import messages from "../../screens/messages";
import {Label, LabelWrapper,} from "../../screens/RegistrationPage";
import {useDispatch, useSelector} from "react-redux";
import {authSelector, getSubscriptionUsersSelector, getUserProfileSelector} from "../../selectors";
import {ButtonsWrapper, CloseButton, SaveButton, SaveButtonWrapper} from "../works/WorkDrawer";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import {inviteUser} from "../../../api-wrapper/user/inviteUser";
import {getAllSubscriptionUsers} from "../../actions/subscriptionUsers";
import {emailRegex} from "../../screens/helpers";
import {deleteInvite} from "../../../api-wrapper/user/deleteInvite";
import {ReactComponent as AlertIcon} from "../../../images/AlertRed.svg";
import {TFetchingStatus} from "../../../helpers";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  contributorEmail: string | null;
};

const ContributorDrawer = (props: Props) => {
  const { isOpen, toggleDrawerOpen, contributorEmail } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {formatMessage: f} = useIntl();
  const subscriptionUsers = useSelector(getSubscriptionUsersSelector);
  const userProfile = useSelector(getUserProfileSelector);
  const auth = useSelector(authSelector);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    if (contributorEmail) {
      form.setFieldsValue({
        email: contributorEmail,
        name: subscriptionUsers.content.filter(user => user.email === contributorEmail)[0].name
      });
    } else {
      form.resetFields();
    }
  }, [contributorEmail, form, isOpen]);

  useEffect(() => {
    if (userProfile.fetchingStatus === TFetchingStatus.Success && userProfile.content.name) {
      form.setFieldsValue({ownerName: userProfile.content.name})
    }
  }, [userProfile, form, isOpen])

  const performCleanup = () => {
    toggleDrawerOpen(false);
    setSaveDisabled(false);
    setEmailError(false);
    setFormFieldsChanged(false);
    form.resetFields();
  }

  const handleDeleteInvite = () => {
    Modal.confirm({
      title: "Confirm deletion",
      content:
        "You’re going to remove "+form.getFieldValue("name")+" from your family account.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        deleteInvite({
          email: contributorEmail !== null ? contributorEmail : "",
          name: subscriptionUsers.content.filter(user => user.email === contributorEmail)[0].name,
          subscriptionId: auth.tenantId
        }).then(() => {
          performCleanup();
          dispatch(getAllSubscriptionUsers(auth.tenantId));
        });
      }
    });
  };

  const handleManageContributor = (e: {
    ownerName: string;
    email: string;
    name: string;
  }) => {
    const { ownerName, name, email } = e;
    const subscriptionId = auth.tenantId;

    if (subscriptionUsers.content.filter(user => user.email === email).length > 0) {
      setEmailError(true);
      return;
    }
    setSaveDisabled(true);

    inviteUser({
      ownerName,
      name,
      email,
      subscriptionId
    }).then(() => {
      performCleanup();
      dispatch(getAllSubscriptionUsers(auth.tenantId));
      notification.success({
        message: "Invitation email sent!",
        description: "Invitation email sent!\n" +
          "We’ve sent an invitation email to "+email+" to join your Livlet account as an additional user. By following the link they will be able to contribute to your Livlet account.",
        placement: "topRight",
        duration: 4
      });
    }).catch((err) => {
      if (err && err.data && err.data.internalCode === 4002) {
        setEmailError(true);
        setSaveDisabled(false);
      }
    });
  };

  const initialValues = () => {
    if ( subscriptionUsers.content.filter(user => user.email === contributorEmail).length === 0) {
      return {};
    }
    const { email, name } = subscriptionUsers.content.filter(user => user.email === contributorEmail)[0];
    return {
      email,
      name,
    };
  };

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        performCleanup();
        dispatch(getAllSubscriptionUsers(auth.tenantId));
      },
    });
  }

  return (
    <Drawer
      closeIcon={false}
      width={window.innerWidth > 1024 ? "55%" : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => {
        if (formFieldsChanged) {
          confirmCloseUnsaved();
          return;
        }
        performCleanup();
      }}
      headerStyle={{
        backgroundColor: "#ebf9ff",
        margin: 0,
        padding: "2rem 2rem",
      }}
      title={
        <>
          <section
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.5rem",
            }}
          >
            <InventoriesIconWrapper>
              <MyPropertyIcon className={"my-property-theme-icon"} />{" "}
              <span style={{ paddingRight: "0.25rem", fontSize:"0.75rem" }}>Account details</span>
            </InventoriesIconWrapper>
            <ButtonsWrapper>
              <CloseButton
                size={"large"}
                onClick={() => performCleanup()}
              >
                {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
              </CloseButton>
            </ButtonsWrapper>
          </section>
          <Title style={{lineHeight: "2rem"}}>Additional user account</Title>
        </>
      }
    >
      <Form
        form={form}
        name="manageProperty"
        layout="vertical"
        size={"large"}
        onFinish={handleManageContributor}
        initialValues={initialValues()}
        onFieldsChange={() => setFormFieldsChanged(true)}
      >
        <>
          {!contributorEmail && <>
            <LabelWrapper>
              <Label>Your name * <span style={{color: "#00B74F", fontSize: "0.875rem"}}>
                (The invitation to the new user will be from this name)
              </span></Label>
            </LabelWrapper>
            <Form.Item
              key={"ownerName"}
              name={"ownerName"}
              rules={[{required: true, message: "Please enter your name"}]}
            >
              <InputStyled
                maxLength={100}
                placeholder={"Enter your name"}
              />
            </Form.Item>
          </>}
          <span style={{fontSize: "1.125rem", fontWeight: 600, color: "#21272B"}}>
            Additional user account
          </span>
          <section
            style={{
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "10px",
              padding: "1rem",
              marginTop: "1em",
            }}
          >
            <div style={{paddingBottom: "0.5rem"}}>
              <LabelWrapper>
                <Label>{f(messages.name)} *</Label>{" "}
              </LabelWrapper>
              <Form.Item
                key={"name"}
                name={"name"}
                rules={[{required: true, message: "Please enter the contributor's name"}]}
              >
                <InputStyled
                  maxLength={100}
                  placeholder={f(messages.enterFullName)}
                  disabled={contributorEmail !== null}
                />
              </Form.Item>
              <LabelWrapper>
                <Label>{f(messages.emailAddress)}</Label>{" "}
              </LabelWrapper>
              <Form.Item
                key={"email"}
                name={"email"}
                rules={[
                  {
                    required: true,
                    pattern: emailRegex,
                    message: f(messages.validationEmail),
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (emailError) {
                        callback("");
                      } else {
                        callback();
                      }
                    }
                  },
                ]}
              >
                <InputStyled
                  maxLength={100}
                  placeholder={f(messages.enterEmailAddress)}
                  onChange={() => {setEmailError(false)}}
                  disabled={contributorEmail !== null}
                />
              </Form.Item>
              {emailError &&
              <div
                  style={{
                    backgroundColor: "#FCF0F0",
                    padding: "1rem",
                    marginTop: "1em",
                    marginBottom: "1em",
                    display: "flex",
                    borderRadius: 6,
                    fontWeight: 400,
                  }}
              >
                  <AlertIcon />
                  <span style={{ paddingLeft: "0.5rem" }}>
                Email already exists in the system.
                </span>
              </div>}
              <SaveButtonWrapper>
                {contributorEmail !== null ?
                  <SaveButton
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    type={"primary"}
                    onClick ={() => handleDeleteInvite()}
                  >
                    Delete
                  </SaveButton>
                  :
                  <SaveButton
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    type={"primary"}
                    htmlType={"submit"}
                    loading={saveDisabled}
                  >
                    Add user
                  </SaveButton>
                }
              </SaveButtonWrapper>
            </div>
          </section>
        </>
      </Form>
    </Drawer>
  );
};


const Title = styled.span`
  border: none;
  outline: none;
  background-color: #ebf9ff;
  font-size: 2.5rem;
  @media (max-width: 1024px) {
    font-size: 2rem;
  }  
`;

const InventoriesIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

const InputStyled = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
  color: rgb(107, 113, 133);
`;

export default ContributorDrawer;
