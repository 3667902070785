import { Guid } from "guid-typescript";
import React, {useState} from "react";
import Carousel from 'react-spring-3d-carousel';
import CarouselGuidanceItem from "./CarouselGuidanceItem";
import { config } from "react-spring";
import {useHistory} from "react-router-dom";
import styled from "styled-components";

type Props = {
  improvements: any;
  categories: any;
}

const GuidanceCarousel = (props: Props) => {
  const {improvements, categories} = props;
  let history = useHistory();
  const [goToSlide, setGoToSlide] = useState(0);

  const filteredImprovements = improvements.filter((item: any) =>
    item.name.includes("Install high efficiency gas boiler") ||
    item.name.includes("Install heat pump (ASHP, GSHP, hybrid) for heating and hot water") ||
    item.name.includes("Insulate hot water cylinder and hot water pipes") ||
    item.name.includes("Install on-site renewable energy systems: PVs"))

  const slides = filteredImprovements.map((item: any, index: any) => {
    return {
      key: Guid.create(),
      content: <CarouselGuidanceItem
        item={item}
        categories={categories}
        onClickAction={index === goToSlide ?
          () => history.push(`/guidance/improvement/${item.category}/${item.id}`) : () => setGoToSlide(index)}
        isActive={index === goToSlide}
      />
    }
  })

  return <CarouselWrapper>
    <Carousel
      slides={slides}
      showNavigation={false}
      goToSlide={goToSlide}
      animationConfig={config.gentle}
    />
    <DotWrapper>
      {filteredImprovements.map((item: any, index: any) => index === goToSlide ?
        <DotGreen onClick={() => setGoToSlide(index)} /> :
        <DotGray onClick={() => setGoToSlide(index)} />)}
    </DotWrapper>
  </CarouselWrapper>
}

const CarouselWrapper = styled.div`
  max-width: 22rem;
  height: 21.5rem;
  margin: auto;
`

const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 0.625rem;
  width: 100%;
`

const DotGray = styled.div`
  cursor: pointer;
  background: #959595;
  opacity: 0.2;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
`

const DotGreen = styled.div`
  cursor: pointer;
  background: #00B74F;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
`

export default GuidanceCarousel