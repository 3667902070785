import React, {useEffect, useState} from "react"
import styled from "styled-components";
import {
  borderSetting,
  ViewAllButton,
  WidgetItemCount,
  WidgetItemCountNumber,
  WidgetItemTitle,
  WidgetWrapper
} from "./ImprovementsWidget";
import {useDispatch, useSelector} from "react-redux";
import {
  getConfirmedExpensesSelector,
  getExpensesSelector,
  getExpenseTypesSelector,
  getMaintenancesSelector, getPropertiesSelector,
  getPropertyIdSelector, getPropertyPreferencesSelector,
  getWorksSelector
} from "../../selectors";
import _ from "lodash";

import {ReactComponent as AllTimeTotalIcon} from "../../../images/dashboard-costs/AllTimeTotalIcon.svg";
import {ReactComponent as EstimatedCostsIcon} from "../../../images/dashboard-costs/EstimatedCostsIcon.svg";
import {ReactComponent as ThisYearTotalIcon} from "../../../images/dashboard-costs/ThisYearTotalIcon.svg";
import {ReactComponent as CostsWidgetIcon} from "../../../images/dashboard-costs/CostsWidgetIcon.svg";
import {ReactComponent as ViewAllArrow} from "../../../images/dashboard-improvements/ViewAllArrow.svg";

import {numberWithCommasFull} from "../helper";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {Select, Spin} from "antd";
import {Guid} from "guid-typescript";
import {TGetExpenseTypesResponse} from "../../../api-wrapper/expenses/getExpenseTypes";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import {getUpcomingTasks, TUpcomingTask} from "../../../api-wrapper/upcomingTasks/getUpcomingTasks";
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons";
import {CalendarPlaceholder, WidgetPlaceholderText} from "../upcomingTasksCalendar/UpcomingTaskCalendar";
import {ViewAllButtonWhite} from "./HomeValueWidget";
import CostsChart, {expenseTypes} from "./CostsChart";
import {TFetchingStatus} from "../../../helpers";
import {putDismissOnboarding} from "../../../api-wrapper/properties/putDismissOnboarding";
import {getAllProperties} from "../../actions/properties";
import {getCurrencySign} from "../../screens/helpers";

type Props = {
  newData?: number | null;
  fullWidth?: boolean | null;
}

const CostsWidget = (props: Props) => {
  const dispatch = useDispatch()
  let history = useHistory();
  const properties = useSelector(getPropertiesSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const confirmedExpenses = useSelector(getConfirmedExpensesSelector);
  const works = useSelector(getWorksSelector);
  const databaseExpenseTypes = useSelector(getExpenseTypesSelector);
  const expenses = useSelector(getExpensesSelector);
  const maintenances = useSelector(getMaintenancesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [upcomingTasksLocal, setUpcomingTasksLocal] = useState<Array<TUpcomingTask>>([]);
  const [topExpenses, setTopExpenses] = useState<any>([]);
  const [categoryToShow, setCategoryToShow] = useState<any>("All");
  const [periodToShow, setPeriodToShow] = useState<any>(12);
  const [budgetCosts, setBudgetCosts] = useState(0);
  const [dataDownloaded, setDataDownloaded] = useState(false);
  const [dataProcessed, setDataProcessed] = useState(false);
  const [costTotals, setCostTotals] = useState({
    improvements: 0,
    maintenances: 0,
    lifestyle: 0,
    landlord: 0,
    unconfirmed: 0
  });

  const [topLevelExpensesTypes, setTopLevelExpensesTypes] = useState<TGetExpenseTypesResponse>([]);
  const [typeForParentMap, setTypeForParentMap] = useState<Map<string, string>>(new Map());

  useEffect(() => {
    const budgetingSubscribed = maintenances.content.filter(task => (task.actualEstimatedCost > 0 ||
      !task.actualEstimatedCost && task.estimatedCost > 0) && task.subscribed)
    setBudgetCosts(budgetingSubscribed.length)
    setDataProcessed(true);
  }, [maintenances, props.newData])

  useEffect(() => {
    let topLevel: any = [];
    let typeForParentMapTmp = new Map<string, string>();
    for (const [key, value] of Object.entries(databaseExpenseTypes.content)) {
      if (_.isNil(value.parentId)) {
        topLevel.push(value);
      } else {
        typeForParentMapTmp.set("" + value.id, "" + value.parentId);
      }
    }
    setTopLevelExpensesTypes(topLevel);
    setTypeForParentMap(typeForParentMapTmp);
  }, [propertyId, databaseExpenseTypes]);

  useEffect(() => {
    if (confirmedExpenses.fetchingStatus !== TFetchingStatus.Success) {
      setDataDownloaded(false);
      return;
    }
    getUpcomingTasks({
      propertyId: propertyId,
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().add(Number.parseInt(periodToShow)+1,'months').format('YYYY-MM-01'),
      firstInSeries: false
    }).then((payload) => {
        payload && setUpcomingTasksLocal(payload.tasks);
        setDataDownloaded(true);
      })
  }, [propertyId, periodToShow, confirmedExpenses, props.newData])

  const allCosts =
    confirmedExpenses.content
      .map(cost => ({totalCost: cost.totalCost, paymentDate: cost.paymentDate, type: cost.type}))
      .concat(
        upcomingTasksLocal
          .filter((task: TUpcomingTask) => (!_.isNil(task.task.estimatedCost) || !_.isNil(task.task.totalCost)) && !_.isNil(task.date) && moment(task.date).isBefore(moment().add(Number.parseInt(periodToShow) + 1, 'month'), 'month'))
          .filter((task:TUpcomingTask) => !task.task.expenseId || confirmedExpenses.content.findIndex(exp => exp.expenseId === task.task.expenseId) < 0)
          .map((task: TUpcomingTask) => ({
            totalCost: !_.isNil(task.task.estimatedCost) ? task.task.estimatedCost : task.task.totalCost,
            paymentDate: task.date,
            type: !_.isNil(task.task.expenseType) && !_.isNil(task.task.expenseType.parentExpenseType) ? task.task.expenseType.parentExpenseType.name : "",
          }))
      ).filter(cost => {
        if (categoryToShow === "All") return true;
        return cost.type === categoryToShow;
      });

  const selectedCosts = allCosts
    .filter(cost =>
      moment(cost.paymentDate).isBetween(moment().subtract(periodToShow, 'months'), moment().add(Number.parseInt(periodToShow)+1, 'months'), 'days', '[]'))

  const allTimeTotalCost = allCosts
    .filter(cost => moment(cost.paymentDate).isBefore(moment()))
    .reduce((sumOfCosts, { totalCost }) => sumOfCosts + totalCost, 0);

  const pastTotalCost = selectedCosts.filter(cost =>
    moment(cost.paymentDate).isBetween(moment().subtract(periodToShow, 'months'), moment(),'days', '[]')
  ).reduce(
    (sumOfCosts, { totalCost }) => sumOfCosts + totalCost, 0);

  const futureTotalCost = selectedCosts.filter(cost =>
    moment(cost.paymentDate).isBetween(moment().add(1, 'day'), moment().add(periodToShow, 'months'),'days', '[]')
  )
    .map(cost => ({totalCost: cost.totalCost}))
    .reduce(
    (sumOfCosts, { totalCost }) => sumOfCosts + totalCost, 0);

  const workNameForId = (workId: Guid) =>
    (works.content.filter((work) => work.workId === workId)[0])?.name;

  useEffect(() => {
    let sourceList = confirmedExpenses.content.map(exp => {
      if (exp.parentType === "work") {
        return {totalCost: exp.totalCost, paymentDate: exp.paymentDate, name: workNameForId(exp.parentId), processed: false}
      } else {
        return {name: exp.name, totalCost: exp.totalCost, paymentDate: exp.paymentDate, processed: false};
      }
    });

    sourceList = sourceList.concat(
      upcomingTasksLocal
        .filter((task:TUpcomingTask) => (!_.isNil(task.task.estimatedCost) || !_.isNil(task.task.totalCost)))
        .filter((task:TUpcomingTask) => !task.task.expenseId || confirmedExpenses.content.findIndex(exp => exp.expenseId === task.task.expenseId) < 0)
        .map((task:TUpcomingTask) => ({name: task.title, paymentDate: task.task.triggerDate, totalCost: !_.isNil(task.task.estimatedCost) ? task.task.estimatedCost : task.task.totalCost, processed: false}))
    );

    let topList:{name: string, totalCost: number}[] = [];
    sourceList.forEach(cost => {
      if (cost.processed)
        return;
      if (cost.totalCost <= 0)
        return;

      topList.push({
        name: cost.name,
        totalCost:
          sourceList.filter(matchedCost => {
            if (matchedCost.processed)
              return false;
            if (matchedCost.name === cost.name) {
              matchedCost.processed = true;
              return true;
            } else
              return false;
          }).reduce(
            (sumOfExpenses: any, {totalCost}: any) => sumOfExpenses + totalCost,
            0
          )
      });
      cost.processed = true;
    });

    setTopExpenses(_.orderBy(topList, "totalCost", "desc").slice(0, 4));
  }, [confirmedExpenses, works, upcomingTasksLocal])

  const showWidget = (budgetCosts > 0 || expenses.content.filter((expense) => expense.isConfirmed).length >= 3 ||
    works.content.filter((work) => work.type === "Expenses").length > 0)
    || properties.find((property) => property.propertyId === propertyId)?.dismissExpenses;

  const placeholderWidget = () => (<WidgetPlaceholder>
    <WidgetPlaceholderText>
      <CloseOutlined
        style={{position:"absolute", top: "0.5rem", right: "0.5rem", cursor:"pointer"}}
        onClick={() => putDismissOnboarding({propertyId, dismissExpenses: true})
          .then(() => {dispatch(getAllProperties());})}
      />
      <span style={{fontWeight: 600, color: "#21272B"}}>Here is where you'll be able to view your dashboard expense tracker</span>
      <span>The expense tracker will allow you to visualise your actual and forecast expenses. It is composed of expenses from your records and your budget expenses.</span>
      <span>Right now we've not much to show as we don't have much expense information to display.</span>
      <span style={{fontWeight: 600, color: "#21272B"}}>Let's start by creating a few budget expenses.</span>
      <ViewAllButton onClick={() => {
        history.push("/questions?openPage=budgetCosts")
      }}>
        Select budget expenses <ViewAllArrow/>
      </ViewAllButton>
    </WidgetPlaceholderText>
  </WidgetPlaceholder>)

  if (!dataDownloaded) {
    return <Spin indicator={<LoadingOutlined style={{fontSize: 72}} spin/>}/>
  }

  return <OuterWidgetWrapper>
    <WidgetHeader>
      <WidgetTitle><CostsWidgetIcon /> Expenses</WidgetTitle>
      {showWidget && <WidgetControls>
        <WidgetSelectControl>
          <WidgetControlTitle>Show</WidgetControlTitle>
          <SelectStyled
            style={{width: window.innerWidth > 1024 ? "14.75rem" : "100%"}}
            defaultValue={categoryToShow}
            onChange={(e) => setCategoryToShow(e)}>
              <Select.Option key={"all"} value={"All"}>All</Select.Option>
              <Select.Option key={expenseTypes.maintenance} value={expenseTypes.maintenance}>{expenseTypes.maintenance}</Select.Option>
              <Select.Option key={expenseTypes.improvement} value={expenseTypes.improvement}>{expenseTypes.improvement}</Select.Option>
              <Select.Option key={expenseTypes.lifestyle} value={expenseTypes.lifestyle}>{expenseTypes.lifestyle}</Select.Option>
              <Select.Option key={expenseTypes.landlord} value={expenseTypes.landlord}>{expenseTypes.landlord}</Select.Option>
          </SelectStyled>
        </WidgetSelectControl>
        <WidgetSelectControl>
          <WidgetControlTitle>Period</WidgetControlTitle>
          <SelectStyled
            style={{width: window.innerWidth > 1024 ? "9.5rem" : "100%"}}
            defaultValue={"Last 12 months"}
            value={""+periodToShow}
            onChange={(e) => setPeriodToShow(e)}>
            <Select.Option key={6} value={"6"}>Last 6 months</Select.Option>
            <Select.Option key={12} value={"12"}>Last 12 months</Select.Option>
            {window.innerWidth < 1024 && <Select.Option value={"18"}>Last 18 months</Select.Option>}
            {window.innerWidth > 1024 && <Select.Option value={"24"}>Last 24 months</Select.Option>}
          </SelectStyled>
        </WidgetSelectControl>
        {window.innerWidth > 1024 && <div style={{display: "flex", columnGap: "0.5rem"}}>
            <ViewAllButton onClick={() => history.push("/upkeep?tab=All&category=Budgeting")}>
                Budget expenses<ViewAllArrow/></ViewAllButton>
            <ViewAllButtonWhite onClick={() => history.push("/expenses")}>
                Expenses<ViewAllArrow/></ViewAllButtonWhite>
        </div>}
      </WidgetControls>}
    </WidgetHeader>

    {showWidget ? <CostsWidgetWrapper>
      <WidgetColumnWrapper>
        <WidgetGraphWrapper>
          <WidgetGraphLegendWrapper>
            {(categoryToShow === "All" || categoryToShow === expenseTypes.maintenance) && costTotals.maintenances > 0 &&
            <WidgetLegend><GreenDot/>{expenseTypes.maintenance}</WidgetLegend>}
            {(categoryToShow === "All" || categoryToShow === expenseTypes.improvement) && costTotals.improvements > 0 &&
            <WidgetLegend><BlueDot/>{expenseTypes.improvement}</WidgetLegend>}
            {(categoryToShow === "All" || categoryToShow === expenseTypes.landlord) && costTotals.landlord > 0 &&
            <WidgetLegend><YellowDot/>Landlord</WidgetLegend>}
            {(categoryToShow === "All" || categoryToShow === expenseTypes.lifestyle) && costTotals.lifestyle > 0 &&
            <WidgetLegend><PinkDot/>Lifestyle</WidgetLegend>}
            {(categoryToShow === "All") && costTotals.unconfirmed > 0 && <WidgetLegend><RedDot/>Unconfirmed</WidgetLegend>}
          </WidgetGraphLegendWrapper>
          <CostsChart
            periodToShow={periodToShow}
            categoryToShow={categoryToShow}
            upcomingCosts={upcomingTasksLocal}
            setCostTotals={setCostTotals}
          />
        </WidgetGraphWrapper>

        <WidgetTotalsWrapper>
          <WidgetTotalsColumn style={{borderRight: window.innerWidth > 1024 ? borderSetting : 0}}>
            <WidgetTotalsTitle>
              <AllTimeTotalIcon/>
              <WidgetItemTitle>Total, to date</WidgetItemTitle>
            </WidgetTotalsTitle>
            <WidgetItemCount>
              <CurrencySign>
                {getCurrencySign(propertyPreferences.preferredCurrency)}
              </CurrencySign>
              <WidgetItemCountNumber>{numberWithCommasFull(allTimeTotalCost)}</WidgetItemCountNumber>
            </WidgetItemCount>
          </WidgetTotalsColumn>
          <WidgetTotalsColumn style={{borderRight: window.innerWidth > 1024 ? borderSetting : 0}}>
            <WidgetTotalsTitle>
              <ThisYearTotalIcon/>
              <WidgetItemTitle>Total, last {periodToShow} mn</WidgetItemTitle>
            </WidgetTotalsTitle>
            <WidgetItemCount>
              <CurrencySign>
                {getCurrencySign(propertyPreferences.preferredCurrency)}
              </CurrencySign>
              <WidgetItemCountNumber>{numberWithCommasFull(pastTotalCost)}</WidgetItemCountNumber>
            </WidgetItemCount>
          </WidgetTotalsColumn>
          <WidgetTotalsColumn>
            <WidgetTotalsTitle>
              <EstimatedCostsIcon/>
              <WidgetItemTitle>Forecast expenses, {periodToShow} mn</WidgetItemTitle>
            </WidgetTotalsTitle>
            <WidgetItemCount>
              <CurrencySign>
                {getCurrencySign(propertyPreferences.preferredCurrency)}
              </CurrencySign>
              <WidgetItemCountNumber>{numberWithCommasFull(futureTotalCost)}</WidgetItemCountNumber>
            </WidgetItemCount>
          </WidgetTotalsColumn>
        </WidgetTotalsWrapper>
      </WidgetColumnWrapper>

      <WidgetTopCostsColumn>
        <WidgetItemTitle style={{marginBottom: window.innerWidth > 1024 ? "1.7rem" : "0.5rem"}}>Top Expenses</WidgetItemTitle>
        {topExpenses.map((expense: any, index: number) => <WidgetTopCostsItem>
          <span style={{display: "flex", columnGap: "0.75rem"}}>
            <TopCostNumber>{index + 1}</TopCostNumber>
            <Ellipsis length={40}>{expense.name}</Ellipsis>
          </span>
          <TopCostPriceTag>
            {getCurrencySign(propertyPreferences.preferredCurrency)}
            {numberWithCommasFull(expense.totalCost)}</TopCostPriceTag>
        </WidgetTopCostsItem>)}
      </WidgetTopCostsColumn>
    </CostsWidgetWrapper> : placeholderWidget()}
    {showWidget && window.innerWidth < 1024 && <div style={{display: "flex", columnGap: "0.125rem"}}>
        <ViewAllButton
            style={{width: "100%"}}
            onClick={() => history.push("/expenses")}>
            Record Expenses<ViewAllArrow/></ViewAllButton>
        <ViewAllButton
            style={{width: "100%"}}
            onClick={() => history.push("/upkeep?tab=All&category=Budgeting")}>
            Budget Expenses<ViewAllArrow/></ViewAllButton>
    </div>}
  </OuterWidgetWrapper>
}

export default CostsWidget

const CostsWidgetWrapper = styled(WidgetWrapper)`
  @media (min-width: 1300px) {
    flex-wrap: nowrap;
  }
`

export const OuterWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  column-gap: 0.75rem;
  
  @media (max-width: 1024px) {
    align-items: flex-start;
    flex-direction: column;
    row-gap: 0.5rem;
  }
`

const WidgetTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #21272B;
`

const WidgetControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    row-gap: 0.5rem;
    margin-top: 0.5rem;
    flex-wrap: nowrap;
  }
`

const WidgetSelectControl = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #C7C9C7;
  background: #F5F6FA;
  padding: 0.563rem 0.5rem;
  height: 2.5rem;
  border-radius: 4px;
  
  @media (max-width: 1024px) {
    width: 100%;
  }
`

const WidgetControlTitle = styled.div`
  color: #8E8E89;
  border-right: 1px solid #d4dadc;
  padding-right: 0.75rem;
  margin-right: 0.75rem;
`

const WidgetColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  @media (min-width: 1300px) {
    border-right: 2px solid #eff1f5!important;
    width: 70%!important;
    max-width: 780px;
  }
  
   @media (min-width: 1024px) {
    border: 0;
    width: 100%;
  }
  
  @media (max-width: 1024px) {
    border-right: 2px solid #eff1f5;
    width: calc(100% / 2);
  }
  
  @media (max-width: 800px) {
    border: 0!important;
    width: 100%;
  }
`

const WidgetGraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
  height: 100%;
`

const WidgetGraphLegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 0.75rem;
  row-gap: 0.5rem;
  margin-bottom: 1.5rem;
  
  @media (max-width: 440px) {
    column-gap: 0.5rem;
  }
`

const WidgetTotalsWrapper = styled.div`
  display: flex;
  border-top: 2px solid #eff1f5;
  
  @media (max-width: 1300px) {
    border-bottom: 2px solid #eff1f5;
  }
  
  @media (max-width: 1024px) {
    flex-direction: column;
    border-top: 0;
  }
`

const WidgetTotalsColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: calc(100% / 3);
  padding: 1.5rem;
  padding-top: 1rem;
  
  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    //padding: 1rem;
    border-bottom: 2px solid #eff1f5;
  }
`

const WidgetTotalsTitle = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  
  @media (max-width: 1024px) {
    flex-direction: row;
    column-gap: 0.5rem;
    align-items: center;
  }
`

const WidgetTopCostsColumn = styled.div`
  display: flex;
  padding: 1.5rem;
  padding-bottom: 0;
  flex-grow: 1;
  flex-direction: column;
  width: 22em;
  
  @media (max-width: 1024px) {
    padding: 1rem;
  }
`

const WidgetTopCostsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #EFF1F5;
  color: #45655E;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 1.5rem 0;
  
  @media (max-width: 1024px) {
    padding: 0.5rem 0;
  }
`

const TopCostNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 100%;
  background: #EFF1F5;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1.125rem;
  color: #21272B;
  flex-shrink: 0;
`

const TopCostPriceTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.125rem 0.375rem;
  background: #F5F6FA;
  border-radius: 4px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #21272B;
  white-space: nowrap;
`

const SelectStyled = styled(Select)`
  .ant-select-selector {
    background: #F5F6FA!important;
    border: 0!important;
    padding: 0!important;
    height: 20px!important;
  }
  
  .ant-select-selection-item {
    color: #21272B!important;
    padding: 0!important;
    height: 20px!important;
    line-height: 20px!important;
  }
`
const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  flex-shrink: 0;
  
  @media (max-width: 1024px) {
   margin-top: 0.25rem;
  }
`

const WidgetLegend = styled.div`
  //width: calc(100% / 3);
  column-gap: 0.375rem;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  
  @media (max-width: 1024px) {
   align-items: flex-start;
  }
  
  @media (max-width: 450px) {
   font-size: 0.8rem;
  }
`

const RedDot = styled(Dot)`
  background: #F28172;
`

const YellowDot = styled(Dot)`
  background: #FECE57;
`

const GreenDot = styled(Dot)`
  background: #86CEA2;
`

const BlueDot = styled(Dot)`
  background: #5599E7;
`

const PinkDot = styled(Dot)`
  background: #ED90CD;
`

const WidgetPlaceholder = styled(CalendarPlaceholder)`
  margin: 0;
  height: 22rem;
  background-image: url(/images/CostsPlaceholderBlurred.png);
  
  @media (max-width: 1024px) {
    background-image: none;
    height: auto;
  }
`

const CurrencySign = styled.span`
  color: #7CC9B7;
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.75rem;
`