import React, { useState } from "react";
import {Alert, Button, Layout} from "antd";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {useSelector} from "react-redux";

import {
  authSelector,
  getPropertiesSelector, getUserProfileSelector,
} from "../../selectors";
import _ from "lodash";
import {emptyGuid} from "../../screens/helpers";

import { ReactComponent as FAQCircle } from "../../../images/menu/FAQ.svg";
import { ReactComponent as ReportTechIssue } from "../../../images/menu/ReportTechIssue.svg";
import { ReactComponent as QuestionIcon } from "../../../images/menu/Question.svg";
import { ReactComponent as CaretGray } from "../../../images/menu/CaretGray.svg";
import { ReactComponent as LivletLogoNew } from "../../../images/LivletLogoNew.svg";

import ReportProblem from "./ReportProblem";
import {LogoutButton} from "./Header";
import MenuLivlet from "./MenuLivlet";
import moment from "moment";
import ReferFriendPopup from "./ReferFriendPopup";
import OnboardingPopup from "./OnboardingPopup";

const { Sider } = Layout;

export const showReferPopup = (registrationDate: string | null) => {
  return moment(registrationDate).add(3, "days").isSameOrBefore(moment())
}

function SideBar() {
  let history = useHistory();
  const properties = useSelector(getPropertiesSelector);
  const auth = useSelector(authSelector);
  const userProfile = useSelector(getUserProfileSelector);

  const [reportProblemModal, toggleReportProblemModal] = useState(false);

  if (_.isEmpty(properties) || _.isEqual(properties[0].propertyId, emptyGuid.toJSON())) {
    return <></>;
  }

  return (
    <SiderStyled
      theme="light"
      breakpoint="xs"
      collapsedWidth="0"
      width={296}
      style={{
        flexDirection: "column",
        flexWrap: "wrap",
        alignContent: "space-between",
        overflow: 'auto',
        height: '100vh',
        position: 'sticky',
        zIndex: 1,
        top: 0,
        left: 0,
      }}
      trigger={null}
    >
      <ReportProblem
        isModalOpen={reportProblemModal}
        toggleModal={toggleReportProblemModal} />
      <SidebarWrapper>
        <LogoWrapper>
          <LivletLogoNew onClick={() => history.push("/home")} />
        </LogoWrapper>
        <MenuLivlet />
        <PopupWrapper>
          <ContactSupportAlert
            message={<div style={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
              <QuestionIcon />
              Do you have a question?
            </div>}
            description={<>
              <ContactSupportButton onClick={() => {
                toggleReportProblemModal(true)
              }}>Contact support</ContactSupportButton>
            </>}
            type="info"
          />
        </PopupWrapper>
        <PopupWrapper>
          {auth.showPopup && (showReferPopup(userProfile.content.dateCreated) ? <ReferFriendPopup /> : <OnboardingPopup />)}
        </PopupWrapper>
        <ExtraOptions>
          <a href={"https://www.livlet.com/faq"} target={"_blank"}><ExtraOption>
            <ExtraOptionTitle>
              <FAQCircle /> FAQs
            </ExtraOptionTitle>
             <CaretGray />
          </ExtraOption></a>
        </ExtraOptions>
        <LogoutButton style={{marginBottom: "1.5rem"}} onClick={() => toggleReportProblemModal(true)}>
          <ReportTechIssue /> Report a tech issue
        </LogoutButton>
      </SidebarWrapper>
    </SiderStyled>
  );
}

export const LogoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
`;

const SiderStyled = styled(Sider)`
  ${(props) =>
  !props.collapsed
    ? `.ant-layout-sider-trigger { text-align:unset;} .anticon.anticon-menu-fold {margin-left:1.5rem;}`
    : null}
  @media (max-width: 1024px) {
    display: none;
  }
`;

const SidebarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  overflow-x: hidden;
  z-index: 1;
  overflow: auto;
`;

export const PopupWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  z-index: 1;
`;

export const ExtraOptions = styled.div`
  border-top: 1px solid #EFF1F5;
  border-bottom: 1px solid #EFF1F5;
  padding: 1.5rem;
  font-size: 1rem;
  line-height: 1.25rem;
  display: flex;
  flex-direction: column;
  color: #666F7A;
  
  a { color: #666F7A; }
`

export const ExtraOption = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ExtraOptionTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.625rem;
`

export const ExtraOptionStyled = styled(ExtraOption)`
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #EFF1F5;
`

const ContactSupportAlert = styled(Alert)`
  margin: 1rem;
  margin-bottom: 2rem;
  padding: 1rem!important;
  border: none;
  border-radius: 10px;
  background-color: #58A291!important;
 
  .ant-alert-message {
    font-weight: 600;
    font-size: 0.75rem!important;
    color: #fff!important;
    margin-bottom: 1.6rem;
    text-transform: uppercase;
  }
  
  .ant-alert-description {
    color: #fff!important;
    font-size: 1rem!important;
    line-height: 1.625rem!important;
  }
  
  svg {
    color: #fff!important;
  }
  
  a {
    color: #fff;
    text-decoration: underline;
  }
`;

const ContactSupportButton = styled(Button)`
  color: white;
  background-color: #58A291;
  text-align: center;
  display: block;
  border: 2px solid #84B9AC;
  border-radius: 6px;
  width: 100%;
  font-weight: 600;
  margin-top: 1.5rem;
  font-size: 1rem;
  height: 3rem;
  
  &:hover, &.selected, &:focus, &:active {
    background: white;
    color: #58A291 !important;
    border: 2px solid #84B9AC !important;
  }
`

export default SideBar;