import React, {useEffect, useState} from "react";
import {Dropdown, List, Modal, Popconfirm, Skeleton} from "antd";
import _ from "lodash";
import {fileTypeIcon} from "../../screens/Files";
import {
  AttachmentDescriptionDesktop,
  AttachmentDescriptionMobile,
  FilePath,
  FileSizeAction,
  ListItemStyled
} from "./ItemRenderComp";
import {attachmentsInitial, niceBytes} from "../helper";
import {ReactComponent as DownloadCircle} from "../../../images/DownloadCircle.svg";
import {downloadAttachment} from "../../../api-wrapper/attachments/downloadAttachment";
import {ReactComponent as DeleteImage} from "../../../images/Cross.svg";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import {useSelector} from "react-redux";
import {getAttachmentsSelector, getPropertyIdSelector} from "../../selectors";
import moment from "moment";
import {RecentlyAddedLineItem, RecentlyAddedModal} from "./UploadFile";
import AttachmentsOnDrawer from "./AttachmentsOnDrawer";
import {AttachButtonStyled} from "../works/WorkDrawer";
import {Guid} from "guid-typescript";
import {Label} from "../../screens/RegistrationPage";

type Props = {
  filesToForward: Array<any>;
  setFilesToForward: (arg: any) => void;
  isForwardingAllowed: boolean;
  setIsForwardingAllowed: (arg: boolean) => void;
}

const AttachFile = (props: Props) => {
  const {filesToForward, setFilesToForward, isForwardingAllowed, setIsForwardingAllowed} = props;

  const propertyId = useSelector(getPropertyIdSelector).value
  const attachments = useSelector(getAttachmentsSelector);
  const [top5attachments, setTop5attachments] = useState(attachmentsInitial);
  const [selectedFiles, setSelectedFiles] = useState<Set<Guid>>(new Set());
  const [showRecentlyAdded, setShowRecentlyAdded] = useState(false);
  const [showFileUploadList, setShowFileUploadList] = useState(false);

  useEffect(() => {
    let alreadyAttached: any = []
    filesToForward.map(file => alreadyAttached.push(file.attachmentId))
    let att = attachments.content.filter(file => !alreadyAttached.includes(file.attachmentId));

    att.sort((n1, n2) => moment(n2.uploadedDate).valueOf() - moment(n1.uploadedDate).valueOf());
    // @ts-ignore
    setTop5attachments(att.slice(0,5));
  }, [attachments, filesToForward])

  useEffect(() => {
    // @ts-ignore
    const filesSize = filesToForward.reduce((sum, {size}) => sum + size, 0)
    setIsForwardingAllowed(filesSize / 1024 / 1024 < 5)
  }, [filesToForward])

  const RecentlyAdded = () =>
    top5attachments.length > 0 ? (<RecentlyAddedModal onClick={(e) => {
        e.stopPropagation();
        setShowRecentlyAdded(false);
      }}>
      <div style={{color: "#6B7185", fontSize: "1rem", margin: "1rem 1rem"}}>Recently added</div>
      {top5attachments.map(item => (
        <RecentlyAddedLineItem
          onClick={() => setFilesToForward([...filesToForward, item])}>
          {(_.isNil(item.thumbnailPath) || _.isEmpty(item.thumbnailPath))
            ? <div>{fileTypeIcon(item)}</div>
            : <img alt="" src={item.thumbnailPath}
                   style={{width: "3.5rem", height: "3.5rem", borderRadius: "6px"}} onError={(e) => {
                    // @ts-ignore
                    e.target.onError = null;
                    // @ts-ignore
                    e.target.src = "/images/file.png"
            }}/>}
          <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", marginLeft: "1rem"}}>
            <div style={{fontSize:"1rem", fontWeight:600, marginBottom:"0.3rem"}}>{item.name}</div>
              {item.uploadedDate && <div style={{fontSize:"0.875rem", color: "#6B7185"}}>
              {moment(item.uploadedDate).format("DD MMM YYYY")}
            </div>}
          </div>
        </RecentlyAddedLineItem>
      ))}
      <a style={{color: "#2A80E1", fontWeight: 600, fontSize: "1rem", margin: "1rem 1rem", textDecoration: "underline"}}
        onClick={() => setShowFileUploadList(true)}>View all your files</a>
    </RecentlyAddedModal>)
    : (<RecentlyAddedModal>
      <div style={{color: "#6B7185", fontSize: "1rem", margin: "1rem 1rem"}}>
        You don't have any files in Livlet yet.
      </div>
    </RecentlyAddedModal>)

  function confirmDownload(resourceLink: any) {
    Modal.confirm({
      title: "Download ready",
      content: "Your file is ready for download",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() { window.open(resourceLink, "_blank") }
    });
  }

  return <div>
    <AttachmentsOnDrawer
      selectedFiles={selectedFiles}
      setSelectedFiles={setSelectedFiles}
      alreadyAttachedFiles={filesToForward}
      isOpen={showFileUploadList}
      toggleDrawerOpen={setShowFileUploadList}
      icon={<></>}
      drawerTitle={"Forward attachments"}
    >
      <AttachButtonStyled onClick={() => {
        let newFileIds = Array.from(selectedFiles)
        let newFiles = attachments.content.filter(file => newFileIds.includes(file.attachmentId))
        setFilesToForward([...filesToForward, ...newFiles])
        setShowFileUploadList(false)
      }}>
        Attach
      </AttachButtonStyled>
    </AttachmentsOnDrawer>

    <Label style={{marginBottom: "0.75rem"}}>Files to attach to this email:</Label>
    <div style={{display: "flex", justifyContent: "space-between"}}>
      <Dropdown
        overlayStyle={{width: window.innerWidth > 1024 ? 'auto' : '100%'}}
        overlay={<RecentlyAdded/>}
        placement={window.innerWidth > 1024 ? "topCenter" : "topLeft"}
        visible={showRecentlyAdded}
        onVisibleChange={(flag) => setShowRecentlyAdded(flag)}
        trigger={["click"]}
      >
        <a onClick={(e) => e.stopPropagation()}>Click here to select files.</a>
      </Dropdown>
      {filesToForward.length > 0 && <a onClick={(e) => setFilesToForward([])}>Remove all files</a>}
    </div>


    {filesToForward.length > 0 && <List
      style={{width: "100%"}}
      itemLayout="horizontal"
      dataSource={filesToForward}
      renderItem={(file: any) => {
        return <ListItemStyled
          actions={[<FileSizeAction>{niceBytes(file.size)}</FileSizeAction>,
            <DownloadCircle onClick={() => {
              propertyId && file.attachmentId &&
              downloadAttachment({propertyId, id: file.attachmentId,}).then((res) => {
                window.innerWidth > 1024 ? window.open(res, "_blank") : confirmDownload(res)})}}
            />,
            <Popconfirm title={<span style={{display: 'block', width: '14rem', marginTop: '0.5rem'}}>
              Are you sure you want to detach this file from this forward?</span>}
              onConfirm={() => setFilesToForward(filesToForward.filter(f => f.attachmentId !== file.attachmentId))}
              onCancel={(e) => {
                e!.stopPropagation()
              }}
              icon={""}
              okText={'Yes'}
              cancelText={'No'}
            ><DeleteImage/></Popconfirm>]}>
          <Skeleton loading={false} title={false} active>
            {file.originFileObj}
            <List.Item.Meta
              title={<span onClick={() => {downloadAttachment({propertyId, id: file.attachmentId})
                .then((res) => {window.innerWidth > 1024 ? window.open(res, "_blank") : confirmDownload(res)})}}>
                  <Ellipsis length={window.innerWidth > 1024 ? 40 : 20}>
                {file.name}
              </Ellipsis>
            </span>}
              description={<>
                <AttachmentDescriptionMobile>
                  <FilePath>{file.path}</FilePath>
                </AttachmentDescriptionMobile>
                <AttachmentDescriptionDesktop>
                  {niceBytes(file.size)}
                </AttachmentDescriptionDesktop>
              </>}/>
          </Skeleton>
        </ListItemStyled>}}
    />}
    {filesToForward.length > 0 && !isForwardingAllowed && <div style={{marginTop: "1rem", color: "#ef624e", fontWeight: 700}}>
        The total size of the attached files exceeds 5 MB.</div>}
  </div>
}

export default AttachFile