import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {
  getAttachments,
  TGetAllAttachmentsResponse,
} from "../../api-wrapper/attachments/getAllAttachments";
import {Guid} from "guid-typescript";

export const getAttachmentsInitAction = createAction(
  "Attachments/GET_Attachments_INIT"
)();
export const getAttachmentsSuccessAction = createAction(
  "Attachments/GET_Attachments_SUCCESS"
)<TGetAllAttachmentsResponse>();
export const getAttachmentsFailureAction = createAction(
  "Attachments/GET_Attachments_FAILURE"
)();

export type TGetAttachmentsInitAction = ActionType<typeof getAttachmentsInitAction>;
export type TGetAttachmentsSuccessAction = ActionType<
  typeof getAttachmentsSuccessAction
>;
export type TGetAttachmentsFailureAction = ActionType<
  typeof getAttachmentsFailureAction
>;

export type TGetAttachmentsActions =
  | TGetAttachmentsInitAction
  | TGetAttachmentsSuccessAction
  | TGetAttachmentsFailureAction;

export type TGetAttachmentsThunkActionType = (propertyId: Guid) => ThunkAction<void, TRootState, null, TGetAttachmentsActions>;

export const getAllAttachments: TGetAttachmentsThunkActionType = (propertyId: Guid) =>
  (dispatch: (action: Action | Promise<Action>) => any) => {
  dispatch(getAttachmentsInitAction());
  return getAttachments(propertyId)
    .then((payload) => {
      return dispatch(getAttachmentsSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getAttachmentsFailureAction()));
};
