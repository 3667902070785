import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TForwardWorkRequest} from "./forwardWork";

export const forwardWorkPreview = (payload: TForwardWorkRequest) => {
  const {workId} = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/forwardWork/${workId}/preview`
  );

  return apiPost<any, TForwardWorkRequest>(
    url, payload
  );
};
