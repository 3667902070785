import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetMaintenancesResponse } from "../../api-wrapper/maintenance/getMaintenances";
import {
  getOnboardingMaintenancesFailureAction,
  getOnboardingMaintenancesInitAction,
  getOnboardingMaintenancesSuccessAction,
  TGetOnboardingMaintenancesActions,
} from "../actions/onboardingMaintenances";
import {emptyGuid} from "../screens/helpers";

export type TOnboardingMaintenances = {
  fetchingStatus: TFetchingStatus;
  content: TGetMaintenancesResponse;
};

const initialState: TOnboardingMaintenances = {
  content: [
    {
      id: emptyGuid,
      propertyId: emptyGuid,
      standardTriggerId: emptyGuid,
      subscriptionId: 0,
      category: "",
      title: "",
      message: "",
      estimatedCost: 0,
      actualEstimatedCost: 0,
      recordAs: "",
      periodicity: 0,
      periodicityUnit: "",
      actualPeriodicity: 0,
      actualPeriodicityUnit: "",
      startDate: "",
      startDateTemplate: 0,
      startDateTemplateMonthToAdd: 0,
      notApplicable: false,
      subscribed: false,
      recommended: false,
      savedRecordsCount: 0,
      maintenanceKeywords: "",
      existingKeywords: "",
      foundByKeywords: false,
      alertTriggerType: "",
      triggerAlgorithmType: "",
      triggerJson: "",
      autocomplete: false,
      expenseType: {},
      workType: {},
      payee: {},
      nextDate: "",
      nextDateOriginal: "",
      standardInternalId: 0,
      upkeepType: ""
    }
  ],
  fetchingStatus: TFetchingStatus.Defined,
};

export const onboardingMaintenancesReducer = createReducer<TOnboardingMaintenances, TGetOnboardingMaintenancesActions>(
  initialState
)
  .handleAction(getOnboardingMaintenancesInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getOnboardingMaintenancesSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getOnboardingMaintenancesFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
