import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import {
  getRecommendedImprovementsFailureAction,
  getRecommendedImprovementsInitAction,
  getRecommendedImprovementsSuccessAction,
  TGetRecommendedImprovementsActions,
} from "../actions/improvements";
import {TGetRecommendedImprovementsResponse} from "../../api-wrapper/properties/getRecommendedImprovements";
import {emptyGuid} from "../screens/helpers";

export type TRecommendedImprovements = {
  fetchingStatus: TFetchingStatus;
  content: TGetRecommendedImprovementsResponse;
};

const initialState: TRecommendedImprovements = {
  content: [{
    id: "",
    parentId: "",
    name: "",
    summary: "",
    description: "",
    category: 0,
    subCategory: 0,
    priority: 0,
    doneByAnswerOn: "",
    doneOn: "",
    wishListedOn: "",
    givenAnswerContext: "",
    displayOrder: 0,
    keywords: [],
    relatedImprovements:  [],
    relatedWorkId: emptyGuid,
    incentives: [],
    ecoImpactRating: 0,
    livletRating: 0,
    savingsRating: 0,
    costRating: 0,
    specialtyOfWorkRequired: "",
    relativeEffortOfWorkRequired: "",
    co2ReductionRating: 0,
    plantedTreesPerYearEquivalent: 0,
    relatedQuestions: [],
    includedRelatedImprovements: [],
    doNotRecommendUntil: "",
    guidanceCardCost: 0,
    guidanceCardSustainability: 0,
    guidanceCardSavings: 0,
    guidanceCardSafety: 0,
    guidanceCardEffort: 0,
    guidanceCardHealthComfort: 0,
    guidanceSortingCost: 0,
    guidanceSortingSustainability: 0,
    guidanceSortingSavings: 0,
    guidanceSortingSafety: 0,
    guidanceSortingEffort: 0,
    guidanceSortingHealthComfort: 0
  }],
  fetchingStatus: TFetchingStatus.Defined,
};

export const recommendedImprovementsReducer = createReducer<TRecommendedImprovements, TGetRecommendedImprovementsActions>(
  initialState
)
  .handleAction(getRecommendedImprovementsInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getRecommendedImprovementsSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getRecommendedImprovementsFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
