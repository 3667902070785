import React, {useEffect, useState} from "react";
import {PageWrapper} from "./components";
import styled from "styled-components";

import {ReactComponent as BookTradeIcon} from "../../images/homepage-june/BookTradeIcon.svg";
import {ReactComponent as BuildHomeIcon} from "../../images/homepage-june/BuildHomeIcon.svg";
import {ReactComponent as DashboardIcon} from "../../images/homepage-june/DashboardIcon.svg";
import {ReactComponent as EmergencyRepairIcon} from "../../images/homepage-june/EmergencyRepairIcon.svg";
import {ReactComponent as ExplorePlayIcon} from "../../images/homepage-june/ExplorePlayIcon.svg";
import {ReactComponent as GuidanceIcon} from "../../images/homepage-june/GuidanceIcon.svg";
import {ReactComponent as HelpIcon} from "../../images/homepage-june/HelpIcon.svg";
import {ReactComponent as FindRecordsIcon} from "../../images/homepage-june/FindRecordsIcon.svg";
import {ReactComponent as ManageCostsIcon} from "../../images/homepage-june/ManageCostsIcon.svg";
import {ReactComponent as PlanWorksIcon} from "../../images/homepage-june/PlanWorksIcon.svg";
import {ReactComponent as InfoIcon} from "../../images/homepage-june/InfoIcon.svg";
import {ReactComponent as SelectPropertyArrow} from "../../images/homepage-june/SelectPropertyArrow.svg";
import {ReactComponent as SelectPropertyIcon} from "../../images/homepage-june/SelectPropertyIcon.svg";
import {ReactComponent as GoArrow} from "../../images/homepage-june/GoArrow.svg";
import {ReactComponent as AddPropertyPhoto} from "../../images/homepage-june/AddPropertyPhoto.svg";
import {ReactComponent as RotatedRecords} from "../../images/homepage-june/RotatedRecords.svg";
import PropertyBanner from "../../images/homepage-june/PropertyBanner.png";
import PropertyBackgroundMobile from "../../images/homepage-june/PropertyBackgroundMobile.png";
import PropertyPhotoBackground from "../../images/homepage-june/PropertyPhotoBackground.png";
import {useDispatch, useSelector} from "react-redux";
import {
  authSelector,
  getImprovementsSelector,
  getPropertiesSelector,
  getPropertyIdSelector,
  getUserProfileSelector
} from "../selectors";
import {getAllProperties} from "../actions/properties";
import {useHistory} from "react-router-dom";
import {setCurrentPropertyId} from "../../localStorage";
import PropertyPhotoDrawer from "../components/homePageJune/PropertyPhotoDrawer";
import {Dropdown} from "antd";
import {setPropertyId} from "../actions/propertyId";
import {TFetchingStatus} from "../../helpers";
import {getAllImprovements} from "../actions/improvements";
import GuidanceCarouselDesktop from "../components/homePageJune/GuidanceCarouselDesktop";
import GuidanceCarousel from "../components/homePage/GuidanceCarousel";
import TradeDrawer from "../components/partners/TradeDrawer";
import RepairDrawer from "../components/partners/RepairDrawer";
import BoilerGasCertificateDrawer from "../components/partners/BoilerGasCertificateDrawer";
import OnboardingComp from "../components/guidance/OnboardingComp";
import WelcomeSurveyModal from "../components/onboarding/WelcomeSurveyModal";
import EnvConfig from "../../config";
import {setUserWindowsState} from "../../api-wrapper/user/setUserWindowsState";
import {getCurrentUserProfile} from "../actions/getUserProfile";
import {WelcomeAlert} from "./Dashboard";
import {dismissLivLetWelcome} from "../actions/dismissWelcome";

import {ReactComponent as CloseCircle} from "../../images/CloseCircle.svg";
import {ReactComponent as HandholdingArrowRight} from "../../images/HandholdingArrowRight.svg";
import InsulationDrawer from "../components/partners/InsulationDrawer";
import {setUserIntroductions} from "../../api-wrapper/user/setUserIntroductions";
import {isUKProperty} from "./helpers";
import {setPropertyPreferences} from "../actions/propertyPreferences";
import {editUser} from "../../api-wrapper/auth/editUser";

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const properties = useSelector(getPropertiesSelector);
  const improvementsConfiguration = useSelector(getImprovementsSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const auth = useSelector(authSelector);
  const userProfile = useSelector(getUserProfileSelector);

  const [improvements, setImprovements] = useState<Array<any>>([]);
  const [categories, setCategories] = useState<Array<any>>([]);

  const [currentPropertyAddress, setCurrentPropertyAddress] = useState("My home");
  const [currentPropertyImage, setCurrentPropertyImage] = useState<any>(null);
  const [ukProperty, setUKProperty] = useState(false);
  const [isPhotoDrawerOpen, togglePhotoDrawer] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [isTradeDrawerOpen, toggleTradeDrawer] = useState(false);
  const [isRepairDrawerOpen, toggleRepairDrawer] = useState(false);
  const [isInsulationDrawerOpen, toggleInsulationDrawer] = useState(false);
  const [isCertificateDrawerOpen, toggleCertificateDrawer] = useState(false);
  const [selectedTradeType, setSelectedTradeType] = useState("");
  const [selectedRepairType, setSelectedRepairType] = useState("");
  const [selectedInsulationType, setSelectedInsulationType] = useState("");

  const [isSurveyModalOpen, toggleSurveyModal] = useState(false);
  const [onboardingStatus, setOnboardingStatus] = useState(0);
  const [welcomeMessage, showWelcomeMessage] = useState(false);
  const [currentProperty, setCurrentProperty] = useState<any>(null);
  const [handholdingStatus, setHandholdingStatus] = useState<any>(null);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  })

  useEffect(() => {
    dispatch(getAllProperties());
    dispatch(getAllImprovements(propertyId));
    dispatch(getCurrentUserProfile());
  }, [propertyId])

  useEffect(() => {
    if (improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success)
      return;
    setCategories(improvementsConfiguration.content.categories.filter(category => category.name));
    setImprovements([...improvementsConfiguration.content.improvements].sort((impr1, impr2) => impr1.displayOrder - impr2.displayOrder));
  }, [improvementsConfiguration]);

  useEffect(() => {
    if (userProfile.fetchingStatus === TFetchingStatus.Success) {
      userProfile.content.welcomeMessageDismissedTs === null ? showWelcomeMessage(true) : showWelcomeMessage(false);
      setHandholdingStatus(userProfile.content.handholdingStatus);
    }
  }, [userProfile])

  useEffect(() => {
    const current = properties.find((property) => property.propertyId === propertyId);
    if (!current)
      return;
    setCurrentProperty(current);
    setOnboardingStatus(current.questionnairePage ?? 0);
    setCurrentPropertyImage(current.imageThumbnail);
    setCurrentPropertyAddress(current.address + (current.city ? `, ${current.city}` : "")
      + (current.zip ? ` ${current.zip}` : ""))
    setUKProperty(isUKProperty(current.country, current.zip))
  }, [properties, propertyId])

  const handleHandholdingStatusChange = (status: string) => {
    setUserWindowsState({
      onboardingInterestSurveyStatus: userProfile.content.onboardingInterestSurveyStatus,
      onboardingUpkeepStatus: currentProperty.onboardingUpkeepStatus,
      onboardingContentStatus: currentProperty.onboardingContentStatus,
      onboardingImprovementsStatus: currentProperty.onboardingImprovementsStatus,
      mobileWelcomeStatus: userProfile.content.mobileWelcomeStatus,
      questionnairePage: currentProperty.questionnairePage,
      handholdingStatus: status,
      propertyId
    }).then(() => dispatch(getCurrentUserProfile()))
  }

  const clearUserExperience = async () => {
    await setUserWindowsState({
      onboardingInterestSurveyStatus: null,
      onboardingUpkeepStatus: null,
      onboardingContentStatus: null,
      onboardingImprovementsStatus: null,
      mobileWelcomeStatus: null,
      questionnairePage: 0,
      handholdingStatus: null,
      propertyId
    })
    await setUserIntroductions({
      contents: null,
      spaces: null,
      documents: null
    })
    await editUser({
      name: userProfile.content.name,
      email: userProfile.content.email,
      emailCommunication: userProfile.content.emailCommunication,
      marketingCookies: userProfile.content.marketingCookies,
      marketingEmails: !userProfile.content.marketingEmails,
      showPets: true
    })
    dispatch(getCurrentUserProfile());
    dispatch(getAllProperties());
  }

  const propertiesDropdown = () => (
    <PropertySelectionList>
      {properties.map(property => <PropertySelectionListItem onClick={() => {
        dispatch(setPropertyId(property.propertyId));
        setCurrentPropertyId(property.propertyId.toString());
        dispatch(setPropertyPreferences({
          preferredCurrency: property.preferredCurrency,
          preferredDateFormat: property.preferredDateFormat,
          taxYearMonth: property.taxYearMonth,
          taxYearDate: property.taxYearDate
        }));
      }}>
        {property.address + (property.city ? `, ${property.city}` : "") + (property.zip ? ` ${property.zip}` : "")}
      </PropertySelectionListItem>)}
    </PropertySelectionList>
  )

  const onboardingInProgress = "Complete the onboarding process to access suggestions and analysis."
  const onboardingPending = "Start building your data home, connect spaces, systems, contents and services to access suggestions and analysis."

  const descriptions = {
    events: "Oversee the upcoming tasks and the projects you have planned",
    trade: "Get a recommended local tradesperson to help you in your home (Service available in England).",
    dashboard:  "Your dashboard provides a visual summary of your property and Livlet account, where you can easily access important information in one place.",
    cost: "Add an expense to your Livlet account which you can view from Home Home information > Expenses. You can add payee details, documents, or invoices to your expense.",
    work: "Create a Project item for your home. Add Expenses, Contents, Contacts, Documents & notes to record the event. Projects in progress and completed projects can be viewed from Plans & projects > Projects."
  }

  const headerStyle = {backgroundImage: `url(${currentPropertyImage})`}

  const infoDropdown = (title: string, description: any) => (
    <Dropdown placement={"topCenter"} overlay={<Overlay>
      <div className={"overlay-title"}>{title}</div>
      {description}
    </Overlay>}>
      <InfoIconWrapper><InfoIcon style={{flexShrink: 0}} /></InfoIconWrapper>
    </Dropdown>
  )

  const testingLinks = () => (
    <p style={{marginTop: "0.5rem"}}>[TEST ONLY] <span
      style={{ color: "#009966", cursor: "pointer"}}
      onClick={() => clearUserExperience()}
    >Reset onboarding</span>, <span style={{ color: "#009966", cursor: "pointer"}}
      onClick={() => history.push("/onboarding")}>New onboarding</span></p>
  )

  const onboardingCard = () => {
    return (onboardingStatus > 7 || handholdingStatus === "skipped") ? <HomePageCard className={"records-card"}>
      <div className={"records-wrapper"}>
        <HomePageCardHeader><FindRecordsIcon/>Find records</HomePageCardHeader>
        <BuildHomeText style={{flexDirection: "column"}}>
          Start building your data home, connect spaces, insights and analysis...
          {EnvConfig.API_URL !== "https://uk.livlet.com" && testingLinks()}
        </BuildHomeText>
        <ButtonGreen onClick={() => history.push("/records")}>View & Add my records</ButtonGreen>
      </div>
      <RotatedRecords />
    </HomePageCard> : <HomePageCard className={"accent"}>
      <HomePageCardHeader className={"handholding"}>
        <div className={"header-content"}>
          <BuildHomeIcon />Build my home
        </div>
        <CloseCircle
            style={{position: "absolute", cursor: "pointer", top: -15, right: -15}}
            onClick={() => handleHandholdingStatusChange("skipped")}
        />
      </HomePageCardHeader>
      <BuildHomeText>
        <div>
          {onboardingStatus > 0 ? onboardingInProgress : onboardingPending}
          {EnvConfig.API_URL !== "https://uk.livlet.com" && testingLinks()}
        </div>
        <OnboardingComp
          origin={"home"}
          onboardingStatus={onboardingStatus}
          marginTop={0}
          marginBottom={0}
        />
      </BuildHomeText>
      <ButtonGreenShiny onClick={() => history.push("/questions?origin=home")}>
        {(handholdingStatus || onboardingStatus > 1) ? "Continue" : "Start here"}
      </ButtonGreenShiny>
    </HomePageCard>}

  const planningCard = () => <HomePageCard>
    <HomePageCardHeader><BuildHomeIcon />Plan</HomePageCardHeader>
    <PlanningButtonsWrapper>
      {ukProperty ? <PlanningButton onClick={() => toggleTradeDrawer(true)}>
        <BookTradeIcon/> Book a trade
        {infoDropdown("Book a trade", descriptions.trade)}
      </PlanningButton> : <PlanningButton onClick={() => history.push("/planner")}>
        <BookTradeIcon /> My schedule
        {infoDropdown("Schedule & Calendar", descriptions.events)}
      </PlanningButton>}
      <PlanningButton onClick={() => history.push("/projects")}>
        <PlanWorksIcon/> Plan project
        {infoDropdown("Plan project", descriptions.work)}
      </PlanningButton>
      <PlanningButton onClick={() => history.push("/expenses")}>
        {/*{propertyPreferences.preferredCurrency === "GBP" && <ManageCostsIcon/>}*/}
        <ManageCostsIcon/>
        Manage expenses
        {infoDropdown("Manage expenses", descriptions.cost)}
      </PlanningButton>
      <PlanningButton onClick={() => history.push("/dashboard")}>
        <DashboardIcon />My Dashboard
        {infoDropdown("My Dashboard", descriptions.dashboard)}
      </PlanningButton>
    </PlanningButtonsWrapper>
  </HomePageCard>

  const helpCard = () => <HomePageCard>
    <HomePageCardHeader><HelpIcon/>Help</HomePageCardHeader>
    <ExplorePlayButtonsWrapper>
      <ExplorePlayButton onClick={() => toggleRepairDrawer(true)}>
        <div style={{display: "flex", gap: "0.5rem", alignItems: "center"}}>
          <EmergencyRepairIcon/>Emergency repair (4-hour response time)
        </div>
        <span className={"nav-link"}>Go <GoArrow /></span>
      </ExplorePlayButton>
    </ExplorePlayButtonsWrapper>
    <HomePageCardHeader><GuidanceIcon/>Guidance</HomePageCardHeader>
    {windowWidth > 768 ? <GuidanceCarouselDesktop improvements={improvements} categories={categories}/> : <CarouselWrapper>
      <GuidanceCarousel improvements={improvements} categories={categories} />
    </CarouselWrapper>}
  </HomePageCard>

  const exploreCard = () => <HomePageCard>
    <HomePageCardHeader><ExplorePlayIcon/>Explore & play</HomePageCardHeader>
    <ExplorePlayButtonsWrapper>
      <ExplorePlayButton className={handholdingStatus === "spaces" ? "handholding" : ""} onClick={() => history.push("/spaces")}>
        <div className={"handholding"}>
          My spaces
          {handholdingStatus === "spaces" && <HandholdingCircle><HandholdingArrowRight/></HandholdingCircle>}
        </div>
        <span className={"nav-link"}>Go <GoArrow /></span>
      </ExplorePlayButton>
      <ExplorePlayButton onClick={() => history.push("/value-simulator")}>
        Property value simulator
        <span className={"nav-link"}>Go <GoArrow /></span>
      </ExplorePlayButton>
      <ExplorePlayButton onClick={() => history.push("/news")}>
        News & Alerts
        <span className={"nav-link"}>Go <GoArrow /></span>
      </ExplorePlayButton>
      <ExplorePlayButton onClick={() => history.push("/upkeep?tab=Recommended")}>
        Suggested upkeep & maintenance
        <span className={"nav-link"}>Go <GoArrow /></span>
      </ExplorePlayButton>
      <a href={"https://www.livlet.com/shower-calculator"} target={"_blank"} rel={"noreferrer"}>
        <ExplorePlayButton>
          Shower cost simulator
          <span className={"nav-link"}>Go <GoArrow /></span>
        </ExplorePlayButton>
      </a>
    </ExplorePlayButtonsWrapper>
  </HomePageCard>

  return <PageWrapper>
    {auth.subscriptionOwner && <WelcomeSurveyModal
        isModalOpen={isSurveyModalOpen}
        toggleModal={toggleSurveyModal}
    />}
    <TradeDrawer
      isDrawerOpen={isTradeDrawerOpen}
      toggleDrawer={toggleTradeDrawer}
      selectedType={selectedTradeType}
      setSelectedType={setSelectedTradeType}
      includeBoilerServices={true}
      toggleInsulationDrawer={toggleInsulationDrawer}
    />
    <InsulationDrawer
      isDrawerOpen={isInsulationDrawerOpen}
      toggleDrawer={toggleInsulationDrawer}
      selectedType={selectedInsulationType}
      setSelectedType={setSelectedInsulationType}
    />
    <RepairDrawer
      isDrawerOpen={isRepairDrawerOpen}
      toggleDrawer={toggleRepairDrawer}
      selectedType={selectedRepairType}
      setSelectedType={setSelectedRepairType}
    />
    <BoilerGasCertificateDrawer
      isDrawerOpen={isCertificateDrawerOpen}
      toggleDrawer={toggleCertificateDrawer}
    />
    <PropertyPhotoDrawer isDrawerOpen={isPhotoDrawerOpen} toggleDrawer={togglePhotoDrawer} />
    {welcomeMessage ? <WelcomeAlert
      closable
      message={"Welcome to the Livlet community"}
      afterClose={() => dispatch(dismissLivLetWelcome())}
      description={<>
        We look forward to helping you sort the hassles and headaches of managing your home and boosting the performance and the value of your property.
        <br/><br/>
        If you have any questions along the way, just drop us an email at <a href={"mailto:info@livlet.com"}>info@livlet.com</a>.
        {/*We also offer an in-person site visit onboarding to help you populate the product which you*/}
        {/*can access from our <span className={"partners-link"} onClick={() => history.push("/partners")}>Partner</span> services.*/}
      </>}
      type="info"
    /> : null}
    <HomePageHeader
      className={currentPropertyImage ? "photo" : ""}
      style={currentPropertyImage && windowWidth <= 768 ? headerStyle : {}}
    >
      <HeaderButtonsWrapper>
        <SelectPropertyButton>
          <SelectPropertyIcon /> Select Property
        </SelectPropertyButton>
        <Dropdown overlay={propertiesDropdown()} placement={"bottomCenter"} trigger={["click"]}>
          <PropertySelectionDropdown>
            {currentPropertyAddress} <SelectPropertyArrow />
          </PropertySelectionDropdown>
        </Dropdown>
      </HeaderButtonsWrapper>
      {currentPropertyImage && <PropertyPhoto style={{background: `url(${currentPropertyImage})`}}/>}
      <AddPhotoIcon>
        <AddPropertyPhoto onClick={() => togglePhotoDrawer(true)} />
      </AddPhotoIcon>
    </HomePageHeader>
    <HomePageCardWrapper>
      {onboardingCard()}
      {planningCard()}
    </HomePageCardWrapper>
    <HomePageCardWrapper className={ukProperty ? "" : "single-card"}>
      {ukProperty && (windowWidth > 768 ? helpCard() : exploreCard())}
      {ukProperty && (windowWidth > 768 ? exploreCard() : helpCard())}
      {!ukProperty && exploreCard()}
    </HomePageCardWrapper>
  </PageWrapper>
}

const HomePageHeader = styled.div`
  width: 100%;
  position: relative;
  height: 12.5rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.5rem;
  
  background-image: url(${PropertyBanner});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right bottom;
  
  &.photo {
    background-image: url(${PropertyPhotoBackground});
  }
  
  @media (max-width: 768px) {
    padding: 0.75rem;
    background: url(${PropertyBackgroundMobile}), linear-gradient(180deg, rgba(0, 204, 51, 0.5) 0%, #00CC33 100%);
    background-repeat: no-repeat;
    background-position: right bottom;
    
    &.photo {
      background-position: center center!important;
      background-size: cover!important;
    }
  }
`

const PropertyPhoto = styled.div`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  height: 12rem;
  width: 21.5rem;
  background-repeat: no-repeat;
  background-position: center center; 
  background-size: cover!important;
  border-radius: 8px;
  
  @media (max-width: 768px) {
    display: none;
  }
`

const HeaderButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.75rem;
  z-index: 10;
`

export const ButtonGreen = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: 3rem;
  
  padding: 0.5rem 1.5rem;
  background: #009966;
  border: 1px solid #009966;
  border-radius: 8px;
  
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #FFFFFF;
  transition: .5s all ease;
  
  &:hover, &:active {
    border: 1px solid #22AB5B;
    background: #22AB5B;
    color: white;
  }
`

export const ButtonGreenShiny = styled(ButtonGreen)`
  animation-name: green-flash;
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;

  @keyframes green-flash {
    from { background-color: #009966; }
    to { background-color: #22AB5B; }
  }
`

const SelectPropertyButton = styled(ButtonGreen)`
  cursor: default;
  padding: 0.25rem 0.75rem;
  height: 2rem;
  
  border: 1px solid #DAE0E6;
  color: #666F7A;
  background: white;
  transition: .5s all ease;
  
  @media (max-width: 768px) {
    display: none;
  }
`

const PropertySelectionDropdown = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 16.5rem;
  padding: 0.75rem;
  gap: 0.5rem;
  
  border: 1px solid #FFFFFF;
  background: #FFFFFF;
  border-radius: 8px;
  
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #009966;
  
  @media (max-width: 768px) {
    border: 1px solid #00CC33;
    border-radius: 4px;
  }
`

const AddPhotoIcon = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 0.5rem;
  cursor: pointer;
  zIndex: 150;
  
  @media (max-width: 768px) {
    top: 0.75rem;
    right: -0.25rem;
  }
`

const PropertySelectionList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
  background: #FFFFFF;
  border: 1px solid #DAE0E6;
  box-shadow: 0px 1px 5px rgba(30, 32, 37, 0.05), 0px 10px 24px rgba(26, 28, 31, 0.1);
  border-radius: 0px 0px 10px 10px;
  width: 16.5rem;
`

const PropertySelectionListItem = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #45655E;
  border-bottom: 1px solid #EFF1F5;
  
  :hover {
    color: #21272B;
    background: #F5F6FA;
    border-radius: 8px;
    border-bottom: 1px solid white;
    transition: background .5s ease;
  }
  
  :last-child {
    border-bottom: 1px solid white;
  }
`

const HomePageCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.5rem;
  
  &.single-card { grid-template-columns: repeat(1, minmax(0, 1fr)); }
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`

const HomePageCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  
  padding: 1.5rem;
  gap: 1rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 100%);
  border: 3px solid #FFFFFF;
  border-radius: 10px;
  
  svg { flex-shrink: 0; }
  
  &.accent { 
    animation-name: border-flash;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  
    @keyframes border-flash {
      from { border: 3px solid #009966; }
      to { border: 3px solid #22AB5B; }
    }
  }
  
  &.records-card {
    flex-direction: row;
    svg { flex-shrink: 0.25; }
  }
  
  .records-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    gap: 1rem;
  }
  
  @media (max-width: 768px) {
    padding: 0.75rem;
  }
`

const HomePageCardHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  position: relative;
  
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1.75rem;
  color: #21272B;
  
  &.handholding { justify-content: space-between; }
  .header-content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  @media (max-width: 768px) {
    font-size: 1.125rem;
    line-height: 1.25rem;
    
    gap: 0.25rem;
    svg { transform: scale(0.65); }
  }
`

const BuildHomeText = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #45655E;
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
`

const PlanningButtonsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
`

const PlanningButton = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  position: relative;
  
  height: 4.25rem;
  border-radius: 8px;
  background: white;
  border: 1px solid #DAE0E6;
  
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #666F7A;
  transition: .5s all ease;
  
  &:hover {
    background: #f5f6f7;
    color: #22AB5B;
  }
  
  @media (max-width: 768px) {
    height: 3rem;
    font-size: 1rem;
    line-height: 1.5rem;
    
    gap: 0.25rem;
    svg { transform: scale(0.65); }
  }
`

const InfoIconWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 0.25rem;
  right: 0.35rem;
`

// const PlanningButtonGreen = styled(PlanningButton)`
//   border: 1px solid #009966;
//   background: #009966;
//   color: white;
//
//   &:hover {
//     border: 1px solid #22AB5B;
//     background: #22AB5B;
//     color: white;
//   }
// `

const ExplorePlayButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const ExplorePlayButton = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 0.75rem 1rem;
  background: #FFFFFF;
  border: 1px solid #DAE0E6;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #0C2A41;
  transition: .5s all ease;
  gap: 1rem;
  
  &.handholding {
    animation-name: border-flash;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  
    @keyframes border-flash {
      from { border: 1px solid #009966; }
      to { border: 1px solid #22AB5B; }
    }
  }
  
  &:hover {
    background: #f5f6f7;
    color: #22AB5B;
  }
  
  .handholding { 
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .nav-link {
    flex-shrink: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-bottom: 1px solid #009966;
    color: #009966;
  }
  
  .nav-link:hover {
    color: #22AB5B;
    border-bottom: 1px solid #22AB5B;
  }
  
  .nav-link:hover svg path {
    fill: #22AB5B;
  }
  
  @media (max-width: 768px) {
    justify-content: center;
    text-align: center;
    .nav-link { display: none; }
    
    .handholding { 
      justify-content: center;
      gap: 0.5rem;
    }
  }
`

const CarouselWrapper = styled.div`
  width: 100%;
  padding-bottom: 1rem;
`

export const Overlay = styled.div`
   width: 15.75rem; 
   background: white; 
   border-radius: 4px;
   padding: 0.5rem; 
   border: 1px solid #DAE0E6;
   
   .overlay-title { 
     font-weight: 600;
     margin-bottom: 0.25rem;
   }
`

const HandholdingButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  padding: 0.125rem 0.5rem;
  gap: 0.25rem;
  background: #2979FF;
  border-radius: 4px;
  
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  color: #FFFFFF;
  
  svg { flex-shrink: 0!important; }

  animation-name: green-flash;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;

  @keyframes green-flash {
    from { background-color: #009966; }
    to { background-color: #22AB5B; }
  }
}
`

export const HandholdingCircle = styled(HandholdingButton)`
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 100%;
  padding: 0;
`

export default Home