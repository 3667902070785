import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import { getUpcomingTasks, TGetUpcomingTasksResponse } from "../../api-wrapper/upcomingTasks/getUpcomingTasks";
import {Guid} from "guid-typescript";
import moment from "moment";

export const getUpcomingTasksInitAction = createAction("UPCOMING_TASKS/GET_UPCOMING_TASKS_INIT")();
export const getUpcomingTasksSuccessAction = createAction(
  "UPCOMING_TASKS/GET_UPCOMING_TASKS_SUCCESS"
)<TGetUpcomingTasksResponse>();
export const getUpcomingTasksFailureAction = createAction("UPCOMING_TASKS/GET_UPCOMING_TASKS_FAILURE")();

export type TGetUpcomingTasksInitAction = ActionType<typeof getUpcomingTasksInitAction>;
export type TGetUpcomingTasksSuccessAction = ActionType<typeof getUpcomingTasksSuccessAction>;
export type TGetUpcomingTasksFailureAction = ActionType<typeof getUpcomingTasksFailureAction>;

export type TGetUpcomingTasksActions =
  | TGetUpcomingTasksInitAction
  | TGetUpcomingTasksSuccessAction
  | TGetUpcomingTasksFailureAction;

export type TGetUpcomingTasksThunkActionType = (
  parameters: {
    propertyId: Guid,
    startDate: string | null,
    endDate: string | null,
    firstInSeries: boolean
  }
) => ThunkAction<void, TRootState, null, TGetUpcomingTasksActions>;

export const getAllUpcomingTasks: TGetUpcomingTasksThunkActionType = (parameters: {
  propertyId: Guid,
  startDate: string | null,
  endDate: string | null,
  firstInSeries: boolean
}) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {

  dispatch(getUpcomingTasksInitAction());
  return getUpcomingTasks(parameters)
    .then((payload) => {
      return dispatch(getUpcomingTasksSuccessAction(payload?? {tasks: [], tasksExists: false, startDate: "", endDate: ""}));
    })
    .catch(() => dispatch(getUpcomingTasksFailureAction()));
};