import React, {useEffect, useState} from "react";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";

import {Form, Input, Modal, Select} from "antd";
import {Label} from "../../screens/RegistrationPage";
import styled from "styled-components";
import {AutoCompleteStyled} from "../styled";
import AddSpaceOptions from "./AddSpaceOptions";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getSpacesSelector} from "../../selectors";
import {addSpace} from "../../../api-wrapper/spaces/addSpace";
import {getAllSpaces} from "../../actions/spaces";
import {ReactComponent as BackArrow} from "../../../images/spaces/ReturnArrow.svg";
import {getEntityTypes} from "../../../api-wrapper/relation/getEntityTypes";
import {updateSpace, updateSpaceWithImage} from "../../../api-wrapper/spaces/updateSpace";
import {getFloors} from "../../../api-wrapper/spaces/getFloors";
import UploadImage from "./UploadImage";
import _ from "lodash";
import {emptyGuid} from "../../screens/helpers";
import {
  ActionButtonGreen,
  ActionButtonWhite, CircleIcon,
  DrawerFooter, DrawerHeader,
  EditDrawerStyled,
  FormStyled
} from "../../screens/components";
import {Guid} from "guid-typescript";
import {linkToRecord} from "./AddNewRecordDrawer";

type Props = {
  spaceId: any;
  isOpen: boolean;
  toggleDrawerOpen: (arg: boolean) => void;
  setEditingSpaceId: (arg: any) => void;
  refreshParent?: () => void;
  parentRecordId?: Guid | null;
  parentRecordType?: string;
  toggleViewDrawerOpen?: (arg: boolean) => void;
  setViewSpaceId?: (arg: any) => void;
}

export const selectStyle = {
  width: "100%",
  border: "1px solid #DAE0E6",
  borderRadius: "6px",
  color: "rgb(107,113,133)",
}

const indoors = ["Master bedroom", "Bedroom", "Home office", "Bathroom", "Kitchen", "Living room", "Basement", "Roof", "Other room"]
const outdoors  = ["Garden", "Shed", "Driveway", "Garage", "Other space"]

const EditSpaceDrawer = (props: Props) => {
  const {spaceId, isOpen, toggleDrawerOpen, setEditingSpaceId, refreshParent, parentRecordId,
    parentRecordType, setViewSpaceId, toggleViewDrawerOpen} = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const spaces = useSelector(getSpacesSelector);

  const [preSelectedType, setPreSelectedType] = useState("");
  const [spaceName, setSpaceName] = useState("My space");
  const [spaceImageLink, setSpaceImageLink] = useState("");
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);

  const [space, setSpace] = useState<any>(null);
  const [spaceTypes, setSpaceTypes] = useState<any>([]);
  const [floorTypes, setFloorTypes] = useState<any>([]);
  const [spaceImageName, setSpaceImageName] = useState("");
  const [spaceImage, setSpaceImage] = useState<any>(null);
  const [removeImage, toggleRemoveImage] = useState(false);
  const [editImage, toggleEditImage] = useState(false);

  const initialValues = () => {
    if (space) {
      const {spaceType, category, floor, notes, dimension1, dimension2, paint} = space;
      return {spaceType, category, floor, notes, dimension1, dimension2, paint};
    } else {
      return {};
    }
  };

  const refreshSpaceTypes = () => {
    getEntityTypes(propertyId, "space").then((res: any) => {
      const types: any = []
      const otherType: any = []
      res && res.map((type: any) => type.name.includes("Other") ?
        otherType.push({key: type.typeId, value: type.name}) : types.push({key: type.typeId, value: type.name}))
      const sortedTypes = _.sortBy(types, "value")
      setSpaceTypes([...sortedTypes, ...otherType])
    })
  }

  const refreshFloorTypes = () => {
    getFloors(propertyId).then((res: any) => {
      const types: any = []
      res && res.map((type: any) => types.push({key: type, value: type}))
      setFloorTypes(types)
    })
  }

  useEffect(() => {
    if (spaceId)
      setSpace(spaces.content.filter(item => item.spaceId === spaceId)[0]);
  }, [spaces, spaceId, isOpen])

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    if (!isOpen)
      return;

    refreshSpaceTypes();
  }, [propertyId, isOpen])

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    if (!isOpen)
      return;

    refreshFloorTypes();
  }, [propertyId, isOpen])

  useEffect(() => {
    if (space) {
      setSpaceName(space.name);
      setSpaceImageLink(space.imageThumbnail);
      setSpaceImage(null);
      setSpaceImageName("")
      setPreSelectedType("");
      form.resetFields();
    } else {
      setSpaceName("");
      setSpaceImageLink("");
      setSpaceImageName("")
      setSpaceImage(null);
      setPreSelectedType("");
      form.resetFields();
    }
    setFormFieldsChanged(false);
  }, [space])

  useEffect(() => {
    if (preSelectedType && preSelectedType.includes("Other")) {
      indoors.includes(preSelectedType) && form.setFieldsValue({category: "Indoors"})
      outdoors.includes(preSelectedType) && form.setFieldsValue({category: "Outdoors"})
    }
    if (preSelectedType && !preSelectedType.includes("Other")) {
      indoors.includes(preSelectedType) && form.setFieldsValue({
        spaceType: preSelectedType === "Master bedroom" ? "Bedroom" : preSelectedType, category: "Indoors"})
      outdoors.includes(preSelectedType) && form.setFieldsValue({spaceType: preSelectedType, category: "Outdoors"})
      setSpaceName(preSelectedType)
    }
  }, [preSelectedType])

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        performCleanup()
      },
    });
  }

  const performCleanup = () => {
    dispatch(getAllSpaces(propertyId));
    setPreSelectedType("");
    setSpace(null);
    setSpaceName("");
    setSpaceImage("");
    setSpaceImageName("");
    setSpaceImageLink("");
    setEditingSpaceId(null);
    form.resetFields();
    setFormFieldsChanged(false);
    toggleEditImage(false);
    toggleRemoveImage(false);
    setPreSelectedType("");
    toggleDrawerOpen(false);
  }

  const handleCancel = () => {
    if (formFieldsChanged) {
      confirmCloseUnsaved();
      return;
    } else {
      performCleanup();
    }
  }

  const handleManageSpace = (e: {
    spaceType: string,
    category: string,
    floor: string,
    notes: string,
    dimension1: string,
    dimension2: string,
    paint: string,
  }) => {
    const {spaceType, category, floor, notes, dimension1, dimension2, paint} = e;

    if (space && spaceId) {
      if (spaceImage || removeImage) {
        updateSpaceWithImage({
          propertyId,
          spaceId,
          request: {
            name: spaceName,
            category,
            floor,
            notes,
            spaceType,
            dimension1,
            dimension2,
            paint,
            createdDate: space.createdDate
          },
          spaceImage
        }).then(() => {
          performCleanup()
          refreshParent && refreshParent();
        })
      } else {
        updateSpace({
          propertyId,
          spaceId,
          name: spaceName,
          category,
          floor,
          notes,
          spaceType,
          dimension1,
          dimension2,
          paint,
          createdDate: space.createdDate
        }).then(() => {
          performCleanup()
          refreshParent && refreshParent();
        })
      }
    } else {
      addSpace({
        propertyId,
        request: {
          name: spaceName ? spaceName : spaceType,
          category,
          floor,
          notes,
          spaceType,
          dimension1,
          dimension2,
          paint,
        },
        spaceImage
      }).then((res) => {
        if (setViewSpaceId && toggleViewDrawerOpen) {
          setViewSpaceId(res.data.spaceId);
          toggleViewDrawerOpen(true);
        }
        if (parentRecordId && parentRecordType) linkToRecord(propertyId, parentRecordId, parentRecordType, res.data.spaceId, "space", dispatch);
        performCleanup();
        refreshParent && refreshParent();
      })
    }
  }

  const onTypeAutocompleteSearch = (searchText: string) => {
    setSpaceTypes(!searchText ? spaceTypes.map((type: any) => { return {key: type.key, value: type.value} }) :
      spaceTypes.filter((type: any) => type.value.toLowerCase().includes(searchText.toLowerCase()))
        .map((type: any) => { return {key: type.key, value: type.value} }))
  };

  const onFloorAutocompleteSearch = (searchText: string) => {
    setFloorTypes(!searchText ? floorTypes.map((type: any) => { return {key: type.key, value: type.value} }) :
      floorTypes.filter((type: any) => type.value.toLowerCase().includes(searchText.toLowerCase()))
        .map((type: any) => { return {key: type.key, value: type.value} }))
  };

  return <EditDrawerStyled
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "100%" : "85%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      visible={isOpen}
      onClose={() => handleCancel()}
      title={<DrawerHeader className={"edit-header"}>
        {window.innerWidth <= 768 && <BackArrow style={{cursor: "pointer", flexShrink: 0}} onClick={() => handleCancel()}/>}
        {(!spaceId && preSelectedType === "") ? "Add a space" : <SpaceTitle
          placeholder={"Enter name"}
          value={spaceName}
          maxLength={100}
          onChange={(e) => {
            setSpaceName(e.target.value);
            setFormFieldsChanged(true);
          }}
        />}
        {window.innerWidth > 768 && <CircleIcon onClick={() => handleCancel()}><CloseIcon /></CircleIcon>}
      </DrawerHeader>}
    >
    {(!spaceId && preSelectedType === "") ? <AddSpaceOptions
        selectedType={preSelectedType}
        setSelectedType={setPreSelectedType}
      /> : <FormStyled
        form={form}
        name="editSpace"
        layout="vertical"
        size={"large"}
        // @ts-ignore
        onFinish={handleManageSpace}
        initialValues={initialValues()}
        onFieldsChange={() => setFormFieldsChanged(true)}
      >
        <EditSpaceForm>
        <Label>Space type *</Label>
        <Form.Item key={"spaceType"} name={"spaceType"} rules={[{
          required: true,
          message: "Space type is required",
        }]}>
          <AutoCompleteStyled
            // @ts-ignore
            getPopupContainer={trigger => trigger.parentElement}
            showSearch
            style={selectStyle}
            allowClear={true}
            placeholder={"Select or enter type"}
            bordered={false}
            showArrow={true}
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={onTypeAutocompleteSearch}
            options={spaceTypes}
          />
        </Form.Item>
        <Label>Space category</Label>
        <Form.Item key={"category"} name={"category"}>
          <Select
            getPopupContainer={trigger => trigger.parentElement}
            placeholder={"Select category"}
            bordered={false}
            style={selectStyle}
          >
            <Select.Option value={"Indoors"}>{"Indoors"}</Select.Option>
            <Select.Option value={"Outdoors"}>{"Outdoors"}</Select.Option>
          </Select>
        </Form.Item>
        <Label>Floor</Label>
        <Form.Item key={"floor"} name={"floor"}>
          <AutoCompleteStyled
            // @ts-ignore
            getPopupContainer={trigger => trigger.parentElement}
            showSearch
            style={selectStyle}
            allowClear={true}
            placeholder={"Select or enter floor"}
            bordered={false}
            showArrow={true}
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={onFloorAutocompleteSearch}
            options={floorTypes}
          />
        </Form.Item>
        <Label>Notes</Label>
        <Form.Item key={"notes"} name={"notes"}>
          <Input
            autoComplete={"off"}
            style={{...selectStyle, height: "2.625rem"}}
            placeholder={"Add any relevant notes"}
          />
        </Form.Item>
        <Label>Dimensions</Label>
        <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"baseline"}}>
          <Form.Item key={"dimension1"} name={"dimension1"}>
            <Input
              autoComplete={"off"}
              style={{...selectStyle, height: "2.625rem"}}
              placeholder={"Height of the space"}
            />
          </Form.Item>
          <div style={{fontSize:"1rem", fontWeight:600}}>x</div>
          <Form.Item key={"dimension2"} name={"dimension2"}>
            <Input
              autoComplete={"off"}
              style={{...selectStyle, height: "2.625rem"}}
              placeholder={"Width of the space"}
            />
          </Form.Item>
        </div>
        <Label>Paint</Label>
        <Form.Item key={"paint"} name={"paint"}>
          <Input
            autoComplete={"off"}
            style={{...selectStyle, height: "2.625rem"}}
            placeholder={"Enter details"}
          />
        </Form.Item>
        <Label>Space image</Label>
        <UploadImage
          image={spaceImage}
          imageName={spaceImageName}
          imageLink={spaceImageLink}
          setImage={setSpaceImage}
          setImageName={setSpaceImageName}
          editImage={editImage}
          toggleEditImage={toggleEditImage}
          removeImage={removeImage}
          toggleRemoveImage={toggleRemoveImage}
          space={space}
          uploadType={"thumbnail"}
        />
      </EditSpaceForm>
        <DrawerFooter className={"fake-footer"}>
          <ActionButtonWhite onClick={() => handleCancel()}>Cancel</ActionButtonWhite>
          <ActionButtonGreen htmlType={"submit"}>Save</ActionButtonGreen>
        </DrawerFooter>
      </FormStyled>}
    </EditDrawerStyled>
}

const EditSpaceForm = styled.div`
  
`

export const SpaceTitle = styled(Input)`
  border: none;
  outline: none;
  display: flex;
  flex-grow: 1;
  height: 3.5rem;
  padding-left: 0;
  
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.625rem;
  color: #21272B;

  & > *:focus {
    border-color: #57a8e9;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }

  .ant-input:hover {
    border-color: #ebf9ff;
  }
  
  &.ant-input::placeholder {
    color: #92ACA0!important;
  }
`;

export default EditSpaceDrawer