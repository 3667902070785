import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import { getCalendarTasks, TGetCalendarTasksResponse } from "../../api-wrapper/upcomingTasks/getCalendarTasks";
import {Guid} from "guid-typescript";

export const getCalendarTasksInitAction = createAction("CALENDAR_TASKS/GET_CALENDAR_TASKS_INIT")();
export const getCalendarTasksSuccessAction = createAction(
  "CALENDAR_TASKS/GET_CALENDAR_TASKS_SUCCESS"
)<TGetCalendarTasksResponse>();
export const getCalendarTasksFailureAction = createAction("CALENDAR_TASKS/GET_CALENDAR_TASKS_FAILURE")();

export type TGetCalendarTasksInitAction = ActionType<typeof getCalendarTasksInitAction>;
export type TGetCalendarTasksSuccessAction = ActionType<typeof getCalendarTasksSuccessAction>;
export type TGetCalendarTasksFailureAction = ActionType<typeof getCalendarTasksFailureAction>;

export type TGetCalendarTasksActions =
  | TGetCalendarTasksInitAction
  | TGetCalendarTasksSuccessAction
  | TGetCalendarTasksFailureAction;

export type TGetCalendarTasksThunkActionType = (
  propertyId: Guid, startDate: string, monthlyView: boolean, amountOfPeriods: number
) => ThunkAction<void, TRootState, null, TGetCalendarTasksActions>;

export const getAllCalendarTasks: TGetCalendarTasksThunkActionType = (propertyId: Guid, startDate: string, monthlyView: boolean, amountOfPeriods: number) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getCalendarTasksInitAction());
  return getCalendarTasks(propertyId, startDate, monthlyView, amountOfPeriods)
    .then((payload) => {
      return dispatch(getCalendarTasksSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getCalendarTasksFailureAction()));
};