import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";


import {ReactComponent as QuestionsAC} from "../../../images/onboarding/QuestionsAC.svg";
import {ReactComponent as QuestionsCCTV} from "../../../images/onboarding/QuestionsCCTV.svg";
import {ReactComponent as QuestionsFireAlarm} from "../../../images/onboarding/QuestionsFireAlarm.svg";
import {ReactComponent as QuestionsFireSprinklers} from "../../../images/onboarding/QuestionsFireSprinklers.svg";
import {ReactComponent as QuestionsAudioVideo} from "../../../images/onboarding/QuestionsAudioVideo.svg";
import {ReactComponent as QuestionsSolarPanels} from "../../../images/onboarding/QuestionsSolarPanels.svg";
import {ReactComponent as QuestionsGarden} from "../../../images/onboarding/QuestionsGarden.svg";
import {ReactComponent as QuestionsGarage} from "../../../images/onboarding/QuestionsGarage.svg";
import {ReactComponent as QuestionsPool} from "../../../images/onboarding/QuestionsPool.svg";
import {ReactComponent as QuestionsJacuzzi} from "../../../images/onboarding/QuestionsJacuzzi.svg";
import {ReactComponent as QuestionsBackUpGenerator} from "../../../images/onboarding/QuestionsBackUpGenerator.svg";
import {ReactComponent as QuestionsAGA} from "../../../images/onboarding/QuestionsAGA.svg";


import {ReactComponent as ContentsCoffeeMachine} from "../../../images/onboarding/ContentsCoffeeMachine.svg";
import {ReactComponent as ContentsComputer} from "../../../images/onboarding/ContentsComputer.svg";
import {ReactComponent as ContentsCookerElectric} from "../../../images/onboarding/ContentsCookerElectric.svg";
import {ReactComponent as ContentsCookerGas} from "../../../images/onboarding/ContentsCookerGas.svg";
import {ReactComponent as ContentsDishWasher} from "../../../images/onboarding/ContentsDishWasher.svg";

import {ReactComponent as ContentsFreezer} from "../../../images/onboarding/ContentsFreezer.svg";
import {ReactComponent as ContentsFridgeFreezer} from "../../../images/onboarding/ContentsFridgeFreezer.svg";
import {ReactComponent as ContentsHobElectric} from "../../../images/onboarding/ContentsHobElectric.svg";
import {ReactComponent as ContentsHobGas} from "../../../images/onboarding/ContentsHobGas.svg";
import {ReactComponent as ContentsKettle} from "../../../images/onboarding/ContentsKettle.svg";

import {ReactComponent as ContentsKitchenHood} from "../../../images/onboarding/ContentsKitchenHood.svg";
import {ReactComponent as ContentsMicrowave} from "../../../images/onboarding/ContentsMicrowave.svg";
import {ReactComponent as ContentsOvenElectric} from "../../../images/onboarding/ContentsOvenElectric.svg";
import {ReactComponent as ContentsOvenGas} from "../../../images/onboarding/ContentsOvenGas.svg";
import {ReactComponent as ContentsRefrigeratorFridge} from "../../../images/onboarding/ContentsRefrigeratorFridge.svg";

import {ReactComponent as ContentsTumbleDryer} from "../../../images/onboarding/ContentsTumbleDryer.svg";
import {ReactComponent as ContentsTV} from "../../../images/onboarding/ContentsTV.svg";
import {ReactComponent as ContentsVacuumCleaner} from "../../../images/onboarding/ContentsVacuumCleaner.svg";
import {ReactComponent as ContentsWasherDryer} from "../../../images/onboarding/ContentsWasherDryer.svg";
import {ReactComponent as ContentsWashingMachine} from "../../../images/onboarding/ContentsWashingMachine.svg";

import {ReactComponent as ContentsExistsCheckIcon} from "../../../images/onboarding/ContentsExistsCheck.svg";

import {getInventoriesSelector, getPropertiesSelector, getPropertyIdSelector,} from "../../selectors";
import _ from "lodash";
import {TFetchingStatus} from "../../../helpers";
import {
  ContentsPageHeader,
  QuestionButtonMobile, QuestionButtonMobileTall,
  QuestionButtonSmall,
  QuestionsButtonsMobile,
  QuestionsButtonsSmall
} from "./QuestionsListComp";
import {getEntityTypes} from "../../../api-wrapper/relation/getEntityTypes";
import {emptyGuid} from "../../screens/helpers";
import {getAllInventories} from "../../actions/inventories";
import {addInventory} from "../../../api-wrapper/inventories/addInventory";

type Props = {
  saveAction: boolean;
  setShowLoader: (arg: string|null) => void;
  redirectUser: any;
};

const inventoryTypesWithSubtypes = ["Appliances", "Systems", "Electronics"];

const OnboardingContents = (props: Props) => {
  const { saveAction, setShowLoader, redirectUser } = props;

  const dispatch = useDispatch();

  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);
  const inventories = useSelector(getInventoriesSelector);
  const [typesDownloaded, toggleTypesDownloaded] = useState(false);
  const [dataDownloaded, toggleDataDownloaded] = useState(false);
  const [inventoryTypes, setInventoryTypes] = useState<any>([]);

  const [inventoriesPerType, setInventoriesPerType] = useState(new Set());
  const [selectedContentTypes, setSelectedContentTypes] = useState(new Set<string>());

  useEffect(() => {
    if (inventories.fetchingStatus !== TFetchingStatus.Success)
      return;
    if (!typesDownloaded)
      return;

    let invPerTypeSet = new Set();
    inventories.content.map(inventory => {
      if (inventory.inventorySubtype)
        invPerTypeSet.add(inventory.inventorySubtype);
    });
    setInventoriesPerType(invPerTypeSet);

    toggleDataDownloaded(true);
  }, [inventories, typesDownloaded]);

  useEffect(() => {
    setSelectedContentTypes(new Set<string>());
    toggleDataDownloaded(false);
    toggleTypesDownloaded(false);
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find((property) => property.propertyId === propertyId);
    if (!currentProperty)
      return;
    dispatch(getAllInventories(propertyId));
    downloadTypes();
  }, [propertyId])

  const downloadTypes = async () => {
    let allInventoryTypes: any = [];
    const allDownloadsPromises = inventoryTypesWithSubtypes.map(async (inventoryType) => {
      const downloadedEntities = await getEntityTypes(propertyId, inventoryType).then((res: any) => {
        allInventoryTypes.push(...res);
      })
      return downloadedEntities;
    });
    await Promise.all(allDownloadsPromises);
    setInventoryTypes(allInventoryTypes);
    toggleTypesDownloaded(true);
  };

  useEffect(() => {
    if (!saveAction)
      return;

    setShowLoader("Creating contents of your house");
    createInventories();
  }, [saveAction]);

  const createInventories = async () => {
    for (let contentTypeToCreate of Array.from(selectedContentTypes)) {
      await addInventory({
        propertyId,
        name: contentTypeToCreate,
        inventoryType: inventoryTypes.filter((inventoryType: any) => inventoryType.name === contentTypeToCreate)[0].category,
        inventorySubtype: contentTypeToCreate,
      });
    }

    setShowLoader(null);
    redirectUser && redirectUser();
  }

  const getClassForContent = (contentType: string) => {
    if (inventoriesPerType.has(contentType))
      return "disabled";
    if (selectedContentTypes.has(contentType))
      return "selected";
    return "";
  }

  const selectInventory = (contentType: string) => {
    if (inventoriesPerType.has(contentType))
      return;
    if (selectedContentTypes.has(contentType))
      selectedContentTypes.delete(contentType);
    else
      selectedContentTypes.add(contentType);
    setSelectedContentTypes(new Set(selectedContentTypes));
  }

  const ContentsQuestionButton = (props: {contentType: string, Icon: any}) =>
    <QuestionButtonSmall
      className={getClassForContent(props.contentType)}
      onClick={() => selectInventory(props.contentType)}
    >{props.Icon}<div style={{width:"60%"}}>{props.contentType} </div>{getClassForContent(props.contentType) === "disabled" && <ContentsExistsCheckIcon style={{marginLeft: 0, position:"absolute", right:"0.6rem"}}/>}</QuestionButtonSmall>

  const ContentsQuestionButtonMobile = (props: {contentType: string, Icon: any}) =>
    <QuestionButtonMobileTall
      className={getClassForContent(props.contentType)}
      onClick={() => selectInventory(props.contentType)}
    >{props.Icon}<div style={{width:"60%"}}>{props.contentType} </div>{getClassForContent(props.contentType) === "disabled" && <ContentsExistsCheckIcon style={{marginLeft: 0, position:"absolute", right:"0.6rem"}}/>}</QuestionButtonMobileTall>

  const displayDesktop = () => (
    <>
      <ContentsPageHeader>
          <span style={{fontWeight: 600, color: "#21272B"}}>Let's start by selecting a few appliances you may have in your home</span>
          <span>We'll help you decide which items deserve to be insured, track end of life and replacement cycles, etc.</span>
      </ContentsPageHeader>
      <QuestionsButtonsSmall>
        <ContentsQuestionButton contentType={"AC"} Icon={<QuestionsAC/>}/>
        <ContentsQuestionButton contentType={"CCTV"} Icon={<QuestionsCCTV/>}/>
        <ContentsQuestionButton contentType={"Fire Alarm"} Icon={<QuestionsFireAlarm/>}/>
        <ContentsQuestionButton contentType={"Fire Sprinklers"} Icon={<QuestionsFireSprinklers/>}/>
        <ContentsQuestionButton contentType={"Audio/Video System"} Icon={<QuestionsAudioVideo/>}/>
        <ContentsQuestionButton contentType={"Solar Panels"} Icon={<QuestionsSolarPanels/>}/>
        <ContentsQuestionButton contentType={"Jacuzzi"} Icon={<QuestionsJacuzzi/>}/>
        <ContentsQuestionButton contentType={"Back-up generator"} Icon={<QuestionsBackUpGenerator/>}/>
        <ContentsQuestionButton contentType={"AGA"} Icon={<QuestionsAGA/>}/>
      </QuestionsButtonsSmall>
      <QuestionsButtonsSmall style={{marginTop: 0}}>
        <ContentsQuestionButton contentType={"Washing Machine"} Icon={<ContentsWashingMachine/>}/>
        <ContentsQuestionButton contentType={"Dishwasher"} Icon={<ContentsDishWasher/>}/>
        <ContentsQuestionButton contentType={"Tumble Dryer"} Icon={<ContentsTumbleDryer/>}/>
        <ContentsQuestionButton contentType={"Washer Dryer"} Icon={<ContentsWasherDryer/>}/>
        <ContentsQuestionButton contentType={"Fridge"} Icon={<ContentsRefrigeratorFridge/>}/>
        <ContentsQuestionButton contentType={"Freezer"} Icon={<ContentsFridgeFreezer/>}/>
        <ContentsQuestionButton contentType={"Fridge Freezer"} Icon={<ContentsFreezer/>}/>
        <ContentsQuestionButton contentType={"Oven (Gas)"} Icon={<ContentsOvenGas/>}/>
        <ContentsQuestionButton contentType={"Microwave"} Icon={<ContentsMicrowave/>}/>
        <ContentsQuestionButton contentType={"Hob (Gas)"} Icon={<ContentsHobGas/>}/>
        <ContentsQuestionButton contentType={"TV"} Icon={<ContentsTV/>}/>
        <ContentsQuestionButton contentType={"Cooker (Gas)"} Icon={<ContentsCookerGas/>}/>
        <ContentsQuestionButton contentType={"Coffee Machine"} Icon={<ContentsCoffeeMachine/>}/>
        <ContentsQuestionButton contentType={"Vacuum Cleaner"} Icon={<ContentsVacuumCleaner/>}/>
        <ContentsQuestionButton contentType={"Computer"} Icon={<ContentsComputer/>}/>
        <ContentsQuestionButton contentType={"Kitchen Hood"} Icon={<ContentsKitchenHood/>}/>
        <ContentsQuestionButton contentType={"Cooker (Elec)"} Icon={<ContentsCookerElectric/>}/>
        <ContentsQuestionButton contentType={"Hob (Elec)"} Icon={<ContentsHobElectric/>}/>
        <ContentsQuestionButton contentType={"Kettle (Elec)"} Icon={<ContentsKettle/>}/>
        <ContentsQuestionButton contentType={"Oven (Elec)"} Icon={<ContentsOvenElectric/>}/>
      </QuestionsButtonsSmall>
    </>
  );

  const displayMobile = () => (
    <>
      <div style={{width: "100%", padding: "0 2rem", marginBottom:"1rem"}}>
        <div style={{marginTop: "0.75rem", textAlign: "center", fontSize: "0.75rem", lineHeight: "1.125rem", color: "#666F7A"}}>
          Tap on all relevant systems and appliances to add them to your Livlet product.
        </div>
      </div>

      <QuestionsButtonsMobile style={{padding: 0}}>
        <ContentsQuestionButtonMobile contentType={"AC"} Icon={<QuestionsAC/>}/>
        <ContentsQuestionButtonMobile contentType={"CCTV"} Icon={<QuestionsCCTV/>}/>
        <ContentsQuestionButtonMobile contentType={"Fire Alarm"} Icon={<QuestionsFireAlarm/>}/>
        <ContentsQuestionButtonMobile contentType={"Fire Sprinklers"} Icon={<QuestionsFireSprinklers/>}/>
        <ContentsQuestionButtonMobile contentType={"Audio/Video System"} Icon={<QuestionsAudioVideo/>}/>
        <ContentsQuestionButtonMobile contentType={"Solar Panels"} Icon={<QuestionsSolarPanels/>}/>
        <ContentsQuestionButtonMobile contentType={"Jacuzzi"} Icon={<QuestionsJacuzzi/>}/>
        <ContentsQuestionButtonMobile contentType={"Back-up generator"} Icon={<QuestionsBackUpGenerator/>}/>
        <ContentsQuestionButtonMobile contentType={"AGA"} Icon={<QuestionsAGA/>}/>
        <div style={{width: "calc(100% / 2 - 1rem)"}}>&nbsp;</div>

        <ContentsQuestionButtonMobile contentType={"Washing Machine"} Icon={<ContentsWashingMachine/>}/>
        <ContentsQuestionButtonMobile contentType={"Dishwasher"} Icon={<ContentsDishWasher/>}/>
        <ContentsQuestionButtonMobile contentType={"Tumble Dryer"} Icon={<ContentsTumbleDryer/>}/>
        <ContentsQuestionButtonMobile contentType={"Washer Dryer"} Icon={<ContentsWasherDryer/>}/>
        <ContentsQuestionButtonMobile contentType={"Fridge"} Icon={<ContentsRefrigeratorFridge/>}/>
        <ContentsQuestionButtonMobile contentType={"Freezer"} Icon={<ContentsFridgeFreezer/>}/>
        <ContentsQuestionButtonMobile contentType={"Fridge Freezer"} Icon={<ContentsFreezer/>}/>
        <ContentsQuestionButtonMobile contentType={"Oven (Gas)"} Icon={<ContentsOvenGas/>}/>
        <ContentsQuestionButtonMobile contentType={"Microwave"} Icon={<ContentsMicrowave/>}/>
        <ContentsQuestionButtonMobile contentType={"Hob (Gas)"} Icon={<ContentsHobGas/>}/>
        <ContentsQuestionButtonMobile contentType={"TV"} Icon={<ContentsTV/>}/>
        <ContentsQuestionButtonMobile contentType={"Cooker (Gas)"} Icon={<ContentsCookerGas/>}/>
        <ContentsQuestionButtonMobile contentType={"Coffee Machine"} Icon={<ContentsCoffeeMachine/>}/>
        <ContentsQuestionButtonMobile contentType={"Vacuum Cleaner"} Icon={<ContentsVacuumCleaner/>}/>
        <ContentsQuestionButtonMobile contentType={"Computer"} Icon={<ContentsComputer/>}/>
        <ContentsQuestionButtonMobile contentType={"Kitchen Hood"} Icon={<ContentsKitchenHood/>}/>
        <ContentsQuestionButtonMobile contentType={"Cooker (Elec)"} Icon={<ContentsCookerElectric/>}/>
        <ContentsQuestionButtonMobile contentType={"Hob (Elec)"} Icon={<ContentsHobElectric/>}/>
        <ContentsQuestionButtonMobile contentType={"Kettle (Elec)"} Icon={<ContentsKettle/>}/>
        <ContentsQuestionButtonMobile contentType={"Oven (Elec)"} Icon={<ContentsOvenElectric/>}/>
      </QuestionsButtonsMobile>
    </>
  );

  if (!dataDownloaded)
    return <></>;

  if (window.innerWidth < 1024)
    return displayMobile();

  return displayDesktop();
};


export default OnboardingContents;
