import {apiGet, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TGetBrandsItem = {
  typeId: Guid;
  category: string;
  name: string;
};

export type TGetBrandsResponse = Array<TGetBrandsItem>;

export const getBrands = (typeId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/entityType/${typeId}/brands`
  );

  return apiGet<TGetBrandsResponse | []>(url);
};
