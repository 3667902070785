import { createReducer } from "typesafe-actions";
import produce from "immer";

import {
  getPropertyIdInitAction,
  setPropertyIdAction,
  TGetPropertyIdActions,
} from "../actions/propertyId";
import {emptyGuid} from "../screens/helpers";
import {Guid} from "guid-typescript";

export type TPropertyId = {value: Guid};

const initialState: TPropertyId = {value: emptyGuid};

export const propertyIdReducer = createReducer<
  TPropertyId,
  TGetPropertyIdActions
>(initialState)
  .handleAction(getPropertyIdInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.value = emptyGuid;
    })
  )
  .handleAction(setPropertyIdAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.value = action.payload;
    })
  );
