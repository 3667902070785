import {apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";

type TAccountContent = {
  content: string;
  files: Array<any>;
}

export const uploadKeytekContent = (payload: TAccountContent) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/user/keytekAccount`);

  let bodyFormData = new FormData();
  bodyFormData.append('content', payload.content);
  for (const image of payload.files) {
    bodyFormData.append("files", image);
  }

  return apiPost<any, any>(url, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};