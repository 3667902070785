import {apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

type TUnsubscribeFromMaintenanceRequest = {
  propertyId: Guid;
  maintenanceId: Guid;
}

export const unsubscribeFromMaintenance = (payload: TUnsubscribeFromMaintenanceRequest) => {
  const { propertyId, maintenanceId } = payload;

  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/alerts/trigger/${maintenanceId}/unsubscribe`
  );

  return apiPost(url);
};