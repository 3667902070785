import React, {useEffect, useState} from "react";
import {CircleIcon, DrawerHeader, DrawerStyled} from "../../screens/components";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";
import {ReactComponent as ReturnArrow} from "../../../images/spaces/ReturnArrow.svg";
import {ReactComponent as AlertIcon} from "../../../images/AlertIcon.svg";
import {
  ContainerItem,
  ContainerItemWrapper,
  ContainerTitle,
} from "../spaces/AddSpaceOptions";
import {ReactComponent as WorksIcon} from "../../../images/spaces/SpaceLinkWorks.svg";
import {
  Legalese,
  ServiceUnavailable,
  supportedLocation,
  TradeDetails,
  TradeItem,
  TradesContainer
} from "./TradeDrawer";
import {useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import _ from "lodash";
import {emptyGuid} from "../../screens/helpers";
import styled from "styled-components";

type Props = {
  isDrawerOpen: boolean;
  toggleDrawer: (arg: boolean) => void;
  selectedType: string;
  setSelectedType: (arg: string) => void;
}

const RepairDrawer = (props: Props) => {
  const {isDrawerOpen, toggleDrawer, selectedType, setSelectedType} = props;
  const [propertyLocation, setPropertyLocation] = useState<any>(null);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find((property) => property.propertyId === propertyId);
    if (!currentProperty)
      return;
    setPropertyLocation(`${currentProperty.ukCountry}, ${currentProperty.country}`)
  }, [propertyId])

  const handleCancel = () => {
    setSelectedType("")
    toggleDrawer(false)
  }

  return <DrawerStyled
    push={{ distance: "32px" }}
    closeIcon={false}
    width={window.innerWidth > 1024 ? "680px" : "100%"}
    height={window.innerWidth > 768 ? "100%" : "85%"}
    placement={window.innerWidth > 768 ? "right" : "bottom"}
    visible={isDrawerOpen && propertyLocation}
    onClose={() => handleCancel()}
    title={<DrawerHeader className={"edit-header"}>
      {window.innerWidth <= 768 && <ReturnArrow onClick={() => handleCancel()}/>}
      Emergency repair
      {window.innerWidth > 768 && <CircleIcon onClick={() => handleCancel()}><CloseIcon /></CircleIcon>}
    </DrawerHeader>}
  >
    {propertyLocation === supportedLocation ? (selectedType === "" ? <>
      <TradesContainer className={"open"}>
        <ContainerTitle><WorksIcon style={{flexShrink: 0}}/>Emergency repair (4-hour response time)</ContainerTitle>
        <ContainerItemWrapper>
          <EmergencyItems>
            <ContainerItem className={"full bold"} onClick={() => setSelectedType("Water leaks")}>
              Water leaks<br/><span className={"thin"}>(4-hour response)</span>
            </ContainerItem>
            <ContainerItem className={"full bold"} onClick={() => setSelectedType("Electrician")}>
              Electrician<br/><span className={"thin"}>(4-hour response)</span>
            </ContainerItem>
            <ContainerItem className={"long full bold"} onClick={() => setSelectedType("Emergency Locksmiths")}>
              Locksmiths<br/><span className={"thin"}>(2-hour response)</span>
            </ContainerItem>
          </EmergencyItems>
        </ContainerItemWrapper>
      </TradesContainer>
      <TradesContainer className={"open"}>
        <ContainerTitle><AlertIcon style={{flexShrink: 0}}/>Emergency advice</ContainerTitle>
          <TradeItem>
            <TradeDetails>
              If you smell a Gas leak call	0800 111 999<br/>(England, Wales and Scotland)
            </TradeDetails>
          </TradeItem>
      </TradesContainer>
    </> : <>
      <TradesContainer className={"open"}>
        <ContainerTitle>{selectedType}<br/>({selectedType === "Emergency Locksmiths" ? "2" : "4"}-hour response time)</ContainerTitle>
        <ContainerItemWrapper>
          <TradeItem>
            <TradeDetails>
              <span>Get a quote by calling {selectedType === "Emergency Locksmiths" ? <span
                className={"accent"}>0808 164 2928*</span> : <span className={"accent"}>0117 313 3248*</span>}.</span>
              <span className={"disclaimer"}>*Standard network charges may apply.</span>
            </TradeDetails>
          </TradeItem>
        </ContainerItemWrapper>
      </TradesContainer>
      {Legalese(false, selectedType === "Emergency Locksmiths" ? "locksmiths" : undefined)}
    </>) : ServiceUnavailable()}
  </DrawerStyled>
}

const EmergencyItems = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.5rem;
  
  .full { 
    display: flex; 
    flex-direction: column; 
    padding: 0 0.5rem;
  }
  .thin { font-weight: 400; }
  .bold { width: 100%; }
  
  @media (max-width: 768px) {
    display: flex; 
    flex-wrap: wrap;
    .bold, .long { height: 4.25rem; }
    .bold { width: calc(100% / 2 - 0.25rem); }
    .long { width: 100%; }
  }
`

export default RepairDrawer