import {Divider, Drawer, Form, Input, Modal, Select, Spin} from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { ReactComponent as SaveIcon } from "../../../images/SaveIcon.svg";
import { ReactComponent as MyPropertyIcon } from "../../../images/MyProperty.svg";
import messages from "../../screens/messages";
import {
  InputsWrapper,
  InputWrapper,
  Label,
  LabelWrapper,
} from "../../screens/RegistrationPage";
import { useDispatch, useSelector } from "react-redux";
import {getImprovementsSelector, getPropertiesSelector} from "../../selectors";
import {ButtonsWrapper, CloseButton, SaveButton, SaveButtonWrapper} from "../works/WorkDrawer";
import { editProperty } from "../../../api-wrapper/properties/editProperty";
import SearchLocationInput from "../SearchLocationInput";
import countryList from "react-select-country-list";
import { getAllProperties } from "../../actions/properties";
import { ReactComponent as CrossIcon } from "../../../images/Cross.svg";
import {getAllImprovements} from "../../actions/improvements";
import {Guid} from "guid-typescript";
import {addProperty} from "../../../api-wrapper/properties/addProperty";
import {setPropertyId} from "../../actions/propertyId";
import {LoadingOutlined} from "@ant-design/icons";
import {TFetchingStatus} from "../../../helpers";
import {isUKProperty} from "../../screens/helpers";
import {setPropertyPreferences} from "../../actions/propertyPreferences";
import {PropertyDrawerTitle} from "../../screens/components";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  editedPropertyId: Guid | null;
  refreshParent?: () => void;
};

const propertyAlreadyExists = (content: string) => {
  Modal.error({
    title: `You already have this property added!`,
    content,
    okText: "OK",
    className: "form-confirm-close-modal",
  });
}

const PostcodesIO = require('postcodesio-client');

const PropertyDrawer = (props: Props) => {
  const { isOpen, toggleDrawerOpen, editedPropertyId, refreshParent } = props;
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();
  const properties = useSelector(getPropertiesSelector);
  const improvementsConfiguration = useSelector(getImprovementsSelector);
  const postcodes = new PostcodesIO('https://api.postcodes.io');

  const [location, setLocation]: any = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [showEPCLoader, setShowEPCLoader] = useState(false);
  const [ukProperty, toggleUKProperty] = useState(true);
  const [postalCodeError, setPostalCodeError] = useState(false);

  const getCountryList = () => {
    let result = countryList()
      .getData()
      .filter(
        (country: { value: string; label: string }) =>
          country.value !== "US" && country.value !== "GB"
      );

    result.unshift({ value: "United Kingdom", label: "United Kingdom" });
    result.unshift({ value: "US", label: "United States" });

    return result;
  };
  const options = useMemo(() => getCountryList(), []);

  useEffect(() => {
    if (location) {
      form.setFieldsValue({
        address: location.formattedAddress,
        city: location.data.city,
        state: location.data.state,
        country: location.data.country,
        zip: location.data.zipcode,
      });
    }
    setPostalCodeError(false);
  }, [location, form]);

  useEffect(() => {
    if (improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success || !saveDisabled)
      return;
    setShowEPCLoader(false);
    toggleDrawerOpen(false);
  }, [improvementsConfiguration]);

  const handleManageProperty = async (e: {
    address: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    nickname: string;
  }) => {
    const {address, address2, city, state, country, zip, nickname} = e;

    let postCodeLookup = await postcodes.lookup(zip);
    if (country === "United Kingdom" && !Boolean(postCodeLookup)) {
      setPostalCodeError(true);
      return;
    }

    setSaveDisabled(true);
    toggleUKProperty(isUKProperty(country, zip))
    if (editedPropertyId) {
      editProperty({
        nickname,
        propertyId: editedPropertyId,
        address,
        address2,
        city,
        state,
        country,
        zip,
      }).then(() => {
        toggleDrawerOpen(false);
        form.resetFields();
        dispatch(getAllProperties());
        dispatch(getAllImprovements(editedPropertyId));
      });
    } else {
      const doubled = properties.some((p) =>
        p.address === address &&
        p.city === city &&
        p.country === country &&
        p.zip === zip
      );

      if (doubled) {
        const propertyName =  `${address}${address2 ? ', ' + address2 : ''}`;
        propertyAlreadyExists(propertyName);
        setSaveDisabled(false);
      } else {
        addProperty({
          nickname,
          address,
          address2,
          city,
          state,
          country,
          zip
        }).then((res: any) => {
          form.resetFields();
          isUKProperty(country, zip) && setShowEPCLoader(true);
          dispatch(getAllImprovements(res.data.propertyId));
          dispatch(getAllProperties());
          dispatch(setPropertyId(res.data.propertyId));
          dispatch(setPropertyPreferences({
            preferredCurrency: res.data.preferredCurrency,
            preferredDateFormat: res.data.preferredDateFormat,
            taxYearMonth: res.data.taxYearMonth,
            taxYearDate: res.data.taxYearDate
          }))
          refreshParent && refreshParent();
        });
      }
    }
  };

  const initialValues = () => {
    if (editedPropertyId) {
      const propertyFound = properties.filter((property) => property.propertyId === editedPropertyId);
      if (propertyFound.length !== 1)
        return;
      const {address, address2, city, state, country, zip, nickname} = propertyFound[0];
      return {
        nickname,
        address,
        address2,
        city,
        state,
        country,
        zip,
      };
    }
  };

  useEffect(() => {
    setSaveDisabled(false);
    setShowEPCLoader(false);
    form.resetFields();
  }, [form, editedPropertyId, isOpen]);

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        toggleDrawerOpen(false);
        form.resetFields();
        properties && dispatch(getAllProperties());
      },
    });
  }

  return (
    <Drawer
      closeIcon={false}
      width={window.innerWidth > 1024 ? "55%" : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => {
        if (formFieldsChanged) {
          confirmCloseUnsaved();
          return;
        }
        toggleDrawerOpen(false);
        form.resetFields();
      }}
      headerStyle={{
        backgroundColor: "#ebf9ff",
        margin: 0,
        padding: "2rem 2rem",
      }}
      title={
        <>
          <section
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.5rem",
            }}
          >
            <InventoriesIconWrapper>
              <MyPropertyIcon className={"my-property-theme-icon"} />{" "}
              <span style={{ paddingRight: "0.25rem", fontSize:"0.75rem" }}>MY PROPERTY</span>
            </InventoriesIconWrapper>
            <ButtonsWrapper>
              <CloseButton
                size={"large"}
                onClick={() => {
                  toggleDrawerOpen(false);
                  form.resetFields();
                }}
              >
                {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
              </CloseButton>
            </ButtonsWrapper>
          </section>
          <PropertyDrawerTitle>Property location</PropertyDrawerTitle>
        </>
      }
    >
    {showEPCLoader ?
      <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 72, marginTop: "10rem"}} spin />} />
        <div style={{marginTop:"3rem"}}>
          {ukProperty ? "Loading EPC Data for your house..." : "Processing your address, please wait..."}
        </div>
      </div>
    :
      <Form
        form={form}
        name="manageProperty"
        layout="vertical"
        size={"large"}
        onFinish={handleManageProperty}
        initialValues={initialValues()}
        onFieldsChange={() => setFormFieldsChanged(true)}
      >
        <>
          <span
            style={{ fontSize: "1.125rem", fontWeight: 600, color: "#21272B" }}
          >
            Property address
          </span>
          <section
            style={{
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "10px",
              padding: "1rem",
              marginTop: "1em",
            }}
          >
            <div style={{ paddingBottom: "0.5rem" }}>
              <Label>{f(messages.findAddress)}</Label>
              <Form.Item key={"findAddress"} name={"findAddress"}>
                <SearchLocationInput setValue={setLocation} />
              </Form.Item>
              <Divider />
            </div>
            <div style={{ paddingBottom: "0.5rem" }}>
              <LabelWrapper>
                <Label>
                  <FormattedMessage
                    {...messages.address}
                    values={{ addressNumber: "1 *" }}
                  />
                </Label>{" "}
              </LabelWrapper>
              <Form.Item
                key={"address"}
                name={"address"}
                rules={[
                  {
                    required: true,
                    message: f(messages.validationStreetAddress),
                  },
                ]}
              >
                <InputStyled
                  maxLength={300}
                  autoComplete={"newpassword"}
                  placeholder={f(messages.enterAddress)}
                />
              </Form.Item>
            </div>
            <div style={{ paddingBottom: "0.5rem" }}>
              <LabelWrapper>
                <Label>
                  <FormattedMessage
                    {...messages.address}
                    values={{ addressNumber: 2 }}
                  />
                </Label>{" "}
              </LabelWrapper>
              <Form.Item key={"address2"} name={"address2"}>
                <InputStyled
                  maxLength={300}
                  autoComplete={"newpassword"}
                  placeholder={f(messages.enterAddress)}
                />
              </Form.Item>
            </div>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>{f(messages.city)}</Label>
                </LabelWrapper>
                <Form.Item
                  key={"city"}
                  name={"city"}
                  rules={[
                    {
                      required: true,
                      message: f(messages.validationCity),
                    },
                  ]}
                >
                  <InputStyled
                    maxLength={100}
                    autoComplete={"newpassword"}
                    placeholder={f(messages.enterCity)}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>{f(messages.state)}</Label>
                </LabelWrapper>
                <Form.Item key={"state"} name={"state"}>
                  <InputStyled
                    maxLength={100}
                    autoComplete={"newpassword"}
                    placeholder={f(messages.enterState)}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <Label>{f(messages.country)}</Label>
                <Form.Item
                  key={"country"}
                  name={"country"}
                  rules={[
                    { required: true, message: f(messages.countryError) },
                  ]}
                >
                  <SelectStyled
                    options={options}
                    bordered={false}
                    placeholder={f(messages.pleaseSelect)}
                    onChange={() => setPostalCodeError(false)}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>{f(messages.postcode)}</Label>
                </LabelWrapper>
                <Form.Item
                  key={"zip"}
                  name={"zip"}
                  rules={[
                    {required: true, message: f(messages.validationPostcode)},
                  ]}
                >
                  <InputStyled
                    maxLength={100}
                    autoComplete={"newpassword"}
                    className={postalCodeError ? "field-error" : ""}
                    placeholder={f(messages.enterPostcode)}
                    onChange={() => setPostalCodeError(false)}
                  />
                </Form.Item>
                {postalCodeError && <div style={{marginTop: "-1.5rem"}}
                  className={"ant-form-item-explain ant-form-item-explain-error"}>Wrong postal code</div>}
              </InputWrapper>
            </InputsWrapper>
          </section>

          <SaveButtonWrapper>
            <SaveButton
              style={{
                display: "flex",
                alignItems: "center",
              }}
              icon={<SaveIconStyled />}
              type={"primary"}
              htmlType={"submit"}
              loading={saveDisabled}
            >
              Save details
            </SaveButton>
          </SaveButtonWrapper>
        </>
      </Form>
    }
    </Drawer>
  );
};

const SaveIconStyled = styled(SaveIcon)`
  padding-right: 0.25rem;
  width: 22px;
  height: 22px;
`;

const InventoriesIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

const InputStyled = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
  color: rgb(107, 113, 133);
  
  &.field-error { border-color: #ff4d4f; }
  &.field-error:focus {
    -webkit-box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
    box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
  }
`;

export const SelectStyled = styled(Select)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  height: 2.5rem;
  border-radius: 6px;
  color: rgb(107, 113, 133);

  @media (min-width: 1024px) {
    padding-left: 0.25em;
  }

  @media (max-width: 1024px) {
    height: 2.5rem;
  }
`;

export default PropertyDrawer;
