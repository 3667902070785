import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";


export const assignAttachmentProperty = (propertyId: Guid, attachmentId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/assignProperty/${attachmentId}`,
    {}
  );

  return apiGet<{}>(url);
};
