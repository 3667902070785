import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import _ from "lodash";

export type TCertificate = {
  propertyId: Guid;
  certificateId: Guid;
  certificateDate: string;
  endOfValidity: string;
  certificateType: string;
  amountPaid: number;
  certificateNumber: number;
};

export type TCertificateRequest = {
  certificateDate: string | null;
  endOfValidity: string | null;
  certificateType: string;
  amountPaid: number | null;
  certificateNumber: number | null;
}

export const addCertificate = (payload: TCertificateRequest & {propertyId: Guid}) => {
  const { propertyId } = payload;
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/certificate`);

  return apiPost<any, TCertificateRequest>(url, _.omit(payload, ['propertyId']));
};
