import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TGetInsurancesResponse = Array<{
  propertyId: Guid;
  id: Guid;
  subscriptionId: number;
  startDate: string;
  effectivePeriod: string;
  insuranceType: string;
  insurer: string;
  policyNumber: string;
  premium: number;
  insurancePremiumTax: number;
  administrationFee: number;
  discountReceived: string;
  totalAmountPaid: number;
  valueOfInsuredItems: number;
  confirmed: boolean;
}>;

export const getInsurances = (propertyId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/insurances`);

  return apiGet<TGetInsurancesResponse | []>(url, {}, propertyId);
};
