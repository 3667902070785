import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Button, Form, Modal, Spin} from "antd";
import {ReactComponent as WelcomeSurveyIllustration} from "../../../images/OnboardingInterests.svg";
import {useDispatch, useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector, getUserProfileSelector} from "../../selectors";
import {getUserInterests} from "../../../api-wrapper/user/getUserInterests";
import {setUserInterests} from "../../../api-wrapper/user/setUserInterests";
import {setUserWindowsState} from "../../../api-wrapper/user/setUserWindowsState";
import {getCurrentUserProfile} from "../../actions/getUserProfile";
import {LoadingOutlined} from "@ant-design/icons";
import {TFetchingStatus} from "../../../helpers";
import {ReactComponent as Checkmark} from "../../../images/planner/Checkmark.svg";
import {CheckboxStyled} from "../../screens/components";

type Props = {
  isModalOpen: boolean,
  toggleModal: (arg: boolean) => void,
}

const WelcomeSurveyModal = (props: Props) => {
  const { isModalOpen, toggleModal } = props
  const propertyId = useSelector(getPropertyIdSelector).value;
  const userProfile = useSelector(getUserProfileSelector);
  const properties = useSelector(getPropertiesSelector);
  const dispatch = useDispatch();

  const [interests, setInterests] = useState<any>([])
  const allowedInterests = ["Organise my home", "Plan maintenance tasks & works", "Digitise my files and folders",
    "Find tradespersons", "Reduce my environmental impact", "Reduce my energy bills", "None or other"]
  const none = "None or other"

  useEffect(() => {
    if (userProfile.fetchingStatus === TFetchingStatus.Success && !userProfile.content.onboardingInterestSurveyStatus) {
      toggleModal(true)
    }
  }, [userProfile])

  useEffect(() => {
    getUserInterests().then(res => res && setInterests(res.filter((i: any) => allowedInterests.includes(i.name))))
  }, [])

  const handleOnboardingStatus = (status: string) => {
    const currentProperty = properties.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty)
      return;
    setUserWindowsState({
      onboardingInterestSurveyStatus: status,
      onboardingUpkeepStatus: currentProperty.onboardingUpkeepStatus,
      onboardingContentStatus: currentProperty.onboardingContentStatus,
      onboardingImprovementsStatus: currentProperty.onboardingImprovementsStatus,
      mobileWelcomeStatus: userProfile.content.mobileWelcomeStatus,
      questionnairePage: currentProperty.questionnairePage,
      handholdingStatus: userProfile.content.handholdingStatus,
      propertyId
    }).then(() => {
      dispatch(getCurrentUserProfile())
      toggleModal(false)
    })
  }

  const handleSubmit = () => {
    let basicInterestIds: any = []
    interests.map((interest: any) => interest.picked && basicInterestIds.push(interest.id))

    setUserInterests({basicInterestIds}).then(() => {
      handleOnboardingStatus("completed")
    })
  }

  const areAnyOptionsSelected = interests.filter((interest: any) => interest.name !== none && interest.picked).length > 0
  const isNoneOfTheAboveSelected = interests.filter((interest: any) => interest.name === none && interest.picked).length > 0

  const getClassName = (item: any) => {
    if (item.name === none && areAnyOptionsSelected) return "disabled"
    if (item.name !== none && isNoneOfTheAboveSelected) return "disabled"
    if (item.picked) return "checked"
    else return ""
  }

  const loadingIndicator = () => (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />} />
      <p style={{
        paddingTop: "1rem",
        paddingBottom: "1rem",
        paddingLeft: "1rem",
        fontWeight: 400,
        fontSize: "1rem",
        color: "#6B7185",
        textAlign: "center"
      }}>Loading your potential interests...</p>
    </div>
  )

  return (
    <SurveyModal
      className={"survey"}
      width={912}
      visible={isModalOpen}
      closable={false}
      footer={null}
    >
      <SurveyModalColumn className={"onboarding"}>
        <SurveyModalHeader>
          {userProfile.content.name ?
            <div>Welcome to Livlet, <span style={{color: "#009966"}}>{userProfile.content.name}!</span></div> :
            <div>Welcome to Livlet<span style={{color: "#009966"}}>!</span></div>}
        </SurveyModalHeader>
        <div>Pick one or more interests, this will help us recommend appropriate home tips that meet your interests</div>
        {interests.length > 0 ? <InterestsWrapper>
          {interests.map((item: any, index: number) =>
            <OnboardingItem>
              <OnboardingCheckbox className={getClassName(item)}
                onClick={() => {
                  if (getClassName(item) !== "disabled") {
                    let interestsTmp = [...interests]
                    interestsTmp[index].picked = !interestsTmp[index].picked;
                    setInterests(interestsTmp)}}}><Checkmark/></OnboardingCheckbox>
              {item.name}
          </OnboardingItem>)}
        </InterestsWrapper> : loadingIndicator()}
        <ConfirmButton onClick={() => handleSubmit()}>Confirm</ConfirmButton>
      </SurveyModalColumn>
      <SurveyModalColumn className={"illustration"}>
        <WelcomeSurveyIllustration/>
      </SurveyModalColumn>
    </SurveyModal>
  )
}

export const SurveyModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
    background: #F5F7F6;
  }
  
  & .ant-modal-body {
    padding: 3.5rem;
    display: flex;
  }
  
  @media (max-width: 1024px) {
    & .ant-modal-body {
      padding: 2rem;
    }
  }
`;

export const SurveyModalColumn = styled.div`
  width: 50%;
  color: #6B7185;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  &.onboarding { gap: 1rem; }
  
  @media (max-width: 850px) {
    width: 100%;
    &.illustration {
      display: none;
    }
  }
`

export const SurveyModalHeader = styled.div`
  color: #21272B;
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.75rem;
`

export const SaveButton = styled(Button)`
  text-align: center;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  width: 8.75rem;
  height: 3.125rem;
  margin-top: -0.5rem;
`;

export const ConfirmButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  width: 8.75rem;
  height: 3.125rem;
  background: #009966;
  color: white;
  transition: all .5s ease;
  
  &:hover { background: #22AB5B; }
`

export const FormStyled = styled(Form)`
  span.ant-checkbox-inner {
    border: 1px solid #6B7185;
    font-size: 1rem;
  }

  label.ant-checkbox-wrapper {
    color: #6B7185;
    font-size: 1rem;
    line-height: 1.5rem;
  }
`

const OnboardingCheckbox = styled(CheckboxStyled)`
  border: 2px solid #3E5D58;
  background: #F5F7F6;
  &.checked { border: 2px solid #009966; }
  &.disabled { border: 2px solid #d9d9d9; } 
  &.checked svg path { fill: #009966; } 
  &.disabled svg { display: none; } 
`

const InterestsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const OnboardingItem = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: #3E5D58;
  gap: 1rem;
`

export default WelcomeSurveyModal