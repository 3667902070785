import React, {useEffect, useState} from "react";

import {ReactComponent as PropertyCertificates} from "../../../images/records/PropertyCertificates.svg";
import {ReactComponent as PropertyRecord} from "../../../images/records/PropertyRecord.svg";
import {ReactComponent as PropertyDetails} from "../../../images/records/PropertyDetails.svg";
import {ReactComponent as PropertyInsurance} from "../../../images/records/PropertyInsurance.svg";
import {useSelector} from "react-redux";
import {
  getCertificatesSelector,
  getImprovementsSelector,
  getInsurancesSelector,
  getPropertyIdSelector
} from "../../selectors";
import {CardContentsItem, CardContentsWrapper, CardTitle, PlusIconWrapper, RecordsCard} from "./SpaceRecordsCard";
import {useHistory} from "react-router-dom";
import {MenuStyled} from "../works/WorksListComp";
import {Dropdown, Menu} from "antd";
import {ReactComponent as MoreMenu} from "../../../images/More.svg";
import {Guid} from "guid-typescript";
import {addCertificate} from "../../../api-wrapper/certificates/addCertificate";
import moment from "moment";
import {getCertificateById} from "../../../api-wrapper/certificates/getCertificateById";

type Props = {
  togglePropertyDrawer: (arg: boolean) => void;
  setEditedPropertyId: (arg: Guid | null) => void;
  togglePropertyDetailsDrawer: (arg: boolean) => void;
  toggleInsuranceDrawer: (arg: boolean) => void;
  toggleCertificateDrawer: (arg: boolean) => void;
  setCertificateData: (arg: any) => void;
}

const PropertyRecordsCard = (props: Props) => {
  const {togglePropertyDrawer, togglePropertyDetailsDrawer, toggleInsuranceDrawer,
    toggleCertificateDrawer, setCertificateData, setEditedPropertyId} = props;

  const improvementsConfiguration = useSelector(getImprovementsSelector);
  const insurances = useSelector(getInsurancesSelector);
  const certificates = useSelector(getCertificatesSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const history = useHistory();

  const [detailCount, setDetailCount] = useState(0);
  const [insuranceCount, setInsuranceCount] = useState(0);
  const [certificateCount, setCertificateCount] = useState(0);

  useEffect(() => {
    let detailsCount = improvementsConfiguration.content.propertyDetails.filter(detail => detail.answer || detail.answerId).length;
    if (improvementsConfiguration.content.propertyHighlights.lastSoldPrice) detailsCount++;
    if (improvementsConfiguration.content.propertyHighlights.propertyValue) detailsCount++;
    setDetailCount(detailsCount);
  }, [propertyId, improvementsConfiguration])

  useEffect(() => {
    setInsuranceCount(insurances.content.length);
  }, [propertyId, insurances])

  useEffect(() => {
    setCertificateCount(certificates.content.filter(c => c.certificateType).length);
  }, [propertyId, certificates])

  const moreMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      if (key === "add-property") {
        setEditedPropertyId(null);
        togglePropertyDrawer(true);
      } else if (key === "add-insurance") {
        toggleInsuranceDrawer(true);
      } else if (key === "add-certificate") {
        addCertificate({
          propertyId,
          certificateDate: moment().format("YYYY-MM-DD"),
          endOfValidity: moment().format("YYYY-MM-DD"),
          certificateType: "",
          amountPaid: null,
          certificateNumber: null
        }).then((res) => {
          getCertificateById(propertyId, res.data.certificateId).then((res: any) => {
            setCertificateData({data: res});
            toggleCertificateDrawer(true);
          })
        })
      } else if (key === "edit-location") {
        setEditedPropertyId(propertyId);
        togglePropertyDrawer(true);
      } else if (key === "edit-details") {
        togglePropertyDetailsDrawer(true);
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="add-property">Add property</Menu.Item>
        <Menu.Item key="add-insurance">Add insurance</Menu.Item>
        <Menu.Item key="add-certificate">Add certificate</Menu.Item>
        <Menu.Item key="edit-location">Edit property location</Menu.Item>
        <Menu.Item key="edit-details">Edit property details</Menu.Item>
      </MenuStyled>
    );
  };

  return <RecordsCard
    className={"property-records"}
    onClick={() => history.push(`/properties/${propertyId}`)}
  >
    <CardTitle>
      <PropertyRecord /> Prop. details
      <Dropdown
        overlay={moreMenuDropdown()}
        placement="bottomRight"
        trigger={["click"]}
      >
        <PlusIconWrapper onClick={(e) => e.stopPropagation()}>
          <MoreMenu />
        </PlusIconWrapper>
      </Dropdown>

    </CardTitle>
    <CardContentsWrapper>
      <CardContentsItem onClick={(e) => {
        e.stopPropagation()
        history.push(`/properties/${propertyId}?openTab=Property details`)
      }}>
        <span className={"item-count"}><PropertyDetails /> {detailCount}</span> Details
      </CardContentsItem>
      <CardContentsItem onClick={(e) => {
        e.stopPropagation()
        history.push(`/properties/${propertyId}?openTab=Insurance`)
      }}>
        <span className={"item-count"}><PropertyInsurance /> {insuranceCount}</span> Insurances
      </CardContentsItem>
      <CardContentsItem onClick={(e) => {
        e.stopPropagation()
        history.push(`/properties/${propertyId}?openTab=Certificates`)
      }}>
        <span className={"item-count"}><PropertyCertificates /> {certificateCount}</span> Certificates
      </CardContentsItem>
    </CardContentsWrapper>
  </RecordsCard>
}

export default PropertyRecordsCard