import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";

export type TRecipient = {
  ccSender?: boolean;
  name: string;
  email: string;
}

export type TReferFriendsRequest = {
  customMessage: string;
  ccSender: boolean;
  recipients: Array<TRecipient>;
  userName: string;
};

export const referFriends = (payload: TReferFriendsRequest) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/referFriends`
  );

  return apiPost<any, TReferFriendsRequest>(
    url, payload
  );
};
