import React from "react";
import {ButtonBlue, ButtonWrapper, FormSectionTitle, FormWrapper, PageTitle, PageWrapper} from "./SiteVisitStep";
import styled from "styled-components";
import {ReactComponent as ImageUpload} from "../../../images/spaces/ImageUpload.svg";
import { UploadedFile } from "../spaces/UploadImage";
import {uploadAccountContent} from "../../../api-wrapper/publicOnboarding/uploadAccountContent";
import {notification} from "antd";

type Props = {
  setStep: (arg: number) => void;
  siteVisitData: any;
  infoGatheringData: any;
  uploadedFiles: Array<any>;
}

const SummaryStep = (props: Props) => {
  const {setStep, siteVisitData, infoGatheringData, uploadedFiles} = props;

  const handleGoBack = () => {
    window.scrollTo(0, 0);
    setStep(3);
  }

  const handleComplete = () => {
    const files: any = []
    uploadedFiles.map(upload => files.push(upload.file))
    uploadAccountContent({
      content: JSON.stringify({...siteVisitData, ...infoGatheringData},null,'\t'),
      files
    }).then(() => {
      notification.success({
        message: "Success!",
        description: "Your data has been uploaded successfully.",
        placement: "topRight",
      })
    }).catch((e) => {
      notification.error({
        message: "Error",
        description: e.data && e.data.message ? e.data.message : "Something went wrong",
        placement: "topRight",
      })
    })
  }

  const standardApplianceTypes = ["Dishwasher", "Washing Machine", "Fridge", "Freezer", "Cooker", "Oven", "Hob"]

  return <PageWrapper>
    <PageTitle>Summary (Page 4 of 4)</PageTitle>
    <FormWrapper>
      <FormSectionTitle>Property & user info</FormSectionTitle>
        <InfoLine>
          <span className={"title"}>Email address</span>
          <span className={"description"}>{siteVisitData.propertyUserInfo.email ?? "None"}</span>
        </InfoLine>
        <InfoLine>
          <span className={"title"}>User preferred name</span>
          <span className={"description"}>{siteVisitData.propertyUserInfo.username ?? "None"}</span>
        </InfoLine>
        <InfoLine>
          <span className={"title"}>User interests</span>
          <span className={"description"}>{siteVisitData.propertyUserInfo.userInterests.length ?
            siteVisitData.propertyUserInfo.userInterests.join("; ") : "None"}</span>
        </InfoLine>
        <InfoLine>
          <span className={"title"}>Property address</span>
          <span className={"description"}>
            {siteVisitData.propertyUserInfo.address ?? "None"}
            <br/>{siteVisitData.propertyUserInfo.zip ?? "None"}
            <br/>{siteVisitData.propertyUserInfo.city ?? "None"}
            <br/>{siteVisitData.propertyUserInfo.country ?? "None"}
          </span>
        </InfoLine>
        <InfoLine>
          <span className={"title"}>House type</span>
          <span className={"description"}>{siteVisitData.propertyUserInfo.houseType ? siteVisitData.propertyUserInfo.houseType : "None"}</span>
        </InfoLine>

      <FormSectionTitle>Spaces</FormSectionTitle>
        <InfoLine>
          <span className={"title"}>Bedrooms</span>
          <span className={"description"}>{siteVisitData.spacesInfo.numberOfBedrooms ?? "None"}</span>
        </InfoLine>
        <InfoLine>
          <span className={"title"}>Bathrooms</span>
          <span className={"description"}>{siteVisitData.spacesInfo.numberOfBathrooms ?? "None"}</span>
        </InfoLine>
        <InfoLine>
          <span className={"title"}>Garden</span>
          <span className={"description"}>{siteVisitData.spacesInfo.garden ?? "None"}</span>
        </InfoLine>
        <InfoLine>
          <span className={"title"}>Off-street parking</span>
          <span className={"description"}>{siteVisitData.spacesInfo.offStreetParking ?? "None"}</span>
        </InfoLine>
        <InfoLine>
          <span className={"title"}>Additional spaces</span>
          <span className={"description"}>{siteVisitData.spacesInfo.otherSpaces.length ?
            siteVisitData.spacesInfo.otherSpaces.join("; ") : "None"}</span>
        </InfoLine>
      <FormSectionTitle>Key system location</FormSectionTitle>
        {infoGatheringData.systemLocations.map((location: any) => <InfoLine>
            <span className={"title"}>{location.type}</span>
            <span className={"description"}>{location.location ?? "None"}</span>
          </InfoLine>)}
      <FormSectionTitle>Key Appliances / Systems</FormSectionTitle>
        {infoGatheringData.keyAppliances.map((appliance: any) => <InfoLine>
          <span className={"title"}>{standardApplianceTypes.includes(appliance.type) ? appliance.type : "Heating system"}</span>
          <span className={"description"}>
            {!standardApplianceTypes.includes(appliance.type) && `Type: ${appliance.type}`}
            {!standardApplianceTypes.includes(appliance.type) && <br/>}
            {`Brand: ${appliance.brand ?? "None"}`}
            <br/>{`Model: ${appliance.model ?? "None"}`}
            <br/>{`Serial number: ${appliance.serialNr ?? "None"}`}
            <br/>{`Location: ${appliance.location ?? "None"}`}
          </span>
        </InfoLine>)}
    </FormWrapper>
    <FormWrapper style={{marginTop: "1.5rem", gap: 0}}>
      <FormSectionTitle>
        Attached files
        <div className={"subtitle"}>Files and assets that have been attached to this project.</div>
      </FormSectionTitle>
      <div style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
        {uploadedFiles.length > 0 && uploadedFiles.map((file: any) => <UploadedFile key={file.id}>
          <span className={"file-title"}>
            <ImageUpload />{file.name}
          </span>
        </UploadedFile>)}
      </div>
      {uploadedFiles.length === 0 && <FormSectionTitle style={{borderBottom: "none"}}>
          <div className={"subtitle"}>You haven't uploaded any files.</div>
      </FormSectionTitle>}
    </FormWrapper>
    <ButtonWrapper>
      <ButtonBlue onClick={() => handleGoBack()}>Back</ButtonBlue>
      <ButtonBlue onClick={() => handleComplete()}>Complete</ButtonBlue>
    </ButtonWrapper>
  </PageWrapper>
}

export const InfoLine = styled.div`
  width: 100%;
  display: flex;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: 1rem;
  column-gap: 1.5rem;

  .title {
    width: 20vw;
    color: #3E5D58;
  }
  
  .description {
    width: 80vw;
    color: #009966;
  }
  
`

export default SummaryStep