import {DatePicker, Drawer, Form, Modal, notification} from "antd";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";

import {ReactComponent as SaveIcon} from "../../../images/SaveIcon.svg";
import {ReactComponent as MyTenantIcon} from "../../../images/MyProperty.svg";
import messages from "../../screens/messages";
import {InputsWrapper, InputWrapper, Label, LabelWrapper,} from "../../screens/RegistrationPage";
import {
  getContactsSelector,
  getMaintenancesSelector,
  getPropertyIdSelector, getPropertyPreferencesSelector,
} from "../../selectors";
import {ButtonsWrapper, CloseButton, SaveButton, SaveButtonWrapper,} from "../works/WorkDrawer";
import {editTenant} from "../../../api-wrapper/tenants/editTenant";
import {addTenant, TAddTenantResponse,} from "../../../api-wrapper/tenants/addTenant";
import ContactDrawer from "../contacts/ContactDrawer";
import {AutoCompleteStyled} from "../styled";
import {addContact} from "../../../api-wrapper/contacts/addContact";
import {getAllContacts} from "../../actions/contacts";
import {getAllTenants} from "../../actions/tenants";
import {Guid} from "guid-typescript";
import {emptyGuid} from "../../screens/helpers";
import moment from "moment";
import {tenantInitialState} from "./TenancyListComp";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import {getAllMaintenances} from "../../actions/maintenances";
import {TFetchingStatus} from "../../../helpers";
import {editMaintenanceApi} from "../../../api-wrapper/maintenance/editMaintenance";
import {completeTask} from "../../../api-wrapper/upcomingTasks/completeTask";
import {getUpcomingTasks} from "../../../api-wrapper/upcomingTasks/getUpcomingTasks";
import {InputNumberStyled} from "../expenses/ExpenseDrawer";
import { PropertyDrawerTitle } from "../../screens/components";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  setEditingTenantData: (tenant: any) => void;
  tenant: { data: TAddTenantResponse } | null;
  refreshParent?: () => void | null;
};

const TenantDrawer = (props: Props) => {
  const {
    isOpen,
    toggleDrawerOpen,
    setEditingTenantData,
    tenant,
    refreshParent,
  } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const maintenances = useSelector(getMaintenancesSelector);
  const contacts = useSelector(getContactsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [saveDisabled, setSaveDisabled] = useState(false);
  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editContactData, setEditContactData] = useState<any>(null);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [contactList, setContactList] = useState<Array<{ key: Guid; value: string }>>([]);

  const contactFilter = (contactId: Guid) =>
    contacts.content.filter((contact) => contact.contactId === contactId);

  const contactNameFilter = (contactName: string) =>
    contacts.content.filter((contact) => contact.name === contactName);

  const getContactId = async (contactName: any) => {
    if (!_.isNil(contactName) && !_.isEmpty(contactName)) {
      if (contactNameFilter(contactName)[0]?.contactId) {
        return contactNameFilter(contactName)[0].contactId
      } else {
        let response = await addContact({
          propertyId,
          request: { name: contactName },
          contactImage: null
        })
        return response.data?.contactId
      }
    } else {
      return null
    }
  }

  const [checkInUpkeep, setCheckInUpkeep] = useState<any>(null);
  const [checkOutUpkeep, setCheckOutUpkeep] = useState<any>(null);
  const [legionellaUpkeep, setLegionellaUpkeep] = useState<any>(null);
  const [initialStartDate, setInitialStartDate] = useState<any>(null);
  const [initialEndDate, setInitialEndDate] = useState<any>(null);

  useEffect(() => {
    dispatch(getAllMaintenances(propertyId));
  }, [propertyId])

  useEffect(() => {
    if (maintenances.fetchingStatus === TFetchingStatus.Success) {
      setCheckInUpkeep(maintenances.content.filter(m => m.standardInternalId === 81)[0])
      setCheckOutUpkeep(maintenances.content.filter(m => m.standardInternalId === 82)[0])
      setLegionellaUpkeep(maintenances.content.filter(m => m.standardInternalId === 83)[0])
    }
  }, [maintenances])

  const performCleanup = () => {
    if (refreshParent) refreshParent();
    toggleDrawerOpen(false);
    setEditingTenantData(null);
    form.resetFields();
    setSaveDisabled(false);
    setContactList([]);
    setFormFieldsChanged(false);
    dispatch(getAllContacts(propertyId));
    dispatch(getAllTenants(propertyId));
  }

  const onDateUpcoming = async (setting: string, newDate: string, upkeep: any) => {
    Modal.confirm({
      title: `You have changed the ${setting} date of your tenancy`,
      content: `Would you like to update the start date of your tenancy-related upkeep task "${upkeep.title}"?`,
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk: async () => {
        await editMaintenanceApi({
          propertyId,
          ...upkeep,
          startDate: newDate
        }, upkeep.id)
        notification.success({
          message: "Updated successfully!",
          description: `Your upkeep task "${upkeep.title}" has been successfully updated.`,
          placement: "topRight",
        })
      }
    })
  }

  const onDatePassed = async (setting: string, newDate: string, upkeep: any) => {
    Modal.confirm({
      title: `You have changed the ${setting} date of your tenancy`,
      content: `Would you like to record your tenancy-related upkeep task "${upkeep.title}" as project that happened on that date?`,
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk: async () => {
        let upcoming = await getUpcomingTasks({
          propertyId: propertyId,
          startDate: initialStartDate,
          endDate: initialEndDate,
          firstInSeries: false
        })
        if (upcoming) {
          let taskToUpdate = upcoming.tasks.filter((t: any) => t.parentId === upkeep.id)[0]
          await completeTask({
            propertyId,
            task: taskToUpdate.task,
            completionDate: newDate,
            totalCost: taskToUpdate.task.actualEstimatedCost ? taskToUpdate.task.actualEstimatedCost : taskToUpdate.task.estimatedCost
          })
          notification.success({
            message: "Recorded successfully!",
            description: `Your upkeep task "${upkeep.title}" has been successfully recorded.`,
            placement: "topRight",
          })
        }
      }
    })
  }

  const handleManageTenant = async (e: {
    startDate: moment.Moment;
    endDate: moment.Moment;
    rentalPayment: number;
    tenancyContactId: any;
    agentFee: number;
    agentContact: any;
    propertyManagementFee: number;
    propertyManagementContact: any;
  }) => {
    const {
      agentContact,
      agentFee,
      endDate,
      propertyManagementContact,
      propertyManagementFee,
      rentalPayment,
      startDate,
      tenancyContactId,
    } = e;
    setSaveDisabled(true);

    let tenantId = await getContactId(tenancyContactId)
    let rentalAgentId = await getContactId(agentContact)
    let propertyManagementId = await getContactId(propertyManagementContact)

    const startDateValue = startDate.format("YYYY-MM-DD")
    const endDateValue = endDate.format("YYYY-MM-DD")

    if (tenant) {
      await editTenant({
        propertyId,
        tenancyId: tenant.data.tenancyId,
        tenancyContactId: tenantId,
        agentContact: rentalAgentId,
        propertyManagementContact: propertyManagementId,
        agentFee,
        endDate: endDateValue,
        propertyManagementFee,
        rentalPayment,
        startDate: startDateValue,
      })
      if (startDateValue !== initialStartDate) {
        if (moment(startDateValue).isBefore(moment())) {
          checkInUpkeep.subscribed && await onDatePassed("start", startDateValue, checkInUpkeep)
          legionellaUpkeep.subscribed && await onDatePassed("start", startDateValue, legionellaUpkeep)
        } else {
          checkInUpkeep.subscribed && await onDateUpcoming("start", startDateValue, checkInUpkeep)
          legionellaUpkeep.subscribed && await onDateUpcoming("start", startDateValue, legionellaUpkeep)
        }
      }
      if (endDateValue !== initialEndDate) {
        if (moment(endDateValue).isBefore(moment())) {
          checkOutUpkeep.subscribed && await onDatePassed("end", endDateValue, checkOutUpkeep)
        } else {
          checkOutUpkeep.subscribed && await onDateUpcoming("end", endDateValue, checkOutUpkeep)
        }
      }
      performCleanup();
    } else {
      await addTenant({
        propertyId,
        agentFee,
        tenancyContactId: tenantId,
        agentContact: rentalAgentId,
        propertyManagementContact: propertyManagementId,
        endDate: endDateValue,
        propertyManagementFee,
        rentalPayment,
        startDate: startDateValue,
      })
      performCleanup();
    }
  };

  const initialValues = () => {
    if (tenant) {
      const {
        agentContact,
        agentFee,
        endDate,
        propertyManagementContact,
        propertyManagementFee,
        rentalPayment,
        startDate,
        tenancyContactId,
      } = tenant.data;
      const filteredTenancyContactId = contactFilter(tenancyContactId);
      const filteredAgentContact = contactFilter(agentContact);
      const filteredPropertyManagementContact = contactFilter(propertyManagementContact);

      return {
        agentContact:
          !_.isNil(agentContact) &&
          agentContact !== null &&
          !_.isEmpty(filteredAgentContact)
            ? filteredAgentContact[0].name
            : agentContact,
        agentFee: agentFee === 0 ? "" : agentFee,
        endDate: moment(endDate),
        propertyManagementContact:
          !_.isNil(propertyManagementContact) &&
          Object.is(propertyManagementContact, emptyGuid) &&
          !_.isEmpty(filteredPropertyManagementContact)
            ? filteredPropertyManagementContact[0].name
            : propertyManagementContact,
        propertyManagementFee:
          propertyManagementFee === 0 ? "" : propertyManagementFee,
        rentalPayment: rentalPayment === 0 ? "" : rentalPayment,
        startDate: moment(startDate),
        tenancyContactId:
          !_.isNil(tenancyContactId) &&
          tenancyContactId !== null &&
          !_.isEmpty(filteredTenancyContactId)
            ? filteredTenancyContactId[0].name
            : tenancyContactId,
      };
    } else {
      return {};
    }
  };

  useEffect(() => {
    setContactList(contacts.content.filter(contact => contact.name).map((contact) => {
      return {key: contact.contactId, value: contact.name}})
    );
    form.resetFields();
  }, [contacts, isOpen, form]);

  const onContactAutocompleteSearch = (searchText: string) => {
    if (!searchText) {setContactList(contacts.content.map((contact) =>
      {return {key: contact.contactId, value: contact.name}}))
      return;
    }

    setContactList(!searchText ? [] : contacts.content.filter((contact) =>
      contact.name.toLowerCase().includes(searchText.toLowerCase())).map((contact) =>
      {return {key: contact.contactId, value: contact.name}}));
  };

  useEffect(() => {
    setSaveDisabled(false);
    form.resetFields();

    if (tenant && tenant.data) {
      setInitialStartDate(tenant.data.startDate);
      setInitialEndDate(tenant.data.endDate);
    }
  }, [tenant, isOpen]);

  const openRelatedContact = (itemType: string) => {
    const itemId =
      itemType === "tenancy"
        ? form.getFieldValue("tenancyContactId")
        : itemType === "property"
        ? form.getFieldValue("propertyManagementContact")
        : form.getFieldValue("agentContact");

    setEditContactData({
      data: contactNameFilter(itemId)[0],
    });
    form.resetFields();
    toggleContactDrawer(true);
    toggleDrawerOpen(false);
    setEditingTenantData(null);
  };

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (tenant && tenant.data !== tenantInitialState) {
      form.resetFields();
      const tenancyContactIdFiltered = contactFilter(
        tenant.data.tenancyContactId
      );
      const propertyManagementContactFiltered = contactFilter(
        tenant.data.propertyManagementContact
      );
      const agentContactFiltered = contactFilter(tenant.data.agentContact);
      !_.isEmpty(tenancyContactIdFiltered) &&
        form.setFieldsValue({
          tenancyContactId: tenancyContactIdFiltered[0].name,
        });

      !_.isEmpty(propertyManagementContactFiltered) &&
        form.setFieldsValue({
          propertyManagementContact: propertyManagementContactFiltered[0].name,
        });

      !_.isEmpty(agentContactFiltered) &&
        form.setFieldsValue({
          agentContact: agentContactFiltered[0].name,
        });
      setRefresh(true);
    }
  }, [refresh, isOpen, tenant, form, contacts]);

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        toggleDrawerOpen(false);
        setEditingTenantData(null);
        form.resetFields();
        tenant && dispatch(getAllTenants(propertyId));
      },
    });
  }

  return (
    <Drawer
      closeIcon={false}
      width={window.innerWidth > 1024 ? "55%" : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => {
        if (formFieldsChanged) {
          confirmCloseUnsaved();
          return;
        }
        toggleDrawerOpen(false);
        setEditContactData({
          data: {
            name: "",
            contactId: emptyGuid,
            propertyId: emptyGuid,
            contactType: "",
            company: "",
            email: "",
            phone: "",
          },
        });
        setContactList([]);
        setEditingTenantData(null);
        form.resetFields();
        dispatch(getAllTenants(propertyId));
      }}
      headerStyle={{
        backgroundColor: "#ebf9ff",
        margin: 0,
        padding: "2rem 2rem",
      }}
      title={
        <>
          <section
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.5rem",
            }}
          >
            <TenantIconWrapper>
              <MyTenantIcon className={"my-property-theme-icon"} />{" "}
              <span style={{ paddingRight: "0.25rem", fontSize: "0.75rem" }}>
                MY PROPERTY
              </span>
            </TenantIconWrapper>
            <ButtonsWrapper>
              <CloseButton
                size={"large"}
                onClick={() => {
                  form.resetFields();
                  setEditingTenantData(null);
                  toggleDrawerOpen(false);
                }}
              >
                {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
              </CloseButton>
            </ButtonsWrapper>
          </section>
          <PropertyDrawerTitle>Property tenancy</PropertyDrawerTitle>
        </>
      }
    >
      <ContactDrawer
        isOpen={isContactDrawerOpen}
        toggleDrawerOpen={toggleContactDrawer}
        contact={editContactData}
        setEditingContactData={setEditContactData}
      />
      <Form
        form={form}
        name="manageTenant"
        layout="vertical"
        size={"large"}
        onFinish={handleManageTenant}
        initialValues={initialValues()}
        onFieldsChange={() => setFormFieldsChanged(true)}
      >
        <>
          <span
            style={{ fontSize: "1.125rem", fontWeight: 600, color: "#21272B" }}
          >
            Property tenancy
          </span>
          <section
            style={{
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "10px",
              padding: "1rem",
              marginTop: "1em",
            }}
          >
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Tenancy start date *</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"startDate"}
                  key={"startDate"}
                  rules={[{
                    required: true,
                    message: "Tenancy start date is required"
                  }]}
                >
                  <DatePicker
                    name={"startDate"}
                    format={propertyPreferences.preferredDateFormat}
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    allowClear={true}
                    inputReadOnly={true}
                    style={{width: "100%", borderRadius: "6px"}}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Tenancy end date *</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"endDate"}
                  key={"endDate"}
                  rules={[{
                    required: true,
                    message: "Tenancy end date is required"
                  }, {
                    validator: (_, value) => {
                      if (moment(form.getFieldValue("startDate")).isBefore(moment(value))) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject('End date must be after start date');
                      }
                  }}]}
                >
                  <DatePicker
                    name={"endDate"}
                    format={propertyPreferences.preferredDateFormat}
                    allowClear={true}
                    inputReadOnly={true}
                    style={{width: "100%", borderRadius: "6px"}}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Rental payments</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"rentalPayment"}
                  key={"rentalPayment"}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/,
                      message: f(messages.validationCostOfInventory),
                    },
                  ]}
                >
                  <InputNumberStyled
                    maxLength={10}
                    className={propertyPreferences.preferredCurrency}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Tenant contact</Label>{" "}
                  {form.getFieldValue("tenancyContactId") &&
                    _.includes(
                      contacts.content.map((contact) => contact.name),
                      form.getFieldValue("tenancyContactId")
                    ) && (
                      <RelatedLink
                        onClick={() => openRelatedContact("tenancy")}
                      >
                        View related contact
                      </RelatedLink>
                    )}
                </LabelWrapper>
                <Form.Item key={"tenancyContactId"} name={"tenancyContactId"}>
                  <AutoCompleteStyled
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    showSearch
                    allowClear={true}
                    placeholder={"Please select"}
                    bordered={false}
                    showArrow={false}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={onContactAutocompleteSearch}
                    // @ts-ignore
                    options={contactList}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Rental agent fees</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"agentFee"}
                  key={"agentFee"}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/,
                      message: f(messages.validationCostOfInventory),
                    },
                  ]}
                >
                  <InputNumberStyled
                    maxLength={10}
                    className={propertyPreferences.preferredCurrency}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Rental agent contact</Label>{" "}
                  {form.getFieldValue("agentContact") &&
                    _.includes(
                      contacts.content.map((contact) => contact.name),
                      form.getFieldValue("agentContact")
                    ) && (
                      <RelatedLink onClick={() => openRelatedContact("agent")}>
                        View related contact
                      </RelatedLink>
                    )}
                </LabelWrapper>
                <Form.Item key={"agentContact"} name={"agentContact"}>
                  <AutoCompleteStyled
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    showSearch
                    allowClear={true}
                    placeholder={"Please select"}
                    bordered={false}
                    showArrow={false}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={onContactAutocompleteSearch}
                    // @ts-ignore
                    options={contactList}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Property management fees</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"propertyManagementFee"}
                  key={"propertyManagementFee"}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/,
                      message: f(messages.validationCostOfInventory),
                    },
                  ]}
                >
                  <InputNumberStyled
                    maxLength={10}
                    className={propertyPreferences.preferredCurrency}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Property management contact</Label>{" "}
                  {form.getFieldValue("propertyManagementContact") &&
                    _.includes(
                      contacts.content.map((contact) => contact.name),
                      form.getFieldValue("propertyManagementContact")
                    ) && (
                      <RelatedLink
                        onClick={() => openRelatedContact("property")}
                      >
                        View related contact
                      </RelatedLink>
                    )}
                </LabelWrapper>
                <Form.Item
                  key={"propertyManagementContact"}
                  name={"propertyManagementContact"}
                >
                  <AutoCompleteStyled
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    showSearch
                    allowClear={true}
                    placeholder={"Please select"}
                    bordered={false}
                    showArrow={false}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={onContactAutocompleteSearch}
                    // @ts-ignore
                    options={contactList}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
          </section>

          <SaveButtonWrapper>
            <SaveButton
              style={{display: "flex", alignItems: "center"}}
              //disabled={!formFieldsChanged}
              loading={saveDisabled}
              icon={<SaveIconStyled />}
              type={"primary"}
              htmlType={"submit"}
            >
              Save details
            </SaveButton>
          </SaveButtonWrapper>
        </>
      </Form>
    </Drawer>
  );
};

const SaveIconStyled = styled(SaveIcon)`
  padding-right: 0.25rem;
  width: 22px;
  height: 22px;
`;

const TenantIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

const RelatedLink = styled.a`
  color: #6b7185;
`;

export default TenantDrawer;
