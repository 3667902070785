import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TTag} from "../tags/addNewTag";

export type TGetNotesByPropertyIdResponse = Array<{
  recordId: Guid;
  title: string;
  message: string;
  createdDate: string;
  //parentId: Guid;
  //parentType: string;
  //parentName: string;
  attachment: {
    attachmentId: Guid;
    propertyId: Guid;
    parentId: Guid;
    parentType: string;
    name: string;
    size: number;
    searchable: boolean;
    lexemesFound: boolean;
  };
  thumbnails: Array<string>;
  emergency: boolean;
  tags: Array<TTag>;
}>;

export const getNotesByPropertyId = (propertyId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/notes`
  );

  return apiGet<TGetNotesByPropertyIdResponse | []>(url, {}, propertyId);
};
