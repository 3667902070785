import { apiDelete, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export const deleteNews = (propertyId: Guid, newsId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/news/${newsId}/delete`
  );

  return apiDelete(url);
};
