import React from "react";
import Modal from "antd/es/modal/Modal";
import styled from "styled-components";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";

type Props = {
  isBatchProcessing: boolean;
}

const BatchProcessingModal = (props: Props) => {
  const {isBatchProcessing} = props;

  return <ModalStyled
    visible={isBatchProcessing}
    width={800}
    centered
    title={null}
    footer={null}
  >
    <ModalContent>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />} />
      Processing your invoices, please wait...
    </ModalContent>
  </ModalStyled>
}

const ModalStyled = styled(Modal)`
  .ant-modal-content, .ant-modal-body {
    background: rgba(255, 255, 255, 0);
    box-shadow: none!important;
  }
  
  .ant-modal-close-icon { display: none!important; }
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
  align-items: center;
  font-size: 2rem;
  color: white;
  font-weight: 600;
`

export default BatchProcessingModal