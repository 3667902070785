import React, {useEffect, useState} from "react";
import {itemRender, UpcomingTasksDesktop} from "./Todo";
import {ReactComponent as SearchIcon} from "../../images/Search.svg";
import {Input, List, Menu, Popover} from "antd";
import {ReactComponent as SortIcon} from "../../images/Sort.svg";
import {ButtonStyled} from "./Costs";

import {ReactComponent as CompanyNewsIcon} from "../../images/news-page/CompanyNewsIcon.svg";
import {ReactComponent as CriticalAlertIcon} from "../../images/news-page/CriticalAlertIcon.svg";
import {ReactComponent as ImprovementSuggestionIcon} from "../../images/news-page/ImprovementSuggestionIcon.svg";
import {ReactComponent as UpkeepItemIcon} from "../../images/news-page/UpkeepItemIcon.svg";
import {ReactComponent as WorkTaskIcon} from "../../images/news-page/WorkTaskIcon.svg";

import styled from "styled-components";
import {ReactComponent as MoreMenu} from "../../images/More.svg";
import {MenuStyled, SortMenuStyled} from "../components/works/WorksListComp";
import NewsDrawer from "../components/news/NewsDrawer";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {getNewsSelector, getPropertyIdSelector, getPropertyPreferencesSelector} from "../selectors";
import {getAllNews} from "../actions/news";
import {Guid} from "guid-typescript";
import {markNewsAsRead} from "../../api-wrapper/news/markNewsAsRead";
import {deleteNews} from "../../api-wrapper/news/deleteNews";
import {FilterGroupHeader, FilterGroupList, FilterGroupRow, FilterGroupWrapper, Filters} from "./styled";
import {CancelButton, CategoryHeader, TagStyled} from "../components/tags/FilterTagsNew";
import moment from "moment";
import {parseToLowerCase} from "./helpers";
import {TFetchingStatus} from "../../helpers";
import {useLocation} from "react-router-dom";
import {ReactComponent as FiltersIconActive} from "../../images/spaces/FiltersIconActive.svg";
import {ReactComponent as FiltersIcon} from "../../images/spaces/FiltersIcon.svg";
import {
  PageHeader,
  PageInfoColumn,
  PageInfoWrapper,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  PaginationStyled
} from "./components";
import BoilerGasCertificateDrawer from "../components/partners/BoilerGasCertificateDrawer";
import MortgagePartnerDrawer from "../components/partners/MortgagePartnerDrawer";

const News = () => {
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const news = useSelector(getNewsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  useEffect(() => {
    dispatch(getAllNews(propertyId));
    setPage(1);
  }, [propertyId])

  const useQuery = () => {
    let query = new URLSearchParams(useLocation().search);
    return query.get("type");
  };
  const typeToOpen = useQuery()

  const [search, setSearch] = useState("");
  const [sortingType, setSortingType] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [sortMenuPopoverVisible, setSortMenuPopoverVisible] = useState(false);
  const [selectedNewsTypes, setSelectedNewsTypes] = useState<Set<string>>(new Set());

  const [isNewsDrawerOpen, toggleNewsDrawer] = useState(false);
  const [isGasDrawerOpen, toggleGasDrawer] = useState(false);
  const [isMortgageDrawerOpen, toggleMortgageDrawer] = useState(false);
  const [page, setPage] = useState(1);
  const [openedNews, setOpenedNews] = useState<any>([])
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (typeToOpen) {
      typeToOpen === "critical" && selectNewsType("Critical Alert")
      typeToOpen === "regular" && selectNewsType("News")
      typeToOpen === "risk" && selectNewsType("Risk of Failure")
    }
  }, [typeToOpen])

  const filteredNews = () =>
    news.content
      .filter((contentEl) => {
        return !_.isEmpty(search)
          ?
          _.includes(
            parseToLowerCase("" + contentEl.title),
            parseToLowerCase(search)
          )
          : true;
      })
      .filter(news => {
        return selectedNewsTypes.size > 0
          ? selectedNewsTypes.has(""+news.rating)
          : true;
      })
      .sort((n1, n2) => {
        return n1.title.localeCompare(n2.title)
      })
      .sort((n1, n2) => {
        if (sortingType === "typeSort") {
          return n2.rating.localeCompare(n1.rating)
        } else {
          return moment(n2.dateTime).valueOf() - moment(n1.dateTime).valueOf()
        }
      })

  const getNews = () =>
    filteredNews().filter(
      (currentValue: any, index: any, array: any) =>
        index >= (page - 1) * pageSize && index < page * pageSize
    );

  useEffect(() => {
    if (news.fetchingStatus === TFetchingStatus.Success && getNews().length === 0 && page !== 1) {
      setPage(page - 1)
    }
  }, [news.fetchingStatus, getNews()])

  const iconDispenser = (type: string) => {
    if (type === "Critical Alert") return <CriticalAlertIcon/>
    if (type === "News" || type === "General News") return <CompanyNewsIcon />
    if (type === "work") return <WorkTaskIcon />
    if (type === "improvement") return <ImprovementSuggestionIcon/>
    if (type === "Risk of Failure") return <UpkeepItemIcon />
  }

  const statusDispenser = (type: string) => {
    if (type === "Critical Alert") return <Dot style={{backgroundColor: "#EF624E"}} />
    if (type === "Risk of Failure") return <Dot style={{backgroundColor: "#EFC45D"}} />
    else return <div style={{width: "0.5rem", height: "0.5rem"}}>{" "}</div>
  }

  const floodAlertCount = news.content.filter((item) => item.rating === "Critical Alert" && !item.markedAsRead).length
  const newsCount = news.content.filter((item) => (item.rating === "News" || item.rating === "General News") && !item.markedAsRead).length
  const riskOfFailureCount = news.content.filter((item) => item.rating === "Risk of Failure" && !item.markedAsRead).length

  const moreMenuDropdown = (item: any) => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const {key} = event;
      if (key === "read") {
        markAsReadAndOpen(item.id)
      } else if (key === "remove") {
        deleteNews(propertyId, item.id)
          .then(() => dispatch(getAllNews(propertyId)))
      }
    }

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="read">Read</Menu.Item>
        <Menu.Item key="remove">Remove</Menu.Item>
      </MenuStyled>)
  };

  const moreMenuPopover = (item: any) => {
    return (<Popover
      content={moreMenuDropdown(item)}
      placement="bottomRight"
      trigger={["click"]}
      //@ts-ignore
      zIndex={2}
    >
      <MoreMenu
        style={{ marginRight: "-0.4rem" }}
        onClick={(e)=>{e.stopPropagation()}} />
    </Popover>)
  }

  const markAsReadAndOpen = (newsId: Guid) => {
    markNewsAsRead({markAsRead: true, newsId, propertyId})
      .then((res: any) => {
        dispatch(getAllNews(propertyId))
        setOpenedNews(res.data)
        toggleNewsDrawer(true)
    })
  }

  const getClassesForTag = (category: string) => {
    if (selectedNewsTypes.has(category)) return "tag-blue"
    if (news.content.filter((item: any) => item.rating === category).length === 0) return "tag-gray"
  }

  const sortMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      setSortMenuPopoverVisible(false);
      setSortingType("" + key);
    };

    return (
      <SortMenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="typeSort">Type</Menu.Item>
        <Menu.Item key="dateSort">Date</Menu.Item>
      </SortMenuStyled>
    );
  };

  const selectNewsType = (type: string) => {
    const newSet = new Set(selectedNewsTypes);
    if (selectedNewsTypes.has(type)) { newSet.delete(type) }
    else { newSet.add(type) }
    setSelectedNewsTypes(newSet);
  }

  const displayFilters = () => (
    <Filters style={{marginBottom: "2rem"}}>
      <FilterGroupRow>
        <CategoryHeader>
          Filters <CancelButton onClick={() => {
            setSelectedNewsTypes(new Set())
        }}>Clear filters</CancelButton>
        </CategoryHeader>
      </FilterGroupRow>
      <FilterGroupRow style={{border: 0, marginBottom: 0, paddingBottom: 0}}>
        <FilterGroupWrapper>
          <FilterGroupHeader>Type</FilterGroupHeader>
          <FilterGroupList style={{alignItems: "center", columnGap: "0.5rem", minHeight: "2rem"}}>
            <TagStyled
              className={getClassesForTag("Critical Alert")}
              onClick={() => selectNewsType("Critical Alert")}>Critical Alert</TagStyled>
            <TagStyled
              className={getClassesForTag("Risk of Failure")}
              onClick={() => selectNewsType("Risk of Failure")}>Risk of Failure</TagStyled>
            <TagStyled
              className={getClassesForTag("News")}
              onClick={() => selectNewsType("News")}>News</TagStyled>
          </FilterGroupList>
        </FilterGroupWrapper>
      </FilterGroupRow>
    </Filters>
  )

  return <PageWrapper>
    <BoilerGasCertificateDrawer
      isDrawerOpen={isGasDrawerOpen}
      toggleDrawer={toggleGasDrawer}
    />
    <MortgagePartnerDrawer
      isDrawerOpen={isMortgageDrawerOpen}
      toggleDrawer={toggleMortgageDrawer}
    />
    <NewsDrawer
      isDrawerOpen={isNewsDrawerOpen}
      toggleDrawer={toggleNewsDrawer}
      news={openedNews}
      toggleGasCertificateDrawer={toggleGasDrawer}
      toggleMortgageDrawer={toggleMortgageDrawer}
    />
    <PageHeader>
      <div>
        <PageTitle>My news</PageTitle>
        <PageSubtitle>Here you will find your latest Livlet news and alerts.</PageSubtitle>
      </div>
    </PageHeader>
    <PageInfoWrapper>
      <PageInfoColumn>
        <div className={"column-header"}>Alerts</div>
        <div className={"column-content"}>
          {floodAlertCount} unread
        </div>
      </PageInfoColumn>
      <PageInfoColumn>
        <div className={"column-header"}>General news</div>
        <div className={"column-content"}>
          {newsCount} unread
        </div>
      </PageInfoColumn>
      <PageInfoColumn>
        <div className={"column-header"}>Risk of failure</div>
        <div className={"column-content"}>
          {riskOfFailureCount} unread
        </div>
      </PageInfoColumn>
    </PageInfoWrapper>
    <UpcomingTasksDesktop style={{marginTop: "1rem"}}>
      <SearchWrapper>
        <Search
          size={"large"}
          value={search}
          onChange={(val) => {
            setSearch(val.target.value);
          }}
          placeholder={"Search news..."}
          suffix={<SearchIcon/>}
        />
        <SearchButton
          icon={selectedNewsTypes.size > 0 ? <FiltersIconActive /> : <FiltersIcon />}
          onClick={() => setShowFilters(!showFilters)}
        >
          {window.innerWidth > 1024 && `${showFilters ? "Hide" : "Show"} filters`}
        </SearchButton>
        <Popover
          content={sortMenuDropdown}
          className={"sortMenuWorks"}
          visible={sortMenuPopoverVisible}
          placement="bottom"
          onVisibleChange={setSortMenuPopoverVisible}
          trigger={["click"]}
        >
          <SearchButton
            icon={<SortIcon />}
            onClick={() => {
              setSortMenuPopoverVisible(!sortMenuPopoverVisible);
            }}
          />
        </Popover>
      </SearchWrapper>
      {showFilters && displayFilters()}
      <List
        style={{ width: "100%", margin: 0}}
        itemLayout="horizontal"
        className="rounded-bottom-list"
        dataSource={getNews()}
        renderItem={(item) => (
          <ListItemStyled
            key={""+item.id}
            className={item.markedAsRead ? "" : "unread"}
            onClick={() => markAsReadAndOpen(item.id)}>
            {iconDispenser(item.rating)}
            {statusDispenser(item.rating)}
            <List.Item.Meta
              title={<ItemTitle style={{color: item.markedAsRead ? "#C4C4C4" : "#21272B"}}>{item.title}</ItemTitle>}
              description={<div style={{display: "flex", overflow: "hidden",
                flexDirection: window.innerWidth > 1024 ? "row" : "column"}}>
                <ItemDescription style={{color: item.markedAsRead ? "#C4C4C4" : "#666F7A"}}>
                  {item.body.replace( /(<([^>]+)>)/ig, '')}
                </ItemDescription>
                {window.innerWidth <= 1024 && <ItemDescription
                    style={{color: item.markedAsRead ? "#C4C4C4" : "#666F7A", marginTop: "0.5rem"}}>
                  {moment(item.dateTime).format(propertyPreferences.preferredDateFormat)}
                </ItemDescription>}
              </div>} />
            {window.innerWidth > 1024 &&
              <ItemDate style={{color: item.markedAsRead ? "#C4C4C4" : "#666F7A"}}>
                {moment(item.dateTime).format(propertyPreferences.preferredDateFormat)}
              </ItemDate>}
            {moreMenuPopover(item)}
          </ListItemStyled>
        )}
      />
      {filteredNews().length > pageSize && <PaginationStyled
        style={{marginBottom: window.innerWidth > 1024 ? 0 : "1rem"}}
        className={filteredNews().length < pageSize ? "single-page-pagination" : ""}
        onChange={(page) => setPage(page)}
        itemRender={itemRender}
        defaultCurrent={1}
        simple={window.innerWidth < 1024}
        showSizeChanger={false}
        defaultPageSize={pageSize}
        total={filteredNews().length}
        current={page}
        onShowSizeChange={(current, size) => setPageSize(size)}
      />}
    </UpcomingTasksDesktop>
  </PageWrapper>
}

const SearchWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  column-gap: 0.5rem;
  
  @media (max-width: 1024px) {
    column-gap: 0.5rem;
    padding: 0.5rem;
  }
`;

const Search = styled(Input)`
  width: 100%;
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
  
  @media (max-width: 1024px) {
    height: 3.25rem;
  }
`;

const SearchButton = styled(ButtonStyled)`
  display: flex;
  column-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 2.5rem;
  min-width: 2.5rem;
  
  @media (max-width: 1024px) {
    width: 3.25rem!important;
    height: 3.25rem;
  }
`;

const ListItemStyled = styled(List.Item)`
  border-left: 4px solid white;
  padding: 0.5rem 1.25rem 0.5rem 1.5rem;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  align-items: flex-start;
  
  &.unread {
    background: #EBF9FF;
    border-left: 4px solid #2A80E1;
  }
  
  @media (max-width: 1024px) {
    padding: 0.5rem 1rem;
  }
`

const ItemTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #21272B;
`

const ItemDescription = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
                    
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666F7A;
`

const ItemDate = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #666F7A;
  margin-right: 1rem;
`

const Dot = styled.div`
  margin-top: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
`

export default News