import React from "react";

import {ReactComponent as EmptyDocumentsIcons} from "../../../images/empty-states/EmptyDocumentsIcons.svg";
import {ReactComponent as EmptyDocumentsUploadIcon} from "../../../images/empty-states/EmptyDocumentsUploadIcon.svg";
import {ReactComponent as ChevronLeft} from "../../../images/ChevronLeft.svg";
import {ReactComponent as ChevronRight} from "../../../images/ChevronRight.svg";

import EmptyDocuments1 from "../../../images/empty-states/EmptyDocuments1.png";
import EmptyDocuments2 from "../../../images/empty-states/EmptyDocuments2.png";
import EmptyDocuments3 from "../../../images/empty-states/EmptyDocuments3.png";
import EmptyDocumentsMobile1 from "../../../images/empty-states/EmptyDocumentsMobile1.png";
import EmptyDocumentsMobile2 from "../../../images/empty-states/EmptyDocumentsMobile2.png";
import EmptyDocumentsMobile3 from "../../../images/empty-states/EmptyDocumentsMobile3.png";
import {ButtonSmallBlue, ButtonSmallWhite, EmptyStateWrapper} from "../../screens/components";
import {AlertContent, AlertStyled, ContentWrapper, Dots, FlexWrapper} from "../inventories/InventoryIntroduction";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getUserProfileSelector} from "../../selectors";
import {setUserIntroductions} from "../../../api-wrapper/user/setUserIntroductions";
import {getCurrentUserProfile} from "../../actions/getUserProfile";
import {getAllAttachments} from "../../actions/attachments";
import {Guid} from "guid-typescript";
import UploadFile from "./UploadFile";

type Props = {
  introductionStatus: string;
  toggleNewlyUploadedDrawer: (arg: boolean) => void;
  uploadInProgress: any;
  setUploadInProgress: (arg: any) => void;
  setNewlyAttached: (arg: any) => void;
}

const DocumentsIntroduction = (props: Props) => {
  const {introductionStatus, toggleNewlyUploadedDrawer, uploadInProgress, setUploadInProgress, setNewlyAttached} = props;

  const dispatch = useDispatch();
  const userProfile = useSelector(getUserProfileSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;

  const handleStateChange = (status: string) => {
    setUserIntroductions({
      contents: userProfile.content.contentsStatus,
      spaces: userProfile.content.spacesStatus,
      documents: status
    }).then(() => dispatch(getCurrentUserProfile()))
  }

  const navigationDots = () => <Dots>
    <span className={`dot ${(introductionStatus === "step1" || introductionStatus === null) && "active"}`} onClick={() => handleStateChange("step1")} />
    <span className={`dot ${introductionStatus === "step2" && "active"}`} onClick={() => handleStateChange("step2")} />
    <span className={`dot ${introductionStatus === "step3" && "active"}`} onClick={() => handleStateChange("step3")} />
  </Dots>

  const slide1 = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>What is the <span className={"highlight"}>Documents</span> feature for?</span>
      <span className={"content-section"}><span className={"highlight"}>Documents</span> is a <b>secure place</b> to save all important or
        relevant documents about your property <b>in the cloud & accessible from anywhere</b>.
        <br/><br/>
        Everything you save here can be easily searched and can be interlinked with other documents as well as
        projects, spaces, contents, expenses, tasks, contacts and more...</span>
      {navigationDots()}
      <FlexWrapper className={"buttons"}>
        <ButtonSmallBlue onClick={() => handleStateChange("step2")}>
          Next <ChevronRight />
        </ButtonSmallBlue>
      </FlexWrapper>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptyDocuments1} /></ContentWrapper>
  </AlertContent>

  const slide1Mobile = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>What is the <span className={"highlight"}>Documents</span> feature for?</span>
      <span className={"content-section"}><b>Documents</b> is a <b>secure place</b> to save all important or relevant
        documents about your property <b>in the cloud & accessible from anywhere</b>.</span>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptyDocumentsMobile1} /></ContentWrapper>
    {navigationDots()}
    <FlexWrapper className={"buttons"}>
      <ButtonSmallBlue onClick={() => handleStateChange("step2")}>
        Next <ChevronRight />
      </ButtonSmallBlue>
    </FlexWrapper>
  </AlertContent>

  const slide2 = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>How to use <span className={"highlight"}>Documents</span>...</span>
      <span className={"content-section"}>For example, if an architect, sends you three PDFs; a building warrant, a drawing of
        the new garage and a photo, you can upload them and then link them to relevant entries in Projects, Contacts,
        Contents, Costs, Spaces, Notes and others.</span>
      <FlexWrapper>
        <EmptyDocumentsUploadIcon />
        <span className={"content-section"}>Use the <b>File upload zone</b> to "Drag and drop"
          documents into Livlet or select files from your computer</span>
      </FlexWrapper>
      <span className={"content-section"}>Examples include: <b>Bills</b> & <b>Invoices</b> for <span className={"highlight"}>
        Expenses</span>, <b>Warranties</b> & <b>Manuals</b> for <span className={"highlight"}>Contents</span> and <b>Floor plans</b>, <b>
        Certificates</b> & <b>Surveys</b> for <span className={"highlight"}>Property details</span>.</span>
      {navigationDots()}
      <FlexWrapper className={"buttons"}>
        <ButtonSmallWhite onClick={() => handleStateChange("step1")}>
          <ChevronLeft /> Back
        </ButtonSmallWhite>
        <ButtonSmallBlue onClick={() => handleStateChange("step3")}>
          Next <ChevronRight />
        </ButtonSmallBlue>
      </FlexWrapper>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptyDocuments2} /></ContentWrapper>
  </AlertContent>

  const slide2Mobile = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>How to use <span className={"highlight"}>Documents</span>...</span>
      <FlexWrapper>
        <EmptyDocumentsUploadIcon />
        <span className={"content-section"}>Use the <b>File upload zone</b> to "Drag and drop"
        documents into Livlet or select files from your computer</span>
      </FlexWrapper>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptyDocumentsMobile2} /></ContentWrapper>
    {navigationDots()}
    <FlexWrapper className={"buttons"}>
      <ButtonSmallWhite onClick={() => handleStateChange("step1")}>
        <ChevronLeft /> Back
      </ButtonSmallWhite>
      <ButtonSmallBlue onClick={() => handleStateChange("step3")}>
        Next <ChevronRight />
      </ButtonSmallBlue>
    </FlexWrapper>
  </AlertContent>

  const slide3 = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>Making the most of <span className={"highlight"}>Documents</span>...</span>
      <span className={"content-section"}>This interconnectivity between documents means Livlet can provide you
        with more relevant and timely insights about your home management as well as making it easier for you to
        find and share important documents when you need to.
        <br/><br/>
        <b>And finally</b>, take advantage of our <span className={"highlight-blue"}>Forwarding</span> feature. You can forward documents that
        you want to save in your Livlet as attachments in emails sent to <a href={`mailto:${process.env.REACT_APP_FILE_EMAIL}`}>
        <span className={"link"}>{process.env.REACT_APP_FILE_EMAIL}</span></a> using your Livlet account's email address.
        <br/><br/>
        You can find more details in the FAQ. <a className={"highlight"} href={'https://www.livlet.com/faq?question=files'}
                                                 target={"_blank"} rel={"noreferrer"}>Try it out!</a></span>
      {navigationDots()}
      <FlexWrapper className={"buttons"}>
        <ButtonSmallWhite onClick={() => handleStateChange("step2")}>
          <ChevronLeft /> Back
        </ButtonSmallWhite>
        <ButtonSmallBlue onClick={() => handleStateChange("completed")}>
          Get started!
        </ButtonSmallBlue>
      </FlexWrapper>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptyDocuments3} /></ContentWrapper>
  </AlertContent>

  const slide3Mobile = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>Making the most of <span className={"highlight"}>Documents</span>...</span>
      <span className={"content-section"}>You can also forward files that you want to save in your Livlet as attachments
        in emails sent to <a href={`mailto:${process.env.REACT_APP_FILE_EMAIL}`}><span className={"link"}>{process.env.REACT_APP_FILE_EMAIL}</span></a>.</span>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptyDocumentsMobile3} /></ContentWrapper>
    {navigationDots()}
    <FlexWrapper className={"buttons"}>
      <ButtonSmallWhite onClick={() => handleStateChange("step2")}>
        <ChevronLeft /> Back
      </ButtonSmallWhite>
      <ButtonSmallBlue onClick={() => handleStateChange("completed")}>
        Get started!
      </ButtonSmallBlue>
    </FlexWrapper>
  </AlertContent>

  const emptyState = () => <EmptyStateWrapper>
    <EmptyDocumentsIcons />
    <span>Add and manage your documents<br/>
      <span style={{fontWeight: 400}}>Add your document with invoices, drawings and plans.</span>
    </span>
    <UploadFile
      fileList={[]}
      refreshParent={() => dispatch(getAllAttachments(propertyId))}
      singleAttachment={false}
      parentId={null}
      parentType={null}
      propertyId={propertyId}
      beforeUploadCallback={(open) => toggleNewlyUploadedDrawer(open)}
      uploadInProgress={uploadInProgress}
      setUploadInProgress={setUploadInProgress}
      addToFileList={(attachmentId: Guid) => setNewlyAttached(attachmentId)}
      uploadImmediately={true}
      isGlobalUpload={true}
      isFileListEmpty={true}
    />
  </EmptyStateWrapper>

  const introContentDesktop = () => {
    if (introductionStatus == "completed") return emptyState()
    if (introductionStatus == "step3") return slide3()
    if (introductionStatus == "step2") return slide2()
    if (introductionStatus === null || introductionStatus == "step1") return slide1()
    return ""
  }

  const introContentMobile = () => {
    if (introductionStatus == "completed") return emptyState()
    if (introductionStatus == "step3") return slide3Mobile()
    if (introductionStatus == "step2") return slide2Mobile()
    if (introductionStatus === null || introductionStatus == "step1") return slide1Mobile()
    return ""
  }

  return <AlertStyled
    message={window.innerWidth > 768 ? introContentDesktop() : introContentMobile()}
    style={{marginBottom: "2rem"}}
    closable={introductionStatus !== "completed"}
    onClose={() => handleStateChange("skipped")}
  />
}

export default DocumentsIntroduction