import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TTag} from "../tags/addNewTag";

export type TGetExpenseByIdResponse = {
  expenseId: Guid;
  notes: Array<{
    recordId: Guid;
    title: string;
    message: string;
    createdDate: string;
    attachment: {
      attachmentId: Guid;
      propertyId: Guid;
      parentId: Guid;
      parentType: string;
      name: string;
      size: number;
    };
  }>;
  attachments: Array<{
    attachmentId: Guid;
    propertyId: Guid;
    parentId: Guid;
    parentType: string;
    storageKey: string;
    name: string;
    size: number;
    path: string;
  }>;
  tags: Array<TTag>;
};

export const getExpenseById = (propertyId: Guid, expenseId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/expense/${expenseId}/details`,
    {}
  );

  return apiGet<TGetExpenseByIdResponse>(url);
};
