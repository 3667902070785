import React, {useEffect, useState} from "react";
import {ReactComponent as NewsAlertsIcon} from "../../../images/dashboard-news-alerts/NewsAlertsIcon.svg";
import {ReactComponent as NewsGrayIcon} from "../../../images/dashboard-news-alerts/NewsGrayIcon.svg";
import {ReactComponent as FailureRiskIcon} from "../../../images/dashboard-news-alerts/FailureRiskIcon.svg";
import {ReactComponent as EOLIcon} from "../../../images/dashboard-contents/EOLIcon.svg";
import {ReactComponent as EmptyInventoryIcon} from "../../../images/dashboard-contents/EmptyInventoryIcon.svg";

import {
  borderSetting,
  OuterWidgetWrapper, ViewAllButton,
  WidgetItem, WidgetItemCount, WidgetItemCountDescription, WidgetItemCountNumber,
  WidgetTitle,
  WidgetTitleWrapper,
  WidgetWrapper
} from "./ImprovementsWidget";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getInventoriesSelector, getNewsSelector} from "../../selectors";
import {useHistory} from "react-router-dom";
import {ReactComponent as ViewAllArrow} from "../../../images/dashboard-improvements/ViewAllArrow.svg";

const NewsAlertsWidget = () => {
  const news = useSelector(getNewsSelector);
  const inventory = useSelector(getInventoriesSelector)
  let history = useHistory();

  const criticalAlertCount = news.content.filter((item) =>
    item.rating === "Critical Alert" && !item.markedAsRead).length
  const riskOfFailureCount = news.content.filter((item) =>
    item.rating === "Risk of Failure" && !item.markedAsRead).length
  const newsCount = news.content.filter((item) =>
    (item.rating === "News" || item.rating === "General News") && !item.markedAsRead).length

  const [displayAlert, setDisplayAlert] = useState(false);
  const [displayRisk, setDisplayRisk] = useState(false);
  const [displayNews, setDisplayNews] = useState(false);
  const [displayInventory, setDisplayInventory] = useState(false);

  useEffect(() => {
    if ((criticalAlertCount > 0 && riskOfFailureCount > 0 && newsCount > 0) ||
      (criticalAlertCount > 0 && riskOfFailureCount > 0) ||
      (criticalAlertCount > 0 && newsCount > 0) ||
      (riskOfFailureCount > 0 && newsCount > 0)) {
      setDisplayAlert(true)
      setDisplayNews(true)
      setDisplayRisk(true)
      setDisplayInventory(false)
    } else if (criticalAlertCount > 0 && riskOfFailureCount === 0 && newsCount === 0) {
      setDisplayAlert(true)
      setDisplayNews(false)
      setDisplayRisk(false)
      setDisplayInventory(true)
    } else if (criticalAlertCount === 0 && riskOfFailureCount > 0 && newsCount === 0) {
      setDisplayAlert(false)
      setDisplayNews(false)
      setDisplayRisk(true)
      setDisplayInventory(true)
    } else if (criticalAlertCount === 0 && riskOfFailureCount === 0 && newsCount > 0) {
      setDisplayAlert(false)
      setDisplayNews(true)
      setDisplayRisk(false)
      setDisplayInventory(true)
    }
  }, [criticalAlertCount, riskOfFailureCount, newsCount])

  return (news.content.filter(item => !item.markedAsRead ).length > 0 ? <OuterWidgetWrapper style={{width: "100%"}}>
    <WidgetTitleWrapper>
      <WidgetTitle><NewsAlertsIcon/>My news & alerts</WidgetTitle>
      {window.innerWidth > 1024 && <ViewAllButton onClick={() => history.push("/news")}>Go to My news<ViewAllArrow/></ViewAllButton>}
    </WidgetTitleWrapper>

    <NewsWidgetWrapper>
      {displayAlert && <NewsWidgetItem style={{borderRight: window.innerWidth > 1024 ? borderSetting : 0}}>
          <WidgetItemCount>
              <EOLIcon/>
              <WidgetItemCountNumber>{criticalAlertCount}</WidgetItemCountNumber>
              <NewsWidgetItemCountDescription>Critical alerts</NewsWidgetItemCountDescription>
          </WidgetItemCount>
          <ViewAllLink onClick={() => history.push("/news?type=critical")}>View all</ViewAllLink>
      </NewsWidgetItem>}
      {displayNews && <NewsWidgetItem style={{borderRight: window.innerWidth > 1024 ? borderSetting : 0}}>
          <WidgetItemCount>
              <NewsGrayIcon/>
              <WidgetItemCountNumber>{newsCount}</WidgetItemCountNumber>
              <NewsWidgetItemCountDescription>Regular news</NewsWidgetItemCountDescription>
          </WidgetItemCount>
          <ViewAllLink onClick={() => history.push("/news?type=regular")}>View all</ViewAllLink>
      </NewsWidgetItem>}
      {displayRisk && <NewsWidgetItem style={{borderRight: displayInventory ? (window.innerWidth > 1024 ? borderSetting: 0) : 0}}>
          <WidgetItemCount>
              <FailureRiskIcon/>
              <WidgetItemCountNumber>{riskOfFailureCount}</WidgetItemCountNumber>
              <NewsWidgetItemCountDescription>At risk of failure</NewsWidgetItemCountDescription>
          </WidgetItemCount>
          <ViewAllLink onClick={() => history.push("/news?type=risk")}>View all</ViewAllLink>
      </NewsWidgetItem>}
      {displayInventory && <NewsWidgetItemLarge>
          <div style={{display: "flex", columnGap: "1.625rem"}}>
              <EmptyInventoryIcon/>
              <div style={{display: "flex", flexDirection: "column"}}>
            <span style={{
              fontSize: "0.875rem",
              lineHeight: "1.125rem",
              color: "#002A43",
              fontWeight: 600
            }}>Add inventory</span>
                  <span style={{
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                    color: "#666F7A"
                  }}>To track risk of failure and get notified</span>
              </div>
          </div>
          <ViewAllButton onClick={() => {
            inventory.content.length > 0 ? history.push("/contents") : history.push("/questions?openPage=contents")
          }}>Add inventory <ViewAllArrow/></ViewAllButton>
      </NewsWidgetItemLarge>}
    </NewsWidgetWrapper>
  </OuterWidgetWrapper> : <></>)
}

const NewsWidgetWrapper = styled(WidgetWrapper)`
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

export const ViewAllLink = styled.div`
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-decoration-line: underline;
  color: #236EC2;
`

const NewsWidgetItem = styled(WidgetItem)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 1024px) {
    width: 100%;
    padding: 1rem 1.5rem;
    border-bottom: 2px solid #eff1f5;
  }
`

const NewsWidgetItemLarge = styled(NewsWidgetItem)`
  width: calc(100% / 3 * 2);
  
  @media (max-width: 1024px) {
    width: 100%;
    padding: 1rem 1.5rem;
  }
`

const NewsWidgetItemCountDescription = styled(WidgetItemCountDescription)`
  @media (max-width: 1024px) {
      color: #45655E;
      text-transform: none;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
`

export default NewsAlertsWidget;