import React, {useEffect, useState} from "react";

import {ReactComponent as CalendarArrowLeft} from "../../../images/dashboard-calendar/CalendarArrowLeft.svg";
import {ReactComponent as CalendarArrowRight} from "../../../images/dashboard-calendar/CalendarArrowRight.svg";
import {ReactComponent as PlannerArrowLeft} from "../../../images/planner/PlannerArrowLeft.svg";
import {ReactComponent as PlannerArrowRight} from "../../../images/planner/PlannerArrowRight.svg";
import {ReactComponent as CalendarHeaderIcon} from "../../../images/dashboard-calendar/CalendarHeaderIcon.svg";
import {ReactComponent as ScheduleCalendarIcon} from "../../../images/dashboard-calendar/ScheduleCalendarIcon.svg";

import styled from "styled-components";
import {Button, Checkbox, DatePicker, Dropdown, Modal, Select, Spin} from "antd";
import moment from "moment";
import CalendarColumn from "./CalendarColumn";

import {DragDropContext} from "react-beautiful-dnd"
import {
  getCalendarTasksSelector,
  getExpensesSelector,
  getMaintenancesSelector, getPropertiesSelector,
  getPropertyIdSelector, getPropertyPreferencesSelector,
  getWorksSelector
} from "../../selectors";
import {useDispatch, useSelector} from "react-redux";
import {getAllWorks} from "../../actions/works";
import {getAllExpenses} from "../../actions/expenses";
import {editExpense} from "../../../api-wrapper/expenses/editExpense";
import {editWork} from "../../../api-wrapper/works/editWork";
import {snoozeUpcomingTask} from "../../../api-wrapper/upcomingTasks/snoozeUpcomingTask";
import {TFetchingStatus, TTaskTypes} from "../../../helpers";
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons";
import {ReactComponent as ViewAllArrow} from "../../../images/dashboard-improvements/ViewAllArrow.svg";
import {ViewAllButton} from "../dashboardWidgets/ImprovementsWidget";
import {useHistory} from "react-router-dom";
import {ReactComponent as CalendarWorksGray} from "../../../images/dashboard-calendar/CalendarWorksGray.svg";
import {ReactComponent as CalendarExpenseGray} from "../../../images/dashboard-calendar/CalendarExpenseGray.svg";
import {ReactComponent as CalendarTaskGray} from "../../../images/dashboard-calendar/CalendarTaskGray.svg";
import {ReactComponent as CalendarBudgetGray} from "../../../images/dashboard-calendar/CalendarBudgetCostGray.svg";
import {ReactComponent as CalendarReminderGray} from "../../../images/dashboard-calendar/CalendarReminderGray.svg";
import {getAllConfirmedExpenses} from "../../actions/confirmedExpenses";
import _ from "lodash";
import {emptyGuid} from "../../screens/helpers";
import {getShowExpenses, setShowExpenses} from "../../../localStorage";
import {putDismissOnboarding} from "../../../api-wrapper/properties/putDismissOnboarding";
import {getAllProperties} from "../../actions/properties";
import {ReactComponent as ArrowDownSelect} from "../../../images/ArrowDownSelect.svg";
const { Option } = Select;

type Props = {
  startDate: any;
  setStartDate: (arg: any) => void;
  toggleCustomUpkeepDrawer: (arg: boolean) => void;
  setCustomUpkeepData: (arg: any) => void;
  handleReminderAction: (arg1: any, arg2: any) => void;
  handleWorkAction: (arg1: any, arg2: any) => void;
  handleExpenseAction: (arg1: any, arg2: any) => void;
  monthlyView: boolean;
  toggleMonthlyView: (arg: boolean) => void;
  setSelectedTaskType: (arg: string) => void;
  refreshParent?: () => void | null;
  totalColumns: number;
  columnsInRow?: number;
  plannerView: boolean;
  refreshTasks: () => void;
}

const UpcomingTaskCalendar = (props: Props) => {
  const { startDate, setStartDate, handleWorkAction, handleReminderAction, handleExpenseAction,
    monthlyView, toggleMonthlyView, refreshParent, toggleCustomUpkeepDrawer, setCustomUpkeepData,
    setSelectedTaskType, totalColumns, columnsInRow, plannerView, refreshTasks } = props;
  let history = useHistory();

  const [localCalendarTasks, setLocalCalendarTasks] = useState<any>(null)
  const [weekStartDate, setWeekStartDate] = useState<any>(moment().startOf("isoWeek"))
  const [monthStartDate, setMonthStartDate] = useState<any>(moment().startOf("month"))
  const [displayedDate, setDisplayedDate] = useState<any>(moment())
  const [showPlaceholderWidget, setShowPlaceholderWidget] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const localstorageExpenseTasks = getShowExpenses();
  const [showExpenseTasks, toggleShowExpenseTasks] = useState(false)

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);
  const works = useSelector(getWorksSelector);
  const expenses = useSelector(getExpensesSelector);
  const calendarTasks = useSelector(getCalendarTasksSelector);
  const maintenances = useSelector(getMaintenancesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [cachedExpensesTotal, setCachedExpensesTotal] = useState(0);

  useEffect(() => {
    setLoading(true);
  }, [monthlyView])

  useEffect(() => {
    if (calendarTasks.fetchingStatus === TFetchingStatus.Success) {
      setLoading(false);
    }
  }, [calendarTasks.fetchingStatus])

  useEffect(() => {
    if (!localstorageExpenseTasks || localstorageExpenseTasks === "false") {
      toggleShowExpenseTasks(false)
    } else toggleShowExpenseTasks(true)
  }, [localstorageExpenseTasks])

  useEffect(() => {
    if (expenses.fetchingStatus === TFetchingStatus.Success) {
      const newExpensesTotal = expenses.content.reduce((sum, { totalCost }) => sum + totalCost, 0);
      if (newExpensesTotal !== cachedExpensesTotal) {
        setCachedExpensesTotal(newExpensesTotal);
        refreshTasks();
      }
    }
  }, [expenses])

  useEffect(() => {
    if (monthlyView) {
      monthStartDate && setStartDate(monthStartDate)
    } else {
      weekStartDate && setStartDate(weekStartDate)
    }
  }, [monthlyView, weekStartDate, monthStartDate])

  useEffect(() => {
    setDisplayedDate(startDate)
  }, [startDate])

  useEffect(() => {
    calendarTasks.content && setLocalCalendarTasks(calendarTasks.content)
  }, [calendarTasks.content])

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    if (maintenances.fetchingStatus === TFetchingStatus.Success) {
      if (maintenances.content.filter(item => item.subscribed).length >= 1) {
        setShowPlaceholderWidget(false)
      } else {
        if (properties.find((property) => property.propertyId === propertyId)?.dismissSchedule)
          setShowPlaceholderWidget(false)
        else
          setShowPlaceholderWidget(true)
      }
    }
  }, [maintenances, properties])

  const getNewDate = (currentDate: any, difference: number, orToday?: boolean) => {
    if (orToday && difference > 0) {
      if (moment(currentDate).subtract(Math.abs(difference), monthlyView ? "months" : "weeks").isBefore(moment(), "days")) {
        return moment().format("YYYY-MM-DD")
      }
    }
    if (difference > 0) {
      return moment(currentDate).subtract(Math.abs(difference), monthlyView ? "months" : "weeks").format("YYYY-MM-DD")
    } else {
      return moment(currentDate).add(Math.abs(difference), monthlyView ? "months" : "weeks").format("YYYY-MM-DD")
    }
  }

  function throwReschedulingError() {
    Modal.error({
      title: "Error",
      content: "Cannot reschedule the reminder to a date that has already passed.",
      okText: "OK",
      className: "form-confirm-close-modal"
    });
  }

  const getReminderPermission = (date: any) => {
    const today = moment()
    const plannedDate = moment(date)
    return today.isSameOrBefore(plannedDate, "day")
      || plannedDate.isBetween(today.startOf("month"), today.endOf("month"), monthlyView ? "months" : "weeks", "[]")
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;

    const draggable = result.draggableId.split(" ")
    const draggableType = draggable[0]
    const draggableId = draggable[1]

    const sourceWeekStart = source.droppableId.substring(9, 19)
    const destinationWeekStart = destination.droppableId.substring(9, 19)
    const difference = moment(sourceWeekStart).diff(moment(destinationWeekStart), monthlyView ? "month" : "week")

    if (source.droppableId !== destination.droppableId) {
      // local state
      const sourceColumn = localCalendarTasks[source.droppableId];
      const destinationColumn = localCalendarTasks[destination.droppableId];
      const sourceItems = [...sourceColumn.tasks];
      const destinationItems = [...destinationColumn.tasks];
      let [removed] = sourceItems.splice(source.index, 1);

      if (draggableType === "work") {
        // if (removed.task.completedDate) {
        //   Modal.error({
        //     title: `Error!`,
        //     content: "This work has already been completed. You cannot reschedule it.",
        //     className: "form-confirm-close-modal",
        //   });
        //   return;
        // }
        removed = {
          ...removed,
          date: getNewDate(removed.task.dueDate, difference),
          task: {...removed.task, dueDate: getNewDate(removed.task.dueDate, difference)}
        }
      }
      if (draggableType === "expense") {
        removed = {
          ...removed,
          date: getNewDate(removed.task.paymentDate, difference),
          task: {...removed.task, paymentDate: getNewDate(removed.task.paymentDate, difference)}
        }
      }
      if (draggableType === "alert") {
        if (getReminderPermission(getNewDate(removed.task.triggerDate, difference))) {
          removed = {
            ...removed,
            date: getNewDate(removed.task.triggerDate, difference, true),
            task: {...removed.task, triggerDate: getNewDate(removed.task.triggerDate, difference, true)}
          }
        } else {
          throwReschedulingError()
          return;
        }
      }
      destinationItems.splice(destination.index, 0, removed);
      setLocalCalendarTasks({
        ...localCalendarTasks,
        [source.droppableId]: {...sourceColumn, tasks: sourceItems},
        [destination.droppableId]: {...destinationColumn, tasks: destinationItems}
      });

      // backend
      if (draggableType === "work") {
        const workToEdit = works.content.filter((work: any) => work.workId === draggableId)[0]
        editWork({
          ...workToEdit,
          completedDate: workToEdit.isComplete && moment().endOf("day")
            .isAfter(moment(getNewDate(workToEdit.dueDate, difference)).startOf("day")) ? getNewDate(workToEdit.dueDate, difference) : null,
          isComplete: workToEdit.isComplete ? moment().endOf("day").isAfter(moment(getNewDate(workToEdit.dueDate, difference))
            .startOf("day")) : false,
          dueDate: getNewDate(workToEdit.dueDate, difference)
        }).then(() => {
          dispatch(getAllWorks(propertyId))
          refreshTasks();
          if (refreshParent) refreshParent();
        })
      }
      if (draggableType === "expense") {
        let expenseToEdit = expenses.content.filter((expense: any) => expense.expenseId === draggableId)[0]
        editExpense({
          ...expenseToEdit, paymentDate: getNewDate(expenseToEdit.paymentDate, difference)
        }).then(() => {
          dispatch(getAllExpenses(propertyId))
          dispatch(getAllConfirmedExpenses(propertyId))
          refreshTasks();
          if (refreshParent) refreshParent();
        })
      }
      if (draggableType === "alert") {
        snoozeUpcomingTask({...removed.task, snoozeDate: removed.task.triggerDate}).then(() => {
          refreshTasks();
          if (refreshParent) refreshParent();
        })
      }
    } else {
      // local state changes only
      const column = localCalendarTasks[source.droppableId];
      const copiedItems = [...column.tasks];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setLocalCalendarTasks({
        ...localCalendarTasks,
        [source.droppableId]: {...column, tasks: copiedItems}
      });
    }
  }

  const addNewItemDropdown = () => {
    return <AddNewItemDropdown>
      Add new event
      <AddNewItemWrapper>
        <AddNewCalendarItem onClick={() => handleWorkAction("add", null)}>
          <CalendarWorksGray /> Project
        </AddNewCalendarItem>
        <AddNewCalendarItem onClick={() => handleExpenseAction("add", null)}>
          {/*{propertyPreferences.preferredCurrency === "GBP" && <CalendarExpenseGray/>}*/}
          <CalendarExpenseGray/>
          Expense
        </AddNewCalendarItem>
        <AddNewCalendarItem onClick={() => {
          setCustomUpkeepData(null);
          setSelectedTaskType(TTaskTypes.Task);
          toggleCustomUpkeepDrawer(true);
        }}>
          <CalendarTaskGray/> Task
        </AddNewCalendarItem>
        <AddNewCalendarItem onClick={() => {
          setCustomUpkeepData(null);
          setSelectedTaskType(TTaskTypes.Budget);
          toggleCustomUpkeepDrawer(true);
        }}>
          {/*{propertyPreferences.preferredCurrency === "GBP" && <CalendarBudgetGray />}*/}
          <CalendarBudgetGray />
          Recurring expense
        </AddNewCalendarItem>
        <AddNewCalendarItem onClick={() => {
          setCustomUpkeepData(null);
          setSelectedTaskType(TTaskTypes.Reminder);
          toggleCustomUpkeepDrawer(true);
        }}>
          <CalendarReminderGray /> Reminder
        </AddNewCalendarItem>
      </AddNewItemWrapper>
    </AddNewItemDropdown>
  }

  const onArrowLeft = () => {
    if (monthlyView) {
      let newDate = startDate.clone()
      newDate.subtract(1, "months")
      setMonthStartDate(newDate)
      if (weekStartDate.format("MM YYYY") !== newDate.format("MM YYYY")) {
        let leapDate = newDate.clone()
        leapDate.startOf("isoWeek")
        setWeekStartDate(leapDate)
      }
    } else {
      let newDate = startDate.clone()
      newDate.subtract(1, "weeks")
      setWeekStartDate(newDate)
      if (monthStartDate.format("MM YYYY") !== newDate.format("MM YYYY")) {
        let leapDate = newDate.clone()
        leapDate.startOf("month")
        setMonthStartDate(leapDate)
      }
    }
  }

  const onArrowRight = () => {
    if (monthlyView) {
      let newDate = startDate.clone()
      newDate.add(1, "months")
      setMonthStartDate(newDate)
      if (weekStartDate.format("MM YYYY") !== newDate.format("MM YYYY")) {
        let leapDate = newDate.clone()
        leapDate.startOf("isoWeek")
        setWeekStartDate(leapDate)
      }
    } else {
      let newDate = startDate.clone()
      newDate.add(1, "weeks")
      setWeekStartDate(newDate)
      if (monthStartDate.format("MM YYYY") !== newDate.format("MM YYYY")) {
        let leapDate = newDate.clone()
        leapDate.startOf("month")
        setMonthStartDate(leapDate)
      }
    }
  }

  const timeUnitSelection = () => (
    <SelectStyled
      placeholder={"Select"}
      suffixIcon={<ArrowDownSelect />}
      dropdownStyle={{borderRadius: "8px", padding: "0.5rem"}}
      style={{width: "8.125rem"}}
      onChange={(val) => toggleMonthlyView(val === "month")}
      value={monthlyView ? "month" : "week"}
    >
      <Option value={"month"}>Month view</Option>
      <Option value={"week"}>Week view</Option>
    </SelectStyled>
  )

  const taskCalendar = () => (<>
    {!plannerView && <CalendarOuterHeader>
      <div style={{display: "flex", alignItems: "center"}}>
        <ScheduleCalendarIcon style={{marginRight: "0.5rem"}}/> Calendar
        {/*<CalendarPill*/}
        {/*  style={{marginLeft: "2rem"}}*/}
        {/*  className={!monthlyView ? "selected-pill" : undefined}*/}
        {/*  onClick={() => toggleMonthlyView(false)}*/}
        {/*>Week</CalendarPill>*/}
        {/*<CalendarPill*/}
        {/*  className={monthlyView ? "selected-pill" : undefined}*/}
        {/*  onClick={() => toggleMonthlyView(true)}*/}
        {/*>Month</CalendarPill>*/}
      </div>
      <div style={{display: "flex", alignItems: "center"}}>
        <Checkbox checked={showExpenseTasks} onClick={() => {
          toggleShowExpenseTasks(!showExpenseTasks)
          setShowExpenses(!showExpenseTasks)
        }}/>
        <BudgetCostsToggle>Show expense tasks</BudgetCostsToggle>
      </div>
    </CalendarOuterHeader>}

    <CalendarWrapper className={plannerView ? "planner" : "dashboard"}>
      {/* calendar header - dashboard view */}
      {!plannerView && <CalendarHeader>
        <FlexWrapper>
          {displayedDate.format("MMM YYYY")}
          <CalendarHeaderIcon style={{marginLeft: "1.25rem"}}/>
          <DatePickerStyled
            value={displayedDate}
            allowClear={false}
            inputReadOnly={true}
            onChange={(event) => {
              setDisplayedDate(event)
              let weekStart = event!.clone()
              let monthStart = event!.clone()
              weekStart.startOf("isoWeek")
              setWeekStartDate(weekStart)
              monthStart.startOf("month")
              setMonthStartDate(monthStart)
            }}/>
          {window.innerWidth > 700 && <div style={{marginLeft: "9rem", display: "flex", columnGap: "1.25rem"}}>
              <Dropdown placement="bottomLeft" overlay={addNewItemDropdown} trigger={["click"]}>
                  <CalendarButtonWhite>+ Add an event</CalendarButtonWhite>
              </Dropdown>
          </div>}
        </FlexWrapper>

        <FlexWrapper>
          {columnsInRow !==1 && timeUnitSelection()}
          <span className={"calendar-navigation"} style={{marginLeft: "1rem"}}>
            <CalendarArrowLeft
              style={{cursor: "pointer"}}
              onClick={() => onArrowLeft()}/>
          </span>
          <span className={"calendar-navigation"}>
            <CalendarArrowRight
              style={{cursor: "pointer"}}
              onClick={() => onArrowRight()} />
          </span>
        </FlexWrapper>
      </CalendarHeader>}

      {/* Calendar header - dashboard view (mobile) */}
      {window.innerWidth < 768 && !plannerView && !showPlaceholderWidget && <CalendarHeader className={"mobile"}>
        <Dropdown placement="bottomLeft" overlay={addNewItemDropdown} trigger={["click"]}>
          <CalendarButtonWhite>+ Add an event</CalendarButtonWhite>
        </Dropdown>
        {timeUnitSelection()}
      </CalendarHeader>}

      {/* Calendar header - planner view */}
      {plannerView && <CalendarHeader style={{paddingRight: 0, paddingTop: "0.5rem", paddingBottom: "1rem"}}>
        <FlexWrapper style={{fontWeight: 600, color: "#21272B"}}>
            Calendar
        </FlexWrapper>
        <FlexWrapper style={{gap: "1rem"}}>
          <ExpensesLabelWrapper>
            <Checkbox checked={showExpenseTasks} onClick={() => {
              toggleShowExpenseTasks(!showExpenseTasks)
              setShowExpenses(!showExpenseTasks)
            }}/>
            Expenses
          </ExpensesLabelWrapper>
          <SelectGray
            suffixIcon={<ArrowDownSelect />}
            dropdownStyle={{borderRadius: "8px", padding: "0.5rem"}}
            onChange={(val) => toggleMonthlyView(val === "month")}
            value={monthlyView ? "month" : "week"}
          >
            <Option value={"month"}>Month view</Option>
            <Option value={"week"}>Week view</Option>
          </SelectGray>
          <MonthSelector>
            <PlannerArrowLeft
              style={{cursor: "pointer"}}
              onClick={() => onArrowLeft()}/>
            {displayedDate.format("MMMM YYYY")}
            <PlannerArrowRight
              style={{cursor: "pointer"}}
              onClick={() => onArrowRight()} />
          </MonthSelector>
        </FlexWrapper>
      </CalendarHeader>}

      {showPlaceholderWidget ? <WidgetPlaceholderText style={{margin: "0.25rem auto"}}>
        <CloseOutlined
          style={{position:"absolute", top: "0.5rem", right: "0.5rem", cursor:"pointer"}}
          onClick={() => putDismissOnboarding({propertyId, dismissSchedule: true})
            .then(() => dispatch(getAllProperties()))}
        />
        <span>The schedule view will highlight recommend and custom maintenance tasks, planned building projects and expenses.</span>
        <span style={{fontWeight: 600, color: "#21272B"}}>Let's start by selecting some maintenance tasks.</span>
        <ViewAllButton onClick={() => history.push("/questions?openPage=upkeep")}>
          Select tasks from maintenance checklist <ViewAllArrow/>
        </ViewAllButton>
      </WidgetPlaceholderText> : <CalendarBody>
        <DragDropContext onDragEnd={result => onDragEnd(result)}>
          <CalendarColumns columns={columnsInRow}>
          {localCalendarTasks && Object.entries(localCalendarTasks)
            .slice(0, totalColumns).map(([columnId, period], elementId) =>
              <CalendarColumn
                key={columnId}
                period={period}
                columnId={columnId}
                elementId={elementId}
                monthlyView={monthlyView}
                handleReminderAction={handleReminderAction}
                handleWorkAction={handleWorkAction}
                handleExpenseAction={handleExpenseAction}
                addNewItemDropdown={addNewItemDropdown}
                showExpenseTasks={showExpenseTasks}
              />
            )}
          </CalendarColumns>
        </DragDropContext>
      </CalendarBody>}
    </CalendarWrapper>
  </>)

  const placeholderCalendar = () => (<CalendarPlaceholder>
    <WidgetPlaceholderText>
      <CloseOutlined
        style={{position:"absolute", top: "0.5rem", right: "0.5rem", cursor:"pointer"}}
        onClick={() => putDismissOnboarding({propertyId, dismissSchedule: true})
          .then(() => dispatch(getAllProperties()))}
      />
      <span style={{fontWeight: 600, color: "#21272B"}}>Here is where you'll be able to view your calendar</span>
      <span>The schedule view will highlight recommended and custom maintenance tasks, planned building projects and expenses.</span>
      <span style={{fontWeight: 600, color: "#21272B"}}>In order to see more, let's start by selecting some maintenance tasks.</span>
      <ViewAllButton onClick={() => history.push("/questions?openPage=upkeep")}>
        Select tasks from maintenance checklist <ViewAllArrow/>
      </ViewAllButton>
    </WidgetPlaceholderText>
  </CalendarPlaceholder>)

  const loadingSpinner = () => (<LoadingWrapper>
    <Spin indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />} />
    <p style={{
      paddingTop: "1rem",
      paddingBottom: "1rem",
      paddingLeft: "1rem",
      fontWeight: 400,
      fontSize: "1rem",
      color: "#6B7185",
      textAlign: "center"
    }}>Loading calendar tasks...</p>
  </LoadingWrapper>)

  return isLoading ? loadingSpinner() : (showPlaceholderWidget && window.innerWidth > 1024 ? placeholderCalendar() : taskCalendar())
}

const CalendarWrapper = styled.div`
  background: white;
  padding: 0.5rem;
  
  &.planner {
    border: 2px solid #DAE0E6;
    border-radius: 16px;
  }
  
  &.dashboard {
    border-radius: 4px;
  }
`

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #6B7185;
  font-weight: 400;
  padding: 0.625rem 1rem;
  
  &.mobile {
    gap: 1rem;
  }
`

const CalendarOuterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #45655E;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
`

const BudgetCostsToggle = styled.span`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #21272B;
  padding-left: 0.5rem;
`

const CalendarBody = styled.div`
  display: flex;
  
  @media (max-width: 700px) {
    flex-direction: column;
  }
`

export const CalendarPill = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  min-width: 7rem;
  border-radius: 24px;
  margin: 0.5rem;
  background-color: #DAE0E6;
  color: #6B7185;
  border: none;
  font-weight: 500;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
    
  @media (max-width: 700px) {
    min-width: 3rem;
  }
  
  @media (max-width: 600px) {
    margin-right: 0;
  }
`

export const CalendarItemStyled = styled.div`
  display: flex;
  align-items: center;
  background: white!important;
  color: #666F7A;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 500;
  padding: 0.25rem;
  border-radius: 8px;
  margin-bottom: 0.313rem;
  border: 1px solid #DAE0E6;
  cursor: pointer;
  overflow: hidden;
`

const AddNewCalendarItem = styled(CalendarItemStyled)`
  column-gap: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
`

const AddNewItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.875rem;
  column-gap: 0.625rem;
  row-gap: 0.25rem;
  max-width: 20rem;
  flex-wrap: wrap;
`

export const ItemTextWrapper = styled.div`
  margin-left: 0.375rem;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
  padding-right: 0.5rem;
`

const DatePickerStyled = styled(DatePicker)`
  border: 0;
  width: 7.5rem!important;
  padding: 0!important;
  opacity: 0;
  margin: -7.5rem;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`

const AddNewItemDropdown = styled.div`
  background: white;
  padding: 1rem;
  font-size: 1.375rem;
  line-height: 1.75rem;
  color: #21272B;
  font-weight: 600;
  border: 0;
  border-radius: 0.625rem;
  box-shadow: 0px 1px 5px rgba(30, 32, 37, 0.05), 0px 10px 24px rgba(26, 28, 31, 0.1);
`

export const AddCalendarItem = styled.div`
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid #C9D0D7;
  color: #C9D0D7;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 500;
  padding: 0.188rem 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 0.313rem;
  cursor: pointer;
`

export const CalendarPlaceholder = styled.div`
  @media (max-width: 1024px) {
    background-image: none;
  }

  height: 18.75rem;
  background-image: url(/images/CalendarPlaceholderBlurred.png);
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const WidgetPlaceholderText = styled.div`
  position: relative;
  border: 2px solid #2A80E1;
  background: white;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666F7A;
  padding: 1.5rem 5.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 41rem;
  row-gap: 1rem;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  
  @media (max-width: 1024px) {
    padding: 1.5rem 1rem;
    max-width: 100%;
    width: 100%;
  }
`

const CalendarButtonWhite = styled(Button)`
  padding: 0.5rem 0.875rem;
  border: 2px solid #2A80E1;
  background: white;
  color: #2A80E1;
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 6px;
`

const CalendarColumns = styled.div.attrs((props: {columns: number}) => props)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, minmax(0, 1fr));
  grid-auto-rows: minmax(20.75rem, auto);
  gap: 0.5rem;
`

const MonthSelector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.5rem;
  height: 2rem;
  background: #F5F6FA;
  border-radius: 8px;
  
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #45655E;
`

const SelectStyled = styled(Select)`
  width: 100%;
  
  .ant-select-selector {
    height: 2rem!important;
    display: flex!important;
    padding-left: 0.5rem!important;
    align-items: center!important;
    background: #67C18B!important;
    border-radius: 6px!important;
    border: none!important;
  }
  
  .ant-select-selection-item, .ant-select-selection-placeholder {
    color: white!important;
    font-weight: 600!important;
    font-size: 0.875rem!important;
    line-height: 1.25rem!important;
  }
  
  svg { 
    margin-right: 0.5rem; 
  }
`

const SelectGray = styled(Select)`
  width: 8.125rem;
  
  .ant-select-selector {
    height: 2rem!important;
    display: flex!important;
    padding-left: 0.5rem!important;
    align-items: center!important;
    background: #F5F6FA!important;
    border-radius: 6px!important;
    border: none!important;
  }
  
  .ant-select-selection-item, .ant-select-selection-placeholder {
    color: #45655E!important;
    font-weight: 600!important;
    font-size: 0.875rem!important;
    line-height: 1.125rem!important;
  }
  
  svg { margin-right: 0.5rem; }
  svg path { stroke: #45655E; }
`

const ExpensesLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #21272B;
`

export default UpcomingTaskCalendar;