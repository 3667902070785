import { createReducer } from "typesafe-actions";
import produce from "immer";
import {
  accountNotActivatedAction, badCredentialsAction,
  clearPopupAction, facebookEmailErrorAction,
  logInCleanAction,
  logInFailureAction,
  logInInitAction,
  logInSuccessAction,
  logOutAction, TAccountNotActivatedAction, TBadCredentialsAction, TClearPopupAction,
  TLogInActions, TLoginCleanAction, TFacebookEmailErrorAction,
  TLogOutAction, resetSubscriptionLevelAction, TResetSubscriptionLevelAction,
} from "../actions/logIn";
import { TFetchingStatus } from "../../helpers";
import {
  signUpFailureAction,
  signUpInitAction, signUpStep1SuccessAction, signUpStep2SuccessAction,
  signUpSuccessAction,
  TSignUpActions,
} from "../actions/signUp";

export type TAuth = {
  fetchingStatus: TFetchingStatus;
  exp: number;
  accessToken: string;
  userName: string;
  jti: string;
  tenantId: number;
  token_use: string;
  showPopup: boolean;
  loginError: string;
  emailError: boolean;
  captchaError: boolean;
  unknownError: boolean;
  firstStepSuccess: boolean;
  registrationSuccess: boolean;
  paymentRedirectUrl: string;
  redirectToPayment: boolean;
  freeUntil: string;
  subscriptionOwner: boolean;
  subscriptionLevel: string;
};

export const initialState: TAuth = {
  exp: 0,
  token_use: "",
  accessToken: "",
  tenantId: 0,
  jti: "",
  userName: "",
  fetchingStatus: TFetchingStatus.Defined,
  showPopup: true,
  loginError: "",
  emailError: false,
  captchaError: false,
  unknownError: false,
  firstStepSuccess: false,
  registrationSuccess: false,
  paymentRedirectUrl: "",
  redirectToPayment: false,
  freeUntil: "",
  subscriptionOwner: false,
  subscriptionLevel: "",
};

export const authReducer = createReducer<
  TAuth,
  TLogInActions | TSignUpActions | TLogOutAction | TLoginCleanAction
  | TClearPopupAction | TBadCredentialsAction | TAccountNotActivatedAction | TFacebookEmailErrorAction
  | TResetSubscriptionLevelAction
>(initialState)
  .handleAction(logInInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(logInSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      const payload = action.payload;
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.token_use = payload.token_use;
      draftState.tenantId = action.payload.tenantId;
      draftState.jti = action.payload.jti;
      draftState.userName = action.payload.userName;
      draftState.accessToken = action.payload.accessToken;
      draftState.exp = action.payload.exp;
      draftState.freeUntil = action.payload.freeUntil;
      draftState.subscriptionOwner = action.payload.subscriptionOwner;
      draftState.subscriptionLevel = action.payload.subscriptionLevel;
      draftState.showPopup = true;
      draftState.loginError = "";
      draftState.redirectToPayment = false;
      draftState.firstStepSuccess = false;
      draftState.registrationSuccess = false;
      draftState.paymentRedirectUrl = "";
    })
  )
  .handleAction(logInFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  )
  .handleAction(signUpInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
      draftState.redirectToPayment = false;
      draftState.paymentRedirectUrl = "";
      draftState.subscriptionLevel = "";
    })
  )
  .handleAction(signUpSuccessAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.registrationSuccess = true;
      draftState.redirectToPayment = false;
      draftState.paymentRedirectUrl = "";
    })
  )
  .handleAction(signUpFailureAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
      draftState.emailError = action.payload.emailError;
      draftState.captchaError = action.payload.captchaError;
      draftState.unknownError = action.payload.unknownError;
    })
  )
  .handleAction(signUpStep1SuccessAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.firstStepSuccess = true;
      draftState.redirectToPayment = false;
      draftState.registrationSuccess = false;
      draftState.paymentRedirectUrl = "";
      draftState.loginError = "";
    })
  )
  .handleAction(signUpStep2SuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.paymentRedirectUrl = action.payload;
      draftState.redirectToPayment = true;
    })
  )
  .handleAction(logOutAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Defined;
      draftState.accessToken = "";
      draftState.userName = "";
      draftState.tenantId = 0;
      draftState.subscriptionLevel = "";
    })
  ).handleAction(logInCleanAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Defined;
    })
 ).handleAction(clearPopupAction, (state) =>
    produce(state, (draftState) => {
      draftState.showPopup = false;
    })
  ).handleAction(accountNotActivatedAction, (state) =>
    produce(state, (draftState) => {
      draftState.loginError = 'activation';
    })
  ).handleAction(badCredentialsAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.loginError = action.payload;
    })
  ).handleAction(facebookEmailErrorAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
      draftState.loginError = "facebook email not verified";
    })
  ).handleAction(resetSubscriptionLevelAction, (state) =>
    produce(state, (draftState) => {
      draftState.subscriptionLevel = "";
    })
  )

