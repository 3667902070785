import { DatePicker, Drawer, Form, Input, Modal, Tooltip } from "antd";
import { useIntl } from "react-intl";
import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SaveIcon } from "../../../images/SaveIcon.svg";
import { ReactComponent as MyTenantIcon } from "../../../images/MyProperty.svg";
import {getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {
  AttachButtonStyled,
  ButtonsWrapper,
  CloseButton,
  NoItemsText,
  SaveButton,
  SaveButtonWrapper
} from "../works/WorkDrawer";
import {InputsWrapper, InputWrapper, Label, LabelWrapper} from "../../screens/RegistrationPage";
import messages from "../../screens/messages";
import {addInsurance, TAddInsuranceResponse} from "../../../api-wrapper/insurance/addInsurance";
import {getAllInsurances} from "../../actions/insurances";
import moment from "moment";
import {editInsurance} from "../../../api-wrapper/insurance/editInsurance";
import {ReactComponent as AttachmentIcon} from "../../../images/Attachments.svg";
import {ReactComponent as HelpIcon} from "../../../images/Help.svg";
import _ from "lodash";
import {attachmentsInitial} from "../helper";
import {emptyGuid} from "../../screens/helpers";
import UploadFile from "../attachments/UploadFile";
import {BorderSection} from "../inventories/InventoryDrawer";
import {Guid} from "guid-typescript";
import {getInsuranceById} from "../../../api-wrapper/insurance/getInsuranceById";
import {TUpcomingTask} from "../../../api-wrapper/upcomingTasks/getUpcomingTasks";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import {ReactComponent as WorkIcon} from "../../../images/WorksNew.svg";
import {addRelation} from "../../../api-wrapper/relation/addRelation";
import AttachmentsOnDrawer from "../attachments/AttachmentsOnDrawer";
import {AutoCompleteStyled} from "../styled";
import {getEntityTypes} from "../../../api-wrapper/relation/getEntityTypes";
import {InputNumberStyled} from "../expenses/ExpenseDrawer";
import {PropertyDrawerTitle} from "../../screens/components";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  setEditingInsuranceData: (insurance: any) => void;
  insurance: { data: TAddInsuranceResponse } | null;
  refreshParent?: (arg?: Guid | null) => void;
  highlightAttachment?: Guid | null;
  reminderToRecord?: TUpcomingTask | null;
};

const InsuranceDrawer = (props: Props) => {
  const {
    isOpen,
    toggleDrawerOpen,
    setEditingInsuranceData,
    insurance,
    highlightAttachment,
    refreshParent,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [saveDisabled, setSaveDisabled] = useState(false);
  const [effectivePeriodError, setEffectivePeriodError] = useState(false);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);

  const [showFileUploadList, setShowFileUploadList] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Set<Guid>>(new Set());
  const [insuranceTypes, setInsuranceTypes] = useState<any>([]);
  const [insuranceTypeOptions, setInsuranceTypeOptions] = useState<Array<{ key: string; value: string }>>([]);
  const selectFilesRef = useRef(null);
  const fileListRef = useRef(null);

  const [insuranceDetails, setInsuranceDetails] = useState<any>({
    insuranceId: emptyGuid,
    attachments: attachmentsInitial
  });

  const refreshInsuranceTypes = () => {
    getEntityTypes(propertyId, "insurance").then((res: any) => res && setInsuranceTypes(res));
  }

  useEffect(() => {
    refreshInsuranceTypes()
  }, [propertyId, insurance]);

  useEffect(() => {
    setInsuranceTypeOptions(insuranceTypes.map((type: any) => {
      return {key: type.name, value: type.name}
    }))
  }, [insuranceTypes])

  const onTypeAutocompleteSearch = (searchText: string) => {
    setInsuranceTypeOptions(!searchText
        ? insuranceTypes.map((type: any) => { return {key: type.name, value: type.name} })
        : insuranceTypes
          .filter((type: any) => type.name.toLowerCase().includes(searchText.toLowerCase()))
          .map((type: any) => { return {key: type.name, value: type.name }}))
  }

  useEffect(() => {
    setSaveDisabled(false);
    form.resetFields();
  }, [form, propertyId, insurance, isOpen]);

  useEffect(() => {
    if (insurance && !Object.is(insurance.data.id, emptyGuid))
      getInsuranceById(propertyId, insurance.data.id).then((res) =>
        res && setInsuranceDetails({
          insuranceId: res.object.id,
          attachments: res.attachments
        })
    )
    else setInsuranceDetails({
      insuranceId: emptyGuid,
      attachments: attachmentsInitial
    })
  }, [insurance]);

  const refreshInsurance = () => {
    insurance && !Object.is(insurance.data.id, emptyGuid) &&
      getInsuranceById(propertyId, insurance.data.id).then((res) => {
        res && setInsuranceDetails({
          insuranceId: res.object.id,
          attachments: res.attachments
      })
    });
  };

  const initialValues = () => {
    if (insurance) {
      const {
        startDate,
        effectivePeriod,
        insuranceType,
        insurer,
        policyNumber,
        premium,
        insurancePremiumTax,
        administrationFee,
        totalAmountPaid,
        discountReceived,
        valueOfInsuredItems
      } = insurance.data;

      return {
        insuranceType,
        insurer,
        policyNumber,
        premium,
        insurancePremiumTax,
        administrationFee,
        totalAmountPaid,
        discountReceived,
        valueOfInsuredItems,
        startDate: startDate === null ? "" : moment(startDate),
        effectivePeriod: effectivePeriod === null ? "" : moment(effectivePeriod),
      };
    } else {
      return {};
    }
  };

  const handleManageInsurance = (e: {
    startDate: moment.Moment,
    effectivePeriod: moment.Moment,
    insuranceType: string,
    insurer: string,
    policyNumber: string,
    premium: number;
    insurancePremiumTax: number,
    administrationFee: number,
    totalAmountPaid: number,
    discountReceived: string,
    valueOfInsuredItems: number,
  }) => {
    const {
      startDate,
      effectivePeriod,
      insuranceType,
      insurer,
      premium,
      policyNumber,
      insurancePremiumTax,
      administrationFee,
      totalAmountPaid,
      discountReceived,
      valueOfInsuredItems } = e

      const startDateString = moment(startDate).format('YYYY-MM-DD').toString()
      const effectivePeriodString = moment(effectivePeriod).format('YYYY-MM-DD').toString()

    if (insurance) {
      editInsurance({
          propertyId,
          id: insurance.data.id,
          startDate: startDateString,
          effectivePeriod: effectivePeriodString,
          insuranceType,
          insurer,
          policyNumber,
          premium,
          insurancePremiumTax,
          administrationFee,
          totalAmountPaid,
          discountReceived,
          valueOfInsuredItems
      }).then(() => {
        toggleDrawerOpen(false);
        setEditingInsuranceData(null);
        form.resetFields();
        setSaveDisabled(false);
        setFormFieldsChanged(false);
        dispatch(getAllInsurances(propertyId));
        if (refreshParent) refreshParent();
      })
    } else {
      addInsurance({
          propertyId,
          startDate: startDateString,
          effectivePeriod: effectivePeriodString,
          insuranceType,
          insurer,
          premium,
          policyNumber,
          insurancePremiumTax,
          administrationFee,
          totalAmountPaid,
          discountReceived,
          valueOfInsuredItems
      }).then(() => {
        toggleDrawerOpen(false);
        setSaveDisabled(false);
        setFormFieldsChanged(false);
        setEditingInsuranceData(null);
        form.resetFields();
        dispatch(getAllInsurances(propertyId));
        if (refreshParent) refreshParent();
      })
    }
  }

  useEffect(() => {
    if (showFileUploadList && selectFilesRef && selectFilesRef.current) {
      // @ts-ignore
      selectFilesRef.current.scrollIntoView();
    } else if (!showFileUploadList && fileListRef && fileListRef.current) {
      // @ts-ignore
      fileListRef.current.scrollIntoView();
    }
  }, [showFileUploadList])

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        toggleDrawerOpen(false);
        setEditingInsuranceData(null);
        form.resetFields();
        dispatch(getAllInsurances(propertyId));
      },
    });
  }

  return (
    <>
      <AttachmentsOnDrawer
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        alreadyAttachedFiles={insuranceDetails.attachments}
        isOpen={showFileUploadList}
        toggleDrawerOpen={setShowFileUploadList}
        icon={<WorkIcon className={"works-theme-icon"}/>}
        drawerTitle={"Insurance > Add Attachment"}
      >
        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
          <AttachButtonStyled onClick={() => {
            selectedFiles.forEach(selectedFile => {
              setShowFileUploadList(false)
              insurance &&
              addRelation({
                propertyId,
                parentId: insurance.data.id,
                childId: selectedFile,
                parentType: "insurance",
                childType: "attachment",
              }).then(() => {
                refreshInsurance()
              });
            })
            setSelectedFiles(new Set());
          }}>
            Attach
          </AttachButtonStyled>
        </div>
      </AttachmentsOnDrawer>
      <Drawer
        closeIcon={false}
        width={window.innerWidth > 1024 ? "55%" : "100%"}
        visible={isOpen}
        placement="right"
        onClose={() => {
          if (formFieldsChanged) {
            confirmCloseUnsaved();
            return;
          }
          toggleDrawerOpen(false);
          setEditingInsuranceData(null);
          form.resetFields();
          dispatch(getAllInsurances(propertyId));
        }}
        headerStyle={{
          backgroundColor: "#ebf9ff",
          margin: 0,
          padding: "2rem 2rem",
        }}
        title={
          <>
            <section
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1.5rem",
              }}
            >
              <TenantIconWrapper>
                <MyTenantIcon className={"my-property-theme-icon"} />{" "}
                <span style={{ paddingRight: "0.25rem", fontSize: "0.75rem" }}>
                MY PROPERTY
              </span>
              </TenantIconWrapper>
              <ButtonsWrapper>
                <CloseButton
                  size={"large"}
                  onClick={() => {
                    form.resetFields();
                    setEditingInsuranceData(null);
                    toggleDrawerOpen(false);
                  }}
                >
                  {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
                </CloseButton>
              </ButtonsWrapper>
            </section>
            <PropertyDrawerTitle>Property Insurance</PropertyDrawerTitle>
          </>
        }
      >
        <Form
          form={form}
          name="manageInsurance"
          layout="vertical"
          size={"large"}
          onFinish={handleManageInsurance}
          initialValues={initialValues()}
          onFieldsChange={() => setFormFieldsChanged(true)}
        >
          <span
            style={{ fontSize: "1.125rem", fontWeight: 600, color: "#21272B" }}
          >
            Property Insurance
          </span>
          <section
            style={{
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "10px",
              padding: "1rem",
              marginTop: "1em",
            }}
          >
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Start date *</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"startDate"}
                  key={"startDate"}
                  rules={[{
                    required: true,
                    message: "Start date is required",
                  }]}
                >
                  <DatePicker
                    name={"startDate"}
                    format={propertyPreferences.preferredDateFormat}
                    allowClear={true}
                    inputReadOnly={true}
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    style={{
                      width: "100%",
                      border: "1px solid #DAE0E6",
                      boxSizing: "border-box",
                      borderRadius: "6px",
                    }}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Effective period *</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"effectivePeriod"}
                  key={"effectivePeriod"}
                  rules={[{
                    required: true,
                    message: "Effective period is required",
                  }, {
                    validator(_, value) {
                      if (!value || moment(form.getFieldValue("startDate")) < moment(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('End date must be after start date'));
                    },
                  }]}
                >
                  <DatePicker
                    name={"effectivePeriod"}
                    format={propertyPreferences.preferredDateFormat}
                    allowClear={true}
                    inputReadOnly={true}
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    style={{
                      width: "100%",
                      border: "1px solid #DAE0E6",
                      boxSizing: "border-box",
                      borderRadius: "6px",
                    }}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Insurance type *</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"insuranceType"}
                  key={"insuranceType"}
                  rules={[{
                    required: true,
                    message: "Insurance type is required",
                  }]}
                >
                  <AutoCompleteStyled
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    showSearch
                    allowClear={true}
                    placeholder={"Select type"}
                    bordered={false}
                    showArrow={false}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={onTypeAutocompleteSearch}
                    // @ts-ignore
                    options={insuranceTypeOptions}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Insurer *</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  key={"insurer"}
                  name={"insurer"}
                  rules={[{
                    required: true,
                    message: "Insurer is required",
                  }]}
                >
                  <InputStyled maxLength={100} />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Policy number</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"policyNumber"}
                  key={"policyNumber"}
                >
                  <InputStyled maxLength={100} />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Premium</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"premium"}
                  key={"premium"}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/,
                      message: f(messages.validationCostOfInventory),
                    },
                  ]}
                >
                  <InputNumberStyled
                    maxLength={10}
                    className={propertyPreferences.preferredCurrency}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Administration fee</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"administrationFee"}
                  key={"administrationFee"}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/,
                      message: f(messages.validationCostOfInventory),
                    },
                  ]}
                >
                  <InputNumberStyled
                    maxLength={10}
                    className={propertyPreferences.preferredCurrency}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Insurance premium tax</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"insurancePremiumTax"}
                  key={"insurancePremiumTax"}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/,
                      message: f(messages.validationCostOfInventory),
                    },
                  ]}
                >
                  <InputNumberStyled
                    maxLength={10}
                    className={propertyPreferences.preferredCurrency}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Total amount paid</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"totalAmountPaid"}
                  key={"totalAmountPaid"}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/,
                      message: f(messages.validationCostOfInventory),
                    },
                  ]}
                >
                  <InputNumberStyled
                    maxLength={10}
                    className={propertyPreferences.preferredCurrency}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Discount received</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"discountReceived"}
                  key={"discountReceived"}
                >
                  <InputStyled/>
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>Value of insured items</Label>{" "}
                </LabelWrapper>
                <Form.Item
                  name={"valueOfInsuredItems"}
                  key={"valueOfInsuredItems"}
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/,
                      message: f(messages.validationCostOfInventory),
                    },
                  ]}
                >
                  <InputNumberStyled
                    maxLength={10}
                    className={propertyPreferences.preferredCurrency}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>

            {insurance && <div
                style={{
                  paddingTop: "3rem",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1em",
                }}
            >
                <div style={{ display: "flex" }}>
                    <AttachmentIcon
                        style={{
                          marginRight: "0.75rem",
                          height: "1.5em",
                          marginTop: "0.2em",
                        }}
                    />
                    <span
                        style={{
                          fontSize: "1.125rem",
                          fontWeight: 600,
                          color: "#21272B",
                        }}
                    >
                    Attachments
                  </span>
                </div>
                <Tooltip
                    placement="top"
                    title={
                      "You can upload PDF, Image, Word, CSV and Excel files up to 20MB."
                    }
                >
                    <HelpIcon />
                </Tooltip>
            </div> }
            {_.isEmpty(insuranceDetails.attachments) && (
              <BorderSection>
                <NoItemsText>No attachments added.</NoItemsText>
              </BorderSection>
            )}
            {
              //insuranceDetails.attachments !== attachmentsInitial &&
              insurance &&
              insurance.data.id &&
              !Object.is(insuranceDetails.insuranceId, emptyGuid) && (
                <UploadFile
                  parentType={"insurance"}
                  parentId={insuranceDetails.insuranceId}
                  propertyId={propertyId}
                  uploadImmediately={true}
                  singleAttachment={false}
                  fileList={insuranceDetails.attachments}
                  refreshParent={refreshInsurance}
                  highlightAttachment={highlightAttachment}
                  setShowFileUploadList={setShowFileUploadList}
                />
              )}
          </section>

          <SaveButtonWrapper>
            <SaveButton
              style={{
                display: "flex",
                alignItems: "center",
              }}
              disabled={effectivePeriodError}
              loading={saveDisabled}
              icon={<SaveIconStyled />}
              type={"primary"}
              htmlType={"submit"}
            >
              Save details
            </SaveButton>
          </SaveButtonWrapper>
        </Form>
      </Drawer>
    </>
  );
};

const SaveIconStyled = styled(SaveIcon)`
  padding-right: 0.25rem;
  width: 22px;
  height: 22px;
`;

const TenantIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

const InputStyled = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
  color: rgb(107, 113, 133);
`;

export default InsuranceDrawer;
