import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import _ from "lodash";
import {Guid} from "guid-typescript";
import {Button, Modal, notification, Select, Tooltip} from "antd";
import {ReactComponent as PenIcon} from "../../../images/Pen.svg";
import {ReactComponent as ArrowDown} from "../../../images/ArrowDownBlue.svg";
import {ReactComponent as ArrowUp} from "../../../images/ArrowUpBlue.svg";
import {ReactComponent as Check} from "../../../images/CheckBlack.svg";
import {ReactComponent as Cross} from "../../../images/CrossBlack.svg";
import {addNewTag} from "../../../api-wrapper/tags/addNewTag";
import {setTagsForParent} from "../../../api-wrapper/tags/setTagsForParent";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getTagsSelector} from "../../selectors";
import {getAllTags} from "../../actions/tags";
import {tagsRegex} from "../../screens/helpers";
import {getAllTagsWithUsageCount} from "../../actions/tagsWithUsageCount";
import {TagStyled} from "./FilterTagsNew";
import {getAllTagsWithUsageCountByType} from "../../actions/tagsWithUsageCountByType";
import {addRelation} from "../../../api-wrapper/relation/addRelation";
import {capitalize} from "../helper";

const { Option } = Select;

type Props = {
  parentTags: any;
  parentId?: Guid | null;
  parents?: Array<any> | null;
  startsOpen?: boolean | false;
  parentType: string;
  selectedTags: any;
  toggleGlobalEditMode: (arg: boolean) => void;
  refreshParent: () => void;
  editDisabled?: boolean | false;
  disableCallback?: boolean;
}

const TagContainer = (props: Props) => {
  const {parentTags, parentId, parentType, selectedTags, toggleGlobalEditMode, refreshParent,
    parents, startsOpen, editDisabled, disableCallback} = props;

  const dispatch = useDispatch();
  const tags = useSelector(getTagsSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;

  const [databaseTags, setDatabaseTags] = useState<any>([])
  const [isOverflowing, setOverflowing] = useState(false)
  const [isCollapsed, toggleCollapsed] = useState(true)
  const [localEditMode, toggleLocalEditMode] = useState(false)
  const [updatedTags, setUpdatedTags] = useState<any>([])
  const [selectedTagIds, setSelectedTagIds] = useState<any>([])

  useEffect(() => {
    if (startsOpen) {
      setUpdatedTags([]);
      toggleLocalEditMode(true);
    }
  }, [startsOpen])

  useEffect(() => {
    localEditMode && dispatch(getAllTags())
  }, [localEditMode])

  useEffect(() => {
    let dbTags: any[] = []
    tags.content.map((tag: any) => dbTags.push(tag.tagId))
    setDatabaseTags(dbTags)
  }, [tags])

  useEffect(() => {
    if (_.isNil(selectedTags))
      return;
    let tagIds: any[] = []
    selectedTags.map((tag: any) => tagIds.push(tag.tagId))
    setSelectedTagIds(tagIds)
  }, [selectedTags])

  let tagWrapper = document.getElementById(`tags-${parentId}`);

  function isOverflown(element: any) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }

  function isTagSelected(tag: any) {
    return selectedTagIds.includes(tag)
  }

  useEffect(() => {
    tagWrapper && setOverflowing(isOverflown(tagWrapper))
  }, [tagWrapper])

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target) && !disableCallback) {
          !event.target.classList.contains("ant-select-item-option-content") && saveTags().then()
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [ref]);
  }

  function OutsideAlerter(props: any) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return <EditTagsWrapper ref={wrapperRef}>{props.children}</EditTagsWrapper>;
  }

  const addNewTags = async (tags: any) => {
    const newTagPromises = tags.map(async (tag: any) => {
      const response = await addNewTag(tag)
      return response.data?.tagId
    });
    return await Promise.all(newTagPromises);
  }

  const promptLinkPopup = async (child: any) => {
    await Modal.confirm({
      title: `Do you want to link this item with its corresponding ${child.itemType}?`,
      content:
        `There's a corresponding ${capitalize(child.name.toLowerCase())} ${child.itemType} on your account. Do you want to link this document with it?`,
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        // @ts-ignore
        return addRelation({propertyId, parentId, parentType, childType: child.itemType, childId: child.itemId})
      },
    });
  }

  const saveTags = async () => {
    let existingTags: any[] = []
    let newTags: any[] = []

    updatedTags.map((tag: string) => {
      databaseTags.includes(tag.split("/")[1]) ?
        existingTags.push(tag.split("/")[1]) : newTags.push(tag)
    })
    let newTagIds = await addNewTags(newTags)

    if (parents && parents.length > 0) {
      const setTagsPromises = parents.map(async (parent: any) => {
        // TODO maybe make it more generic, for now it assumes that parents are attachments with attachmentId as ID
        let existingTags = parent.tags.map((tag:any) => tag.tagId);

        updatedTags.map((tag: string) => {
          if (databaseTags.includes(tag.split("/")[1]) && !existingTags.includes(tag.split("/")[1]))
            existingTags.push(tag.split("/")[1])
        })
        if ([...existingTags, ...newTagIds].length > 6 && parent.tags.length === 6) {
          notification.error({
            message: "Too many tags assigned",
            description: "There is a limit of 6 tags that has been exceeded for "+parent.name,
            placement: "topRight",
          });
          return Promise.resolve(true);
        } else if ([...existingTags, ...newTagIds].length > 6 && parent.tags.length < 6) {
          const parentTagIds = parent.tags.map((tag:any) => tag.tagId);
          let allowedNewTagIds = [...existingTags, ...newTagIds].filter(tag => !parentTagIds.includes(tag)).slice(0, 6 - parent.tags.length);
          notification.warning({
            message: "Too many tags assigned",
            description: "There is a limit of 6 tags that has been exceeded for "+parent.name+". Not all tags were added.",
            placement: "topRight",
          });
          return await setTagsForParent([...parentTagIds, ...allowedNewTagIds], parent.attachmentId, parentType, propertyId);
        } else {
          return await setTagsForParent([...existingTags, ...newTagIds], parent.attachmentId, parentType, propertyId);
        }
      });
      await Promise.all(setTagsPromises);
      refreshParent();
      dispatch(getAllTags());
      dispatch(getAllTagsWithUsageCount(propertyId));
      dispatch(getAllTagsWithUsageCountByType(propertyId, parentType))
      toggleLocalEditMode(false);
      toggleGlobalEditMode(false);
    } else if (parentId) {
      setTagsForParent([...existingTags, ...newTagIds], parentId, parentType, propertyId).then((res) => {
        if (res.data) {
          res.data.map(async (option: any) => {
            return await promptLinkPopup(option)
          });
        }
        refreshParent();
        dispatch(getAllTags());
        dispatch(getAllTagsWithUsageCount(propertyId));
        dispatch(getAllTagsWithUsageCountByType(propertyId, parentType))
        toggleLocalEditMode(false);
        toggleGlobalEditMode(false);
      })
    }
  }

  function showTagError(error: string) {
    Modal.error({
      title: "Error!",
      content: error,
      okText: "OK",
      className: "form-confirm-close-modal"
    });
  }

  const validateAndSetTags = (updatedTags: any) => {
    let existingTags: any[] = []
    let newTags: any[] = []
    let sanitizedNewTags: any[] = []

    updatedTags.map((tag: string) => {
      if (databaseTags.includes(tag.split("/")[1])) {
        existingTags.push(tag)
      } else {
        if (newTags.filter((newTag: string) => newTag.toUpperCase() === tag.toUpperCase()).length) {
          showTagError("You have already entered this tag.")
        } else if (tags.content.filter((dbTag: any) => dbTag.name.toUpperCase() === tag.toUpperCase()).length) {
          let selectedTag = tags.content.filter((dbTag: any) => dbTag.name.toUpperCase() === tag.toUpperCase())[0]
          existingTags.push(`${selectedTag.name}/${selectedTag.tagId}`)
        } else {
          newTags.push(tag)
        }
      }
    })

    newTags.map((tag: string) => {
      let sanitizedTag = tag.replace(tagsRegex, '').toUpperCase()
      if (sanitizedTag.length < 64) sanitizedNewTags.push(sanitizedTag)
      else showTagError("Your tag cannot be longer than 64 characters.")
    })

    let tagsToSave = [...existingTags, ...sanitizedNewTags]

    if (tagsToSave.length <= 6) setUpdatedTags(tagsToSave)
    else showTagError("You can only add 6 tags to a single entry.")
  }

  const tagCount = (location: string) => <div className={location} style={{lineHeight: "0.5rem", fontSize: "0.75rem"}}>
    <Tooltip title={"You can only have six tags per entry"}>
      {updatedTags.length} out of 6 tags selected
    </Tooltip>
  </div>

  return (
    <>
      {!localEditMode && <TagWrapper>
        {parentTags?.map((tag: any) =>
          <TagStyled
            className={isTagSelected(tag.tagId) ? "tag-blue" : "tag-gray"}
            key={tag.tagId}>{tag.name}</TagStyled>)}
        {!editDisabled && <TagStyled
          className={"tag-white"}
          key={"edit"}
          onClick={(e) => {
            e.stopPropagation();
            let values: any[] = []
            parentTags?.map((tag: any) => values.push(`${tag.name}/${tag.tagId}`))
            setUpdatedTags(values)
            toggleLocalEditMode(true)
            toggleGlobalEditMode(true)
          }}
        >Edit tags <PenIcon /></TagStyled>}
      </TagWrapper>}

      {localEditMode && <EditTagsContainer>
        <OutsideAlerter callback={() => saveTags().then()}>
          <SelectStyled
              autoFocus={true}
              className={"tag-select"}
              mode="tags"
              style={{width: '100%', flexShrink: 2}}
              placeholder="Add tags"
              value={updatedTags}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => validateAndSetTags(e)}
          >
            {_.orderBy(tags.content, 'name', 'asc').map((tag: any) => <Option
              className={"tag-option"}
              key={`${tag.name}/${tag.tagId}`}
              value={`${tag.name}/${tag.tagId}`}>{tag.name}</Option>)}
          </SelectStyled>
          <EditControlsWrapper>
            {tagCount("mobile")}
            <div style={{display: "flex", alignItems: "center"}}>
              <Check style={{marginLeft: "0.5rem"}}
                onClick={(e) => {
                e.stopPropagation();
                saveTags().then()
              }} />
              <Cross onClick={(e) => {
                e.stopPropagation();
                setUpdatedTags(parentTags)
                toggleLocalEditMode(false)
                toggleGlobalEditMode(false)}} />
            </div>
          </EditControlsWrapper>
        </OutsideAlerter>
        {tagCount("desktop")}
      </EditTagsContainer>}
      {isOverflowing && !localEditMode && <ButtonStyled
          onClick={(e) => {
            e.stopPropagation();
            toggleCollapsed(!isCollapsed)
          }}>
        {isCollapsed ? <>Show all <ArrowDown className={"arrow-gray"} /></> : <>Hide <ArrowUp className={"arrow-gray"} /></>}
      </ButtonStyled>}
    </>
  )
}

export const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;  
  overflow: hidden;
  row-gap: 0.5rem;
`

const EditTagsContainer = styled.div`
   .mobile { display: none; }
   
   @media (max-width: 768px) {
    .desktop { display: none; }
    .mobile { display: flex; }
 }
`

const EditTagsWrapper = styled.div`
 display: flex;
 align-items: center;
 margin-bottom: 0.75rem;
 
 svg { flex-shrink: 0; }
 
 @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
    row-gap: 0.25rem;
 }
`

const EditControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
 }
`

export const SelectStyled = styled(Select)`
  .ant-select-selection-item {
    display: flex;
    height: 1.75rem;
    width: auto;
    padding: 0.375rem 0.75rem;
    color: #8E8E89;
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 400;
    border-radius: 5px;
    align-items: center;
    border: 0;
  }
`

export const TagDiv = styled.div`
  display: flex;
  height: 1.75rem;
  width: auto;
  padding: 0.375rem 0.75rem;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
  color: #8E8E89;
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 400;
  border-radius: 5px;
  align-items: center;
`

const ButtonStyled = styled(Button)`
  background: white;
  border: 0;
  display: flex;
  align-items: center;
  color: #6B7185;
  box-shadow: none;
  
  :hover, :active, :focus {
    color: #6B7185; 
  }
`

export default TagContainer