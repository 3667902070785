import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {
  getBudgetCosts,
  TGetBudgetCostsResponse,
} from "../../api-wrapper/budgetCosts/getBudgetCosts";
import {Guid} from "guid-typescript";

export const getBudgetCostsInitAction = createAction(
  "BudgetCosts/GET_BudgetCosts_INIT"
)();
export const getBudgetCostsSuccessAction = createAction(
  "BudgetCosts/GET_BudgetCosts_SUCCESS"
)<TGetBudgetCostsResponse>();
export const getBudgetCostsFailureAction = createAction(
  "BudgetCosts/GET_BudgetCosts_FAILURE"
)();

export type TGetBudgetCostsInitAction = ActionType<typeof getBudgetCostsInitAction>;
export type TGetBudgetCostsSuccessAction = ActionType<
  typeof getBudgetCostsSuccessAction
>;
export type TGetBudgetCostsFailureAction = ActionType<
  typeof getBudgetCostsFailureAction
>;

export type TGetBudgetCostsActions =
  | TGetBudgetCostsInitAction
  | TGetBudgetCostsSuccessAction
  | TGetBudgetCostsFailureAction;

export type TGetBudgetCostsThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetBudgetCostsActions>;

export const getAllBudgetCosts: TGetBudgetCostsThunkActionType = (
  propertyId: Guid
) => (dispatch: (action: Action | Promise<Action>) => any) => {
  dispatch(getBudgetCostsInitAction());
  return getBudgetCosts(propertyId)
    .then((payload) => {
      return dispatch(getBudgetCostsSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getBudgetCostsFailureAction()));
};

