import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetContactsResponse } from "../../api-wrapper/contacts/getContacts";
import {
  getContactsFailureAction,
  getContactsInitAction,
  getContactsSuccessAction,
  TGetContactsActions,
} from "../actions/contacts";
import {initialContactData} from "../components/helper";

export type TContacts = {
  fetchingStatus: TFetchingStatus;
  content: TGetContactsResponse;
};

const initialState: TContacts = {
  content: initialContactData,
  fetchingStatus: TFetchingStatus.Defined,
};

export const contactsReducer = createReducer<TContacts, TGetContactsActions>(
  initialState
)
  .handleAction(getContactsInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getContactsSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getContactsFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
