import React, {useEffect, useState} from "react";
import {CircleIcon, DrawerHeader, DrawerStyled} from "../../screens/components";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";
import {ContainerItem, ContainerItemWrapper, ContainerTitle, SpaceContainer} from "./AddSpaceOptions";
import {ReactComponent as ContentsIcon} from "../../../images/spaces/SpaceLinkContents.svg";
import {ReactComponent as WorksIcon} from "../../../images/spaces/SpaceLinkWorks.svg";
import {ReactComponent as NotesIcon} from "../../../images/spaces/SpaceLinkNotes.svg";
import {ReactComponent as CostsIcon} from "../../../images/spaces/SpaceLinkCosts.svg";
import {ReactComponent as SpacesIcon} from "../../../images/spaces/SpaceLinkSpaces.svg";
import {ReactComponent as ContactsIcon} from "../../../images/spaces/SpaceLinkContacts.svg";
import {ReactComponent as AddNewRecordIcon} from "../../../images/spaces/AddNewRecordIcon.svg";
import {addWork} from "../../../api-wrapper/works/addWork";
import {getAllWorks} from "../../actions/works";
import {addExpense} from "../../../api-wrapper/expenses/addExpense";
import {getAllExpenses} from "../../actions/expenses";
import {addInventory} from "../../../api-wrapper/inventories/addInventory";
import {getAllInventories} from "../../actions/inventories";
import {addContact} from "../../../api-wrapper/contacts/addContact";
import {getAllContacts} from "../../actions/contacts";
import {addNote} from "../../../api-wrapper/notes/addNote";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../selectors";
import {getAllAttachments} from "../../actions/attachments";
import UploadFile from "../attachments/UploadFile";
import WorkDrawer from "../works/WorkDrawer";
import ExpenseDrawer from "../expenses/ExpenseDrawer";
import InventoryDrawer from "../inventories/InventoryDrawer";
import ContactDrawer from "../contacts/ContactDrawer";
import NoteDrawer from "../notes/NoteDrawer";
import {Guid} from "guid-typescript";
import {addRelation} from "../../../api-wrapper/relation/addRelation";
import {notification} from "antd";
import EditSpaceDrawer from "./EditSpaceDrawer";
import {getAllSpaces} from "../../actions/spaces";
import AddEventDrawer from "../planner/AddEventDrawer";
import UpdateTaskDrawer from "../planner/UpdateTaskDrawer";
import {ReactComponent as MaintenanceIcon} from "../../../images/spaces/SpaceLinkMaintenances.svg";
import {getAllNotesByPropertyId} from "../../actions/notesByPropertyId";
import {processSuggestedMappings} from "./LinkDrawer";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg: boolean) => void;
  parentId: Guid | null;
  parentType: string;
  refreshParent: any;
  preSelectedType?: string | null;
  setPreSelectedType?: (arg: string | null) => void;
}

export const linkToRecord = (propertyId: Guid, parentId: Guid, parentType: string, childId: Guid, childType: string, dispatch: any) => {
  addRelation({
    propertyId,
    parentId,
    parentType,
    childType,
    childId,
  }).then((newRelation: any) => {
    if (newRelation.data && newRelation.data.suggestedMappings && newRelation.data.suggestedMappings.length > 0) {
      processSuggestedMappings(propertyId, newRelation.data.suggestedMappings, () => dispatch(getAllWorks(propertyId)))
    }
    notification.success({
      message: "Linked successfully!",
      description: `Your ${childType} has been successfully linked.`,
      placement: "topRight",
    });
    if (parentType === "space") dispatch(getAllSpaces(propertyId))
    if (parentType === "work") {dispatch(getAllWorks(propertyId)); dispatch(getAllExpenses(propertyId)); }
    if (parentType === "attachment") dispatch(getAllAttachments(propertyId));
    if (parentType === "note") dispatch(getAllNotesByPropertyId(propertyId));
  })
}

const AddNewRecordDrawer = (props: Props) => {
  const {isOpen, toggleDrawerOpen, parentId, parentType, refreshParent, preSelectedType, setPreSelectedType} = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;

  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editingWorkData, setEditingWorkData] = useState<any>(null);
  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [editingExpenseData, setEditingExpenseData] = useState<any>(null);
  const [isInventoryDrawerOpen, toggleInventoryDrawer] = useState(false);
  const [editingInventoryData, setEditingInventoryData] = useState<any>(null);
  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editingContactData, setEditingContactData] = useState<any>(null);
  const [isNoteDrawerOpen, toggleNoteDrawer] = useState(false);
  const [editingNoteData, setEditingNoteData] = useState<any>(null);
  const [isEditSpaceDrawerOpen, toggleEditSpaceDrawer] = useState(false);
  const [editSpaceId, setEditSpaceId] = useState(null);
  const [isAddEventDrawerOpen, toggleAddEventDrawer] = useState(false);
  const [isUpdateTaskDrawerOpen, toggleUpdateTaskDrawer] = useState(false);
  const [selectedTaskType, setSelectedTaskType] = useState("");
  const [taskToUpdate, setTaskToUpdate] = useState(null);

  const handleCancel = () => {
    toggleDrawerOpen(false);
  }

  const handleAddNew = (selectedType: string) => {
    switch(selectedType) {
      case "Projects":
        addWork({
          propertyId,
          name: null,
          type: null,
          completedDate: null,
          dueDate: null,
          recurring: null,
          isComplete: false,
        }).then((res) => {
          setEditingWorkData(res);
          dispatch(getAllWorks(propertyId));
          toggleWorkDrawer(true);
          toggleDrawerOpen(false);
        });
        break;
      case "Expenses":
        addExpense({
          propertyId,
          isConfirmed: true
        }).then((res) => {
          setEditingExpenseData(res);
          dispatch(getAllExpenses(propertyId));
          toggleExpenseDrawer(true);
          toggleDrawerOpen(false);
        });
        break;
      case "Contents":
        addInventory({
          propertyId,
        }).then((res) => {
          setEditingInventoryData(res);
          dispatch(getAllInventories(propertyId));
          toggleInventoryDrawer(true);
          toggleDrawerOpen(false);
        });
        break;
      case "Contacts":
        addContact({
          propertyId,
          request: {},
          contactImage: null
        }).then((res) => {
          setEditingContactData(res);
          dispatch(getAllContacts(propertyId));
          toggleContactDrawer(true);
          toggleDrawerOpen(false);
        });
        break;
      case "Notes":
        addNote({
          propertyId,
        }).then((res) => {
          setEditingNoteData({workNotes: res.data})
          dispatch(getAllNotesByPropertyId(propertyId));
          toggleNoteDrawer(true);
          toggleDrawerOpen(false);
        });
        break;
      case "Spaces":
        setEditSpaceId(null);
        dispatch(getAllSpaces(propertyId));
        toggleEditSpaceDrawer(true);
        toggleDrawerOpen(false);
        break;
      case "Maintenance":
        setTaskToUpdate(null);
        toggleAddEventDrawer(true);
        toggleDrawerOpen(false);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (preSelectedType) {
      handleAddNew(preSelectedType);
      setPreSelectedType && setPreSelectedType(null);
    }
  }, [preSelectedType])

  return <>
    <WorkDrawer
      isOpen={isWorkDrawerOpen}
      toggleDrawerOpen={toggleWorkDrawer}
      work={editingWorkData}
      // @ts-ignore
      setEditingWorkData={setEditingWorkData}
      refreshParent={refreshParent}
      parentRecordId={parentId}
      parentRecordType={parentType}
    />
    <ExpenseDrawer
      isOpen={isExpenseDrawerOpen}
      toggleDrawerOpen={toggleExpenseDrawer}
      expense={editingExpenseData}
      // @ts-ignore
      setEditingExpenseData={setEditingExpenseData}
      refreshParent={refreshParent}
      parentRecordId={parentId}
      parentRecordType={parentType}
      preventWorkCreation={true}
    />
    <InventoryDrawer
      isOpen={isInventoryDrawerOpen}
      toggleDrawerOpen={toggleInventoryDrawer}
      inventory={editingInventoryData}
      // @ts-ignore
      setEditingInventoryData={setEditingInventoryData}
      refreshParent={refreshParent}
      parentRecordId={parentId}
      parentRecordType={parentType}
      setViewWorkId={() => {}}
      toggleViewWorkDrawer={() => {}}
    />
    <ContactDrawer
      isOpen={isContactDrawerOpen}
      toggleDrawerOpen={toggleContactDrawer}
      contact={editingContactData}
      // @ts-ignore
      setEditingContactData={setEditingContactData}
      refreshParent={refreshParent}
      parentRecordId={parentId}
      parentRecordType={parentType}
    />
    {parentType !== "note" && <NoteDrawer
      data={editingNoteData}
      isOpen={isNoteDrawerOpen}
      toggleDrawerOpen={toggleNoteDrawer}
      refreshParent={refreshParent}
      parentRecordId={parentId}
      parentRecordType={parentType}
      toggleTaskDrawer={toggleUpdateTaskDrawer}
      setEditTaskData={setTaskToUpdate}
      setPresetLinkType={() => {}}
      toggleLinkDrawerOpen={() => {}}
      setViewWorkId={() => {}}
      toggleViewWorkDrawer={() => {}}
      toggleExpenseDrawer={toggleExpenseDrawer}
      setEditExpenseData={setEditingExpenseData}
      toggleInventoryDrawer={toggleInventoryDrawer}
      setEditInventoryData={setEditingInventoryData}
      toggleContactDrawer={toggleContactDrawer}
      setEditContactData={setEditingContactData}
      setEditSpaceId={setEditSpaceId}
      toggleSpaceDrawer={toggleEditSpaceDrawer}
      toggleViewFileDrawer={() => {}}
      setAttachmentId={() => {}}
    />}
    <EditSpaceDrawer
      spaceId={editSpaceId}
      isOpen={isEditSpaceDrawerOpen}
      toggleDrawerOpen={toggleEditSpaceDrawer}
      setEditingSpaceId={setEditSpaceId}
      refreshParent={refreshParent}
      parentRecordId={parentId}
      parentRecordType={parentType}
    />
    <AddEventDrawer
      isOpen={isAddEventDrawerOpen}
      toggleDrawer={toggleAddEventDrawer}
      setSelectedType={setSelectedTaskType}
      toggleTaskDrawer={toggleUpdateTaskDrawer}
      setEditingWorkData={setEditingWorkData}
      toggleWorkDrawer={toggleWorkDrawer}
    />
    <UpdateTaskDrawer
      task={taskToUpdate}
      setEditingTaskData={setTaskToUpdate}
      isOpen={isUpdateTaskDrawerOpen}
      toggleDrawer={toggleUpdateTaskDrawer}
      taskType={selectedTaskType}
      setTaskType={setSelectedTaskType}
      refreshParent={refreshParent}
      parentRecordId={parentId}
      parentRecordType={parentType}
    />
    <DrawerStyled
      push={{distance: "32px"}}
      closeIcon={false}
      visible={isOpen}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "100%" : "85%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      onClose={() => handleCancel()}
      title={<DrawerHeader>
        Add new record
        <CircleIcon onClick={() => handleCancel()}><CloseIcon/></CircleIcon>
      </DrawerHeader>}
    >
      <SpaceContainer className={"open"}>
        <ContainerTitle><AddNewRecordIcon />Records</ContainerTitle>
        <ContainerItemWrapper className={"record-drawer"}>
          <ContainerItem
            onClick={() => handleAddNew("Contents")}>
            <ContentsIcon style={{flexShrink: 0}} /> Contents
          </ContainerItem>
          {parentType !== "attachment" && <UploadFile
            fileList={[]}
            refreshParent={() => {
              dispatch(getAllAttachments(propertyId));
              toggleDrawerOpen(false);
              refreshParent();
            }}
            singleAttachment={false}
            parentId={parentId}
            parentType={parentType}
            propertyId={propertyId}
            uploadImmediately={false}
            isGlobalUpload={true}
            uploadOrigin={"add-record"}
          />}
          <ContainerItem
            onClick={() => handleAddNew("Projects")}>
            <WorksIcon style={{flexShrink: 0}} /> Projects
          </ContainerItem>
          <ContainerItem
            onClick={() => handleAddNew("Notes")}>
            <NotesIcon style={{flexShrink: 0}} /> Notes
          </ContainerItem>
          <ContainerItem
            onClick={() => handleAddNew("Expenses")}>
            <CostsIcon style={{flexShrink: 0}} /> Expenses
          </ContainerItem>
          <ContainerItem
            onClick={() => handleAddNew("Contacts")}>
            <ContactsIcon style={{flexShrink: 0}} /> Contacts
          </ContainerItem>
          {parentType !== "space" && <ContainerItem
              className={parentType === "upkeep" ? "full-width" : ""}
              onClick={() => handleAddNew("Spaces")}>
              <SpacesIcon style={{flexShrink: 0}}/> Spaces
          </ContainerItem>}
          {parentType !== "upkeep" && <ContainerItem
            className={(parentType === "space" || parentType === "attachment") ? "full-width" : ""}
            onClick={() => handleAddNew("Maintenance")}>
            <MaintenanceIcon style={{flexShrink: 0}}/> Maintenance
          </ContainerItem>}
        </ContainerItemWrapper>
      </SpaceContainer>
    </DrawerStyled>
  </>
}

export default AddNewRecordDrawer