import React, {useEffect, useState} from "react";
import {Form} from "antd";
import KeytekSiteVisitStep from "../components/keytekOnboarding/KeytekSiteVisitStep";
import KeytekSummaryStep from "../components/keytekOnboarding/KeytekSummaryStep";

type Props = {
  isMapLoaded: boolean;
}

const KeytekOnboarding = (props: Props) => {
  const { isMapLoaded } = props;
  const [keytekForm] = Form.useForm();

  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState<any>(null);
  const [workData, setWorkData] = useState<any>(null);
  const [location, setLocation]: any = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  })

  const isMobile = windowWidth <= 768

  const currentStep = (step: number) => {
    if (step === 1 || step === 2) return <KeytekSiteVisitStep
      step={step}
      setStep={setStep}
      setUserData={setUserData}
      setWorkData={setWorkData}
      form={keytekForm}
      isMobile={isMobile}
      location={location}
      setLocation={setLocation}
      uploadedFiles={uploadedFiles}
      setUploadedFiles={setUploadedFiles}
    />
    if (step === 3) return <KeytekSummaryStep
      setStep={setStep}
      isMobile={isMobile}
      userData={userData}
      workData={workData}
      uploadedFiles={uploadedFiles}
    />
    else return <></>
  }

  if (!isMapLoaded)
    return <></>
  else
    return currentStep(step)
}

export default KeytekOnboarding