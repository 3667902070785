import React, {useEffect, useState} from "react"
import {Button, Form, Input} from "antd";
import {Label} from "../../screens/RegistrationPage";
import {safetyRegex} from "../../screens/helpers";
import TextArea from "antd/es/input/TextArea";
import {useDispatch, useSelector} from "react-redux";
import {getUserProfileSelector} from "../../selectors";
import {getCurrentUserProfile} from "../../actions/getUserProfile";
import {EmailModal} from "../SendViaEmail";
import {reportProblem} from "../../../api-wrapper/email/reportProblem";

type Props = {
  isModalOpen: boolean,
  toggleModal: (arg: boolean) => void
}

const ReportProblem = (props: Props) => {
  const { isModalOpen, toggleModal } = props
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const userProfile = useSelector(getUserProfileSelector)

  const [message, setMessage] = useState("")
  const [subject, setSubject] = useState("")
  const [name, setName] = useState(userProfile.content.name);

  const clearForm = () => {
    setMessage("")
    setSubject("")
    setName(userProfile.content.name);
    toggleModal(false);
  }

  const handleOk = () => {
    let pageURL = window.location.href
    let sanitizedMessage = message.replace(safetyRegex, '')
      reportProblem({
        name: name ? name : "Name not provided",
        email: userProfile.content.email,
        subject,
        body: sanitizedMessage,
        pageURL
      }).then(() => {
        clearForm()
      })
  };

  const handleCancel = () => {
    clearForm()
  };

  return (
      <EmailModal
        width={800}
        style={{top: 20}}
        title={"Ask a question"}
        visible={isModalOpen}
        mask={false}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<Button style={{marginLeft: '-3.8rem'}} type={'ghost'}>Cancel</Button>}
        footer={null}>
        <Form
          form={form}
          name="sendEmail"
          layout="vertical"
          size={"large"}
        >
        <section style={{
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "10px",
            padding: "1rem",
            marginTop: "0.5rem",
          }}
        >
          <Label>Your name:</Label>
          <Form.Item>
            <Input
              style={{borderRadius: '5px'}}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>
          <Label>Your email:</Label>
          <Form.Item>
            <Input
              disabled={true}
              style={{borderRadius: '5px'}}
              value={userProfile.content.email}
            />
          </Form.Item>
          <Label>Subject:</Label>
          <Form.Item>
            <Input
              style={{borderRadius: '5px'}}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </Form.Item>
          <Label>Message:</Label>
          <Form.Item>
            <TextArea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={5}
              placeholder={"Input text..."}
              maxLength={1000}
              style={{
                width: "100%",
                border: "1px solid #DAE0E6",
                boxSizing: "border-box",
                borderRadius: "6px",
                color: "rgb(107,113,133)",
              }}
            />
          </Form.Item>
          </section>
          <div style={{textAlign: 'center', marginTop: "1.2rem"}}>
            <Button
              style={{borderRadius: '6px'}}
              type={"primary"}
              onClick={() => handleOk()}
              disabled={message.length === 0}
            >Send</Button>
          </div>
        </Form>
      </EmailModal>
  )
}

export default ReportProblem