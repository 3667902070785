import {
  Form,
  Input,
  Modal,
} from "antd";
import { useIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { ReactComponent as ContactsCompanyIcon } from "../../../images/ContactsCompanyIcon.svg";
import { ReactComponent as ContactsEmailIcon } from "../../../images/ContactsEmailIcon.svg";
import { ReactComponent as ContactsPhoneIcon } from "../../../images/ContactsPhoneIcon.svg";
import { ReactComponent as ContactsWebsiteIcon } from "../../../images/ContactsWebsiteIcon.svg";
import messages from "../../screens/messages";
import {
  InputsWrapper,
  InputWrapper,
  Label,
} from "../../screens/RegistrationPage";
import { removeContact } from "../../../api-wrapper/contacts/removeContact";
import { getAllContacts } from "../../actions/contacts";
import { useDispatch, useSelector } from "react-redux";
import {
  addContact,
  TAddContactResponse,
} from "../../../api-wrapper/contacts/addContact";
import {editContact, updateContactWithImage} from "../../../api-wrapper/contacts/editContact";
import {
  getContactsSelector,
  getPropertyIdSelector
} from "../../selectors";
import {emailRegex, emptyGuid} from "../../screens/helpers";
import { getContactById } from "../../../api-wrapper/contacts/getContactById";
import {Guid} from "guid-typescript";
import {getEntityTypes, TGetEntityTypesResponse} from "../../../api-wrapper/relation/getEntityTypes";
import {AutoCompleteStyled} from "../styled";
import {addRelation} from "../../../api-wrapper/relation/addRelation";
import {linkToRecord} from "../spaces/AddNewRecordDrawer";
import {
  CheckboxStyled,
  DrawerFooter,
  EditDrawerHeader,
  EditDrawerSaveButton,
  EditDrawerStyled,
  PhotoNameFieldsWrapper
} from "../../screens/components";
import {ReactComponent as DrawerCloseIcon} from "../../../images/DrawerCloseIcon.svg";
import UploadImage from "../spaces/UploadImage";
import TagContainer from "../tags/TagContainer";
import {ReactComponent as Checkmark} from "../../../images/planner/Checkmark.svg";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  contact: { data: TAddContactResponse } | null;
  setEditingContactData: (arg01: TAddContactResponse | { accessToken: any } | null) => void;
  refreshParent?: (arg?: Guid | null) => void;
  parentType?: string;
  parentRecordId?: Guid | null;
  parentRecordType?: string;
};

const ContactDrawer = (props: Props) => {
  const {isOpen, toggleDrawerOpen, contact, setEditingContactData, refreshParent, parentType, parentRecordId, parentRecordType} = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const contacts = useSelector(getContactsSelector)

  const [entityTypes, setEntityTypes] = useState<TGetEntityTypesResponse>([]);
  const [categories, setCategories] = useState<Array<{ key: string; value: string }>>([]);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("")
  const [contactTags, setContactTags] = useState<any>([]);

  const [contactImageLink, setContactImageLink] = useState("");
  const [contactImageName, setContactImageName] = useState("");
  const [contactImage, setContactImage] = useState<any>(null);
  const [previewLink, setPreviewLink] = useState("");
  const [removeImage, toggleRemoveImage] = useState(false);
  const [editImage, toggleEditImage] = useState(false);

  const [emergency, setEmergency] = useState(false);

  const [isCompanyFieldShown, toggleCompanyField] = useState(false);
  const [isEmailFieldShown, toggleEmailField] = useState(false);
  const [isPhoneFieldShown, togglePhoneField] = useState(false);
  const [isWebsiteFieldShown, toggleWebsiteField] = useState(false);

  const checkForContactName = (name: string, surname: string) => {
    if (contact) {
      const currentContactId = contact.data.contactId
      return contacts.content.filter(c =>
        c.name && c.name.toLowerCase() === name.toLowerCase()
        && (c.surname ? c.surname.toLowerCase() === surname.toLowerCase() : true)
        && c.contactId !== currentContactId)
    }
    else {
      return contacts.content.filter(c =>
        c.name && c.name.toLowerCase() === name.toLowerCase()
        && (c.surname ? c.surname.toLowerCase() === surname.toLowerCase() : true))
    }
  }

  const checkForPayeeName = (name: string, surname: string) => {
    return contacts.content.filter(c =>
      c.name && c.name.toLowerCase() === name.toLowerCase()
      && (c.surname ? c.surname.toLowerCase() === surname.toLowerCase() : true)
      && c.contactType === "Payee")
  }

  const checkForPayerName = (name: string, surname: string) => {
    return contacts.content.filter(c =>
      c.name && c.name.toLowerCase() === name.toLowerCase()
      && (c.surname ? c.surname.toLowerCase() === surname.toLowerCase() : true)
      && c.contactType === "Payer")
  }

  function contactExists(name: string, surname: string) {
    Modal.error({
      title: `A contact with name ${name}${surname ? ` ${surname}` : ""} already exists!`,
      content:
        "Please pick another name for the contact you're creating.",
      okText: "OK",
      className: "form-confirm-close-modal",
    });
  }
  
  const performCleanup = (res?: any, payerId?: any) => {
    if (parentRecordId && parentRecordType)
      linkToRecord(propertyId, parentRecordId, parentRecordType, res.contactId, "contact", dispatch);

    if (refreshParent && !payerId) refreshParent();
    if (refreshParent && payerId) refreshParent(payerId);

    refreshEntityTypes();
    setSaveDisabled(false);
    form.resetFields();
    setFormFieldsChanged(false);

    toggleDrawerOpen(false);
    setEditingContactData(null);
    dispatch(getAllContacts(propertyId));
  }

  const handleManageContact = (e: {
    name: string;
    surname: string;
    category: string;
    company: string;
    email: string;
    website: string;
    notes: string;
  }) => {
    const { name, surname, category, company, email, website, notes } = e;

    // a contact with such name exists, and it's not a payee/payer
    if (checkForContactName(name, surname).length > 0
        && checkForPayeeName(name, surname).length === 0 && checkForPayerName(name, surname).length === 0) {
      contactExists(name, surname)
      return;
    }

    let request = {
      propertyId,
      name,
      surname,
      contactType: category ?? "",
      company,
      email: email ? email.trim() : null,
      phone: phoneNumber,
      website,
      notes,
      emergency,
      tags: [],
    }

    const editContactWithProvidedId = (providedId: Guid) => {
      if (contactImage || removeImage) {
        updateContactWithImage({
          request: {...request, contactId: providedId},
          contactImage
        }).then((res: any) => performCleanup(res, providedId));
        return;
      } else {
        editContact({
          ...request,
          contactId: providedId,
        }).then((res: any) => performCleanup(res, providedId));
        return;
      }
    }

    // a contact with such name exists, but it's a payee
    if (checkForContactName(name, surname).length > 0 && checkForPayeeName(name, surname).length > 0) {
      const payeeId = checkForPayeeName(name, surname)[0]?.contactId;
      editContactWithProvidedId(payeeId);
      // idk if this is needed, we'll check
      // return;
    }

    // a contact with such name exists, but it's a payer
    if (checkForContactName(name, surname).length > 0 && checkForPayerName(name, surname).length > 0) {
      const payerId = checkForPayerName(name, surname)[0]?.contactId
      editContactWithProvidedId(payerId);
    }

    // a contact with such name doesn't exist
    setSaveDisabled(true);
    if (contact) {
      editContactWithProvidedId(contact.data.contactId);
    } else {
      addContact({
        propertyId,
        request,
        contactImage
      }).then((res) => {
        setSaveDisabled(false);
        form.resetFields();
        setEditingContactData(res);
        if (refreshParent)
          res.data && res.data.contactId
            ? refreshParent(res.data.contactId)
            : refreshParent();
        dispatch(getAllContacts(propertyId));
        setFormFieldsChanged(false);
        refreshEntityTypes();
        if (refreshParent) {
          setEditingContactData(null);
          toggleDrawerOpen(false);
        }
      });
    }
  };

  const initialValues = () => {
    if (contact) {
      const { name, surname, company, email, phone, website, notes, emergency } = contact.data;
      let {contactType} = contact.data;
      if (contactType === "Payee" || contactType === "Payer") {
        contactType = "";
      }
      return { name, surname, category: contactType, company, email, phone, website, notes, emergency };
    } else {
      return {};
    }
  };

  const refreshEntityTypes = () => {
    getEntityTypes(propertyId, "contact").then(res =>
      res && setEntityTypes(res.sort((a,b) => a.name.localeCompare(b.name))
        .filter(type => type.name !== "Payee" && type.name !== "Payer")));
  }

  const onCategoryAutocompleteSearch = (searchText: string) => {
    setCategories(
      !searchText
        ? entityTypes.map((entityType: any) => {
          return {
            key: entityType.name,
            value: entityType.name,
          };
        })
        : entityTypes
          .filter((entityType) =>
            entityType.name.toLowerCase().includes(searchText.toLowerCase())
          )
          .map((entityType) => {
            return {
              key: entityType.name,
              value: entityType.name,
            }
          })
    );
  };

  useEffect(() => {
    setCategories(entityTypes.map((entityType: any) => {
      return {
        key: entityType.name,
        value: entityType.name,
      };
    }));
  }, [entityTypes])

  useEffect(() => {
    if (contact) {
      setContactImageLink(contact.data.imageThumbnail);
      toggleCompanyField(!_.isEmpty(contact.data.company));
      toggleEmailField(!_.isEmpty(contact.data.email));
      togglePhoneField(!_.isEmpty(contact.data.phone));
      toggleWebsiteField(!_.isEmpty(contact.data.website));
      setEmergency(contact.data.emergency);
    } else {
      setContactImageLink("");
      toggleCompanyField(false);
      toggleEmailField(false);
      togglePhoneField(false);
      toggleWebsiteField(false);
      setEmergency(false);
    }
    setContactImage(null);
    setContactImageName("")
    form.resetFields();
    setFormFieldsChanged(false);
    isOpen && refreshEntityTypes();
  }, [contact, isOpen]);

  useEffect(() => {
    if (!isOpen)
      return;

    refreshContactTags();
  }, [isOpen, contacts]);

  const refreshContactTags = () => {
    if (!contact)
      return;
    const refreshedContact = contacts.content.filter(c => c.contactId === contact.data.contactId)[0];
    refreshedContact && setContactTags(refreshedContact.tags);
  };

  const refreshContactFromParent = (recordId?: Guid | null) => {
    recordId && contact && contact.data.contactId && parentType &&
    addRelation({
      propertyId,
      parentId: recordId,
      childId: contact.data.contactId,
      parentType,
      childType: "contact",
    }).then(() => {
      refreshContactFromParent();
      setFormFieldsChanged(false);
    });

    contact && !Object.is(contact.data.contactId, emptyGuid)  &&
    getContactById(propertyId, contact.data.contactId).then((res) => {
      // @ts-ignore
      setContactDetails({ ...res });
    });
  };

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        toggleDrawerOpen(false);
        setEditingContactData(null);
        form.resetFields();
        if (contact && _.isEmpty(contact.data.name)) {
          contact && removeContact(contact.data.propertyId, contact.data.contactId)
            .then(() => contact && dispatch(getAllContacts(contact.data.propertyId)))
        } else {
          contact && dispatch(getAllContacts(propertyId));
        }
      },
    });
  }

  const handleCancel = () => {
    if (formFieldsChanged) {
      confirmCloseUnsaved();
      return;
    }
    if (contact && _.isEmpty(contact.data.name)) {
      contact && removeContact(contact.data.propertyId, contact.data.contactId)
        .then(() => contact && dispatch(getAllContacts(contact.data.propertyId)))
    } else {
      contact && dispatch(getAllContacts(propertyId));
    }
    toggleDrawerOpen(false);
    setEditingContactData(null);
    form.resetFields();
  }

  return (
    <EditDrawerStyled
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "100%" : "85%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      visible={isOpen}
      onClose={() => handleCancel()}
      title={<EditDrawerHeader>
        Contact
        <DrawerCloseIcon style={{cursor: "pointer"}} onClick={() => handleCancel()} />
      </EditDrawerHeader>}
    >
      <Form
        form={form}
        name="manageContact"
        layout="vertical"
        size={"large"}
        onFinish={handleManageContact}
        initialValues={initialValues()}
        onFieldsChange={() => setFormFieldsChanged(true)}
      >
        <PhotoNameFieldsWrapper>
          <div>
            <UploadImage
              image={contactImage}
              imageName={contactImageName}
              imageLink={contactImageLink}
              setImageLink={setContactImageLink}
              previewLink={previewLink}
              setPreviewLink={setPreviewLink}
              setImage={setContactImage}
              setImageName={setContactImageName}
              editImage={editImage}
              toggleEditImage={toggleEditImage}
              removeImage={removeImage}
              toggleRemoveImage={toggleRemoveImage}
              uploadType={"photo"}
            />
          </div>
          <div className={"field-wrapper"}>
            <Label>Contact name</Label>
            <div className={"full-name-wrapper"}>
              <Form.Item key={"name"} name={"name"} rules={[{required: true, message: "Please enter contact name"}]}>
                <Input style={{borderRadius: "6px", width: "100%"}} placeholder={"Name"} />
              </Form.Item>
              <Form.Item key={"surname"} name={"surname"}>
                <Input style={{borderRadius: "6px", width: "100%"}} placeholder={"Surname"} />
              </Form.Item>
            </div>
          </div>
        </PhotoNameFieldsWrapper>
        <InputsWrapper>
          <InputWrapper style={{ width: "100%", marginRight: 0 }}>
            <Label>Category</Label>
            <Form.Item key={"category"} name={"category"}>
              <AutoCompleteStyled
                // @ts-ignore
                getPopupContainer={trigger => trigger.parentElement}
                showSearch
                allowClear={true}
                placeholder={"Select or create category"}
                bordered={false}
                showArrow={false}
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={onCategoryAutocompleteSearch}
                // @ts-ignore
                options={categories}
              />
            </Form.Item>
          </InputWrapper>
        </InputsWrapper>

        {(!isPhoneFieldShown || !isEmailFieldShown || !isWebsiteFieldShown || !isCompanyFieldShown) && <DetailLabels>
          {!isPhoneFieldShown && <div className={"detail-label"} onClick={() => togglePhoneField(true)}>
              <ContactsPhoneIcon/> Phone number
          </div>}
          {!isEmailFieldShown && <div className={"detail-label"} onClick={() => toggleEmailField(true)}>
              <ContactsEmailIcon/> Email
          </div>}
          {!isWebsiteFieldShown && <div className={"detail-label"} onClick={() => toggleWebsiteField(true)}>
              <ContactsWebsiteIcon/> Website
          </div>}
          {!isCompanyFieldShown && <div className={"detail-label"} onClick={() => toggleCompanyField(true)}>
              <ContactsCompanyIcon/> Company
          </div>}
        </DetailLabels>}

        {(isPhoneFieldShown || isEmailFieldShown) && <InputsWrapper>
          {isPhoneFieldShown && <InputWrapper>
            <Label>Phone</Label>
            <Form.Item key={"phone"} name={"phone"}>
              <StyledPhoneInput
                defaultCountry="GB"
                value={phoneNumber}
                onChange={setPhoneNumber}/>
            </Form.Item>
          </InputWrapper>}
          {isEmailFieldShown && <InputWrapper>
            <Label>Email</Label>
            <Form.Item key={"email"} name={"email"}
                       rules={[{pattern: emailRegex, message: f(messages.validationEmail)}]}>
              <InputStyled maxLength={100}/>
            </Form.Item>
          </InputWrapper>}
        </InputsWrapper>}

        {(isWebsiteFieldShown || isCompanyFieldShown) && <InputsWrapper>
          {isWebsiteFieldShown && <InputWrapper>
            <Label>Website</Label>
            <Form.Item key={"website"} name={"website"}>
              <InputStyled maxLength={50}/>
            </Form.Item>
          </InputWrapper>}
          {isCompanyFieldShown && <InputWrapper>
            <Label>Company</Label>
            <Form.Item key={"company"} name={"company"}>
              <InputStyled maxLength={100}/>
            </Form.Item>
          </InputWrapper>}
        </InputsWrapper>}

        <InputsWrapper style={{display:"flex", columnGap:"1rem", justifyContent:"flex-start"}}>
            <Label>Emergency contact</Label>
            <CheckboxStyled className={emergency ? "checked small" : "small"} onClick={() => setEmergency(!emergency)}><Checkmark/></CheckboxStyled>
        </InputsWrapper>

        <InputsWrapper style={{display:"flex", flexDirection:"column"}}>
              <Label>Notes</Label>
              <Form.Item key={"notes"} name={"notes"}  style={{width:"100%"}}>
                  <InputStyled maxLength={50}/>
              </Form.Item>
        </InputsWrapper>

        {contact && contact.data && <Label>Tags</Label>}
        {contact && contact.data && <TagContainer
          parentTags={contactTags}
          parentId={contact.data.contactId}
          parentType={"contact"}
          selectedTags={[]}
          toggleGlobalEditMode={() => {}}
          refreshParent={() => dispatch(getAllContacts(propertyId))}
        />}

        <DrawerFooter className={"fake-footer"}>
          <EditDrawerSaveButton disabled={saveDisabled} htmlType={"submit"} loading={saveDisabled}>
            Save
          </EditDrawerSaveButton>
        </DrawerFooter>
      </Form>
    </EditDrawerStyled>)
};

const DetailLabels = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  cursor: pointer;
  padding-bottom: 1.5rem;
  
  .detail-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #009966;
  }
`

const InputStyled = styled(Input)`
  border: 1px solid #dae0e6;
  box-sizing: border-box;
  border-radius: 6px;
  color: rgb(107, 113, 133);
`;

const StyledPhoneInput = styled(PhoneInput)`
  .PhoneInputInput {
  border: 1px solid #dae0e6;
  border-radius: 6px;
  height: 2.55rem;
  padding: 0.4rem 0.7rem;
  font-size: 1rem;
  width: calc(100% - 2rem);
  }
  
  .PhoneInputInput:hover {
  border: 1px solid #1890ff;
  border-radius: 6px;
  }
  
  .PhoneInputInput:active, .PhoneInputInput:focus, .PhoneInputInput:focus-visible {
  border: 1px solid #1890ff;
  border-radius: 6px;
  outline: 2px solid #d1e9ff;
  }
`

export default ContactDrawer;
