import {apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";

export type TInviteUserRequest = {
  name: string;
  email: string;
}

export const inviteUser = (payload: TInviteUserRequest & { subscriptionId: number, ownerName: string }) => {
  const { subscriptionId, ownerName } = payload;
  let url = createUrl(`${EnvConfig.API_URL}/api/user/invite/${subscriptionId}/ownerName/${ownerName}`);

  return apiPost<{}, TInviteUserRequest>(url, payload);
};
