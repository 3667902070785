import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TForwardContactRequest} from "./forwardContact";

export const forwardContactPreview = (payload: TForwardContactRequest) => {
  const {contactId} = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/forwardContact/${contactId}/preview`
  );

  return apiPost<any, TForwardContactRequest>(
    url, payload
  );
};
