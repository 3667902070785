import {Guid} from "guid-typescript";
import {TGetNotesByPropertyIdResponse} from "../notes/getNotesByPropertyId";
import {TGetAllAttachmentsResponse} from "../attachments/getAllAttachments";
import {TGetWorksResponse} from "../works/getWorks";
import {TGetExpensesResponse} from "../expenses/getExpenses";
import {TGetInventoriesResponse} from "../inventories/getInventories";
import {TGetContactsResponse} from "../contacts/getContacts";
import {apiGet, createUrl} from "../apiCall";
import EnvConfig from "../../config";

export type TSpace = {
  spaceId: Guid;
  subscriptionId: number;
  propertyId: Guid;
  name: string;
  category: string;
  floor: string;
  notes: string;
  spaceType: string;
  imageThumbnail: string;
  createdDate: string;
  dimension1: string;
  dimension2: string;
  paint: string;

  spaceNotes: TGetNotesByPropertyIdResponse;
  attachments: TGetAllAttachmentsResponse;
  works: TGetWorksResponse;
  expenses: TGetExpensesResponse;
  inventories: TGetInventoriesResponse;
  contacts: TGetContactsResponse;
};

export type TGetSpacesResponse = Array<TSpace>

export const getSpaces = (propertyId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/spaces`);

  return apiGet<TGetSpacesResponse | []>(url);
};