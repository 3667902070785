import {apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

type TSubscribeToMaintenanceRequest = {
  propertyId: Guid;
  maintenanceId: Guid;
  startDate: string;
}

export const subscribeToMaintenance = (payload: TSubscribeToMaintenanceRequest) => {
  const { propertyId, maintenanceId, startDate } = payload;

  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/alerts/trigger/${maintenanceId}/subscribe?startDate=${startDate}`
  );

  return apiPost(url);
};