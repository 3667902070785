import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TRecipient} from "./referFriends";
import {Guid} from "guid-typescript";

export type TForwardWorkRequest = {
  customMessage: string;
  ccSender: boolean;
  recipients: Array<TRecipient>;
  workId: Guid;
  propertyId: Guid;

  isQuote: boolean;
  isWorkNameEnabled: boolean;
  isPropertyAddressEnabled: boolean;
  isStartDateEnabled: boolean;
  isWorkNotesEnabled: boolean;
  attachments: Array<Guid>;
};

export const forwardWork = (payload: TForwardWorkRequest) => {
  const {workId} = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/forwardWork/${workId}/send`
  );

  return apiPost<any, TForwardWorkRequest>(
    url, payload
  );
};
