import {apiDelete, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export const removeRelation = (propertyId: Guid, parentId: Guid, parentType: string, childId: Guid, childType: string) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/entity/relation/${parentId}/${parentType}/${childId}/${childType}`
  );

  return apiDelete(url);
};
