import React, {useState} from "react";
import {ReactComponent as ReferFriendPopupIcon} from "../../../images/ReferFriendPopupIcon.svg";
import {clearPopup} from "../../actions/logIn";
import {useDispatch} from "react-redux";
import styled from "styled-components";
import {Alert, Button} from "antd";
import SendViaEmail from "../SendViaEmail";

const ReferFriendPopup = () => {
  const dispatch = useDispatch();
  const [emailModal, toggleEmailModal] = useState(false);

  return <>
    <SendViaEmail
      modalTitle={"Tell a friend"}
      emailType={"referFriends"}
      parentData={null}
      isModalOpen={emailModal}
      toggleModal={toggleEmailModal} />

    <TellAFriendAlert
      message={<div style={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
        <ReferFriendPopupIcon />
        Refer a friend
      </div>}
      afterClose={() => {
        dispatch(clearPopup())
      }}
      description={<>
        <p><b>Tell a friend about Livlet</b></p>
        <p style={{fontSize: "0.875rem"}}>
          We hope you're enjoying Livlet. Please feel free to share us with your friends and family! We would greatly appreciate it.
        </p>
        <TellAFriendButton onClick={() => {
          toggleEmailModal(true)
        }}>Tell a friend</TellAFriendButton>
      </>}
      type="info"
      closable
    />
  </>
}

export const TellAFriendAlert = styled(Alert)`
  margin: 1rem;
  margin-bottom: 2rem;
  padding: 1rem!important;
  border: none;
  border-radius: 10px;
  background-color: #2a80e1!important;
 
  .ant-alert-message {
    font-weight: 600;
    font-size: 0.8rem!important;
    color: #fff!important;
    margin-bottom: 1.6rem;
    text-transform: uppercase;
  }
  
  .ant-alert-description {
    color: #fff!important;
    font-size: 1rem!important;
    line-height: 1.625rem!important;
  }
  
  svg {
    color: #fff!important;
  }
  
  a {
    color: #fff;
    text-decoration: underline;
  }
`;

export const TellAFriendButton = styled(Button)`
  color: white;
  background-color: #2a80e1;
  text-align: center;
  display: block;
  border: 2px solid #5fa0e9;
  border-radius: 6px;
  width: 100%;
  font-weight: 600;
  margin-top: 1.5rem;
  font-size: 1rem;
  height: 3rem;
`

export default ReferFriendPopup