import React, {useEffect, useState} from "react"
import {DatePicker, notification} from "antd";
import moment from "moment";
import {ApplyButton, ButtonGroup, CancelButton, ModalStyled, Title} from "../maintenances/CustomFrequencyModal";
import { Subtitle } from "../../screens/PasswordReset";
import {snoozeUpcomingTask} from "../../../api-wrapper/upcomingTasks/snoozeUpcomingTask";
import {disabledDate} from "../helper";
import {useSelector} from "react-redux";
import {getPropertyPreferencesSelector} from "../../selectors";

type Props = {
  taskToReschedule: any
  isRescheduleModalOpen: boolean;
  toggleRescheduleModal: (arg: boolean) => void;
  handleRefresh?: () => void;
  handleRefreshTask?: (arg?: any) => void;
}

const RescheduleModal = (props: Props) => {
  const { taskToReschedule, isRescheduleModalOpen, toggleRescheduleModal, handleRefresh, handleRefreshTask } = props
  const [date, setDate] = useState<any>(null)

  const propertyPreferences = useSelector(getPropertyPreferencesSelector);
  const currentDate = moment().endOf("day")

  useEffect(() => {
    if (taskToReschedule) {
      moment(taskToReschedule.triggerDate).isBefore(currentDate) ? setDate(currentDate) : setDate(moment(taskToReschedule.triggerDate))
    } else {
      setDate(currentDate)
    }
  }, [taskToReschedule])

  const handleOk = () => {
    snoozeUpcomingTask({
      ...taskToReschedule,
      snoozeDate: date.format('YYYY-MM-DD')
    }).then((res: any) => {
      notification.success({
        message: "Action successful",
        description: <>Task <b>{taskToReschedule.title}</b> has been rescheduled successfully on {date.format(propertyPreferences.preferredDateFormat)}.
          You can now view it in your Planner.</>,
        placement: "topRight",
      });
      handleRefresh && handleRefresh();
      handleRefreshTask && handleRefreshTask(res.data);
      setDate(null);
      toggleRescheduleModal(false);
    })
  }

  const handleCancel = () => { toggleRescheduleModal(false) }

  return (
    <ModalStyled
      width={469}
      title={""}
      style={{zIndex: 100}}
      visible={isRescheduleModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      closeIcon={<></>}
      footer={null}
    >
      <Title>Reschedule reminder</Title>
      <Subtitle>Select a preferred date for this reminder.</Subtitle>

      <DatePicker
        value={date}
        size={'large'}
        format={propertyPreferences.preferredDateFormat}
        allowClear={false}
        inputReadOnly={true}
        style={{
          width: "100%",
          border: "1px solid #DAE0E6",
          boxSizing: "border-box",
          borderRadius: "6px",
          color: "rgb(107,113,133)",
          marginBottom: '2rem'
        }}
        disabledDate={disabledDate}
        onChange={(value) => setDate(value)}
      />

      <ButtonGroup>
        <CancelButton onClick={() => handleCancel()}>Cancel</CancelButton>
        <ApplyButton onClick={() => handleOk()}>Apply</ApplyButton>
      </ButtonGroup>
    </ModalStyled>
  )
}

export default RescheduleModal