import {useSelector} from "react-redux";
import styled from "styled-components";
import React, {useEffect, useState} from "react";
import _ from "lodash";

import {getPropertiesSelector, getPropertyIdSelector} from "../../selectors";

import {Dropdown, Menu, Spin, Tooltip} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {getCrimeData} from "../../../api-wrapper/properties/getCrimeData";
import {emptyGuid} from "../../screens/helpers";
import {SectionLabel} from "../../screens/components";
import moment from "moment";
import {MenuStyled} from "../works/WorksListComp";
import { ReactComponent as ArrowRedUp } from "../../../images/ArrowRedUp.svg";
import { ReactComponent as ArrowGreenDown } from "../../../images/ArrowGreenDown.svg";
import { ReactComponent as ArrowDownBlue } from "../../../images/ArrowDownBlue.svg";
import {MinusOutlined} from "@ant-design/icons";

const CrimeDataWidgetWidget = () => {
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);

  const crimeColors = new Map([
    ["Anti-social behaviour", "#4AFF85"],
    ["Bicycle theft", "#7A86D6"],
    ["Burglary", "#ACA88F"],
    ["Criminal damage and arson", "#85BAA0"],
    ["Drugs", "#56CEDF"],
    ["Possession of weapons", "#B691BA"],
    ["Public order", "#99C396"],
    ["Robbery", "#51F5C9"],
    ["Shoplifting", "#A78CD2"],
    ["Theft from the person", "#AAC295"],
    ["Vehicle crime", "#88B7A8"],
    ["Violence and sexual offences", "#5E99DA"],
    ["Other crime", "#B1969A"],
    ["Other theft", "#8AC09A"]
  ]);

  const [dataDownloaded, setDataDownloaded] = useState(false);
  const [dataDownloadedForProperty, setDataDownloadedForProperty] = useState<any>();
  const [crimes, setCrimes] = useState<any>([]);
  const [zipCode, setZipCode] = useState("");
  const [crimeRadius, setCrimeRadius] = useState(0);
  const [sinceDate, setSinceDate] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dataDownloadError, setDataDownloadError] = useState<any>(null);
  const [selectedType, setSelectedType] = useState("currentMonth");
  const [crimeDelta, setCrimeDelta] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  })

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find((property) => property.propertyId === propertyId);
    if (!currentProperty)
      return;
    if (dataDownloaded && dataDownloadedForProperty === propertyId && zipCode === currentProperty.zip)
      return;
    if (zipCode !== currentProperty.zip)
      setDataDownloaded(false);
    setZipCode(currentProperty.zip);
    setDataDownloadError(null);
    getCrimeData(propertyId).then((payload) => {
      if (payload && payload.length > 0) {
        const colorCodedCrimes: any = [];
        const allCrimes: any = [];
        const otherCrimes: any = [];
        let counter = 0;
        let currentMonthCrimeCount = 0;
        let previousMonthCrimeCount = 0;

        setCrimeRadius(payload[0].distanceMeters);
        setSinceDate(payload[0].crimeDate);
        payload.map(crime => {
          crime.crimeType.toLowerCase().includes("other") ?
            otherCrimes.push({
              type: crime.crimeType,
              amount: crime.lastMonthCrimesCount,
              previousMonthAmount: crime.previousMonthCrimesCount
            }) :
            allCrimes.push({
              type: crime.crimeType,
              amount: crime.lastMonthCrimesCount,
              previousMonthAmount: crime.previousMonthCrimesCount
            })
          currentMonthCrimeCount += crime.lastMonthCrimesCount;
          previousMonthCrimeCount += crime.previousMonthCrimesCount;
        })
        const allCrimesSorted = _.orderBy(allCrimes, "type")
        const otherCrimesSorted = _.orderBy(otherCrimes, "type")
        const sortedCrimes = [...allCrimesSorted, ...otherCrimesSorted];
        sortedCrimes.map((crime: any) => {
          colorCodedCrimes.push({...crime, color: crimeColors.get(crime.type)})
          if (counter < 3) { counter++ }
          else { counter = 0 }
        })
        setCrimes(colorCodedCrimes);
        setCrimeDelta(currentMonthCrimeCount - previousMonthCrimeCount);
        setDataDownloaded(true);
        setDataDownloadedForProperty(propertyId);
      } else {
        setDataDownloaded(true);
        setDataDownloadedForProperty(propertyId);
        setDataDownloadError("There is no data available for your property.")
      }
    })
  }, [propertyId, properties]);

  if (!dataDownloaded) {
    return <Spin indicator={<LoadingOutlined style={{fontSize: 72}} spin/>}/>
  }

  const typeToShowSelection = () => (
    <Dropdown overlay={<MenuStyled selectedKeys={[]}>
      <Menu.Item key="currentMonth" onClick={() => setSelectedType("currentMonth")}>
        <span className={"accent"}>{moment(sinceDate+"-01").format("MMM YYYY")}</span>
      </Menu.Item>
      <Menu.Item key="previousMonth" onClick={() => setSelectedType("previousMonth")}>
        <span className={"accent"}>{moment(sinceDate+"-01").subtract(1, "months").format("MMM YYYY")}</span>
      </Menu.Item>
      <Menu.Item key="compare" onClick={() => setSelectedType("compare")}>Compare</Menu.Item>
    </MenuStyled>} placement="bottomCenter">
      <span className={"dropdown"}>
        {selectedType === "currentMonth" && moment(sinceDate+"-01").format("MMM YYYY")}
        {selectedType === "previousMonth" && moment(sinceDate+"-01").subtract(1, "months").format("MMM YYYY")}
        {selectedType === "compare" && "Compare"}
        <ArrowDownBlue />
      </span>
    </Dropdown>
  )

  const getDiffColor = (amount: number) => {
    if (amount < 0) return "#86CEA2"
    if (amount === 0) return "#DDDFDD"
    if (amount > 0) return "#F28172"
  }

  const getDiffArrow = (amount: number) => {
    if (amount > 0) return <Tooltip placement="top" title={"The total number of crimes has increased in comparison to the previous month's statistics"}>
      <ArrowRedUp style={{marginLeft: "0.5rem"}} />
    </Tooltip>
    if (amount === 0) return <Tooltip placement="top" title={"The total number of crimes has remained the same as in the previous month"}>
      <MinusOutlined style={{color: "#DDDFDD", marginLeft: "0.5rem"}} />
    </Tooltip>
    if (amount < 0) return <Tooltip placement="top" title={"The total number of crimes has decreased in comparison to the previous month's statistics"}>
      <ArrowGreenDown style={{marginLeft: "0.5rem"}} />
    </Tooltip>
  }

  const getBarWidth = (crime: any) => {
    if (selectedType === "currentMonth") return crime.amount * 10 + "px"
    if (selectedType === "previousMonth") return crime.previousMonthAmount * 10 + "px"
    if (selectedType === "compare") {
      if (crime.amount - crime.previousMonthAmount === 0) {
        return 0
      } else {
        return Math.abs(crime.amount - crime.previousMonthAmount) * 10 + "px"
      }
    }
  }

  const showCrimeBar = (crime: any, view: string) => {
    if (selectedType === "currentMonth") return crime.amount !== 0
    if (selectedType === "previousMonth") return crime.previousMonthAmount !== 0
    if (selectedType === "compare") {
      if (view === "general") return true
      else return crime.amount - crime.previousMonthAmount !== 0
    }
  }

  const crimeSecurityBlock = () => (<WidgetColumn style={{width: "40%"}}>
    <BlockTitle>
      <span className={"block-title"}>Crime & Security in your Local Area</span>
    </BlockTitle>
    <SecurityInfo>
          <span>Keeping your home safe and secure is vitally important for peace of mind as well as financial security.
            Find out what the main risks are to your property and explore Livlet guidance on how to minimise these risks.
            If the unexpected happens, use the resources below to help deal with it...</span>
      <span className={"contact-wrapper"}>
            <div className={"contact-data"}>0800 555 111</div>
            Report a Crime anonymously
          </span>
      <span className={"contact-wrapper"}>
            <div className={"contact-data"}>Email</div>
            Your council Safer Neighbourhoods Team
          </span>
      <span className={"contact-wrapper"}>
            <div className={"contact-data"}>JLS Locks - 0203 298 1122</div>
            Your nearest MLA and GCL accredited locksmith
          </span>
    </SecurityInfo>
  </WidgetColumn>)

  const desktopWidget = () => (
    <WidgetWrapper>
      <WidgetTitle>
        Crime & Security
      </WidgetTitle>
      <DesktopWidget>
        <WidgetColumn>
          <BlockTitle>
            <span className={"block-title"}>Crime Data (by type):{" "}{typeToShowSelection()}</span>
            <span className={"subtitle"}>
              Within <span className={"accent"}>250 meters</span> of <span className={"accent"}>{zipCode}</span>
              {" "}{selectedType === "compare" && getDiffArrow(crimeDelta)}
            </span>
          </BlockTitle>
          {selectedType === "compare" && <CrimeData>
            {selectedType === "compare" && <span className={"period-label"}>
              {moment(sinceDate+"-01").subtract(1, "months").format("MMM YYYY")}
            </span>}
            {crimes.map((crime: any) => <span className={"crime-wrapper"} style={{flexGrow: crime.amount}}>
              <span className={`color-block`} style={{background: crime.color}}>
                {crime.previousMonthAmount}
              </span>
            </span>)}
          </CrimeData>}

          <CrimeData>
            {selectedType === "compare" && <span className={"period-label"}>
              {moment(sinceDate+"-01").format("MMM YYYY")}
            </span>}
            {crimes.map((crime: any) => showCrimeBar(crime, "general") && <span className={"crime-wrapper"} style={{flexGrow: crime.amount}}>
              <span className={`color-block`} style={{background: crime.color}}>
                {selectedType === "previousMonth" ? crime.previousMonthAmount : crime.amount}
              </span>
              {selectedType !== "compare" && <span className={"subtitle"}>{crime.type}</span>}
            </span>)}
          </CrimeData>

          {selectedType === "compare" && <CrimeData>
            {selectedType === "compare" && <span className={"period-label"}>Change</span>}
            {crimes.map((crime: any) => <span className={"crime-wrapper"} style={{flexGrow: crime.amount}}>
              <span className={`color-block`} style={{color: getDiffColor(crime.amount - crime.previousMonthAmount)}}>
                {crime.amount - crime.previousMonthAmount > 0 ? "+" : ""}{crime.amount - crime.previousMonthAmount}
              </span>
              <span className={"subtitle"}>{crime.type}</span>
            </span>)}
          </CrimeData>}
        </WidgetColumn>
      </DesktopWidget>
   </WidgetWrapper>
  )

  const mobileWidget = () => (
    <WidgetWrapper>
      <WidgetTitle className={"mobile"}>
        Crime & Security
      </WidgetTitle>
      <MobileWidget>
          <BlockTitle>
            <span className={"block-title"}>Crime Data (by type):</span>
            <span>
              {typeToShowSelection()}
            </span>
          </BlockTitle>
          <CrimeData className={"mobile"}>
            {crimes.map((crime: any) => showCrimeBar(crime, "general") && <span className={"crime-wrapper"}>
                <span className={"subtitle"}>{crime.type}</span>
                <div className={"crime-counter"}>
                  {selectedType === "currentMonth" && crime.amount}
                  {selectedType === "previousMonth" && crime.previousMonthAmount}
                  {selectedType === "compare" && `${crime.amount - crime.previousMonthAmount > 0 ? "+" : ""}${crime.amount - crime.previousMonthAmount}`}
                  {showCrimeBar(crime, "bar") && <span className={`color-block`} style={{width: getBarWidth(crime), background: crime.color}}/>}
                </div>
              </span>)}
          </CrimeData>
        <LocationWrapper className={"mobile"}>
          <span>Within <span className={"accent"}>250 meters</span> of <span className={"accent"}>{zipCode}</span></span>
          {selectedType === "compare" && getDiffArrow(crimeDelta)}
        </LocationWrapper>
      </MobileWidget>
    </WidgetWrapper>
  )

  return <>
    <SectionLabel>
      Local Area Info
    </SectionLabel>
    {dataDownloadError ? <WidgetWrapper>{dataDownloadError}</WidgetWrapper> :
      (crimes.length < 10 ? (windowWidth > 768 ? desktopWidget() : mobileWidget()) :
        (windowWidth > 1200 || windowWidth <= 1024 && windowWidth > 768) ? desktopWidget() : mobileWidget())}
  </>
}

const WidgetTitle = styled.div`
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.625rem;
  color: #21272B;
  margin-bottom: 1.5rem;
  
  &.mobile {
    font-size: 1.625rem;
    line-height: 1.75rem;
    color: #3E5D58;
  }
`

const WidgetColumn = styled.div`
  width: 100%;
`

const BlockTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-bottom: 0.375rem;
  border-bottom: 1px solid #DAE0E6;
  margin-bottom: 1rem;
  
  .block-title { 
    font-weight: 600;
    color: #000000;
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
  }
  
  .subtitle {
    text-align: right;
    font-weight: 400;
    color: #21272B;
  }
  
  .accent {
    color: #00CC33;
  }
  
  .dropdown {
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
    color: #00CC33;
    cursor: pointer;
  }
  
  .dropdown svg path { stroke: #00CC33; }
`

const LocationWrapper = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 0.75rem;
  
  .accent {
    color: #00CC33;
  }
  
  &.mobile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

const SecurityInfo = styled.div`
  display: flex;
  flex-direction: column; 
  row-gap: 1rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #21272B;
  
  .contact-wrapper {
    gap: 0;
  }
  
  .contact-data {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2.625rem;
    color: #21272B;
  }
`

const CrimeData = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  
  .period-label {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    height: 2.5rem;
  }
  
  .crime-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
  }
  
  .color-block {
    width: 100%;
    padding: 1rem;
    height: 2.5rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 0.75rem;
  }
  
  .acid {
    background: #99FF00;
    color: #45655E;
  }
  
  .green {
    background: #00B74F;
    color: white;
  }
  
  .teal {
    background: #45655E;
    color: white;
  }
  
  .gray {
    background: #DDDFDD;
    color: #45655E;
  }
  
  .subtitle {
    width: 100%;
    text-align: left;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #21272B;
  }
  
  &.mobile { 
    flex-direction: column;
    gap: 0.375rem;
  }
  
  &.mobile .crime-wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .crime-counter { 
    display: flex; 
    align-items: center; 
    column-gap: 0.25rem;
    height: 2rem;
  }
  
   &.mobile .color-block { height: 1.25rem; }
   
   &.mobile .subtitle { width: auto; }
`

const DesktopWidget = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
`;

const MobileWidget = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 3rem;
`;

const WidgetWrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem 1.5rem;
  
  @media (max-width: 1024px) {
    margin-bottom: 1.5rem;
  }
`;


export default CrimeDataWidgetWidget;
