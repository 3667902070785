import React, {useEffect, useState} from "react";
import GuidanceListComp, {
  CategoryLine,
  CategoryTitle,
  CategoryTitleWrapper
} from "../components/guidance/GuidanceListComp";
import GuidanceSearchComp, { SearchSortSeparator } from "../components/guidance/GuidanceSearchComp";
import {useDispatch, useSelector} from "react-redux";
import {
  getImprovementsSelector,
  getPropertiesSelector,
  getPropertyIdSelector, getPropertyPreferencesSelector,
  getWorksSelector
} from "../selectors";
import {getAllImprovements} from "../actions/improvements";
import {TFetchingStatus} from "../../helpers";
import styled from "styled-components";
import {Button, DatePicker, Modal, notification} from "antd";
import _ from "lodash";

import {ReactComponent as WishlistIcon} from "../../images/guidance/WishlistIcon.svg";
import {ReactComponent as GuidanceFiltersActive} from "../../images/guidance/GuidanceFiltersActive.svg";
import {ReactComponent as GuidanceFiltersInactive} from "../../images/guidance/GuidanceFiltersInactive.svg";
import GuidanceItem from "../components/guidance/GuidanceItem";
import {useHistory, useLocation} from "react-router-dom";
import RelatedMeasuresComp from "../components/guidance/RelatedMeasuresComp";
import {addWork} from "../../api-wrapper/works/addWork";
import {
  addToWishlist,
  linkImprovementWithWork,
  markImprovementAsApplicable,
  markImprovementAsDone,
  markImprovementAsNotApplicable,
  markImprovementAsUndone,
  removeFromWishlist
} from "../../api-wrapper/properties/improvementsOptions";
import {getAllWorks} from "../actions/works";
import WorkDrawer from "../components/works/WorkDrawer";
import {getCurrentUserProfile} from "../actions/getUserProfile";

import moment from "moment";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {removeWork} from "../../api-wrapper/works/removeWork";
import {getEntityTypes} from "../../api-wrapper/relation/getEntityTypes";
import OnboardingComp from "../components/guidance/OnboardingComp";
import InventoryDrawer from "../components/inventories/InventoryDrawer";
import ViewWorkDrawer from "../components/works/ViewWorkDrawer";
import LinkDrawer from "../components/spaces/LinkDrawer";
import ViewSpaceDrawer from "../components/spaces/ViewSpaceDrawer";
import NoteDrawer from "../components/notes/NoteDrawer";
import {getAllSpaces} from "../actions/spaces";
import ViewFileDrawer from "../components/attachments/ViewFileDrawer";
import ExpenseDrawer from "../components/expenses/ExpenseDrawer";
import ContactDrawer from "../components/contacts/ContactDrawer";
import {Guid} from "guid-typescript";
import {editWork} from "../../api-wrapper/works/editWork";

const Guidance = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const propertyId = useSelector(getPropertyIdSelector).value;
  const improvementsConfiguration = useSelector(getImprovementsSelector);
  const works = useSelector(getWorksSelector);
  const properties = useSelector(getPropertiesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [categories, setCategories] = useState<Array<any>>([]);
  const [improvements, setImprovements] = useState<Array<any>>([]);
  const [selectedImprovement, setSelectedImprovement] = useState<any>(null);
  const [onboardingStatus, setOnboardingStatus] = useState(0);
  const [workAction, setWorkAction] = useState("")

  const [isViewWorkDrawerOpen, toggleViewWorkDrawer] = useState(false);
  const [workToViewId, setWorkToViewId] = useState<any>(null);
  const [isViewFileDrawerOpen, toggleViewFileDrawer] = useState(false);
  const [attachmentToViewId, setAttachmentToViewId] = useState<any>(null);
  const [isNoteDrawerOpen, toggleNoteDrawer] = useState(false);
  const [editingNoteData, setEditingNoteData] = useState(null);
  const [isViewSpaceDrawerOpen, toggleViewSpaceDrawer] = useState(false);
  const [viewSpaceId, setViewSpaceId] = useState(null);
  const [isLinkDrawerOpen, toggleLinkDrawer] = useState(false);
  const [presetLinkType, setPresetLinkType] = useState<any>(null);
  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [editingExpenseData, setEditingExpenseData] = useState<any>(null);
  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editingContactData, setEditingContactData] = useState(null);
  const [editingInventoryData, setEditingInventoryData] = useState<any>(null);
  const [isInventoryDrawerOpen, toggleInventoryDrawer] = useState(false);

  useEffect(() => {
    dispatch(getAllImprovements(propertyId));
    dispatch(getAllWorks(propertyId));
    dispatch(getCurrentUserProfile());
    const currentProperty = properties.find(
      (property) => property.propertyId === propertyId
    );
    if (!currentProperty)
      return;
    setOnboardingStatus(currentProperty.questionnairePage ?? 0);
  }, [propertyId])

  useEffect(() => {
    if (location.pathname.includes("wishlist")) {
      toggleWishlist(true)
    } else {
      toggleWishlist(false)
    }

    if (location.pathname.includes("improvement")) {
      let path = location.pathname.split("/")
      let improvement = improvements.filter(i => i.category === parseInt(path[3]) && i.id === path[4])[0]
      setSelectedImprovement(improvement)
    } else {
      setSelectedImprovement(null)
    }
  }, [location, improvements])

  const [isWishlist, toggleWishlist] = useState(false)
  const [isFiltering, toggleFiltering] = useState(false)
  const [search, setSearch] = useState("")
  const [ecoPriority, setEcoPriority] = useState(0)
  const [effortPriority, setEffortPriority] = useState(0)
  const [savingsPriority, setSavingsPriority] = useState(0)
  const [safetyPriority, setSafetyPriority] = useState(0)
  const [costPriority, setCostPriority] = useState(0)
  const [wellbeingPriority, setWellbeingPriority] = useState(0)
  const [selectedCategories, setSelectedCategories] = useState<any>([])
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<any>([])

  const [workType, setWorkType] = useState<any>("")
  const [isNewWork, setIsNewWork] = useState(false)
  const [editingWorkData, setEditingWorkData] = useState<any>(null)
  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false)
  const [isCompletionModalOpen, toggleCompletionModal] = useState(false)
  const [improvementToComplete, setImprovementToComplete] = useState<any>(null)
  const [completionDate, setCompletionDate] = useState(moment())

  useEffect(() => {
    getEntityTypes(propertyId, "work").then((res: any) => {
      res && setWorkType(res.filter((type: any) => type.name.toLowerCase().includes("improvement"))[0].name)
    })
  }, [propertyId])

  useEffect(() => {
    if (improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success)
      return;
    setCategories(improvementsConfiguration.content.categories.filter(category => category.name));
    setImprovements([...improvementsConfiguration.content.improvements].sort((impr1, impr2) => impr1.displayOrder - impr2.displayOrder));
  }, [improvementsConfiguration]);

  const useQuery = () => {
    let query = new URLSearchParams(useLocation().search);
    return query.get("category");
  };
  const categoryToOpen = useQuery()

  useEffect(() => {
    const previouslySelectedCategories = [...selectedCategories]
    categoryToOpen ? setSelectedCategories(categories.filter(c => c.id === parseInt(categoryToOpen)))
      : setSelectedCategories([...previouslySelectedCategories])
  }, [categoryToOpen, categories])

  useEffect(() => {
    const selectedIds: any = []
    selectedCategories.map((category: any) => selectedIds.push(category.id))
    setSelectedCategoryIds(selectedIds)
  }, [selectedCategories])

  const wishListSize = () => improvements.filter(improvement => !_.isNil(improvement.wishListedOn)
      && _.isNil(improvement.doneOn) && _.isNil(improvement.doneByAnswerOn)).length;

  const getCategoryName = (categoryId: number) => categories
    .filter(c => c.id === categoryId)[0].name

  const totalImprovementsFromCategory = (categoryId: number) => improvements
    .filter(i => i.category === categoryId)

  const doneImprovementsFromCategory = (categoryId: number) => improvements
    .filter(i => i.category === categoryId && (i.doneOn || i.doneByAnswerOn))

  const selectImprovement = (category: number, id: string) => {
    history.push(`/guidance/improvement/${category}/${id}`)
  }

  const createWork = (completedOn?: string) => {
    setWorkAction("improvement-create");
    addWork({
      propertyId,
      name: completedOn ? improvementToComplete.name : selectedImprovement.name,
      type: workType,
      completedDate: completedOn?? null,
      dueDate: completedOn?? null,
      recurring: null,
      isComplete: !!completedOn,
    }).then((res) => {
        !completedOn && setIsNewWork(true);
        !completedOn && setEditingWorkData(res);
        res.data && linkImprovementWithWork(propertyId,
        completedOn ? improvementToComplete.id : selectedImprovement.id, res.data.workId).then(() => {
        dispatch(getAllWorks(propertyId));
        !completedOn && (getAllImprovements(propertyId));
        !completedOn && toggleWorkDrawer(true);
      });
    });
  }

  const completeExistingWork = (workId: Guid, completedOn: string) => {
    let existingWork = works.content.filter(work => work.workId === workId)[0]
    editWork({
      ...existingWork,
      isComplete: true,
      completedDate: completedOn
    }).then(() => dispatch(getAllWorks(propertyId)))
  }


  const openRelatedWork = () => {
    setWorkToViewId(selectedImprovement.relatedWorkId);
    toggleViewWorkDrawer(true);
    // setWorkAction("improvement-edit");
    // setEditingWorkData({
    //   data: works.content.filter((work) => work.workId === selectedImprovement.relatedWorkId)[0],
    // });
    // setIsNewWork(false);
    // toggleWorkDrawer(true);
  }

  const manageWishlistStatus = (item: any) => {
    setImprovements(improvements.map(improvement => {
      if (improvement.id === item.id) {
        if (item.wishListedOn) {
          removeFromWishlist(propertyId, item.id).then(() => {
            dispatch(getAllImprovements(propertyId))
            notification.info({
              message: "Removed successfully",
              description: `${item.name} has been removed from your wishlist.`,
              placement: "topRight"
            })
          })
          return {...improvement, wishListedOn: null}
        } else {
          addToWishlist(propertyId, item.id).then(() => {
            dispatch(getAllImprovements(propertyId))
            notification.success({
              message: "Added successfully!",
              description: `${item.name} has been added to your wishlist.`,
              placement: "topRight"
            })
          })
          return {...improvement, wishListedOn: new Date()}
        }
      } else return improvement
    }))
  }

  const manageImprovementStatus = (item: any) => {
    setImprovements(improvements.map(improvement => {
        if (improvement.id === item.id) {
          if (improvement.doneOn || improvement.doneByAnswerOn) {
            item.relatedWorkId && removeWork(propertyId, item.relatedWorkId).then()
            markImprovementAsUndone(propertyId, item.id).then(() => {
              dispatch(getAllImprovements(propertyId))
              notification.info({
                message: "Saved changes",
                description: `${item.name} has been marked as not done.`,
                placement: "topRight"
              })
            })
            return {...improvement, doneOn: null}
          } else {
            setImprovementToComplete(item)
            toggleCompletionModal(true)
            return improvement
          }
        } else
          return improvement
      }
    ));
  }

  const manageApplicableStatus = (item: any) => {
    setImprovements(improvements.map(improvement => {
        if (improvement.id === item.id) {
          if (improvement.isApplicable === undefined || improvement.isApplicable === true) {
            markImprovementAsNotApplicable(propertyId, item.id).then(() => {
              removeFromWishlist(propertyId, item.id).then()
              dispatch(getAllImprovements(propertyId))
              notification.info({
                message: "Saved changes",
                description: `${item.name} has been marked as not applicable.`,
                placement: "topRight"
              })
            })
            return {...improvement, isApplicable: false, wishListedOn: null}
          } else {
            markImprovementAsApplicable(propertyId, item.id).then(() => {
              dispatch(getAllImprovements(propertyId))
              notification.success({
                message: "Saved changes",
                description: `${item.name} has been marked as applicable.`,
                placement: "topRight"
              })
            })
            return {...improvement, isApplicable: true}
          }
        } else
          return improvement
      }
    ));
  }

  const toggleWishlistButton = () => {
    setSearch("")
    setSelectedCategories([])
    setWellbeingPriority(0)
    setEffortPriority(0)
    setEcoPriority(0)
    setSafetyPriority(0)
    setSavingsPriority(0)
    setCostPriority(0)
    isWishlist ? history.push("/guidance") : history.push("/guidance/wishlist")
  }

  const mainSectionContent = () => (
    selectedImprovement ? <GuidanceItem
        improvement={selectedImprovement}
        parentCategory={{
          name: getCategoryName(selectedImprovement.category),
          totalImprovements: totalImprovementsFromCategory(selectedImprovement.category).length,
          doneImprovements: doneImprovementsFromCategory(selectedImprovement.category).length,
        }}
        createWork={createWork}
        openRelatedWork={openRelatedWork}
        selectImprovement={selectImprovement}
        manageWishlistStatus={manageWishlistStatus}
        manageImprovementStatus={manageImprovementStatus}
      /> :
      <GuidanceListComp
        categories={categories}
        improvements={improvements}
        selectedCategories={selectedCategories}
        selectedCategoryIds={selectedCategoryIds}
        search={search}
        isWishlist={isWishlist}
        selectImprovement={selectImprovement}
        manageWishlistStatus={manageWishlistStatus}
        manageImprovementStatus={manageImprovementStatus}
        manageApplicableStatus={manageApplicableStatus}
        ecoPriority={ecoPriority}
        costPriority={costPriority}
        effortPriority={effortPriority}
        savingsPriority={savingsPriority}
        safetyPriority={safetyPriority}
        wellbeingPriority={wellbeingPriority}
      />
  )

  const sidebarSectionContent = () => (
    selectedImprovement ? <RelatedMeasuresComp
        improvement={selectedImprovement}
        improvements={improvements}
        selectImprovement={selectImprovement}
      /> :
      <GuidanceSearchComp
        search={search}
        setSearch={setSearch}
        ecoPriority={ecoPriority}
        setEcoPriority={setEcoPriority}
        costPriority={costPriority}
        setCostPriority={setCostPriority}
        effortPriority={effortPriority}
        setEffortPriority={setEffortPriority}
        savingsPriority={savingsPriority}
        setSavingsPriority={setSavingsPriority}
        safetyPriority={safetyPriority}
        setSafetyPriority={setSafetyPriority}
        wellbeingPriority={wellbeingPriority}
        setWellbeingPriority={setWellbeingPriority}
        categories={categories}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
  )

  return <>
    <WorkDrawer
      width={null}
      isOpen={isWorkDrawerOpen}
      toggleDrawerOpen={toggleWorkDrawer}
      setEditingWorkData={setEditingWorkData}
      work={editingWorkData}
      parentDrawer={workAction}
      isNewWork={isNewWork}
      parentImprovement={selectedImprovement}
      refreshParent={() => {
        dispatch(getAllImprovements(propertyId));
      }}
    />
    <InventoryDrawer
      isOpen={isInventoryDrawerOpen}
      toggleDrawerOpen={toggleInventoryDrawer}
      width={null}
      // @ts-ignore
      setEditingInventoryData={setEditingInventoryData}
      inventory={editingInventoryData}
      setViewWorkId={setWorkToViewId}
      toggleViewWorkDrawer={toggleViewWorkDrawer}
    />
    <ViewWorkDrawer
      workId={workToViewId}
      setWorkId={setWorkToViewId}
      isOpen={isViewWorkDrawerOpen}
      toggleDrawer={toggleViewWorkDrawer}
      toggleWorkDrawer={toggleWorkDrawer}
      setEditWorkData={setEditingWorkData}
      toggleExpenseDrawer={toggleExpenseDrawer}
      setEditExpenseData={setEditingExpenseData}
      toggleInventoryDrawer={toggleInventoryDrawer}
      setEditInventoryData={setEditingInventoryData}
      toggleContactDrawer={toggleContactDrawer}
      setEditContactData={setEditingContactData}
      toggleNoteDrawer={toggleNoteDrawer}
      setEditNoteData={setEditingNoteData}
      setLinkedWorkId={() => {}}
      toggleLinkDrawerOpen={toggleLinkDrawer}
      setWorkIdToForward={() => {}}
      toggleForwardDrawer={() => {}}
      toggleTradeDrawer={() => {}}
      setEditSpaceId={setViewSpaceId}
      toggleSpaceDrawer={toggleViewSpaceDrawer}
      setPresetLinkType={() => {}}
      toggleTaskDrawer={() => {}}
      setEditTaskData={() => {}}
      setAttachmentId={setAttachmentToViewId}
      toggleViewFileDrawer={toggleViewFileDrawer}
    />
    <LinkDrawer
      parentId={viewSpaceId}
      parentType={"cost"}
      isOpen={isLinkDrawerOpen}
      toggleDrawerOpen={toggleLinkDrawer}
      presetLinkType={presetLinkType}
      setPresetLinkType={setPresetLinkType}
      toggleWorkDrawer={toggleWorkDrawer}
      setEditWorkData={setEditingWorkData}
      toggleExpenseDrawer={toggleExpenseDrawer}
      setEditExpenseData={setEditingExpenseData}
      toggleInventoryDrawer={toggleInventoryDrawer}
      setEditInventoryData={setEditingInventoryData}
      toggleContactDrawer={toggleContactDrawer}
      setEditContactData={setEditingContactData}
      toggleNoteDrawer={toggleNoteDrawer}
      setEditNoteData={setEditingNoteData}
      setEditSpaceId={setViewSpaceId}
      toggleSpaceDrawer={toggleViewSpaceDrawer}
      toggleTaskDrawer={() => {}}
      setEditTaskData={() => {}}
      toggleEventDrawer={() => {}}
      setViewWorkId={setWorkToViewId}
      toggleViewWorkDrawer={toggleViewWorkDrawer}
      setAttachmentId={setAttachmentToViewId}
      toggleViewFileDrawer={toggleViewFileDrawer}
    />
    <ViewSpaceDrawer
      spaceId={viewSpaceId}
      isOpen={isViewSpaceDrawerOpen}
      toggleDrawerOpen={toggleViewSpaceDrawer}
      toggleEditDrawerOpen={toggleViewSpaceDrawer}
      setEditSpaceId={setViewSpaceId}
      toggleLinkDrawerOpen={toggleLinkDrawer}
      setLinkedSpaceId={() => {}}
      setPresetLinkType={setPresetLinkType}
      toggleWorkDrawer={toggleWorkDrawer}
      setEditWorkData={setEditingWorkData}
      toggleExpenseDrawer={toggleExpenseDrawer}
      setEditExpenseData={setEditingExpenseData}
      toggleInventoryDrawer={toggleInventoryDrawer}
      setEditInventoryData={setEditingInventoryData}
      toggleContactDrawer={toggleContactDrawer}
      setEditContactData={setEditingContactData}
      toggleNoteDrawer={toggleNoteDrawer}
      setEditNoteData={setEditingNoteData}
      toggleTaskDrawer={() => {}}
      setEditTaskData={() => {}}
      setViewWorkId={setWorkToViewId}
      toggleViewWorkDrawer={toggleViewWorkDrawer}
      setAttachmentId={setAttachmentToViewId}
      toggleViewFileDrawer={toggleViewFileDrawer}
    />
    <NoteDrawer
      currentType={"note"}
      data={editingNoteData}
      isOpen={isNoteDrawerOpen}
      toggleDrawerOpen={toggleNoteDrawer}
      refreshParent={() => dispatch(getAllSpaces(propertyId))}
      isGlobalNote={true}
      toggleTaskDrawer={() => {}}
      setEditTaskData={() => {}}
      setPresetLinkType={setPresetLinkType}
      toggleLinkDrawerOpen={toggleLinkDrawer}
      setViewWorkId={setWorkToViewId}
      toggleViewWorkDrawer={toggleViewWorkDrawer}
      toggleExpenseDrawer={toggleExpenseDrawer}
      setEditExpenseData={setEditingExpenseData}
      toggleInventoryDrawer={toggleInventoryDrawer}
      setEditInventoryData={setEditingInventoryData}
      toggleContactDrawer={toggleContactDrawer}
      setEditContactData={setEditingContactData}
      setEditSpaceId={setViewSpaceId}
      toggleSpaceDrawer={toggleViewSpaceDrawer}
      setAttachmentId={setAttachmentToViewId}
      toggleViewFileDrawer={toggleViewFileDrawer}
    />
    <ViewFileDrawer
      attachmentId={attachmentToViewId}
      setAttachmentId={setAttachmentToViewId}
      isOpen={isViewFileDrawerOpen}
      toggleDrawer={toggleViewFileDrawer}
      viewFileDrawerDefaultTab={"preview"}
      attachmentsWithoutProperty={[]}
      duplicateAttachments={[]}
      selectedTags={[]}
      toggleEditTagsMode={() => {}}
      toggleEditDrawerOpen={() => {}}
      setPresetLinkType={() => {}}
      toggleWorkDrawer={() => {}}
      setEditWorkData={() => {}}
      toggleExpenseDrawer={() => {}}
      setEditExpenseData={() => {}}
      toggleInventoryDrawer={() => {}}
      setEditInventoryData={() => {}}
      toggleContactDrawer={() => {}}
      setEditContactData={() => {}}
      toggleNoteDrawer={() => {}}
      setEditNoteData={() => {}}
      toggleLinkDrawerOpen={() => {}}
      setEditSpaceId={() => {}}
      toggleSpaceDrawer={() => {}}
      refreshParent={() => {}}
      toggleTaskDrawer={() => {}}
      setEditTaskData={() => {}}
      toggleViewWorkDrawer={() => {}}
      setViewWorkId={() => {}}
    />
    <ExpenseDrawer
      isOpen={isExpenseDrawerOpen}
      toggleDrawerOpen={toggleExpenseDrawer}
      setEditingExpenseData={setEditingExpenseData}
      expense={editingExpenseData}
    />
    <ContactDrawer
      isOpen={isContactDrawerOpen}
      toggleDrawerOpen={toggleContactDrawer}
      contact={editingContactData}
      // @ts-ignore
      setEditingContactData={setEditingContactData}
      refreshParent={() => dispatch(getAllSpaces(propertyId))}
      setViewWorkId={setWorkToViewId}
      toggleViewWorkDrawer={toggleViewWorkDrawer}
    />

    <Modal
      className={"form-confirm-close-modal dismiss-modal"}
      visible={isCompletionModalOpen}
      closable={false}
      maskClosable={false}
      closeIcon={<></>}
      width={416}
      okText={"Complete"}
      onOk={() => {
        removeFromWishlist(propertyId, improvementToComplete.id).then()
        if (improvementToComplete.relatedWorkId) {
          completeExistingWork(improvementToComplete.relatedWorkId, completionDate.format('YYYY-MM-DD'))
        } else {
          createWork(completionDate.format('YYYY-MM-DD'))
        }
        markImprovementAsDone(propertyId, improvementToComplete.id).then(() => {
          toggleCompletionModal(false)
          dispatch(getAllImprovements(propertyId))
          notification.success({
            message: "Saved changes",
            description: `${improvementToComplete.name} has been marked as done.`,
            placement: "topRight"
          })
        })
      }}
      onCancel={() => toggleCompletionModal(false)}
    >
      <div>
        <QuestionCircleOutlined className={"question-icon"} />
      </div>
      <div style={{marginLeft: "1rem"}}>
        <p>Almost completed!</p>
        Set a completion date for this improvement.
        <DatePicker
          value={completionDate}
          format={propertyPreferences.preferredDateFormat}
          allowClear={false}
          inputReadOnly={true}
          style={{
            marginTop: "0.5rem",
            width: "100%",
            border: "1px solid #DAE0E6",
            boxSizing: "border-box",
            borderRadius: "6px",
            color: "rgb(107,113,133)",
          }}
          // @ts-ignore
          onChange={(event) => setCompletionDate(moment(event._d))}
        />
      </div>
    </Modal>
    <PageWrapper>
      <PageHeader>
        Guidance
        <ButtonWrapperMobile>
          <WishlistButton
            className={isWishlist ? "selected" : ""}
            onClick={() => toggleWishlistButton()}>
            <WishlistIcon />
          </WishlistButton>
          <WishlistButton
            className={isFiltering ? "selected" : ""}
            onClick={() => toggleFiltering(!isFiltering)}>
            {isFiltering ? <GuidanceFiltersActive /> : <GuidanceFiltersInactive />}
          </WishlistButton>
        </ButtonWrapperMobile>
      </PageHeader>
      <PageRow style={{
        alignItems: "flex-end",
        marginBottom: window.innerWidth > 1024 ? "1.5rem" : "0",
        padding: window.innerWidth > 1024 ? 0 : "0 1rem"
      }}>
        <MainSection>
          <DescriptionWrapper>
            <PageDescription>
              Compare costs and benefits of various improvements and learn what is involved in making the changes.
            </PageDescription>
            <WishlistButton
              className={isWishlist ? "selected" : ""}
              onClick={() => toggleWishlistButton()}>
              <WishlistIcon />
              <span style={{color: "#EF624E"}}>{wishListSize()}</span>
              My Wish List
            </WishlistButton>
          </DescriptionWrapper>
          <DescriptionWrapperMobile>
            Keep track of your house inventory
          </DescriptionWrapperMobile>
          {(selectedImprovement || isWishlist)  ? <CategoryTitleWrapper style={{marginBottom: window.innerWidth > 1024 ? 0 : "1.5rem"}}>
            <CategoryTitle className={"link-title"} onClick={() => {
              history.push("/guidance")
              toggleFiltering(false)
              setSearch("")
            }}>
              Back to all
            </CategoryTitle>
            <CategoryLine/>
          </CategoryTitleWrapper> : window.innerWidth > 1024 ? <SearchSortSeparator/> : null}
        </MainSection>
        <SidebarSection>
          <OnboardingComp
            origin={"guidance"}
            onboardingStatus={onboardingStatus}
            marginTop={window.innerWidth > 1024 ? 0 : "1.5rem"}
          />
          <SearchSortSeparator style={{marginBottom: (selectedImprovement || isWishlist) ? "0.7rem" : 0}} />
        </SidebarSection>
      </PageRow>
      <PageRow>
        <MainSectionDesktop>
          {mainSectionContent()}
        </MainSectionDesktop>
        <MainSectionMobile>
          {isFiltering ? sidebarSectionContent() : mainSectionContent()}
        </MainSectionMobile>
        <SidebarSection>
          {sidebarSectionContent()}
        </SidebarSection>
      </PageRow>
    </PageWrapper>
  </>
}

export default Guidance

const PageHeader = styled.div`
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3.5rem;
  color: #21272B;
  display: flex;
  justify-content: space-between;
  
  @media (max-width: 1024px) {
    margin: 0 1rem;
  }
`

const PageDescription = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #21272B;
  max-width: 23.5rem;
`

const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  
  @media (max-width: 1024px) {
    display: none;
  }
`

const DescriptionWrapperMobile = styled.div`
  display: none;
  
  @media (max-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #666F7A;
    margin: 0 1rem 1.5rem 1rem;
  }
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  margin: 0 4.2rem;
  
  @media (max-width: 1024px) {
    margin: 0;
  }
`

const PageRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  column-gap: 1.5rem;
`

const MainSection = styled.div`
  width: 60%;
  
  @media (max-width: 1024px) {
    width: 100%;
  }
`

const MainSectionDesktop = styled.div`
  width: 60%;
  
  @media (max-width: 1024px) {
    display: none;
  }
`

const MainSectionMobile = styled.div`
  display: none;
  
  @media (max-width: 1024px) {
    display: block;
    width: 100%;
  }
`

const SidebarSection = styled.div`
  width: 40%;
  
  @media (max-width: 1024px) {
    display: none;
  }
`

const WishlistButton = styled(Button)`
  height: 3.25rem;
  background: #FFFFFF;
  border: 1px solid #EFF1F5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #21272B;
  
  :hover, :active, :focus {
    color: #67C18B;
    border: 1px solid #67C18B;
  }
  
  &.selected {
    color: #21272B;
    background: #67C18B;
  }
  
  @media (max-width: 1024px) {
    border: 1px solid #C7C9C7;
    height: 3rem;
    width: 3rem;
    padding: 0;
  }
`

const ButtonWrapperMobile = styled.div`
  display: none;
  
  @media (max-width: 1024px) {
    display: flex;
    column-gap: 0.25rem;
  }
`