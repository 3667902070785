import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {ReactComponent as LinkIcon} from "../../../images/spaces/LinkNewIcon.svg";
import {ReactComponent as ReturnArrow} from "../../../images/spaces/ReturnArrow.svg";
import {search, TSearchFilters} from "../../../api-wrapper/search/search";
import {useDispatch, useSelector} from "react-redux";
import {
  getAttachmentsSelector,
  getContactsSelector,
  getExpensesSelector,
  getInventoriesSelector, getMaintenancesSelector, getNotesByPropertyIdSelector,
  getPropertyIdSelector, getSpacesSelector,
  getWorksSelector
} from "../../selectors";
import moment from "moment";
import {
  ActionButtonGreen,
  ActionButtonWhite, DrawerFooter, DrawerHeader,
  DrawerStyled,
  paginationButtons,
  PaginationStyled
} from "../../screens/components";
import {addRelation} from "../../../api-wrapper/relation/addRelation";
import {getAllSpaces} from "../../actions/spaces";
import {Modal, notification} from "antd";
import AddNewRecordDrawer from "./AddNewRecordDrawer";
import {getSpaceById} from "../../../api-wrapper/spaces/getSpaceById";
import LinkedItemsList from "./LinkedItemsList";
import LinkedItemsHeader from "./LinkedItemsHeader";
import {Guid} from "guid-typescript";
import {getAllWorks} from "../../actions/works";
import {getWorkById} from "../../../api-wrapper/works/getWorkById";
import {getAttachmentById} from "../../../api-wrapper/attachments/getAttachmentById";
import {getAllAttachments} from "../../actions/attachments";
import {getAllExpenses} from "../../actions/expenses";
import {getAllMaintenances} from "../../actions/maintenances";
import {getUpcomingTaskById} from "../../../api-wrapper/upcomingTasks/getUpcomingTaskById";
import {capitalize, upkeepTypes} from "../helper";
import {getAllNotesByPropertyId} from "../../actions/notesByPropertyId";
import {getNoteById} from "../../../api-wrapper/notes/getNoteById";
import {getContactById} from "../../../api-wrapper/contacts/getContactById";
import {getAllContacts} from "../../actions/contacts";

type Props = {
  parentId: Guid | null;
  parentSet?: Set<Guid> | null;
  setParentSet?: (arg: Set<Guid>) => void | null;
  parentType: string;
  isOpen: boolean;
  presetLinkType: string;
  setPresetLinkType: (arg: string | null) => void;
  toggleDrawerOpen: (arg: boolean) => void;
  toggleViewWorkDrawer: (arg: boolean) => void;
  setViewWorkId: (arg: any) => void;
  toggleWorkDrawer: (arg: boolean) => void;
  setEditWorkData: (arg: any) => void;
  toggleExpenseDrawer: (arg: boolean) => void;
  setEditExpenseData: (arg: any) => void;
  toggleInventoryDrawer: (arg: boolean) => void;
  setEditInventoryData: (arg: any) => void;
  toggleContactDrawer: (arg: boolean) => void;
  setEditContactData: (arg: any) => void;
  toggleNoteDrawer: (arg: boolean) => void;
  setEditNoteData: (arg: any) => void;
  toggleSpaceDrawer: (arg: boolean) => void;
  setEditSpaceId: (arg: any) => void;
  toggleTaskDrawer: (arg: boolean) => void;
  setEditTaskData: (arg: any) => void;
  toggleEventDrawer: (arg: any) => void;
  setAttachmentId: (arg: any) => void;
  toggleViewFileDrawer: (arg: boolean) => void;
  refreshParent?: () => void;
}

export const processSuggestedMappings = (propertyId: Guid, suggestedMappings: any, handleRefresh?: () => void) => {
  suggestedMappings.map((item: any) => {
    Modal.confirm({
      title: `Link this record to the related ${item.parentType}?`,
      content: `Would you like to link the ${item.childType} ${item.childName}
         to the related ${item.parentType} ${item.parentName}?`,
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        addRelation({
          propertyId,
          parentId: item.parentId,
          parentType: item.parentType,
          childType: item.childType,
          childId: item.childId
        }).then(() => {
          notification.success({
            message: "Linked successfully!",
            description: `${capitalize(item.childType)} ${item.childName} has been successfully 
                linked to ${item.parentType} ${item.parentName}.`,
            placement: "topRight",
          });
          handleRefresh && handleRefresh();
        });
      }
    })
  })
}

const LinkDrawer = (props: Props) => {
  const {parentId, parentSet, setParentSet, parentType, presetLinkType, setPresetLinkType, isOpen, toggleDrawerOpen,
    toggleWorkDrawer, toggleContactDrawer, toggleExpenseDrawer, toggleInventoryDrawer, toggleNoteDrawer, setEditWorkData,
    setEditContactData, setEditExpenseData, setEditInventoryData, setEditNoteData, toggleSpaceDrawer, setEditSpaceId, refreshParent,
    toggleTaskDrawer, setEditTaskData, toggleEventDrawer, setViewWorkId, toggleViewWorkDrawer, setAttachmentId, toggleViewFileDrawer} = props;

  const attachmentFilters: TSearchFilters = { dataTypes: ["contact", "expense", "inventory", "note", "work", "space", "upkeep"] };
  const workFilters: TSearchFilters = { dataTypes: ["attachment", "contact", "expense", "inventory", "note", "space", "upkeep"] };
  const noteFilters: TSearchFilters = { dataTypes: ["attachment", "contact", "expense", "inventory", "work", "space", "upkeep"] };
  const spaceFilters: TSearchFilters = { dataTypes: ["attachment", "contact", "expense", "inventory", "note", "work", "upkeep"] };
  const upkeepFilters: TSearchFilters = { dataTypes: ["attachment", "contact", "expense", "inventory", "note", "work", "space", "upkeep"] };
  const contactFilters: TSearchFilters = { dataTypes: ["attachment", "contact", "expense", "inventory", "note", "work", "space", "upkeep"] };

  const getSearchFilters = () => {
    if (parentType === "space") return { ...spaceFilters, properties: [propertyId]}
    if (parentType === "work") return {...workFilters, properties: [propertyId]}
    if (parentType === "note") return {...noteFilters, properties: [propertyId]}
    if (parentType === "attachment") return {...attachmentFilters, properties: [propertyId]}
    if (parentType === "contact") return {...contactFilters, properties: [propertyId]}
    if (upkeepTypes.includes(parentType)) return {...upkeepFilters, properties: [propertyId]}
    else return {}
  }

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const works = useSelector(getWorksSelector);
  const costs = useSelector(getExpensesSelector);
  const contacts = useSelector(getContactsSelector);
  const contents = useSelector(getInventoriesSelector);
  const notes = useSelector(getNotesByPropertyIdSelector);
  const files = useSelector(getAttachmentsSelector);
  const spaces = useSelector(getSpacesSelector);
  const maintenances = useSelector(getMaintenancesSelector);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [selectedType, setSelectedType] = useState("");
  const [globalSearchResults, setGlobalSearchResults] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [isNewRecordDrawerOpen, toggleNewRecordDrawer] = useState(false);
  const [parentLinks, setParentLinks] = useState<any>([]);
  const [linkedRecord, setLinkedRecord] = useState<any>(null);
  const [preSelectedType, setPreSelectedType] = useState<any>(null);

  useEffect(() => {
    if (presetLinkType) {
      setSelectedType(presetLinkType)
      setPreSelectedType(presetLinkType)
    }
  },[presetLinkType])

  useEffect(() => {
    if (isOpen && parentId && parentType === "space") {
      setLinkedRecord(spaces.content.filter(item => item.spaceId === parentId)[0])
    }
  }, [isOpen, spaces, parentId])

  useEffect(() => {
    if (isOpen && parentId && parentType === "work") {
      setLinkedRecord(works.content.filter(item => item.workId === parentId)[0])
    }
  }, [isOpen, works, parentId])

  useEffect(() => {
    if (isOpen && parentId && parentType === "attachment") {
      setLinkedRecord(files.content.filter(item => item.attachmentId === parentId)[0])
    }
  }, [isOpen, files, parentId])

  useEffect(() => {
    if (isOpen && parentId && parentType === "note") {
      setLinkedRecord(notes.filter(item => item.recordId === parentId)[0])
    }
  }, [isOpen, files, parentId])

  useEffect(() => {
    if (isOpen && parentId && upkeepTypes.includes(parentType)) {
      setLinkedRecord(maintenances.content.filter(item => item.id === parentId)[0])
    }
  }, [isOpen, maintenances, parentId])

  useEffect(() => {
    if (isOpen && parentId && parentType === "contact") {
      setLinkedRecord(contacts.content.filter(item => item.contactId === parentId)[0])
    }
  }, [isOpen, contacts, parentId])

  useEffect(() => {
    linkedRecord && parentType === "space" && getSpaceById(propertyId, linkedRecord.spaceId).then((res: any) => {
      setParentLinks([...res.works, ...res.expenses, ...res.inventories, ...res.contacts,
        ...res.attachments, ...res.spaceNotes, ...res.recurringExpenses, ...res.reminders, ...res.tasks])
    })
    linkedRecord && parentType === "work" && getWorkById(propertyId, linkedRecord.workId).then((res: any) => {
      setParentLinks([...res.spaces, ...res.works, ...res.expenses, ...res.inventories, ...res.contacts,
        ...res.attachments, ...res.workNotes, ...res.recurringExpenses, ...res.reminders, ...res.tasks])
    })
    linkedRecord && parentType === "note" && getNoteById(propertyId, linkedRecord.recordId).then((res: any) => {
      setParentLinks([...res.spaces, ...res.works, ...res.expenses, ...res.inventories, ...res.contacts,
        ...res.attachments, ...res.recurringExpenses, ...res.reminders, ...res.tasks])
    })
    linkedRecord && parentType === "attachment" && getAttachmentById(propertyId, linkedRecord.attachmentId).then((res: any) => {
      setParentLinks([...res.spaces, ...res.expenses, ...res.inventories, ...res.contacts, ...res.attachments,
        ...res.attachmentNotes, ...res.recurringExpenses, ...res.reminders, ...res.tasks])
    })
    linkedRecord && upkeepTypes.includes(parentType) && getUpcomingTaskById(propertyId, linkedRecord.id).then((res: any) => {
      setParentLinks([...res.spaces, ...res.works, ...res.expenses, ...res.inventories, ...res.contacts, ...res.attachments,
        ...res.notes, ...res.recurringExpenses, ...res.reminders, ...res.tasks])
    })
    linkedRecord && parentType === "contact" && getContactById(propertyId, linkedRecord.contactId).then((res: any) => {
      setParentLinks([...res.contactWorks, ...res.expenses,  ...res.attachments, ...res.spaces,  ...res.contacts, ...res.inventories,
        ...res.notes, ...res.recurringExpenses, ...res.reminders, ...res.tasks, ...res.payedExpenses])
    })
  }, [linkedRecord])

  useEffect(() => {
    if (searchQuery) {
      !selectedType && search({ query: searchQuery, filter: getSearchFilters(), page: 0, size: 10 })
        .then(res => {
          let results: any = []
          res?.data?.foundItemList.filter((item: any) => item.content.object.hasOwnProperty("standardTriggerId")
            && item.content.object.hasOwnProperty("subscribed") ? item.content.object.subscribed : true)
            .map((item: any) => results.push({...item.content.object, recordType: item.content.type}))
          setGlobalSearchResults(results)
        }).catch(e => {/* do nothing, it's caused by cancelling requests when user types fast and we need to cancel some*/})
    } else setGlobalSearchResults([])
  }, [searchQuery, selectedType])

  useEffect(() => {
    setSearchQuery("")
  }, [selectedType])

  const filteredItems = () => {
    if (globalSearchResults.length > 0) {
      return globalSearchResults
        .filter((item: any) => item.hasOwnProperty("expenseId") ?
          (item.parentId === parentId && item.parentType === "work" || !item.parentId) : true)
        .filter((item: any) => item.propertyId !== null)
    } else if (selectedType) {
      switch(selectedType) {
        case "work":
          return works.content.filter(item => item.name)
            .filter(item => parentType === "work" ? item.workId !== parentId : true)
            .filter(item => searchQuery ? item.name.toLowerCase().includes(searchQuery.toLowerCase()) : true)
            .sort((w1, w2) => w1.name.localeCompare(w2.name))
        case "expense":
          return costs.content.filter(item => item.name && item.isConfirmed)
            .filter(item => parentType === "work" ? (item.expenseRelations.filter((relation: any) =>
                relation.parentType === "work" && relation.parentId !== parentId).length === 0) : true)
            .filter(item => searchQuery ? item.name.toLowerCase().includes(searchQuery.toLowerCase()) : true)
            .sort((c1, c2) => moment(c2.paymentDate).valueOf() - moment(c1.paymentDate).valueOf())
        case "contact":
          return contacts.content.filter(item => item.name && item.contactType !== "Payee")
            .filter(item => searchQuery ? item.name.toLowerCase().includes(searchQuery.toLowerCase()) : true)
            .sort((c1, c2) => c1.name.localeCompare(c2.name))
        case "inventory":
          return contents.content.filter(item => item.name)
            .filter(item => searchQuery ? item.name.toLowerCase().includes(searchQuery.toLowerCase()) : true)
            .sort((i1, i2) => i1.name.localeCompare(i2.name))
        case "attachment":
          return files.content.filter(item => searchQuery ? item.name.toLowerCase().includes(searchQuery.toLowerCase()) : true)
            .sort((f1, f2) => moment(f2.uploadedDate).valueOf() - moment(f1.uploadedDate).valueOf())
        case "note":
          return notes.filter(item => item.title).
          filter(item => searchQuery ? item.title.toLowerCase().includes(searchQuery.toLowerCase()) : true)
            .sort((n1, n2) => moment(n2.createdDate).valueOf() - moment(n1.createdDate).valueOf())
        case "space":
          return spaces.content.filter(item => (item.name !== null && item.name.length > 1) ? searchQuery ? item.name.toLowerCase().includes(searchQuery.toLowerCase()) : true : false)
        case "upkeep":
          return maintenances.content.filter(item => item.subscribed)
            .filter(item => searchQuery ? item.title.toLowerCase().includes(searchQuery.toLowerCase()) : true)
        default:
          return []
      }
    } else return []
  }

  const getItemId = (item: any) => {
    if (item.hasOwnProperty("workId")) return item["workId"]
    if (item.hasOwnProperty("expenseId")) return item["expenseId"]
    if (item.hasOwnProperty("id")) return item["id"]
    if (item.hasOwnProperty("contactId")) return item["contactId"]
    if (item.hasOwnProperty("attachmentId")) return item["attachmentId"]
    if (item.hasOwnProperty("recordId")) return item["recordId"]
    if (item.hasOwnProperty("spaceId")) return item["spaceId"]
    else return null
  }

  const isItemSelected = (id: any) => selectedItems.filter((selectedItem: any) => selectedItem.id === id).length > 0
  const isSelectedByDefault = (id: any) => parentLinks.filter((selectedItem: any) => getItemId(selectedItem) === id).length > 0

  const toggleItemSelection = (item: any) => {
    if (isItemSelected(item.id)) {
      setSelectedItems((prevItems: any) => prevItems.filter((prevItem: any) => prevItem.id !== item.id))
    } else {
      setSelectedItems((prevItems: any) => [...prevItems, item])
    }
  }

  const handleCancel = () => {
    toggleDrawerOpen(false);
    setSelectedType("");
    setPreSelectedType(null);
    setPresetLinkType(null);
    setSearchQuery("");
    setSelectedItems([]);
    setPage(1);
    setLinkedRecord(null);
  }

  const performRefresh = () => {
    if (parentType === "space") dispatch(getAllSpaces(propertyId))
    if (parentType === "work") {dispatch(getAllWorks(propertyId)); dispatch(getAllExpenses(propertyId)); }
    if (parentType === "attachment") dispatch(getAllAttachments(propertyId));
    if (parentType === "note") dispatch(getAllNotesByPropertyId(propertyId));
    if (upkeepTypes.includes(parentType)) dispatch(getAllMaintenances(propertyId));
    if (parentType === "contact") dispatch(getAllContacts(propertyId));
    if (refreshParent) refreshParent();
  }

  const addNewLinks = async (itemsToLink: any) => {
    if (parentId) {
      for (let item of itemsToLink) {
        const newRelation = await addRelation({
          propertyId,
          parentId: parentId,
          parentType: parentType,
          childType: item.type,
          childId: item.id
        })
        if (newRelation.data && newRelation.data.suggestedMappings && newRelation.data.suggestedMappings.length > 0) {
          processSuggestedMappings(propertyId, newRelation.data.suggestedMappings, performRefresh)
        }
      }
      return Promise.resolve("OK");
    } else if (parentSet) {
      const parentPromises = Array.from(parentSet).map(async parentId => {
        const linkPromises = itemsToLink.map(async (item: any) => {
          const newRelation = await addRelation({
            propertyId,
            parentId: parentId,
            parentType: parentType,
            childType: item.type,
            childId: item.id
          })
          if (newRelation.data && newRelation.data.suggestedMappings && newRelation.data.suggestedMappings.length > 0) {
            processSuggestedMappings(propertyId, newRelation.data.suggestedMappings, performRefresh)
          }
          return newRelation;
        });
        return await Promise.all(linkPromises);
      })
      setParentSet && setParentSet(new Set());
      return await Promise.all(parentPromises);
    }
  }

  const handleSave = async () => {
    const links = await addNewLinks(selectedItems);
    if (links) {
      notification.success({
        message: "Linked successfully!",
        description: `All your items have been successfully linked.`,
        placement: "topRight",
      })
      toggleDrawerOpen(false)
      setSelectedType("")
      setPresetLinkType(null)
      setSelectedItems([])
      performRefresh()
    }
  }

  const getItems = () => filteredItems().filter((currentValue: any, index: any) =>
    index >= (page - 1) * 10 && index < page * 10);

  return <>
    <AddNewRecordDrawer
      isOpen={isNewRecordDrawerOpen}
      toggleDrawerOpen={toggleNewRecordDrawer}
      parentId={parentId}
      parentType={parentType}
      refreshParent={performRefresh}
      preSelectedType={preSelectedType}
      setPreSelectedType={setPreSelectedType}
    />
    <LinkDrawerStyled
      push={{distance: "32px"}}
      closeIcon={false}
      height={window.innerWidth > 768 ? "100%" : "85%"}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      visible={isOpen}
      onClose={() => handleCancel()}
      title={<>{(window.innerWidth <= 768 && selectedType) ? null :
        <DrawerHeader style={{justifyContent: "flex-start", marginBottom: "2rem"}}>
          {window.innerWidth <= 768 && <ReturnArrow onClick={() => handleCancel()}/>} Link
        </DrawerHeader>}
        <LinkedItemsHeader
          title={selectedType ? selectedType : linkedRecord ? linkedRecord.name : parentSet ? "List": "Linked items"}
          parentId={parentId}
          parentType={parentType}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          toggleLinkDrawerOpen={toggleDrawerOpen}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          toggleAddNewRecordDrawer={toggleNewRecordDrawer}
          toggleAddNewEventDrawer={toggleEventDrawer}
          setPreSelectedType={setPreSelectedType}
          refreshParent={performRefresh}
          clearItemSelection={() => setPage(1)}
        />
        </>}
      footer={<DrawerFooter>
        <ActionButtonWhite
          onClick={() => handleCancel()}>Cancel</ActionButtonWhite>
        <ActionButtonGreen
          disabled={selectedItems.length === 0}
          onClick={() => handleSave()}><LinkIcon/> Add links</ActionButtonGreen>
      </DrawerFooter>}
    >
      <LinkedItemsList
        parentId={parentId}
        parentType={parentType}
        items={getItems()}
        searchQuery={searchQuery}
        isSelectionMode={true}
        isItemSelected={isItemSelected}
        isSelectedByDefault={isSelectedByDefault}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        toggleItemSelection={toggleItemSelection}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditNoteData}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleSpaceDrawer}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditTaskData}
        refreshParent={performRefresh}
        setViewWorkId={setViewWorkId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      {filteredItems().length > 10 && <PaginationStyled
        className={filteredItems().length < 10 ? "single-page-pagination" : ""}
        onChange={(page) => setPage(page)}
        simple={window.innerWidth < 1024}
        showSizeChanger={false}
        itemRender={paginationButtons}
        defaultCurrent={1}
        current={page}
        total={filteredItems().length}
        defaultPageSize={10}
      />}
    </LinkDrawerStyled>
  </>
}

const LinkDrawerStyled = styled(DrawerStyled)`
  // .ant-drawer-content-wrapper {
  //     height: 100%;
  //     bottom: 0!important;
  // }
  
  .ant-drawer-body {
    padding: 0 0.5rem 0.25rem 0;
    margin: 0.5rem 0 1.5rem 0!important;
  }
`

export default LinkDrawer