import React from "react";
import styled from "styled-components";
import {ReactComponent as InfoIcon} from "../../../images/dashboard-contents/InfoIcon.svg";
import {Dropdown} from "antd";

type Props = {
  description: string;
}

export const InfoDropdown = (props: Props) => {
  const {description} = props;

  return <Dropdown
    className={"dashboard-info-icon"}
    placement="topRight"
    trigger={['hover']}
    overlay={<DropdownOverlay>
      <span style={{fontWeight: 600}}>What is this?</span>
      <span>{description}</span>
    </DropdownOverlay>}>
    <InfoIcon />
  </Dropdown>
}

export const DropdownOverlay = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 19.75rem;
  background: #236EC2;
  box-shadow: 0px 2px 4px 1px rgba(30, 32, 37, 0.05), 0px 5px 12px rgba(26, 28, 31, 0.12);
  border-radius: 8px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 1rem;
  color: white;
`

export default InfoDropdown;