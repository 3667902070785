import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {Guid} from "guid-typescript";
import _ from "lodash";
import {getDocuments, TGetDocumentsResponse} from "../../api-wrapper/documents/getDocuments";

export const getSurveysInitAction = createAction("surveys/GET_SURVEYS_INIT")();
export const getSurveysSuccessAction = createAction("surveys/GET_SURVEYS_SUCCESS")<TGetDocumentsResponse>();
export const getSurveysFailureAction = createAction("surveys/GET_SURVEYS_FAILURE")();

export type TGetSurveysInitAction = ActionType<typeof getSurveysInitAction>;
export type TGetSurveysSuccessAction = ActionType<typeof getSurveysSuccessAction>;
export type TGetSurveysFailureAction = ActionType<typeof getSurveysFailureAction>;

export type TGetSurveysActions =
  | TGetSurveysInitAction
  | TGetSurveysSuccessAction
  | TGetSurveysFailureAction;

export type TGetSurveysThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetSurveysActions>;

export const getAllSurveys: TGetSurveysThunkActionType = (propertyId: Guid) => (
  dispatch: (action: Action | Promise<Action>) => any
) => {
  dispatch(getSurveysInitAction());
  return getDocuments(propertyId, "surveys")
    .then((payload) => {
      let surveys = _.orderBy(payload, ['documentDate'], ['desc'])
      return dispatch(getSurveysSuccessAction(surveys));
    })
    .catch(() => dispatch(getSurveysFailureAction()));
};