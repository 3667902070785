import {Guid} from "guid-typescript";
import {apiGet, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {TGetContactsResponse} from "../contacts/getContacts";

export type CertificateAttachment = {
  attachmentId: Guid;
  propertyId: Guid;
  parentId: Guid;
  parentType: string;
  storageKey: string;
  name: string;
  size: number;
  path: string;
}

export type TGetCertificatesResponse = Array<{
  propertyId: Guid;
  certificateId: Guid;
  certificateDate: string;
  endOfValidity: string;
  certificateType: string;
  amountPaid: number;
  certificateNumber: number;
  confirmed: boolean;
  attachments: Array<CertificateAttachment> | [];
  contacts: TGetContactsResponse;
}>

export const getCertificates = (propertyId: Guid) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/certificates`);

  return apiGet<TGetCertificatesResponse | []>(url, {}, propertyId);
};