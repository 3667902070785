import React, {useEffect, useState} from "react";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";

import {Spin} from "antd";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getAttachmentsSelector, getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import {ReactComponent as BackArrow} from "../../../images/spaces/ReturnArrow.svg";
import _ from "lodash";
import {
  ActionButtonGreen,
  ActionButtonWhite,
  DrawerFooter, DrawerHeader,
  EditDrawerStyled, FormStyled, CircleIcon
} from "../../screens/components";
import {getAllAttachments} from "../../actions/attachments";
import {Guid} from "guid-typescript";
import {LoadingOutlined} from "@ant-design/icons";
import FileItem from "./FileItem";
import {ReactComponent as AddTagsIcon} from "../../../images/attachments/AddTags.svg";
import {ReactComponent as AddLinksIcon} from "../../../images/attachments/AddLinks.svg";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg: boolean) => void;
  newlyAttached: Guid;
  setNewlyAttached: (arg: any) => void;
  refreshParent?: () => void;
  uploadInProgress: Array<string>;
  setUploadInProgress: (arg: Array<string>) => void;

  selectedTags: Array<any>;
  editTagsMode: boolean;
  toggleEditTagsMode: (arg: boolean) => void;
  toggleForwardDrawer: (arg: boolean) => void;
  setAttachmentToForward: (arg: Guid) => void;

  setInvoiceData: (arg: any) => void;
  toggleExpenseDrawer: (arg: boolean) => void;
  setExpenseData: (arg: any) => void;

  toggleVieFileDrawer?: (arg: boolean) => void | null;
  setAttachmentToViewId: (arg: any) => void | null;

  selectedFiles: Set<Guid>;
  setSelectedFiles: (arg: Set<Guid>) => void;
  toggleAssignTagDrawerOpen: (arg: boolean) => void;
  toggleLinkDrawer: (arg: boolean) => void;
  toggleEditDrawerOpen: (arg: boolean) => void | null;
}

const NewlyUploadedDrawer = (props: Props) => {
  const {
    isOpen,
    toggleDrawerOpen,
    refreshParent,
    newlyAttached,
    setNewlyAttached,
    uploadInProgress,
    setUploadInProgress,
    setAttachmentToViewId, selectedFiles, setSelectedFiles, toggleEditDrawerOpen,
    setAttachmentToForward, toggleForwardDrawer, toggleVieFileDrawer, toggleAssignTagDrawerOpen, toggleLinkDrawer,
    selectedTags, toggleExpenseDrawer, setExpenseData, toggleEditTagsMode, setInvoiceData, editTagsMode
  } = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const attachments = useSelector(getAttachmentsSelector);

  const [newlyAttachedIds, setNewlyAttachedIds] = useState<any>([]);
  const [newlyAttachedDetails, setNewlyAttachedDetails] = useState<Map<Guid, any>>(new Map());
  const [inUploadDetails, setInUploadDetails] = useState<any>([]);
  const [nameMap, setNameMap] = useState(new Map())
  const [nameInEditId, setNameInEditId] = useState("");

  useEffect(() => {
    if (newlyAttached) {
      setNewlyAttachedIds([...newlyAttachedIds, newlyAttached]);
    }
  }, [newlyAttached])

  useEffect(() => {
    if (!isOpen)
      return;

    setSelectedFiles(new Set());
    newlyAttachedIds.forEach((newlyAttachedId: Guid) => {
      let details = attachments.content.filter(attachment => attachment.attachmentId === newlyAttachedId);
      if (details && details.length > 0) {
        newlyAttachedDetails.set(details[0].attachmentId, details[0]);
        setNewlyAttachedDetails(new Map(newlyAttachedDetails));
      }
    });
  }, [newlyAttachedIds, attachments, isOpen])

  useEffect(() => {
    const uploadedNames = new Set(Array.from(newlyAttachedDetails.values()).map(details => details.name.toLowerCase()));
    setInUploadDetails(inUploadDetails.filter((inProgressName: string) => !uploadedNames.has(inProgressName.toLowerCase())));
    Array.from(newlyAttachedDetails.values()).map(att => nameMap.set(att.attachmentId, att.name.toLowerCase()));
    setNameMap(new Map(nameMap));
  }, [newlyAttachedDetails])

  useEffect(() => {
    if (uploadInProgress) {
      setInUploadDetails(uploadInProgress.filter(fileName => !inUploadDetails.map((details: any) => details.fileName).includes(fileName)));
    }
  }, [uploadInProgress])

  const performCleanup = () => {
    dispatch(getAllAttachments(propertyId));
    refreshParent && refreshParent();
    setNewlyAttached([]);
    setNewlyAttachedDetails(new Map());
    setUploadInProgress([]);
    setInUploadDetails([]);
    setNewlyAttachedIds([]);
    toggleDrawerOpen(false);
    setSelectedFiles(new Set());
  }

  const handleCancel = () => {
    performCleanup();
  }

  return <EditDrawerStyled
      style={{zIndex: 10}}
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "100%" : "85%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      visible={isOpen}
      onClose={() => handleCancel()}
      title={<DrawerHeader style={{flexDirection: "row", alignItems:"flex-start", rowGap:"0.5rem"}}>
        {window.innerWidth <= 768 && <BackArrow style={{cursor: "pointer", flexShrink: 0}} onClick={() => handleCancel()}/>}
        Upload queue
        {window.innerWidth > 768 && <CircleIcon onClick={() => handleCancel()}><CloseIcon /></CircleIcon>}
      </DrawerHeader>}
    >
     <div style={{width:"100%"}}>
      <EditSpaceForm>
        <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
        {inUploadDetails.map((file: any) =>
          <div key={"inProgressFile"+file} style={{width:"100%", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>
            <span style={{fontSize:"1.25rem", fontWeight: 700}}>{file}</span>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
          </div>)}
        </div>

        <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
        {Array.from(newlyAttachedDetails.values()).map((file: any) =>
            <FileItem
              item={file}
              onDrawer={true}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              nameMap={nameMap}
              setNameMap={setNameMap}
              nameInEditId={nameInEditId}
              setNameInEditId={setNameInEditId}
              selectedTags={selectedTags}
              editTagsMode={editTagsMode}
              toggleEditTagsMode={toggleEditTagsMode}
              toggleForwardDrawer={toggleForwardDrawer}
              setAttachmentToForward={setAttachmentToForward}
              setInvoiceData={setInvoiceData}
              toggleExpenseDrawer={toggleExpenseDrawer}
              setExpenseData={setExpenseData}
              toggleVieFileDrawer={toggleVieFileDrawer}
              setAttachmentToViewId={setAttachmentToViewId}
              setAttachmentToEditId={setAttachmentToViewId}
              toggleEditDrawerOpen={toggleEditDrawerOpen}
            />
        )}
        </div>
      </EditSpaceForm>

       {selectedFiles.size > 0
         ?<DrawerFooter className={"fake-footer"}>
           <ActionButtonGreen onClick={() => toggleAssignTagDrawerOpen(true)}><AddTagsIcon/> Add tags</ActionButtonGreen>
           <ActionButtonGreen onClick={() => {setAttachmentToViewId(null); toggleLinkDrawer(true)}}><AddLinksIcon/> Add links</ActionButtonGreen>
         </DrawerFooter>
         : <></>
         }

     </div>
    </EditDrawerStyled>
}

const EditSpaceForm = styled.div`
  width: 100%;
`

export default NewlyUploadedDrawer