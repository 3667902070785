import {apiPost, apiPut, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

type TPutDetailsRequest = Array<{
  id: number;
  answer?: string | number | null;
  answerId?: number | null;
}>;

export const putDetails = (propertyId: Guid, payload: TPutDetailsRequest, updateValuation?: boolean) => {
  let requestParam = "";
  if (updateValuation)
    requestParam = "?updateValuation=true";
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/details${requestParam}`);

  return apiPut(url, payload);
};
