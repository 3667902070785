import React, {useEffect, useState} from "react";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";

import {Dropdown} from "antd";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {ReactComponent as BackArrow} from "../../../images/spaces/ReturnArrow.svg";
import _ from "lodash";
import moment from "moment";
import {
  ActionButtonGreen,
  DrawerFooter, DrawerHeader,
  EditDrawerStyled, CircleIcon
} from "../../screens/components";
import {Guid} from "guid-typescript";
import {FileItemSize, FileItemTitle, FileItemTitleWrapper} from "./FileItem";
import {ReactComponent as AddLinksIcon} from "../../../images/attachments/AddLinks.svg";
import {RadioStyled} from "../navigation/Header";
import {fileTypeIcon} from "../../screens/Files";
import {niceBytes} from "../helper";
import Ellipsis from "ant-design-pro/lib/Ellipsis";
import {ReactComponent as SelectPropertyArrow} from "../../../images/homepage-june/SelectPropertyArrow.svg";
import {assignAttachmentProperty} from "../../../api-wrapper/attachments/assignAttachmentProperty";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg: boolean) => void;
  attachmentsWithoutProperty: Array<any>;
  refreshParent?: () => void;
  preSelectedFiles?: any;
  clearSelection?: () => void;
  toggleViewFileDrawer: (arg: boolean) => void;
  setViewFileDrawerDefaultTab: (arg: any) => void;
  setAttachmentToPreviewId: (arg: any) => void;
}

const AssignMultiPropertyDrawer = (props: Props) => {
  const {
    isOpen,
    toggleDrawerOpen,
    refreshParent,
    attachmentsWithoutProperty,
    preSelectedFiles,
    clearSelection,
    toggleViewFileDrawer,
    setViewFileDrawerDefaultTab,
    setAttachmentToPreviewId
  } = props;

  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [selectedFiles, setSelectedFiles] = useState<Set<Guid>>(new Set());
  const [selectedProperties, setSelectedProperties] = useState<Map<Guid, Guid>>(new Map());

  useEffect(() => {
    preSelectedFiles && setSelectedFiles(new Set(preSelectedFiles));
  }, [preSelectedFiles])

  const performCleanup = () => {
    refreshParent && refreshParent();
    toggleDrawerOpen(false);
    setSelectedFiles(new Set());
    setSelectedProperties(new Map());
    clearSelection && clearSelection();
  }

  const handleCancel = () => {
    performCleanup();
  }

  const handleLinkMultiProperty = async () => {
    const assignPromises = Array.from(selectedFiles).map(async (attachmentId) => {
      if (selectedProperties.get(attachmentId)) {
        // @ts-ignore
        return await assignAttachmentProperty(selectedProperties.get(attachmentId), attachmentId);
      } else {
        return await assignAttachmentProperty(propertyId, attachmentId);
      }
    });
    await Promise.all(assignPromises);
    handleCancel();
  }

  const LineItem = (item: any) => {
    const previewFile = () => {
      setAttachmentToPreviewId(item.attachmentId);
      setViewFileDrawerDefaultTab("previewOnly");
      toggleViewFileDrawer(true);
    }

    const selectFile = (e: any, force: boolean) => {
      e && e.stopPropagation();
      if (!selectedFiles || !setSelectedFiles)
        return;

      if (selectedFiles.has(item.attachmentId) && !force) {
        selectedFiles.delete(item.attachmentId);
      } else {
        selectedFiles.add(item.attachmentId);
      }

      setSelectedFiles(new Set(selectedFiles));
    }

    const isFileSelected = () => {
      if (!selectedFiles || !setSelectedFiles)
        return false;
      return selectedFiles.has(item.attachmentId);
    }

    const propertiesDropdown = () => (
      <PropertySelectionList>
        {properties.map(property => <PropertySelectionListItem
          onClick={() => {
            setSelectedProperties(new Map(selectedProperties.set(item.attachmentId, property.propertyId)));
            selectFile(null, true);
          }}
        >
          {property.address + (property.city ? `, ${property.city}` : "") + (property.zip ? ` ${property.zip}` : "")}
        </PropertySelectionListItem>)}
      </PropertySelectionList>
    )

    const currentPropertyAddress = () => {
      let currentProperty = properties.find((property) => property.propertyId === selectedProperties.get(item.attachmentId));
      if (!currentProperty)
        currentProperty = properties.find((property) => property.propertyId === propertyId);
      if (!currentProperty)
        return "";
      return currentProperty.address + (currentProperty.city ? `, ${currentProperty.city}` : "")
        + (currentProperty.zip ? ` ${currentProperty.zip}` : "");
    }

    return <div
      key={item.id}
      style={{display:"flex", justifyContent: "flex-start", flexDirection: "row", alignItems: "center", width: "100%", margin:"0.25rem 0"}}
    >
      <div
        onClick={(e) => selectFile(e, false)}
      >
        <RadioStyled checked={isFileSelected()}/>
      </div>
      <div style={{display:"flex", justifyContent: "space-between", flexDirection: "column", alignItems: "flex-start", background:"#F5F6FA", borderRadius:"8px", width: "100%", padding: "1.5rem 1rem", rowGap: "0.75rem"}}>
        <div style={{display:"flex", justifyContent: "flex-start", flexDirection: "row", columnGap: "1rem", alignItems: "center", width: "100%"}}>
          {(_.isNil(item.thumbnailPath) || _.isEmpty(item.thumbnailPath)) ? <div style={{cursor: "pointer"}} onClick={() => previewFile()}>{fileTypeIcon(item)}</div>
            : <div style={{width:"3rem", cursor: "pointer"}} onClick={() => previewFile()}><img className="circle-image-cropped" alt="" src={item.thumbnailPath}
                 onError={(e) => {
                   // @ts-ignore
                   e.target.onError = null;
                   // @ts-ignore
                   e.target.src = "/images/file.png"
                 }}/></div>}

          <FileItemTitleWrapper style={{width: "100%", rowGap: "0.1rem"}}>
            <FileItemTitle onClick={() => previewFile()}>
              <Ellipsis lines={1}>{item.name}</Ellipsis>
            </FileItemTitle>
            <FileItemSize>{niceBytes("" + item.size)}</FileItemSize>
            <FileItemSize>Received {moment(item.uploadedDate).format(propertyPreferences.preferredDateFormat)}</FileItemSize>
          </FileItemTitleWrapper>
        </div>

        <Dropdown overlay={propertiesDropdown()} placement={"bottomCenter"} trigger={["click"]}>
          <PropertySelectionDropdown>
            {currentPropertyAddress()} <SelectPropertyArrow />
          </PropertySelectionDropdown>
        </Dropdown>
      </div>
    </div>;
  }

  return <EditDrawerStyled
      style={{zIndex: 10}}
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      height={window.innerWidth > 768 ? "100%" : "85%"}
      placement={window.innerWidth > 768 ? "right" : "bottom"}
      visible={isOpen}
      onClose={() => handleCancel()}
      title={<DrawerHeader style={{flexDirection: "row", alignItems:"flex-start", rowGap:"0.5rem"}}>
        {window.innerWidth <= 768 && <BackArrow style={{cursor: "pointer", flexShrink: 0}} onClick={() => handleCancel()}/>}
        <div style={{display: "flex", flexDirection: "row", alignItems:"center"}}>
          <span style={{fontSize:"1.75rem"}}>Received documents</span>
          <span style={{fontSize:"1.125rem", color:"#009966", fontWeight: 600, marginLeft:"1rem"}}>{attachmentsWithoutProperty.length} Document{attachmentsWithoutProperty.length > 1 && "s"}</span>
        </div>
        {window.innerWidth > 768 && <CircleIcon onClick={() => handleCancel()}><CloseIcon /></CircleIcon>}
      </DrawerHeader>}
      footer={<DrawerFooter className={"fake-footer"}>
        <ActionButtonGreen onClick={() => handleLinkMultiProperty()}><AddLinksIcon/> Assign property to selected</ActionButtonGreen>
      </DrawerFooter>}
    >
     <div style={{width:"100%"}}>
      <EditSpaceForm>
        <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
          {attachmentsWithoutProperty.map((file: any) => LineItem(file))}
        </div>
      </EditSpaceForm>
     </div>
    </EditDrawerStyled>
}

const EditSpaceForm = styled.div`
  width: 100%;
`

export const PropertySelectionDropdown = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.75rem;
  gap: 0.5rem;
  height: 2rem;
  
  border: 1px solid #009966;
  background: #FFFFFF;
  border-radius: 8px;
  
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #009966;
  
  @media (max-width: 768px) {
    border: 1px solid #00CC33;
    border-radius: 4px;
  }
`

export const PropertySelectionList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
  background: #FFFFFF;
  border: 1px solid #DAE0E6;
  box-shadow: 0px 1px 5px rgba(30, 32, 37, 0.05), 0px 10px 24px rgba(26, 28, 31, 0.1);
  border-radius: 0px 0px 10px 10px;
  width: 100%;
`

export const PropertySelectionListItem = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #45655E;
  border-bottom: 1px solid #EFF1F5;
  
  :hover {
    color: #21272B;
    background: #F5F6FA;
    border-radius: 8px;
    border-bottom: 1px solid white;
    transition: background .5s ease;
  }
  
  :last-child {
    border-bottom: 1px solid white;
  }
`

export default AssignMultiPropertyDrawer