import { createSelector } from "reselect";

import { TRootState } from "./reducers";

const _getAuth = (state: TRootState) => state.auth;
const _getProperties = (state: TRootState) => state.properties;
const _getPropertyId = (state: TRootState) => state.propertyId;
const _getPropertyPreferences = (state: TRootState) => state.propertyPreferences;
const _getWorks = (state: TRootState) => state.works;
const _getExpenses = (state: TRootState) => state.expenses;
const _getConfirmedExpenses = (state: TRootState) => state.confirmedExpenses;
const _getInventories = (state: TRootState) => state.inventories;
const _getContacts = (state: TRootState) => state.contacts;
const _getResetUpdate = (state: TRootState) => state.resetUpdate;
const _getTenants = (state: TRootState) => state.tenants;
const _getUserProfile = (state: TRootState) => state.userProfile;
const _getNotesByPropertyId = (state: TRootState) => state.notesByPropertyId;
const _getUpcomingTasks = (state: TRootState) => state.upcomingTasks;
const _getExpenseReport = (state: TRootState) => state.expenseReport;
const _getInventoryReport = (state: TRootState) => state.inventoryReport;
const _getMaintenances = (state: TRootState) => state.maintenances;
const _getOnboardingMaintenances = (state: TRootState) => state.onboardingMaintenances;
const _getImprovements = (state: TRootState) => state.improvements;
const _getRecommendedImprovements = (state: TRootState) => state.recommendedImprovements;
const _getInsurances = (state: TRootState) => state.insurances;
const _getAttachments = (state: TRootState) => state.attachments;
const _getTags = (state: TRootState) => state.tags;
const _getExpenseTypes = (state: TRootState) => state.expenseTypes;
const _getCalendarTasks = (state: TRootState) => state.calendarTasks;
const _getTagsWithUsageCount = (state: TRootState) => state.tagsWithUsageCount;
const _getTagsWithUsageCountByType = (state: TRootState) => state.tagsWithUsageCountByType;
const _getStorageUsage = (state: TRootState) => state.storageUsage;
const _getNews = (state: TRootState) => state.news;
const _getBudgetCosts = (state: TRootState) => state.budgetCosts;
const _getSubscriptionUsers = (state: TRootState) => state.subscriptionUsers;
const _getCertificates = (state: TRootState) => state.certificates;
const _getSpaces = (state: TRootState) => state.spaces;
const _getSurveys = (state: TRootState) => state.surveys;
const _getFloorPlans = (state: TRootState) => state.floorPlans;
const _getMortgages = (state: TRootState) => state.mortgages;
const _getPets = (state: TRootState) => state.pets;

export const authSelector = createSelector(_getAuth, (auth) => auth);
export const userNameSelector = createSelector(
  _getAuth,
  (auth) => auth.userName
);

export const getPropertiesSelector = createSelector(
  _getProperties,
  (properties) => properties.content
);

export const getPropertiesWithFetchingStatusSelector = createSelector(
  _getProperties,
  (properties) => properties
);

export const getNotesByPropertyIdSelector = createSelector(
  _getNotesByPropertyId,
  (notes) => notes.content
);

export const getContactsSelector = createSelector(
  _getContacts,
  (contacts) => contacts
);

export const getPropertyIdSelector = createSelector(
  _getPropertyId,
  (propertyId) => propertyId
);

export const getPropertyPreferencesSelector = createSelector(
  _getPropertyPreferences,
  (propertyPreferences) => propertyPreferences
);

export const getWorksSelector = createSelector(
  _getWorks,
  (works) => works
);

export const getExpensesSelector = createSelector(
  _getExpenses,
  (expenses) => expenses
);

export const getConfirmedExpensesSelector = createSelector(
  _getConfirmedExpenses,
  (confirmedExpenses) => confirmedExpenses
);

export const getInventoriesSelector = createSelector(
  _getInventories,
  (inventories) => inventories
);

export const getResetUpdateSelector = createSelector(
  _getResetUpdate,
  (resetUpdate) => resetUpdate
);

export const getTenantsSelector = createSelector(
  _getTenants,
  (tenants) => tenants
);

export const getUpcomingTasksSelector = createSelector(
  _getUpcomingTasks,
  (reminders) => reminders
)


export const getUserProfileSelector = createSelector(
  _getUserProfile,
  (userProfile) => userProfile
);

export const getExpenseReportSelector = createSelector(
  _getExpenseReport,
  (expenseReport) => expenseReport
);

export const getInventoryReportSelector = createSelector(
  _getInventoryReport,
  (inventoryReport) => inventoryReport
);

export const getMaintenancesSelector = createSelector(
  _getMaintenances,
  (maintenances) => maintenances
);

export const getOnboardingMaintenancesSelector = createSelector(
  _getOnboardingMaintenances,
  (onboardingMaintenances) => onboardingMaintenances
);

export const getImprovementsSelector = createSelector(
  _getImprovements,
  (improvements) => improvements
);

export const getInsurancesSelector = createSelector(
  _getInsurances,
  (insurances) => insurances
);

export const getAttachmentsSelector = createSelector(
  _getAttachments,
  (attachments) => attachments
);

export const getTagsSelector = createSelector(
  _getTags,
  (tags) => tags
);

export const getExpenseTypesSelector = createSelector(
  _getExpenseTypes,
  (expenseTypes) => expenseTypes
);

export const getRecommendedImprovementsSelector = createSelector(
  _getRecommendedImprovements,
  (recommendedImprovements) => recommendedImprovements
)

export const getCalendarTasksSelector = createSelector(
  _getCalendarTasks,
  (calendarTasks) => calendarTasks
)

export const getTagsWithUsageCountSelector = createSelector(
  _getTagsWithUsageCount,
  (tagsWithUsageCount) => tagsWithUsageCount
);

export const getTagsWithUsageCountByTypeSelector = createSelector(
  _getTagsWithUsageCountByType,
  (tagsWithUsageCountByType) => tagsWithUsageCountByType
);

export const getStorageUsageSelector = createSelector(
  _getStorageUsage,
  (storageUsage) => storageUsage
);

export const getNewsSelector = createSelector(
  _getNews,
  (news) => news
);

export const getBudgetCostsSelector = createSelector(
  _getBudgetCosts,
  (budgetCosts) => budgetCosts
);

export const getSubscriptionUsersSelector = createSelector(
  _getSubscriptionUsers,
  (subscriptionUsers) => subscriptionUsers
);

export const getCertificatesSelector = createSelector(
  _getCertificates,
  (certificates) => certificates
);

export const getSpacesSelector = createSelector(
  _getSpaces,
  (spaces) => spaces
);

export const getSurveysSelector = createSelector(
  _getSurveys,
  (surveys) => surveys
);

export const getFloorPlansSelector = createSelector(
  _getFloorPlans,
  (floorPlans) => floorPlans
);

export const getMortgagesSelector = createSelector(
  _getMortgages,
  (mortgages) => mortgages
);

export const getPetsSelector = createSelector(
  _getPets,
  (pets) => pets
);

