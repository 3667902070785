import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import _ from "lodash";

export type TEditPetRequest = {
  propertyId: Guid,
  name: string,
  petType: string
};

export const updatePet = (payload: TEditPetRequest & { petId: Guid }) => {
  const { petId } = payload;
  const url = createUrl(`${EnvConfig.API_URL}/api/pet/${petId}/update`);

  return apiPut<any, any>(url, _.omit(payload, ["petId"]));
};

export const updatePetWithImage = (payload: { petCreateDto: TEditPetRequest, petId: Guid, petImage: any }) => {
  const { petId } = payload;
  const url = createUrl(`${EnvConfig.API_URL}/api/pet/${petId}/updateWithImage`);

  let bodyFormData = new FormData();
  bodyFormData.append('petCreateDto',
    new Blob([JSON.stringify(payload.petCreateDto)], {type: 'application/json; charset=UTF-8'}));
  bodyFormData.append("petImage", payload.petImage);

  return apiPut<any, any>(url, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
