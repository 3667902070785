import {apiPut, createUrl} from "../apiCall";
import EnvConfig from "../../config";

export const dismissUserWarnings = (warningIds: string) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/userWarnings/shown/?warningsIds=${warningIds}`
  );

  return apiPut(url);
};
