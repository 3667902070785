import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import _ from "lodash";

export type TGetDocumentsResponse = Array<{
  name: string,
  documentDate: string,
  documentType: string,
  description: string,
  category: string,
  relatedFileId: Guid,
  documentId: Guid,
  subscriptionId: number,
  propertyId: Guid
}>

export type TDocumentRequest = {
  name: string,
  documentDate: string | null,
  documentType: string,
  description: string,
  relatedFileId: Guid | null;
}

export const addDocument = (payload: TDocumentRequest & {propertyId: Guid, category: string}) => {
  const { propertyId, category } = payload;
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/document/${category}`);

  return apiPost<any, TDocumentRequest>(url, _.omit(payload, ["propertyId", "category"]));
};
