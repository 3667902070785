import React, {useEffect, useState} from "react"
import styled from "styled-components";
import {capitalize, numberWithCommasFull, ReadMoreReadLess} from "../helper";
import MaintenanceCost, {EditableValueWrapper} from "./editableParams/MaintenanceCost";
import MaintenanceFrequency from "./editableParams/MaintenanceFrequency";
import MaintenanceStartDate from "./editableParams/MaintenanceStartDate";
import {ReactComponent as RecommendedImprovementIcon} from "../../../images/RecommendedImprovementIcon.svg";
import { ReactComponent as CancelIcon } from "../../../images/CancelIcon.svg";
import {Dropdown, Modal} from "antd";
import moment from "moment";
import {ReactComponent as MaintenanceCalendar} from "../../../images/MaintenanceCalendar.svg";
import {ReactComponent as ReadMoreArrow} from "../../../images/ReadMoreArrow.svg";
import MaintenanceSwitch from "./editableParams/MaintenanceSwitch";
import MaintenanceMoreMenu from "./editableParams/MaintenanceMoreMenu";
import MaintenanceSaveButton from "./editableParams/MaintenanceSaveButton";
import {Guid} from "guid-typescript";
import {Overlay} from "../../screens/Home";
import MaintenanceRemove from "./editableParams/MaintenanceRemove";
import {useSelector} from "react-redux";
import {getPropertyPreferencesSelector} from "../../selectors";
import {getCurrencySign} from "../../screens/helpers";

type Props = {
  item: any;
  editing: string;
  setEditing: (arg: string) => void;
  isOnboarding: boolean;
  toggleViewWorkDrawer?: (arg: boolean) => void;
  setViewWorkId?: (arg: any) => void;
  toggleExpenseDrawer?: (arg: boolean) => void;
  setEditingExpenseData?: (arg: any) => void;
  toggleUpkeepDrawer: (arg: boolean) => void;
  setEditingUpkeepData: (arg: any) => void;
  toggleSubscribe?: (id: Guid, isSubscribed: boolean) => void;
  updateMaintenance?: (id: Guid, newValues: any) => void;
}

export const getStartDate = (item: any) => {
  if (item.startDate && item.subscribed || item.startDate && moment(item.startDate).isSameOrAfter(moment())) {
    return item.startDate
  }
  else if (item.startDateTemplate) {
    let currentYear = moment().year()
    return moment(item.startDateTemplate).add(item.startDateTemplateMonthToAdd ?? 0, "months")
      .set("year", currentYear).format("YYYY-MM-DD")
  }
  else {
    return moment().format("YYYY-MM-DD")

  }
}

export const getDate = (item: any) => {
  if ((item.subscribed && item.nextDate) ||
    (!item.subscribed && moment(item.nextDate).isSameOrAfter(moment()))) return item.nextDate
  else return getStartDate(item)
}

export const isOnce = (item: any) => {
  if (item.actualPeriodicityUnit === "once") return true
  if (!item.actualPeriodicityUnit && item.periodicityUnit === "once") return true
  else return false
}

export const isCustom = (item: any) => item.category === "User-created" || item.category === "Recurring expense - User-created" || item.category === "Reminder"
export const isCustomReminder = (item: any) => (item.category === "Reminder")

const MaintenanceListItemNew = (props: Props) => {
  const { item, toggleViewWorkDrawer, setViewWorkId, toggleUpkeepDrawer, setEditingUpkeepData,
    editing, setEditing, isOnboarding, toggleSubscribe } = props;

  const [itemCost, setItemCost] = useState(0)
  const [itemFrequency, setItemFrequency] = useState(1)
  const [itemFrequencyUnit, setItemFrequencyUnit] = useState("")
  const [itemStartDate, setItemStartDate] = useState("")
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const setDefaults = (item: any) => {
    setItemCost(item.actualEstimatedCost ?? item.estimatedCost ?? 0)
    setItemFrequency(item.actualPeriodicity ?? item.periodicity)
    setItemFrequencyUnit(item.actualPeriodicityUnit ?? item.periodicityUnit)
    setItemStartDate(getStartDate(item))
  }

  useEffect(() => {
    setDefaults(item)
  }, [item])

  const handleReset = () => {
    setDefaults(item)
    setEditing("")
  }

  const editItem = (item: any) => {
    if (editing && item.id !== editing) {
      Modal.error({
        title: "Error",
        content: "You're already editing another item right now.",
        className: "form-confirm-close-modal"
      });
      return
    }
    if (!editing && !isCustom(item)) {
      setEditing(item.id)
    }
    if (!editing && isCustom(item)) {
      setEditingUpkeepData(item);
      toggleUpkeepDrawer(true);
    }
  }

  const itemTitleAndDescription = (item: any) => (
    <div style={{display: "flex", flexDirection: "column", rowGap: "0.5rem"}}>
      <ItemTitle style={{color: item.recommended ? "#2A80E1" : "#45655E"}}>
        {item.recommended && <RecommendedImprovementIcon style={{flexShrink: 0}}/>} {item.title}
      </ItemTitle>
      <ReadMoreReadLess
        charLimit={45}
        readMoreText={<ReadMoreText>Read more <ReadMoreArrow style={{marginLeft: '0.3rem'}} /></ReadMoreText>}
        readLessText={<ReadMoreText>Hide all <ReadMoreArrow style={{marginLeft: '0.3rem', transform: 'scaleY(-1)'}} /></ReadMoreText>}>
        {item.message !== null ? item.message : ""}
      </ReadMoreReadLess>
    </div>
  )

  const moreMenu = () => <MaintenanceMoreMenu
    item={item}
    isOnboarding={isOnboarding}
    editItem={editItem}
    setViewWorkId={setViewWorkId}
    toggleViewWorkDrawer={toggleViewWorkDrawer}
  />

  const saveButton = () => <MaintenanceSaveButton
    item={item}
    itemFrequency={itemFrequency}
    itemFrequencyUnit={itemFrequencyUnit}
    itemCost={itemCost}
    itemStartDate={itemStartDate}
    isOnboarding={isOnboarding}
    setEditing={setEditing} />

  const listItemCost = (item: any) => <>
    <Dropdown placement="bottomCenter" overlay={isOnboarding ? <Overlay>
      <div>By clicking here you can set the expected cost of the task. We've set some indicative guidelines.
        If there is no cost, i.e. a DIY task, you can set the value to zero.</div>
      <div>Once a task is completed, you'll get a chance to confirm the exact expenditure.</div>
    </Overlay> : <></>}>
      <MobileColumnTitle>Cost ({getCurrencySign(propertyPreferences.preferredCurrency)})</MobileColumnTitle>
    </Dropdown>
    <ItemCost>
      <span>{getCurrencySign(propertyPreferences.preferredCurrency)}</span>
      <span>{item.actualEstimatedCost ? numberWithCommasFull(item.actualEstimatedCost) :
        item.estimatedCost ? numberWithCommasFull(item.estimatedCost) : 0}</span>
    </ItemCost>
  </>

  const listItemFrequency = (item: any) => <>
    <Dropdown placement="bottomCenter" overlay={isOnboarding ? <Overlay>
      <div>By clicking here you can set the recurrence or frequency of the task. For example a monthly task would be set at "Once every 1 Months".
        If there is no recurrence, select "Once".</div>
      <div>If a task doesn't happen as you planned, you can always reschedule the task or dismiss it later.</div>
    </Overlay> : <></>}>
      <MobileColumnTitle>Once Every</MobileColumnTitle>
    </Dropdown>
    <ItemFrequency>
      {!isOnce(item) ? (item.actualPeriodicity ?? item.periodicity) : ""}
      <ItemFrequencyUnit style={{
        border: isOnce(item) ? "none" : "auto",
        marginLeft: isOnce(item) ? "0" : "12px",
        paddingLeft: isOnce(item) ? "0" : "12px"
      }}>
        {capitalize(item.actualPeriodicityUnit ?? item.periodicityUnit)}
        {!isOnce(item) && "s"}
      </ItemFrequencyUnit>
    </ItemFrequency>
  </>

  const listItemDate = (item: any) => <>
    <Dropdown placement="bottomCenter" overlay={isOnboarding ? <Overlay>
      <div>By clicking here you can can set the expected task date.</div>
      <div>If a task doesn't happen as you planned, you can always reschedule the task or dismiss it later.</div>
    </Overlay> : <></>}>
      <MobileColumnTitle>Next Date</MobileColumnTitle>
    </Dropdown>
    <ItemStartDate>
      {moment(getDate(item)).format(propertyPreferences.preferredDateFormat)}
      <MaintenanceCalendar style={{flexShrink: 0}}/>
    </ItemStartDate>
  </>

  const listItemRemove = (item: any) => <>
    <Dropdown placement="bottomCenter" overlay={isOnboarding ? <Overlay>
      <div>By removing an upkeep, you are signalling that it isn't relevant to you. They'll be placed in the non-applicable maintenance tasks.</div>
    </Overlay> : <></>}>
      <MobileColumnTitle>Remove</MobileColumnTitle>
    </Dropdown>
    <MaintenanceRemove item={item} />
  </>

  const listItemSignUp = () => <Dropdown placement="bottomCenter" overlay={isOnboarding ? <Overlay>
    <div>By clicking the Sign-Up toggle you can "activate" the task (green status).</div>
    <div>This means it will be part of your calendar, projected maintenance costs.
      Once a task is completed, you can confirm the date and cost of the item.</div>
    <div>You can unsubscribe to an upkeep by clicking the toggle again (Greyed status).
      This will remove the task from your schedule and to-do lists. You can also dismiss the task from these areas too.</div>
  </Overlay> : <></>}>
    <MobileColumnTitle>Sign-up</MobileColumnTitle>
  </Dropdown>

  const listItemEdit = () => <Dropdown placement="bottomCenter" overlay={isOnboarding ? <Overlay>
    <div>You can edit the expense, date and recurrence fields by clicking here. You can choose to save or cancel your changes.</div>
  </Overlay> : <></>}>
    <MobileColumnTitle>Edit</MobileColumnTitle>
  </Dropdown>

  return <div>
    {/*1300px and wider - regular*/}
    {item.id !== editing && <MaintenanceItemWrapper
      className={isOnboarding ? "onboarding" : ""}
      style={{cursor: isCustom(item) ? "pointer" : "auto"}}>
      {!isOnboarding && <MaintenanceColumn spanWidth={"4%"}/>}
      <MaintenanceColumn spanWidth={isOnboarding ? "40%" : "36%"} style={{
        flexDirection: "column",
        alignItems: "flex-start",
        paddingRight: "1rem"
      }}>
        {itemTitleAndDescription(item)}
      </MaintenanceColumn>
      <MaintenanceColumnMobile spanWidth={"16%"} onClick={() => editItem(item)}>
        {!isCustomReminder(item) && listItemCost(item)}
      </MaintenanceColumnMobile>
      <MaintenanceColumnMobile spanWidth={"16%"} onClick={() => editItem(item)}>
        {listItemFrequency(item)}
      </MaintenanceColumnMobile>
      <MaintenanceColumnMobile spanWidth={"16%"} onClick={() => editItem(item)}>
        {listItemDate(item)}
      </MaintenanceColumnMobile>
      <MaintenanceColumnMobile spanWidth={"8%"}>
        {listItemSignUp()}
        <MaintenanceSwitch item={item} isOnboarding={isOnboarding} toggleSubscribe={toggleSubscribe} />
      </MaintenanceColumnMobile>
      <MaintenanceColumnMobile spanWidth={"8%"}>
        {listItemEdit()}
        {moreMenu()}
      </MaintenanceColumnMobile>
      {isOnboarding && <MaintenanceColumnMobile spanWidth={"5%"}>
        {listItemRemove(item)}
      </MaintenanceColumnMobile>}
    </MaintenanceItemWrapper>}

    {/*less than 1300px - regular*/}
    {item.id !== editing && <MaintenanceItemWrapperMobile style={{cursor: isCustom(item) ? "pointer" : "auto"}}>
      <MaintenanceMobileBlockWrapper spanWidth={"40%"} style={{rowGap: 0}}>
        {itemTitleAndDescription(item)}
      </MaintenanceMobileBlockWrapper>
      <MaintenanceMobileBlockWrapper spanWidth={"60%"}>
        <MaintenanceRowMobile>
          <MaintenanceColumnMobile spanWidth={"50%"} onClick={() => editItem(item)}>
            {!isCustomReminder(item) && listItemCost(item)}
          </MaintenanceColumnMobile>
          <MaintenanceColumnMobile spanWidth={"50%"} onClick={() => editItem(item)}>
            {listItemFrequency(item)}
          </MaintenanceColumnMobile>
      </MaintenanceRowMobile>
      <MaintenanceRowMobile>
        <MaintenanceColumnMobile spanWidth={"50%"} onClick={() => editItem(item)}>
          {listItemDate(item)}
        </MaintenanceColumnMobile>
        <MaintenanceColumnMobile spanWidth={isOnboarding ? "17%" : "25%"}>
          {listItemSignUp()}
          <MaintenanceSwitch item={item} isOnboarding={isOnboarding} toggleSubscribe={toggleSubscribe} />
        </MaintenanceColumnMobile>
        {isOnboarding && <MaintenanceColumnMobile spanWidth={"16%"}>
          {listItemRemove(item)}
        </MaintenanceColumnMobile>}
        <MaintenanceColumnMobile spanWidth={isOnboarding ? "17%" : "25%"}>
          {listItemEdit()}
          {moreMenu()}
        </MaintenanceColumnMobile>
        </MaintenanceRowMobile>
      </MaintenanceMobileBlockWrapper>
    </MaintenanceItemWrapperMobile>}

    {/*1300px and wider - inline editing*/}
    {item.id === editing && <EditModeWrapper className={isOnboarding ? "onboarding" : ""}>
      <EditModeRow>
        {!isOnboarding && <MaintenanceColumn spanWidth={"4%"}/>}
        <MaintenanceColumn spanWidth={isOnboarding ? "40%" : "36%"}>
          <TitleDescriptionWrapper>
            {itemTitleAndDescription(item)}
          </TitleDescriptionWrapper>
        </MaintenanceColumn>
        <MaintenanceColumnMobile spanWidth={"16%"}>
          <MaintenanceCost cost={itemCost} setCost={setItemCost}/>
        </MaintenanceColumnMobile>
        <MaintenanceColumnMobile spanWidth={"16%"}>
          <MaintenanceFrequency
            frequency={itemFrequency}
            frequencyUnit={itemFrequencyUnit}
            setFrequency={setItemFrequency}
            setFrequencyUnit={setItemFrequencyUnit}
          />
        </MaintenanceColumnMobile>
        <MaintenanceColumnMobile spanWidth={"16%"}>
          <MaintenanceStartDate startDate={itemStartDate} setStartDate={setItemStartDate}/>
        </MaintenanceColumnMobile>
        <MaintenanceColumnMobile spanWidth={"8%"}>
          <EditableValueWrapper>
            Save
            {saveButton()}
          </EditableValueWrapper>
        </MaintenanceColumnMobile>
        <MaintenanceColumnMobile spanWidth={"8%"}>
          <EditableValueWrapper>
            Cancel
            <CancelButton onClick={() => handleReset()}><CancelIcon/></CancelButton>
          </EditableValueWrapper>
        </MaintenanceColumnMobile>
        <MaintenanceColumn spanWidth={"5%"} />
      </EditModeRow>
    </EditModeWrapper>}

    {/*less than 1300px - inline editing*/}
    {item.id === editing && <EditModeRowMobile>
      <MaintenanceMobileBlockWrapper spanWidth={"40%"} style={{flexDirection: "row"}}>
        <TitleDescriptionWrapper>
          {itemTitleAndDescription(item)}
        </TitleDescriptionWrapper>
      </MaintenanceMobileBlockWrapper>
      <MaintenanceMobileBlockWrapper spanWidth={"60%"}>
        <MaintenanceRowMobile>
          <MaintenanceColumn spanWidth={"50%"}>
            <MaintenanceCost cost={itemCost} setCost={setItemCost}/>
          </MaintenanceColumn>
          <MaintenanceColumn spanWidth={"50%"}>
            <MaintenanceFrequency
              frequency={itemFrequency}
              frequencyUnit={itemFrequencyUnit}
              setFrequency={setItemFrequency}
              setFrequencyUnit={setItemFrequencyUnit}
            />
          </MaintenanceColumn>
      </MaintenanceRowMobile>
      <MaintenanceRowMobile>
        <MaintenanceColumn spanWidth={"50%"}>
            <MaintenanceStartDate startDate={itemStartDate} setStartDate={setItemStartDate}/>
        </MaintenanceColumn>
        <MaintenanceColumnMobile spanWidth={"25%"}>
          <MobileColumnTitle>Save</MobileColumnTitle>
          {saveButton()}
        </MaintenanceColumnMobile>
        <MaintenanceColumnMobile spanWidth={"25%"}>
          <MobileColumnTitle>Cancel</MobileColumnTitle>
          <CancelButton onClick={() => handleReset()}><CancelIcon/></CancelButton>
        </MaintenanceColumnMobile>
      </MaintenanceRowMobile>
      </MaintenanceMobileBlockWrapper>
    </EditModeRowMobile>}
  </div>
}

export const MaintenanceItemWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1.5rem 1rem 1.5rem;
  border-bottom: 1px solid #f0f2f5;
  column-gap: 0.5rem;
  align-items: center;
  
  &.onboarding { padding: 0.5rem 1rem 1rem 1rem}
  
  @media (max-width: 1300px) {
    display: none;
  }
`

export const MaintenanceItemWrapperMobile = styled.div`
  display: flex;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #f0f2f5;
  column-gap: 0.5rem;
  align-items: center;
  
  @media (min-width: 1300px) {
    display: none;
  }
  
  @media (max-width: 700px) {
    flex-direction: column;
    row-gap: 2rem;
  }
`

export const EditModeRowMobile = styled(MaintenanceItemWrapperMobile)`
  background: #F2FAF7;
`

export const MaintenanceColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: ${(props: { spanWidth: string }) => props.spanWidth};
  max-width: ${(props: { spanWidth: string }) => props.spanWidth};
  flex-grow: 1;
  
  font-size: 1rem;
  line-height: 1.5rem;
  color: #45655E;
`

export const MaintenanceColumnMobile = styled(MaintenanceColumn)`
  flex-direction: column;
  align-items: center;
  row-gap: 0.25rem;
`

export const MaintenanceMobileBlockWrapper = styled.div`
  display: flex;
  width: ${(props: { spanWidth: string }) => props.spanWidth};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 1rem;
  min-height: 8.5rem;
  
  @media (max-width: 700px) {
    width: 100%;
    min-height: auto;
  }
`

export const MaintenanceRowMobile = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 0.5rem;
`

export const EditModeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1.5rem 1rem 1.5rem;
  background: #F2FAF7;
  row-gap: 0.75rem;
  
  &.onboarding { padding: 0.5rem 1rem 1rem 1rem}
  
  @media (max-width: 1300px) {
    display: none;
  }
`

export const EditModeRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
`

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
`

export const ItemCost = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  padding: 0.25rem  0.75rem;
  max-width: 8.25rem;
  cursor: pointer;
  border: 1px solid #D4E6F9;
  border-radius: 6px;
`

export const ItemFrequency = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 100%;
  border: 1px solid #D4E6F9;
  border-radius: 6px;
  padding: 0 1.5rem;
  max-width: 10rem;
  cursor: pointer;
  background: white;
`

export const ItemFrequencyUnit = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid #D4E6F9;
  margin-left: 12px;
  padding-left: 12px;
  height: 2rem;
  cursor: pointer;
`

export const ItemStartDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  width: 100%;
  color: #45655E!important;
  font-size: 1rem;
  line-height: 1.25rem;
  height: 2rem;
  max-width: 8.25rem;
  cursor: pointer;
  border: 1px solid #D4E6F9;
  border-radius: 6px;
`

export const ControlBox = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 2rem;
  width: 2rem;
`

export const CancelButton = styled(ControlBox)`
  cursor: pointer;
  background: white;
  border: 1px solid #009966;
  svg path { fill: #009966; }
`

export const ReadMoreText = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  color: #2A80E1; 
  cursor: pointer;
`

export const TitleDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.5rem;
  overflow: hidden;
`

export const MobileColumnTitle = styled.span`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #0C2A41;
  width: 100%;
  text-align: center;
  font-weight: 500;
`

export default MaintenanceListItemNew