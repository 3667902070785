import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";

export type TGetUserProfileResponse = {
  address: string | null;
  defaultStartPage: string | null;
  name: string;
  email: string;
  phoneNumber: string | null;
  welcomeMessageDismissedTs: string | null;
  dateCreated: string | null;
  emailCommunication: boolean;
  marketingCookies: boolean;
  previousLogin: string;
  lastLogin: string;
  onboardingInterestSurveyStatus: string;
  mobileWelcomeStatus: string;
  marketingEmails: boolean;
  handholdingStatus: string | null;
  contentsStatus: string | null;
  spacesStatus: string | null;
  documentsStatus: string | null;
  showPets: boolean;
};

export const getUserProfile = () => {
  let url = createUrl(`${EnvConfig.API_URL}/api/user/profile`);

  return apiGet<TGetUserProfileResponse>(url)
};
