import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {PageHeader, PageSubtitle, PageTitle, PageWrapper} from "./components";
import SpaceRecordsCard from "../components/records/SpaceRecordsCard";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector} from "../selectors";
import {getAllWorks} from "../actions/works";
import {getAllExpenses} from "../actions/expenses";
import {getAllInventories} from "../actions/inventories";
import {getAllContacts} from "../actions/contacts";
import {getAllAttachments} from "../actions/attachments";
import {getAllNotesByPropertyId} from "../actions/notesByPropertyId";
import {getAllSpaces} from "../actions/spaces";
import ContactRecordsCard from "../components/records/ContactRecordsCard";
import CostRecordsCard from "../components/records/CostRecordsCard";
import FileRecordsCard from "../components/records/FileRecordsCard";
import NoteRecordsCard from "../components/records/NoteRecordsCard";
import ContentRecordsCard from "../components/records/ContentRecordsCard";
import WorkRecordsCard from "../components/records/WorkRecordsCard";
import PropertyRecordsCard from "../components/records/PropertyRecordsCard";
import EditSpaceDrawer from "../components/spaces/EditSpaceDrawer";
import WorkDrawer from "../components/works/WorkDrawer";
import ExpenseDrawer from "../components/expenses/ExpenseDrawer";
import InventoryDrawer from "../components/inventories/InventoryDrawer";
import ContactDrawer from "../components/contacts/ContactDrawer";
import NoteDrawer from "../components/notes/NoteDrawer";
import {getAllCertificates} from "../actions/certificates";
import {getAllInsurances} from "../actions/insurances";
import { getAllImprovements } from "../actions/improvements";
import PropertyDrawer from "../components/properties/PropertyDrawer";
import PropertyDetailsDrawer from "../components/properties/PropertyDetailsDrawer";
import InsuranceDrawer from "../components/insurance/InsuranceDrawer";
import CertificateDrawer from "../components/certificates/CertificateDrawer";
import {getCurrentUserProfile} from "../actions/getUserProfile";
import {notification} from "antd";
import {useHistory} from "react-router-dom";
import {capitalize} from "../components/helper";
import ViewSpaceDrawer from "../components/spaces/ViewSpaceDrawer";
import ViewWorkDrawer from "../components/works/ViewWorkDrawer";
import LinkDrawer from "../components/spaces/LinkDrawer";
import AddEventDrawer from "../components/planner/AddEventDrawer";
import UpdateTaskDrawer from "../components/planner/UpdateTaskDrawer";
import ViewFileDrawer from "../components/attachments/ViewFileDrawer";

const Records = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const propertyId = useSelector(getPropertyIdSelector).value;

  const [isViewSpaceDrawerOpen, toggleViewSpaceDrawer] = useState(false);
  const [viewSpaceId, setViewSpaceId] = useState(null);
  const [isEditSpaceDrawerOpen, toggleEditSpaceDrawer] = useState(false);
  const [editSpaceId, setEditSpaceId] = useState(null);
  const [linkedSpaceId, setLinkedSpaceId] = useState(null);
  const [isLinkSpaceDrawerOpen, toggleLinkSpaceDrawer] = useState(false);

  const [presetLinkType, setPresetLinkType] = useState<any>(null);
  const [taskType, setTaskType] = useState("Task");
  const [isTaskDrawerOpen, toggleTaskDrawer] = useState(false);
  const [editingTaskData, setEditingTaskData] = useState<any>(null);
  const [workToViewId, setWorkToViewId] = useState<any>(null);
  const [isViewWorkDrawerOpen, toggleViewWorkDrawer] = useState(false);
  const [attachmentToViewId, setAttachmentToViewId] = useState<any>(null);
  const [isViewFileDrawerOpen, toggleViewFileDrawer] = useState(false);
  const [isEventDrawerOpen, toggleEventDrawer]  = useState(false);

  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editingWorkData, setEditingWorkData] = useState(null);
  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [editingExpenseData, setEditingExpenseData] = useState(null);
  const [isInventoryDrawerOpen, toggleInventoryDrawer] = useState(false);
  const [editingInventoryData, setEditingInventoryData] = useState(null);
  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editingContactData, setEditingContactData] = useState(null);
  const [isNoteDrawerOpen, toggleNoteDrawer] = useState(false);
  const [editingNoteData, setEditingNoteData] = useState(null);

  const [isPropertyDrawerOpen, togglePropertyDrawer] = useState(false);
  const [isInsuranceDrawerOpen, toggleInsuranceDrawer] = useState(false);
  const [insuranceData, setInsuranceData] = useState(null)
  const [isCertificateDrawerOpen, toggleCertificateDrawer] = useState(false);
  const [certificateData, setCertificateData] = useState(null)
  const [isPropertyDetailsDrawerOpen, togglePropertyDetailsDrawer] = useState(false);
  const [editedPropertyId, setEditedPropertyId] = useState<any>(null)

  useEffect(() => {
    dispatch(getAllWorks(propertyId));
    dispatch(getAllExpenses(propertyId));
    dispatch(getAllInventories(propertyId));
    dispatch(getAllContacts(propertyId));
    dispatch(getAllAttachments(propertyId));
    dispatch(getAllNotesByPropertyId(propertyId));
    dispatch(getAllSpaces(propertyId));
    dispatch(getAllCertificates(propertyId));
    dispatch(getAllInsurances(propertyId));
    dispatch(getAllImprovements(propertyId));
    dispatch(getCurrentUserProfile());
  }, [propertyId])

  const sectionLink = (type: string) => {
    if (type === "insurance") return `/properties/${propertyId}?openTab=Insurance`
    else if (type === "certificate") return `/properties/${propertyId}?openTab=Certificates`
    else if (type === "property") return `/properties/${propertyId}`
    else return `/${type}s`
  }

  const showCreatedPopup = (type: string) => {
    notification.success({
      message: `A ${type} has been created successfully!`,
      description: <>You may now navigate to the <a onClick={() => history.push(sectionLink(type))}>
        {type === "property" ? "Properties" : capitalize(type)}s</a> page to view it.</>,
      placement: "topRight",
      duration: 5
    })
  }

  return <PageWrapper>
    <EditSpaceDrawer
      spaceId={editSpaceId}
      isOpen={isEditSpaceDrawerOpen}
      toggleDrawerOpen={toggleEditSpaceDrawer}
      setEditingSpaceId={setEditSpaceId}
      refreshParent={() => showCreatedPopup("space")}
      setViewSpaceId={setViewSpaceId}
      toggleViewDrawerOpen={toggleViewSpaceDrawer}
    />
    <ViewSpaceDrawer
      spaceId={viewSpaceId}
      isOpen={isViewSpaceDrawerOpen}
      toggleDrawerOpen={toggleViewSpaceDrawer}
      toggleEditDrawerOpen={toggleEditSpaceDrawer}
      setEditSpaceId={setEditSpaceId}
      toggleLinkDrawerOpen={toggleLinkSpaceDrawer}
      setLinkedSpaceId={setLinkedSpaceId}
      setPresetLinkType={setPresetLinkType}
      toggleWorkDrawer={toggleWorkDrawer}
      setEditWorkData={setEditingWorkData}
      toggleExpenseDrawer={toggleExpenseDrawer}
      setEditExpenseData={setEditingExpenseData}
      toggleInventoryDrawer={toggleInventoryDrawer}
      setEditInventoryData={setEditingInventoryData}
      toggleContactDrawer={toggleContactDrawer}
      setEditContactData={setEditingContactData}
      toggleNoteDrawer={toggleNoteDrawer}
      setEditNoteData={setEditingNoteData}
      toggleTaskDrawer={toggleTaskDrawer}
      setEditTaskData={setEditingTaskData}
      setViewWorkId={setWorkToViewId}
      toggleViewWorkDrawer={toggleViewWorkDrawer}
      setAttachmentId={setAttachmentToViewId}
      toggleViewFileDrawer={toggleViewFileDrawer}
    />
    <ViewWorkDrawer
      workId={workToViewId}
      setWorkId={setWorkToViewId}
      isOpen={isViewWorkDrawerOpen}
      toggleDrawer={toggleViewWorkDrawer}
      toggleWorkDrawer={toggleWorkDrawer}
      setEditWorkData={setEditingWorkData}
      toggleExpenseDrawer={toggleExpenseDrawer}
      setEditExpenseData={setEditingExpenseData}
      toggleInventoryDrawer={toggleInventoryDrawer}
      setEditInventoryData={setEditingInventoryData}
      toggleContactDrawer={toggleContactDrawer}
      setEditContactData={setEditingContactData}
      toggleNoteDrawer={toggleNoteDrawer}
      setEditNoteData={setEditingNoteData}
      setLinkedWorkId={() => {}}
      toggleLinkDrawerOpen={toggleLinkSpaceDrawer}
      setWorkIdToForward={() => {}}
      toggleForwardDrawer={() => {}}
      toggleTradeDrawer={() => {}}
      setEditSpaceId={setEditSpaceId}
      toggleSpaceDrawer={toggleViewSpaceDrawer}
      setPresetLinkType={setPresetLinkType}
      toggleTaskDrawer={toggleTaskDrawer}
      setEditTaskData={setEditingTaskData}
      setAttachmentId={setAttachmentToViewId}
      toggleViewFileDrawer={toggleViewFileDrawer}
    />
    <LinkDrawer
      parentId={linkedSpaceId}
      parentType={"space"}
      isOpen={isLinkSpaceDrawerOpen}
      toggleDrawerOpen={toggleLinkSpaceDrawer}
      presetLinkType={presetLinkType}
      setPresetLinkType={setPresetLinkType}
      toggleWorkDrawer={toggleWorkDrawer}
      setEditWorkData={setEditingWorkData}
      toggleExpenseDrawer={toggleExpenseDrawer}
      setEditExpenseData={setEditingExpenseData}
      toggleInventoryDrawer={toggleInventoryDrawer}
      setEditInventoryData={setEditingInventoryData}
      toggleContactDrawer={toggleContactDrawer}
      setEditContactData={setEditingContactData}
      toggleNoteDrawer={toggleNoteDrawer}
      setEditNoteData={setEditingNoteData}
      setEditSpaceId={setEditSpaceId}
      toggleSpaceDrawer={toggleEditSpaceDrawer}
      toggleTaskDrawer={toggleTaskDrawer}
      setEditTaskData={setEditingTaskData}
      toggleEventDrawer={toggleEventDrawer}
      setViewWorkId={setWorkToViewId}
      toggleViewWorkDrawer={toggleViewWorkDrawer}
      setAttachmentId={setAttachmentToViewId}
      toggleViewFileDrawer={toggleViewFileDrawer}
    />
    <AddEventDrawer
      isOpen={isEventDrawerOpen}
      toggleDrawer={toggleEventDrawer}
      setSelectedType={setTaskType}
      toggleWorkDrawer={toggleWorkDrawer}
      toggleTaskDrawer={toggleTaskDrawer}
      setEditingWorkData={setEditingWorkData}
    />
    <UpdateTaskDrawer
      task={editingTaskData}
      setEditingTaskData={setEditingTaskData}
      isOpen={isTaskDrawerOpen}
      toggleDrawer={toggleTaskDrawer}
      taskType={taskType}
      setTaskType={setTaskType}
    />
    <ViewFileDrawer
      attachmentId={attachmentToViewId}
      setAttachmentId={setAttachmentToViewId}
      isOpen={isViewFileDrawerOpen}
      toggleDrawer={toggleViewFileDrawer}
      viewFileDrawerDefaultTab={"preview"}
      attachmentsWithoutProperty={[]}
      duplicateAttachments={[]}
      selectedTags={[]}
      toggleEditTagsMode={() => {}}
      toggleEditDrawerOpen={() => {}}
      setPresetLinkType={() => {}}
      toggleWorkDrawer={() => {}}
      setEditWorkData={() => {}}
      toggleExpenseDrawer={() => {}}
      setEditExpenseData={() => {}}
      toggleInventoryDrawer={() => {}}
      setEditInventoryData={() => {}}
      toggleContactDrawer={() => {}}
      setEditContactData={() => {}}
      toggleNoteDrawer={() => {}}
      setEditNoteData={() => {}}
      toggleLinkDrawerOpen={() => {}}
      setEditSpaceId={() => {}}
      toggleSpaceDrawer={() => {}}
      refreshParent={() => {}}
      toggleTaskDrawer={() => {}}
      setEditTaskData={() => {}}
      toggleViewWorkDrawer={() => {}}
      setViewWorkId={() => {}}
    />
    <WorkDrawer
      isOpen={isWorkDrawerOpen}
      toggleDrawerOpen={toggleWorkDrawer}
      work={editingWorkData}
      // @ts-ignore
      setEditingWorkData={setEditingWorkData}
      refreshParent={() => showCreatedPopup("work")}
    />
    <ExpenseDrawer
      isOpen={isExpenseDrawerOpen}
      toggleDrawerOpen={toggleExpenseDrawer}
      expense={editingExpenseData}
      // @ts-ignore
      setEditingExpenseData={setEditingExpenseData}
      refreshParent={() => showCreatedPopup("cost")}
    />
    <InventoryDrawer
      isOpen={isInventoryDrawerOpen}
      toggleDrawerOpen={toggleInventoryDrawer}
      inventory={editingInventoryData}
      // @ts-ignore
      setEditingInventoryData={setEditingInventoryData}
      refreshParent={() => showCreatedPopup("content")}
      setViewWorkId={() => {}}
      toggleViewWorkDrawer={() => {}}
    />
    <ContactDrawer
      isOpen={isContactDrawerOpen}
      toggleDrawerOpen={toggleContactDrawer}
      contact={editingContactData}
      // @ts-ignore
      setEditingContactData={setEditingContactData}
      refreshParent={() => showCreatedPopup("contact")}
      setViewWorkId={() => {}}
      toggleViewWorkDrawer={() => {}}
    />
    <NoteDrawer
      currentType={"note"}
      data={editingNoteData}
      isOpen={isNoteDrawerOpen}
      toggleDrawerOpen={toggleNoteDrawer}
      parentPage={"records"}
      refreshParent={() => showCreatedPopup("note")}
      isGlobalNote={true}
      toggleTaskDrawer={() => {}}
      setEditTaskData={() => {}}
      setPresetLinkType={() => {}}
      toggleLinkDrawerOpen={() => {}}
      setViewWorkId={() => {}}
      toggleViewWorkDrawer={() => {}}
      toggleExpenseDrawer={toggleExpenseDrawer}
      setEditExpenseData={setEditingExpenseData}
      toggleInventoryDrawer={toggleInventoryDrawer}
      setEditInventoryData={setEditingInventoryData}
      toggleContactDrawer={toggleContactDrawer}
      setEditContactData={setEditingContactData}
      setEditSpaceId={() => {}}
      toggleSpaceDrawer={toggleEditSpaceDrawer}
      setAttachmentId={() => {}}
      toggleViewFileDrawer={() => {}}
    />
    <PropertyDrawer
      toggleDrawerOpen={togglePropertyDrawer}
      isOpen={isPropertyDrawerOpen}
      editedPropertyId={editedPropertyId}
      refreshParent={() => showCreatedPopup("property")}
    />
    <PropertyDetailsDrawer
      toggleDrawerOpen={togglePropertyDetailsDrawer}
      isOpen={isPropertyDetailsDrawerOpen}
    />
    <InsuranceDrawer
      toggleDrawerOpen={toggleInsuranceDrawer}
      isOpen={isInsuranceDrawerOpen}
      insurance={insuranceData}
      setEditingInsuranceData={setInsuranceData}
      refreshParent={() => showCreatedPopup("insurance")}
    />
    <CertificateDrawer
      isOpen={isCertificateDrawerOpen}
      toggleDrawerOpen={toggleCertificateDrawer}
      setEditingCertificateData={setCertificateData}
      certificate={certificateData}
      refreshParent={() => showCreatedPopup("certificate")}
    />
    <PageHeader className={"no-controls"}>
      <div>
        <PageTitle>Home information</PageTitle>
        <PageSubtitle>Organise your data home</PageSubtitle>
      </div>
    </PageHeader>
    <RecordsWrapper>
      <PropertyRecordsCard
        togglePropertyDrawer={togglePropertyDrawer}
        setEditedPropertyId={setEditedPropertyId}
        togglePropertyDetailsDrawer={togglePropertyDetailsDrawer}
        toggleInsuranceDrawer={toggleInsuranceDrawer}
        toggleCertificateDrawer={toggleCertificateDrawer}
        setCertificateData={setCertificateData}
      />
      <SpaceRecordsCard toggleDrawerOpen={toggleEditSpaceDrawer} />
      <ContentRecordsCard
        toggleDrawerOpen={toggleInventoryDrawer}
        setEditingData={setEditingInventoryData}
      />
      <FileRecordsCard />
      <NoteRecordsCard
        toggleDrawerOpen={toggleNoteDrawer}
        setEditingData={setEditingNoteData}
      />
      <CostRecordsCard
        toggleDrawerOpen={toggleExpenseDrawer}
        setEditingData={setEditingExpenseData}
      />
      <ContactRecordsCard
        toggleDrawerOpen={toggleContactDrawer}
        setEditingData={setEditingContactData}
      />
      <WorkRecordsCard
        toggleDrawerOpen={toggleWorkDrawer}
        setEditingData={setEditingWorkData}
      />
    </RecordsWrapper>
  </PageWrapper>
}

const RecordsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;
  
  @media (max-width: 1350px) { grid-template-columns: repeat(2, minmax(0, 1fr)); }
  @media (max-width: 768px) { grid-template-columns: minmax(0, 1fr); }
`

export default Records