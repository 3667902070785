import { apiDelete, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TRemoveAttachmentRequest = {
  propertyId: Guid;
  id: Guid;
};

export const removeAttachment = (payload: TRemoveAttachmentRequest) => {
  const { propertyId, id } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/attachment/${id}`
  );
  return apiDelete<{}, any>(url);
};
