import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetAllAttachmentsResponse } from "../../api-wrapper/attachments/getAllAttachments";
import {
  getAttachmentsFailureAction,
  getAttachmentsInitAction,
  getAttachmentsSuccessAction,
  TGetAttachmentsActions,
} from "../actions/attachments";
import {attachmentsInitial} from "../components/helper";
import _ from "lodash";

export type TAttachments = {
  fetchingStatus: TFetchingStatus;
  content: TGetAllAttachmentsResponse;
};

const initialState: TAttachments = {
  content: attachmentsInitial,
  fetchingStatus: TFetchingStatus.Defined,
};

export const attachmentsReducer = createReducer<TAttachments, TGetAttachmentsActions>(
  initialState
)
  .handleAction(getAttachmentsInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getAttachmentsSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = _.sortBy(action.payload, ["uploadedDate"]);
    })
  )
  .handleAction(getAttachmentsFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
