import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetMortgagesResponse } from "../../api-wrapper/mortgages/getMortgages";
import {
  getMortgagesFailureAction,
  getMortgagesInitAction,
  getMortgagesSuccessAction,
  TGetMortgagesActions,
} from "../actions/mortgages";
import {mortgagesInitial} from "../components/helper";

export type TMortgages = {
  fetchingStatus: TFetchingStatus;
  content: TGetMortgagesResponse;
};

const initialState: TMortgages = {
  content: mortgagesInitial,
  fetchingStatus: TFetchingStatus.Defined,
};

export const mortgagesReducer = createReducer<TMortgages, TGetMortgagesActions>(
  initialState
)
  .handleAction(getMortgagesInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getMortgagesSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getMortgagesFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
