import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TAddInventoryResponse = {
  propertyId: Guid;
  brand: string;
  model: string;
  installedOrPurchaseDate: string;
  warrantyExpiration: string;
  inventoryType: string;
  purchaseCost: number;
  insuredItems: boolean;
  serialNumber: string;
  inventorySubtype: string;
  applianceLifetimeYears: number | null;
  applianceLifetimeYearsLeft: number | null;
  name: string;
  id: Guid;
  notes: string;
  relatedUpkeepsCount?: number;
  subscribedRelatedUpkeepsCount?: number;
  expensesCount?: number;
};

export type TAddInventoryRequest = {
  name?: string | null;
  propertyId: Guid;
  inventoryType?: string | null;
  brand?: string | null;
  model?: string | null;
  installedOrPurchaseDate?: string | null;
  warrantyExpiration?: string | null;
  purchaseCost?: number | null;
  insuredItems?: boolean | null;
  serialNumber?: string | null;
  inventorySubtype?: string | null;
};

export const addInventory = (
  payload: TAddInventoryRequest & { propertyId: Guid }
) => {
  const { propertyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/inventory`
  );

  return apiPost<TAddInventoryResponse, TAddInventoryRequest>(url, payload);
};
