import {DatePicker, Drawer, Form, Modal, notification, Select} from "antd";
import { useIntl } from "react-intl";
import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import _ from "lodash";

import { ReactComponent as SaveIcon } from "../../../images/SaveIcon.svg";
import { ReactComponent as MyPropertyIcon } from "../../../images/MyProperty.svg";
import messages from "../../screens/messages";
import {
  InputStyled,
  InputsWrapper,
  InputWrapper,
  Label,
  LabelWrapper,
} from "../../screens/RegistrationPage";
import { useDispatch, useSelector } from "react-redux";
import {getImprovementsSelector, getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import {ButtonsWrapper, CloseButton, SaveButton, SaveButtonWrapper} from "../works/WorkDrawer";
import {getAllImprovements} from "../../actions/improvements";
import {InputNumberStyled} from "../expenses/ExpenseDrawer";
import {TFetchingStatus} from "../../../helpers";
import {putDetails} from "../../../api-wrapper/properties/putDetails";
import {PropertyQuestionAnswer} from "../../../api-wrapper/properties/getImprovements";
import { ReactComponent as CrossIcon } from "../../../images/Cross.svg";
import moment from "moment";
import {editProperty} from "../../../api-wrapper/properties/editProperty";
import {getAllProperties} from "../../actions/properties";
import countryList from "react-select-country-list";
import {SelectStyled} from "./PropertyDrawer";

const PostcodesIO = require('postcodesio-client');

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  refreshParent?: (arg01: Promise<any>) => void;
};

const PropertyValueDetailsDrawer = (props: Props) => {
  const { isOpen, toggleDrawerOpen, refreshParent } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);
  const improvementsConfiguration = useSelector(getImprovementsSelector);
  const postcodes = new PostcodesIO('https://api.postcodes.io');

  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [questions, setQuestions] = useState<Array<any>>([]);
  const [propertyDetails, setPropertyDetails] = useState<Array<any>>([]);

  const [detailsToDisplay, setDetailsToDisplay] = useState<any>([]);
  const [changedDetailsIds, setChangedDetailsIds] = useState(new Map());
  const [changedDetails, setChangedDetails] = useState(new Map());
  const [errors, setErrors] = useState(new Set());

  const [postalCode, setPostalCode] = useState<any>(null);
  const [country, setCountry] = useState<any>(null);
  const [postalCodeChanged, setPostalCodeChanged] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);

  const getCountryList = () => {
    let result = countryList()
      .getData()
      .filter(
        (country: { value: string; label: string }) =>
          country.value !== "US" && country.value !== "GB"
      );

    result.unshift({ value: "United Kingdom", label: "United Kingdom" });
    result.unshift({ value: "US", label: "United States" });

    return result;
  };
  const options = useMemo(() => getCountryList(), []);

  useEffect(() => {
    if (!isOpen) return; 

    setErrors(new Set());
    setSaveDisabled(false);
    setChangedDetails(new Map());
    setChangedDetailsIds(new Map());
    setFormFieldsChanged(false);
    setPostalCodeError(false);
    setPostalCodeChanged(false);
  }, [isOpen]);

  useEffect(() => {
    if (improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success)
      return;
    setQuestions([...improvementsConfiguration.content.questions]);
    setPropertyDetails([...improvementsConfiguration.content.propertyDetails]);

    setDetailsToDisplay(
      [
        {
          groupName: "",
          groupOrder: 0,
          items: [
            [
              improvementsConfiguration.content.propertyDetails.filter(detail => detail.id === 2)[0]
            ],
            [
              improvementsConfiguration.content.propertyDetails.filter(detail => detail.id === 1)[0],
              improvementsConfiguration.content.propertyDetails.filter(detail => detail.id === 3)[0],
            ],
            [
              improvementsConfiguration.content.propertyDetails.filter(detail => detail.id === 5)[0],
              improvementsConfiguration.content.propertyDetails.filter(detail => detail.id === 6)[0],
            ],
            [
              improvementsConfiguration.content.propertyDetails.filter(detail => detail.id === 24)[0],
              improvementsConfiguration.content.propertyDetails.filter(detail => detail.id === 22)[0],
            ],
            [
              improvementsConfiguration.content.propertyDetails.filter(detail => detail.id === 23)[0],
            ],
          ]
        }
      ]
    );
  }, [improvementsConfiguration]);

  useEffect(() => {
    if (isOpen && properties && properties.length > 0) {
      const currentProperty = properties.filter((property) => property.propertyId === propertyId)[0]
      currentProperty && currentProperty.hasOwnProperty("zip") && setPostalCode(currentProperty.zip);
      currentProperty && currentProperty.hasOwnProperty("country") && setCountry(currentProperty.country);
    }
  }, [properties, propertyId, isOpen])

  const handleManageSubmit = async () => {
    setSaveDisabled(true);
    let errorFields = new Set();
    if (!getValue(2)) errorFields.add(2);
    if (!getValueForSelect(1)) errorFields.add(1);
    if (!getValue(3)) errorFields.add(3);
    if (!getValue(5)) errorFields.add(5);
    if (!getValue(6)) errorFields.add(6);
    if (!getValueForSelect(24)) errorFields.add(24);
    if (!getValueForSelect(22)) errorFields.add(22);
    if (!getValueForSelect(23)) errorFields.add(23);

    let postCodeLookup = null
    if (country === "United Kingdom") {
      postCodeLookup = postalCode && postalCode.length >= 5 && await postcodes.lookup(postalCode);
      setPostalCodeError(!Boolean(postCodeLookup))
    } else {
      postCodeLookup = "passed";
      setPostalCodeError(false);
    }

    let textAnswers: any[] = [];
    let selectAnswers: any[] = [];

    if (postalCodeChanged) {
      textAnswers = Array.from([2, 3, 5, 6]).map(itemId => {
        let itemArray = [itemId, getValue(itemId)];

        if (_.isNil(itemArray[1]))
          return {
            id: itemArray[0]
          };
        if (changedDetails.has(itemId))
          errorFields.delete(itemArray[0]);
        return {
          id: itemArray[0],
          answer: itemArray[1]
        }
      });

      selectAnswers = Array.from([1, 24, 22, 23]).map(itemId => {
        let itemArray = [itemId, getValueForSelect(itemId)];
        if (changedDetailsIds.has(itemId))
          errorFields.delete(itemArray[0]);
        return {
          id: itemArray[0],
          questionId: propertyDetails.filter(detail => detail.id === itemArray[0])[0].questionId,
          answerId: itemArray[1]
        }
      });
    } else {
      textAnswers = Array.from(changedDetails).map(itemArray => {
        if (_.isNil(itemArray[1]))
          return {
            id: itemArray[0]
          };
        errorFields.delete(itemArray[0]);
        return {
          id: itemArray[0],
          answer: itemArray[1]
        }
      });
      selectAnswers = Array.from(changedDetailsIds).map(itemArray => {
        errorFields.delete(itemArray[0]);
        return {
          id: itemArray[0],
          questionId: propertyDetails.filter(detail => detail.id === itemArray[0])[0].questionId,
          answerId: itemArray[1]
        }
      });
    }

    if (errorFields.size > 0 || !postCodeLookup) {
      setErrors(errorFields);
      setSaveDisabled(false);
      return;
    }
    notification.info({
      message: "Valuation",
      description: "We are working on getting you the valuation. It may take a few moments.",
      placement: "topRight",
    });
    if (postalCodeChanged) {
      const { address, address2, city, state, nickname } = properties.filter(
        (property) => property.propertyId === propertyId
      )[0];
      editProperty({
        nickname,
        propertyId,
        address,
        address2,
        city,
        state,
        country: country,
        zip: postalCode,
      }).then(async () => {
        new Promise(resolve => setTimeout(resolve, 3000));
        putDetails(propertyId, [...textAnswers, ...selectAnswers], true)
          .then((res) => {
            if (!res) {
              notification.error({
                message: "Valuation",
                description: "There has been an error in the valuation. Please contact support for assistance.",
                placement: "topRight",
              });
            }
            toggleDrawerOpen(false);
            dispatch(getAllImprovements(propertyId));
            setSaveDisabled(false);
          });
        dispatch(getAllProperties());
      });
    } else {
      let putDetailsPromise = putDetails(propertyId, [...textAnswers, ...selectAnswers], true);
      if (refreshParent) {
        toggleDrawerOpen(false);
        setSaveDisabled(false);
        refreshParent(putDetailsPromise);
      }
      putDetailsPromise
        .then((res) => {
          if (!res) {
            notification.error({
              message: "Valuation",
              description: "There has been an error in the valuation. Please contact support for assistance.",
              placement: "topRight",
              duration: 10,
            });
          }
          toggleDrawerOpen(false);
          !refreshParent && dispatch(getAllImprovements(propertyId));
          setSaveDisabled(false);
        });
    }
  };

  const getValueForSelect = (id: number) => {
    if (changedDetailsIds.has(id))
      return changedDetailsIds.get(id);

    return propertyDetails.filter(detail => detail.id === id)[0].answerId;
  }

  const setAnswerSelect = (id: number, answerId: string) => {
    let newDetails = new Map(changedDetailsIds);
    newDetails.set(id, answerId);
    setFormFieldsChanged(true)
    setChangedDetailsIds(newDetails);
  };

  const getValue = (id: number) => {
    if (changedDetails.has(id))
      return changedDetails.get(id);
    return propertyDetails.filter(detail => detail.id === id)[0]?.answer;
  }

  const setValue = (id: number, answer: number | null | undefined | string) => {
    let newDetails = new Map(changedDetails);
    setFormFieldsChanged(true)
    if (_.isUndefined(answer) || _.isNil(answer) || !_.isNumber(answer)) {
      newDetails.set(id, null);
    } else {
      newDetails.set(id, answer);
    }
    setChangedDetails(newDetails);
  };

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        toggleDrawerOpen(false);
        dispatch(getAllImprovements(propertyId));
      },
    });
  }

  const renderQuestionPostcode = () =>
    <InputsWrapper style={{width: "100%"}}>
      <InputWrapper>
        <LabelWrapper>
          <Label>Country <span style={{color: "red"}}>*</span></Label>
        </LabelWrapper>
        <Form.Item
          rules={[
            { required: true, message: f(messages.countryError) },
          ]}
        >
          <Select
            defaultValue={country}
            value={country}
            onChange={e => {
              setCountry(e);
              setPostalCodeError(false);
            }}
            options={options}
            style={{border: "1px solid #dae0e6", borderRadius: "6px"}}
            bordered={false}
            placeholder={f(messages.pleaseSelect)}
          />
        </Form.Item>
      </InputWrapper>
      <InputWrapper>
        <LabelWrapper>
          <Label>Postcode <span style={{color: "red"}}>*</span></Label>
        </LabelWrapper>
        <Form.Item
          key={"itemIdPostcode"}
          name={"itemIdPostcode"}
        ><>
          <InputStyled
            maxLength={100}
            autoComplete={"newpassword"}
            placeholder={f(messages.enterPostcode)}
            value={postalCode}
            style={{borderColor: postalCodeError ? "red": "", height: "2.5rem"}}
            onChange={(e) => {
              setPostalCode(e.target.value);
              setPostalCodeChanged(true);
              setPostalCodeError(false);
            }}
          /></>
          {postalCodeError && <div className={"ant-form-item?-explain ant-form-item?-explain-error"}>
              <div className={"alert"}>Wrong postal code</div>
          </div>}
        </Form.Item>
      </InputWrapper>
    </InputsWrapper>

  const renderQuestion = (item: any) =>
    <InputWrapper
      className={errors.has(item?.id) ? "ant-form-item-has-error" : ""}
    >
      <LabelWrapper>
        <Label>{item?.label} <span style={{color: "red"}}>*</span></Label>
      </LabelWrapper>
      <Form.Item
        key={"itemId"+item?.id}
        name={"itemId"+item?.id}
      >
        {(_.isNil(item?.questionId) && item?.id === 2) && <InputWithSuffix>
            <InputNumberStyled
                maxLength={10}
              // precision={2}
                min={0}
                placeholder={item?.placeholder}
                value={getValue(item?.id)}
                onChange={(val) => setValue(item?.id, val)}
            />
            <span className={"suffix"}>m²</span>
        </InputWithSuffix>}
        {(_.isNil(item?.questionId) && item?.id === 3) &&
        <DatePicker
            style={{width: "100%", borderRadius: "6px"}}
            placeholder={item?.placeholder}
            value={getValue(item?.id) ? moment().set('year', getValue(item?.id)) : null}
            onChange={(val) => {
              val && setValue(item?.id, val.year())
            }}
            disabledDate={(current) => current && current > moment().endOf('year')}
            picker="year"
            allowClear={false}
            inputReadOnly={true}
        />
        }
        {(_.isNil(item?.questionId) && item?.id !== 2 && item?.id !== 3) &&
        <InputNumberStyled
            maxLength={10}
            precision={0}
            min={item?.id !== 6 && item?.id !== 5 ? 0 : 1}
            placeholder={item?.placeholder}
            value={getValue(item?.id)}
            onChange={(val) => setValue(item?.id, val)}
        />}

        {!_.isNil(item?.questionId) &&
        <Select
            placeholder={item?.placeholder}
            bordered={false}
            getPopupContainer={trigger => trigger.parentElement}
            style={{
              width: "100%",
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "6px",
              color: "rgb(107,113,133)",
            }}
            onChange={(val) => setAnswerSelect(item?.id, val.toString())}
            defaultValue={getValueForSelect(item?.id)}
        >
          {questions.filter(question => question.id === item?.questionId).map(question => question.answers.slice()
            .sort((a1: PropertyQuestionAnswer, a2: PropertyQuestionAnswer) => a1.answerOrder - a2.answerOrder)
            .map((answer: PropertyQuestionAnswer) =>
              <Select.Option value={answer.id}>{answer.answer}</Select.Option>
            ))}
        </Select>
        }
        {errors.has(item?.id) && <div className={"ant-form-item?-explain ant-form-item?-explain-error"}>
          <div className={"alert"}>Value is required</div>
        </div>}
      </Form.Item>
    </InputWrapper>


  return (
    <Drawer
      closeIcon={false}
      width={window.innerWidth > 1024 ? "55%" : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => {
        if (formFieldsChanged || postalCodeChanged) {
          confirmCloseUnsaved();
          return;
        }
        dispatch(getAllImprovements(propertyId));
        toggleDrawerOpen(false);
      }}
      headerStyle={{
        backgroundColor: "#ebf9ff",
        margin: 0,
        padding: "2rem 2rem",
      }}
      title={
        <>
          <section
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.5rem",
            }}
          >
            <InventoriesIconWrapper>
              <MyPropertyIcon className={"my-property-theme-icon"} />{" "}
              <span style={{ paddingRight: "0.25rem", fontSize:"0.75rem" }}>Improved Property Evaluation</span>
            </InventoriesIconWrapper>
            <ButtonsWrapper>
              <CloseButton
                size={"large"}
                onClick={() => {
                  toggleDrawerOpen(false);
                }}
              >
                {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
              </CloseButton>
            </ButtonsWrapper>
          </section>
          <Title>Improve Value Estimate</Title>
        </>
      }
    >
      <Form
        form={form}
        name="managePropertyDetails"
        layout="vertical"
        size={"large"}
        onFinish={handleManageSubmit}
      >
        {detailsToDisplay.map((group:any) =>
        <>
          <span
            style={{ fontSize: "1.125rem", fontWeight: 600, color: "#21272B" }}
          >
            By entering all the following fields we can considerably improve your property value estimate. Elements with a red * are required for the enhanced evaluation to be presented.
          </span>
          <section
            style={{
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "10px",
              padding: "1rem",
              margin: "1rem 0",
            }}
          >
            {group.items.length > 0 && group.items.filter((itemPair:any) => itemPair[0]?.id === 2)
              .map((itemPair: any) => renderQuestionPostcode())}
            {group.items.length > 0 && group.items.filter((itemPair:any) => itemPair[0]?.id !== 2)
              .map((itemPair:any) => <InputsWrapper>
                {renderQuestion(itemPair[0])}
                {itemPair.length > 1 && renderQuestion(itemPair[1])}
              </InputsWrapper>
            )}
          </section>
          <span
            style={{ fontSize: "1.125rem", fontWeight: 600, color: "#21272B" }}
          >
            You’ll be able to correct these details from your Property Details page.
          </span>

        </>
        )}
        <SaveButtonWrapper>
          <SaveButton
            style={{
              display: "flex",
              alignItems: "center",
            }}
            icon={window.innerWidth > 1024 ? <SaveIconStyled /> : null}
            type={"primary"}
            htmlType={"submit"}
            loading={saveDisabled}
          >
            Save details
          </SaveButton>
        </SaveButtonWrapper>
      </Form>
    </Drawer>
  );
};

const SaveIconStyled = styled(SaveIcon)`
  padding-right: 0.25rem;
  width: 22px;
  height: 22px;
`;

const Title = styled.span`
  border: none;
  outline: none;
  background-color: #ebf9ff;
  font-size: 2.5rem;
  @media (max-width: 1024px) {
    font-size: 2.5rem;
    line-height: 2.75rem;
    font-weight: 700;
  }  
`;

const InventoriesIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

const InputWithSuffix = styled.div`
  margin: auto;
  position: relative;
  display: inline-block;
  width: 100%;
  .suffix {
    position: absolute;
    top: 0.5rem;
    right: 1rem ;
  }
`;

export default PropertyValueDetailsDrawer;
