import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAttachmentsSelector, getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {Guid} from "guid-typescript";
import AttachmentsOnDrawer from "../attachments/AttachmentsOnDrawer";
import {ReactComponent as WorkIcon} from "../../../images/WorksNew.svg";
import {
  AttachButtonStyled,
  ButtonsWrapper,
  CloseButton,
  SaveButton,
  SaveButtonWrapper,
} from "../works/WorkDrawer";
import {DatePicker, Drawer, Form, Input, Modal, Select, Tooltip} from "antd";
import {ReactComponent as MyTenantIcon} from "../../../images/MyProperty.svg";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import {InputsWrapper, InputWrapper, Label, LabelWrapper} from "../../screens/RegistrationPage";
import {Title, TitleError} from "../expenses/ExpenseDrawer";
import {ReactComponent as AttachmentIcon} from "../../../images/Attachments.svg";
import {ReactComponent as HelpIcon} from "../../../images/Help.svg";
import UploadFile from "../attachments/UploadFile";
import {addDocument} from "../../../api-wrapper/documents/addDocument";
import {editDocument} from "../../../api-wrapper/documents/editDocument";
import {TenantIconWrapper, SaveIconStyled} from "../certificates/CertificateDrawer";
import {getAllFloorPlans} from "../../actions/floorPlans";
import {selectStyle} from "../spaces/EditSpaceDrawer";
import {getEntityTypes, TGetEntityTypesResponse} from "../../../api-wrapper/relation/getEntityTypes";
import {getAllAttachments} from "../../actions/attachments";
import ItemRenderComp from "../attachments/ItemRenderComp";
import {getAllSurveys} from "../../actions/surveys";
import _ from "lodash";
import moment from "moment";
import ViewFileDrawer from "../attachments/ViewFileDrawer";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg: boolean) => void;
  setEditingDocumentData: (arg: any) => void;
  document: { data: any } | null;
  refreshParent?: (arg?: Guid | null) => void;
  documentType: string;
}

const DocumentDrawer = (props: Props) => {
  const {isOpen, toggleDrawerOpen, setEditingDocumentData, document, refreshParent, documentType} = props;
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const attachments = useSelector(getAttachmentsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [saveDisabled, setSaveDisabled] = useState(false);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [form] = Form.useForm();
  const titleInput = useRef(null);

  const [documentName, setDocumentName] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [showFileUploadList, setShowFileUploadList] = useState(false);
  const [documentAttachmentId, setDocumentAttachmentId] = useState<any>(null);
  const [documentAttachments, setDocumentAttachments] = useState<any>([]);
  const [documentTypes, setDocumentTypes] = useState<TGetEntityTypesResponse>([]);
  const [selectedFiles, setSelectedFiles] = useState<Set<Guid>>(new Set());

  const [isViewFileDrawerOpen, toggleViewFileDrawer] = useState(false);
  const [attachmentToViewId, setAttachmentToViewId] = useState<any>(null);

  useEffect(() => {
    getEntityTypes(propertyId, documentType.toLowerCase()).then(res => {
      let regularTypes: any = [];
      let otherTypes: any = [];
      res && res.map((type: any) => type.name.toLowerCase().includes("other") ? otherTypes.push(type) : regularTypes.push(type))
      regularTypes.sort((a: any, b: any) => a.name.localeCompare(b.name))
      otherTypes.sort((a: any, b: any) => a.name.localeCompare(b.name))
      setDocumentTypes([...regularTypes, ...otherTypes])
      }
    );
  }, [propertyId, documentType])

  const initialValues = () => {
    if (document) {
      const {documentType, description, documentDate} = document.data;
      return {type: documentType, description, documentDate: moment(documentDate)};
    } else {
      return {};
    }
  };

  useEffect(() => {
    if (document) {
      setDocumentName(document.data.name);
      setDocumentAttachmentId(document.data.relatedFileId);
    } else {
      setDocumentName("");
      setDocumentAttachmentId(null);
    }
    setTitleError(false);
    setSaveDisabled(false);
    form.resetFields();
  }, [document])

  useEffect(() => {
    if (documentAttachmentId) {
      setDocumentAttachments(attachments.content.filter(attachment => attachment.attachmentId === documentAttachmentId))
    } else {
      setDocumentAttachments([]);
    }
  }, [documentAttachmentId, attachments])

  useEffect(() => {
    if (documentAttachments.length) {
      if (documentAttachments[0].attachmentId !== documentAttachmentId) {
        setDocumentAttachmentId(documentAttachments[0].attachmentId)
      }
    }
  }, [documentAttachments])

  const performCleanup = () => {
    toggleDrawerOpen(false);
    setEditingDocumentData(null);
    setDocumentName("");
    setDocumentAttachmentId(null);
    form.resetFields();
    setSaveDisabled(false);
    setFormFieldsChanged(false);
    setTitleError(false);
    documentType === "survey" ? dispatch(getAllSurveys(propertyId)) : dispatch(getAllFloorPlans(propertyId));
    if (refreshParent) refreshParent();
  }

  const handleManageDocument = async (e: {
    type: string,
    description: string,
    documentDate: moment.Moment
  }) => {
    const {type, description, documentDate } = e
    if (_.isEmpty(documentName)) {
      setTitleError(true);
      return;
    }

    if (document) {
      editDocument({
        propertyId,
        category: documentType,
        documentId: document.data.documentId,
        name: documentName,
        documentDate: documentDate ? documentDate.format("YYYY-MM-DD")+"T00:00:00.000" : null,
        documentType: type,
        description,
        relatedFileId: documentAttachmentId
      }).then(() => {
        performCleanup();
      })
    } else {
      addDocument({
        propertyId,
        category: documentType,
        name: documentName,
        documentDate: documentDate ? documentDate.format("YYYY-MM-DD")+"T00:00:00.000" : null,
        documentType: type,
        description,
        relatedFileId: documentAttachmentId
      }).then(() => {
        performCleanup();
      })
    }
  }
  
  const confirmCloseUnsaved = () => {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() { performCleanup() }
    });
  }

  const refreshFile = (fileId: any) => {
    setDocumentAttachmentId(fileId)
    dispatch(getAllAttachments(propertyId));
  };

  const clearAttachment = () => {
    setDocumentAttachmentId(null);
    setDocumentAttachments([]);
  }

  return (
    <>
      <ViewFileDrawer
        attachmentId={attachmentToViewId}
        setAttachmentId={setAttachmentToViewId}
        isOpen={isViewFileDrawerOpen}
        toggleDrawer={toggleViewFileDrawer}
        viewFileDrawerDefaultTab={"previewOnly"}
        duplicateAttachments={[]}
        selectedTags={[]}
        toggleEditTagsMode={() => {}}
        attachmentsWithoutProperty={[]}
        toggleEditDrawerOpen={() => {}}
        setPresetLinkType={() => {}}
        toggleWorkDrawer={() => {}}
        setEditWorkData={() => {}}
        toggleExpenseDrawer={() => {}}
        setEditExpenseData={() => {}}
        toggleInventoryDrawer={() => {}}
        setEditInventoryData={() => {}}
        toggleContactDrawer={() => {}}
        setEditContactData={() => {}}
        toggleNoteDrawer={() => {}}
        setEditNoteData={() => {}}
        toggleLinkDrawerOpen={() => {}}
        setEditSpaceId={() => {}}
        toggleSpaceDrawer={() => {}}
        refreshParent={() => {}}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        toggleViewWorkDrawer={() => {}}
        setViewWorkId={() => {}}
      />
      <AttachmentsOnDrawer
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        alreadyAttachedFiles={documentAttachments}
        isOpen={showFileUploadList}
        toggleDrawerOpen={setShowFileUploadList}
        icon={<WorkIcon className={"works-theme-icon"}/>}
        drawerTitle={"Document > Add attachment"}
        isSingleFileAttachment={true}
      >
        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
          <AttachButtonStyled onClick={() => {
            setDocumentAttachmentId(Array.from(selectedFiles)[0]);
            setShowFileUploadList(false);
          }}>
            Attach
          </AttachButtonStyled>
        </div>
      </AttachmentsOnDrawer>
      <Drawer
        closeIcon={false}
        width={window.innerWidth > 1024 ? "680px" : "100%"}
        visible={isOpen}
        placement="right"
        onClose={() => {
          if (formFieldsChanged) {
            confirmCloseUnsaved();
            return;
          } else performCleanup();
        }}
        headerStyle={{ backgroundColor: "#ebf9ff", margin: 0, padding: "2rem 2rem" }}
        title={
          <>
            <section
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1.5rem",
              }}
            >
              <TenantIconWrapper>
                <MyTenantIcon className={"my-property-theme-icon"} />{" "}
                <span style={{ paddingRight: "0.25rem", fontSize: "0.75rem" }}>
                MY PROPERTY
              </span>
              </TenantIconWrapper>
              <ButtonsWrapper>
                <CloseButton size={"large"} onClick={() => performCleanup()}>
                  {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
                </CloseButton>
              </ButtonsWrapper>
            </section>
            <Title
              ref={titleInput}
              placeholder={`${documentType === "floorPlan" ? "Drawing" : "Document"} name`}
              value={documentName}
              style={{ fontSize: "2.5rem", color: "#002A43", marginTop: 0 }}
              maxLength={100}
              className={titleError ? "form-title-error" : ""}
              onChange={(e) => {
                setFormFieldsChanged(true);
                setDocumentName(e.target.value)
                if (e.target.value) { setTitleError(false) }
                else { setTitleError(true) }
              }}
            />
            {titleError && (<TitleError role="alert">{documentType === "floorPlan" ? "Drawing" : "Document"} plan name is required</TitleError>)}
          </>
        }
      >
        <Form
          form={form}
          name="manageDocument"
          layout="vertical"
          size={"large"}
          onFinish={handleManageDocument}
          initialValues={initialValues()}
          onFieldsChange={() => setFormFieldsChanged(true)}
        >
          <section>
            <InputsWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <Label>{documentType === "floorPlan" ? "Drawing" : "Document"} type</Label>
                </LabelWrapper>
                <Form.Item
                  name={"type"}
                  key={"type"}
                >
                  <Select
                    style={selectStyle}
                    bordered={false}
                    placeholder={`Select ${documentType === "floorPlan" ? "drawing" : "document"} type`}
                    getPopupContainer={trigger => trigger.parentElement}
                  >
                    {documentTypes.map((type: any) => <Select.Option key={type.name} value={type.name}>{type.name}</Select.Option>)}
                  </Select>
                </Form.Item>
              </InputWrapper>
              <InputWrapper>
                <Label>Document date</Label>
                <Form.Item key={"documentDate"} name={"documentDate"}>
                  <DatePicker
                    name={"documentDate"}
                    format={propertyPreferences.preferredDateFormat}
                    allowClear={true}
                    inputReadOnly={true}
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentElement}
                    style={{
                      width: "100%",
                      border: "1px solid #DAE0E6",
                      boxSizing: "border-box",
                      borderRadius: "6px",
                    }}
                  />
                </Form.Item>
              </InputWrapper>
            </InputsWrapper>
            <InputWrapper style={{width: "100%"}}>
              <Label>Description</Label>
              <Form.Item key={"description"} name={"description"}>
                <Input
                  autoComplete={"off"}
                  style={{...selectStyle, height: "2.625rem", width: "100%"}}
                  placeholder={"Add description"}
                />
              </Form.Item>
            </InputWrapper>

            <div style={{paddingTop: "1.5rem", display: "flex", justifyContent: "space-between"}}>
              <div style={{ display: "flex" }}>
                <AttachmentIcon style={{marginRight: "0.75rem", height: "1.5em", marginTop: "0.2rem"}}/>
                <span style={{fontSize: "1.125rem", fontWeight: 600, color: "#21272B"}}>Attachments</span>
              </div>
              <Tooltip placement="top" title={"You can upload PDF, Image, Word, CSV and Excel files up to 20MB."}>
                <HelpIcon />
              </Tooltip>
            </div>
            {documentAttachments.length === 0 && <UploadFile
              parentType={"document"}
              parentId={null}
              propertyId={propertyId}
              singleAttachment={false}
              fileList={documentAttachments}
              refreshParent={refreshFile}
              uploadImmediately={false}
              setShowFileUploadList={setShowFileUploadList}
              addToFileList={setDocumentAttachmentId}
            />}
            {documentAttachments.length > 0 && <ItemRenderComp
              fileList={[]}
              parentId={null}
              parentType={null}
              propertyId={propertyId}
              file={documentAttachments[0]}
              addedRef={null}
              refreshParent={() => {}}
              setFile={clearAttachment}
              toggleViewFileDrawer={toggleViewFileDrawer}
              setAttachmentToViewId={setAttachmentToViewId}
            />}
          </section>
          <SaveButtonWrapper style={{marginTop: 0}}>
            <SaveButton
              style={{display: "flex", alignItems: "center"}}
              loading={saveDisabled}
              icon={<SaveIconStyled />}
              type={"primary"}
              htmlType={"submit"}
            >
              Save details
            </SaveButton>
          </SaveButtonWrapper>
        </Form>
      </Drawer>
    </>
  );
}

export default DocumentDrawer;