import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetInsurancesResponse } from "../../api-wrapper/insurance/getInsurances";
import {
  getInsurancesFailureAction,
  getInsurancesInitAction,
  getInsurancesSuccessAction,
  TGetInsurancesActions,
} from "../actions/insurances";
import {emptyGuid} from "../screens/helpers";

export type TInsurances = {
  fetchingStatus: TFetchingStatus;
  content: TGetInsurancesResponse;
};

const initialState: TInsurances = {
  content: [
    {
      propertyId: emptyGuid,
      id: emptyGuid,
      subscriptionId: 0,
      startDate: "",
      effectivePeriod: "",
      insuranceType: "",
      insurer: "",
      policyNumber: "",
      premium: 0,
      insurancePremiumTax: 0,
      administrationFee: 0,
      discountReceived: "",
      totalAmountPaid: 0,
      valueOfInsuredItems: 0,
      confirmed: false
    }
  ],
  fetchingStatus: TFetchingStatus.Defined,
};

export const insurancesReducer = createReducer<TInsurances, TGetInsurancesActions>(
  initialState
)
  .handleAction(getInsurancesInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getInsurancesSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getInsurancesFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
