import React, { useMemo } from "react";
import {ReactComponent as CalendarWorkIcon} from "../../../images/dashboard-calendar/CalendarWork.svg";
import {ReactComponent as CalendarExpenseIcon} from "../../../images/dashboard-calendar/CalendarExpense.svg";
import {CalendarItemStyled, FlexWrapper, ItemTextWrapper} from "./UpcomingTaskCalendar";
import {Draggable} from "react-beautiful-dnd";
import {ReactComponent as CalendarReminderIcon} from "../../../images/dashboard-calendar/CalendarReminder.svg";
import {ReactComponent as CalendarTaskIcon} from "../../../images/dashboard-calendar/CalendarTask.svg";
import {ReactComponent as CalendarBudgetCostIcon} from "../../../images/dashboard-calendar/CalendarBudgetCost.svg";
import {Dropdown} from "antd";
import {ReactComponent as CalendarBudgetCostBig} from "../../../images/dashboard-calendar/CalendarBudgetCostBig.svg";
import {ReactComponent as CalendarReminderBig} from "../../../images/planner/ReminderIcon.svg";
import {ReactComponent as CalendarTaskBig} from "../../../images/dashboard-calendar/CalendarTaskBig.svg";
import {ReactComponent as CalendarSnooze} from "../../../images/dashboard-calendar/CalendarSnooze.svg";
import {ReactComponent as CalendarReschedule} from "../../../images/dashboard-calendar/CalendarReschedule.svg";
import {ReactComponent as CalendarView} from "../../../images/dashboard-calendar/CalendarView.svg";
import {ReactComponent as CalendarDismiss} from "../../../images/dashboard-calendar/CalendarDismiss.svg";
import {ReactComponent as CalendarForward} from "../../../images/dashboard-calendar/CalendarForward.svg";
import {ReactComponent as FindTradespersonIcon} from "../../../images/dashboard-calendar/FindTradespersonIcon.svg";
import {ReactComponent as EditDetailsIcon} from "../../../images/dashboard-calendar/EditDetailsIcon.svg";

import styled from "styled-components";
import {useSelector} from "react-redux";
import {
  authSelector,
  getPropertiesSelector,
  getPropertyPreferencesSelector,
  getUserProfileSelector
} from "../../selectors";
import moment from "moment";
import ICalendarLink from "react-icalendar-link";
import { CALENDAR_FREQUENCY, Task } from "./types";
import { Link } from "react-router-dom";
import {ReactComponent as Checkmark} from "../../../images/planner/Checkmark.svg";
import {ReactComponent as AddToCalendarIcon} from "../../../images/planner/AddToCalendarIcon.svg";
import {ActionButton, getTradingOption} from "../planner/ViewTaskDrawer";
import {numberWithCommasFull} from "../helper";
import {TTaskCategories} from "../../../helpers";
import { CheckboxStyled } from "../../screens/components";
import {getCurrencySign} from "../../screens/helpers";

type Props = {
  task: Task;
  index: any;
  handleReminderAction: (arg1: any, arg2: any) => void;
  handleWorkAction: (arg1: any, arg2: any) => void;
  handleExpenseAction: (arg1: any, arg2: any) => void;
}

const CalendarItem = (props: Props) => {
  const {task, index, handleWorkAction, handleReminderAction, handleExpenseAction} = props;

  const properties = useSelector(getPropertiesSelector);
  const auth = useSelector(authSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);
  const { content: user } = useSelector(getUserProfileSelector);

  const calendarEvent = useMemo(() => {
    const property = properties.find((p) => p.propertyId === task.task.propertyId);
    return {
      title: `${task.title} - ${property?.address}@Livlet`,
      startTime: moment(task.date).startOf('day').toString(),
      endTime: moment(task.date).endOf('day').toString(),
      description: task.message,
      location: `${property?.address}@ https://uk.livlet.com/`,
    }
  }, [task, properties]);

  // ! warning - don't indent the raw string
  const calendarRawContent = useMemo(() => `RRULE:FREQ=${CALENDAR_FREQUENCY[task.task.periodicityUnit]};INTERVAL=${task.task.periodicity}
ATTENDEE;ROLE=REQ-PARTICIPANT:MAILTO:${user.email}`, [task, user]);

  const iconDispenser = (item: any) => {
    if (item.type === "work") return <CalendarWorkIcon />
    if (item.type === "expense") {
      // if (propertyPreferences.preferredCurrency === "GBP") return <CalendarExpenseIcon/>
      // else return null
      return <CalendarExpenseIcon/>
    }
    if (item.type === "alert") {
     if (item.task.category === TTaskCategories.Budget || item.task.category === TTaskCategories.CustomBudget) {
       // if (propertyPreferences.preferredCurrency === "GBP") return <CalendarBudgetCostIcon/>
       // else return null
       return <CalendarBudgetCostIcon/>
     }
     if (item.task.category === TTaskCategories.Reminder) return <CalendarReminderIcon />
     else return <CalendarTaskIcon />
    }
    else return null
  }

  const bigIconDispenser = (item: any) => {
    if (item.task.category === TTaskCategories.Budget || item.task.category === TTaskCategories.CustomBudget) return <CalendarBudgetCostBig />
    if (item.task.category === TTaskCategories.Reminder) return <CalendarReminderBig />
    else return <CalendarTaskBig />
  }

  const dropdownTitleDispenser = (item: any) => {
    if (item.task.category === TTaskCategories.Budget || item.task.category === TTaskCategories.CustomBudget) return "Recurring expense"
    if (item.task.category === TTaskCategories.Reminder) return "Reminder"
    else return "Task"
  }

  const displayAlertPeriodicity = (periodicity: string) => {
    if (!periodicity) return ""
    if (periodicity[0] === '1' && periodicity[1] === ' ') return `Once a ${periodicity.slice(2, periodicity.length)}`
    else return `Once in ${periodicity}(s)`
  }

  const reminderDropdown = (task: Task) => {
    return <ReminderDropdown>
      <FlexWrapper style={{width: "100%", justifyContent: "space-between"}}>
        <FlexWrapper>
          {bigIconDispenser(task)}
          <div style={{marginLeft: "0.5rem"}}>
            <ReminderDropdownHeader>{dropdownTitleDispenser(task)}</ReminderDropdownHeader>
            {moment(task.task.triggerDate).format(propertyPreferences.preferredDateFormat)} {task.task.periodicity ? ` - ${displayAlertPeriodicity(task.periodicity)}` : " - Once"}
          </div>
        </FlexWrapper>
        <ActionButton className={"mini light-gray"} onClick={() => handleReminderAction("viewTask", task)}>
          <CalendarView /> Open
        </ActionButton>
      </FlexWrapper>

      <FlexWrapperVertical>
         <ReminderDropdownTitle>{task.title}</ReminderDropdownTitle>
        {task.message && <span style={{wordBreak: "break-word"}}>{task.message}</span>}

        <div style={{width: "100%"}}>
          {(task.task.actualEstimatedCost > 0 || task.task.estimatedCost > 0) && <ReminderInformationRow>
            <div className={"row-title"}>Cost</div>
            <div className={"row-content"}>
              {getCurrencySign(propertyPreferences.preferredCurrency)}
              {task.task.actualEstimatedCost ? numberWithCommasFull(task.task.actualEstimatedCost) :
              task.task.estimatedCost ? numberWithCommasFull(task.task.estimatedCost) : 0}</div>
          </ReminderInformationRow>}
          {task.task.category && task.task.category !== TTaskCategories.Reminder && <ReminderInformationRow>
            <div className={"row-title"}>Livlet task category</div>
            <div className={"row-content wrap-row"}>
              <Link className={"link"} to={`/upkeep?tab=All&category=${task.task.category.replace("&", "AND")}`}>
                {task.task.category}
              </Link>
            </div>
          </ReminderInformationRow>}
        </div>

        <div style={{width: "100%"}}>
          <ActionButtonsRow>
            <div className={"action-column"}>
              <ActionButton className={"mini gray"} onClick={() =>
                setTimeout(() => {handleReminderAction("markAsCompleted", task)}, 200)}>
                <CheckboxStyled className={"small"}><Checkmark/></CheckboxStyled>
                Mark as complete?
              </ActionButton>
            </div>
            <div className={"action-column"}>
              <ActionButton className={"mini"} onClick={() =>
                setTimeout(() => {handleReminderAction("dismiss", task)}, 200)}>
                <CalendarDismiss /> Dismiss
              </ActionButton>
            </div>
          </ActionButtonsRow>
          <ActionButtonsRow>
            <div className={"action-column"}>
              <ActionButton className={"mini"} onClick={() =>
                setTimeout(() => {handleReminderAction("snooze", task)}, 200)}>
                <CalendarSnooze /> Snooze
              </ActionButton>
            </div>
            <div className={"action-column"}>
              <ActionButton className={"mini"} onClick={() =>
                setTimeout(() => {handleReminderAction("reschedule", task)}, 200)}>
                <CalendarReschedule /> Reschedule
              </ActionButton>
            </div>
          </ActionButtonsRow>
          <ActionButtonsRow>
            <div className={"action-column"}>
              <ActionButton className={"mini"} onClick={() =>
                setTimeout(() => {handleReminderAction("forwardReminder", task)}, 200)}>
                <CalendarForward /> Forward
              </ActionButton>
            </div>
            <div className={"action-column"}>
              {task && <ActionButton className={"mini"}>
                  <AddToCalendarIcon /><ICalendarLink event={calendarEvent} rawContent={calendarRawContent}>
                  Add to calendar</ICalendarLink>
              </ActionButton>}
            </div>
          </ActionButtonsRow>
          <ActionButtonsRow>
            {/*{getTradingOption(task.title, auth.subscriptionLevel) && <div className={"action-column"}>*/}
            {/*  <ActionButton className={"mini acid-green"} onClick={() => handleReminderAction("findTrade", task)}>*/}
            {/*    <FindTradespersonIcon /> {getTradingOption(task.title, auth.subscriptionLevel)}*/}
            {/*  </ActionButton>*/}
            {/*</div>}*/}
            <div className={"action-column"}>
              <ActionButton className={"mini"} onClick={() =>
                setTimeout(() => {handleReminderAction("editTask", task)}, 200)}>
                <EditDetailsIcon />Edit details
              </ActionButton>
            </div>
          </ActionButtonsRow>
        </div>
      </FlexWrapperVertical>
    </ReminderDropdown>
  }

  const returnId = (task: any) => {
    if (task.task.workId) return `work ${task.task.workId}`
    else if (task.task.expenseId) return `expense ${task.task.expenseId}`
    else return `alert ${task.task.templateId} ${task.task.originalDate}`
  }

  return <Draggable key={returnId(task)} draggableId={returnId(task)} index={index} isDragDisabled={task.type === "budgetcost"}>
    {(provided, snapshot) => {
        return <CalendarItemStyled
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            backgroundColor: snapshot.isDragging ? "#e1e5ed" : "#EFF1F5",
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 0,
            ...provided.draggableProps.style
          }}
          onClick={() => {
            switch (task.type) {
              case "work":
                handleWorkAction("view", task.task);
                break;
              case "expense":
                handleExpenseAction("edit", task.task)
                break;
              case "alert":
                window.innerWidth <= 768 && handleReminderAction("editTask", task);
                break;
              default:
                break;
            }
          }}>
          {task.type === "alert" && window.innerWidth > 768 &&
            <Dropdown placement="bottomLeft" overlay={reminderDropdown(task)} trigger={["click"]}>
              <ItemContentWrapper>
                {/*<WhiteFade />*/}
                <div style={{display: "flex", flexShrink: 0}}>{iconDispenser(task)}</div>
                <ItemTextWrapper>{task.title}</ItemTextWrapper>
              </ItemContentWrapper>
            </Dropdown>}
          {(task.type !== "alert" || window.innerWidth <= 768) && <ItemContentWrapper>
            {/*<WhiteFade />*/}
            <div style={{display: "flex", flexShrink: 0}}>{iconDispenser(task)}</div>
            <ItemTextWrapper>{task.title}</ItemTextWrapper>
          </ItemContentWrapper>}
        </CalendarItemStyled>
    }}
  </Draggable>
}

const FlexWrapperVertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;
`

const ReminderDropdown = styled.div`
  z-index: 10!important;
  width: 29.25rem;
  background: white;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #6B7185;
  font-weight: 400;
  border: 0;
  border-radius: 0.625rem;
  box-shadow: 0px 1px 5px rgba(30, 32, 37, 0.05), 0px 10px 24px rgba(26, 28, 31, 0.1);
`

const ReminderDropdownHeader = styled.div`
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #21272B;
  font-weight: 600;
`

const ItemContentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  min-width: 0;
`

const ReminderDropdownTitle = styled.div`
  font-size: 1.375rem;
  line-height: 1.75rem;
  color: #21272B;
  font-weight: 600;
`

const ReminderInformationRow = styled.div`
  width: 100%;
  display: flex;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  overflow: hidden;
  
  .row-title {
    width: 50%;
    color: #9CA4AE;
  }
  
  .row-content {
    width: 50%;
    color: #666F7A;
  }
  
  .wrap-row {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .link {
    cursor: pointer;
    color: #2979FF;
  }
`

const ActionButtonsRow = styled.div`
  width: 100%;
  display: flex;

  .action-column {
    width: 50%;
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  &.centered { justify-content: center; }
`

export default CalendarItem