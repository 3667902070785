import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetDocumentsResponse } from "../../api-wrapper/documents/addDocument";
import {
  getSurveysFailureAction,
  getSurveysInitAction,
  getSurveysSuccessAction,
  TGetSurveysActions,
} from "../actions/surveys";
import {emptyGuid} from "../screens/helpers";

export type TSurveys = {
  fetchingStatus: TFetchingStatus;
  content: TGetDocumentsResponse;
};

const initialState: TSurveys = {
  content: [
    {
      name: "",
      documentDate: "",
      documentType: "",
      description: "",
      category: "",
      relatedFileId: emptyGuid,
      documentId: emptyGuid,
      subscriptionId: 0,
      propertyId: emptyGuid
    }
  ],
  fetchingStatus: TFetchingStatus.Defined,
};

export const surveysReducer = createReducer<TSurveys, TGetSurveysActions>(
  initialState
)
  .handleAction(getSurveysInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getSurveysSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getSurveysFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
