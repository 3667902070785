import {Drawer, Select} from "antd";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ReactComponent as ContactsIcon } from "../../../images/ContactsGray.svg";
import { getPropertyIdSelector, getContactsSelector } from "../../selectors";
import { addRelation } from "../../../api-wrapper/relation/addRelation";
import {Guid} from "guid-typescript";
import {ButtonStyled, ButtonWrapper, FieldLabel, FormHeader, FormWrapper, Title} from "../inventories/AddExistingWorkDrawer";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg01: boolean) => void;
  refreshParent: () => void;
  width?: number | null;
  parentId: Guid;
  parentType: string,
  drawerTitle: string;
};

const AddExistingContactDrawer = (props: Props) => {
  const {
    isOpen,
    toggleDrawerOpen,
    parentId,
    refreshParent,
    width,
    drawerTitle,
    parentType
  } = props;
  const propertyId = useSelector(getPropertyIdSelector).value;
  const contacts = useSelector(getContactsSelector);
  const titleInput = useRef(null);

  const [relatedItems, setRelatedItems] = useState<{ key: Guid; value: string }[]>([]);
  const [contactId, setContactId] = useState<any>(null);

  const clearDrawer = () => {
    setContactId(null);
    toggleDrawerOpen(false);
  }

  const addContact = () => {
    contactId &&
      addRelation({
        propertyId,
        parentId,
        childId: contactId,
        parentType,
        childType: "contact",
      }).then(() => {
        refreshParent();
        clearDrawer();
      });
  };

  const onContactAutocompleteSearch = (searchText: string) => {
    setRelatedItems(!searchText ? contacts.content.filter((contact) =>
      contact.name && contact.contactType !== "Payee").map((contact) => {
        return {key: contact.contactId, value: contact.name}
      }) : contacts.content.filter((contact) =>
      (contact.name ? contact.name.toLowerCase().includes(searchText.toLowerCase()) : false) && contact.contactType !== "Payee"
      ).map((contact) => {
        return {key: contact.contactId, value: contact.name}
      })
    )
  }

  useEffect(() => {
    setRelatedItems(contacts.content.filter((contact) =>
      contact.name && contact.contactType !== "Payee").map((contact) => {
        return {key: contact.contactId, value: contact.name}
      })
    );
  }, [contacts]);

  useEffect(() => {
    // @ts-ignore
    titleInput.current !== null && titleInput.current.focus();
    // @ts-ignore
    titleInput.current !== null && titleInput.current.setSelectionRange(0, 0)
  }, [isOpen, titleInput])

  return (
    <Drawer
      push={{ distance: "32px" }}
      closeIcon={false}
      width={window.innerWidth > 1024 ? (width ? width + "%" : "55%") : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => clearDrawer()}
      headerStyle={{backgroundColor: "#ebf9ff", margin: 0, padding: "2rem 2rem",}}
      title={<>
          <section style={{ display: "flex", justifyContent: "space-between" }}>
            <ContactsIconWrapper>
              <ContactsIcon className={"works-stroke-theme-icon"} />{" "}
              <span style={{ paddingRight: "0.25rem" }}>{drawerTitle}</span>
            </ContactsIconWrapper>
            <HeaderButtonStyled size={"large"} onClick={() => clearDrawer()}>
              <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span>
            </HeaderButtonStyled>
          </section>
          <Title>Add contact</Title>
        </>}
    >
        <FormHeader>Select existing contact</FormHeader>
        <FormWrapper>
          <FieldLabel>Search your contact</FieldLabel>
          <Select
            placeholder={"Search for contact..."}
            showSearch
            size={"large"}
            bordered={false}
            showArrow={false}
            defaultActiveFirstOption={false}
            filterOption={false}
            value={contactId}
            onSearch={onContactAutocompleteSearch}
            style={{
              width: "100%",
              border: "1px solid #DAE0E6",
              boxSizing: "border-box",
              borderRadius: "6px",
              color: "rgb(107,113,133)",
            }}
            onChange={(val) => setContactId(val)}
          >
            {relatedItems.map((relateItem) => (
              <Select.Option key={relateItem.value} value={relateItem.key.toString()}>
                {relateItem.value}
              </Select.Option>
            ))}
          </Select>
        </FormWrapper>
      <ButtonWrapper>
        <ButtonStyled
          disabled={_.isEmpty(contactId)}
          htmlType={"submit"}
          type={"primary"}
          size={"large"}
          onClick={addContact}
        >+ Add contact
        </ButtonStyled>
      </ButtonWrapper>
    </Drawer>
  );
};

const ContactsIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

const HeaderButtonStyled = styled(ButtonStyled)`
  background-color: #ebf9ff;
`;

export default AddExistingContactDrawer;
