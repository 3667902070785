import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetPropertiesResponse } from "../../api-wrapper/properties/getProperties";
import {
  getPropertiesFailureAction,
  getPropertiesInitAction, getPropertiesResetAction,
  getPropertiesSuccessAction,
  TGetPropertiesActions,
} from "../actions/properties";
import {emptyGuid} from "../screens/helpers";

export type TProperties = {
  fetchingStatus: TFetchingStatus;
  content: TGetPropertiesResponse;
};

const initialState: TProperties = {
  content: [
    {
      propertyId: emptyGuid,
      address: "",
      city: "",
      country: "",
      zip: "",
      dateAdded: "",
      imageThumbnail: "",
      onboardingUpkeepStatus: "",
      onboardingContentStatus: "",
      onboardingImprovementsStatus: "",
      questionnairePage: 1,
      dismissExpenses: false,
      dismissHomeValue: false,
      dismissSchedule: false,
      preferredDateFormat: "",
      preferredCurrency: "",
      taxYearMonth: 0,
      taxYearDate: 0
    },
  ],
  fetchingStatus: TFetchingStatus.Defined,
};

export const propertiesReducer = createReducer<
  TProperties,
  TGetPropertiesActions
>(initialState)
  .handleAction(getPropertiesResetAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Defined;
      draftState.content = initialState.content;
    })
  )
  .handleAction(getPropertiesInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getPropertiesSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getPropertiesFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
