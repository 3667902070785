import {apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";

export type TDeleteInviteRequest = {
  email: string;
  name: string;
}

export const deleteInvite = (payload: TDeleteInviteRequest & { subscriptionId: number }) => {
  const { subscriptionId } = payload;
  let url = createUrl(`${EnvConfig.API_URL}/api/user/delete/invite/${subscriptionId}`);

  return apiPost<any, TDeleteInviteRequest>(url, payload);
};
