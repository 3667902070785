import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getMaintenancesSelector,
  getPropertiesSelector,
  getPropertyIdSelector,
  getPropertyPreferencesSelector
} from "../selectors";
import MaintenanceListComp from "../components/maintenances/MaintenanceListComp";
import {getAllMaintenances} from "../actions/maintenances";
import _ from "lodash";
import WorkDrawer from "../components/works/WorkDrawer";
import ExpenseDrawer from "../components/expenses/ExpenseDrawer";
import {getAllExpenseTypes} from "../actions/expenses";
import {getAllInventories} from "../actions/inventories";
import {
  AddButtonStyled,
  HeaderControlsWrapper,
  PageHeader,
  PageInfoColumn,
  PageInfoWrapper,
  PageSubtitle,
  PageTitle,
  PageWrapper
} from "./components";
import {numberWithCommas} from "../components/helper";
import {PlusOutlined} from "@ant-design/icons";
import {emptyGuid, getCurrencySign} from "./helpers";
import {getMaintenanceTotalCost} from "../../api-wrapper/maintenance/getMaintenanceTotalCost";
import {Guid} from "guid-typescript";
import {getEntityTypes} from "../../api-wrapper/relation/getEntityTypes";
import UpdateTaskDrawer, {getTaskType} from "../components/planner/UpdateTaskDrawer";
import AddEventDrawer from "../components/planner/AddEventDrawer";
import InventoryDrawer from "../components/inventories/InventoryDrawer";
import ViewWorkDrawer from "../components/works/ViewWorkDrawer";
import LinkDrawer from "../components/spaces/LinkDrawer";
import ViewSpaceDrawer from "../components/spaces/ViewSpaceDrawer";
import NoteDrawer from "../components/notes/NoteDrawer";
import {getAllSpaces} from "../actions/spaces";
import ViewFileDrawer from "../components/attachments/ViewFileDrawer";
import ContactDrawer from "../components/contacts/ContactDrawer";

const Upkeep = () => {
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const dbMaintenances = useSelector(getMaintenancesSelector);
  const properties = useSelector(getPropertiesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [maintenances, setMaintenances] = useState<any>([])
  const [maintenanceTypes, setMaintenanceTypes] = useState<any>([]);
  const [maintenanceTypesMap, setMaintenanceTypesMap] = useState<any>([]);
  const [contentTypes, setContentTypes] = useState<any>([]);

  const [isViewWorkDrawerOpen, toggleViewWorkDrawer] = useState(false);
  const [workToViewId, setWorkToViewId] = useState<any>(null);
  const [isViewFileDrawerOpen, toggleViewFileDrawer] = useState(false);
  const [attachmentToViewId, setAttachmentToViewId] = useState<any>(null);
  const [isNoteDrawerOpen, toggleNoteDrawer] = useState(false);
  const [editingNoteData, setEditingNoteData] = useState(null);
  const [isViewSpaceDrawerOpen, toggleViewSpaceDrawer] = useState(false);
  const [viewSpaceId, setViewSpaceId] = useState(null);
  const [isLinkDrawerOpen, toggleLinkDrawer] = useState(false);
  const [presetLinkType, setPresetLinkType] = useState<any>(null);
  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editingContactData, setEditingContactData] = useState(null);
  const [editingInventoryData, setEditingInventoryData] = useState<any>(null);
  const [isInventoryDrawerOpen, toggleInventoryDrawer] = useState(false);

  useEffect(() => {
    setMaintenances(dbMaintenances.content)
  }, [dbMaintenances])

  const [isEventDrawerOpen, toggleEventDrawer] = useState(false);
  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editingWorkData, setEditingWorkData] = useState<any>(null);
  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [editingExpenseData, setEditingExpenseData] = useState<any>(null);
  const [isTaskDrawerOpen, toggleTaskDrawer] = useState(false);
  const [editingTaskData, setEditingTaskData] = useState<any>(null);
  const [taskType, setTaskType] = useState("");

  useEffect(() => {
    setTaskType(getTaskType(editingTaskData))
  }, [editingTaskData])

  const maintenancesSubscribedTo = maintenances.filter((maintenance: any) => maintenance.subscribed).length
  const [maintenancesEstimatedCostYear, setMaintenancesEstimatedCostYear] = useState(0)
  const [maintenancesEstimatedCostTaxYear, setMaintenancesEstimatedCostTaxYear] = useState(0)

  useEffect(() => {
    dispatch(getAllMaintenances(propertyId));
    dispatch(getAllExpenseTypes(propertyId));
    dispatch(getAllInventories(propertyId));
  }, [propertyId])

  useEffect(() => {
    if (_.isEmpty(properties) || _.isEqual(properties[0].propertyId, emptyGuid.toJSON())
      || _.isEqual(properties[0].propertyId, emptyGuid) || _.isEqual(propertyId, emptyGuid))
      return;
    getMaintenanceTotalCost(propertyId).then((res) => {
      res && setMaintenancesEstimatedCostYear(res.totalCostYear)
      res && setMaintenancesEstimatedCostTaxYear(res.totalCostTaxYear)
    })
  }, [properties, maintenances])

  useEffect(() => {
    let maintenanceMap = new Map();
    let maintenanceTypesArray = ["User-created", "Recurring expense - User-created"]

    maintenanceMap.set("All", 0)
    maintenanceMap.set("My maintenances", 0)
    maintenanceMap.set("Recommended", 0)
    maintenanceMap.set("Not applicable", 0)

    maintenances.map((maintenance: any) => {
      if (maintenanceMap.has(maintenance.category)) {
        !maintenance.notApplicable && maintenanceMap.set(maintenance.category, maintenanceMap.get(maintenance.category)+1);
      } else {
        if (!maintenance.notApplicable && maintenance.category !== "Reminder") {
          maintenanceMap.set(maintenance.category, 1);
          !maintenanceTypesArray.includes(maintenance.category) && maintenanceTypesArray.push(maintenance.category)
        }
      }
      maintenanceMap.set("All", maintenanceMap.get("All") + 1)
      maintenance.subscribed && maintenanceMap.set("My maintenances", maintenanceMap.get("My maintenances") + 1)
      maintenance.recommended && maintenanceMap.set("Recommended", maintenanceMap.get("Recommended") + 1)
      maintenance.notApplicable && maintenanceMap.set("Not applicable", maintenanceMap.get("Not applicable") + 1)
    })

    setMaintenanceTypesMap(maintenanceMap)
    setMaintenanceTypes(["User-created", "Recurring expense - User-created",
      ...maintenanceTypesArray.filter((i: string) => i !== "User-created" && i !== "Recurring expense - User-created").sort()])
  }, [maintenances])

  useEffect(() => {
    const getContentTypes = async (contentTypes: any) => {
      const contentTypePromises = contentTypes.map(async (type: any) => {
        return await getEntityTypes(propertyId, type)
      });
      return await Promise.all(contentTypePromises);
    }

    const getCleanContentTypes = async () => {
      const contentTypes = ["Appliances", "Systems", "Electronics", "Spaces"]
      return await getContentTypes(contentTypes)
    }

    getCleanContentTypes().then((res) => {
      let cleanContentTypes: any = []
      res && res.map((contentType: any) => contentType && cleanContentTypes.push(...contentType
        .filter((type: any) => type.name !== "Other" && type.name !== "")))
      setContentTypes(cleanContentTypes)
    })
  }, [propertyId])

  const toggleSubscribe = (id: Guid, isSubscribed: boolean) => {
    let maintenanceToUpdate = maintenances.filter((maintenance: any) => maintenance.id === id)[0]
    setMaintenances([...maintenances.filter((maintenance: any) => maintenance.id !== id), {...maintenanceToUpdate, subscribed: isSubscribed}])
  }

  const updateMaintenance = (id: Guid, newValues: any) => {
    let maintenanceToUpdate = maintenances.filter((maintenance: any) => maintenance.id === id)[0]
    setMaintenances([...maintenances.filter((maintenance: any) => maintenance.id !== id), maintenanceToUpdate])
  }

  return (
    <PageWrapper>
      <WorkDrawer
        isOpen={isWorkDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        setEditingWorkData={setEditingWorkData}
        work={editingWorkData}
        />
      <ExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        toggleDrawerOpen={toggleExpenseDrawer}
        expense={editingExpenseData}
        setEditingExpenseData={setEditingExpenseData}
      />
      <AddEventDrawer
        isOpen={isEventDrawerOpen}
        toggleDrawer={toggleEventDrawer}
        setSelectedType={setTaskType}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditingWorkData={setEditingWorkData}
        toggleWorkDrawer={toggleWorkDrawer}
      />
      <UpdateTaskDrawer
        task={editingTaskData}
        setEditingTaskData={setEditingTaskData}
        isOpen={isTaskDrawerOpen}
        toggleDrawer={toggleTaskDrawer}
        taskType={taskType}
        setTaskType={setTaskType}
      />
      <InventoryDrawer
        isOpen={isInventoryDrawerOpen}
        toggleDrawerOpen={toggleInventoryDrawer}
        width={null}
        // @ts-ignore
        setEditingInventoryData={setEditingInventoryData}
        inventory={editingInventoryData}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
      />
      <ViewWorkDrawer
        workId={workToViewId}
        setWorkId={setWorkToViewId}
        isOpen={isViewWorkDrawerOpen}
        toggleDrawer={toggleViewWorkDrawer}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditingWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setLinkedWorkId={() => {}}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setWorkIdToForward={() => {}}
        toggleForwardDrawer={() => {}}
        toggleTradeDrawer={() => {}}
        setEditSpaceId={setViewSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        setPresetLinkType={() => {}}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <LinkDrawer
        parentId={viewSpaceId}
        parentType={"cost"}
        isOpen={isLinkDrawerOpen}
        toggleDrawerOpen={toggleLinkDrawer}
        presetLinkType={presetLinkType}
        setPresetLinkType={setPresetLinkType}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditingWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setEditSpaceId={setViewSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        toggleEventDrawer={() => {}}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <ViewSpaceDrawer
        spaceId={viewSpaceId}
        isOpen={isViewSpaceDrawerOpen}
        toggleDrawerOpen={toggleViewSpaceDrawer}
        toggleEditDrawerOpen={toggleViewSpaceDrawer}
        setEditSpaceId={setViewSpaceId}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setLinkedSpaceId={() => {}}
        setPresetLinkType={setPresetLinkType}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditingWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <NoteDrawer
        currentType={"note"}
        data={editingNoteData}
        isOpen={isNoteDrawerOpen}
        toggleDrawerOpen={toggleNoteDrawer}
        refreshParent={() => dispatch(getAllSpaces(propertyId))}
        isGlobalNote={true}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        setPresetLinkType={setPresetLinkType}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        setEditSpaceId={setViewSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <ViewFileDrawer
        attachmentId={attachmentToViewId}
        setAttachmentId={setAttachmentToViewId}
        isOpen={isViewFileDrawerOpen}
        toggleDrawer={toggleViewFileDrawer}
        viewFileDrawerDefaultTab={"preview"}
        attachmentsWithoutProperty={[]}
        duplicateAttachments={[]}
        selectedTags={[]}
        toggleEditTagsMode={() => {}}
        toggleEditDrawerOpen={() => {}}
        setPresetLinkType={() => {}}
        toggleWorkDrawer={() => {}}
        setEditWorkData={() => {}}
        toggleExpenseDrawer={() => {}}
        setEditExpenseData={() => {}}
        toggleInventoryDrawer={() => {}}
        setEditInventoryData={() => {}}
        toggleContactDrawer={() => {}}
        setEditContactData={() => {}}
        toggleNoteDrawer={() => {}}
        setEditNoteData={() => {}}
        toggleLinkDrawerOpen={() => {}}
        setEditSpaceId={() => {}}
        toggleSpaceDrawer={() => {}}
        refreshParent={() => {}}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        toggleViewWorkDrawer={() => {}}
        setViewWorkId={() => {}}
      />
      <ContactDrawer
        isOpen={isContactDrawerOpen}
        toggleDrawerOpen={toggleContactDrawer}
        contact={editingContactData}
        // @ts-ignore
        setEditingContactData={setEditingContactData}
        refreshParent={() => dispatch(getAllSpaces(propertyId))}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
      />
      <PageHeader>
        <HeaderControlsWrapper>
          <div>
            <PageTitle>Maintenance checklist</PageTitle>
            <PageSubtitle>Stay on top of regular maintenance jobs with timely reminders</PageSubtitle>
          </div>
        </HeaderControlsWrapper>
        <HeaderControlsWrapper>
          <AddButtonStyled
            icon={<PlusOutlined />}
            onClick={() => toggleEventDrawer(true)}
          >
            {window.innerWidth > 1024 && "Add an event"}
          </AddButtonStyled>
        </HeaderControlsWrapper>
      </PageHeader>
      <PageInfoWrapper>
        <PageInfoColumn>
          <div className={"column-header"}>Total subscriptions</div>
          <div className={"column-content"}>
            {maintenancesSubscribedTo}
          </div>
        </PageInfoColumn>
        <PageInfoColumn>
          <div className={"column-header"}>Expected cost this year</div>
          <div className={"column-content"}>
            {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(maintenancesEstimatedCostYear)}
          </div>
        </PageInfoColumn>
        <PageInfoColumn>
          <div className={"column-header"}>Expected cost this tax year</div>
          <div className={"column-content"}>
            {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(maintenancesEstimatedCostTaxYear)}
          </div>
        </PageInfoColumn>
      </PageInfoWrapper>
      <MaintenanceListComp
        maintenances={maintenances}
        maintenanceTypes={maintenanceTypes}
        maintenanceTypesMap={maintenanceTypesMap}
        contentTypes={contentTypes}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditingExpenseData={setEditingExpenseData}
        toggleUpkeepDrawer={toggleTaskDrawer}
        setEditingUpkeepData={setEditingTaskData}
        toggleSubscribe={toggleSubscribe}
        updateMaintenance={updateMaintenance}
       />
    </PageWrapper>
  )
};

export default Upkeep;