import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";


export const assignExpenseProperty = (propertyId: Guid, recordId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/assignExpense/${recordId}`,
    {}
  );

  return apiGet<{}>(url);
};
