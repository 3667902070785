import moment from "moment";
import {snoozeUpcomingTask} from "../../../api-wrapper/upcomingTasks/snoozeUpcomingTask";
import {Modal} from "antd";
import {deleteUpcomingTask} from "../../../api-wrapper/upcomingTasks/dismissUpcomingTask";
import {completeTask} from "../../../api-wrapper/upcomingTasks/completeTask";

export const handleSnooze = (reminder: any, handleRefresh?: () => void, handleRefreshTask?: (arg: any) => void) => {
  let snoozeDate = moment(reminder.triggerDate).add(1, 'w')

  let nextReminderDate = (reminder.actualPeriodicityUnit === "once" || !reminder.actualPeriodicityUnit && reminder.periodicityUnit === "once") ? null :
    moment(reminder.originalDate).add(reminder.actualPeriodicity ?? reminder.periodicity,
      reminder.actualPeriodicityUnit ?? reminder.periodicityUnit)

  if (!nextReminderDate || snoozeDate.isBefore(nextReminderDate)) {
    snoozeUpcomingTask({
      ...reminder,
      snoozeDate: moment(reminder.triggerDate).add(1, 'w').format('YYYY-MM-DD')})
      .then((res: any) => {
        handleRefresh && handleRefresh()
        handleRefreshTask && handleRefreshTask(res.data)
      })
  } else Modal.error({
    title: "Error",
    content: "Cannot snooze this task past the following task date in the series.",
    okText: "OK",
    cancelText: "Cancel",
    className: "form-confirm-close-modal",
  });
}

export const confirmDismissSingle = (reminder: any, handleRefresh?: () => void, setReminderToDismiss?: (arg: any) => void) => {
  Modal.confirm({
    title: "Are you sure you want to delete this task?",
    content: "This action will remove the current reminder from your To-do list.",
    okText: "OK",
    cancelText: "Cancel",
    className: "form-confirm-close-modal",
    onOk() {
      deleteUpcomingTask(reminder).then(() => {
        handleRefresh && handleRefresh()
        setReminderToDismiss && setReminderToDismiss(null)
      })
    },
    onCancel() {
      setReminderToDismiss && setReminderToDismiss(null)
    }
  });
}

export const markReminderAsComplete = (reminder: any, handleRefresh?: () => void) => {
  completeTask({
    propertyId: reminder.propertyId,
    task: reminder,
    completionDate: moment().format("YYYY-MM-DD"),
    totalCost: 0
  }).then(() => {
    handleRefresh && handleRefresh()
  })
}



