import React from "react";
import {List, Modal, Skeleton} from "antd";
import styled from "styled-components";
import Ellipsis from "ant-design-pro/lib/Ellipsis";

import { TGetWorksResponse } from "../../../api-wrapper/works/getWorks";
import { ReactComponent as DeleteImage } from "../../../images/Delete.svg";
import { TAddInventoryResponse } from "../../../api-wrapper/inventories/addInventory";
import {numberWithCommas, renderRecurringText} from "../helper";
import {TagStyledBold} from "../works/WorksListComp";
import {emptyGuid, getCurrencySign} from "../../screens/helpers";
import {removeRelation} from "../../../api-wrapper/relation/removeRelation";
import {TAddContactResponse} from "../../../api-wrapper/contacts/addContact";
import moment from "moment";
import {useSelector} from "react-redux";
import {getPropertyPreferencesSelector} from "../../selectors";

type Props = {
  works: TGetWorksResponse;
  drawerTitle: string | null;
  refreshParent: () => void;
  parentToClose: (arg01:boolean) => void;
  inventory: TAddInventoryResponse | null;
  contact?: TAddContactResponse | null;
  defaultOpenType: string | null;
  setDefaultOpenType: (arg01: string) => void;
  toggleViewWorkDrawer: (arg: boolean) => void;
  setViewWorkId: (arg: any) => void;
};

const InventoryWorksListComp = (props: Props) => {
  const {
    refreshParent,
    works,
    parentToClose,
    // drawerTitle,
    // setDefaultOpenType,
    // defaultOpenType,
    inventory,
    contact,
    setViewWorkId, toggleViewWorkDrawer,
  } = props;

  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  function confirmUnlink(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will still be able to find it in the Project page.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        const childId = (contact ? contact.contactId : inventory ? inventory.id : emptyGuid);
        const childType = (contact ? "contact" : inventory ? "inventory" : null);
        childId && childType && removeRelation(item.propertyId, item.workId, "work", childId, childType).then(() => {
          if (refreshParent) refreshParent();
        });
      }
    });
  }

  // function confirmDelete(item: any) {
  //   Modal.confirm({
  //     title: "Are you sure you want to delete this item?",
  //     content:
  //       "You will not be able to restore it after deletion.",
  //     okText: "OK",
  //     cancelText: "Cancel",
  //     className: "form-confirm-close-modal",
  //     onOk() {
  //       removeWork(propertyId, item.workId).then(() =>
  //         refreshParent()
  //       )
  //     }
  //   });
  // }

  return (
    <>
      <WorkListDesktop>
        <List
          style={{ width: "100%", alignItems: "center" }}
          itemLayout="horizontal"
          dataSource={works}
          renderItem={(item) => (
            <ListItemStyled
              actions={[
                <DeleteImage
                  onClick={() => confirmUnlink(item)}/>,
              ]}
            >
              <Skeleton loading={false} title={false} active>
                <List.Item.Meta
                  title={
                    <span
                      onClick={() => {
                        parentToClose(false);
                        toggleViewWorkDrawer(true);
                        setViewWorkId(item.workId);
                      }}
                    >
                      <Ellipsis length={70}>{item.name}</Ellipsis>
                    </span>
                  }
                  description={
                    item.isComplete
                      ? "Completed on: " + moment(item.completedDate).format(propertyPreferences.preferredDateFormat)
                      : item.recurring && item.recurring !== "None"
                      ? "Recurring " +
                        renderRecurringText(item.recurring) +
                        (item.dueDate ? " - Due " + moment(item.dueDate).format(propertyPreferences.preferredDateFormat) : "\u00A0")
                      :  moment(item.dueDate).format(propertyPreferences.preferredDateFormat)
                      ? "Due " + moment(item.dueDate).format(propertyPreferences.preferredDateFormat)
                      : "\u00A0"
                  }
                />
              </Skeleton>
              {item.workTotalExpense > 0 && (
                <TagStyledBold>
                  {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(item.workTotalExpense)}
                </TagStyledBold>
              )}

            </ListItemStyled>
          )}
        />
      </WorkListDesktop>
      <WorkListMobile>
        <List
          style={{ width: "100%", alignItems: "center" }}
          itemLayout="vertical"
          dataSource={works}
          renderItem={(item) => (
            <ListItemStyled>
              <Skeleton loading={false} title={false} active>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <List.Item.Meta
                    style={{ margin: 0 }}
                    title={
                      <span
                        onClick={() => {
                          toggleViewWorkDrawer(true);
                          setViewWorkId(item.workId);
                        }}
                      >
                        <Ellipsis length={20}>{item.name}</Ellipsis>
                      </span>
                    }
                    description={
                      item.isComplete
                        ? "Completed on: " + item.completedDate
                        : item.recurring && item.recurring !== "None"
                        ? "Recurring " +
                          item.recurring.toLowerCase() +
                          (item.dueDate ? " - Due " + item.dueDate : "\u00A0")
                        : item.dueDate
                        ? "Due " + item.dueDate
                        : "\u00A0"
                    }
                  />
                  {/*{item.attachment && (*/}
                  {/*  <TagStyled icon={<AttachmentsMobile />}>1</TagStyled>*/}
                  {/*)}*/}
                  <DeleteImage
                    onClick={() => confirmUnlink(item)}
                  />
                </div>
              </Skeleton>
            </ListItemStyled>
          )}
        />
      </WorkListMobile>
    </>
  );
};

const WorkListDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0.625rem;
  width: 100%;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const WorkListMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0.625rem;
  width: 100%;
  padding: 0 0.75rem;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const ListItemStyled = styled(List.Item)`
  // .ant-list-item-action {
  //   margin-left: 0;
  // }
  @media (max-width: 1024px) {
    width: 100%;

    .ant-list-item-action > li {
      width: 100%;
    }
  }
  @media (min-width: 1024px) {
    height: 6rem;
    padding: 1em 1.25rem;
    padding-right: 2rem;
  }

  .ant-list-item-meta-title {
    font-size: 1rem;
    font-weight: 600;
    color: #21272b;
  }
  .ant-list-item-meta-description {
    color: #6b7185;
    font-weight: 400;
    margin-top: 0.6rem;
  }
  .ant-tag {
    margin: 0;
  }
`;

export default InventoryWorksListComp;
