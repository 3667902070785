import {apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {TGetUserInterestsResponse} from "./getUserInterests";
import {Guid} from "guid-typescript";

export type TSetUserInterestsRequest = {basicInterestIds: Array<Guid>};

export const setUserInterests = (payload: TSetUserInterestsRequest) => {
  let url = createUrl(`${EnvConfig.API_URL}/api/interests`);

  return apiPost<TGetUserInterestsResponse, TSetUserInterestsRequest>(url, payload)
};
