import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetUpcomingTasksResponse } from "../../api-wrapper/upcomingTasks/getUpcomingTasks";
import {
  getUpcomingTasksFailureAction,
  getUpcomingTasksInitAction,
  getUpcomingTasksSuccessAction,
  TGetUpcomingTasksActions,
} from "../actions/upcomingTasks";
import {emptyGuid} from "../screens/helpers";

export type TUpcomingTasks = {
  fetchingStatus: TFetchingStatus;
  content: TGetUpcomingTasksResponse;
};

const initialState: TUpcomingTasks = {
  content: {
    tasks: [ {
      id: emptyGuid,
      propertyId: emptyGuid,
      templateId: emptyGuid,
      subscriptionId: 0,

      type: "",
      date: "",
      title: "",
      message: "",
      periodicity: "",
      periodicityUnit: "",
      parentId: emptyGuid,
      parentType: "",
      parentName: "",
      recordAs: "",

      templateType: "",
      originalDate: "",
      triggerDate: "",
      templateStartDate: "",
      sendTo: null,
      task: {}
    }],
    tasksExists: false,
    startDate: "",
    endDate: ""
  },
  fetchingStatus: TFetchingStatus.Defined,
};

export const upcomingTasksReducer = createReducer<TUpcomingTasks, TGetUpcomingTasksActions>(
  initialState
)
  .handleAction(getUpcomingTasksInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getUpcomingTasksSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getUpcomingTasksFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
