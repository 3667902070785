import {apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import _ from "lodash";
import {TAddRelationResponse} from "./addRelation";

export type TDisableRelationRequest = {
  propertyId: Guid;
  parentId: Guid;
  parentType: string;
  childType: string;
  childId: Guid;
  disabled: boolean;
};

export const disableRelation = (payload: TDisableRelationRequest) => {
  const { propertyId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/entity/relation/disable`
  );

  return apiPost<
    TAddRelationResponse,
    {
      parentId: Guid;
      parentType: string;
      childType: string;
      childId: Guid;
      disabled: boolean;
    }
    >(url, _.omit(payload, ["propertyId"]));
};
