import {Guid} from "guid-typescript";
import {apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";

export type TTag = {
  tagId: Guid,
  name: string
}

export const addNewTag = (newTag: string) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/tag/${newTag}`
  );

  return apiPost<TTag, any>(url);
};