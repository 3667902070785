import { apiDelete, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export const deleteCustomTag = (tagId: Guid) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/tag/${tagId}/delete`
  );

  return apiDelete(url);
};
