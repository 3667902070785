import { apiGet, createUrl } from "../apiCall";
import EnvConfig from "../../config";

export type SubscriptionUsers = {
  name: string,
  email: string,
  inviteTime: string,
  acceptedInvite: boolean,
  userId: number
};

export type TGetSubscriptionUsersResponse = Array<SubscriptionUsers>;

export const getSubscriptionUsers = (subscriptionId: number) => {
  let url = createUrl(`${EnvConfig.API_URL}/api/subscriptionUsers/${subscriptionId}`);

  return apiGet<TGetSubscriptionUsersResponse>(url)
};
