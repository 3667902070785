import React, {useState} from "react";
import styled from "styled-components";
import { ReactComponent as SpaceRooms } from "../../../images/spaces/SpaceRooms.svg";
import { ReactComponent as SpaceOther } from "../../../images/spaces/SpaceOther.svg";

import { ReactComponent as SpaceTypeMasterBedroom } from "../../../images/spaces/SpaceTypeMasterBedroom.svg";
import { ReactComponent as SpaceTypeBedroom } from "../../../images/spaces/SpaceTypeBedroom.svg";
import { ReactComponent as SpaceTypeOffice } from "../../../images/spaces/SpaceTypeOffice.svg";
import { ReactComponent as SpaceTypeBathroom } from "../../../images/spaces/SpaceTypeBathroom.svg";
import { ReactComponent as SpaceTypeKitchen } from "../../../images/spaces/SpaceTypeKitchen.svg";
import { ReactComponent as SpaceTypeLivingRoom } from "../../../images/spaces/SpaceTypeLivingRoom.svg";
import { ReactComponent as SpaceTypeOtherIndoors } from "../../../images/spaces/SpaceTypeOtherIndoors.svg";
import { ReactComponent as SpaceTypeGarden } from "../../../images/spaces/SpaceTypeGarden.svg";
import { ReactComponent as SpaceTypeGarage } from "../../../images/spaces/SpaceTypeGarage.svg";
import { ReactComponent as SpaceTypeOtherOutdoors } from "../../../images/spaces/SpaceTypeOtherOutdoors.svg";
import { ReactComponent as SpaceTypeRoof } from "../../../images/spaces/SpaceTypeRoof.svg";
import { ReactComponent as SpaceTypeDriveway } from "../../../images/spaces/SpaceTypeDriveway.svg";
import { ReactComponent as SpaceTypeShed } from "../../../images/spaces/SpaceTypeShed.svg";
import { ReactComponent as SpaceTypeBasement } from "../../../images/spaces/SpaceTypeBasement.svg";

type Props = {
  selectedType: string;
  setSelectedType: (arg: string) => void;
}

const AddSpaceOptions = (props: Props) => {
  const {selectedType, setSelectedType} = props;

  const [isRoomsContainerOpen, toggleRoomsContainer] = useState(true);
  const [isSpacesContainerOpen, toggleSpacesContainer] = useState(false);

  const selectType = (e: any, type: string) => {
    e.stopPropagation()
    setSelectedType(type)
  }

  return <SpaceOptions>
    <SpaceContainer
        className={isRoomsContainerOpen ? "open" : "closed"}
        onClick={() => toggleRoomsContainer(!isRoomsContainerOpen)}
      >
        <ContainerTitle><SpaceRooms />Rooms</ContainerTitle>
        {isRoomsContainerOpen && <ContainerItemWrapper>
          <ContainerItem
              className={selectedType === "Bedroom" ? "selected" : ""}
              onClick={(e) => selectType(e, "Master bedroom")}>
              <SpaceTypeMasterBedroom style={{flexShrink: 0}} /> <ContainerText>Master bedroom</ContainerText>
          </ContainerItem>
          <ContainerItem
              className={selectedType === "Bedroom" ? "selected" : ""}
              onClick={(e) => selectType(e, "Bedroom")}>
              <SpaceTypeBedroom style={{flexShrink: 0}} /> <ContainerText>Bedroom</ContainerText>
          </ContainerItem>
          <ContainerItem
              className={selectedType === "Home office" ? "selected" : ""}
              onClick={(e) => selectType(e, "Home office")}>
              <SpaceTypeOffice style={{flexShrink: 0}} /> <ContainerText>Home office</ContainerText>
          </ContainerItem>
          <ContainerItem
              className={selectedType === "Bathroom" ? "selected" : ""}
              onClick={(e) => selectType(e, "Bathroom")}>
              <SpaceTypeBathroom style={{flexShrink: 0}} /> <ContainerText>Bathroom</ContainerText>
          </ContainerItem>
          <ContainerItem
              className={selectedType === "Kitchen" ? "selected" : ""}
              onClick={(e) => selectType(e, "Kitchen")}>
              <SpaceTypeKitchen style={{flexShrink: 0}} /> <ContainerText>Kitchen</ContainerText>
          </ContainerItem>
          <ContainerItem
              className={selectedType === "Living room" ? "selected" : ""}
              onClick={(e) => selectType(e, "Living room")}>
              <SpaceTypeLivingRoom style={{flexShrink: 0}} /> <ContainerText>Living room</ContainerText>
          </ContainerItem>
          <ContainerItem
              className={selectedType === "Other room" ? "selected" : ""}
              style={{width: "100%"}}
              onClick={(e) => selectType(e, "Other room")}>
              <SpaceTypeOtherIndoors style={{flexShrink: 0}} /> <ContainerText>Other room</ContainerText>
          </ContainerItem>
        </ContainerItemWrapper>}
      </SpaceContainer>
      <SpaceContainer
        className={isSpacesContainerOpen ? "open" : "closed"}
        onClick={() => toggleSpacesContainer(!isSpacesContainerOpen)}
      >
        <ContainerTitle><SpaceOther style={{flexShrink: 0}} />Other spaces</ContainerTitle>
        {isSpacesContainerOpen && <ContainerItemWrapper>
          <ContainerItem
              className={selectedType === "Basement" ? "selected" : ""}
              onClick={(e) => selectType(e, "Basement")}>
              <SpaceTypeBasement style={{flexShrink: 0}} /> <ContainerText>Basement</ContainerText>
          </ContainerItem>
          <ContainerItem
              className={selectedType === "Roof" ? "selected" : ""}
              onClick={(e) => selectType(e, "Roof")}>
              <SpaceTypeRoof style={{flexShrink: 0}} /> <ContainerText>Roof</ContainerText>
          </ContainerItem>
          <ContainerItem
              className={selectedType === "Garden" ? "selected" : ""}
              onClick={(e) => selectType(e, "Garden")}>
              <SpaceTypeGarden style={{flexShrink: 0}} /> <ContainerText>Garden</ContainerText>
          </ContainerItem>
          <ContainerItem
              className={selectedType === "Shed" ? "selected" : ""}
              onClick={(e) => selectType(e, "Shed")}>
              <SpaceTypeShed style={{flexShrink: 0}} /> <ContainerText>Shed</ContainerText>
          </ContainerItem>
          <ContainerItem
              className={selectedType === "Driveway" ? "selected" : ""}
              onClick={(e) => selectType(e, "Driveway")}>
              <SpaceTypeDriveway style={{flexShrink: 0}} /> <ContainerText>Driveway</ContainerText>
          </ContainerItem>
          <ContainerItem
              className={selectedType === "Garage" ? "selected" : ""}
              onClick={(e) => selectType(e, "Garage")}>
              <SpaceTypeGarage style={{flexShrink: 0}} /> <ContainerText>Garage</ContainerText>
          </ContainerItem>
          <ContainerItem
              className={selectedType === "Other space" ? "selected" : ""}
              style={{width: "100%"}}
              onClick={(e) => selectType(e, "Other space")}>
              <SpaceTypeOtherOutdoors style={{flexShrink: 0}} /> <ContainerText>Other space</ContainerText>
          </ContainerItem>
        </ContainerItemWrapper>}
      </SpaceContainer>
  </SpaceOptions>
}

export const SpaceOptions = styled.div`
  width: 100%;
`

export const SpaceContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  transition: all .5s ease;
  
  &.closed { 
    min-height: 8.25rem;
    cursor: pointer
    background: white;
  }
  
  &.open { 
    background: #F0F2F5;
    row-gap: 1rem;
  } 
  
  @media (max-width: 768px) {
    &.event-drawer {
      background: white;
      padding 0;
      border: 0;
    }
  }
`

export const ContainerTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #45655E;
  column-gap: 0.5rem;
  
  .fake-link {
    cursor: pointer;
    color: #2A80E1;
    transition: 0.5s all ease;
  }
  
  .fake-link:hover { color: #40a9ff; }
`

export const ContainerItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  
  &.record-drawer {
    .ant-upload.ant-upload-select { 
      display: flex; 
    }
    span {  
      width: 100%!important; 
    }
  }
  
  @media (max-width: 768px) {
    &.event-drawer {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 1rem;
    }
  }
`

export const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  column-gap: 0.5rem;
  
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  width: calc(100% / 2 - 0.25rem);
  height: 6.5rem;
  padding: 0 2.75rem;
  
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #45655E;
  background: white;
  transition: all .5s ease;
  
  &.full-width { width: 100%; }
  
  &.stretched {
    width: calc(100% / 2 - 0.25rem);
    border-radius: 1rem;
    height: 8.125rem;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #45655E;
  }
  
  &.bold {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #45655E;
  }
  
  &.files { width: 100%!important; flex: 100 0 100%; }
  
  &:hover, &.selected {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #2E2D68;
  }
  
  @media (max-width: 768px) {
    &.bold { 
      height: auto;
      padding: 0.625rem 1rem;
    }
  }
`

export const ContainerText = styled.span`
  width: 4.656rem;
  text-align: left;
`

export default AddSpaceOptions