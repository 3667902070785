import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";

export type TSetUserIntroductionsRequest = {
  contents: string | null;
  spaces: string | null;
  documents: string | null;
};

export const setUserIntroductions = (payload: TSetUserIntroductionsRequest) => {
  let url = createUrl(`${EnvConfig.API_URL}/api/user/introductions`);

  return apiPut<any, TSetUserIntroductionsRequest>(url, payload)
};
