import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

export type TAddSpaceRequest = {
  name: string;
  category: string;
  floor: string;
  notes: string;
  spaceType: string;
  dimension1: string;
  dimension2: string;
  paint: string;
};

export const addSpace = (payload: { request: TAddSpaceRequest, propertyId: Guid, spaceImage: any; }) => {
  const { propertyId } = payload;
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/space`);

  let bodyFormData = new FormData();
  bodyFormData.append('spaceCreateDto',
    new Blob([JSON.stringify(payload.request)], {type: 'application/json; charset=UTF-8'}));
  bodyFormData.append("spaceImage", payload.spaceImage);

  return apiPost<any, any>(url, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
