import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import _ from "lodash";

export type TEditPreferredSettingsRequest = {
  preferredDateFormat: string;
  preferredCurrency: string;
  taxYearMonth: number,
  taxYearDate: number
}

export const editPreferredSettings = (payload: TEditPreferredSettingsRequest & {propertyId: Guid} ) => {
  const { propertyId } = payload;
  const url = createUrl(`${EnvConfig.API_URL}/api/property/${propertyId}/preferredSettings`);

  return apiPut<any, any>(url, _.omit(payload, ["propertyId"]));
};
