import React from "react";
import styled from "styled-components";
import {Input} from "antd";
import {ReactComponent as ReturnIcon} from "../../../images/spaces/ReturnIconLink.svg";
import {ReactComponent as AddLinkIcon} from "../../../images/spaces/LinkedAdd.svg";
import {ReactComponent as LinkedSearch} from "../../../images/spaces/LinkedSearch.svg";
import {getAllAttachments} from "../../actions/attachments";
import UploadFile from "../attachments/UploadFile";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector} from "../../selectors";
import {Guid} from "guid-typescript";

type Props = {
  title: string;
  parentId: Guid | null;
  parentType: string;
  toggleLinkDrawerOpen: (arg: boolean) => void;
  searchQuery: string;
  setSearchQuery: (arg: string) => void;
  selectedType?: string;
  setSelectedType?: (arg: string) => void;
  clearItemSelection?: () => void;
  toggleAddNewRecordDrawer: (arg: boolean) => void;
  toggleAddNewEventDrawer?: (arg: boolean) => void;
  setPreSelectedType?: (arg: string | null) => void;
  refreshParent?: () => void;
  hideSearch?: boolean;
  showFileUploadButton?: boolean;
}

const LinkedItemsHeader = (props: Props) => {
  const {
    title,
    parentId,
    parentType,
    toggleLinkDrawerOpen,
    searchQuery,
    setSearchQuery,
    selectedType,
    setSelectedType,
    clearItemSelection,
    toggleAddNewRecordDrawer,
    toggleAddNewEventDrawer,
    setPreSelectedType,
    refreshParent,
    hideSearch,
    showFileUploadButton
  } = props;

  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;

  const getTitle = (drawerTitle: string) => {
    if (drawerTitle === "work") return "Projects"
    if (drawerTitle === "expense") return "Expenses"
    if (drawerTitle === "inventory") return "Contents"
    if (drawerTitle === "contact") return "Contacts"
    if (drawerTitle === "attachment") return "Documents"
    if (drawerTitle === "note") return "Notes"
    if (drawerTitle === "space") return "Spaces"
    if (drawerTitle === "upkeep") return "Maintenance"
    else return drawerTitle
  }

  const addNewRecord = () => {
    if (selectedType && setPreSelectedType) {
      if (toggleAddNewEventDrawer && selectedType === "upkeep") toggleAddNewEventDrawer(true)
      else setPreSelectedType(getTitle(selectedType))
    } else toggleAddNewRecordDrawer(true)
  }

  return <LinkedHeader>
    <LinkedRecordsHeader>
    <LinkedRecordsWrapper>
      {setSelectedType && <ReturnIcon onClick={() => {
        if (selectedType) {
          setSelectedType("")
          clearItemSelection && clearItemSelection()
        } else toggleLinkDrawerOpen(false)
      }} />}
      {getTitle(title)}
    </LinkedRecordsWrapper>
    <div className={"button-wrapper"}>
      {selectedType !== "attachment" ?
        <AddLinkedRecordButton onClick={() => addNewRecord()}><AddLinkIcon/> Add new</AddLinkedRecordButton> : <UploadFile
        fileList={[]}
        refreshParent={() => {
        dispatch(getAllAttachments(propertyId));
        refreshParent && refreshParent();
      }}
        singleAttachment={false}
        parentId={parentId}
        parentType={parentType}
        propertyId={propertyId}
        uploadImmediately={false}
        isGlobalUpload={true}
        uploadOrigin={"add-linked-item"}
        />}
        {showFileUploadButton && <UploadFile
            fileList={[]}
            refreshParent={() => {
              dispatch(getAllAttachments(propertyId));
              refreshParent && refreshParent();
            }}
            singleAttachment={false}
            parentId={parentId}
            parentType={parentType}
            propertyId={propertyId}
            uploadImmediately={false}
            isGlobalUpload={true}
            uploadOrigin={"view-space-drawer"}
        />}
    </div>
    </LinkedRecordsHeader>
    {!hideSearch && <LinkedRecordsControls>
      <SearchStyled
        placeholder={"Search..."}
        value={searchQuery}
        onChange={(val) => setSearchQuery(val.target.value)}
        prefix={<LinkedSearch/>}
      />
    </LinkedRecordsControls>}
  </LinkedHeader>
}

export const LinkedHeader = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`

export const LinkedRecordsHeader = styled.div`
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #21272B;
  gap: 0.5rem;
  
  .button-wrapper {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  
  @media (max-width: 768px) {
    &.note-drawer {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.125rem;
    }
    
    .button-wrapper { gap: 0.5rem; }
  }
`

export const LinkedRecordsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
`

export const LinkedRecordsControls = styled.div`
  margin: 0.5rem 0;
  display: flex;
  column-gap: 0.5rem;
`

export const AddLinkedRecordButton = styled.div`
  cursor: pointer;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #00B74F;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  
  @media (max-width: 768px) {
    gap: 0.25rem;
  }
`

export const SearchStyled = styled(Input)`
  padding: 0 1rem;
  height: 2.75rem;
  background: #F5F6FA;
  border-radius: 32px;
  border: none;
  box-shadow: none;
  
  .ant-input {
    background: #F5F6FA;
    margin-left: 0.5rem;
  }
`

export default LinkedItemsHeader