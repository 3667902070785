import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { hotjar } from 'react-hotjar';
import ReactPixel from 'react-facebook-pixel';

import store, { history } from "./configureStore";
import "./index.css";
import App from "./App";
import envConfig from "./config";

import TagManager from 'react-gtm-module';
import {CookiesProvider} from "react-cookie";
// import {getMarketingCookies} from "./localStorage";

const tagManagerArgs = {
  gtmId: envConfig.GOOGLE_TAG
}

// if (getMarketingCookies() === "true") {
TagManager.initialize(tagManagerArgs)
// }

if (envConfig.PRODUCTION_TRACKING.length > 0) {
  if (window.location.hostname.includes("mcl-r")) {
    hotjar.initialize(3287936, 6);
  } else if (window.location.hostname.includes("mcl-a")) {
    hotjar.initialize(3287937, 6);
  } else {
    hotjar.initialize(2823113, 6);
  }
  ReactPixel.init('653525659124184');
}

const rootEl = document.getElementById("root");

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <IntlProvider locale="en">
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </IntlProvider>
    </Provider>
  </CookiesProvider>,
  rootEl
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}