import React, {useEffect, useMemo, useState} from "react";
import {
  ActionButtonGreen, CheckboxStyled,
  CircleIconGreen,
  DrawerFooter,
  DrawerHeader,
  DrawerStyled,
  LinkedItemsContainer, LoadingWrapper,
  ViewDrawerTabs
} from "../../screens/components";
import {ReactComponent as ReturnIcon} from "../../../images/spaces/ReturnIcon.svg";
import {ReactComponent as EditIcon} from "../../../images/spaces/EditIcon.svg";
import moment from "moment";
import {ReactComponent as Checkmark} from "../../../images/planner/Checkmark.svg";
import {ReactComponent as WorkIcon} from "../../../images/planner/WorkIcon.svg";
import {ReactComponent as CalendarDismiss} from "../../../images/dashboard-calendar/CalendarDismiss.svg";
import {ReactComponent as CalendarReschedule} from "../../../images/dashboard-calendar/CalendarReschedule.svg";
import {ReactComponent as CalendarForward} from "../../../images/dashboard-calendar/CalendarForward.svg";
import {ReactComponent as FindTradespersonIcon} from "../../../images/dashboard-calendar/FindTradespersonIcon.svg";
import {ReactComponent as AddToCalendarIcon} from "../../../images/planner/AddToCalendarIcon.svg";
import {ActionButton, ActionButtonsRow, TaskDescription} from "../planner/ViewTaskDrawer";
import LinkedItemsList from "../spaces/LinkedItemsList";
import {paginationButtons, PaginationStyled} from "../../screens/components";
import { getWorkById } from "../../../api-wrapper/works/getWorkById";
import {useDispatch, useSelector} from "react-redux";
import {
  getImprovementsSelector, getPropertiesSelector,
  getPropertyIdSelector, getPropertyPreferencesSelector,
  getUserProfileSelector,
  getWorksSelector
} from "../../selectors";
import LinkedItemsHeader from "../spaces/LinkedItemsHeader";
import {ReactComponent as LinkIcon} from "../../../images/spaces/LinkNewIcon.svg";
import {Guid} from "guid-typescript";
import AddNewRecordDrawer from "../spaces/AddNewRecordDrawer";
import {getAllWorks} from "../../actions/works";
import styled from "styled-components";
import {editWork} from "../../../api-wrapper/works/editWork";
import {Dropdown, Modal, Spin} from "antd";
import {removeWork} from "../../../api-wrapper/works/removeWork";
import {useHistory} from "react-router-dom";
import RescheduleWorkModal from "./RescheduleWorkModal";
import CompleteWorkModal from "./CompleteWorkModal";
import {Overlay} from "../../screens/Home";
import {ReactComponent as HelpIcon} from "../../../images/Help.svg";
import {CALENDAR_FREQUENCY} from "../upcomingTasksCalendar/types";
import ICalendarLink from "react-icalendar-link";
import TagContainer from "../tags/TagContainer";
import {ReactComponent as ReturnArrow} from "../../../images/spaces/ReturnArrow.svg";
import {ReadMoreText} from "../maintenances/MaintenanceListItemNew";
import {ReadMoreReadLess} from "../helper";
import AddLinkedItem from "../spaces/AddLinkedItem";
import {LoadingOutlined} from "@ant-design/icons";
import {getCurrencySign} from "../../screens/helpers";

type Props = {
  workId: any;
  isOpen: boolean;
  toggleDrawer: (arg: boolean) => void;
  refreshParent?: () => void;
  setWorkId: (arg: any) => void;
  preSelectedTab?: string;

  toggleWorkDrawer: (arg: boolean) => void;
  setEditWorkData: (arg: any) => void;
  toggleExpenseDrawer: (arg: boolean) => void;
  setEditExpenseData: (arg: any) => void;
  toggleInventoryDrawer: (arg: boolean) => void;
  setEditInventoryData: (arg: any) => void;
  toggleContactDrawer: (arg: boolean) => void;
  setEditContactData: (arg: any) => void;
  toggleNoteDrawer: (arg: boolean) => void;
  setEditNoteData: (arg: any) => void;
  toggleSpaceDrawer: (arg: boolean) => void;
  setEditSpaceId: (arg: any) => void;
  toggleTaskDrawer: (arg: boolean) => void;
  setEditTaskData: (arg: any) => void;

  setLinkedWorkId: (arg: Guid) => void;
  setLinkedRecordType?: (arg: string) => void;
  toggleLinkDrawerOpen: (arg: boolean) => void;
  setPresetLinkType: (arg: string) => void;
  setWorkIdToForward: (arg: any) => void;
  toggleForwardDrawer: (arg: boolean) => void;
  toggleTradeDrawer: (arg: boolean) => void;
  setAttachmentId: (arg: any) => void;
  toggleViewFileDrawer: (arg: boolean) => void;
  refreshSearch?: () => void;
}

const ViewWorkDrawer = (props: Props) => {
  const {workId, setWorkId, isOpen, toggleDrawer, toggleWorkDrawer, setEditWorkData, toggleExpenseDrawer, preSelectedTab,
    setEditExpenseData, toggleNoteDrawer, setEditNoteData, toggleContactDrawer, setEditContactData, toggleInventoryDrawer,
    setEditInventoryData, setLinkedWorkId, toggleLinkDrawerOpen, setWorkIdToForward, toggleForwardDrawer, toggleTradeDrawer,
    toggleSpaceDrawer, setEditSpaceId, refreshParent, setPresetLinkType, setLinkedRecordType, toggleTaskDrawer, setEditTaskData,
    setAttachmentId, toggleViewFileDrawer, refreshSearch} = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const propertyId = useSelector(getPropertyIdSelector).value;
  const works = useSelector(getWorksSelector);
  const improvements = useSelector(getImprovementsSelector);
  const { content: user } = useSelector(getUserProfileSelector);
  const properties = useSelector(getPropertiesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [work, setWork] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [workLinks, setWorkLinks] = useState<any>([]);
  const [isNewRecordDrawerOpen, toggleNewRecordDrawer] = useState(false);
  const [isRescheduleModalOpen, toggleRescheduleModal] = useState(false);
  const [isCompleteModalOpen, toggleCompleteModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Details");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading, toggleLoading] = useState(false);
  const [totalExpensesCost, setTotalExpensesCost] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  })

  useEffect(() => {
    preSelectedTab ? setSelectedTab(preSelectedTab) : setSelectedTab("Details")
  }, [isOpen, preSelectedTab])

  const frequencyConvertor = (frequency: string) => {
    const monthly = ["Monthly", "Quarterly", "OnceIn4Months", "TwiceAYear"]
    const yearly = ["Yearly", "OnceIn3Years"]
    if (frequency === "Weekly") return "week"
    if (monthly.includes(frequency)) return "month"
    if (yearly.includes(frequency)) return "year"
    else return null
  }

  const intervalConvertor = (frequency: string) => {
    const once = ["Weekly", "Monthly", "Yearly"]
    const thrice = ["Quarterly", "OnceIn3Years"]
    if (once.includes(frequency)) return 1
    if (frequency === "TwiceAYear") return 2
    if (thrice.includes(frequency)) return 3
    if (frequency === "OnceIn4Months") return 4
    else return null
  }

  // ! warning - don't indent the raw string
  // @ts-ignore
  const calendarRawContent = useMemo(() => `RRULE:FREQ=${CALENDAR_FREQUENCY[frequencyConvertor(work?.recurring)]};INTERVAL=${intervalConvertor(work?.recurring)}
ATTENDEE;ROLE=REQ-PARTICIPANT:MAILTO:${user.email}`, [work, user]);

  const calendarEvent = useMemo(() => {
    const property = properties.find((p) => p.propertyId === work?.propertyId);
    return {
      title: `${work?.name} - ${property?.address}@Livlet`,
      startTime: moment(work?.dueDate).startOf('day').toString(),
      endTime: moment(work?.dueDate).endOf('day').toString(),
      description: work?.message,
      location: `${property?.address}@ https://uk.livlet.com/`,
    }
  }, [work, properties]);

  useEffect(() => {
    if (workId) {
      setLinkedWorkId(workId);
      setWork(works.content.filter(item => item.workId === workId)[0]);
      refreshWork();
    }
  }, [works, workId])
  
  const handleWorkAction = (key: string) => {
    if (key === "snooze") {
      editWork({
        ...work,
        dueDate: moment(work.dueDate).add(7, "days").format("YYYY-MM-DD")
      }).then(() => {
          dispatch(getAllWorks(propertyId));
          refreshWork();
        })
    }
    if (key === "reschedule") {
      toggleRescheduleModal(true);
    }
    if (key === "forward") {
      setWorkIdToForward(work.workId);
      toggleForwardDrawer(true);
    }
    if (key === "dismiss") {
      confirmDeleteWork();
    }
    if (key === "toggleComplete") {
      work.isComplete
        ? editWork({
          ...work,
          isComplete: false,
          completedDate: null
        }).then(() => {
          dispatch(getAllWorks(propertyId));
          refreshParent && refreshParent();
        })
        : toggleCompleteModal(true)
    }
    if (key === "findTradesperson") {
      toggleTradeDrawer(true);
    }
  }

  const confirmDeleteWork = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this project?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        removeWork(propertyId, work.workId).then(() => {
          toggleDrawer(false);
          dispatch(getAllWorks(propertyId));
          refreshParent && refreshParent();
        });
      }
    });
  }

  const closeDrawer = () => {
    toggleDrawer(false);
    setWorkId(null);
    setWork(null);
    setWorkLinks([]);
    dispatch(getAllWorks(propertyId));
  }

  const handleEditWork = () => {
    setEditWorkData({data: works.content.filter(item => item.workId === workId)[0]});
    toggleWorkDrawer(true);
    toggleDrawer(false);
  }

  const setWorkToLink = () => {
    setLinkedWorkId(work.workId);
    setLinkedRecordType && setLinkedRecordType("work");
  }

  const refreshWork = () => {
    toggleLoading(true);
    getWorkById(propertyId, workId).then((res: any) => {
      setWorkLinks([...res.spaces, ...res.works, ...res.expenses, ...res.inventories, ...res.contacts, ...res.attachments,
        ...res.workNotes, ...res.recurringExpenses, ...res.reminders, ...res.tasks])
      // @ts-ignore
      setTotalExpensesCost(res.expenses.reduce((sum, {totalCost}) => sum + totalCost, 0))
      toggleLoading(false)
    })
    refreshParent && refreshParent();
  }

  useEffect(() => {
    work && setWorkToLink()
  }, [work])

  const relatedImprovement = improvements.content.improvements
    .filter(improvement => improvement.id === work?.relatedImprovementId)[0]

  const filteredItems = () => workLinks.filter((item: any) => searchQuery ?
    (item.name?.toLowerCase().includes(searchQuery.toLowerCase()) || item.title?.toLowerCase().includes(searchQuery.toLowerCase())): true)

  const getItems = () => filteredItems()
    .filter((currentValue: any, index: any) => index >= (page - 1) * 10 && index < page * 10);

  const displayFrequency = (frequency: string) => {
    if (frequency === "OnceIn4Months") return "Once in 4 months"
    if (frequency === "TwiceAYear") return "Twice a year"
    if (frequency === "OnceIn3Years") return "Once in 3 years"
    else return frequency
  }

  const workDetails = () => (<TaskDescription className={"work-description"}>
    {windowWidth > 768 && <div className={"row-title"}>Project name</div>}
    {windowWidth > 768 && <div className={"row-description"} style={{fontWeight: 600}}>{work.name}</div>}
    <div className={"row-title"}>Type</div>
    <div className={"row-description"}>{work.type}</div>
    {!work.isComplete && <div className={"row-title"}>Due date</div>}
    {!work.isComplete && <div className={"row-description"}>{work.dueDate ? moment(work.dueDate).format(propertyPreferences.preferredDateFormat) : "-"}</div>}
    {work.isComplete && <div className={"row-title"}>Completion date</div>}
    {work.isComplete && <div className={"row-description"}>{moment(work.completedDate).format(propertyPreferences.preferredDateFormat)}</div>}
    <div className={"row-title"}>Frequency</div>
    <div className={"row-description"}>{work.recurring ? displayFrequency(work.recurring) : "None"}</div>
    {work.notes && <div className={"row-title"}>Notes</div>}
    {work.notes && <div className={"row-description"}>
      <ReadMoreReadLess
          charLimit={45}
          readMoreText={<ReadMoreText>{"View more >"}</ReadMoreText>}
          readLessText={<ReadMoreText>{"View less"}</ReadMoreText>}>
        {work.notes ? work.notes : ""}
      </ReadMoreReadLess>
    </div>}
    {relatedImprovement && <div className={"row-title"}>Related improvement</div>}
    {relatedImprovement && <div className={"row-description"}>
      <span style={{color: "#009966"}} onClick={() => {
        toggleDrawer(false);
        history.push(`/guidance/improvement/${relatedImprovement.category}/${relatedImprovement.id}`)
      }}>{relatedImprovement.name}</span></div>}
    <div className={"row-title"} style={{width: "100%"}}>Tags</div>
    <div style={{width: "100%"}}>
      <TagContainer
        parentTags={work.tags}
        parentId={work.workId}
        parentType={"work"}
        selectedTags={[]}
        toggleGlobalEditMode={() => {}}
        refreshParent={() => {
          dispatch(getAllWorks(propertyId));
          refreshSearch && refreshSearch();
        }}
      />
    </div>
  </TaskDescription>)

  const workActions = () => (<div>
    <ActionButtonsRow>
      <ActionButton className={"gray"} onClick={() => handleWorkAction("toggleComplete")}>
        <CheckboxStyled className={work.isComplete ? "checked small" : "small"}><Checkmark/></CheckboxStyled>
        Mark as {work.isComplete && "in"}complete?
        {work.isComplete && <Dropdown placement="bottomRight" overlay={<Overlay>
          If you mark a completed project as incomplete, the due date will be automatically set as today's date.
        </Overlay>}><HelpIcon/></Dropdown>}
      </ActionButton>
      <ActionButton onClick={() => handleWorkAction("dismiss")}><CalendarDismiss/> Delete</ActionButton>
    </ActionButtonsRow>
    <ActionButtonsRow>
      <ActionButton onClick={() => handleWorkAction("forward")}><CalendarForward/> Forward</ActionButton>
      <ActionButton onClick={() => handleWorkAction("reschedule")}><CalendarReschedule/> Reschedule</ActionButton>
    </ActionButtonsRow>
    <ActionButtonsRow>
      {/*{!work.isComplete && <ActionButton onClick={() => handleWorkAction("findTradesperson")} className={"green"}>*/}
      {/*    <FindTradespersonIcon/> Find tradesperson*/}
      {/*</ActionButton>}*/}
      <ActionButton><AddToCalendarIcon /><ICalendarLink event={calendarEvent} rawContent={calendarRawContent}>
        Add to calendar
      </ICalendarLink></ActionButton>
    </ActionButtonsRow>
  </div>)

  const linkedItemsHeader = () => <LinkedItemsHeader
    title={"Linked items"}
    toggleLinkDrawerOpen={toggleLinkDrawerOpen}
    searchQuery={searchQuery}
    setSearchQuery={setSearchQuery}
    toggleAddNewRecordDrawer={toggleNewRecordDrawer}
    parentId={work && work.workId}
    parentType={"work"}
    refreshParent={refreshWork}
  />

  const detailsAndActions = () => (
    <SpacedContainer style={{marginTop: "1rem", marginBottom: 0}}>
      {work && workDetails()}
      {work && workActions()}
    </SpacedContainer>
  )

  const linkedItems = () => (
    isLoading ? <LoadingWrapper>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </LoadingWrapper> : <LinkedItemsContainer>
      {work && workLinks.length === 0 && <AddLinkedItem
        toggleLinkDrawerOpen={toggleLinkDrawerOpen}
        toggleNewRecordDrawer={toggleNewRecordDrawer}
        setPresetLinkType={setPresetLinkType}
      />}
      {totalExpensesCost > 0 && <TotalExpensesCostWrapper>
        Expenses total:
        <div className={"price-label"}>{getCurrencySign(propertyPreferences.preferredCurrency)} {totalExpensesCost}</div>
      </TotalExpensesCostWrapper>}
      {work && workLinks.length > 0 && <LinkedItemsList
        parentId={work.workId}
        parentType={"work"}
        items={getItems()}
        searchQuery={searchQuery}
        isSelectionMode={false}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditNoteData}
        refreshParent={refreshWork}
        setEditSpaceId={setEditSpaceId}
        toggleSpaceDrawer={toggleSpaceDrawer}
        toggleTaskDrawer={toggleTaskDrawer}
        setEditTaskData={setEditTaskData}
        toggleViewWorkDrawer={toggleDrawer}
        setViewWorkId={setWorkId}
        setAttachmentId={setAttachmentId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />}
      {filteredItems().length > 10 && <PaginationStyled
        className={filteredItems().length < 10 ? "single-page-pagination" : ""}
        onChange={(page) => setPage(page)}
        simple={window.innerWidth < 1024}
        showSizeChanger={false}
        itemRender={paginationButtons}
        defaultCurrent={1}
        current={page}
        total={filteredItems().length}
        defaultPageSize={10}
      />}
    </LinkedItemsContainer>
  )

  const desktopDrawerContent = () => <>
    {detailsAndActions()}
    <SpacedContainer style={{ rowGap: "1rem" }}>
      {workLinks.length > 0 && linkedItemsHeader()}
      {linkedItems()}
    </SpacedContainer>
  </>

  const mobileDrawerContent = () => <>
    {selectedTab === "Details" ? detailsAndActions() : linkedItems()}
  </>

  return <>
    <AddNewRecordDrawer
      isOpen={isNewRecordDrawerOpen}
      toggleDrawerOpen={toggleNewRecordDrawer}
      parentId={work ? work.workId : null}
      parentType={"work"}
      refreshParent={() => dispatch(getAllWorks(propertyId))}
    />
    <RescheduleWorkModal
      work={work}
      isModalOpen={isRescheduleModalOpen}
      toggleModal={toggleRescheduleModal}
      refreshParent={refreshParent}
    />
    <CompleteWorkModal
      work={work}
      isModalOpen={isCompleteModalOpen}
      toggleModal={toggleCompleteModal}
      refreshParent={refreshParent}
    />
    <ViewWorkDrawerStyled
      push={{distance: "32px"}}
      closeIcon={false}
      visible={isOpen}
      width={windowWidth > 1024 ? "680px" : "100%"}
      height={windowWidth > 768 ? "100%" : "85%"}
      placement={windowWidth > 768 ? "right" : "bottom"}
      onClose={() => closeDrawer()}
      title={<SpacedContainer>
        <DrawerHeader className={"header-desktop"}>
          <div style={{display: "flex", alignItems: "center", columnGap: "1rem"}}>
            <ReturnIcon style={{cursor: "pointer"}} onClick={() => closeDrawer()} />
            <WorkIcon /> Work project
          </div>
          <div style={{display: "flex", alignItems: "center", columnGap: "1.5rem"}}>
            <EditIcon onClick={() => handleEditWork()} style={{cursor: "pointer"}}/>
            <CircleIconGreen style={{cursor: "pointer"}} onClick={() => {
              setWorkToLink();
              toggleLinkDrawerOpen(true);
            }}><LinkIcon /></CircleIconGreen>
          </div>
        </DrawerHeader>
        <DrawerHeader className={"task-header header-mobile"}>
          <div style={{display: "flex", alignItems: "center", columnGap: "1rem", width: "100%"}}>
            <WorkIcon /> Work project
          </div>
          <div style={{display: "flex", alignItems: "center", columnGap: "1.5rem", justifyContent: "space-between", width: "100%"}}>
            <div style={{display: "flex", alignItems: "center", gap: "1rem"}}>
              <ReturnArrow style={{cursor: "pointer", flexShrink: 0}} onClick={() => closeDrawer()} />
              {work && <span className={"reminder-title"}>{work.name}</span>}
            </div>
            <EditIcon onClick={() => handleEditWork()} style={{cursor: "pointer"}}/>
          </div>
        </DrawerHeader>
        <ViewDrawerTabs style={{marginBottom: "1rem"}}>
            <span className={selectedTab === "Details" ? "selected" : "default"} onClick={() => setSelectedTab("Details")}>Details</span>
            <span className={selectedTab === "Links" ? "selected" : "default"} onClick={() => {
              setWorkToLink();
              setSelectedTab("Links")
            }}>Links</span>
        </ViewDrawerTabs>
        {workLinks.length > 0 && selectedTab === "Links" && linkedItemsHeader()}
      </SpacedContainer>}
      footer={<DrawerFooter>
        <ActionButtonGreen onClick={() => {
          setWorkToLink();
          toggleLinkDrawerOpen(true);
        }}><LinkIcon/> Add links</ActionButtonGreen>
      </DrawerFooter>}
    >
      {windowWidth > 768 ? desktopDrawerContent() : mobileDrawerContent()}
  </ViewWorkDrawerStyled>
  </>
}

const ViewWorkDrawerStyled = styled(DrawerStyled)`
  .ant-drawer-body {
    margin: 0.5rem 0 1.5rem 0!important;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .ant-drawer-body {
      margin: 0 0 1.5rem 0!important;
    }
  }
`

const SpacedContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (max-width: 768px) { gap: 1rem; }
`

const TotalExpensesCostWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #767E88;
  font-weight: 600;
  
  .price-label {
    color: #21272B;
    background: #EBEDF0;
    padding: 0.125rem 0.5rem;
  }
  
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
`

export default ViewWorkDrawer;