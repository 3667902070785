import React from "react";
import {HandholdingCircle} from "../../screens/Home";
import {ReactComponent as HandholdingArrowRight} from "../../../images/HandholdingArrowRight.svg";
import {useHistory} from "react-router-dom";
import {Alert} from 'antd';
import styled from "styled-components";

import {ReactComponent as EmptyContentsAdd} from "../../../images/empty-states/EmptyContentsAdd.svg";
import {ReactComponent as EmptyContentsIcons} from "../../../images/empty-states/EmptyContentsIcons.svg";
import {ReactComponent as EmptyContentsWizard} from "../../../images/empty-states/EmptyContentsWizard.svg";
import {ReactComponent as ChevronLeft} from "../../../images/ChevronLeft.svg";
import {ReactComponent as ChevronRight} from "../../../images/ChevronRight.svg";
import {ReactComponent as ChevronSmall} from "../../../images/ChevronSmall.svg";

import EmptyContents1 from "../../../images/empty-states/EmptyContents1.png";
import EmptyContents2 from "../../../images/empty-states/EmptyContents2.png";
import EmptyContents3 from "../../../images/empty-states/EmptyContents3.png";
import EmptyContentsDemo from "../../../images/empty-states/EmptyContentsDemo.png";
import EmptyContentsMobile1 from "../../../images/empty-states/EmptyContentsMobile1.png";
import EmptyContentsMobile2 from "../../../images/empty-states/EmptyContentsMobile2.png";
import EmptyContentsMobile3 from "../../../images/empty-states/EmptyContentsMobile3.png";
import {ButtonSmallBlue, ButtonSmallWhite, EmptyStateWrapper, IntroductionButton} from "../../screens/components";
import {useDispatch, useSelector} from "react-redux";
import {getUserProfileSelector} from "../../selectors";
import {setUserIntroductions} from "../../../api-wrapper/user/setUserIntroductions";
import {getCurrentUserProfile} from "../../actions/getUserProfile";

type Props = {
  handholdingStatus: string;
  introductionStatus: string | null;
}

const InventoryIntroduction = (props: Props) => {
  const {handholdingStatus, introductionStatus} = props;

  let history = useHistory();
  const dispatch = useDispatch();
  const userProfile = useSelector(getUserProfileSelector);

  const handleStateChange = (status: string) => {
    setUserIntroductions({
      contents: status,
      spaces: userProfile.content.spacesStatus,
      documents: userProfile.content.documentsStatus
    }).then(() => dispatch(getCurrentUserProfile()))
  }

  const navigationDots = () => <Dots>
    <span className={`dot ${(introductionStatus === "step1" || introductionStatus === null) && "active"}`} onClick={() => handleStateChange("step1")} />
    <span className={`dot ${introductionStatus === "step2" && "active"}`} onClick={() => handleStateChange("step2")} />
    <span className={`dot ${introductionStatus === "step3" && "active"}`} onClick={() => handleStateChange("step3")} />
  </Dots>

  const slide1 = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>What is the <span className={"highlight"}>Contents</span> feature for?</span>
      <span className={"content-section"}>In Livlet, Contents are your <b>Appliances</b>, <b>Electronics</b>, <b>Furniture</b>,
        <b>Tools & Machinery</b>, <b>Fittings</b>, <b>Fixtures</b> and <b>Systems</b> inventory items.
        <br/><br/>
        Use it to help you monitor your home contents, keep track of expiring warranties & typical lifetimes and access
        all associated information you want to keep with them (such as costs, warranties and manuals).
        <br/><br/>
        It serves as a <b>digital</b> location for all the <b>physical</b> things inside your home.</span>
      {navigationDots()}
      <FlexWrapper className={"buttons"}>
        <ButtonSmallBlue onClick={() => handleStateChange("step2")}>
          Next <ChevronRight />
        </ButtonSmallBlue>
      </FlexWrapper>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptyContents1} /></ContentWrapper>
  </AlertContent>

  const slide1Mobile = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>What is the <span className={"highlight"}>Contents</span> feature for?</span>
      <span className={"content-section"}>Contents is a <b>digital</b> location for all the <b>physical</b> things
        inside your home, such as <b>Appliances</b>, <b>Electronics</b>, <b>Fittings</b> etc.</span>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptyContentsMobile1} /></ContentWrapper>
    {navigationDots()}
    <FlexWrapper className={"buttons"}>
      <ButtonSmallBlue onClick={() => handleStateChange("step2")}>
        Next <ChevronRight />
      </ButtonSmallBlue>
    </FlexWrapper>
  </AlertContent>

  const slide2 = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>How to use <span className={"highlight"}>Contents</span>...</span>
      <span className={"content-section"}>You can use the <b>Contents</b> feature to record your product and purchase details,
        model and serial number, length of warranty and insurance cover of your 'big ticket' items.
        <br /><br />
        We'll recommend maintenance items and advise <b>warranty</b> and <b>life alerts</b> relevant to your inventory.</span>
      <FlexWrapper className={"vertical"}>
        <FlexWrapper>
          <EmptyContentsAdd />
          <span className={"content-section"}>Use the <b>Add content</b> button to add contents manually.</span>
        </FlexWrapper>
        <FlexWrapper>
          <EmptyContentsWizard />
          <span className={"content-section"}>The <b>Content wizard</b> button allows you to add many items at once.</span>
        </FlexWrapper>
      </FlexWrapper>
      {navigationDots()}
      <FlexWrapper className={"buttons"}>
        <ButtonSmallWhite onClick={() => handleStateChange("step1")}>
          <ChevronLeft /> Back
        </ButtonSmallWhite>
        <ButtonSmallBlue onClick={() => handleStateChange("step3")}>
          Next <ChevronRight />
        </ButtonSmallBlue>
      </FlexWrapper>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptyContents2} /></ContentWrapper>
  </AlertContent>

  const slide2Mobile = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>How to use <span className={"highlight"}>Contents</span>...</span>
      <FlexWrapper className={"vertical"}>
        <FlexWrapper>
          <EmptyContentsWizard />
          <span className={"content-section"}>The <b>Content wizard</b> button allows you to add many items at once.</span>
        </FlexWrapper>
        <FlexWrapper>
          <EmptyContentsAdd />
          <span className={"content-section"}>Use the <b>Add content</b> button to add contents manually.</span>
        </FlexWrapper>
      </FlexWrapper>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptyContentsMobile2} /></ContentWrapper>
    {navigationDots()}
    <FlexWrapper className={"buttons"}>
      <ButtonSmallWhite onClick={() => handleStateChange("step1")}>
        <ChevronLeft /> Back
      </ButtonSmallWhite>
      <ButtonSmallBlue onClick={() => handleStateChange("step3")}>
        Next <ChevronRight />
      </ButtonSmallBlue>
    </FlexWrapper>
  </AlertContent>

  const slide3 = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>Making the most of <span className={"highlight"}>Contents</span>...</span>
      <span className={"content-section"}>Once you've added your contents you can easily find them again using the search tool
        as well as create alerts to maintenance schedules, warranty expiries, share details with tradespeople, access
        troubleshooting guides and more.</span>
      <img alt={""} src={EmptyContentsDemo} />
      {navigationDots()}
      <FlexWrapper className={"buttons"}>
        <ButtonSmallWhite onClick={() => handleStateChange("step2")}>
          <ChevronLeft /> Back
        </ButtonSmallWhite>
        <ButtonSmallBlue onClick={() => handleStateChange("completed")}>
          Get started!
        </ButtonSmallBlue>
      </FlexWrapper>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptyContents3} /></ContentWrapper>
  </AlertContent>

  const slide3Mobile = () => <AlertContent>
    <ContentWrapper>
      <span className={"header-section"}>Making the most of <span className={"highlight"}>Contents</span>...</span>
      <span className={"content-section"}>Create alerts, set up maintenance tasks link warranty expiries,
        share details with tradespeople, access troubleshooting guides and more.</span>
    </ContentWrapper>
    <ContentWrapper><img alt={""} src={EmptyContentsMobile3} /></ContentWrapper>
    {navigationDots()}
    <FlexWrapper className={"buttons"}>
      <ButtonSmallWhite onClick={() => handleStateChange("step2")}>
        <ChevronLeft /> Back
      </ButtonSmallWhite>
      <ButtonSmallBlue onClick={() => handleStateChange("completed")}>
        Get started!
      </ButtonSmallBlue>
    </FlexWrapper>
  </AlertContent>

  const emptyState = () => <EmptyStateWrapper>
    <EmptyContentsIcons />
    <span>Keep track of and manage your home's contents</span>
    <IntroductionButton onClick={() => history.push("/questions?openPage=contents&origin=contents")} size={"large"}>
      Let's start by selecting a few items
      <ChevronSmall />
      {handholdingStatus === "contents" && <HandholdingCircle style={{marginLeft: "0.5rem"}}>
          <HandholdingArrowRight/>
      </HandholdingCircle>}
    </IntroductionButton>
  </EmptyStateWrapper>

  const introContentDesktop = () => {
    if (introductionStatus == "completed") return emptyState()
    if (introductionStatus == "step3") return slide3()
    if (introductionStatus == "step2") return slide2()
    if (introductionStatus === null || introductionStatus == "step1") return slide1()
    return ""
  }

  const introContentMobile = () => {
    if (introductionStatus == "completed") return emptyState()
    if (introductionStatus == "step3") return slide3Mobile()
    if (introductionStatus == "step2") return slide2Mobile()
    if (introductionStatus === null || introductionStatus == "step1") return slide1Mobile()
    return ""
  }

  return <AlertStyled
    message={window.innerWidth > 768 ? introContentDesktop() : introContentMobile()}
    closable={introductionStatus !== "completed"}
    onClose={() => handleStateChange("skipped")}
  />
}

export const AlertStyled = styled(Alert)`
  background: #FFFFFF;
  border-radius: 16px;
  border: none;
  padding: 2rem;
  
  .ant-alert-close-icon {
    position: absolute;
    font-size: 1rem;
    color: #3E5D58;
    top: 0.75rem;
    right: 0.75rem;
  }
  
  @media (max-width: 1024px) {
    padding: 1.5rem;
  }
`

export const AlertContent = styled.div`
  width: 100%;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`

export const ContentWrapper = styled.div`
  img { max-width: 100%; }
  
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  
  .highlight { font-weight: 600; color: #009966; }
  .highlight-blue, .link { font-weight: 600; color: #2A80E1; }
  .link { font-weight: 600; cursor: pointer; }
  
  .header-section {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #3E5D58;
  }
  
  .content-section {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #3E5D58;
  }
  
  @media (max-width: 1024px) {
    gap: 1rem;
  }
`

export const Dots = styled.div`
  display: flex;
  gap: 0.75rem;
  
  .dot {
    width: 0.5rem;
    height: 0.5rem;
    cursor: pointer;
    background: #EAEAEA;
    border-radius: 100%;
  }
  
  .active { background: #009966; }
`

export const FlexWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  
  svg { flex-shrink: 0; }
  &.buttons { gap: 1.5rem; }
  &.vertical { flex-direction: column; }
`

export default InventoryIntroduction