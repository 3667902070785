import React, {useEffect, useState} from "react";
import {
  AddButtonStyled,
  HeaderControlsWrapper,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  ViewDrawerTabs
} from "./components";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getUpcomingTasksSelector} from "../selectors";
import UpcomingTasksWrapper from "../components/upcomingTasks/UpcomingTasksWrapper";
import moment from "moment";
import styled from "styled-components";

import {ReactComponent as GridViewIcon} from "../../images/planner/GridViewIcon.svg";
import {ReactComponent as ListViewIcon} from "../../images/planner/ListViewIcon.svg";
import {getAllWorks} from "../actions/works";
import {ButtonGreen} from "./Home";
import {PlusOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {getAllMaintenances} from "../actions/maintenances";

const Planner = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const upcomingTasks = useSelector(getUpcomingTasksSelector);

  const [startDate, setStartDate] = useState(moment().startOf("month"));
  const [columns, setColumns] = useState(8);
  const [isPlannerViewMobile, togglePlannerViewMobile] = useState(true);
  const [eventDrawerRequest, toggleEventDrawerRequest] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  })

  useEffect(() => {
    columns === 8 ? history.push("/planner?plannerView=double") : history.push("/planner?plannerView=single")
  }, [columns])

  useEffect(() => {
    dispatch(getAllWorks(propertyId));
    dispatch(getAllMaintenances(propertyId));
  }, [propertyId])

  const getWrapperMode = () => {
    if (windowWidth > 768) {
      return columns === 8 ? "planner-double" : "planner-single"
    } else {
      return isPlannerViewMobile ? "planner-mobile" : "calendar-mobile"
    }
  }

  return <PageWrapper>
    <PageHeader>
      <HeaderControlsWrapper>
        <div>
          <PageTitle>Schedule & calendar</PageTitle>
          <PageSubtitle>Ensure pending projects and tasks get completed on time.</PageSubtitle>
        </div>
      </HeaderControlsWrapper>
      <HeaderControlsWrapper className={"desktop"}>
        {upcomingTasks.content.tasksExists && <ViewModeButton>
          Switch view mode:
        </ViewModeButton>}
        {upcomingTasks.content.tasksExists && <ViewModeWrapper>
          <ViewButton className={columns === 8 ? "selected" : ""} onClick={() => setColumns(8)}>
            <ListViewIcon/>
          </ViewButton>
          <ViewButton className={columns === 12 ? "selected" : ""} onClick={() => setColumns(12)}>
            <GridViewIcon/>
          </ViewButton>
        </ViewModeWrapper>}
        <ButtonGreen onClick={() => toggleEventDrawerRequest(true)}>
          <PlusOutlined /> Add an event
        </ButtonGreen>
      </HeaderControlsWrapper>
      <HeaderControlsWrapper className={"mobile"}>
        <AddButtonStyled
          icon={<PlusOutlined />}
          onClick={() => toggleEventDrawerRequest(true)}
        />
      </HeaderControlsWrapper>
    </PageHeader>
    {upcomingTasks.content.tasksExists && <PageHeader>
      <ViewDrawerTabs className={"planner"}>
        <span className={isPlannerViewMobile ? "selected" : "default"}
              onClick={() => togglePlannerViewMobile(true)}>To-do</span>
        <span className={!isPlannerViewMobile ? "selected" : "default"}
              onClick={() => togglePlannerViewMobile(false)}>Calendar</span>
      </ViewDrawerTabs>
    </PageHeader>}

    <ContentWrapper className={upcomingTasks.content.tasksExists ? (columns === 8 ? "condensed-view" : "extended-view") : "extended-view"}>
      <UpcomingTasksWrapper
        wrapperMode={getWrapperMode()}
        startDate={startDate}
        setStartDate={setStartDate}
        totalColumns={columns}
        columnsInRow={columns / 2}
        eventDrawerRequest={eventDrawerRequest}
        toggleEventDrawerRequest={toggleEventDrawerRequest}
      />
    </ContentWrapper>
  </PageWrapper>
}

const ContentWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 0.875rem;
  
  &.condensed-view { grid-template-columns: calc(60% - 0.875rem) 40%; }
  &.extended-view { grid-template-columns: 100%; }
  
  @media (max-width: 768px) {
    &.condensed-view, &.extended-view { grid-template-columns: 100%; } 
  }
`

const ViewModeButton = styled.div`
  border: 1px solid #DAE0E6;
  border-radius: 8px;
  padding: 0.75rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #009966;
`

const ViewModeWrapper = styled.div`
  display: flex;
  gap: 0.5rem; 
  padding-right: 1.5rem; 
  border-right: 1px solid #DAE0E6;
`

const ViewButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border: 1px solid #DAE0E6;
  border-radius: 8px;
  transition: all .5s ease;
  background: #EFF1F5;
  svg path { fill: #45655E; }
  
  &:hover, &.selected { background: #009966; }
  &:hover svg path, &.selected svg path { fill: white; stroke: white; }
`

export default Planner
