import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {TForwardReminderRequest} from "./forwardReminder";

export const forwardReminderPreview = (payload: TForwardReminderRequest) => {
  const url = createUrl(
    `${EnvConfig.API_URL}/api/email/forwardReminderPreview`
  );

  return apiPost<any, TForwardReminderRequest>(
    url, payload
  );
};
