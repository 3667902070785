import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {
  AddRecordButton,
  ButtonOutline,
  EmptyRecordsDescription,
  EmptyRecordsHeader,
  EmptyRecordsList,
  SectionLabel
} from "../../screens/components";
import {PlusOutlined} from "@ant-design/icons";
import {
  ListItemStyled,
  PropertyListDesktop,
  PropertyPaymentDetails,
  SubTag,
} from "../properties/PropertyDetailsComp";
import {List, Modal, Skeleton} from "antd";
import {ReactComponent as DeleteImage} from "../../../images/Delete.svg";
import moment from "moment";
import {removeDocument} from "../../../api-wrapper/documents/removeDocument";
import {getAllSurveys} from "../../actions/surveys";
import {getAllFloorPlans} from "../../actions/floorPlans";

type Props = {
  documents: any;
  toggleDocumentDrawer: (arg: boolean) => void;
  setDocumentData: (arg: any) => void;
  documentType: string;
  setDocumentType: (arg: string) => void;
}

const DocumentListComp = (props: Props) => {
  const {documents, toggleDocumentDrawer, setDocumentData, documentType, setDocumentType} = props;
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const text: any = {
    floorPlan: {
      pageTitle: "Floor plans",
      emptyPageTitle: "Add Floor plans & property drawings.",
      emptyPageDescription: "Track all your property drawings.",
      button: "Add Drawing"
    },
    survey: {
      pageTitle: "Surveys",
      emptyPageTitle: "Add Surveys & important documents.",
      emptyPageDescription: "Track surveys and key property documents.",
      button: "Add Document"
    }
  }

  const addNewDocument = () => {
    setDocumentType(documentType);
    toggleDocumentDrawer(true);
  }

  function confirmDelete(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        removeDocument(propertyId, item.documentId).then(() => {
          documentType === "survey" ? dispatch(getAllSurveys(propertyId)) : dispatch(getAllFloorPlans(propertyId))
        })
      }
    });
  }

  return (
    <>
      <SectionLabel>
        {text[documentType].pageTitle}
        <ButtonOutline onClick={() => addNewDocument()}>
          {text[documentType].button}
        </ButtonOutline>
      </SectionLabel>
      {documents.content.length === 0 && <EmptyRecordsList>
          <EmptyRecordsHeader>{text[documentType].emptyPageTitle}</EmptyRecordsHeader>
          <EmptyRecordsDescription>
            {text[documentType].emptyPageDescription}
          </EmptyRecordsDescription>
          <AddRecordButton
              onClick={() => addNewDocument()}
              icon={<PlusOutlined />}
              size={"large"}
          >
            {text[documentType].button}
          </AddRecordButton>
      </EmptyRecordsList>
      }

      {documents.content.length > 0 && (
        <>
          <PropertyListDesktop>
            <List
              style={{ width: "100%" }}
              className="rounded-top-bottom-list"
              itemLayout="horizontal"
              dataSource={documents.content}
              renderItem={(item: any) => (
                <ListItemStyled
                  onClick={() => {
                    setDocumentData({data: item});
                    toggleDocumentDrawer(true);
                    setDocumentType(documentType);
                  }}
                  actions={[
                    <DeleteImage style={{ cursor: "pointer" }} onClick={(e) => {
                      e.stopPropagation();
                      confirmDelete(item)
                    }}/>
                  ]}
                >
                  <Skeleton loading={false} title={false} active>
                    <List.Item.Meta
                      title={<>
                        {item.name}{" "}
                        <SubTag>
                          ({item.documentType ? item.documentType : "Type not specified"})
                        </SubTag>
                      </>}
                      description={<>
                        {item.description ? item.description : "No description entered"}
                        <div className={"display-mobile"}>
                          Document date: {moment(item.documentDate).format(propertyPreferences.preferredDateFormat)}
                        </div>
                      </>}
                    />
                    <PropertyPaymentDetails>
                      <SubTag className={"display-desktop"}>
                        {item.documentDate && <>Document date: {moment(item.documentDate).format(propertyPreferences.preferredDateFormat)}</>}
                      </SubTag>
                    </PropertyPaymentDetails>
                  </Skeleton>
                </ListItemStyled>
              )}
            />
          </PropertyListDesktop>
        </>
      )}
    </>
  )
}

export default DocumentListComp;