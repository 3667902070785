import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

import { TRootState } from "../reducers";
import {
  getExpenses,
  TGetExpensesResponse,
} from "../../api-wrapper/expenses/getExpenses";
import {Guid} from "guid-typescript";
import {getExpenseTypes, TGetExpenseTypesResponse} from "../../api-wrapper/expenses/getExpenseTypes";

export const getExpensesInitAction = createAction(
  "Expenses/GET_Expenses_INIT"
)();
export const getExpensesSuccessAction = createAction(
  "Expenses/GET_Expenses_SUCCESS"
)<TGetExpensesResponse>();
export const getExpensesFailureAction = createAction(
  "Expenses/GET_Expenses_FAILURE"
)();

export const getExpenseTypesInitAction = createAction(
  "Expenses/GET_ExpenseTypes_INIT"
)();
export const getExpenseTypesSuccessAction = createAction(
  "Expenses/GET_ExpenseTypes_SUCCESS"
)<TGetExpenseTypesResponse>();
export const getExpenseTypesFailureAction = createAction(
  "Expenses/GET_ExpenseTypes_FAILURE"
)();

export type TGetExpensesInitAction = ActionType<typeof getExpensesInitAction>;
export type TGetExpensesSuccessAction = ActionType<
  typeof getExpensesSuccessAction
>;
export type TGetExpensesFailureAction = ActionType<
  typeof getExpensesFailureAction
>;

export type TGetExpenseTypesInitAction = ActionType<typeof getExpenseTypesInitAction>;
export type TGetExpenseTypesSuccessAction = ActionType<
  typeof getExpenseTypesSuccessAction
  >;
export type TGetExpenseTypesFailureAction = ActionType<
  typeof getExpenseTypesFailureAction
  >;

export type TGetExpensesActions =
  | TGetExpensesInitAction
  | TGetExpensesSuccessAction
  | TGetExpensesFailureAction;

export type TGetExpensesThunkActionType = (propertyId: Guid) =>
  ThunkAction<void, TRootState, null, TGetExpensesActions>;

export type TGetExpenseTypesActions =
  | TGetExpenseTypesInitAction
  | TGetExpenseTypesSuccessAction
  | TGetExpenseTypesFailureAction;

export type TGetExpenseTypesThunkActionType = (
  propertyId: Guid
) => ThunkAction<void, TRootState, null, TGetExpenseTypesActions>;

export const getAllExpenses: TGetExpensesThunkActionType = (propertyId: Guid) =>
  (dispatch: (action: Action | Promise<Action>) => any) => {
  dispatch(getExpensesInitAction());
  return getExpenses(propertyId)
    .then((payload) => {
      return dispatch(getExpensesSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getExpensesFailureAction()));
};

export const getAllExpenseTypes: TGetExpenseTypesThunkActionType = (
  propertyId: Guid
) => (dispatch: (action: Action | Promise<Action>) => any) => {
  dispatch(getExpenseTypesInitAction());
  return getExpenseTypes(propertyId)
    .then((payload) => {
      return dispatch(getExpenseTypesSuccessAction(payload?? []));
    })
    .catch(() => dispatch(getExpenseTypesFailureAction()));
};
