import { apiPut, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";
import {TTag} from "../tags/addNewTag";

type TEditExpenseRequest = {
  name: string;
  propertyId: Guid;
  expenseId: Guid;
  type: Guid | string;
  payee?: Guid | null;
  paidBy?: number | null;
  totalCost: number;
  paymentDate?: string | null;
  isTaxDeductible: boolean;
  notes: string;
  tags: Array<TTag>;
  isConfirmed: boolean;
  userPaidBy: number | null;
  contactPaidBy: Guid | null;
};

export const editExpense = (payload: TEditExpenseRequest) => {
  const { propertyId, expenseId } = payload;
  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/expense/${expenseId}`
  );

  return apiPut<
    {
      name: string;
      propertyId: Guid;
      expenseId: Guid;
      type: Guid | string;
      payee: Guid;
      totalCost: number;
      dateOfExpense: string;
      isTaxDeductible: boolean;
      parentId: Guid;
      parentType: string;
      notes: string;
      userPaidBy: number | null;
      contactPaidBy: Guid | null;
    },
    {}
  >(url, payload);
};
