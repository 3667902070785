import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getContactsSelector, getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {Guid} from "guid-typescript";
import {
  ButtonsWrapper,
  CloseButton,
  SaveButton,
  SaveButtonWrapper,
} from "../works/WorkDrawer";
import {Checkbox, DatePicker, Drawer, Form, Input, Modal, notification, Select} from "antd";
import {ReactComponent as MyTenantIcon} from "../../../images/MyProperty.svg";
import {ReactComponent as CrossIcon} from "../../../images/Cross.svg";
import {InputsWrapper, InputWrapper, Label, LabelWrapper} from "../../screens/RegistrationPage";
import {TenantIconWrapper, SaveIconStyled} from "../certificates/CertificateDrawer";
import {selectStyle} from "../spaces/EditSpaceDrawer";
import moment from "moment";
import { editMortgage } from "../../../api-wrapper/mortgages/editMortgage";
import {addMortgage} from "../../../api-wrapper/mortgages/addMortgage";
import styled from "styled-components";
import {getAllMortgages} from "../../actions/mortgages";
import {addContact} from "../../../api-wrapper/contacts/addContact";
import {getAllContacts} from "../../actions/contacts";
import _ from "lodash";
import {AutoCompleteStyled} from "../styled";
import ContactDrawer from "../contacts/ContactDrawer";
import {numberWithCommasFull} from "../helper";
import {createPastExpenses} from "../../../api-wrapper/mortgages/createPastExpenses";
import {TMortgage} from "../../../api-wrapper/mortgages/getMortgages";
import {getCurrencySign} from "../../screens/helpers";
import {InputNumberStyled} from "../expenses/ExpenseDrawer";

type Props = {
  isOpen: boolean;
  toggleDrawerOpen: (arg: boolean) => void;
  setEditingMortgageData: (arg: any) => void;
  mortgage: { data: any } | null;
  refreshParent?: (arg?: Guid | null) => void;
}

const MortgageDrawer = (props: Props) => {
  const {isOpen, toggleDrawerOpen, refreshParent, setEditingMortgageData, mortgage} = props;
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const contacts = useSelector(getContactsSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [saveDisabled, setSaveDisabled] = useState(false);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [form] = Form.useForm();

  const [initialPayment, setInitialPayment] = useState<any>(0);
  const [monthlyInstallment, setMonthlyInstallment] = useState<any>(0);
  const [mortgageDate, setMortgageDate] = useState<any>(moment());

  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editContactData, setEditContactData] = useState<any>(null);
  const [saveAsContact, setSaveAsContact] = useState(false);
  const [contactList, setContactList] = useState<Array<{ value: string; label: string }>>([]);
  const [isExistingProvider, setIsExistingProvider] = useState(false);

  const initialValues = () => {
    if (mortgage) {
      const {provider, loanTotal, mortgageDate, durationYears, monthlyInstallment, initialPayment, fixedPeriodYears, interestRate} = mortgage.data;
      let providerName = undefined
      if (provider) {
        providerName = contacts.content.filter(contact => contact.contactId === provider)[0]?.name
      }
      return {
        provider: providerName,
        loanTotal,
        mortgageDate: moment(mortgageDate),
        durationYears,
        monthlyInstallment,
        initialPayment,
        fixedPeriodYears,
        interestRate
      };
    } else {
      return {};
    }
  };

  useEffect(() => {
    if (mortgage) {
      setInitialPayment(mortgage.data.initialPayment);
      setMonthlyInstallment(mortgage.data.monthlyInstallment);
      setMortgageDate(moment(mortgage.data.mortgageDate));
      setIsExistingProvider(contacts.content.filter((contact: any) =>
        contact.contactId === mortgage.data.provider)[0]?.contactType === ("Payee"));
    } else {
      setInitialPayment(0);
      setMonthlyInstallment(0);
      setMortgageDate(moment());
      setIsExistingProvider(false);
    }
    setSaveDisabled(false);
    form.resetFields();
  }, [mortgage])

  const createNewContact = async (payee: string) => {
    return await addContact({
      propertyId,
      request: {
        contactType: saveAsContact ? '' : 'Payee',
        name: payee,
        // @ts-ignore
        tags: []
      },
      contactImage: null
    })
  }

  const getContact = async (payee: string) => {
    if (!payee) return null
    else {
      let contactSearch = contacts.content.filter(contact => contact.name === payee)
      if (contactSearch.length > 0) {
        return { contact: contactSearch[0], isNewContact: false }
      } else {
        let newContact = await createNewContact(payee)
        return { contact: newContact.data, isNewContact: true }
      }
    }
  }

  const performCleanup = () => {
    toggleDrawerOpen(false);
    setEditingMortgageData(null);
    form.resetFields();
    setSaveDisabled(false);
    setFormFieldsChanged(false);
    dispatch(getAllMortgages(propertyId));
    if (refreshParent) refreshParent();
  }

  const promptCostCreation = (res: TMortgage) => {
    Modal.confirm({
      title: "Successfully created!",
      content:
        "Do you want to create expenses for mortgage payments in the past?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        createPastExpenses(res).then(() => notification.success({
          message: "Success!",
          description: "We have successfully created the records for your past payments. You can view them in the Expenses section.",
          placement: "topRight"
        })).catch(() => notification.error({
        message: "Error",
        description: "An error occurred during the records creation.",
        placement: "topRight",
      }))}
    });
  }

  const handleManageMortgage = async (e: {
    provider: string,
    loanTotal: number,
    mortgageDate: moment.Moment,
    durationYears: number,
    monthlyInstallment: number,
    initialPayment: number,
    fixedPeriodYears: number,
    interestRate: number
  }) => {
    const { provider, loanTotal, mortgageDate, durationYears, monthlyInstallment, initialPayment, fixedPeriodYears, interestRate } = e;

    const providerContact = await getContact(provider);

    if (mortgage) {
      editMortgage({
        propertyId,
        mortgageId: mortgage.data.mortgageId,
        // @ts-ignore
        provider: providerContact ? providerContact.contact.contactId : null,
        loanTotal,
        mortgageDate: mortgageDate.format("YYYY-MM-DD"),
        durationYears,
        monthlyInstallment,
        initialPayment,
        fixedPeriodYears,
        interestRate
      }).then(() => {
        providerContact && dispatch(getAllContacts(propertyId));
        performCleanup();
      })
    } else {
      addMortgage({
        propertyId,
        // @ts-ignore
        provider: providerContact ? providerContact.contact.contactId : null,
        loanTotal,
        mortgageDate: mortgageDate.format("YYYY-MM-DD"),
        durationYears,
        monthlyInstallment,
        initialPayment,
        fixedPeriodYears,
        interestRate
      }).then((res) => {
        res.data.expensesInPast && promptCostCreation(res.data)
        providerContact && dispatch(getAllContacts(propertyId));
        performCleanup();
      })
    }
    if (providerContact && saveAsContact) {
      toggleContactDrawer(true)
      setEditContactData({data: providerContact.contact})
    }
  }

  useEffect(() => {
    let unsortedContactList = contacts.content.filter(contact => contact.name && contact.contactType !== 'Payer').map((contact) => {
      return {value: contact.name, label: contact.name +
          (_.isEmpty(contact.company) ? "" : (contact.company === contact.name ? "" : " - " + contact.company)) +
          (_.isEmpty(contact.contactType) ? "" : " (" + contact.contactType+")")}})
    setContactList(unsortedContactList.sort((a: any, b: any) => {
      if(a.label.toLowerCase() < b.label.toLowerCase()) { return -1; }
      if(a.label.toLowerCase() > b.label.toLowerCase()) { return 1; }
      return 0;
    }))
  }, [contacts.content]);

  const onProviderAutocompleteSearch = (searchText: string) => {
    setContactList(!searchText ? contacts.content.filter((contact) => contact.contactType !== 'Payer').map((contact) => {
        return {value: contact.name, label: contact.name + (_.isEmpty(contact.contactType) ? "" : " (" + contact.contactType+")")}})
      : contacts.content.filter((contact) => contact.contactType !== 'Payer' && contact.name && contact.name.toLowerCase().includes(searchText.toLowerCase())).map((contact) => {
        return {value: contact.name, label: contact.name + (_.isEmpty(contact.contactType) ? "" : " (" + contact.contactType+")")}}))
  };

  const confirmCloseUnsaved = () => {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() { performCleanup() }
    });
  }

  const repaidEstimate = () => {
    return (typeof initialPayment === "number" ? initialPayment : 0) +
      (mortgageDate ? (typeof monthlyInstallment === "number" ? monthlyInstallment : 0) * moment().diff(mortgageDate, "months") : 0)
  }

  return (<>
    <ContactDrawer
      isOpen={isContactDrawerOpen}
      toggleDrawerOpen={toggleContactDrawer}
      setEditingContactData={setEditContactData}
      contact={editContactData}
    />
    <Drawer
      closeIcon={false}
      width={window.innerWidth > 1024 ? "680px" : "100%"}
      visible={isOpen}
      placement="right"
      onClose={() => {
        if (formFieldsChanged) {
          confirmCloseUnsaved();
          return;
        } else performCleanup();
      }}
      headerStyle={{ backgroundColor: "#ebf9ff", margin: 0, padding: "2rem 2rem" }}
      title={
        <>
          <section
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1.5rem",
            }}
          >
            <TenantIconWrapper>
              <MyTenantIcon className={"my-property-theme-icon"} />{" "}
              <span style={{ paddingRight: "0.25rem", fontSize: "0.75rem" }}>
              MY PROPERTY
            </span>
            </TenantIconWrapper>
            <ButtonsWrapper>
              <CloseButton size={"large"} onClick={() => performCleanup()}>
                {window.innerWidth > 1024 ? <span style={{ opacity: 1, color: "#21272B" }}>Cancel</span> : <CrossIcon />}
              </CloseButton>
            </ButtonsWrapper>
          </section>
          <Title>Mortgage</Title>
        </>
      }
    >
      <Form
        form={form}
        name="manageMortgage"
        layout="vertical"
        size={"large"}
        onFinish={handleManageMortgage}
        initialValues={initialValues()}
        onFieldsChange={() => setFormFieldsChanged(true)}
      >
        <section>
          <InputsWrapper>
            <InputWrapper>
              <LabelWrapper>
                <Label>Mortgage provider</Label>
              </LabelWrapper>
              <Form.Item name={"provider"} key={"provider"}>
                <AutoCompleteStyled
                  // @ts-ignore
                  getPopupContainer={trigger => trigger.parentElement}
                  showSearch
                  allowClear={true}
                  placeholder={"Enter a name"}
                  bordered={false}
                  showArrow={false}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  onSearch={onProviderAutocompleteSearch}
                  onChange={(val) => { setIsExistingProvider(contactList.filter((contact: any) =>
                    contact.value === val)[0]?.label?.includes("Payee"))}}
                >
                  {contactList.map((contact: any) =>
                    <Select.Option value={contact.value}>
                      <span style={{fontWeight: contact.label.includes("Payee") ? 400 : 600}}>
                        {contact.label}
                      </span>
                    </Select.Option>)}
                </AutoCompleteStyled>
              </Form.Item>
              {form.getFieldValue("provider") && (!contactList.filter((contact: any) =>
                contact.value === form.getFieldValue("provider")).length || isExistingProvider) &&
              <div style={{display: "flex", alignItems: "center", columnGap: "0.75rem", marginTop: "-0.5rem", marginBottom: "1rem"}}>
                  <Checkbox checked={saveAsContact} onClick={() => setSaveAsContact(!saveAsContact)}>
                  </Checkbox>
                  Add this Provider to your Contacts list
              </div>}
            </InputWrapper>
            <InputWrapper>
              <LabelWrapper>
                <Label>Mortgage loan (total)</Label>
              </LabelWrapper>
              <Form.Item
                name={"loanTotal"}
                key={"loanTotal"}
                rules={[{
                  pattern: /^\d*\.?\d*$/,
                  message: "Please input a valid numeric value",
                }]}
              >
                <InputNumberStyled
                  maxLength={10}
                  className={propertyPreferences.preferredCurrency}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                />
              </Form.Item>
            </InputWrapper>
          </InputsWrapper>

          <InputsWrapper>
            <InputWrapper>
              <LabelWrapper>
                <Label>Mortgage start date *</Label>
              </LabelWrapper>
              <Form.Item key={"mortgageDate"} name={"mortgageDate"} rules={[{required: true, message: "Mortgage start date is required"}]}>
                <DatePicker
                  name={"mortgageDate"}
                  format={propertyPreferences.preferredDateFormat}
                  allowClear={true}
                  inputReadOnly={true}
                  // @ts-ignore
                  getPopupContainer={trigger => trigger.parentElement}
                  onChange={(e) => setMortgageDate(e)}
                  style={{
                    width: "100%",
                    border: "1px solid #DAE0E6",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                  }}
                />
              </Form.Item>
            </InputWrapper>
            <InputWrapper>
              <LabelWrapper>
                <Label>Mortgage duration</Label>
              </LabelWrapper>
              <Form.Item
                name={"durationYears"}
                key={"durationYears"}
                rules={[{
                  pattern: /^[0-9]*$/,
                  message: "Please input a valid numeric value",
                }]}
              >
                <Input
                  style={{...selectStyle, height: "2.625rem", width: "100%"}}
                  suffix={<>yrs</>}
                />
              </Form.Item>
            </InputWrapper>
          </InputsWrapper>

          <InputsWrapper>
            <InputWrapper>
              <LabelWrapper>
                <Label>Monthly installment</Label>
              </LabelWrapper>
              <Form.Item
                name={"monthlyInstallment"}
                key={"monthlyInstallment"}
                rules={[{
                  pattern: /^\d*\.?\d*$/,
                  message: "Please input a valid numeric value",
                }]}
              >
                <InputNumberStyled
                  maxLength={10}
                  className={propertyPreferences.preferredCurrency}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  onChange={(e) => setMonthlyInstallment(e)}
                />
              </Form.Item>
            </InputWrapper>
            <InputWrapper>
              <LabelWrapper>
                <Label>First installment</Label>
              </LabelWrapper>
              <Form.Item
                name={"initialPayment"}
                key={"initialPayment"}
                rules={[{
                  pattern: /^\d*\.?\d*$/,
                  message: "Please input a valid numeric value",
                }]}
              >
                <InputNumberStyled
                  maxLength={10}
                  className={propertyPreferences.preferredCurrency}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  onChange={(e) => setInitialPayment(e)}
                />
              </Form.Item>
            </InputWrapper>
          </InputsWrapper>

          <InputsWrapper>
            <InputWrapper>
              <LabelWrapper>
                <Label>Fixed period</Label>
              </LabelWrapper>
              <Form.Item
                name={"fixedPeriodYears"}
                key={"fixedPeriodYears"}
                rules={[{
                  pattern: /^[0-9]*$/,
                  message: "Please input a valid numeric value",
                }]}
              >
                <Input
                  style={{...selectStyle, height: "2.625rem", width: "100%"}}
                  suffix={<>yrs</>}
                />
              </Form.Item>
            </InputWrapper>
            <InputWrapper>
              <LabelWrapper>
                <Label>Interest rate (fixed)</Label>
              </LabelWrapper>
              <Form.Item
                name={"interestRate"}
                key={"interestRate"}
              >
                <InputNumberStyled className={"percent"} precision={2} />
              </Form.Item>
            </InputWrapper>
          </InputsWrapper>

          <InputsWrapper>
            <InputWrapper style={{width: "100%", marginRight: 0}}>
              <LabelWrapper>
                <Label>Total repaid estimate</Label>
              </LabelWrapper>
              <FakeField>
                {getCurrencySign(propertyPreferences.preferredCurrency)}{" "}
                {numberWithCommasFull(repaidEstimate())}
              </FakeField>
            </InputWrapper>
          </InputsWrapper>
        </section>
        <SaveButtonWrapper style={{marginTop: 0}}>
          <SaveButton
            style={{display: "flex", alignItems: "center"}}
            loading={saveDisabled}
            icon={<SaveIconStyled />}
            type={"primary"}
            htmlType={"submit"}
          >
            Save details
          </SaveButton>
        </SaveButtonWrapper>
      </Form>
    </Drawer>
  </>);
}

const Title = styled.span`
  border: none;
  outline: none;
  background-color: #ebf9ff;
  font-size: 2.5rem;
`;

const FakeField = styled.div`
  border-radius: 6px;
  height: 2.625rem;
  border: 1px solid #F0F0F0;
  color: #BFBFBF;
  padding: 0.5rem 0.688rem;
  font-size: 1rem;
`

export default MortgageDrawer;