import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import { TGetInventoriesResponse } from "../../api-wrapper/inventories/getInventories";
import {
  getInventoriesFailureAction,
  getInventoriesInitAction,
  getInventoriesSuccessAction,
  TGetInventoriesActions,
} from "../actions/inventories";
import {inventoriesInitial} from "../components/helper";

export type TInventories = {
  fetchingStatus: TFetchingStatus;
  content: TGetInventoriesResponse;
};

const initialState: TInventories = {
  content: inventoriesInitial,
  fetchingStatus: TFetchingStatus.Defined,
};

export const inventoriesReducer = createReducer<
  TInventories,
  TGetInventoriesActions
>(initialState)
  .handleAction(getInventoriesInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getInventoriesSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getInventoriesFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
