import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import GuidanceRating from "../guidance/GuidanceRating";
import {ButtonGreen} from "../../screens/Home";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import Ellipsis from "ant-design-pro/lib/Ellipsis";

type Props = {
  improvements: any;
  categories: any;
}

const GuidanceCarouselDesktop = (props: Props) => {
  const {improvements, categories} = props;
  let history = useHistory();
  const [slide, setSlide] = useState(0);

  const filteredImprovements = improvements.filter((item: any) =>
    item.name.includes("Install high efficiency gas boiler") ||
    item.name.includes("Install heat pump (ASHP, GSHP, hybrid) for heating and hot water") ||
    item.name.includes("Insulate hot water cylinder and hot water pipes") ||
    item.name.includes("Install on-site renewable energy systems: PVs"))

  const getCategoryName = (categoryId: number) => categories.filter((c: any) => c.id === categoryId)[0].name;
  const item = filteredImprovements[slide];

  return item ? <GuidanceCard>
    <GuidanceImage style={{backgroundImage: `url("https://www.livlet.com/guidance/${item.id}.jpg")`}} />
    <GuidanceInfoWrapper>
      <div className={"line-wrapper"}>
        <span className={"rating-wrapper"}>
          <GuidanceRating item={item}/>
        </span>
        <ViewAllLink onClick={() => history.push("/guidance")}>View all</ViewAllLink>
      </div>
      <span>
        <div className={"item-category"}><Ellipsis lines={1}>{getCategoryName(item.category)}</Ellipsis></div>
        <div className={"item-title"}><Ellipsis lines={2}>{item.name}</Ellipsis></div>
      </span>
      <div className={"line-wrapper"}>
        <span className={"dots-wrapper"}>
          {filteredImprovements.map((item: any, index: any) => index === slide ?
            <Dot className={"active"} onClick={() => setSlide(index)} /> :
            <Dot onClick={() => setSlide(index)} />)}
        </span>
        <ButtonGreen onClick={() => {(slide+1) === filteredImprovements.length ? setSlide(0) : setSlide(slide+1)}}>Next</ButtonGreen>
      </div>
    </GuidanceInfoWrapper>
  </GuidanceCard> : <GuidanceCard className={"loading-card"}>
    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
    Loading guidance items...
  </GuidanceCard>
}

const GuidanceCard = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  gap: 1.25rem;
  
  &.loading-card {
    flex-direction: column;
    gap: 0.75rem;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  
  @media (max-width: 1340px) { gap: 0.75rem; }
`

const GuidanceInfoWrapper = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  gap: 0.5rem;
  
  .rating-wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
  }
  
  .dots-wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
  }
  
  .line-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .item-category {
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    color: #666F7A;
  }
  
  .item-title {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #21272B;
  }
`

const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background: #D9D9D9;
  border-radius: 100%;
  
  &.active { background: #00CC33; }
`

const GuidanceImage = styled.div`
  width: 35%;
  max-width: 10rem;
  height: 10rem;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: center center; 
  background-size: cover;
`

const ViewAllLink = styled.span`
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-bottom: 1px solid #009966;
  color: #009966;
  
  &:hover {
    color: #22AB5B;
    border-bottom: 1px solid #22AB5B;
  }
`

export default GuidanceCarouselDesktop