import { createReducer } from "typesafe-actions";
import produce from "immer";

import { TFetchingStatus } from "../../helpers";
import {
  getInventoryReportFailureAction,
  getInventoryReportInitAction,
  getInventoryReportSuccessAction,
  TGetInventoryReportActions,
} from "../actions/inventoryReport";
import {TGetInventoryReportResponse} from "../../api-wrapper/reports/inventoryReport";

export type TInventoryReport = {
  fetchingStatus: TFetchingStatus;
  content: TGetInventoryReportResponse;
};

const initialState: TInventoryReport = {
  content: [],
  fetchingStatus: TFetchingStatus.Defined,
};

export const inventoryReport = createReducer<
  TInventoryReport,
  TGetInventoryReportActions
>(initialState)
  .handleAction(getInventoryReportInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Initial;
    })
  )
  .handleAction(getInventoryReportSuccessAction, (state, action) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.content = action.payload;
    })
  )
  .handleAction(getInventoryReportFailureAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
    })
  );
