import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {ReactComponent as RecommendedImprovementIcon} from "../../../images/RecommendedImprovementIcon.svg";
import MaintenanceSwitch from "../maintenances/editableParams/MaintenanceSwitch";
import MaintenanceCost, {EditableValueWrapper} from "../maintenances/editableParams/MaintenanceCost";
import MaintenanceFrequency from "../maintenances/editableParams/MaintenanceFrequency";
import MaintenanceStartDate from "../maintenances/editableParams/MaintenanceStartDate";
import {CancelButton, getStartDate} from "../maintenances/MaintenanceListItemNew";
import MaintenanceMoreMenu from "../maintenances/editableParams/MaintenanceMoreMenu";
import MaintenanceSaveButton from "../maintenances/editableParams/MaintenanceSaveButton";
import {ReactComponent as CancelIcon} from "../../../images/CancelIcon.svg";
import {Modal} from "antd";
import {Guid} from "guid-typescript";
import {LinkedItemTags} from "../spaces/LinkedItem";

type Props = {
  item: any;
  editing: string;
  inventory: Guid;
  setEditing: (arg: string) => void;
  toggleUpkeepDrawer: (arg: boolean) => void;
  setEditingUpkeepData: (arg: any) => void;
  refreshParent: any;
}

const InventoryUpkeepListItem = (props: Props) => {
  const {item, inventory, editing, setEditing, toggleUpkeepDrawer, setEditingUpkeepData, refreshParent} = props;

  const [itemCost, setItemCost] = useState(0)
  const [itemFrequency, setItemFrequency] = useState(1)
  const [itemFrequencyUnit, setItemFrequencyUnit] = useState("")
  const [itemStartDate, setItemStartDate] = useState("")

  const setDefaults = (item: any) => {
    setItemCost(item.actualEstimatedCost ?? item.estimatedCost ?? 0)
    setItemFrequency(item.actualPeriodicity ?? item.periodicity)
    setItemFrequencyUnit(item.actualPeriodicityUnit ?? item.periodicityUnit)
    setItemStartDate(getStartDate(item))
  }

  useEffect(() => {
    setDefaults(item)
  }, [item])

  const handleReset = () => {
    setDefaults(item)
    setEditing("")
  }

  const editItem = (item: any) => {
    if (editing && item.id !== editing) {
      Modal.error({
        title: "Error",
        content: "You're already editing another item right now.",
        className: "form-confirm-close-modal"
      });
      return
    }
    if (!editing && item.category !== "Custom") {
      setEditing(item.id)
    }
    if (!editing && item.category === "Custom") {
      setEditingUpkeepData(item);
      toggleUpkeepDrawer(true);
    }
  }

  return <UpkeepItem>
    <UpkeepDetailsWrapper>
      <UpkeepTitleWrapper>
        <div style={{display: "flex", columnGap: "1rem"}}>
          <UpkeepTitle style={{color: item.recommended ? "#2A80E1" : "#45655E"}}>
            {item.recommended && <RecommendedImprovementIcon style={{flexShrink: 0}}/>} {item.title}
          </UpkeepTitle>
          <UpkeepCategory>{item.category}</UpkeepCategory>
        </div>
        {/*<LinkedItemTags>*/}
        {/*  {item.maintenanceKeywords && item.maintenanceKeywords.split(",").map((tag: string) => <UpkeepTag>{tag}</UpkeepTag>)}*/}
        {/*</LinkedItemTags>*/}
      </UpkeepTitleWrapper>
      <div style={{display: "flex", columnGap: "1.5rem"}}>
        {item.id !== editing && <EditableValueWrapper style={{width: "4rem"}}>
          Sign-up
          <MaintenanceSwitch item={item} isOnboarding={false} refreshParent={refreshParent} />
        </EditableValueWrapper>}
        <EditableValueWrapper style={{width: "2.5rem"}}>
          Edit
          <MaintenanceMoreMenu item={item} inventory={inventory} isOnboarding={false} editItem={editItem} refreshParent={refreshParent} />
        </EditableValueWrapper>
      </div>
    </UpkeepDetailsWrapper>
    {item.id === editing && <UpkeepDescription>{item.message}</UpkeepDescription>}
    {item.id === editing && <UpkeepDetailsToEdit>
      <UpkeepDetail>
          <MaintenanceCost cost={itemCost} setCost={setItemCost}/>
      </UpkeepDetail>
      <UpkeepDetail>
          <MaintenanceFrequency
          frequency={itemFrequency}
          frequencyUnit={itemFrequencyUnit}
          setFrequency={setItemFrequency}
          setFrequencyUnit={setItemFrequencyUnit}
      />
      </UpkeepDetail>
      <UpkeepDetail>
          <MaintenanceStartDate
              startDate={itemStartDate}
              setStartDate={setItemStartDate}
          />
      </UpkeepDetail>
      <UpkeepDetail className={"save"}>
          <EditableValueWrapper style={{width: "2.5rem"}}>
              Save
              <MaintenanceSaveButton
                  item={item}
                  itemFrequency={itemFrequency}
                  itemFrequencyUnit={itemFrequencyUnit}
                  itemCost={itemCost}
                  itemStartDate={itemStartDate}
                  isOnboarding={false}
                  setEditing={setEditing}
              />
          </EditableValueWrapper>
      </UpkeepDetail>
      <UpkeepDetail className={"cancel"}>
          <EditableValueWrapper style={{width: "2.5rem"}}>
              Cancel
              <CancelButton onClick={() => handleReset()}><CancelIcon/></CancelButton>
          </EditableValueWrapper>
      </UpkeepDetail>
    </UpkeepDetailsToEdit>}
    </UpkeepItem>
}

const UpkeepItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1.5rem;
  border: 1px solid #DAE0E6;
  border-radius: 6px;
  margin: 0.75rem 0;
  row-gap: 0.5rem;
`

const UpkeepTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  max-width: 75%;
`

const UpkeepTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #2A80E1;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`

const UpkeepCategory = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #9CA4AE;
`

const UpkeepDescription = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #45655E;
`

const UpkeepDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const UpkeepDetailsToEdit = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  column-gap: 0.5rem;
  
  @media (max-width: 720px) {
    flex-wrap: wrap;
    column-gap: 0;
    row-gap: 0.5rem;
  }
`

const UpkeepDetail = styled.div`
  width: auto;
  display: flex;

  @media (max-width: 720px) {
    width: 50%;
    justify-content: space-around;
    align-items: center;
    
    &.save { 
      width: 25%;
      justify-content: flex-end;
      padding-right: 1rem;
    }
     
    &.cancel {
      width: 25%;
      justify-content: flex-start;
      padding-left: 1rem;
    }
  }
`

const UpkeepTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.75rem;
  row-gap: 0.25rem;
`

const UpkeepTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EFF1F5;
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.938rem;
  color: #8E8E89;
  text-transform: uppercase;
  height: 2rem;
  flex-shrink: 0;
`

export default InventoryUpkeepListItem