import decodeJwt from "jwt-decode";
import {refreshTokenRequest} from "./logIn";
import {Guid} from "guid-typescript";

export const ACCESS_TOKEN_KEY = "livlet-token";

export function getAccessToken() {
  try {
    const data = window.localStorage.getItem(ACCESS_TOKEN_KEY);
    const { accessToken } = data && JSON.parse(data);
    return accessToken;
  } catch (e) {
    return null;
  }
}

export function saveAccessToken(accessToken: string) {
  const auth = JSON.stringify({ accessToken });
  window.localStorage.setItem(ACCESS_TOKEN_KEY, auth);
}

export function clearAccessToken() {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
}

export function decodeToken(accessToken: string, refreshToken?: string) {
  const { exp, userName, tenantId, jti } = decodeJwt<{
    tenantId: Guid;
    jti: string;
    exp: number;
    userName: string;
  }>(accessToken);
  return { exp, accessToken, userName, jti, tenantId };
}

export async function refreshToken() {
  return refreshTokenRequest(getAccessToken());
}