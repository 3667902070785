import React, {useEffect, useState} from "react";
import {Form, Input, Modal, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getPropertiesSelector} from "../../selectors";
import {
  DrawerFooter, EditDrawerHeader, EditDrawerSaveButton,
  EditDrawerStyled,
  FormStyled, PhotoNameFieldsWrapper
} from "../../screens/components";
import {Label} from "../../screens/RegistrationPage";
import UploadImage from "../spaces/UploadImage";
import {selectStyle} from "../spaces/EditSpaceDrawer";
import {updatePet, updatePetWithImage} from "../../../api-wrapper/pets/editPet";
import {Guid} from "guid-typescript";
import {addPet} from "../../../api-wrapper/pets/addPet";
import {ReactComponent as DrawerCloseIcon} from "../../../images/DrawerCloseIcon.svg";
import {getAllPets} from "../../actions/pets";

type Props = {
  isDrawerOpen: boolean;
  toggleDrawer: (arg: boolean) => void;
  pet: any;
  setPet: (arg: any) => void;
  refreshParent?: () => void;
}

const PetDrawer = (props: Props) => {
  const {isDrawerOpen, toggleDrawer, pet, setPet, refreshParent} = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const properties = useSelector(getPropertiesSelector);

  const petTypes = ["Cat", "Dog", "Bird", "Rabbit", "Fish", "Reptile", "Other"]

  const [petImageLink, setPetImageLink] = useState("");
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [petImageName, setPetImageName] = useState("");
  const [petImage, setPetImage] = useState<any>(null);
  const [previewLink, setPreviewLink] = useState("");
  const [removeImage, toggleRemoveImage] = useState(false);
  const [editImage, toggleEditImage] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const initialValues = () => {
    if (pet) {
      const {name, propertyId, petType} = pet;
      return {name, selectedPropertyId: propertyId, petType};
    } else {
      return {};
    }
  };

  useEffect(() => {
    if (pet) {
      setPetImageLink(pet.imageThumbnail);
    } else {
      setPetImageLink("");
    }
    setPetImageName("");
    setPetImage(null);
    form.resetFields();
    setFormFieldsChanged(false);
  }, [pet])

  const performCleanup = () => {
    dispatch(getAllPets());
    toggleDrawer(false);
    setPet(null);
    setSaveDisabled(false);
    setPetImageLink("");
    setPetImageName("");
    setPreviewLink("");
    setPetImage(null);
    form.resetFields();
  }

  function confirmCloseUnsaved() {
    Modal.confirm({
      title: "You have unsaved changes",
      content:
        "You have unsaved changes, if you close the form these changes will be lost. Are you sure you want to close the form?",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        performCleanup()
      },
    });
  }

  const handleCancel = () => {
    if (formFieldsChanged) {
      confirmCloseUnsaved();
      return;
    } else {
      performCleanup();
    }
  }

  const handleManagePet = (e: {
    name: string, 
    selectedPropertyId: Guid, 
    petType: string,
  }) => {
    const {name, selectedPropertyId, petType} = e;
    setSaveDisabled(true);
    if (pet) {
      if (petImage || removeImage) {
        updatePetWithImage({
          petId: pet.petId,
          petCreateDto: {
            name,
            petType,
            propertyId: selectedPropertyId
          },
          petImage
        }).then(() => {
          performCleanup();
          refreshParent && refreshParent();
        })
      } else {
        updatePet({
          name,
          petType,
          propertyId: selectedPropertyId,
          petId: pet.petId
        }).then(() => {
          performCleanup();
          refreshParent && refreshParent();
        })
      }
    } else {
      addPet({
        petCreateDto: {
          name,
          petType,
          propertyId: selectedPropertyId
        },
        petImage
      }).then(() => {
        performCleanup();
        refreshParent && refreshParent();
      })
    }
  }

  return <EditDrawerStyled
    push={{ distance: "32px" }}
    closeIcon={false}
    width={window.innerWidth > 1024 ? "680px" : "100%"}
    height={window.innerWidth > 768 ? "100%" : "85%"}
    placement={window.innerWidth > 768 ? "right" : "bottom"}
    visible={isDrawerOpen}
    onClose={() => handleCancel()}
    title={<EditDrawerHeader>
      Pet
      <DrawerCloseIcon style={{cursor: "pointer"}} onClick={() => handleCancel()} />
    </EditDrawerHeader>}
  >
    <FormStyled
      form={form}
      name="editSpace"
      layout="vertical"
      size={"large"}
      // @ts-ignore
      onFinish={handleManagePet}
      initialValues={initialValues()}
      onFieldsChange={() => setFormFieldsChanged(true)}
    >
      <div>
        <PhotoNameFieldsWrapper>
          <div>
            <UploadImage
              image={petImage}
              imageName={petImageName}
              imageLink={petImageLink}
              setImageLink={setPetImageLink}
              previewLink={previewLink}
              setPreviewLink={setPreviewLink}
              setImage={setPetImage}
              setImageName={setPetImageName}
              editImage={editImage}
              toggleEditImage={toggleEditImage}
              removeImage={removeImage}
              toggleRemoveImage={toggleRemoveImage}
              uploadType={"photo"}
            />
          </div>
          <div className={"field-wrapper"}>
            <Label>Pet name</Label>
            <Form.Item key={"name"} name={"name"} rules={[{required: true, message: "Pet name is required"}]}>
              <Input style={{borderRadius: "6px", width: "100%"}} placeholder={"Enter pet name"} />
            </Form.Item>
          </div>
        </PhotoNameFieldsWrapper>
        <Label>Type of pet</Label>
        <Form.Item key={"petType"} name={"petType"} rules={[{required: true, message: "Pet type is required"}]}>
          <Select
            getPopupContainer={trigger => trigger.parentElement}
            placeholder={"Select pet type"}
            bordered={false}
            style={selectStyle}
          >
            {petTypes.map(type => <Select.Option value={type}>{type}</Select.Option>)}
          </Select>
        </Form.Item>

        <Label>Which property do they live in?</Label>
        <Form.Item key={"selectedPropertyId"} name={"selectedPropertyId"}>
          <Select
            getPopupContainer={trigger => trigger.parentElement}
            placeholder={"Select property"}
            bordered={false}
            style={selectStyle}
          >
            {properties.map(property => <Select.Option value={property.propertyId.toString()}>{property.address}</Select.Option>)}
          </Select>
        </Form.Item>
        <DrawerFooter className={"fake-footer"}>
          <EditDrawerSaveButton disabled={saveDisabled} htmlType={"submit"}>Save</EditDrawerSaveButton>
        </DrawerFooter>
      </div>
    </FormStyled>
  </EditDrawerStyled>
}

export default PetDrawer;