import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Button, Collapse} from "antd";
import styled from "styled-components";
import { useIntl } from "react-intl";
import messages from "./messages";
import {
  getInventoriesSelector,
  getPropertiesSelector,
  getPropertyIdSelector, getPropertyPreferencesSelector,
  getUserProfileSelector
} from "../selectors";
import { getAllInventories } from "../actions/inventories";
import _ from "lodash";
import InventoryDrawer from "../components/inventories/InventoryDrawer";
import InventoriesListComp, {inventoryTypes} from "../components/inventories/InventoriesListComp";
import { ReactComponent as DotIcon } from "../../images/DotForFilters.svg";
import { getAllContacts } from "../actions/contacts";
import { PlusOutlined } from "@ant-design/icons";
import { getAllWorks } from "../actions/works";
import {downloadExport, inventoriesInitial, numberWithCommas} from "../components/helper";
import {useHistory, useLocation} from "react-router-dom";
import {ReactComponent as SearchIcon} from "../../images/SearchGray.svg";
import {ReactComponent as FilterIcon} from "../../images/Filters.svg";
import {FilterGroupRow, Filters, SearchWrapper} from "./styled";
import FilterTagsNew, {
  CancelButton,
  CategoryHeader,
  EditCategoryBody,
  EditCategoryContainer,
  EditCategoryHeader,
  EditTagsBody,
  SubcategoryHeader, TagStyled
} from "../components/tags/FilterTagsNew";
import {aggregateistOfTags, emptyGuid, getCurrencySign, hasSomeSelectedTags, parseToLowerCase} from "./helpers";
import ForwardInventoryDrawer from "../components/inventories/ForwardInventoryDrawer";
import moment from "moment";
import {ButtonFilters,  FilterTagsWrapper} from "./Costs";
import {getAllMaintenances} from "../actions/maintenances";
import {ReactComponent as DownloadExportIcon} from "../../images/DownloadExports.svg";
import {exportInventory} from "../../api-wrapper/inventories/exportInventory";
import {ReactComponent as FlashIcon} from "../../images/FlashIcon.svg";
import {addInventory} from "../../api-wrapper/inventories/addInventory";
import {
  HeaderControlsWrapper,
  PageHeader, PageSubtitle, PageTitle, PageWrapper, AddButtonStyled, PageInfoColumn,
  PageInfoWrapper, Search, IntroductionButton, EmptyStateWrapper
} from "./components";
import {ReactComponent as HandholdingArrowRight} from "../../images/HandholdingArrowRight.svg";
import {HandholdingCircle} from "./Home";
import {TFetchingStatus} from "../../helpers";
import {setUserWindowsState} from "../../api-wrapper/user/setUserWindowsState";
import {getCurrentUserProfile} from "../actions/getUserProfile";
import {getAllProperties} from "../actions/properties";
import InventoryIntroduction from "../components/inventories/InventoryIntroduction";
import {ReactComponent as ChevronSmall} from "../../images/ChevronSmall.svg";
import {ReactComponent as EmptyContentsIcons} from "../../images/empty-states/EmptyContentsIcons.svg";
import WorkDrawer from "../components/works/WorkDrawer";
import ViewWorkDrawer from "../components/works/ViewWorkDrawer";
import LinkDrawer from "../components/spaces/LinkDrawer";
import ViewSpaceDrawer from "../components/spaces/ViewSpaceDrawer";
import NoteDrawer from "../components/notes/NoteDrawer";
import {getAllSpaces} from "../actions/spaces";
import ViewFileDrawer from "../components/attachments/ViewFileDrawer";
import ExpenseDrawer from "../components/expenses/ExpenseDrawer";
import ContactDrawer from "../components/contacts/ContactDrawer";
import {ReactComponent as CollapseIconMinus} from "../../images/CollapseIconMinus.svg";
import {ReactComponent as CollapseIconPlus} from "../../images/CollapseIconPlus.svg";
const { Panel } = Collapse;

const inventoryStatuses = [
  "Insured",
  "Uninsured",
  "Under warranty",
  "Warranty expired",
  "Within typical life",
  "Past typical life",
]

const Contents = () => {
  const { formatMessage: f } = useIntl();

  const dispatch = useDispatch();
  let history = useHistory();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const inventories = useSelector(getInventoriesSelector);
  const userProfile = useSelector(getUserProfileSelector);
  const properties = useSelector(getPropertiesSelector);
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [editingInventoryData, setEditingInventoryData] = useState<any>(null);
  const [isInventoryDrawerOpen, toggleInventoryDrawer] = useState(false);
  const [defaultOpenType, setDefaultOpenType] = useState<any>("");

  const [search, setSearch] = useState("");
  const [showTagFilters, setShowTagFilters] = useState(false);
  const [selectedInventoryTypes, setSelectedInventoryTypes] = useState<Set<string>>(new Set());
  const [selectedInventoryStatuses, setSelectedInventoryStatuses] = useState<Set<string>>(new Set());
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [isFiltering, toggleFiltering] = useState(false);

  const [isForwardDrawerOpen, toggleForwardDrawer] = useState(false);
  const [inventoryToForward, setInventoryToForward] = useState(emptyGuid);

  const [workDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editWorkData, setEditWorkData] = useState<any>(null);
  const [isViewWorkDrawerOpen, toggleViewWorkDrawer] = useState(false);
  const [workToViewId, setWorkToViewId] = useState<any>(null);
  const [isViewFileDrawerOpen, toggleViewFileDrawer] = useState(false);
  const [attachmentToViewId, setAttachmentToViewId] = useState<any>(null);
  const [isNoteDrawerOpen, toggleNoteDrawer] = useState(false);
  const [editingNoteData, setEditingNoteData] = useState(null);
  const [isViewSpaceDrawerOpen, toggleViewSpaceDrawer] = useState(false);
  const [viewSpaceId, setViewSpaceId] = useState(null);
  const [isLinkDrawerOpen, toggleLinkDrawer] = useState(false);
  const [presetLinkType, setPresetLinkType] = useState<any>(null);
  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [editingExpenseData, setEditingExpenseData] = useState<any>(null);
  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editingContactData, setEditingContactData] = useState(null);

  const [inventoryTypesWithAmounts, setInventoryTypesWithAmounts] = useState<any>([]);
  const [inventoryStatusesWithAmounts, setInventoryStatusesWithAmounts] = useState<any>([]);
  const [currentProperty, setCurrentProperty] = useState<any>(null);
  const [handholdingStatus, setHandholdingStatus] = useState<any>(null);
  const [introductionStatus, setIntroductionStatus] = useState<any>(null);
  const [activeKeys, setActiveKeys] = useState<any>(["categories"]);

  const haveFiltersBeenUsed = () => selectedTags.length > 0 ||
    selectedInventoryTypes.size > 0 || selectedInventoryStatuses.size > 0

  const amountOfInventories = inventories.content.filter(inventory => !_.isNull(inventory.name)).length;
  const amountOfInsuredInventories = inventories.content.filter(inventory => !_.isNull(inventory.name) && inventory.insuredItems).length;
  const inventoriesTotalCost = inventories.content.reduce((sumOfExpenses, { purchaseCost }) => sumOfExpenses + purchaseCost, 0);

  useEffect(() => {
    if (userProfile.fetchingStatus === TFetchingStatus.Success) {
      setHandholdingStatus(userProfile.content.handholdingStatus);
      setIntroductionStatus(userProfile.content.contentsStatus);
    }
  }, [userProfile])

  useEffect(() => {
    const current = properties.find((property) => property.propertyId === propertyId);
    if (!current)
      return;
    setCurrentProperty(current);
  }, [properties, propertyId])

  useEffect(() => {
    if (inventories.content.length > 0 && handholdingStatus === "contents") {
      setUserWindowsState({
        onboardingInterestSurveyStatus: userProfile.content.onboardingInterestSurveyStatus,
        onboardingUpkeepStatus: currentProperty.onboardingUpkeepStatus,
        onboardingContentStatus: currentProperty.onboardingContentStatus,
        onboardingImprovementsStatus: currentProperty.onboardingImprovementsStatus,
        mobileWelcomeStatus: userProfile.content.mobileWelcomeStatus,
        questionnairePage: currentProperty.questionnairePage,
        handholdingStatus: "completed",
        propertyId
      }).then(() => dispatch(getCurrentUserProfile()))
    }
  }, [inventories])

  const addNewInventory = () => {
    addInventory({
      propertyId,
    }).then((res) => {
      setEditingInventoryData(res);
      dispatch(getAllInventories(propertyId));
      toggleInventoryDrawer(true);
    });
  }

  useEffect(() => {
    dispatch(getAllInventories(propertyId));
    dispatch(getAllContacts(propertyId));
    dispatch(getAllWorks(propertyId));
    dispatch(getAllMaintenances(propertyId));
    dispatch(getAllProperties());
    dispatch(getCurrentUserProfile());
  }, [propertyId]);

  const useQuery = () => {
    let query = new URLSearchParams(useLocation().search);
    return query.get("category");
  };
  const queryCategory = useQuery();

  useEffect(() => {
    queryCategory && setDefaultOpenType(queryCategory)
  }, [queryCategory])

  const determineInventoryStatuses = (inventory: any) => {
    const inventoryStatuses: any = [];
    if (inventory.insuredItems) inventoryStatuses.push("Insured")
    if (!inventory.insuredItems) inventoryStatuses.push("Uninsured")
    if (inventory.warrantyExpiration && moment(inventory.warrantyExpiration).isAfter(moment())) inventoryStatuses.push("Under warranty")
    if (inventory.warrantyExpiration && !moment(inventory.warrantyExpiration).isAfter(moment())) inventoryStatuses.push("Warranty expired")
    if (inventory.applianceLifetimeYearsLeft && inventory.applianceLifetimeYearsLeft >= 0 ) inventoryStatuses.push("Within typical life")
    if (inventory.applianceLifetimeYearsLeft && inventory.applianceLifetimeYearsLeft < 0 ) inventoryStatuses.push("Past typical life")
    return inventoryStatuses
  }

  const getClassesForTag = (type: any, selectedType: any) => {
    if (type.has(selectedType.type)) return "tag-blue"
    if (selectedType.amount === 0) return "tag-gray"
  }

  const filteredInventory = () =>
    inventories.content
      .filter(inventory => !_.isEmpty(inventory.name))
      .filter((inventory) => {
        return !_.isEmpty(search)
          ? (!_.isNil(inventory.name) &&
          _.includes(
            parseToLowerCase("" + inventory.name),
            parseToLowerCase(search)
          )) ||
          (!_.isNil(inventory.tags) &&
            _.includes(
              parseToLowerCase(aggregateistOfTags(inventory.tags)),
              parseToLowerCase(search)
            )) : true;
      })
      .filter(inventory => {
        return selectedInventoryTypes.size > 0
          ? selectedInventoryTypes.has(inventory.inventoryType)
          : true;
      })
      .filter(inventory => {
        const statuses = determineInventoryStatuses(inventory)
        const availableStatuses = statuses.filter((status: any) => selectedInventoryStatuses.has(status))
        return selectedInventoryStatuses.size > 0
          ? availableStatuses.length > 0
          : true;
      })
      .filter(inventory => {
        return selectedTags.length > 0
          ? hasSomeSelectedTags(selectedTags, inventory.tags)
          : true;
      })
      .sort((i1, i2) => {
        return i1.name.localeCompare(i2.name)
      })

  useEffect(() => {
    let amountsMap = new Map();
    const inventoryItems = selectedTags.length > 0 ? filteredInventory() : inventories.content

    inventoryItems.forEach((inventory => {
      if (amountsMap.has(inventory.inventoryType)) {
        amountsMap.set(inventory.inventoryType, amountsMap.get(inventory.inventoryType)+1);
      } else {
        amountsMap.set(inventory.inventoryType, 1);
      }
    }));

    let amounts: any = [];
    inventoryTypes.forEach((inventoryType) => {
      let amount = 0;
      if (amountsMap.has(inventoryType)) {
        amount = amountsMap.get(inventoryType);
      }
      amounts.push({type: inventoryType, amount: amount});
    });

    amounts = amounts.sort((e1: any, e2: any) => e2.amount - e1.amount);
    setInventoryTypesWithAmounts(amounts);
  }, [inventories, search, selectedTags]);

  useEffect(() => {
    let amountsMap = new Map();
    inventoryStatuses.forEach((inventoryStatus) => amountsMap.set(inventoryStatus, 0));
    const inventoryItems = selectedTags.length > 0 ? filteredInventory() : inventories.content

    inventoryItems.forEach((inventory => {
      if (inventory.insuredItems) amountsMap.set("Insured", amountsMap.get("Insured") + 1);
      if (!inventory.insuredItems) amountsMap.set("Uninsured", amountsMap.get("Uninsured") + 1);
      if (inventory.warrantyExpiration && moment(inventory.warrantyExpiration).isAfter(moment()))
        amountsMap.set("Under warranty", amountsMap.get("Under warranty") + 1);
      if (inventory.warrantyExpiration && !moment(inventory.warrantyExpiration).isAfter(moment()))
        amountsMap.set("Warranty expired", amountsMap.get("Warranty expired") + 1);
      if (inventory.applianceLifetimeYearsLeft && inventory.applianceLifetimeYearsLeft >= 0 )
        amountsMap.set("Within typical life", amountsMap.get("Within typical life") + 1);
      if (inventory.applianceLifetimeYearsLeft && inventory.applianceLifetimeYearsLeft < 0 )
        amountsMap.set("Past typical life", amountsMap.get("Past typical life") + 1);
    }));

    let amounts: any = [];
    inventoryStatuses.forEach((inventoryStatus) => {
      amounts.push({type: inventoryStatus, amount: amountsMap.get(inventoryStatus)});
    });
    amounts = amounts.sort((e1: any, e2: any) => e2.amount - e1.amount);
    setInventoryStatusesWithAmounts(amounts);
  }, [inventories, search, selectedTags]);

  const selectInventoryType = (type: string) => {
    const newSet = new Set(selectedInventoryTypes);
    if (selectedInventoryTypes.has(type)) {
      newSet.delete(type);
    } else {
      newSet.add(type);
    }
    setSelectedInventoryTypes(newSet);
  }

  const selectInventoryStatus = (type: string) => {
    const newSet = new Set(selectedInventoryStatuses);
    if (selectedInventoryStatuses.has(type)) {
      newSet.delete(type);
    } else {
      newSet.add(type);
    }
    setSelectedInventoryStatuses(newSet);
  }

  useEffect(() => {
    if (selectedInventoryTypes.size || selectedInventoryStatuses.size || selectedTags.length) {
      toggleFiltering(true)
    } else {
      toggleFiltering(false)
    }
  }, [selectedInventoryTypes, selectedInventoryStatuses, selectedTags])

  useEffect(() => {
    const inventoryItems = selectedTags.length > 0 ? filteredInventory() : inventories.content
    let amountsMap = new Map();
    inventoryItems.forEach((inventory => {
      if (amountsMap.has(inventory.inventoryType)) {
        amountsMap.set(inventory.inventoryType, amountsMap.get(inventory.inventoryType)+1);
      } else {
        amountsMap.set(inventory.inventoryType, 1);
      }
    }));

    let amounts: any = [];
    inventoryTypes.forEach((inventoryStatus) => {
      amountsMap.get(inventoryStatus) > 0 && amounts.push({type: inventoryStatus, amount: amountsMap.get(inventoryStatus)});
    });

    if (amounts.length) {
      !queryCategory && !defaultOpenType && setDefaultOpenType(amounts[0].type)
    }
  }, [inventories, queryCategory, selectedInventoryTypes, selectedInventoryStatuses, selectedTags])

  const displayFilters = () => (<>
    <Filters>
      <FilterGroupRow>
        <CategoryHeader>
          Filters <CancelButton onClick={() => {
          setSelectedInventoryTypes(new Set());
          setSelectedInventoryStatuses(new Set());
          setSelectedTags([]);
        }}>Clear filters</CancelButton>
        </CategoryHeader>
      </FilterGroupRow>

      <FilterGroupRow style={{flexDirection: "column", rowGap: "1rem", border: 0, marginBottom: 0}}>
        <Collapse bordered={false} ghost onChange={(key) => setActiveKeys(key)} defaultActiveKey={activeKeys}>
          <Panel showArrow={false} header={<SubcategoryHeader>
            Categories
            <div className={"button-wrapper"}>
              {activeKeys.includes("categories") ? <CollapseIconMinus /> : <CollapseIconPlus />}
            </div>
          </SubcategoryHeader>} key="categories">
            <EditTagsBody>
              <EditCategoryContainer>
                <EditCategoryHeader>Type of Inventory</EditCategoryHeader>
                <EditCategoryBody>
                  {inventoryTypesWithAmounts.map((inventoryType: any, index: any) => <TagStyled
                    key={index}
                    className={getClassesForTag(selectedInventoryTypes, inventoryType)}
                    onClick={() => selectInventoryType(inventoryType.type)}
                  >{inventoryType.type === "ToolsAndMachinery" ? "Tools and Machinery" : inventoryType.type} ({inventoryType.amount})</TagStyled>)}
                </EditCategoryBody>
                <EditCategoryHeader>Inventory Status</EditCategoryHeader>
                <EditCategoryBody>
                  {inventoryStatusesWithAmounts.map((inventoryStatus: any, index: any) => <TagStyled
                    key={index}
                    className={getClassesForTag(selectedInventoryStatuses, inventoryStatus)}
                    onClick={() => selectInventoryStatus(inventoryStatus.type)}
                  >{inventoryStatus.type} ({inventoryStatus.amount})</TagStyled>)}
                </EditCategoryBody>
              </EditCategoryContainer>
            </EditTagsBody>
          </Panel>
        </Collapse>
      </FilterGroupRow>

      <FilterTagsNew
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        hideClearTagsButton={true}
        parentType={"inventory"}
        entries={filteredInventory()}
        // @ts-ignore
        selectedCategories={[...selectedInventoryTypes, ...selectedInventoryStatuses]}
      />
    </Filters>
  </>);

  const isContentListEmpty = inventories.content.length === 0 || inventories.content.filter(inventory => !_.isNull(inventory.name)).length === 0
  const showIntroduction = introductionStatus !== "skipped" && introductionStatus !== "completed"
  const showEmptyStateIntroduction = introductionStatus !== "skipped"

  return (
    <PageWrapper>
      <ForwardInventoryDrawer
        isOpen={isForwardDrawerOpen}
        toggleDrawerOpen={toggleForwardDrawer}
        inventoryId={inventoryToForward}
      />
      <InventoryDrawer
        isOpen={isInventoryDrawerOpen}
        toggleDrawerOpen={toggleInventoryDrawer}
        width={null}
        // @ts-ignore
        setEditingInventoryData={setEditingInventoryData}
        inventory={editingInventoryData}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
      />
      <WorkDrawer
        isOpen={workDrawerOpen}
        toggleDrawerOpen={toggleWorkDrawer}
        work={editWorkData}
        setEditingWorkData={setEditWorkData}
        width={null}
      />
      <ViewWorkDrawer
        workId={workToViewId}
        setWorkId={setWorkToViewId}
        isOpen={isViewWorkDrawerOpen}
        toggleDrawer={toggleViewWorkDrawer}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setLinkedWorkId={() => {}}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setWorkIdToForward={() => {}}
        toggleForwardDrawer={() => {}}
        toggleTradeDrawer={() => {}}
        setEditSpaceId={setViewSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        setPresetLinkType={() => {}}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <LinkDrawer
        parentId={viewSpaceId}
        parentType={"cost"}
        isOpen={isLinkDrawerOpen}
        toggleDrawerOpen={toggleLinkDrawer}
        presetLinkType={presetLinkType}
        setPresetLinkType={setPresetLinkType}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        setEditSpaceId={setViewSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        toggleEventDrawer={() => {}}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <ViewSpaceDrawer
        spaceId={viewSpaceId}
        isOpen={isViewSpaceDrawerOpen}
        toggleDrawerOpen={toggleViewSpaceDrawer}
        toggleEditDrawerOpen={toggleViewSpaceDrawer}
        setEditSpaceId={setViewSpaceId}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setLinkedSpaceId={() => {}}
        setPresetLinkType={setPresetLinkType}
        toggleWorkDrawer={toggleWorkDrawer}
        setEditWorkData={setEditWorkData}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        toggleNoteDrawer={toggleNoteDrawer}
        setEditNoteData={setEditingNoteData}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <NoteDrawer
        currentType={"note"}
        data={editingNoteData}
        isOpen={isNoteDrawerOpen}
        toggleDrawerOpen={toggleNoteDrawer}
        refreshParent={() => dispatch(getAllSpaces(propertyId))}
        isGlobalNote={true}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        setPresetLinkType={setPresetLinkType}
        toggleLinkDrawerOpen={toggleLinkDrawer}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
        toggleExpenseDrawer={toggleExpenseDrawer}
        setEditExpenseData={setEditingExpenseData}
        toggleInventoryDrawer={toggleInventoryDrawer}
        setEditInventoryData={setEditingInventoryData}
        toggleContactDrawer={toggleContactDrawer}
        setEditContactData={setEditingContactData}
        setEditSpaceId={setViewSpaceId}
        toggleSpaceDrawer={toggleViewSpaceDrawer}
        setAttachmentId={setAttachmentToViewId}
        toggleViewFileDrawer={toggleViewFileDrawer}
      />
      <ViewFileDrawer
        attachmentId={attachmentToViewId}
        setAttachmentId={setAttachmentToViewId}
        isOpen={isViewFileDrawerOpen}
        toggleDrawer={toggleViewFileDrawer}
        viewFileDrawerDefaultTab={"preview"}
        attachmentsWithoutProperty={[]}
        duplicateAttachments={[]}
        selectedTags={[]}
        toggleEditTagsMode={() => {}}
        toggleEditDrawerOpen={() => {}}
        setPresetLinkType={() => {}}
        toggleWorkDrawer={() => {}}
        setEditWorkData={() => {}}
        toggleExpenseDrawer={() => {}}
        setEditExpenseData={() => {}}
        toggleInventoryDrawer={() => {}}
        setEditInventoryData={() => {}}
        toggleContactDrawer={() => {}}
        setEditContactData={() => {}}
        toggleNoteDrawer={() => {}}
        setEditNoteData={() => {}}
        toggleLinkDrawerOpen={() => {}}
        setEditSpaceId={() => {}}
        toggleSpaceDrawer={() => {}}
        refreshParent={() => {}}
        toggleTaskDrawer={() => {}}
        setEditTaskData={() => {}}
        toggleViewWorkDrawer={() => {}}
        setViewWorkId={() => {}}
      />
      <ExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        toggleDrawerOpen={toggleExpenseDrawer}
        setEditingExpenseData={setEditingExpenseData}
        expense={editingExpenseData}
      />
      <ContactDrawer
        isOpen={isContactDrawerOpen}
        toggleDrawerOpen={toggleContactDrawer}
        contact={editingContactData}
        // @ts-ignore
        setEditingContactData={setEditingContactData}
        refreshParent={() => dispatch(getAllSpaces(propertyId))}
        setViewWorkId={setWorkToViewId}
        toggleViewWorkDrawer={toggleViewWorkDrawer}
      />
      <PageHeader>
        <HeaderControlsWrapper>
          <div>
            <PageTitle>Contents</PageTitle>
            <PageSubtitle>Keep track of your house inventory</PageSubtitle>
          </div>
        </HeaderControlsWrapper>
        <HeaderControlsWrapper className={"mini"}>
          <ExportButtonStyled
            type={"primary"}
            size={"large"}
            icon={<DownloadExportIcon />}
            onClick={() => { exportInventory({propertyId}).then((response: any) =>
              downloadExport(response, "application/vnd.openxmlformats-officedocument.wordprocessingml.document"))}}
          >{window.innerWidth > 1024 && "Export to Word"}</ExportButtonStyled>
          <OnboardingButtonStyled onClick={() => history.push("/questions?openPage=contents&origin=contents")}>
            <FlashIcon style={{flexShrink: 0}} />
          </OnboardingButtonStyled>
          <AddButtonStyled
            icon={<PlusOutlined />}
            onClick={() => addNewInventory()}
          >{window.innerWidth > 1024 && f(messages.addInventoryBtn)}</AddButtonStyled>
        </HeaderControlsWrapper>
      </PageHeader>
      <PageInfoWrapper>
        <PageInfoColumn>
          <div className={"column-header"}>Assets value</div>
          <div className={"column-content"}>
            {getCurrencySign(propertyPreferences.preferredCurrency)}{numberWithCommas(inventoriesTotalCost)}
          </div>
        </PageInfoColumn>
        <PageInfoColumn>
          <div className={"column-header"}>Total items</div>
          <div className={"column-content"}>
            {amountOfInventories}
          </div>
        </PageInfoColumn>
        <PageInfoColumn>
          <div className={"column-header"}>Insured items</div>
          <div className={"column-content"}>
            {amountOfInsuredInventories}
          </div>
        </PageInfoColumn>
      </PageInfoWrapper>

      {(isContentListEmpty && showEmptyStateIntroduction || !isContentListEmpty && showIntroduction) && <InventoryIntroduction
        handholdingStatus={handholdingStatus}
        introductionStatus={introductionStatus}
      />}

      {isContentListEmpty && !showEmptyStateIntroduction && <EmptyStateWrapper className={"unwrapped"}>
          <EmptyContentsIcons />
          <span>Keep track of and manage your home's contents</span>
          <IntroductionButton onClick={() => history.push("/questions?openPage=contents&origin=contents")} size={"large"}>
              Let's start by selecting a few items
              <ChevronSmall />
            {handholdingStatus === "contents" && <HandholdingCircle style={{marginLeft: "0.5rem"}}>
                <HandholdingArrowRight/>
            </HandholdingCircle>}
          </IntroductionButton>
      </EmptyStateWrapper>}

      {!_.isEmpty(inventories.content.filter(inventory => !_.isNull(inventory.name))) &&
        !_.isEqual(inventories.content, inventoriesInitial) && (
          <InventoriesListWrapper>
            <InventoriesSearchWrapper>
              <SearchWrapperStyled>
                <Search
                  size={"large"}
                  value={search}
                  onChange={(val) => {
                    setSearch(val.target.value);
                  }}
                  placeholder={"Search contents..."}
                  suffix={<SearchIcon/>}
                />
                <ButtonFilters
                  size={"large"}
                  onClick={() => setShowTagFilters(!showTagFilters)}
                  icon={<div style={{position: "relative"}}>
                    {haveFiltersBeenUsed() && <DotIcon
                        style={{position: "absolute", top: "-0.25rem", left: "-0.3rem"}}/>}
                    <FilterIcon style={{marginRight: "0.5rem"}} />
                  </div>}
                >{showTagFilters ? "Hide" : "Show"} filters</ButtonFilters>
              </SearchWrapperStyled>
              {showTagFilters && displayFilters()}
            </InventoriesSearchWrapper>

            {window.innerWidth > 1024 && <InventoryListHeader>
              <InventoryListColumn style={{justifyContent: "center"}} spanWidth={"5%"}>Type</InventoryListColumn>
              <InventoryListColumn style={{justifyContent: "flex-start"}} spanWidth={"45%"}>Item</InventoryListColumn>
              <InventoryListColumn style={{justifyContent: "center"}} spanWidth={"15%"}>Related Upkeep</InventoryListColumn>
              <InventoryListColumn style={{justifyContent: "flex-end"}} spanWidth={"16%"}>Cost</InventoryListColumn>
              <InventoryListColumn style={{justifyContent: "flex-start"}} spanWidth={"15%"}>Life left</InventoryListColumn>
              <InventoryListColumn spanWidth={"5%"} />
            </InventoryListHeader>}
            <InventoriesListComp
              work={null}
              drawerTitle={null}
              inventories={filteredInventory()}
              refreshParent={() => {}}
              drawerToClose={() => {}}
              defaultOpenType={defaultOpenType}
              setDefaultOpenType={setDefaultOpenType}
              selectedTags={selectedTags}
              setInventoryToForward={setInventoryToForward}
              toggleForwardDrawer={toggleForwardDrawer}
              isFiltering={isFiltering}
              toggleInventoryDrawer={toggleInventoryDrawer}
              setEditInventoryData={setEditingInventoryData}
            />
            {!filteredInventory().length && <NoResultsSearch>No results matching your search query.</NoResultsSearch>}
          </InventoriesListWrapper>
      )}
    </PageWrapper>
  );
};

const InventoryListHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
  
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666F7A;
`

export const InventoryListColumn = styled.div`
  display: flex;
  padding: 0 0.5rem;
  width: ${(props: { spanWidth: string }) => props.spanWidth};
`

const InventoriesListWrapper = styled.div`
  background: white;
  border-radius: 0.5rem;
`;

const InventoriesSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SearchWrapperStyled = styled(SearchWrapper)`
  column-gap: 0.5rem;
  
  @media (max-width: 1024px) {
    padding: 0.5rem;
    margin: 0;
    flex-direction: column;
    row-gap: 0.5rem;
  }
`

const NoResultsSearch = styled.div`
  width: 100%;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
`

const ExportButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #2F80ED;
  background: white;
  height: 3rem;
  min-width: 7rem;
  box-shadow: 0!important;
  border: 1px solid #DAE0E6;
  border-radius: 6px;
  column-gap: 0.75rem;
  
  :hover, :active, :focus, :target, :visited {
    background: white;
    color: #40a9ff;
    border: 1px solid #DAE0E6;
  }
  
  svg path {
    stroke: #2F80ED!important;
  }
  
  :hover svg path, :active svg path, :focus svg path, :target svg path, :visited svg path {
    stroke: #40a9ff!important;
  }
  
  @media (max-width: 1024px) {
    min-width: 3rem;
    min-height: 3rem;
    border-radius: 6px!important;
  }
`

const OnboardingButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00B74F;
  height: 3rem;
  width: 3rem;
  box-shadow: 0!important;
  border: 1px solid #00B74F;
  border-radius: 6px;
  
  :hover, :active, :focus, :target, :visited {
    background: #67C18B;
    border: 1px solid #67C18B;
  }
`

export default Contents;
