import React from 'react'
import {List, Modal, Skeleton} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {ReactComponent as DeleteImage} from "../../../images/Delete.svg";
import {ReactComponent as GreenDot} from "../../../images/GreenDot.svg";
import {numberWithCommas} from "../helper";
import {
  ListItemStyled,
  PropertyListDesktop,
  PropertyPaymentDetails, SubTag, TagList, TagStyledBold,  TagStyledGreen, TagStyledRed
} from '../properties/PropertyDetailsComp';
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {getPropertyIdSelector, getPropertyPreferencesSelector} from "../../selectors";
import {emptyGuid, getCurrencySign} from "../../screens/helpers";
import {removeCertificate} from "../../../api-wrapper/certificates/removeCertificate";
import {getAllCertificates} from "../../actions/certificates";
import {getCertificateById} from "../../../api-wrapper/certificates/getCertificateById";
import {addCertificate} from "../../../api-wrapper/certificates/addCertificate";
import {
  AddRecordButton,
  ButtonOutline,
  EmptyRecordsDescription,
  EmptyRecordsHeader,
  EmptyRecordsList, SectionLabel
} from "../../screens/components";

type Props = {
  certificates: any;
  toggleCertificateDrawer: (arg: boolean) => void;
  setCertificateData: (arg: any) => void;
};

const CertificateListComp = (props: Props) => {
  const {certificates, toggleCertificateDrawer, setCertificateData} = props
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const isCertificateListEmpty = (certificate: any) => {
    if (certificate.length === 0) return true
    else if (certificate[0].propertyId === emptyGuid) return true
    else return false
  }

  function confirmDelete(item: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content:
        "You will not be able to restore it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() {
        removeCertificate(propertyId, item.certificateId).then(() => dispatch(getAllCertificates(propertyId)))
      }
    });
  }

  function addNewCertificate() {
    addCertificate({
      propertyId,
      certificateDate: null,
      endOfValidity: null,
      certificateType: "",
      amountPaid: null,
      certificateNumber: null
    }).then((res) => {
        getCertificateById(propertyId, res.data.certificateId).then((res: any) => {
        setCertificateData({data: res});
        toggleCertificateDrawer(true);
      })
    })
  }

  return (
    <>
      <SectionLabel>
        Certificates
        <ButtonOutline onClick={() => addNewCertificate()}>
          Add Certificate
        </ButtonOutline>
      </SectionLabel>
      {isCertificateListEmpty(certificates) && <EmptyRecordsList>
          <EmptyRecordsHeader>Add certificate</EmptyRecordsHeader>
          <EmptyRecordsDescription>
              Add certificate information about your property so you can easily keep track of your certificates.
          </EmptyRecordsDescription>
          <AddRecordButton
              onClick={() => addNewCertificate()}
              icon={<PlusOutlined />}
              size={"large"}
          >
              Add certificate
          </AddRecordButton>
      </EmptyRecordsList>
      }

      {!isCertificateListEmpty(certificates) && (
        <>
          <PropertyListDesktop>
            <List
              style={{ width: "100%" }}
              className="rounded-top-bottom-list"
              itemLayout="horizontal"
              dataSource={certificates} //.filter((c: any) => c.certificateType)
              renderItem={(item: any) => (
                <ListItemStyled
                  onClick={() => {
                    getCertificateById(propertyId, item.certificateId).then((res: any) => {
                      setCertificateData({data: res});
                      toggleCertificateDrawer(true);
                    })
                  }}
                  actions={[
                    <DeleteImage style={{ cursor: "pointer" }} onClick={(e) => {
                        e.stopPropagation();
                        confirmDelete(item)
                    }}/>
                  ]}
                >
                  <Skeleton loading={false} title={false} active>
                    <List.Item.Meta
                      title={<span style={{color: item.confirmed ? "#21272B" : "#C4C4C4"}}>
                          {item.certificateType ? item.certificateType : "No certificate type entered"}
                        </span>}
                      description={<span style={{color: item.confirmed ? "#6b7185" : "#C4C4C4"}}>
                        {item.certificateNumber ?
                          `Certificate number: ${item.certificateNumber}` : "No certificate number entered"}
                        <SubTag className={"display-mobile"}>
                          {item.certificateDate && item.endOfValidity && (<>
                            Period:{" "}
                            {moment(item.certificateDate).format(propertyPreferences.preferredDateFormat)} -{" "}
                            {moment(item.endOfValidity).format(propertyPreferences.preferredDateFormat)}
                          </>)}
                        </SubTag>
                      </span>}
                    />
                    <PropertyPaymentDetails>
                      <TagList>
                        {!item.confirmed && <TagStyledRed>
                          <GreenDot style={{marginTop: "0.5rem", marginRight: "0.5rem"}}/> Unconfirmed
                        </TagStyledRed>}
                        {moment().isBetween(item.certificateDate, item.endOfValidity) && (
                          <TagStyledGreen>
                            <GreenDot style={{marginTop: "0.5rem", marginRight: "0.5rem"}}/> CURRENT
                          </TagStyledGreen>)}
                        {item.amountPaid > 0 && <TagStyledBold>{getCurrencySign(propertyPreferences.preferredCurrency)} {numberWithCommas(item.amountPaid)}</TagStyledBold>}
                      </TagList>
                      <SubTag className={"display-desktop"}>
                        {item.certificateDate && item.endOfValidity && (
                          <span style={{color: item.confirmed ? "#6b7185" : "#C4C4C4"}}>
                            Period:{" "}
                            {moment(item.certificateDate).format(propertyPreferences.preferredDateFormat)} -{" "}
                            {moment(item.endOfValidity).format(propertyPreferences.preferredDateFormat)}
                          </span>
                        )}
                      </SubTag>
                    </PropertyPaymentDetails>
                  </Skeleton>
                </ListItemStyled>
              )}
            />
          </PropertyListDesktop>
        </>
      )}
    </>
  )
}

export default CertificateListComp