import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Button, Dropdown, List, Modal, Tag} from "antd";
import {useDispatch, useSelector} from "react-redux";

import {
  getImprovementsSelector,
  getPropertiesSelector,
  getPropertyIdSelector,
  getPropertyPreferencesSelector,
} from "../../selectors";
import _ from "lodash";
import {TFetchingStatus} from "../../../helpers";
import {PlusOutlined} from "@ant-design/icons";

import {ReactComponent as EPCIconBlue} from "../../../images/EPCIconBlue.svg";
import {numberWithCommasFull, propertyYearReversedConversion} from "../helper";
import {ReactComponent as DeleteImage} from "../../../images/Delete.svg";
import {getAllProperties} from "../../actions/properties";
import {setPropertyId} from "../../actions/propertyId";
import {emptyGuid, getCurrencyName, getCurrencySign, isUKProperty, months} from "../../screens/helpers";
import {deleteProperty} from "../../../api-wrapper/properties/deleteProperty";
import {history} from "../../../configureStore";
import {
  AddRecordButton, ButtonOutline,
  EmptyRecordsDescription,
  EmptyRecordsHeader,
  EmptyRecordsList,
  SectionLabel
} from "../../screens/components";

type Props = {
  property: any;
  togglePropertyDetailsDrawer: (arg: boolean) => void;
  togglePropertyPreferencesDrawer: (arg: boolean) => void;
};

const PropertyDetailsComp = (props: Props) => {
  const {property, togglePropertyDetailsDrawer, togglePropertyPreferencesDrawer} = props;

  const dispatch = useDispatch();
  const properties = useSelector(getPropertiesSelector);
  const improvementsConfiguration = useSelector(getImprovementsSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const propertyPreferences = useSelector(getPropertyPreferencesSelector);

  const [detailsToDisplay, setDetailsToDisplay] = useState<any>([]);
  const [questions, setQuestions] = useState<Array<any>>([]);
  const [propertyDetails, setPropertyDetails] = useState<Array<any>>([]);

  const purchasedValue = improvementsConfiguration.content.propertyHighlights.lastSoldPrice
  const currentValue = improvementsConfiguration.content.propertyHighlights.propertyValue

  const isAnswerNull = (group: any) => {
    if (group.id === 18) return !_.isNil(purchasedValue)
    if (group.id === 19) return !_.isNil(currentValue)
    else return (!_.isNil(group.answer) && group.answer !== "")
  }

  useEffect(() => {
    if (improvementsConfiguration.fetchingStatus !== TFetchingStatus.Success)
      return;
    setQuestions([...improvementsConfiguration.content.questions]);
    setPropertyDetails([...improvementsConfiguration.content.propertyDetails]);

    let listOfGroups = new Array<any>();

    {[...Array(10)].map((x,groupOrder) => {
      if (improvementsConfiguration.content.propertyDetails.filter(group => group.groupOrder === groupOrder).length < 1)
        return;

      if (improvementsConfiguration.content.propertyDetails.filter(group => group.groupOrder === groupOrder &&
        (!_.isNil(group.answerId) || !_.isNil(group.answer) || isAnswerNull(group))).length > 0)
        listOfGroups.push({
          groupName: improvementsConfiguration.content.propertyDetails.filter(group => group.groupOrder === groupOrder)[0].groupName,
          groupOrder: improvementsConfiguration.content.propertyDetails.filter(group => group.groupOrder === groupOrder)[0].groupOrder,
          items: _.chunk(improvementsConfiguration.content.propertyDetails.filter(group => group.groupOrder === groupOrder
              && (!_.isNil(group.answerId) || !_.isNil(group.answer) || isAnswerNull(group))
          ), 3),
        });
    })}

    listOfGroups.sort((group1, group2) => group1.groupOrder - group2.groupOrder);
    setDetailsToDisplay(listOfGroups);
  }, [improvementsConfiguration]);

  function confirmDelete() {
    Modal.confirm({
      title: "Are you sure you want to delete your property?",
      content:
        "You will not be able to restore it or any contents & expenses associated with it after deletion.",
      okText: "OK",
      cancelText: "Cancel",
      className: "form-confirm-close-modal",
      onOk() { deleteProperty(propertyId).then(() => {dispatch(setPropertyId(emptyGuid)); dispatch(getAllProperties()); history.push("/")}) }
    });
  }

  const renderQuestion = (item: any) =>
    <DetailsFieldWrapper>
      <Label>{item.label}</Label>
      <PropertyData>
        {(item.id === 18 || item.id === 19 || item.id === 25 || item.id === 26) && `${getCurrencySign(propertyPreferences.preferredCurrency)} `}
        {item.id === 18 ? propertyDetails.filter(detail => detail.id === item.id)[0].answer ?
          numberWithCommasFull(propertyDetails.filter(detail => detail.id === item.id)[0].answer)
          : numberWithCommasFull(purchasedValue) : null}
        {item.id === 19 ? propertyDetails.filter(detail => detail.id === item.id)[0].answer ?
          numberWithCommasFull(propertyDetails.filter(detail => detail.id === item.id)[0].answer)
          : numberWithCommasFull(parseInt(currentValue)) : null}
        {item.id === 2 && Math.round(propertyDetails.filter(detail => detail.id === item.id)[0].answer)}
        {item.id === 3 && propertyYearReversedConversion(propertyDetails.filter(detail => detail.id === item.id)[0].answer)}
        {(item.id !== 18 && item.id !== 19 && item.id !== 2 && item.id !== 3) &&
          propertyDetails.filter(detail => detail.id === item.id)[0].answer}
        {(!_.isNil(propertyDetails.filter(detail => detail.id === item.id)[0].answerId) &&
          questions.filter(question => item.questionId === question.id).length > 0) &&
        questions.filter(question => item.questionId === question.id)[0].answers.filter((answer:any) =>
          answer.id === propertyDetails.filter(detail => detail.id === item.id)[0].answerId)[0].answer}
        {item.dataSource === "EPC" && <Dropdown
            overlay={<div style={{background: "#EBF9FF", padding: "0.25rem 0.5rem", borderRadius: "0.5rem"}}>
              Data sourced from EPC</div>} placement="topCenter">
            <EPCIconBlue style={{marginLeft: "0.5rem", marginBottom: "-0.25rem"}} />
        </Dropdown>}
      </PropertyData>
    </DetailsFieldWrapper>

  return (<>
      <SectionLabel>
        Property Details
        <ButtonOutline onClick={() => togglePropertyDetailsDrawer(true)}>
          Edit
        </ButtonOutline>
      </SectionLabel>

      <PropertyDetailsWrapper>
        {detailsToDisplay.length < 1 &&
        <EmptyRecordsList>
            <EmptyRecordsHeader>Manage your property details</EmptyRecordsHeader>
            <EmptyRecordsDescription>
              Add details about your property and we'll use it to rate your property, its potential
                and provide you with suggested improvements and maintenance tasks.
            </EmptyRecordsDescription>
            <AddRecordButton
              onClick={() => togglePropertyDetailsDrawer(true)}
              icon={<PlusOutlined />}
              size={"large"}
            >Add details</AddRecordButton>
        </EmptyRecordsList>
        }
        {detailsToDisplay.map((group: any, index: number) =>
          <PropertyDetailsGroupWrapper>
            <PropertyDetailsGroupTitle style={{paddingTop: index === 0 ? '1rem' : '0'}}>
              <CategoryNumber>
                {index+1}
              </CategoryNumber>
              <span style={{marginLeft: "1rem"}}>{group.groupName}</span>
            </PropertyDetailsGroupTitle>
            <PropertyDetailsGroup style={{alignItems: "stretch"}}>

              {group.items.map((itemPair:any) =>
                <RowWrapper>
                  {renderQuestion(itemPair[0])}
                  {itemPair.length > 1 && renderQuestion(itemPair[1])}
                  {itemPair.length > 2 && renderQuestion(itemPair[2])}
                </RowWrapper>
              )}

            </PropertyDetailsGroup>
          </PropertyDetailsGroupWrapper>
        )}
      </PropertyDetailsWrapper>

      {!isUKProperty(property.country, property.zip) && <SectionLabel>
        Property Preferences
        <ButtonOutline onClick={() => togglePropertyPreferencesDrawer(true)}>
          Edit
        </ButtonOutline>
      </SectionLabel>}
      {!isUKProperty(property.country, property.zip) && <PropertyDetailsWrapper>
        <PropertyDetailsGroup>
          <RowWrapper>
            <DetailsFieldWrapper>
              <Label>Property currency</Label>
              <PropertyData>
                {getCurrencyName(propertyPreferences.preferredCurrency)} ({getCurrencySign(propertyPreferences.preferredCurrency)})
              </PropertyData>
            </DetailsFieldWrapper>
            <DetailsFieldWrapper>
              <Label>Property date format</Label>
              <PropertyData>
                {propertyPreferences.preferredDateFormat}
              </PropertyData>
            </DetailsFieldWrapper>
            <DetailsFieldWrapper>
                <Label>Tax year end date </Label>
                <PropertyData>
                  {propertyPreferences.taxYearMonth && months.filter(m => m.number === propertyPreferences.taxYearMonth)[0].name}
                  {" "}
                  {propertyPreferences.taxYearDate}
                </PropertyData>
            </DetailsFieldWrapper>
          </RowWrapper>
        </PropertyDetailsGroup>
      </PropertyDetailsWrapper>}

      {properties.length > 1 &&
      <div style={{marginTop:"2rem", display:"flex", justifyContent:"space-between", justifyItems: "center"}}>
        <span style={{fontWeight:600, fontSize:"1.125rem"}}>Remove additional property</span>
        <DeleteButtonStyled
          type={"primary"}
          danger
          icon={<DeleteImage/>}
          onClick={() => confirmDelete()}
        >
          Delete property
        </DeleteButtonStyled>
      </div>}
    </>);
};

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 35%;
  
  @media (max-width: 575px) {
    width: 100%;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-right: 1rem;
  
  @media (max-width: 575px) {
    width: 100%;
  }
`;

export const DetailsFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;
  margin-right: 1rem;
  
  @media (max-width: 575px) {
    width: 100%;
  }
`;

export const PropertyData = styled.span`
  font-weight: 400;
  color: #6b7185;
  font-size: 1rem;
  padding-bottom: 2rem;
  font-style: normal;
  font-family: Inter;
`;

export const Label = styled.p`
  color: #21272b;
  // padding-bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  font-family: Inter;
`;

export const PropertyLocationData = styled.div`
  min-height: 204px;
  display: flex;
  padding: 1rem;
  padding-bottom: 0;
  
  @media (max-width: 575px) {
    flex-direction: column;
  }
`;

export const PropertyLocationWrapper = styled.section`
  background: #ffffff;
  min-height: 250px;
  padding: 20px 20px 18px 26px;
  border-radius: 10px;
`;

export const ListItemStyled = styled(List.Item)`
  @media (max-width: 1024px) {
    width: 100%;

    .ant-list-item-action > li {
      width: 100%;
    }
  }
  
  @media (min-width: 1024px) {
    padding: 0.5rem 2rem;
    min-height: 5.5rem;
  }

  .ant-list-item-meta-title {
    font-size: 1rem;
    font-weight: 600;
    color: #21272b;
  }
  
  .ant-list-item-meta-description {
    color: #6b7185;
    font-weight: 400;
    margin-top: 0.4rem;
  }
`;

export const PropertyList = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0.625rem;

  @media (max-width: 1024px) {
    padding: 1rem 2rem;
  }
`;

export const PropertyHeader = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    margin: 0;
  }
`;

export const PropertyListDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 0.625rem;

  @media (max-width: 1024px) {
    border: 1px solid #dae0e6;
    padding: 1rem 1rem;
    margin: 0;
  }
`;

export const EmptyPropertyList = styled(PropertyList)`
  @media (min-width: 1024px) {
    padding: 5vh 20vw;
  }
  
`;

export const PropertyDetailsSection = styled.section`
  align-content: center;
  align-items: center;
    
  @media (min-width: 1024px) {
    margin-bottom: 1em;
  }
  
  @media (max-width: 1024px) {
    margin: 0;
  };
`;

export const TagList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  
  @media (max-width: 875px) {
    flex-direction: column;
    margin-left: 0.5rem;
  } ;
`;

export const TagStyled = styled(Tag)`
  background-color: #eff1f5;
  color: #6b7185;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 1rem;
  margin-right: 0;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 0;
  font-size: 0.75rem;
  font-weight: 500;
  @media (max-width: 1024px) {
    //display: none;
  }
`;

export const TagStyledBold = styled(TagStyled)`
  font-weight: 700;
  color: #21272b;
  font-size: 1rem;
  
  @media (max-width: 875px) {
    margin: 0.25rem 0;
  };
`;

export const TagStyledBoldRed = styled(TagStyledBold)`
  color: #eb5757;
  
  @media (max-width: 875px) {
    margin: 0.25rem 0;
  };
`;

export const TagStyledRed = styled(TagStyledBold)`
  background-color: #fad7d2;
  color: #ef624e;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
  
  svg circle { fill: #ef624e; }
  
  @media (max-width: 875px) {
    margin: 0.25rem 0;
  };
`;

export const TagStyledGreen = styled(TagStyled)`
  background-color: #effdf7;
  
  @media (max-width: 875px) {
    margin: 0.25rem 0;
  };
`;

export const PropertyPaymentDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SubTag = styled.span`
  color: #6b7185;
  font-weight: 400;
  margin-top: 0.6rem;
`;

export const RowWrapper = styled.div`
  display: flex;
  @media (min-width: 575px) {
    flex-direction: row;
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
`;

export const PropertyDetailsGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  //background-color: #F0F2F5;
  border-radius: 6px;
`;

export const PropertyDetailsGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const PropertyDetailsGroupTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  color: #2A80E1;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #DAE0E6;
`;

export const PropertyDetailsWrapper = styled.section`
  background: #ffffff;
  padding: 20px 20px 18px 26px;
  border-radius: 10px;
`;

export const EmptyDetailsList = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 1024px) {
    padding: 5vh 20vw;
    max-width: 1100px;
  }
`;

export const PropertyDetailsHeaderSection = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const CategoryNumber = styled.div`
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 100%;
  background: #2A80E1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
`

const DeleteButtonStyled = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 1rem!important;

  border-radius: 6px;
  font-weight: 600;
  background-color: #2a80e1;
  border-color: #2a80e1;
  
  svg {
    margin-right: 0.5rem
  }
  svg path{
    stroke: #fff;
  }
`;


export default PropertyDetailsComp;
