import React from "react";
import {ReactComponent as CostRatedNeg} from "../../../images/guidance/CostRatedNeg.svg";
import {ReactComponent as CostRated0} from "../../../images/guidance/CostRated0.svg";
import {ReactComponent as CostRated1} from "../../../images/guidance/CostRated1.svg";
import {ReactComponent as CostRated2} from "../../../images/guidance/CostRated2.svg";
import {ReactComponent as CostRated3} from "../../../images/guidance/CostRated3.svg";
import {ReactComponent as EcoRatedNeg} from "../../../images/guidance/EcoRatedNeg.svg";
import {ReactComponent as EcoRated0} from "../../../images/guidance/EcoRated0.svg";
import {ReactComponent as EcoRated1} from "../../../images/guidance/EcoRated1.svg";
import {ReactComponent as EcoRated2} from "../../../images/guidance/EcoRated2.svg";
import {ReactComponent as EcoRated3} from "../../../images/guidance/EcoRated3.svg";
import {ReactComponent as EffortRatedNeg} from "../../../images/guidance/EffortRatedNeg.svg";
import {ReactComponent as EffortRated0} from "../../../images/guidance/EffortRated0.svg";
import {ReactComponent as EffortRated1} from "../../../images/guidance/EffortRated1.svg";
import {ReactComponent as EffortRated2} from "../../../images/guidance/EffortRated2.svg";
import {ReactComponent as EffortRated3} from "../../../images/guidance/EffortRated3.svg";
import {ReactComponent as SafetyRatedNeg} from "../../../images/guidance/SafetyRatedNeg.svg";
import {ReactComponent as SafetyRated0} from "../../../images/guidance/SafetyRated0.svg";
import {ReactComponent as SafetyRated1} from "../../../images/guidance/SafetyRated1.svg";
import {ReactComponent as SafetyRated2} from "../../../images/guidance/SafetyRated2.svg";
import {ReactComponent as SafetyRated3} from "../../../images/guidance/SafetyRated3.svg";
import {ReactComponent as SavingsRatedNeg} from "../../../images/guidance/SavingsRatedNeg.svg";
import {ReactComponent as SavingsRated0} from "../../../images/guidance/SavingsRated0.svg";
import {ReactComponent as SavingsRated1} from "../../../images/guidance/SavingsRated1.svg";
import {ReactComponent as SavingsRated2} from "../../../images/guidance/SavingsRated2.svg";
import {ReactComponent as SavingsRated3} from "../../../images/guidance/SavingsRated3.svg";
import {ReactComponent as WellRatedNeg} from "../../../images/guidance/WellRatedNeg.svg";
import {ReactComponent as WellRated0} from "../../../images/guidance/WellRated0.svg";
import {ReactComponent as WellRated1} from "../../../images/guidance/WellRated1.svg";
import {ReactComponent as WellRated2} from "../../../images/guidance/WellRated2.svg";
import {ReactComponent as WellRated3} from "../../../images/guidance/WellRated3.svg";
import { Dropdown } from "antd";
import { WishlistOverlay } from "./GuidanceListItem";

type Props = {
  item: any;
}

export const getRatingIcon = (rating: any) => {
  if (rating.type === "savingsRating") {
    switch (Math.floor(rating.value)) {
      case -1:
        return <Dropdown overlay={<WishlistOverlay>Negative Savings Possible</WishlistOverlay>} placement="topCenter">
          <SavingsRatedNeg />
        </Dropdown>
      case 0:
        return <Dropdown overlay={<WishlistOverlay>No Savings Possible</WishlistOverlay>} placement="topCenter">
          <SavingsRated0 />
        </Dropdown>
      case 1:
        return <Dropdown overlay={<WishlistOverlay>Low Savings Possible</WishlistOverlay>} placement="topCenter">
          <SavingsRated1 />
        </Dropdown>
      case 2:
        return <Dropdown overlay={<WishlistOverlay>Medium Savings Possible</WishlistOverlay>} placement="topCenter">
          <SavingsRated2 />
        </Dropdown>
      case 3:
        return <Dropdown overlay={<WishlistOverlay>High Savings Possible</WishlistOverlay>} placement="topCenter">
          <SavingsRated3 />
        </Dropdown>
    }
  }
  if (rating.type === "costRating") {
    switch (Math.floor(rating.value)) {
      case -1:
        return <Dropdown overlay={<WishlistOverlay>Negative Costs Requirement</WishlistOverlay>} placement="topCenter">
          <CostRatedNeg />
        </Dropdown>
      case 0:
        return <Dropdown overlay={<WishlistOverlay>No Costs Requirement</WishlistOverlay>} placement="topCenter">
          <CostRated0 />
        </Dropdown>
      case 1:
        return <Dropdown overlay={<WishlistOverlay>Low Costs Requirement</WishlistOverlay>} placement="topCenter">
          <CostRated1 />
        </Dropdown>
      case 2:
        return <Dropdown overlay={<WishlistOverlay>Medium Costs Requirement</WishlistOverlay>} placement="topCenter">
          <CostRated2 />
        </Dropdown>
      case 3:
        return <Dropdown overlay={<WishlistOverlay>High Costs Requirement</WishlistOverlay>} placement="topCenter">
          <CostRated3 />
        </Dropdown>
    }
  }
  if (rating.type === "ecoImpactRating") {
    switch (Math.floor(rating.value)) {
      case -1:
        return <Dropdown overlay={<WishlistOverlay>Negative Sustainability Impact</WishlistOverlay>} placement="topCenter">
          <EcoRatedNeg />
        </Dropdown>
      case 0:
        return <Dropdown overlay={<WishlistOverlay>No Sustainability Impact</WishlistOverlay>} placement="topCenter">
          <EcoRated0 />
        </Dropdown>
      case 1:
        return <Dropdown overlay={<WishlistOverlay>Low Sustainability Impact</WishlistOverlay>} placement="topCenter">
          <EcoRated1 />
        </Dropdown>
      case 2:
        return <Dropdown overlay={<WishlistOverlay>Medium Sustainability Impact</WishlistOverlay>} placement="topCenter">
          <EcoRated2 />
        </Dropdown>
      case 3:
        return <Dropdown overlay={<WishlistOverlay>High Sustainability Impact</WishlistOverlay>} placement="topCenter">
          <EcoRated3 />
        </Dropdown>
    }
  }
  if (rating.type === "effortRating") {
    switch (Math.floor(rating.value)) {
      case -1:
        return <Dropdown overlay={<WishlistOverlay>Negative Effort Requirement</WishlistOverlay>} placement="topCenter">
          <EffortRatedNeg />
        </Dropdown>
      case 0:
        return <Dropdown overlay={<WishlistOverlay>No Effort Requirement</WishlistOverlay>} placement="topCenter">
          <EffortRated0 />
        </Dropdown>
      case 1:
        return <Dropdown overlay={<WishlistOverlay>Low Effort Requirement</WishlistOverlay>} placement="topCenter">
          <EffortRated1 />
        </Dropdown>
      case 2:
        return <Dropdown overlay={<WishlistOverlay>Medium Effort Requirement</WishlistOverlay>} placement="topCenter">
          <EffortRated2 />
        </Dropdown>
      case 3:
        return <Dropdown overlay={<WishlistOverlay>High Effort Requirement</WishlistOverlay>} placement="topCenter">
          <EffortRated3 />
        </Dropdown>
    }
  }
  if (rating.type === "safetyRating") {
    switch (Math.floor(rating.value)) {
      case -1:
        return <Dropdown overlay={<WishlistOverlay>Negative Safety Impact</WishlistOverlay>} placement="topCenter">
          <SafetyRatedNeg />
        </Dropdown>
      case 0:
        return <Dropdown overlay={<WishlistOverlay>No Safety Impact</WishlistOverlay>} placement="topCenter">
          <SafetyRated0 />
        </Dropdown>
      case 1:
        return <Dropdown overlay={<WishlistOverlay>Low Safety Impact</WishlistOverlay>} placement="topCenter">
          <SafetyRated1 />
        </Dropdown>
      case 2:
        return <Dropdown overlay={<WishlistOverlay>Medium Safety Impact</WishlistOverlay>} placement="topCenter">
          <SafetyRated2 />
        </Dropdown>
      case 3:
        return <Dropdown overlay={<WishlistOverlay>High Safety Impact</WishlistOverlay>} placement="topCenter">
          <SafetyRated3 />
        </Dropdown>
    }
  }
  if (rating.type === "wellnessRating") {
    switch (Math.floor(rating.value)) {
      case -1:
        return <Dropdown overlay={<WishlistOverlay>Negative Health & Comfort Impact</WishlistOverlay>} placement="topCenter">
          <WellRatedNeg />
        </Dropdown>
      case 0:
        return <Dropdown overlay={<WishlistOverlay>No Health & Comfort Impact</WishlistOverlay>} placement="topCenter">
          <WellRated0 />
        </Dropdown>
      case 1:
        return <Dropdown overlay={<WishlistOverlay>Low Health & Comfort Impact</WishlistOverlay>} placement="topCenter">
          <WellRated1 />
        </Dropdown>
      case 2:
        return <Dropdown overlay={<WishlistOverlay>Medium Health & Comfort Impact</WishlistOverlay>} placement="topCenter">
          <WellRated2 />
        </Dropdown>
      case 3:
        return <Dropdown overlay={<WishlistOverlay>High Health & Comfort Impact</WishlistOverlay>} placement="topCenter">
          <WellRated3 />
        </Dropdown>
    }
  }
}

const GuidanceRating = (props: Props) => {
  const {item} = props;

  const getTopThreeRatings = () => {
    let itemRatings = [
      {key: "costRating", type: "costRating", value: item.guidanceCardCost + 0.6},
      {key: "effortRating", type: "effortRating", value: item.guidanceCardEffort + 0.5},
      {key: "ecoImpactRating", type: "ecoImpactRating", value: item.guidanceCardSustainability + 0.4},
      {key: "savingsRating", type: "savingsRating", value: item.guidanceCardSavings + 0.3},
      {key: "safetyRating", type: "safetyRating", value: item.guidanceCardSafety + 0.2},
      {key: "wellnessRating", type: "wellnessRating", value: item.guidanceCardHealthComfort + 0.1}
    ]
    return itemRatings.sort((i1, i2) => i2.value - i1.value).slice(0, 3)
  }

  return <>{getTopThreeRatings().map((rating) => getRatingIcon(rating))}</>
}

export default GuidanceRating