import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FilterGroupRowNotes} from "../../screens/styled";
import styled from "styled-components";
import {Button, Collapse, Tag} from "antd";
import {getPropertyIdSelector, getTagsWithUsageCountByTypeSelector} from "../../selectors";
import {ReactComponent as AddTagsIcon} from "../../../images/AddTags.svg";
import NewTagsModal from "./NewTagsModal";
import {getAllTagsWithUsageCountByType} from "../../actions/tagsWithUsageCountByType";
import _ from "lodash";
import {emptyGuid} from "../../screens/helpers";
import {ReactComponent as CollapseIconPlus} from "../../../images/CollapseIconPlus.svg";
import {ReactComponent as CollapseIconMinus} from "../../../images/CollapseIconMinus.svg";
const { Panel } = Collapse;

type Props = {
  selectedTags: any;
  setSelectedTags: (arg: any) => void;
  hideClearTagsButton?: boolean;
  parentType: string;
  workType?: string;
  selectedCategories?: any;
  entries?: any;
  disableCollapse?: boolean;
}

const FilterTagsNew = (props: Props) => {
  const {selectedTags, setSelectedTags, hideClearTagsButton, parentType, workType, selectedCategories, entries, disableCollapse} = props;
  const tags = useSelector(getTagsWithUsageCountByTypeSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;

  const dispatch = useDispatch();
  const [selectedTagIds, setSelectedTagIds] = useState<any>([]);
  const [isNewTagModalOpen, toggleNewTagModal] = useState(false);
  const [tagUsageCount, setTagUsageCount] = useState<Map<string, number>>(new Map());
  const [activeKeys, setActiveKeys] = useState<any>(["tags"]);

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    dispatch(getAllTagsWithUsageCountByType(propertyId, parentType))
  }, [propertyId]);

  useEffect(() => {
    let selectedIds: string[] = []
    selectedTags.map((tag: any) => selectedIds.push(tag.tagId))
    setSelectedTagIds(selectedIds)
  }, [selectedTags])

  useEffect(() => {
    if (entries) {
      const entryTags: any = []
      entries.map((entry: any) => entryTags.push(...entry.tags))
      let entryTagsMap = new Map();
      entryTags.forEach((tag: any) => {
        if (entryTagsMap.has(tag.name)) {
          entryTagsMap.set(tag.name, entryTagsMap.get(tag.name)+1);
        } else {
          entryTagsMap.set(tag.name, 1);
        }
      })
      setTagUsageCount(entryTagsMap)
    }
  }, [entries])

  function getTagCount(tag: any) {
    if (selectedCategories && (selectedCategories.size > 0 || selectedCategories.length > 0)) {
      return tagUsageCount.get(tag.name) ?? 0
    } else {
      return tag.usageCount
    }
  }

  function isTagSelected(selectedTags: any, tag: any) {
    return selectedTagIds.includes(tag.tagId)
  }

  function getClassesForTag(tag: any, tagType: string) {
    if (isTagSelected(selectedTags, tag) && tagType === "system") return "tag-blue"
    if (isTagSelected(selectedTags, tag) && tagType === "custom") return "tag-royalblue"
    if (getTagCount(tag) === 0) return "tag-gray"
  }

  function showTag(tag: any) {
    if (parentType === "work") {
      return tag.category === workType
    } else { return true }
  }

  return (<>
    <NewTagsModal
      isNewTagModalOpen={isNewTagModalOpen}
      toggleNewTagModal={toggleNewTagModal}
    />
    <FilterGroupRowNotes style={{alignItems: "center"}}>
      <EditTagsContainer>
        <Collapse collapsible={disableCollapse ? "disabled" : "header"}
                  bordered={false} ghost onChange={(key) => setActiveKeys(key)} defaultActiveKey={activeKeys}>
          <Panel showArrow={false} header={<SubcategoryHeader>
            Tags
            <div className={"button-wrapper"}>
              {!disableCollapse && (activeKeys.includes("tags") ? <CollapseIconMinus /> : <CollapseIconPlus />)}
              {!hideClearTagsButton && <CancelButton onClick={() => setSelectedTags([])}>Clear tags</CancelButton>}
            </div>
          </SubcategoryHeader>} key="tags">
            <EditTagsBody>
              <EditCategoryContainer>
                <EditCategoryHeader>System tags</EditCategoryHeader>
                <EditCategoryBody>
                  {tags.content.filter((tag: any) => !tag.subscriptionId && showTag(tag)).map((tag: any) => <TagStyled
                    key={tag.tagId}
                    className={getClassesForTag(tag, "system")}
                    onClick={() =>
                      isTagSelected(selectedTags, tag) ? setSelectedTags(selectedTags.filter((selectedTag: any) =>
                        selectedTag.tagId !== tag.tagId))
                        : setSelectedTags([...selectedTags, tag])}
                  >{tag.name} ({getTagCount(tag)})</TagStyled>)}
                  {tags.content.filter((tag: any) => !tag.subscriptionId && showTag(tag)).length === 0 &&
                  "You haven't used any of the system tags in this section."}
                </EditCategoryBody>
                <EditCategoryHeader className={"lower-category"}>
                  Custom tags
                  <InlineButtonDesktop onClick={() => toggleNewTagModal(true)}>
                    <AddTagsIcon /> Add tags
                  </InlineButtonDesktop>
                </EditCategoryHeader>
                <EditCategoryBody>
                  {tags.content.filter((tag: any) => tag.subscriptionId && showTag(tag)).map((tag: any) => <TagStyled
                    key={tag.tagId}
                    className={getClassesForTag(tag, "custom")}
                    onClick={() =>
                      isTagSelected(selectedTags, tag) ? setSelectedTags(selectedTags.filter((selectedTag: any) =>
                        selectedTag.tagId !== tag.tagId))
                        : setSelectedTags([...selectedTags, tag])}
                  >{tag.name} ({getTagCount(tag)})</TagStyled>)}
                  {tags.content.filter((tag: any) => tag.subscriptionId && showTag(tag)).length === 0 &&
                  "You haven't used any of the custom tags in this section."}
                </EditCategoryBody>
                <AddTagsMobile onClick={() => toggleNewTagModal(true)}>
                  <AddTagsIcon />
                  <InlineButtonMobile>Add tags</InlineButtonMobile>
                </AddTagsMobile>
              </EditCategoryContainer>
            </EditTagsBody>
          </Panel>
        </Collapse>
      </EditTagsContainer>
    </FilterGroupRowNotes>
    </>
  )
}

const EditTagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #EBF9FF;
  row-gap: 1rem;
  width: 100%!important;
  
  .ant-collapse-header { padding: 0!important; }
`

export const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.75rem;
  color: #21272B;
`

export const SubcategoryHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #21272B;
  
  .button-wrapper {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  @media (max-width: 1024px) {
    padding-bottom: 1rem;
  }
`

export const EditTagsBody = styled.div`
  display: flex;
  width: 100%;
  column-gap: 1rem;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 1rem;
  }
`

export const EditCategoryContainer = styled.div`
  width: 100%;
  border: 1px solid #dae0e6;
  background: #EBF9FF;
  padding: 0.75rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  
  @media (max-width: 1024px) {
    border: 0;
    padding: 0;
  }
`

export const EditCategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #21272B;
  font-weight: 600;
`

export const EditCategoryBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.625rem;
`

export const TagStyled = styled(Tag)`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  background: white;
  padding: 0.188rem 0.5rem;
  border-radius: 4px;
  font-weight: 600;
  line-height: 1.125rem;
  color: #666F7A;
  border: none;
  cursor: pointer;
  height: 1.625rem;
`

export const CancelButton = styled(Button)`
  border: 1px solid #DAE0E6;
  color: #21272B;
  background: #EBF9FF;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  border-radius: 6px;
  height: 2.625rem;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  text-align: center;
  
  @media (max-width: 1024px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

export const ApplyButton = styled(Button)`
  border: 1px solid #2A80E1;
  color: white;
  background: #2A80E1;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  border-radius: 6px;
  height: 2.625rem;
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  text-align: center;
`

const InlineButtonDesktop = styled.span`
  display: flex;
  align-items: center;
  font-weight: 500;
  column-gap: 0.25rem;
  cursor: pointer;
  color: #236EC2;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1rem;
  height: 1.125rem;
  
  @media (max-width: 1024px) {
    display: none;
  }
`

const InlineButtonMobile = styled.span`
  @media (min-width: 1024px) {
    display: none;
  }
  
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    font-weight: 500;
    column-gap: 0.25rem;
    cursor: pointer;
    color: #236EC2;
    padding-bottom: 0.25rem;
    font-size: 0.875rem;
    line-height: 1rem;
    border-bottom: 2px dotted #236EC2;
  }
`

const AddTagsMobile = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
  
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 0.5rem;
    column-gap: 0.375rem;
  }
`

export default FilterTagsNew