import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {ReactComponent as FiltersIcon} from "../../images/spaces/FiltersIcon.svg";
import {ReactComponent as FiltersIconActive} from "../../images/spaces/FiltersIconActive.svg";
import {ReactComponent as SearchIcon} from "../../images/spaces/SearchIcon.svg";
import {ReactComponent as SortIcon} from "../../images/spaces/SortIcon.svg";

import SpaceCard from "../components/spaces/SpaceCard";
import EditSpaceDrawer from "../components/spaces/EditSpaceDrawer";
import {PlusOutlined} from "@ant-design/icons";
import ViewSpaceDrawer from "../components/spaces/ViewSpaceDrawer";
import LinkDrawer from "../components/spaces/LinkDrawer";
import WorkDrawer from "../components/works/WorkDrawer";
import ExpenseDrawer from "../components/expenses/ExpenseDrawer";
import InventoryDrawer from "../components/inventories/InventoryDrawer";
import ContactDrawer from "../components/contacts/ContactDrawer";
import NoteDrawer from "../components/notes/NoteDrawer";
import {MenuStyled} from "../components/works/WorksListComp";
import {Collapse, Dropdown, Menu, Tooltip} from "antd";
import {FilterGroupRow, Filters} from "./styled";
import {
  CancelButton,
  CategoryHeader,
  EditCategoryBody,
  EditCategoryContainer,
  EditCategoryHeader,
  EditTagsBody,
  SubcategoryHeader,
  TagStyled
} from "../components/tags/FilterTagsNew";
// @ts-ignore
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector, getSpacesSelector, getUserProfileSelector} from "../selectors";
import {getAllSpaces} from "../actions/spaces";
import {getAllProperties} from "../actions/properties";
import {getAllMaintenances} from "../actions/maintenances";
import {getAllExpenses} from "../actions/expenses";
import {getAllWorks} from "../actions/works";
import {getAllInventories} from "../actions/inventories";
import {getAllAttachments} from "../actions/attachments";
import {getAllNotesByPropertyId} from "../actions/notesByPropertyId";
import {TFetchingStatus} from "../../helpers";
import {useHistory, useLocation} from "react-router-dom";
import {capitalize} from "../components/helper";
import {
  HeaderControlsWrapper,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  AddButtonStyled,
  Search,
  TooltipStyle,
  EmptyStateWrapper,
  IntroductionButton
} from "./components";
import _ from "lodash";
import {emptyGuid} from "./helpers";
import UpdateTaskDrawer from "../components/planner/UpdateTaskDrawer";
import AddEventDrawer from "../components/planner/AddEventDrawer";
import {ReactComponent as HandholdingArrowRight} from "../../images/HandholdingArrowRight.svg";
import {HandholdingCircle} from "./Home";
import {getCurrentUserProfile} from "../actions/getUserProfile";
import {setUserWindowsState} from "../../api-wrapper/user/setUserWindowsState";
import SpacesIntroduction from "../components/spaces/SpacesIntroduction";
import {ReactComponent as EmptySpacesIcons} from "../../images/empty-states/EmptySpacesIcons.svg";
import ViewFileDrawer from "../components/attachments/ViewFileDrawer";
import ViewWorkDrawer from "../components/works/ViewWorkDrawer";
import {ReactComponent as CollapseIconMinus} from "../../images/CollapseIconMinus.svg";
import {ReactComponent as CollapseIconPlus} from "../../images/CollapseIconPlus.svg";
import {ReactComponent as ChevronRight} from "../../images/ChevronRight.svg";
const { Panel } = Collapse;

const Spaces = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const properties = useSelector(getPropertiesSelector);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const spaces = useSelector(getSpacesSelector);
  const userProfile = useSelector(getUserProfileSelector);

  const [isViewSpaceDrawerOpen, toggleViewSpaceDrawer] = useState(false);
  const [viewSpaceId, setViewSpaceId] = useState(null);
  const [isEditSpaceDrawerOpen, toggleEditSpaceDrawer] = useState(false);
  const [editSpaceId, setEditSpaceId] = useState(null);
  const [linkedSpaceId, setLinkedSpaceId] = useState(null);
  const [isLinkSpaceDrawerOpen, toggleLinkSpaceDrawer] = useState(false);

  const [isViewWorkDrawerOpen, toggleViewWorkDrawer] = useState(false);
  const [workToViewId, setWorkToViewId] = useState<any>(null);
  const [isWorkDrawerOpen, toggleWorkDrawer] = useState(false);
  const [editingWorkData, setEditingWorkData] = useState(null);
  const [isExpenseDrawerOpen, toggleExpenseDrawer] = useState(false);
  const [editingExpenseData, setEditingExpenseData] = useState(null);
  const [isInventoryDrawerOpen, toggleInventoryDrawer] = useState(false);
  const [editingInventoryData, setEditingInventoryData] = useState(null);
  const [isContactDrawerOpen, toggleContactDrawer] = useState(false);
  const [editingContactData, setEditingContactData] = useState(null);
  const [isNoteDrawerOpen, toggleNoteDrawer] = useState(false);
  const [editingNoteData, setEditingNoteData] = useState(null);
  const [isTaskDrawerOpen, toggleTaskDrawer] = useState(false);
  const [editingTaskData, setEditingTaskData] = useState<any>(null);
  const [taskType, setTaskType] = useState("Task");
  const [isEventDrawerOpen, toggleEventDrawer]  = useState(false);
  const [isViewFileDrawerOpen, toggleViewFileDrawer] = useState(false);
  const [attachmentToViewId, setAttachmentToViewId] = useState<any>(null);

  const [isSearchOn, toggleSearch] = useState(false);
  const [areFiltersVisible, toggleFilters] = useState(false);
  const [search, setSearch] = useState("");
  const [sortingType, setSortingType] = useState("alphabet");
  const [activeKeys, setActiveKeys] = useState<any>(["categories"]);

  const [spaceTypesWithAmounts, setSpaceTypesWithAmounts] = useState<any>([]);
  const [spaceFloorsWithAmounts, setSpaceFloorsWithAmounts] = useState<any>([]);
  const [spaceCategoriesWithAmounts, setSpaceCategoriesWithAmounts] = useState<any>([]);
  const [selectedSpaceTypes, setSelectedSpaceTypes] = useState<Set<string>>(new Set());
  const [selectedSpaceFloors, setSelectedSpaceFloors] = useState<Set<string>>(new Set());
  const [selectedSpaceCategories, setSelectedSpaceCategories] = useState<Set<string>>(new Set());
  const [presetLinkType, setPresetLinkType] = useState<any>(null);
  const [currentProperty, setCurrentProperty] = useState<any>(null);
  const [handholdingStatus, setHandholdingStatus] = useState<any>(null);
  const [introductionStatus, setIntroductionStatus] = useState<any>(null);

  useEffect(() => {
    if (userProfile.fetchingStatus === TFetchingStatus.Success) {
      setHandholdingStatus(userProfile.content.handholdingStatus);
      setIntroductionStatus(userProfile.content.spacesStatus);
    }
  }, [userProfile])

  useEffect(() => {
    const current = properties.find((property) => property.propertyId === propertyId);
    if (!current)
      return;
    setCurrentProperty(current);
  }, [properties, propertyId])

  useEffect(() => {
    dispatch(getAllProperties());
    if (_.isEmpty(properties) || _.isEqual(properties[0].propertyId, emptyGuid.toJSON())  || _.isEqual(properties[0].propertyId, emptyGuid) || _.isEqual(propertyId, emptyGuid))
      return;
    dispatch(getAllSpaces(propertyId));
    dispatch(getAllMaintenances(propertyId));
    dispatch(getAllWorks(propertyId));
    dispatch(getAllExpenses(propertyId));
    dispatch(getAllInventories(propertyId));
    dispatch(getAllAttachments(propertyId));
    dispatch(getAllNotesByPropertyId(propertyId));
    dispatch(getCurrentUserProfile());
  }, [propertyId])

  useEffect(() => {
    if (spaces.content.length > 0 && handholdingStatus === "spaces") {
      setUserWindowsState({
        onboardingInterestSurveyStatus: userProfile.content.onboardingInterestSurveyStatus,
        onboardingUpkeepStatus: currentProperty.onboardingUpkeepStatus,
        onboardingContentStatus: currentProperty.onboardingContentStatus,
        onboardingImprovementsStatus: currentProperty.onboardingImprovementsStatus,
        mobileWelcomeStatus: userProfile.content.mobileWelcomeStatus,
        questionnairePage: currentProperty.questionnairePage,
        handholdingStatus: "contents",
        propertyId
      }).then(() => dispatch(getCurrentUserProfile()))
    }
  }, [spaces])

  const getLinkedItemCount = (space: any) => {
    return space.spaceNotes.length + space.attachments.length + space.works.length +
    space.expenses.length + space.inventories.length + space.contacts.length
  }

  const getSpaces = () =>
    spaces.content
      .filter(space => search ? space.name.toLowerCase().includes(search.toLowerCase()) : true)
      .filter(space => selectedSpaceTypes.size > 0 ? selectedSpaceTypes.has(space.spaceType) : true)
      .filter(space => selectedSpaceFloors.size > 0 ? selectedSpaceFloors.has(space.floor) : true)
      .filter(space => selectedSpaceCategories.size > 0 ? selectedSpaceCategories.has(space.category) : true)
      .sort((s1, s2) => {
        if (sortingType === "dateAsc") {
          return moment(s1.createdDate).valueOf() - moment(s2.createdDate).valueOf()
        } else if (sortingType === "dateDesc") {
          return moment(s2.createdDate).valueOf() - moment(s1.createdDate).valueOf()
        } else if (sortingType === "linksAsc") {
          return getLinkedItemCount(s1) - getLinkedItemCount(s2)
        } else if (sortingType === "linksDesc") {
          return getLinkedItemCount(s2) - getLinkedItemCount(s1)
        } else {
          return s1.name.toLowerCase().localeCompare(s2.name.toLowerCase())
        }
      })

  useEffect(() => {
    let typesMap = new Map();
    let floorsMap = new Map();
    let categoriesMap = new Map();
    let spaceTypes: any = [];
    let spaceFloors: any = [];
    let spaceCategories: any = [];

    spaces.content.forEach((space => {
      if (typesMap.has(space.spaceType)) {
        typesMap.set(space.spaceType, typesMap.get(space.spaceType)+1);
      } else {
        typesMap.set(space.spaceType, 1);
        spaceTypes.push(space.spaceType);
      }
      if (categoriesMap.has(space.category)) {
        categoriesMap.set(space.category, categoriesMap.get(space.category)+1);
      } else {
        categoriesMap.set(space.category, 1);
        spaceCategories.push(space.category);
      }
      if (space.floor) {
        if (floorsMap.has(space.floor)) {
          floorsMap.set(space.floor, floorsMap.get(space.floor) + 1);
        } else {
          floorsMap.set(space.floor, 1);
          spaceFloors.push(space.floor);
        }
      }
    }));

    let typeAmounts: any = [];
    let floorAmounts: any = [];
    let categoryAmounts: any = [];
    spaceTypes.forEach((type: string) => typeAmounts.push({type: type, amount: typesMap.get(type)}));
    spaceFloors.forEach((floor: string) => floorAmounts.push({type: floor, amount: floorsMap.get(floor)}));
    spaceCategories.forEach((category: string) => categoryAmounts.push({type: category, amount: categoriesMap.get(category)}));

    typeAmounts = typeAmounts.sort((e1: any, e2: any) => e2.amount - e1.amount);
    floorAmounts = floorAmounts.sort((e1: any, e2: any) => e2.amount - e1.amount);
    categoryAmounts = categoryAmounts.sort((e1: any, e2: any) => e2.amount - e1.amount);
    setSpaceTypesWithAmounts(typeAmounts);
    setSpaceFloorsWithAmounts(floorAmounts);
    setSpaceCategoriesWithAmounts(categoryAmounts);
  }, [spaces]);

  const sortingMenuDropdown = () => {
    const handleMenuClick = (event: {
      key: React.Key;
      keyPath: React.Key[];
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      event.domEvent.stopPropagation();
      const { key } = event;
      if (key === "alphabet") {
        setSortingType("alphabet")
      } else if (key === "dateAsc") {
        setSortingType("dateAsc")
      } else if (key === "dateDesc") {
        setSortingType("dateDesc")
      } else if (key === "linksAsc") {
        setSortingType("linksAsc")
      } else if (key === "linksDesc") {
        setSortingType("linksDesc")
      }
    };

    return (
      <MenuStyled selectedKeys={[]} onClick={handleMenuClick}>
        <Menu.Item key="alphabet">By alphabet</Menu.Item>
        <Menu.Item key="dateAsc">By date added (asc)</Menu.Item>
        <Menu.Item key="dateDesc">By date added (desc)</Menu.Item>
        <Menu.Item key="linksAsc">By number of links (asc)</Menu.Item>
        <Menu.Item key="linksDesc">By number of links (desc)</Menu.Item>
      </MenuStyled>
    );
  };

  const haveFiltersBeenUsed = selectedSpaceFloors.size > 0 || selectedSpaceTypes.size > 0 || selectedSpaceCategories.size > 0

  const getClassesForTag = (type: any, selectedType: any) => {
    if (type.has(selectedType.type)) return "tag-blue"
    if (selectedType.amount === 0) return "tag-gray"
  }

  const selectSpaceType = (type: string) => {
    const newSet = new Set(selectedSpaceTypes);
    if (selectedSpaceTypes.has(type)) {
      newSet.delete(type);
    } else {
      newSet.add(type);
    }
    setSelectedSpaceTypes(newSet);
  }

  const selectSpaceFloor = (type: string) => {
    const newSet = new Set(selectedSpaceFloors);
    if (selectedSpaceFloors.has(type)) {
      newSet.delete(type);
    } else {
      newSet.add(type);
    }
    setSelectedSpaceFloors(newSet);
  }

  const selectSpaceCategory = (type: string) => {
    const newSet = new Set(selectedSpaceCategories);
    if (selectedSpaceCategories.has(type)) {
      newSet.delete(type);
    } else {
      newSet.add(type);
    }
    setSelectedSpaceCategories(newSet);
  }

  const useQuery = (q: string) => {
    let query = new URLSearchParams(useLocation().search);
    return query.get(q);
  };
  const typeToOpen = useQuery("type")

  useEffect(() => {
    if (typeToOpen) {
      selectSpaceCategory(capitalize(typeToOpen))
    }
  }, [typeToOpen])
  
  const displayFilters = () => (
    <Filters style={{borderRadius: "16px"}}>
      <FilterGroupRow>
        <CategoryHeader>
          Filters <CancelButton onClick={() => {
            setSelectedSpaceTypes(new Set());
            setSelectedSpaceFloors(new Set());
            setSelectedSpaceCategories(new Set());
        }}>Clear filters</CancelButton>
        </CategoryHeader>
      </FilterGroupRow>
      <FilterGroupRow style={{flexDirection: "column", rowGap: "1rem", border: 0, marginBottom: 0}}>
        <Collapse collapsible={"disabled"} bordered={false} ghost onChange={(key) => setActiveKeys(key)} defaultActiveKey={activeKeys}>
          <Panel showArrow={false} header={<SubcategoryHeader>
            Categories
          </SubcategoryHeader>} key="categories">
            <EditTagsBody>
              <EditCategoryContainer>
                <EditCategoryHeader>Room type</EditCategoryHeader>
                <EditCategoryBody>
                  {spaceTypesWithAmounts.map((spaceType: any, index: any) => <TagStyled
                    key={index}
                    className={getClassesForTag(selectedSpaceTypes, spaceType)}
                    onClick={() => selectSpaceType(spaceType.type)}
                  >{spaceType.type} ({spaceType.amount})</TagStyled>)}
                </EditCategoryBody>
                <EditCategoryHeader>Floor</EditCategoryHeader>
                <EditCategoryBody>
                  {spaceFloorsWithAmounts.map((spaceFloor: any, index: any) => <TagStyled
                    key={index}
                    className={getClassesForTag(selectedSpaceFloors, spaceFloor)}
                    onClick={() => selectSpaceFloor(spaceFloor.type)}
                  >{spaceFloor.type} ({spaceFloor.amount})</TagStyled>)}
                </EditCategoryBody>
                <EditCategoryHeader>Space type</EditCategoryHeader>
                <EditCategoryBody>
                  {spaceCategoriesWithAmounts.map((spaceCategory: any, index: any) => <TagStyled
                    key={index}
                    className={getClassesForTag(selectedSpaceCategories, spaceCategory)}
                    onClick={() => selectSpaceCategory(spaceCategory.type)}
                  >{spaceCategory.type} ({spaceCategory.amount})</TagStyled>)}
                </EditCategoryBody>
              </EditCategoryContainer>
            </EditTagsBody>
          </Panel>
        </Collapse>
      </FilterGroupRow>
    </Filters>
  )

  const searchSortFilter = () => (
    <HeaderControlsWrapper className={isSearchOn ? "active mini" : "mini"}>
      {isSearchOn ? <Search
        size={"large"}
        value={search}
        style={{height: "3rem"}}
        onChange={(val) => setSearch(val.target.value)}
        placeholder={"Search spaces..."}
        suffix={<SearchIcon style={{cursor: "pointer"}} onClick={() => {
          toggleSearch(false)
          setSearch("")
        }} />}
      /> : <Tooltip title={"Search"} overlayClassName={"handholding"} placement={"bottom"} overlayInnerStyle={TooltipStyle} mouseEnterDelay={0.5}>
        <HeaderControl onClick={() => toggleSearch(true)}>
          <SearchIcon />
        </HeaderControl>
      </Tooltip>}
      <Tooltip title={"Filter"} overlayClassName={"handholding"} placement={"bottom"} overlayInnerStyle={TooltipStyle} mouseEnterDelay={0.5}>
        <HeaderControl
          onClick={() => toggleFilters(!areFiltersVisible)}
          className={areFiltersVisible ? "active" : ""}>
          {haveFiltersBeenUsed ? <FiltersIconActive /> : <FiltersIcon />}
        </HeaderControl>
      </Tooltip>
      <Tooltip title={"Sort"} overlayClassName={"handholding"} placement={"bottom"} overlayInnerStyle={TooltipStyle} mouseEnterDelay={0.5}>
        <Dropdown overlay={sortingMenuDropdown()} placement="bottomCenter" trigger={["click"]}>
          <HeaderControl
            className={sortingType !== "alphabet" ? "active mini" : "mini"}>
            <SortIcon/>
          </HeaderControl>
        </Dropdown>
      </Tooltip>
    </HeaderControlsWrapper>
  )

  const isSpacesListEmpty = spaces.fetchingStatus === TFetchingStatus.Success && spaces.content.length === 0
  const showIntroduction = introductionStatus !== "skipped" && introductionStatus !== "completed"
  const showEmptyStateIntroduction = introductionStatus !== "skipped"

  return <PageWrapper>
    <ViewSpaceDrawer
      spaceId={viewSpaceId}
      isOpen={isViewSpaceDrawerOpen}
      toggleDrawerOpen={toggleViewSpaceDrawer}
      toggleEditDrawerOpen={toggleEditSpaceDrawer}
      setEditSpaceId={setEditSpaceId}
      toggleLinkDrawerOpen={toggleLinkSpaceDrawer}
      setLinkedSpaceId={setLinkedSpaceId}
      setPresetLinkType={setPresetLinkType}
      toggleWorkDrawer={toggleWorkDrawer}
      setEditWorkData={setEditingWorkData}
      toggleExpenseDrawer={toggleExpenseDrawer}
      setEditExpenseData={setEditingExpenseData}
      toggleInventoryDrawer={toggleInventoryDrawer}
      setEditInventoryData={setEditingInventoryData}
      toggleContactDrawer={toggleContactDrawer}
      setEditContactData={setEditingContactData}
      toggleNoteDrawer={toggleNoteDrawer}
      setEditNoteData={setEditingNoteData}
      toggleTaskDrawer={toggleTaskDrawer}
      setEditTaskData={setEditingTaskData}
      setViewWorkId={setWorkToViewId}
      toggleViewWorkDrawer={toggleViewWorkDrawer}
      setAttachmentId={setAttachmentToViewId}
      toggleViewFileDrawer={toggleViewFileDrawer}
    />
    <EditSpaceDrawer
      spaceId={editSpaceId}
      isOpen={isEditSpaceDrawerOpen}
      toggleDrawerOpen={toggleEditSpaceDrawer}
      setEditingSpaceId={setEditSpaceId}
      setViewSpaceId={setViewSpaceId}
      toggleViewDrawerOpen={toggleViewSpaceDrawer}
    />
    <AddEventDrawer
      isOpen={isEventDrawerOpen}
      toggleDrawer={toggleEventDrawer}
      setSelectedType={setTaskType}
      toggleWorkDrawer={toggleWorkDrawer}
      toggleTaskDrawer={toggleTaskDrawer}
      setEditingWorkData={setEditingWorkData}
    />
    <LinkDrawer
      parentId={linkedSpaceId}
      parentType={"space"}
      isOpen={isLinkSpaceDrawerOpen}
      toggleDrawerOpen={toggleLinkSpaceDrawer}
      presetLinkType={presetLinkType}
      setPresetLinkType={setPresetLinkType}
      toggleWorkDrawer={toggleWorkDrawer}
      setEditWorkData={setEditingWorkData}
      toggleExpenseDrawer={toggleExpenseDrawer}
      setEditExpenseData={setEditingExpenseData}
      toggleInventoryDrawer={toggleInventoryDrawer}
      setEditInventoryData={setEditingInventoryData}
      toggleContactDrawer={toggleContactDrawer}
      setEditContactData={setEditingContactData}
      toggleNoteDrawer={toggleNoteDrawer}
      setEditNoteData={setEditingNoteData}
      setEditSpaceId={setEditSpaceId}
      toggleSpaceDrawer={toggleEditSpaceDrawer}
      toggleTaskDrawer={toggleTaskDrawer}
      setEditTaskData={setEditingTaskData}
      toggleEventDrawer={toggleEventDrawer}
      setViewWorkId={setWorkToViewId}
      toggleViewWorkDrawer={toggleViewWorkDrawer}
      setAttachmentId={setAttachmentToViewId}
      toggleViewFileDrawer={toggleViewFileDrawer}
    />
    <ViewWorkDrawer
      workId={workToViewId}
      setWorkId={setWorkToViewId}
      isOpen={isViewWorkDrawerOpen}
      toggleDrawer={toggleViewWorkDrawer}
      toggleWorkDrawer={toggleWorkDrawer}
      setEditWorkData={setEditingWorkData}
      toggleExpenseDrawer={toggleExpenseDrawer}
      setEditExpenseData={setEditingExpenseData}
      toggleInventoryDrawer={toggleInventoryDrawer}
      setEditInventoryData={setEditingInventoryData}
      toggleContactDrawer={toggleContactDrawer}
      setEditContactData={setEditingContactData}
      toggleNoteDrawer={toggleNoteDrawer}
      setEditNoteData={setEditingNoteData}
      setLinkedWorkId={() => {}}
      toggleLinkDrawerOpen={toggleLinkSpaceDrawer}
      setWorkIdToForward={() => {}}
      toggleForwardDrawer={() => {}}
      toggleTradeDrawer={() => {}}
      setEditSpaceId={setEditSpaceId}
      toggleSpaceDrawer={toggleViewSpaceDrawer}
      setPresetLinkType={setPresetLinkType}
      toggleTaskDrawer={toggleTaskDrawer}
      setEditTaskData={setEditingTaskData}
      setAttachmentId={setAttachmentToViewId}
      toggleViewFileDrawer={toggleViewFileDrawer}
    />
    <UpdateTaskDrawer
      task={editingTaskData}
      setEditingTaskData={setEditingTaskData}
      isOpen={isTaskDrawerOpen}
      toggleDrawer={toggleTaskDrawer}
      taskType={taskType}
      setTaskType={setTaskType}
    />
    <WorkDrawer
      isOpen={isWorkDrawerOpen}
      toggleDrawerOpen={toggleWorkDrawer}
      work={editingWorkData}
      // @ts-ignore
      setEditingWorkData={setEditingWorkData}
      // @ts-ignore
      refreshParent={() => dispatch(getAllSpaces(propertyId))}
    />
    <ExpenseDrawer
      isOpen={isExpenseDrawerOpen}
      toggleDrawerOpen={toggleExpenseDrawer}
      expense={editingExpenseData}
      // @ts-ignore
      setEditingExpenseData={setEditingExpenseData}
      // @ts-ignore
      refreshParent={() => dispatch(getAllSpaces(propertyId))}
    />
    <InventoryDrawer
      isOpen={isInventoryDrawerOpen}
      toggleDrawerOpen={toggleInventoryDrawer}
      inventory={editingInventoryData}
      // @ts-ignore
      setEditingInventoryData={setEditingInventoryData}
      refreshParent={() => dispatch(getAllSpaces(propertyId))}
      setViewWorkId={setWorkToViewId}
      toggleViewWorkDrawer={toggleViewWorkDrawer}
    />
    <ContactDrawer
      isOpen={isContactDrawerOpen}
      toggleDrawerOpen={toggleContactDrawer}
      contact={editingContactData}
      // @ts-ignore
      setEditingContactData={setEditingContactData}
      refreshParent={() => dispatch(getAllSpaces(propertyId))}
      setViewWorkId={setWorkToViewId}
      toggleViewWorkDrawer={toggleViewWorkDrawer}
    />
    <NoteDrawer
      currentType={"note"}
      data={editingNoteData}
      isOpen={isNoteDrawerOpen}
      toggleDrawerOpen={toggleNoteDrawer}
      refreshParent={() => dispatch(getAllSpaces(propertyId))}
      isGlobalNote={true}
      toggleTaskDrawer={toggleTaskDrawer}
      setEditTaskData={setEditingTaskData}
      setPresetLinkType={setPresetLinkType}
      toggleLinkDrawerOpen={toggleLinkSpaceDrawer}
      setViewWorkId={setWorkToViewId}
      toggleViewWorkDrawer={toggleViewWorkDrawer}
      toggleExpenseDrawer={toggleExpenseDrawer}
      setEditExpenseData={setEditingExpenseData}
      toggleInventoryDrawer={toggleInventoryDrawer}
      setEditInventoryData={setEditingInventoryData}
      toggleContactDrawer={toggleContactDrawer}
      setEditContactData={setEditingContactData}
      setEditSpaceId={setViewSpaceId}
      toggleSpaceDrawer={toggleViewSpaceDrawer}
      setAttachmentId={setAttachmentToViewId}
      toggleViewFileDrawer={toggleViewFileDrawer}
    />
    <ViewFileDrawer
      attachmentId={attachmentToViewId}
      setAttachmentId={setAttachmentToViewId}
      isOpen={isViewFileDrawerOpen}
      toggleDrawer={toggleViewFileDrawer}
      viewFileDrawerDefaultTab={"preview"}
      attachmentsWithoutProperty={[]}
      duplicateAttachments={[]}
      selectedTags={[]}
      toggleEditTagsMode={() => {}}
      toggleEditDrawerOpen={() => {}}
      setPresetLinkType={() => {}}
      toggleWorkDrawer={() => {}}
      setEditWorkData={() => {}}
      toggleExpenseDrawer={() => {}}
      setEditExpenseData={() => {}}
      toggleInventoryDrawer={() => {}}
      setEditInventoryData={() => {}}
      toggleContactDrawer={() => {}}
      setEditContactData={() => {}}
      toggleNoteDrawer={() => {}}
      setEditNoteData={() => {}}
      toggleLinkDrawerOpen={() => {}}
      setEditSpaceId={() => {}}
      toggleSpaceDrawer={() => {}}
      refreshParent={() => {}}
      toggleTaskDrawer={() => {}}
      setEditTaskData={() => {}}
      toggleViewWorkDrawer={() => {}}
      setViewWorkId={() => {}}
    />

    <PageHeader>
      <HeaderControlsWrapper>
        <div>
          <PageTitle>Spaces</PageTitle>
          <PageSubtitle>Organise your home information room-by-room</PageSubtitle>
        </div>
      </HeaderControlsWrapper>
      <HeaderControlsWrapper>
        <Conditional className={"desktop"}>{searchSortFilter()}</Conditional>
        <AddButtonStyled
          className={"spaces"}
          icon={<PlusOutlined />}
          onClick={() => toggleEditSpaceDrawer(true)}
        >
          Add space
        </AddButtonStyled>
      </HeaderControlsWrapper>
    </PageHeader>
    <Conditional className={"mobile"} style={{width: "100%", justifyContent: "flex-end"}}>
      {searchSortFilter()}
    </Conditional>

    {areFiltersVisible && displayFilters()}

    {(isSpacesListEmpty && showEmptyStateIntroduction || !isSpacesListEmpty && showIntroduction) && <SpacesIntroduction
      handholdingStatus={handholdingStatus}
      introductionStatus={introductionStatus}
      toggleSpacesDrawer={toggleEditSpaceDrawer}
      />}

    {isSpacesListEmpty && !showEmptyStateIntroduction && <EmptyStateWrapper className={"unwrapped"}>
      <EmptySpacesIcons />
      <span>Add spaces to view your home information by room and location.</span>
      <IntroductionButton onClick={() => history.push("/questions?openPage=spaces&origin=spaces")} size={"large"}>
        Let’s start by selecting a few items
        <ChevronRight style={{marginLeft:"0.5rem", height:"0.75rem"}} />
      </IntroductionButton>
    </EmptyStateWrapper>}

    {spaces.fetchingStatus === TFetchingStatus.Success && <ResponsiveMasonry
        columnsCountBreakPoints={{100: 2, 768: 3, 1024: 2, 1200: 3}}>
      <Masonry gutter={"1rem"}>
        {getSpaces().map(space => <SpaceCard
          space={space}
          toggleLinkSpaceDrawer={toggleLinkSpaceDrawer}
          toggleEditSpaceDrawer={toggleEditSpaceDrawer}
          toggleViewSpaceDrawer={toggleViewSpaceDrawer}
          setLinkedSpaceId={setLinkedSpaceId}
          setEditSpaceId={setEditSpaceId}
          setViewSpaceId={setViewSpaceId}
          setViewWorkId={setWorkToViewId}
          toggleViewWorkDrawer={toggleViewWorkDrawer}
          toggleWorkDrawer={toggleWorkDrawer}
          setEditWorkData={setEditingWorkData}
          toggleExpenseDrawer={toggleExpenseDrawer}
          setEditExpenseData={setEditingExpenseData}
          toggleInventoryDrawer={toggleInventoryDrawer}
          setEditInventoryData={setEditingInventoryData}
          toggleContactDrawer={toggleContactDrawer}
          setEditContactData={setEditingContactData}
          toggleNoteDrawer={toggleNoteDrawer}
          setEditNoteData={setEditingNoteData}
          setAttachmentId={setAttachmentToViewId}
          toggleViewFileDrawer={toggleViewFileDrawer}
        />)}
      </Masonry>
    </ResponsiveMasonry>}
    {spaces.fetchingStatus === TFetchingStatus.Success && getSpaces().length === 0 && (haveFiltersBeenUsed || search.length > 0) &&
      <PageSubtitle>Couldn't find any spaces corresponding to your query.</PageSubtitle>}
  </PageWrapper>
}

const HeaderControl = styled.div`
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  border: 1px solid #DAE0E6;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #f0f2f5;
  transition: all .5s ease;
  
  &.active {
    background: white;
  }
  
  @media (max-width: 768px) {
    height: 2rem;
    width: 2rem;
    
    svg path { 
      transform: scale(0.75);
      transform-origin: center;
    }
  }
`

const Conditional = styled.div`
  &.desktop { display: flex; }
  &.mobile { display: none; }
  
  @media (max-width: 1024px) {
    &.desktop { display: none; }
    &.mobile { display: flex; }
  }
`

// const FloorNumberBox = styled.div`
//   width: 5rem;
//   height: 5rem;
//   background: #DAE0E6;
//   border-radius: 8px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   font-weight: 500;
//   font-size: 0.75rem;
//   line-height: 1rem;
//   text-transform: uppercase;
//   color: #666F7A;
//   row-gap: 0.375rem;
//
//   .number {
//     font-size: 1.5rem;
//     line-height: 2rem;
//   }
// `

export default Spaces