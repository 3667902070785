import { apiPost, createUrl } from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

type TAddPropertyRequest = {
  nickname?: string | null;
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zip?: string | null;
};

type TAddPropertyResponse = {
  nickname?: string | null;
  address: string;
  address2?: string | null;
  city: string;
  state?: string | null;
  country: string;
  zip: string;
  propertyId: Guid;
};


export const addProperty = (payload: TAddPropertyRequest) => {
  const url = createUrl(`${EnvConfig.API_URL}/api/property`);

  return apiPost<TAddPropertyResponse, TAddPropertyRequest>(url, payload);
};
