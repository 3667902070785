import {apiPost, createUrl} from "../apiCall";
import EnvConfig from "../../config";
import {Guid} from "guid-typescript";

type TNotApplicableRequest = {
  propertyId: Guid;
  maintenanceId: Guid;
  applicable: boolean;
}

export const toggleNotApplicable = (payload: TNotApplicableRequest) => {
  const { propertyId, maintenanceId, applicable } = payload;

  const url = createUrl(
    `${EnvConfig.API_URL}/api/property/${propertyId}/alerts/trigger/${maintenanceId}/notapplicable?applicable=${applicable}`
  );

  return apiPost(url);
};