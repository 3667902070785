import React, {useEffect} from "react";
import styled from "styled-components";

import { ReactComponent as BuildHomeContacts } from "../../../images/homepage/BuildHomeContacts.svg";
import { ReactComponent as BuildHomeContents } from "../../../images/homepage/BuildHomeContents.svg";
import { ReactComponent as BuildHomeFiles } from "../../../images/homepage/BuildHomeFiles.svg";
import { ReactComponent as BuildHomeNotes } from "../../../images/homepage/BuildHomeNotes.svg";
import { ReactComponent as BuildHomeTitle } from "../../../images/homepage/BuildHomeTitle.svg";
import {Popover} from "antd";
import {useHistory} from "react-router-dom";
import {getAllInventories} from "../../actions/inventories";
import {getAllContacts} from "../../actions/contacts";
import {getAllAttachments} from "../../actions/attachments";
import {useDispatch, useSelector} from "react-redux";
import {
  getAttachmentsSelector,
  getContactsSelector,
  getInventoriesSelector, getNotesByPropertyIdSelector,
  getPropertyIdSelector
} from "../../selectors";
import {getAllNotesByPropertyId} from "../../actions/notesByPropertyId";

const HomeCard = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const propertyId = useSelector(getPropertyIdSelector).value;
  const inventories = useSelector(getInventoriesSelector)
  const attachments = useSelector(getAttachmentsSelector)
  const contacts = useSelector(getContactsSelector)
  const notes = useSelector(getNotesByPropertyIdSelector)

  useEffect(() => {
    dispatch(getAllInventories(propertyId))
    dispatch(getAllContacts(propertyId))
    dispatch(getAllNotesByPropertyId(propertyId))
    dispatch(getAllAttachments(propertyId))
  }, [propertyId])

  const inventoriesCount = inventories.content?.filter(item => item.name).length
  const contactsCount = contacts.content?.filter(item => item.name && item.contactType !== "Payee").length

  return <CardOuterWrapper>
    <CardTitleWrapper>
      <BuildHomeTitle /> Build my home
    </CardTitleWrapper>
    <CardInnerWrapper>
      <CardIntro>View and add to your data home's records:</CardIntro>
      <CardItem onClick={() => history.push("/contents")}>
        <InfoIconWrapper>
          <CountIcon>{inventoriesCount > 100 ? "99+" : inventoriesCount}</CountIcon>
        </InfoIconWrapper>
        <BuildHomeContents /> Contents</CardItem>
      <CardItem onClick={() => history.push("/documents")}>
        <InfoIconWrapper>
          <CountIcon>{attachments.content?.length > 100 ? "99+" : attachments.content?.length}</CountIcon>
        </InfoIconWrapper>
        <BuildHomeFiles /> Documents
      </CardItem>
      <CardItem onClick={() => history.push("/notes")}>
        <InfoIconWrapper>
          <CountIcon>{notes.length > 100 ? "99+" : notes.length}</CountIcon>
        </InfoIconWrapper>
        <BuildHomeNotes /> Notes
      </CardItem>
      <CardItem onClick={() => history.push("/contacts")}>
        <InfoIconWrapper>
          <CountIcon>{contactsCount > 100 ? "99+" : contactsCount}</CountIcon>
        </InfoIconWrapper>
        <BuildHomeContacts /> Contacts
      </CardItem>
    </CardInnerWrapper>
  </CardOuterWrapper>
}

export const CardOuterWrapper = styled.div`
  width: calc(50% - 0.75rem);
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  
  @media (max-width: 1275px) {
     width: 100%;
  }
  
  @media (max-width: 1024px) {
     width: calc(50% - 0.75rem);
  }
  
  @media (max-width: 850px) {
     width: 100%;
  }
`

export const CardTitleWrapper = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #21272B;
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
`

export const CardIntro = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #45655E;
`

export const CardInnerWrapper = styled.div`
  padding: 1.5rem;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const CardItem = styled.div`
  cursor: pointer;
  position: relative;
  border: 1px solid #DAE0E6;
  border-radius: 24px;
  padding: 0.5rem;
  width: calc(50% - 1rem);
  min-height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #0C2A41;
  row-gap: 1rem;
`

export const InfoIconWrapper = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  width: 1.5rem;
  height: 1.5rem;
`

export const DropdownOverlay = styled.div`
  padding: 1rem;
  width: 300px;
  background: #236EC2;
  box-shadow: 0 2px 4px 1px rgba(30, 32, 37, 0.05), 0 5px 12px rgba(26, 28, 31, 0.12);
  border-radius: 8px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #FFFFFF;
`

const CountIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #EFF1F5;
  font-size: 0.65rem;
  font-weight: 600;
  color: #8E8E89;
`

export const infoDropdown = (title: string, description: any, content: any) => (
  <Popover placement={"left"} content={<DropdownOverlay>
    <div style={{fontWeight: 600, marginBottom: "0.5rem"}}>{title}</div>
    {description}
  </DropdownOverlay>}>{content}</Popover>
)

export default HomeCard