import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getPropertiesSelector, getPropertyIdSelector} from "../../selectors";
import _ from "lodash";
import {emptyGuid} from "../../screens/helpers";
import {CircleIcon, DrawerHeader, DrawerStyled} from "../../screens/components";
import {ReactComponent as ReturnArrow} from "../../../images/spaces/ReturnArrow.svg";
import {ReactComponent as CloseIcon} from "../../../images/spaces/CloseIcon.svg";
import {Legalese, ServiceUnavailable, TradeDetails, TradeDisclaimer, TradeItem, TradesContainer} from "./TradeDrawer";
import {ContainerItemWrapper, ContainerTitle} from "../spaces/AddSpaceOptions";
import {ReactComponent as MortgageIcon} from "../../../images/partners/MortgageIcon.svg";

type Props = {
  isDrawerOpen: boolean;
  toggleDrawer: (arg: boolean) => void;
}

const MortgagePartnerDrawer = (props: Props) => {
  const {isDrawerOpen, toggleDrawer} = props;
  const [propertyLocation, setPropertyLocation] = useState<any>(null);
  const [propertyZipCode, setPropertyZipCode] = useState<any>(null);
  const propertyId = useSelector(getPropertyIdSelector).value;
  const properties = useSelector(getPropertiesSelector);

  useEffect(() => {
    if (_.isObject(propertyId) && _.isEqual(propertyId, emptyGuid))
      return;
    const currentProperty = properties.find((property) => property.propertyId === propertyId);
    if (!currentProperty)
      return;
    setPropertyLocation(`${currentProperty.country}`)
    setPropertyZipCode(currentProperty.zip)
  }, [propertyId])

  const handleCancel = () => {
    toggleDrawer(false)
  }

  return <DrawerStyled
    push={{ distance: "32px" }}
    closeIcon={false}
    width={window.innerWidth > 1024 ? "680px" : "100%"}
    height={window.innerWidth > 768 ? "100%" : "85%"}
    placement={window.innerWidth > 768 ? "right" : "bottom"}
    visible={isDrawerOpen}
    onClose={() => handleCancel()}
    title={<DrawerHeader className={"edit-header"}>
      {window.innerWidth <= 768 && <ReturnArrow onClick={() => handleCancel()}/>}
      Mortgage adviser
      {window.innerWidth > 768 && <CircleIcon onClick={() => handleCancel()}><CloseIcon /></CircleIcon>}
    </DrawerHeader>}
  >
    {propertyLocation === "United Kingdom" && propertyZipCode ? <>
      <TradesContainer className={"open stretched"}>
        <ContainerTitle><MortgageIcon style={{flexShrink: 0}} />Speak to an expert mortgage adviser</ContainerTitle>
        <ContainerItemWrapper>
          <TradeItem>
            <TradeDetails>
              <span>Arrange a free consultation meeting on <span className={"accent-green"}>0330 043 0013*</span></span>
              <span className={"disclaimer"}>*Standard network charges may apply.</span>
            </TradeDetails>
          </TradeItem>
        </ContainerItemWrapper>
      </TradesContainer>
      {Legalese(false, "mortgage")}
    </> : ServiceUnavailable()}
  </DrawerStyled>
}

export default MortgagePartnerDrawer;